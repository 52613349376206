import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { UserContext } from '../../../context/UserContext';

import Tooltip from 'antd/es/tooltip';

import Heading from '../../ui/Heading';

import ShipRoute from '../../activity/ShipRoute';
import ShipBadges from '../../activity/ShipBadges';
import { TIME_FORMAT } from '../../../utils/constants';
import NotificationRow from '../../activity/NotificationRow';
import Transition from '../../activity/Transition';
import Icon from '../../ui/Icon';
import { ColorSquare } from '../PilotageColorIndicator';
import DateComponent from '../../ui/DateComponent';

const CardWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding: 12px ${({ theme }) => theme.sizing.gap} 3px ${({ theme }) => theme.sizing.gap};
  background: ${props => (props.pinned ? props.theme.color.highlight : props.theme.color.white)};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.style.border_radius};
  width: 264px;
  height: ${props => (props.marineTraffic ? '226px' : '196px')};
`;

const NotificationWrapper = styled.div`
  position: absolute;
  bottom: -0.5rem;
  right: calc(${({ theme }) => theme.sizing.gap} + 1px);
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const CardInnerWrap = styled.div``;

const ShipName = styled(Heading)`
  display: inline-block;
  margin-bottom: 6px;
  font-size: 17px;
  line-height: 14px;
  white-space: nowrap;
  color: #4a4a4a;
`;

const Agent = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IMORow = styled.div`
  display: flex;
  flex-wrap: none;
  height: 18px;
  line-height: 18px;
`;

const ShipIMOLinkIconContainer = styled.span`
  display: none;
  margin-left: 4px;
  cursor: pointer;
`;

const ShipIMO = styled.div`
  margin-right: 8px;
  display: flex;
  &:hover {
    ${ShipIMOLinkIconContainer} {
      display: inline;
    }
  }
`;

const ShipIMOText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
`;

const NextEvent = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};
  font-size: 12px;
  white-space: nowrap;
`;

const NextEventTitle = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
`;

const NextEventTimestamp = styled(DateComponent)``;

const Nationality = styled.sup`
  margin-left: ${({ theme }) => theme.sizing.gap_tiny};
  font-size: 12px;
  user-select: none;
  margin-top: 2px;
  top: -0.1em;
  position: relative;
  height: 10px;
`;

const Placeholder = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: 12px;
`;

const LowerRowWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: ${({ theme }) => theme.sizing.gap_small};
  width: 100%;
  height: 36px;
  cursor: default;
`;

const Common = styled.div`
  display: flex;
  padding: 0px 10px 0 10px;
  font-size: 13px;

  a {
    cursor: pointer;
  }
`;

const ShipIcon = styled(Icon)`
  svg {
    width: 14px;
    height: 14px;
  }
`;

const TooltipValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 6px;
`;

const SourceTag = styled.div`
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  height: 18px;
  line-height: 16px;
  padding: 0 4px;
  display: inline-block;
  border-radius: 4px;
  color: #4a4a4a;
  margin-left: 12px;
`;

const TimestampRow = styled.div`
  display: flex;
  white-space: nowrap;
`;

const PilotContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

const StyledColorSquare = styled(ColorSquare)`
  margin: 2px 4px 0 0px;
  height: 13px;
  width: 13px;
`;

const VesselTimestampFleetModalCard = ({
  data,
  imo,
  master_id,
  notifications,
  decisions,
  transitions,
  privateData,
  ownData,
  map = false,
  fleetAisData = undefined,
  marineTraffic,
  inPilot,
  outPilot,
  ...props
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { vesselName, nationality, previousPortName, nextPortName, portName, dataSource } = data;

  const getVesselShortName = (name, transitionsAvailable) => {
    const nameLength = name.length;

    const maxLength = transitionsAvailable ? 18 : 20;

    if (nameLength > maxLength) {
      if (isNaN(name.slice(-1))) {
        return name.substring(0, maxLength - 2) + '...';
      } else {
        return name.substring(0, maxLength - 6) + '...' + name.substring(name.length - 4, name.length);
      }
    } else {
      return name;
    }
  };

  let agent = data.agentInfo && data.agentInfo.length === 1 ? data.agentInfo[0].name : null;
  if (agent === null && data.agentInfo) {
    const agentData = data.agentInfo.find(a => a.role === 1);
    if (agentData) {
      agent = agentData.name;
    }
  }

  let badges = !privateData
    ? data.portAreaDetails &&
      data.portAreaDetails[data.portAreaDetails.length - 1] &&
      data.portAreaDetails[data.portAreaDetails.length - 1].berthName
      ? [
          {
            parameter: 'berth_name',
            type: 'default',
            value: data.portAreaDetails[data.portAreaDetails.length - 1].berthName,
          },
        ]
      : null
    : privateData.ship.badges;

  let portAreaName = data?.portAreaDetails?.[0]?.portAreaName;

  return (
    <CardWrapper
      {...props}
      map={map}
      marineTraffic={(namespace === 'vts' && marineTraffic) || (namespace !== 'vts' && !!dataSource)}
    >
      <CardInner>
        <CardInnerWrap>
          <ShipName h3>
            <Tooltip
              placement="top"
              title={vesselName}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              {getVesselShortName(vesselName, !!transitions)}
            </Tooltip>
            <Nationality>{nationality}</Nationality>
            <Transition transitions={transitions} />
          </ShipName>
          <IMORow>
            <ShipIMO>
              <ShipIMOText>
                IMO <span style={{ fontWeight: 400 }}>{imo}</span>
              </ShipIMOText>
            </ShipIMO>
            <Tooltip
              placement="top"
              title={agent || '-'}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              <Agent>{agent || '-'}</Agent>
            </Tooltip>
          </IMORow>
          <ShipBadges badges={badges} vessel_name={vesselName} />
          {previousPortName ? (
            <ShipRoute
              from={previousPortName ? previousPortName.replace(/ *\([^)]*\) */g, '') : t('Unknown port')}
              to={portName ? portName.replace(/ *\([^)]*\) */g, '') : t('Unknown port')}
              portArea={portAreaName}
              next={nextPortName ? nextPortName.replace(/ *\([^)]*\) */g, '') : t('Unknown port')}
            />
          ) : (
            <Placeholder style={{ marginBottom: '3px' }}>{t('Trip details unknown')}</Placeholder>
          )}
          {!privateData ? (
            data?.portAreaDetails?.[data.portAreaDetails.length - 1]?.eta ? (
              <TimestampRow>
                <NextEvent style={{ width: '130px' }}>
                  <NextEventTitle>{t('ETA')}</NextEventTitle>
                  <NextEventTimestamp
                    format={TIME_FORMAT}
                    date={data.portAreaDetails[data.portAreaDetails.length - 1].eta}
                  />
                </NextEvent>
                {inPilot ? (
                  <PilotContainer>
                    <StyledColorSquare
                      inPilotEstimated={inPilot.type === 'RPS'}
                      inPilotOrdered={inPilot.type === 'PPS'}
                      inPilotCommenced={inPilot.type === 'APS'}
                      inPilotCompleted={inPilot.type === 'APC'}
                    />
                    <NextEvent>
                      <NextEventTimestamp format={TIME_FORMAT} date={inPilot.date} />
                    </NextEvent>
                  </PilotContainer>
                ) : (
                  <NextEvent>{t('No pilot info')}</NextEvent>
                )}
              </TimestampRow>
            ) : (
              <Placeholder>{t('ETA unknown')}</Placeholder>
            )
          ) : null}
          {!privateData ? (
            data?.portAreaDetails?.[data.portAreaDetails.length - 1]?.etd ? (
              <TimestampRow>
                <NextEvent style={{ width: '130px' }}>
                  <NextEventTitle>{t('ETD')}</NextEventTitle>
                  <NextEventTimestamp
                    format={TIME_FORMAT}
                    date={data.portAreaDetails[data.portAreaDetails.length - 1].etd}
                  />
                </NextEvent>
                {outPilot ? (
                  <PilotContainer>
                    <StyledColorSquare
                      outPilotEstimated={outPilot.type === 'RPS'}
                      outPilotOrdered={outPilot.type === 'PPS'}
                      outPilotCommenced={outPilot.type === 'APS'}
                      outPilotCompleted={outPilot.type === 'APC'}
                    />
                    <NextEvent>
                      <NextEventTimestamp format={TIME_FORMAT} date={outPilot.date} />
                    </NextEvent>
                  </PilotContainer>
                ) : (
                  <NextEvent>{t('No pilot info')}</NextEvent>
                )}
              </TimestampRow>
            ) : (
              <Placeholder>{t('ETD unknown')}</Placeholder>
            )
          ) : null}
          {privateData
            ? !privateData.ship.next_events &&
              (privateData.ship.next_event?.ts && privateData.ship.next_event?.title ? (
                <NextEvent>
                  <NextEventTitle>{t(privateData.ship.next_event.title)}</NextEventTitle>
                  <NextEventTimestamp format={TIME_FORMAT} date={privateData.ship.next_event.ts} />
                </NextEvent>
              ) : (
                <Placeholder>{t('ETA unknown')}</Placeholder>
              ))
            : null}
          {privateData
            ? !!privateData.ship.next_events &&
              privateData.ship.next_events.map((e, i) => {
                return (
                  <NextEvent key={i}>
                    <NextEventTitle>{t(e.title)}</NextEventTitle>
                    {e.ts ? <NextEventTimestamp format={TIME_FORMAT} date={e.ts} /> : '-'}
                  </NextEvent>
                );
              })
            : null}
        </CardInnerWrap>
        {ownData && (
          <LowerRowWrapper>
            <NotificationWrapper>
              <NotificationRow
                notifications={notifications}
                decisions={decisions}
                imo={imo}
                vessel_name={vesselName}
                master_id={master_id}
              />
            </NotificationWrapper>
          </LowerRowWrapper>
        )}
        <div>
          <NextEvent>
            <NextEventTitle>{t('AIS destination')}:</NextEventTitle>
            <NextEventTitle>{fleetAisData?.data?.aisData?.destination || '-'}</NextEventTitle>
          </NextEvent>
          {fleetAisData?.data?.aisData?.formattedEta ? (
            <NextEvent>
              <NextEventTitle>{t('AIS ETA')}</NextEventTitle>
              <NextEventTitle>
                {fleetAisData?.data?.aisData?.formattedEta
                  ? dayjs(fleetAisData?.data?.aisData?.formattedEta)
                    .utc()
                    .format('DD.MM. HH:mm') + ' UTC'
                  : '-'}
              </NextEventTitle>
            </NextEvent>
          ) : (
            <Placeholder>{t('AIS ETA unknown')}</Placeholder>
          )}
        </div>

        {namespace === 'vts' && marineTraffic && (
          <Common style={{ paddingBottom: '6px' }}>
            <a href={`https://www.marinetraffic.com/en/ais/details/ships/imo:${imo}`} target="_blank" rel="noreferrer">
              <ShipIcon type="ship" style={{ marginRight: '.8rem' }} />
              {t('Open in Marine Traffic')}
            </a>
          </Common>
        )}

        {namespace !== 'vts' && !!dataSource && (
          <TooltipValue>
            {t('Source')} <SourceTag>{dataSource}</SourceTag>
          </TooltipValue>
        )}
      </CardInner>
    </CardWrapper>
  );
};

export default VesselTimestampFleetModalCard;
