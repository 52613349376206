import React, { useContext, useRef, useState, useEffect } from 'react';
import { TIME_FORMAT, PAGINATION_LIMIT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import { v4 as uuid } from 'uuid';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';
import Spin from 'antd/es/spin';
import DatePicker from 'antd/es/date-picker';
import Popconfirm from 'antd/es/popconfirm';
import Switch from 'antd/es/switch';
import App from 'antd/es/app';

import { isEmailValid, isPortUnikie } from '../../utils/utils';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import Select from '../ui/Select';
import Input from '../ui/Input';
import Form from '../ui/Form';
import SecretText from '../ui/SecretText';

import PageActionForm from '../page/PageActionForm';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import FormsCodeSentEmail from './FormsCodeSentEmail';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const ModalInner = styled.div`
  padding: 24px;
`;

// TODO: get list from backend
const FORM_TYPES = ['register-port-call'];

const FormsCodes = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const { message } = App.useApp();

  let loading = true;

  const [apiCallPending, setApiCallPending] = useState(false);

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [uniqueMessageKey, setUniqueMessageKey] = useState('');
  const initModalData = {
    id: null,
    emails: '',
  };
  const [modalData, setModalData] = useState(initModalData);
  const [emailsLoading, setEmailsLoading] = useState(false);
  const [emailsData, setEmailsData] = useState(null);
  const [emailsError, setEmailsError] = useState(null);

  const initModal = {
    visible: false,
    confirmLoading: false,
  };
  const [modal, setModal] = useState(initModal);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'created_at DESC',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: codesLoading, data: codesData, error: codesError, fetchData } = useApi(
    'get',
    'forms-codes',
    newParams
  );

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const codes = codesError || !codesData ? [] : codesData.codes;

  if (codesError) {
    message.error(codesError);
  }

  const { start, total } = codesError || !codesData ? { start: 0, total: 0 } : codesData.pagination;

  if (!codesLoading) {
    loading = false;
  }

  const fetchSentEmails = async id => {
    setEmailsLoading(true);
    try {
      const res = await apiCall('get', `forms-codes-sent-email/${id}`);
      setEmailsData(res.data);
    } catch (e) {
      setEmailsLoading(false);
      const responseErrorMessage = e?.response?.data?.error;
      setEmailsError(responseErrorMessage || 'Oops! Something went wrong.');
    }
    showActions(false);
    setEmailsLoading(false);
  };

  const handleSave = async values => {
    let apiCallError = null;
    const { description, expires, type } = values;
    setApiCallPending(true);
    showActions(false);
    try {
      await apiCall('post', 'forms-code', {
        description,
        type,
        expires: expires ? dayjs(expires).toISOString() : null,
      });
    } catch (e) {
      apiCallError = e;
    }
    values.type = FORM_TYPES[0];
    values.description = '';
    values.expires = dayjs().add(1, 'month');
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleDelete = async id => {
    let apiCallError = null;
    setApiCallPending(true);
    try {
      await apiCall('delete', `forms-code/${id}`, {});
    } catch (e) {
      apiCallError = e;
    }
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleUpdate = async (id, enabled, type, expires) => {
    let apiCallError = null;
    setApiCallPending(true);
    try {
      await apiCall('put', `forms-code/${id}`, {
        enabled: enabled ? 1 : 0,
        type: type,
        expires: expires ? dayjs(expires).toISOString() : null,
      });
    } catch (e) {
      apiCallError = e;
    }
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    values.type = FORM_TYPES[0];
    values.description = '';
    values.expires = dayjs().add(1, 'month');
  };

  const copyToClipboard = text => {
    if (uniqueMessageKey !== '') {
      message.destroy(uniqueMessageKey);
    }
    setUniqueMessageKey(uuid());
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(text);
      message.success({
        key: uniqueMessageKey,
        content: 'Forms code copied to clipboard',
        duration: 3,
      });
    } else {
      window.clipboardData.setData('Text', text);
      message.success({
        key: uniqueMessageKey,
        content: 'Forms code copied to clipboard',
        duration: 3,
      });
    }
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('post', 'forms-send-code', {
        id: modalData.id,
        emails: modalData.emails,
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal(initModal);
    showActions(false);
    setModalData(initModalData);
    fetchData(false, newParams);
    fetchSentEmails(modalData.id);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setModalData(initModalData);
  };

  const handleInputChange = (name, value) => {
    setModalData(previous => ({ ...previous, [name]: value }));
  };

  const applyChangesDisabled = () => {
    const emailsArray = modalData.emails.replaceAll(' ', '').split(',');
    return !(modalData.emails.length && emailsArray.every(isEmailValid));
  };

  const portUnikie = isPortUnikie(namespace);

  const fields = ['type', 'description', 'expires'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, {
    type: FORM_TYPES[0],
    expires: dayjs().add(1, 'month'),
  });

  const columns = [
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      sortableKey: 'enabled',
      render: (text, record) => (
        <Tooltip
          title={
            (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
            (record.expires && dayjs(record.expires).isBefore(dayjs()))
              ? t('This register code does not work anymore.')
              : null
          }
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <Switch
            disabled={
              portUnikie ||
              (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
              (record.expires && dayjs(record.expires).isBefore(dayjs()))
            }
            defaultChecked={text}
            onChange={enabled => handleUpdate(record.id, enabled, record.type, record.expires)}
          />
        </Tooltip>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      sortableKey: 'type',
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      sortableKey: 'code',
      render: text => (
        <div>
          <SecretText text={text} />
          <Button copy style={{ zIndex: '5' }} onClick={() => copyToClipboard(text)}>
            <Icon type="copy" />
          </Button>
        </div>
      ),
    },
    {
      title: t('Expires'),
      dataIndex: 'expires',
      key: 'expires',
      sortableKey: 'expires',
      render: date => (date ? <DateComponent format={TIME_FORMAT} date={date} /> : t('Not set')),
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      sortableKey: 'description',
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'modified_at',
      sortableKey: 'modified_at',
      render: record => {
        let modified_by = record?.modified_by || '';
        if (record?.modified_by_name && record?.modified_by_email) {
          modified_by = `${record.modified_by_name} (${record.modified_by_email})`;
        } else if (record?.modified_by_name) {
          modified_by = record.modified_by_name;
        } else if (record?.modified_by_email) {
          modified_by = record.modified_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.modified_at} />
              </Content2>
            )}
            <Content2>{modified_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          onClick={() => {
            setModal({ ...initModal, visible: true });
            setModalData(previous => ({ ...previous, id: record.id }));
          }}
        >
          <Icon type="edit" />
          {t('Send code link email')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete code {{code}}?', { code: record.code })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          key="action-2"
          id="pop-confirm-for-new-list"
        >
          <div>
            <ListActionButton red disabled={portUnikie}>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  if (portUnikie) {
    columns.splice(4, 0, {
      title: t('Namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
      render: namespace => namespace && <span>{namespace}</span>,
    });
  }

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: loading,
      text: t('Add new code'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <Modal
        title={t('Send form links via email')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled() }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="emails"
              label={t('E-mail(s). Comma to separate addresses')}
              value={modalData.emails || ''}
              onChange={({ target }) => handleInputChange(target.name, target.value.replaceAll(' ', ''))}
              style={{ width: '100%' }}
              required
            />
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm title={t('Add new code')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'type') {
                return (
                  <Select
                    value={values[field]}
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    options={FORM_TYPES.map(type => ({
                      key: type,
                      label: type,
                      value: type,
                    }))}
                    onChange={handleChange}
                  />
                );
              } else if (field === 'expires') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Code expiration')}</InputLabel>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      style={{
                        width: '100%',
                      }}
                      value={values[field] ? dayjs(values[field]) : null}
                      onChange={value => {
                        handleChange({
                          target: {
                            value: value ? dayjs(value).toISOString() : null,
                            name: field,
                          },
                        });
                      }}
                      placeholder={t('Expires')}
                    />
                  </InputContainer>
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    onChange={handleChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link>{t('Add code')}</Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>

      <List
        rowKey="id"
        columns={columns}
        dataSource={codes}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by description')}
        additionalButtons={additionalButtons}
        transparentBgs
        onRow={record => ({
          style: {
            backgroundColor:
              (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
              (record.expires && dayjs(record.expires).isBefore(dayjs())) ||
              !record.enabled
                ? '#e8e8e8'
                : 'white',
          },
        })}
        expandedRowRender={() => <FormsCodeSentEmail data={emailsData} loading={emailsLoading} error={emailsError} />}
        onExpand={(expanded, record) => {
          if (expanded) {
            fetchSentEmails(record.id);
          }
        }}
      />
    </>
  );
};

export default FormsCodes;
