import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MapContainer as Map } from 'react-leaflet';
import { UserContext } from '../../context/UserContext';

import SeaPassageMapInnerContainer from './SeaPassageMapInnerContainer';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  .leaflet-control-container {
    right: 50px;
  }
  .leaflet-control-attribution.leaflet-control {
    display: ${props => (props.showAttribution ? 'inline' : 'none')};
  }
  .leaflet-container {
    width: 100%;
    height: 100%;
    align-content: auto;
  }
`;

const SeaPassageMap = ({
  showAttribution,
  buttonMargin,
  mapSize,
  seaPassage,
  showRoute,
  setShowSeaPassage,
  portcall,
}) => {
  const { mapDefaultCoordinates } = useContext(UserContext);
  const defCoordinates = mapDefaultCoordinates.split(',');

  const [appliedZoom, setAppliedZoom] = useState(4);

  return (
    <MapContainer showAttribution={showAttribution}>
      <Map center={defCoordinates} tap={false} zoom={appliedZoom} zoomControl={false}>
        <SeaPassageMapInnerContainer
          zoom={appliedZoom}
          setZoom={setAppliedZoom}
          buttonMargin={buttonMargin}
          mapSize={mapSize}
          seaPassage={seaPassage}
          showRoute={showRoute}
          setShowSeaPassage={setShowSeaPassage}
          portcall={portcall}
        />
      </Map>
    </MapContainer>
  );
};

export default SeaPassageMap;
