import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 400px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
  min-height: 317px;
  padding: 6px;
`;

const Priorities = styled.div`
  display: flex;
  flex-direction: column;
`;

const Priority = styled.div`
  margin: 12px 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  padding: 6px;
  border-radius: 3px;
  border: 1px solid #f6f6f6;
  background-color: ${props => (props.new ? '#f5faff' : 'white')};
`;

const PriorityHeader = styled.div`
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
`;

const ApiKey = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AddPrioButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddTimestampButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const AddButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
  padding-right: 2px;
  border-radius: 3px;
  font-size: 13px;

  i {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  svg {
    margin-top: -1px;
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const DeleteButton = styled.div`
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;
  font-weight: 600;
  padding-left: 2px;
  border-radius: 3px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 2px;

  i {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }

  svg {
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -18px;
  margin-top: 4px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  padding-left: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  font-size: 13px;
  margin-left: 12px;
  margin-top: 2px;

  i {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }

  svg {
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const AddNewTimestampOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.65);
  top: 0;
  left: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`;

const OverlayContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 0px 24px 24px 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  height: calc(100% - 80px);
  max-height: 400px;
  max-width: calc(100% - 40px);
`;

const OverlayHeader = styled.div`
  font-weight: 600;
  margin-bottom: 18px;
`;

const Option = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
  border-radius: 3px;
  white-space: nowrap;
  display: flex;

  i {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    min-width: 16px;
  }

  svg {
    margin-top: -1px;
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Title = styled.div`
  white-space: wrap;
`;

const OverlayScroll = styled.div`
  max-height: calc(100% - 100px);
  margin-bottom: 12px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 6px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const InfoText = styled.div`
  margin: 100px 0 28px 0;
  text-align: center;
`;

const ApiKeyWeightsTimestampsModal = ({ timestamp, closeModal, saveData, apiKeys }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [innerAddModal, setInnerAddModal] = useState(null);

  const createInputData = () => {
    const weights = [];
    timestamp.api_keys.forEach(a => {
      if (weights.findIndex(w => w === a.weight) === -1) {
        weights.push(a.weight);
      }
    });
    weights.sort((a, b) => b - a);

    const apiKeysSorted = [];
    weights.forEach(w => {
      apiKeysSorted.push({
        id: w,
        keys: timestamp.api_keys.filter(k => k.weight === w),
      });
    });
    return apiKeysSorted;
  };

  const [inputData, setInputData] = useState(createInputData());

  const sendData = () => {
    saveData({ inputData, timestamp_state: timestamp.state, timestamp_time_type: timestamp.time_type });
    closeModal();
  };

  const addPrio = (place, value) => {
    const ids = inputData.map(i => Number(i.id));
    const newId = Math.max(...ids) + 1;
    const newInput = [...inputData];
    newInput.splice(place + 1, 0, {
      id: newId,
      keys: [
        {
          api_key_id: value.id,
          api_key_name: value.name,
          new: true,
        },
      ],
      new: true,
    });
    setInputData(newInput);
    setInnerAddModal(null);
  };

  const deletePrio = place => {
    const newInput = [...inputData];
    newInput.splice(place, 1);
    setInputData(newInput);
  };

  const addApiKey = (place, value) => {
    const newInput = [...inputData];
    newInput[place].keys.push({
      api_key_id: value.id,
      api_key_name: value.name,
      new: true,
    });
    setInputData(newInput);
  };

  const deleteApiKey = (index, place) => {
    const newInput = [...inputData];
    newInput[index].keys.splice(place, 1);
    setInputData(newInput);
  };

  const apiKeysInUse = [];
  inputData.forEach(i => {
    i.keys.forEach(k => {
      apiKeysInUse.push(k.api_key_id);
    });
  });

  const options = apiKeys.filter(o => apiKeysInUse.findIndex(k => o.id === k) === -1).filter(o => o.label.length > 0);

  return (
    <Modal title={t(timestamp.title)} open={true} width={400} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <ScrollContainer>
          <Priorities>
            {inputData.length === 0 && <InfoText>{t('No priorities.')}</InfoText>}
            <AddPrioButtonContainer>
              <AddButton onClick={() => setInnerAddModal({ prio: true, index: -1 })}>
                <Icon type="plus" />
                {t('Priority')}
              </AddButton>
            </AddPrioButtonContainer>
            {inputData.map((prio, index) => (
              <div key={prio.id}>
                <Priority new={prio.new}>
                  <PriorityHeader>
                    <div>{t('Priority {{number}}', { number: index + 1 })}</div>
                    <DeleteButton style={{ marginTop: 0 }} onClick={() => deletePrio(index)}>
                      <Icon type="trash" />
                    </DeleteButton>
                  </PriorityHeader>
                  {prio.keys.map((apikey, j) => (
                    <div
                      key={apikey.api_key_id}
                      style={{
                        display: 'flex',
                        backgroundColor: apikey.new ? '#f5faff' : 'white',
                        padding: '2px',
                        borderRadius: '3px',
                      }}
                    >
                      <ApiKey>{t(apikey.api_key_name)}</ApiKey>
                      {prio.keys.length > 1 && (
                        <DeleteButton onClick={() => deleteApiKey(index, j)}>
                          <Icon type="trash" />
                        </DeleteButton>
                      )}
                    </div>
                  ))}
                  <AddTimestampButtonContainer>
                    <AddButton style={{ marginTop: '6px' }} onClick={() => setInnerAddModal({ prio: false, index })}>
                      <Icon type="plus" />
                      {t('API key')}
                    </AddButton>
                  </AddTimestampButtonContainer>
                </Priority>
                <AddPrioButtonContainer>
                  <AddButton onClick={() => setInnerAddModal({ prio: true, index })}>
                    <Icon type="plus" />
                    {t('Priority')}
                  </AddButton>
                </AddPrioButtonContainer>
              </div>
            ))}
          </Priorities>
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={false} onClick={sendData}>
            {t('Save')}
          </ButtonLight>
        </ActionButtons>
        {!!innerAddModal && (
          <AddNewTimestampOverlay onClick={() => setInnerAddModal(null)}>
            <OverlayContainer onClick={e => e.stopPropagation()}>
              <CloseButtonContainer>
                <CloseButton onClick={() => setInnerAddModal(null)}>
                  <Icon type="close" />
                </CloseButton>
              </CloseButtonContainer>
              <OverlayHeader>
                {innerAddModal.prio
                  ? t('Add new Priority with API key')
                  : t('Add new API key(s) to Priority {{number}}', { number: innerAddModal.index + 1 })}
              </OverlayHeader>
              <OverlayScroll>
                {options.map(o => (
                  <Option
                    key={o.id}
                    onClick={() => {
                      if (innerAddModal.prio) {
                        addPrio(innerAddModal.index, o);
                      } else {
                        addApiKey(innerAddModal.index, o);
                      }
                    }}
                  >
                    <Icon type="plus" />
                    <Title>{t(o.name)}</Title>
                  </Option>
                ))}
              </OverlayScroll>
              <ButtonContainer>
                <ButtonLight disabled={false} cancel onClick={() => setInnerAddModal(null)}>
                  {t('Close')}
                </ButtonLight>
              </ButtonContainer>
            </OverlayContainer>
          </AddNewTimestampOverlay>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default ApiKeyWeightsTimestampsModal;
