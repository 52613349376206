import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import App from 'antd/es/app';
import Checkbox from 'antd/es/checkbox';
import Spin from 'antd/es/spin';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);

  .ant-checkbox-disabled + span {
    color: #4a4a4a;
  }
`;

const Mods = styled.div`
  padding: 12px 24px;
`;

const Info = styled.div`
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
`;

const Product = styled.span`
  font-weight: 600;
`;

const RestOfInfo = styled.span`
  padding-right: 3px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

const ProductLinksModal = ({ modification, closeModal, closeAndReload, readOnly }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [reloadNeeded, setReloadNeeded] = useState(false);

  const [selected, setSelected] = useState(modification?.products || []);

  const fetchModData = async () => {
    setLoading(true);
    let result;
    try {
      result = await apiCall('get', 'invoicing/v2/products');
    } catch (e) {
      setLoading(false);
    }

    if (result?.data?.results?.products) {
      setProducts(result.data.results.products);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchModData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = t('Edit product links');

  const updateLink = async (id, state) => {
    setReloadNeeded(true);
    setLoading(true);

    try {
      const result = await apiCall(
        'post',
        state.target.checked ? 'invoicing/v2/modifier/attach' : 'invoicing/v2/modifier/detach',
        {
          id: modification.id,
          product_id: id,
        }
      );
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }

      const result2 = await apiCall('get', 'invoicing/v2/modifier/' + modification.id);
      if (result2?.data.result === 'ERROR' && result2?.data.message.length) {
        message.error(result.data.message, 4);
      }

      if (result2?.data?.modifier) {
        setSelected(result2.data.modifier.products);
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={title}
      open={true}
      width={420}
      onCancel={reloadNeeded ? closeAndReload : closeModal}
      footer={null}
      maskClosable={false}
    >
      <ModalInnerInput>
        <ScrollContainer>
          <Spin spinning={loading}>
            <Info>
              <RestOfInfo>{t('Select products that should be linked to ')}</RestOfInfo>
              <Product>{modification.name}</Product>.
            </Info>
            <Mods>
              {products.map(mod => (
                <CheckBoxContainer key={`mod-${mod.id}`}>
                  <Checkbox
                    checked={selected.find(c => c.id === mod.id)}
                    name={mod.name}
                    onClick={e => updateLink(mod.id, e)}
                    disabled={readOnly}
                  >
                    {mod.name} ({mod.code})
                  </Checkbox>
                </CheckBoxContainer>
              ))}
            </Mods>
          </Spin>
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={reloadNeeded ? closeAndReload : closeModal}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default ProductLinksModal;
