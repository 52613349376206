import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';

import { UserContext } from '../context/UserContext';

import Alert from 'antd/es/alert';

import Heading from '../components/ui/Heading';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import Paragraph from '../components/ui/Paragraph';

import LoginWrapper from '../components/login/LoginWrapper';
import LoginLogo from '../components/login/LoginLogo';
import LoginForm from '../components/login/LoginForm';
import LoginReturnLink from '../components/login/LoginReturnLink';

import EUInterreg from '../images/footers/eu-interreg-new.jpg';
import LogoFintrafficImg from '../images/logo-fintraffic.svg';
import Footer from '../images/footers/Footer-Main.svg?react';
import LogoImage from '../images/logo.svg?react';

import Icon from '../components/ui/Icon';

const PortName = styled(Heading)`
  color: ${({ theme }) => theme.color.secondary};
`;

const InstructionText = styled.div`
  margin: 12px 0;
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 88px;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const UnikieContainer = styled.div`
  display: block;
  position: absolute;
  height: 48px;
  top: 26px;
  left: calc(50% - 50px);

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
    width: 80px;
  }

  img {
    width: 100px;
    margin-top: 16px;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 48px;
  margin: 0 6px;

  img {
    width: 280px;
    object-fit: contain;
  }

  @media (max-width: 899px) {
    justify-content: center;
    padding-bottom: 48px;
    padding-top: 0px;
    display: inline-block;

    img {
      width: 200px;
      margin: auto;
    }
  }
`;

const ResetContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: block;
  justify-content: center;
  padding-top: 3vh;
  background-color: #f3f3f3;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-height: 780px) {
    position: relative;
    margin-top: 24px;
  }
`;

const BottomContainerSuccess = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-height: 630px) {
    position: relative;
    margin-top: 24px;
  }
`;

const FormContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledLoginForm = styled(LoginForm)`
  background-color: white;
  border-radius: 0px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 26px;
`;

const StyledLoginReturnLink = styled(LoginReturnLink)`
  display: inline-block;
`;

const IconContainer = styled.span`
  margin-right: 12px;
  padding-left: 4px;
  margin-top: -1px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.deep_blue)};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 0px;
  text-align: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: 36px;
  text-transform: uppercase;
  line-height: 26px;
  border-color: transparent;
  margin-top: 24px;

  &:hover {
    background-color: ${props => (props.disabled ? '#a8a8a8' : '#729bc0')};
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.almost_black};
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.almost_black};
`;

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color.almost_black};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 24px;
  border-radius: 0px;
`;

const BigHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const HeaderTexts = styled.div`
  margin-left: 18px;
`;

const HeaderRow1 = styled.div`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 4px;
`;

const HeaderRow2 = styled.div`
  color: ${({ theme }) => theme.color.middle_grey};
  letter-spacing: 2px;
`;

const ResetPassword = ({ location }) => {
  if (localStorage.getItem('sessionId')) {
    localStorage.removeItem('sessionId');
    window.location.reload();
  }

  const [tokenChecked, setTokenChecked] = useState(false);
  const [validToken, setValidToken] = useState(false);

  const { token } = queryString.parse(location.search);

  useEffect(() => {
    if (!tokenChecked && token) {
      setTokenChecked(true);
      const { exp: jwtExp } = jwt_decode(token);

      if (new Date().getTime() < (jwtExp - 300) * 1000) {
        setValidToken(true);
      }
    }
  }, [token, tokenChecked]);

  const { resetPassword } = useContext(UserContext);
  const history = useHistory();

  let port = 'common';

  const { t } = useTranslation(port);

  const initState = {
    confirm: '',
    password: '',
    resetRequested: false,
  };

  const [state, setState] = useState(initState);

  const [writingStarted, setWritingStarted] = useState(false);
  const [writingConfirmStarted, setWritingConfirmStarted] = useState(false);

  const { password, confirm, resetRequested } = state;

  const dontMatch = state.password.length > 0 && state.password !== state.confirm;
  const tooShort = state.password.length < 1;

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (password && confirm && token) {
      if (password !== confirm) {
        // TODO: show errors
        console.error(t('Passwords do not match'));
        return;
      }
      // TODO: validation
      const res = await resetPassword(password, token);
      if (res) {
        setState({
          ...state,
          resetRequested: true,
        });
      }
    }
  };

  const isSwedishVersion =
    window.location.host.includes('portactivity.se') || window.location.host.includes('se.ft-testing.dev');

  return resetRequested ? (
    !isSwedishVersion ? (
      <ResetContainer>
        <BigHeader>
          <LogoImage style={{ fill: '#0F67CC', width: '42px' }} />
          <HeaderTexts>
            <HeaderRow1>PORT ACTIVITY</HeaderRow1>
            <HeaderRow2>FINLAND</HeaderRow2>
          </HeaderTexts>
        </BigHeader>
        <FormContainer>
          <StyledLoginForm>
            <Header>
              <HeaderText>{t('Reset password')}</HeaderText>
            </Header>
            <StyledAlert
              type="success"
              message={t('Your password is now reset.')}
              description={t('Please login with your new credentials.')}
            />
          </StyledLoginForm>
          <StyledLoginReturnLink onClick={() => history.replace('/')}>
            <IconContainer>
              <Icon type="arrow-left" />
            </IconContainer>
            {t('Return to login')}
          </StyledLoginReturnLink>
        </FormContainer>

        <BottomContainerSuccess>
          <BottomRow>
            <img alt={t('EU interreg')} src={EUInterreg} />
          </BottomRow>
          <FooterContainer>
            <Footer />
            <UnikieContainer>
              <img alt={t('Fintraffic')} src={LogoFintrafficImg} />
            </UnikieContainer>
          </FooterContainer>
        </BottomContainerSuccess>
      </ResetContainer>
    ) : (
      <LoginWrapper>
        <LoginLogo />
        <LoginForm>
          <LoginReturnLink onClick={() => history.replace('/')}>{t('« Return to login')}</LoginReturnLink>
          <PortName h4>{t('Port Activity')}</PortName>
          <Heading h3>{t('Reset Password')}</Heading>
          <Alert
            type="success"
            message={t('Your password is now reset.')}
            description={t('Please login with your new credentials.')}
          />
        </LoginForm>
      </LoginWrapper>
    )
  ) : !isSwedishVersion ? (
    <ResetContainer>
      <BigHeader>
        <LogoImage style={{ fill: '#0F67CC', width: '42px' }} />
        <HeaderTexts>
          <HeaderRow1>PORT ACTIVITY</HeaderRow1>
          <HeaderRow2>FINLAND</HeaderRow2>
        </HeaderTexts>
      </BigHeader>
      <FormContainer>
        <StyledLoginForm onSubmit={handleSubmit}>
          <Header>
            <HeaderText>{t('Reset password')}</HeaderText>
          </Header>
          {validToken && <StyledParagraph>{t('Please enter your new password')}</StyledParagraph>}
          {validToken && (
            <>
              <Label>{t('Password')}</Label>
              <Input
                name={t('Password')}
                type="password"
                onChange={e => {
                  setState({ ...state, password: e.target.value });

                  if (!writingStarted) {
                    setWritingStarted(true);
                  }
                }}
                autoFocus
              />
            </>
          )}
          {validToken && (
            <>
              <Label>{t('Confirm password')}</Label>
              <Input
                name={t('Confirm')}
                type="password"
                onChange={e => {
                  setState({ ...state, confirm: e.target.value });

                  if (!writingConfirmStarted) {
                    setWritingConfirmStarted(true);
                  }
                }}
              />
            </>
          )}
          {writingConfirmStarted && dontMatch && <StyledAlert type="error" message={t('Passwords do not match')} />}
          {writingStarted && tooShort && <StyledAlert type="error" message={t('Password cannot be empty!')} />}
          {validToken && <StyledButton disabled={tooShort || dontMatch}>{t('Reset Password')}</StyledButton>}
          {!validToken && (
            <InstructionText>{t('Token has expired. You need to request a new password reset.')}</InstructionText>
          )}
        </StyledLoginForm>
        <StyledLoginReturnLink onClick={() => history.replace('/')}>
          <IconContainer>
            <Icon type="arrow-left" />
          </IconContainer>
          {t('Return to login')}
        </StyledLoginReturnLink>
      </FormContainer>

      <BottomContainer>
        <BottomRow>
          <img alt={t('EU interreg')} src={EUInterreg} />
        </BottomRow>
        <FooterContainer>
          <Footer />
          <UnikieContainer>
            <img alt={t('Fintraffic')} src={LogoFintrafficImg} />
          </UnikieContainer>
        </FooterContainer>
      </BottomContainer>
    </ResetContainer>
  ) : (
    <LoginWrapper>
      <LoginLogo />
      <LoginForm onSubmit={handleSubmit}>
        <LoginReturnLink onClick={() => history.replace('/')}>{t('« Return to login')}</LoginReturnLink>
        <PortName h4>{t('Port Activity')}</PortName>
        <Heading h3>{t('Reset Password')}</Heading>
        {writingConfirmStarted && dontMatch && <Alert type="error" message={t('Passwords do not match')} />}
        {writingStarted && tooShort && <Alert type="error" message={t('Password cannot be empty!')} />}
        {validToken && <Paragraph>{t('Please enter your new password')}</Paragraph>}
        {validToken && (
          <Input
            label={t('Password')}
            name={t('Password')}
            type="password"
            onChange={e => {
              setState({ ...state, password: e.target.value });

              if (!writingStarted) {
                setWritingStarted(true);
              }
            }}
            autoFocus
          />
        )}
        {validToken && (
          <Input
            label={t('Confirm password')}
            name={t('Confirm')}
            type="password"
            onChange={e => {
              setState({ ...state, confirm: e.target.value });

              if (!writingConfirmStarted) {
                setWritingConfirmStarted(true);
              }
            }}
          />
        )}
        {validToken && <Button disabled={tooShort || dontMatch}>{t('Reset Password')}</Button>}
        {!validToken && (
          <InstructionText>{t('Token has expired. You need to request a new password reset.')}</InstructionText>
        )}
      </LoginForm>
    </LoginWrapper>
  );
};

export default ResetPassword;
