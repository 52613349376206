import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Popconfirm from 'antd/es/popconfirm';
import Radio from 'antd/es/radio';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';

import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import Icon from '../../components/ui/Icon';
import DateComponent from '../../components/ui/DateComponent';

const ShipName = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const LinkText = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  cursor: pointer;
`;

const RequestsList = styled.div`
  .additional-buttons {
    justify-content: flex-start;
    flex-direction: row;
    flex: 1;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-self: center;
  margin-left: 32px;
`;

const AdminPortCallRequests = () => {
  const { apiCall, namespace, user, setAlert, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Port call requests | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [status, setStatus] = useState('open');

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: 'eta DESC',
    search: params.get('search') ? params.get('search') : '',
    status: status,
  };

  const [apiCallPending, setApiCallPending] = useState(false);
  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data: requestsData, error: requestsError, fetchData: fetchRequests } = useApi(
    'get',
    'port-call-requests',
    newParams
  );

  useEffect(() => {
    fetchRequests(false, newParams);
  }, [newParams, fetchRequests]);

  useEffect(() => {
    setNewParams(previous => {
      return {
        ...previous,
        status,
      };
    });
  }, [status]);

  const getRequests = useCallback(() => {
    fetchRequests(false, newParams);
  }, [fetchRequests, newParams]);

  useUserSocket('port-call-requests-changed', getRequests);

  const requests = loading || !requestsData?.['port-call-requests'] ? [] : requestsData?.['port-call-requests'];
  const { start, total } = requestsError || !requestsData ? { start: 0, total: 0 } : requestsData.pagination;

  if (requestsError) {
    setAlert({ type: 'error', message: requestsError });
  }

  const handleCancelRequest = async ({ id }) => {
    setApiCallPending(true);
    try {
      await apiCall('put', `port-call-request/status/${id}`, {
        status: 'cancelled_by_port',
        notes: null,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);

    fetchRequests(false, newParams);
  };

  const handleDeleteRequest = async ({ id }) => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `port-call-request/${id}`, {});
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);

    fetchRequests(false, newParams);
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Ship'),
      dataIndex: 'vessel_name',
      width: '30%',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
      render: (text, record) => {
        return (
          <>
            <ShipName>{record.vessel_name}</ShipName>
            <LinkText
              onClick={e => {
                e.stopPropagation();
                if (user.permissions.includes('manage_port_call')) {
                  history.push(`/vessels/vessel-timestamps/${record.imo}`);
                }
              }}
            >
              IMO {record.imo}
            </LinkText>
          </>
        );
      },
    },
    {
      title: t('Primary berth'),
      dataIndex: 'primary_berth_name',
      key: 'primary_berth_name',
      sortableKey: 'primary_berth',
    },
    {
      title: t('ETA'),
      dataIndex: 'eta',
      key: 'eta',
      sortableKey: 'eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ETD'),
      dataIndex: 'etd',
      key: 'etd',
      sortableKey: 'etd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('LOA'),
      dataIndex: 'loa',
      key: 'loa',
      sortableKey: 'loa',
    },
    {
      title: t('Beam'),
      dataIndex: 'beam',
      key: 'beam',
      sortableKey: 'beam',
    },
    {
      title: t('Draft'),
      dataIndex: 'draft',
      key: 'draft',
      sortableKey: 'draft',
    },
    {
      title: t('Net tonnage'),
      dataIndex: 'net_tonnage',
      key: 'net_tonnage',
      sortableKey: 'net_tonnage',
    },
    {
      title: t('Notes'),
      dataIndex: 'notes',
      key: 'notes',
      sortableKey: 'notes',
    },
    {
      title: t('Status'),
      dataIndex: 'readable_port_call_request_status',
      key: 'readable_port_call_request_status',
      sortableKey: 'port_call_request_status',
    },
    {
      title: t('Port call ID'),
      dataIndex: 'port_call_id',
      key: 'port_call_id',
      sortableKey: 'port_call_id',
      render: (text, record) => {
        return (
          <>
            <LinkText
              onClick={e => {
                e.stopPropagation();
                if (user.permissions.includes('manage_port_call')) {
                  history.push(`/admin/port-calls/${record.port_call_id}`);
                }
              }}
            >
              {record.port_call_id}
            </LinkText>
          </>
        );
      },
    },
  ];

  const actions = [
    {
      render: record => (
        <Popconfirm
          title={t('Really cancel port call request {{id}}?', { id: record.id })}
          onConfirm={e => {
            e.stopPropagation();
            handleCancelRequest(record);
          }}
          onCancel={e => e.stopPropagation()}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          key="action-1"
          id="pop-confirm-for-new-list"
        >
          <div>
            <ListActionButton
              onClick={e => e.stopPropagation()}
              //red
              disabled={
                !user.permissions.includes('manage_port_call') ||
                record.port_call_request_status === 'completed_by_port' ||
                record.port_call_request_status === 'cancelled_by_port' ||
                record.port_call_request_status === 'cancelled_by_vessel'
              }
            >
              {t('Cancel port call request')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete port call request {{id}}?', { id: record.id })}
          onConfirm={e => {
            e.stopPropagation();
            handleDeleteRequest(record);
          }}
          onCancel={e => e.stopPropagation()}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          key="action-2"
          id="pop-confirm-for-new-list"
        >
          <div>
            <ListActionButton
              onClick={e => e.stopPropagation()}
              red
              disabled={
                !user.permissions.includes('manage_port_call') ||
                record.port_call_request_status === 'completed_by_port'
              }
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      render: index => (
        <StatusContainer key={index}>
          <Radio.Group onChange={e => setStatus(e.target.value)} value={status}>
            <Radio value="open">{t('Open')}</Radio>
            <Radio value="closed">{t('Closed')}</Radio>
            <Radio value="all">{t('All')}</Radio>
          </Radio.Group>
        </StatusContainer>
      ),
    },
  ];

  return (
    <Layout pagename={t('Port Call Requests')}>
      <Page fullWidth>
        <RequestsList>
          <List
            rowKey="id"
            columns={columns}
            dataSource={requests}
            actions={actions}
            apiCallPending={apiCallPending}
            spinning={loading}
            setParams={setNewParams}
            newParams={newParams}
            start={start}
            total={total}
            searchPlaceHolder={t('Search by vessel name, berth exact IMO')}
            additionalButtons={additionalButtons}
            transparentBgs
            noUrlUpdate={true}
            onRow={record => {
              return {
                onClick: () => {
                  history.push(`/port-call-request/${record.id}`);
                },
              };
            }}
          />
        </RequestsList>
      </Page>
    </Layout>
  );
};

export default AdminPortCallRequests;
