import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';
import List from '../../ui/List';
import ServiceOrderTaskListAcknowledgement from './ServiceOrderTaskListAcknowledgement';
import ServiceOrderTaskListDelivery from './ServiceOrderTaskListDelivery';
import ServiceOrderTaskListPhoneCall from './ServiceOrderTaskListPhoneCall';
import { ExternalTypeServiceOrderTaskLine } from './externalTypes/ServiceOrderExternalTypes';
import dayjs from 'dayjs';

const Orders = styled.div`
  height: calc(70vh - 106px);
  width: 100%;
  overflow-x: auto;

  .antd-table-content thead th,
  .antd-table-content tbody td {
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }

  td,
  th {
    padding: 8px !important;
  }

  .ant-table-measure-row {
    display: none;
  }

  .external-rows {
    td {
      border-bottom: none;
    }
  }
`;

const TableContainer = styled.div`
  min-width: 1176px;
`;

const Status = styled.div`
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 130px;
  height: 27px;
  display: inline-block;
  border-radius: 10px;
  margin-top: 6px;
  font-size: 13px;

  ${props => {
    if (props.state === 1) {
      return `
        border: 1px solid #F44336;
        color: #F44336;
        padding-top: 2px;
      `;
    } else if (props.state === 2) {
      return `
        border: 1px solid #F5BD3E;
        color: #F5BD3E;
        padding-top: 2px;
      `;
    } else if (props.state === 3) {
      return `
        background-color: #407505;
        color: white;
        padding-top: 3px;
      `;
    }
  }}
`;

const StatusTime = styled.span`
  font-weight: 600;
  margin-left: 6px;
`;

const Container = styled.div`
  display: inline-block;
  height: ${props => (props.tallRow ? '80px' : '60px')};
`;

const Rows = styled.div`
  display: inline-block;
  height: ${props => (props.tallRow ? '80px' : '60px')};
`;

const Row = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 144px;
`;

const ShortRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 124px;
`;

const Note = styled.div`
  max-width: 176px;
  margin-top: 6px;
  ${props => {
    if (props.tallRow) {
      return `
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 22px;
      `;
    } else {
      return `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 18px;
      `;
    }
  }}
`;

const SentSuccess = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
`;

const StatusContainer = styled.div`
  display: flex;
`;

const RequestedDeliveryTime = styled.div`
  color: #959c9c;
`;

const DeliveryRow = styled.div`
  display: 'inline-block';
  font-size: 12px;
  margin-left: 16px;
`;

const Amount = styled.div`
  white-space: nowrap;
  height: 27px;
  display: flex;
  justify-content: end;
  border-radius: 0 14px 14px 0;
  background-color: #407505;
  margin-left: -9px;
  padding-left: 42px;
  padding-right: 18px;
  margin-top: 6px;
`;

const AmountText = styled.div`
  color: white;
  font-weight: 600;
  margin-top: 3px;
`;

const Header = styled.div`
  background-color: #f8f8f8;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
`;

const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width};
  min-width: ${props => props.width};
`;

const HeaderText = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  letter-spacing: 0.3px;
`;

const HeaderLine = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  margin: 8px 0;
`;

const ServiceOrderTaskList = ({ orders, done = false, listAttributes, getListRef, tallRow }) => {
  const { modules, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [sentAckSuccess, setSentAckSuccess] = useState(undefined);
  const [sentAckRejectSuccess, setSentAckRejectSuccess] = useState(undefined);
  const [sentDelSuccess, setSentDelSuccess] = useState(undefined);
  const [sentDelCancelSuccess, setSentDelCancelSuccess] = useState(undefined);
  const [sentPhoneCallSuccess, setSentPhoneCallSuccess] = useState(undefined);

  let timer = useRef();
  let listRef = useRef();

  const orderList = orders.map(order => {
    return {
      id: order.id,
      vessel_name: order.vessel_name,
      imo: order.imo,
      service_type_name: order.order_lines[0].service_type_name,
      quantity: order.order_lines[0].quantity,
      when: order.order_lines[0].when,
      requested_delivery_time: order.order_lines[0].requested_delivery_time,
      notes: order.order_lines[0].notes,
      toBeAcknowledged: !order.order_lines[0].ack && order.order_lines[0].deliveries.length === 0,
      toBeDelivered:
        order.order_lines[0].ack &&
        (order.order_lines[0].deliveries.length === 0 ||
          (order.order_lines[0].deliveries.length > 0 && order.order_lines[0].deliveries[0].status !== 'done')),
      requestedTimestamp: order.order_lines[0].updated_at,
      acknowledgedTimestamp: order.order_lines[0].ack?.created_at,
      acknowledgedNote: order.order_lines[0].ack?.notes,
      deliveredTimestamp:
        order.order_lines[0].deliveries.length > 0 &&
        (order.order_lines[0].deliveries[0].delivery_time || order.order_lines[0].deliveries[0].updated_at),
      deliveredNote: order.order_lines[0].deliveries.length > 0 && order.order_lines[0].deliveries[0].notes,
      deliveredQuantity: order.order_lines[0].deliveries.length > 0 && order.order_lines[0].deliveries[0].quantity,
      data: order,
      service_phone_call_confirmation: order.order_lines[0].service_phone_call_confirmation,
      service_phone_call_before_minutes: order.order_lines[0].service_phone_call_before_minutes,
      service_phone_call_ignore_ack: order.order_lines[0].service_phone_call_ignore_ack,
      orderlineData: order.order_lines[0].data,
      orderlineAck: order.order_lines[0].ack,
      orderlineDeliveries: order.order_lines[0].deliveries,
      externalType: order.order_lines[0]?.data?.service_external_type || null,
    };
  });

  const emptyAllSuccessMessages = () => {
    setSentAckSuccess(undefined);
    setSentAckRejectSuccess(undefined);
    setSentDelSuccess(undefined);
    setSentDelCancelSuccess(undefined);
    setSentPhoneCallSuccess(undefined);
  };

  const updateSentAckSuccess = type => {
    setSentAckSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateSentAckRejectSuccess = type => {
    setSentAckRejectSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateSentDelSuccess = type => {
    setSentDelSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateSentDelCancelSuccess = type => {
    setSentDelCancelSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateSentPhonecallSuccess = type => {
    setSentPhoneCallSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const phoneCallModuleOn = modules.phone_call_module === 'enabled' && !done;

  const vesselColumnWidth = phoneCallModuleOn ? '130px' : '160px';
  const servicesColumnWidth = phoneCallModuleOn ? '130px' : '160px';
  const whenColumnWidth = phoneCallModuleOn ? '134px' : '160px';
  const requestNoteColumnWidth = phoneCallModuleOn ? '146px' : '170px';
  const acknowledgementColumnWidth = phoneCallModuleOn ? '188px' : '200px';
  const deliveryColumnWidth = phoneCallModuleOn ? '255px' : null;
  const phoneCallColumnWidth = null;

  const columns = [
    {
      title: t('Vessel'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: done ? 'vessel_name' : undefined,
      width: vesselColumnWidth,
      render: (text, record) => (
        <Rows tallRow={tallRow}>
          <Tooltip title={text} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
            <Row style={{ textTransform: 'uppercase', fontWeight: 600 }}>{text}</Row>
          </Tooltip>
          <Row>
            {t('IMO')} {record.imo}
          </Row>
        </Rows>
      ),
    },
    {
      title: t('Services'),
      dataIndex: 'service_type_name',
      key: 'service_type_name',
      sortableKey: done ? 'order_line_service_type_name' : undefined,
      width: servicesColumnWidth,
      render: (text, record) => (
        <Rows tallRow={tallRow}>
          <Tooltip title={text} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
            <Row>{text}</Row>
          </Tooltip>
          <Row style={{ fontWeight: 600 }}>{record.quantity}</Row>
        </Rows>
      ),
    },
    {
      title: t('When'),
      dataIndex: 'when',
      key: 'when',
      width: whenColumnWidth,
      render: (text, record) => (
        <Rows tallRow={tallRow}>
          <Tooltip title={text} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
            <ShortRow style={{ fontWeight: 600 }}>{text || '-'}</ShortRow>
          </Tooltip>
          <Row>
            {record.requested_delivery_time ? (
              <span>
                {dayjs(record.requested_delivery_time).format('DD.MM.YYYY')}
                <span style={{ fontWeight: 600, paddingLeft: '6px' }}>
                  {dayjs(record.requested_delivery_time).format('HH:mm')}
                </span>
              </span>
            ) : (
              '-'
            )}
          </Row>
        </Rows>
      ),
    },
    {
      title: t('Request note'),
      dataIndex: 'notes',
      key: 'notes',
      width: requestNoteColumnWidth,
      render: (text, record) => {
        let updated_by = record.data?.updated_by || '';
        if (record.orderlineData?.updated_by_name && record.orderlineData?.updated_by_email) {
          updated_by = `${record.orderlineData?.updated_by_name} (${record.orderlineData?.updated_by_email})`;
        } else if (record.orderlineData?.updated_by_name) {
          updated_by = record.orderlineData?.updated_by_name;
        } else if (record.orderlineData?.updated_by_email) {
          updated_by = record.orderlineData?.updated_by_email;
        }
        const tooltipText = t('Requested by {{updated_by}}', { updated_by });

        return (
          <Container tallRow={tallRow}>
            <Tooltip title={tooltipText} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Status state={1}>
                {dayjs(record.requestedTimestamp).format('DD.MM.YYYY')}
                <StatusTime>{dayjs(record.requestedTimestamp).format('HH:mm')}</StatusTime>
              </Status>
            </Tooltip>
            <Tooltip title={record.notes} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Note tallRow={tallRow}>{record.notes}</Note>
            </Tooltip>
          </Container>
        );
      },
    },
    {
      title: t('Acknowledgement'),
      dataIndex: 'toBeAcknowledged',
      key: 'toBeAcknowledged',
      width: acknowledgementColumnWidth,
      render: (toBeAcknowledged, record) => {
        let created_by = record.orderlineAck?.created_by || '';
        const emailOrPhone = record.orderlineAck?.data?.created_by_email || record.orderlineAck?.data?.created_by_phone;
        if (record.orderlineAck?.data?.created_by_name && emailOrPhone) {
          created_by = `${record.orderlineAck?.data.created_by_name} (${emailOrPhone})`;
        } else if (record.orderlineAck?.data?.created_by_name) {
          created_by = record.orderlineAck?.data.created_by_name;
        } else if (emailOrPhone) {
          created_by = emailOrPhone;
        }
        const tooltipText = t('{{action}} by {{created_by}}', {
          action: record.orderlineAck?.status === 'rejected' ? 'Rejected' : 'Acknowledged',
          created_by,
        });

        return toBeAcknowledged ? (
          record.orderlineData?.external_data?.disabledActions?.includes('ack') ? null : (
            <ServiceOrderTaskListAcknowledgement
              record={record}
              setSentSuccess={updateSentAckSuccess}
              setSentRejectSuccess={updateSentAckRejectSuccess}
              phoneCallModuleOn={phoneCallModuleOn}
            />
          )
        ) : (
          !!record.acknowledgedTimestamp && (
            <Container tallRow={tallRow}>
              <Tooltip title={tooltipText} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
                <Status state={2}>
                  {dayjs(record.acknowledgedTimestamp).format('DD.MM.YYYY')}
                  <StatusTime>{dayjs(record.acknowledgedTimestamp).format('HH:mm')}</StatusTime>
                </Status>
              </Tooltip>
              <Tooltip
                title={record.acknowledgedNote}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
              >
                <Note tallRow={tallRow}>{record.acknowledgedNote}</Note>
              </Tooltip>
            </Container>
          )
        );
      },
    },
    {
      title: t('Delivery'),
      dataIndex: 'toBeDelivered',
      key: 'toBeDelivered',
      sortableKey: done ? 'order_line_deliveries_updated_at' : undefined,
      width: deliveryColumnWidth,
      render: (toBeDelivered, record) => {
        const delivery = record.orderlineDeliveries?.[0];
        let updated_by = delivery?.updated_by || '';
        if (delivery?.data?.updated_by_name && delivery?.data?.updated_by_email) {
          updated_by = `${delivery?.data.updated_by_name} (${delivery?.data.updated_by_email})`;
        } else if (delivery?.data?.updated_by_name) {
          updated_by = delivery?.data.updated_by_name;
        } else if (delivery?.data?.updated_by_email) {
          updated_by = delivery?.data.updated_by_email;
        }
        const tooltipText = t('{{action}} by {{updated_by}}', {
          action: delivery?.status === 'cancelled' ? 'Cancelled' : 'Delivered',
          updated_by,
        });

        return toBeDelivered ? (
          record.orderlineData?.external_data?.disabledActions?.includes('delivery') ? null : (
            <ServiceOrderTaskListDelivery
              record={record}
              setSentSuccess={updateSentDelSuccess}
              setSentCancelSuccess={updateSentDelCancelSuccess}
            />
          )
        ) : record.deliveredTimestamp ? (
          <Container tallRow={tallRow} style={{ width: '100%' }}>
            <StatusContainer>
              <Tooltip title={tooltipText} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
                <Status state={3}>
                  {dayjs(record.deliveredTimestamp).format('DD.MM.YYYY')}
                  <StatusTime>{dayjs(record.deliveredTimestamp).format('HH:mm')}</StatusTime>
                </Status>
              </Tooltip>
              <Tooltip
                title={t('Delivery quantity')}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
              >
                <Amount style={{ lineHeight: '22px' }}>
                  <AmountText>{record.deliveredQuantity}</AmountText>
                </Amount>
              </Tooltip>
            </StatusContainer>
            <div style={{ display: 'flex' }}>
              <DeliveryRow>
                <Tooltip
                  title={t('Requested delivery time')}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                >
                  <RequestedDeliveryTime>
                    {record.requested_delivery_time ? (
                      <span>
                        {dayjs(record.requested_delivery_time).format('DD.MM.YYYY')}
                        <span style={{ fontWeight: 600, paddingLeft: '6px' }}>
                          {dayjs(record.requested_delivery_time).format('HH:mm')}
                        </span>
                      </span>
                    ) : null}
                  </RequestedDeliveryTime>
                </Tooltip>
              </DeliveryRow>
              <Tooltip
                title={record.deliveredNote}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
              >
                <Note tallRow={tallRow} style={{ marginLeft: '30px', maxWidth: '120px' }}>
                  {record.deliveredNote}
                </Note>
              </Tooltip>
            </div>
          </Container>
        ) : null;
      },
    },
    ...(phoneCallModuleOn
      ? [
          {
            title: t('Phone call'),
            key: 'phone_call_confirmation',
            width: phoneCallColumnWidth,
            render: record =>
              record.orderlineData?.external_data?.disabledActions?.includes('phone') ? null : (
                <ServiceOrderTaskListPhoneCall record={record} setSentSuccess={updateSentPhonecallSuccess} />
              ),
          },
        ]
      : []),
  ];

  const refHandler = el => {
    listRef.current = el;
    if (getListRef) {
      getListRef(el);
    }
  };

  return (
    <Orders id="service-order-list" ref={refHandler}>
      <TableContainer>
        {sentAckSuccess && (
          <SentSuccess>{t('{{service}} acknowledged successfully.', { service: sentAckSuccess })}</SentSuccess>
        )}
        {sentAckRejectSuccess && (
          <SentSuccess>{t('{{service}} rejected successfully.', { service: sentAckRejectSuccess })}</SentSuccess>
        )}
        {sentDelSuccess && (
          <SentSuccess>{t('{{service}} delivered successfully.', { service: sentDelSuccess })}</SentSuccess>
        )}
        {sentDelCancelSuccess && (
          <SentSuccess>{t('{{service}} cancelled successfully.', { service: sentDelCancelSuccess })}</SentSuccess>
        )}
        {sentPhoneCallSuccess && (
          <SentSuccess>
            {t('Phone call notification for {{service}} updated successfully.', { service: sentPhoneCallSuccess })}
          </SentSuccess>
        )}

        <Header>
          <HeaderColumn width={vesselColumnWidth}>
            <HeaderText>{t('Vessel')}</HeaderText>
            <HeaderLine />
          </HeaderColumn>
          <HeaderColumn width={servicesColumnWidth}>
            <HeaderText>{t('Services')}</HeaderText>
            <HeaderLine />
          </HeaderColumn>
          <HeaderColumn width={whenColumnWidth}>
            <HeaderText>{t('When')}</HeaderText>
            <HeaderLine />
          </HeaderColumn>
          <HeaderColumn width={requestNoteColumnWidth}>
            <HeaderText>{t('Request note')}</HeaderText>
            <HeaderLine />
          </HeaderColumn>
          <HeaderColumn width={acknowledgementColumnWidth}>
            <HeaderText>{t('Acknowledgement')}</HeaderText>
            <HeaderLine />
          </HeaderColumn>
          <HeaderColumn width={deliveryColumnWidth}>
            <HeaderText>{t('Delivery')}</HeaderText>
            {phoneCallModuleOn && <HeaderLine />}
          </HeaderColumn>
          {phoneCallModuleOn && (
            <HeaderColumn width={phoneCallColumnWidth}>
              <HeaderText>{t('Phone call')}</HeaderText>
            </HeaderColumn>
          )}
        </Header>
        {done ? (
          <List
            rowKey="id"
            columns={columns}
            dataSource={orderList}
            {...listAttributes}
            noUrlUpdate={true}
            rowExpandable={record => !!record?.externalType}
            expandedRowRender={record => (
              <ExternalTypeServiceOrderTaskLine
                key={`external-type-line-${record.id}`}
                vessel={{
                  vessel: {
                    vessel_name: record.vessel_name,
                  },
                }}
                line={record.data?.order_lines?.[0]}
                reloadData={() => null}
                externalType={record.externalType}
              />
            )}
            defaultExpandAllRows={true}
            expandIconColumnIndex={-1}
            scrollAmount={'calc(100vh - 442px)'}
            showHeader={false}
            transparentBgs={true}
            rowClassName={record => (record.externalType ? 'external-rows' : undefined)}
          />
        ) : (
          <List
            rowKey="id"
            columns={columns}
            dataSource={orderList}
            rowExpandable={record => !!record?.externalType}
            expandedRowRender={record => (
              <ExternalTypeServiceOrderTaskLine
                key={`external-type-line-${record.id}`}
                vessel={{
                  vessel: {
                    vessel_name: record.vessel_name,
                  },
                }}
                line={record.data?.order_lines?.[0]}
                reloadData={() => null}
                externalType={record.externalType}
              />
            )}
            defaultExpandAllRows={true}
            expandIconColumnIndex={-1}
            scrollAmount={'calc(100vh - 442px)'}
            showHeader={false}
            transparentBgs={true}
            rowClassName={record => (record.externalType ? 'external-rows' : undefined)}
          />
        )}
      </TableContainer>
    </Orders>
  );
};

export default ServiceOrderTaskList;
