import React, { useContext, useState } from 'react';
import Popover from 'antd/es/popover';
import styled from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import FleetFilter from './FleetFilter';

import { UserContext } from '../../context/UserContext';
import FleetFilterTagList from './FleetFilterTagList';
import { FleetFilteringContext } from '../../context/FleetFilteringContext';

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 12px;
  cursor: pointer;
  gap: 10px;
  overflow: hidden;
`;

const FiltersBtnContainer = styled.div`
  align-items: center;
  display: flex;
  color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_dark)};
  padding: 3px 6px 4px 6px;
  border-radius: 3px;
  background-color: ${props => (props.dropDownOpen ? '#f3f3f3' : 'transparent')};
  font-weight: 600;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  font-size: ${({ theme }) => theme.text.medium};
`;

const FleetFilterContainer = ({ containerId }) => {
  const { isFiltering } = useContext(FleetFilteringContext);
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(visible => !visible);
  };

  return (
    <FilterContainer id={containerId}>
      <Popover
        open={visible}
        onOpenChange={handleVisibleChange}
        trigger="click"
        content={<FleetFilter setVisible={setVisible} />}
        placement="bottomLeft"
        overlayInnerStyle={{ padding: 0 }}
      >
        <FiltersBtnContainer active={isFiltering} dropDownOpen={visible}>
          <IconContainer>
            <FilterOutlined />
          </IconContainer>
          {t('Filters')}
        </FiltersBtnContainer>
      </Popover>
      <FleetFilterTagList />
    </FilterContainer>
  );
};

export default FleetFilterContainer;
