import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { WikiContext } from '../../context/WikiContext';
import { StatusType } from '../info/WikiConstants';
import Icon from '../ui/Icon';
import MarkDownEditor from '../info/MarkDownEditor';

const Container = styled.div`
  width: 800px;
  min-width: 400px;
  min-height: 500px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  height: 100%;
  padding: 24px;
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
    min-width: 300px;
    font-size: 13px;
  }
`;

const EditButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-radius: 3px;
  padding: 2px 4px;
  right: 12px;
  top: 12px;

  &:hover {
    color: ${({ theme }) => darken(0.15, theme.color.secondary)};
  }

  i {
    margin-right: 3px;
  }

  svg {
    margin-top: -4px;
    margin-right: -3px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 6px;
`;

const Info = styled.div`
  text-align: left;
  margin-bottom: 24px;

  @media (max-width: 500px) {
    margin-right: 36px;
  }
`;

const StatusEditList = () => {
  const [startLoader, setStartLoader] = useState(true);
  const [shouldRefetchData, setShouldRefetchData] = useState(false);
  const [editModeOn, setEditModeOn] = useState(false);
  const [title, setTitle] = useState(null);

  const { availableWikiLinks } = useContext(WikiContext);

  const objectId = 'main-status';
  const wikiLink = availableWikiLinks.find(w => w.object_type === StatusType && w.object_id === objectId);

  return (
    <Container>
      <Info>
        Title for this needs to be <span style={{ fontWeight: 600, color: '#4990dd' }}>Release notes</span> for
        everything to work.
      </Info>
      {!editModeOn && !!wikiLink && (
        <EditButton onClick={() => setEditModeOn(true)}>
          <Icon type="edit" />
        </EditButton>
      )}
      {!editModeOn && !!wikiLink && <Title>{title}</Title>}
      <MarkDownEditor
        markdownId={wikiLink ? wikiLink.markdown_id : null}
        newWiki={wikiLink ? !wikiLink.markdown_id : true}
        objectType={StatusType}
        objectId={objectId}
        startLoader={startLoader}
        setStartLoader={setStartLoader}
        shouldRefetchData={shouldRefetchData}
        setShouldRefetchData={setShouldRefetchData}
        noAnchors={true}
        setEditModeOn={setEditModeOn}
        setModalTitle={setTitle}
        editModeOn={editModeOn}
      />
    </Container>
  );
};
export default StatusEditList;
