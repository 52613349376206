import dayjs from 'dayjs';

export function calculateXPositionForTime(canvasTimeStart, canvasTimeEnd, canvasWidth, time) {
  const widthToZoomRatio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
  const timeOffset = time - canvasTimeStart;

  return timeOffset * widthToZoomRatio;
}

export function calculateTimeForXPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset) {
  const timeToPxRatio = (canvasTimeEnd - canvasTimeStart) / canvasWidth;

  const timeFromCanvasTimeStart = timeToPxRatio * leftOffset;

  return timeFromCanvasTimeStart + canvasTimeStart;
}

export function iterateTimes(start, end, range, callback) {
  const visibleRange = range / 3;
  let time = null;

  if (visibleRange < 9) {
    time = dayjs(start).startOf('day');
  } else {
    time = dayjs(start).startOf('isoWeek');
  }

  while (time.valueOf() < end) {
    let nextTime = dayjs(time);

    if (visibleRange < 9) {
      nextTime = nextTime.add(1, 'days');
    } else {
      nextTime = nextTime.add(7, 'days');
    }
    callback(time, nextTime);
    time = nextTime;
  }
}

export function getCanvasWidth(width, buffer = 3) {
  return width * buffer;
}

export function getCanvasBoundariesFromVisibleTime(visibleTimeStart, visibleTimeEnd, bufferMultiplier = 1) {
  const zoom = (visibleTimeEnd - visibleTimeStart) * bufferMultiplier;
  const canvasTimeStart = visibleTimeStart - zoom;
  const canvasTimeEnd = canvasTimeStart + (visibleTimeEnd - visibleTimeStart) + zoom * 2;

  return [canvasTimeStart, canvasTimeEnd];
}

export function calculateScrollCanvas(visibleTimeStart, visibleTimeEnd, canvasTimeStart, bufferMultiplier = 1) {
  const newState = { visibleTimeStart, visibleTimeEnd };
  const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
  const canKeepCanvas =
    visibleTimeStart >= canvasTimeStart + viewWidthInTime * 0.5 &&
    visibleTimeStart <= canvasTimeStart + viewWidthInTime * 1.5 &&
    visibleTimeEnd >= canvasTimeStart + viewWidthInTime * 1.5 &&
    visibleTimeEnd <= canvasTimeStart + viewWidthInTime * 2.5;
  if (!canKeepCanvas) {
    const [canvasTimeStart, canvasTimeEnd] = getCanvasBoundariesFromVisibleTime(visibleTimeStart, visibleTimeEnd);
    newState.canvasTimeStart = canvasTimeStart;
    newState.canvasTimeEnd = canvasTimeEnd;
  }
  const [bufferTimeStart, bufferTimeEnd] = getCanvasBoundariesFromVisibleTime(
    visibleTimeStart,
    visibleTimeEnd,
    bufferMultiplier
  );
  newState.bufferTimeStart = bufferTimeStart;
  newState.bufferTimeEnd = bufferTimeEnd;

  return newState;
}

export const isMobile = () => {
  // Not 100% way to figure out if mobile but best what internet offered
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return true;
  }

  return false;
};
