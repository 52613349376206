import React, { useContext, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { UserContext } from '../../context/UserContext';
import { SideHelpContext } from '../../context/SideHelpContext';

import SideHelp from './SideHelp';

const ModalInner = styled.div`
  min-height: 50vh;
  max-height: 80vh;
  padding: 24px;
  overflow-y: auto;
`;

const SideHelpMobile = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sideHelpOpen, setSideHelpOpen } = useContext(SideHelpContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const resize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  if (!sideHelpOpen) {
    return null;
  }

  return (
    <Modal
      title={t('Help')}
      open={windowWidth < 1001}
      width={600}
      footer={null}
      onCancel={() => setSideHelpOpen(false)}
    >
      <ModalInner>
        <SideHelp />
      </ModalInner>
    </Modal>
  );
};

export default SideHelpMobile;
