import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';
import PriceModificationModal from './PriceModificationModal';
import useApi from '../../hooks/useApi';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import dayjs from 'dayjs';
import ProductLinksModal from './ProductLinksModal';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const IconContainer = styled.span`
  color: #4990dd;

  i {
    width: 18px;
    height: 18px;
  }

  svg {
    margin-top: -2px;
  }
`;

const Table = styled.table`
  td {
    padding: 4px 12px 4px 1px;
  }
`;

const EditIconContainer = styled.div`
  color: ${props => props.theme.color.secondary};
  cursor: pointer;
  margin-left: 6px;
  padding: 0 ${props => (props.sizeUp ? '6px' : '4px')} 0 ${props => (props.sizeUp ? '1px' : '4px')};

  &:hover {
    background-color: #dddddd;
    border-radius: 3px;
  }

  i {
    margin-top: -2px;
  }

  svg {
    margin-top: -3px;
    height: ${props => (props.sizeUp ? '20px' : '16px')};
    width: ${props => (props.sizeUp ? '20px' : '16px')};
  }
`;

const PriceModificationsList = () => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [apiCallPending, setApiCallPending] = useState(false);

  const [modificationModalOpen, setModificationModalOpen] = useState(false);
  const [modificationModalReadOnly, setModificationModalReadOnly] = useState(false);
  const [modalModification, setModalModification] = useState(undefined);
  const [productLinksModalMod, setProductLinksModalMod] = useState(null);
  const [productLinksModalReadOnly, setProductLinksModalReadOnly] = useState(false);

  const [duplicateCurrent, setDuplicateCurrent] = useState(false);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'name',
    search: '',
  };

  const getParamsInRightForm = useCallback(parameters => {
    let correctParams = {
      query: { text: parameters.search },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'name',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi(
    'post',
    'invoicing/v2/modifier/search',
    getParamsInRightForm(newParams),
    null,
    false
  );

  let modifications = error || !data ? [] : data.results ? data.results.modifiers : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [fetchData, getParamsInRightForm, newParams]);

  const createTimingTooltip = (type, values) => {
    return (
      <Table>
        <thead>
          <tr>
            {type === 'schedule' && <th>{t('Day')}</th>}
            <th>{t('From')}</th>
            <th>{t('To')}</th>
          </tr>
        </thead>
        <tbody>
          {values.map((v, i) => (
            <tr key={i}>
              {type === 'schedule' && (
                <td>
                  {t(v.day.replace('_', ' '))
                    .charAt(0)
                    .toUpperCase() +
                    t(v.day.replace('_', ' '))
                      .toLowerCase()
                      .slice(1)}
                </td>
              )}
              <td>
                {type === 'range'
                  ? dayjs(v.from).format(TIME_FORMAT)
                  : type === 'range_without_year'
                    ? dayjs(dayjs().year() + '-' + v.from).format('DD.MM HH:mm')
                    : dayjs(v.from, 'HH:mm:ss').format('HH:mm')}
              </td>
              <td>
                {type === 'range'
                  ? dayjs(v.to)
                    .add(1, 'second')
                    .format(TIME_FORMAT)
                  : type === 'range_without_year'
                    ? dayjs(dayjs().year() + '-' + v.to)
                      .add(1, 'second')
                      .format('DD.MM HH:mm')
                    : dayjs(v.to, 'HH:mm:ss')
                      .add(1, 'second')
                      .format('HH:mm')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const createProductLinksTooltip = list => {
    return (
      <div style={{ padding: '6px' }}>
        {list.map((l, i) => (
          <div key={i}>{l.name}</div>
        ))}
      </div>
    );
  };

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      sortableKey: 'code',
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Adjustment'),
      dataIndex: 'adjustment',
      key: 'adjustment',
      render: value => <div>{value.value} %</div>,
    },
    {
      title: t('Validity'),
      dataIndex: 'timing',
      key: 'timing',
      render: value => {
        if (value.type === 'always') {
          return t('Always');
        } else if (value.type === 'schedule') {
          return (
            <Tooltip
              placement="top"
              title={createTimingTooltip(value.type, value.values)}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              {t('Weekly schedule')}{' '}
              <IconContainer>
                <Icon type="info" />
              </IconContainer>
            </Tooltip>
          );
        } else if (value.type === 'range') {
          return (
            <Tooltip
              placement="top"
              title={createTimingTooltip(value.type, value.values)}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              {t('Ranges')}{' '}
              <IconContainer>
                <Icon type="info" />
              </IconContainer>
            </Tooltip>
          );
        } else if (value.type === 'range_without_year') {
          return (
            <Tooltip
              placement="top"
              title={createTimingTooltip(value.type, value.values)}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              {t('Ranges without year')}{' '}
              <IconContainer>
                <Icon type="info" />
              </IconContainer>
            </Tooltip>
          );
        }
      },
    },
    {
      title: t('Product links'),
      dataIndex: 'products',
      key: 'products',
      render: (value, item) => (
        <div style={{ display: 'flex' }}>
          <Tooltip
            placement="top"
            title={value?.length ? createProductLinksTooltip(value) : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
          >
            {value?.length || 0}
            {!!value?.length && (
              <IconContainer style={{ marginLeft: '6px' }}>
                <Icon type="info" />
              </IconContainer>
            )}
          </Tooltip>
          {user.permissions.includes('manage_invoicing') ? (
            <EditIconContainer onClick={() => setProductLinksModalMod(item)} sizeUp={value?.length === 0}>
              <Icon type={value?.length ? 'edit' : 'plus'} />
            </EditIconContainer>
          ) : (
            <EditIconContainer
              onClick={() => {
                setProductLinksModalReadOnly(true);
                setProductLinksModalMod(item);
              }}
            >
              <Icon type="view" />
            </EditIconContainer>
          )}
        </div>
      ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/modifier/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, getParamsInRightForm(newParams));
  };

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalModification(record);
                setModificationModalOpen(true);
              }}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setDuplicateCurrent(true);
                setModalModification(record);
                setModificationModalOpen(true);
              }}
            >
              <Icon type="duplicate" />
              {t('Duplicate')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <Popconfirm
              title={t('Are you sure you want to remove the modification?')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-new-list"
              key="action-5"
            >
              <ListActionButton red title={t('Delete')}>
                <Icon type="trash" />
                {t('Delete')}
              </ListActionButton>
            </Popconfirm>
          ),
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModificationModalReadOnly(true);
                setModalModification(record);
                setModificationModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          onClick: () => {
            setModalModification(undefined);
            setModificationModalOpen(true);
          },
          text: t('Create new modification'),
          icon: 'plus',
        },
      ]
    : [];

  const closeModificationModal = () => {
    setModalModification(undefined);
    setModificationModalOpen(false);
    setModificationModalReadOnly(false);
    setDuplicateCurrent(false);
  };

  const closeModificationModalAndReload = () => {
    closeModificationModal();
    fetchData(false, getParamsInRightForm(newParams));
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={modifications}
        apiCallPending={apiCallPending}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        actions={actionList}
        noUrlUpdate={true}
      />

      {modificationModalOpen && (
        <PriceModificationModal
          modification={modalModification}
          closeModal={closeModificationModal}
          closeAndReload={closeModificationModalAndReload}
          readOnly={modificationModalReadOnly}
          duplicateCurrent={duplicateCurrent}
        />
      )}

      {!!productLinksModalMod && (
        <ProductLinksModal
          closeModal={() => {
            setProductLinksModalReadOnly(false);
            setProductLinksModalMod(null);
          }}
          modification={productLinksModalMod}
          closeAndReload={() => {
            setProductLinksModalReadOnly(false);
            setProductLinksModalMod(null);
            fetchData(false, getParamsInRightForm(newParams));
          }}
          readOnly={productLinksModalReadOnly}
        />
      )}
    </Container>
  );
};

export default PriceModificationsList;
