import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import Incidents from '../components/incidents/Incidents';

const IncidentsPage = styled(Page)`
  background-color: #ffffff;
`;

const IncidentsView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Incidents and events | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Incidents adn events')}>
      <IncidentsPage fullWidth>
        <Incidents />
      </IncidentsPage>
    </Layout>
  );
};

export default IncidentsView;
