import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import Tag from 'antd/es/tag';

import { HistoryOutlined } from '@ant-design/icons';

import { UserContext } from '../../../../context/UserContext';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../../../utils/constants';
import Icon from '../../../ui/Icon';

const Header = styled.div`
  font-weight: 600;
  margin-right: 44px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 0px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  padding-left: 8px;
  display: inline-block;
  margin-bottom: 12px;
`;

const PendingChanges = styled.div`
  padding-left: 8px;
  display: inline-block;
  font-style: italic;
  background-color: #d8d8d8;
  border-radius: 4px;
`;

const RejectedChange = styled.div`
  padding-left: 8px;
  display: inline-block;
  font-style: italic;
  background-color: #d0011c33;
  border-radius: 4px;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  border-radius: 2px;
  flex-wrap: wrap;
`;

const InfoRowSplit = styled.div`
  width: auto;
  padding-right: 25px;
  margin-bottom: 8px;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 12px;
`;

const StateBadge = styled(Tag)`
  border: none;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.025em;
  padding: 0 ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.secondary};
`;

const CommentsContainer = styled.div`
  width: 30px;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  margin-left: auto;

  svg {
    /*height: 28px;*/
    width: 24px;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    fill: #4990dd !important;
  }
`;

const ChangesContainer = styled.div`
  width: 30px;
  margin-right: 12px;
  position: relative;
  cursor: pointer;

  svg {
    width: 24px;
    height: 19px;
    vertical-align: sub;
  }
`;

const CountIndicator = styled.div`
  background-color: ${props => props.theme.color.warning};
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -4px;
  font-size: 12px;
  font-weight: 700;
`;

const FinnpilotStatusLine = ({ data, openChanges, openComments }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const routeStart =
    data?.route?.start?.name && data?.route?.start?.code
      ? `${data?.route.start.name} (${data.route.start.code})`
      : data?.route?.start?.name
        ? data.route.start.name
        : data?.route?.start?.code
          ? data.route.start.code
          : '';

  const routeEnd =
    data?.route?.end?.name && data?.route?.end?.code
      ? `${data.route.end.name} (${data.route.end.code})`
      : data?.route?.end?.name
        ? data.route.end.name
        : data?.route?.end?.code
          ? data.route.end.code
          : '';

  const berthStart =
    data?.route?.startBerth?.name && data?.route?.startBerth?.code
      ? `${data?.route.startBerth.name} (${data.route.startBerth.code})`
      : data?.route?.startBerth?.name
        ? data.route.startBerth.name
        : data?.route?.startBerth?.code
          ? data.route.startBerth.code
          : '';

  const berthEnd =
    data?.route?.endBerth?.name && data?.route?.endBerth?.code
      ? `${data.route.endBerth.name} (${data.route.endBerth.code})`
      : data?.route?.endBerth?.name
        ? data.route.endBerth.name
        : data?.route?.endBerth?.code
          ? data.route.endBerth.code
          : '';

  const tugOrderStatus = data?.tugOrderStatus?.toUpperCase() || null;
  const tugsRequested = data?.tugsRequestedLabel || null;

  const finnpilotComments = data?.comments?.filter(comment => comment.authorType === 'FINNPILOT') || [];
  const lastChange = Object.values(data?.changeHistory || {})?.at?.(-1);

  return (
    <InfoContainer>
      <RowContainer>
        <Container style={{ margin: '4px 0 12px 0' }}>
          <Header>{t('Pilotage information')}</Header>
          <StateBadge>{data?.state}</StateBadge>
          <Tooltip
            title={
              finnpilotComments.length
                ? t('Comments ({{count}} from Finnpilot)', { count: finnpilotComments.length })
                : t('Comments')
            }
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          >
            <CommentsContainer
              onClick={openComments}
              className={!data?.comments ? 'is-disabled' : ''}
              style={!data?.comments ? { pointerEvents: 'none', opacity: '0.5' } : undefined}
            >
              <StyledIcon type="notification" />
              {finnpilotComments.length > 0 && <CountIndicator>{finnpilotComments.length}</CountIndicator>}
            </CommentsContainer>
          </Tooltip>
          {data?.changeHistory?.length ? (
            <Tooltip
              title={t('Change history')}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <ChangesContainer onClick={openChanges}>
                <HistoryOutlined style={{ color: '#4990dd' }} />
              </ChangesContainer>
            </Tooltip>
          ) : null}
        </Container>
        {Object.entries(data?.pendingChanges || {}).length ? (
          <PendingChanges>
            <TopContainer>
              <Header>{t('Pending changes')}</Header>
              {Object.entries(data.pendingChanges).map(([key, value], index) => {
                if (value) {
                  if (key === 'tugsRequested') {
                    return;
                  }
                  let newKey = key;
                  if (key === 'tugsRequestedLabel') {
                    newKey = 'tugsRequested';
                  }
                  return (
                    <InfoRowSplit key={`pending-${index}`}>
                      {`${t(newKey)}: `}
                      <BoldText>
                        {dayjs(value, dayjs.ISO_8601, true).isValid() ? dayjs(value).format(TIME_FORMAT) : value}
                      </BoldText>
                    </InfoRowSplit>
                  );
                }
                return null;
              })}
            </TopContainer>
          </PendingChanges>
        ) : null}
        {lastChange?.details?.type === 'ChangesRejected' ? (
          <RejectedChange>
            <TopContainer>
              <Header>{t('Rejected change')}</Header>
              {Object.entries(lastChange).map(([key, value], index) => {
                if (key === 'details') {
                  return null;
                }
                if (value) {
                  return (
                    <InfoRowSplit key={`rejected-${index}`}>
                      {`${t(key)}: `}
                      <BoldText>
                        {dayjs(value, dayjs.ISO_8601, true).isValid() ? dayjs(value).format(TIME_FORMAT) : value}
                      </BoldText>
                    </InfoRowSplit>
                  );
                }
                return null;
              })}
              {Object.entries(lastChange.details).map(([key, value], index) => {
                if (key === 'type' && value === 'ChangesRejected') {
                  return null;
                }
                if (value) {
                  return (
                    <InfoRowSplit key={`rejected-details-${index}`}>
                      {`${t(key)}: `}
                      <BoldText>
                        {dayjs(value, dayjs.ISO_8601, true).isValid() ? dayjs(value).format(TIME_FORMAT) : value}
                      </BoldText>
                    </InfoRowSplit>
                  );
                }
                return null;
              })}
            </TopContainer>
          </RejectedChange>
        ) : null}
        <FirstColumnSplit>
          <TopContainer>
            <InfoRowSplit>
              {t('Start time:')}
              <BoldText>{dayjs(data?.startTime).format(TIME_FORMAT)}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('Route start:')}
              <BoldText>{routeStart}</BoldText>
              {berthStart ? <BoldText>{`/ ${berthStart}`}</BoldText> : null}
            </InfoRowSplit>
            <InfoRowSplit>
              {t('Route end:')}
              <BoldText>{routeEnd}</BoldText>
              {berthEnd ? <BoldText>{`/ ${berthEnd}`}</BoldText> : null}
            </InfoRowSplit>
            {tugsRequested ? (
              <InfoRowSplit>
                {t('Tugs requested:')}
                <BoldText>{tugsRequested}</BoldText>
              </InfoRowSplit>
            ) : null}
            {tugOrderStatus ? (
              <InfoRowSplit>
                {t('Tug order status:')}
                <BoldText>{tugOrderStatus}</BoldText>
              </InfoRowSplit>
            ) : null}
          </TopContainer>
        </FirstColumnSplit>
      </RowContainer>
    </InfoContainer>
  );
};

export default FinnpilotStatusLine;
