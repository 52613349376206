import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';

import ButtonLight from '../ui/ButtonLight';
import Icon from '../../components/ui/Icon';

import { UserContext } from '../../context/UserContext';
import { FleetViewContext } from '../../context/FleetViewContext';
import FleetFilterContainer from '../filter/FleetFilterContainer';
import FleetFilterSearch from '../filter/FleetFilterSearch';

const { RangePicker } = DatePicker;

import { sidebarWidth } from '../commonCalendar/utility/constants';
import DelayedVessels from './DelayedVessels';
import PilotageColorIndicator from './PilotageColorIndicator';

const TopPanel = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const FleetFilterAndSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  font-size: 22px;
  color: black;
  width: calc(${sidebarWidth}px - 12px);
  padding-left: 12px;
`;

const CalendarButtons = styled.div`
  display: flex;
  margin-right: 12px;
  margin-left: 12px;
  position: relative;

  button {
    margin-bottom: 4px;
  }
`;

const CalendarTypeButtons = styled.div`
  margin-right: 12px;
  margin-left: 12px;
`;

const ButtonVersion = styled(ButtonLight)`
  font-size: 14px;
  min-width: 60px;
  height: 24px;
  line-height: 8px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  margin-left: 6px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 0 4px;
  color: #626262;
`;

const CustomContainer = styled.div`
  display: flex;
  line-height: 21px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
  width: 240px;
`;

const Buttons = styled.div`
  display: inline-block;
`;

const SpinContainer = styled.div`
  width: 26px;
  min-height: 20px;
`;

const PilotageColors = styled.div`
  display: inline-block;
  font-size: 13px;
  text-align: center;
`;

const PilotageTitle = styled.div`
  margin-bottom: 2px;
`;

const Indicators = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const PilotageContainer = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Stripes = styled.div`
  margin-top: 24px;
`;

const StripeText = styled.div`
  text-align: center;
  max-width: 160px;
  margin-bottom: 4px;
`;

const StripeContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StripedPart = styled.div`
  width: 30px;
  height: 10px;
  margin: 3px 12px;
  border: 1px solid ${props => props.color};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: ${props => props.color}
    repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1.5px,
      ${props => props.color2} 1.5px,
      ${props => props.color2} 3px
    );
`;

const FleetViewTopPanel = () => {
  const { namespace, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  const [currentDates, setCurrentDates] = useState([null, null]);

  const {
    setTimelineType,
    setToday,
    timelineUnit,
    moveForwards,
    moveBackwards,
    visibleTimeStart,
    visibleTimeEnd,
    changeCustomDate,
    changeDate,
    smallLoader,
  } = useContext(FleetViewContext);

  const setCalendarTypeDay = () => {
    setTimelineType('day');
  };

  const setCalendarTypeTwoDays = () => {
    setTimelineType('twodays');
  };

  const setCalendarTypeWeek = () => {
    setTimelineType('week');
  };

  const setCalendarTypeMonth = () => {
    setTimelineType('month');
  };

  const setCalendarTypeCustom = () => {
    setTimelineType('custom');
  };

  const { Option } = Select;
  const handleChange = value => {
    switch (value) {
      case 'day':
        setCalendarTypeDay();
        break;
      case 'twodays':
        setCalendarTypeTwoDays();
        break;
      case 'week':
        setCalendarTypeWeek();
        break;
      case 'month':
        setCalendarTypeMonth();
        break;
      case 'custom':
        setCalendarTypeCustom();
        break;

      default:
        break;
    }
  };

  const updateCustomDates = (dates, info) => {
    setCurrentDates(dates);
    if (timelineUnit === 'custom') {
      if (dates?.[0] && dates?.[1]) {
        changeCustomDate(dates);
        setRangePickerOpen(false);
      }
    } else {
      if (dates?.[0] || dates?.[1]) {
        const range = timelineUnit === 'day' ? 1 : timelineUnit === 'week' ? 7 : 28;
        if (info.range === 'end') {
          const first = dates[1].clone();
          changeDate(first.add(-range, 'days').startOf('day'), dates[1].startOf('day'));
        } else {
          const second = dates[0].clone();
          changeDate(dates[0].startOf('day'), second.add(range, 'days').startOf('day'));
        }
        setRangePickerOpen(false);
      }
    }
  };

  const disabledDate = current => {
    if (timelineUnit !== 'custom' || (!currentDates[0] && !currentDates[1])) {
      return false;
    }

    const comparison = currentDates[0] || currentDates[1];

    const tooLate = Math.abs(current.diff(comparison, 'days')) > 28;

    return !!tooLate;
  };

  const PilotageTooltip = () => {
    return (
      <PilotageContainer>
        <TitleContainer>
          <PilotageTitle>{t('Pilotages')}</PilotageTitle>
        </TitleContainer>
        <table>
          <tbody>
            <tr>
              <td style={{ paddingRight: '18px' }}>{t('Requested')}</td>
              <td>
                <IndicatorContainer>
                  <PilotageColorIndicator inPilotEstimated={true} />
                </IndicatorContainer>
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '18px' }}>{t('Ordered')}</td>
              <td>
                <IndicatorContainer>
                  <PilotageColorIndicator inPilotOrdered={true} />
                </IndicatorContainer>
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '18px' }}>{t('Commenced')}</td>
              <td>
                <IndicatorContainer>
                  <PilotageColorIndicator inPilotCommenced={true} />
                </IndicatorContainer>
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '18px' }}>{t('Completed')}</td>
              <td>
                <IndicatorContainer>
                  <PilotageColorIndicator inPilotCompleted={true} />
                </IndicatorContainer>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ height: '12px', width: '6px' }} />
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: '12px' }}>{t('No pilotage info')}</td>
              <td>
                <PilotageColorIndicator />
              </td>
            </tr>
          </tbody>
        </table>
        <Stripes>
          <StripeText>{t('Difference between pilotage and ETA/ETD')}</StripeText>
          <StripeContainer>
            <StripedPart color={'#2ea363'} color2={'#A5D6A7'} />
            <StripedPart color={'#6f97de'} color2={'#A7C4F4'} />
            <StripedPart color={'#edca1a'} color2={'#FFE77C'} />
          </StripeContainer>
        </Stripes>
      </PilotageContainer>
    );
  };

  return (
    <TopPanel>
      <Header>{t('Timeline')}</Header>
      <Buttons>
        <CalendarButtons>
          <ButtonVersion cancel onClick={setToday}>
            {t('Today')}
          </ButtonVersion>
          <CalendarTypeButtons>
            <div id="calendar-type-select">
              <Select value={timelineUnit} style={{ width: 102 }} onChange={handleChange} size="small">
                <Option value="day">{t('Day')}</Option>
                <Option value="twodays">{t('2 days')}</Option>
                <Option value="week">{t('Week')}</Option>
                <Option value="month">{t('4 weeks')}</Option>
                <Option value="custom">{t('Custom')}</Option>
              </Select>
            </div>
          </CalendarTypeButtons>
          <IconContainer onClick={moveBackwards} style={{ marginLeft: 0 }}>
            <Icon type="chevron-left" />
          </IconContainer>
          <IconContainer onClick={moveForwards}>
            <Icon type="chevron-right" />
          </IconContainer>
        </CalendarButtons>
        <CustomContainer>
          <RangePicker
            format={'DD.MM.YYYY'}
            allowClear={true}
            value={rangePickerOpen ? [null, null] : [dayjs(visibleTimeStart), dayjs(visibleTimeEnd)]}
            onCalendarChange={(dates, datesStr, info) => updateCustomDates(dates, info)}
            disabledDate={disabledDate}
            onOpenChange={e => {
              setRangePickerOpen(e);

              if (e) {
                setCurrentDates([null, null]);
              }
            }}
            allowEmpty={[true, true]}
            open={rangePickerOpen}
          />
        </CustomContainer>
      </Buttons>
      <SpinContainer>{smallLoader && <Spin size="small" />}</SpinContainer>

      {modules.sea_passage_module === 'enabled' && <DelayedVessels />}
      <Tooltip title={PilotageTooltip()} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
        <PilotageColors>
          <PilotageTitle>{t('Pilotages')}</PilotageTitle>
          <Indicators>
            <PilotageColorIndicator outPilotEstimated={true} margin={true} />
            <PilotageColorIndicator outPilotOrdered={true} margin={true} />
            <PilotageColorIndicator outPilotCommenced={true} margin={true} />
            <PilotageColorIndicator outPilotCompleted={true} margin={true} />
          </Indicators>
        </PilotageColors>
      </Tooltip>
      <FleetFilterAndSearchContainer>
        <FleetFilterContainer containerId="fleet-filter-in-fleet-view" />
        <FleetFilterSearch />
      </FleetFilterAndSearchContainer>
    </TopPanel>
  );
};

export default FleetViewTopPanel;
