import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import ActivityListComponent from '../components/activity/ActivityListComponent';

const ActivityList = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Activity List | Port Activity App';
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout filtering pagename={t('Activity List')}>
      <Page fullWidth>
        <ActivityListComponent />
      </Page>
    </Layout>
  );
};

export default ActivityList;
