import React, { useContext, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import { UserContext } from '../context/UserContext';
import { TimestampContext } from '../context/TimestampContext';

import HeaderSearch from './ui/HeaderSearch';

const Container = styled.div`
  margin-right: 10px;
`;

const SearchContainer = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { setSearch } = useContext(TimestampContext);

  const location = useLocation();
  const history = useHistory();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const debouncedSearch = useMemo(() => {
    const handleSearch = value => {
      if (mounted.current) {
        setSearch(value);
      }
    };

    return debounce(500, handleSearch);
  }, [setSearch]);

  const handleSearchChange = e => {
    e.preventDefault();
    const searchParameters = {
      search: e.target.value,
    };
    history.replace(location.pathname + '?search=' + encodeURIComponent(searchParameters.search));
    debouncedSearch(searchParameters.search);
  };

  const constructSearchContentFromLocation = () => {
    const params = new URLSearchParams(location.search);
    if (params.get('search')) {
      const searchParameters = {
        search: params.get('search'),
      };
      debouncedSearch(searchParameters.search);
      return params.get('search');
    } else {
      debouncedSearch('');
    }
    return '';
  };

  return (
    <Container>
      <HeaderSearch
        id="timeline-search-bar"
        name="search"
        placeholder={t('Search')}
        autoComplete="off"
        value={constructSearchContentFromLocation()}
        onChange={handleSearchChange}
      />
    </Container>
  );
};

export default SearchContainer;
