import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { TimestampFilteringContext } from '../../context/TimestampFilteringContext';
import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const TimestampFiltering = styled.div`
  font-size: 14px;
  margin: auto 10px;
  color: ${props => (props.blue ? props.theme.color.secondary : '#4a4a4a')};
  font-weight: ${props => (props.current ? 700 : 400)};
  cursor: pointer;
  display: flex;
  align-content: center;
  padding: 4px 8px;
  background-color: ${props => (!props.open ? 'transparent' : '#f3f3f3')};

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const TimestampFilteringButton = ({ timestampFilteringOpen, openTimestampFilterModal }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { timestampFilteringList, userOnOffTimestamps } = useContext(TimestampFilteringContext);

  return (
    <TimestampFiltering
      open={timestampFilteringOpen}
      blue={userOnOffTimestamps || timestampFilteringList.findIndex(ts => !ts.visible) > -1}
      onClick={openTimestampFilterModal}
    >
      <IconContainer>
        <Icon type="filter-timestamps" />
      </IconContainer>
      {t('Filter timestamps')}
    </TimestampFiltering>
  );
};

export default TimestampFilteringButton;
