import socketClusterClient from 'socketcluster-client';

export const createSocket = namespace => {
  let options = {
    autoReconnectOptions: {
      initialDelay: 1000, //milliseconds
      randomness: 1000, //milliseconds
      multiplier: 1.5, //decimal
      maxDelay: 6000, //milliseconds
    },
  };

  if (import.meta.env.VITE_REACT_APP_SOCKETCLUSTER_HOST) {
    options.hostname = import.meta.env.VITE_REACT_APP_SOCKETCLUSTER_HOST;
  }
  if (import.meta.env.VITE_REACT_APP_SOCKETCLUSTER_PORT) {
    options.port = import.meta.env.VITE_REACT_APP_SOCKETCLUSTER_PORT;
    if (options.port === '443') {
      options.secure = true;
    }
  }

  let socket = null;

  if (namespace) {
    options.path = '/socketcluster/' + namespace + '/';
    if (import.meta.env.VITE_REACT_APP_IS_DEV === 'yes') {
      // no namespace/socket proxy when developing locally
      options.path = '/socketcluster/';
    }
    socket = socketClusterClient.create(options);
  }

  if (socket) {
    (async () => {
      (async () => {
        // eslint-disable-next-line
          for await (let { error } of socket.listener('error')) {
          console.error('Error with socket', error);
        }
      })();
      (async () => {
        // eslint-disable-next-line
          for await (let event of socket.listener('connect')) {
          console.log('Socket is connected');
        }
      })();
    })();
  }

  return socket;
};
