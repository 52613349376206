import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { API_URL } from './utils/constants';
// import { getEnvironmentName } from './utils/utils';

const backendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  // the function might return a promise
  // returning falsy will abort the download
  //
  // If not used with i18next-multiload-backend-adapter, lngs and namespaces will have only one element each,
  // If used with i18next-multiload-backend-adapter, lngs and namespaces can have multiple elements
  //   and also your server needs to support multiloading
  //      /locales/resources.json?lng=de+en&ns=ns1+ns2
  //   Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
  //   Returned JSON structure in this case is
  //   {
  //    lang : {
  //     namespaceA: {},
  //     namespaceB: {},
  //     ...etc
  //    }
  //   }
  loadPath: API_URL + '/translations/{{ns}}/{{lng}}',

  // path to post missing resources, or a function
  // function(lng, namespace) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  addPath: API_URL + '/translation/{{ns}}/{{lng}}',

  // parse data before it has been sent by addPath
  parsePayload: (namespace, key, fallbackValue) => {
    return {
      missing: {
        [key]: fallbackValue || '',
      },
    };
  },

  // custom request headers sets request.setRequestHeader(key, value)
  // can also be a function, that returns the headers
  customHeaders: () => {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      return {
        Authorization: `Bearer ${sessionId}`,
      };
    }
  },
};

const saveMissing = localStorage.getItem('saveMissingTranslations');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    backend: backendOptions,
    debug: false, //getEnvironmentName(document.location.hostname) === 'development',
    fallbackLng: 'en',
    lng:
      document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')
        ? undefined
        : 'en', // override language detection
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true, // use if translations are fetched from backend
    },
    ns: [],
    defaultNS: 'common',
    nsMode: 'fallback',
    nsSeparator: '::',
    preload: ['en'],
    //useLocalStorage: true | false,
    //localStorageExpirationTime: 86400000, // in ms, default 1 week
    saveMissing, // send not translated keys to endpoint
    saveMissingTo: 'all',
  });

export default i18n;
