import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import TreeSelect from 'antd/es/tree-select';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

const { SHOW_PARENT } = TreeSelect;

const SelectAll = styled.div`
  color: ${({ theme }) => theme.color.secondary};
`;

const FleetFilterTreeSelect = ({ data, onChange, selectedValues, placeholder }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [expandedKeys, setExpandedKeys] = useState(data.map(item => item.value));

  const handleExpand = value => {
    setExpandedKeys(value);
  };

  const allParents = data.map(item => ({
    value: item.value,
    label: item.label,
  }));

  return (
    <TreeSelect
      placeholder={t(placeholder)}
      treeCheckable={true}
      showCheckedStrategy={SHOW_PARENT}
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ minWidth: '300px' }}
      labelInValue={true}
      onChange={onChange}
      value={selectedValues || []}
      treeExpandedKeys={expandedKeys}
      treeNodeFilterProp={'label'}
      onTreeExpand={handleExpand}
      maxTagCount={6}
      maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} ...`}
      treeData={[
        {
          title:
            selectedValues.length > 0 ? (
              <SelectAll onClick={() => onChange([])}>{t('Unselect all')}</SelectAll>
            ) : (
              <SelectAll onClick={() => onChange(allParents)}>{t('Select all')}</SelectAll>
            ),
          value: 'selectAll',
          disableCheckbox: true,
          checkable: false,
          selectable: false,
        },
        ...data,
      ]}
    />
  );
};

export default FleetFilterTreeSelect;
