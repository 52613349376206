import React, { useContext, useEffect, useRef, useState } from 'react';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonLight from '../components/ui/ButtonLight';
import AddNamespaceModal from '../components/AddNamespaceModal';
import ProfileCircle from '../components/ui/ProfileCircle';
import UserSignature from '../components/waste/UserSignature';
import { mobilePixelMaxWidthLimit } from '../utils/constants';
import ProfileChangeEmailModal from '../components/ProfileChangeEmailModal';
import ProfileChangePasswordModal from '../components/ProfileChangePasswordModal';
import ProfileEditDetailsModal from '../components/ProfileEditDetailsModal';

const DataColumn = styled.div`
  margin-right: 42px;
`;

const HeaderTitle = styled.div`
  font-size: 20px;
  margin-left: 22px;
  line-height: 64px;
`;

const Permission = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`;

const DoubleColumn = styled.div`
  display: flex;
  padding: 10px 0px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: inline-block;
  }
`;

const BannerBg = styled.div`
  background-color: #f8f8f8;
  margin: -22px -22px 0 -22px;
  height: 64px;
  position: relative;
`;

const PageContainer = styled.div`
  display: flex;
  padding: 30px 0 0 30px;

  @media (max-width: 650px) {
    display: inline-block;
  }
`;

const Container = styled.div`
  display: inline-block;

  @media (max-width: 650px) {
    width: calc(100vw - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 650px) {
    margin-bottom: 30px;
  }
`;

const ProfileInfo = styled.div`
  background-color: white;
  padding: 22px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 280px;
  margin-bottom: 30px;

  @media (max-width: 650px) {
    width: calc(100vw - 60px);
  }
`;

const ProfileDetails = styled.div`
  background-color: white;
  padding: 22px;
`;

const ProfilePermissions = styled.div`
  background-color: white;
  padding: 22px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: calc(100% - 340px);
  margin-left: 30px;

  @media (max-width: 650px) {
    width: calc(100vw - 60px);
    margin-left: 0px;
  }
`;

const NameRow = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  margin-top: 12px;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
`;

const InfoRow = styled.div`
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  font-size: 13px;
  line-height: 22px;
`;

const CircleContainer = styled.div`
  position: absolute;
  top: 37px;
  left: calc((100% - 52px) / 2);
`;

const Spacer = styled.div`
  margin-bottom: 20px;
`;
const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
`;

const LinkButton = styled.div`
  margin-left: 6px;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
`;

const SentSuccess = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const ProfilePage = () => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [namespaceModalOpen, setNamespaceModalOpen] = useState(false);
  const [emailSentSuccess, setEmailSentSuccess] = useState(undefined);
  const [passwordSentSuccess, setPasswordSentSuccess] = useState(undefined);
  const [detailsSentSuccess, setDetailsSentSuccess] = useState(undefined);
  const [namespaceSentSuccess, setNamespaceSentSuccess] = useState(undefined);

  let timer = useRef();

  useEffect(() => {
    document.title = 'User profile | Port Activity App';
  }, []);

  const emptyAllSuccessMessages = () => {
    setEmailSentSuccess(undefined);
    setPasswordSentSuccess(undefined);
    setDetailsSentSuccess(undefined);
    setNamespaceSentSuccess(undefined);
  };

  const updateEmailSentSuccess = type => {
    setEmailSentSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updatePasswordSentSuccess = type => {
    setPasswordSentSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateDetailsSentSuccess = type => {
    setDetailsSentSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  const updateNamespaceSentSuccess = type => {
    setNamespaceSentSuccess(type);
    timer.current = setTimeout(() => {
      emptyAllSuccessMessages();
    }, 3000);
  };

  return (
    <Layout pagename={t('Profile')}>
      <PageContainer>
        {emailSentSuccess && <SentSuccess>{t('Email updated successfully.')}</SentSuccess>}
        {passwordSentSuccess && <SentSuccess>{t('Password updated successfully.')}</SentSuccess>}
        {detailsSentSuccess && <SentSuccess>{t('Details updated successfully.')}</SentSuccess>}
        {namespaceSentSuccess && <SentSuccess>{t('Namespace added successfully.')}</SentSuccess>}
        <Container>
          <ProfileInfo>
            <BannerBg>
              <CircleContainer>
                <ProfileCircle big />
              </CircleContainer>
            </BannerBg>
            <ProfileDetails>
              <NameRow>
                {t('Name')}: {user.first_name} {user.last_name}
              </NameRow>
              <InfoRow>
                {t('Email')}: {user.email}
              </InfoRow>
              <InfoRow>
                {t('Role')}: {user.role}
              </InfoRow>
              <InfoRow>
                {t('ID')}: {user.id}
              </InfoRow>
            </ProfileDetails>
          </ProfileInfo>
          <UserSignature />
          <Spacer />
          <LinkContainer>
            <LinkButton onClick={() => setEmailModalOpen(true)}>{t('Change email')}</LinkButton>
          </LinkContainer>
          <LinkContainer>
            <LinkButton onClick={() => setPasswordModalOpen(true)}>{t('Change password')}</LinkButton>
          </LinkContainer>
          <LinkContainer>
            <LinkButton onClick={() => setDetailsModalOpen(true)}>{t('Edit profile details')}</LinkButton>
          </LinkContainer>
          <ButtonContainer>
            <ButtonLight onClick={() => setNamespaceModalOpen(true)}>{t('Add namespace with code')}</ButtonLight>
          </ButtonContainer>
        </Container>
        <ProfilePermissions>
          <BannerBg>
            <HeaderTitle>{t('Permissions')} </HeaderTitle>
          </BannerBg>
          <DoubleColumn>
            <DataColumn>
              {user.permissions
                .filter((p, i) => i % 2 === 0)
                .map((p, i) => (
                  <Permission key={i}>{p.replace(/_/g, ' ')}</Permission>
                ))}
            </DataColumn>
            <DataColumn>
              {user.permissions
                .filter((p, i) => i % 2 === 1)
                .map((p, i) => (
                  <Permission key={i}>{p.replace(/_/g, ' ')}</Permission>
                ))}
            </DataColumn>
          </DoubleColumn>
        </ProfilePermissions>
      </PageContainer>

      {emailModalOpen && (
        <ProfileChangeEmailModal closeModal={() => setEmailModalOpen(false)} setSentSuccess={updateEmailSentSuccess} />
      )}
      {passwordModalOpen && (
        <ProfileChangePasswordModal
          closeModal={() => setPasswordModalOpen(false)}
          setSentSuccess={updatePasswordSentSuccess}
        />
      )}
      {detailsModalOpen && (
        <ProfileEditDetailsModal
          closeModal={() => setDetailsModalOpen(false)}
          setSentSuccess={updateDetailsSentSuccess}
        />
      )}
      {namespaceModalOpen && (
        <AddNamespaceModal
          closeModal={() => setNamespaceModalOpen(false)}
          setSentSuccess={updateNamespaceSentSuccess}
        />
      )}
    </Layout>
  );
};

export default ProfilePage;
