import React, { useContext, useCallback, useEffect, useState } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const AlertGreen = styled.div`
   {
    color: green;
  }
`;

const AlertOrange = styled.div`
   {
    color: darkorange;
  }
`;

const AlertRed = styled.div`
   {
    color: red;
  }
`;

const AdminDashboard = () => {
  const { useUserSocket, namespace, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const location = useLocation();

  useEffect(() => {
    document.title = 'Queue - Just-In-Time Arrival | Port Activity App';
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'dashboard-slot-reservations', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const reFetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useUserSocket('queue-portcalls-changed', reFetch);

  const slotRequests = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
    },
    {
      title: t('ETA'),
      dataIndex: 'eta',
      key: 'eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('RTA'),
      dataIndex: 'rta_window_start',
      key: 'rta_window_start',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('JIT ETA'),
      dataIndex: 'jit_eta',
      key: 'jit_eta',
      render: (text, record) => {
        if (text && record.jit_eta_alert_state === 'green') {
          return (
            <AlertGreen>
              <DateComponent format={TIME_FORMAT} date={text} />
            </AlertGreen>
          );
        } else if (text && record.jit_eta_alert_state === 'orange') {
          return (
            <AlertOrange>
              <DateComponent format={TIME_FORMAT} date={text} />
            </AlertOrange>
          );
        } else if (text && record.jit_eta_alert_state === 'red') {
          return (
            <AlertRed>
              <DateComponent format={TIME_FORMAT} date={text} />
            </AlertRed>
          );
        }
      },
    },
    {
      title: t('Live ETA'),
      dataIndex: 'live_eta',
      key: 'live_eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('PTD'),
      dataIndex: 'ptd',
      key: 'ptd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Berth'),
      dataIndex: 'berth_name',
      key: 'berth_name',
    },
    {
      title: t('Status'),
      dataIndex: 'readable_slot_reservation_status',
      key: 'readable_slot_reservation_status',
    },
  ];

  return (
    <Layout pagename={t('Queue - JIT Arrival')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={slotRequests}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          hideSearch={true}
        />
      </Page>
    </Layout>
  );
};

export default AdminDashboard;
