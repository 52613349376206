import React from 'react';

const AssetToolTip = ({ asset }) => {
  return (
    <div>
      <h4>{asset.name}</h4>
    </div>
  );
};

export default AssetToolTip;
