import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import { debounce, throttle } from 'throttle-debounce';

import useApi from '../../../hooks/useApi';

import { UserContext } from '../../../context/UserContext';
import NotificationMessage from './NotificationMessage';
import NotificationGroup from './NotificationGroup';
import NotificationModalMenu from './NotificationModalMenu';
import Icon from '../../ui/Icon';

const ModalInner = styled.div`
  height: 70vh;
  min-height: 500px;
  position: relative;
  min-width: 300px;
  display: flex;
`;

const LoaderBase = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  position: absolute;
  height: calc(100% - 224px);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Loader = styled(LoaderBase)`
  background-color: ${({ theme }) => theme.color.white};
`;

const LoaderLight = styled(LoaderBase)`
  background-color: ${({ theme }) => theme.color.white};
  opacity: 0.5;
`;

const NotificationsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const NoNotificationsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NoNotificationsText = styled.div`
  text-align: center;
  padding-top: 200px;
`;

const NotificationsList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TitleRowContainer = styled.div`
  display: inline-block;
  i {
    width: 32px;
    height: 20px;
    margin-right: 12px;
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

const UnreadMessagesText = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  border-radius: 2px;
  padding: 3px 5px;
  position: absolute;
  top: calc(100% - 242px);
  right: 24px;
  font-size: ${({ theme }) => theme.text.smaller};
  opacity: 0.7;
  z-index: 5;
`;

const MenuIcon = styled(Icon)`
  svg {
    fill: #4a4a4a !important;
    margin-top: -2px;
  }

  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

const CardNotificationModal = ({ imo, master_id, vessel_name, close }) => {
  const [loadingTimerOn, setLoadingTimer] = useState(true);
  const [portcallsAvailable, setPortcallsAvailable] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [portCallId, setPortCallId] = useState(master_id);
  const [scrolling, setScrolling] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [readArray, setReadArray] = useState([]);

  let timer = useRef();
  let timer2 = useRef();
  let readtimer = useRef();
  let containerRef = useRef();
  let listRef = useRef();
  let messageRef = useRef();
  let timeout = useRef();
  let portcallIdRef = useRef(portCallId);

  useEffect(() => {
    if (loadingTimerOn) {
      timer.current = setTimeout(() => {
        setLoadingTimer(false);
      }, 4000);
    }
  }, [loadingTimerOn]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const onScroll = () => {
    if (!scrolling) {
      setScrolling(true);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      setScrolling(false);
    }, 500);
  };

  const { useUserSocket, user, namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const defaultParams = {
    imo,
    limit: 100,
    offset: 0,
    sort: 'modified_at DESC',
    port_call_master_id: master_id,
  };

  const defaultParamsForPortcall = {
    limit: 100,
    offset: 0,
    sort: 'atd DESC',
    search: imo,
  };

  const {
    loading: loadingForNotification,
    data: notificationData,
    error: notificationError,
    fetchData: notificationFetchData,
  } = useApi('get', 'notifications-by-filter', defaultParams);

  const {
    loading: loadingForPortCall,
    data: portcallData,
    error: portcallError,
    fetchData: portcallFetchData,
  } = useApi('get', 'port-calls', defaultParamsForPortcall);

  useEffect(() => {
    notificationFetchData(false, {
      imo,
      limit: 200,
      offset: 0,
      sort: 'modified_at DESC',
      port_call_master_id: portCallId,
    });
    portcallIdRef.current = portCallId;
  }, [portCallId, notificationFetchData, imo]);

  const portcallsChanged = useMemo(
    () =>
      throttle(15000, false, () => {
        // spread out request per client
        setTimeout(() => portcallFetchData(), Math.floor(Math.random() * 10000));
      }),
    [portcallFetchData]
  );

  const notificationsChanged = useCallback(() => {
    portcallFetchData();
    notificationFetchData(portcallIdRef.current !== portCallId, {
      imo,
      limit: 200,
      offset: 0,
      sort: 'modified_at DESC',
      port_call_master_id: portCallId,
    });
  }, [notificationFetchData, portcallFetchData, imo, portCallId]);

  useUserSocket('portcalls-changed', portcallsChanged);
  useUserSocket('notifications-changed', notificationsChanged);
  useUserSocket(`notifications-summary-changed-${user.id}`, notificationsChanged);

  let notifications = notificationError ? [] : notificationData ? notificationData.data : [];
  let portCalls = portcallError ? [] : portcallData;

  const closeModal = () => {
    close(undefined);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const choosePortcall = masterId => {
    setPortCallId(masterId);
    setMenuOpen(false);
    setLoadingTimer(true);
    notifications = null;
  };

  if (!portcallsAvailable && portCalls) {
    setPortcallsAvailable(true);
  }

  const TitleRow = () => {
    return (
      <TitleRowContainer>
        <MenuIcon type="menu" onClick={() => toggleMenu()} />
        {t('Send notification {{name}}', { name: vessel_name.toUpperCase() })}
      </TitleRowContainer>
    );
  };

  const replyOpened = (height, yValue, state) => {
    if (state) {
      const bottom =
        containerRef.current.getBoundingClientRect().y +
        containerRef.current.getBoundingClientRect().height -
        messageRef.current.getBoundingClientRect().height;
      let hiddenPart = height + yValue - bottom;
      if (hiddenPart < 0) {
        if (listRef.current) {
          listRef.current.scrollBy(0, -height - 7);
        }
      }
    } else {
      if (listRef.current) {
        listRef.current.scrollBy(0, height + 7);
      }
    }
  };

  const moveList = height => {
    if (listRef.current) {
      listRef.current.scrollBy(0, -height - 2.5);
    }
  };

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollBy(0, 10000);
    }
  };

  const messageSent = () => {
    setOverlay(true);
    timer2.current = setTimeout(() => {
      scrollToBottom();
      setOverlay(false);
    }, 5000);
  };

  const sendOnRead = async () => {
    const dataids = [...readArray];
    setReadArray([]);
    await apiCall('post', 'notifications-read', {
      notification_ids: dataids,
    });

    notificationFetchData(false, {
      imo,
      limit: 200,
      offset: 0,
      sort: 'modified_at DESC',
      port_call_master_id: portCallId,
    });
  };

  const debouncedRead = debounce(3000, sendOnRead);

  useEffect(() => {
    if (readArray.length) {
      clearTimeout(readtimer.current);
      readtimer.current = setTimeout(() => {
        sendOnRead();
      }, 1000);
    }

    return () => {
      clearTimeout(readtimer.current);
    };
    // eslint-disable-next-line
  }, [debouncedRead, readArray]);

  const onRead = id => {
    setReadArray(array => [...array, id]);
  };

  const viewPort = containerRef.current ? containerRef.current.getBoundingClientRect() : {};
  let unread = !!notifications.find(n => !n.read || (n.children ? n.children.find(c => !c.read) : false));

  return (
    <Modal title={TitleRow()} open={imo} width={1000} onCancel={closeModal} footer={null}>
      <ModalInner>
        {unread && <UnreadMessagesText>{t('Unread messages')}</UnreadMessagesText>}
        {loadingForNotification && loadingTimerOn && (
          <Loader>
            <Spin size="large" />
          </Loader>
        )}
        {overlay && loadingForNotification && (
          <LoaderLight>
            <Spin size="large" />
          </LoaderLight>
        )}
        {/* Menu that opens from hamburger menu button */}
        {menuOpen && (
          <NotificationModalMenu
            data={portCalls}
            onClose={closeMenu}
            onChoose={choosePortcall}
            loading={loadingForPortCall && !portcallsAvailable}
            chosenId={portCallId}
            stayOpen={false}
          />
        )}
        {/* Menu for wider screen that stays visible */}
        <NotificationModalMenu
          data={portCalls}
          onClose={closeMenu}
          onChoose={choosePortcall}
          loading={loadingForPortCall && !portcallsAvailable}
          chosenId={portCallId}
          stayOpen={true}
        />
        {!!notifications && (
          <NotificationsContainer ref={containerRef}>
            {notifications.length === 0 ? (
              <NoNotificationsContainer>
                <NoNotificationsText>{t('No notifications to be shown.')}</NoNotificationsText>
              </NoNotificationsContainer>
            ) : (
              <NotificationsList ref={listRef} onScroll={onScroll}>
                {notifications.map(d => (
                  <NotificationGroup
                    key={d.id}
                    group={d}
                    replyOpened={replyOpened}
                    viewPortTop={viewPort.top}
                    viewPortBottom={viewPort.bottom}
                    moveList={moveList}
                    scrolling={scrolling}
                    onRead={onRead}
                    messageSent={messageSent}
                  />
                ))}
              </NotificationsList>
            )}
            <NotificationMessage
              imo={imo}
              master_id={portCallId}
              ref={messageRef}
              messageSent={messageSent}
              closeModal={closeModal}
            />
          </NotificationsContainer>
        )}
      </ModalInner>
    </Modal>
  );
};

export default CardNotificationModal;
