import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import VesselDataEdit from './VesselDataEdit';
import { TIME_FORMAT } from '../../utils/constants';
import { TimestampContext } from '../../context/TimestampContext';
import Icon from '../ui/Icon';
import { darken } from 'polished';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 18px 24px 18px;
  justify-content: center;
  position: relative;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const Header = styled.td`
  font-weight: 600;
`;

const HeaderText = styled.div`
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 6px;
`;

const Label = styled.td`
  padding-right: 24px;
  white-space: nowrap;
`;

const EmptyTd = styled.td`
  height: 1px;
  width: 36px;
`;

const EmptyTdRow = styled.td`
  height: 24px;
  width: 1px;
`;

const Value = styled.td`
  font-weight: 600;
  white-space: nowrap;
`;

const EditIconContainer = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.color.secondary};
  top: -7px;
  right: 24px;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-radius: 3px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    margin-left: 1px;
    margin-bottom: 2px;
  }

  &:hover {
    border-color: ${props => darken(0.1, props.theme.color.secondary)};
  }
`;

const InfoTable = styled.div`
  margin-top: 12px;
  margin-bottom: 36px;
`;

const VesselInfo = ({ portcallId, reloadOngoing }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { iso31661alpha2 } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);

  const [vesselData, setVesselData] = useState(null);
  const [startDataAvailable, setStartDataAvailable] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const getStartData = async () => {
      setLoading(true);
      if (!dataFetched) {
        setDataFetched(true);
        let result;
        try {
          result = await apiCall('get', 'port-call', { id: portcallId });
        } catch (e) {
          setVesselData(null);
        }
        if (result?.data?.ship) {
          const data = { ...result.data.ship };
          data.vessel_loa = data.loa;
          data.vessel_beam = data.beam;
          data.vessel_draft = data.draft;
          data.from_port_name = data.from_port;
          data.from_port = data.from_port_code;
          data.next_port_name = data.next_port;
          data.next_port = data.next_port_code;
          setVesselData(data);
        }
      }
      setLoading(false);
    };

    if (!startDataAvailable) {
      getStartData();
      setStartDataAvailable(true);
    }
  }, [apiCall, dataFetched, portcallId, startDataAvailable]);

  return (
    <Container>
      {user.permissions.includes('add_admin_timestamp') && !editing && (
        <Tooltip placement="top" title={t('Edit data')} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
          <EditIconContainer onClick={() => setEditing(true)}>
            <Icon type="edit" />
          </EditIconContainer>
        </Tooltip>
      )}
      {loading ? (
        <Loader>
          <Spin size="medium" />
        </Loader>
      ) : vesselData ? (
        editing ? (
          <VesselDataEdit
            portcallId={portcallId}
            data={vesselData}
            closeEdit={() => setEditing(false)}
            reload={() => {
              setDataFetched(false);
              setStartDataAvailable(false);
              reloadOngoing();
            }}
          />
        ) : (
          <InfoTable>
            <table>
              <tbody>
                <tr>
                  <Header colSpan="5">
                    <HeaderText>{t('Portcall')}</HeaderText>
                  </Header>
                </tr>
                <tr>
                  <Label>{t('Agent')}</Label>
                  <Value colSpan="4">{vesselData.agent}</Value>
                </tr>
                <tr>
                  <EmptyTdRow />
                </tr>
                <tr>
                  <Header colSpan="5">
                    <HeaderText>{t('Vessel')}</HeaderText>
                  </Header>
                </tr>
                <tr>
                  <Label>{t('Name')}</Label>
                  <Value>{vesselData.vessel_name}</Value>
                  <EmptyTd />
                  <Label>{t('Net tonnage')}</Label>
                  <Value>{vesselData.net_tonnage || '-'} </Value>
                </tr>
                <tr>
                  <Label>{t('IMO')}</Label>
                  <Value>{vesselData.imo}</Value>
                  <EmptyTd />
                  <Label>{t('Dead weight')}</Label>
                  <Value>
                    {vesselData.dead_weight || '-'}{' '}
                    {vesselData.dead_weight || vesselData.dead_weight === 0 ? t('tons') : ''}
                  </Value>
                </tr>
                <tr>
                  <Label>{t('MMSI')}</Label>
                  <Value>{vesselData.mmsi}</Value>
                  <EmptyTd />
                  <Label>{t('Loa')}</Label>
                  <Value>
                    {vesselData.loa || '-'} {vesselData.loa || vesselData.loa === 0 ? t('meters') : ''}
                  </Value>
                </tr>
                <tr>
                  <Label>{t('Nationality')}</Label>
                  <Value>
                    {vesselData.nationality && vesselData.nationality.length == 2
                      ? iso31661alpha2[vesselData.nationality]
                      : vesselData.nationality}
                  </Value>
                  <EmptyTd />
                  <Label>{t('Beam')}</Label>
                  <Value>
                    {vesselData.beam || '-'} {vesselData.beam || vesselData.beam === 0 ? t('meters') : ''}
                  </Value>
                </tr>
                <tr>
                  <Label>{t('Type')}</Label>
                  <Value>{vesselData.vessel_type_name}</Value>
                  <EmptyTd />
                  <Label>{t('Draft')}</Label>
                  <Value>
                    {vesselData.draft || '-'} {vesselData.draft || vesselData.draft === 0 ? t('meters') : ''}
                  </Value>
                </tr>
                <tr>
                  <EmptyTdRow />
                </tr>
                <tr>
                  <Label>{t('Estimated travel')}</Label>
                  <Value>{vesselData.estimated_travel_distance || '-'}</Value>
                  <EmptyTd />
                  <Header colSpan="2">
                    <HeaderText>{t('AIS')}</HeaderText>
                  </Header>
                </tr>
                <tr>
                  <EmptyTd />
                  <EmptyTd />
                  <EmptyTd />
                  <Label>{t('Destination')}</Label>
                  <Value>{vesselData.ais_data?.destination || '-'}</Value>
                </tr>
                <tr>
                  <Label>{t('From port')}</Label>
                  {vesselData.from_port ? (
                    <Value>
                      {vesselData.from_port_name} ({vesselData.from_port})
                    </Value>
                  ) : (
                    <Value>-</Value>
                  )}
                  <EmptyTd />
                  <Label>{t('ETA')}</Label>
                  <Value>
                    {vesselData.ais_data?.formattedEta ? (
                      <DateComponent format={TIME_FORMAT} date={vesselData.ais_data?.formattedEta} />
                    ) : (
                      '-'
                    )}
                  </Value>
                </tr>
                <tr>
                  <Label>{t('Next port')}</Label>
                  {vesselData.next_port ? (
                    <Value>
                      {vesselData.next_port_name} ({vesselData.next_port})
                    </Value>
                  ) : (
                    <Value>-</Value>
                  )}
                  <EmptyTd />
                  <Label>{t('Updated')}</Label>
                  <Value>
                    {vesselData.ais_data?.updated_at ? (
                      <DateComponent format={TIME_FORMAT} date={vesselData.ais_data?.updated_at} />
                    ) : (
                      '-'
                    )}
                  </Value>
                </tr>
              </tbody>
            </table>
          </InfoTable>
        )
      ) : (
        <NoData>{t('No data available.')}</NoData>
      )}
    </Container>
  );
};

export default VesselInfo;
