import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import ShipRoute from '../ShipRoute';
import ShipBadges from '../ShipBadges';

import { UserContext } from '../../../context/UserContext';
import { TIME_FORMAT } from '../../../utils/constants';
import DateComponent from '../../ui/DateComponent';

const Menu = styled.div`
  display: flex;
  height: 100%;
  z-index: 101;

  @media only screen and (min-width: 700px) {
    width: 300px;
  }

  ${props => {
    if (props.stayOpen) {
      return `
        @media only screen and (max-width: 699px) {
          display: none;
        }
      `;
    } else {
      return `
        @media only screen and (max-width: 699px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
        @media only screen and (min-width: 700px) {
          display: none;
        }
      `;
    }
  }}
`;

const MenuContainer = styled.div`
  height: 100%;
  width: 40%;
  min-width: 300px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
`;

const MenuLayover = styled.div`
  @media only screen and (max-width: 699px) {
    height: 100%;
    width: 60%;
  }
`;

const PortCallItem = styled.div`
  margin: 15px;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  cursor: pointer;
  background-color: ${props => (props.chosen ? ({ theme }) => theme.color.beige : 'transparent')};
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${({ theme }) => theme.color.white};
`;

const Placeholder = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
`;

const NextEvent = styled.p`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const NextEventTitle = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  text-align: center;
  padding: 4px 0;
  color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.white};
  height: 31px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 31px);
`;

const NotificationsAndDecisions = styled.div`
  display: flex;
  align-content: center;
`;

const DecisionOrNotification = styled.div`
  display: flex;
  margin-right: 1px;
  width: 50%;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.text.small};
`;

const ColorBackground = styled.div`
  line-height: 18px;
  height: 18px;
  width: 18px;
  padding-bottom: 1px;
  border-radius: 45%;
  text-align: center;
  margin-left: 0.4rem;
  color: ${({ theme }) => theme.color.white};
  font-size: 10px;
`;

const RedBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning};
`;

const WarningYellow = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning_yellow};
`;

const GreyBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.grey};
`;

const NextEventTimestamp = styled(DateComponent)``;

const NotificationModalMenu = ({ onClose, onChoose, data, loading, chosenId, stayOpen }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  /* eslint-disable-next-line quotes */
  const headerText = "All ship's port calls";
  return (
    <Menu stayOpen={stayOpen}>
      <MenuContainer>
        {loading && (
          <Loader>
            <Spin size="medium" />
          </Loader>
        )}
        {!loading && <Header>{t(headerText)}</Header>}
        {!loading && !!data && !!data.data && (
          <ScrollContainer>
            {data.data.map(d => (
              <PortCallItem key={d.id} onClick={() => onChoose(d.master_id)} chosen={d.master_id === chosenId}>
                <ShipBadges badges={d.badges} />
                {d.from_port ? (
                  <ShipRoute
                    from={d.from_port ? d.from_port : t('Unknown port')}
                    to={d.to_port ? d.to_port : t('Unknown port')}
                    portArea={d.port_area_name}
                    next={d.next_port ? d.next_port : t('Unknown port')}
                  />
                ) : (
                  <Placeholder>{t('Trip details unknown')}</Placeholder>
                )}
                {d.next_event?.ts && d.next_event?.title ? (
                  <NextEvent>
                    <NextEventTitle>{t(d.next_event.title)}</NextEventTitle>
                    <NextEventTimestamp format={TIME_FORMAT} date={d.next_event.ts} />
                  </NextEvent>
                ) : (
                  <Placeholder>{t('ETA unknown')}</Placeholder>
                )}
                <NotificationsAndDecisions>
                  <DecisionOrNotification>
                    {t('Notifications')}
                    {d.notification_summary.unread > 0 && (
                      <RedBackground>{d.notification_summary.unread}</RedBackground>
                    )}
                    {d.notification_summary.unread === 0 && (
                      <GreyBackground>{d.notification_summary.total}</GreyBackground>
                    )}
                  </DecisionOrNotification>
                  <DecisionOrNotification>
                    {d.decision_summary.unanswered > 0
                      ? t('Open issues')
                      : d.decision_summary.total > 0
                        ? t('Issues')
                        : t('')}
                    {d.decision_summary.unanswered > 0 && (
                      <WarningYellow>{d.decision_summary.unanswered}</WarningYellow>
                    )}
                    {d.decision_summary.unanswered === 0 && <GreyBackground>{d.decision_summary.total}</GreyBackground>}
                  </DecisionOrNotification>
                </NotificationsAndDecisions>
              </PortCallItem>
            ))}
          </ScrollContainer>
        )}
      </MenuContainer>
      <MenuLayover onClick={() => !stayOpen && onClose()} />
    </Menu>
  );
};

export default NotificationModalMenu;
