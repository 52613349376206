import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import Spin from 'antd/es/spin';

const Container = styled.div`
  font-size: 13px;
  min-height: 60px;
`;

const Item = styled.div`
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const ItemContainer = styled.div`
  margin-top: 4px;
`;

const Row = styled.div`
  font-size: 12px;
`;

const PortcallHistory = ({ currentPortcall }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [dataFetched, setDataFetched] = useState(false);
  const [data, setData] = useState(undefined);

  const [loader, setLoader] = useState(false);

  const idRef = useRef();

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const response = await apiCall('get', 'fleet/manual-portcall-history/' + currentPortcall);

      if (response?.data?.results?.history) {
        setData(response?.data?.results?.history);
      } else {
        setData([]);
      }
      setLoader(false);
    };

    if (!dataFetched) {
      setDataFetched(true);
      getData();
    }

    if (idRef.current !== currentPortcall && dataFetched) {
      getData();
    }

    idRef.current = currentPortcall;
  }, [apiCall, currentPortcall, data, dataFetched]);

  const ItemContainerCreator = data => {
    return (
      <ItemContainer>
        {!!data.eta && (
          <Row>
            {t('ETA')}: <span style={{ fontWeight: 600 }}>{dayjs(data.eta).format('DD.MM.YYYY HH:mm')}</span>
          </Row>
        )}
        {!!data.etd && (
          <Row>
            {t('ETD')}: <span style={{ fontWeight: 600 }}>{dayjs(data.etd).format('DD.MM.YYYY HH:mm')}</span>
          </Row>
        )}
        {!!data.locode && (
          <Row>
            {t('Port')}: <span style={{ fontWeight: 600 }}>{data.data ? data.data.portName : '-'}</span>
          </Row>
        )}
        {data.data?.isCancelled && (
          <Row>
            <span style={{ fontWeight: 600 }}>{t('Cancelled')}</span>
          </Row>
        )}
      </ItemContainer>
    );
  };

  return (
    <Container id="portcall-history">
      <Spin style={{ width: '100%', marginTop: '20px' }} spinning={loader} size="medium">
        {!!data &&
          data.map((item, index) => {
            let added = false;
            let updated = false;
            let deleted = false;

            let title = '';

            if (item.data.added.constructor.name === 'Object') {
              added = true;
              title = t('Added');
            } else if (item.data.updated.constructor.name === 'Object') {
              updated = true;
              title = t('Updated');
            } else if (item.data.deleted.constructor.name === 'Object') {
              deleted = true;
              title = t('Deleted');
            }

            return (
              <Item key={index}>
                <Title>{title}</Title>
                {added && ItemContainerCreator(item.data.added)}
                {updated && ItemContainerCreator(item.data.updated)}
                {deleted && ItemContainerCreator(item.data.deleted)}
              </Item>
            );
          })}
      </Spin>
    </Container>
  );
};

export default PortcallHistory;
