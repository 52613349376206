import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LogoImage from '../../images/logo.svg?react';
import Footer from '../../images/footers/Footer-Introduction.svg?react';
import useLogin from '../../views/UseLogin';

const LeftInfoPanel = styled.div`
  width: 420px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  color: ${({ theme }) => theme.color.almost_black};
  overflow: hidden;
  z-index: 100;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
`;

const HeaderTexts = styled.div`
  margin-left: 18px;
`;

const HeaderRow1 = styled.div`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 4px;
`;

const HeaderRow2 = styled.div`
  color: ${({ theme }) => theme.color.middle_grey};
  letter-spacing: 2px;
`;

const MiddleContainer = styled.div`
  margin: 40px 26px;
`;

const MiddleHeader = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
`;

const MiddleHeaderText = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
`;

const MiddleHeaderButton = styled.div`
  background-color: ${({ theme }) => theme.color.deep_blue};
  border-radius: 15px;
  color: white;
  padding: 0px 18px;
  cursor: pointer;
  height: 22px;
  text-align: center;

  &:hover {
    background-color: #729bc0;
  }
`;

const MiddleText = styled.div`
  margin-top: 12px;
`;

const BottomContainer = styled.div`
  margin: 50px 26px;
`;

const BottomText = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const BottomButton = styled.div`
  background-color: ${({ theme }) => theme.color.deep_blue};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 0px;
  text-align: center;
  cursor: pointer;
  height: 36px;
  text-transform: uppercase;
  margin-top: 24px;
  line-height: 26px;

  &:hover {
    background-color: #729bc0;
  }
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 48px;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const LandingPageLeftInfoPanel = ({ handleClick, openSignUp }) => {
  const { namespace } = useLogin();
  const { i18n } = useTranslation(namespace);
  const t = i18n.getFixedT(i18n.language, namespace);

  return (
    <LeftInfoPanel>
      <Header>
        <LogoImage style={{ fill: '#0F67CC', width: '42px' }} />
        <HeaderTexts>
          <HeaderRow1>PORT ACTIVITY</HeaderRow1>
          <HeaderRow2>FINLAND</HeaderRow2>
        </HeaderTexts>
      </Header>
      <MiddleContainer>
        <MiddleHeader>
          <MiddleHeaderText>{t('What is port activity?')}</MiddleHeaderText>
          <MiddleHeaderButton onClick={handleClick}>{t('Read more')}</MiddleHeaderButton>
        </MiddleHeader>
        <MiddleText>
          {t(
            'Port Activity service provides a new toolset for ports to improve the overall efficiency of port flow, covering the end-to-end chain of actions and operations related to vessel visit. It is a user interface for port digitalization and communication based on open ecosystem and open interfaces, strengthening collaboration both inside the port community and between ports.'
          )}
        </MiddleText>
      </MiddleContainer>
      <MiddleContainer>
        <MiddleHeader>
          <MiddleHeaderText>{t('Who can join?')}</MiddleHeaderText>
          <MiddleHeaderButton onClick={handleClick}>{t('Read more')}</MiddleHeaderButton>
        </MiddleHeader>
        <MiddleText>
          {t(
            'Ports in Finland can join the SaaS solution free of charge. The service has already expanded to most ports in Finland. The aim is to encourage other countries around the Baltic Sea to start using the app.'
          )}
        </MiddleText>
      </MiddleContainer>
      <BottomContainer>
        <BottomText>{t("Don't have an account?")}</BottomText> {/* eslint-disable-line quotes */}
        <BottomButton onClick={openSignUp}>{t('Sign up for port activity')}</BottomButton>
      </BottomContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LeftInfoPanel>
  );
};

export default LandingPageLeftInfoPanel;
