import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import PricingSummary from './PricingSummary';
import PricingTableMatrix from './PricingTableMatrix';
import VesselNetTonnageSelector from './VesselNetTonnageSelector';
import Categories from './Categories';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 676px;
  .ant-tabs-tab {
    text-shadow: 0 0 0.25px currentColor;
    text-transform: initial;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const FullViewButton = styled.div`
  display: flex;
  gap: 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary};
  color: #4a4a4a;
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.color.secondary};
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.sizing.gap_huge};
    margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  }
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -31px;

  @media (max-width: 650px) {
    margin-top: 0px;
  }
`;

const PricingTableMatrixContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummaryContainer = styled.div`
  padding: 15px;
`;

const CatagoriesContainer = styled.div`
  margin: 0 0 0 132px;

  @media (max-width: 650px) {
    margin: 0 0 0 0px;
  }
`;

const PricingTabbedView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [currentTab, setCurrentTab] = useState('1');

  const tabItems = [
    {
      label: t('Summary'), // eslint-disable-line quotes
      children: (
        <>
          <CatagoriesContainer>
            <Categories />
          </CatagoriesContainer>
          <PricingSummary />
        </>
      ),
      key: '1',
    },
    {
      label: t('Pricing table matrix'), // eslint-disable-line quotes
      children: (
        <>
          <CatagoriesContainer>
            <Categories />
          </CatagoriesContainer>
          <PricingTableMatrixContainer>
            <SelectorContainer>
              <VesselNetTonnageSelector />
            </SelectorContainer>
            <PricingTableMatrix />
          </PricingTableMatrixContainer>
        </>
      ),
      key: '2',
    },
  ];

  return (
    <Container>
      <Header>
        {t('Mooring and unmooring fee for different vessels type')}
        {currentTab == 2 && (
          <Link to="/invoicing/matrix">
            <FullViewButton>
              <Icon type={'expand'} />
              {t('See full view')}
            </FullViewButton>
          </Link>
        )}
      </Header>
      <SummaryContainer>
        <Tabs
          style={{ marginLeft: '10px' }}
          activeKey={currentTab}
          onTabClick={key => setCurrentTab(key)}
          size="small"
          items={tabItems}
        />
      </SummaryContainer>
    </Container>
  );
};

export default PricingTabbedView;
