import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import MapPocContainer from '../components/map-poc/MapPocContainer';
import { PublicProvider } from '../context/PublicContext';

const MapPocView = () => {
  const { namespace, mapDefaultCoordinates, mapDefaultZoom } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Map PoC | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Map PoC')}>
      <PageWithoutHeaderAndMargins fullWidth>
        <PublicProvider>
          <MapPocContainer coordinatesString={mapDefaultCoordinates} zoom={mapDefaultZoom} />
        </PublicProvider>
      </PageWithoutHeaderAndMargins>
    </Layout>
  );
};

export default MapPocView;
