import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../ui/Icon';

import { formatSender } from '../../utils/utils';
import DateComponent from '../ui/DateComponent';

const { confirm } = Modal;

const DeleteButton = styled.span`
  display: none;
`;

const DeleteButtonIcon = styled.span`
  cursor: pointer;
`;

const StyledChild = styled.div`
  margin-left: ${({ theme }) => theme.sizing.gap_medium};
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  &:hover {
    ${DeleteButton} {
      display: inline;
    }
  }
`;

const Time = styled(DateComponent)`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-size: 0.8571rem;
`;

const Title = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
`;

const SentBy = styled.span`
  color: ${({ theme }) => theme.color.grey};
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-size: 0.8571rem;
`;

const NotificationChild = ({ data }) => {
  const { created_at, message, sender, id } = data;
  const { apiCall, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const handleDelete = async id => {
    await apiCall('delete', 'notifications', { id: id });
  };

  const showDeleteConfirm = ({ message, id }) => {
    confirm({
      title: 'Delete notification?',
      content: message,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  return (
    <StyledChild>
      <div>
        <Time format={TIME_FORMAT} date={created_at} />
      </div>
      <span>
        <Title>{message}</Title>
        <SentBy>{t('by {{sender}}', { sender: formatSender(sender) })}</SentBy>
        {user.role === 'admin' && (
          <DeleteButton>
            <DeleteButtonIcon onClick={() => showDeleteConfirm({ message, id })}>
              <Icon type="trash" />
            </DeleteButtonIcon>
          </DeleteButton>
        )}
      </span>
    </StyledChild>
  );
};

export default NotificationChild;
