import React, { useContext } from 'react';

import { UserContext } from '../context/UserContext';
import styled from 'styled-components';

import { PublicProvider } from '../context/PublicContext';
import LandingPageMapContainer from '../components/map/LandingPageMapContainer';

import LandingHeader from '../components/LandingHeader';
import LandingFooter from '../components/LandingFooter';

import LandingPageContainer from '../components/landingPage/LandingPageContainer';

const Content = styled.div`
  position: relative;
  flex: 1 0 auto;
  background-color: ${({ theme }) => theme.color.primary};
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const LandingPage = () => {
  const { mapDefaultCoordinates, mapDefaultZoom } = useContext(UserContext);
  const isSwedishVersion =
    window.location.host.includes('portactivity.se') || window.location.host.includes('se.ft-testing.dev');

  return !isSwedishVersion ? (
    <LandingPageContainer />
  ) : (
    <Layout>
      <LandingHeader isSwedishVersion={isSwedishVersion} />
      <Content>
        <PublicProvider>
          <LandingPageMapContainer coordinatesString={mapDefaultCoordinates} zoom={mapDefaultZoom} />
        </PublicProvider>
      </Content>
      <LandingFooter isSwedishVersion={isSwedishVersion} />
    </Layout>
  );
};

export default LandingPage;
