import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';
import { ServiceOrderContext } from '../../../context/ServiceOrderContext';
import ButtonLight from '../../ui/ButtonLight';
import { TIME_FORMAT } from '../../../utils/constants';
import DateComponent from '../../ui/DateComponent';

const PhoneCall = styled.div`
  height: 80px;
  padding-top: 6px;
  flex-direction: row;
  display: flex;
`;

const Column = styled.div`
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.text.normal};
`;

const Timestamp = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  letter-spacing: 0.025em;
  font-style: italic;
`;

const ServiceOrderTaskListPhoneCall = ({ record, setSentSuccess }) => {
  const { modules, namespace, apiCall } = useContext(UserContext);
  const { getServiceOrders } = useContext(ServiceOrderContext);
  const { t } = useTranslation(namespace);

  const [phoneCallBeforeMinutes, setPhoneCallBeforeMinutes] = useState(
    record?.orderlineData?.phone_call_before_minutes !== undefined
      ? record?.orderlineData?.phone_call_before_minutes
      : record?.service_phone_call_before_minutes
  );

  const acked = record.toBeDelivered;
  const performCall =
    record?.service_phone_call_confirmation && !record?.orderlineData?.disable_phone_call_confirmation;
  const ignoreAck = record?.service_phone_call_ignore_ack || record?.orderlineData?.phone_call_ignore_ack;
  const currentPhoneCallConfirmation = (!acked && performCall) || (acked && performCall && ignoreAck);

  const [phoneCallConfirmation, setPhoneCallConfirmation] = useState(currentPhoneCallConfirmation);

  const sendData = async data => {
    const result = await apiCall('put', 'service-orders/service-order-line/set-phone-call-time', data);

    if (result?.status === 200) {
      setSentSuccess(
        record.data.order_lines[0].service_provider_name + ' ' + record.data.order_lines[0].service_type_name
      );
      getServiceOrders();
    }
  };

  const setPhoneCallConfiguration = () => {
    // If phoneCallBeforeMinutes is empty, revert to service's default value
    sendData({
      data: {
        id: record.data.order_lines[0].id,
        type: 'order-line',
        ...(phoneCallBeforeMinutes !== '' ? { phone_call_before_minutes: parseInt(phoneCallBeforeMinutes, 10) } : {}),
        disable_phone_call_confirmation: !phoneCallConfirmation,
        ...(acked ? { phone_call_ignore_ack: true } : {}),
      },
    });
    if (phoneCallBeforeMinutes === '' || phoneCallBeforeMinutes === undefined) {
      setPhoneCallBeforeMinutes(record?.service_phone_call_before_minutes);
    }
  };

  const dataIsValid = () => {
    return (
      modules.phone_call_module === 'enabled' &&
      record?.service_phone_call_confirmation &&
      (parseInt(phoneCallBeforeMinutes, 10) !==
        parseInt(
          record?.orderlineData?.phone_call_before_minutes !== undefined
            ? record?.orderlineData?.phone_call_before_minutes
            : record?.service_phone_call_before_minutes,
          10
        ) ||
        phoneCallConfirmation !== currentPhoneCallConfirmation)
    );
  };

  const ConfirmationTooltip = () => {
    if (!(modules.phone_call_module === 'enabled' && record?.service_phone_call_confirmation)) {
      return <Title>{t('Phone call not enabled')}</Title>;
    }

    if (record?.orderlineData?.phone_call_updated_by_id) {
      let updated_by = record.orderlineData.phone_call_updated_by_id;
      if (record.orderlineData.phone_call_updated_by_name && record.orderlineData.phone_call_updated_by_email) {
        updated_by = `${record.orderlineData.phone_call_updated_by_name} (${record.orderlineData.phone_call_updated_by_email})`;
      } else if (record.orderlineData.phone_call_updated_by_name) {
        updated_by = record.orderlineData.phone_call_updated_by_name;
      } else if (record.orderlineData.phone_call_updated_email) {
        updated_by = record.orderlineData.phone_call_updated_email;
      }
      return (
        <>
          <Title>
            {t('Click to {{action}} phone call reminder for this order line', {
              action: record.orderlineData.disable_phone_call_confirmation ? t('enable') : t('disable'),
            })}
          </Title>
          <Timestamp>
            {updated_by} <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
          </Timestamp>
        </>
      );
    }

    return (
      <Title>
        {t('Click to {{action}} phone call reminder for this order line', {
          action: record.orderlineData.disable_phone_call_confirmation ? t('enable') : t('disable'),
        })}
      </Title>
    );
  };

  const TimeTooltip = () => {
    if (!(modules.phone_call_module === 'enabled' && record?.service_phone_call_confirmation)) {
      return <Title>{t('Phone call not enabled')}</Title>;
    }

    if (record?.orderlineData?.phone_call_updated_by_id) {
      let updated_by = record.orderlineData.phone_call_updated_by_id;
      if (record.orderlineData.phone_call_updated_by_name && record.orderlineData.phone_call_updated_by_email) {
        updated_by = `${record.orderlineData.phone_call_updated_by_name} (${record.orderlineData.phone_call_updated_by_email})`;
      } else if (record.orderlineData.phone_call_updated_by_name) {
        updated_by = record.orderlineData.phone_call_updated_by_name;
      } else if (record.orderlineData.phone_call_updated_email) {
        updated_by = record.orderlineData.phone_call_updated_email;
      }
      return (
        <>
          <Title>{t('Adjust the time of phone call reminder (minutes before requested delivery).')}</Title>
          <Title>
            {t(
              "Set empty to use service's default value" // eslint-disable-line quotes
            )}
          </Title>
          <Timestamp>
            {updated_by} <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
          </Timestamp>
        </>
      );
    }

    return (
      <>
        <Title>{t('Adjust the time of phone call reminder (minutes before requested delivery).')}</Title>
        <Title>
          {t(
            "Set empty to use service's default value" // eslint-disable-line quotes
          )}
        </Title>
      </>
    );
  };

  return (
    <PhoneCall>
      <Column>
        <Row>
          <Tooltip placement="top" title={<ConfirmationTooltip />}>
            <Switch
              disabled={!(modules.phone_call_module === 'enabled' && record?.service_phone_call_confirmation)}
              checked={phoneCallConfirmation}
              defaultChecked={phoneCallConfirmation}
              onChange={checked => setPhoneCallConfirmation(checked)}
            ></Switch>
          </Tooltip>
        </Row>
      </Column>
      <Column style={{ width: '90px', marginLeft: '6px' }}>
        <Tooltip placement="top" title={<TimeTooltip />}>
          <Row>
            <Input
              disabled={!(modules.phone_call_module === 'enabled' && record?.service_phone_call_confirmation)}
              value={phoneCallBeforeMinutes}
              defaultValue={record?.service_phone_call_before_minutes}
              onChange={e => {
                if (isNaN(e.target.value)) {
                  return;
                }
                setPhoneCallBeforeMinutes(previous => {
                  if (previous !== e?.target?.value) {
                    // Enable phone call if time is changed
                    setPhoneCallConfirmation(true);
                  }
                  return e?.target?.value;
                });
              }}
            />
          </Row>
          <Row style={{ marginTop: '6px' }}>
            <ButtonLight type="button" onClick={setPhoneCallConfiguration} disabled={!dataIsValid()}>
              {t('Set time')}
            </ButtonLight>
          </Row>
        </Tooltip>
      </Column>
    </PhoneCall>
  );
};

export default ServiceOrderTaskListPhoneCall;
