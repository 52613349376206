import React, { useContext } from 'react';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import FleetViewTopPanel from './FleetViewTopPanel';
import FleetViewCalendar from './FleetViewCalendar';

import { FleetViewContext } from '../../context/FleetViewContext';

const CalendarContainer = styled.div`
  height: calc(100% - 49px);
  min-width: 790px;
`;

const SpinContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;

const FleetViewCalendarContainer = () => {
  const { sidePanelWidth, timelineUnit, zoomChecked } = useContext(FleetViewContext);

  return timelineUnit && zoomChecked ? (
    <CalendarContainer style={{ width: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <FleetViewTopPanel />
      <FleetViewCalendar />
    </CalendarContainer>
  ) : (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  );
};

export default FleetViewCalendarContainer;
