import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MapContainer as Map } from 'react-leaflet';
import { UserContext } from '../../context/UserContext';

import BerthPlanningToolMapInnerContainer from './BerthPlanningToolMapInnerContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  .leaflet-control-container {
    right: 50px;
  }
  .leaflet-control-attribution.leaflet-control {
    display: ${props => (props.showAttribution ? 'inline' : 'none')};
  }
  .leaflet-container {
    width: 100%;
    height: 100%;
    align-content: auto;
  }
`;

const BerthPlanningToolMap = ({ showAttribution, showResize = false, showCraneOptions, craneView }) => {
  const { mapDefaultCoordinates } = useContext(UserContext);
  const defCoordinates = mapDefaultCoordinates.split(',');

  const [appliedZoom, setAppliedZoom] = useState(12);

  return (
    <MapContainer showAttribution={showAttribution}>
      <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small')} onError={logError}>
        <Map center={defCoordinates} tap={false} zoom={appliedZoom} zoomControl={false}>
          <BerthPlanningToolMapInnerContainer
            zoom={appliedZoom}
            setZoom={setAppliedZoom}
            showResize={showResize}
            showCraneOptions={showCraneOptions}
            craneView={craneView}
          />
        </Map>
      </ErrorBoundary>
    </MapContainer>
  );
};

export default BerthPlanningToolMap;
