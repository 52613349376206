import React, { useContext } from 'react';
import styled from 'styled-components';
import Select from 'antd/es/select';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

const SelectAll = styled.div`
  color: ${({ theme }) => theme.color.secondary};
`;

const FleetFilterSelect = ({ data, onChange, selectedValues, placeholder, showSelectAll = false }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const handleSelectAll = value => {
    if (value?.length && value.some(item => item.value === 'selectAll')) {
      return;
    }
    onChange(value);
  };

  return (
    <Select
      mode="multiple"
      placeholder={t(placeholder)}
      onChange={handleSelectAll}
      style={{ width: '100%' }}
      value={selectedValues || []}
      labelInValue={true}
      dropdownStyle={{ minWidth: '300px' }}
      maxTagCount={6}
      maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} ...`}
      filterOption={(input, option) => {
        if (option.value === 'selectAll') {
          return;
        }
        return (
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      options={[
        ...(showSelectAll
          ? [
              {
                value: 'selectAll',
                label:
                  selectedValues.length > 0 ? (
                    <SelectAll onClick={() => onChange([])}>{t('Unselect all')}</SelectAll>
                  ) : (
                    <SelectAll onClick={() => onChange(data)}>{t('Select all')}</SelectAll>
                  ),
              },
            ]
          : []),
        ...data,
      ]}
    />
  );
};

export default FleetFilterSelect;
