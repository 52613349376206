import React, { useContext, useRef, useEffect, useCallback, useState, useMemo } from 'react';
import Spin from 'antd/es/spin';
import { throttle } from 'throttle-debounce';

import Card from './Card';
import { TimestampContext } from '../../context/TimestampContext';
import { UserContext } from '../../context/UserContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { ServiceOrderContext } from '../../context/ServiceOrderContext';

const StyledCards = styled.div`
  display: grid;
  grid-column: 1;
  grid-row: 1;
  grid-template-columns: repeat(${({ totalVessels }) => totalVessels}, 300px);
  grid-column-gap: 24px;
  grid-auto-flow: row;
  position: relative;
  padding: ${({ theme }) => theme.sizing.gap} ${({ theme }) => theme.sizing.gap_big};
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    height: 16px;
    z-index: 5;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
    background-blend-mode: darken;
  }
  display: -ms-grid;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-columns: 250px 300px[${({ totalVessels }) => totalVessels}]);
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const Cards = props => {
  const { namespace, user, useUserSocket, modules } = useContext(UserContext);
  const { timestamps, totalVessels, loader } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);

  const { socketUpdate } = useContext(ServiceOrderContext);

  const [portcallsIds, setPortcallIds] = useState([]);
  const [jitDataRetrieved, setJitDataRetrieved] = useState(false);

  let masterIdListWithCommas = '';

  if (modules.queue_module === 'enabled' && user.permissions.includes('manage_queue_slot_reservation')) {
    timestamps?.forEach(portCall => (masterIdListWithCommas += portCall.ship.master_id + ','));
  }

  const { data: decisionData, error: decisionError, fetchData: fetchDecisions } = useApi(
    'get',
    'port-call-decisions-summary'
  );

  const { data: notificationData, error: notificationError, fetchData: fetchNotifications } = useApi(
    'get',
    'port-call-notifications-summary'
  );

  const { data: serviceOrderData, error: serviceOrderError, fetchData: fetchServiceOrders } = useApi(
    'post',
    'service-orders/service-order/search',
    { query: { text: portcallsIds.join('|') } },
    null,
    false
  );

  const { data: jitData, error: jitError, fetchData: fetchJitData } = useApi(
    'get',
    'jit-eta-vessels',
    { port_call_master_ids: masterIdListWithCommas.slice(0, -1) },
    null,
    modules.queue_module === 'enabled' &&
      user.permissions.includes('manage_queue_slot_reservation') &&
      masterIdListWithCommas.length > 0
  );

  useEffect(() => {
    setPortcallIds(previous => {
      const newPortcallIds = timestamps?.map?.(portcall => portcall.ship?.master_id) || [];
      if (newPortcallIds.length === previous.length && newPortcallIds.every(id => previous.includes(id))) {
        return previous;
      }
      return newPortcallIds;
    });
  }, [timestamps]);

  const reFetchJIT = useMemo(
    () =>
      throttle(20000, false, () => {
        // spread out request per client
        setTimeout(() => {
          if (
            modules.queue_module === 'enabled' &&
            user.permissions.includes('manage_queue_slot_reservation') &&
            masterIdListWithCommas.length > 0
          ) {
            fetchJitData(false, { port_call_master_ids: masterIdListWithCommas.slice(0, -1) });
          }
        }, Math.floor(Math.random() * 5000));
      }),
    [fetchJitData, masterIdListWithCommas, modules.queue_module, user.permissions]
  );

  useUserSocket('portcalls-changed', reFetchJIT);

  const getServiceOrders = useCallback(() => {
    if (
      modules.service_order_module === 'enabled' &&
      user.permissions.includes('view_service_order') &&
      portcallsIds.length
    ) {
      fetchServiceOrders(false, { query: { text: portcallsIds.join('|') } });
    }
  }, [fetchServiceOrders, modules.service_order_module, portcallsIds, user.permissions]);

  useEffect(() => {
    getServiceOrders();
  }, [getServiceOrders, portcallsIds]);

  useEffect(() => {
    if (
      !jitDataRetrieved &&
      modules.queue_module === 'enabled' &&
      user.permissions.includes('manage_queue_slot_reservation') &&
      masterIdListWithCommas.length > 0
    ) {
      setJitDataRetrieved(true);
      fetchJitData(false, { port_call_master_ids: masterIdListWithCommas.slice(0, -1) });
    }
  }, [fetchJitData, jitDataRetrieved, masterIdListWithCommas, modules.queue_module, user.permissions]);

  const getDecisions = useCallback(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  const getNotifications = useCallback(() => {
    const masterIds = timestamps?.map(portCall => portCall.ship.master_id);
    if (masterIds.length > 0) {
      fetchNotifications(false, { port_call_master_ids: masterIds });
    }
  }, [fetchNotifications, timestamps]);

  useUserSocket('port-call-decision-changed', getDecisions);
  useUserSocket('notifications-summary-common-changed', getNotifications);
  useUserSocket(`notifications-summary-changed-${user.id}`, getNotifications);

  const socketUpdateRef = useRef(socketUpdate);
  useEffect(() => {
    if (socketUpdate !== socketUpdateRef.current) {
      getServiceOrders();
    }
    socketUpdateRef.current = socketUpdate;
  }, [getServiceOrders, socketUpdate]);

  let notifications = notificationError ? [] : notificationData;
  let decisions = decisionError ? [] : decisionData;
  const serviceOrders = serviceOrderError || !serviceOrderData ? [] : serviceOrderData.results['service-orders'];
  const jitList = jitError ? [] : jitData;

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const reloadServiceOrderData = () => {
    getServiceOrders();
  };

  let noVessels = false;
  if (!loader && timestamps && timestamps.length === 0) {
    noVessels = true;
  }

  return (
    <StyledCards totalVessels={totalVessels} {...props} x-ms-format-detection="none">
      {loader && <Spin spinning={loader} size="large" />}
      {!loader &&
        !noVessels &&
        timestamps.map(({ ship, portcalls }) => {
          let newNotifications = null;
          let masterId = ship?.master_id;
          if (notifications && masterId && notifications[masterId]) {
            newNotifications = notifications[masterId];
          }
          let newDecisions = null;
          if (decisions && masterId && decisions[masterId]) {
            newDecisions = decisions[masterId];
          }
          let transitions = null;
          if (ship?.berth_transitions && ship.berth_transitions.length) {
            transitions = ship.berth_transitions;
          }
          let newServiceOrders = null;
          if (serviceOrders) {
            newServiceOrders = serviceOrders.filter(so => so.port_call_id === ship.master_id);
          }
          let jitForVessel = null;
          if (jitList && ship) {
            jitForVessel = jitList[ship.master_id];
          }

          return (
            <Card
              totalVessels={totalVessels}
              notifications={newNotifications || ship.notification_summary}
              decisions={newDecisions || ship.decision_summary}
              serviceOrders={newServiceOrders}
              jitForVessel={jitForVessel}
              key={ship.id}
              data={ship}
              cardOpen={!!localStorage.getItem(`vessel-notification-${ship.imo}`)}
              transitions={transitions}
              reloadServiceOrderData={reloadServiceOrderData}
              hasWarnings={portcalls[0]?.has_warnings}
            />
          );
        })}
      {noVessels && (
        <TextContainer>
          <Text>{t('There are no vessels to be shown.')}</Text>
        </TextContainer>
      )}
    </StyledCards>
  );
};

export default Cards;
