import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import Incident from '../components/incidents/Incident';

const IncidentPage = styled(Page)`
  background-color: #ffffff;
`;

const IncidentView = props => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { id } = props.match.params;

  useEffect(() => {
    document.title = 'Incident and event | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Incident and event')}>
      <IncidentPage fullWidth>
        <Incident id={id} />
      </IncidentPage>
    </Layout>
  );
};

export default IncidentView;
