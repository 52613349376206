import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';
import LinkToThisHeaderButton from './LinkToThisHeaderButton';
import EditShortTextButton from './EditShortTextButton';
import { useEffect } from 'react';

const StyledH1 = styled.h1`
  font-size: 22px;
  margin: 16px 0 8px 0;
  font-weight: 600;
  display: inline-flex;

  ${props => {
    if (props.linked) {
      return `
        background-color: #f0f0f0;
        margin-left: -24px;
        padding: 4px 6px 4px 24px;
        border-radius: 0px 3px 3px 0px;
      `;
    }
  }}
`;

const StyledH2 = styled.h2`
  font-size: 20px;
  margin: 20px 0 8px 0;
  display: inline-flex;

  ${props => {
    if (props.linked) {
      return `
        background-color: #f0f0f0;
        margin-left: -24px;
        padding: 4px 6px 4px 24px;
        border-radius: 0px 3px 3px 0px;
      `;
    }
  }}
`;

const StyledH3 = styled.h3`
  font-size: 16px;
  margin: 20px 0 0 0;
  display: inline-flex;

  ${props => {
    if (props.linked) {
      return `
        background-color: #f0f0f0;
        margin-left: -24px;
        padding: 4px 6px 4px 24px;
        border-radius: 0px 3px 3px 0px;
      `;
    }
  }}
`;

const StyledH4 = styled.h4`
  font-size: 14px;
  display: flex;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 3px;
  margin-top: 24px;
`;

const StyledH6 = styled.h6`
  font-size: 12px;
  margin: 6px 0 0 0;
  display: flex;
  font-weight: 600;
  color: #949494;
`;

const StyledP = styled.p`
  margin: 8px 0 4px 0;
`;

const StyledA = styled.a`
  text-decoration: underline;
`;

const StyledImg = styled.img`
  margin: 8px 0;
`;

const StyledBlockQuote = styled.blockquote`
  border-left: 8px solid #e8e8e8;
  padding: 6px 0;
  padding-left: 18px;
`;

const StyledTable = styled.table`
  background: white;
`;

const StyledTh = styled.th`
  border: 1px solid #d0d0d0;
  padding: 6px 13px;
`;

const StyledTr = styled.tr`
  border-top: 1px solid #d0d0d0;
`;

const StyledTd = styled.td`
  border: 1px solid #d0d0d0;
  padding: 6px 13px;
`;

const StyledTBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #f4f4f4;
  }
`;

const StyledPre = styled.pre`
  background-color: #f4f4f4;
  padding: 12px;
  border-radius: 3px;

  code {
    padding: 0px;
  }
`;

const StyledCode = styled.code`
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
`;

const MarkDownHandler = ({
  markDownData,
  markdownAnchor,
  editable = true,
  noAnchors,
  setAnchorTitle,
  objectType,
  objectId,
  coordinates,
  shortText,
  markdownId,
  setEditTitle,
}) => {
  const { user } = useContext(UserContext);

  const [scrolledTo, setScrolledTo] = useState(false);

  const headerRef = useRef();
  const objectIdRef = useRef();
  const refKey = 'scrollRef-';

  const intervalRef = useRef();

  useEffect(() => {
    if (objectId && objectId !== objectIdRef.current) {
      setScrolledTo(false);
      intervalRef.current = null;
    }
    objectIdRef.current = objectId;
  }, [objectId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (!scrolledTo && markDownData) {
      setScrolledTo(true);
      if (!intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          if (headerRef.current) {
            headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            clearInterval(intervalRef.current);
          }
        }, 500);
      }
    }
  }, [markDownData, scrolledTo]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: props => {
          const id = refKey + props.children[0].toLowerCase().replace(/ /g, '-');
          return (
            <div>
              <StyledH1
                id={id}
                ref={refKey + markdownAnchor === id ? headerRef : undefined}
                linked={refKey + markdownAnchor === id}
              >
                {props.children[0]}
                {editable && user?.permissions.includes('manage_wiki') && !noAnchors && (
                  <LinkToThisHeaderButton
                    anchored={refKey + markdownAnchor === id}
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setAnchorTitle={setAnchorTitle}
                  />
                )}
                {user?.permissions.includes('manage_wiki') && refKey + markdownAnchor === id && (
                  <EditShortTextButton
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setEditTitle={setEditTitle}
                  />
                )}
              </StyledH1>
            </div>
          );
        },
        h2: props => {
          const id = refKey + props.children[0].toLowerCase().replace(/ /g, '-');
          return (
            <div>
              <StyledH2
                id={id}
                ref={refKey + markdownAnchor === id ? headerRef : undefined}
                linked={refKey + markdownAnchor === id}
              >
                {props.children[0]}
                {editable && user?.permissions.includes('manage_wiki') && !noAnchors && (
                  <LinkToThisHeaderButton
                    anchored={refKey + markdownAnchor === id}
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setAnchorTitle={setAnchorTitle}
                  />
                )}
                {user?.permissions.includes('manage_wiki') && refKey + markdownAnchor === id && (
                  <EditShortTextButton
                    anchored={refKey + markdownAnchor === id}
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setEditTitle={setEditTitle}
                  />
                )}
              </StyledH2>
            </div>
          );
        },
        h3: props => {
          const id = refKey + props.children[0].toLowerCase().replace(/ /g, '-');
          return (
            <div>
              <StyledH3
                id={id}
                ref={refKey + markdownAnchor === id ? headerRef : undefined}
                linked={refKey + markdownAnchor === id}
              >
                {props.children[0]}
                {editable && user?.permissions.includes('manage_wiki') && !noAnchors && (
                  <LinkToThisHeaderButton
                    anchored={refKey + markdownAnchor === id}
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setAnchorTitle={setAnchorTitle}
                  />
                )}
                {user?.permissions.includes('manage_wiki') && refKey + markdownAnchor === id && (
                  <EditShortTextButton
                    anchored={refKey + markdownAnchor === id}
                    data={{
                      titleKey: props.children[0].toLowerCase().replace(/ /g, '-'),
                      title: props.children[0],
                      objectType,
                      objectId,
                      coordinates,
                      shortText,
                      markdownId,
                      anchored: refKey + markdownAnchor === id,
                    }}
                    setEditTitle={setEditTitle}
                  />
                )}
              </StyledH3>
            </div>
          );
        },
        h4: props => {
          return <StyledH4>{props.children}</StyledH4>;
        },
        h6: props => {
          return <StyledH6>{props.children}</StyledH6>;
        },
        p: props => {
          return <StyledP>{props.children}</StyledP>;
        },
        a: props => {
          if (props.href.substring(0, 1) === '#') {
            const href = props.href.substring(0, 1) + refKey + props.href.substring(1, props.href.length);
            return <StyledA href={href}>{props.children}</StyledA>;
          } else if (props.href.match(/^\//)) {
            return <Link to={props.href}>{props.children}</Link>;
          }
          return (
            <StyledA href={props.href} target="_blank" rel="nofollow noreferrer noopener">
              {props.children}
            </StyledA>
          );
        },
        img: props => {
          return (
            <StyledImg src={props.src} alt={props.alt}>
              {props.children}
            </StyledImg>
          );
        },
        blockquote: props => {
          return <StyledBlockQuote>{props.children}</StyledBlockQuote>;
        },
        table: props => {
          return <StyledTable>{props.children}</StyledTable>;
        },
        th: props => {
          return <StyledTh>{props.children}</StyledTh>;
        },
        td: props => {
          return <StyledTd>{props.children}</StyledTd>;
        },
        tr: props => {
          return <StyledTr>{props.children}</StyledTr>;
        },
        tbody: props => {
          return <StyledTBody>{props.children}</StyledTBody>;
        },
        pre: props => {
          return <StyledPre>{props.children}</StyledPre>;
        },
        code: props => {
          return <StyledCode>{props.children}</StyledCode>;
        },
      }}
    >
      {markDownData}
    </ReactMarkdown>
  );
};

export default MarkDownHandler;
