import React from 'react';
import MovableModalContainer from './MovableModalContainer';

const MovableModal = ({ children, open, ...props }) => {
  return (
    open && (
      <MovableModalContainer open={open} {...props}>
        {children}
      </MovableModalContainer>
    )
  );
};

export default MovableModal;
