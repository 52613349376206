import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { WeatherContext } from '../../context/WeatherContext';
import Icon from '../../components/ui/Icon';

const Container = styled.div`
  display: inline;
  position: relative;
`;

const NameRow = styled.div`
  text-decoration: none;
  display: flex;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #86b9f0;
  }

  svg {
    fill: #4990dd !important;
  }
`;

const Name = styled.div`
  font-size: 12px;
`;

const List = styled.div`
  background-color: white;
  position: absolute;
  padding: 3px 0;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 80;
  width: 220px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const Station = styled.div`
  padding: 6px 12px;
  cursor: pointer;
  color: #4a4a4a;
  font-weight: ${props => (props.chosen ? 700 : 400)};
  background-color: ${props => (props.chosen ? '#f0fbff' : 'white')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: ${props => (props.chosen ? '#f0fbff' : '#f2f2f2')};
  }
`;

const IconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const WeatherStationDropdown = () => {
  const { chosenStation, stationList, chooseStation } = useContext(WeatherContext);

  const [open, setOpen] = useState(false);

  const closePopover = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onStationClick = station => {
    chooseStation(station);
  };

  return (
    <>
      <Container onClick={toggleOpen}>
        <NameRow>
          <Name>{chosenStation ? chosenStation.name : '-'}</Name>
          {!open ? (
            <IconContainer>
              <Icon type="chevron-down" />
            </IconContainer>
          ) : (
            <IconContainer>
              <Icon type="chevron-up" />
            </IconContainer>
          )}
        </NameRow>

        {open && (
          <div>
            <Blocker onClick={closePopover} />
            <List>
              {stationList.map(s => (
                <Station
                  key={s.uid}
                  onClick={() => onStationClick(s)}
                  chosen={chosenStation ? s.uid === chosenStation.uid : false}
                >
                  {s.name}
                </Station>
              ))}
            </List>
          </div>
        )}
      </Container>
    </>
  );
};

export default WeatherStationDropdown;
