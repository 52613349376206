import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MapContainer as Map } from 'react-leaflet';
import L from 'leaflet';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import Button from '../ui/Button';
import IncidentsModalMapInnerContainerPreview from './IncidentsModalMapInnerContainerPreview';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 24px;
  display: inline-grid;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 3px;
    margin-left: -1px;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  padding: 3px;
`;

const Title = styled.div`
  white-space: nowrap;
  font-size: 13px;
`;

const IncidentHistoryItemMap = ({ geojson, level, item, type }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [appliedZoom, setAppliedZoom] = useState(9);

  const handleZoomButton = selectedZoom => {
    setAppliedZoom(selectedZoom);
  };

  // Make sure geojson indexes start from 0
  const newGeoJson = Object.values(geojson).filter(val => val);
  if (!newGeoJson[0]?.geometry.type) {
    return <Title style={{ marginTop: '6px' }}>{t('Map coordinates ' + item)}.</Title>;
  }

  const zoomPrevDiv = L.DomUtil.get('zoompreventer');
  if (zoomPrevDiv) {
    L.DomEvent.disableClickPropagation(zoomPrevDiv);
  }

  return (
    <MapContainer>
      <Title>{t('Map coordinates')}:</Title>
      <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small', t)} onError={logError}>
        <Map
          style={{
            height: '260px',
            width: '100%',
            marginTop: '6px',
          }}
          center={
            newGeoJson[0]?.geometry.type === 'Point'
              ? [newGeoJson[0]?.geometry.coordinates[1], newGeoJson[0]?.geometry.coordinates[0]]
              : [newGeoJson[0]?.geometry.coordinates[0][0][1], newGeoJson[0]?.geometry.coordinates[0][0][0]]
          }
          zoom={appliedZoom}
          tap={false}
          zoomControl={false}
        >
          <IncidentsModalMapInnerContainerPreview
            appliedZoom={appliedZoom}
            data={newGeoJson}
            level={level}
            type={type}
          />

          <ZoomButtonContainer id={'zoompreventer'}>
            <ZoomButton
              data-testid="map-zoom-in"
              copy
              onClick={() => handleZoomButton(appliedZoom + 1)}
              style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
            >
              <Icon type="zoom-in" />
            </ZoomButton>
            <ZoomButton
              data-testid="map-zoom-out"
              copy
              onClick={() => handleZoomButton(appliedZoom - 1)}
              style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
            >
              <Icon type="zoom-out" />
            </ZoomButton>
          </ZoomButtonContainer>
        </Map>
      </ErrorBoundary>
    </MapContainer>
  );
};
export default IncidentHistoryItemMap;
