import React, { useContext } from 'react';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';
import ProfileDropdown from './ProfileDropdown';

const StyledProfile = styled.div`
  margin-left: auto;
`;

const Profile = ({ user }) => {
  const { maintenanceModeEnabled } = useContext(UserContext);

  return maintenanceModeEnabled ? (
    <StyledProfile />
  ) : (
    <StyledProfile>
      <ProfileDropdown user={user} />
    </StyledProfile>
  );
};

export default Profile;
