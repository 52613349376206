import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: auto;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const SpinContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const ErrorContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const CreditInvoiceModal = ({ id, closeModal, setInvoiceData, creditInvoiceData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [dataError, setDataError] = useState(false);

  const [loading, setLoading] = useState(true);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const title = t('Get credit invoice data');

  useEffect(() => {
    const getInvoiceData = async () => {
      let result;
      try {
        result = await apiCall('get', 'invoicing/v2/credit-invoice-base/' + id);
      } catch (e) {
        setDataError(true);
      }

      if (result?.status === 200 && result.data?.invoice) {
        if (creditInvoiceData) {
          setInvoiceData({
            ...creditInvoiceData,
            creditable_products: result.data.invoice.creditable_products,
            new_credit: false,
          });
        } else {
          setInvoiceData({ ...result.data.invoice, credited_invoice_id: id, new_credit: true });
        }
      } else {
        setDataError(true);
      }
    };

    if (id) {
      getInvoiceData();

      setTimeout(() => {
        if (mounted.current) {
          setLoading(false);
        }
      }, 1000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal title={title} open={true} width={600} onCancel={() => closeModal(false)} footer={null} maskClosable={false}>
      <ModalInnerInput>
        {dataError && !loading ? (
          <ErrorContainer>{t('Credit invoice data fetch failed.')}</ErrorContainer>
        ) : (
          <SpinContainer>
            <Spin size="medium" />
          </SpinContainer>
        )}
        <MiddleLine />
        <ActionButtons style={{ justifyContent: 'flex-end' }}>
          <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default CreditInvoiceModal;
