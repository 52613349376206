import React from 'react';
import styled from 'styled-components';

import { color_planned, color_not_planned, color_uncommitted } from '../commonCalendar/utility/constants';

export const ColorSquare = styled.div`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;

  margin: ${props => (props.margin ? '0px 2px' : '')};

  ${props => {
    if (props.outPilotCompleted) {
      return `
          background-color: ${color_planned};
        `;
    } else if (props.outPilotCommenced) {
      return `
          background-color: ${color_planned};
        `;
    } else if (props.outPilotOrdered) {
      return `
          background-color: ${color_not_planned};
        `;
    } else if (props.outPilotEstimated) {
      return `
          background-color: ${color_uncommitted};
        `;
    } else if (props.inPilotCompleted) {
      return `
          background-color: ${color_planned};
        `;
    } else if (props.inPilotCommenced) {
      return `
          background-color: ${color_planned};
        `;
    } else if (props.inPilotOrdered) {
      return `
          background-color: ${color_not_planned};
        `;
    } else if (props.inPilotEstimated) {
      return `
          background-color: ${color_uncommitted};
        `;
    } else {
      return `  
        background-color: #f7fbfc;
      `;
    }
  }};

  ${props => {
    if (props.outPilotCompleted) {
      return `
          border: 1px solid #0a4726;
        `;
    } else if (props.outPilotCommenced) {
      return `
          border: 1px solid #2ea363;
        `;
    } else if (props.outPilotOrdered) {
      return `
          border: 1px solid #6f97de;
        `;
    } else if (props.outPilotEstimated) {
      return `
          border: 1px solid #edca1a;
        `;
    } else if (props.inPilotCompleted) {
      return `
          border: 1px solid #0a4726;
        `;
    } else if (props.inPilotCommenced) {
      return `
          border: 1px solid #2ea363;
        `;
    } else if (props.inPilotOrdered) {
      return `
          border: 1px solid #6f97de;
        `;
    } else if (props.inPilotEstimated) {
      return `
          border: 1px solid #edca1a;
        `;
    } else {
      return `
          border: 1px solid #a0a0a0;
        `;
    }
  }};
`;

const PilotageColorIndicator = ({
  outPilotEstimated,
  outPilotOrdered,
  outPilotCommenced,
  outPilotCompleted,
  inPilotEstimated,
  inPilotOrdered,
  inPilotCommenced,
  inPilotCompleted,
  margin,
}) => {
  return (
    <ColorSquare
      outPilotEstimated={outPilotEstimated}
      outPilotOrdered={outPilotOrdered}
      outPilotCommenced={outPilotCommenced}
      outPilotCompleted={outPilotCompleted}
      inPilotOrdered={inPilotOrdered}
      inPilotEstimated={inPilotEstimated}
      inPilotCommenced={inPilotCommenced}
      inPilotCompleted={inPilotCompleted}
      margin={margin}
    />
  );
};

export default PilotageColorIndicator;
