import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// import Spin from 'antd/es/spin';

import Timeline from './calendar/Timeline';

import { CranePlanningToolContext } from '../../context/CranePlanningToolContext';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import { UserContext } from '../../context/UserContext';

const Calendar = styled.div`
  position: relative;
`;

// const SpinContainer = styled.div`
//   position: absolute;
//   top: -6px;
//   right: 6px;
//   z-index: 90;
// `;

const CranePlanningToolCalendar = () => {
  const { getStartData } = useContext(CranePlanningToolContext);
  const { getBerthStartData } = useContext(BerthPlanningToolContext);
  const { user } = useContext(UserContext);

  const [berthsAvailable, setBerthsAvailable] = useState(false);

  useEffect(() => {
    if (!berthsAvailable) {
      getBerthStartData();
      setBerthsAvailable(true);
    }
  }, [berthsAvailable, getBerthStartData]);

  const [cranesAvailable, setCranesAvailable] = useState(false);

  useEffect(() => {
    if (!cranesAvailable) {
      getStartData();
      setCranesAvailable(true);
    }
  }, [cranesAvailable, getStartData]);

  // If you want to use loader, it needs to be added to the contexts imports
  return (
    <Calendar style={{ height: 'calc(100% - ' + (user.permissions.includes('manage_bpt') ? 50 : 0) + 'px)' }}>
      {/* {loader > 0 && (
        <SpinContainer data-testid="buu">
          <Spin size="small" />
        </SpinContainer>
      )} */}
      <Timeline />
    </Calendar>
  );
};

export default CranePlanningToolCalendar;
