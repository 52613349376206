import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

const BptWeatherContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  width: 100%;
  color: #eba900;
  font-size: 14px;
  text-align: center;
  padding: 1px 10px;
  background: #fbfbfb;
  span {
    color: #797979;
  }
`;

const BerthPlanningWeatherNoData = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <BptWeatherContainer>
      <TextContainer>
        {t('No')} <br></br>
        <span>{t('weather')}</span>
        <br></br>
        {t('data')}
      </TextContainer>
    </BptWeatherContainer>
  );
};

export default BerthPlanningWeatherNoData;
