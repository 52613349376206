import React, { Fragment, useContext, useMemo } from 'react';
import styled from 'styled-components';

import { FleetViewContext } from '../../../context/FleetViewContext';

import { CalendarRow } from './CalendarRow';

const RowLines = styled.div`
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
`;

const PaddingRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  height: 32px;

  ${props => {
    if (props.index % 2 === 0) {
      return 'background-color: ' + props.theme.color.grey_lighter2;
    } else {
      return 'background-color: white;';
    }
  }}
`;

export const CalendarRows = () => {
  const { canvasWidth, ports = {} } = useContext(FleetViewContext);

  const getRowLines = useMemo(() => {
    let rowIndex = 0;
    return Object.entries(ports).map(([areaName, ports]) => {
      return (
        <Fragment key={`area-name-row-${areaName}`}>
          <PaddingRow
            style={{
              width: `${canvasWidth}px`,
            }}
            index={rowIndex}
          />
          {ports.map(port => {
            const row = <CalendarRow key={rowIndex} index={rowIndex} port={port} />;
            rowIndex += 1;
            return row;
          })}
        </Fragment>
      );
    });
  }, [canvasWidth, ports]);

  return <RowLines>{getRowLines}</RowLines>;
};
