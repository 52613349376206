import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spin from 'antd/es/spin';

import { UserContext } from '../context/UserContext';

import Heading from '../components/ui/Heading';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import Paragraph from '../components/ui/Paragraph';
import Form from '../components/ui/Form';
import useToggle from '../hooks/useToggle';

const AmnesiaLink = styled.a`
  margin-top: -${({ theme }) => theme.sizing.gap};
  margin-bottom: -${({ theme }) => theme.sizing.gap};
  margin-left: ${({ theme }) => theme.sizing.gap_small};
`;

const StyledForm = styled(Form)`
  position: relative;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.sizing.gap};
  width: 340px;
  input,
  button,
  select {
    width: 100%;
  }
`;

const ForgotPasswordContainer = styled.div`
  box-shadow: 4px 4px 8px rgb(0 0 0 / 45%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-width: 300px;
  position: absolute;
  z-index: 50;
  top: 100px;
  right: 0px;
  height: 236px;
  background-color: ${({ theme }) => theme.color.primary};
  @media (min-width: 768px) {
    right: 20px;
  }
`;

const ParagraphContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_huge};
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const ForgotPasswordV2 = () => {
  const { setCurrentAuthView, requestPasswordReset, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [email, setEmail] = useState('');
  const ref = useRef();
  const [isOpen, toggleIsOpen] = useToggle(false, ref);
  const [emailSent, showEmailSent] = useState(false);
  const [loader, showLoader] = useState(false);

  const disabled = email === '';

  const handleForgotPassword = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (email) {
      showLoader(true);
      const res = await requestPasswordReset(email, namespace);
      if (res) {
        showLoader(false);
        showEmailSent(true);
        setCurrentAuthView('LOGIN');
      }
    }
  };

  const toggleOpen = () => {
    toggleIsOpen();
    showEmailSent(false);
  };

  return (
    <span ref={ref}>
      <AmnesiaLink onClick={() => toggleOpen()}>{t('Forgot your password?')}</AmnesiaLink>
      {isOpen && (
        <ForgotPasswordContainer>
          <StyledForm onSubmit={handleForgotPassword}>
            <Heading h3>{t('Forgot your password?')}</Heading>
            <Paragraph>{t('Please enter your e-mail address to receive a link to reset your password.')}</Paragraph>
            {!emailSent && (
              <div>
                <Input
                  label={t('Email')}
                  name={t('Email')}
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  autoFocus
                />
                <Spin spinning={loader}>
                  <Button disabled={disabled}>{t('Request Password Reset')}</Button>
                </Spin>
              </div>
            )}
            {emailSent && !loader && (
              <ParagraphContainer>
                <Paragraph>{t('Email was sent.')}</Paragraph>
              </ParagraphContainer>
            )}
          </StyledForm>
        </ForgotPasswordContainer>
      )}
    </span>
  );
};

export default ForgotPasswordV2;
