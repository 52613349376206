import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const TimeIndicatorLineLine = styled.div`
  background-color: ${props => (props.isUserSet ? 'blue' : props.theme.color.warning)};
  width: 2px;
  top: 0px;
  position: absolute;
  height: 100%;
  z-index: 101;
  cursor: ew-resize;
`;

const TimeIndicatorLine = ({ indicatorLine, setIndicatorLine, canvasWidth, canvasTimeStart, canvasTimeEnd }) => {
  const draggableRef = useRef(null);

  const getLineStyle = timeLeft => {
    return {
      style: {
        left: timeLeft,
      },
    };
  };

  const onStop = (i, position) => {
    const pixelInMilliseconds =
      dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;

    const userDraggedTime = dayjs(indicatorLine.time + position.x * pixelInMilliseconds);
    setIndicatorLine({ time: userDraggedTime, isUserSet: true });

    return false;
  };

  if (
    (indicatorLine.time.isAfter(canvasTimeStart) && indicatorLine.time.isBefore(canvasTimeEnd)) ||
    indicatorLine.time.isSame(canvasTimeStart) ||
    indicatorLine.time.isSame(canvasTimeEnd)
  ) {
    const diffMinutes = indicatorLine.time.diff(canvasTimeStart, 'minutes');
    const percentage = diffMinutes / dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart), 'minutes');

    const timeLeft = canvasWidth * percentage;

    return (
      <Draggable axis="x" onStop={onStop} key={indicatorLine.time.format()} nodeRef={draggableRef}>
        <TimeIndicatorLineLine
          ref={draggableRef}
          data-testid={'time-indicator-line'}
          isUserSet={indicatorLine.isUserSet}
          {...getLineStyle(timeLeft)}
        />
      </Draggable>
    );
  }

  return null;
};

export default TimeIndicatorLine;
