import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';
import PricingTableMatrix from '../components/invoicing/PricingTableMatrix';
import VesselNetTonnageSelector from '../components/invoicing/VesselNetTonnageSelector';
import Categories from '../components/invoicing/Categories';
import EditPricingCategoriesModal from '../components/invoicing/EditPricingCategoriesModal';
import { InvoicingContext } from '../context/InvoicingContext';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  width: 100%;
  box-shadow: none;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: 6px;
`;

const TopPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    display: inline-block;
  }
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 20px;

  @media (max-width: 650px) {
    margin-left: 10px;
    margin-bottom: 6px;
  }
`;

const InvoicingMatrixView = () => {
  const { namespace } = useContext(UserContext);
  const { getStartData } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    getStartData();
    document.title = 'Invoicing matrix | Port Activity App';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pagename={t('Invoicing matrix')}>
      <AlteredPage fullWidth>
        <TopPanel>
          <SelectorContainer>
            <VesselNetTonnageSelector />
          </SelectorContainer>
          <CategoryContainer>
            <Categories />
          </CategoryContainer>
        </TopPanel>
        <PricingTableMatrix />
        <EditPricingCategoriesModal />
      </AlteredPage>
    </Layout>
  );
};

export default InvoicingMatrixView;
