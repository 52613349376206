import React, { useContext } from 'react';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import FleetVesselViewTopPanel from './FleetVesselViewTopPanel';
import FleetVesselViewCalendar from './FleetVesselViewCalendar';

import { FleetVesselViewContext } from '../../context/FleetVesselViewContext';

const CalendarContainer = styled.div`
  height: calc(100% - 49px);
  min-width: 790px;
`;

const SpinContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;

const FleetVesselViewCalendarContainer = () => {
  const { sidePanelWidth, timelineUnit, zoomChecked } = useContext(FleetVesselViewContext);

  return timelineUnit && zoomChecked ? (
    <CalendarContainer style={{ width: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <FleetVesselViewTopPanel />
      <FleetVesselViewCalendar />
    </CalendarContainer>
  ) : (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  );
};

export default FleetVesselViewCalendarContainer;
