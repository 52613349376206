import React from 'react';
import { FeatureGroup } from 'react-leaflet';
import DrawControlsInner from './DrawControlsInner';

const DrawControl = () => {
  return (
    <FeatureGroup>
      <DrawControlsInner />
    </FeatureGroup>
  );
};

export default DrawControl;
