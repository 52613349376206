import React, { useContext } from 'react';
import styled from 'styled-components';

import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';

import BerthPlanningToolUpcomingVessels from './BerthPlanningToolUpcomingVessels';

const SidePanel = styled.div`
  height: 100%;
  border-left: 1px solid #f8f8f8;
  z-index: 110;

  @media (max-width: 1140px) {
    display: none;
  }
`;

const BerthPlanningToolSidePanel = () => {
  const { sidePanelWidth } = useContext(BerthPlanningToolContext);

  return (
    <SidePanel style={{ width: sidePanelWidth + 'px' }}>
      <BerthPlanningToolUpcomingVessels />
    </SidePanel>
  );
};

export default BerthPlanningToolSidePanel;
