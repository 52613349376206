import { useState } from 'react';

const useForm = (fields, cb, initials) => {
  const defaults = fields.reduce((acc, val) => {
    return { ...acc, [val]: initials?.[val] || '' };
  }, {});

  const [values, setValues] = useState(defaults);

  const handleSubmit = e => {
    if (e) {
      e.preventDefault?.();
    }
    cb(values);
  };

  const handleChange = e => {
    e.persist?.();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCheckbox = e => {
    e.persist?.();
    setValues({ ...values, [e.target.name]: e.target.checked });
  };

  const handleSetMultiple = keysAndValues => {
    setValues(previous => ({
      ...previous,
      ...keysAndValues,
    }));
  };

  return {
    values,
    handleSubmit,
    handleChange,
    handleCheckbox,
    handleSetMultiple,
  };
};

export default useForm;
