import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../../../context/UserContext';

import { smallSidebarWidth } from '../../../commonCalendar/utility/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light2};
  margin-bottom: 1px;
`;

const Header = styled.div`
  width: ${smallSidebarWidth}px;
  text-align: right;
  padding-right: 8px;
  padding-bottom: 4px;
  text-transform: uppercase;
  font-weight: 600;
`;

const SidebarHeader = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <Container>
      <Header>{t("Vessel's name")}</Header> {/*eslint-disable-line quotes*/}
    </Container>
  );
};

export default SidebarHeader;
