import deepEqual from 'deep-equal';
import {
  CANCEL,
  SHOW_ADD_TIMESTAMP,
  SHOW_SEND_NOTIFICATION,
  SHOW_RECOMMEND_TIME,
  SHOW_RECOMMEND_TIME_VIS,
  SHOW_SEND_MESSAGE,
  VERIFY_SESSION,
  SET_USER,
  SET_AUTHVIEW,
  SET_NAMESPACE,
  SET_PORTNAME,
  SET_SESSION_ID,
  SET_LOGOUT,
  SET_EXPIRES_AT,
  HANDLE_LOGIN_LOGOUT,
  REFRESH_SESSION_STATE,
} from './constants.js';

export const cardReducer = (state, action) => {
  switch (action.type) {
    case CANCEL:
      return {
        showAddTimestamp: false,
        showSendNotification: false,
        showRecommendTime: false,
        showRecommendTimeVis: false,
        showSendMessage: false,
      };

    case SHOW_ADD_TIMESTAMP:
      return {
        showAddTimestamp: true,
        showSendNotification: false,
        showRecommendTime: false,
        showRecommendTimeVis: false,
        showSendMessage: false,
      };

    case SHOW_SEND_NOTIFICATION:
      return {
        showAddTimestamp: false,
        showSendNotification: true,
        showRecommendTime: false,
        showRecommendTimeVis: false,
        showSendMessage: false,
      };

    case SHOW_RECOMMEND_TIME:
      return {
        showAddTimestamp: false,
        showSendNotification: false,
        showRecommendTime: true,
        showRecommendTimeVis: false,
        showSendMessage: false,
      };

    case SHOW_RECOMMEND_TIME_VIS:
      return {
        showAddTimestamp: false,
        showSendNotification: false,
        showRecommendTime: false,
        showRecommendTimeVis: true,
        showSendMessage: false,
      };

    case SHOW_SEND_MESSAGE:
      return {
        showAddTimestamp: false,
        showSendNotification: false,
        showRecommendTime: false,
        showRecommendTimeVis: false,
        showSendMessage: true,
      };

    default:
      return state;
  }
};

export const sessionReducer = (state, action) => {
  let newState = state;
  switch (action.type) {
    case SET_USER:
      newState = {
        ...state,
        sessionId: action.id,
        user: action.user,
        namespace: action.ns,
        namespaces: action.namespaces,
        verifyingSession: action.verify,
        refreshingSession: action.refreshing,
        expiresAt: action.expiresAt,
        ttl: action.ttl,
        modules: action.modules,
        rtaPointCoordinates: action.rtaPointCoordinates,
        mapDefaultCoordinates: action.mapDefaultCoordinates,
        mapDefaultZoom: action.mapDefaultZoom,
        jwt: action.jwt,
        activeAlerts: action.activeAlerts,
      };
      break;
    case SET_EXPIRES_AT:
      newState = {
        ...state,
        expiresAt: action.expiresAt,
      };
      break;
    case SET_AUTHVIEW:
      newState = {
        ...state,
        currentAuthView: action.currentAuthView,
      };
      break;
    case SET_NAMESPACE:
      newState = {
        ...state,
        namespace: action.namespace,
      };
      break;
    case SET_PORTNAME:
      newState = {
        ...state,
        portName: action.portName,
      };
      break;
    case SET_SESSION_ID:
      newState = {
        ...state,
        sessionId: action.id,
      };
      break;
    case VERIFY_SESSION:
      newState = {
        ...state,
        verifyingSession: action.state,
      };
      break;
    case REFRESH_SESSION_STATE:
      newState = {
        ...state,
        refreshingSession: action.state,
      };
      break;
    case SET_LOGOUT:
      newState = {
        ...state,
        currentAuthView: {
          view: 'LOGIN',
          params: null,
        },
        portName: '',
        namespace: 'common',
        sessionId: null,
        verifyingSession: false,
        user: undefined,
        modules: undefined,
        session: undefined,
        rtaPointCoordinates: '',
      };
      break;
    case HANDLE_LOGIN_LOGOUT:
      newState = {
        ...state,
        handlingLoginLogout: action.state,
      };
      break;
    default:
      return state;
  }
  if (!deepEqual(state, newState, true)) {
    return newState;
  }
  return state;
};
