import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { onMarkerMouseOver } from './MapHelpers';

const TooltipShadow = styled.div`
  padding: 3px 3px 0 3px;
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.35));
`;

const Container = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
  background-color: #a5d6a7;
  color: black;
`;

const Rows = styled.div`
  font-size: 12px;
  padding: 6px 16px 10px 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #f6fff7;
`;

const Row = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const BoldText = styled.span`
  font-weight: 700;
  padding-left: 6px;
`;

const BottomArrowContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  height: 14px;
  display: flex;
  justify-content: center;
  margin-top: -1px;
`;

const BottomArrow = styled.div`
  height: 10px;
  width: 20px;
  background-color: ${props => (props.otherSelected ? '#a5d6a7' : '#f6fff7')};
  clip-path: polygon(100% 0, 50% 100%, 0 0);
`;

const TopArrowContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  height: 10px;
  display: flex;
  justify-content: center;
  margin-top: -1px;
`;

const TopArrow = styled.div`
  height: 10px;
  width: 20px;
  background-color: #a5d6a7;
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
`;

const LeftArrowContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  height: 10px;
  display: flex;
  justify-content: center;
  margin-top: -1px;
`;

const LeftArrow = styled.div`
  height: 20px;
  width: 10px;
  background-color: ${props => (props.otherSelected ? '#a5d6a7' : '#f6fff7')};
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
`;

const RightArrowContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  height: 10px;
  display: flex;
  justify-content: center;
  margin-top: -1px;
`;

const RightArrow = styled.div`
  height: 20px;
  width: 10px;
  background-color: ${props => (props.otherSelected ? '#a5d6a7' : '#f6fff7')};
  clip-path: polygon(0 0, 100% 50%, 0 100%);
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeselectRow = styled.div`
  display: flex;
`;

const DeselectButton = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: -4px;
`;

const FleetMapMarker = ({ feature, clickPort, selected, deselectPort, fleetCardsOn }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [direction, setDirection] = useState('top');

  const map = useMap();

  const getMarkerIcon = () => {
    return L.divIcon({
      html: ' <div />',
      iconSize: [12, 12],
      iconAnchor: [0, 0],
      className: 'map-marker-icon-class',
    });
  };

  const clickTooltip = e => {
    if (e.target.id !== 'port-deselection-button') {
      clickPort(feature.port_name);
    }
  };

  const onDeselectPort = e => {
    e.preventDefault();
    deselectPort();
  };

  const thisSelected = selected === feature.port_name;
  const otherSelected = !thisSelected && !!selected;

  const getMiniMarkerIcon = () => {
    let classname = thisSelected ? 'div-map-marker-fleet-selected-port' : 'div-map-marker-fleet-port';
    return L.divIcon({
      html: ` <div class=${classname} style="background-color:transparent;
          height:24px;
          width:24px;
          background-size:24px;!important;"/>`,
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      className: 'map-marker-icon-class',
    });
  };

  const getTooltip = permanent => {
    return (
      <Tooltip
        direction={direction}
        permanent={permanent}
        className={'fleet-marker-tooltip' + (thisSelected ? '-selected' : '')}
        offset={permanent ? [0, 0] : [0, -15]}
        key={`${feature.port_name}-${direction}`}
      >
        <TooltipShadow onClick={clickTooltip}>
          {direction === 'bottom' && (
            <TopArrowContainer>
              <TopArrow color={feature.color} otherSelected={otherSelected && fleetCardsOn} />
            </TopArrowContainer>
          )}
          <ArrowContainer>
            {direction === 'right' && (
              <LeftArrowContainer>
                <LeftArrow color={feature.color} otherSelected={otherSelected && fleetCardsOn} />
              </LeftArrowContainer>
            )}
            <Container>
              {!otherSelected || !fleetCardsOn ? (
                <Header data-id="tooltip-header">
                  {t('Port of')} {feature.port_name}
                </Header>
              ) : (
                <Header data-id="tooltip-header">{feature.port_name}</Header>
              )}
              {(!otherSelected || !fleetCardsOn) && (
                <Rows>
                  <Row>
                    {t('At berth')}:<BoldText>{feature.in_port ? feature.in_port.length : 0}</BoldText>
                  </Row>
                  <Row>
                    {t('Arriving')}:<BoldText>{feature.expected ? feature.expected.length : 0}</BoldText>
                  </Row>
                  {thisSelected && permanent && (
                    <DeselectRow>
                      <DeselectButton id="port-deselection-button" onClick={onDeselectPort}>
                        {t('Deselect port')}
                      </DeselectButton>
                    </DeselectRow>
                  )}
                </Rows>
              )}
            </Container>
            {direction === 'left' && (
              <RightArrowContainer>
                <RightArrow color={feature.color} otherSelected={otherSelected && fleetCardsOn} />
              </RightArrowContainer>
            )}
          </ArrowContainer>
          {direction === 'top' && (
            <BottomArrowContainer>
              <BottomArrow color={feature.color} otherSelected={otherSelected && fleetCardsOn} />
            </BottomArrowContainer>
          )}
        </TooltipShadow>
      </Tooltip>
    );
  };

  if (!fleetCardsOn) {
    return (
      <Marker
        icon={getMiniMarkerIcon()}
        position={feature.coordinates}
        eventHandlers={{
          mouseover: () => onMarkerMouseOver(map, feature.coordinates, direction, setDirection, true),
          click: clickTooltip,
        }}
      >
        {getTooltip(false)}
      </Marker>
    );
  }

  return (
    <Marker
      eventHandlers={{
        mouseover: () => onMarkerMouseOver(map, feature.coordinates, direction, setDirection),
      }}
      icon={getMarkerIcon()}
      position={feature.coordinates}
    >
      {getTooltip(true)}
    </Marker>
  );
};

export default FleetMapMarker;
