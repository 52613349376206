import React, { createContext, useContext, useCallback, useEffect, useRef } from 'react';

import { UserContext } from './UserContext';
import useApi from '../hooks/useApi';
import { ServiceOrderContext } from './ServiceOrderContext';

export const FleetServiceOrderContext = createContext();

export const FleetServiceOrderProvider = ({ children, context }) => {
  const { namespace, modules, user } = useContext(UserContext);
  const { portcallIds } = useContext(context);
  const { socketUpdate } = useContext(ServiceOrderContext);

  const { data: serviceOrderData, error: serviceOrderError, fetchData: fetchServiceOrders } = useApi(
    'post',
    'service-orders/service-order/search',
    {},
    null,
    false
  );

  const getServiceOrders = useCallback(() => {
    if (
      namespace === 'vts' &&
      modules &&
      modules.service_order_module === 'enabled' &&
      user.permissions.includes('view_service_order') &&
      portcallIds?.length > 0
    ) {
      const params = {
        query: {
          conditions: {
            and: [
              {
                type: 'order_line_allow_public_viewing',
                operator: 'is',
                value: true,
              },
            ],
          },
          text: portcallIds.join('|'),
        },
      };
      fetchServiceOrders(false, params);
    }
  }, [namespace, modules, user.permissions, portcallIds, fetchServiceOrders]);

  useEffect(() => {
    getServiceOrders();
  }, [getServiceOrders, namespace, portcallIds]);

  const socketUpdateRef = useRef(socketUpdate);
  useEffect(() => {
    if (socketUpdate !== socketUpdateRef.current) {
      getServiceOrders();
    }
    socketUpdateRef.current = socketUpdate;
  }, [getServiceOrders, socketUpdate]);

  let serviceOrders = serviceOrderError
    ? []
    : serviceOrderData?.results
      ? serviceOrderData.results['service-orders']
      : [];

  return (
    <FleetServiceOrderContext.Provider
      value={{
        serviceOrders,
        getServiceOrders,
      }}
    >
      {children}
    </FleetServiceOrderContext.Provider>
  );
};
