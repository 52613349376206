import React, { useRef, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useMap } from 'react-leaflet';
import { Control } from 'leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { useLeafletContext } from '@react-leaflet/core';
import { UserContext } from '../../context/UserContext';
import Draggable from 'react-draggable';
import Icon from '../ui/Icon';
import { METRIC, MILES, NAUTICALMILES, readableDistance } from './MapHelpers';

const InfoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50px;
  z-index: 999;
  background: white;
  cursor: default;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  margin: 2px;

  table tfoot {
    position: sticky;
    bottom: 0;
  }
`;

const Container = styled.div`
  padding: 8px 12px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: move;
  padding: 8px 12px;
`;

const BottomDragRow = styled.div`
  width: 100%;
  height: 6px;
  cursor: move;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
`;

const ChevronIconContainer = styled.div`
  margin-right: 8px;
  margin-top: -1px;
  cursor: pointer;
  margin-left: 24px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const IconContainer = styled.div`
  margin-left: 4px;
  margin-top: -2px;
  cursor: pointer;
  margin-left: 24px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const AmountTd = styled.td`
  padding: 6px 12px;
`;

const AmountDiv = styled.div`
  min-width: 64px;
`;

const FirstTd = styled.td`
  padding: 6px 12px;
  text-align: left;
`;

const ShowPartsButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  display: flex;
`;

const TableContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const EditingButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 55;
  bottom: 134px;
  right: 54px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  padding: 6px 12px;
  border-radius: 4px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawCreated: 'draw:created',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onMousemove: 'mousemove',
  onClick: 'click',
};

export const MeasureMapToolContainer = ({
  measureDrawing,
  setMeasuring,
  setLocations,
  setDistances,
  setCursorLocation,
  setShowParts,
  setEditing,
  editing,
  measuring,
  distances,
  setMeasureDrawing,
  showParts,
  locations,
  cursorLocation,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const context = useLeafletContext();
  const drawRef = useRef();
  const measureDrawingRef = useRef();
  const editingRef = useRef();

  const draggableRef = useRef(null);

  const map = useMap();

  useEffect(() => {
    if (measureDrawing && !measureDrawingRef.current) {
      drawRef.current?._toolbars.draw._modes.polyline?.handler.enable();
      setMeasuring(true);
    } else if (!measureDrawing && measureDrawingRef.current) {
      context.layerContainer.clearLayers();
      setLocations([]);
      setDistances([]);
      setCursorLocation(null);
      setShowParts(false);
      drawRef.current?._toolbars.draw._modes.polyline?.handler.disable();
      drawRef.current?._toolbars.edit._modes.edit.handler.disable();
    }

    measureDrawingRef.current = measureDrawing;
  }, [
    context.layerContainer,
    map,
    measureDrawing,
    setCursorLocation,
    setDistances,
    setLocations,
    setMeasuring,
    setShowParts,
  ]);

  useEffect(() => {
    if (editing && !editingRef.current) {
      drawRef.current?._toolbars.edit._modes.edit.handler.enable();
    } else if (!editing && editingRef.current) {
      drawRef.current?._toolbars.edit._modes.edit.handler.disable();
    }

    editingRef.current = editing;
  }, [editing]);

  useEffect(() => {
    return () => {
      context.layerContainer.clearLayers();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clickPreventer = () => {
    const div = L.DomUtil.get('measure-info');
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  };

  const onDrawStart = useCallback(() => {
    context?.layerContainer?.clearLayers();
  }, [context.layerContainer]);

  const onDrawVertex = useCallback(
    e => {
      if (measureDrawingRef.current) {
        const distanceList = Object.keys(e.layers._layers).map(l => e.layers._layers[l].getLatLng());
        setLocations(distanceList);
        setDistances(
          distanceList.map((d, index) => {
            if (index === 0) {
              return 0;
            } else {
              return map.distance(distanceList[index - 1], distanceList[index]);
            }
          })
        );
      }
    },
    [map, setDistances, setLocations]
  );

  const onDrawStop = useCallback(() => {
    setMeasuring(false);
  }, [setMeasuring]);

  const onDrawCreate = useCallback(
    e => {
      if (measureDrawingRef.current) {
        const container = context.layerContainer;
        container.addLayer(e.layer);
        const layers = container.getLayers();
        if (layers.length > 0) {
          const distanceList = layers[0].getLatLngs();
          setLocations(distanceList);
          setDistances(
            distanceList.map((d, index) => {
              if (index === 0) {
                return 0;
              } else {
                return map.distance(distanceList[index - 1], distanceList[index]);
              }
            })
          );
        }
      }
    },
    [context.layerContainer, map, setDistances, setLocations]
  );

  const onEditVertex = useCallback(
    e => {
      if (measureDrawingRef.current) {
        let distanceList = e.poly.getLatLngs().map(l => l);
        setLocations(distanceList);
        setDistances(
          distanceList.map((d, index) => {
            if (index === 0) {
              return 0;
            } else {
              return map.distance(distanceList[index - 1], distanceList[index]);
            }
          })
        );
      }
    },
    [map, setDistances, setLocations]
  );

  useEffect(() => {
    map.on(eventHandlers.onDrawStart, onDrawStart);
    map.on(eventHandlers.onDrawVertex, onDrawVertex);
    map.on(eventHandlers.onDrawStop, onDrawStop);
    map.on(eventHandlers.onDrawCreated, onDrawCreate);

    map.on(eventHandlers.onEditVertex, onEditVertex);

    drawRef.current = createDrawElement(context);
    map.addControl(drawRef.current);

    return () => {
      map.off(eventHandlers.onDrawStart);
      map.off(eventHandlers.onDrawVertex);
      map.off(eventHandlers.onDrawStop);
      map.off(eventHandlers.onDrawCreated);

      drawRef.current.remove(map);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDrag = e => {
    e.stopPropagation();
  };

  const startEditing = () => {
    setEditing(true);
  };

  const stopEditing = () => {
    setEditing(false);
  };

  let lastLeg = 0;
  if (locations?.length > 0 && cursorLocation) {
    if (measuring) {
      lastLeg = map.distance(locations[locations.length - 1], cursorLocation);
    }
  }

  if (!measureDrawing) {
    return null;
  }

  return (
    <>
      {!measuring && !editing && <EditingButton onClick={() => startEditing()}>{t('Edit measurements')}</EditingButton>}
      {!measuring && editing && <EditingButton onClick={() => stopEditing()}>{t('Stop editing')}</EditingButton>}
      <Draggable onDrag={onDrag} handle=".measure-info-drag-handle" bounds="parent" nodeRef={draggableRef}>
        <InfoContainer id="measurement-info-container" ref={draggableRef}>
          <TopRow className="measure-info-drag-handle">
            <Title>{t('Distances')}</Title>
            <IconContainer className="close" href="#" onClick={() => setMeasureDrawing(false)}>
              <Icon type="close" />
            </IconContainer>
          </TopRow>

          <Container id="measure-info" onClick={() => clickPreventer()}>
            <Row>
              <div />
              <ShowPartsButton onClick={() => setShowParts(!showParts)}>
                {!showParts ? (
                  <ChevronIconContainer>
                    <Icon type="chevron-down" />
                  </ChevronIconContainer>
                ) : (
                  <ChevronIconContainer>
                    <Icon type="chevron-up" />
                  </ChevronIconContainer>
                )}
                {t('Show cumulative distances')}
              </ShowPartsButton>
            </Row>
            <TableContainer>
              <table>
                <tbody>
                  {showParts &&
                    distances?.map((d, index) => {
                      if (index === 0 || index === distances.length - 1) {
                        return null;
                      }

                      const distanceArray = distances.slice(0, index + 1);

                      return (
                        <tr key={index}>
                          <FirstTd>{index}.</FirstTd>
                          <AmountTd>
                            <AmountDiv>
                              {readableDistance(
                                distanceArray.reduce((partialSum, a) => partialSum + a, 0),
                                METRIC
                              )}
                            </AmountDiv>
                          </AmountTd>
                          <AmountTd>
                            <AmountDiv>
                              {readableDistance(
                                distanceArray.reduce((partialSum, a) => partialSum + a, 0) + lastLeg,
                                MILES
                              )}
                            </AmountDiv>
                          </AmountTd>
                          <AmountTd>
                            <AmountDiv>
                              {readableDistance(
                                distanceArray.reduce((partialSum, a) => partialSum + a, 0),
                                NAUTICALMILES
                              )}
                            </AmountDiv>
                          </AmountTd>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot style={{ backgroundColor: '#f2f2f2' }}>
                  <tr>
                    <FirstTd>{t('Total distance')}</FirstTd>
                    <AmountTd>
                      <AmountDiv>
                        {readableDistance(distances.reduce((partialSum, a) => partialSum + a, 0) + lastLeg, METRIC)}
                      </AmountDiv>
                    </AmountTd>
                    <AmountTd>
                      <AmountDiv>
                        {readableDistance(distances.reduce((partialSum, a) => partialSum + a, 0) + lastLeg, MILES)}
                      </AmountDiv>
                    </AmountTd>
                    <AmountTd>
                      <AmountDiv>
                        {readableDistance(
                          distances.reduce((partialSum, a) => partialSum + a, 0) + lastLeg,
                          NAUTICALMILES
                        )}
                      </AmountDiv>
                    </AmountTd>
                  </tr>
                </tfoot>
              </table>
            </TableContainer>
          </Container>
          <BottomDragRow className="measure-info-drag-handle" />
        </InfoContainer>
      </Draggable>
    </>
  );
};

function createDrawElement(context) {
  const { layerContainer } = context;
  const options = {
    edit: {
      edit: {
        selectedPathOptions: {
          maintainColor: true,
        },
      },
      remove: false,
      featureGroup: layerContainer,

    },
    draw: {
      rectangle: false,
      polyline: {
        icon: new L.DivIcon({
          iconSize: new L.Point(7, 7),
          className: 'leaflet-div-icon leaflet-editing-icon',
        }),
        shapeOptions: {
          color: 'navy',
          weight: 3,
        },
        guidelineDistance: 10,
      },
      circle: false,
      circlemarker: false,
      marker: false,
      polygon: false,
    },
    position: 'topright'
  };

  return new Control.Draw(options);
}

export default MeasureMapToolContainer;
