import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Privacy policy | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Privacy Policy')}>
      <Page fullWidth>
        <PrivacyPolicy user={user} />
      </Page>
    </Layout>
  );
};

export default PrivacyPolicyPage;
