import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserContext } from '../../../context/UserContext';
import { TIME_FORMAT } from '../../../utils/constants';
import Tooltip from 'antd/es/tooltip';

const Container = styled.div`
  overflow-y: auto;

  td {
    padding: 6px 12px 6px 8px;
  }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;

  ${props => {
    if (props.cancelled) {
      return `
        background: #e8e8e8;
      `;
    } else if (props.status === 1) {
      return `
        background: #F44336;
      `;
    } else if (props.status === 2) {
      return `
        background: #F5BD3E;
      `;
    } else if (props.status == 3) {
      return `
        background: #407505;
      `;
    }
  }}
`;

const HeaderRow = styled.tr`
  font-weight: 600;

  td {
    background-color: #f8f8f8;
  }
`;

const InfoRow = styled.tr`
  td {
    border-bottom: 1px solid #d8d8d8;
  }
`;

const StateHeaderTd = styled.td`
  width: 32px;
  text-align: center;
`;

const StateTd = styled.td`
  width: 32px;
  text-align: center;
  vertical-align: middle;
`;

const WhenTd = styled.td`
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ServiceTd = styled.td`
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: ${props => props.cancelled && 'line-through'};
`;

const ServiceOrderInfoTable = ({ otherData, pilotData }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const QuantityTooltip = (qty, del_qty) => {
    return (
      <table>
        <tbody>
          <tr>
            <td style={{ padding: '4px' }}>{t('Requested quantity')}:</td>
            <td style={{ fontWeight: 600, padding: '4px' }}>{qty || qty === 0 ? qty : '-'}</td>
          </tr>
          <tr>
            <td style={{ padding: '4px' }}>{t('Delivered quantity')}:</td>
            <td style={{ fontWeight: 600, padding: '4px' }}>{del_qty || del_qty === 0 ? del_qty : '-'}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Container>
      <table style={{ minWidth: '520px' }}>
        <tbody>
          {otherData.length > 0 && (
            <HeaderRow>
              <td>{t('Service')}</td>
              <td>{t('Qty / Req qty')}</td>
              <StateHeaderTd>{t('Req')}</StateHeaderTd>
              <StateHeaderTd>{t('Ackn')}</StateHeaderTd>
              <StateHeaderTd>{t('Deliv')}</StateHeaderTd>
              <td>{t('Req. delivery time')}</td>
              <td>{t('Delivery time')}</td>
              <td>{t('When')}</td>
            </HeaderRow>
          )}
          {otherData.map(d => {
            return (
              <InfoRow key={d.key}>
                <ServiceTd cancelled={d.cancelled}>{d.services}</ServiceTd>
                <Tooltip
                  placement="right"
                  title={QuantityTooltip(d.quantity, d.delivery_quantity)}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <td>
                    {d.delivery_quantity || d.delivery_quantity === 0 ? d.delivery_quantity : '-'} /{' '}
                    {d.quantity || d.quantity === 0 ? d.quantity : '-'}
                  </td>
                </Tooltip>
                <StateTd>
                  <Tooltip
                    placement="right"
                    title={d.time1 ? t('Updated at') + ': ' + dayjs(d.time1).format(TIME_FORMAT) : null}
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a' }}
                  >
                    <Dot status={1} cancelled={d.cancelled} />
                  </Tooltip>
                </StateTd>
                <StateTd>
                  {d.state > 1 && (
                    <Tooltip
                      placement="right"
                      title={d.time2 ? t('Updated at') + ': ' + dayjs(d.time2).format(TIME_FORMAT) : null}
                      color="white"
                      overlayInnerStyle={{ color: '#4a4a4a' }}
                    >
                      <Dot status={2} cancelled={d.cancelled} />
                    </Tooltip>
                  )}
                </StateTd>
                <StateTd>
                  {d.state > 2 && (
                    <Tooltip
                      placement="right"
                      title={d.time3 ? t('Updated at') + ': ' + dayjs(d.time3).format(TIME_FORMAT) : null}
                      color="white"
                      overlayInnerStyle={{ color: '#4a4a4a' }}
                    >
                      <Dot status={3} cancelled={d.cancelled} />
                    </Tooltip>
                  )}
                </StateTd>
                <td>{d.requested_delivery_time ? dayjs(d.requested_delivery_time).format(TIME_FORMAT) : null}</td>
                <td>{d.delivery_time ? dayjs(d.delivery_time).format(TIME_FORMAT) : null}</td>
                <WhenTd style={{ maxWidth: '160px' }}>
                  <Tooltip placement="right" title={d.when} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
                    {d.when}
                  </Tooltip>
                </WhenTd>
              </InfoRow>
            );
          })}
          {otherData.length > 0 && pilotData.length > 0 && <tr style={{ height: '36px' }} />}
          {pilotData.length > 0 && (
            <HeaderRow>
              <td>{t('Service')}</td>
              <td>{t('Qty / Req qty')}</td>
              <StateHeaderTd>{t('Req')}</StateHeaderTd>
              <StateHeaderTd>{t('Ackn')}</StateHeaderTd>
              <StateHeaderTd>{t('Deliv')}</StateHeaderTd>
              <td>{t('Orig. start time')}</td>
              <td>{t('Start time')}</td>
              <td></td>
            </HeaderRow>
          )}
          {pilotData.map(d => {
            return (
              <InfoRow key={d.key}>
                <ServiceTd cancelled={d.cancelled}>{d.services}</ServiceTd>
                <Tooltip
                  placement="right"
                  title={QuantityTooltip(d.quantity, d.delivery_quantity)}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <td>
                    {d.delivery_quantity || d.delivery_quantity === 0 ? d.delivery_quantity : '-'} /{' '}
                    {d.quantity || d.quantity === 0 ? d.quantity : '-'}
                  </td>
                </Tooltip>
                <StateTd>
                  <Tooltip
                    placement="right"
                    title={d.time1 ? t('Updated at') + ': ' + dayjs(d.time1).format(TIME_FORMAT) : null}
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a' }}
                  >
                    <Dot status={1} cancelled={d.cancelled} />
                  </Tooltip>
                </StateTd>
                <StateTd>
                  {d.state > 1 && (
                    <Tooltip
                      placement="right"
                      title={d.time2 ? t('Updated at') + ': ' + dayjs(d.time2).format(TIME_FORMAT) : null}
                      color="white"
                      overlayInnerStyle={{ color: '#4a4a4a' }}
                    >
                      <Dot status={2} cancelled={d.cancelled} />
                    </Tooltip>
                  )}
                </StateTd>
                <StateTd>
                  {d.state > 2 && (
                    <Tooltip
                      placement="right"
                      title={d.time3 ? t('Updated at') + ': ' + dayjs(d.time3).format(TIME_FORMAT) : null}
                      color="white"
                      overlayInnerStyle={{ color: '#4a4a4a' }}
                    >
                      <Dot status={3} cancelled={d.cancelled} />
                    </Tooltip>
                  )}
                </StateTd>
                <td>{d.original_start_time ? dayjs(d.original_start_time).format(TIME_FORMAT) : null}</td>
                <td>{d.start_time ? dayjs(d.start_time).format(TIME_FORMAT) : null}</td>
                <td></td>
              </InfoRow>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default ServiceOrderInfoTable;
