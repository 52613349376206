import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import * as Sentry from '@sentry/browser';

import { UserContext } from '../context/UserContext';

const useApi = (method, path, firstParams, callback, allow = true) => {
  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { apiCall } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(allow);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (cancelled, nextParams) => {
      setLoading(true);
      const params = nextParams || firstParams;
      try {
        const { data } = await apiCall(method, path, params);
        if (!cancelled && mounted.current) {
          setError(null);
          setData(data);
          setLoading(false);
          if (callback) {
            callback(data);
          }
        }
      } catch (e) {
        const responseErrorMessage = e?.response?.data?.error;
        setError(responseErrorMessage || 'Oops! Something went wrong.');
        Sentry.captureException(e);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    let cancelled = false;
    if (allow) {
      fetchData(cancelled, firstParams);
    }
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line
  }, [fetchData]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export default useApi;
