import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FleetVesselViewContext } from '../../context/FleetVesselViewContext';

import Timeline from './vesselCalendar/Timeline';

const Calendar = styled.div`
  position: relative;
`;

const FleetVesselViewCalendar = () => {
  const { getStartData } = useContext(FleetVesselViewContext);

  const [dataAvailable, setDataAvailable] = useState(false);

  useEffect(() => {
    if (!dataAvailable) {
      getStartData();
      setDataAvailable(true);
    }
  }, [dataAvailable, getStartData]);

  return (
    <Calendar style={{ height: '100%' }}>
      <Timeline />
    </Calendar>
  );
};

export default FleetVesselViewCalendar;
