import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { WeatherContext } from '../../context/WeatherContext';
import { UserContext } from '../../context/UserContext';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const SeaLevelContainer = styled.div`
  font-size: 14px;
  margin-left: 24px;
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-left: 52px;
  }
`;

const Text = styled.div`
  color: #c4c4c4;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
  }
`;

const Value = styled.span`
  color: white;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
  }
`;

const Name = styled.div`
  font-size: 12px;
  text-decoration: none;
  display: flex;
  color: ${({ theme }) => theme.color.secondary};
  height: 24px;
`;

const HeaderSeaLevel = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { currentSeaLevel } = useContext(WeatherContext);

  if (!currentSeaLevel) {
    return null;
  }

  return (
    <SeaLevelContainer>
      <Link to="/sea-level">
        <Text>
          {currentSeaLevel.fi_preferred_measurement === 'n2000' ? t('N2000') : t('Mean Water')}{' '}
          <Value>
            {(currentSeaLevel.fi_preferred_measurement === 'n2000'
              ? currentSeaLevel.observation?.n2000
              : currentSeaLevel.observation?.mw) || '-'}{' '}
            {t('cm')}
          </Value>
        </Text>
        <Name>{currentSeaLevel.mareograph_station?.short_name || '-'}</Name>
      </Link>
    </SeaLevelContainer>
  );
};

export default HeaderSeaLevel;
