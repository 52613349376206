import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserContext';

export const NewInvoicingContext = createContext();

export const NewInvoicingProvider = ({ children }) => {
  const { namespace, apiCall, user, modules, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [invoicePageVisited, setInvoicePageVisited] = useState(false);

  const [units, setUnits] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [taxClasses, setTaxClasses] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [invoiceDeliveryMethods, setInvoiceDeliveryMethods] = useState([]);
  const [isoCountries, setIsoCountries] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [defaultCountry, setDefaultCountry] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [invoiceSummaries, setInvoiceSummaries] = useState({});

  const [invoiceLinks, setInvoiceLinks] = useState([]);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const days = [
    { label: t('Public holidays'), value: 'public_holiday' },
    { label: t('Monday'), value: 'mon' },
    { label: t('Tuesday'), value: 'tue' },
    { label: t('Wednesday'), value: 'wed' },
    { label: t('Thursday'), value: 'thu' },
    { label: t('Friday'), value: 'fri' },
    { label: t('Saturday'), value: 'sat' },
    { label: t('Sunday'), value: 'sun' },
  ];

  const daysWithoutHolidays = [
    { label: t('Monday'), value: 'mon' },
    { label: t('Tuesday'), value: 'tue' },
    { label: t('Wednesday'), value: 'wed' },
    { label: t('Thursday'), value: 'thu' },
    { label: t('Friday'), value: 'fri' },
    { label: t('Saturday'), value: 'sat' },
    { label: t('Sunday'), value: 'sun' },
  ];

  const numbersInMonth = [
    { label: '1.', value: 1 },
    { label: '2.', value: 2 },
    { label: '3.', value: 3 },
    { label: '4.', value: 4 },
    { label: '5.', value: 5 },
    { label: '6.', value: 6 },
    { label: '7.', value: 7 },
    { label: '8.', value: 8 },
    { label: '9.', value: 9 },
    { label: '10.', value: 10 },
    { label: '11.', value: 11 },
    { label: '12.', value: 12 },
    { label: '13.', value: 13 },
    { label: '14.', value: 14 },
    { label: '15.', value: 15 },
    { label: '16.', value: 16 },
    { label: '17.', value: 17 },
    { label: '18.', value: 18 },
    { label: '19.', value: 19 },
    { label: '20.', value: 20 },
    { label: '21.', value: 21 },
    { label: '22.', value: 22 },
    { label: '23.', value: 23 },
    { label: '24.', value: 24 },
    { label: '25.', value: 25 },
    { label: '26.', value: 26 },
    { label: '27.', value: 27 },
    { label: '28.', value: 28 },
    { label: '29.', value: 29 },
    { label: '30.', value: 30 },
    { label: t('31. or last day of the month'), value: 31 },
  ];

  const months = [
    { label: t('January'), value: 1 },
    { label: t('February'), value: 2 },
    { label: t('March'), value: 3 },
    { label: t('April'), value: 4 },
    { label: t('May'), value: 5 },
    { label: t('June'), value: 6 },
    { label: t('July'), value: 7 },
    { label: t('August'), value: 8 },
    { label: t('September'), value: 9 },
    { label: t('October'), value: 10 },
    { label: t('November'), value: 11 },
    { label: t('December'), value: 12 },
  ];

  const getSummaries = useCallback(async () => {
    if (
      invoicePageVisited &&
      user.permissions.includes('view_invoicing') &&
      modules.invoicing_v2_module === 'enabled'
    ) {
      let result;
      try {
        result = await apiCall('get', 'invoicing/v2/summary');
      } catch (e) {
        setDefaultCountry(null);
        setDefaultCurrency(null);
        setInvoiceSummaries({});
      }

      if (result?.status === 200) {
        if (result?.data?.defaults) {
          setDefaultCountry(result.data.defaults.country_code);
          setDefaultCurrency(result.data.defaults.currency_code);
        }
        if (result?.data?.invoices) {
          setInvoiceSummaries(result.data.invoices);
        }
      }
    }
  }, [apiCall, invoicePageVisited, modules.invoicing_v2_module, user.permissions]);

  useEffect(() => {
    getSummaries();
  }, [invoicePageVisited]); // eslint-disable-line

  const getLinks = useCallback(async () => {
    if (
      invoicePageVisited &&
      user.permissions.includes('view_invoicing') &&
      modules.invoicing_v2_module === 'enabled'
    ) {
      let result;
      try {
        result = await apiCall('get', 'invoicing/v2/links');
      } catch (e) {
        setInvoiceLinks([]);
      }

      if (result?.status === 200) {
        if (result?.data?.results) {
          setInvoiceLinks(result.data.results);
        }
      }
    }
  }, [apiCall, invoicePageVisited, modules.invoicing_v2_module, user.permissions]);

  useEffect(() => {
    getLinks();
  }, [invoicePageVisited]); // eslint-disable-line

  useUserSocket('invoicing-invoices-changed', getSummaries);

  const getCustomers = useCallback(async () => {
    let result;
    try {
      result = await apiCall('get', 'invoicing/v2/customers');
    } catch (e) {
      setCustomers([]);
    }

    if (result?.data?.results?.customers && result.status === 200) {
      setCustomers(
        result.data.results.customers.map(u => {
          return {
            label: u.name,
            value: u.customer_number,
            data: u,
          };
        })
      );
    }
  }, [apiCall]);

  const customersChanged = useCallback(() => {
    if (
      invoicePageVisited &&
      user.permissions.includes('view_invoicing') &&
      modules.invoicing_v2_module === 'enabled'
    ) {
      getCustomers();
    }
  }, [getCustomers, invoicePageVisited, modules.invoicing_v2_module, user.permissions]);

  useUserSocket('invoicing-customers-changed', customersChanged);

  useEffect(() => {
    async function fetchUnits() {
      const { data } = await apiCall('get', 'invoicing/v2/units');
      if (mounted.current && data?.results?.units) {
        setUnits(
          data.results.units.map(u => {
            return {
              label: u.description + ' (' + u.name + ')',
              value: u.name,
              data: u,
            };
          })
        );
      }
    }

    async function fetchCurrencies() {
      const { data } = await apiCall('get', 'invoicing/v2/currencies');
      if (mounted.current && data?.results) {
        setCurrencies(
          data.results.map(u => {
            return {
              label: u.name + ' (' + u.code + ')',
              value: u.code,
              data: u,
            };
          })
        );
      }
    }

    async function fetchTaxClasses() {
      const { data } = await apiCall('get', 'invoicing/v2/tax-classes');
      if (mounted.current && data?.results) {
        setTaxClasses(
          data.results.map(u => {
            return {
              label: u.name,
              value: u.code,
              data: u,
            };
          })
        );
      }
    }

    if (
      invoicePageVisited &&
      user.permissions.includes('view_invoicing') &&
      modules.invoicing_v2_module === 'enabled'
    ) {
      fetchUnits();
      fetchCurrencies();
      fetchTaxClasses();
      getCustomers();
    }
    // eslint-disable-next-line
  }, [invoicePageVisited]);

  const getCustomerStartData = async () => {
    let result;
    try {
      result = await apiCall('get', 'invoicing/v2/account-types');
    } catch (e) {
      setAccountTypes([]);
    }
    if (result?.data?.results && result.status === 200) {
      setAccountTypes(
        result.data.results.map(u => {
          return {
            label: u.name,
            value: u.code,
            data: u,
          };
        })
      );
    }

    let result2;
    try {
      result2 = await apiCall('get', 'invoicing/v2/invoice-delivery-methods');
    } catch (e) {
      setInvoiceDeliveryMethods([]);
    }
    if (result2?.data.results && result2.status === 200) {
      setInvoiceDeliveryMethods(
        result2.data.results.map(u => {
          return {
            label: u.name,
            value: u.code,
            data: u,
          };
        })
      );
    }

    let result3;
    try {
      result3 = await apiCall('get', 'invoicing/v2/iso-countries');
    } catch (e) {
      setIsoCountries([]);
    }
    if (result3?.data.results && result3.status === 200) {
      setIsoCountries(
        result3.data.results.map(u => {
          return {
            label: u.name + ' (' + u.code + ')',
            value: u.code,
            data: u,
          };
        })
      );
    }
  };

  return (
    <NewInvoicingContext.Provider
      value={{
        namespace,
        days,
        daysWithoutHolidays,
        numbersInMonth,
        months,
        units,
        currencies,
        taxClasses,
        getCustomerStartData,
        accountTypes,
        invoiceDeliveryMethods,
        isoCountries,
        customers,
        defaultCountry,
        defaultCurrency,
        invoiceSummaries,
        invoiceLinks,
        invoicePageVisited,
        setInvoicePageVisited,
      }}
    >
      {children}
    </NewInvoicingContext.Provider>
  );
};
