import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import deepEqual from 'deep-equal';
import { UserContext } from './UserContext';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const { activeAlerts, apiCall, modules, user } = useContext(UserContext);
  const [alerts, setAlerts] = useState([]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const resolveAlerts = useCallback(
    async newAlerts => {
      const resolvedAlerts = await Promise.all(
        newAlerts.map(async alert => {
          switch (alert.type) {
            case 'general':
              if (alert.message) {
                return {
                  id: alert.id,
                  type: alert.type,
                  message: alert.message,
                  message_body: alert.message_body,
                  level: alert.level ? alert.level : 1,
                };
              }
              break;
            case 'incident':
            case 'event':
              if (
                alert.type_id &&
                modules.incident_module === 'enabled' &&
                user.permissions.includes('view_incident')
              ) {
                const result = await apiCall('get', 'incident/' + alert.type_id);
                if (result?.status === 200 && result.data?.incident) {
                  return {
                    id: alert.id,
                    type: alert.type,
                    incident: result.data.incident,
                  };
                }
              }
              break;
            default:
              break;
          }
          return null;
        })
      );
      if (mounted.current) {
        setAlerts(previous => {
          if (
            !deepEqual(
              previous,
              resolvedAlerts.filter(item => item),
              true
            )
          ) {
            return resolvedAlerts.filter(item => item);
          }
          return previous;
        });
      }
    },
    [apiCall, modules.incident_module, user.permissions]
  );

  useEffect(() => {
    resolveAlerts(activeAlerts);
  }, [activeAlerts, resolveAlerts]);

  const addAlert = async (type, data) => {
    if (!user.permissions.includes('manage_all_user')) {
      return;
    }
    const params = {
      type,
    };
    switch (type) {
      case 'general':
        if (!data.message) {
          return;
        }
        params.message = data.message;
        params.message_body = data.message_body || '';
        params.level = data.level ? data.level : 1;
        break;
      case 'incident':
        if (!data.id) {
          return;
        }
        params.type_id = data.id;
        params.level = data.level_of_emergency;
        break;
      case 'event':
        if (!data.id) {
          return;
        }
        params.type_id = data.id;
        params.level = 1;
        break;
      default:
        return;
    }
    await apiCall('post', 'alert', params);
  };

  const closeAlert = index => {
    setAlerts(previous => {
      const newAlerts = [...previous];
      newAlerts.splice(index, 1);
      return newAlerts;
    });
  };

  const removeAlert = async alert => {
    if (alert?.id) {
      await apiCall('delete', `alert/${alert.id}`);
    }
  };

  return (
    <AlertContext.Provider
      value={{
        alerts,
        alertsHeight: alerts?.length * 40,
        closeAlert,
        addAlert,
        removeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
