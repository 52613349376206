import { markDownData } from './markdown-test.js';

const data = [
  {
    key: 'api-keys-bound-function-column',
    title: 'Bound function',
    rows: [
      'Microservice function(s) that the API key is bound to.',
      'Every function can be bound only to one API key.',
    ],
  },
  {
    key: 'access-control-login',
    title: 'Login',
    rows: ['Allows user to log in to the system.'],
  },
  {
    key: 'access-control-basic-user-actions',
    title: 'Basic user actions',
    rows: ['Allows user to perform basic actions inside the application.'],
  },
  {
    key: 'access-control-manage-admin-users',
    title: 'Manage admin users',
    rows: [
      'Allows user to add admin users and create admin registration codes.',
      'To be able to add a new code, the registration code permission needs to be enabled as well.',
    ],
  },
  {
    key: 'access-control-manage-second-admin-users',
    title: 'Manage second admin users',
    rows: [
      'Allows user to add second admin users and create second admin registration codes.',
      'To be able to add a new code, the registration code permission needs to be enabled as well.',
    ],
  },
  {
    key: 'access-control-manage-first-users',
    title: 'Manage first users',
    rows: [
      'Allows user to add first users and create first user registration codes.',
      'To be able to add a new code, the registration code permission needs to be enabled as well.',
    ],
  },
  {
    key: 'access-control-manage-normal-users',
    title: 'Manage normal users',
    rows: [
      'Allows user to add normal users and create normal user registration codes.',
      'To be able to add a new code, the registration code permission needs to be enabled as well.',
    ],
  },
  {
    key: 'access-control-manage-inactive-users',
    title: 'Manage inactive users',
    rows: ['Allows user to manage inactive users.'],
  },
  {
    key: 'access-control-manage-registration-codes',
    title: 'Manage registration codes',
    rows: [
      'Allows user to manage registration codes.',
      'User will also need a permission to manage the user type to add a new code.',
    ],
  },
  {
    key: 'access-control-manage-permissions',
    title: 'Manage permissions',
    rows: ['Allows user to manage access control and permissions.'],
  },
  {
    key: 'access-control-manage-translations',
    title: 'Manage translations',
    rows: ['Allows user to update and create new translations.'],
  },
  {
    key: 'access-control-manage-settings',
    title: 'Manage settings',
    rows: ['Allows user to enable/disable modules and create default settings for timestamp filtering.'],
  },
  {
    key: 'access-control-manage-api-keys',
    title: 'Manage API keys',
    rows: ['Allows user to add new API keys, bound functions to API keys and manage API key priorities.'],
  },
  {
    key: 'access-control-send-push-notifications',
    title: 'Send push notifications',
    rows: ['Allows user to send push notifications.'],
  },
  {
    key: 'access-control-delete-push-notifications',
    title: 'Delete push notification',
    rows: ['Allows user to delete push notifications.'],
  },
  {
    key: 'access-control-send-rta-via-web-form',
    title: 'Send RTA via web form',
    rows: ['Allows user to send RTA via web form.'],
  },
  {
    key: 'access-control-add-timestamps-manually',
    title: 'Add timestamps manually',
    rows: ['Allows user to add timestamps manually into the system.'],
  },
  {
    key: 'access-control-manage-port-calls',
    title: 'Manage port calls',
    rows: ['Allows user to manage port calls.'],
  },
  {
    key: 'access-control-view-vis-information',
    title: 'View VIS information',
    rows: ['Allows user to view VIS information.'],
  },
  {
    key: 'access-control-send-vis-text-messages',
    title: 'Send VIS text messages',
    rows: ['Allows user to send VIS text messages.'],
  },
  {
    key: 'access-control-send-rta-via-vis',
    title: 'Send RTA via VIS',
    rows: ['Allows user to send RTA via VIS.'],
  },
  {
    key: 'access-control-manage-ports',
    title: 'Manage ports',
    rows: ['Allows user to manage ports.'],
  },
  {
    key: 'access-control-manage-consignee-users',
    title: 'Manage consignee users',
    rows: ['Allows user to manage consignee users.'],
  },
  {
    key: 'access-control-view-own-queue-nominations',
    title: 'View own queue nominations',
    rows: ['Allows user to view own queue nominations.'],
  },
  {
    key: 'access-control-manage-own-queue-nominations',
    title: 'Manage own queue nominations',
    rows: ['Allows user to manage own queue nominations.'],
  },
  {
    key: 'access-control-view-all-queue-nominations',
    title: 'View all queue nominations',
    rows: ['Allows user to view all queue nominations.'],
  },
  {
    key: 'access-control-manage-all-queue-nominations',
    title: 'Manage all queue nominations',
    rows: ['Allows user to manage all queue nominations.'],
  },
  {
    key: 'access-control-view-queue-slot-reservations',
    title: 'View queue slot reservations',
    rows: ['Allows user to view queue slot reservations.'],
  },
  {
    key: 'access-control-manage-queue-slot-reservations',
    title: 'Manage queue slot reservations',
    rows: ['Allows user to manage queue slot reservations.'],
  },
  {
    key: 'access-control-manage-berths',
    title: 'Manage berths',
    rows: ['Allows user to manage berths.'],
  },
  {
    key: 'access-control-view-berths',
    title: 'View berths',
    rows: ['Allows user to view berths.'],
  },
  {
    key: 'access-control-manage-berth-reservations',
    title: 'Manage berth reservations',
    rows: ['Allows user to manage berth reservations.'],
  },
  {
    key: 'access-control-view-berth-reservations',
    title: 'View berth reservations',
    rows: ['Allows user to view berth reservations.'],
  },
  {
    key: 'access-control-manage-own-users',
    title: 'Manage own users',
    rows: ['Allows user to manage own users.'],
  },
  {
    key: 'access-control-manage-all-users',
    title: 'Manage all users',
    rows: ['Allows user to manage all users.'],
  },
  {
    key: 'access-control-manage-predefined-port-actors-on-map',
    title: 'Manage predefined port actors on map',
    rows: ['Allows user to manage predefined port actors on map.'],
  },
  {
    key: 'access-control-manage-vessel-properties',
    title: 'Manage vessel properties',
    rows: ['Allows user to manage vessel properties.'],
  },
  {
    key: 'access-control-manage-geodata',
    title: 'Manage geodata',
    rows: ['Allows user to manage geodata.'],
  },
  {
    key: 'access-control-enable/disable-emergency-shutdown',
    title: 'Enable/disable emergency shutdown',
    rows: ['Allows user to enable/disable emergency shutdown.'],
  },
  {
    key: 'access-control-view-loading-jobs',
    title: 'View loading jobs',
    rows: ['Allows user to view loading jobs.'],
  },
  {
    key: 'access-control-manage-loading-jobs',
    title: 'Manage loading jobs',
    rows: ['Allows user to manage loading jobs.'],
  },
  {
    key: 'access-control-view-berth-planning-tool',
    title: 'View berth planning tool',
    rows: ['Allows user to view berth planning tool.'],
  },
  {
    key: 'access-control-manage-berth-planning-tool',
    title: 'Manage berth planning tool',
    rows: ['Allows user to manage berth planning tool.'],
  },
  {
    key: 'access-control-manage-holidays',
    title: 'Manage holidays',
    rows: ['Allows user to manage holidays.'],
  },
  {
    key: 'access-control-manage-predictive-berth-planning',
    title: 'Manage predictive berth planning',
    rows: ['Allows user to manage predictive berth planning.'],
  },
  {
    key: 'access-control-view-fleet-data',
    title: 'View fleet data',
    rows: ['Allows user to view fleet data.'],
  },
  {
    key: 'access-control-manage-fleet-data',
    title: 'Manage fleet data',
    rows: ['Allows user to manage fleet data.'],
  },
  {
    key: 'access-control-view-pbp-data',
    title: 'View PBP data',
    rows: ['Allows user to view predictive berth planning tool data.'],
  },
  {
    key: 'access-control-view-crane-reservation-data',
    title: 'View crane reservation data',
    rows: ['Allows user to view crane reservation data.'],
  },
  {
    key: 'access-control-view-invoicing',
    title: 'View invoicing',
    rows: ['Allows user to view invoicing.'],
  },
  {
    key: 'access-control-manage-invoicing',
    title: 'Manage invoicing',
    rows: ['Allows user to manage invoicing.'],
  },
  {
    key: 'access-control-view-service-orders',
    title: 'View service orders',
    rows: ['Allows user to view service orders.'],
  },
  {
    key: 'access-control-manage-service-orders',
    title: 'Manage service orders',
    rows: ['Allows user to manage service orders.'],
  },
  {
    key: 'access-control-manage-service-order-services',
    title: 'Manage service order services',
    rows: ['Allows user to manage service order services.'],
  },
  {
    key: 'access-control-manage-service-order-ack-&-deliveries',
    title: 'Manage service order ack & deliveries',
    rows: ['Allows user to manage service order acknowledgements and deliveries.'],
  },
  {
    key: 'access-control-admin-service-order',
    title: 'Admin service order',
    rows: ['Allows user to admin service order.'],
  },
];

export const apiCallFake = async (method, path, params) => {
  await new Promise(res => setTimeout(res, 500));

  const foundData = data.find(k => k.key === params.key);
  if (foundData) {
    return { data: foundData, status: 200 };
  } else {
    return {
      data: {
        title: params.key,
        rows: ['Placeholder text row 1.', 'Placeholder text row 2.'],
      },
      status: 200,
    };
  }
};

export const apiCallFakeForMarkDown = async () => {
  await new Promise(res => setTimeout(res, 500));
  return {
    data: markDownData,
    status: 200,
  };
};
