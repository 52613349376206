import React, { useContext, useState, useEffect, useRef } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const InboundVessels = () => {
  const { namespace, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Inbound vessels | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'vessel_name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'inbound-vessels', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const inboundVessels = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Source'),
      dataIndex: 'source',
      key: 'source',
      sortableKey: 'source',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('Name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('From'),
      dataIndex: 'departing_from',
      key: 'departing_from',
      sortableKey: 'departing_from',
    },
    {
      title: t('ETD'),
      dataIndex: 'departing_from_etd',
      key: 'departing_from_etd',
      sortableKey: 'departing_from_etd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ATD'),
      dataIndex: 'departing_from_atd',
      key: 'departing_from_atd',
      sortableKey: 'departing_from_atd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
  ];

  return (
    <Layout pagename={t('Inbound Vessels')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={inboundVessels}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search by name or exact IMO')}
        />
      </Page>
    </Layout>
  );
};

export default InboundVessels;
