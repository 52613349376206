import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import NotificationMessageDecisions from './NotificationMessageDecisions';
import { formatSender } from '../../../utils/utils';
import ProfileCircle from '../../ui/ProfileCircle';

const NotificationMessageListItemContainer = styled.div`
  max-width: ${props => (props.child ? '95%' : '100%')};
  align-self: flex-start;
  margin-left: ${props => (props.child ? '5%' : '0')};
  display: flex;
  margin-top: ${props => (props.child ? '15px' : '30px')};
`;

const NotificationMessageListItemText = styled.div`
  font-size: ${({ theme }) => theme.text.small};
  border-radius: ${props => (props.decision ? '4px 4px 0 0' : '4px')};
  padding: 0.45rem;
  font-weight: ${props => (props.newItem ? 700 : 400)};
  background-color: ${({ theme }) => theme.color.beige};
`;

const NotificationMessageListItemTime = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.tiny};
`;

const NotificationMessageListItemSender = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap};
`;

const ProfileCircleContainer = styled.div`
  display: flex;
  height: 40px;
  width: 48px;
  margin-top: 7px;
`;

const MessageContainer = styled.div`
  display: inline-block;
`;

const NotificationMessageListItem = ({ child, data, viewPortTop, viewPortBottom, scrolling, onRead, messageSent }) => {
  const [newItem, setNewItem] = useState(!data.read);
  const [oneReadSend, setOneReadSend] = useState(false);

  let timer = useRef();
  let ref = useRef();

  const handleMarkRead = async () => {
    if (!data.read && !oneReadSend) {
      setOneReadSend(true);
      onRead(data.id);
    }
  };

  useEffect(() => {
    if (ref.current && newItem) {
      const rect = ref.current.getClientRects();
      if (rect[0] && rect[0].top > viewPortTop && rect[0].top < viewPortBottom && !scrolling) {
        timer.current = setTimeout(() => {
          setNewItem(false);
          handleMarkRead();
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timer.current);
    };
  });

  return (
    <NotificationMessageListItemContainer child={child} ref={ref}>
      <ProfileCircleContainer>
        <ProfileCircle small givenUser={data.sender} />
      </ProfileCircleContainer>
      <MessageContainer>
        <NotificationMessageListItemTime>
          <NotificationMessageListItemSender>{formatSender(data.sender)}</NotificationMessageListItemSender>
          {dayjs(data.created_at).format('DD.MM.YYYY HH:mm')}
        </NotificationMessageListItemTime>
        <NotificationMessageListItemText newItem={newItem} decision={!!data.decision}>
          {data.message}
        </NotificationMessageListItemText>
        {data.decision && (
          <NotificationMessageDecisions newItem={newItem} data={data.decision} messageSent={messageSent} />
        )}
      </MessageContainer>
    </NotificationMessageListItemContainer>
  );
};

export default NotificationMessageListItem;
