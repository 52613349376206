import React, { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';

import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import { UserContext } from '../../../context/UserContext';
import { TIME_FORMAT } from '../../../utils/constants';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const RowContainer = styled.div`
  background-color: #ff8c00;
  opacity: 0.3;
  height: 100%;
  position: absolute;
  z-index: 41;
`;

const Container = styled.div`
  font-size: 11px;
  padding: 4px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 4px;
`;

const Data = styled.div`
  display: flex;
`;

const Headers = styled.div`
  margin-right: 6px;
`;

const Header = styled.div`
  margin-bottom: 3px;
`;

const Values = styled.div`
  font-weight: 700;
`;

const Value = styled.div`
  margin-bottom: 3px;
`;

const WindWarningRow = ({ berthId, bollardId }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { canvasWidth, canvasTimeStart, canvasTimeEnd, windWarnings } = useContext(BerthPlanningToolContext);

  const warnings = [];

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();

  if (windWarnings) {
    const berthData = windWarnings[berthId];
    if (berthData) {
      const bollardData = berthData[bollardId];
      if (bollardData) {
        bollardData.forEach(b => {
          warnings.push({
            left:
              dayjs.duration(dayjs(b.start.time).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels,
            width: dayjs.duration(dayjs(b.end.time).diff(b.start.time)).asMilliseconds() * millisecondInPixels,
            from: dayjs(b.start.time).format(TIME_FORMAT),
            to: dayjs(b.end.time).format(TIME_FORMAT),
          });
        });
      }
    }
  }

  const TooltipForWindWarning = (from, to) => {
    return (
      <Container>
        <Title>{t('Wind restriction')}</Title>
        <Data>
          <Headers>
            <Header>{t('Berth')}:</Header>
            <Header>{t('Bollard')}:</Header>
            <Header>{t('From')}:</Header>
            <Header>{t('To')}:</Header>
          </Headers>
          <Values>
            <Value>{berthId}</Value>
            <Value>{bollardId}</Value>
            <Value>{from}</Value>
            <Value>{to}</Value>
          </Values>
        </Data>
      </Container>
    );
  };

  return (
    <>
      {warnings.map((w, i) => (
        <Tooltip
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a' }}
          key={i}
          title={TooltipForWindWarning(w.from, w.to)}
        >
          <RowContainer id="wind-warning" style={{ left: w.left, width: w.width }}></RowContainer>
        </Tooltip>
      ))}
    </>
  );
};

export default WindWarningRow;
