import React, { useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import Footer from '../../images/footers/Footer-Introduction.svg?react';
import useRegister from '../../views/useRegister';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Input from '../ui/Input';
import Form from '../ui/Form';
import Icon from '../ui/Icon';
import { UserContext } from '../../context/UserContext';
import GdprPopup from './GdprPopup';

const LeftInfoPanel = styled.div`
  width: 400px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  background-color: white;
  color: ${({ theme }) => theme.color.almost_black};
  overflow: hidden;

  @media (max-width: 899px) {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    color: #8a8a8a;
  }

  i {
    height: 22px;
    width: 22px;
  }

  svg {
    height: 22px;
    width: 22px;
  }
`;

const StyledForm = styled(Form)`
  position: relative;
  margin: 20px 26px 40px 26px;
  input,
  button,
  select {
    width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const BottomButtonContainer = styled.div`
  margin-top: 24px;
`;

const BottomButton = styled.button`
  background-color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.deep_blue)};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 0px;
  text-align: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: 36px;
  text-transform: uppercase;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    background-color: ${props => (props.disabled ? '#a8a8a8' : '#729bc0')};
  }
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 48px;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const GdprConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
  padding-bottom: 12px;
  padding-top: 12px;
`;

const ErrorContainer = styled.div`
  height: 20px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.warning};
`;

const ErrorMessage = styled.div`
  display: block;
`;

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const GdprInfoText = styled.div`
  text-align: center;
`;

const LandingPageSignUpPanel = ({ closeModal }) => {
  const { namespace, registerLoader, showRegisterLoader } = useContext(UserContext);
  const { i18n } = useTranslation(namespace);
  const t = i18n.getFixedT(i18n.language, namespace);

  const { handleRegister, info, setInfo, disabled } = useRegister();

  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [gdprPopupOpen, setGdprPopupOpen] = useState(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);

  useOnClickOutside(ref, () => {
    if (gdprPopupOpen) {
      setGdprPopupOpen(isOpen);
    } else {
      setInfo({ ...info, checked: false, firstName: '', lastName: '', code: '', email: '', password: '', confirm: '' });
      setIsOpen(false);
    }
  });

  const handleChecked = () => {
    setInfo({ ...info, checked: true });
  };

  const handleRegistering = async e => {
    showRegisterLoader(true);
    const res = await handleRegister(e);
    if (res?.['action-required']) {
      closeModal();
    }
  };

  useEffect(() => {
    if (info.password !== '' && info.confirm !== '' && info.password !== info.confirm) {
      setPasswordsDoNotMatch(true);
    } else {
      setPasswordsDoNotMatch(false);
    }
  }, [info.password, info.confirm]);

  return (
    <LeftInfoPanel>
      <IconContainer onClick={closeModal}>
        <Icon type="close-2" />
      </IconContainer>
      <Header>
        <HeaderText>{t('Sign up')}</HeaderText>
      </Header>
      <StyledForm onSubmit={handleRegistering} autoComplete="off">
        <Label>{t('First name')}</Label>
        <Input
          name={t('First name')}
          type="text"
          onChange={e => setInfo({ ...info, firstName: e.target.value })}
          autoFocus
          required
        />
        <Label>{t('Last name')}</Label>
        <Input
          name={t('Last name')}
          type="text"
          onChange={e => setInfo({ ...info, lastName: e.target.value })}
          required
        />
        <Label>{t('Code')}</Label>
        <Input name={t('Code')} type="text" onChange={e => setInfo({ ...info, code: e.target.value })} required />
        <Label>{t('Email / Username')}</Label>
        <Input name={t('Email')} type="text" onChange={e => setInfo({ ...info, email: e.target.value })} required />
        <Label>{t('Password')}</Label>
        <Input
          name={t('Password')}
          type="password"
          onChange={e => setInfo({ ...info, password: e.target.value })}
          required
        />
        <Label>{t('Password again')}</Label>
        <Input
          name={t('Password')}
          type="password"
          onChange={e => setInfo({ ...info, confirm: e.target.value })}
          required
        />
        <GdprConfirmation>
          <GdprPopup
            isOpen={gdprPopupOpen}
            setIsOpen={setGdprPopupOpen}
            handleChecked={handleChecked}
            checked={info.checked}
            orig={false}
          />
        </GdprConfirmation>
        {info.checked ? (
          <GdprInfoText>{t('The terms and conditions have been accepted.')}</GdprInfoText>
        ) : (
          <GdprInfoText>
            {t('The terms and conditions need to be read through and accepted before continuing.')}
          </GdprInfoText>
        )}
        <BottomButtonContainer>
          <Spin spinning={registerLoader}>
            <BottomButton disabled={disabled}>{t('Continue')}</BottomButton>
          </Spin>
        </BottomButtonContainer>
        <ErrorContainer>
          {passwordsDoNotMatch && <ErrorMessage>{t('Passwords do not match!')}</ErrorMessage>}
        </ErrorContainer>
      </StyledForm>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LeftInfoPanel>
  );
};

export default LandingPageSignUpPanel;
