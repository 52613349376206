import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';
import InputNumber from 'antd/es/input-number';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../../context/UserContext';
import ButtonLight from '../../ui/ButtonLight';

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Header = styled.div`
  font-weight: 600;
  margin-right: 44px;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0px 2px 0px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const DeliverServiceOrderModalLine = ({ data, sendData, update }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    email: update ? data.deliveries[0].email : data.service_provider_or_ack_email,
    phone: update ? data.deliveries[0].phone : data.service_provider_or_ack_phone,
    notes: update ? data.deliveries[0].notes : null,
    delivery_time: update ? data.deliveries[0].delivery_time : data.requested_delivery_time,
    quantity: update ? data.deliveries[0].quantity : data.quantity,
  });

  const updateNotesSplit = e => {
    let newInputData = { ...inputData };
    newInputData['notes'] = e.target.value;
    setInputData(newInputData);
  };

  const updateEmailSplit = e => {
    let newInputData = { ...inputData };
    newInputData['email'] = e.target.value;
    setInputData(newInputData);
  };

  const updateQuantitySplit = e => {
    let newInputData = { ...inputData };
    newInputData['quantity'] = e.target.value;
    setInputData(newInputData);
  };

  const updatePhoneSplit = e => {
    let newInputData = { ...inputData };
    newInputData['phone'] = e.target.value;
    setInputData(newInputData);
  };

  const updateDeliveryTimeSplit = value => {
    let newInputData = { ...inputData };
    newInputData['delivery_time'] = value ? value.format() : value;
    setInputData(newInputData);
  };

  const createDataToBeSent = status => {
    if (update) {
      sendData(
        {
          service_delivery: {
            id: data.deliveries[0].id,
            status: status,
            quantity: parseFloat(inputData.quantity),
            delivery_time: inputData.delivery_time,
            notes: inputData.notes,
            email: inputData.email,
            phone: inputData.phone,
          },
        },
        data,
        status
      );
    } else {
      sendData(
        {
          service_delivery: {
            order_line_id: data.id,
            status: status,
            quantity: parseFloat(inputData.quantity),
            delivery_time: inputData.delivery_time,
            notes: inputData.notes,
            email: inputData.email,
            phone: inputData.phone,
          },
        },
        data,
        status
      );
    }
  };

  const getStatus = row => {
    if (row.deliveries.length === 0) {
      if (row.ack.status === 'accepted') {
        return 'acknowledged';
      } else {
        return 'rejected';
      }
    } else {
      return row.deliveries[0].status;
    }
  };

  const status = getStatus(data);
  let edit = status !== 'rejected' && status !== 'done' && status !== 'cancelled';

  if (!user.permissions.includes('manage_service_order_ack_delivery')) {
    edit = false;
  }

  return (
    <InfoContainer>
      <RowContainer>
        <Container style={{ margin: '4px 0 4px 0' }}>
          <Header>{t('Delivery or cancellation')}</Header>
        </Container>
        <Container>
          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
            <RowHeader>{t('Quantity')}</RowHeader>
            <StyledInputNumber
              placeholder={t('Quantity')}
              value={inputData.quantity || ''}
              decimalSeparator={','}
              min={0}
              onChange={e => updateQuantitySplit({ target: { value: e } })}
              style={{ color: '#4a4a4a' }}
            />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
            <RowHeader>{t('Delivery time')}</RowHeader>
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              style={{
                width: '100%',
                color: '#4a4a4a',
              }}
              value={inputData.delivery_time ? dayjs(inputData.delivery_time) : null}
              onChange={value => updateDeliveryTimeSplit(value)}
              placeholder={t('Delivery time')}
              required
              disabled={!edit}
            />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
            <RowHeader>{t('Delivery email')}</RowHeader>
            <Input
              placeholder={t('Email')}
              value={inputData.email || ''}
              onChange={e => updateEmailSplit(e)}
              style={{ color: '#4a4a4a' }}
            />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
            <RowHeader>{t('Delivery phone')}</RowHeader>
            <Input
              placeholder={t('Phone number')}
              value={inputData.phone || ''}
              onChange={e => updatePhoneSplit(e)}
              style={{ color: '#4a4a4a' }}
            />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
            <RowHeader>{inputData.status === 'cancelled' ? t('Cancellation note') : t('Delivery note')}</RowHeader>
            <Input
              placeholder={inputData.status === 'cancelled' ? t('Cancellation note') : t('Delivery note')}
              value={inputData.notes || ''}
              onChange={e => updateNotesSplit(e)}
            />
          </RowContainerSplit>
          <RowContainerSplit
            style={{ width: 'calc(100% / 5)', marginTop: '23px', marginLeft: '12px', display: 'flex' }}
          >
            <Popconfirm
              title={t('Deliver service?')}
              onConfirm={() => createDataToBeSent('done')}
              okText={t('Yes')}
              okType="primary"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery"
              key="action-1"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight disabled={data.disabled}>{t('Deliver')}</ButtonLight>
            </Popconfirm>
            <Popconfirm
              title={t('Cancel service?')}
              onConfirm={() => createDataToBeSent('cancelled')}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-cancel"
              key="action-2"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight disabled={data.disabled} cancel style={{ marginLeft: '6px' }}>
                {t('Cancel')}
              </ButtonLight>
            </Popconfirm>
          </RowContainerSplit>
        </Container>
      </RowContainer>
    </InfoContainer>
  );
};

export default DeliverServiceOrderModalLine;
