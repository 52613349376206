import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from 'antd/es/select';

import { UserContext } from '../../context/UserContext';
import { WeatherContext } from '../../context/WeatherContext';

const LabelInfo = styled.div`
  margin: 0 10px;
`;

const WeatherStationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 10px 12px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
`;

const Title = styled.div`
  align-self: flex-start;
  font-size: 22px;
  font-weight: 400;
  margin-left: 10px;
`;

const WeatherStationSelect = ({ title }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { stationList, chosenStation, chooseStation } = useContext(WeatherContext);

  const selectStation = value => {
    chooseStation(stationList.find(s => s.uid === value));
  };

  return (
    <Container>
      <Title>{title}</Title>
      <WeatherStationContainer>
        <LabelInfo>{t('Nearest locations:')}</LabelInfo>
        <Select
          style={{ width: 200 }}
          placeholder={t('Select a station')}
          value={chosenStation ? chosenStation.uid : undefined}
          options={stationList.map(station => {
            return { label: station.name, value: station.uid };
          })}
          onChange={selectStation}
        />
      </WeatherStationContainer>
    </Container>
  );
};

export default WeatherStationSelect;
