import React, { useContext } from 'react';

import { CraneContext } from '../../context/CraneContext';
import MapCurrentCrane from '../map/MapCurrentCrane';

const CraneOverlay = ({ zoom, setCraneClicked, predictiveBPTOn }) => {
  const { cranes, cranesUpdated } = useContext(CraneContext);

  return (
    predictiveBPTOn &&
    !!cranes &&
    cranes.map((crane, index) => {
      return (
        <div key={'current-crane-' + index}>
          <MapCurrentCrane
            key={crane.id}
            coordinates={[crane.lon, crane.lat]}
            appliedZoomLevel={zoom}
            name={crane.code}
            setCraneClicked={setCraneClicked}
            cranesUpdated={cranesUpdated}
          />
        </div>
      );
    })
  );
};

export default CraneOverlay;
