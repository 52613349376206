import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import InvoicesPerPortcallList from '../../components/invoicing/InvoicesPerPortcallList';
import SavedInvoicesList from '../../components/invoicing/SavedInvoicesList';
import SentInvoicesList from '../../components/invoicing/SentInvoicesList';
import AutomatedInvoicesList from '../../components/invoicing/AutomatedInvoicesList';
import AutomatedInvoiceExceptionsList from '../../components/invoicing/AutomatedInvoiceExceptionsList';

const AlteredPage = styled(Page)`
  padding-top: 14px;
`;

const InvoiceView = props => {
  const { page } = props.match.params;
  const { namespace } = useContext(UserContext);
  const { invoiceSummaries, invoicePageVisited, setInvoicePageVisited } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Invoices | Port Activity App';

    if (!invoicePageVisited) {
      setInvoicePageVisited(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();

  const onTabChange = e => {
    history.push('/invoicing-invoices/' + e);
  };

  const tabItems = [
    {
      label: t('Invoices per portcall'),
      key: 'per-portcall',
      children: <InvoicesPerPortcallList />,
    },
    {
      label: (
        <span>
          <span>{t('Saved invoices')}</span> (<span>{invoiceSummaries?.unsent || '-'})</span>
        </span>
      ),
      key: 'saved',
      children: <SavedInvoicesList />,
    },
    {
      label: t('Sent invoices'),
      key: 'sent',
      children: <SentInvoicesList />,
    },
    {
      label: t('Automated invoices'),
      key: 'automated',
      children: <AutomatedInvoicesList />,
    },
    {
      label: t('Automated invoice exceptions'),
      key: 'exceptions',
      children: <AutomatedInvoiceExceptionsList />,
    },
  ];

  return (
    <Layout pagename={t('Invoices')}>
      <AlteredPage fullWidth>
        <Tabs defaultActiveKey={page} animated={false} onTabClick={onTabChange} items={tabItems} />
      </AlteredPage>
    </Layout>
  );
};

export default InvoiceView;
