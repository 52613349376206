import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from '../ui/Icon';
import { UserContext } from '../../context/UserContext';
import Tabs from 'antd/es/tabs';
import Switch from 'antd/es/switch';
import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';
import NotificationListMessages from './NotificationListMessages';
import { NotificationContext } from '../../context/NotificationContext';
import NotificationListAddNewMessage from './NotificationListAddNewMessage';
import { debounce } from 'throttle-debounce';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Modal = styled.div`
  position: fixed;
  top: 64px;
  right: 0;
  width: 540px;
  z-index: 999;
  height: calc(100vh - 64px);
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.color.grey_dark};
  padding-top: 12px;

  .ant-tabs-tab {
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
    letter-spacing: 0em;
  }

  .ant-tabs-nav {
    margin: 0 24px 6px 24px;
  }

  .ant-tabs-tabpane {
    position: relative;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    max-width: 540px;
    width: 100vw;
    height: calc(100vh - 134px);
  }
`;

const CloseRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -2px;
`;

const IconContainer = styled.div`
  padding: 0 6px 2px 3px;
  cursor: pointer;
  margin-right: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 24px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: black;
`;

const SwitchLabel = styled.div`
  margin-right: 12px;
  line-height: 22px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 13px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-left: 12px;
  margin-top: 3px;

  button {
    margin-top: 3px;
  }
`;

const HeaderEnd = styled.div`
  display: flex;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: calc(100vh - 400px);
`;

const MoreButton = styled.div`
  margin-left: 18px;
  cursor: pointer;
  margin-top: 4px;
  height: 22px;
  width: 16px;
  border-radius: 4px;

  &:hover {
    background-color: #f8f8f8;
  }

  svg {
    fill: #a6a6a6 !important;
    height: 18px;
    width: 18px;
    transform: rotate(90deg);
    margin-top: -16px;
  }
`;

const Container = styled.div`
  padding: 0 24px;
  height: calc(100vh - ${props => props.newMessageHeight}px);
  overflow-y: auto;
  flex-direction: column-reverse;
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 70px - ${props => props.newMessageHeight}px);
  }
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 58px;
  right: 37px;
  background-color: white;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  z-index: 100;
`;

const PopupRow = styled.div`
  padding: 3px 6px;
  cursor: pointer;
  margin: 2px 0px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    color: #2069ba;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  background-color: rgba(250, 250, 250, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - ${props => props.newMessageHeight}px);
`;

const AllNotificationsModal = ({ close }) => {
  const { namespace, useUserSocket, apiCall } = useContext(UserContext);
  const { portcallSummaryCount, portNotificationSummary } = useContext(NotificationContext);

  const { t } = useTranslation(namespace);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);

  const [showStartLoading, setShowStartLoading] = useState(true);
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');

  const [scroll, setScroll] = useState(false);

  const [messageAmount, setMessageAmount] = useState(1);

  const [morePopupOpen, setMorePopupOpen] = useState(false);
  const [newMessageHeight, setNewMessageHeight] = useState(258);

  const [readRememberArray, setReadRememberArray] = useState([]);
  const [readArray, setReadArray] = useState([]);
  const [scrolling, setScrolling] = useState(false);

  let timeout = useRef();
  let readtimer = useRef();

  const onScroll = () => {
    if (!scrolling) {
      setScrolling(true);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      setScrolling(false);
    }, 500);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#mark-all-as-read-container');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setMorePopupOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const ref = useRef();
  const listRef1 = useRef();
  const listRef2 = useRef();
  const listRef3 = useRef();
  const loadingRef = useRef();
  const containerRef1 = useRef();
  const containerRef2 = useRef();
  const containerRef3 = useRef();

  useEffect(() => {
    if (!loading && !loadingRef.current) {
      if (scroll) {
        if (currentTab === '1' && listRef1.current) {
          setTimeout(() => {
            if (listRef1.current) {
              listRef1.current.scrollTop = 0;
            }
          }, 2000);
        }
        if (currentTab === '2' && listRef2.current) {
          setTimeout(() => {
            if (listRef2.current) {
              listRef2.current.scrollTop = 0;
            }
          }, 2000);
        }
        if (currentTab === '3' && listRef3.current) {
          setTimeout(() => {
            if (listRef3.current) {
              listRef3.current.scrollTop = 0;
            }
          }, 2000);
        }
      }

      setScroll(false);
    }

    loadingRef.current = loading;
  }, [currentTab, loading, scroll]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const result = await apiCall('get', 'notifications/' + 100, {});
        if (result?.data && result.status === 200) {
          setData(result.data);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(
    async amount => {
      setLoadingOverlayVisible(true);
      try {
        let params = {
          limit: amount ? amount * 100 : messageAmount * 100,
        };

        if (currentTab === '2' || currentTab === '3') {
          params.type = currentTab === '2' ? 'ship' : 'port';
        }
        const result = await apiCall('get', 'notifications-by-filter/', params);
        if (result?.data && result.status === 200) {
          setData(result.data?.data || []);
          setLoadingOverlayVisible(false);
        }
      } catch (e) {
        console.log('notification error', e);
        setLoadingOverlayVisible(false);
      }
    },
    [apiCall, currentTab, messageAmount]
  );

  const fetchDataWithoutOverlay = useCallback(
    async amount => {
      try {
        let params = {
          limit: amount ? amount * 100 : messageAmount * 100,
        };

        if (currentTab === '2' || currentTab === '3') {
          params.type = currentTab === '2' ? 'ship' : 'port';
        }
        const result = await apiCall('get', 'notifications-by-filter/', params);
        if (result?.data && result.status === 200) {
          setData(result.data?.data || []);
        }
      } catch (e) {
        console.log('notification error', e);
      }
    },
    [apiCall, currentTab, messageAmount]
  );

  const fetchNewData = useCallback(
    async key => {
      setLoading(true);
      try {
        let params = {
          limit: 100,
        };

        if (key === '2' || key === '3') {
          params.type = key === '2' ? 'ship' : 'port';
        }

        const result = await apiCall('get', 'notifications-by-filter/', params);
        if (result?.data && result.status === 200) {
          setData(result.data?.data || []);
          setLoading(false);
        }
      } catch (e) {
        console.log('notification error', e);
        setLoading(false);
      }
    },
    [apiCall]
  );

  const onCurrentTabChange = key => {
    setCurrentTab(key);
    setMessageAmount(1);
    setData([]);
    setShowStartLoading(true);
    setReadRememberArray([]);
    fetchNewData(key);
  };

  const getNotifications = useCallback(() => {
    setShowStartLoading(false);
    fetchData();
  }, [fetchData]);

  const notificationsChanged = useCallback(() => {
    getNotifications();
  }, [getNotifications]);

  useUserSocket('notifications-changed', notificationsChanged);

  const readMessage = () => {
    setShowStartLoading(false);
    fetchData();
  };

  const loadMoreMessages = () => {
    setMessageAmount(count => count + 1);
    fetchData(messageAmount + 1);
  };

  const setAllAsRead = async () => {
    setMorePopupOpen(false);
    await apiCall('post', 'all-notifications-read', {});
    setShowStartLoading(false);
    fetchDataWithoutOverlay();
  };

  const sendOnRead = async () => {
    const dataids = [...readArray];
    setReadArray([]);
    await apiCall('post', 'notifications-read', {
      notification_ids: dataids,
    });

    fetchDataWithoutOverlay();
  };

  const debouncedRead = debounce(3000, sendOnRead);

  useEffect(() => {
    if (readArray.length) {
      clearTimeout(readtimer.current);
      readtimer.current = setTimeout(() => {
        sendOnRead();
      }, 500);
    }

    return () => {
      clearTimeout(readtimer.current);
    };
    // eslint-disable-next-line
  }, [debouncedRead, readArray]);

  let notifications = !data ? [] : data;
  let shipNotifications = notifications
    ? notifications.filter(n => n.type === 'ship' || n.type === 'port_call_decision')
    : [];
  let portNotifications = notifications ? notifications.filter(n => n.type === 'port') : [];

  if (showOnlyUnread) {
    notifications = notifications.filter(
      n =>
        !n.read ||
        n.children?.some(c => !c.read) ||
        readRememberArray.includes(n.id) ||
        n.children?.some(c => readRememberArray.includes(c.id))
    );
    shipNotifications = shipNotifications.filter(
      n =>
        !n.read ||
        n.children?.some(c => !c.read) ||
        readRememberArray.includes(n.id) ||
        n.children?.some(c => readRememberArray.includes(c.id))
    );
    portNotifications = portNotifications.filter(
      n =>
        !n.read ||
        n.children?.some(c => !c.read) ||
        readRememberArray.includes(n.id) ||
        n.children?.some(c => readRememberArray.includes(c.id))
    );
  }

  let viewPortContainer = undefined;
  let viewPort = {};

  if (currentTab === '1') {
    viewPortContainer = containerRef1.current ? containerRef1.current.getClientRects() : undefined;
    viewPort = viewPortContainer ? viewPortContainer[0] : {};
  } else if (currentTab === '2') {
    viewPortContainer = containerRef2.current ? containerRef2.current.getClientRects() : undefined;
    viewPort = viewPortContainer ? viewPortContainer[0] : {};
  } else if (currentTab === '3') {
    viewPortContainer = containerRef3.current ? containerRef3.current.getClientRects() : undefined;
    viewPort = viewPortContainer ? viewPortContainer[0] : {};
  }

  if (!viewPort) {
    viewPort = {};
  }

  const onRead = id => {
    setReadRememberArray(array => [...array, id]);
    setReadArray(array => [...array, id]);
  };

  const addToRememberList = id => {
    setReadRememberArray(array => [...array, id]);
  };

  const tabItems = [
    {
      label: t('All'),
      children: (
        <div ref={containerRef1}>
          <Container newMessageHeight={newMessageHeight} ref={listRef1} onScroll={onScroll}>
            <Messages>
              {showStartLoading && loading ? (
                <SpinContainer>
                  <Spin spinning={loading} />
                </SpinContainer>
              ) : notifications.length > 0 ? (
                notifications.map(n => (
                  <NotificationListMessages
                    key={n.id}
                    data={n}
                    readMessage={readMessage}
                    onRead={onRead}
                    viewPortTop={viewPort.top}
                    viewPortBottom={viewPort.bottom}
                    scrolling={scrolling}
                    addToRememberList={addToRememberList}
                  />
                ))
              ) : showOnlyUnread ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No unread messages.')} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No messages.')} />
              )}
            </Messages>
            {!(showStartLoading && loading) && (
              <LoadMore onClick={loadMoreMessages}>{t('Load more messages...')}</LoadMore>
            )}
          </Container>
          {loadingOverlayVisible && (
            <LoadingOverlay newMessageHeight={newMessageHeight}>
              <SpinContainer>
                <Spin spinning={true} />
              </SpinContainer>
            </LoadingOverlay>
          )}
          <NotificationListAddNewMessage
            port={true}
            ship={true}
            setNewMessageHeight={setNewMessageHeight}
            setScroll={setScroll}
          />
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <span>
          {t('Ship notifications')}{' '}
          {portcallSummaryCount > 0 && <span style={{ color: '#4990DD' }}>({portcallSummaryCount})</span>}
        </span>
      ),
      children: (
        <div ref={containerRef2}>
          <Container newMessageHeight={newMessageHeight} ref={listRef2} onScroll={onScroll}>
            <Messages>
              {showStartLoading && loading ? (
                <SpinContainer>
                  <Spin spinning={loading} />
                </SpinContainer>
              ) : shipNotifications.length > 0 ? (
                shipNotifications.map(n => (
                  <NotificationListMessages
                    key={n.id}
                    data={n}
                    readMessage={readMessage}
                    onRead={onRead}
                    viewPortTop={viewPort.top}
                    viewPortBottom={viewPort.bottom}
                    scrolling={scrolling}
                  />
                ))
              ) : showOnlyUnread ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No unread messages.')} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No messages.')} />
              )}
            </Messages>
            {!(showStartLoading && loading) && (
              <LoadMore onClick={loadMoreMessages}>{t('Load more messages...')}</LoadMore>
            )}
          </Container>
          {loadingOverlayVisible && (
            <LoadingOverlay newMessageHeight={newMessageHeight}>
              <SpinContainer>
                <Spin spinning={true} />
              </SpinContainer>
            </LoadingOverlay>
          )}
          <NotificationListAddNewMessage
            port={false}
            ship={true}
            setNewMessageHeight={setNewMessageHeight}
            setScroll={setScroll}
          />
        </div>
      ),
      key: '2',
    },
    {
      label: (
        <span>
          {t('Port notifications')}{' '}
          {portNotificationSummary?.unread > 0 && (
            <span style={{ color: '#4990DD' }}>({portNotificationSummary.unread})</span>
          )}
        </span>
      ),
      children: (
        <div ref={containerRef3}>
          <Container newMessageHeight={newMessageHeight} ref={listRef3} onScroll={onScroll}>
            <Messages>
              {showStartLoading && loading ? (
                <SpinContainer>
                  <Spin spinning={loading} />
                </SpinContainer>
              ) : portNotifications.length > 0 ? (
                portNotifications.map(n => (
                  <NotificationListMessages
                    key={n.id}
                    data={n}
                    readMessage={readMessage}
                    onRead={onRead}
                    viewPortTop={viewPort.top}
                    viewPortBottom={viewPort.bottom}
                    scrolling={scrolling}
                  />
                ))
              ) : showOnlyUnread ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No unread messages.')} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No messages.')} />
              )}
            </Messages>
            {!(showStartLoading && loading) && (
              <LoadMore onClick={loadMoreMessages}>{t('Load more messages...')}</LoadMore>
            )}
          </Container>
          {loadingOverlayVisible && (
            <LoadingOverlay newMessageHeight={newMessageHeight}>
              <SpinContainer>
                <Spin spinning={true} />
              </SpinContainer>
            </LoadingOverlay>
          )}
          <NotificationListAddNewMessage port={true} setNewMessageHeight={setNewMessageHeight} setScroll={setScroll} />
        </div>
      ),
      key: '3',
    },
  ];

  return (
    <Modal>
      <CloseRow>
        <IconContainer onClick={close}>
          <Icon type="close" />
        </IconContainer>
      </CloseRow>
      <HeaderRow>
        <Header>{t('Notifications')}</Header>
        <HeaderEnd>
          <SwitchContainer>
            <SwitchLabel>{t('Show only unread')}</SwitchLabel>
            <Switch size="small" checked={showOnlyUnread} onChange={() => setShowOnlyUnread(!showOnlyUnread)} />
          </SwitchContainer>
          <MoreButton onClick={() => setMorePopupOpen(true)}>
            <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
          </MoreButton>
          {morePopupOpen && (
            <PopupContainer id="mark-all-as-read-container" ref={ref}>
              <PopupRow onClick={() => setAllAsRead()}>{t('Mark all notifications as read')}</PopupRow>
            </PopupContainer>
          )}
        </HeaderEnd>
      </HeaderRow>
      <Tabs activeKey={currentTab} onTabClick={key => onCurrentTabChange(key)} size="small" items={tabItems} />
    </Modal>
  );
};

export default AllNotificationsModal;
