import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import BerthPlanningToolSidePanel from '../components/berthPlanningTool/BerthPlanningToolSidePanel';
import BerthPlanningToolMapResizableContainer from '../components/berthPlanningTool/BerthPlanningToolMapResizableContainer';

import { BerthPlanningToolProvider } from '../context/BerthPlanningToolContext';
import { SeaChartMarkersProvider } from '../context/SeaChartMarkersContext';
import { SeaChartVesselsProvider } from '../context/SeaChartVesselsContext';
import BerthPlanningToolCalendarContainer from '../components/berthPlanningTool/BerthPlanningToolCalendarContainer';
import { CraneProvider } from '../context/CraneContext';
import { AlertContext } from '../context/AlertContext';

const BerthPlanningToolContainer = styled.div`
  display: flex;
  height: ${props =>
    props.noTopBar
      ? props.alertsHeight
        ? `calc(100vh - 93px - ${props.alertsHeight}px)`
        : 'calc(100vh - 93px)'
      : props.alertsHeight
        ? `calc(100vh - 141px - ${props.alertsHeight}px)`
        : 'calc(100vh - 141px)'};
  overflow: hidden;
`;

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  height: 100%;
`;

const BerthPlanningTool = () => {
  const { user, namespace, expandedViewState } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  useEffect(() => {
    document.title = 'Berth Planning Tool | Port Activity App';
  }, []);

  return (
    <Layout filtering pagename={t('Berth Planning Tool')}>
      <div className="offsetParent" style={{ height: '100%' }}>
        <AlteredPage fullWidth>
          <BerthPlanningToolProvider>
            <SeaChartMarkersProvider>
              <SeaChartVesselsProvider>
                <CraneProvider>
                  <BerthPlanningToolContainer noTopBar={expandedViewState === 3} alertsHeight={alertsHeight}>
                    <BerthPlanningToolCalendarContainer />
                    {user.permissions.includes('manage_bpt') && expandedViewState === 1 && (
                      <BerthPlanningToolSidePanel />
                    )}
                  </BerthPlanningToolContainer>
                  {user.permissions.includes('manage_bpt') && expandedViewState === 1 && (
                    <BerthPlanningToolMapResizableContainer />
                  )}
                </CraneProvider>
              </SeaChartVesselsProvider>
            </SeaChartMarkersProvider>
          </BerthPlanningToolProvider>
        </AlteredPage>
      </div>
    </Layout>
  );
};

export default BerthPlanningTool;
