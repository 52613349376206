import React from 'react';
import styled from 'styled-components';
import Slider from 'antd/es/slider';
import dayjs from 'dayjs';
import Icon from '../ui/Icon';

const Container = styled.div`
  position: relative;
  width: 100%;
  .ant-slider-track {
    background-color: ${props => (props.active ? '#cfebff' : props.theme.color.beige2)};
  }
  .ant-slider-track:hover {
    background-color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_medium)};
    color: ${props => (props.active ? '#cfebff' : props.theme.color.grey_medium)};
  }
  .ant-slider-handle {
    color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
    border: solid 2px ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
  }
  .ant-slider-handle:hover {
    color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
    border: solid 2px ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
  }
  .ant-slider-handle:focus {
    border-color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
    outline: none;
    box-shadow: 0 0 0 5px #1890ff1f;
  }
  .ant-slider-mark {
    font-size: 11px;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${props => (props.active ? '#7FABDB' : props.theme.color.grey_light2)};
  }
  .ant-slider-dot {
    position: absolute;
    width: 1px;
    height: 8px;
    margin-left: -2px;
    background-color: ${props => (props.active ? '#cfebff' : props.theme.color.grey_light)};
    border: 0;
    border-radius: 0%;
    cursor: pointer;
  }
  .ant-slider-dot-active {
    background-color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_light2)};
  }
`;

const ClearContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 10px;
  cursor: pointer;
  svg {
    display: inline-block;
    color: ${props => props.theme.color.grey_light2};
    height: 14px;
  }
`;

const marks = {
  0: '-1w',
  1: '-6d',
  2: '-5d',
  3: '-4d',
  4: '-3d',
  5: '-2d',
  6: '-1d',
  7: {
    style: {
      marginLeft: '1px',
    },
    label: 'Today',
  },
  8: '1d',
  9: '2d',
  10: '3d',
  11: '4d',
  12: '5d',
  13: '6d',
  14: '1w',
  15: '2w',
  16: '3w',
};

const markValuesInDays = {
  0: -7,
  1: -6,
  2: -5,
  3: -4,
  4: -3,
  5: -2,
  6: -1,
  7: 0,
  8: 1,
  9: 2,
  10: 3,
  11: 4,
  12: 5,
  13: 6,
  14: 7,
  15: 14,
  16: 21,
};

export const getDateFromSliderValue = value => {
  return dayjs()
    .startOf('day')
    .add(markValuesInDays[value], 'days');
};

const FleetFilterDateSlider = ({ handleChange, value }) => {
  const tipFormatter = value => getDateFromSliderValue(value).format('MMMM DD, YYYY');

  return (
    <Container active={value?.length}>
      {value?.length > 0 && (
        <ClearContainer>
          <Icon type="x-circle" fill="none" onClick={() => handleChange([])} />
        </ClearContainer>
      )}
      <Slider
        min={0}
        max={Object.keys(marks).length - 1}
        marks={marks}
        range={{ draggableTrack: true }}
        value={value?.length ? value : [0, 14]}
        step={null}
        onChange={handleChange}
        tooltip={ tipFormatter}
      />
    </Container>
  );
};

export default FleetFilterDateSlider;
