import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../../context/UserContext';
import useApi from '../../../../hooks/useApi';

import List from '../../../ui/List';
import ListActionButton from '../../../ui/ListActionButton';
import Icon from '../../../ui/Icon';
import AddOrEditCompanyModal from './AddOrEditCompanyModal';
import RemoveCompanyModal from './RemoveCompanyModal';

const CompanyList = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [companyModalData, setCompanyModalData] = useState(undefined);
  const [companyModalType, setCompanyModalType] = useState(undefined);
  const [companyDeleteModal, setCompanyDeleteModal] = useState(undefined);

  const { loading, data, error, fetchData } = useApi('get', 'pbp/companies', {});

  const holidays = error || !data ? [] : data;

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Short name'),
      dataIndex: 'shortName',
      key: 'shortName',
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          onClick={() => {
            setCompanyModalData(record);
            setCompanyModalType('edit');
          }}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          onClick={() => {
            setCompanyDeleteModal(record);
          }}
          red
        >
          <Icon type="trash" />
          {t('Remove')}
        </ListActionButton>
      ),
    },
  ];

  const refetchData = async () => {
    await fetchData(false, {});
  };

  const addItemButton = {
    onClick: () => {
      setCompanyModalData(undefined);
      setCompanyModalType('add');
    },
    text: t('Add company'),
  };

  const closeCompanyModal = async () => {
    setCompanyModalData(undefined);
    setCompanyModalType(undefined);
  };

  const closeCompanyDeleteModal = async () => {
    setCompanyDeleteModal(undefined);
  };

  return (
    <div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={holidays}
        actions={actions}
        spinning={loading}
        addItemButton={addItemButton}
      />
      {!!companyModalType && (
        <AddOrEditCompanyModal
          type={companyModalType}
          data={companyModalData}
          closeModal={closeCompanyModal}
          refetchData={refetchData}
        />
      )}
      {!!companyDeleteModal && (
        <RemoveCompanyModal data={companyDeleteModal} closeModal={closeCompanyDeleteModal} refetchData={refetchData} />
      )}
    </div>
  );
};

export default CompanyList;
