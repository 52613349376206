import React, { useContext } from 'react';
import styled from 'styled-components';

import Tooltip from 'antd/es/tooltip';

import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

const SidebarContainer = styled.div`
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px -1px 1px 0px rgb(0 0 0 / 20%);
  margin-right: 2px;
`;

const SidebarParentLine = styled.div`
  padding: 0 4px;
  display: flex;
  justify-content: flex-end;
`;

const SidebarCrane = styled.div`
  margin: 0 3px;
`;

const CraneName = styled.div`
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-size: 11px;
  text-transform: uppercase;
  margin-right: 5px;
  font-weight: 700;
`;

export const Sidebar = () => {
  const { sidebarWidth, rowHeight, craneList, reservationCraneList } = useContext(CranePlanningToolContext);

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  const sidebarStyle = {
    width: `${sidebarWidth}px`,
    minHeight: `${outerComponentHeight}px`,
  };

  const cranesStyle = {
    width: `${sidebarWidth}px`,
  };

  let craneLines = craneList.map((crane, index) => {
    const elementStyle = {
      height: `${rowHeight}px`,
      lineHeight: `${rowHeight}px`,
    };

    return (
      <SidebarCrane key={`sidebar-crane-${crane.craneId}`}>
        <SidebarParentLine even={index % 2 === 0} style={elementStyle}>
          <Tooltip title={crane.craneName} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            <CraneName>{crane.craneName}</CraneName>
          </Tooltip>
        </SidebarParentLine>
      </SidebarCrane>
    );
  });

  let reservationCraneLines = reservationCraneList.map((crane, index) => {
    const elementStyle = {
      height: `${rowHeight}px`,
      lineHeight: `${rowHeight}px`,
    };

    return (
      <SidebarCrane key={`sidebar-crane-${crane.craneId}`}>
        <SidebarParentLine even={index % 2 === 0} style={elementStyle}>
          <Tooltip title={crane.craneName} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            <CraneName>{crane.craneName}</CraneName>
          </Tooltip>
        </SidebarParentLine>
      </SidebarCrane>
    );
  });

  return (
    <SidebarContainer style={sidebarStyle}>
      <div style={cranesStyle}>{craneLines}</div>
      <div style={cranesStyle}>{reservationCraneLines}</div>
    </SidebarContainer>
  );
};
