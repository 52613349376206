import React, { useState, createContext, useEffect, useMemo, useRef, useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import { throttle } from 'throttle-debounce';
import {
  calculateXPositionForTime,
  calculateTimeForXPosition,
  getCanvasBoundariesFromVisibleTime,
  getCanvasWidth,
  calculateScrollCanvas,
} from '../components/commonCalendar/utility/calendar';
import { UserContext } from './UserContext';
import { FilteringContext } from './FilteringContext';
import {
  createBerthsOpenObject,
  setVesselsData,
  updateBerths,
  setUpcomingVesselsData,
  getBerthAreasAvailableFromData,
} from '../components/berthPlanningTool/calendar/utility/contextHelpers';
import useApi from '../hooks/useApi';

import { collapsedRowHeightConstant, maxRowHeightConstant } from '../components/commonCalendar/utility/constants';

import { filterTimestampsForBPT } from '../utils/utils';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);
dayjs.extend(duration);

/* Vessel states: notPlanned=1, uncommitted=2, planned=3, reserved=4 */

// const VESSEL_STATE_NOT_PLANNED = 1;
// const VESSEL_STATE_UNCOMMITTED = 2;
const VESSEL_STATE_PLANNED = 3;
// const VESSEL_STATE_RESERVED = 4;
// const VESSEL_STATE_ACCEPTED = 5;
// const VESSEL_STATE_OFFERED = 6;
// const VESSEL_STATE_UPDATED = 7;

export const BerthPlanningToolContext = createContext();

export const BerthPlanningToolProvider = ({ children }) => {
  const { apiCall, user, useUserSocket, modules, expandedViewState, namespace } = useContext(UserContext);

  const defaultTimeStart = dayjs()
    .startOf('isoWeek')
    .toDate();

  const defaultTimeEnd = dayjs()
    .startOf('isoWeek')
    .add(1, 'week')
    .toDate();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initialSummary = {
    not_planned: 0,
    planned: 0,
    reserved: 0,
    uncommitted: 0,
  };

  const initialIndicatorLine = {
    time: dayjs(),
    isUserSet: false,
  };

  const [visibleTimeStart, setVisibleTimeStart] = useState(defaultTimeStart.valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(defaultTimeEnd.valueOf());
  const [canvasTimeStart, setCanvasTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[0]
  );
  const [canvasTimeEnd, setCanvasTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[1]
  );

  const [berths, setBerths] = useState([]);
  const [berthsOpen, setBerthsOpen] = useState({});
  const [upcomingVessels, setUpcomingVessels] = useState([]);
  const [width, setWidth] = useState(1000);
  const [height, setHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(getCanvasWidth(1000));
  const [timelineUnit, setTimelineUnit] = useState('week');
  const [sidebarWidth, setSidebarWidth] = useState(150);
  const [sidePanelWidth, setSidePanelWidth] = useState(255);
  const [indicatorLine, setIndicatorLine] = useState(initialIndicatorLine);
  const [calendarVessels, setCalendarVessels] = useState([]);
  const [calendarVesselSummary, setCalendarVesselSummary] = useState(initialSummary);
  const [draggingArea, setDraggingArea] = useState(undefined);
  const [draggingOverlapping, setDraggingOverlapping] = useState(false);
  const [rowHeight, setRowHeight] = useState(maxRowHeightConstant);
  const [collapsedRowHeight, setCollapsedRowHeight] = useState(collapsedRowHeightConstant);
  const [rowsOpen, setRowsOpen] = useState(false);

  const [additionalData, setAdditionalData] = useState(null);

  const [berthAreasAvailable, setBerthAreasAvailable] = useState(false);
  const [zoomSliderValue, setZoomSliderValue] = useState(7);
  const [rowHeightFromLocalStorageChecked, setRowHeightFromLocalStorageChecked] = useState(false);

  const [draggingMove, setDraggingMove] = useState(0);
  const [containerLimits, setContainerLimits] = useState({ top: 0, left: 0 });
  const [draggingRowInfo, setDraggingRowInfo] = useState({ height: 0, open: false });
  const [disablePbpDragging, setDisablePbpDragging] = useState(false);

  const [settingsFetched, setSettingsFetched] = useState(false);

  const [undoDisabled, setUndoDisabled] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);

  const [loader, setLoader] = useState(false);
  const [undoLoader, setUndoLoader] = useState(0);
  const [redoLoader, setRedoLoader] = useState(0);
  const [commitLoader, setCommitLoader] = useState(0);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [disableScrolling, setDisableScrolling] = useState(false);
  const [multiselectDisableScrolling, setMultiselectDisableScrolling] = useState(false);

  const [reloadCalendarData, setReloadCalendarData] = useState(false);
  const [reloadUpcomingVesselsData, setReloadUpcomingVesselsData] = useState(false);

  const [toFilter, setToFilter] = useState(false);

  const [predictiveBPTOn, setPredictiveBPTOn] = useState(false);
  const [bptModalOpen, setBptModalOpen] = useState(false);
  const [queueModalTypeOpen, setQueueModalTypeOpen] = useState(undefined);

  const [socketBlocker, setSocketBlocker] = useState(true);

  const [jitVesselLoader, setJitVesselLoader] = useState(0);
  const [firstDataLoaded, setFirstDataLoaded] = useState(false);

  const [windWarnings, setWindWarnings] = useState(undefined);

  const [moveSelection, setMoveSelection] = useState({
    startX: undefined,
    startY: undefined,
    moveX: undefined,
    moveY: undefined,
    scrollTop: undefined,
    scrollLeft: undefined,
    stopped: false,
  });

  const [multiselectedVessels, setMultiselectedVessels] = useState([]);
  const [multiselectedMoveVessels, setMultiselectedMoveVessels] = useState([]);
  const [multiselectedMove, setMultiselectedMove] = useState({ x: 0, move: false, id: undefined });
  const [multiselectedModalOpen, setMultiselectedModalOpen] = useState(false);

  const [vesselLayerOn, setVesselLayerOn] = useState(true);
  const [vesselOptions, setVesselOptions] = useState({
    notPlanned: true,
    accepted: true,
    offered: true,
    updated: true,
    reserved: true,
    uncommitted: true,
    planned: true,
  });
  const timeIndicatorLineTimer = useRef();

  const { filteringStorageData, updateBPTData, setUpdateBPTData } = useContext(FilteringContext);

  const [dynamicBollards, setDynamicBollards] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSocketBlocker(false);
      setReloadCalendarData(true);
    }, 20000);
  }, []);

  useEffect(() => {
    const getSettings = async () => {
      const result = await apiCall('get', 'settings');
      if (mounted.current && result?.status === 200) {
        const disablePredictivePlanDraggingObj = result.data.find(element =>
          Object.prototype.hasOwnProperty.call(element, 'pbp_disable_dragging')
        );
        const disablePredictivePlanDragging = disablePredictivePlanDraggingObj?.pbp_disable_dragging === 'true';
        setDisablePbpDragging(disablePredictivePlanDragging);
      }
    };
    if (!settingsFetched) {
      setSettingsFetched(true);
      getSettings();
    }
  }, [apiCall, settingsFetched]);

  const queueMode = modules.queue_module === 'enabled';

  const getWindWarningData = useCallback(async () => {
    if (namespace !== 'gothenburg') {
      return;
    }

    const windData = await apiCall('post', 'pbp/get-berth-restrictions', {
      start_time: dayjs(defaultTimeStart)
        .add(-1, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      end_time: dayjs(defaultTimeStart)
        .add(2, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (windData?.data?.wind) {
      setWindWarnings(windData?.data?.wind);
    }
  }, [apiCall, defaultTimeStart, namespace]);

  const updateHeight = useCallback(
    list => {
      const berthList = berths.length ? berths : list;
      let heightInPixels = 0;
      heightInPixels += rowHeight * berthList.length;
      berthList.forEach(g => {
        let open = list.find(id => id === g.id);
        heightInPixels += (open ? rowHeight : collapsedRowHeight) * g.subRows.length;
      });

      setHeight(heightInPixels);
    },
    [berths, collapsedRowHeight, rowHeight]
  );

  const debouncedUpdateCalendarVesselData = useCallback(
    async (start, end, ignoreDraggingArea = false) => {
      const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
        start_date_time: dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ'),
        end_date_time: dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ'),
      });

      if (bptModalOpen) {
        setReloadCalendarData(true);
        return;
      }

      if (mounted.current) {
        const newBerths = updateBerths(
          berths,
          calendarVesselData.data,
          rowHeight,
          collapsedRowHeight,
          berthsOpen,
          berthAreasAvailable,
          filteringStorageData,
          namespace === 'gavle'
        );
        setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
        setBerths(newBerths);
        updateHeight(newBerths);
        setCalendarVessels(
          filterTimestampsForBPT(
            setVesselsData(calendarVesselData.data, draggingArea, calendarVessels, ignoreDraggingArea),
            filteringStorageData
          )
        );
        setCalendarVesselSummary(calendarVesselData.data.summary);

        getWindWarningData();

        if (
          modules.additional_data_module === 'enabled' &&
          (user.permissions.includes('view-additional-data') || user.permissions.includes('manage-additional-data'))
        ) {
          const additionalInfoData = await apiCall('get', 'additional/port-call-data', {
            port_call_ids: createIds(calendarVesselData.data),
          });

          if (mounted.current && additionalInfoData.data) {
            setAdditionalData(additionalInfoData.data);
          }
        }
      }
    },
    [
      apiCall,
      berthAreasAvailable,
      berths,
      berthsOpen,
      bptModalOpen,
      calendarVessels,
      collapsedRowHeight,
      draggingArea,
      filteringStorageData,
      getWindWarningData,
      modules.additional_data_module,
      namespace,
      queueMode,
      rowHeight,
      updateHeight,
      user.permissions,
    ]
  );

  const updateCalendarVesselData = useCallback(
    (start, end, ignoreDraggingArea = false) => {
      if (firstAllowedRef.current) {
        firstAllowedRef.current = false;
        debouncedUpdateCalendarVesselData(start, end, ignoreDraggingArea);
        firstTimerRef.current = setTimeout(() => {
          firstAllowedRef.current = true;
        }, 500);

        return;
      }

      clearTimeout(firstTimerRef.current);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        debouncedUpdateCalendarVesselData(start, end, ignoreDraggingArea);
        timerRef.current = undefined;
        firstTimerRef.current = true;
        firstAllowedRef.current = true;
      }, 500);
    },
    [debouncedUpdateCalendarVesselData]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(firstTimerRef.current);
    };
  }, []);

  const updateUpcomingVesselData = useCallback(
    async (limit, offset, sort) => {
      if (user?.permissions.includes('manage_bpt')) {
        limit = limit ? limit : 1000;
        offset = offset ? offset : 0;
        sort = sort ? sort : 'eta';

        const upcomingVesselsData = await apiCall('get', 'bpt-upcoming-vessels', {
          limit: limit,
          offset: offset,
          sort: sort,
        });

        if (mounted.current) {
          setUpcomingVessels(setUpcomingVesselsData(upcomingVesselsData.data.data));
        }
      }
    },
    [apiCall, user]
  );

  useEffect(() => {
    const getUndoRedo = async () => {
      if (user?.permissions.includes('manage_bpt')) {
        const statusData = await apiCall('get', 'bpt-calendar-vessels-can-undo-redo');
        if (mounted.current && statusData.data) {
          setRedoDisabled(!statusData.data.can_redo);
          setUndoDisabled(!statusData.data.can_undo);
        }
      }
    };
    if (reloadCalendarData && !bptModalOpen && !socketBlocker) {
      setReloadCalendarData(false);
      updateCalendarVesselData(canvasTimeStart, canvasTimeEnd);
      if (modules.queue_module === 'disabled') {
        getUndoRedo();
      }
    }

    if (reloadUpcomingVesselsData) {
      setReloadUpcomingVesselsData(false);
    }
  }, [
    apiCall,
    canvasTimeEnd,
    canvasTimeStart,
    bptModalOpen,
    modules.queue_module,
    reloadCalendarData,
    reloadUpcomingVesselsData,
    updateCalendarVesselData,
    updateUpcomingVesselData,
    user,
    socketBlocker,
  ]);

  const timerRef = useRef();
  const firstTimerRef = useRef();
  const firstAllowedRef = useRef(true);

  useEffect(() => {
    if (!user.permissions.includes('manage_bpt') || expandedViewState > 1) {
      setSidePanelWidth(0);
    } else {
      setSidePanelWidth(255);
    }
  }, [expandedViewState, user]);

  const getUndoRedoAvailability = async () => {
    if (user.permissions.includes('manage_bpt') && !queueMode) {
      const statusData = await apiCall('get', 'bpt-calendar-vessels-can-undo-redo');
      if (mounted.current && statusData.data) {
        setRedoDisabled(!statusData.data.can_redo);
        setUndoDisabled(!statusData.data.can_undo);
      }
    }
  };

  useEffect(() => {
    const gavleFiltering = async () => {
      const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
        start_date_time: dayjs(defaultTimeStart)
          .add(-1, 'week')
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        end_date_time: dayjs(defaultTimeStart)
          .add(2, 'week')
          .format('YYYY-MM-DDTHH:mm:ssZ'),
      });

      const newBerths = updateBerths(
        berths,
        calendarVesselData.data,
        rowHeight,
        collapsedRowHeight,
        berthsOpen,
        berthAreasAvailable,
        filteringStorageData,
        namespace === 'gavle'
      );
      setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
      setBerths(newBerths);
      updateHeight(newBerths);
      setCalendarVessels(filterTimestampsForBPT(calendarVessels, filteringStorageData));
    };

    if ((updateBPTData || toFilter) && firstDataLoaded) {
      setUpdateBPTData(false);
      setToFilter(false);
      if (namespace === 'gavle') {
        gavleFiltering();
      } else {
        setCalendarVessels(filterTimestampsForBPT(calendarVessels, filteringStorageData));
      }
    }
  }, [
    apiCall,
    berthAreasAvailable,
    berths,
    berthsOpen,
    calendarVessels,
    collapsedRowHeight,
    defaultTimeStart,
    draggingArea,
    filteringStorageData,
    firstDataLoaded,
    namespace,
    queueMode,
    rowHeight,
    setUpdateBPTData,
    toFilter,
    updateBPTData,
    updateHeight,
  ]);

  const createIds = data => {
    let ids = '';
    Object.keys(data).forEach(k => {
      if (k !== 'summary' && k !== 'reserved' && k !== 'dynamic_bollards') {
        Object.keys(data[k]).forEach(b => {
          if (Array.isArray(data[k][b])) {
            data[k][b].forEach(v => {
              ids += v.port_call_id + ',';
            });
          } else {
            Object.keys(data[k][b]).forEach(v => {
              if (v !== 'not_planned_rows') {
                ids += data[k][b][v].port_call_id + ',';
              }
            });
          }
        });
      }
    });

    if (ids.length > 0) {
      ids = ids.substring(0, ids.length - 1);
    }

    return ids;
  };

  const getBerthStartData = async () => {
    setLoader(true);
    getUndoRedoAvailability();

    const berthData = await apiCall('get', 'bpt-berths', { include_berth_areas: 1, include_configuration: 1 });
    const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
      start_date_time: dayjs(defaultTimeStart)
        .add(-1, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      end_date_time: dayjs(defaultTimeStart)
        .add(2, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      if (berthData.data && calendarVesselData.data) {
        let bAreasAvailable = getBerthAreasAvailableFromData(berthData.data);
        delete berthData.data.configuration;
        setBerthAreasAvailable(bAreasAvailable);
        const openBerths = createBerthsOpenObject(berthData.data, bAreasAvailable);
        setBerthsOpen(openBerths);
        const newBerths = updateBerths(
          berthData.data,
          calendarVesselData.data,
          rowHeight,
          collapsedRowHeight,
          openBerths,
          bAreasAvailable,
          filteringStorageData,
          namespace === 'gavle',
          true
        );
        setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
        updateHeight(newBerths);
        setBerths(newBerths);
        setCalendarVessels(filterTimestampsForBPT(setVesselsData(calendarVesselData.data), filteringStorageData));
        setToFilter(true);
        setCalendarVesselSummary(calendarVesselData.data.summary);
        setFirstDataLoaded(true);
      }
      updateUpcomingVesselData();
      setLoader(false);
    }

    getWindWarningData();

    if (
      modules.additional_data_module === 'enabled' &&
      (user.permissions.includes('view-additional-data') || user.permissions.includes('manage-additional-data'))
    ) {
      const additionalInfoData = await apiCall('get', 'additional/port-call-data', {
        port_call_ids: createIds(calendarVesselData.data),
      });

      if (mounted.current && additionalInfoData.data) {
        setAdditionalData(additionalInfoData.data);
      }
    }
  };

  useUserSocket(
    'bpt-calendar-changed',
    useCallback(() => {
      if (!queueMode) {
        setReloadCalendarData(true);
      }
    }, [queueMode])
  );

  const reFetch = useMemo(
    () =>
      throttle(6000, false, () => {
        // spread out request per client
        setTimeout(() => setReloadCalendarData(true), Math.floor(Math.random() * 4000));
      }),
    []
  );

  useUserSocket(
    'queue-portcalls-changed',
    useCallback(() => {
      if (queueMode) {
        reFetch();
      }
    }, [queueMode, reFetch])
  );

  useUserSocket(
    'bpt-upcoming-vessels-changed',
    useCallback(() => {
      setReloadUpcomingVesselsData(true);
    }, [])
  );

  const updateTime = useCallback(() => {
    setIndicatorLine({ isUserSet: false, time: dayjs() });
  }, []);

  useEffect(() => {
    if (!indicatorLine.isUserSet) {
      timeIndicatorLineTimer.current = setInterval(updateTime, 30000);
    }
    return () => clearInterval(timeIndicatorLineTimer.current);
  }, [indicatorLine, updateTime]);

  const getLeftOffsetFromDate = date => {
    return calculateXPositionForTime(canvasTimeStart, canvasTimeEnd, canvasWidth, date);
  };

  const getDateFromLeftOffsetPosition = leftOffset => {
    return calculateTimeForXPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset);
  };

  const updateScrollCanvas = async (visibleTimeStart2, visibleTimeEnd2) => {
    const scrollCanvas = calculateScrollCanvas(visibleTimeStart2, visibleTimeEnd2, canvasTimeStart);

    setVisibleTimeStart(scrollCanvas.visibleTimeStart);
    setVisibleTimeEnd(scrollCanvas.visibleTimeEnd);
    setCanvasTimeStart(scrollCanvas.canvasTimeStart);
    setCanvasTimeEnd(scrollCanvas.canvasTimeEnd);

    await updateCalendarVesselData(scrollCanvas.canvasTimeStart, scrollCanvas.canvasTimeEnd, false);
  };

  const setTimelineType = async (type, keepPlace = true) => {
    setLoader(true);
    setTimelineUnit(type);

    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    let start = canvasTimeStart + (scrollOffset / width) * viewWidthInTime;
    start = dayjs(start);

    let newVisibleStart = null;
    let newVisibleEnd = null;

    switch (type) {
      case 'day': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'week': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(7, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .add(-2, 'days')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(4, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'month': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();

          newVisibleStart = start
            .startOf('isoWeek')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }
      default:
        break;
    }

    const [canvasStart, canvasEnd] = getCanvasBoundariesFromVisibleTime(newVisibleStart, newVisibleEnd);

    setVisibleTimeStart(newVisibleStart);
    setVisibleTimeEnd(newVisibleEnd);
    setCanvasTimeStart(canvasStart);
    setCanvasTimeEnd(canvasEnd);

    setForceUpdate(true);
    await updateCalendarVesselData(canvasStart, canvasEnd, true);
    setLoader(false);
  };

  const setToday = () => {
    if (timelineUnit === 'day') {
      setTimelineType('day', false);
    } else if (timelineUnit === 'week') {
      setTimelineType('week', false);
    } else if (timelineUnit === 'month') {
      setTimelineType('month', false);
    }
    updateTime();
  };

  const openRow = async id => {
    let openBerthsObject = { ...berthsOpen };
    openBerthsObject[id] = true;
    const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
      start_date_time: dayjs(canvasTimeStart).format('YYYY-MM-DDTHH:mm:ssZ'),
      end_date_time: dayjs(canvasTimeEnd).format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      const newBerths = updateBerths(
        berths,
        calendarVesselData.data,
        rowHeight,
        collapsedRowHeight,
        openBerthsObject,
        berthAreasAvailable,
        filteringStorageData,
        namespace === 'gavle'
      );
      setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
      setBerths(newBerths);
      updateHeight(newBerths);
      setCalendarVessels(
        filterTimestampsForBPT(
          setVesselsData(calendarVesselData.data, draggingArea, calendarVessels, true),
          filteringStorageData
        )
      );
      setBerthsOpen(openBerthsObject);
    }
  };

  const closeRow = async id => {
    let openBerthsObject = { ...berthsOpen };
    openBerthsObject[id] = false;

    const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
      start_date_time: dayjs(canvasTimeStart).format('YYYY-MM-DDTHH:mm:ssZ'),
      end_date_time: dayjs(canvasTimeEnd).format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      const newBerths = updateBerths(
        berths,
        calendarVesselData.data,
        rowHeight,
        collapsedRowHeight,
        openBerthsObject,
        berthAreasAvailable,
        filteringStorageData,
        namespace === 'gavle'
      );
      setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
      setBerths(newBerths);
      updateHeight(newBerths);
      setCalendarVessels(
        filterTimestampsForBPT(
          setVesselsData(calendarVesselData.data, draggingArea, calendarVessels, true),
          filteringStorageData
        )
      );
      setBerthsOpen(openBerthsObject);
    }
  };

  const toggleAllRowsOpen = async isOpen => {
    let openBerthsObject = { ...berthsOpen };

    for (let g in openBerthsObject) {
      openBerthsObject[g] = isOpen;
    }

    const calendarVesselData = await apiCall('get', queueMode ? 'jit-eta-vessels' : 'bpt-calendar-vessels', {
      start_date_time: dayjs(canvasTimeStart).format('YYYY-MM-DDTHH:mm:ssZ'),
      end_date_time: dayjs(canvasTimeEnd).format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      const newBerths = updateBerths(
        berths,
        calendarVesselData.data,
        rowHeight,
        collapsedRowHeight,
        openBerthsObject,
        berthAreasAvailable,
        filteringStorageData,
        namespace === 'gavle'
      );
      setDynamicBollards(!!calendarVesselData.data?.dynamic_bollards);
      setBerths(newBerths);
      updateHeight(newBerths);
      setCalendarVessels(
        filterTimestampsForBPT(
          setVesselsData(calendarVesselData.data, draggingArea, calendarVessels, true),
          filteringStorageData
        )
      );
      setBerthsOpen(openBerthsObject);
    }
  };

  const updateQueueVesselLocation = data => {
    if (multiselectedVessels.length > 0) {
      setMultiselectedModalOpen(true);
    } else {
      if (data.onlyBerthChange) {
        setBptModalOpen(true);
        setQueueModalTypeOpen({
          type: 'berth',
          newBerthCode: data.berth_code,
          port_call_master_id: data.port_call_master_id,
        });
        setDraggingArea(undefined);
      } else {
        setBptModalOpen(true);
        setQueueModalTypeOpen({
          type: 'time',
          newPta: data.pta,
          port_call_master_id: data.port_call_master_id,
        });
        setDraggingArea(undefined);
      }
    }
  };

  const updateVesselLocation = async vesselData => {
    const draggedVessel = calendarVessels.find(
      element => element.port_call_master_id === vesselData.port_call_master_id
    );
    if (draggedVessel && draggedVessel.direction_down === undefined) {
      try {
        const bollardOptions = berths.find(g => g.id === vesselData.berth_code).subRows;
        const startBollardIndex = bollardOptions.findIndex(b => b.id === vesselData.start_bollard);
        const endBollardIndex = bollardOptions.findIndex(b => b.id === vesselData.end_bollard);
        draggedVessel.direction_down = startBollardIndex <= endBollardIndex;
      } catch {
        draggedVessel.direction_down = true;
      }
    }
    const invertBollards = vesselData.dragging ? draggedVessel.direction_down : true;
    setLoader(true);

    let result;
    try {
      result = await apiCall('post', 'bpt-update-calendar-vessel', {
        port_call_master_id: vesselData.port_call_master_id,
        berth_code: vesselData.berth_code !== 'unknown' ? vesselData.berth_code : null,
        start_bollard:
          vesselData.start_bollard && dynamicBollards
            ? 'B1'
            : !invertBollards
                ? vesselData.end_bollard
                : vesselData.start_bollard,
        end_bollard:
          vesselData.end_bollard && dynamicBollards
            ? 'B1'
            : !invertBollards
                ? vesselData.start_bollard
                : vesselData.end_bollard,
        pta: dayjs(vesselData.pta)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        ptd: dayjs(vesselData.ptd)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        agent: vesselData.agent,
        berth_transitions: vesselData.berth_transitions,
        ...(vesselData.pbp_data ? { pbp_data: vesselData.pbp_data } : {})
      });

      if (mounted.current && result?.status === 200) {
        getUndoRedoAvailability();

        await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      }
    } catch {
      console.error('updateVesselLocation failed.');
      if (vesselData.dragging) {
        keepVesselLocation(vesselData);
      }
      setLoader(false);
      return result;
    }

    setDraggingArea(undefined);
    setLoader(false);

    return result;
  };

  const keepVesselLocation = vesselData => {
    setCalendarVessels(
      filterTimestampsForBPT(
        calendarVessels.map(v => {
          if (v.port_call_master_id === vesselData.port_call_master_id) {
            let newVessel = { ...v };
            newVessel.tagId = v.port_call_master_id + '_' + dayjs();

            return newVessel;
          }

          return v;
        })
      ),
      filteringStorageData
    );

    setDraggingArea(undefined);
  };

  const keepMultiVessels = vesselData => {
    setCalendarVessels(
      filterTimestampsForBPT(
        calendarVessels.map(v => {
          if (vesselData.findIndex(vessel => vessel.id === v.port_call_master_id) > -1) {
            let newVessel = { ...v };
            newVessel.tagId = v.port_call_master_id + '_' + dayjs();

            return newVessel;
          }

          return v;
        })
      ),
      filteringStorageData
    );

    setDraggingArea(undefined);
  };

  const updateUpcomingVessel = vesselData => {
    const vessel = upcomingVessels.find(v => v.port_call_master_id === vesselData.port_call_master_id);
    let cVessels = calendarVessels.map(v => v);
    cVessels.push({
      name: vessel.name,
      port_call_master_id: vesselData.port_call_master_id,
      berth_code: vesselData.berth_code,
      start_bollard: vesselData.start_bollard,
      end_bollard: vesselData.end_bollard,
      pta: dayjs(vesselData.pta)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      ptd: dayjs(vesselData.ptd)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      direction_down: vesselData.direction_down,
      state: vesselData.state,
      tagId: vessel.port_call_master_id + '_' + dayjs(),
    });

    const uVessels = upcomingVessels
      .filter(u => u.port_call_master_id !== vesselData.port_call_master_id)
      .map(v => {
        return { ...v, tagId: v.port_call_master_id + '_' + dayjs() };
      });

    const newBerths = updateBerths(
      berths,
      cVessels,
      rowHeight,
      collapsedRowHeight,
      berthsOpen,
      berthAreasAvailable,
      filteringStorageData,
      namespace === 'gavle'
    );

    setBerths(newBerths);
    updateHeight(newBerths);
    setUpcomingVessels(uVessels);
    setCalendarVessels(filterTimestampsForBPT(cVessels, filteringStorageData));

    setDraggingArea(undefined);
  };

  const keepUpcomingVessel = vesselData => {
    setUpcomingVessels(
      upcomingVessels.map(v => {
        if (v.port_call_master_id === vesselData.port_call_master_id) {
          let newVessel = { ...v };
          newVessel.tagId = v.port_call_master_id + '_' + dayjs();

          return newVessel;
        }

        return v;
      })
    );

    setDraggingArea(undefined);
  };

  const moveForwards = () => {
    let days = 1;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      days = 1;
      start = start.startOf('day');
    } else if (timelineUnit === 'week') {
      days = 7;
      start = start.startOf('isoWeek');
    } else if (timelineUnit === 'month') {
      days = 28;
    }

    let ctStart = start.valueOf();
    let vtStart = start.add(days, 'days').valueOf();
    let vtEnd = start.add(2 * days, 'days').valueOf();
    let ctEnd = start.add(3 * days, 'days').valueOf();

    setVisibleTimeStart(vtStart);
    setVisibleTimeEnd(vtEnd);
    setCanvasTimeStart(ctStart);
    setCanvasTimeEnd(ctEnd);

    updateCalendarVesselData(ctStart, ctEnd, true);
  };

  const moveBackwards = () => {
    let timespan = 0;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      timespan = 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
      start = start.startOf('isoWeek').valueOf();
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
      start = start.valueOf();
    }

    setVisibleTimeStart(start - timespan);
    setVisibleTimeEnd(start);
    setCanvasTimeStart(start - 2 * timespan);
    setCanvasTimeEnd(start + timespan);

    updateCalendarVesselData(start - 2 * timespan, start + timespan, true);
  };

  const changeDate = date => {
    const millisecondsDate = dayjs(date).valueOf();
    let timespan = 0;
    if (timelineUnit === 'day') {
      timespan = 86400000;
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
    }

    setVisibleTimeStart(millisecondsDate);
    setVisibleTimeEnd(millisecondsDate + timespan);
    setCanvasTimeStart(millisecondsDate - timespan);
    setCanvasTimeEnd(millisecondsDate + 2 * timespan);

    updateCalendarVesselData(millisecondsDate - timespan, millisecondsDate + 2 * timespan, true);
  };

  const loadMoreRight = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const loadMoreLeft = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const onRedo = async () => {
    setRedoLoader(prevValue => prevValue + 1);

    try {
      const redo = await apiCall('get', 'bpt-calendar-vessels-update-redo');

      if (mounted.current && redo && redo.status === 200) {
        await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      }
    } catch {
      console.error('onRedo failed.');
    }

    getUndoRedoAvailability();
    setRedoLoader(prevValue => prevValue - 1);
  };

  const onUndo = async () => {
    setUndoLoader(prevValue => prevValue + 1);

    try {
      const undo = await apiCall('get', 'bpt-calendar-vessels-update-undo');

      if (mounted.current && undo && undo.status === 200) {
        await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      }
    } catch {
      console.error('onUndo failed.');
    }

    getUndoRedoAvailability();
    setUndoLoader(prevValue => prevValue - 1);
  };

  const sendCommitAll = async () => {
    setCommitLoader(prevValue => prevValue + 1);
    try {
      await apiCall('post', 'bpt-commit-calendar-vessels-updates');

      if (mounted.current) {
        await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      }
    } catch {
      console.error('commit failed.');
    }

    getUndoRedoAvailability();
    setCommitLoader(prevValue => prevValue - 1);
  };

  const sendDeleteReservation = async id => {
    await apiCall('delete', 'bpt-delete-reservation', {
      id: id,
    });

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
    }
  };

  const sendDeleteBerthBlock = async id => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('delete', 'berth-reservations', {
        id: id,
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendDeleteSlotResquest = async id => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('delete', 'slot-reservations', {
        id,
        cancel_only: true,
        by_vessel: false,
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendAddReservation = async reservationData => {
    let result = await apiCall('post', 'bpt-add-reservation', {
      agent: reservationData.agent,
      notes: reservationData.notes,
      name: reservationData.name,
      imo: reservationData.imo,
      start_time: reservationData.start_time
        ? dayjs(reservationData.start_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      end_time: reservationData.end_time
        ? dayjs(reservationData.end_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      berth_code: reservationData.berth_code,
      start_bollard: reservationData.start_bollard,
      end_bollard: reservationData.end_bollard,
    });

    if (mounted.current) {
      updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
    }

    return result;
  };

  const sendAddBerthBlock = async data => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('post', 'berth-reservations', {
        reservation_start: data.start_time
          ? dayjs(data.start_time)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        reservation_end: data.end_time
          ? dayjs(data.end_time)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        berth_code: data.berth_code,
        berth_reservation_type: 'port_blocked',
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendEditSlotRequest = async data => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('put', 'slot-reservations', {
        id: data.id,
        laytime: dayjs.duration(data.laytime).toISOString(),
        jit_eta: dayjs(data.rta_window_start).format('YYYY-MM-DDTHH:mm:ssZ'),
        rta_window_start: dayjs(data.rta_window_start).format('YYYY-MM-DDTHH:mm:ssZ'),
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendEditEtdSlotRequest = async data => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('post', 'slot-reservation-loading-master-etd', {
        id: data.id,
        loading_master_etd: dayjs(data.loading_master_etd).format('YYYY-MM-DDTHH:mm:ssZ'),
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendUpdateReservation = async reservationData => {
    let result = await apiCall('put', 'bpt-update-reservation', {
      id: reservationData.id,
      agent: reservationData.agent,
      notes: reservationData.notes,
      name: reservationData.name,
      imo: reservationData.imo,
      start_time: reservationData.start_time
        ? dayjs(reservationData.start_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      end_time: reservationData.end_time
        ? dayjs(reservationData.end_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      berth_code: reservationData.berth_code,
      start_bollard: reservationData.start_bollard,
      end_bollard: reservationData.end_bollard,
    });

    if (mounted.current) {
      updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
    }

    return result;
  };

  const sendUpdateBerthBlock = async data => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('put', 'berth-reservations', {
        id: data.id,
        reservation_start: data.start_time
          ? dayjs(data.start_time)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        reservation_end: data.end_time
          ? dayjs(data.end_time)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        berth_code: data.berth_code,
        berth_reservation_type: 'port_blocked',
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendUpdateNotPlannedBerth = async data => {
    setJitVesselLoader(value => value + 1);

    try {
      await apiCall('post', 'bpt-port-call-data', {
        port_call_id: data.port_call_id,
        berth_code: data.berth_code,
      });
    } catch (e) {
      console.error(e);
      setJitVesselLoader(value => value - 1);
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const sendPredictiveBerthPlanData = async data => {
    const result = await apiCall('post', 'bpt-update-calendar-vessel', data);

    if (mounted.current && result?.status === 200) {
      getUndoRedoAvailability();
    }

    return result;
  };

  /**
   * Make API call to backend in order to add upcoming vessels and promote them (if selected).
   *
   * @param {*} upcomingVesselData Array containing upcoming vessels to add.
   * @param {*} promotionOn True if "Promote all vessels" has been selected. Else false.
   *
   * @returns Result from backend.
   */
  const sendAddUpcomingVessel = async (upcomingVesselData, promotionOn) => {
    let data = upcomingVesselData.map(d => {
      return {
        source: 'manual',
        imo: d.imo,
        eta: d.eta
          ? dayjs(d.eta)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        etd: d.etd
          ? dayjs(d.etd)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ')
          : undefined,
        agent: d.agent,
        name: d.name,
        berth_code: d.berth_code,
        notes: d.notes,
      };
    });

    let result = await apiCall('post', 'bpt-add-upcoming-multi-vessel', { vessels: data, promote: promotionOn });
    if (mounted.current) {
      updateUpcomingVesselData();
    }
    return result;
  };

  const sendUpdateUpcomingVessel = async upcomingVesselData => {
    let result = await apiCall('put', 'bpt-update-upcoming-vessel', {
      id: upcomingVesselData.id,
      source: 'manual',
      imo: upcomingVesselData.imo,
      eta: upcomingVesselData.eta
        ? dayjs(upcomingVesselData.eta)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      etd: upcomingVesselData.etd
        ? dayjs(upcomingVesselData.etd)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined,
      agent: upcomingVesselData.agent,
      name: upcomingVesselData.name,
      berth_code: upcomingVesselData.berth_code,
      notes: upcomingVesselData.notes,
    });

    if (mounted.current) {
      await updateUpcomingVesselData();
    }

    return result;
  };

  const sendDeleteUpcomingVessel = async id => {
    await apiCall('delete', 'bpt-delete-upcoming-vessel', {
      id: id,
    });

    if (mounted.current) {
      await updateUpcomingVesselData();
    }
  };

  const sendPromoteUpcomingVessel = async id => {
    await apiCall('post', 'bpt-promote-upcoming-vessel', {
      id: id,
    });

    if (mounted.current) {
      await updateUpcomingVesselData();
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
    }
  };

  const setRowHeightFunction = rowHeight => {
    setRowHeight(rowHeight);
  };

  const setCollapsedRowHeightFunction = collapsedRowHeight => {
    setCollapsedRowHeight(collapsedRowHeight);
  };

  const handlePortcallDelete = async ship => {
    const data = {
      imo: ship.imo,
      vessel_name: ship.name,
      time_type: 'Actual',
      state: 'PortVisit_Cancelled',
      time: dayjs()
        .utc()
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      payload: {
        source: 'ui',
        external_id: ship.port_call_master_id,
      },
    };

    if (mounted.current) {
      if (ship.state === VESSEL_STATE_PLANNED) {
        await apiCall('delete', 'bpt-delete-plan', {
          port_call_id: ship.port_call_id,
        });
      } else {
        await apiCall('post', 'timestamps', data);
      }

      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
    }
  };

  const getStartTime = vessel => {
    if (vessel.state === 6) {
      return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else if (vessel.state === 5) {
      return dayjs.max([dayjs.min([dayjs(vessel.pta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else if (vessel.state === 7) {
      return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else {
      return dayjs.max([dayjs(vessel.start_time), dayjs(canvasTimeStart)]);
    }
  };

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();

  const getTimeFromPixels = pixels => {
    const pixelInMilliseconds =
      dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;

    return dayjs(canvasTimeStart + pixels * pixelInMilliseconds).format();
  };

  const sendMultiSelectVesselData = async data => {
    setJitVesselLoader(value => value + 1);

    const pixelsFromTimes = (data.hours * 3600000 + data.minutes * 60000) * millisecondInPixels;

    let selectedVessels = data.vessels.map(v => {
      const vessel = calendarVessels.find(vl => vl.port_call_master_id === v.id);
      const left =
        dayjs.duration(dayjs(getStartTime(vessel)).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels;
      return {
        id: vessel.slot_reservation_id,
        rta: getTimeFromPixels(left + pixelsFromTimes * (data.direction === 'plus' ? 1 : -1)),
        laytime: vessel.laytime,
      };
    });

    if (data.direction === 'plus') {
      selectedVessels = selectedVessels.sort((a, b) => new Date(b.rta) - new Date(a.rta));
      for (let index = 0; index < selectedVessels.length; index++) {
        const selectedVessel = selectedVessels[index];
        try {
          await apiCall('put', 'slot-reservations', {
            id: selectedVessel.id,
            laytime: dayjs.duration(selectedVessel.laytime).toISOString(),
            jit_eta: dayjs(selectedVessel.rta).format('YYYY-MM-DDTHH:mm:ssZ'),
            rta_window_start: dayjs(selectedVessel.rta).format('YYYY-MM-DDTHH:mm:ssZ'),
          });
        } catch {
          if (mounted.current) {
            await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
            setJitVesselLoader(value => value - 1);
          }
        }
      }
    } else {
      selectedVessels = selectedVessels.sort((b, a) => new Date(b.rta) - new Date(a.rta));
      for (let index = 0; index < selectedVessels.length; index++) {
        const selectedVessel = selectedVessels[index];
        try {
          await apiCall('put', 'slot-reservations', {
            id: selectedVessel.id,
            laytime: dayjs.duration(selectedVessel.laytime).toISOString(),
            jit_eta: dayjs(selectedVessel.rta).format('YYYY-MM-DDTHH:mm:ssZ'),
            rta_window_start: dayjs(selectedVessel.rta).format('YYYY-MM-DDTHH:mm:ssZ'),
          });
        } catch {
          if (mounted.current) {
            await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
            setJitVesselLoader(value => value - 1);
          }
        }
      }
    }

    if (mounted.current) {
      await updateCalendarVesselData(canvasTimeStart, canvasTimeEnd, true);
      setJitVesselLoader(value => value - 1);
    }
  };

  const handleAdditionalData = async (data, id) => {
    const toBeDeleted = [];
    const toBeAdded = [];

    setLoader(true);

    for (let index in data) {
      let category = data[index];

      category.items.forEach(i => {
        if (category.deleted || i.deleted) {
          toBeDeleted.push({ category: category.type, port_call_id: id, name: i.name, value: i.value });
        } else {
          toBeAdded.push({ category: category.type, port_call_id: id, name: i.name, value: i.value });
        }
      });
    }

    try {
      for (const item of toBeDeleted) {
        await apiCall('delete', 'additional/port-call-data', item);
      }

      for (const item of toBeAdded) {
        await apiCall('put', 'additional/port-call-data', item);
      }
    } catch (e) {
      setLoader(false);
    }

    setLoader(false);
  };

  const { data: commodityData, error: commodityError } = useApi(
    'get',
    'pbp/port-commodities',
    {},
    null,
    modules.predictive_berth_planning_module === 'enabled' && user.permissions.includes('manage_pbp')
  );
  const { data: companyData, error: companyError } = useApi(
    'get',
    'pbp/companies',
    {},
    null,
    modules.predictive_berth_planning_module === 'enabled' && user.permissions.includes('manage_pbp')
  );
  const { data: craneData, error: craneError } = useApi(
    'get',
    'pbp/cranes',
    {},
    null,
    modules.predictive_berth_planning_module === 'enabled' && user.permissions.includes('view_crane_reservations')
  );
  const { data: operationTypeData, error: operationTypeError } = useApi(
    'get',
    'pbp/operation-types',
    {},
    null,
    modules.predictive_berth_planning_module === 'enabled' && user.permissions.includes('view_pbp')
  );

  const commodities = commodityError || !commodityData ? [] : commodityData;
  const companies = companyError || !companyData ? [] : companyData;
  const cranes = craneError || !craneData ? [] : craneData;
  const operationTypes = operationTypeError || !operationTypeData ? [] : operationTypeData.operation_types;

  return (
    <BerthPlanningToolContext.Provider
      value={{
        visibleTimeStart,
        setVisibleTimeStart,
        visibleTimeEnd,
        setVisibleTimeEnd,
        canvasTimeStart,
        setCanvasTimeStart,
        canvasTimeEnd,
        setCanvasTimeEnd,
        getLeftOffsetFromDate,
        getDateFromLeftOffsetPosition,
        sidebarWidth,
        setSidebarWidth,
        berths,
        setBerths,
        canvasWidth,
        setCanvasWidth,
        timelineUnit,
        setTimelineUnit,
        width,
        setWidth,
        height,
        updateScrollCanvas,
        setTimelineType,
        openRow,
        closeRow,
        setToday,
        indicatorLine,
        setIndicatorLine,
        calendarVessels,
        calendarVesselSummary,
        draggingArea,
        setDraggingArea,
        updateVesselLocation,
        keepVesselLocation,
        draggingOverlapping,
        setDraggingOverlapping,
        containerLimits,
        setContainerLimits,
        upcomingVessels,
        updateUpcomingVessel,
        keepUpcomingVessel,
        moveForwards,
        moveBackwards,
        changeDate,
        getBerthStartData,
        loadMoreRight,
        loadMoreLeft,
        draggingMove,
        setDraggingMove,
        draggingRowInfo,
        setDraggingRowInfo,
        loader,
        undoLoader,
        redoLoader,
        commitLoader,
        onRedo,
        onUndo,
        undoDisabled,
        redoDisabled,
        berthsOpen,
        sendCommitAll,
        toggleAllRowsOpen,
        sendDeleteReservation,
        sendAddReservation,
        sendUpdateReservation,
        sendDeleteBerthBlock,
        sendAddBerthBlock,
        sendUpdateBerthBlock,
        sendAddUpcomingVessel,
        sendUpdateUpcomingVessel,
        sendDeleteUpcomingVessel,
        sendPromoteUpcomingVessel,
        collapsedRowHeight,
        setCollapsedRowHeightFunction,
        rowHeight,
        setRowHeightFunction,
        rowsOpen,
        setRowsOpen,
        zoomSliderValue,
        setZoomSliderValue,
        setScrollOffset,
        forceUpdate,
        setForceUpdate,
        disableScrolling,
        setDisableScrolling,
        handlePortcallDelete,
        sidePanelWidth,
        setSidePanelWidth,
        predictiveBPTOn,
        setPredictiveBPTOn,
        commodities,
        companies,
        cranes,
        operationTypes,
        getUndoRedoAvailability,
        setReloadCalendarData,
        berthAreasAvailable,
        sendDeleteSlotResquest,
        sendEditSlotRequest,
        setBptModalOpen,
        sendUpdateNotPlannedBerth,
        updateQueueVesselLocation,
        queueModalTypeOpen,
        setQueueModalTypeOpen,
        jitVesselLoader,
        sendPredictiveBerthPlanData,
        sendEditEtdSlotRequest,
        moveSelection,
        setMoveSelection,
        multiselectedVessels,
        setMultiselectedVessels,
        multiselectedMoveVessels,
        setMultiselectedMoveVessels,
        multiselectDisableScrolling,
        setMultiselectDisableScrolling,
        multiselectedMove,
        setMultiselectedMove,
        setMultiselectedModalOpen,
        multiselectedModalOpen,
        keepMultiVessels,
        sendMultiSelectVesselData,
        windWarnings,
        vesselLayerOn,
        setVesselLayerOn,
        vesselOptions,
        setVesselOptions,
        additionalData,
        handleAdditionalData,
        dynamicBollards,
        rowHeightFromLocalStorageChecked,
        setRowHeightFromLocalStorageChecked,
        disablePbpDragging,
      }}
    >
      {children}
    </BerthPlanningToolContext.Provider>
  );
};
