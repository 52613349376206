import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';

import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';

import ButtonLight from '../../ui/ButtonLight';
import DebounceSelect from '../../ui/DebounceSelect';

import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import ExternalField from './externalTypes/ExternalFields';

const ColumnsSplit = styled.div`
  display: block;
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .ant-input[disabled] {
    border: 1px solid #e0e0e0;
  }
`;

const Header = styled.div`
  font-weight: 700;
  margin: 8px;
`;

const ModalInner = styled.div`
  min-height: 230px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;

  textarea {
    resize: none;
    height: 94px;
  }
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: ${props => !props.noBorder && '1px solid transparent'};
    }
  }
`;

const SecondColumnSplit = styled.div`
  width: 100%;
  display: inline-block;
`;

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const Required = styled.span`
  color: red;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  border-radius: 2px;
`;

const TrashButton = styled(Button)`
  color: #1c1c1c;
  :hover {
    color: #d0011c;
  }
`;

const RowContainerSplitButtons = styled.div`
  padding: 0px 0px 7px 7px;
  min-width: 10px;
`;

const AddNewServiceLink = styled.div`
  text-transform: none;
  letter-spacing: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  color: #407505;
  margin-top: 18px;
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  padding-left: 8px;
  display: inline-block;
  height: 70px;
  margin-bottom: 24px;
`;

const InfoRowSplit = styled.div`
  width: auto;
  padding-right: 25px;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 12px;
`;

const AddRowButton = styled(Button)`
  zindex: '5';
  margintop: '-5px';
  marginleft: '0px';
  color: '#4990DD';
  display: 'flex';

  &:hover {
    background-color: #f8f8f8;
  }

  svg {
    margin-top: 2px;
  }
`;

const AddButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  padding: 0 4px;

  &:hover {
    background-color: #f8f8f8;
  }

  i {
    margin-right: 12px;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-top: -4px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const HeaderInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoIcon = styled.div`
  color: ${({ theme }) => theme.color.secondary};

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const WHEN_HELPTEXT = `Should be used when the exact delivery time is unknown.
Example values:
- on arrival
- during portcall
- before departure`;

const AddServiceOrderModal = ({ closeModal, vessel, reloadData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState([
    {
      service_id: null,
      service_name: null,
      service_search: null,
      quantity: null,
      when: null,
      notes: null,
      requested_delivery_time: null,
      email: '',
      phone: '',
      additionalOpen: false,
      external_type_data: {},
      externalType: null,
      organization_id: null,
      organization_name: null,
      organization: null,
    },
  ]);
  const [additionalFields, setAdditionalFields] = useState([
    {
      fields: [],
    },
  ]);
  const [supportedOrderLineFields, setSupportedOrderLineFields] = useState([
    {
      fields: null,
    },
  ]);
  const [dataSent, setDataSent] = useState(false);

  const title = t('Add new service request for {{vesselName}}', { vesselName: vessel.vessel_name });

  const listDataIsValid = () => {
    let dataIsValid = true;
    inputData.forEach(d => {
      if (!d.service_id) {
        dataIsValid = false;
        return;
      }
    });
    const supportedFieldsValid = !inputData.some((orderLine, index) => {
      if (supportedOrderLineFields?.[index]?.fields?.length) {
        for (const field of supportedOrderLineFields[index].fields) {
          if (field.required && !orderLine[field.name]) {
            return true;
          }
        }
      }
      return false;
    });

    return dataIsValid && supportedFieldsValid;
  };

  const updateServiceSplit = (value, data, index) => {
    let newInputData = [...inputData];
    newInputData[index]['service_id'] = value;
    newInputData[index]['service_name'] = data.label;
    newInputData[index]['externalType'] = data.data?.provider.data?.external_type || null;
    setInputData(newInputData);

    clearOrganizationSplit(index);

    fetchAdditionalCreateFields(index, data.data?.provider.data?.external_type);

    setSupportedOrderLineFields(prev => {
      const newData = [...prev];
      if (newData[index]) {
        newData[index]['fields'] = data.data?.data?.external_data?.supportedOrderLineFields || null;
      }
      return newData;
    });

    data.data?.data?.external_data?.supportedOrderLineFields?.forEach(field => {
      if (field.defaultValue) {
        setInputData(prev => {
          const newData = [...prev];
          newData[index][field.name] = field.defaultValue;
          return newData;
        });
        if (field.name === 'organization') {
          // Special handling for organizaton
          fetchOrganizationList(field.defaultValue, index);
        }
      } else if (field.defaultValueFrom && vessel[field.defaultValueFrom]) {
        setInputData(prev => {
          const newData = [...prev];
          newData[index][field.name] = vessel[field.defaultValueFrom];
          return newData;
        });
        if (field.name === 'organization') {
          // Special handling for organizaton
          fetchOrganizationList(vessel[field.defaultValueFrom], index);
        }
      }
    });
  };

  const clearServiceSplit = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['service_id'] = '';
    newInputData[index]['service_name'] = '';
    newInputData[index]['external_type_data'] = {};
    newInputData[index]['externalType'] = null;
    setInputData(newInputData);

    clearOrganizationSplit(index);

    setAdditionalFields(prev => {
      const newData = [...prev];
      if (newData[index]) {
        newData[index]['fields'] = [];
      }
      return newData;
    });

    setSupportedOrderLineFields(prev => {
      const newData = [...prev];
      if (newData[index]) {
        newData[index]['fields'] = null;
      }
      return newData;
    });
  };

  const handleServiceSearchChange = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['service_search'] = value;
    setInputData(newInputData);
  };

  const handleOrganizationSearchChange = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['organization'] = value;
    setInputData(newInputData);
  };

  const updateQuantitySplit = (e, index) => {
    let newInputData = [...inputData];
    newInputData[index]['quantity'] = e.target.value;
    setInputData(newInputData);
  };

  const updateWhenSplit = (e, index) => {
    let newInputData = [...inputData];
    newInputData[index]['when'] = e.target.value;
    setInputData(newInputData);
  };

  const updateNotesSplit = (e, index) => {
    let newInputData = [...inputData];
    newInputData[index]['notes'] = e.target.value;
    setInputData(newInputData);
  };

  const updateEmailSplit = (e, index) => {
    let newInputData = [...inputData];
    newInputData[index]['email'] = e.target.value;
    setInputData(newInputData);
  };

  const updatePhoneSplit = (e, index) => {
    let newInputData = [...inputData];
    newInputData[index]['phone'] = e.target.value;
    setInputData(newInputData);
  };

  const updateRequestedTimeSplit = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['requested_delivery_time'] = value ? value.format() : value;
    setInputData(newInputData);
  };

  const openAdditionalOpen = index => {
    let newInputData = [...inputData];
    newInputData[index]['additionalOpen'] = true;
    setInputData(newInputData);
  };

  const closeAdditionalOpen = index => {
    let newInputData = [...inputData];
    newInputData[index]['additionalOpen'] = false;
    newInputData[index]['phone'] = null;
    newInputData[index]['email'] = null;
    setInputData(newInputData);
  };

  const updateExternalTypeDataSplit = (value, index, key) => {
    let newInputData = [...inputData];
    newInputData[index]['external_type_data'][key] = value;
    setInputData(newInputData);
  };

  const updateOrganizationSplit = (value, data, index) => {
    let newInputData = [...inputData];
    newInputData[index]['organization_id'] = value;
    newInputData[index]['organization_name'] = data.label;
    setInputData(newInputData);
  };

  const clearOrganizationSplit = index => {
    let newInputData = [...inputData];
    newInputData[index]['organization_id'] = null;
    newInputData[index]['organization_name'] = null;
    newInputData[index]['organization'] = null;
    setInputData(newInputData);
  };

  const sendData = async () => {
    const result = await apiCall('post', 'service-orders/service-order', {
      service_order: {
        port_call_id: vessel.master_id,
        imo: vessel.imo,
        mmsi: vessel.mmsi,
        vessel_name: vessel.vessel_name,
        order_lines: inputData.map(d => {
          return {
            service_id: d.service_id,
            quantity: parseFloat(d.quantity),
            when: d.when,
            requested_delivery_time: d.requested_delivery_time,
            notes: d.notes,
            email: d.email,
            phone: d.phone,
            ...(d.organization_id ? { organization_id: d.organization_id } : {}),
            ...(d.externalType
              ? {
                  external_type_data: {
                    ...d.external_type_data,
                    type: d.externalType,
                  },
                }
              : {}),
          };
        }),
      },
    });

    if (result?.status === 200) {
      reloadData();
      setDataSent(true);
    }
  };

  const addSplitButton = () => {
    let newInputData = [...inputData];

    newInputData.push({
      service_id: null,
      quantity: null,
      when: null,
      notes: null,
      external_type_data: {},
      externalType: null,
      organization_id: null,
      organization_name: null,
      organization: null,
    });

    setInputData(newInputData);

    setAdditionalFields(prev => {
      const newData = [...prev];
      newData.push({
        fields: [],
      });
      return newData;
    });

    setSupportedOrderLineFields(prev => {
      const newData = [...prev];
      newData.push({
        fields: null,
      });
      return newData;
    });
  };

  const removeSplitButton = value => {
    let inputDataForDeleting = [...inputData];
    inputDataForDeleting.splice(value, 1);
    setInputData(inputDataForDeleting);

    setAdditionalFields(prev => {
      const newData = [...prev];
      newData.splice(value, 1);
      return newData;
    });

    setSupportedOrderLineFields(prev => {
      const newData = [...prev];
      newData.splice(value, 1);
      return newData;
    });
  };

  const fetchServiceList = async value => {
    const params = {
      query: {
        text: value,
      },
    };
    const result = await apiCall('post', 'service-orders/service/search', params);
    if (result?.status === 200 && result?.data) {
      return result.data.results.services.map(d => {
        return { value: d.id, label: d.type.name + ', ' + d.provider.name, data: d };
      });
    }
    return [];
  };

  const fetchAdditionalCreateFields = async (index, type) => {
    let data = [];
    if (type) {
      const result = await apiCall('get', 'service-orders/external-type/create-fields', { type: type });
      if (result?.status === 200 && result?.data) {
        data = result.data?.fields || [];
      }
    }
    setAdditionalFields(prev => {
      const newData = [...prev];
      if (newData[index]) {
        newData[index]['fields'] = data;
      }
      return newData;
    });
  };

  const fetchOrganizationList = async (value, index = undefined) => {
    const params = {
      limit: 100,
      offset: 0,
      sort: 'name',
      search: value,
    };
    const result = await apiCall('get', 'organizations', params);
    if (result?.status === 200 && result?.data) {
      if (result.data.organizations) {
        const organizations = result.data.organizations.map(d => {
          return { value: d.id.toString(), label: d.name, data: d };
        });
        if (index !== undefined && organizations.length) {
          // Try to update organization of the order line
          if (inputData?.[index]?.['organization'] && !inputData?.[index]?.['organization_id']) {
            const organization = organizations.find(
              item => item.label?.toUpperCase?.() === inputData[index]['organization'].toUpperCase?.()
            );
            if (organization) {
              updateOrganizationSplit(organization.value, { label: organization.label }, index);
            } else {
              // Organization not found, clear prefilled data
              clearOrganizationSplit(index);
            }
          }
        }

        return organizations;
      } else {
        // Organization not found, clear prefilled data
        clearOrganizationSplit(index);
        return [];
      }
    }
    if (index !== undefined) {
      // Organization not found, clear prefilled data
      clearOrganizationSplit(index);
    }
    return [];
  };

  return (
    <Modal title={title} open={true} width={1200} footer={null} onCancel={closeModal}>
      {dataSent ? (
        <ModalInner>
          <IconContainer>
            <Icon type="request-succ"></Icon>
          </IconContainer>
          <ConfirmationText>{t('Service request sent successfully!')}</ConfirmationText>
          <MiddleLine />
          <ActionButtons noBorder={true}>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        </ModalInner>
      ) : (
        <ModalInner>
          <FirstColumnSplit>
            <TopContainer>
              <InfoRowSplit>
                {t('Vessel:')}
                <BoldText>{vessel.vessel_name}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('IMO:')}
                <BoldText>{vessel.imo}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('Length:')}
                <BoldText>{vessel.loa || '-'}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('Draft:')}
                <BoldText>{vessel.draft || '-'}</BoldText>
              </InfoRowSplit>
            </TopContainer>
            <TopContainer>
              <InfoRowSplit>
                {t('ETA:')}
                <BoldText>{vessel.current_eta ? dayjs(vessel.current_eta).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('ETD:')}
                <BoldText>{vessel.current_etd ? dayjs(vessel.current_etd).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('Agent:')}
                <BoldText>{vessel.agent || 'Unknown'}</BoldText>
              </InfoRowSplit>
            </TopContainer>
          </FirstColumnSplit>
          <Header>{t('Services')}</Header>
          <ColumnsSplit>
            <SecondColumnSplit>
              {inputData.map((row, index) => {
                const quantityField = supportedOrderLineFields?.[index]?.fields?.find?.(
                  field => field.name === 'quantity'
                );
                const showQuantity =
                  !supportedOrderLineFields?.[index]?.fields || (quantityField && !quantityField.hidden);

                const whenField = supportedOrderLineFields?.[index]?.fields?.find?.(field => field.name === 'when');
                const showWhen = !supportedOrderLineFields?.[index]?.fields || (whenField && !whenField.hidden);

                const requestedDeliveryTimeField = supportedOrderLineFields?.[index]?.fields?.find?.(
                  field => field.name === 'requested_delivery_time'
                );
                const showRequestedDeliveryTime =
                  !supportedOrderLineFields?.[index]?.fields ||
                  (requestedDeliveryTimeField && !requestedDeliveryTimeField.hidden);

                const notesField = supportedOrderLineFields?.[index]?.fields?.find?.(field => field.name === 'notes');
                const showNotes = !supportedOrderLineFields?.[index]?.fields || (notesField && !notesField.hidden);

                const emailField = supportedOrderLineFields?.[index]?.fields?.find?.(field => field.name === 'email');
                const showEmail = !supportedOrderLineFields?.[index]?.fields || (emailField && !emailField.hidden);

                const phoneField = supportedOrderLineFields?.[index]?.fields?.find?.(field => field.name === 'phone');
                const showPhone = !supportedOrderLineFields?.[index]?.fields || (phoneField && !phoneField.hidden);

                const showAdditionalRow = showEmail || showPhone;

                const organizationField = supportedOrderLineFields?.[index]?.fields?.find?.(
                  field => field.name === 'organization'
                );
                const showOrganization = organizationField && !organizationField.hidden;

                return (
                  <InfoContainer
                    key={index + '_' + row.id}
                    style={{ backgroundColor: index % 2 === 1 ? '#f9f9f9' : 'transparent' }}
                  >
                    <RowContainer style={{ width: showNotes ? 'calc(100% / 3 * 2' : '100%' }}>
                      <Container>
                        <RowContainerSplit style={{ width: '35%' }}>
                          <RowHeader>
                            {t('Service')}
                            <Required>*</Required>
                          </RowHeader>
                          <DebounceSelect
                            placeholder={t('Provider and type')}
                            fetchOptions={fetchServiceList}
                            onChange={value => handleServiceSearchChange(value, index)}
                            onSelect={(value, data) => updateServiceSplit(value, data, index)}
                            value={row.service_search || row.service_name}
                            style={{ width: '100%' }}
                            allowClear={true}
                            onClear={() => clearServiceSplit('', index)}
                          />
                        </RowContainerSplit>
                        {showQuantity ? (
                          <RowContainerSplit style={{ width: '15%' }}>
                            <RowHeader>
                              {t('Quantity')}
                              {quantityField?.required ? <Required>*</Required> : null}
                            </RowHeader>
                            <StyledInputNumber
                              placeholder={t('Amount')}
                              value={row.quantity || ''}
                              defaultValue={
                                supportedOrderLineFields[index]?.fields?.quantity?.defaultValue || undefined
                              }
                              decimalSeparator={','}
                              min={0}
                              onChange={e => updateQuantitySplit({ target: { value: e } }, index)}
                            />
                          </RowContainerSplit>
                        ) : null}
                        {showWhen ? (
                          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
                            <HeaderInfoRow>
                              <RowHeader>
                                {t('When (free text)')}
                                {whenField?.required ? <Required>*</Required> : null}
                              </RowHeader>
                              <InfoIcon>
                                <Tooltip
                                  title={t(WHEN_HELPTEXT)}
                                  color="white"
                                  overlayStyle={{ whiteSpace: 'pre-line' }}
                                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                                >
                                  <div>
                                    <Icon type="info"></Icon>
                                  </div>
                                </Tooltip>
                              </InfoIcon>
                            </HeaderInfoRow>
                            <Input
                              placeholder={t('When')}
                              value={row.when || ''}
                              onChange={value => updateWhenSplit(value, index)}
                            />
                          </RowContainerSplit>
                        ) : null}
                        {showRequestedDeliveryTime ? (
                          <RowContainerSplit style={{ width: 'calc(100% / 4)' }}>
                            <HeaderInfoRow>
                              <RowHeader>
                                {t('Requested delivery time')}
                                {requestedDeliveryTimeField?.required ? <Required>*</Required> : null}
                              </RowHeader>
                              <InfoIcon>
                                <Tooltip
                                  title={
                                    requestedDeliveryTimeField?.required
                                      ? t('Must be set')
                                      : t('Should be always set if possible')
                                  }
                                  color="white"
                                  overlayStyle={{ whiteSpace: 'pre-line' }}
                                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                                >
                                  <div>
                                    <Icon type="info"></Icon>
                                  </div>
                                </Tooltip>
                              </InfoIcon>
                            </HeaderInfoRow>
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              showTime={{ format: 'HH:mm', minuteStep: 15 }}
                              style={{
                                width: '100%',
                              }}
                              value={row.requested_delivery_time ? dayjs(row.requested_delivery_time) : null}
                              onChange={value => updateRequestedTimeSplit(value, index)}
                              placeholder={t('Requested delivery time')}
                            />
                          </RowContainerSplit>
                        ) : null}
                      </Container>
                      {showOrganization || additionalFields?.[index]?.fields.length ? (
                        <Container>
                          {showOrganization ? (
                            <RowContainerSplit style={{ width: '35%' }}>
                              <RowHeader>
                                {t('Organization')}
                                {organizationField?.required ? <Required>*</Required> : null}
                              </RowHeader>
                              <DebounceSelect
                                placeholder={t('Search {{name}}', {
                                  name: organizationField?.defaultValueFrom
                                    ? organizationField.defaultValueFrom
                                    : 'organization',
                                })}
                                fetchOptions={fetchOrganizationList}
                                onChange={value => handleOrganizationSearchChange(value, index)}
                                onSelect={(value, data) => updateOrganizationSplit(value, data, index)}
                                value={row.organization || row.organization_name}
                                defaultValue={row.organization || row.organization_name}
                                style={{ width: '100%' }}
                                allowClear={true}
                                onClear={() => clearOrganizationSplit(index)}
                              />
                            </RowContainerSplit>
                          ) : null}
                          {additionalFields?.[index]?.fields?.map?.((field, idx) => {
                            return (
                              <RowContainerSplit
                                key={`additional-field-${idx}`}
                                style={{ width: '100%', maxWidth: '350px' }}
                              >
                                <RowHeader style={{ textTransform: 'capitalize' }}>
                                  {t(field.name.replace('_', ' '))}
                                  {field.required ? <Required>*</Required> : null}
                                </RowHeader>
                                <ExternalField
                                  type={field.type}
                                  index={index}
                                  field={field.name}
                                  value={row.external_type_data?.[field.name] || null}
                                  handleUpdate={updateExternalTypeDataSplit}
                                  placeHolder={t(field.name.replace('_', ' '))}
                                  options={field.options}
                                />
                              </RowContainerSplit>
                            );
                          })}
                        </Container>
                      ) : null}
                      {showAdditionalRow ? (
                        <Container>
                          {row.additionalOpen ? (
                            <div style={{ display: 'flex', width: '100%' }}>
                              {showEmail ? (
                                <RowContainerSplit style={{ width: '35%' }}>
                                  <RowHeader>{t('Email')}</RowHeader>
                                  <Input
                                    placeholder={t('Email')}
                                    value={row.email || ''}
                                    onChange={e => updateEmailSplit(e, index)}
                                  />
                                </RowContainerSplit>
                              ) : null}
                              {showPhone ? (
                                <RowContainerSplit style={{ width: '35%' }}>
                                  <RowHeader>{t('Phone')}</RowHeader>
                                  <Input
                                    placeholder={t('Phone number')}
                                    value={row.phone || ''}
                                    onChange={e => updatePhoneSplit(e, index)}
                                  />
                                </RowContainerSplit>
                              ) : null}
                              <AddButton
                                onClick={() => closeAdditionalOpen(index)}
                                style={{ marginTop: '28px', marginLeft: '6px', height: '22px', whiteSpace: 'nowrap' }}
                              >
                                <Icon type={'minus'} />
                                {t('Remove phone and/or email')}
                              </AddButton>
                            </div>
                          ) : (
                            <AddButton onClick={() => openAdditionalOpen(index)}>
                              <Icon type={'plus'} />
                              {t('Add phone and/or email')}
                            </AddButton>
                          )}
                        </Container>
                      ) : null}
                    </RowContainer>
                    {showNotes ? (
                      <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                        <RowHeader>
                          {t('Request note')}
                          {notesField?.required ? <Required>*</Required> : null}
                        </RowHeader>
                        <Input
                          placeholder={t('Request note')}
                          value={row.notes || ''}
                          onChange={e => updateNotesSplit(e, index)}
                        />
                      </RowContainerSplit>
                    ) : null}
                    {inputData.length > 1 && (
                      <RowContainerSplitButtons>
                        <TrashButton
                          copy
                          style={{
                            zIndex: '5',
                            marginTop: '55px',
                            marginLeft: '0',
                            color: '#D0011C',
                          }}
                          onClick={() => removeSplitButton(index)}
                        >
                          <Icon type={'trash'} />
                        </TrashButton>
                      </RowContainerSplitButtons>
                    )}
                  </InfoContainer>
                );
              })}
            </SecondColumnSplit>
            <RowContainerSplitButtons style={{ marginLeft: 'calc(100% - 158px)' }}>
              <AddRowButton
                normal
                style={{ zIndex: '5', marginTop: '-5px', marginLeft: '0px', color: '#4990DD', display: 'flex' }}
                onClick={() => addSplitButton()}
              >
                <Icon type={'circle-plus'} />
                <AddNewServiceLink style={{ fontSize: '15px', fontWeight: '400' }}>
                  {t('Add new service')}
                </AddNewServiceLink>
              </AddRowButton>
            </RowContainerSplitButtons>
          </ColumnsSplit>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!listDataIsValid()} onClick={sendData}>
              {t('Send request')}
            </ButtonLight>
          </ActionButtons>
        </ModalInner>
      )}
    </Modal>
  );
};

export default AddServiceOrderModal;
