import React, { useState, createContext, useEffect, useContext, useRef, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  calculateXPositionForTime,
  calculateTimeForXPosition,
  getCanvasBoundariesFromVisibleTime,
  getCanvasWidth,
  calculateScrollCanvas,
} from '../components/commonCalendar/utility/calendar';

import { fleetRowHeightConstant, smallSidebarWidth } from '../components/commonCalendar/utility/constants';

import {
  calculatePortcallRows,
  fleetTimelineTypeStorageKey,
  fleetTimelineTypeStorageKeyOptions,
} from '../components/fleetView/calendar/utility/contextHelpers';
import { UserContext } from './UserContext';
import { FleetFilteringContext } from './FleetFilteringContext';
import { buildQuery } from '../components/filter/fleetFilterHelpers';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const bufferMultiplier = 0.5;

const fleetViewZoomStorage = 'fleetView_zoom_value';

export const FleetViewContext = createContext();

export const FleetViewProvider = ({ children }) => {
  const defaultTimeStart = dayjs()
    .startOf('isoWeek')
    .toDate();

  const defaultTimeEnd = dayjs()
    .startOf('isoWeek')
    .add(1, 'week')
    .toDate();

  const initialIndicatorLine = {
    time: dayjs(),
    isUserSet: false,
  };

  const { apiCall, namespaces, namespace, useUserSocket } = useContext(UserContext);
  const { fleetFilter, formData, filteringDataAvailable } = useContext(FleetFilteringContext);
  const [visibleTimeStart, setVisibleTimeStart] = useState(defaultTimeStart.valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(defaultTimeEnd.valueOf());
  const [canvasTimeStart, setCanvasTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[0]
  );
  const [canvasTimeEnd, setCanvasTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[1]
  );
  const [bufferTimeStart, setBufferTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf(), bufferMultiplier)[0]
  );
  const [bufferTimeEnd, setBufferTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf(), bufferMultiplier)[1]
  );

  const [ports, setPorts] = useState([]);
  const [width, setWidth] = useState(1000);
  const [canvasWidth, setCanvasWidth] = useState(getCanvasWidth(1000));
  const [timelineUnit, setTimelineUnit] = useState(null);
  const [indicatorLine, setIndicatorLine] = useState(initialIndicatorLine);
  const [rowHeight, setRowHeight] = useState(fleetRowHeightConstant);

  const [sideModalOpen, setSideModalOpen] = useState(undefined);

  const [loader, setLoader] = useState(true);
  const [smallLoader, setSmallLoader] = useState(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [otherNamespaceData, setOtherNamespaceData] = useState({});
  const [sidebarSelection, setSidebarSelection] = useState('port');

  const [sidebarWidth, setSidebarWidth] = useState(smallSidebarWidth);
  const [zoomChecked, setZoomChecked] = useState(false);

  const [portLocodes, setPortLocodes] = useState([]);

  const [loadingAllowed, setLoadingAllowed] = useState(false);

  const [portcallIds, setPortcallIds] = useState(null);

  const timeIndicatorLineTimer = useRef();

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [zoomSliderValue, setZoomSliderValue] = useState(6);

  const setRowHeightFunction = rowHeight => {
    rowHeight < 14
      ? setRowHeight(14)
      : rowHeight > fleetRowHeightConstant + 10
        ? setRowHeight(fleetRowHeightConstant + 10)
        : setRowHeight(rowHeight);
    localStorage.setItem(fleetViewZoomStorage, (rowHeight - 14) / 2);
  };

  useEffect(() => {
    const value = localStorage.getItem(fleetViewZoomStorage);
    if (value && ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(value.toString())) {
      setRowHeightFunction(14 + value * 2);
      setZoomSliderValue(value);
    }
    setZoomChecked(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataFromOtherNamespaces = async () => {
    let dataCollection = {};
    for (const ns of namespaces) {
      if (ns.namespace !== namespace) {
        const result = await apiCall('get', 'ongoing-port-calls', undefined, undefined, undefined, ns.namespace);

        if (result.data) {
          const { portcalls } = result.data || { portcalls: [] };
          dataCollection[ns.namespace] = portcalls;
        }
      }
    }
    setOtherNamespaceData(dataCollection);
  };

  const getPortcallData = useCallback(
    async (startTime, endTime) => {
      const start = dayjs(startTime).format('YYYY-MM-DDTHH:mm:ssZ');
      const end = dayjs(endTime).format('YYYY-MM-DDTHH:mm:ssZ');

      let { query } = buildQuery(formData, start, end);

      const res = await apiCall('post', 'fleet/search/portcall,manual-portcall', {
        query: {
          ...query,
          ...(fleetFilter.query?.text ? { text: fleetFilter.query.text } : {}),
        },
        order_by: [
          {
            field: 'portarea',
            order: 'asc',
          },
          {
            field: 'berth_name',
            order: 'asc',
          },
          {
            field: 'vessel_name',
            order: 'asc',
          },
          {
            field: 'eta',
            order: 'asc',
          },
        ],
        group_by: 'port_name,berth_name',
      });

      const idList = [];

      const portData = Object.entries(res?.data?.results?.portcalls || {}).map(([portkey, items]) => {
        const berths = [];
        let rowNumber = 0;
        let even = true;
        Object.entries(items).map(([berth_key, portcalls]) => {
          const rows = calculatePortcallRows(
            portcalls,
            dayjs.duration(dayjs(endTime).diff(dayjs(startTime))).asHours() / (1 + 2 * bufferMultiplier)
          );
          const row_amount = Math.max(...rows.map(o => o.row), 0) + 1;
          const row_end = rowNumber + row_amount - 1;
          berths.push({
            berth_code: berth_key,
            portcalls: rows,
            row_count: row_amount,
            row_start: rowNumber,
            row_end,
            even,
          });
          rowNumber += row_amount;
          even = !even;

          portcalls.forEach(p => {
            if (idList.findIndex(i => i === p.master_id) === -1) {
              idList.push(p.master_id);
            }
          });
        });

        const portname = portkey.replace(/ *\([^)]*\) */g, '');

        const row_count = 0;
        const sumWithInitial = berths
          .map(b => b.row_count)
          .reduce((accumulator, currentValue) => accumulator + currentValue, row_count);

        return {
          vts_area_name: berths[0].portcalls[0].data.vtsAreaName || 'No VTS area',
          port_name: portname,
          locode: berths[0].portcalls[0].locode,
          row_count: sumWithInitial,
          berthList: berths,
        };
      });

      setPortcallIds(idList);

      const locodes = portData.map(d => d.locode);
      setPortLocodes(locodes);

      let areas = portData.reduce((group, port) => {
        const category = port.vts_area_name;
        if (category) {
          group[category] = group[category] ?? [];
          group[category].push(port);
        }
        return group;
      }, {});
      areas = Object.fromEntries(
        Object.entries(areas).sort(([a], [b]) => {
          if (a === 'No VTS area') {
            return 1; // a is greater than b
          }
          if (b === 'No VTS area') {
            return -1; // b is greater than a
          }
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
      );

      return areas;
    },
    [formData, apiCall, fleetFilter.query]
  );

  const portcallChanged = useCallback(() => {
    const onDataFetch = async () => {
      setLoader(true);

      const portData = await getPortcallData(bufferTimeStart, bufferTimeEnd);

      if (portData && isMountedRef.current) {
        setPorts(portData);
        setLoader(false);
      }
    };

    onDataFetch();
  }, [getPortcallData, bufferTimeStart, bufferTimeEnd]);

  useUserSocket('fleet-portcalls-changed', portcallChanged);
  useUserSocket('fleet-manual-portcalls-changed', portcallChanged);

  const updateTime = useCallback(() => {
    setIndicatorLine({ isUserSet: false, time: dayjs() });
  }, []);

  useEffect(() => {
    if (!indicatorLine.isUserSet) {
      timeIndicatorLineTimer.current = setInterval(updateTime, 30000);
    }
    return () => clearInterval(timeIndicatorLineTimer.current);
  }, [indicatorLine, updateTime]);

  useEffect(() => {
    const type = localStorage.getItem(fleetTimelineTypeStorageKey);
    if (type && fleetTimelineTypeStorageKeyOptions.includes(type)) {
      setTimelineType(type, false);
    } else {
      setTimelineType('week', false);
    }

    (async () => {
      setLoader(true);

      setTimeout(() => {
        getDataFromOtherNamespaces();
      }, 3000);

      if (isMountedRef.current) {
        setLoader(false);
      }
    })();
    // Run only when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (filteringDataAvailable && timelineUnit && !loadingAllowed) {
        setLoadingAllowed(true);
        setLoader(true);

        const portData = await getPortcallData(bufferTimeStart, bufferTimeEnd);

        if (portData && isMountedRef.current) {
          setPorts(portData);
          setLoader(false);
        }
      }
    })();
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetFilter, formData, timelineUnit, filteringDataAvailable, loadingAllowed]);

  useEffect(() => {
    (async () => {
      if (loadingAllowed) {
        setLoader(true);

        const portData = await getPortcallData(bufferTimeStart, bufferTimeEnd);

        if (portData && isMountedRef.current) {
          setPorts(portData);
          setLoader(false);
        }
      }
    })();
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetFilter, formData]);

  const updateCalendarVesselData = useCallback(
    async (start, end) => {
      if (loadingAllowed) {
        setSmallLoader(true);
        try {
          const portData = await getPortcallData(
            dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ'),
            dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ')
          );

          if (portData) {
            setPorts(portData);
          }
        } catch (error) {
          setSmallLoader(false);
        }
        setSmallLoader(false);
      }
    },
    [getPortcallData, loadingAllowed]
  );

  const getLeftOffsetFromDate = date => {
    return calculateXPositionForTime(canvasTimeStart, canvasTimeEnd, canvasWidth, date);
  };

  const getDateFromLeftOffsetPosition = leftOffset => {
    return calculateTimeForXPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset);
  };

  const updateScrollCanvas = async (visibleTimeStart2, visibleTimeEnd2) => {
    const scrollCanvas = calculateScrollCanvas(visibleTimeStart2, visibleTimeEnd2, canvasTimeStart, bufferMultiplier);

    setVisibleTimeStart(scrollCanvas.visibleTimeStart);
    setVisibleTimeEnd(scrollCanvas.visibleTimeEnd);
    setCanvasTimeStart(scrollCanvas.canvasTimeStart);
    setCanvasTimeEnd(scrollCanvas.canvasTimeEnd);
    setBufferTimeStart(scrollCanvas.bufferTimeStart);
    setBufferTimeEnd(scrollCanvas.bufferTimeEnd);

    await updateCalendarVesselData(scrollCanvas.bufferTimeStart, scrollCanvas.bufferTimeEnd);
  };

  const setTimelineType = async (type, keepPlace = true) => {
    setLoader(true);

    localStorage.setItem(fleetTimelineTypeStorageKey, type);

    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    let start = canvasTimeStart + (scrollOffset / width) * viewWidthInTime;
    let end = canvasTimeStart + (scrollOffset / width) * viewWidthInTime + viewWidthInTime;
    start = dayjs(start);
    end = dayjs(end);

    setTimelineUnit(type);

    let newVisibleStart = null;
    let newVisibleEnd = null;

    switch (type) {
      case 'custom': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = end
            .startOf('day')
            .toDate()
            .valueOf();
        } else {
          const days = dayjs.duration(end.diff(start)).asDays();
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(days, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'day': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'twodays': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(2, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(2, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'week': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(7, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .add(-2, 'days')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(4, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'month': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();

          newVisibleStart = start
            .startOf('isoWeek')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }
      default:
        break;
    }

    const [canvasStart, canvasEnd] = getCanvasBoundariesFromVisibleTime(newVisibleStart, newVisibleEnd);
    const [bufferStart, bufferEnd] = getCanvasBoundariesFromVisibleTime(
      newVisibleStart,
      newVisibleEnd,
      bufferMultiplier
    );

    setVisibleTimeStart(newVisibleStart);
    setVisibleTimeEnd(newVisibleEnd);
    setCanvasTimeStart(canvasStart);
    setCanvasTimeEnd(canvasEnd);
    setBufferTimeStart(bufferStart);
    setBufferTimeEnd(bufferEnd);

    setForceUpdate(true);

    await updateCalendarVesselData(bufferStart, bufferEnd);

    if (loadingAllowed) {
      setLoader(false);
    }
  };

  const setToday = () => {
    if (timelineUnit === 'day') {
      setTimelineType('day', false);
    } else if (timelineUnit === 'twodays') {
      setTimelineType('twodays', false);
    } else if (timelineUnit === 'week') {
      setTimelineType('week', false);
    } else if (timelineUnit === 'month') {
      setTimelineType('month', false);
    } else if (timelineUnit === 'custom') {
      setTimelineType('custom', false);
    }
    updateTime();
  };

  const moveForwards = () => {
    let days = 1;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      days = 1;
      start = start.startOf('day');
    } else if (timelineUnit === 'twodays') {
      days = 2;
      start = start.startOf('day');
    } else if (timelineUnit === 'week') {
      days = 7;
      start = start.startOf('isoWeek');
    } else if (timelineUnit === 'month') {
      days = 28;
    } else if (timelineUnit === 'custom') {
      days = dayjs.duration(dayjs(visibleTimeEnd).diff(dayjs(visibleTimeStart))).asDays();
    }

    let ctStart = start.valueOf();
    let vtStart = start.add(days, 'days').valueOf();
    let vtEnd = start.add(2 * days, 'days').valueOf();
    let ctEnd = start.add(3 * days, 'days').valueOf();
    let bufferStart = dayjs(ctStart)
      .add(days * bufferMultiplier, 'days')
      .valueOf();
    let bufferEnd = dayjs(vtEnd)
      .add(days * bufferMultiplier, 'days')
      .valueOf();

    setVisibleTimeStart(vtStart);
    setVisibleTimeEnd(vtEnd);
    setCanvasTimeStart(ctStart);
    setCanvasTimeEnd(ctEnd);
    setBufferTimeStart(bufferStart);
    setBufferTimeEnd(bufferEnd);

    updateCalendarVesselData(bufferStart, bufferEnd);
  };

  const moveBackwards = () => {
    let timespan = 0;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      timespan = 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'twodays') {
      timespan = 2 * 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
      start = start.startOf('isoWeek').valueOf();
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
      start = start.valueOf();
    } else if (timelineUnit === 'custom') {
      const days = dayjs.duration(dayjs(visibleTimeEnd).diff(dayjs(visibleTimeStart))).asDays();
      timespan = days * 86400000;
      start = start.valueOf();
    }

    setVisibleTimeStart(start - timespan);
    setVisibleTimeEnd(start);
    setCanvasTimeStart(start - 2 * timespan);
    setCanvasTimeEnd(start + timespan);
    setBufferTimeStart(start - timespan - timespan * bufferMultiplier);
    setBufferTimeEnd(start + timespan * bufferMultiplier);

    updateCalendarVesselData(start - timespan - timespan * bufferMultiplier, start + timespan * bufferMultiplier);
  };

  const changeCustomDate = dates => {
    const start = dayjs(dates[0]).startOf('day');
    const end = dayjs(dates[1]).endOf('day');
    const startValueOf = start.valueOf();

    let timespan = dayjs.duration(end.diff(start)).asMilliseconds();

    setVisibleTimeStart(startValueOf);
    setVisibleTimeEnd(startValueOf + timespan);
    setCanvasTimeStart(startValueOf - timespan);
    setCanvasTimeEnd(startValueOf + 2 * timespan);
    setBufferTimeStart(startValueOf - timespan * bufferMultiplier);
    setBufferTimeEnd(startValueOf + timespan + timespan * bufferMultiplier);

    updateCalendarVesselData(
      startValueOf - timespan * bufferMultiplier,
      start + timespan + timespan * bufferMultiplier
    );
  };

  const changeDate = date => {
    const millisecondsDate = dayjs(date).valueOf();
    let timespan = 0;
    if (timelineUnit === 'day') {
      timespan = 86400000;
    } else if (timelineUnit === 'twodays') {
      timespan = 2 * 86400000;
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
    }

    setVisibleTimeStart(millisecondsDate);
    setVisibleTimeEnd(millisecondsDate + timespan);
    setCanvasTimeStart(millisecondsDate - timespan);
    setCanvasTimeEnd(millisecondsDate + 2 * timespan);
    setBufferTimeStart(millisecondsDate - timespan * bufferMultiplier);
    setBufferTimeEnd(millisecondsDate + timespan + timespan * bufferMultiplier);

    updateCalendarVesselData(
      millisecondsDate - timespan * bufferMultiplier,
      millisecondsDate + timespan + timespan * bufferMultiplier
    );
  };

  const loadMoreRight = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const loadMoreLeft = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();
  const viewWidthInPixels =
    (dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels) / 3;

  const momentCanvasStartTime = dayjs(canvasTimeStart);
  const momentCanvasEndTime = dayjs(canvasTimeEnd);

  return (
    <FleetViewContext.Provider
      value={{
        visibleTimeStart,
        setVisibleTimeStart,
        visibleTimeEnd,
        setVisibleTimeEnd,
        canvasTimeStart,
        setCanvasTimeStart,
        canvasTimeEnd,
        setCanvasTimeEnd,
        getLeftOffsetFromDate,
        getDateFromLeftOffsetPosition,
        ports,
        canvasWidth,
        setCanvasWidth,
        timelineUnit,
        setTimelineUnit,
        width,
        setWidth,
        updateScrollCanvas,
        setTimelineType,
        setToday,
        indicatorLine,
        setIndicatorLine,
        moveForwards,
        moveBackwards,
        changeDate,
        loadMoreRight,
        loadMoreLeft,
        loader,
        rowHeight,
        setRowHeightFunction,
        zoomSliderValue,
        setZoomSliderValue,
        setScrollOffset,
        scrollOffset,
        forceUpdate,
        setForceUpdate,
        otherNamespaceData,
        setSideModalOpen,
        sideModalOpen,
        changeCustomDate,
        smallLoader,
        sidebarSelection,
        setSidebarSelection,
        sidebarWidth,
        setSidebarWidth,
        zoomChecked,
        viewWidthInPixels,
        millisecondInPixels,
        momentCanvasStartTime,
        momentCanvasEndTime,
        portLocodes,
        portcallIds,
      }}
    >
      {children}
    </FleetViewContext.Provider>
  );
};
