import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';

import Input from 'antd/es/input';
import App from 'antd/es/app';

const { TextArea } = Input;

const EditTabWrapper = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapperBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TextAreaWrapper = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const GeoJsonInputField = styled(TextArea)`
  border: ${props => (props.valid ? null : '1px solid #d0011c')};
  &:hover {
    border: ${props => (props.valid ? null : '1px solid #d0011c')};
  }
  &:focus {
    border: ${props => (props.valid ? null : '1px solid #d0011c')};
  }
`;

//TODO: Cancel button. Update LineString broken

const GeoJsonTab = ({ data, mainMap }) => {
  const { namespace } = useContext(UserContext);
  const {
    rightPanelData,
    drawAssets,
    setGeoJsonLayerData,
    geoJsonLayerData,
    setGeoJsonLayerUpdate,
    geoJsonLayerUpdate,
    updateAssetCoodinates,
    setRightPanelData,
    setRightPanelOpen,
    setNewRotatedCoords,
    setEditPointsInCreate,
  } = useContext(GeoAssetToolContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [update, setUpdate] = useState(false);
  const dataRef = useRef(data);
  const [textFieldContent, setTextFieldContent] = useState();
  const [geoJsonObjectValid, setGeoJsonObjectValid] = useState();
  // eslint-disable-next-line no-unused-vars
  const [latLngOrder, setLatLngOrder] = useState(false);

  const formatGeoJson = (data, latLngOrder) => {
    function constructCoordinates(type, coordinates, latLngOrder) {
      if (latLngOrder) {
        if (type === 'Circle' || type === 'Point' || type === 'LineString') return coordinates;
        else return [[...coordinates, coordinates[0]]];
      } else {
        if (coordinates.length === 0) return [];
        if (type === 'Circle' || type === 'Point') {
          const coordOne = coordinates[0];
          const coordTwo = coordinates[1];
          if (!coordOne || !coordTwo) return [];
          if (typeof coordOne !== 'number' || typeof coordTwo !== 'number') return [];
          return [coordTwo, coordOne];
        } else {
          const newCoords = [];
          const oldCoords = coordinates;
          for (let index = 0; index < oldCoords.length; index++) {
            const coords = oldCoords[index];
            const coordOne = coords[0];
            const coordTwo = coords[1];
            newCoords.push([coordTwo, coordOne]);
          }
          return type === 'Polygon' ? [[...newCoords]] : newCoords;
        }
      }
    }
    const geoJson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: data.properties,
          style: data.style,
          geometry: {
            type: dataRef.current.geo_data.type,
            coordinates: constructCoordinates(data.geo_data.type, data.geo_data.coordinates, latLngOrder),
          },
        },
      ],
    };
    return JSON.stringify(geoJson, null, 2);
  };

  useEffect(() => {
    if (data?.geo_asset_id) {
      dataRef.current = data;
      setTextFieldContent(formatGeoJson(data, latLngOrder));
      setUpdate(!update);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData]);

  const handleChange = e => {
    setTextFieldContent(e.target.value);
    setUpdate(!update);
  };

  const placeholderText = `
  Paste GeoJSON here...

  Coordinates are read in Lng Lat order

  {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": <Object>,
        "style": <Object>,
        "Geometry": {
            type: "LineString" | "Polygon",
            coordinates: <Array>
        }
      }
    ]
  } 
  `;

  const validTypes = ['LineString', 'Polygon'];

  const coordinateFunction = (coordinates, type) => {
    const coords = [];
    if (type === 'Point') {
      const coordOne = coordinates[0];
      const coordTwo = coordinates[1];
      coords.push(coordTwo);
      coords.push(coordOne);
    } else {
      const array = type === 'Polygon' ? coordinates[0] : coordinates;
      if (array.length) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          const coordOne = element[0];
          const coordTwo = element[1];
          coords.push([coordTwo, coordOne]);
        }
        if (type === 'Polygon') return [coords];
      }
    }
    return coords;
  };

  useEffect(() => {
    try {
      let inputData = { ...JSON.parse(textFieldContent).features[0], geo_asset_id: data.geo_asset_id };
      // if (inputData.type !== 'Feature') {
      //   throw 'Feature';
      // } else if (inputData.properties && typeof inputData.properties !== 'object') {
      //   throw 'Properties';
      // } else if (inputData.style && typeof inputData.style !== 'object') {
      //   throw 'Properties';
      // } else if (typeof inputData.geometry !== 'object') {
      //   throw 'Geometry';
      // } else if (typeof inputData.geometry.type !== 'string') {
      //   throw 'Type';
      // } else if (!validTypes.includes(inputData.geometry.type)) {
      //   throw 'TypeValue';
      // } else if (inputData.geometry.coordinates.length === 0) {
      //   throw 'Coordinates';
      // } else if (inputData.geometry.type === 'Polygon' && typeof inputData.geometry.coordinates[0][0][0] !== 'number') {
      //   throw 'Coordinate format';
      // } else if (inputData.geometry.type === 'LineString' && typeof inputData.geometry.coordinates[0][0] !== 'number') {
      //   throw 'Coordinate format';
      // }
      setGeoJsonObjectValid(true);
      setGeoJsonLayerData({
        ...inputData,
        geometry: {
          ...inputData.geometry,
          coordinates: coordinateFunction(inputData.geometry.coordinates, inputData.geometry.type),
        },
      });
    } catch {
      setGeoJsonObjectValid(null);
      setGeoJsonLayerData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFieldContent]);

  useEffect(() => {
    setGeoJsonLayerUpdate(geoJsonLayerUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJsonLayerData]);

  const switchLatLng = () => {
    try {
      let inputData = JSON.parse(textFieldContent).features[0];
      if (inputData.geometry.coordinates.length === 0) return; //throw 'No coordinates provided';
      if (!validTypes.includes(inputData.geometry.type)) {
        // throw `"${inputData.geometry.type}" is not valid type`;
      }
      if (inputData.geometry.type === 'Circle' || inputData.geometry.type === 'Point') {
        const coordOne = inputData.geometry.coordinates[0];
        const coordTwo = inputData.geometry.coordinates[1];
        if (!coordOne || !coordTwo) return; //throw 'Invalid coordinates provided';
        if (typeof coordOne !== 'number' || typeof coordTwo !== 'number') return; //throw 'Coordinates are not numbers';
        inputData.geometry.coordinates = [coordTwo, coordOne];
        setTextFieldContent(JSON.stringify(inputData, 0, 4));
        return [coordTwo, coordOne];
      } else {
        const newCoords = [];
        const oldCoords =
          inputData.geometry.type === 'Polygon' ? inputData.geometry.coordinates[0] : inputData.geometry.coordinates;
        for (let index = 0; index < oldCoords.length; index++) {
          const coords = oldCoords[index];
          const coordOne = coords[0];
          const coordTwo = coords[1];
          if (!coordOne || !coordTwo) return; //throw 'Invalid coordinates provided in index' + ' ' + index;
          else if (typeof coordOne !== 'number' || typeof coordTwo !== 'number') {
            // throw 'Coordinates are not numbers in index' + ' ' + index;
          } else {
            newCoords.push([coordTwo, coordOne]);
          }
        }
        inputData.geometry.coordinates = inputData.geometry.type === 'Polygon' ? [newCoords] : newCoords;
        inputData = {
          type: 'FeatureCollection',
          features: [inputData],
        };
        setTextFieldContent(JSON.stringify(inputData, 0, 3));
        return inputData.geometry.type === 'Polygon' ? [newCoords] : newCoords;
      }
    } catch (error) {
      return message.error(error);
    }
  };

  return (
    <EditTabWrapper>
      <ButtonWrapper>
        <ButtonLight
          type="button"
          style={{
            fontSize: '12px',
            margin: 0,
            minWidth: '62px',
          }}
          onClick={() => geoJsonObjectValid && switchLatLng()}
        >
          {t('Rotate coordinates')}
        </ButtonLight>
        <ButtonLight
          type="button"
          style={{
            fontSize: '12px',
            margin: 0,
            minWidth: '62px',
          }}
          onClick={() => {
            try {
              geoJsonObjectValid && setTextFieldContent(JSON.stringify(JSON.parse(textFieldContent), 0, 3));
            } catch {
              console.error('setTextFieldContent failed.');
            }
          }}
        >
          {t('Format')}
        </ButtonLight>
        <ButtonLight
          type="button"
          style={{
            fontSize: '12px',
            margin: 0,
            minWidth: '62px',
          }}
          onClick={() => {
            navigator.clipboard.writeText(textFieldContent);
            alert(t('Text field content copied'));
          }}
        >
          <Icon type="copy" />
          {t('Copy')}
        </ButtonLight>
      </ButtonWrapper>
      <TextAreaWrapper>
        <GeoJsonInputField
          valid={!textFieldContent ? true : !!geoJsonObjectValid}
          row={30}
          value={textFieldContent}
          onChange={e => handleChange(e)}
          style={{ height: mainMap ? 'calc(100vh - 388px)' : 'calc(100vh - 350px)', resize: 'none' }}
          placeholder={placeholderText}
        />
      </TextAreaWrapper>
      <ButtonWrapperBottom>
        <ButtonLight
          type="button"
          lightred
          style={{ marginRight: '5px' }}
          onClick={() => {
            setRightPanelOpen(false);
            setRightPanelData({});
            setEditPointsInCreate(false);
            setNewRotatedCoords(null);
          }}
        >
          {t('Cancel')}
        </ButtonLight>
        <ButtonLight
          disabled={!geoJsonObjectValid}
          type="button"
          onClick={() => {
            const geoJson = JSON.parse(textFieldContent).features[0];
            if (rightPanelData.newAsset && rightPanelData.fromGeoJson) {
              const style = {
                stroke: geoJson.style?.stroke ? geoJson.style.stroke : true,
                color: geoJson.style?.color ? geoJson.style.color : 'navy',
                weight: geoJson.style?.weight ? geoJson.style.weight : 2,
                opacity: geoJson.style?.opacity ? geoJson.style.opacity : 0.6,
                fillColor: geoJson.style?.fillColor ? geoJson.style.color : 'navy',
                fillOpacity: geoJson.style?.fillOpacity ? geoJson.style.fillOpacity : 0.6,
              };
              drawAssets(
                coordinateFunction(geoJson.geometry.coordinates, geoJsonLayerData.geometry.type),
                geoJsonLayerData.geometry.type,
                style,
                false,
                true
              );
            } else {
              updateAssetCoodinates(
                data.geo_asset_id,
                coordinateFunction(geoJson.geometry.coordinates, geoJsonLayerData.geometry.type),
                true
              ); //Fix line
            }
          }}
        >
          {!rightPanelData.newAsset ? t('Apply') : t('Import')}
        </ButtonLight>
      </ButtonWrapperBottom>
    </EditTabWrapper>
  );
};

export default GeoJsonTab;
