import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InvoiceInput from './InvoiceInput';
import { UserContext } from '../../../context/UserContext';
import Icon from '../../ui/Icon';
import RowAdder from './RowAdder';
import InvoiceAmountInput from './InvoiceAmountInput';
import Tooltip from 'antd/es/tooltip';
import ButtonLight from '../../ui/ButtonLight';
import PriceMatrixModal from '../PriceMatrixModal';
import CreditRowAdder from './CreditRowAdder';

const HeightContainer = styled.div`
  min-height: 300px;
`;

const List = styled.div`
  margin-top: 18px;
  min-width: ${props => (props.showSmall ? '580px' : '966px')};
  padding-right: ${props => (props.showSmall ? '6px' : '18px')};
  position: relative;

  tbody:before {
    content: '@';
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  width: 100%;
  text-align: left;
  border-bottom: 2px solid #666666;
  margin-bottom: 12px;

  th {
    border-bottom: 2px solid #666666;
  }
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const RowTrashContainer = styled.div`
  line-height: 21px;
  margin-left: 8px;
  color: ${props => (props.disabled ? props.theme.color.grey : props.theme.color.warning)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  svg {
    margin-top: -2px;
    height: 16px;
    width: 16px;
  }
`;

const RowEditContainer = styled.div`
  line-height: 21px;
  margin-left: 8px;
  color: ${props => (props.disabled ? props.theme.color.grey : props.theme.color.success)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  svg {
    margin-top: -2px;
    height: 16px;
    width: 16px;
  }
`;

const AddInputButton = styled.div`
  color: ${props => props.theme.color.secondary};
  display: inline-flex;
  cursor: pointer;
  position: absolute;
  bottom: ${props => (props.template ? '12px' : '60px')};

  i {
    height: 21px;
    width: 21px;
  }

  svg {
    height: 21px;
    width: 21px;
  }
`;

const AddInputText = styled.div`
  color: ${props => props.theme.color.secondary};
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const IconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 16px;
    width: 16px;
    margin-top: -2px;
  }
`;

const AsteriskContainer = styled.span`
  color: ${props => props.theme.color.dark_grey};

  svg {
    height: 10px;
    width: 10px;
    margin-top: -4px;
  }
`;

const DynamicTable = styled.table`
  td {
    padding: 4px 4px 4px 1px;
  }
`;

const MatrixTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceModalTable = ({
  inputData,
  setInputData,
  updateRowInput,
  makeFocus,
  moveFocus,
  setMakeFocus,
  showSmall = false,
  totalWithTax,
  totalWithoutTax,
  setRowInputChanged,
  readOnly,
  template,
  automated,
  deleteOngoing,
  setDeleteOngoing,
  creditInvoice,
  creditableProducts,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [rowAdderOpen, setRowAdderOpen] = useState(null);

  const [rowAdderSelection, setRowAdderSelection] = useState(null);
  const [rowAdderModifications, setRowAdderModifications] = useState([]);

  const [priceMatrix, setPriceMatrix] = useState(null);

  const editRow = index => {
    setRowAdderSelection({
      code: inputData.rows[index].code,
      combined_unit: inputData.rows[index].combined_unit,
      amounts: inputData.rows[index].amounts,
      vat: inputData.rows[index].vat,
      name: inputData.rows[index].name,
      unit_amount: inputData.rows[index].unit_amount,
      product_data: inputData.rows[index].product_data,
      pricing: inputData.rows[index].product_data?.pricing,
    });
    const mods = inputData.rows[index].modifications;
    setRowAdderModifications(mods);
    setRowAdderOpen({ index });
    setRowInputChanged?.(true);
  };

  const deleteRow = index => {
    setDeleteOngoing(true);
    const newArray = [...inputData.rows];
    newArray.splice(index, 1);
    setInputData({ ...inputData, rows: newArray });
    setRowInputChanged?.(true);
    setTimeout(() => {
      setDeleteOngoing(false);
    }, 1000);
  };

  const deleteModRow = (i, j) => {
    setDeleteOngoing(true);
    const newArray = [...inputData.rows[i].modifications];
    newArray.splice(j, 1);
    const newData = { ...inputData };
    newData.rows[i].modifications = newArray;
    setInputData({ ...newData });
    setRowInputChanged?.(true);
    setTimeout(() => {
      setDeleteOngoing(false);
    }, 1000);
  };

  const createDynamicTooltip = (list, units) => {
    let unitString = ' / ' + units[0].name;
    if (units[1]) {
      unitString += ' / ' + units[1].name;
    }
    return (
      <DynamicTable>
        <thead>
          <tr>
            <th colSpan="3">{t('Range')}</th>
            <th>{t('Price')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((l, i) => (
            <tr key={i}>
              <td>{l.limits.lower}</td>
              <td>-</td>
              <td style={{ paddingRight: '18px' }}>
                {l.limits.upper}
                {l.limits.upper ? ':' : ''}
              </td>
              <td>
                {l.price} {inputData.currency} {unitString}
              </td>
            </tr>
          ))}
        </tbody>
      </DynamicTable>
    );
  };

  const createMatrixTooltip = item => {
    let unitString = ' / ' + item.pricing.units[0].name;
    if (item.pricing.units[1]) {
      unitString += ' / ' + item.pricing.units[1].name;
    }
    return (
      <MatrixTooltip>
        <DynamicTable>
          <thead>
            <tr>
              <th colSpan="3">{t('Range')}</th>
              <th>{t('Prices')}</th>
            </tr>
          </thead>
          <tbody>
            {item.pricing.unit_prices.map((l, i) => {
              let priceString = '';
              l.timings.forEach((timing, i) => {
                if (i > 0) {
                  priceString += ' / ';
                }
                priceString += timing.price;
              });

              return (
                <tr key={i}>
                  <td>{l.limits.lower}</td>
                  <td>-</td>
                  <td style={{ paddingRight: '18px' }}>
                    {l.limits.upper}
                    {l.limits.upper ? ':' : ''}
                  </td>
                  <td>
                    {priceString}
                    <span
                      style={{
                        paddingRight: '10px',
                      }}
                    />
                    {inputData.currency} {unitString}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DynamicTable>

        <ButtonLight onClick={() => setPriceMatrix(item)} style={{ marginTop: '12px' }}>
          {t('Show price matrix')}
        </ButtonLight>
      </MatrixTooltip>
    );
  };

  const createUnitPrice = row => {
    const product = row.product_data;
    if (product?.pricing?.type === 'dynamic') {
      return (
        <Tooltip
          placement="top"
          title={createDynamicTooltip(product.pricing.unit_prices, product.pricing.units)}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <AsteriskContainer>
            <Icon type="asterisk" />
          </AsteriskContainer>
          {inputData.currency} / {row.combined_unit}
          <IconContainer style={{ marginLeft: '3px' }}>
            <Icon type="info" />
          </IconContainer>
        </Tooltip>
      );
    } else if (product?.pricing?.type === 'dynamic_time_based') {
      return (
        <Tooltip
          placement="top"
          title={createMatrixTooltip(product)}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <AsteriskContainer>
            <Icon type="asterisk" />
          </AsteriskContainer>
          {inputData.currency} / {row.combined_unit}
          <IconContainer style={{ marginLeft: '3px' }}>
            <Icon type="info" />
          </IconContainer>
        </Tooltip>
      );
    } else {
      return (
        <div>
          {row.unit_amount} {inputData.currency} / {row.combined_unit}
        </div>
      );
    }
  };

  return (
    <HeightContainer>
      <List showSmall={showSmall}>
        <Table>
          <TableHead>
            <tr>
              {!(template || automated) && <th>{t('Date')}</th>}
              <th>{t('Product name')}</th>
              {!showSmall && <th>{t('Product code')}</th>}
              <th>{t('Amount(s)')}</th>
              <th>{t('Unit price')}</th>
              {!showSmall && <th>{t('Price')}</th>}
              <th>{t('VAT %')}</th>
              {!showSmall && <th></th>}
            </tr>
          </TableHead>
          <TableBody>
            {inputData.rows.map((row, i) => {
              let mods =
                row.modifications?.map(m => {
                  const not_used = row.unused_modifications?.findIndex(mod => mod.modifier_code === m.code) > -1;
                  return { ...m, not_used };
                }) || [];
              return [
                <tr key={i}>
                  {!showSmall && !(template || automated) && (
                    <td style={{ width: '150px' }}>
                      <InvoiceInput
                        time={true}
                        value={row.timeframe?.at}
                        updateValue={value => updateRowInput?.({ from: null, to: null, at: value }, 'timeframe', i)}
                        width={146}
                        makeFocus={makeFocus === 'timeframe/' + i}
                        moveNextFocus={() => moveFocus('timeframe', i)}
                        movePrevFocus={() => moveFocus('timeframe', i, true)}
                        removeMakeFocus={() => setMakeFocus(null)}
                        readOnly={showSmall || readOnly}
                        marginTop={true}
                      />
                    </td>
                  )}
                  <td style={{ width: showSmall ? '210px' : '300px' }}>
                    {row.empty_row ? (
                      <InvoiceInput
                        value={row.name}
                        updateValue={value => updateRowInput?.(value, 'name', i)}
                        width={200}
                        makeFocus={makeFocus === 'name/' + i}
                        moveNextFocus={() => moveFocus('name', i)}
                        movePrevFocus={() => moveFocus('name', i, true)}
                        removeMakeFocus={() => setMakeFocus(null)}
                        readOnly={showSmall || readOnly}
                        marginTop={true}
                      />
                    ) : (
                      <span>
                        <span>{row.name}</span>{' '}
                        {!!row.info && (
                          <span>
                            <Tooltip
                              placement="right"
                              color="white"
                              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                              title={
                                <div>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '6px', fontWeight: 600, whiteSpace: 'nowrap' }}>
                                      {t('Invoice row description')}:
                                    </div>
                                    <div>{row.info}</div>
                                  </div>
                                </div>
                              }
                            >
                              <IconContainer>
                                <Icon type="info" />
                              </IconContainer>
                            </Tooltip>
                          </span>
                        )}
                      </span>
                    )}
                  </td>
                  {!showSmall && (
                    <td style={{ width: showSmall ? '100px' : '140px' }}>
                      {row.empty_row ? (
                        <InvoiceInput
                          value={row.code}
                          updateValue={value => updateRowInput?.(value, 'code', i)}
                          width={60}
                          makeFocus={makeFocus === 'code/' + i}
                          moveNextFocus={() => moveFocus('code', i)}
                          movePrevFocus={() => moveFocus('code', i, true)}
                          removeMakeFocus={() => setMakeFocus(null)}
                          readOnly={showSmall || readOnly}
                          marginTop={true}
                        />
                      ) : (
                        row.code
                      )}
                    </td>
                  )}
                  <td style={{ width: showSmall ? '100px' : '140px' }}>
                    <InvoiceAmountInput
                      amounts={row.amounts}
                      readOnly={showSmall || readOnly || template}
                      moveFocus={moveFocus}
                      removeMakeFocus={() => setMakeFocus(null)}
                      updateRowInput={updateRowInput}
                      makeFocus={makeFocus}
                      index={i}
                      marginTop={true}
                      templateAmount={template}
                    />
                  </td>
                  <td style={{ width: showSmall ? '130px' : '140px' }}>
                    {row.empty_row ? (
                      <div style={{ display: 'flex' }}>
                        <InvoiceInput
                          onlyNumber={true}
                          value={row.unit_amount}
                          updateValue={value => updateRowInput?.(value, 'unit_amount', i)}
                          width={40}
                          makeFocus={makeFocus === 'unit_amount/' + i}
                          moveNextFocus={() => moveFocus('unit_amount', i)}
                          movePrevFocus={() => moveFocus('unit_amount', i, true)}
                          removeMakeFocus={() => setMakeFocus(null)}
                          useOnlyTextWidth={true}
                          readOnly={showSmall || readOnly}
                          marginTop={true}
                        />
                        <InvoiceInput
                          value={row.unit_unit}
                          updateValue={value => updateRowInput?.(value, 'unit_unit', i)}
                          width={60}
                          makeFocus={makeFocus === 'unit_unit/' + i}
                          moveNextFocus={() => moveFocus('unit_unit', i)}
                          movePrevFocus={() => moveFocus('unit_unit', i, true)}
                          removeMakeFocus={() => setMakeFocus(null)}
                          readOnly={showSmall || readOnly}
                          marginTop={true}
                        />
                      </div>
                    ) : (
                      <div>{createUnitPrice(row)}</div>
                    )}
                  </td>
                  {!showSmall && (
                    <td style={{ minWidth: '100px' }}>
                      {row.price || '-'} {inputData.currency}
                    </td>
                  )}
                  <td style={{ width: '50px' }}>
                    {row.empty_row ? (
                      <InvoiceInput
                        onlyNumber={true}
                        value={row.vat}
                        updateValue={value => updateRowInput?.(value, 'vat', i)}
                        width={50}
                        makeFocus={makeFocus === 'vat/' + i}
                        moveNextFocus={() => moveFocus('vat', i)}
                        movePrevFocus={() => moveFocus('vat', i, true)}
                        removeMakeFocus={() => setMakeFocus(null)}
                        readOnly={showSmall || readOnly}
                        marginTop={true}
                      />
                    ) : (
                      <div>{row.vat} %</div>
                    )}
                  </td>
                  {!showSmall && !readOnly && (
                    <td style={{ width: '50px' }}>
                      <div style={{ display: 'flex' }}>
                        <RowEditContainer
                          onClick={() => !deleteOngoing && !creditInvoice && editRow(i)}
                          disabled={deleteOngoing || creditInvoice}
                        >
                          <Icon type="edit" />
                        </RowEditContainer>
                        <RowTrashContainer onClick={() => !deleteOngoing && deleteRow(i)} disabled={deleteOngoing}>
                          <Icon type="trash" />
                        </RowTrashContainer>
                      </div>
                    </td>
                  )}
                </tr>,
                mods.map((mod, j) => (
                  <tr key={j}>
                    {!(template || automated) && <td></td>}
                    <td
                      style={{
                        paddingLeft: '24px',
                        textDecoration: mod.not_used && 'line-through',
                        color: mod.not_used && '#c8c8c8',
                      }}
                    >
                      {mod.name}
                    </td>
                    {!showSmall && <td></td>}
                    <td></td>
                    <td
                      style={{
                        paddingLeft: '18px',
                        textDecoration: mod.not_used && 'line-through',
                        color: mod.not_used && '#c8c8c8',
                      }}
                    >
                      {mod.adjustment?.value > 0 && '+'}
                      {mod.adjustment?.value} %
                    </td>
                    <td></td>
                    {!showSmall && <td></td>}
                    {!showSmall && !readOnly && (
                      <td style={{ width: '50px' }}>
                        <RowTrashContainer
                          onClick={() => !deleteOngoing && deleteModRow(i, j)}
                          style={{ paddingLeft: '22px' }}
                          disabled={deleteOngoing}
                        >
                          <Icon type="trash" />
                        </RowTrashContainer>
                      </td>
                    )}
                  </tr>
                )),
              ];
            })}
            <tr>
              <td style={{ height: '20px' }}></td>
            </tr>
            <tr>
              <td style={{ height: '20px' }}></td>
            </tr>
            <tr>
              <td style={{ height: '20px' }}></td>
            </tr>
            {!template && (
              <tr>
                <td colSpan={showSmall ? 3 : 4} style={{ textAlign: 'right', paddingRight: '12px' }}>
                  {t('Total excluding VAT')}:
                </td>
                <td>
                  {totalWithoutTax || 0} {inputData.currency}
                </td>
              </tr>
            )}
            {!template && (
              <tr>
                <td colSpan={showSmall ? 3 : 4} style={{ textAlign: 'right', paddingRight: '12px' }}>
                  {t('Invoice total')}:
                </td>
                <td>
                  {totalWithTax || 0} {inputData.currency}
                </td>
              </tr>
            )}
          </TableBody>
        </Table>

        {!showSmall && !readOnly && (
          <AddInputButton onClick={() => setRowAdderOpen({ new: true })} template={template || automated}>
            <Icon type={'plus'} />
            <AddInputText>{t('Add new product row')}</AddInputText>
          </AddInputButton>
        )}
      </List>
      {rowAdderOpen &&
        (creditInvoice ? (
          <CreditRowAdder
            setRowAdderOpen={setRowAdderOpen}
            rowAdderSelection={rowAdderSelection}
            setRowAdderSelection={setRowAdderSelection}
            setInputData={setInputData}
            inputData={inputData}
            creditableProducts={creditableProducts}
          />
        ) : (
          <RowAdder
            rowAdderOpen={rowAdderOpen}
            setRowAdderOpen={setRowAdderOpen}
            rowAdderSelection={rowAdderSelection}
            rowAdderModifications={rowAdderModifications}
            setRowAdderModifications={setRowAdderModifications}
            setRowAdderSelection={setRowAdderSelection}
            setInputData={setInputData}
            inputData={inputData}
            setRowInputChanged={setRowInputChanged}
            template={template || automated}
          />
        ))}

      {!!priceMatrix && <PriceMatrixModal product={priceMatrix} closeModal={() => setPriceMatrix(null)} />}
    </HeightContainer>
  );
};

export default InvoiceModalTable;
