import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { FleetFilteringContext } from '../../context/FleetFilteringContext';
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input';
import Select from 'antd/es/select';

import ButtonLight from '../ui/ButtonLight';
import { buildQuery, setFleetFilterStorageData } from './fleetFilterHelpers';
import Icon from '../ui/Icon';
import FleetFilterTreeSelect from './FleetFilterTreeSelect';
import FleetFilterSelect from './FleetFilterSelect';

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 0 7px 0;
`;

const DeleteLink = styled.a`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.lighter_black};
`;

const LoadFilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:hover ${DeleteLink} {
    color: red;
  }
`;

const FilterLink = styled.a`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  &:hover {
    opacity: 0.7;
  }
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

const OuterContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
  width: 540px;
`;

const InnerContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding: 14px 38px 10px 38px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 38px 14px 38px;

  button {
    margin-bottom: 0px;
    margin-right: 0.8rem;
    min-width: 80px;

    &:last-child {
      margin-right: 0;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
`;

const SelectContainer = styled.div`
  padding: 4px 0 4px 0;
`;

const TreeSelectContainer = styled.div`
  padding: 4px 0 4px 0;
`;

const FilterSaveContainer = styled.div`
  background-color: #f5f5f5;
  margin-top: 24px;
  padding: 0px 12px 6px 12px;
`;

const SaveFilterRow = styled.div`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  height: 32px;
  flex-direction: row;
  gap: 10px;
`;

const CheckBoxContainer = styled.div`
  padding: 4px 0 4px 0;
  display: flex;
  justify-content: space-between;
  gap: 1px;
`;

const ColumnContainer = styled.div`
  padding: 4px 0 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 220px;
`;

const FleetFilter = ({ setVisible }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const {
    agentData,
    activeFilter,
    setActiveFilter,
    clearFormData,
    fleetFilters,
    setFleetFilter,
    formData,
    setFormData,
    getFleetFilters,
    vesselData,
    vesselTypeData,
    portAreaData,
    shippingCompanyData,
    nationalityData,
    vtsAreaData,
  } = useContext(FleetFilteringContext);

  const { t } = useTranslation(namespace);

  const [modifiedFormData, setModifiedFormData] = useState(formData);
  const [hasChanges, setHasChanges] = useState(false);
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    getFleetFilters();
    // Run only when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModifiedFormData(formData);
    setHasChanges(false);
  }, [formData]);

  const saveFilter = async () => {
    const newData = modifiedFormData;
    const newQuery = buildQuery(newData);

    const res = await apiCall('post', 'fleet/filters', {
      name: filterName,
      namespace,
      userId: user.id,
      data: {
        // query: newQuery,
        formData: newData,
      },
    });

    if (res.data) {
      getFleetFilters();
      setActiveFilter({ id: res.data.id, name: filterName });
      setFleetFilter(newQuery);
      setFormData(newData);
      setFilterName('');
      setHasChanges(false);
      setFleetFilterStorageData(namespace, {
        // query: newQuery.query,
        formData: newData,
        id: res.data.id,
        name: filterName,
      });
    }
  };

  const deleteFilter = async id => {
    await apiCall('delete', 'fleet/filters', { id });
    getFleetFilters();
  };

  const loadFilter = filter => {
    if (filter.data) {
      // Remove possibly stored text searches as it is not supported anymore
      delete filter.data.query?.query?.text;
      delete filter.data.formData?.text;

      const result = buildQuery(filter.data.formData);

      setVisible(false);
      setHasChanges(false);
      setActiveFilter({ id: filter.id, name: filter.name });
      setFleetFilter({ query: result.query });
      setFormData({ ...filter.data.formData });
      setModifiedFormData({ ...filter.data.formData });
      setFleetFilterStorageData(namespace, {
        formData: filter.data.formData,
        query: result.query,
        id: filter.id,
        name: filter.name,
      });
    }
  };

  const applyFilter = () => {
    setFormData(modifiedFormData);
    setHasChanges(false);
    setActiveFilter(null);
    const result = buildQuery(modifiedFormData);
    if (result) {
      setFleetFilter(result);
      setVisible(false);
      setFleetFilterStorageData(namespace, { formData: modifiedFormData });
    } else {
      setFleetFilter({});
    }
  };

  const isEmpty = () =>
    Object.values(modifiedFormData).every(x => x === null || x === '' || x.length === 0 || x === false);

  const handleNameChange = e => {
    const name = e.target.value;
    setFilterName(name);
  };

  const handleInputChange = (key, value) => {
    if (formData[key] !== value) {
      setHasChanges(true);
    }

    setModifiedFormData(old => ({
      ...old,
      [key]: value,
    }));
  };

  const clickCheckbox = (key, event) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setModifiedFormData(old => ({ ...old, [key]: event.target.checked }));
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <ColumnContainer>
          <Column>
            <Title>{t('VTS area')}</Title>
            <SelectContainer>
              <FleetFilterSelect
                data={vtsAreaData}
                placeholder={t('Search or select VTS area')}
                selectedValues={modifiedFormData.vtsArea || []}
                onChange={value => handleInputChange('vtsArea', value)}
              />
            </SelectContainer>
          </Column>
          <Column>
            <Title>{t('Port area')}</Title>
            <TreeSelectContainer>
              <FleetFilterTreeSelect
                data={portAreaData}
                placeholder={t('Search or select port area')}
                selectedValues={modifiedFormData.portAreaSelect || []}
                onChange={value => handleInputChange('portAreaSelect', value)}
              />
            </TreeSelectContainer>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column>
            <Title>{t('Vessel')}</Title>
            <SelectContainer>
              <Select
                mode="multiple"
                placeholder={t('Search or select vessel')}
                onChange={value => handleInputChange('vessel', value)}
                style={{ width: '100%' }}
                value={modifiedFormData.vessel || []}
                labelInValue={true}
                dropdownStyle={{ minWidth: '300px' }}
                options={vesselData}
                maxTagCount={6}
                maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} ...`}
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.mmsi.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </SelectContainer>
          </Column>
          <Column>
            <Title>{t('Agent')}</Title>
            <SelectContainer>
              <Select
                mode="multiple"
                placeholder={t('Search or select agent')}
                onChange={value => handleInputChange('agent', value)}
                style={{ width: '100%' }}
                value={modifiedFormData.agent || []}
                dropdownStyle={{ minWidth: '300px' }}
                maxTagCount={6}
                maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} ...`}
              >
                {agentData.map(agent => (
                  <Select.Option key={agent}>{agent}</Select.Option>
                ))}
              </Select>
            </SelectContainer>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column>
            <Title>{t('Vessel type')}</Title>
            <SelectContainer>
              <Select
                mode="multiple"
                placeholder={t('Search or select vessel type')}
                onChange={value => handleInputChange('vesselType', value)}
                style={{ width: '100%' }}
                value={modifiedFormData.vesselType || []}
                dropdownStyle={{ minWidth: '300px' }}
                maxTagCount={6}
                maxTagPlaceholder={omittedValues => `+ ${omittedValues.length} ...`}
              >
                {vesselTypeData.map(vesselType => (
                  <Select.Option key={vesselType.name}>{vesselType.name}</Select.Option>
                ))}
              </Select>
            </SelectContainer>
          </Column>
          <Column>
            <Title>{t('Shipping company')}</Title>
            <SelectContainer>
              <FleetFilterSelect
                data={shippingCompanyData}
                placeholder={t('Search or select shipping company')}
                selectedValues={modifiedFormData.shippingCompany || []}
                onChange={value => handleInputChange('shippingCompany', value)}
              />
            </SelectContainer>
          </Column>
        </ColumnContainer>
        <ColumnContainer>
          <Column>
            <Title>{t('Nationality')}</Title>
            <SelectContainer>
              <FleetFilterSelect
                data={nationalityData}
                placeholder={t('Search or select nationality')}
                selectedValues={modifiedFormData.nationality || []}
                onChange={value => handleInputChange('nationality', value)}
                showSelectAll={true}
              />
            </SelectContainer>
          </Column>
        </ColumnContainer>
        <Title>{t('Arrival/Departure')}</Title>
        <CheckBoxContainer>
          <Checkbox
            style={{ width: '100%' }}
            checked={modifiedFormData.arriving}
            onChange={e => clickCheckbox('arriving', e)}
          >
            <span>{t('Arriving')}</span>
          </Checkbox>
          <Checkbox
            style={{ width: '100%' }}
            checked={modifiedFormData.departed}
            onChange={e => clickCheckbox('departed', e)}
          >
            <span>{t('Departed')}</span>
          </Checkbox>
        </CheckBoxContainer>
        <FilterSaveContainer>
          <Title>{t('Save filters')}</Title>
          <SaveFilterRow>
            <Input
              placeholder={t('Custom filter name')}
              name="filterName"
              style={{ width: '100%', marginBottom: 0 }}
              value={filterName}
              maxLength={40}
              onChange={handleNameChange}
            />
            <ButtonLight disabled={!filterName || isEmpty()} cancel onClick={() => saveFilter()}>
              {t('Save filter')}
            </ButtonLight>
          </SaveFilterRow>
          <span>{t('Saved filters')}</span>
          <div>
            {fleetFilters.map(filter => (
              <LoadFilterRow key={filter.id}>
                <FilterLink active={activeFilter?.id === filter.id && !hasChanges} onClick={() => loadFilter(filter)}>
                  {filter?.name}
                </FilterLink>
                <DeleteLink onClick={() => deleteFilter(filter.id)}>
                  <Icon type="trash" style={{ marginRight: '5px' }} />
                </DeleteLink>
              </LoadFilterRow>
            ))}
          </div>
        </FilterSaveContainer>
      </InnerContainer>
      <ButtonsContainer>
        <ButtonLight
          cancel
          onClick={() => {
            setHasChanges(false);
            setFleetFilter({});
            setModifiedFormData({
              etaStart: null,
              etaEnd: null,
              etdStart: null,
              etdEnd: null,
              portarea: [],
              locode: [],
              text: '',
              name: '',
              agent: [],
              vesselType: [],
              arriving: false,
              departed: false,
            });
            clearFormData();
            setActiveFilter(null);
            setFleetFilterStorageData(namespace, null);
          }}
        >
          {t('Clear all')}
        </ButtonLight>
        <ButtonLight disabled={!hasChanges} onClick={() => applyFilter()}>
          {t('Apply filter')}
        </ButtonLight>
      </ButtonsContainer>
    </OuterContainer>
  );
};

export default FleetFilter;
