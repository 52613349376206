import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { UserContext } from '../../context/UserContext';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';

import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LeftContainer = styled.div`
  width: 340px;
  min-width: 300px;
  border-right: 1px solid #e8e8e8;
  height: calc(100vh - 168px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  padding-right: 12px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 154px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const RightContainer = styled.div`
  width: calc(100% - 340px);
  min-width: 600px;
`;

const HelpText = styled.div`
  margin-bottom: 24px;
`;

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NoDocument = styled.div`
  margin-top: 150px;
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;

  button {
    margin-bottom: 0px;
    margin-left: 12px;
  }
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: flex;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

const PageNumber = styled.div`
  margin-right: 12px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.disabled && props.theme.color.grey_light2};
`;

const WasteViewDocument = () => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const [code, setCode] = useState(null);
  const [blob, setBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const sendCode = async () => {
    setLoading(true);
    try {
      const result = await apiCall('get', 'document/by-code', { code });
      if (result?.data && result.data?.contents && result.status === 200) {
        const base64Response = await fetch(`data:application/pdf;base64,${result.data?.contents}`);
        const newblob = await base64Response.blob();
        setBlob(newblob);
      }
    } catch (e) {
      console.error('Getting document failed.', e);
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <Container>
      <LeftContainer alertsHeight={alertsHeight}>
        <HelpText>{t('Here you can check the validity of a printed waste receipt.')}</HelpText>
        <HelpText>
          {t(
            'With the 20 digit Port Activity document code you can check the contents of the official document and compare them to the printed one.'
          )}
        </HelpText>
        <RowContainerSplit>
          <RowHeader>{t('Document code')}</RowHeader>
          <InputRow>
            <Input placeholder={t('Code')} value={code || ''} onChange={e => setCode(e.target.value)} />
            <ButtonLight disabled={!code || code.length !== 24} onClick={sendCode}>
              {t('Send')}
            </ButtonLight>
          </InputRow>
        </RowContainerSplit>
      </LeftContainer>
      <RightContainer>
        {loading ? (
          <Loader>
            <Spin spinning={true} size={'large'} />
          </Loader>
        ) : blob ? (
          <>
            <Buttons>
              <PageNumber>{t('Page {{pageNumber}} of {{numPages}}', { pageNumber, numPages })}</PageNumber>
              {numPages > 1 && (
                <IconContainer onClick={previousPage} disabled={pageNumber <= 1} style={{ marginLeft: 0 }}>
                  <Icon type="chevron-left" />
                </IconContainer>
              )}
              {numPages > 1 && (
                <IconContainer onClick={nextPage} disabled={pageNumber >= numPages}>
                  <Icon type="chevron-right" />
                </IconContainer>
              )}
            </Buttons>
            <Document loading={null} file={blob} className="waste-pdf-file" onLoadSuccess={onDocumentLoadSuccess} options={options}>
              <Page loading={null} className="pdf-page" pageNumber={pageNumber} width={600} />
            </Document>
          </>
        ) : (
          <NoDocument>{t('No document available.')}</NoDocument>
        )}
      </RightContainer>
    </Container>
  );
};
export default WasteViewDocument;
