import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 142px;
`;

const ModalInnerDelete = styled(ModalInner)`
  min-height: 320px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc((100% - 24px) / 3);
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 12px;
  margin-bottom: 12px;
`;

const RemoveButtonColumn = styled.div`
  margin-right: 12px;
`;

const TimeColumn = styled.div`
  margin-left: 12px;
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 138px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RedIcon = styled.span`
  color: red;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-item: center;
  svg {
    width: 26px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  padding: 3px 0 0 10px;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

const AddOrEditBerthBlockModal = ({ type, closeModal, vessel = undefined, readOnly }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { berths, sendDeleteBerthBlock, sendAddBerthBlock, sendUpdateBerthBlock } = useContext(
    BerthPlanningToolContext
  );

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [inputData, setInputData] = useState({
    start_time: vessel ? vessel.start_time : undefined,
    end_time: vessel ? vessel.end_time : undefined,
    berth_code: vessel ? vessel.berth_code : undefined,
  });

  const title = readOnly
    ? t('Berth block')
    : type === 'new'
      ? t('Add new berth block')
      : t('Edit or remove the berth block');

  const dataIsValid = () => {
    return inputData.start_time && inputData.end_time && inputData.berth_code;
  };

  const handleBerthChange = value => {
    setInputData({ ...inputData, berth_code: value });
  };

  const onStartTimeChange = value => {
    setInputData({ ...inputData, start_time: value || null });
  };

  const onEndTimeChange = value => {
    setInputData({ ...inputData, end_time: value || null });
  };

  const deleteBerthBlockClick = () => {
    setDeleteConfirmation(true);
  };

  const closeConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const removeBerthBlock = () => {
    sendDeleteBerthBlock(vessel.id);

    closeModal();
  };

  const sendData = () => {
    if (type === 'new') {
      sendAddBerthBlock({ ...inputData });
    } else {
      sendUpdateBerthBlock({ ...inputData, id: vessel.id });
    }

    closeModal();
  };

  const berthOptions = [];
  berths.forEach(g => {
    g.subRows.forEach(b => {
      if (b.reservable) {
        berthOptions.push({ label: b.short_name, value: b.id });
      }
    });
  });

  return (
    <Modal title={title} open={!!type} width={800} onCancel={closeModal} footer={null}>
      {deleteConfirmation ? (
        <ModalInnerDelete>
          <IconContainer>
            <Icon type="remove-illustration"></Icon>
          </IconContainer>
          <ConfirmationText>{t('Are you sure you want to remove the berth block?')}</ConfirmationText>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight type="button" cancel onClick={closeConfirmation}>
              {t('Back')}
            </ButtonLight>
            <ButtonLight red onClick={removeBerthBlock}>
              {t('Remove')}
            </ButtonLight>
          </ActionButtons>
        </ModalInnerDelete>
      ) : (
        <ModalInnerInput>
          {type !== 'new' && !readOnly && (
            <RemoveContainer>
              <RemoveButtonColumn>
                <ButtonContainer onClick={deleteBerthBlockClick}>
                  <RedIcon>
                    <Icon type="trash"></Icon>
                  </RedIcon>
                  <BoldText>{t('Remove berth block')}</BoldText>
                </ButtonContainer>
              </RemoveButtonColumn>
              <TimeColumn>
                <FirstColumn>
                  <RowHeaderForTooltip>{t('Start')}:</RowHeaderForTooltip>
                  <RowHeaderForTooltip>{t('End')}:</RowHeaderForTooltip>
                </FirstColumn>
                <SecondColumn>
                  <TooltipValue>{dayjs(vessel.start_time).format('MMM DD, YYYY HH:mm')}</TooltipValue>
                  <TooltipValue>{dayjs(vessel.end_time).format('MMM DD, YYYY HH:mm')}</TooltipValue>
                </SecondColumn>
              </TimeColumn>
            </RemoveContainer>
          )}
          <ScrollContainer>
            <Row>
              <RowContainer>
                <RowHeader>
                  {t('Berth')}
                  <Required>*</Required>
                </RowHeader>
                {readOnly || type !== 'new' ? (
                  <Input
                    placeholder={t('Berth')}
                    value={vessel.berth_short_name}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <Select
                    showSearch
                    placeholder={t('Berth name')}
                    optionFilterProp="label"
                    onChange={handleBerthChange}
                    style={{ width: '100%' }}
                    options={berthOptions}
                    value={inputData.berth_code || undefined}
                  />
                )}
              </RowContainer>
              <RowContainer>
                <RowHeader>
                  {t('Start')}
                  <Required>*</Required>
                </RowHeader>
                {readOnly ? (
                  <Input
                    placeholder={t('Berth block start')}
                    value={inputData.start_time ? dayjs(inputData.start_time).format('DD.MM.YYYY HH:mm') : ''}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                    defaultValue={inputData.start_time ? dayjs(inputData.start_time) : null}
                    style={{
                      width: '100%',
                    }}
                    onChange={onStartTimeChange}
                    placeholder={t('Berth block start')}
                  />
                )}
              </RowContainer>
              <RowContainer style={{ marginRight: '0px' }}>
                <RowHeader>
                  {t('End')}
                  <Required>*</Required>
                </RowHeader>
                {readOnly ? (
                  <Input
                    placeholder={t('Berth block end')}
                    value={inputData.end_time ? dayjs(inputData.end_time).format('DD.MM.YYYY HH:mm') : ''}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    showTime={{ format: 'HH:mm', minuteStep: 15 }}
                    defaultValue={inputData.end_time ? dayjs(inputData.end_time) : null}
                    style={{
                      width: '100%',
                    }}
                    onChange={onEndTimeChange}
                    placeholder={t('Berth block end')}
                  />
                )}
              </RowContainer>
            </Row>
          </ScrollContainer>
          <MiddleLine />
          {readOnly ? (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
                {t('Close')}
              </ButtonLight>
            </ActionButtons>
          ) : (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight disabled={!dataIsValid()} onClick={sendData}>
                {type === 'new' ? t('Add') : t('Save')}
              </ButtonLight>
            </ActionButtons>
          )}
        </ModalInnerInput>
      )}
    </Modal>
  );
};

export default AddOrEditBerthBlockModal;
