import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';

import { UserContext } from '../../../context/UserContext';
import { ServiceOrderContext } from '../../../context/ServiceOrderContext';
import ButtonLight from '../../ui/ButtonLight';

const Acknowledgement = styled.div`
  height: 80px;
  padding-top: 6px;
`;

const ActionButtons = styled.div`
  display: flex;
  text-align: right;
  margin-top: 6px;
  button {
    margin-bottom: 0px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = styled(ButtonLight)`
  min-width: ${props => (props.phoneCallModuleOn ? '66px' : '76px')} !important;
`;

const ServiceOrderTaskListAcknowledgement = ({ record, setSentSuccess, setSentRejectSuccess, phoneCallModuleOn }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { getServiceOrders } = useContext(ServiceOrderContext);
  const { t } = useTranslation(namespace);

  const [note, setNote] = useState('');

  const sendData = async (data, status) => {
    const result = await apiCall('post', 'service-orders/service-ack', data);

    if (result?.status === 200) {
      if (status === 'accepted') {
        setSentSuccess(
          record.data.order_lines[0].service_provider_name + ' ' + record.data.order_lines[0].service_type_name
        );
      } else {
        setSentRejectSuccess(
          record.data.order_lines[0].service_provider_name + ' ' + record.data.order_lines[0].service_type_name
        );
      }
      getServiceOrders();
    }
  };

  const createDataToBeSent = status => {
    sendData(
      {
        service_ack: {
          order_line_id: record.data.order_lines[0].id,
          status: status,
          notes: note,
          email: record.data.order_lines[0].email,
          phone: record.data.order_lines[0].phone,
        },
      },
      status
    );
  };

  return (
    <Acknowledgement>
      <Input value={note} onChange={e => setNote(e.target.value)} placeholder={t('Note')} />
      <ActionButtons>
        <Popconfirm
          title={t('Acknowledge service?')}
          onConfirm={() => createDataToBeSent('accepted')}
          okText={t('Yes')}
          okType="primary"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-delivery"
          key="action-1"
          cancelButtonProps={{ type: 'link' }}
        >
          <div>
            <ButtonLight>{t('Acknowledge')}</ButtonLight>
          </div>
        </Popconfirm>
        <Popconfirm
          title={t('Reject service?')}
          onConfirm={() => createDataToBeSent('rejected')}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-cancel"
          key="action-2"
          cancelButtonProps={{ type: 'link' }}
        >
          <div>
            <Button cancel phoneCallModuleOn={phoneCallModuleOn}>
              {t('Reject')}
            </Button>
          </div>
        </Popconfirm>
      </ActionButtons>
    </Acknowledgement>
  );
};

export default ServiceOrderTaskListAcknowledgement;
