import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';

import { UserContext } from '../../context/UserContext';
import { NotificationContext } from '../../context/NotificationContext';

import Form from '../ui/Form';
import ButtonLight from '../ui/ButtonLight';
import ProfileCircle from '../ui/ProfileCircle';

const { TextArea } = Input;

const NotificationMessageContainer = styled.div`
  margin-left: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(ButtonLight)`
  height: 22px;
  line-height: 18px;
  padding: 0 4px !important;
  min-width: 76px !important;
  margin-top: 6px;
`;

const Unclicked = styled.div`
  background-color: #f7f7f7;
  height: 30px;
  width: calc(100% - 36px);
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.small};
  line-height: 30px;
  padding-left: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

const ActionButtons = styled.div`
  text-align: right;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NotificationForm = styled(Form)`
  padding: 6px 0 0 0;
  select,
  input,
  textarea,
  span {
    width: 100%;
  }
  label {
    text-align: center;
  }
`;

const MessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  display: flex;
  padding-bottom: 0.5px;
  width: 100%;

  div {
    margin-bottom: 0;
  }
  textarea {
    min-height: 50px;
    resize: none;
    margin-left: 6px;
  }
`;

const UnclickedContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  display: flex;
  padding-bottom: 0.5px;
`;

const NotificationListReplyMessage = ({ data, childrenAvailable, messageSent }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState(undefined);

  const openRef = useRef();

  useEffect(() => {
    if (!openRef.current && open && labelRef.current) {
      labelRef.current.focus();
    }

    openRef.current = open;
  }, [open]);

  const { apiSendNotification } = useContext(NotificationContext);

  let labelRef = useRef();

  const handleMessageChange = e => {
    setReplyMessage(e.target.value);
  };

  const handleCancel = () => {
    setReplyMessage(undefined);
    setOpen(false);
  };

  const handleReplySend = e => {
    e.preventDefault();

    let type = 'ship';

    if (data.type === 'port') {
      type = 'port';
    }

    setOpen(false);
    apiSendNotification(type, replyMessage, data.ship_imo, null, null, data.id);
    setReplyMessage(undefined);
    messageSent();
  };

  if (!user.permissions.includes('send_push_notification')) {
    return null;
  }

  return (
    <NotificationMessageContainer childrenAvailable={childrenAvailable}>
      <NotificationForm onSubmit={handleReplySend}>
        {open ? (
          <div>
            <MessageContainer>
              <ProfileCircle small />
              <TextArea
                style={{
                  width: 'calc(100% - 36px)',
                }}
                placeholder={t('Write a reply...')}
                value={replyMessage}
                onChange={handleMessageChange}
                ref={input => {
                  labelRef.current = input;
                }}
              />
            </MessageContainer>
            <ActionButtons>
              <StyledButton cancel onClick={handleCancel}>
                {t('Cancel')}
              </StyledButton>
              <StyledButton disabled={!replyMessage}>{t('Send')}</StyledButton>
            </ActionButtons>
          </div>
        ) : (
          <UnclickedContainer>
            <ProfileCircle small />
            <Unclicked onClick={() => setOpen(true)}>{t('Write a reply...')}</Unclicked>
          </UnclickedContainer>
        )}
      </NotificationForm>
    </NotificationMessageContainer>
  );
};

export default NotificationListReplyMessage;
