import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';

import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';
import { ServiceOrderContext } from '../../../context/ServiceOrderContext';
import ButtonLight from '../../ui/ButtonLight';

const Delivery = styled.div`
  height: 80px;
  display: flex;
  padding-top: 6px;
`;

const Column = styled.div`
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
`;

const ActionButtons = styled.div`
  margin-left: 6px;
  button {
    margin-bottom: 0px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-top: 6px;
    }
  }
`;

const ServiceOrderTaskListDelivery = ({ record, setSentSuccess, setSentCancelSuccess }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { getServiceOrders } = useContext(ServiceOrderContext);
  const { t } = useTranslation(namespace);

  const [note, setNote] = useState('');
  const [amount, setAmount] = useState(record.data.order_lines[0].quantity);
  const [time, setTime] = useState(record.data.order_lines[0].requested_delivery_time);

  const sendData = async (data, status) => {
    const result = await apiCall(
      record.data.order_lines[0].deliveries.length > 0 ? 'put' : 'post',
      'service-orders/service-delivery',
      data
    );

    if (result?.status === 200) {
      if (status === 'done') {
        setSentSuccess(
          record.data.order_lines[0].service_provider_name + ' ' + record.data.order_lines[0].service_type_name
        );
      } else {
        setSentCancelSuccess(
          record.data.order_lines[0].service_provider_name + ' ' + record.data.order_lines[0].service_type_name
        );
      }

      getServiceOrders();
    }
  };

  const createDataToBeSent = status => {
    if (record.data.order_lines[0].deliveries.length > 0) {
      sendData(
        {
          service_delivery: {
            id: record.data.order_lines[0].deliveries[0].id,
            status: status,
            quantity: parseFloat(amount),
            delivery_time: time,
            notes: note,
            email: record.data.order_lines[0].email,
            phone: record.data.order_lines[0].phone,
          },
        },
        status
      );
    } else {
      sendData(
        {
          service_delivery: {
            order_line_id: record.data.order_lines[0].id,
            status: status,
            quantity: parseFloat(amount),
            delivery_time: time,
            notes: note,
            email: record.data.order_lines[0].email,
            phone: record.data.order_lines[0].phone,
          },
        },
        status
      );
    }
  };

  return (
    <Delivery>
      <Column>
        <Row>
          <Input
            value={amount}
            onChange={e => setAmount(e.target.value)}
            placeholder={t('Amount')}
            style={{ marginRight: '6px', width: '100px' }}
          />
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 15 }}
            style={{
              width: '100%',
              color: '#4a4a4a',
            }}
            value={time ? dayjs(time) : null}
            onChange={value => setTime(value ? value.format() : value)}
            placeholder={t('Delivery time')}
          />
        </Row>
        <Row style={{ marginTop: '6px' }}>
          <Input value={note} onChange={e => setNote(e.target.value)} placeholder={t('Note')} />
        </Row>
      </Column>
      <Column style={{ width: '90px' }}>
        <ActionButtons>
          <Popconfirm
            title={t('Deliver service?')}
            onConfirm={() => createDataToBeSent('done')}
            okText={t('Yes')}
            okType="primary"
            cancelText={t('No')}
            icon={null}
            id="pop-confirm-for-delivery"
            key="action-1"
            cancelButtonProps={{ type: 'link' }}
          >
            <div>
              <ButtonLight style={{ marginTop: '0px' }}>{t('Deliver')}</ButtonLight>
            </div>
          </Popconfirm>

          <Popconfirm
            title={t('Cancel service?')}
            onConfirm={() => createDataToBeSent('cancelled')}
            okText={t('Yes')}
            okType="danger"
            cancelText={t('No')}
            icon={null}
            id="pop-confirm-for-cancel"
            key="action-2"
            cancelButtonProps={{ type: 'link' }}
          >
            <div>
              <ButtonLight cancel>{t('Cancel')}</ButtonLight>
            </div>
          </Popconfirm>
        </ActionButtons>
      </Column>
    </Delivery>
  );
};

export default ServiceOrderTaskListDelivery;
