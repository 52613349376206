import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { TileLayer, useMap, useMapEvents } from 'react-leaflet';
import Tooltip from 'antd/es/tooltip';
import Switch from 'antd/es/switch';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';

import Button from '../ui/Button';
import Icon from '../ui/Icon';

import { getOptions } from '../map/MapUtils';
import BerthOverlay from './BerthOverlay';
import BerthVesselsOverlay from './BerthVesselsOverlay';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import { UserContext } from '../../context/UserContext';
import { CraneContext } from '../../context/CraneContext';
import CraneOverlay from '../cranePlanningTool/CraneOverlay';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 8px;
  bottom: ${props => (props.showResize ? '52px' : '7px')};
  display: inline-grid;
  margin: 0;
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
`;

const CraneOptions = styled.div`
  position: absolute;
  top: 6px;
  right: 0px;
  z-index: 400;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(225px)')};
  transition: all 0.3s linear;
`;

const Opener = styled.div`
  position: absolute;
  width: 14px;
  height: 40px;
  top: 6px;
  left: -14px;
  cursor: pointer;
  z-index: 100;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 1px 0 0 1px;
`;

const IconContainer = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  margin-left: 1px;
  color: ${({ theme }) => theme.color.grey};
`;

const IconContainerCrane = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  color: ${({ theme }) => theme.color.grey};
  margin-left: 12px;

  svg {
    margin-top: -20px;
  }
`;

const Options = styled.div`
  padding: 12px 18px;
  background-color: ${({ theme }) => theme.color.grey_lighter};
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : '8px')};

  button {
    margin-top: 3px;
  }
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-left: 12px;
`;

const BerthPlanningToolMapInnerContainer = ({ zoom, setZoom, showResize, showCraneOptions, craneView }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const BerthContext = useContext(BerthPlanningToolContext);
  const CraneContextContext = useContext(CraneContext);

  const [craneClicked, setCraneClicked] = useState(undefined);
  const [craneOptionsOpen, setCraneOptionsOpen] = useState(true);

  const [currentCranes, setCurrentCranes] = useState(true);
  const [plannedCranes, setPlannedCranes] = useState(true);

  const options = getOptions();
  const map = useMap();

  const predictiveBptRef = useRef();

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  useEffect(() => {
    if (!predictiveBptRef.current && BerthContext.predictiveBPTOn) {
      setPlannedCranes(true);
    }

    predictiveBptRef.current = BerthContext.predictiveBPTOn;
  }, [BerthContext.predictiveBPTOn]);

  useEffect(() => {
    map.invalidateSize();
  });

  const onCurrentCranesClick = e => {
    if (e) {
      BerthContext.setPredictiveBPTOn(true);
    }
    setCurrentCranes(e);
  };

  const onPlannedCranesClick = e => {
    if (e) {
      BerthContext.setPredictiveBPTOn(true);
    }
    setPlannedCranes(e);
  };

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  const toggleOpen = () => {
    setCraneOptionsOpen(!craneOptionsOpen);
  };

  const cranePrevDiv = L.DomUtil.get('clickpreventercranes');
  if (cranePrevDiv) {
    L.DomEvent.disableScrollPropagation(cranePrevDiv);
  }

  const clickPreventerCranes = () => {
    const div = L.DomUtil.get('clickpreventercranes');
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  };

  return (
    <>
      <ZoomButtonContainer showResize={showResize}>
        <ZoomButton data-testid="map-zoom-in" copy onClick={() => handleZoomButton(zoom + 1)}>
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton data-testid="map-zoom-out" copy onClick={() => handleZoomButton(zoom - 1)}>
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      {zoom > 15 && <BerthOverlay />}
      {!!BerthContext && !craneView && (
        <BerthVesselsOverlay
          zoom={zoom}
          setCraneClicked={setCraneClicked}
          craneClicked={craneClicked}
          plannedCranes={plannedCranes && BerthContext.predictiveBPTOn}
        />
      )}
      {!!CraneContextContext && currentCranes && BerthContext.predictiveBPTOn && (
        <CraneOverlay
          zoom={zoom}
          setCraneClicked={setCraneClicked}
          predictiveBPTOn={!craneView ? BerthContext.predictiveBPTOn : true}
        />
      )}

      {showCraneOptions && !!CraneContextContext && CraneContextContext.cranesAvailable && (
        <CraneOptions data-id="crane-options" open={craneOptionsOpen}>
          <Opener onClick={toggleOpen}>
            <Tooltip
              title={craneOptionsOpen ? t('Close crane options') : t('Open crane options')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="left"
            >
              {craneOptionsOpen ? (
                <IconContainer aria-label="map-layers-caret-right">
                  <CaretRightOutlined />
                </IconContainer>
              ) : (
                <IconContainer aria-label="map-layers-caret-left">
                  <CaretLeftOutlined />
                </IconContainer>
              )}
            </Tooltip>
          </Opener>
          <Options id={'clickpreventercranes'} onClick={() => clickPreventerCranes()}>
            {!craneView && (
              <Tooltip
                title={t('Show planned cranes on the map')}
                color="white"
                overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                placement="top"
              >
                <SwitchContainer
                  style={{ marginTop: '9px', marginBottom: '9px', height: '22px' }}
                  noMarginBottom={true}
                >
                  <Switch
                    size="small"
                    checked={plannedCranes && BerthContext.predictiveBPTOn}
                    onChange={onPlannedCranesClick}
                    aria-label="Planned cranes switch"
                  />
                  <IconContainerCrane aria-label="map-layers-caret-right">
                    <Icon type="crane-planned" />
                  </IconContainerCrane>
                  <SwitchLabel>{t('Planned cranes')}</SwitchLabel>
                </SwitchContainer>
              </Tooltip>
            )}
            <Tooltip
              title={t('Show crane live positions on the map')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="top"
            >
              <SwitchContainer style={{ marginTop: '9px', marginBottom: '9px', height: '22px' }} noMarginBottom={true}>
                <Switch
                  size="small"
                  checked={currentCranes && BerthContext.predictiveBPTOn}
                  onChange={onCurrentCranesClick}
                  aria-label="Current cranes switch"
                />
                <IconContainerCrane aria-label="map-layers-caret-right">
                  <Icon type="crane-current" />
                </IconContainerCrane>
                <SwitchLabel>{t('Crane live positions')}</SwitchLabel>
              </SwitchContainer>
            </Tooltip>
          </Options>
        </CraneOptions>
      )}
    </>
  );
};

export default BerthPlanningToolMapInnerContainer;
