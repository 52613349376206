import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import 'chartjs-adapter-dayjs-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import { UserContext } from '../../context/UserContext';
import { WeatherContext } from '../../context/WeatherContext';

const Wrapper = styled.div`
  padding: 20px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  margin: 10px;
  height: calc(100vh - 240px);
`;

const SeaLevelGraph = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const { seaLevelForecast, currentSeaLevel } = useContext(WeatherContext);

  const resize = () => {
    const { innerWidth: windowWidth } = window;
    setInnerWidth(windowWidth);
  };

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  if (!currentSeaLevel || !seaLevelForecast) {
    return null;
  }

  let showableData = seaLevelForecast.filter(f => f.mw || f.mw === 0);

  const smallerItems = innerWidth < 700;

  const obsColor = '#4990DD';

  const data = {
    labels: [
      showableData[0] ? dayjs(showableData[0].time).subtract(1, 'hour').toDate() : '-',
      ...showableData.map(f => dayjs(f.time).toDate()),
    ],
    datasets: [
      {
        type: 'bar',
        label: 'Observed Sea Level (Mean Water)',
        backgroundColor: obsColor,
        data: showableData[0]
          ? [
              {
                y: currentSeaLevel.observation ? currentSeaLevel.observation.mw : 0,
                x: dayjs(showableData[0].time).subtract(1, 'hour').toDate(),
              },
            ]
          : [],
        borderColor: obsColor,
        borderWidth: 2,
        barPercentage: 1,
        categoryPercentage: 0.4,
        datalabels: {
          display: false,
        },
      },
      {
        type: 'bar',
        label: 'Observed Sea Level (N2000)',
        backgroundColor: '#26C6DA',
        data: showableData[0]
          ? [
              {
                y: currentSeaLevel.observation ? currentSeaLevel.observation.n2000 : 0,
                x: dayjs(showableData[0].time).subtract(1, 'hour').toDate(),
              },
            ]
          : [],
        borderColor: '#26C6DA',
        borderWidth: 2,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
        datalabels: {
          display: false,
        },
      },
      {
        label: t('Sea Level Forecast (Mean Water)'),
        data: showableData.map(f => ({
          y: f.mw,
          x: dayjs(f.time).toDate(),
        })),
        backgroundColor: '#84B644',
        borderColor: 'rgba(132, 182, 68, 1)',
        datalabels: {
          display: false,
        },
        pointRadius: smallerItems ? 1 : 3,
        borderWidth: smallerItems ? 1 : 4,
      },
      {
        label: t('Sea Level Forecast (N2000)'),
        data: showableData.map(f => ({
          y: f.n2000,
          x: dayjs(f.time).toDate(),
        })),
        backgroundColor: '#009688',
        borderColor: 'rgba(0, 150, 136, 1)',
        datalabels: {
          display: false,
        },
        pointRadius: smallerItems ? 1 : 3,
        borderWidth: smallerItems ? 1 : 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        suggestedMin: -50,
        suggestedMax: 100,
        title: {
          text: 'Sea Level (cm)',
          display: true,
        },
      },
      x: {
        type: 'time',
        time: {
          displayFormats: {
            hour: 'DD.MM HH:mm',
          },
          unit: 'hour',
        },
        ticks: {
          callback: function(value, index) {
            if (smallerItems) {
              return value;
            }
            if (index === 0 || value.substring(6, 8) === '00' || value.substring(6, 8) === '01') {
              return value;
            } else {
              return value.substring(6, 11);
            }
          },
          maxTicksLimit: smallerItems ? 10 : 30,
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return context.dataset.label + ': ' + context.formattedValue + 'cm';
          },
          title: function(context) {
            if (context[0].dataset.data.length === 1) {
              return t('Observed at ') + dayjs(currentSeaLevel.observation.time).format('MMM DD, YYYY HH:mm');
            }
            return dayjs(context[0].parsed.x).format('MMM DD, YYYY HH:mm');
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 20,
        },
      },
    },
  };

  const plugins = [
    {
      beforeDraw: chart => {
        let ctx = chart.ctx;
        let xAxis = chart.scales['x'];
        let yAxis = chart.scales['y'];
        ctx.save();
        ctx.fillStyle = '#e0e0e0';
        ctx.beginPath();
        let yTop = yAxis.getPixelForValue(0);
        ctx.fillRect(xAxis.left - 8, yTop - 1, xAxis.right - xAxis.left + 8, 3);
        ctx.stroke();
        ctx.restore();
      },
    },
  ];

  return (
    <Wrapper>
      <Line data={data} options={options} plugins={[...plugins, ChartDataLabels]} />
    </Wrapper>
  );
};

export default SeaLevelGraph;
