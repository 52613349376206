import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import { UserContext } from '../../../context/UserContext';
import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerDelete = styled(ModalInner)`
  min-height: 320px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const DeleteReservationModal = ({ type, closeModal, reservation }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sendDeleteReservation } = useContext(CranePlanningToolContext);

  const removeReservation = () => {
    sendDeleteReservation(reservation.id);

    closeModal();
  };

  return (
    <Modal
      title={t('Remove reservation for ') + reservation.crane_name}
      open={!!type}
      width={800}
      onCancel={closeModal}
      footer={null}
    >
      <ModalInnerDelete>
        <IconContainer>
          <Icon type="remove-illustration"></Icon>
        </IconContainer>
        <ConfirmationText>{t('Are you sure you want to remove the reservation?')}</ConfirmationText>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight red onClick={removeReservation}>
            {t('Remove')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerDelete>
    </Modal>
  );
};

export default DeleteReservationModal;
