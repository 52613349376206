import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Icon from './ui/Icon';
import ServiceOrderTaskListModal from './activity/serviceOrder/ServiceOrderTaskListModal';
import { ServiceOrderContext } from '../context/ServiceOrderContext';
import { mobilePixelMaxWidthLimit } from '../utils/constants';

const ServiceOrderButtonContainer = styled.div`
  width: 30px;
  margin-right: 24px;
  position: relative;
  cursor: pointer;

  svg {
    height: 28px;
    width: 24px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-right: 18px;
    width: 24px;
  }
`;

const CountIndicator = styled.div`
  background-color: ${props => props.theme.color.warning};
  color: white;
  width: 26px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 35%;
  position: absolute;
  top: -6px;
  right: -9px;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 11px;
    width: 22px;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    fill: white !important;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    svg {
      height: 22px;
      width: 22px;
    }
  }
`;

const ServiceOrderTaskListButton = () => {
  const [modal, setModal] = useState(false);

  const { serviceOrders } = useContext(ServiceOrderContext);

  const close = () => {
    setModal(false);
  };

  const open = () => {
    setModal(true);
  };

  return (
    <>
      <ServiceOrderButtonContainer onClick={open}>
        <StyledIcon type="service-order" />
        {serviceOrders.length > 0 && <CountIndicator>{serviceOrders.length}</CountIndicator>}
      </ServiceOrderButtonContainer>

      {!!modal && <ServiceOrderTaskListModal open={modal} close={close} />}
    </>
  );
};

export default ServiceOrderTaskListButton;
