import React, { useContext } from 'react';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import AddOrEditJITSlotRequestInner from './AddOrEditJITSlotRequestInner';

const AddOrEditJITSlotRequestModal = ({ type, closeModal, vessel = undefined, readOnly, newTime }) => {
  let { sendDeleteSlotResquest, sendEditSlotRequest, sendEditEtdSlotRequest } = useContext(BerthPlanningToolContext);

  return (
    <AddOrEditJITSlotRequestInner
      vessel={vessel}
      type={type}
      closeModal={closeModal}
      readOnly={readOnly}
      newTime={newTime}
      sendDeleteSlotResquest={sendDeleteSlotResquest}
      sendEditSlotRequest={sendEditSlotRequest}
      sendEditEtdSlotRequest={sendEditEtdSlotRequest}
    />
  );
};

export default AddOrEditJITSlotRequestModal;
