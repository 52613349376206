import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import styled from 'styled-components';

import { UserContext } from '../../../../context/UserContext';
import ButtonLight from '../../../ui/ButtonLight';

const ModalInner = styled.div`
  min-height: 214px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RowContainer = styled.div`
  padding: 8px 0 12px 0;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const AddOrEditCraneModal = ({ type, closeModal, data, refetchData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    name: data ? data.name : undefined,
    short_name: data ? data.shortName : undefined,
  });

  const [sending, setSending] = useState(false);

  const title = type === 'add' ? t('Add crane') : t('Edit crane');
  const buttonText = type === 'add' ? t('Add') : t('Save');

  const dataIsValid = () => {
    return inputData.name && inputData.short_name;
  };

  const updateName = e => {
    setInputData({ ...inputData, name: e.target.value });
  };

  const updateShortName = e => {
    setInputData({ ...inputData, short_name: e.target.value });
  };

  const sendData = async () => {
    setSending(true);
    let result;

    try {
      if (type === 'add') {
        result = await apiCall('post', 'pbp/cranes', {
          name: inputData.name,
          short_name: inputData.short_name,
        });
      } else {
        result = await apiCall('put', 'pbp/cranes', {
          id: data.id,
          name: inputData.name,
          short_name: inputData.short_name,
        });
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      refetchData();
      closeModal();
    } else {
      setSending(false);
    }
  };

  return (
    <Modal title={title} open={!!type} width={600} footer={null} onCancel={closeModal}>
      <ModalInner>
        <RowContainer>
          <RowHeader>
            {t('Name')}
            <Required>*</Required>
          </RowHeader>
          <Input placeholder={t('Name of the crane')} value={inputData.name || ''} onChange={updateName} />
        </RowContainer>
        <RowContainer>
          <RowHeader>
            {t('Short name')}
            <Required>*</Required>
          </RowHeader>
          <Input
            placeholder={t('Short name of the crane')}
            value={inputData.short_name || ''}
            onChange={updateShortName}
          />
        </RowContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
            {buttonText}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default AddOrEditCraneModal;
