import React, { useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';

import { TIME_FORMAT } from '../../../utils/constants';

import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';
import { UserContext } from '../../../context/UserContext';

import { color_planned } from '../../commonCalendar/utility/constants';
import { getDuration } from '../../../utils/utils';
import EditWorkModal from '../modals/EditWorkModal';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

const WorkContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  z-index: 50;
  border: 1px solid white;
  opacity: 1;
  cursor: pointer;
`;

const WorkInnerContainer = styled.div`
  height: calc(100% ${props => props.craneHeight && ' - ' + props.craneHeight});
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CraneContainer = styled.div`
  height: ${props => props.craneHeight};
  display: flex;
  position: relative;
  background-color: #388e3c;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
`;

const CraneNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 52;
`;

const CraneName = styled.div`
  color: white;
  font-size: 10px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const Break = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0px;
  border-radius: 1px;
  z-index: 50;
`;

const Reservation = styled(Break)`
  background-color: rgb(255, 171, 145);
  z-index: 51;
`;

const TooltipContainer = styled.div`
  max-width: 400px;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey_medium};
  padding: 5px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
  line-height: 21px;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
  position: relative;
`;

const Common = styled.div`
  display: flex;
  position: relative;
`;

const MinorBreaks = styled.div`
  margin-top: 12px;
  display: flex;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.beige};
  width: calc(100% + 26px);
  left: -13px;
  height: 22px;
`;

const SecondColumn = styled.div`
  max-width: 240px;
  position: relative;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const WorkNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px;
  color: #000000;
  font-weight: 600;
`;

const WeatherCrane = styled.div`
  position: relative;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  background-color: rgb(208, 1, 28);
`;

const Work = ({ work }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { canvasTimeStart, canvasTimeEnd, canvasWidth, rowHeight } = useContext(CranePlanningToolContext);

  const [modalOpen, setModalOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  if (!work.start_time || !work.end_time) {
    return null;
  }

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();

  const starttime = dayjs.max([dayjs(work.start_time), dayjs(canvasTimeStart)]);
  const endtime = dayjs.min([dayjs(work.end_time), dayjs(canvasTimeEnd)]);

  const left = dayjs.duration(dayjs(starttime).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels - 2;
  const width = dayjs.duration(dayjs(endtime).diff(starttime)).asMilliseconds() * millisecondInPixels + 4;

  let weatherWidth = 0;

  if (work.weather_adjusted_end_time) {
    const weatherEndtime = dayjs.min([dayjs(work.weather_adjusted_end_time), dayjs(canvasTimeEnd)]);
    weatherWidth = dayjs.duration(dayjs(weatherEndtime).diff(starttime)).asMilliseconds() * millisecondInPixels + 4;
  }

  const getWorkProps = () => {
    let fontSize = '12px';

    return {
      style: {
        width,
        top: 0,
        left,
        backgroundColor: color_planned,
        height: rowHeight,
        fontSize,
      },
    };
  };

  const WorkToolTip = () => {
    return (
      <TooltipContainer data-id="work-tooltip">
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Crane')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Vessel')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>
              {work.crane_name} ({work.crane_short_name})
            </TooltipValue>
            <TooltipValue>{work.vessel_name ? work.vessel_name : '-'}</TooltipValue>
          </SecondColumn>
          <FirstColumn style={{ marginLeft: '18px' }}>
            <RowHeaderForTooltip>{t('Commodity')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Amount')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{work.commodity_name}</TooltipValue>
            <TooltipValue>{work.commodity_amount}</TooltipValue>
          </SecondColumn>
        </Common>
        <Common style={{ marginTop: '6px' }}>
          <Background />
          <FirstColumn style={{ marginRight: '18px' }}>
            <RowHeaderForTooltip style={{ fontWeight: 600 }}>{t('Time')}:</RowHeaderForTooltip>
            {!!work.weather_adjusted_end_time && (
              <RowHeaderForTooltip style={{ fontWeight: 700, height: '30px', lineHeight: '14px', margin: '6px 0' }}>
                {t('Weather adjusted time')}:
              </RowHeaderForTooltip>
            )}
            {work.major_breaks &&
              !!work.major_breaks.length &&
              work.major_breaks.map((b, j) => {
                return <RowHeaderForTooltip key={j}>{b.name}</RowHeaderForTooltip>;
              })}
          </FirstColumn>
          <SecondColumn>
            <TooltipValue style={{ fontWeight: 600 }}>
              {dayjs(work.start_time).format(TIME_FORMAT)} - {dayjs(work.end_time).format(TIME_FORMAT)}
            </TooltipValue>
            {!!work.weather_adjusted_end_time && (
              <TooltipValue
                style={{ fontWeight: 700, height: '30px', lineHeight: '14px', margin: '6px 0', paddingTop: '14px' }}
              >
                {dayjs(work.start_time).format(TIME_FORMAT)} -{' '}
                {dayjs(work.weather_adjusted_end_time).format(TIME_FORMAT)}
              </TooltipValue>
            )}
            {work.major_breaks &&
              !!work.major_breaks.length &&
              work.major_breaks.map((b, j) => {
                return (
                  <TooltipValue style={{ fontWeight: 400 }} key={j}>
                    {dayjs(b.start_time).format(TIME_FORMAT) + ' - ' + dayjs(b.end_time).format(TIME_FORMAT)}
                  </TooltipValue>
                );
              })}
          </SecondColumn>
          <SecondColumn style={{ marginLeft: '18px' }}>
            <TooltipValue style={{ fontWeight: 600 }}>{getDuration(work.duration)}</TooltipValue>
            {!!work.weather_adjusted_end_time && (
              <TooltipValue
                style={{ fontWeight: 700, height: '30px', lineHeight: '14px', margin: '6px 0', paddingTop: '14px' }}
              >
                {getDuration(work.weather_adjusted_duration)}
              </TooltipValue>
            )}
            {work.major_breaks &&
              !!work.major_breaks.length &&
              work.major_breaks.map((b, j) => {
                return (
                  <TooltipValue style={{ fontWeight: 600 }} key={j}>
                    {getDuration(b.duration)}
                  </TooltipValue>
                );
              })}
          </SecondColumn>
        </Common>
        {work.minor_breaks && !!work.minor_breaks.length && (
          <MinorBreaks>{t('And minor breaks, such as coffee, lunch and shift changes.')}</MinorBreaks>
        )}
      </TooltipContainer>
    );
  };

  const WorkContainerCreator = () => {
    return (
      <>
        <WorkContainer data-testid={`work-${work.id}`} onClick={() => setModalOpen(true)} {...getWorkProps()}>
          <Tooltip placement="top" title={WorkToolTip()} color="white" overlayStyle={{ pointerEvents: 'none' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <WorkInnerContainer craneHeight={'48%'}>
                <WorkNameContainer>{work.vessel_name}</WorkNameContainer>
              </WorkInnerContainer>
              <CraneContainer
                style={{
                  width: width - 4 + 'px',
                  left: '1px',
                  height: 'calc(40% - ' + (work.weather_adjusted_end_time ? '4px)' : '0px)'),
                  top: '0px',
                }}
              >
                {work.minor_breaks &&
                  !!work.minor_breaks.length &&
                  work.minor_breaks.map((b, j) => {
                    let breakLeft =
                      dayjs
                        .duration(dayjs(b.start_time).diff(dayjs.max([dayjs(work.start_time), dayjs(canvasTimeStart)])))
                        .asMilliseconds() * millisecondInPixels;
                    let breakWidth =
                      dayjs.duration(dayjs(b.end_time).diff(b.start_time)).asMilliseconds() * millisecondInPixels;

                    if (breakLeft < 0) {
                      breakWidth = breakWidth + breakLeft;
                      breakLeft = 0;
                    }

                    return <Break key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />;
                  })}
                {work.major_breaks &&
                  !!work.major_breaks.length &&
                  work.major_breaks.map((b, j) => {
                    let breakLeft =
                      dayjs
                        .duration(dayjs(b.start_time).diff(dayjs.max([dayjs(work.start_time), dayjs(canvasTimeStart)])))
                        .asMilliseconds() * millisecondInPixels;
                    let breakWidth =
                      dayjs
                        .duration(dayjs.min([dayjs(b.end_time), dayjs(work.end_time)]).diff(b.start_time))
                        .asMilliseconds() * millisecondInPixels;

                    if (breakLeft < 0) {
                      breakWidth = breakWidth + breakLeft;
                      breakLeft = 0;
                    }

                    if (b.type === 'crane_reservation') {
                      return <Reservation key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />;
                    } else {
                      return <Break key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />;
                    }
                  })}
                <CraneNameContainer>
                  <CraneName>{work.crane_short_name}</CraneName>
                </CraneNameContainer>
              </CraneContainer>
              {!!work.weather_adjusted_end_time && (
                <WeatherCrane
                  data-id="weather-crane"
                  style={{
                    height: '3px',
                    left: '1px',
                    top: '1px',
                    width: weatherWidth - 4 + 'px',
                  }}
                />
              )}
            </div>
          </Tooltip>
        </WorkContainer>

        {!!modalOpen && (
          <EditWorkModal
            work={work}
            closeModal={() => {
              setModalOpen(false);
            }}
          />
        )}
      </>
    );
  };

  return WorkContainerCreator();
};

export default Work;
