import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Tag from 'antd/es/tag';
import { PlusOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TimestampContext } from '../../context/TimestampContext';

import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';
import ProfileCircle from '../ui/ProfileCircle';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const { TextArea } = Input;

const NotificationMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  box-shadow: 1px 0px 3px rgb(0 0 0 / 30%);
  padding: 14px 24px;
  background-color: white;
`;

const StyledButton = styled(ButtonLight)`
  margin-top: 6px;
  margin-bottom: 8px;
`;

const ActionButtons = styled.div`
  text-align: right;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  display: flex;
  padding-bottom: 0.5px;
  width: 100%;

  div {
    margin-bottom: 0;
  }
  textarea {
    min-height: 50px;
    resize: none;
    margin-left: 6px;
  }
`;

const UnclickedContainer = styled.div`
  padding-top: 8px;
  position: relative;
  height: 40px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 13px;
  }
`;

const NewMessageButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  padding: 2px 4px;
  position: absolute;
  cursor: pointer;
  font-weight: 600;
  border-radius: 4px;

  &:hover {
    background-color: #f8f8f8;
  }

  i {
    margin-right: 4px;
  }

  svg {
    fill: ${({ theme }) => theme.color.secondary} !important;
    height: 20px;
    width: 20px;
    margin-left: -4px;
    margin-top: -4px;
  }
`;

const SelectContainer = styled.div`
  padding: 0 0 4px 0;
  display: flex;

  svg {
    fill: ${({ theme }) => theme.color.grey} !important;
  }
`;

const Label = styled.div`
  width: 46px;
`;

const MarginTag = styled(Tag)`
  margin-bottom: 4px;
`;

const TagWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  width: unset;
  span {
    width: unset !important;
  }
`;

const TagsWrapper = styled.div`
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  overflow-x: auto;
  span {
    width: unset !important;
  }
`;

const ProfileCircleContainer = styled.div`
  height: 46px;
  margin-top: 8px;
  width: 42px;
`;

const NotificationListAddNewMessage = ({ port, ship, setNewMessageHeight, setScroll }) => {
  const { namespace, user } = useContext(UserContext);
  const { allTimestamps } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState(undefined);

  const [vesselImo, setVesselImo] = useState(undefined);
  const [type, setType] = useState(undefined);

  const initDecisionTags = {
    tags: [],
    inputVisible: false,
    inputValue: '',
    limited: false,
  };

  const [decisionTags, setDecisionTags] = useState(initDecisionTags);

  const openRef = useRef();

  useEffect(() => {
    if (!openRef.current && open && labelRef.current) {
      labelRef.current.focus();
    }

    openRef.current = open;
  }, [open]);

  const { apiSendNotification } = useContext(NotificationContext);

  let labelRef = useRef();

  const handleMessageChange = e => {
    setReplyMessage(e.target.value);
  };

  const handleCancel = () => {
    setType(undefined);
    setDecisionTags(initDecisionTags);
    setNewMessageHeight(248);
    setReplyMessage(undefined);
    setOpen(false);
  };

  const handleReplySend = e => {
    let masterId = undefined;
    let messageType = type;

    if (decisionTags.tags.length > 0) {
      messageType = 'port_call_decision';
    }

    if (messageType === ship || messageType === 'port_call_decision') {
      const ship = allTimestamps.find(s => s.ship.imo === vesselImo);
      if (ship) {
        masterId = ship.ship.master_id;
      }
    }

    e.preventDefault();
    setOpen(false);
    apiSendNotification(
      messageType,
      replyMessage,
      vesselImo,
      masterId,
      messageType === 'port_call_decision' ? decisionTags.tags : null,
      null
    );
    setReplyMessage(undefined);
    setVesselImo(undefined);
    setNewMessageHeight(258);
    setDecisionTags(initDecisionTags);
    setOpen(false);
    setScroll(true);
  };

  const handleTagClose = closed => {
    const tags = decisionTags.tags.filter(tag => tag !== closed);
    const limited = tags.length > 4;
    setDecisionTags({ ...decisionTags, tags, limited });
  };

  const handleTagInputChange = e => {
    setDecisionTags({ ...decisionTags, inputValue: e.target.value });
  };

  const showTagInput = () => {
    setDecisionTags({ ...decisionTags, inputVisible: true });
  };

  const handleTagInputConfirm = e => {
    if (e.key === undefined || e.key === 'Enter') {
      const { inputValue } = decisionTags;
      let { tags } = decisionTags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      const limited = tags.length > 4;
      setDecisionTags({
        tags,
        inputVisible: false,
        inputValue: '',
        limited: limited,
      });
    }
  };

  return (
    <NotificationMessageContainer>
      {open ? (
        <div>
          {type === 'ship' && (
            <SelectContainer>
              <Label />
              <Select
                showSearch
                optionFilterProp="label"
                value={vesselImo}
                style={{ width: '100%' }}
                onChange={e => setVesselImo(e)}
                options={allTimestamps.map(g => {
                  return { label: g.ship.vessel_name + ' (' + g.ship.imo + ')', value: g.ship.imo };
                })}
                placeholder={t('Select vessel')}
                allowClear={true}
                onClear={() => setVesselImo(undefined)}
              />
            </SelectContainer>
          )}
          <MessageContainer>
            <ProfileCircleContainer>
              <ProfileCircle />
            </ProfileCircleContainer>
            <TextArea
              style={{
                width: 'calc(100% - 36px)',
              }}
              placeholder={t('Write a new message...')}
              value={replyMessage}
              onChange={handleMessageChange}
              ref={input => {
                labelRef.current = input;
              }}
            />
          </MessageContainer>
          {type === 'ship' && (
            <div style={{ height: '64px', marginLeft: '47px', marginTop: '12px' }}>
              <TagsWrapper>
                {decisionTags.tags.map(value => {
                  return (
                    <MarginTag
                      key={value}
                      closable
                      onClose={e => {
                        e.preventDefault();
                        handleTagClose(value);
                      }}
                    >
                      {value}
                    </MarginTag>
                  );
                })}
              </TagsWrapper>
              {decisionTags.inputVisible && !decisionTags.limited && (
                <Input
                  autoFocus
                  value={decisionTags.inputValue}
                  onChange={handleTagInputChange}
                  onBlur={handleTagInputConfirm}
                  onKeyPress={handleTagInputConfirm}
                />
              )}
              {!decisionTags.inputVisible && !decisionTags.limited && (
                <TagWrapper>
                  <Tag onClick={showTagInput}>
                    <PlusOutlined /> {t('Request actions by')}
                  </Tag>
                </TagWrapper>
              )}
            </div>
          )}
          <ActionButtons>
            <StyledButton cancel onClick={handleCancel}>
              {t('Cancel')}
            </StyledButton>
            <StyledButton
              disabled={type === 'ship' ? !replyMessage || !vesselImo : !replyMessage}
              onClick={handleReplySend}
            >
              {t('Send')}
            </StyledButton>
          </ActionButtons>
        </div>
      ) : (
        <UnclickedContainer>
          {user.permissions.includes('send_push_notification') && ship && (
            <NewMessageButton
              onClick={() => {
                setType('ship');
                setOpen(true);
                setNewMessageHeight(422);
              }}
              style={{ left: 0 }}
            >
              <Icon type="plus" />
              {t('New ship notification')}
            </NewMessageButton>
          )}
          {user.permissions.includes('send_push_notification') && port && (
            <NewMessageButton
              onClick={() => {
                setType('port');
                setOpen(true);
                setNewMessageHeight(308);
              }}
              style={{ right: 0 }}
            >
              <Icon type="plus" />
              {t('New port notification')}
            </NewMessageButton>
          )}
        </UnclickedContainer>
      )}
    </NotificationMessageContainer>
  );
};

export default NotificationListAddNewMessage;
