import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MapContainer as Map } from 'react-leaflet';
import { UserContext } from '../../context/UserContext';

import MapWithRouteMapInnerContainer from './MapWithRouteMapInnerContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';

const MapContainer = styled.div`
  width: 100%;
  height: ${props => (props.showGraph ? '66%' : '100%')};
  .leaflet-control-container {
    right: 50px;
  }
  .leaflet-control-attribution.leaflet-control {
    display: ${props => (props.showAttribution ? 'inline' : 'none')};
  }
  .leaflet-container {
    width: 100%;
    height: 100%;
    align-content: auto;
  }

  @media (max-height: 800px) {
    height: 100%;
  }
`;

const MapWithRouteMap = ({ dataList, showGraph, hoverData, setHoverData, markerData }) => {
  const { mapDefaultCoordinates } = useContext(UserContext);
  const defCoordinates = mapDefaultCoordinates.split(',');

  const [appliedZoom, setAppliedZoom] = useState(4);

  return (
    <MapContainer showAttribution={false} showGraph={showGraph}>
      <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small')} onError={logError}>
        <Map center={defCoordinates} tap={false} zoom={appliedZoom} zoomControl={false}>
          <MapWithRouteMapInnerContainer
            zoom={appliedZoom}
            setZoom={setAppliedZoom}
            dataList={dataList}
            hoverData={hoverData}
            setHoverData={setHoverData}
            markerData={markerData}
          />
        </Map>
      </ErrorBoundary>
    </MapContainer>
  );
};

export default MapWithRouteMap;
