import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TimeIndicatorText from '../../../commonCalendar/TimeIndicatorText';
import { UserContext } from '../../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../../context/BerthPlanningToolContext';
import BerthPlanningWeather from '../../../weather/BerthPlanningWeather';
import BerthPlanningWeatherNoData from '../../../weather/BerthPlanningWeatherNoData';

const HeaderInterval = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
`;

const Hour = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.color.grey_light2};
  position: absolute;
  width: 30px;
  text-align: center;
`;

const Hours = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
  padding-top: 7px;
`;

const HourForWeek = styled.div`
  font-size: 11px;
  color: ${props => (props.bold ? '#b3b3b3' : props.theme.color.grey_light2)};
  font-weight: ${props => (props.bold ? 700 : 400)};
`;

const HoursForWeek = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 50%;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
`;

const Day = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.color.grey_light2};
  position: absolute;
  width: 12px;
  text-align: center;
`;

const Days = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
`;

const Row = styled.div`
  line-height: 12px;
  font-size: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.black};
  height: 21px;
`;

const hours = [
  '00.00',
  '02.00',
  '04.00',
  '06.00',
  '08.00',
  '10.00',
  '12.00',
  '14.00',
  '16.00',
  '18.00',
  '20.00',
  '22.00',
  '24.00',
];

const dayNames = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const hoursForWeek = ['00.00', '23.00'];

const Interval = ({ bold, getIntervalProps, interval, intervalText, showWeather, unit }) => {
  const { namespace, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { indicatorLine, timelineUnit, width } = useContext(BerthPlanningToolContext);
  const getIntervalProps2 = () => {
    const newProps = getIntervalProps({
      interval,
    });
    return {
      ...newProps,
    };
  };

  const checkIndicatorPosition = () => {
    return (
      (indicatorLine.time.isAfter(interval.startTime) && indicatorLine.time.isBefore(interval.endTime)) ||
      indicatorLine.time.isSame(interval.startTime) ||
      indicatorLine.time.isSame(interval.endTime)
    );
  };

  return (
    <HeaderInterval data-testid="dateHeaderInterval" {...getIntervalProps2()}>
      {checkIndicatorPosition() && (
        <TimeIndicatorText
          start={interval.startTime}
          indicatorLine={indicatorLine}
          timelineUnit={timelineUnit}
          width={width}
        />
      )}
      {unit === 'day' && (
        <>
          <Header>
            <TextContainer>
              <span style={{ fontWeight: bold ? 700 : 400, color: '#444444' }}>{intervalText}</span>
            </TextContainer>
            <Hours>
              {modules.weather_module === 'enabled' && (
                <div>
                  {showWeather ? (
                    <BerthPlanningWeather interval={interval} type="day" />
                  ) : (
                    <BerthPlanningWeatherNoData />
                  )}
                </div>
              )}

              {hours.map((h, i) => {
                let position = 6;
                if (i === hours.length - 1) {
                  position = interval.labelWidth - 30 - 10;
                } else if (i !== 0) {
                  position = i * ((interval.labelWidth - 16) / (hours.length - 1)) - 9;
                }
                return (
                  <Hour key={h} style={{ left: `${position}px` }}>
                    {h}
                  </Hour>
                );
              })}
            </Hours>
          </Header>
        </>
      )}
      {unit === 'week' && (
        <>
          <div style={{ display: 'block', width: '100%', height: '100%' }}>
            {modules.weather_module === 'enabled' && (
              <div>
                {showWeather ? (
                  <BerthPlanningWeather interval={interval} type="week" />
                ) : (
                  <BerthPlanningWeatherNoData />
                )}
              </div>
            )}

            <Header>
              <TextContainer>
                <span style={{ fontWeight: bold ? 700 : 400, color: '#444444' }}>{intervalText}</span>
              </TextContainer>
              <HoursForWeek>
                {hoursForWeek.map(h => (
                  <HourForWeek bold={bold} key={h}>
                    {h}
                  </HourForWeek>
                ))}
              </HoursForWeek>
            </Header>
          </div>
        </>
      )}
      {unit === 'month' && (
        <>
          <Header>
            <TextContainer>
              <span style={{ fontWeight: bold ? 700 : 400, color: '#444444' }}>
                {t('Week')} {intervalText}
              </span>
            </TextContainer>
            {modules.weather_module === 'enabled' && (
              <div>
                {showWeather ? (
                  <BerthPlanningWeather interval={interval} type="month" />
                ) : (
                  <BerthPlanningWeatherNoData />
                )}
              </div>
            )}

            <Days>
              {dayNames.map((h, i) => {
                let oneFourteenth = interval.labelWidth / 14;
                let position = (i * 2 + 1) * oneFourteenth - 7;

                let dayOfWeek = bold ? dayjs().isoWeekday() : undefined;
                let dayNumber = dayjs(interval.startTime).add(i, 'days');

                return (
                  <Day
                    key={i}
                    style={{
                      left: `${position}px`,
                      fontWeight: dayOfWeek ? (i === dayOfWeek - 1 ? '700' : '400') : '400',
                      color: bold ? '#b3b3b3' : '#bebebe',
                    }}
                  >
                    <Row>{h}</Row>
                    <Row>{dayNumber.format('DD')}</Row>
                  </Day>
                );
              })}
            </Days>
          </Header>
        </>
      )}
    </HeaderInterval>
  );
};

export default Interval;
