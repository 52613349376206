import React from 'react';
import styled from 'styled-components';

import Timeline from './calendar/Timeline';

const Calendar = styled.div`
  position: relative;
`;

const FleetViewCalendar = () => {
  return (
    <Calendar style={{ height: '100%' }}>
      <Timeline />
    </Calendar>
  );
};

export default FleetViewCalendar;
