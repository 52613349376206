import React, { useContext } from 'react';
import styled from 'styled-components';

import { TimestampFilteringContext } from '../../context/TimestampFilteringContext';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';

const TimestampFiltering = styled(ButtonLight)`
  min-width: 32px;
  width: 32px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  border: 1px solid #d0d0d0;
  background-color: white;
  color: #4a4a4a;
  background-color: ${props => props.disabled && '#d3d2d8 !important'};
  color: ${props => props.disabled && '#747D7D !important'};
  margin-right: 16px;

  &:hover {
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
    background-color: white;
  }
`;

const IconContainer = styled.div`
  margin-right: 0px;

  i {
    margin-top: 1px;
    margin-left: 5px;
  }
`;

const TimestampFilteringButtonMobile = ({ timestampFilteringOpen, openTimestampFilterModal }) => {
  const { timestampFilteringList, userOnOffTimestamps } = useContext(TimestampFilteringContext);

  return (
    <TimestampFiltering
      open={timestampFilteringOpen}
      blue={userOnOffTimestamps || timestampFilteringList.findIndex(ts => !ts.visible) > -1}
      onClick={openTimestampFilterModal}
    >
      <IconContainer>
        <Icon type="filter-timestamps" />
      </IconContainer>
    </TimestampFiltering>
  );
};

export default TimestampFilteringButtonMobile;
