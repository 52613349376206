import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import Icon from '../../ui/Icon';

import AssetTree from './AssetTree.js';

import Select from 'antd/es/select';

import ButtonLight from '../../ui/ButtonLight';
import PublishModal from './PublishModal';

const LeftSidepanel = styled.div`
  width: 190px;
  height: auto;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonWrapper = styled.div`
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  visibility: ${props => (props.enabled ? 'visible' : 'hidden')};
  &:hover {
    color: #d0011c;
  }
`;

const GeoAssetToolLeftPanel = () => {
  const { namespace, namespaces } = useContext(UserContext);
  const { getAssetsAndTypes, currentNamespace, globalSave, assetDataHistory, assetData } = useContext(
    GeoAssetToolContext
  );
  const { t } = useTranslation(namespace);

  const [geoAssetToolPublish, setGeoAssetToolPublish] = useState(false);

  const refactorNameSpaces = namespaceData => {
    const namespaceCopy = [];
    for (let index = 0; index < namespaceData.length; index++) {
      const ns = namespaceData[index];
      ns.value = ns.key;
      delete ns.locked;
      namespaceCopy.push(ns);
    }
    return namespaceCopy;
  };

  const [usedNamespaceData] = useState(refactorNameSpaces([...namespaces]));

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const handeleNamespaceChange = value => {
    getAssetsAndTypes(value);
  };

  return (
    <LeftSidepanel>
      <TopWrapper>
        <Select
          showSearch
          placeholder={t('Select port')}
          optionFilterProp="label"
          onChange={e => {
            handeleNamespaceChange(e);
          }}
          style={{ minWidth: '190px' }}
          options={usedNamespaceData}
          value={currentNamespace}
        />
        <SaveButtonWrapper enabled={globalSave}>
          <ButtonLight
            type="button"
            style={{ marginBottom: '0px' }}
            onClick={() => {
              setGeoAssetToolPublish(true);
            }}
          >
            <Icon type="world" />
            {t('Publish')}
          </ButtonLight>
        </SaveButtonWrapper>
      </TopWrapper>
      {geoAssetToolPublish && (
        <PublishModal
          assetDataHistory={assetDataHistory}
          assetData={assetData}
          closeModal={() => setGeoAssetToolPublish(false)}
        />
      )}
      {currentNamespace ? <AssetTree /> : null}
    </LeftSidepanel>
  );
};

export default GeoAssetToolLeftPanel;
