import React from 'react';

import LogoImage from '../../images/logo.svg?react';
import LogoImageRauma from '../../images/logo-rauma.svg?react';
import LogoImageGavle from '../../images/logo-gavle.svg?react';

const Logo = ({ port }) => {
  switch (port) {
    case 'rauma':
      return <LogoImageRauma alt="Port Activity App" />;
    case 'gavle':
      return <LogoImageGavle alt="Port Activity App" />;
    default:
      return <LogoImage alt="Port Activity App" />;
  }
};

export default Logo;
