import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Tooltip from 'antd/es/tooltip';

import Icon from '../../../components/ui/Icon';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

const SidebarContainer = styled.div`
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px -1px 1px 0px rgb(0 0 0 / 20%);
  margin-right: 2px;
`;

const SidebarParentLine = styled.div`
  padding: 0 4px;
  display: flex;
  justify-content: flex-end;
`;

const SidebarChildLine = styled.div`
  padding: 0 4px;
  display: flex;
  justify-content: flex-end;
`;

const NotPlannedLine = styled.div`
  background-color: white;
`;

const SidebarBerth = styled.div`
  margin: 0 3px;
`;

const IconContainer = styled.div`
  line-height: 21px;
  height: 24px;
  width: 14px;
`;

const BerthName = styled.div`
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-size: 11px;
  text-transform: uppercase;
  margin-right: 5px;
  font-weight: 700;
`;

const ChildName = styled.div`
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-size: 11px;
  margin-right: 5px;
`;

export const Sidebar = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    openRow,
    closeRow,
    sidebarWidth,
    berths,
    berthsOpen,
    collapsedRowHeight,
    rowHeight,
    berthAreasAvailable,
    dynamicBollards,
  } = useContext(BerthPlanningToolContext);

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  const sidebarStyle = {
    width: `${sidebarWidth}px`,
    minHeight: `${outerComponentHeight}px`,
  };

  const berthsStyle = {
    width: `${sidebarWidth}px`,
  };

  const getNotPlannedLines = (count, open) => {
    const notPlannedStyle = {
      height: `${open ? 2 * rowHeight : 2 * collapsedRowHeight}px`,
      lineHeight: `${open ? 2 * rowHeight : 2 * collapsedRowHeight}px`,
    };

    let rows = [];
    for (let i = 0; i < count; i++) {
      rows.push(
        <NotPlannedLine key={`sidebar-not-planned-${i}`} style={notPlannedStyle}>
          {open && i === 0 && <ChildName>{t('Not Planned')}</ChildName>}
        </NotPlannedLine>
      );
    }

    return rows;
  };

  let berthLines = berths.map((berth, index) => {
    const elementStyle = {
      height: `${rowHeight}px`,
      lineHeight: `${rowHeight}px`,
    };

    const open = berthsOpen[berth.id];

    const childStyle = rowCount => {
      return {
        height: `${
          open
            ? berthAreasAvailable || dynamicBollards
              ? 2 * rowCount * rowHeight
              : rowHeight
            : berthAreasAvailable || dynamicBollards
              ? 2 * rowCount * collapsedRowHeight
              : collapsedRowHeight
        }px`,
        lineHeight: `${open ? rowHeight : collapsedRowHeight}px`,
      };
    };

    const hasSubRows = !!berth.subRows;

    if (!berth.show) {
      return null;
    }

    return (
      <SidebarBerth key={`sidebar-berth-${berth.id}`}>
        <SidebarParentLine
          even={index % 2 === 0}
          style={elementStyle}
          onClick={open ? () => closeRow(berth.id) : () => openRow(berth.id)}
        >
          <Tooltip title={berth.name} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            <BerthName>{berth.id === 'unknown' ? 'No berth' : berth.short_name}</BerthName>
          </Tooltip>

          {hasSubRows ? (
            open ? (
              <IconContainer>
                <Icon type="chevron-down" />
              </IconContainer>
            ) : (
              <IconContainer>
                <Icon type="chevron-right-2" />
              </IconContainer>
            )
          ) : (
            <IconContainer />
          )}
        </SidebarParentLine>
        {hasSubRows &&
          berth.subRows.map(b => (
            <SidebarChildLine key={`sidebar-subRow-${b.id}`} style={childStyle(b.rowCount)}>
              {open && <ChildName>{b.short_name}</ChildName>}
            </SidebarChildLine>
          ))}
        {getNotPlannedLines(berth.notPlannedCount, open)}
      </SidebarBerth>
    );
  });

  return (
    <SidebarContainer style={sidebarStyle}>
      <div style={berthsStyle}>{berthLines}</div>
    </SidebarContainer>
  );
};
