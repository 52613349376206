import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { WeatherContext } from '../../context/WeatherContext';
import { UserContext } from '../../context/UserContext';

import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';
import WeatherText from '../ui/WeatherText';
import { getWindType } from './weatherUtils';
import WeatherStationDropdown from './WeatherStationDropdown';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const PortWeatherContainer = styled.div`
  font-size: 14px;
  margin-left: 40px;
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-left: 6px;
  }
`;

const BigIconContainer = styled.div`
  svg {
    width: 60px;
    height: 60px;
    margin-top: -8px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    svg {
      width: 42px;
      height: 42px;
      margin-top: -4px;
    }
  }
`;

const TempContainer = styled.div`
  color: #c4c4c4;
  display: flex;
`;

const Temp = styled.div`
  font-size: 18px;
  margin: -4px 0 0 5px;
  color: #ffffff;
  span {
    font-size: 14px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
    font-size: 15px;
    margin-top: -1px;
    margin-left: 0px;
  }
`;

const WindContainer = styled.div`
  display: flex;
  color: #c4c4c4;
  margin-left: 10px;

  svg {
    width: 16px;
    margin-top: -3px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
  }
`;

const WindLabel = styled.div`
  margin-right: 10px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
  }
`;

const WindInfo = styled.div`
  color: #ffffff;
  margin-left: 10px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    color: #4a4a4a;
  }
`;

const Content = styled.div`
  margin-left: 50px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-left: 32px;
  }
`;

const TempWindContainer = styled.div`
  display: flex;
`;

const StyledWeatherText = styled(WeatherText)`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const HeaderWeather = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { currentWeather } = useContext(WeatherContext);

  return (
    <PortWeatherContainer>
      <Link to="/port-weather">
        <BigIconContainer>
          <WeatherIconCombined type={currentWeather.description} descriptionObj={currentWeather.description_object} />
        </BigIconContainer>
      </Link>
      <Content>
        <Link to="/port-weather">
          <TempWindContainer>
            <TempContainer>
              <StyledWeatherText
                type={currentWeather.description}
                style={{ cursor: 'pointer' }}
                descriptionText={
                  currentWeather.description_object ? currentWeather.description_object.description_text : null
                }
              />{' '}
              <Temp>
                {currentWeather.temperature || currentWeather.temperature === 0
                  ? Math.round(currentWeather.temperature)
                  : '-'}{' '}
                <span>°C</span>
              </Temp>
            </TempContainer>
            <WindContainer>
              <WindLabel>{t('Wind')}</WindLabel>
              <WeatherIcon
                type={
                  currentWeather.wind_speed || currentWeather.wind_speed === 0
                    ? getWindType(currentWeather.wind_speed)
                    : null
                }
                rotation={currentWeather.wind_direction || 0}
                style={{ cursor: 'pointer' }}
              />
              <WindInfo>
                {currentWeather.wind_speed || currentWeather.wind_speed === 0
                  ? Math.round(currentWeather.wind_speed)
                  : '-'}{' '}
                m/s
              </WindInfo>
            </WindContainer>
          </TempWindContainer>
        </Link>
        <WeatherStationDropdown />
      </Content>
    </PortWeatherContainer>
  );
};

export default HeaderWeather;
