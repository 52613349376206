import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationContext } from '../../context/NotificationContext';
import { UserContext } from '../../context/UserContext';

import List from 'antd/es/list';
import Tabs from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';

import NotificationListItem from './NotificationListItem';

const NotificationsList = ({ selectedTab, setSelectedTab, notifications, loading, notificationChildren }) => {
  const { namespace, portName } = useContext(UserContext);
  const { notificationReplyOpen, selectedImo } = useContext(NotificationContext);

  const { t } = useTranslation(namespace);

  if (!loading && !notificationReplyOpen) {
    window.scrollTo(0, 0);
  }

  const imoFilteredNotifications = selectedImo
    ? notifications.filter(item => item.ship_imo === selectedImo)
    : notifications;

  const handleTabChange = e => {
    setSelectedTab(e);
  };

  const tabItems = [
    {
      label: t('All notifications'),
      children: (
        <List
          split={false}
          itemLayout="horizontal"
          dataSource={notifications}
          loading={notificationReplyOpen ? false : loading}
          renderItem={item => (
            <List.Item>
              <NotificationListItem data={item} notificationChildren={notificationChildren} />
            </List.Item>
          )}
        />
      ),
      key: '1',
    },
    {
      label: <Tooltip title={t('Notifications related to a single vessel.')}>{t('Ship notifications')}</Tooltip>,
      children: (
        <List
          split={false}
          itemLayout="horizontal"
          dataSource={imoFilteredNotifications.filter(
            item => item.type === 'ship' || item.type === 'port_call_decision'
          )}
          loading={notificationReplyOpen ? false : loading}
          renderItem={item => (
            <List.Item>
              <NotificationListItem data={item} notificationChildren={notificationChildren} />
            </List.Item>
          )}
        />
      ),
      key: '2',
    },
    {
      label: (
        <Tooltip title={t('Notification related to all actors at Port of {{portname}}.', { portname: portName })}>
          {t('Port notifications')}
        </Tooltip>
      ),
      children: (
        <List
          split={false}
          itemLayout="horizontal"
          dataSource={notifications.filter(item => item.type === 'port')}
          loading={notificationReplyOpen ? false : loading}
          renderItem={item => (
            <List.Item>
              <NotificationListItem data={item} notificationChildren={notificationChildren} />
            </List.Item>
          )}
        />
      ),
      key: '3',
    },
  ];

  return (
    <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange} animated={false} size="large" items={tabItems} />
  );
};

export default NotificationsList;
