import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import TransitionIcon from '../../images/TransitionActivityView.svg?react';

import { TIME_FORMAT } from '../../utils/constants';

const IconContainer = styled.span`
  border: none;

  svg {
    height: 16px;
    width: 26px;
    margin-bottom: -4px;
  }
`;

const TooltipBackground = styled.div`
  padding: 5px;
  display: block;
`;

const NameRow = styled.div`
  color: black;
  font-size: 12px;
  margin-bottom: 6px;
`;

const Transitions = styled.div`
  display: flex;
`;

const TransitionContainer = styled.div`
  background-color: #a5d6a7;
  border-radius: 3px;
  padding: 8px;
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }
`;

const Text = styled.div`
  font-size: 11px;
  color: black;
  white-space: nowrap;
`;

const BoldedText = styled(Text)`
  font-weight: 700;
`;

const TransitionTooltip = ({ vessel_name, transitions, bollard, pta, ptd }) => (
  <TooltipBackground>
    <NameRow>{vessel_name}</NameRow>
    <Transitions>
      {transitions.map((transition, i) => (
        <TransitionContainer key={i}>
          <BoldedText>{transition.berth_short_name || transition.berth_name}</BoldedText>
          <Text>
            {bollard} {transition.start_bollard_short_name || transition.start_bollard} -{' '}
            {transition.end_bollard_short_name || transition.end_bollard}
          </Text>
          <Text>{transition.agent}</Text>
          <Text>
            {pta}: <span style={{ fontWeight: 700 }}>{dayjs(transition.pta).format(TIME_FORMAT)}</span>
          </Text>
          <Text>
            {ptd}: <span style={{ fontWeight: 700 }}>{dayjs(transition.ptd).format(TIME_FORMAT)}</span>
          </Text>
        </TransitionContainer>
      ))}
    </Transitions>
  </TooltipBackground>
);

const Transition = ({ transitions, vessel_name }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return transitions ? (
    <Tooltip
      title={
        <TransitionTooltip
          vessel_name={vessel_name}
          transitions={transitions}
          bollard={t('Bollard')}
          pta={t('PTA')}
          ptd={t('PTD')}
        />
      }
      placement="bottom"
      color="white"
      overlayInnerStyle={{ display: 'inline-block' }}
    >
      <IconContainer>
        <TransitionIcon />
      </IconContainer>
    </Tooltip>
  ) : null;
};

export default Transition;
