import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Spin from 'antd/es/spin';

import ButtonLight from '../../components/ui/ButtonLight';
import Input from '../../components/ui/Input';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';

const AdminQueueSettings = () => {
  const { loading, data, error, fetchData } = useApi('get', 'settings');

  const { apiCall, namespace, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();

  const [apiCallPending, setApiCallPending] = useState(false);

  let initSettings = {
    loaded: false,
    portOperatorEmails: '',
  };

  const [settings, setSettings] = useState(initSettings);

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  if (!loading && !settings.loaded) {
    let obj = data.find(element => Object.prototype.hasOwnProperty.call(element, 'port_operator_emails'));
    let portOperatorEmails = obj ? obj.port_operator_emails : '';

    let loaded = true;

    setSettings({
      loaded,
      portOperatorEmails,
    });
  }

  const handleSave = async () => {
    setApiCallPending(true);
    let portOperatorEmails = settings.portOperatorEmails;
    if (portOperatorEmails === '') {
      portOperatorEmails = 'empty';
    }

    try {
      await apiCall('put', `settings/port_operator_emails/${portOperatorEmails}`);
    } catch (e) {
      await fetchData(false);
      setSettings(initSettings);
      setApiCallPending(false);
      throw e;
    }

    await fetchData(false);
    setSettings(initSettings);
    setApiCallPending(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleChange = e => {
    settings[e.target.name] = e.target.value;
    setSettings({ ...settings });
  };

  return (
    <Layout pagename={t('Queue Settings')}>
      <Spin spinning={loading || apiCallPending}>
        <Page fullWidth style={{ paddingTop: '48px' }}>
          <Input
            style={{ width: '500px' }}
            label={t(
              'Port operator emails (comma separated list). (Live ETA alerts and automatic berth block shifts are sent to these emails.)'
            )}
            name="portOperatorEmails"
            value={settings.portOperatorEmails}
            onChange={handleChange}
          />
          <br></br>
          <ButtonLight style={{ marginRight: '12px' }} type="button" cancel onClick={() => handleCancel()}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight type="button" onClick={() => handleSave()}>
            {t('Send')}
          </ButtonLight>
        </Page>
      </Spin>
    </Layout>
  );
};

export default AdminQueueSettings;
