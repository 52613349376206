import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import { UserContext } from '../../../context/UserContext';
import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 144px;
`;

const Row = styled.div`
  display: flex;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / 3);
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

const AddOrEditReservationModal = ({ type, closeModal, reservation }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sendAddReservation, sendUpdateReservation, cranes } = useContext(CranePlanningToolContext);

  const [inputData, setInputData] = useState({
    crane_id: reservation ? reservation.crane_id : undefined,
    notes: reservation ? reservation.notes : undefined,
    start_time: reservation ? reservation.start_time : undefined,
    end_time: reservation ? reservation.end_time : undefined,
  });

  const [sending, setSending] = useState(false);

  const title = type === 'new' ? t('Add new reservation') : t('Edit reservation');

  const dataIsValid = () => {
    return inputData.notes && inputData.crane_id && inputData.start_time && inputData.end_time;
  };

  const updateNotes = value => {
    setInputData({ ...inputData, notes: value.target.value });
  };

  const handleCraneChange = value => {
    setInputData({ ...inputData, crane_id: value });
  };

  const onStartTimeChange = value => {
    setInputData({ ...inputData, start_time: value || null });
  };

  const onEndTimeChange = value => {
    setInputData({ ...inputData, end_time: value || null });
  };

  const sendData = async () => {
    setSending(true);

    let result;
    try {
      if (type === 'new') {
        result = await sendAddReservation({ ...inputData });
      } else {
        result = await sendUpdateReservation({ ...inputData, id: reservation.id });
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal(true);
    } else {
      setSending(false);
    }
  };

  return (
    <Modal title={title} open={!!type} width={600} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <ScrollContainer>
          <Row>
            <RowContainer>
              <RowHeader>
                {t('Crane')}
                <Required>*</Required>
              </RowHeader>
              <Select
                showSearch
                placeholder={t('Crane name')}
                optionFilterProp="label"
                onChange={handleCraneChange}
                style={{ width: '100%' }}
                options={cranes.map(c => {
                  return { label: c.name, value: c.id };
                })}
                value={inputData.crane_id || undefined}
              />
            </RowContainer>
            <RowContainer>
              <RowHeader>
                {t('Start')}
                <Required>*</Required>
              </RowHeader>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                defaultValue={inputData.start_time ? dayjs(inputData.start_time) : null}
                style={{
                  width: '100%',
                }}
                onChange={onStartTimeChange}
                onSelect={onStartTimeChange}
                placeholder={t('Reservation start')}
              />
            </RowContainer>
            <RowContainer style={{ marginRight: '0px' }}>
              <RowHeader>
                {t('End')}
                <Required>*</Required>
              </RowHeader>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                defaultValue={inputData.end_time ? dayjs(inputData.end_time) : null}
                style={{
                  width: '100%',
                }}
                onChange={onEndTimeChange}
                onSelect={onEndTimeChange}
                placeholder={t('Reservation end')}
              />
            </RowContainer>
          </Row>
          <Row>
            <RowContainer style={{ width: '100%', marginRight: '0px' }}>
              <RowHeader>
                {t('Notes')}
                <Required>*</Required>
              </RowHeader>
              <Input placeholder={t('Notes')} value={inputData.notes || ''} onChange={updateNotes} />
            </RowContainer>
          </Row>
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
            {type === 'new' ? t('Reserve') : t('Save')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default AddOrEditReservationModal;
