export const headerHeight = 56;
export const maxRowHeightConstant = 24;
export const minRowHeightConstant = 3;
export const fleetRowHeightConstant = 28;
export const fleetVesselRowHeightConstant = 26;
export const craneRowHeightConstant = 48;
export const collapsedRowHeightConstant = 7;

export const color_planned = '#A5D6A7';
export const color_not_planned = '#A7C4F4';
export const color_uncommitted = '#FFE77C';
export const color_reserved = '#FFAB91';
export const color_updated = '#d5b5eb';

export const lighter_color_not_planned = '#fff4cf';
export const lighter_color_planned = '#c7f0c9';

export const sidebarWidth = 200;
export const smallSidebarWidth = 170;

export const sideModalWidth = 320;
