import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import Draggable from 'react-draggable';

import versionData from '../version';

import { UserContext } from '../context/UserContext';
import { TimestampContext } from '../context/TimestampContext';

import Icon from '../components/ui/Icon';
import { darken } from 'polished';
import { mobilePixelMaxWidthLimit } from '../utils/constants';
import { AlertContext } from '../context/AlertContext';

const sliderWidth = '210px';

const Navigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 997;
  height: calc(100vh - 64px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);

  @media print {
    display: none;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const NavContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
`;

const Container = styled.div`
  display: flex;
`;

const BottomInfo = styled.div`
  margin-top: 32px;
  padding: 3px 42px;
  color: ${({ theme }) => theme.color.grey_light2};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.color.grey_light2)};
  }
`;

const Slider = styled.div`
  height: 100%;
  z-index: 4;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  width: ${props => (props.open ? sliderWidth : 0)};
  transition: width 0.3s;
  padding-top: 44px;
`;

const StaticList = styled.div`
  width: ${props =>
    'calc(' + props.menuWidth + (props.scrollbarWidth ? ' - ' + props.scrollbarWidth + 'px' : '') + ')'};
  height: 100%;
  position: relative;
  z-index: 5;
  padding-top: 44px;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  transition: width 0.3s;
`;

const OpenClose = styled.div`
  background-color: white;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.grey_light2};
  position: absolute;
  top: 12px;
  right: -9px;
  height: 21px;
  width: 21px;
  cursor: pointer;
  z-index: 4;
  &:hover {
    background-color: #4990dd;
  }
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -2px;
  &:hover {
    color: white;
  }
  svg {
    height: 12px;
    width: 12px;
  }
`;

const FolderIcon = styled.div`
  position: absolute;
  right: 28px;
  top: 10px;
  line-height: 21px;
  margin-left: 3px;
  margin-top: -2px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const BaseMenuItem = styled.div`
  height: 38px;
  background-color: ${props => (props.current ? props.theme.color.secondary : 'transparent')};
  margin-bottom: 12px;
  padding-left: 10px;
  color: ${props => (props.current ? props.theme.color.white : props.theme.color.grey_dark)};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const MenuItem = styled(BaseMenuItem)`
  margin-left: 8px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-top: 6px;
  margin-right: -1px;
  margin-top: ${props => props.sub && '-12px'};
  background-color: ${props => props.hover && !props.current && '#eaeaea'};

  i {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 100%;
  }
`;

const SliderMenuItem = styled(BaseMenuItem)`
  padding-top: 8px;
  width: 100%;
  margin-top: ${props => props.sub && '-12px'};
  border-bottom-left-radius: ${props => props.sub && '4px'};
  border-top-left-radius: ${props => props.sub && '4px'};
  background-color: ${props => props.hover && !props.current && '#eaeaea'};

  &:hover {
    background-color: ${props => props.sub && '#eaeaea'};
  }
`;

const VersionNumber = styled.span``;
const VersionBuild = styled.span``;
const VersionTimestamp = styled.span`
  display: block;
  font-style: italic;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 1;
`;

const DraggingHoverLine = styled.div`
  height: 100%;
  z-index: 3;
  width: 2px;
  top: 0px;
  right: 11px;
  position: absolute;
  transition: background-color 0.5s;
`;

const DraggingLine = styled.div`
  background-color: transparent;
  width: 12px;
  top: 0px;
  right: -12px;
  position: absolute;
  height: 100%;
  z-index: 3;
  cursor: ${props => props.both && 'ew-resize'};
  cursor: ${props => props.right && 'e-resize'};
  cursor: ${props => props.left && 'w-resize'};

  &:hover {
    ${DraggingHoverLine} {
      background-color: #4990dd;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
    }
  }
`;

const SideMenu = ({ menuWidth, navStructure, setNavStructure, setMenuWidth }) => {
  const { namespace, logout, navScrollbarWidth: scrollbarWidth } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const { setSearch } = useContext(TimestampContext);

  const { version, build, ts } = versionData;

  const [navOpen, setNavOpen] = useState(false);
  const [navTotallyOpen, setNavTotallyOpen] = useState(false);

  const navOpenRef = useRef(false);
  const draggableRef = React.useRef(null);

  const [oneDrag, setOneDrag] = useState(false);
  const [folderHover, setFolderHover] = useState(undefined);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!navOpenRef.current && navOpen) {
      setTimeout(() => {
        setNavTotallyOpen(true);
      }, 300);
    }

    if (navOpenRef.current && !navOpen) {
      setNavTotallyOpen(false);

      let newStructure = navStructure.map(s => {
        if (s.open !== undefined) {
          return { ...s, open: false };
        } else {
          return s;
        }
      });

      setNavStructure(newStructure);
    }

    navOpenRef.current = navOpen;
  }, [navOpen, navStructure, setNavStructure]);

  const currentPath = location.pathname.includes(namespace)
    ? location.pathname.substring(1 + namespace.length, location.pathname.length)
    : location.pathname.substring(0, location.pathname.length);

  const handleClick = path => {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('vessel-notification-')) {
        localStorage.removeItem(key);
      }
    });
    if (path === '/') {
      setSearch('');
    }
    history.push(path);
  };

  const toggleFolder = (event, index) => {
    const newStructure = [...navStructure];
    if (!newStructure[index].open) {
      setNavOpen(true);
    }
    newStructure[index].open = !newStructure[index].open;
    setNavStructure(newStructure);
    event.stopPropagation();
  };

  const goToFirst = index => {
    if (navStructure[index].links.length > 0) {
      const link = navStructure[index].links.find(l => l.checks);

      if (link) {
        history.push(link.path);
      }
    }
  };

  const handleLogout = () => {
    history.entries = [];
    history.index = -1;
    history.push('/');
    logout();
  };

  const openComponent = () => {
    return (
      <OpenClose
        onClick={() => {
          if (!navOpen) {
            if (menuWidth === '16px') {
              setMenuWidth('62px');
            } else {
              setNavOpen(!navOpen);
              setMenuWidth('62px');
            }
          } else {
            setNavOpen(!navOpen);
          }
        }}
      >
        {navOpen ? (
          <IconContainer aria-label="close-side-menu-icon">
            <RotatedIcon type="chevron-right-2" />
          </IconContainer>
        ) : (
          <IconContainer aria-label="open-side-menu-icon">
            <Icon type="chevron-right-2" />
          </IconContainer>
        )}
      </OpenClose>
    );
  };

  const onDrag = (i, position) => {
    if (!oneDrag) {
      if (menuWidth === '16px') {
        if (position.x > 10) {
          setMenuWidth('62px');
          setOneDrag(true);
        }
      } else if (menuWidth === '62px' && !navOpen) {
        if (position.x > 10) {
          setNavOpen(true);
          setOneDrag(true);
        } else if (position.x < -10) {
          setMenuWidth('16px');
          setOneDrag(true);
        }
      } else if (menuWidth === '62px' && navOpen) {
        if (position.x < -10) {
          setNavOpen(false);
          setOneDrag(true);
        }
      }
    }
  };

  const onStop = () => {
    setOneDrag(false);
  };

  return (
    <Navigation data-id="navigation" alertsHeight={alertsHeight}>
      {openComponent()}
      {navOpen && <Blocker onClick={() => setNavOpen(false)} />}
      <NavContainer navTotallyOpen={navTotallyOpen} data-id="navigation-container" id="navigation-container">
        <Container>
          <StaticList menuWidth={menuWidth} scrollbarWidth={scrollbarWidth}>
            {navStructure.map((item, index) => {
              if (item.type === 'link' && item.checks) {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleClick(item.path)}
                    current={item.path === currentPath}
                    onMouseEnter={() => setFolderHover(index)}
                    onMouseLeave={() => setFolderHover(undefined)}
                    hover={index === folderHover}
                  >
                    <Tooltip
                      placement="right"
                      title={t(item.title)}
                      color="white"
                      overlayInnerStyle={{ color: '#4a4a4a' }}
                    >
                      <div>
                        <Icon type={item.icon} />
                      </div>
                    </Tooltip>
                  </MenuItem>
                );
              } else if (item.type === 'folder' && item.links && item.links.findIndex(l => l.checks) > -1) {
                let current = false;

                if (
                  item.links.findIndex(
                    l => l.path === currentPath || l.alternativePaths?.findIndex(p => p === currentPath) > -1
                  ) > -1
                ) {
                  current = true;
                }

                return (
                  <div key={index}>
                    <MenuItem
                      onClick={e => (item.type === 'folder' ? goToFirst(index) : toggleFolder(e, index))}
                      current={current}
                      onMouseEnter={() => setFolderHover(index)}
                      onMouseLeave={() => setFolderHover(undefined)}
                      hover={index === folderHover}
                    >
                      <Tooltip
                        placement="right"
                        title={t(item.title)}
                        color="white"
                        overlayInnerStyle={{ color: '#4a4a4a' }}
                      >
                        <div>
                          <Icon type={item.icon} />
                        </div>
                      </Tooltip>
                    </MenuItem>
                    {item.open && (
                      <>
                        {item.links.map((subItem, i) => {
                          if (subItem.checks) {
                            if (subItem.type === 'sign-out') {
                              return <MenuItem sub={true} key={i} onClick={handleLogout} />;
                            } else {
                              return <MenuItem sub={true} key={i} onClick={() => handleClick(subItem.path)} />;
                            }
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </StaticList>
          <Slider data-id="nav-slider" open={navOpen}>
            {navStructure.map((item, index) => {
              if (item.type === 'link' && item.checks) {
                return (
                  <SliderMenuItem
                    key={index}
                    onClick={() => handleClick(item.path)}
                    current={item.path === currentPath}
                    onMouseEnter={() => setFolderHover(index)}
                    onMouseLeave={() => setFolderHover(undefined)}
                    hover={index === folderHover}
                  >
                    {t(item.title)}
                  </SliderMenuItem>
                );
              } else if (item.type === 'folder' && item.links && item.links.findIndex(l => l.checks) > -1) {
                let current = false;

                if (
                  item.links.findIndex(
                    l => l.path === currentPath || l.alternativePaths?.findIndex(p => p === currentPath) > -1
                  ) > -1
                ) {
                  current = true;
                }

                return (
                  <div key={index}>
                    <SliderMenuItem
                      current={current}
                      onClick={e => toggleFolder(e, index)}
                      onMouseEnter={() => setFolderHover(index)}
                      onMouseLeave={() => setFolderHover(undefined)}
                      hover={index === folderHover}
                    >
                      {t(item.title)}
                      {item.open ? (
                        <FolderIcon>
                          <Icon type="chevron-down" />
                        </FolderIcon>
                      ) : (
                        <FolderIcon>
                          <Icon type="chevron-right-2" />
                        </FolderIcon>
                      )}
                    </SliderMenuItem>
                    {item.open && (
                      <>
                        {item.links.map((subItem, i) => {
                          if (subItem.checks) {
                            if (subItem.type === 'sign-out') {
                              return (
                                <SliderMenuItem sub={true} key={i} onClick={handleLogout}>
                                  {t(subItem.title)}
                                </SliderMenuItem>
                              );
                            } else {
                              return (
                                <SliderMenuItem sub={true} key={i} onClick={() => handleClick(subItem.path)}>
                                  {t(subItem.title)}
                                </SliderMenuItem>
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Slider>
        </Container>
        {navTotallyOpen && (
          <BottomInfo onClick={() => history.push('/release-notes')}>
            <VersionNumber>{version}</VersionNumber>,{' '}
            <VersionBuild>
              {t('Build')} {build}
            </VersionBuild>
            <VersionTimestamp>{ts}</VersionTimestamp>
          </BottomInfo>
        )}
      </NavContainer>
      <Draggable axis="x" onStop={onStop} onDrag={onDrag} position={{ x: 0, y: 0 }} nodeRef={draggableRef}>
        <DraggingLine
          ref={draggableRef}
          data-testid={'nav-dragging-line'}
          both={menuWidth === '62px' && !navOpen}
          left={menuWidth === '62px' && navOpen}
          right={menuWidth === '16px'}
        >
          <DraggingHoverLine />
        </DraggingLine>
      </Draggable>
    </Navigation>
  );
};

export default SideMenu;
