import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import DataExportListing from '../components/dataExport/DataExportListing';

const StyledPage = styled(Page)`
  @media (max-width: 850px) {
    padding: 1rem;
  }
`;

const DataExport = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Data Export | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Data Export')}>
      <StyledPage fullWidth>
        <DataExportListing />
      </StyledPage>
    </Layout>
  );
};

export default DataExport;
