import React, { useContext } from 'react';
import styled from 'styled-components';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';

import { CalendarRow } from './CalendarRow';

const RowLines = styled.div`
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
`;

export const CalendarRows = () => {
  const { vessels = [] } = useContext(FleetVesselViewContext);

  return (
    <RowLines>
      {vessels.map((vessel, i) => {
        return <CalendarRow key={i} index={i} vessel={vessel} />;
      })}
    </RowLines>
  );
};
