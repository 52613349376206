import React, { useContext } from 'react';
import styled from 'styled-components';

import Card from './Card';
import TimestampGroup from './TimestampGroup';
import Timestamp from './Timestamp';
import { AlertContext } from '../../context/AlertContext';
import Icon from '../ui/Icon';

const Container = styled.div`
  width: 330px;
  padding: 6px;
  height: calc(100vh - 140px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow-y: auto;
  background-color: #f2f2f2;
  border-radius: 4px;

  > div {
    margin-right: 0px;
  }

  @media (max-width: 800px) {
    position: absolute;
    z-index: 998;
    margin-left: calc((100vw - 330px) / 2);
  }
`;

const TopRow = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled.div`
  margin-right: 4px;
  padding-left: 2px;
  border-radius: 3px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Background = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const Timestamps = styled.div``;

const SideTimestamp = ({
  notifications,
  decisions,
  serviceOrders,
  jitForVessel,
  data,
  cardOpen,
  transitions,
  reloadServiceOrderData,
  hasWarnings,
  portcalls,
  closePanel,
}) => {
  const { alertsHeight } = useContext(AlertContext);

  let timestampList = [];
  portcalls[0].events.forEach(event => {
    event.timestamps.forEach(ts => timestampList.push(ts));
  });

  return (
    <>
      <Background />
      <Container alertsHeight={alertsHeight}>
        <TopRow>
          <CloseIcon onClick={closePanel}>
            <Icon type="close" />
          </CloseIcon>
        </TopRow>
        <Card
          notifications={notifications}
          decisions={decisions}
          serviceOrders={serviceOrders}
          jitForVessel={jitForVessel}
          data={data}
          cardOpen={cardOpen}
          transitions={transitions}
          reloadServiceOrderData={reloadServiceOrderData}
          hasWarnings={hasWarnings}
          noScale={true}
        />
        <Timestamps>
          <TimestampGroup style={{ width: '290px', marginLeft: '6px', marginTop: 0 }}>
            {timestampList ? timestampList.map((timestamp, index) => <Timestamp data={timestamp} key={index} />) : null}
          </TimestampGroup>
        </Timestamps>
      </Container>
    </>
  );
};

export default SideTimestamp;
