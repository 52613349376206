import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import SeaPassageMap from './SeaPassageMap';

import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { PublicProvider } from '../../context/PublicContext';

const Wrapper = styled.div`
  z-index: 200;
  ${props => {
    if (props.mapSize === 1) {
      return `
        position: absolute;
        padding-left: 0px;
        width: 100%;
        height: 180px;
      `;
    } else if (props.mapSize === 2) {
      return `
        position: fixed;
        margin-top: 40px;
        margin-left: 4px;
        width: calc(100% - 247px);
        height: calc(100% - 312px);
        bottom: 2px;
        right: 2px;
      `;
    }
  }}
`;

const MapContainer = styled.div`
  position: relative;
  width: ${props => (props.mapSize > 1 ? '100%' : '286px')};
  height: ${props => (props.mapSize > 1 ? '100%' : '180px')};
  z-index: ${props => (props.mapSize > 1 ? 115 : 70)};
  box-shadow: ${props => (props.mapSize > 1 ? '0px 0px 15px lightgray' : '0')};
`;

const ButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 25px;
  z-index: 71;
  flex: 1;
  position: absolute;
  bottom: ${props => (props.buttonMargin ? '20px' : '6px')};
  right: 11.5px;
`;

const MapButton = styled(Button)`
  z-index: 1;
  width: 28px;
  height: 28px;
  margin: 0;
`;

const MapButtonClose = styled(Button)`
  z-index: 1;
  width: 10px;
  height: 10px;
  margin: 0;
  color: #4a4a4a;
`;

const CloseMapContainer = styled.div`
  z-index: 1;
  display: inline;
  position: absolute;
  right: 36px;
  top: 13px;
  width: 0;
  height: 0;
  padding-bottom: 20px;
  align-content: center;
`;

const HeaderContainer = styled.div`
  z-index: 1;
  position: absolute;
  flex: 1;
  display: ${props => (props.mapSize > 1 ? 'inline' : 'none')};
  width: ${props => (props.mapSize > 1 ? 'auto' : '0px')};
  height: ${props => (props.mapSize > 1 ? '56px' : '0px')};
  background-color: white;
  padding-left: 18px;
  padding-top: 15px;
  bottom: 2rem;
  right: 0px;
  left: 0px;
  top: -53px;
  border-radius: 4px;
  box-shadow: ${props => (props.mapSize > 1 ? '0px 0px 10px lightgray' : '0')};
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
`;

const SeaPassageMapResizableContainer = ({ seaPassage, showRoute, setShowSeaPassage, portcall }) => {
  const [mapSize, setMapSize] = useState(1); // small=1,medium=2,big=3
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const clickIcon = () => {
    if (mapSize === 1) {
      setMapSize(2);
    } else if (mapSize === 2) {
      setMapSize(1);
    }
  };

  const clickClose = () => {
    setMapSize(1);
  };

  return (
    <Wrapper mapSize={mapSize}>
      <MapContainer mapSize={mapSize}>
        <ButtonContainer buttonMargin={mapSize === 2}>
          <MapButton data-testid="map-expand" copy onClick={clickIcon}>
            <Icon type={mapSize === 1 ? 'arrow-expand-all' : 'arrow-collapse-all'} />
          </MapButton>
        </ButtonContainer>
        <HeaderContainer mapSize={mapSize} className="handle">
          {t('Sea passage')}
          <CloseMapContainer>
            <MapButtonClose copy onClick={clickClose} className="cancel">
              <Icon data-testid="map-close" type={'close'} style={{ width: '15px' }} />
            </MapButtonClose>
          </CloseMapContainer>
        </HeaderContainer>

        <PublicProvider>
          <SeaPassageMap
            showAttribution={mapSize > 1}
            buttonMargin={mapSize === 2}
            mapSize={mapSize}
            seaPassage={seaPassage}
            showRoute={showRoute}
            setShowSeaPassage={setShowSeaPassage}
            portcall={portcall}
          />
        </PublicProvider>
      </MapContainer>
    </Wrapper>
  );
};

export default SeaPassageMapResizableContainer;
