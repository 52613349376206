import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import WeatherIconCombined from '../components/ui/WeatherIconCombined';

const Container = styled.div`
  display: inline;
`;

const Header = styled.div`
  font-size: 17px;
`;

const WeatherContainer = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;
  width: 100px;
`;

const WeatherContainerSmall = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;
  width: 100px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const WeatherContainerBig = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;
  width: 100px;

  svg {
    width: 60px;
    height: 60px;
  }
`;

const IconContainerSmall = styled.div`
  border: 1px solid black;
  width: 22px;
  height: 22px;
  margin-bottom: 4px;
`;

const IconContainerBig = styled.div`
  border: 1px solid black;
  width: 62px;
  height: 62px;
  margin-bottom: 4px;
`;

const IconContainer = styled.div`
  border: 1px solid black;
  width: 39px;
  height: 39px;
  margin-bottom: 4px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Text = styled.div`
  font-size: 11px;
  word-wrap: break-word;
`;

const WeatherIcons = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const cloudiness = ['clear', 'mostly_clear', 'partly_clear', 'partly_cloudy', 'mostly_cloudy', 'overcast'];
  const raintypes = [
    'none',
    'light_snow',
    'moderate_snow',
    'heavy_snow',
    'light_rain',
    'moderate_rain',
    'heavy_rain',
    'light_rain_snow',
    'moderate_rain_snow',
    'heavy_rain_snow',
    'light_sleet',
    'moderate_sleet',
    'heavy_sleet',
    'light_freezing_rain',
    'moderate_freezing_rain',
    'heavy_freezing_rain',
    'fog',
  ];
  const thunder = ['none', 'light_thunderstorms', 'moderate_thunderstorms', 'heavy_thunderstorms'];

  useEffect(() => {
    document.title = 'Weather icons | Port Activity App';
  }, []);

  const getList = type => {
    let list = [];

    cloudiness.forEach(c => {
      raintypes.forEach(r => {
        thunder.forEach(t => {
          if ((c === 'clear' && r === 'none' && t === 'none') || c !== 'clear')
            list.push({
              base_type: type,
              cloudiness_type: c,
              description_text: type + '+' + c + '+' + r + '+' + t,
              rain_type: r,
              thunder_type: t,
            });
        });
      });
    });

    return list;
  };

  return (
    <Layout pagename={t('Weather Icons')}>
      <Page fullWidth>
        <Container>
          <Header>Small</Header>
          <WeatherContainerSmall>
            <IconContainerSmall>
              <WeatherIconCombined
                descriptionObj={{
                  base_type: 'moon',
                  cloudiness_type: 'mostly_clear',
                  description_text:
                    'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet_mostly_clear' + '+' + 'light_thunderstorms',
                  rain_type: 'heavy_sleet_mostly_clear',
                  thunder_type: 'light_thunderstorms',
                }}
              />
            </IconContainerSmall>
            <Text>
              {'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet_mostly_clear' + '+' + 'light_thunderstorms'}
            </Text>
          </WeatherContainerSmall>
          <Header>Big</Header>
          <List>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'mostly_clear',
                    description_text: 'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet' + '+' + 'none',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'none',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet' + '+' + 'none'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'mostly_clear',
                    description_text: 'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet_mostly_clear' + '+' + 'none',
                    rain_type: 'heavy_sleet_mostly_clear',
                    thunder_type: 'none',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet_mostly_clear' + '+' + 'none'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'overcast',
                    description_text: 'moon' + '+' + 'overcast' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'heavy_thunderstorms',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'overcast' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'mostly_cloudy',
                    description_text:
                      'moon' + '+' + 'mostly_cloudy' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'heavy_thunderstorms',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'mostly_cloudy' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'partly_cloudy',
                    description_text:
                      'moon' + '+' + 'partly_cloudy' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'heavy_thunderstorms',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'partly_cloudy' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'partly_clear',
                    description_text: 'moon' + '+' + 'partly_clear' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'heavy_thunderstorms',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'partly_clear' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms'}</Text>
            </WeatherContainerBig>
            <WeatherContainerBig>
              <IconContainerBig>
                <WeatherIconCombined
                  descriptionObj={{
                    base_type: 'moon',
                    cloudiness_type: 'mostly_clear',
                    description_text: 'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms',
                    rain_type: 'heavy_sleet',
                    thunder_type: 'heavy_thunderstorms',
                  }}
                />
              </IconContainerBig>
              <Text>{'moon' + '+' + 'mostly_clear' + '+' + 'heavy_sleet' + '+' + 'heavy_thunderstorms'}</Text>
            </WeatherContainerBig>
          </List>
          <Header>Sun</Header>
          <List>
            {getList('sun').map(l => (
              <WeatherContainer key={l.base_type + l.rain_type + l.cloudiness_type + l.thunder_type + Math.random()}>
                <IconContainer>
                  <WeatherIconCombined descriptionObj={l} />
                </IconContainer>
                <Text>{l.description_text}</Text>
              </WeatherContainer>
            ))}
          </List>
        </Container>
        <Container>
          <Header>Moon</Header>
          <List>
            {getList('moon').map(l => (
              <WeatherContainer key={l.base_type + l.rain_type + l.cloudiness_type + l.thunder_type + Math.random()}>
                <IconContainer>
                  <WeatherIconCombined descriptionObj={l} />
                </IconContainer>
                <Text>{l.description_text}</Text>
              </WeatherContainer>
            ))}
          </List>
        </Container>
      </Page>
    </Layout>
  );
};

export default WeatherIcons;
