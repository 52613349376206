import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import StatusList from '../components/status/StatusList';
import StatusEditList from '../components/status/StatusEditList';

const StatusPage = styled(Page)`
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
`;

const StatusView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Status | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Status')}>
      <StatusPage fullWidth>{namespace === 'unikie' ? <StatusEditList /> : <StatusList />}</StatusPage>
    </Layout>
  );
};

export default StatusView;
