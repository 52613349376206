import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Select from 'antd/es/select';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import DebounceAutocomplete from '../../ui/DebounceAutocomplete';

import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import PredictiveBerthPlanModalCranes from './PredictiveBerthPlanModalCranes';
import { getDuration } from '../../../utils/utils';
import ModalLinks from './ModalLinks';
import duration from 'dayjs/plugin/duration';
import { getPbpWorksFromData } from '../calendar/utility/contextHelpers';

dayjs.extend(duration);

const Blocker = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
`;

const ModalInner = styled.div`
  z-index: 999;
  position: fixed;
  top: 20px;
  left: max(59px, calc((100% - 1200px) / 2));
  margin-top: 100px;
  padding: 0;
  width: calc(100% - 118px);
  background-color: white;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  min-width: 300px;
`;

const SmallTitle = styled.div`
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 22px;
  color: #070145;
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 700;
`;

const CommoditiesTitle = styled.div`
  width: 100%;
  font-size: 14px;
  margin: 8px 10px;
  font-weight: 700;
  color: #7a7878;
`;

const ColumnsSplit = styled.div`
  display: block;
  width: 100%;
  padding-right: 9px;
  height: calc(100% - 72px);
  min-width: 800px;
`;

const UpperColumnsSplit = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  padding-left: 22px;
  display: flex;
  justify-content: space-between;
  height: 102px;
  margin-bottom: 8px;
`;

const SecondColumnSplit = styled.div`
  width: 100%;
  padding-left: 12px;
  max-height: calc(100vh - 470px);
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Planning = styled.div`
  display: inline-block;
  width: 100%;
  padding: 14px 0;
  margin-bottom: 6px;
`;

const PlanningContainer = styled.div`
  width: 100%;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 195px;
  padding-top: 55px;
`;

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
  width: 100%;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-right: 12px;
  margin-bottom: 14px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: 1px solid transparent;
    }
  }
`;

const ActionButtonsReadOnly = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-right: 12px;
  margin-bottom: 14px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const InfoRowSplit = styled.div`
  margin: 0 0 8px;
  width: auto;
  padding-right: 25px;
`;

const InfoContainer = styled.div`
  width: auto;
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
`;

const Info = styled.div`
  white-space: nowrap;
`;

const Container = styled.div`
  display: flex;
  width: 100%;

  .ant-picker-input > input[disabled] {
    color: #4a4a4a;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 12px;
`;

const HeaderContainer = styled.div`
  z-index: 1;
  position: absolute;
  display: inline;
  width: 100%;
  height: 56px;
  padding-left: 22px;
  padding-top: 15px;
  bottom: 2rem;
  right: 0px;
  left: 0px;
  top: 0px;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
  border-bottom: 1px solid #f0f0f0;
`;

const CloseModalButton = styled(Button)`
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 1;
  width: auto;
  height: auto;
  margin: 0;
  color: #4a4a4a;
`;

const RowContainerSplitButtons = styled.div`
  padding: 14px 0px 0px 7px;
  display: inline-block;
  min-width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddBerthTransitionsLink = styled.a`
  text-transform: none;
  letter-spacing: 0;
`;

const DeleteModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const InfoText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const DeleteActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddCraneRow = styled.div`
  margin-top: 6px;
`;

const AddOrEditPredictiveBerthPlanModal = ({ type, closeModal, vessel, readOnly, workId, updateCranes }) => {
  const { berths, handlePortcallDelete, sendPredictiveBerthPlanData } = useContext(BerthPlanningToolContext);
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [linksDisabled, setLinksDisabled] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);

  const [inputData, setInputData] = useState(
    vessel.berth_transitions?.length
      ? vessel.berth_transitions.reduce((obj, bt, i) => {
        obj[i] = {
          agent: bt?.agent,
          pta: bt ? (bt.pta ? dayjs(bt.pta) : dayjs(bt.eta)) : '',
          ptd: bt ? (bt.ptd ? dayjs(bt.ptd) : dayjs(bt.etd)) : '',
          berth_code: bt?.berth_code,
          start_bollard: bt?.start_bollard,
          end_bollard: bt?.end_bollard,
          direction_down: !!bt.directionDown,
          crane_start_time: vessel?.pbp_data_full?.[i + 1]?.start_time
            ? dayjs(vessel.pbp_data_full[i + 1].start_time)
            : '',
          crane_end_time: vessel?.pbp_data_full?.[i + 1]?.end_time ? dayjs(vessel.pbp_data_full[i + 1].end_time) : '',
          crane_duration: vessel?.pbp_data_full?.[i + 1]?.duration ? vessel.pbp_data_full[i + 1].duration : '',
          pbp_data: vessel?.pbp_data_full?.[i + 1]?.works
            ? vessel.pbp_data_full[i + 1].works.map(d => {
              return {
                crane_id: d.crane_id,
                company_id: d.company_id,
                company_name: d.company_name,
                start_time: d.start_time ? dayjs(d.start_time) : '',
                end_time: d.end_time ? dayjs(d.end_time) : '',
                commodity_id: d?.commodity_id,
                commodity_name: d?.commodity_name,
                commodity_amount: d?.commodity_amount,
                operation_type: d?.operation_type,
                id: d?.id,
                operation: d?.operation,
                duration: d?.duration,
                manual_end_time: d ? d.manual_end_time : false,
                plan_id: vessel.pbp_data_full[i + 1].id,
              };
            })
            : [],
        };
        return obj;
      }, {})
      : {
          0: {
            agent: vessel ? vessel.agent : null,
            pta: vessel ? (vessel.pta ? dayjs(vessel.pta) : dayjs(vessel.eta)) : '',
            ptd: vessel ? (vessel.ptd ? dayjs(vessel.ptd) : dayjs(vessel.etd)) : '',
            berth_code: vessel?.berth_code,
            start_bollard: vessel?.start_bollard,
            end_bollard: vessel?.end_bollard,
            direction_down: !!vessel.directionDown,
            crane_start_time: vessel?.pbp_data?.start_time ? dayjs(vessel.pbp_data.start_time) : '',
            crane_end_time: vessel?.pbp_data?.end_time ? dayjs(vessel.pbp_data.end_time) : '',
            crane_duration: vessel?.pbp_data?.duration ? vessel.pbp_data.duration : '',
            pbp_data:
              vessel?.pbp_data && vessel.pbp_data.works
                ? vessel.pbp_data.works.map(d => {
                  return {
                    crane_id: d.crane_id,
                    company_id: d.company_id,
                    company_name: d.company_name,
                    start_time: d.start_time ? dayjs(d.start_time) : '',
                    end_time: d.end_time ? dayjs(d.end_time) : '',
                    commodity_id: d?.commodity_id,
                    commodity_name: d?.commodity_name,
                    commodity_amount: d?.commodity_amount,
                    operation_type: d?.operation_type,
                    id: d?.id,
                    operation: d?.operation,
                    duration: d?.duration,
                    manual_end_time: d ? d.manual_end_time : false,
                    plan_id: vessel.pbp_data.id,
                  };
                })
                : [],
          },
        }
  );

  const title = type === 'add' || readOnly ? t('Predictive berth plan') : t('Edit predictive berth plan');

  const listDataIsValid = () => {
    let validData = true;

    for (let index in inputData) {
      let transition = inputData[index];

      if (!transition.pta || !transition.ptd || !transition.berth_code) {
        validData = false;
        break;
      }

      if (validData && transition.berth_code !== 'unknown') {
        let validStartBollard = !!transition.start_bollard && !!transition.start_bollard.length;
        let validEndBollard = !!transition.end_bollard && !!transition.end_bollard.length;

        if ((validStartBollard && !validEndBollard) || (!validStartBollard && validEndBollard)) {
          validData = false;
          break;
        }
      }

      if (transition.pbp_data && transition.pbp_data.length > 0) {
        for (let crane in transition.pbp_data) {
          if (
            !transition.pbp_data[crane].commodity_id ||
            !transition.pbp_data[crane].commodity_amount ||
            !transition.pbp_data[crane].crane_id ||
            !transition.pbp_data[crane].operation ||
            !transition.pbp_data[crane].operation_type ||
            !transition.pbp_data[crane].start_time ||
            (transition.pbp_data[crane].manual_end_time && !transition.pbp_data[crane].end_time)
          ) {
            validData = false;
          }
        }
      }
    }

    return validData;
  };

  const setInputDataToState = stateData => {
    if (!linksDisabled) {
      setLinksDisabled(true);
    }
    setInputData(stateData);
  };

  const handleBerthChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['berth_code'] = value;
    newInputData[index]['start_bollard'] = null;
    newInputData[index]['end_bollard'] = null;
    setInputDataToState(newInputData);
  };

  const handleFromBollardChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['start_bollard'] = value;
    setInputDataToState(newInputData);
  };

  const handleToBollardChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['end_bollard'] = value;
    setInputDataToState(newInputData);
  };

  const updateAgentSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['agent'] = value;
    setInputDataToState(newInputData);
  };

  const handleCraneNameChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['crane_id'] = value;
    setInputDataToState(newInputData);
  };

  const handleCraneCompanyChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['company_id'] = value;
    setInputDataToState(newInputData);
  };

  const handleCraneOperationTypeChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['operation_type'] = value;
    setInputDataToState(newInputData);
  };

  const handleCraneOperationChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['operation'] = value;
    setInputDataToState(newInputData);
  };

  const handleCommodityChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['commodity_id'] = value;
    newInputData[index].pbp_data[index2]['operation'] = null;
    setInputDataToState(newInputData);
  };

  const handleAmountChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['commodity_amount'] = value.target.value;
    setInputDataToState(newInputData);
  };

  const onPTAChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['pta'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneStartChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['start_time'] = value;
    newInputData[index].pbp_data[index2]['end_time'] = null;
    setInputDataToState(newInputData);
  };

  const onCraneEndChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['end_time'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneManualEndChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['manual_end_time'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneDurationChangeSplit = (value, index, index2) => {
    let newInputData = { ...inputData };
    newInputData[index].pbp_data[index2]['duration'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneOperationStartChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['crane_start_time'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneOperationEndChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['crane_end_time'] = value;
    setInputDataToState(newInputData);
  };

  const onCraneOperationDurationChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['crane_duration'] = value;
    setInputDataToState(newInputData);
  };

  const onPTDChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['ptd'] = value;

    if (newInputData[Number(index) + 1] && !newInputData[Number(index) + 1]['pta']) {
      newInputData[Number(index) + 1]['pta'] = value;
    }

    setInputDataToState(newInputData);
  };

  const closeDeleteModal = () => {
    setDeleteConfirmationOpen(false);
  };

  const sendData = async () => {
    setConfirmClicked(true);
    let result;

    if (Object.keys(inputData).length === 1) {
      let element = inputData[0];

      let dataToBeSent = {
        port_call_master_id: vessel.port_call_master_id.split('___transition_')[0],
        berth_code: element.berth_code !== 'unknown' ? element.berth_code : null,
        start_bollard: element.start_bollard,
        end_bollard: element.end_bollard,
        pta: dayjs(element.pta)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        ptd: dayjs(element.ptd)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        agent: element.agent,
        berth_transitions: [],
      };

      if (element.pbp_data?.length > 0) {
        dataToBeSent.pbp_data = [
          {
            works: getPbpWorksFromData(element.pbp_data),
          },
        ];
      } else {
        dataToBeSent.pbp_data = [];
      }

      try {
        result = await sendPredictiveBerthPlanData(dataToBeSent);
      } catch (e) {
        setConfirmClicked(false);
      }
    } else {
      const splitDataList = [];
      const pbpData = [];
      Object.keys(inputData).forEach(elementId => {
        let element = inputData[elementId];
        let bollards = berths.find(g => g.id === element.berth_code).subRows;
        const startBollardIndex = bollards.findIndex(b => b.id === element.start_bollard);
        const endBollardIndex = bollards.findIndex(b => b.id === element.end_bollard);
        const directionDown = startBollardIndex <= endBollardIndex;
        splitDataList.push({
          agent: element.agent,
          pta: element.pta.format(),
          ptd: element.ptd.format(),
          berth_code: element.berth_code !== 'unknown' ? element.berth_code : null,
          start_bollard: element.start_bollard,
          end_bollard: element.end_bollard,
          direction_down: directionDown,
        });

        if (element.pbp_data && element.pbp_data.length > 0) {
          pbpData.push({
            works: getPbpWorksFromData(element.pbp_data),
          });
        } else {
          pbpData.push([]);
        }
      });
      try {
        result = await sendPredictiveBerthPlanData({
          port_call_master_id: vessel.port_call_master_id.split('___transition_')[0],
          berth_transitions: splitDataList,
          pbp_data: pbpData,
        });
      } catch (e) {
        setConfirmClicked(false);
      }
    }

    if (result?.status === 200) {
      closeModal();
    } else {
      setConfirmClicked(false);
    }
  };

  const addCraneSlotsButton = index => {
    let newInputData = { ...inputData };
    newInputData[index]['pbp_data'] = [
      {
        crane_id: null,
        company_id: null,
        start_time: '',
        end_time: '',
        commodity_id: null,
        commodity_amount: null,
        operation_type: null,
        id: null,
        operation: null,
        duration: null,
        manual_end_time: false,
      },
    ];
    setInputDataToState(newInputData);
  };

  const onUpdateWork = async () => {
    setConfirmClicked(true);
    let dataToBeSent = {};
    try {
      for (let i = 0; i < Object.keys(inputData).length; i++) {
        let dataPart = inputData[Object.keys(inputData)[i]];
        if (dataPart.pbp_data) {
          for (let work of dataPart.pbp_data) {
            if (work.id === workId) {
              dataToBeSent.id = work.plan_id;
              dataToBeSent.works = getPbpWorksFromData(dataPart.pbp_data);
              await apiCall('put', 'pbp/plan', dataToBeSent);
            }
          }
        }
      }
    } catch (e) {
      setConfirmClicked(false);
    }

    if (updateCranes) {
      updateCranes();
    }
    closeModal();
  };

  const addSplitButton = index => {
    let newInputData = { ...inputData };
    let objLength = Object.keys(newInputData).length;

    if (Number(index) === objLength - 1) {
      newInputData[objLength] = {
        agent: newInputData[objLength - 1].agent,
        pta: null,
        ptd: newInputData[objLength - 1].ptd,
        berth_code: newInputData[objLength - 1].berth_code,
        start_bollard: newInputData[objLength - 1].start_bollard,
        end_bollard: newInputData[objLength - 1].end_bollard,
        commodity_id: null,
        commodity_amount: null,
        crane_start_time: '',
        crane_end_time: '',
        duration: '',
        pbp_data: [],
      };

      newInputData[objLength - 1].ptd = null;
    } else {
      for (let i = objLength - 1; i > -1; i--) {
        if (i > Number(index)) {
          newInputData[i + 1] = { ...newInputData[i] };
        } else if (i === Number(index)) {
          newInputData[i + 1] = {
            agent: newInputData[i].agent,
            pta: null,
            ptd: null,
            berth_code: newInputData[i].berth_code,
            start_bollard: newInputData[i].start_bollard,
            end_bollard: newInputData[i].end_bollard,
            commodity_id: null,
            commodity_amount: null,
            crane_start_time: '',
            crane_end_time: '',
            duration: '',
            pbp_data: [],
          };
        }
      }
    }

    setInputDataToState(newInputData);
  };

  const removeSplitButton = value => {
    let inputDataForDeleting = { ...inputData };
    let savedPTD = inputDataForDeleting[value].ptd;
    let savedPTA = inputDataForDeleting[value].pta;

    delete inputDataForDeleting[value];

    let objLength = Object.keys(inputDataForDeleting).length;

    if (Number(value) === objLength && !inputDataForDeleting[objLength - 1].ptd) {
      inputDataForDeleting[objLength - 1].ptd = savedPTD;
    }

    if (Number(value) === 0 && !inputDataForDeleting[1].pta) {
      inputDataForDeleting[1].pta = savedPTA;
    }

    let newInputData = {};

    let deletedFound = false;

    for (let i = 0; i < objLength + 1; i++) {
      if (inputDataForDeleting[i] && !deletedFound) {
        newInputData[i] = { ...inputDataForDeleting[i] };
      } else {
        deletedFound = true;
        newInputData[i] = { ...inputDataForDeleting[i + 1] };
      }
    }

    delete newInputData[objLength];

    setInputDataToState(newInputData);
  };

  const addCraneSplitButton = index => {
    let newInputData = { ...inputData };
    let data = newInputData[index].pbp_data;

    data.push({
      crane_id: null,
      company_id: null,
      start_time: '',
      end_time: '',
      commodity_id: null,
      commodity_amount: null,
      operation_type: null,
      id: null,
      operation: null,
      duration: null,
      manual_end_time: false,
    });

    setInputDataToState(newInputData);
  };

  const duplicateCraneSplitButton = (index, j) => {
    let newInputData = { ...inputData };
    let data = newInputData[index].pbp_data;

    data.push({ ...newInputData[index].pbp_data[j], id: null });

    setInputDataToState(newInputData);
  };

  const removeCraneSplitButton = (value, index2) => {
    let inputDataForDeleting = { ...inputData };
    inputDataForDeleting[value].pbp_data.splice(index2, 1);

    setInputDataToState(inputDataForDeleting);
  };

  // const openDeleteConfirmation = () => {
  //   setDeleteConfirmationOpen(true);
  // };

  const handleDelete = async () => {
    handlePortcallDelete(vessel);
    closeModal();
  };

  const fetchAgentList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    const result = await apiCall('get', 'agents', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  let dataLength = Object.keys(inputData).length;

  const getModalContent = () => {
    return (
      <ModalInnerInput small={dataLength === 1 && inputData[0].pbp_data && inputData[0].pbp_data.length === 0}>
        <HeaderContainer>
          {t(title)}
          <CloseModalButton copy onClick={closeModal}>
            <Icon type={'close'} style={{ width: '15px' }} />
          </CloseModalButton>
        </HeaderContainer>
        <UpperColumnsSplit>
          <ColumnsSplit>
            <FirstColumnSplit>
              <Info>
                <InfoContainer>
                  <InfoRowSplit>
                    {t('Vessel:')}
                    <BoldText>{vessel.name}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('IMO:')}
                    <BoldText>{vessel.imo}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Master source id:')}
                    <BoldText>{vessel.port_call_master_id.split('___transition_')[0]}</BoldText>
                  </InfoRowSplit>
                </InfoContainer>
                <InfoContainer>
                  <InfoRowSplit>
                    {t('Length:')}
                    <BoldText>{vessel.loa}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Draft:')}
                    <BoldText>{vessel.draft}</BoldText>
                  </InfoRowSplit>
                </InfoContainer>
              </Info>
            </FirstColumnSplit>
            <SmallTitle>{t('Berth and crane planning')}</SmallTitle>
            <SecondColumnSplit disableScrolling={dataLength < 3}>
              {Object.keys(inputData)
                .sort(function(a, b) {
                  return a - b;
                })
                .map(index => {
                  let rowObject = inputData[index];

                  let bollardOptions =
                    rowObject.berth_code && rowObject.berth_code !== 'unknown'
                      ? berths.find(g => g.id === rowObject.berth_code).subRows
                      : [];

                  return (
                    <PlanningContainer key={vessel.name + index}>
                      <Planning style={{ backgroundColor: '#f8f8f8' }}>
                        <Container>
                          <RowContainerSplit
                            dataLength={dataLength}
                            style={{ width: 'calc(100% / 6)', padding: '0 7px' }}
                          >
                            <RowHeader>{t('Berth')}</RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('Berth name')}
                                value={
                                  berths && rowObject.berth_code
                                    ? berths.find(b => b.id === rowObject.berth_code)?.short_name
                                    : ''
                                }
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <Select
                                listHeight={80}
                                showSearch
                                placeholder={t('Berth name')}
                                optionFilterProp="label"
                                onChange={value => handleBerthChangeSplit(value, index)}
                                style={{ width: '100%' }}
                                options={berths.map(g => {
                                  return { label: g.id === 'unknown' ? t('No berth') : g.short_name, value: g.id };
                                })}
                                value={rowObject.berth_code || null}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                            <RowHeader>{t('From bollard')}</RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('From bollard name')}
                                value={
                                  rowObject.start_bollard
                                    ? bollardOptions.find(g => g.id === rowObject.start_bollard)?.short_name
                                    : ''
                                }
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <Select
                                showSearch
                                listHeight={80}
                                placeholder={t('From bollard name')}
                                optionFilterProp="label"
                                onChange={value => handleFromBollardChangeSplit(value, index)}
                                style={{ width: '100%' }}
                                options={bollardOptions.map(g => {
                                  return { label: g.short_name, value: g.id };
                                })}
                                name={index}
                                disabled={!rowObject.berth_code || rowObject.berth_code === 'unknown'}
                                value={rowObject.start_bollard}
                                allowClear={true}
                                onClear={() => handleFromBollardChangeSplit('', index)}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                            <RowHeader>{t('To bollard')}</RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('To bollard name')}
                                value={
                                  rowObject.end_bollard
                                    ? bollardOptions.find(g => g.id === rowObject.end_bollard)?.short_name
                                    : ''
                                }
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <Select
                                listHeight={80}
                                showSearch
                                placeholder={t('To bollard name')}
                                optionFilterProp="label"
                                onChange={value => handleToBollardChangeSplit(value, index)}
                                style={{ width: '100%' }}
                                options={bollardOptions.map(g => {
                                  return { label: g.short_name, value: g.id };
                                })}
                                disabled={!rowObject.berth_code || rowObject.berth_code === 'unknown'}
                                value={rowObject.end_bollard}
                                allowClear={true}
                                onClear={() => handleToBollardChangeSplit('', index)}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                            <RowHeader>
                              {t('PTA')}
                              <Required>*</Required>
                            </RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('Planned time of arrival')}
                                value={rowObject.pta ? dayjs(rowObject.pta).format('DD.MM.YYYY HH:mm') : ''}
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                value={rowObject.pta}
                                style={{ width: '100%' }}
                                onChange={value => onPTAChangeSplit(value, index)}
                                onSelect={value => onPTAChangeSplit(value, index)}
                                placeholder={t('Planned time of arrival')}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                            <RowHeader>
                              {t('PTD')}
                              <Required>*</Required>
                            </RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('Planned time of departure')}
                                value={rowObject.ptd ? dayjs(rowObject.ptd).format('DD.MM.YYYY HH:mm') : ''}
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                value={rowObject.ptd}
                                style={{ width: '100%' }}
                                onChange={value => onPTDChangeSplit(value, index)}
                                onSelect={value => onPTDChangeSplit(value, index)}
                                placeholder={t('Planned time of departure')}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                            <RowHeader>{t('Agent')}</RowHeader>
                            {readOnly ? (
                              <Input
                                placeholder={t('Agent name')}
                                value={rowObject.agent}
                                disabled={true}
                                style={{ color: '#4a4a4a' }}
                              />
                            ) : (
                              <DebounceAutocomplete
                                placeholder={t('Agent name')}
                                fetchOptions={fetchAgentList}
                                onChange={value => updateAgentSplit(value, index)}
                                onSelect={value => updateAgentSplit(value, index)}
                                value={rowObject.agent || ''}
                                style={{ width: '100%' }}
                                allowClear={true}
                                onClear={() => updateAgentSplit('', index)}
                              />
                            )}
                          </RowContainerSplit>
                        </Container>
                        {(!rowObject.pbp_data || rowObject.pbp_data.length === 0) && !readOnly && (
                          <RowContainerSplitButtons
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              normal
                              style={{ zIndex: '5', marginTop: '-10px', marginRight: '4px', color: '#4990DD' }}
                              onClick={() => addCraneSlotsButton(index)}
                            >
                              <Icon type={'circle-plus'} />
                              <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                                {t('Start crane planning')}
                              </AddBerthTransitionsLink>
                            </Button>
                          </RowContainerSplitButtons>
                        )}
                        {!!rowObject.pbp_data && !!rowObject.pbp_data.length && (
                          <CommoditiesTitle>{t('Crane operation time slots')}</CommoditiesTitle>
                        )}
                        {!!rowObject.pbp_data && !!rowObject.pbp_data.length && (
                          <Container>
                            <RowContainerSplit
                              dataLength={dataLength}
                              style={{ width: 'calc(100% / 6)', padding: '0 10px' }}
                            >
                              <RowHeader>{t('Crane operation start')}</RowHeader>
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                value={rowObject.crane_start_time}
                                style={{ width: '100%' }}
                                placeholder={t('Crane operation start')}
                                disabled={true}
                              />
                            </RowContainerSplit>
                            <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                              <RowHeader>{t('Crane operation end')}</RowHeader>
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                value={rowObject.crane_end_time}
                                style={{ width: '100%' }}
                                placeholder={t('Crane operation end')}
                                disabled={true}
                              />
                            </RowContainerSplit>
                            <RowContainerSplit dataLength={dataLength} style={{ width: 'calc(100% / 6)' }}>
                              <RowHeader>{t('Total duration')}</RowHeader>
                              <Input
                                disabled={true}
                                value={getDuration(rowObject.crane_duration)}
                                style={{ width: '100%', color: '#4a4a4a' }}
                              />
                            </RowContainerSplit>
                          </Container>
                        )}
                        <PredictiveBerthPlanModalCranes
                          data={rowObject.pbp_data}
                          index={index}
                          handleCommodityChangeSplit={handleCommodityChangeSplit}
                          handleAmountChangeSplit={handleAmountChangeSplit}
                          handleCraneOperationChangeSplit={handleCraneOperationChangeSplit}
                          handleCraneOperationTypeChangeSplit={handleCraneOperationTypeChangeSplit}
                          handleCraneNameChangeSplit={handleCraneNameChangeSplit}
                          handleCraneCompanyChangeSplit={handleCraneCompanyChangeSplit}
                          onCraneStartChangeSplit={onCraneStartChangeSplit}
                          onCraneEndChangeSplit={onCraneEndChangeSplit}
                          onCraneDurationChangeSplit={onCraneDurationChangeSplit}
                          removeCraneSplitButton={removeCraneSplitButton}
                          onCraneOperationDurationChangeSplit={onCraneOperationDurationChangeSplit}
                          onCraneOperationStartChangeSplit={onCraneOperationStartChangeSplit}
                          onCraneOperationEndChangeSplit={onCraneOperationEndChangeSplit}
                          duplicateCraneSplitButton={duplicateCraneSplitButton}
                          onCraneManualEndChangeSplit={onCraneManualEndChangeSplit}
                          readOnly={readOnly}
                          workId={workId}
                        />
                        <AddCraneRow>
                          <Button
                            normal
                            style={{ zIndex: '5', marginRight: '10px', color: '#4990dd' }}
                            onClick={() => addCraneSplitButton(index)}
                          >
                            <Icon type={'circle-plus'} />
                            <AddBerthTransitionsLink
                              href="#"
                              style={{ fontSize: '15px', fontWeight: '400', color: '#4990dd' }}
                            >
                              {t('Add crane work')}
                            </AddBerthTransitionsLink>
                          </Button>
                        </AddCraneRow>

                        <RowContainerSplitButtons
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {dataLength > 1 && !readOnly && (
                            <Button
                              normal
                              style={{ zIndex: '5', marginTop: '-5px', marginRight: '10px', color: '#D0011C' }}
                              onClick={() => removeSplitButton(index)}
                            >
                              <Icon type={'trash'} />
                              <AddBerthTransitionsLink
                                href="#"
                                style={{ fontSize: '15px', fontWeight: '400', color: '#D0011C' }}
                              >
                                {t('Delete transition')}
                              </AddBerthTransitionsLink>
                            </Button>
                          )}
                        </RowContainerSplitButtons>
                      </Planning>
                      {!readOnly && (
                        <RowContainerSplitButtons
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '0px',
                          }}
                        >
                          <Button
                            normal
                            style={{ zIndex: '5', marginBottom: '18px', marginRight: '10px', color: '#4990DD' }}
                            onClick={() => addSplitButton(index)}
                          >
                            <Icon type={'circle-plus'} />
                            <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                              {t('Add berth transition')}
                            </AddBerthTransitionsLink>
                          </Button>
                        </RowContainerSplitButtons>
                      )}
                    </PlanningContainer>
                  );
                })}
            </SecondColumnSplit>
          </ColumnsSplit>
        </UpperColumnsSplit>
        <MiddleLine />
        <BottomRow>
          <ModalLinks vessel={vessel} linksDisabled={linksDisabled} />
          {readOnly && !workId ? (
            <ActionButtonsReadOnly>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtonsReadOnly>
          ) : (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              {/* <ButtonLight type="button" lightred onClick={openDeleteConfirmation}>
              {t('Delete')}
            </ButtonLight> */}

              <ButtonLight
                disabled={!listDataIsValid() || confirmClicked}
                sending={confirmClicked}
                onClick={() => (!!workId || workId === 0 ? onUpdateWork() : sendData())}
              >
                {!!workId || workId === 0 ? t('Confirm and Commit') : t('Confirm')}
              </ButtonLight>
            </ActionButtons>
          )}
        </BottomRow>
      </ModalInnerInput>
    );
  };

  return (
    <>
      {!deleteConfirmationOpen && <Blocker />}

      {getModalContent()}

      {deleteConfirmationOpen && (
        <Modal
          title={t('Remove portcall for') + ' ' + vessel.name}
          open={deleteConfirmationOpen}
          width={800}
          footer={null}
          headStyle={{ backgroundColor: 'red' }}
          onCancel={closeDeleteModal}
        >
          <DeleteModalInner>
            <IconContainer>
              <Icon type="remove-illustration"></Icon>
            </IconContainer>
            <InfoText>{t('Are you sure you want to delete the portcall?')}</InfoText>
            <MiddleLine />
            <DeleteActionButtons>
              <ButtonLight type="button" cancel onClick={closeDeleteModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight red onClick={handleDelete}>
                {t('Delete')}
              </ButtonLight>
            </DeleteActionButtons>
          </DeleteModalInner>
        </Modal>
      )}
    </>
  );
};

export default AddOrEditPredictiveBerthPlanModal;
