import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import Page from '../../components/ui/Page';
import TemplateList from '../../components/invoicing/TemplateList';

const TemplatesView = () => {
  const { namespace } = useContext(UserContext);
  const { invoicePageVisited, setInvoicePageVisited } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Templates | Port Activity App';

    if (!invoicePageVisited) {
      setInvoicePageVisited(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout pagename={t('Templates')}>
      <Page fullWidth>
        <TemplateList />
      </Page>
    </Layout>
  );
};

export default TemplatesView;
