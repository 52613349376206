import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';

import { CranePlanningToolContext } from '../../context/CranePlanningToolContext';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';

import Icon from '../ui/Icon';

import { TIME_FORMAT } from '../../utils/constants';
import AddOrEditReservationModal from './modals/AddOrEditReservationModal';
import DeleteReservationModal from './modals/DeleteReservationModal';

const Container = styled.div`
  margin-left: 5px;
  margin-bottom: 180px;
  position: relative;
  z-index: 0;
`;

const ButtonVersion = styled(ButtonLight)`
  font-size: 13px;
  min-width: 60px;
  margin-bottom: 10px;
  margin-top: 2px;
`;

const ItemContainer = styled.div`
  background-color: white;
  z-index: 70;
  position: relative;
  display: flex;
`;

const Title = styled.div`
  font-size: 14px;
  color: #000000;
`;

const ScrollContainer = styled.div`
  height: calc(100% - 102px);
  position: absolute;
  z-index: 60;
`;

const VesselContainer = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  border-radius: 4px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
  margin-right: 6px;
  position: relative;
  z-index: 61;
  width: 228px;
  cursor: default;
`;

const VesselData = styled.div`
  padding: 8px 12px;
`;

const VesselNameContainer = styled.div`
  display: flex;
  margin-bottom: 4px;

  @media (max-width: 1140px) {
    display: inline-block;
  }
`;

const VesselName = styled.div`
  text-transform: uppercase;
  margin-right: 0px;
  font-weight: 700;
  text-overflow: ellipsis;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1140px) {
    font-size: 12px;
  }
`;

const VesselInfoContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grey};
`;

const InfoTexts = styled.div`
  margin-right: 4px;
  font-weight: 700;
  max-width: 128px;
  white-space: nowrap;
`;

const InfoDividers = styled.div`
  margin: 0px 4px 0px 2px;
`;

const Row = styled.div`
  font-size: 12px;
`;

const NotesRow = styled(Row)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ThreeDotContainer = styled.div`
  position: absolute;
  margin-top: 3px;
  height: 17px;
  width: 26px;
  padding-left: 2px;
  @media (min-width: 1110px) {
    right: 10px;
  }
  @media (min-width: 900px) and (max-width: 1109px) {
    right: calc(6rem + 1038px - 100vw);
  }
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  z-index: 62;
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
  i {
    margin-top: -8px;
    svg {
      margin-top: -6px;
    }
  }
`;

const InvisibleContainer = styled.div`
  position: relative;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const ThreeDotPopupContainer = styled.div`
  background-color: white;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 80;
  position: absolute;
  border-radius: 2px;
  top: 15px;
  @media (min-width: 1140px) {
    left: 115px;
  }
  @media (min-width: 900px) and (max-width: 1139px) {
    right: calc(6rem + 1063px - 100vw);
  }
  padding: 4px 4px 4px 4px;
  font-weight: bold;
`;

const ThreeDotPopupRow = styled.div`
  display: flex;
  cursor: pointer;
  margin: 10px;

  span {
    margin-right: 9px;
    margin-top: 6px;
  }
`;

const IconContainer = styled.div`
  margin-top: -2px;
  width: 26px;
`;

const IconContainerRemove = styled.div`
  margin-left: -3px;
  width: 29px;
  margin-top: -1px;

  svg {
    width: 20px;
    fill: #d0011c;
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  font-size: 11px;
  line-height: 16px;
  max-width: 200px;
  color: ${({ theme }) => theme.color.grey};
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  margin-right: 0px;
`;

const TooltipValue = styled.div`
  font-weight: bold;
`;

const Column = styled.div`
  width: 34px;
`;

const NoReservationsText = styled.div`
  width: 228px;
  text-align: center;
  font-size: 13px;
`;

const CranePlanningToolReservations = () => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { reservations, sidePanelWidth, canvasTimeStart, canvasTimeEnd } = useContext(CranePlanningToolContext);

  const [threeDotPopupIndex, setThreeDotPopupIndex] = useState(undefined);
  const [newReservationModalOpen, setNewReservationModalOpen] = useState(false);
  const [editReservationModalOpen, setEditReservationModalOpen] = useState(undefined);
  const [deleteReservationModalOpen, setDeleteReservationModalOpen] = useState(undefined);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const clickThreeDots = i => {
    setThreeDotPopupIndex(i);
  };

  const showAddModal = () => {
    setNewReservationModalOpen(true);
  };

  const showEditModal = () => {
    setEditReservationModalOpen(reservations[threeDotPopupIndex]);
    setThreeDotPopupIndex(undefined);
  };

  const showRemoveModal = () => {
    setDeleteReservationModalOpen(reservations[threeDotPopupIndex]);
    setThreeDotPopupIndex(undefined);
  };

  const closeAddModal = () => {
    setNewReservationModalOpen(false);
  };

  const closeEditModal = () => {
    setEditReservationModalOpen(undefined);
  };

  const closeDeleteModal = () => {
    setDeleteReservationModalOpen(undefined);
  };

  const ReservationTooltip = v => {
    return (
      <>
        <TooltipContainer>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Notes')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{v.notes}</TooltipValue>
          </SecondColumn>
        </TooltipContainer>
      </>
    );
  };

  return (
    <Container style={{ width: sidePanelWidth - 5 + 'px', height: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <ItemContainer>
        <ButtonVersion onClick={showAddModal} disabled={!user.permissions.includes('manage_bpt')}>
          {t('Add new reservation')}
        </ButtonVersion>
      </ItemContainer>
      <ItemContainer style={{ display: 'inline-block' }}>
        <Title style={{ marginTop: '6px' }}>{t('Reservations between')}</Title>
        <Title style={{ marginBottom: '6px' }}>
          {dayjs(canvasTimeStart).format('DD.MM.YYYY HH:mm') + ' - ' + dayjs(canvasTimeEnd).format('DD.MM.YYYY HH:mm')}
        </Title>
      </ItemContainer>
      <ScrollContainer
        style={{
          overflowX: 'hidden',
          overflowY: 'scroll',
          top: '109px',
        }}
      >
        {reservations.length === 0 && (
          <>
            <NoReservationsText>{t('No reservations between')}</NoReservationsText>
          </>
        )}
        {reservations.map((v, i) => (
          <InvisibleContainer key={v.id}>
            {user.permissions.includes('manage_pbp') && (
              <ThreeDotContainer onClick={() => clickThreeDots(i)}>
                <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
              </ThreeDotContainer>
            )}
            <VesselContainer>
              <Tooltip
                placement="left"
                title={() => ReservationTooltip(v)}
                color="white"
                overlayStyle={{ pointerEvents: 'none' }}
              >
                <VesselData>
                  <VesselNameContainer>
                    <VesselName>{v.crane_name}</VesselName>
                  </VesselNameContainer>
                  <VesselInfoContainer>
                    <Column>
                      <Row>{t('Start')}</Row>
                      <Row>{t('End')}</Row>
                      <Row>{t('Notes')}</Row>
                    </Column>
                    <InfoDividers>
                      <Row>:</Row>
                      <Row>:</Row>
                      <Row>:</Row>
                    </InfoDividers>
                    <InfoTexts>
                      <Row>{dayjs(v.start_time).format(TIME_FORMAT)}</Row>
                      <Row>{dayjs(v.end_time).format(TIME_FORMAT)}</Row>
                      <NotesRow>{v.notes}</NotesRow>
                    </InfoTexts>
                  </VesselInfoContainer>
                </VesselData>
              </Tooltip>
            </VesselContainer>

            {threeDotPopupIndex === i && (
              <div>
                <Blocker onClick={() => setThreeDotPopupIndex(undefined)} />
                <ThreeDotPopupContainer>
                  <ThreeDotPopupRow onClick={showEditModal}>
                    <IconContainer>
                      <Icon type="edit-outline"></Icon>
                    </IconContainer>
                    {t('Edit')}
                  </ThreeDotPopupRow>
                  <ThreeDotPopupRow onClick={showRemoveModal}>
                    <IconContainerRemove>
                      <Icon type="trash"></Icon>
                    </IconContainerRemove>
                    {t('Remove')}
                  </ThreeDotPopupRow>
                </ThreeDotPopupContainer>
              </div>
            )}
          </InvisibleContainer>
        ))}
      </ScrollContainer>

      {!!newReservationModalOpen && <AddOrEditReservationModal type={'new'} closeModal={closeAddModal} />}
      {!!editReservationModalOpen && (
        <AddOrEditReservationModal type={'edit'} reservation={editReservationModalOpen} closeModal={closeEditModal} />
      )}
      {!!deleteReservationModalOpen && (
        <DeleteReservationModal type={'new'} reservation={deleteReservationModalOpen} closeModal={closeDeleteModal} />
      )}
    </Container>
  );
};

export default CranePlanningToolReservations;
