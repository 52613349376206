import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import { debounce } from 'throttle-debounce';
import dayjs from 'dayjs';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import SaveAsATemplate from './invoiceHelps/SaveAsATemplate';
import InnerInvoiceModal from './InnerInvoiceModal';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: auto;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
  min-width: 1000px;
`;

const NewInvoiceModal = ({
  invoice,
  closeModal,
  readOnly,
  sendAllowed,
  duplicateCurrent,
  setSendEditModeOn,
  sendEditModeOn,
}) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { defaultCurrency } = useContext(NewInvoicingContext);

  const getCombinedUnit = units => {
    let unitString = units[0].name;
    if (units[1]) {
      unitString += ' / ' + units[1].name;
    }

    return unitString;
  };

  const creditInvoice = invoice?.type === 'credit' && !!invoice?.credited_invoice_id;
  const newCredit = invoice?.new_credit;

  const [inputData, setInputData] = useState({
    customer: invoice?.customer_number,
    customer_data: invoice?.customer,
    invoice_date: invoice?.invoice_date,
    due_date: invoice?.due_date,
    due_date_days: invoice?.due_date_days,
    shipping_date: invoice?.shipping_date,
    currency: invoice?.currency_code || defaultCurrency || 'EUR',
    tax_class: invoice?.tax_class,
    description: invoice?.description,
    port_call_data: invoice?.port_call_data,
    your_reference: invoice?.your_reference,
    rows:
      invoice?.invoice_rows?.map(r => {
        return {
          product_data: r.product,
          amounts:
            r.amounts ||
            r.product?.pricing?.units.map(u => {
              return {
                amount: null,
                unit_name: u.name,
              };
            }),
          info: r.calculated?.description,
          code: r.product_code,
          name: r.calculated?.product_name || r.product?.name,
          price: r.calculated?.sum_excluding_vat,
          unit_amount: r.calculated?.unit_price || r.product.pricing.unit_price,
          combined_unit: r.calculated?.combined_unit || getCombinedUnit(r.product?.pricing?.units),
          vat: r.product ? r.product.vat : r.calculated?.vat,
          timeframe: r.timeframe || { from: null, to: null, at: null },
          used_timeframe: r.calculated?.used_timeframe,
          used_modifications: r.calculated?.used_modifiers || [],
          unused_modifications: r.calculated?.unused_modifiers || [],
          modifications: r.modifiers.map(m => {
            return {
              code: m.modifier_code,
              name: m.modifier?.name,
              adjustment: m.modifier?.adjustment,
            };
          }),
        };
      }) || [],
  });

  const [dueDateInDays, setDueDateInDays] = useState(!invoice?.due_date || false);

  const [rowInputChanged, setRowInputChanged] = useState(false);

  const [totalWithoutTax, setTotalWithoutTax] = useState(invoice?.calculated?.total_sum_excluding_vat || 0);
  const [totalWithTax, setTotalWithTax] = useState(invoice?.calculated?.total_sum_including_vat || 0);

  const [sending, setSending] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [openSaveAsATemplate, setOpenSaveAsATemplate] = useState(false);

  const title = readOnly
    ? t(creditInvoice ? 'Credit invoice' : 'Invoice')
    : !invoice || invoice?.from_template || duplicateCurrent || newCredit
        ? t(creditInvoice ? 'Create new credit invoice' : 'Create new invoice')
        : t(creditInvoice ? 'Edit credit invoice' : 'Edit invoice');

  const dataIsValid = () => {
    let valid =
      inputData.customer &&
      inputData.invoice_date &&
      (inputData.due_date?.toString().length || inputData.due_date_days?.toString().length) &&
      inputData.currency &&
      inputData.tax_class &&
      inputData.rows.length;

    if (valid) {
      for (let i = 0; i < inputData.rows.length; i++) {
        for (let j = 0; j < inputData.rows[i].amounts.length; j++) {
          if (!(inputData.rows[i].amounts[j].amount || inputData.rows[i].amounts[j].amount === 0)) {
            valid = false;
          }
        }
      }
    }

    return valid;
  };

  const sendData = async () => {
    setSending(true);

    let dataToBeSent = {
      id: invoice?.id && !duplicateCurrent ? invoice.id : null,
      customer_number: inputData.customer,
      tax_class: inputData.tax_class,
      currency_code: inputData.currency,
      your_reference: inputData.your_reference,
      invoice_date: dayjs(inputData.invoice_date).format('YYYY-MM-DD'),
      due_date: inputData.due_date ? dayjs(inputData.due_date).format('YYYY-MM-DD') : '',
      due_date_days: inputData.due_date_days || '',
      description: inputData.description || '',
      shipping_date: inputData.shipping_date ? dayjs(inputData.shipping_date).format('YYYY-MM-DD') : '',
      port_call_id: inputData.port_call_data?.port_call_id,
    };

    if (creditInvoice) {
      dataToBeSent.type = 'credit';
      dataToBeSent.credited_invoice_id = invoice.credited_invoice_id;
      dataToBeSent.invoice_rows = inputData.rows.map(r => {
        return {
          timeframe: r.timeframe,
          product_code: r.code,
          amounts: r.amounts,
          modifiers: [],
          product: {
            vat: r.product_data.vat,
            code: r.product_data.code,
            name: r.product_data.name,
            links: [],
            pricing: r.product_data.pricing,
            max_fees: [],
            min_fees: [],
          },
        };
      });
    } else {
      dataToBeSent.invoice_rows = inputData.rows.map(r => {
        return {
          timeframe: r.timeframe,
          product_code: r.code,
          amounts: r.amounts,
          modifiers: r.modifications.map(m => {
            return {
              modifier_code: m.code,
            };
          }),
        };
      });
    }

    let result;
    try {
      result = await apiCall(invoice?.id && !duplicateCurrent ? 'put' : 'post', 'invoicing/v2/invoice', dataToBeSent);
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      if (sendEditModeOn) {
        setSendEditModeOn(false);
        setSending(false);
      } else {
        closeModal(true);
      }
    } else {
      setSending(false);
    }
  };

  const sendInvoice = async () => {
    setSending(true);
    let result;
    try {
      result = await apiCall('post', 'invoicing/v2/send-invoice', {
        id: invoice.id,
      });
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal(true);
    } else {
      setSending(false);
    }
  };

  const getModifiedCreditRows = useCallback(rows => {
    return rows.map(r => {
      return {
        timeframe: r.timeframe,
        product_code: r.code,
        product: {
          vat: r.product_data.vat,
          code: r.product_data.code,
          name: r.product_data.name,
          links: [],
          pricing: r.product_data.pricing,
          max_fees: [],
          min_fees: [],
        },
        amounts: r.amounts.map(a => {
          return {
            amount: !a.amount || a.amount === '' ? 0 : a.amount,
            unit_name: a.unit_name,
          };
        }),
        modifiers: [],
      };
    });
  }, []);

  const getModifiedRows = useCallback(rows => {
    return rows.map(r => {
      return {
        timeframe: r.timeframe,
        product_code: r.code,
        amounts: r.amounts.map(a => {
          return {
            amount: !a.amount || a.amount === '' ? 0 : a.amount,
            unit_name: a.unit_name,
          };
        }),
        modifiers: r.modifications.map(m => {
          return {
            modifier_code: m.code,
          };
        }),
      };
    });
  }, []);

  const calculateRowPrices = useCallback(
    async rows => {
      setRowInputChanged(false);
      let dataToBeSent = {
        invoice_date: inputData.invoice_date ? dayjs(inputData.invoice_date).format('YYYY-MM-DD') : null,
        shipping_date: inputData.shipping_date ? dayjs(inputData.shipping_date).format('YYYY-MM-DD') : null,
        tax_class: inputData.tax_class,
        invoice_rows: getModifiedRows(rows),
      };

      if (creditInvoice) {
        dataToBeSent.type = 'credit';
        dataToBeSent.invoice_rows = getModifiedCreditRows(rows);
      }

      let result;
      try {
        result = await apiCall('post', 'invoicing/v2/calculate-invoice', dataToBeSent);
      } catch (e) {
        message.error('Calculating prices failed', e);
      }
      if (result?.data?.invoice && result.status === 200) {
        setTotalWithTax(result.data.invoice.calculated?.total_sum_including_vat);
        setTotalWithoutTax(result.data.invoice.calculated?.total_sum_excluding_vat);
        setInputData(data => {
          return {
            ...data,
            rows: rows.map((r, i) => {
              return {
                ...r,
                price: result.data.invoice.invoice_rows[i].calculated.sum_excluding_vat,
                info: result.data.invoice.invoice_rows[i].calculated.description,
                used_timeframe: result.data.invoice.invoice_rows[i].calculated.used_timeframe,
                vat: result.data.invoice.invoice_rows[i].calculated.vat,
                used_modifications: result.data.invoice.invoice_rows[i].calculated.used_modifiers,
                unused_modifications: result.data.invoice.invoice_rows[i].calculated.unused_modifiers,
                unit_amount: result.data.invoice.invoice_rows[i].calculated.unit_price,
              };
            }),
          };
        });
      }
    },
    [
      apiCall,
      creditInvoice,
      getModifiedCreditRows,
      getModifiedRows,
      inputData.invoice_date,
      inputData.shipping_date,
      inputData.tax_class,
      message,
    ]
  );

  const debouncedCalculate = useMemo(() => debounce(800, calculateRowPrices), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputData.rows.length && rowInputChanged) {
      debouncedCalculate(inputData.rows);
    }
    if (inputData.rows.length === 0) {
      setTotalWithTax(0);
      setTotalWithoutTax(0);
    }
  }, [inputData.rows, rowInputChanged, inputData.invoice_date, inputData.shipping_date]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal title={title} open={true} width={1100} onCancel={() => closeModal(false)} footer={null} maskClosable={false}>
      <ModalInnerInput>
        <ScrollContainer>
          <InnerInvoiceModal
            inputData={inputData}
            setInputData={setInputData}
            readOnly={readOnly}
            totalWithoutTax={totalWithoutTax}
            totalWithTax={totalWithTax}
            setRowInputChanged={setRowInputChanged}
            dueDateInDays={dueDateInDays}
            setDueDateInDays={setDueDateInDays}
            creditInvoice={creditInvoice}
            creditableProducts={invoice?.creditable_products}
          />
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons style={{ justifyContent: 'flex-end' }}>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
            {sendAllowed && (
              <ButtonLight type="button" cancel onClick={() => setSendEditModeOn(true)}>
                {t('Edit')}
              </ButtonLight>
            )}
            {sendAllowed && (
              <Popconfirm
                title={t('Are you sure you want to send the invoice?')}
                onConfirm={() => sendInvoice()}
                okText={t('Yes')}
                okType="danger"
                cancelText={t('No')}
                icon={null}
                id="pop-confirm-for-new-list"
              >
                <ButtonLight disabled={sending} sending={sending} type="button">
                  {t('Send')}
                </ButtonLight>
              </Popconfirm>
            )}
          </ActionButtons>
        ) : (
          <ActionButtons>
            <div>
              <ButtonLight
                type="button"
                disabled={inputData.rows.length === 0}
                onClick={() => setOpenSaveAsATemplate(true)}
              >
                {t('Save as a template')}
              </ButtonLight>
            </div>
            <div>
              <ButtonLight
                type="button"
                cancel
                onClick={() => {
                  if (sendEditModeOn) {
                    setSendEditModeOn(false);
                  } else {
                    closeModal(false);
                  }
                }}
              >
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
                {t('Save')}
              </ButtonLight>
            </div>
          </ActionButtons>
        )}
      </ModalInnerInput>

      {openSaveAsATemplate && <SaveAsATemplate data={inputData} closeModal={() => setOpenSaveAsATemplate(false)} />}
    </Modal>
  );
};

export default NewInvoiceModal;
