import React, { createContext, useContext, useState } from 'react';
import { UserContext } from './UserContext';

export const StatisticsContext = createContext();

export const StatisticsProvider = ({ children }) => {
  const { apiCall } = useContext(UserContext);

  const [dashboardData, setDashboardData] = useState({});
  const [emissionsDashboardData, setEmissionsDashboardData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [emissionsDataFetched, setEmissionsDataFetched] = useState(false);
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(false);
  const [isEmissionsDashboardDataLoading, setIsEmissionsDashboardDataLoading] = useState(false);

  const getDashboardData = async () => {
    let result;
    try {
      result = await apiCall('get', 'statistics/dashboard?name=port_overview');
    } catch (e) {
      setDashboardData({});
    }
    if (result?.data && result.status === 200) {
      setDashboardData(result.data);
    }
  };

  const getEmissionsDashboardData = async () => {
    let result;
    try {
      result = await apiCall('get', 'statistics/dashboard?name=port_emissions');
    } catch (e) {
      setEmissionsDashboardData({});
    }
    if (result?.data && result.status === 200) {
      setEmissionsDashboardData(result.data);
    }
  };

  const getStartData = async () => {
    setIsDashboardDataLoading(true);
    if (!dataFetched) {
      setDataFetched(true);
      await getDashboardData();
    }
    setIsDashboardDataLoading(false);
  };

  const getEmissionsStartData = async () => {
    setIsEmissionsDashboardDataLoading(true);
    if (!emissionsDataFetched) {
      setEmissionsDataFetched(true);
      await getEmissionsDashboardData();
    }
    setIsEmissionsDashboardDataLoading(false);
  };

  return (
    <StatisticsContext.Provider
      value={{
        isDashboardDataLoading,
        isEmissionsDashboardDataLoading,
        dashboardData,
        emissionsDashboardData,
        getStartData,
        getEmissionsStartData,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};
