import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import useFakeApi from './StatisticsContextData';
import dayjs from 'dayjs';
import { TIME_FORMAT, mobilePixelMaxWidthLimit } from '../../utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;
  overflow: hidden;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const InfoDataContainer = styled.div`
  display: inline-block;
`;

const InfoData = styled.div`
  display: inline-block;
`;

const DotContainer = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  background-color: ${props => (props.blue ? props.theme.color.secondary : props.theme.color.grey_light2)};
  margin-top: 8px;
`;

const Line = styled.div`
  width: 1px;
  height: calc(100% + 35px);
  background-color: ${({ theme }) => theme.color.grey_light2};
  position: absolute;
  left: 14px;
`;

const LineAbove = styled(Line)`
  top: -180%;
`;

const LineBelow = styled(Line)`
  top: 15px;
`;

const Update = styled.div`
  margin-bottom: 18px;
  display: flex;
`;

const Rows = styled.div`
  display: inline-block;
  width: calc(100% - 30px);
`;

const BoldedPart = styled.span`
  margin-right: 6px;
  font-weight: 700;
`;

const NormalPart = styled.span`
  margin-right: 6px;
`;

const TopRow = styled.div`
  display: flex;
`;

const BottomRow = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
`;

const BoldedPartSmall = styled.span`
  margin-right: 4px;
  font-weight: 700;
`;

const NormalPartSmall = styled.span`
  margin-right: 4px;
`;

const StatisticsLatestUpdates = props => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useFakeApi('get', props.data_url, {});
  const infoData = error || !data ? [] : data.data;

  useEffect(() => {
    const timer = setInterval(() => {
      setStartLoader(false);
      fetchData();
    }, 5 * 60000);
    return () => clearInterval(timer);
  }, []); // eslint-disable-line

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
        </HeaderRow>

        {startLoader && loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : infoData ? (
          <InfoDataContainer>
            {infoData.map((d, i) => (
              <Update key={i}>
                <DotContainer>
                  {i > 0 && <LineAbove />}
                  <Dot blue={i === 0} />
                  <LineBelow />
                </DotContainer>
                <Rows>
                  <TopRow>
                    <InfoData>
                      {!!d.message.boldedBegin && <BoldedPart>{d.message.boldedBegin}</BoldedPart>}
                      {!!d.message.middle && <NormalPart>{d.message.middle}</NormalPart>}
                      {!!d.message.boldedEnd && <BoldedPart>{d.message.boldedEnd}</BoldedPart>}
                    </InfoData>
                  </TopRow>
                  <BottomRow>
                    <NormalPartSmall>{t('by')}</NormalPartSmall>
                    <BoldedPartSmall>{d.updated_by || '-'}</BoldedPartSmall>
                    <NormalPartSmall>{t('at')}</NormalPartSmall>
                    <NormalPartSmall style={{ fontWeight: 600 }}>
                      {d.updated_at ? dayjs(d.updated_at).format(TIME_FORMAT) : '-'}
                    </NormalPartSmall>
                  </BottomRow>
                </Rows>
              </Update>
            ))}
          </InfoDataContainer>
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default StatisticsLatestUpdates;
