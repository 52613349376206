import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import Modal from 'antd/es/modal';
import BerthPlanningToolTopPanel from './BerthPlanningToolTopPanel';
import BerthPlanningToolUpcomingVessels from './BerthPlanningToolUpcomingVessels';
import BerthPlanningToolMap from './BerthPlanningToolMap';

const TopPanel = styled.div`
  height: 46px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1141px) {
    display: none;
  }
`;

const StyledButtonLight = styled(ButtonLight)`
  margin-bottom: 0px;
  margin-right: 6px;
`;

const ModalInner = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MapContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 300px);
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: ${props => !props.noBorder && '1px solid transparent'};
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const BptMobileTopPanel = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [modalTypeOpen, setModalTypeOpen] = useState(null);

  const closeModal = () => {
    setModalTypeOpen(null);
  };

  return (
    <>
      <TopPanel>
        <StyledButtonLight type="button" cancel onClick={() => setModalTypeOpen('options')}>
          {t('Options')}
        </StyledButtonLight>
        <StyledButtonLight type="button" cancel onClick={() => setModalTypeOpen('upcoming')}>
          {t('Upcoming vessels')}
        </StyledButtonLight>
        <StyledButtonLight type="button" cancel onClick={() => setModalTypeOpen('map')}>
          {t('Map')}
        </StyledButtonLight>
      </TopPanel>

      {modalTypeOpen === 'options' && (
        <Modal title={t('Options')} open={true} width={320} footer={null} onCancel={closeModal}>
          <ModalInner style={{ paddingTop: '12px' }}>
            <BerthPlanningToolTopPanel main={false} />
            <MiddleLine />
            <ActionButtons noBorder={true}>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}

      {modalTypeOpen === 'upcoming' && (
        <Modal title={t('Upcoming vessels')} open={true} width={320} footer={null} onCancel={closeModal}>
          <ModalInner>
            <BerthPlanningToolUpcomingVessels />
            <MiddleLine />
            <ActionButtons noBorder={true}>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}

      {modalTypeOpen === 'map' && (
        <Modal title={t('Map')} open={true} width={1200} footer={null} onCancel={closeModal}>
          <ModalInner>
            <MapContainer>
              <BerthPlanningToolMap
                showAttribution={true}
                showResize={true}
                showCraneOptions={true}
                craneView={false}
              />
            </MapContainer>
            <MiddleLine />
            <ActionButtons noBorder={true}>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}
    </>
  );
};

export default BptMobileTopPanel;
