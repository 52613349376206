import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { UserContext } from '../../context/UserContext';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 18px;
  height: calc(50% - 18px);
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
`;

const IncidentStatistics = ({ yAxel, loading, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const graphData = props.data;
  const newLegend = props.legend;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.6,
    categoryPercentage: 0.8,
    maxBarThickness: 30,
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: 45,
        top: 25,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: yAxel
        ? {
            grid: {
              borderDash: [8, 6],
              color: '#f4f4f4',
            },
          }
        : {
            grid: {
              display: false,
            },
            display: false,
          },
    },
  };

  const datasets = graphData
    ? {
        labels: [''],
        datasets: newLegend.map(l => {
          const value = Object.keys(graphData).find(d => d === l.key);
          return {
            label: l.title,
            data: value ? [graphData[value]] : [0],
            backgroundColor: l.color,
          };
        }),
      }
    : {
        labels: [],
        datasets: [],
      };

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
        </HeaderRow>

        {loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : graphData ? (
          <Bar plugins={[ChartDataLabels]} options={options} data={datasets} />
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default IncidentStatistics;
