import React, { useRef, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useMap } from 'react-leaflet';
import L, { Control } from 'leaflet';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useLeafletContext } from '@react-leaflet/core';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import { METRIC, MILES, NAUTICALMILES, readableDistance } from './MapHelpers';

const InfoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50px;
  z-index: 999;
  background: white;
  cursor: default;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  margin: 2px;

  table tfoot {
    position: sticky;
    bottom: 0;
  }
`;

const Container = styled.div`
  padding: 8px 12px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: move;
  padding: 8px 12px;
`;

const BottomDragRow = styled.div`
  width: 100%;
  height: 6px;
  cursor: move;
`;

const Row = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-weight: 600;
`;

const IconContainer = styled.div`
  margin-left: 4px;
  margin-top: -2px;
  cursor: pointer;
  margin-left: 24px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const HeaderTd = styled.td`
  padding: 6px 12px;
`;

const ValueTd = styled.td`
  padding: 6px 12px;
  font-weight: 600;
`;

const TableContainer = styled.div`
  max-height: 300px;
  min-width: 360px;
  overflow-y: auto;
`;

const EditingButton = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 55;
  bottom: 172px;
  right: 54px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  padding: 6px 12px;
  border-radius: 4px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const RadiusContainer = styled.div`
  display: flex;
`;

const Radius = styled.div`
  padding: 0px 12px;
  border-right: ${props => !props.noBorder && '1px solid #e8e8e8'};
`;

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawCreated: 'draw:created',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onMousemove: 'mousemove',
  onClick: 'click',
};

export const CircleMapToolContainer = ({
  circleDrawing,
  setDrawing,
  setCursorLocation,
  setEditing,
  editing,
  drawing,
  setCircleDrawing,
  center,
  setCenter,
  radius,
  setRadius,
  angle,
  setAngle,
  drawingFinished,
  setDrawingFinished,
  location,
  setLocation,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const context = useLeafletContext();
  const drawRef = useRef();
  const circleDrawingRef = useRef();
  const editingRef = useRef();

  const editableLayersRef = useRef();
  const markersRef = useRef();

  const draggableRef = useRef(null);

  const map = useMap();

  useEffect(() => {
    if (circleDrawing && !circleDrawingRef.current) {
      drawRef.current?._toolbars.draw._modes.circle?.handler.enable();
      setDrawing(true);
    } else if (!circleDrawing && circleDrawingRef.current) {
      context.layerContainer.clearLayers();
      editableLayersRef.current.clearLayers();
      markersRef.current.clearLayers();
      setCenter(null);
      setRadius(0);
      setAngle(0);
      setCursorLocation(null);
      setDrawingFinished(false);
      setEditing(false);
      drawRef.current?._toolbars.draw._modes.circle?.handler.disable();
      drawRef.current?._toolbars.edit._modes.edit.handler.disable();
    }

    circleDrawingRef.current = circleDrawing;
  }, [
    context.layerContainer,
    map,
    circleDrawing,
    setCursorLocation,
    setDrawing,
    setCenter,
    setRadius,
    setAngle,
    setDrawingFinished,
    setEditing,
  ]);

  useEffect(() => {
    return () => {
      context.layerContainer.clearLayers();
      editableLayersRef.current.clearLayers();
      markersRef.current.clearLayers();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editing && !editingRef.current) {
      markersRef.current.clearLayers();
      drawRef.current?._toolbars.edit._modes.edit.handler.enable();
    } else if (!editing && editingRef.current) {
      drawRef.current?._toolbars.edit._modes.edit.handler.disable();
    }

    editingRef.current = editing;
  }, [editing]);

  const clickPreventer = () => {
    const div = L.DomUtil.get('measure-info');
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  };

  const onDrawStart = useCallback(() => {
    context?.layerContainer?.clearLayers();
  }, [context.layerContainer]);

  const onDrawVertex = useCallback(
    e => {
      if (e.radius) {
        setRadius(e.radius);
      }
      if (e.center) {
        setCenter(e.center);
      }
      if (e.angle) {
        setAngle(e.angle);
      }
      if (e.location) {
        setLocation(e.location);
      }
    },
    [setAngle, setCenter, setLocation, setRadius]
  );

  const onDrawStop = useCallback(() => {
    setDrawing(false);
  }, [setDrawing]);

  const onDrawCreate = useCallback(() => {
    setDrawingFinished(true);
  }, [setDrawingFinished]);

  const onCircleClick = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  useEffect(() => {
    if (drawingFinished && center && radius && location) {
      editableLayersRef.current.clearLayers();
      markersRef.current.clearLayers();
      setDrawingFinished(false);

      const drawnCircle = L.circle(center, {
        radius,
        color: '#4990dd',
        weight: '2',
        opacity: '1',
        stroke: '#4990dd',
      });
      const centerCircle = L.circleMarker(center, {
        radius: 3,
        fillColor: 'navy',
        fillOpacity: 0.8,
        opacity: 1,
        color: 'navy',
        weight: '1',
      });
      const handleCircle = L.circleMarker(location, {
        radius: 3,
        fillColor: 'navy',
        fillOpacity: 0.8,
        opacity: 1,
        color: 'navy',
        weight: '1',
      });

      editableLayersRef.current.addLayer(drawnCircle);
      markersRef.current.addLayer(centerCircle);
      markersRef.current.addLayer(handleCircle);

      setTimeout(() => {
        drawnCircle.on('click', onCircleClick);
      }, 200);
    } else if (drawingFinished && !center && circleDrawing) {
      setDrawingFinished(false);
      drawRef.current?._toolbars.draw._modes.circle?.handler.enable();
    }
  }, [center, circleDrawing, drawingFinished, location, onCircleClick, radius, setDrawingFinished]);

  const onEditResize = useCallback(
    e => {
      if (e.radius) {
        setRadius(e.radius);
      }
      if (e.center) {
        setCenter(e.center);
      }
      if (e.angle) {
        setAngle(e.angle);
      }
      if (e.location) {
        setLocation(e.location);
      }
    },
    [setAngle, setCenter, setLocation, setRadius]
  );

  const onEditMove = useCallback(
    e => {
      if (e.center) {
        setCenter(e.center);
      }
    },
    [setCenter]
  );

  useEffect(() => {
    const layers = new L.FeatureGroup();
    const markers = new L.FeatureGroup();
    map.addLayer(layers);
    map.addLayer(markers);
    editableLayersRef.current = layers;
    markersRef.current = markers;

    map.on(eventHandlers.onDrawStart, onDrawStart);
    map.on(eventHandlers.onDrawVertex, onDrawVertex);
    map.on(eventHandlers.onDrawStop, onDrawStop);
    map.on(eventHandlers.onDrawCreated, onDrawCreate);

    map.on(eventHandlers.onEditResize, onEditResize);
    map.on(eventHandlers.onEditMove, onEditMove);
    drawRef.current = createDrawElement(layers);
    map.addControl(drawRef.current);

    return () => {
      map.off(eventHandlers.onDrawStart);
      map.off(eventHandlers.onDrawVertex);
      map.off(eventHandlers.onDrawStop);
      map.off(eventHandlers.onDrawCreated);

      drawRef.current.remove(map);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDrag = e => {
    e.stopPropagation();
  };

  const startEditing = () => {
    setEditing(true);
  };

  const stopEditing = () => {
    setDrawingFinished(true);
    setEditing(false);
  };

  if (!circleDrawing) {
    return null;
  }

  L.Draw.Circle.include({
    _onMouseMove: function(e) {
      let latlng = e.latlng,
        showRadius = this.options.showRadius,
        useMetric = this.options.metric,
        radius;

      this._tooltip?.updatePosition(latlng);
      if (this._isDrawing) {
        this._drawShape(latlng);

        // Get the new radius (rounded to 1 dp)
        radius = this._shape.getRadius().toFixed(1);

        const angle =
          (Math.atan2(latlng.lat - this._startLatLng.lat, latlng.lng - this._startLatLng.lng) * 180) / Math.PI - 90;

        this._map.fire(L.Draw.Event.DRAWVERTEX, {
          radius: this._shape.getRadius(),
          center: this._startLatLng,
          angle: angle >= -270 && angle < 0 ? angle * -1 : 360 - angle,
          location: latlng,
        });

        let subtext = '';
        if (showRadius) {
          subtext =
            L.drawLocal.draw.handlers.circle.radius +
            ': ' +
            L.GeometryUtil.readableDistance(radius * 1, useMetric, this.options.feet, this.options.nautic);
        }
        this._tooltip?.updateContent({
          text: this._endLabelText,
          subtext: subtext,
        });
      }
    },
  });

  L.Edit.Circle.include({
    _resize: function(latlng) {
      let moveLatLng = this._moveMarker.getLatLng();
      let radius = 0;

      // Calculate the radius based on the version
      if (L.GeometryUtil.isVersion07x()) {
        radius = moveLatLng.distanceTo(latlng);
      } else {
        radius = this._map.distance(moveLatLng, latlng);
      }
      this._shape.setRadius(radius);

      if (this._map._editTooltip) {
        this._map._editTooltip.updateContent({
          text: L.drawLocal.edit.handlers.edit.tooltip.subtext + '<br />' + L.drawLocal.edit.handlers.edit.tooltip.text,
          subtext:
            L.drawLocal.draw.handlers.circle.radius +
            ': ' +
            L.GeometryUtil.readableDistance(radius, true, this.options.feet, this.options.nautic),
        });
      }

      this._shape.setRadius(radius);
      const angle = (Math.atan2(moveLatLng.lat - latlng.lat, moveLatLng.lng - latlng.lng) * 180) / Math.PI + 90;

      this._map.fire(L.Draw.Event.EDITRESIZE, {
        layer: this._shape,
        angle: angle >= -270 && angle < 0 ? angle * -1 : 360 - angle,
        radius,
        location: latlng,
      });
    },
    _getResizeMarkerPoint: function(latlng) {
      if (location) {
        return location;
      } else {
        let point = this._map.project(latlng);

        return this._map.unproject([
          point.x + this._shape._radius * Math.cos((angle - 90) * (Math.PI / 180)),
          point.y + this._shape._radius * Math.sin((angle - 90) * (Math.PI / 180)),
        ]);
      }
    },
    _createMoveMarker: function() {
      let center = this._shape.getLatLng();

      let icon = new L.DivIcon({
        iconSize: new L.Point(4, 4),
        className: 'leaflet-div-icon leaflet-editing-icon leaflet-circle-center-round',
      });

      let marker = new L.Marker(center, {
        draggable: false,
        icon,
        zIndexOffset: 10,
      });

      this._bindMarker(marker);

      this._markerGroup.addLayer(marker);
      this._moveMarker = marker;
    },
  });

  return (
    <>
      {!drawing && !editing && <EditingButton onClick={() => startEditing()}>{t('Edit circle')}</EditingButton>}
      {!drawing && editing && <EditingButton onClick={() => stopEditing()}>{t('Stop editing')}</EditingButton>}
      <Draggable onDrag={onDrag} handle=".measure-info-drag-handle" bounds="parent" nodeRef={draggableRef}>
        <InfoContainer id="measurement-info-container" ref={draggableRef}>
          <TopRow className="measure-info-drag-handle">
            <Title>{t('Circle')}</Title>
            <IconContainer className="close" href="#" onClick={() => setCircleDrawing(false)}>
              <Icon type="close" />
            </IconContainer>
          </TopRow>

          <Container id="measure-info" onClick={() => clickPreventer()}>
            <Row>
              <TableContainer>
                <table>
                  <tbody>
                    <tr>
                      <HeaderTd>{t('Radius')}:</HeaderTd>
                      <ValueTd>
                        <RadiusContainer>
                          <Radius style={{ paddingLeft: '0px' }}>{readableDistance(radius, METRIC)}</Radius>
                          <Radius>{readableDistance(radius, MILES)}</Radius>
                          <Radius noBorder={true}>{readableDistance(radius, NAUTICALMILES)}</Radius>
                        </RadiusContainer>
                      </ValueTd>
                    </tr>
                    <tr>
                      <HeaderTd>{t('Center (lat, lng)')}:</HeaderTd>
                      <ValueTd>
                        {center?.lat.toFixed(7)}, {center?.lng.toFixed(7)}
                      </ValueTd>
                    </tr>
                    <tr>
                      <HeaderTd>{t('Angle')}:</HeaderTd>
                      <ValueTd>{angle.toFixed(2)}°</ValueTd>
                    </tr>
                  </tbody>
                </table>
              </TableContainer>
            </Row>
          </Container>
          <BottomDragRow className="measure-info-drag-handle" />
        </InfoContainer>
      </Draggable>
    </>
  );
};

function createDrawElement(context) {
  const options = {
    edit: {
      edit: {
        selectedPathOptions: {
          maintainColor: true,
        },
      },
      remove: false,
      featureGroup: context,
    },
  };

  options.draw = {
    rectangle: false,
    polyline: false,
    circle: {
      shapeOptions: {
        color: '#4990dd',
        weight: 2,
        radius: 2,
        fillOpacity: 0.2,
        opacity: 1,
      },
    },
    circlemarker: false,
    marker: false,
    polygon: false,
  };

  options.position = 'topright';

  return new Control.Draw(options);
}

export default CircleMapToolContainer;
