import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Popconfirm from 'antd/es/popconfirm';
import Switch from 'antd/es/switch';
import App from 'antd/es/app';
import Checkbox from 'antd/es/checkbox';
import Slider from 'antd/es/slider';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import LocalInput from '../../components/ui/Input';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import DebounceAutocomplete from '../ui/DebounceAutocomplete';
import DateComponent from '../ui/DateComponent';

const { TextArea } = Input;

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const InfoValueNumber = styled.div`
  color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const Content = styled.p`
  white-space: nowrap;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const IntSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0 4px 0;
  gap: 10px;
  padding: 0px 12px 12px 12px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 98%;
  .ant-slider-track {
    background-color: ${props => (props.level > 7 ? '#ffa0a0' : props.level < 4 ? '#96e696' : '#ffdb64')};
  }
  .ant-slider-track:hover {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    color: ${props => (props.level > 7 ? '#ffa0a0' : props.level < 4 ? '#96e696' : '#ffdb64')};
  }
  .ant-slider-handle {
    color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    border: solid 2px ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle:hover {
    color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    border: solid 2px ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle:focus {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    outline: none;
    box-shadow: 0 0 0 5px #1890ff1f;
  }
  .ant-slider-mark {
    font-size: 11px;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-dot {
    position: absolute;
    width: 1px;
    height: 8px;
    margin-left: -2px;
    background-color: #cfebff;
    border: 0;
    border-radius: 0%;
    cursor: pointer;
  }
  .ant-slider-dot-active {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
`;

const ALERT_BASE_URL = 'alert';

const ALERT_TYPES = [
  {
    value: 'general',
    name: 'general',
  },
  {
    value: 'incident',
    name: 'incident',
  },
  {
    value: 'event',
    name: 'event',
  },
];

const SLIDER_OPTIONS = {
  min: 1,
  max: 10,
  step: 1,
  marks: {
    1: {
      style: {
        color: '#80d183',
        fontWeight: 600,
      },
      label: (
        <>
          <div>1</div>
          <div>Minor</div>
        </>
      ),
    },
    2: {
      style: {
        color: '#80d183',
        fontWeight: 600,
      },
      label: '2',
    },
    3: {
      style: {
        color: '#80d183',
        fontWeight: 600,
      },
      label: '3',
    },
    4: {
      style: {
        color: '#F5BD3E',
        fontWeight: 600,
      },
      label: '4',
    },
    5: {
      style: {
        color: '#F5BD3E',
        fontWeight: 600,
      },
      label: (
        <>
          <div>5</div>
          <div>Medium</div>
        </>
      ),
    },
    6: {
      style: {
        color: '#F5BD3E',
        fontWeight: 600,
      },
      label: '6',
    },
    7: {
      style: {
        color: '#F5BD3E',
        fontWeight: 600,
      },
      label: '7',
    },
    8: {
      style: {
        color: '#F44336',
        fontWeight: 600,
      },
      label: '8',
    },
    9: {
      style: {
        color: '#F44336',
        fontWeight: 600,
      },
      label: '9',
    },
    10: {
      style: {
        color: '#F44336',
        fontWeight: 600,
      },
      label: (
        <>
          <div>10</div>
          <div>Major</div>
        </>
      ),
    },
  },
};

const Alerts = () => {
  const { apiCall, modules, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [alertsData, setAlertsData] = useState(null);
  const [alertsError, setAlertsError] = useState(null);
  const [alertsLoading, setAlertsLoading] = useState(null);
  const [incidentEditSearch, setIncidentEditSearch] = useState(null);

  const { message: alertMessage } = App.useApp();

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initAlert = {
    id: null,
    type: '',
    type_id: '',
    level: '',
    message: '',
    message_body: '',
    active: true,
    created_at: '',
    modified_at: '',
    created_by: '',
    modified_by: '',
  };

  const [alert, setAlert] = useState(initAlert);

  const initModal = {
    visible: false,
    confirmLoading: false,
    alert: {
      id: null,
      type: '',
      type_id: '',
      level: '',
      message: '',
      message_body: '',
      active: true,
      created_at: '',
      modified_at: '',
      created_by: '',
      modified_by: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'type',
    search: params.get('search') ? params.get('search') : '',
  };

  // Check incident permission
  if (modules.incident_module !== 'enabled' || !user.permissions.includes('manage_incident')) {
    const incidentIndex = ALERT_TYPES.findIndex(item => item.value === 'incident');
    if (incidentIndex > -1) {
      ALERT_TYPES.splice(incidentIndex, 1);
    }
    const eventIndex = ALERT_TYPES.findIndex(item => item.value === 'event');
    if (eventIndex > -1) {
      ALERT_TYPES.splice(eventIndex, 1);
    }
  }

  const alertsChanged = useCallback(data => {
    if (mounted.current) {
      if (data) {
        setAlertsData(data);
      } else {
        setAlertsData(null);
      }
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: alertsFetchLoading, error: alertsFetchError, fetchData: alertsFetchData } = useApi(
    'get',
    'alerts',
    newParams,
    alertsChanged,
    false
  );

  useEffect(() => {
    if (mounted.current) {
      alertsFetchData(false, newParams);
    }
  }, [newParams, alertsFetchData]);

  useEffect(() => {
    if (mounted.current) {
      setAlertsError(alertsFetchError);
    }
  }, [alertsFetchError]);

  useEffect(() => {
    if (mounted.current) {
      setAlertsLoading(alertsFetchLoading);
    }
  }, [alertsFetchLoading]);

  if (alertsError) {
    alertMessage.error(alertsError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const alerts = alertsLoading || !alertsData?.['alerts'] ? [] : alertsData['alerts'];

  const { start, total } = alertsError || !alertsData ? { start: 0, total: 0 } : alertsData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', `${ALERT_BASE_URL}/${entryIdentifier}`);
      if (mounted.current) {
        setAlert({
          ...data,
        });
        if (data.type === 'incident' || data.type === 'event') {
          const res = await apiCall('get', `incident/${data.type_id}`);
          if (res.data?.incident) {
            if (res.data.incident.type === 'incident') {
              setIncidentEditSearch(
                `(${res.data.incident.level_of_emergency}) ${res.data.incident.incident_type_name} - ${res.data.incident.location_option_name}: ${res.data.incident.details}`
              );
            } else if (res.data.incident.type === 'event') {
              setIncidentEditSearch(`${res.data.incident.details}`);
            }
          }
        }
        setModal({ ...initModal, visible: true });
      }
    } catch (e) {
      if (mounted.current) {
        setApiCallPending(false);
      }
      throw e;
    }
    if (mounted.current) {
      setApiCallPending(false);
    }
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${ALERT_BASE_URL}/${entryIdentifier}`);
    } catch (e) {
      if (mounted.current) {
        setApiCallPending(false);
      }
      throw e;
    }
    if (mounted.current) {
      showActions(false);
      setApiCallPending(false);
      history.replace(location.pathname);
      await alertsFetchData(false, defaultParams);
    }
  };

  const handleSave = async values => {
    const { type, type_id, level, message, message_body, active } = values;
    const result = await apiCall('post', `${ALERT_BASE_URL}`, {
      type: type,
      type_id: type_id,
      level: level,
      message: message,
      message_body: message_body,
      active: active,
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      alertMessage.error(result.data.message, 4);
    } else {
      if (mounted.current) {
        setAlert(initAlert);
        handleSetMultiple(initAlert);
        setIncidentEditSearch(null);
        history.replace(location.pathname);
        alertsFetchData();
      }
    }
    if (mounted.current) {
      showActions(false);
    }
  };

  const handleCancel = e => {
    e.preventDefault();
    if (mounted.current) {
      showActions(false);
      setAlert(initAlert);
      handleSetMultiple(initAlert);
      setIncidentEditSearch(null);
    }
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${ALERT_BASE_URL}`, {
        id: alert.id,
        type: alert.type ? alert.type : '',
        type_id: alert.type_id ? alert.type_id : '',
        level: alert.level ? alert.level : '',
        message: alert.message ? alert.message : '',
        message_body: alert.message_body ? alert.message_body : '',
        active: alert.active ? alert.active : false,
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        alertMessage.error(result.data.message, 4);
      }
    } catch (e) {
      if (mounted.current) {
        setModal({
          ...modal,
          confirmLoading: false,
        });
        setApiCallPending(false);
      }
      throw e;
    }
    if (mounted.current) {
      setApiCallPending(false);
      setModal({
        ...initModal,
        visible: false,
        confirmLoading: false,
      });
      showActions(false);
      setAlert(initAlert);
      handleSetMultiple(initAlert);
      setIncidentEditSearch(null);
      history.replace(location.pathname);
      await alertsFetchData(false, defaultParams);
    }
  };

  const handleModalCancel = async () => {
    if (mounted.current) {
      setModal(initModal);
      setAlert(initAlert);
      handleSetMultiple(initAlert);
      setIncidentEditSearch(null);
    }
  };

  const handleModalChange = e => {
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === 'active') {
      newValue = checked;
    }
    if (mounted.current) {
      setAlert(previous => ({ ...previous, [name]: newValue }));
    }
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === 'active') {
      newValue = checked;
      handleCheckbox(e);
    }
    if (mounted.current) {
      setAlert(previous => ({ ...previous, [name]: newValue }));
    }
  };

  const handleAddNewTypeChange = value => {
    if (mounted.current) {
      setAlert(previous => {
        if (previous.type !== value) {
          handleSetMultiple({
            ...initAlert,
            type: value,
          });
          return {
            ...initAlert,
            type: value,
          };
        }
        handleSetMultiple({
          ...previous,
          type: value,
        });
        return {
          ...previous,
          type: value,
        };
      });
    }
  };

  const handleChangeAlertActive = async (incident, value) => {
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${ALERT_BASE_URL}`, {
        id: incident.id,
        type: incident.type,
        type_id: incident.type_id,
        level: incident.level,
        message: incident.message,
        message_body: incident.message_body || '',
        active: value,
      });
      if (mounted.current) {
        setApiCallPending(false);
      }
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        alertMessage.error(result.data.message, 4);
      }
    } catch (e) {
      if (mounted.current) {
        setApiCallPending(false);
      }
    }
    if (mounted.current) {
      await alertsFetchData(false, defaultParams);
    }
  };

  const handleAddNewIncident = (value, obj) => {
    setAlert(previous => {
      return {
        ...previous,
        type_id: obj?.data?.id || null,
        level: obj?.data?.level_of_emergency || 0,
      };
    });
    handleSetMultiple({
      type_id: obj?.data?.id || null,
      level: obj?.data?.level_of_emergency || 0,
    });
    setIncidentEditSearch(value);
  };

  const handleModalIncidentChange = (value, obj) => {
    setAlert(previous => {
      return {
        ...previous,
        type_id: obj?.data?.id || null,
        level: obj?.data?.level_of_emergency || 0,
      };
    });
    setIncidentEditSearch(value);
  };

  const fetchIncidents = async (value, type) => {
    const params = {
      query: {
        text: value,
        conditions: {
          and: [
            {
              type: 'status',
              operator: 'is',
              value: 'open',
            },
            {
              type: 'type',
              operator: 'is',
              value: type,
            },
          ],
        },
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [
        {
          field: 'details',
          order: 'ASC',
        },
      ],
    };

    try {
      const result = await apiCall('post', 'incident/search', params);
      if (result?.status === 200) {
        return result.data?.results?.['incidents']?.map(d => {
          if (d.type === 'incident') {
            return {
              key: d.id,
              value: `(${d.level_of_emergency}) ${d.incident_type_name} - ${d.location_option_name}: ${d.details}`,
              label: `(${d.level_of_emergency}) ${d.incident_type_name} - ${d.location_option_name}: ${d.details}`,
              data: d,
            };
          } else if (d.type === 'event') {
            return {
              key: d.id,
              value: `${d.details}`,
              label: `${d.details}`,
              data: d,
            };
          }
        });
      }
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }

    return [];
  };

  const tipFormatter = value => (value > 7 ? t('Major') : value < 4 ? t('Minor') : t('Medium'));

  const fields = ['type', 'type_id', 'level', 'message', 'message_body', 'active'];
  const { values, handleChange, handleSubmit, handleCheckbox, handleSetMultiple } = useForm(fields, handleSave, {
    type: null,
    active: true,
  });

  useEffect(() => {
    let isValid = false;
    switch (alert.type) {
      case 'incident':
      case 'event':
        isValid = alert?.type_id > 0;
        break;
      case 'general':
        isValid = alert?.message?.length > 0 && alert.level > 0;
        break;
      default:
        break;
    }
    if (mounted.current) {
      setApplyChangesDisabled(!isValid);
    }
  }, [setApplyChangesDisabled, alert]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Type'),
      key: 'type',
      sorter: (a, b) => a.type?.localeCompare?.(b.type),
      render: record => {
        switch (record.type) {
          case 'incident':
          case 'event':
            return (
              <Link to={`/incident-and-event/${record.type_id}`}>
                <Content>{`incident-and-event/${record.type_id}`}</Content>
              </Link>
            );
          case 'general':
            return record.type;
          default:
            break;
        }
      },
    },
    {
      title: t('Level'),
      dataIndex: 'level',
      key: 'level',
      sortableKey: 'level',
      render: record => {
        return <InfoValueNumber level={record}>{record}</InfoValueNumber>;
      },
    },
    {
      title: t('Message'),
      dataIndex: 'message',
      key: 'message',
      sortableKey: 'message',
    },
    {
      title: t('Message body'),
      dataIndex: 'message_body',
      key: 'message_body',
      sortableKey: 'message_body',
    },
    {
      title: t('Is active'),
      key: 'active',
      sorter: (a, b) => Number(a.active || false) - Number(b.active || false),
      render: record => {
        return (
          <Switch
            disabled={
              (record.type === 'incident' || record.type === 'event') &&
              (modules.incident_module !== 'enabled' || !user.permissions.includes('manage_incident'))
            }
            checked={record.active}
            onChange={checked => handleChangeAlertActive(record, checked)}
          ></Switch>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'modified_at',
      sortableKey: 'modified_at',
      render: record => {
        let modified_by = record?.modified_by || '';
        if (record?.modified_by_name && record?.modified_by_email) {
          modified_by = `${record.modified_by_name} (${record.modified_by_email})`;
        } else if (record?.modified_by_name) {
          modified_by = record.modified_by_name;
        } else if (record?.modified_by_email) {
          modified_by = record.modified_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.modified_at} />
              </Content2>
            )}
            <Content2>{modified_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={
            (record.type === 'incident' || record.type === 'event') &&
            (modules.incident_module !== 'enabled' || !user.permissions.includes('manage_incident'))
          }
          key="action-1"
          onClick={() => showModal(record.id)}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete alert {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              disabled={
                (record.type === 'incident' || record.type === 'event') &&
                (modules.incident_module !== 'enabled' || !user.permissions.includes('manage_incident'))
              }
              red
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => {
        showActions(!actions);
      },
      disabled: alertsLoading,
      text: t('Add alert'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <Modal
        title={t('Edit alert')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            {alert.type === 'incident' ? (
              <InputContainer>
                <InputLabel>{t('Incident')}</InputLabel>
                <DebounceAutocomplete
                  placeholder={t('Search for incident')}
                  fetchOptions={value => fetchIncidents(value, values['type'])}
                  onChange={(value, data) => handleModalIncidentChange(value, data)}
                  onSelect={(value, data) => handleModalIncidentChange(value, data)}
                  value={incidentEditSearch}
                  style={{ width: '100%' }}
                  allowClear={true}
                  onClear={() => handleModalIncidentChange('', null)}
                />
              </InputContainer>
            ) : null}
            {alert.type === 'event' ? (
              <InputContainer>
                <InputLabel>{t('Event')}</InputLabel>
                <DebounceAutocomplete
                  placeholder={t('Search for event')}
                  fetchOptions={value => fetchIncidents(value, values['type'])}
                  onChange={(value, data) => handleModalIncidentChange(value, data)}
                  onSelect={(value, data) => handleModalIncidentChange(value, data)}
                  value={incidentEditSearch}
                  style={{ width: '100%' }}
                  allowClear={true}
                  onClear={() => handleModalIncidentChange('', null)}
                />
              </InputContainer>
            ) : null}
            {alert.type !== 'incident' && alert.type !== 'event' ? (
              <InputContainer>
                <InputLabel>{t('Level')}</InputLabel>
                <IntSliderContainer>
                  <SliderContainer level={alert.level ? alert.level : 1} active>
                    <Slider
                      min={SLIDER_OPTIONS.min}
                      max={SLIDER_OPTIONS.max}
                      marks={SLIDER_OPTIONS.marks}
                      value={alert.level ? alert.level : 1}
                      step={SLIDER_OPTIONS.step}
                      onChange={value =>
                        handleModalChange({
                          target: {
                            value: value,
                            name: 'level',
                          },
                        })
                      }
                      tooltip={{ open: true, tipFormatter }}
                      style={{ width: '100%' }}
                    />
                  </SliderContainer>
                </IntSliderContainer>
              </InputContainer>
            ) : null}
            {alert.type !== 'incident' && alert.type !== 'event' ? (
              <InputContainer>
                <InputLabel>{t('Message (banner text)')}</InputLabel>
                <TextArea
                  label={t('Message')}
                  name="message"
                  field="message"
                  value={alert.message}
                  onChange={handleModalChange}
                  style={{ width: '100%' }}
                  autoSize
                />
              </InputContainer>
            ) : null}
            {alert.type !== 'incident' && alert.type !== 'event' ? (
              <InputContainer>
                <InputLabel>{t('Message body (supports markdown)')}</InputLabel>
                <TextArea
                  label={t('Message body')}
                  name="message_body"
                  field="message_body"
                  value={alert.message_body}
                  onChange={handleModalChange}
                  style={{ width: '100%' }}
                  autoSize
                />
              </InputContainer>
            ) : null}
            <Checkbox
              name="active"
              checked={alert.active ? alert.active : false}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              value="1"
            >
              {t('Is active')}
            </Checkbox>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add alert')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', width: '500px' }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'active') {
                return (
                  <Checkbox
                    key={field}
                    name={field}
                    field={field}
                    checked={!!values[field]}
                    value="1"
                    onChange={handleAddNewChange}
                  >
                    {t('Is active')}
                  </Checkbox>
                );
              } else if (field === 'type') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Type')}</InputLabel>
                    <Select
                      placeholder={t('Select type')}
                      optionFilterProp="label"
                      onChange={value => handleAddNewTypeChange(value)}
                      style={{ width: '100%' }}
                      options={ALERT_TYPES.map(option => {
                        return {
                          label: option.name,
                          value: option.value,
                        };
                      })}
                      value={values[field]}
                    />
                  </InputContainer>
                );
              } else if (field === 'type_id') {
                if (values['type'] === 'incident') {
                  return (
                    <InputContainer key={field}>
                      <InputLabel>{t('Incident')}</InputLabel>
                      <DebounceAutocomplete
                        placeholder={t('Search for incident')}
                        fetchOptions={value => fetchIncidents(value, values['type'])}
                        onChange={(value, data) => handleAddNewIncident(value, data)}
                        onSelect={(value, data) => handleAddNewIncident(value, data)}
                        value={incidentEditSearch}
                        style={{ width: '100%' }}
                        allowClear={true}
                        onClear={() => handleAddNewIncident('', null)}
                      />
                    </InputContainer>
                  );
                } else if (values['type'] === 'event') {
                  return (
                    <InputContainer key={field}>
                      <InputLabel>{t('Event')}</InputLabel>
                      <DebounceAutocomplete
                        placeholder={t('Search for event')}
                        fetchOptions={value => fetchIncidents(value, values['type'])}
                        onChange={(value, data) => handleAddNewIncident(value, data)}
                        onSelect={(value, data) => handleAddNewIncident(value, data)}
                        value={incidentEditSearch}
                        style={{ width: '100%' }}
                        allowClear={true}
                        onClear={() => handleAddNewIncident('', null)}
                      />
                    </InputContainer>
                  );
                }
              } else if (field === 'level') {
                if (values['type'] !== 'incident' && values['type'] !== 'event') {
                  return (
                    <InputContainer key={field}>
                      <InputLabel>{t('Level')}</InputLabel>
                      <IntSliderContainer>
                        <SliderContainer level={values[field] ? values[field] : 1} active>
                          <Slider
                            min={SLIDER_OPTIONS.min}
                            max={SLIDER_OPTIONS.max}
                            marks={SLIDER_OPTIONS.marks}
                            value={values[field] ? values[field] : 1}
                            step={SLIDER_OPTIONS.step}
                            onChange={value =>
                              handleAddNewChange({
                                target: {
                                  value: value,
                                  name: field,
                                },
                              })
                            }
                            tooltip={{ open: true, tipFormatter }}
                            style={{ width: '100%' }}
                          />
                        </SliderContainer>
                      </IntSliderContainer>
                    </InputContainer>
                  );
                }
              } else if (field === 'message') {
                if (values['type'] !== 'incident' && values['type'] !== 'event') {
                  return (
                    <InputContainer key={field}>
                      <InputLabel>{t('Message (banner text)')}</InputLabel>
                      <TextArea
                        label={t('Message')}
                        name={field}
                        field={field}
                        value={values[field]}
                        onChange={handleAddNewChange}
                        style={{ width: '100%' }}
                        autoSize
                      />
                    </InputContainer>
                  );
                }
              } else if (field === 'message_body') {
                if (values['type'] !== 'incident' && values['type'] !== 'event') {
                  return (
                    <InputContainer key={field}>
                      <InputLabel>{t('Message body (supports markdown)')}</InputLabel>
                      <TextArea
                        label={t('Message body')}
                        name={field}
                        field={field}
                        value={values[field]}
                        onChange={handleAddNewChange}
                        style={{ width: '100%' }}
                        autoSize
                      />
                    </InputContainer>
                  );
                }
              } else {
                return (
                  <LocalInput
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="text"
                    onChange={handleAddNewChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add alert')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={alerts}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={alertsLoading}
        setParams={params => {
          if (mounted.current) {
            setNewParams(params);
          }
        }}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
      />
    </>
  );
};

export default Alerts;
