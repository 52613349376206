import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { Chart, registerables } from 'chart.js';
import { darken } from 'polished';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import { UserContext } from '../../context/UserContext';

import shipIconSource from '../../images/icons/ship-bg.svg';
import { externalTooltipHandler } from '../statistics/graphHelpers';

Chart.register(...registerables);

const Container = styled.div`
  height: 32%;
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${props => (props.inModal ? null : props.theme.fx.box_shadow)};
  border-radius: ${props => (props.inModal ? null : props.theme.style.border_radius)};
  border: ${props => (props.inModal ? null : '1px solid transparent')};
  padding: 0 12px;
  margin-bottom: 14px;

  .ant-select-show-arrow {
    height: 24px !important;
  }

  @media (max-height: 800px) {
    display: none;
  }
`;

const cursorLinePlugin = {
  afterDatasetsDraw: chart => {
    if (chart.tooltip?._active?.length) {
      let x = chart.tooltip._active[0].element.x;
      let yAxis = chart.scales.y;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#4a4a4a';
      ctx.stroke();
      ctx.restore();
    }
  },
};

const SpeedModalTimeGraphMini = ({ auxData, dataList, hoverData, setHoverData, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const shipIcon = new Image(24, 24);
  shipIcon.src = shipIconSource;

  const lastOfDataList = dataList.slice(-1);
  const firstOfDataList = dataList.slice(1);

  let timespan = 48;
  let datasets = [];

  if (dataList && dataList.length > 1) {
    timespan = dayjs.duration(dayjs(lastOfDataList[0].time).diff(dayjs(firstOfDataList[0].time))).asHours();
  }

  let auxList = [];

  if (auxData?.timestamps && Object.keys(auxData.timestamps).length > 0) {
    datasets.push({
      label: t('Timestamps'),
      lineAtIndex:
        auxData?.timestamps && Object.keys(auxData.timestamps).length > 0
          ? Object.keys(auxData.timestamps).map(t => auxData.timestamps[t])
          : [],
      data:
        auxData?.timestamps && Object.keys(auxData.timestamps).length > 0
          ? Object.keys(auxData.timestamps).map((key, i) => {
            let value = 0;
            if (dataList && dataList.length > 0) {
              let index = dataList.findIndex(o => {
                return o.timestamp >= auxData.timestamps[key].timestamp;
              });
              auxList.push({ aux: i, index });
              let prev = dataList[index - 1];
              let next = dataList[index];
              if (prev && (prev.speed || prev.speed === 0) && next && (next.speed || next.speed === 0)) {
                let slope = (next.speed - prev.speed) / (next.timestamp - prev.timestamp);
                value = prev.speed + (auxData.timestamps[key].timestamp - prev.timestamp) * slope;
              }
            }
            return { y: value, x: dayjs(auxData.timestamps[key].time) };
          })
          : [],
      backgroundColor: '#81C784',
      borderColor: '#81C784',
      pointBackgroundColor: '#81C784',
      pointBorderColor: '#ffffff',
      datalabels: {
        align: 'end',
        anchor: 'end',
      },
      pointStyle: 'circle',
      pointRadius: 4,
      pointBorderWidth: 2,
      borderWidth: 0,
      pointHitRadius: 1,
      order: 1,
    });
  }

  let dataOptions = {
    type: 'line',
    labels: [...dataList.map(f => dayjs(f.time))],
    datasets: [
      ...datasets,
      {
        label: props.legend['speed'].title,
        data: dataList.map(f => {
          return { y: f['speed'], x: dayjs(f.time) };
        }),
        backgroundColor: darken(0.2, '#A7C4F4'),
        borderColor: darken(0.2, '#A7C4F4'),
        pointBackgroundColor: darken(0.2, '#A7C4F4'),
        pointBorderColor: darken(0.2, '#A7C4F4'),
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
        pointStyle: 'circle',
        pointRadius: 1,
        borderWidth: 3,
        pointBorderWidth: 0,
        pointHitRadius: 1,
        order: 4,
      },
      {
        label: t('Latest speed'),
        data: lastOfDataList.map(f => {
          return { y: f.speed, x: dayjs(f.time) };
        }),
        backgroundColor: darken(0.2, '#A7C4F4'),
        borderColor: darken(0.2, '#A7C4F4'),
        pointBackgroundColor: darken(0.2, '#A7C4F4'),
        pointBorderColor: '#ffffff',
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
        pointStyle: shipIcon,
        pointRadius: 6,
        borderWidth: 3,
        pointBorderWidth: 2,
        pointHitRadius: 1,
        order: 2,
      },
    ],
  };

  let timeMin = null;
  let timeMax = null;

  if (dataList && dataList.length > 0) {
    const diff = dayjs(dataList[dataList.length - 1].time).diff(dataList[0].time);
    if (diff > 0) {
      timeMin = dayjs(dataList[0].time).add(diff * -0.1, 'milliseconds');
      timeMax = dayjs(dataList[dataList.length - 1].time).add(diff * 0.2, 'milliseconds');
    }
  }

  const options = {
    interaction: {
      axis: 'x',
      mode: 'x',
      intersect: false,
    },
    onHover: (event, array) => {
      if (array.length === 0) {
        setHoverData(undefined);
      } else {
        if (auxData?.timestamps && Object.keys(auxData.timestamps).length > 0 && array[0].datasetIndex === 0) {
          const rightIndex = auxList.find(d => d.aux === array[0].index);
          if (rightIndex) {
            setHoverData(rightIndex?.index);
          }
        } else {
          setHoverData(array[0].index);
        }
      }
    },
    clip: 10,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 30,
        bottom: 15,
        top: 25,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [3, 3],
        },
        display: true,
        suggestedMin: 0,
        suggestedMax: 30,
        title: {
          text: props['y-axis']?.title || '',
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
        min: timeMin,
        max: timeMax,
        type: 'time',
        time: {
          displayFormats: {
            day: 'DD.MM',
            hour: 'DD.MM HH:mm',
            minute: 'DD.MM HH:mm',
          },
          unit: timespan >= 48 ? 'day' : 'hour',
          unitStepSize: timespan >= 48 ? 1 : 4,
        },
        ticks: {
          maxTicksLimit: 12,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: e => externalTooltipHandler(e, t),
        callbacks: {
          label: function(context) {
            if (context.dataset.label === t('Timestamps')) {
              return (
                t(context.dataset.lineAtIndex[context.dataIndex].label) +
                ': ' +
                dayjs(context.dataset.lineAtIndex[context.dataIndex].value).format('DD.MM.YYYY HH:mm')
              );
            } else {
              return t(context.dataset.label) + ': ' + context.formattedValue + ' Knots';
            }
          },
          title: function(context) {
            return context.map(c => dayjs(c.parsed.x).format('DD.MM.YYYY HH:mm'));
          },
        },
      },
      legend: {
        title: {
          padding: {
            right: 5,
          },
        },
        labels: {
          boxWidth: 20,
          boxHeight: 5,
          usePointStyle: true,
          generateLabels: chart => {
            return chart.data.datasets.map((set, i) => {
              return {
                pointStyle: i === chart.data.datasets.length - 1 ? shipIcon : 'circle',
                datasetIndex: i,
                text: set.label + '  ',
                fillStyle: set.backgroundColor,
                strokeStyle: set.backgroundColor,
                hidden: !chart.isDatasetVisible(i),
              };
            });
          },
          filter: item => {
            return !item.text.includes('Hover');
          },
        },
        position: 'bottom',
      },
      datalabels: {
        display: function() {
          return false;
        },
      },
    },
  };

  const hoverDataSet = hoverData
    ? [
        {
          label: 'Hover',
          data: [{ y: dataList[hoverData].speed, x: dayjs(dataList[hoverData].time) }],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: '#55d366',
          datalabels: {
            align: 'end',
            anchor: 'end',
          },
          pointStyle: 'circle',
          pointRadius: 4,
          pointBorderWidth: 4,
          order: 1,
          animation: {
            duration: 0,
          },
        },
      ]
    : [];

  dataOptions.datasets = [...hoverDataSet, ...dataOptions.datasets];

  let destinations = [dataList[0]];
  let lastDestination = dataList[0]?.ais_destination;

  for (let i = 1; i < dataList.length; i++) {
    if (dataList[i].ais_destination !== lastDestination) {
      lastDestination = dataList[i].ais_destination;
      destinations.push(dataList[i]);
    } else if (i === dataList.length - 1) {
      destinations.push(dataList[i]);
    }
  }

  const backgroundColorPlugin = {
    beforeDraw: chart => {
      const {
        ctx,
        chartArea: { top, height },
        scales: { x },
      } = chart;

      const bgcolors = ['#f8f8f8', '#eeeeee'];

      for (let i = 0; i < destinations.length - 1; i++) {
        let start = destinations[i].time;
        let end = destinations[i + 1].time;

        ctx.fillStyle = bgcolors[i % 2];
        ctx.fillRect(
          x.getPixelForValue(dayjs(start)),
          top,
          x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start))),
          height
        );
      }

      for (let i = 0; i < destinations.length - 1; i++) {
        let start = destinations[i].time;
        let end = destinations[i + 1].time;

        let keepTop = true;

        if (
          x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start))) <= 20 &&
          i < destinations.length - 2
        ) {
          keepTop = false;
        }

        if (destinations[i].ais_destination) {
          ctx.fillStyle = '#4a4a4a';
          ctx.font = 'normal 11px sans-serif';
          ctx.fillText(
            destinations[i].ais_destination,
            x.getPixelForValue(dayjs(start)),
            top + (keepTop ? -8 : 10),
            keepTop && i < destinations.length - 2
              ? x.getPixelForValue(dayjs(end)) - x.getPixelForValue(dayjs(dayjs(start)))
              : undefined
          );
        }
      }
    },
  };

  return (
    <Container>
      <Line data={dataOptions} options={options} plugins={[ChartDataLabels, cursorLinePlugin, backgroundColorPlugin]} />
    </Container>
  );
};
export default SpeedModalTimeGraphMini;
