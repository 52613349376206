import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import { StatisticsContext } from '../../context/StatisticsContext';
import StatisticsStructure from './StatisticsStructure';

const Container = styled.div`
  width: 100%;
  min-height: 300px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const NoGraphs = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const EmissionsGraphs = () => {
  const { getEmissionsStartData, emissionsDashboardData, isEmissionsDashboardDataLoading } = useContext(
    StatisticsContext
  );
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startDataAvailable, setStartDataAvailable] = useState(false);

  useEffect(() => {
    if (!startDataAvailable) {
      getEmissionsStartData();
      setStartDataAvailable(true);
    }
  }, [startDataAvailable, getEmissionsStartData]);

  return (
    <Container>
      {isEmissionsDashboardDataLoading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : emissionsDashboardData.structure &&
        (emissionsDashboardData.structure.length === 2 || emissionsDashboardData.structure.length === 1) ? (
            <StatisticsStructure elements={emissionsDashboardData.elements} structure={emissionsDashboardData.structure} />
          ) : (
            <NoGraphs>{t('No emission statistics available.')}</NoGraphs>
          )}
    </Container>
  );
};
export default EmissionsGraphs;
