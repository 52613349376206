import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/es/checkbox';
import Popover from 'antd/es/popover';

import { UserContext } from '../../context/UserContext';
import Icon from '../../components/ui/Icon';
import ButtonLight from '../ui/ButtonLight';

const ColumnSelect = styled.div`
  margin: 0 0 0 14px;
  display: flex;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 4px 4px 0px;
  border-radius: 3px;
  color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_dark)};
  background-color: ${props => (props.modalOpen ? '#f3f3f3' : 'transparent')};

  &:hover {
    background-color: #f8f8f8;
  }
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -2px;
  i {
    height: 24px;
    width: 28px;
  }
  svg {
    height: 24px;
    width: 24px;
    margin-top: 1px;
  }
`;

const OuterContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
`;

const InnerContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding: 14px 38px 10px 38px;
  display: inline-block;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  margin: 8px 0;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 0 7px 0;
`;

const Info = styled.div`
  font-size: 14px;
  max-width: 260px;
  margin-bottom: 20px;
  margin-top: 6px;
`;

const Buttons = styled.div`
  text-align: left;
  margin-top: 24px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = styled(ButtonLight)`
  height: 26px;
  line-height: 22px;
  padding: 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  padding: 10px 0px;
`;

const Column = styled.div`
  margin-right: ${props => (props.first ? '100px' : '10px')};
`;

const FleetDataColumnSelect = ({ allColumns, visibleColumns, setVisibleColumns }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const storageKey = 'fleetDataColumns_' + namespace;

  const [modalOpen, setModalOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const value = localStorage.getItem(storageKey);
    if (!value) {
      return;
    }
    try {
      const parsedArray = JSON.parse(value);
      if (Array.isArray(parsedArray)) {
        if (parsedArray.length === 0) {
          setVisibleColumns([]);
        } else {
          let filteredColumns = allColumns.filter(c => parsedArray.some(item => item === c.key));
          setVisibleColumns(filteredColumns);
        }
      }
    } catch {
      setVisibleColumns(allColumns);
      localStorage.setItem(storageKey, JSON.stringify(allColumns.map(c => c.key)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalChange = () => {
    setModalOpen(open => !open);
  };

  const checkboxClicked = (key, event) => {
    let vColumns = [...visibleColumns];
    if (event.target.checked) {
      vColumns.push(allColumns.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleColumns(vColumns);
    localStorage.setItem(storageKey, JSON.stringify(vColumns.map(c => c.key)));
  };

  const selectAll = () => {
    setVisibleColumns(allColumns);
    localStorage.setItem(storageKey, JSON.stringify(allColumns.map(c => c.key)));
  };

  const unselectAll = () => {
    setVisibleColumns([]);
    localStorage.setItem(storageKey, JSON.stringify([]));
  };

  const columnSelect = (
    <OuterContainer>
      <InnerContainer>
        <Title>{t('Column selection')}</Title>
        <Info>{t('Select columns to be visible in the table and in the excel export.')}</Info>
        <ColumnContainer>
          <Column first={true}>
            {allColumns
              .filter((p, i) => i % 2 === 0)
              .map((column, index) => (
                <CheckBoxContainer key={`column-${index}`}>
                  <Checkbox
                    checked={visibleColumns.find(c => c.key === column.key)}
                    name={column.title}
                    onClick={e => checkboxClicked(column.key, e)}
                  >
                    {column.title}
                  </Checkbox>
                </CheckBoxContainer>
              ))}
          </Column>
          <Column first={false}>
            {allColumns
              .filter((p, i) => i % 2 === 1)
              .map((column, index) => (
                <CheckBoxContainer key={`column-${index}`}>
                  <Checkbox
                    checked={visibleColumns.find(c => c.key === column.key)}
                    name={column.title}
                    onClick={e => checkboxClicked(column.key, e)}
                  >
                    {column.title}
                  </Checkbox>
                </CheckBoxContainer>
              ))}
          </Column>
        </ColumnContainer>
        <Buttons>
          <Button cancel type="button" onClick={selectAll}>
            {t('Select all')}
          </Button>
          <Button cancel type="button" onClick={unselectAll}>
            {t('Unselect all')}
          </Button>
        </Buttons>
      </InnerContainer>
    </OuterContainer>
  );

  return (
    <Popover
      open={modalOpen}
      onOpenChange={handleModalChange}
      trigger="click"
      content={columnSelect}
      placement="bottomLeft"
    >
      <ColumnSelect
        active={!!allColumns && !!visibleColumns && allColumns.length !== visibleColumns.length}
        modalOpen={modalOpen}
      >
        <IconContainer aria-label="open-side-menu-icon">
          <Icon type="columns" />
        </IconContainer>
        {t('Select columns')}
      </ColumnSelect>
    </Popover>
  );
};

export default FleetDataColumnSelect;
