import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Checkbox from 'antd/es/checkbox';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import OrganizationUsers from './OrganizationUsers';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const ORGANIZATION_BASE_URL = 'organization';

const Organizations = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [organizationsData, setOrganizationsData] = useState(null);
  const [organizationsError, setOrganizationsError] = useState(null);
  const [organizationsLoading, setOrganizationsLoading] = useState(null);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initOrganization = {
    id: null,
    name: '',
    email: '',
    phone: '',
    notes: '',
    receive_emails: true,
    created_at: '',
    modified_at: '',
    created_by: '',
    modified_by: '',
  };

  const [organization, setOrganization] = useState(initOrganization);

  const initModal = {
    visible: false,
    confirmLoading: false,
    organization: {
      id: null,
      name: '',
      email: '',
      phone: '',
      notes: '',
      created_at: '',
      modified_at: '',
      created_by: '',
      modified_by: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'name',
    search: params.get('search') ? params.get('search') : '',
  };

  const organizationsChanged = useCallback(data => {
    if (data) {
      setOrganizationsData(data);
    } else {
      setOrganizationsData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const {
    loading: organizationsFetchLoading,
    error: organizationsFetchError,
    fetchData: organizationsFetchData,
  } = useApi('get', 'organizations', newParams, organizationsChanged, false);

  useEffect(() => {
    organizationsFetchData(false, newParams);
  }, [newParams, organizationsFetchData]);

  useEffect(() => {
    setOrganizationsError(organizationsFetchError);
  }, [organizationsFetchError]);

  useEffect(() => {
    setOrganizationsLoading(organizationsFetchLoading);
  }, [organizationsFetchLoading]);

  if (organizationsError) {
    message.error(organizationsError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const organizations =
    organizationsLoading || !organizationsData?.['organizations'] ? [] : organizationsData['organizations'];

  const { start, total } =
    organizationsError || !organizationsData ? { start: 0, total: 0 } : organizationsData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', `${ORGANIZATION_BASE_URL}/${entryIdentifier}`);
      setOrganization({
        ...data,
      });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${ORGANIZATION_BASE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await organizationsFetchData(false, defaultParams);
  };

  const handleSave = async values => {
    const { name, email, phone, notes, receive_emails } = values;
    const result = await apiCall('post', `${ORGANIZATION_BASE_URL}`, {
      name: name,
      email: email,
      phone: phone,
      notes: notes,
      receive_emails: receive_emails,
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setOrganization(initOrganization);
      history.replace(location.pathname);
      organizationsFetchData();
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setOrganization(initOrganization);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${ORGANIZATION_BASE_URL}`, {
        id: organization.id,
        name: organization.name ? organization.name : '',
        email: organization.email ? organization.email : '',
        phone: organization.phone ? organization.phone : '',
        notes: organization.notes ? organization.notes : '',
        receive_emails: organization.receive_emails ? organization.receive_emails : false,
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setOrganization(initOrganization);
    history.replace(location.pathname);
    await organizationsFetchData(false, defaultParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setOrganization(initOrganization);
  };

  const handleModalChange = e => {
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === 'receive_emails') {
      newValue = checked;
    }
    setOrganization({ ...organization, [name]: newValue });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === 'receive_emails') {
      newValue = checked;
      handleCheckbox(e);
    }
    setOrganization({ ...organization, [name]: newValue });
  };

  const fields = ['name', 'email', 'phone', 'notes', 'receive_emails'];
  const { values, handleChange, handleSubmit, handleCheckbox } = useForm(fields, handleSave, { receive_emails: true });

  useEffect(() => {
    let isValid = organization?.name?.length > 0;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, organization]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sortableKey: 'email',
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      key: 'phone',
      sortableKey: 'phone',
    },
    {
      title: t('Notes'),
      dataIndex: 'notes',
      key: 'notes',
      sortableKey: 'notes',
    },
    {
      title: t('Receive emails'),
      dataIndex: 'receive_emails',
      key: 'receive_emails',
      sortableKey: 'receive_emails',
      render: record => {
        return <>{<Content2>{record ? 'Yes' : 'No'}</Content2>}</>;
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'modified_at',
      sortableKey: 'modified_at',
      render: record => {
        let modified_by = record?.modified_by || '';
        if (record?.modified_by_name && record?.modified_by_email) {
          modified_by = `${record.modified_by_name} (${record.modified_by_email})`;
        } else if (record?.modified_by_name) {
          modified_by = record.modified_by_name;
        } else if (record?.modified_by_email) {
          modified_by = record.modified_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.modified_at} />
              </Content2>
            )}
            <Content2>{modified_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete organization {{name}}?', { name: record.name })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => {
        showActions(!actions);
      },
      disabled: organizationsLoading,
      text: t('Add organization'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <Modal
        title={t('Edit organization')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="name"
              label={t('Name')}
              value={organization.name ? organization.name : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="notes"
              label={t('Notes')}
              value={organization.notes ? organization.notes : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="email"
              label={t('Email')}
              value={organization.email ? organization.email : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="phone"
              label={t('Phone')}
              value={organization.phone ? organization.phone : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Checkbox
              name="receive_emails"
              checked={organization.receive_emails ? organization.receive_emails : false}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              value="1"
            >
              {t('Receive emails')}
            </Checkbox>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm title={t('Add organization')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              return field === 'receive_emails' ? (
                <Checkbox
                  key={field}
                  name={field}
                  field={field}
                  checked={!!values[field]}
                  value="1"
                  onChange={handleAddNewChange}
                >
                  {t('Receive emails')}
                </Checkbox>
              ) : (
                <Input
                  label={field.replace(/_/g, ' ')}
                  key={field}
                  name={field}
                  field={field}
                  value={values[field]}
                  type="text"
                  onChange={handleAddNewChange}
                />
              );
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add organization')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={organizations}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={organizationsLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        expandedRowRender={record => <OrganizationUsers organization={record} />}
      />
    </>
  );
};

export default Organizations;
