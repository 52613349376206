import React from 'react';
import styled from 'styled-components';

import Clear from '../../images/icons/weather/fmi-1-clear.svg?react';
import PartlyCloudy from '../../images/icons/weather/fmi-2-partly-cloudy.svg?react';
import Overcast from '../../images/icons/weather/fmi-3-overcast.svg?react';
import IsolatedShowers from '../../images/icons/weather/fmi-21-isolated-showers.svg?react';
import ScatteredShowers from '../../images/icons/weather/fmi-22-scattered-showers.svg?react';
import Showers from '../../images/icons/weather/fmi-23-showers.svg?react';
import LightRain from '../../images/icons/weather/fmi-31-light-rain.svg?react';
import ModerateRain from '../../images/icons/weather/fmi-32-moderate-rain.svg?react';
import HeavyRain from '../../images/icons/weather/fmi-33-heavy-rain.svg?react';
import IsolatedLightSnowShowers from '../../images/icons/weather/fmi-41-isolated-light-snow-showers.svg?react';
import IsolatedModerateSnowShowers from '../../images/icons/weather/fmi-42-isolated-moderate-snow-showers.svg?react';
import IsolatedHeavySnowShowers from '../../images/icons/weather/fmi-43-isolated-heavy-snow-showers.svg?react';
import LightSnowfall from '../../images/icons/weather/fmi-51-light-snowfall.svg?react';
import ModerateSnowfall from '../../images/icons/weather/fmi-52-moderate-snowfall.svg?react';
import HeavySnowfall from '../../images/icons/weather/fmi-53-heavy-snowfall.svg?react';
import IsolatedThundershowers from '../../images/icons/weather/fmi-61-isolated-thundershowers.svg?react';
import ScatteredThundershowers from '../../images/icons/weather/fmi-62-scattered-thundershowers.svg?react';
import Thundershowers from '../../images/icons/weather/fmi-63-thundershowers.svg?react';
// Alternative thundershowers icon
import Thundershowers2 from '../../images/icons/weather/fmi-64-thundershowers.svg?react'; //eslint-disable-line 
import IsolatedLightSleetShowers from '../../images/icons/weather/fmi-71-isolated-light-sleet-showers.svg?react';
import IsolatedModerateSleetShowers from '../../images/icons/weather/fmi-72-isolated-moderate-sleet-showers.svg?react';
import IsolatedHeavySleetShowers from '../../images/icons/weather/fmi-73-isolated-heavy-sleet-showers.svg?react';
import LightSleet from '../../images/icons/weather/fmi-81-light-sleet.svg?react';
import ModerateSleet from '../../images/icons/weather/fmi-82-moderate-sleet.svg?react';
import HeavySleet from '../../images/icons/weather/fmi-83-heavy-sleet.svg?react';
import Fog from '../../images/icons/weather/fmi-91-92-fog.svg?react';

import WindDirection0 from '../../images/icons/weather/wind-direction-0.svg?react';
import WindDirection1To3 from '../../images/icons/weather/wind-direction-1-3.svg?react';
import WindDirection4To7 from '../../images/icons/weather/wind-direction-4-7.svg?react';
import WindDirection8To13 from '../../images/icons/weather/wind-direction-8-13.svg?react';
import WindDirection14To20 from '../../images/icons/weather/wind-direction-14-20.svg?react';
import WindDirection21To32 from '../../images/icons/weather/wind-direction-21-32.svg?react';

const IconWrapper = styled.i`
  display: inline-block;
  position: relative;
  height: 1em;
  width: 1em;
  svg {
    display: inline-block;
    fill: ${props => props.fill || 'currentColor'};
    color: ${props => props.color || 'currentColor'};
    vertical-align: ${props => props.align || 'middle'};
    text-align: center;
  }
`;

const WeatherIcon = ({ type, rotation, ...props }) => {
  switch (type) {
    case 'clear':
      return (
        <IconWrapper {...props}>
          <Clear />
        </IconWrapper>
      );
    case 'partly_cloudy':
      return (
        <IconWrapper {...props}>
          <PartlyCloudy />
        </IconWrapper>
      );
    case 'overcast':
      return (
        <IconWrapper {...props}>
          <Overcast />
        </IconWrapper>
      );
    case 'isolated_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedShowers />
        </IconWrapper>
      );
    case 'scattered_showers':
      return (
        <IconWrapper {...props}>
          <ScatteredShowers />
        </IconWrapper>
      );
    case 'showers':
      return (
        <IconWrapper {...props}>
          <Showers />
        </IconWrapper>
      );
    case 'light_rain':
      return (
        <IconWrapper {...props}>
          <LightRain />
        </IconWrapper>
      );
    case 'moderate_rain':
      return (
        <IconWrapper {...props}>
          <ModerateRain />
        </IconWrapper>
      );
    case 'heavy_rain':
      return (
        <IconWrapper {...props}>
          <HeavyRain />
        </IconWrapper>
      );
    case 'isolated_light_snow_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedLightSnowShowers />
        </IconWrapper>
      );
    case 'isolated_moderate_snow_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedModerateSnowShowers />
        </IconWrapper>
      );
    case 'isolated_heavy_snow_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedHeavySnowShowers />
        </IconWrapper>
      );
    case 'light_snowfall':
      return (
        <IconWrapper {...props}>
          <LightSnowfall />
        </IconWrapper>
      );
    case 'moderate_snowfall':
      return (
        <IconWrapper {...props}>
          <ModerateSnowfall />
        </IconWrapper>
      );
    case 'heavy_snowfall':
      return (
        <IconWrapper {...props}>
          <HeavySnowfall />
        </IconWrapper>
      );
    case 'isolated_thundershowers':
      return (
        <IconWrapper {...props}>
          <IsolatedThundershowers />
        </IconWrapper>
      );
    case 'scattered_thundershowers':
      return (
        <IconWrapper {...props}>
          <ScatteredThundershowers />
        </IconWrapper>
      );
    case 'thundershowers':
      return (
        <IconWrapper {...props}>
          <Thundershowers />
        </IconWrapper>
      );
    case 'isolated_light_sleet_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedLightSleetShowers />
        </IconWrapper>
      );
    case 'isolated_moderate_sleet_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedModerateSleetShowers />
        </IconWrapper>
      );
    case 'isolated_heavy_sleet_showers':
      return (
        <IconWrapper {...props}>
          <IsolatedHeavySleetShowers />
        </IconWrapper>
      );
    case 'light_sleet':
      return (
        <IconWrapper {...props}>
          <LightSleet />
        </IconWrapper>
      );
    case 'moderate_sleet':
      return (
        <IconWrapper {...props}>
          <ModerateSleet />
        </IconWrapper>
      );
    case 'heavy_sleet':
      return (
        <IconWrapper {...props}>
          <HeavySleet />
        </IconWrapper>
      );
    case 'fog':
      return (
        <IconWrapper {...props}>
          <Fog />
        </IconWrapper>
      );
    case 'wind_direction_0':
      return (
        <IconWrapper {...props}>
          <WindDirection0 />
        </IconWrapper>
      );
    case 'wind_direction_1_3':
      return (
        <IconWrapper {...props}>
          <WindDirection1To3
            style={{
              transformOrigin: 'center',
              transform: `rotate(${rotation + 90}deg)`,
            }}
          />
        </IconWrapper>
      );
    case 'wind_direction_4_7':
      return (
        <IconWrapper {...props}>
          <WindDirection4To7
            style={{
              transformOrigin: 'center',
              transform: `rotate(${rotation + 90}deg)`,
            }}
          />
        </IconWrapper>
      );
    case 'wind_direction_8_13':
      return (
        <IconWrapper {...props}>
          <WindDirection8To13
            style={{
              transformOrigin: 'center',
              transform: `rotate(${rotation + 90}deg)`,
            }}
          />
        </IconWrapper>
      );
    case 'wind_direction_14_20':
      return (
        <IconWrapper {...props}>
          <WindDirection14To20
            style={{
              transformOrigin: 'center',
              transform: `rotate(${rotation + 90}deg)`,
            }}
          />
        </IconWrapper>
      );
    case 'wind_direction_21_32':
      return (
        <IconWrapper {...props}>
          <WindDirection21To32
            style={{
              transformOrigin: 'center',
              transform: `rotate(${rotation + 90}deg)`,
            }}
          />
        </IconWrapper>
      );
    default:
      return null;
  }
};

export default WeatherIcon;
