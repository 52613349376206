import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled, { keyframes } from 'styled-components';

import { UserContext } from '../../context/UserContext';

import TimestampTooltip from './TimestampTooltip';
import { TimestampFilteringContext } from '../../context/TimestampFilteringContext';
import Icon from '../ui/Icon';
import DateComponent from '../ui/DateComponent';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledTimestamp = styled.div`
  position: relative;
  display: flex;
  padding: ${({ theme }) => theme.sizing.gap_small} 0.35rem;
  animation: ${fadeIn} 0.125s;
  font-weight: ${props => (props.new ? 700 : 500)};
  &:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 50%;
    margin-top: -4px;
    background: ${({ theme }) => theme.color.primary};
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const TypeState = styled.div`
  width: ${props => (props.warning ? '158px' : '178px')};
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
`;

const TimestampContainer = styled.div`
  display: flex;
`;

const TimeContainer = styled.div`
  display: inline-flex;
  line-height: 24px;
  width: ${props => (props.warning ? '96px' : '76px')};
`;

const Warning = styled.div`
  color: #db3535;
  margin-right: 4px;

  svg {
    width: 12px;
    height: 12px;
    margin-top: -4px;
  }
`;

const Time = styled(DateComponent)`
  font-style: italic;
  font-size: ${({ theme }) => theme.text.small};
  margin-bottom: 0;
  white-space: nowrap;
`;

const Timestamp = ({ data, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { timestampFilteringList, showTimestamps, userOnOffTimestamps } = useContext(TimestampFilteringContext);

  const [isNew, setIsNew] = useState(false);

  const timeState = data.state?.replace(/_/g, ' ');
  const maybeLocation = data?.payload?.location;
  const maybeDirection = data?.payload?.direction;
  const extraData = '' + (maybeLocation ? ' ' + maybeLocation : '') + (maybeDirection ? ' ' + maybeDirection : '');
  const timestampTextKey = `${data.time_type} ${timeState}${extraData}`;

  useEffect(() => {
    if (data.time) {
      const createdAt = dayjs(data.created_at);
      if (createdAt.isBefore(dayjs().add(10, 'minutes')) && createdAt.isAfter(dayjs().subtract(10, 'minutes'))) {
        setIsNew(true);
      }
    }
  }, [data]);

  if (showTimestamps && userOnOffTimestamps) {
    let filteredList = timestampFilteringList.filter(t => {
      return data.state === t.filter_object.state && data.time_type === t.filter_object.time_type;
    });
    let filteringItem = null;
    if (filteredList.length === 1) {
      filteringItem = filteredList[0];
    } else {
      filteringItem = filteredList.find(
        t =>
          t.filter_object.payload.location === data.payload.location &&
          t.filter_object.payload.direction === data.payload.direction
      );
      if (!filteringItem) {
        filteringItem = filteredList.find(t => t.filter_object.payload.location === data.payload.location);
        if (!filteringItem) {
          filteringItem = filteredList.find(t => t.filter_object.payload.direction === data.payload.direction);
          if (!filteringItem) {
            filteringItem = filteredList.find(
              t => !t.filter_object.payload.direction && !t.filter_object.payload.location
            );
          }
        }
      }
    }
    if (!filteringItem || (!data.time && filteringItem && !filteringItem.visible)) {
      return null;
    }
  }

  return (
    <StyledTimestamp new={isNew} {...props}>
      <TimestampTooltip data={data} warnings={data.warnings} textKey={timestampTextKey + ' description'}>
        <TimestampContainer>
          <TypeState warning={!!data.warnings}>{t(timestampTextKey)}</TypeState>
          {data.time && (
            <TimeContainer warning={!!data.warnings}>
              {!!data.warnings && (
                <Warning>
                  <Icon type="warning" />
                </Warning>
              )}
              <Time format={'DD.MM. HH:mm'} date={data.time} />
            </TimeContainer>
          )}
        </TimestampContainer>
      </TimestampTooltip>
    </StyledTimestamp>
  );
};

export default Timestamp;
