import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import Spin from 'antd/es/spin';

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.secondary};
  border: 0.125rem solid transparent;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
  transition: ${({ theme }) => theme.transition.ease(0.2)};
  white-space: nowrap;
  line-height: 1.1rem;
  height: 32px;
  position: relative;
  &:hover {
    background-color: ${props => !props.disabled && darken(0.05, props.theme.color.secondary)};
  }
  ${props => {
    if (props.primary) {
      return `
        background: ${props.theme.color.primary};
      `;
    } else if (props.disabled) {
      return `
        cursor: not-allowed;
        color: #c9c9c9;
        background: #f5f5f5;
        border-color: #d9d9d9 !important;
        outline: none;
        svg {
          fill: ${props.theme.color.grey} !important;
        }
        &:hover {
          background: #f5f5f5;
        }
      `;
    } else if (props.warning) {
      return `
        color: ${props.theme.color.warning};
        background:  ${props.theme.color.white};
        border-color: ${props.theme.color.grey};
        &:hover {
          background-color: ${darken(0.02, props.theme.color.white)};
        }
      `;
    } else if (props.cancel) {
      return `
        color: #4a4a4a;
        background: ${props.theme.color.white};
        border-color: #D8D8D8;
        &:hover {
          border-color: ${props.theme.color.secondary};
          color: ${props.theme.color.secondary};
          background: ${props.theme.color.white};

          svg {
            fill: ${props.theme.color.secondary};
          }
        }
      `;
    }
  }}
  ${props => {
    if (props.small) {
      return `
        padding: ${props.theme.sizing.gap_tinier};
      `;
    } else {
      return `
        min-width: 90px;
        padding: ${props.theme.sizing.gap_small};
      `;
    }
  }}
  ${props => {
    if (props.red) {
      return `
        background: ${props.theme.color.warning};
        color: ${props.theme.color.white};
        &:hover {
          background-color: ${darken(0.05, props.theme.color.warning)};
        }
      `;
    }
  }}
  ${props => {
    if (props.lightred) {
      return `
        border: 0.125rem solid ${props.theme.color.warning} !important;
        background: white;
        color: ${props.theme.color.warning};
        &:hover {
          border: 0.125rem solid ${darken(0.05, props.theme.color.warning)};
          color: ${darken(0.05, props.theme.color.warning)};
          background: ${darken(0.02, 'white')};
        }
      `;
    }
  }}
  i {
    fill: currentColor;
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    top: -1px;
  }
`;

const SpinContainer = styled.div`
  width: 28px;
  height: 28px;
  position: absolute;
`;

const ButtonLight = props => (
  <StyledButton {...props}>
    {props.sending ? (
      <SpinContainer>
        <Spin size="small" />
      </SpinContainer>
    ) : null}
    {props.children}
  </StyledButton>
);

export default ButtonLight;
