import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import Switch from 'antd/es/switch';
import App from 'antd/es/app';
import styled from 'styled-components';
import deepEqual from 'deep-equal';

import List from '../ui/List';
import useApi from '../../hooks/useApi';

const MFA_SETTING_BASE_URL = 'mfa-setting';

const ListContainer = styled.div`
  padding-top: 16px;
`;

const MfaSettings = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const [apiCallPending, setApiCallPending] = useState(false);
  const [rolesMfaData, setRolesMfaData] = useState([]);
  const [groupsMfaData, setGroupsMfaData] = useState([]);
  const [groupsData, setGroupsData] = useState(null);

  const { message } = App.useApp();

  const defaultParams = {
    limit: 10000,
    offset: 0,
    sort: 'id',
    search: '',
  };

  const params = new URLSearchParams(location.search);
  const defaultGroupParams = {
    limit: 10,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: 'name',
    search: '',
  };
  const [newGroupParams, setNewGroupParams] = useState(defaultGroupParams);

  const { loading: rolesLoading, data: rolesData, error: rolesError, fetchData: rolesFetchData } = useApi(
    'get',
    'roles',
    {}
  );

  if (rolesError) {
    message.error(rolesError, 4);
  }

  const groupsChanged = useCallback(data => {
    if (data) {
      setGroupsData(data);
    } else {
      setGroupsData(null);
    }
  }, []);

  const { loading: groupsLoading, error: groupsError, fetchData: groupsFetchData } = useApi(
    'get',
    'groups',
    newGroupParams,
    groupsChanged,
    false
  );

  useEffect(() => {
    groupsFetchData(false, newGroupParams);
  }, [newGroupParams, groupsFetchData]);

  if (groupsError) {
    message.error(groupsError, 4);
  }

  const {
    loading: mfaSettingsLoading,
    data: mfaSettingsData,
    error: mfaSettingsError,
    fetchData: mfaSettingsFetchData,
  } = useApi('get', 'mfa-settings', defaultParams);

  if (mfaSettingsError) {
    message.error(mfaSettingsError, 4);
  }

  useEffect(() => {
    const roles = rolesError || !rolesData ? [] : rolesData;
    const groups = groupsLoading || !groupsData?.['groups'] ? [] : groupsData['groups'];
    const mfaSettings =
      mfaSettingsLoading || !mfaSettingsData?.['mfa-settings'] ? null : mfaSettingsData['mfa-settings'];
    if (roles.length && mfaSettings) {
      const mergedData = roles.map(role => {
        const mfaSetting = mfaSettings.find(setting => setting.role_id === role.id);
        return {
          ...role,
          mfa_id: mfaSetting ? mfaSetting.id : null,
          mfa_enabled: mfaSetting ? mfaSetting.mfa_enabled : false,
        };
      });
      setRolesMfaData(previous => {
        if (!deepEqual(previous, mergedData, true)) {
          return mergedData;
        }
        return previous;
      });
    }
    if (groups.length && mfaSettings) {
      const mergedData = groups.map(group => {
        const mfaSetting = mfaSettings.find(setting => setting.group_id === group.id);
        return {
          ...group,
          mfa_id: mfaSetting ? mfaSetting.id : null,
          mfa_enabled: mfaSetting ? mfaSetting.mfa_enabled : false,
        };
      });
      setGroupsMfaData(previous => {
        if (!deepEqual(previous, mergedData, true)) {
          return mergedData;
        }
        return previous;
      });
    }
  }, [groupsData, groupsLoading, mfaSettingsData, mfaSettingsLoading, rolesData, rolesError]);

  const handleToggleRole = async (role_id, mfa_id, mfa_enabled) => {
    setApiCallPending(true);
    try {
      const result = await apiCall(mfa_id ? 'put' : 'post', `${MFA_SETTING_BASE_URL}`, {
        ...(mfa_id ? { id: mfa_id } : {}),
        role_id: role_id,
        mfa_enabled: mfa_enabled,
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    history.replace(location.pathname);
    await rolesFetchData(false, {});
    await mfaSettingsFetchData(false, defaultParams);
  };

  const handleToggleGroup = async (group_id, mfa_id, mfa_enabled) => {
    setApiCallPending(true);
    try {
      const result = await apiCall(mfa_id ? 'put' : 'post', `${MFA_SETTING_BASE_URL}`, {
        ...(mfa_id ? { id: mfa_id } : {}),
        group_id: group_id,
        mfa_enabled: mfa_enabled,
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    history.replace(location.pathname);
    await groupsFetchData(false, defaultGroupParams);
    await mfaSettingsFetchData(false, defaultParams);
  };

  const { start, total } = groupsError || !groupsData ? { start: 0, total: 0 } : groupsData.pagination;

  const roleColumns = [
    {
      title: t('MFA Enabled'),
      key: 'role',
      align: 'center',
      width: '128px',
      render: record => (
        <Switch
          checked={record?.mfa_enabled}
          defaultChecked={record?.mfa_enabled}
          onChange={checked => handleToggleRole(record?.id, record?.mfa_id, checked)}
        ></Switch>
      ),
    },
    {
      title: t('Role name'),
      dataIndex: 'readable_name',
      key: 'readable_name',
    },
  ];

  const groupColumns = [
    {
      title: t('MFA Enabled'),
      key: 'role',
      align: 'center',
      width: '128px',
      render: record => (
        <Switch
          checked={record?.mfa_enabled}
          defaultChecked={record?.mfa_enabled}
          onChange={checked => handleToggleGroup(record?.id, record?.mfa_id, checked)}
        ></Switch>
      ),
    },
    {
      title: t('Group name'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <>
      <ListContainer>
        <h3>{t('MFA settings for roles')}</h3>
        <List
          rowKey="id"
          columns={roleColumns}
          dataSource={rolesMfaData}
          apiCallPending={apiCallPending}
          spinning={rolesLoading || mfaSettingsLoading}
        />
      </ListContainer>
      <ListContainer>
        <h3>{t('MFA settings for groups')}</h3>
        <List
          rowKey="id"
          columns={groupColumns}
          dataSource={groupsMfaData}
          apiCallPending={apiCallPending}
          spinning={groupsLoading || mfaSettingsLoading}
          setParams={setNewGroupParams}
          newParams={newGroupParams}
          start={start}
          total={total}
          hideSearch
        />
      </ListContainer>
    </>
  );
};

export default MfaSettings;
