import React, { useState, useContext, useRef } from 'react';
import Tree from 'antd/es/tree';
import Spin from 'antd/es/spin';
import App from 'antd/es/app';

import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import styled from 'styled-components';

const TreeWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 100px;
  width: 100%;
`;

const AssetTree = () => {
  const {
    treeData,
    setTreeData,
    openRightPanel,
    updateAssetdataOrder,
    typeData,
    loading,
    setUpdateMapCenter,
    filterList,
  } = useContext(GeoAssetToolContext);
  // eslint-disable-next-line no-unused-vars
  const [expandedKeys, setExpandedKeys] = useState([]);
  const currentDraggedNode = useRef(null);
  const canDrop = useRef(true);
  const errorOpen = useRef(false);

  const { message } = App.useApp();

  const onDragEnter = info => {
    setExpandedKeys(info.expandedKeys);
  };

  const onDrop = info => {
    const keyOrderList = [];
    errorOpen.current = false;
    function recursive(node, index, parentId, parent_type) {
      canDrop.current = dropValidation(node, parent_type);
      if (canDrop.current) {
        node.order_number = index;
        node.parent = parentId;
        keyOrderList.push({ geo_asset_id: node.geo_asset_id, order_number: index, parent: parentId });
        for (let i = 0, count = node.children.length; i < count; i++) {
          recursive(node.children[i], i, node.geo_asset_id, node.type_id);
        }
      } else {
        errorOpen.current = true;
      }
    }
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }

        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = JSON.parse(JSON.stringify(treeData));
    const backupTree = JSON.parse(JSON.stringify(treeData));

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, item => {
        item.children = item.children || [];

        item.children.unshift(dragObj);
      });
    } else if ((info.node.props.children || []).length > 0 && info.node.props.expanded && dropPosition === 1) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    for (let index = 0; index < data.length; index++) {
      recursive(data[index], index, null, null);
    }
    if (errorOpen.current) {
      setTreeData(backupTree);
      errorOpen.current = false;
    } else {
      setTreeData(data);
      updateAssetdataOrder(keyOrderList);
    }
  };

  const onDragStart = element => {
    currentDraggedNode.current = element.node;
  };

  const dropValidation = (node, parent) => {
    if (!parent) return true;
    const nodeType = typeData[node.type_id];
    if (!nodeType) {
      message.error(`${node.name} has invalid type`);
      return false;
    }
    const parentType = typeData[parent].type_id;
    if (nodeType.allowed_parents?.includes(parentType)) return true;
    else {
      message.error(`${nodeType.name} can't be a child of ${typeData[parent].name} `);
      return false;
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <TreeWrapper>
          <Tree
            style={{ borderRadius: '4px' }}
            className="draggable-tree"
            blockNode
            defaultExpandAll={true}
            draggable
            onDragStart={onDragStart}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            treeData={treeData}
            onSelect={(e, node) => {
              if (!filterList.includes(node.node.type_id)) {
                const coords = node.node.geo_data.coordinates;
                if (node.node.geo_data.type === 'Polygon' || node.node.geo_data.type === 'LineString') {
                  setUpdateMapCenter({ coords: coords, zoom: null });
                } else setUpdateMapCenter({ coords: [coords], zoom: null });
              }
              openRightPanel(node.node);
            }}
          />
        </TreeWrapper>
      </Spin>
    </>
  );
};

export default AssetTree;
