import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import WikiList from '../components/info/WikiList';

const WikiPage = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Wiki | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Wiki')}>
      <Page fullWidth>
        <WikiList />
      </Page>
    </Layout>
  );
};

export default WikiPage;
