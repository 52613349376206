import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';

import TimestampFiltering from '../../components/admin/TimestampFiltering';

const AdminTimestampFiltering = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Timestamp Filtering | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Timestamp Filtering')}>
      <Page fullWidth>
        <TimestampFiltering />
      </Page>
    </Layout>
  );
};

export default AdminTimestampFiltering;
