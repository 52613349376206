import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Draggable from 'react-draggable';
import Tooltip from 'antd/es/tooltip';
import Icon from '../../ui/Icon';

import { TIME_FORMAT } from '../../../utils/constants';

import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import { UserContext } from '../../../context/UserContext';
import AddOrEditReservationModal from '../modals/AddOrEditReservationModal';
import AddOrEditBerthPlanModal from '../modals/AddOrEditBerthPlanModal';
import AddOrEditPredictiveBerthPlanModal from '../modals/AddOrEditPredictiveBerthPlanModal';
import {
  color_planned,
  color_not_planned,
  color_reserved,
  color_uncommitted,
  color_updated,
} from '../../commonCalendar/utility/constants';
import { getDuration } from '../../../utils/utils';
import AddOrEditBerthBlockModal from '../modals/AddOrEditBerthBlockModal';
import AddOrEditJITSlotRequestModal from '../modals/AddOrEditJITSlotRequest';
import EditJITSlotRequestBerth from '../modals/EditJITSlotRequestBerth';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import { getPbpWorksFromData } from './utility/contextHelpers';

dayjs.extend(duration);
dayjs.extend(minMax);

/* Vessel states: notPlanned=1, uncommitted=2, planned=3, reserved=4 */

const VESSEL_STATE_NOT_PLANNED = 1;
const VESSEL_STATE_UNCOMMITTED = 2;
const VESSEL_STATE_PLANNED = 3;
const VESSEL_STATE_RESERVED = 4;
const VESSEL_STATE_ACCEPTED = 5;
const VESSEL_STATE_OFFERED = 6;
const VESSEL_STATE_UPDATED = 7;

const VesselContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  z-index: ${props => (props.dragging ? 51 : 50)};
  border: ${props => (props.overlapping ? '1px solid red' : '1px solid white')};
  opacity: ${props => (props.hiddenVessel ? 0.1 : props.locked ? 0.5 : 1)};
  cursor: ${props => (props.locked || props.noDrag ? 'pointer' : 'move')};
  box-shadow: ${props => (props.dragging ? '0px 0px 10px rgba(0, 0, 0, 0.15)' : 'none')};

  border: ${props => props.multiselect && '2px solid #4990dd !important'};
  opacity: ${props => props.multiselectNotChosen && 0.4};
  cursor: ${props => (props.multiselectNotChosen || props.hiddenVessel) && 'default'};
`;

const AnchorageContainers = styled.div``;

const AnchorageContainer = styled.div`
  position: absolute;
  z-index: 49;
`;

const AnchorageBox = styled.div`
  background: #c8c8c8;
  border-radius: 3px;
  border: 1px solid white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnchorageNameContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px 0 0px;
  color: #000000;
  font-size: ${props => (props.realOpen ? '12px' : '10px')};
  text-align: center;
`;

const VesselInnerContainer = styled.div`
  height: calc(100% ${props => props.craneHeight && ' - ' + props.craneHeight});
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MiddleDiv = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 3px;

  ${props => {
    if (props.vesselState) {
      // reserved
      if (props.vesselState === VESSEL_STATE_RESERVED) {
        return `
          background: repeating-linear-gradient(90deg,
            #FFAB91,
            #FFAB91 2px,
            #ffd2c7 2px,
            #ffd2c7 4px);
        `;
        // accepted
      } else if (props.vesselState === VESSEL_STATE_ACCEPTED) {
        return `
          border: 1px solid #39543a;
        `;
        // offered
      } else if (props.vesselState === VESSEL_STATE_OFFERED) {
        return `
          border: 1px dotted #5c4c04;
        `;
        // updated
      } else if (props.vesselState === VESSEL_STATE_UPDATED) {
        return `
          border: 1px dashed #5e486e;
        `;
      }
    }
  }};
`;

const CraneContainer = styled.div`
  height: ${props => props.craneHeight};
  display: flex;
  position: relative;
`;

const CraneBox = styled.div`
  background-color: #388e3c;
  position: absolute;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
`;

const CraneNameContainer = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: ${props => props.show && 'center'};
  align-items: ${props => props.show && 'center'};
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 52;
`;

const CraneName = styled.div`
  color: white;
  font-size: ${props => (props.bigText ? '10px' : props.tiny ? '6px' : '7px')};
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const Break = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0px;
  border-radius: 1px;
  z-index: 50;
`;

const Reservation = styled(Break)`
  background-color: rgb(255, 171, 145);
  z-index: 51;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 0px 6px 6px 6px;
  align-self: center;

  svg {
    border: 0px;
    fill: ${({ theme }) => theme.color.vesselIconColor};
    height: 16px;
    height: ${props => props.smallIcon && '9px'};
    height: ${props => props.tinyIcon && '6px'};
    margin-bottom: ${props => (props.smallIcon ? '1px' : '3px')};
  }
`;

const TooltipContainer = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey_medium};
  padding: 5px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
  line-height: 21px;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const FullRow = styled.div`
  width 100%;
`;

const Common = styled.div`
  display: flex;
`;

const PBPPart = styled.div`
  display: inline-block;
  margin-top: 3px;
`;

const ReservationNote = styled.div`
  display: flex;
  margin-top: 3px;
`;

const AdditionalData = styled.div`
  display: block;
  margin-top: 6px;
`;

const AdditionalDataContainer = styled.div`
  display: flex;
  margin-left: 9px;
`;

const SecondColumn = styled.div`
  max-width: 240px;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const NoteValue = styled.div`
  color: ${({ theme }) => theme.color.grey_dark};
  line-height: 21px;
`;

const VesselNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${props => (props.icon ? '0 12px 0 0px' : '0 2px 0 2px')};
  color: #000000;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
`;

const VesselInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
`;

const VesselCargoContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${props => (props.icon ? '0 12px 0 0px' : '0 2px 0 2px')};
  color: #000000;
  font-weight: 400;
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const TotalCraneRow = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  display: flex;
  padding: 4px 8px;
  margin: 4px -13px;
`;

const Text = styled.div`
  white-space: nowrap;
`;

const CranePart = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 4px;
`;

const WeatherCrane = styled.div`
  position: absolute;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  background-color: rgb(208, 1, 28);
`;

const MinorBreaks = styled.div`
  margin-top: 12px;
  display: flex;
  position: relative;
`;

const QueueIndicator = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 1.5px,
    ${props =>
    props.vesselState === VESSEL_STATE_ACCEPTED
      ? '#39543a'
      : props.vesselState === VESSEL_STATE_OFFERED
        ? '#5c4c04'
        : '#5e486e'}
      1.5px,
    ${props =>
    props.vesselState === VESSEL_STATE_ACCEPTED
      ? '#39543a'
      : props.vesselState === VESSEL_STATE_OFFERED
        ? '#5c4c04'
        : '#5e486e'}
      3px
  );
`;

const QueueIndicatorTooltipContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #8a8a8a;
  margin-top: 12px;
`;

const QueueIndicatorTooltip = styled.div`
  width: 120px;
  margin-top: 12px;
`;

const Texts = styled.div`
  display: flex;
  margin-top: 3px;
`;

const TooltipText = styled.div`
  font-weight: 600;
`;

const ColorPanel = styled.div`
  display: flex;
  margin-left: 26px;
`;

const StripedPart = styled.div`
  width: 30px;
  height: 10px;
  margin-top: 3px;
  border: 1px solid #39543a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: ${props => props.color}
    repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #39543a 1.5px, #39543a 3px);
`;

const ColoredPart = styled.div`
  width: 40px;
  height: 10px;
  margin-top: 3px;
  background-color: ${props => props.color};

  ${props => {
    if (props.vesselState === VESSEL_STATE_ACCEPTED) {
      return `
          border-top: 1px solid #39543a;
          border-bottom: 1px solid #39543a;
        `;
      // offered
    } else if (props.vesselState === VESSEL_STATE_OFFERED) {
      return `
          border-top: 1px dotted #5c4c04;
          border-bottom: 1px dotted #5c4c04;
        `;
      // updated
    } else {
      return `
          border-top: 1px dashed #5e486e;
          border-bottom: 1px dashed #5e486e;
        `;
    }
  }};
`;

const Vessel = ({ vessel, open, berth, notPlannedPlace, draggingValue, additionalData }) => {
  const { namespace, user, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const {
    canvasTimeStart,
    canvasTimeEnd,
    canvasWidth,
    setDraggingArea,
    draggingOverlapping,
    draggingArea,
    draggingRowInfo,
    rowHeight,
    collapsedRowHeight,
    setDisableScrolling,
    berths,
    predictiveBPTOn,
    berthAreasAvailable,
    setBptModalOpen,
    bptModalOpen,
    queueModalTypeOpen,
    setQueueModalTypeOpen,
    keepVesselLocation,
    moveSelection,
    multiselectedVessels,
    setMultiselectedVessels,
    multiselectedMoveVessels,
    setMultiselectedMoveVessels,
    multiselectedMove,
    setMultiselectedMove,
    setMultiselectedModalOpen,
    multiselectedModalOpen,
    vesselLayerOn,
    vesselOptions,
    dynamicBollards,
    zoomSliderValue,
    disablePbpDragging,
  } = useContext(BerthPlanningToolContext);
  const [editReservationModalOpen, setEditReservationModalOpen] = useState(false);
  const [editBerthBlockModalOpen, setEditBerthBlockModalOpen] = useState(false);
  const [editSlotRequestModalOpen, setEditSlotRequestModalOpen] = useState(false);
  const [editBerthPlanModalOpen, setEditBerthPlanModalOpen] = useState(false);
  const [editPredictiveBerthPlanModalOpen, setEditPredictiveBerthPlanModalOpen] = useState(false);
  const [editNotPlannedJitModalOpen, setEditNotPlannedJitModalOpen] = useState(false);

  const [multiselect, setMultiselect] = useState(false);
  const [craneHover, setCraneHover] = useState(undefined);

  const multiselectRef = useRef();

  const draggableRef = useRef(null);

  const craneData = vessel.pbp_data?.works && vessel.pbp_data.works.length > 0;

  const realOpen =
    draggingArea && draggingArea.port_call_master_id === vessel.port_call_master_id ? draggingRowInfo.open : open;

  const getDefaultHeight = () => {
    if (berthAreasAvailable) {
      return realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
    }

    const heightMultiplier = dynamicBollards ? 2 : 1;
    let bollardAmount = 0;
    const startBollardIndex = berth.subRows.findIndex(b => b.id === vessel.start_bollard);
    const endBollardIndex = berth.subRows.findIndex(b => b.id === vessel.end_bollard);

    if (endBollardIndex < 0 || startBollardIndex < 0) {
      bollardAmount = 2;
    } else {
      bollardAmount = Math.abs(endBollardIndex - startBollardIndex) + 1;
    }

    let height = 0;

    if (notPlannedPlace) {
      height = 2 * (realOpen ? rowHeight : collapsedRowHeight);
    } else if (Math.abs(endBollardIndex - startBollardIndex) > 0) {
      height = bollardAmount * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
    } else {
      height = heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
    }

    return height;
  };

  const [vesselHeight, setVesselHeight] = useState(getDefaultHeight());

  const rowHeightRef = useRef();
  const collapsedRowHeightRef = useRef();
  const realOpenRef = useRef();

  const [settingOk, setSettingOk] = useState(true);
  const [updater, setUpdater] = useState(false);
  const escFunction = event => {
    if (event.keyCode === 27) {
      disabledRef.current = true;
      axisRef.current = "none"; //eslint-disable-line
      setUpdater(!updater);
    }
  };

  useEffect(() => {
    if (multiselectRef.current !== multiselect) {
      if (multiselect) {
        setMultiselectedMoveVessels(vs => {
          if (
            vs.findIndex(v => v.id === vessel.port_call_master_id) === -1 &&
            (vs.length > 0 ? vs.findIndex(v => v.berth === vessel.berth_name) > -1 : true)
          ) {
            let list = [...vs];
            list.push({ id: vessel.port_call_master_id, berth: vessel.berth_name });
            return list;
          }

          return vs;
        });
      } else {
        setMultiselectedMoveVessels(vs => {
          let index = vs.findIndex(v => v.id === vessel.port_call_master_id);
          let list = [...vs];
          if (index > -1) {
            list.splice(index, 1);
          }
          return list;
        });
      }
    }
  }, [multiselect, setMultiselectedMoveVessels, vessel.berth_name, vessel.port_call_master_id]);

  const dragging = useRef();

  //eslint-disable-next-line
  const axisRef = useRef(modules.queue_module === 'enabled' ? (vessel.state === VESSEL_STATE_NOT_PLANNED ? "y" : "x") : "both");
  const prevAxis = useRef();
  const lockedToAxis = useRef(modules.queue_module === 'enabled');
  const disabledRef = useRef(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const getStartTime = () => {
    if (modules.queue_module !== 'enabled') {
      return dragging.current
        ? dayjs(vessel.pta || vessel.eta)
        : dayjs.max([dayjs(vessel.pta || vessel.eta), dayjs(canvasTimeStart)]);
    } else {
      if (vessel.drawable) {
        return dayjs(vessel.drawable.visuals[0].start_time);
      } else if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        return dayjs.max([dayjs(vessel.eta), dayjs(canvasTimeStart)]);
      } else if (vessel.state === VESSEL_STATE_OFFERED) {
        return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
      } else if (vessel.state === VESSEL_STATE_ACCEPTED) {
        return dayjs.max([dayjs.min([dayjs(vessel.pta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
      } else if (vessel.state === VESSEL_STATE_UPDATED) {
        return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
      } else {
        return dayjs.max([dayjs(vessel.start_time), dayjs(canvasTimeStart)]);
      }
    }
  };

  const getEndTime = () => {
    if (modules.queue_module !== 'enabled') {
      return dragging.current
        ? dayjs(vessel.ptd || vessel.etd)
        : dayjs.min([dayjs(vessel.ptd || vessel.etd), dayjs(canvasTimeEnd)]);
    } else {
      if (vessel.drawable) {
        return dayjs(vessel.drawable.visuals[vessel.drawable.visuals.length - 1].end_time);
      } else if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        return dayjs.min([dayjs(vessel.etd), dayjs(canvasTimeEnd)]);
      } else if (vessel.state === VESSEL_STATE_OFFERED) {
        return dayjs.min([dayjs(vessel.total_end), dayjs(canvasTimeEnd)]);
      } else if (vessel.state === VESSEL_STATE_ACCEPTED) {
        return dayjs.min([dayjs(vessel.total_end), dayjs(canvasTimeEnd)]);
      } else if (vessel.state === VESSEL_STATE_UPDATED) {
        return dayjs.min([dayjs(vessel.total_end), dayjs(canvasTimeEnd)]);
      } else {
        return dayjs.min([dayjs(vessel.end_time), dayjs(canvasTimeEnd)]);
      }
    }
  };

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();
  const startBollardIndex = berth.subRows.findIndex(b => b.id === vessel.start_bollard);
  const endBollardIndex = berth.subRows.findIndex(b => b.id === vessel.end_bollard);

  const starttime = getStartTime();
  const endtime = getEndTime();

  const left = dayjs.duration(dayjs(starttime).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels;
  const width = dayjs.duration(dayjs(endtime).diff(starttime)).asMilliseconds() * millisecondInPixels;

  let top = 0;

  useEffect(() => {
    if ([5, 6, 7].includes(vessel.state) && !vessel.loading_master_etd_active && moveSelection && moveSelection.moveX) {
      if (
        left - moveSelection.scrollLeft < Math.max(moveSelection.moveX, moveSelection.startX) &&
        left - moveSelection.scrollLeft + (isNaN(width) ? 0 : width) >
          Math.min(moveSelection.moveX, moveSelection.startX) &&
        berth.startHeight + top < Math.max(moveSelection.moveY, moveSelection.startY) &&
        berth.startHeight + top + vesselHeight > Math.min(moveSelection.moveY, moveSelection.startY) &&
        (multiselectedMoveVessels.length > 0
          ? multiselectedMoveVessels.findIndex(v => v.berth === vessel.berth_name) > -1
          : true)
      ) {
        setMultiselect(true);

        if (moveSelection.stopped) {
          setMultiselectedVessels(vs => {
            if (
              vs.findIndex(v => v.id === vessel.port_call_master_id) === -1 &&
              (vs.length > 0 ? vs.findIndex(v => v.berth === vessel.berth_name) > -1 : true)
            ) {
              let list = [...vs];
              list.push({ id: vessel.port_call_master_id, berth: vessel.berth_name });
              return list;
            }

            return vs;
          });
        }
      } else {
        setMultiselect(false);
      }
    } else if (multiselect) {
      setMultiselect(false);
    }
  }, [
    berth,
    left,
    moveSelection,
    multiselect,
    multiselectedMoveVessels,
    multiselectedVessels,
    setMultiselectedVessels,
    top,
    vessel,
    vesselHeight,
    width,
  ]);

  useEffect(() => {
    const realOpenValue =
      draggingArea && draggingArea.port_call_master_id === vessel.port_call_master_id ? draggingRowInfo.open : open;

    if (
      rowHeightRef.current !== rowHeight ||
      collapsedRowHeightRef.current !== collapsedRowHeight ||
      realOpenRef.current !== realOpenValue
    ) {
      let bollardAmount = 0;
      const startBollardIndex = berth.subRows.findIndex(b => b.id === vessel.start_bollard);
      const endBollardIndex = berth.subRows.findIndex(b => b.id === vessel.end_bollard);

      if (endBollardIndex < 0 || startBollardIndex < 0) {
        bollardAmount = 2;
      } else {
        bollardAmount = Math.abs(endBollardIndex - startBollardIndex) + 1;
      }

      let height = 0;
      const heightMultiplier = dynamicBollards ? 2 : 1;

      if (notPlannedPlace) {
        height = 2 * (realOpen ? rowHeight : collapsedRowHeight);
      } else if (Math.abs(endBollardIndex - startBollardIndex) > 0) {
        height = bollardAmount * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
      } else {
        height = heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
      }

      if (berthAreasAvailable) {
        height = realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
      }

      setVesselHeight(height);
    }

    rowHeightRef.current = rowHeight;
    collapsedRowHeightRef.current = collapsedRowHeight;
    realOpenRef.current = realOpenValue;
  }, [
    rowHeight,
    collapsedRowHeight,
    realOpen,
    berth.subRows,
    notPlannedPlace,
    vessel.start_bollard,
    vessel.end_bollard,
    draggingArea,
    vessel.port_call_master_id,
    draggingRowInfo.open,
    open,
    berthAreasAvailable,
    berth.id,
    dynamicBollards,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []); //eslint-disable-line

  if (!vessel.eta.match(/^\d/) || !vessel.etd.match(/^\d/)) {
    return null;
  }

  let startQueueIndicatorWidth = 0;
  if (vessel.drawable?.visuals && vessel.drawable.visuals[0].style === 'striped') {
    startQueueIndicatorWidth =
      (vessel.drawable.visuals[0].end_ts - vessel.drawable.visuals[0].start_ts) * 1000 * millisecondInPixels;
  } else if (
    vessel.total_start &&
    (vessel.state === VESSEL_STATE_ACCEPTED ||
      vessel.state === VESSEL_STATE_OFFERED ||
      vessel.state === VESSEL_STATE_UPDATED)
  ) {
    startQueueIndicatorWidth =
      dayjs.duration(dayjs(vessel.total_start).diff(dayjs(starttime))).asMilliseconds() * millisecondInPixels;
  }

  let endQueueIndicatorWidth = 0;
  if (
    vessel.drawable?.visuals?.length > 1 &&
    vessel.drawable.visuals[vessel.drawable.visuals.length - 1].style === 'striped'
  ) {
    endQueueIndicatorWidth =
      (vessel.drawable.visuals[vessel.drawable.visuals.length - 1].end_ts -
        vessel.drawable.visuals[vessel.drawable.visuals.length - 1].start_ts) *
      1000 *
      millisecondInPixels;
  }

  if (!berthAreasAvailable) {
    const heightMultiplier = dynamicBollards ? 2 : 1;
    if (notPlannedPlace) {
      top = notPlannedPlace;
    } else if (endBollardIndex - startBollardIndex < 0) {
      top = rowHeight + endBollardIndex * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
    } else if (endBollardIndex - startBollardIndex === 0) {
      top = rowHeight + startBollardIndex * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
    } else {
      top = rowHeight + startBollardIndex * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);
    }
  } else {
    if (berth.id === 'unknown') {
      top = notPlannedPlace;
    } else {
      let subRow = berth.subRows.find(r => r.id === vessel.berth_code);
      if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        top =
          rowHeight +
          ((subRow?.rowIndex || 0) + 1 + vessel.not_planned_row) * (realOpen ? 2 * rowHeight : 2 * collapsedRowHeight);
      } else {
        top = rowHeight + (subRow?.rowIndex || 0) * (realOpen ? 2 * rowHeight : 2 * collapsedRowHeight);
      }
    }
  }

  const getVesselProps = () => {
    let backgroundColor;

    if (vessel.grey) {
      backgroundColor = '#E8E8E8';
    } else if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
      backgroundColor = color_not_planned;
    } else if (vessel.state === VESSEL_STATE_UNCOMMITTED) {
      backgroundColor = color_uncommitted;
    } else if (vessel.state === VESSEL_STATE_PLANNED) {
      backgroundColor = color_planned;
    } else if (vessel.state === VESSEL_STATE_RESERVED) {
      backgroundColor = color_reserved;
    } else if (vessel.state === VESSEL_STATE_ACCEPTED) {
      backgroundColor = color_planned;
    } else if (vessel.state === VESSEL_STATE_OFFERED) {
      backgroundColor = color_uncommitted;
    } else if (vessel.state === VESSEL_STATE_UPDATED) {
      backgroundColor = color_updated;
    } else {
      backgroundColor = color_not_planned;
    }

    let fontSize = realOpen ? '12px' : '10px';

    return {
      style: {
        width: isNaN(width) ? 0 : width,
        top,
        left,
        backgroundColor,
        height: vesselHeight,
        fontSize,
      },
    };
  };

  const getIconProps = () => {
    let topToBottom = true;
    let height = (endBollardIndex - startBollardIndex + 1) * (realOpen ? rowHeight : collapsedRowHeight);

    if (height < 0) {
      topToBottom = false;
    }

    return {
      style: {
        transform: topToBottom ? '' : 'scale(-1)',
      },
    };
  };

  const throttledSet = position => {
    //eslint-disable-next-line
    if (axisRef.current === "x") {
      position.y = 0;
    }
    //eslint-disable-next-line
    if (axisRef.current === "y") {
      position.x = 0;
    }

    if (dragging.current && settingOk) {
      const placement = berth.startHeight + top + position.y;

      if (!berthAreasAvailable) {
        let bollardAmount;
        let tooBig = false;

        const currentBerth = berths.find((b, i) =>
          i < berths.length - 1
            ? b.startHeight < placement && berths[i + 1].startHeight > placement
            : b.startHeight < placement
        );

        if (currentBerth) {
          const bollardIndex = Math.trunc(
            (placement - (currentBerth ? currentBerth.startHeight : 0) - rowHeight) /
              ((dynamicBollards ? 2 : 1) * (realOpen ? rowHeight : collapsedRowHeight))
          );

          const currentBollard = currentBerth.subRows[bollardIndex];
          const bollardCode = currentBollard ? currentBollard.id : null;
          bollardAmount =
            bollardCode && vessel.bollard_count_lookup[currentBerth.id][bollardCode]
              ? vessel.bollard_count_lookup[currentBerth.id][bollardCode]
              : 2;

          if (!bollardCode || !vessel.bollard_count_lookup[currentBerth.id][bollardCode]) {
            tooBig = true;
            let nullBollardCount = 1;

            if (currentBerth.id !== 'unknown') {
              Object.keys(vessel.bollard_count_lookup[currentBerth.id]).forEach(b => {
                if (!vessel.bollard_count_lookup[currentBerth.id][b]) {
                  nullBollardCount++;
                }
              });
              bollardAmount = nullBollardCount;
            }
          }
        } else {
          bollardAmount = 2;
        }

        const heightMultiplier = dynamicBollards ? 2 : 1;
        let vHeight = bollardAmount * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);

        if (berthAreasAvailable) {
          vHeight = realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
        }

        setVesselHeight(vHeight);
        setDraggingArea({
          top: berth.startHeight + top + position.y,
          right: left + width + position.x,
          bottom: berth.startHeight + top + vHeight + position.y,
          left: left + position.x,
          port_call_master_id: vessel.port_call_master_id,
          bollardAmount,
          new: false,
          state: vessel.state,
          tagId: vessel.tagId,
          berth_transitions: vessel.berth_transitions,
          tooBig,
        });
      } else {
        let vHeight = realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
        setVesselHeight(vHeight);
        setDraggingArea({
          top: berth.startHeight + top + position.y,
          right: left + width + position.x,
          bottom: berth.startHeight + top + vHeight + position.y,
          left: left + position.x,
          port_call_master_id: vessel.port_call_master_id,
          bollardAmount: 0,
          new: false,
          state: vessel.state,
          tagId: vessel.tagId,
          berth_transitions: vessel.berth_transitions,
          tooBig: false,
        });

        if (multiselect) {
          setMultiselectedMove({ x: position.x, id: vessel.port_call_master_id });
        }
      }

      setSettingOk(false);
      setTimeout(() => {
        if (mounted.current) {
          setSettingOk(true);
        }
      }, 100);
    }
  };

  const onStop = (e, position) => {
    //eslint-disable-next-line
    if (axisRef.current === "x") {
      position.y = 0;
    }
    //eslint-disable-next-line
    if (axisRef.current === "y") {
      position.x = 0;
    }

    if (disabledRef.current) {
      position.x = 0;
      position.y = 0;
    }

    if (dragging.current || (!dragging.current && disabledRef.current)) {
      dragging.current = false;
      lockedToAxis.current = false;
      const placement = berth.startHeight + top + position.y;

      // Send unchanged pbp_data if cranes should not move when dragging vessel
      const filteredPbpData = disablePbpDragging && vessel.pbp_data?.works?.length ? [
        {
          works: getPbpWorksFromData(vessel.pbp_data.works)
        },
      ] : null;

      if (!berthAreasAvailable) {
        let bollardAmount;
        let tooBig = false;

        const currentBerth = berths.find((b, i) =>
          i < berths.length - 1
            ? b.startHeight < placement && berths[i + 1].startHeight > placement
            : b.startHeight < placement
        );

        if (currentBerth) {
          const bollardIndex = Math.trunc(
            (placement - (currentBerth ? currentBerth.startHeight : 0) - rowHeight) /
              ((dynamicBollards ? 2 : 1) * (realOpen ? rowHeight : collapsedRowHeight))
          );

          const currentBollard = currentBerth.subRows[bollardIndex];
          const bollardCode = currentBollard ? currentBollard.id : null;
          bollardAmount =
            bollardCode && vessel.bollard_count_lookup[currentBerth.id][bollardCode]
              ? vessel.bollard_count_lookup[currentBerth.id][bollardCode]
              : 2;

          if (!bollardCode || !vessel.bollard_count_lookup[currentBerth.id][bollardCode]) {
            tooBig = true;
            let nullBollardCount = 1;

            if (currentBerth.id !== 'unknown') {
              Object.keys(vessel.bollard_count_lookup[currentBerth.id]).forEach(b => {
                if (!vessel.bollard_count_lookup[currentBerth.id][b]) {
                  nullBollardCount++;
                }
              });
              bollardAmount = nullBollardCount;
            }
          }
        } else {
          bollardAmount = 2;
        }

        const heightMultiplier = dynamicBollards ? 2 : 1;
        let vHeight = bollardAmount * heightMultiplier * (realOpen ? rowHeight : collapsedRowHeight);

        if (berthAreasAvailable) {
          vHeight = realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
        }

        setVesselHeight(vHeight);
        setDraggingArea({
          top: berth.startHeight + top + position.y,
          right: left + width + position.x,
          bottom: berth.startHeight + top + vHeight + position.y,
          left: left + position.x,
          port_call_master_id: vessel.port_call_master_id,
          bollardAmount,
          update: true,
          new: false,
          state: vessel.state,
          tagId: vessel.tagId,
          berth_transitions: vessel.berth_transitions,
          tooBig,
          ...(filteredPbpData ? { pbp_data: filteredPbpData } : {}),
        });
      } else {
        let vHeight = realOpen ? 2 * rowHeight : 2 * collapsedRowHeight;
        setVesselHeight(vHeight);
        setDraggingArea({
          top: berth.startHeight + top + position.y,
          right: left + width + position.x,
          bottom: berth.startHeight + top + vHeight + position.y,
          left: left + position.x,
          port_call_master_id: vessel.port_call_master_id,
          bollardAmount: 0,
          update: true,
          new: false,
          state: vessel.state,
          tagId: vessel.tagId,
          berth_transitions: vessel.berth_transitions,
          tooBig: false,
          ...(filteredPbpData ? { pbp_data: filteredPbpData } : {}),
        });

        if (multiselect) {
          setMultiselectedMove({ x: position.x, id: vessel.port_call_master_id });
        }
      }
    } else {
      if (vessel.state === VESSEL_STATE_RESERVED) {
        if (modules.queue_module === 'enabled') {
          setEditBerthBlockModalOpen(true);
          setBptModalOpen(true);
        } else {
          setEditReservationModalOpen(true);
          setBptModalOpen(true);
        }
      } else if (modules.queue_module === 'enabled') {
        if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
          setEditNotPlannedJitModalOpen(true);
          setBptModalOpen(true);
        } else {
          if (multiselect) {
            setMultiselectedModalOpen(true);
          } else {
            setEditSlotRequestModalOpen(true);
            setBptModalOpen(true);
          }
        }
      } else {
        if (predictiveBPTOn) {
          setEditPredictiveBerthPlanModalOpen(true);
          setBptModalOpen(true);
        } else {
          setEditBerthPlanModalOpen(true);
          setBptModalOpen(true);
        }
      }
    }
  };

  const onDrag = (e, position) => {
    if (modules.queue_module !== 'enabled') {
      //eslint-disable-next-line
      if (axisRef.current === "x" && Math.abs(position.y) > 130) {
        //eslint-disable-next-line
        axisRef.current = "both";
      }
      //eslint-disable-next-line
      if (axisRef.current === "y" && Math.abs(position.x) > 130) {
        //eslint-disable-next-line
        axisRef.current = "both";
      }
      //eslint-disable-next-line
      if (axisRef.current === "both" && lockedToAxis.current) {
        if (prevAxis.current === "x" && Math.abs(position.y) < 15) { //eslint-disable-line
          axisRef.current = "x"; //eslint-disable-line
        }
        if (prevAxis.current === "y" && Math.abs(position.x) < 15) { //eslint-disable-line
          axisRef.current = "y"; //eslint-disable-line
        }
      }

      if (Math.abs(position.x) > 4 && !lockedToAxis.current) {
        //eslint-disable-next-line
        axisRef.current = "x";
        lockedToAxis.current = true;
        //eslint-disable-next-line
        prevAxis.current = "x";
      }

      if (Math.abs(position.y) > 4 && !lockedToAxis.current) {
        //eslint-disable-next-line
        axisRef.current = "y"
        lockedToAxis.current = true;
        //eslint-disable-next-line
        prevAxis.current = "y"
      }
    }
    if (lockedToAxis.current) {
      dragging.current = true;
      throttledSet(position);
    }
  };

  const getTimeHeader = start => {
    if (modules.queue_module !== 'enabled') {
      if (start) {
        return vessel.pta ? t('PTA') : t('ETA Berth');
      } else {
        return vessel.pta ? t('PTD') : t('ETD');
      }
    } else {
      if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        if (start) {
          return t('ETA Berth');
        } else {
          return t('ETD');
        }
      } else if (vessel.state === VESSEL_STATE_OFFERED) {
        if (start) {
          return t('ETA Berth');
        } else {
          return t('ETD');
        }
      } else if (vessel.state === VESSEL_STATE_ACCEPTED) {
        if (start) {
          return t('ETA Berth');
        } else {
          return t('ETD');
        }
      } else if (vessel.state === VESSEL_STATE_UPDATED) {
        if (start) {
          return t('ETA Berth');
        } else {
          return t('ETD');
        }
      } else {
        if (start) {
          return t('Start');
        } else {
          return t('End');
        }
      }
    }
  };

  const getTimeValue = start => {
    if (modules.queue_module !== 'enabled') {
      if (start) {
        return dayjs(vessel.pta || vessel.eta).format(TIME_FORMAT);
      } else {
        return dayjs(vessel.ptd || vessel.etd).format(TIME_FORMAT);
      }
    } else {
      if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        if (start) {
          return dayjs(vessel.eta).format(TIME_FORMAT);
        } else {
          return dayjs(vessel.etd).format(TIME_FORMAT);
        }
      } else if (vessel.state === VESSEL_STATE_OFFERED) {
        if (start) {
          return dayjs(vessel.eta).format(TIME_FORMAT);
        } else {
          return dayjs(vessel.etd).format(TIME_FORMAT);
        }
      } else if (vessel.state === VESSEL_STATE_ACCEPTED) {
        if (start) {
          return dayjs(vessel.eta).format(TIME_FORMAT);
        } else {
          return dayjs(vessel.etd).format(TIME_FORMAT);
        }
      } else if (vessel.state === VESSEL_STATE_UPDATED) {
        if (start) {
          return dayjs(vessel.eta).format(TIME_FORMAT);
        } else {
          return dayjs(vessel.etd).format(TIME_FORMAT);
        }
      } else {
        if (start) {
          return dayjs(vessel.start_time).format(TIME_FORMAT);
        } else {
          return dayjs(vessel.end_time).format(TIME_FORMAT);
        }
      }
    }
  };

  const getQueueIndicatorsTooltip = () => {
    let backgroundColor;

    if (vessel.state === VESSEL_STATE_ACCEPTED) {
      backgroundColor = color_planned;
    } else if (vessel.state === VESSEL_STATE_OFFERED) {
      backgroundColor = color_uncommitted;
    } else {
      backgroundColor = color_updated;
    }
    return (
      <QueueIndicatorTooltipContainer>
        {startQueueIndicatorWidth > 0 && (
          <QueueIndicatorTooltip>
            <ColorPanel>
              <StripedPart color={backgroundColor} />
              <ColoredPart color={backgroundColor} vesselState={vessel.state} />
            </ColorPanel>
            <Texts>
              <TooltipText style={{ marginLeft: '16px' }}>{t('RTA')}</TooltipText>
              <TooltipText style={{ marginLeft: '12px' }}>{t('ETA')}</TooltipText>
            </Texts>
          </QueueIndicatorTooltip>
        )}
        {endQueueIndicatorWidth > 0 && (
          <QueueIndicatorTooltip style={{ width: '94px' }}>
            <ColorPanel style={{ marginLeft: '0px' }}>
              <ColoredPart color={backgroundColor} vesselState={vessel.state} />
              <StripedPart
                color={backgroundColor}
                style={{
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                  borderTopRightRadius: '3px',
                  borderBottomRightRadius: '3px',
                }}
              />
            </ColorPanel>
            <Texts>
              <TooltipText style={{ marginLeft: '30px' }}>{t('ETC')}</TooltipText>
              <TooltipText style={{ marginLeft: '12px' }}>{t('ETD')}</TooltipText>
            </Texts>
          </QueueIndicatorTooltip>
        )}
      </QueueIndicatorTooltipContainer>
    );
  };

  const VesselToolTip = () => {
    return (
      <TooltipContainer data-id="vessel-tooltip">
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Vessel')}:</RowHeaderForTooltip>
            {modules.queue_module !== 'enabled' && <RowHeaderForTooltip>{t('Agent')}:</RowHeaderForTooltip>}
            {modules.queue_module === 'enabled' &&
              (vessel.state === VESSEL_STATE_OFFERED || vessel.state === VESSEL_STATE_UPDATED) && (
              <RowHeaderForTooltip>{t('RTA')}:</RowHeaderForTooltip>
            )}
            {modules.queue_module === 'enabled' &&
              (vessel.state === VESSEL_STATE_ACCEPTED || vessel.state === VESSEL_STATE_UPDATED) && (
              <RowHeaderForTooltip>{t('RTA conf')}:</RowHeaderForTooltip>
            )}
            <RowHeaderForTooltip>{getTimeHeader(true)}:</RowHeaderForTooltip>
            {!!vessel.ata && <RowHeaderForTooltip>{'ATA'}:</RowHeaderForTooltip>}
            {vessel.drawable?.visuals?.length > 1 &&
              vessel.drawable.visuals[vessel.drawable.visuals.length - 1].style === 'striped' && (
              <RowHeaderForTooltip>{'ETC'}:</RowHeaderForTooltip>
            )}
            <RowHeaderForTooltip>{getTimeHeader(false)}:</RowHeaderForTooltip>
            {!!vessel.atd && <RowHeaderForTooltip>{'ATD'}:</RowHeaderForTooltip>}
            {modules.queue_module === 'enabled' && <RowHeaderForTooltip>{t('Berth')}:</RowHeaderForTooltip>}
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{vessel.name ? vessel.name : '-'}</TooltipValue>
            {modules.queue_module !== 'enabled' && <TooltipValue>{vessel.agent ? vessel.agent : '-'}</TooltipValue>}
            {modules.queue_module === 'enabled' &&
              (vessel.state === VESSEL_STATE_OFFERED || vessel.state === VESSEL_STATE_UPDATED) && (
              <TooltipValue>{vessel.rta ? dayjs(vessel.rta).format(TIME_FORMAT) : '-'}</TooltipValue>
            )}
            {modules.queue_module === 'enabled' &&
              (vessel.state === VESSEL_STATE_ACCEPTED || vessel.state === VESSEL_STATE_UPDATED) && (
              <TooltipValue>{vessel.pta ? dayjs(vessel.pta).format(TIME_FORMAT) : '-'}</TooltipValue>
            )}
            <TooltipValue>{getTimeValue(true)}</TooltipValue>
            {!!vessel.ata && <TooltipValue>{vessel.ata ? dayjs(vessel.ata).format(TIME_FORMAT) : '-'}</TooltipValue>}
            {vessel.drawable?.visuals?.length > 1 &&
              vessel.drawable.visuals[vessel.drawable.visuals.length - 1].style === 'striped' && (
              <TooltipValue>
                {dayjs(vessel.drawable.visuals[vessel.drawable.visuals.length - 1].start_time).format(TIME_FORMAT)}
              </TooltipValue>
            )}
            <TooltipValue>{getTimeValue(false)}</TooltipValue>
            {!!vessel.atd && <TooltipValue>{vessel.atd ? dayjs(vessel.atd).format(TIME_FORMAT) : '-'}</TooltipValue>}
            {modules.queue_module === 'enabled' && (
              <TooltipValue>{vessel.berth_short_name ? vessel.berth_short_name : '-'}</TooltipValue>
            )}
          </SecondColumn>
        </Common>
        {vessel.notes && vessel.notes.length > 0 && (
          <ReservationNote>
            <FirstColumn>
              <RowHeaderForTooltip>{t('Notes')}:</RowHeaderForTooltip>
            </FirstColumn>
            <SecondColumn>
              <NoteValue>{vessel.notes}</NoteValue>
            </SecondColumn>
          </ReservationNote>
        )}
        {additionalData &&
          Object.keys(additionalData).map((k, index) => (
            <AdditionalData key={index}>
              <FullRow>{t(k)}</FullRow>
              <AdditionalDataContainer>
                <FirstColumn>
                  {additionalData[k].map((c, j) => (
                    <RowHeaderForTooltip key={j}>{t(c.name)}:</RowHeaderForTooltip>
                  ))}
                </FirstColumn>
                <SecondColumn>
                  {additionalData[k].map((c, j) => (
                    <TooltipValue key={j}>{c.value}</TooltipValue>
                  ))}
                </SecondColumn>
              </AdditionalDataContainer>
            </AdditionalData>
          ))}
        {(startQueueIndicatorWidth > 0 || endQueueIndicatorWidth > 0) && getQueueIndicatorsTooltip()}
        {predictiveBPTOn && craneData && (
          <PBPPart>
            <TotalCraneRow>
              <Text style={{ marginRight: '12px' }}>{t('Total crane operation')}</Text>
              <Text style={{ color: '#747D7D' }}>
                {vessel.pbp_data?.start_time ? dayjs(vessel.pbp_data.start_time).format(TIME_FORMAT) : '-'}
              </Text>
              <Text style={{ margin: '0 4px', color: '#747D7D' }}>{'-'}</Text>
              <Text style={{ color: '#747D7D' }}>
                {vessel.pbp_data?.end_time ? dayjs(vessel.pbp_data.end_time).format(TIME_FORMAT) : '-'}
              </Text>
              <Text style={{ fontWeight: 700, marginLeft: '12px' }}>{getDuration(vessel.pbp_data.duration)}</Text>
            </TotalCraneRow>
            {!!craneHover && (
              <CranePart>
                <Common>
                  <FirstColumn style={{ marginRight: '18px' }}>
                    <RowHeaderForTooltip>{t('Crane name')}</RowHeaderForTooltip>
                    {user.permissions.includes('manage_pbp') && (
                      <RowHeaderForTooltip style={{ whiteSpace: 'nowrap' }}>
                        {t('Commodity (amount)')}
                      </RowHeaderForTooltip>
                    )}
                    <RowHeaderForTooltip style={{ marginBottom: '6px' }}>{t('Operation time')}</RowHeaderForTooltip>
                    {!!craneHover.weather_adjusted_end_time && (
                      <RowHeaderForTooltip
                        style={{
                          marginBottom: '6px',
                          height: '30px',
                          lineHeight: '14px',
                          fontWeight: 700,
                        }}
                      >
                        {t('Weather adjusted operation time')}
                      </RowHeaderForTooltip>
                    )}
                    {craneHover.major_breaks &&
                      !!craneHover.major_breaks.length &&
                      craneHover.major_breaks.map((b, j) => {
                        return <RowHeaderForTooltip key={j}>{b.name}</RowHeaderForTooltip>;
                      })}
                  </FirstColumn>
                  <SecondColumn>
                    <TooltipValue>
                      {craneHover.crane_name} ({craneHover.crane_short_name})
                    </TooltipValue>
                    {user.permissions.includes('manage_pbp') && (
                      <TooltipValue>
                        {craneHover.commodity_name || '-'} ({craneHover.commodity_amount || '-'})
                      </TooltipValue>
                    )}
                    <TooltipValue style={{ fontWeight: 400, marginBottom: '6px' }}>
                      {dayjs(craneHover.start_time).format(TIME_FORMAT) +
                        ' - ' +
                        dayjs(craneHover.end_time).format(TIME_FORMAT)}
                    </TooltipValue>
                    {!!craneHover.weather_adjusted_end_time && (
                      <TooltipValue
                        style={{
                          marginBottom: '6px',
                          height: '30px',
                          lineHeight: '14px',
                          paddingTop: '14px',
                          fontWeight: 700,
                        }}
                      >
                        {dayjs(craneHover.start_time).format(TIME_FORMAT) +
                          ' - ' +
                          dayjs(craneHover.weather_adjusted_end_time).format(TIME_FORMAT)}
                      </TooltipValue>
                    )}
                    {craneHover.major_breaks &&
                      !!craneHover.major_breaks.length &&
                      craneHover.major_breaks.map((b, j) => {
                        return (
                          <TooltipValue style={{ fontWeight: 400 }} key={j}>
                            {dayjs(b.start_time).format(TIME_FORMAT) + ' - ' + dayjs(b.end_time).format(TIME_FORMAT)}
                          </TooltipValue>
                        );
                      })}
                  </SecondColumn>
                  <SecondColumn style={{ marginLeft: '18px' }}>
                    <TooltipValue style={{ width: '3px', height: '21px' }}></TooltipValue>
                    {user.permissions.includes('manage_pbp') && (
                      <TooltipValue style={{ width: '3px', height: '21px' }}></TooltipValue>
                    )}
                    <TooltipValue style={{ fontWeight: 600, marginBottom: '6px' }}>
                      {getDuration(craneHover.duration)}
                    </TooltipValue>
                    {!!craneHover.weather_adjusted_end_time && (
                      <TooltipValue
                        style={{
                          fontWeight: 700,
                          marginBottom: '6px',
                          height: '30px',
                          lineHeight: '14px',
                          paddingTop: '14px',
                        }}
                      >
                        {getDuration(craneHover.weather_adjusted_duration)}
                      </TooltipValue>
                    )}
                    {craneHover.major_breaks &&
                      !!craneHover.major_breaks.length &&
                      craneHover.major_breaks.map((b, j) => {
                        return (
                          <TooltipValue style={{ fontWeight: 600 }} key={j}>
                            {getDuration(b.duration)}
                          </TooltipValue>
                        );
                      })}
                  </SecondColumn>
                </Common>

                {craneHover.minor_breaks && !!craneHover.minor_breaks.length && (
                  <MinorBreaks>{t('And minor breaks, such as coffee, lunch and shift changes.')}</MinorBreaks>
                )}
              </CranePart>
            )}
          </PBPPart>
        )}
      </TooltipContainer>
    );
  };

  const AnchorageToolTip = (anchorageLocation, anchorage) => {
    return (
      <TooltipContainer data-id="anchorage-tooltip">
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Location')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ETA')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ATA')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ETD')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ATD')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{t(anchorageLocation)}</TooltipValue>
            <TooltipValue>{anchorage.eta ? dayjs(anchorage.eta).format(TIME_FORMAT) : '-'}</TooltipValue>
            <TooltipValue>{anchorage.ata ? dayjs(anchorage.ata).format(TIME_FORMAT) : '-'}</TooltipValue>
            <TooltipValue>{anchorage.etd ? dayjs(anchorage.etd).format(TIME_FORMAT) : '-'}</TooltipValue>
            <TooltipValue>{anchorage.atd ? dayjs(anchorage.atd).format(TIME_FORMAT) : '-'}</TooltipValue>
          </SecondColumn>
        </Common>
      </TooltipContainer>
    );
  };

  const closeModal = () => {
    setEditReservationModalOpen(false);
    setBptModalOpen(false);
  };

  const closeBerthBlockModal = () => {
    setEditBerthBlockModalOpen(false);
    setBptModalOpen(false);
  };

  const closeSlotRequestModal = withData => {
    setEditSlotRequestModalOpen(false);
    if (queueModalTypeOpen) {
      if (!withData) {
        keepVesselLocation(vessel);
      }
      setQueueModalTypeOpen(undefined);
    }
    setBptModalOpen(false);
  };

  const closeBerthPlanModal = () => {
    setEditBerthPlanModalOpen(false);
    setDisableScrolling(false);
    setBptModalOpen(false);
  };

  const closePredictiveBerthPlanModal = () => {
    setEditPredictiveBerthPlanModalOpen(false);
    setBptModalOpen(false);
  };

  const closeNotPlannedJitModal = withData => {
    setEditNotPlannedJitModalOpen(false);
    if (queueModalTypeOpen) {
      if (!withData) {
        keepVesselLocation(vessel);
      }
      setQueueModalTypeOpen(undefined);
    }
    setBptModalOpen(false);
  };

  const onVesselClick = () => {
    if (vessel.state === VESSEL_STATE_RESERVED) {
      if (modules.queue_module === 'enabled') {
        setEditBerthBlockModalOpen(true);
        setBptModalOpen(true);
      } else {
        setEditReservationModalOpen(true);
        setBptModalOpen(true);
      }
    } else if (modules.queue_module === 'enabled') {
      if (vessel.state === VESSEL_STATE_NOT_PLANNED) {
        setEditNotPlannedJitModalOpen(true);
        setBptModalOpen(true);
      } else {
        setEditSlotRequestModalOpen(true);
        setBptModalOpen(true);
      }
    } else {
      if (predictiveBPTOn) {
        setEditPredictiveBerthPlanModalOpen(true);
        setBptModalOpen(true);
      } else {
        setEditBerthPlanModalOpen(true);
        setBptModalOpen(true);
      }
    }
  };

  const getCraneHeight = rowAmount => {
    if (modules.predictive_berth_planning_module !== 'enabled' || !craneData || !predictiveBPTOn) {
      return 0;
    }

    if (vesselHeight > 62) {
      if (rowAmount === 1) {
        return 24;
      } else if (rowAmount === 2) {
        return Math.min(42, vesselHeight / 2 - 2);
      } else {
        return Math.min(63, vesselHeight / 2 - 2);
      }
    } else if (vesselHeight > 54) {
      return 24;
    } else if (vesselHeight > 46) {
      return 20;
    } else if (vesselHeight > 38) {
      return 16;
    } else if (vesselHeight > 30) {
      return 12;
    } else if (vesselHeight > 24) {
      return 9;
    } else if (vesselHeight > 14) {
      return 6;
    } else if (vesselHeight > 8) {
      return 3;
    } else {
      return 0;
    }
  };

  const VesselContainerCreator = hiddenVessel => {
    let craneRows = [];
    let craneList = [];

    if (craneData) {
      craneList = vessel.pbp_data.works.map(work => {
        const craneRowKey = craneRows.findIndex(r => r.toString() === work.crane_id.toString());

        const workData = { ...work };

        if (craneRowKey === -1) {
          craneRows.push(work.crane_id);
          workData.rowIndex = craneRows.length - 1;
        } else {
          workData.rowIndex = craneRowKey;
        }

        return workData;
      });
    }

    const craneRowAmount = craneRows.length > 3 ? 3 : craneRows.length;
    const craneContainerHeight = getCraneHeight(craneRowAmount);
    const drawPbp = disablePbpDragging && dragging.current ? false : true;

    return (
      <VesselContainer
        ref={draggableRef}
        hiddenVessel={hiddenVessel}
        data-testid={`vessel-${vessel.port_call_master_id}`}
        {...getVesselProps()}
        overlapping={dragging.current && (draggingOverlapping || disabledRef.current || draggingArea?.tooBig)}
        dragging={dragging.current}
        locked={vessel.locked}
        noDrag={
          !user.permissions.includes('manage_bpt') ||
          vessel.state === VESSEL_STATE_RESERVED ||
          vessel.loading_master_etd_active ||
          (multiselectedVessels.length > 0 &&
            multiselectedVessels.findIndex(v => v.id === vessel.port_call_master_id) === -1)
        }
        onClick={
          !hiddenVessel &&
          (vessel.locked ||
            vessel.state === VESSEL_STATE_RESERVED ||
            !user.permissions.includes('manage_bpt') ||
            vessel.loading_master_etd_active)
            ? onVesselClick
            : null
        }
        multiselect={multiselect}
        multiselectNotChosen={
          multiselectedVessels.length > 0 &&
          multiselectedVessels.findIndex(v => v.id === vessel.port_call_master_id) === -1
        }
      >
        <Tooltip
          placement="top"
          title={dragging.current || hiddenVessel ? null : VesselToolTip()}
          color="white"
          overlayStyle={{ pointerEvents: 'none' }}
          open={bptModalOpen || dragging.current || multiselectedModalOpen ? false : undefined}
        >
          <MiddleDiv vesselState={modules.queue_module === 'enabled' ? vessel.state : undefined}>
            <VesselInnerContainer craneHeight={craneContainerHeight + 'px'}>
              {modules.queue_module !== 'enabled' && (
                <IconContainer
                  {...getIconProps()}
                  smallIcon={craneData && predictiveBPTOn ? vesselHeight < 30 : vesselHeight < 24}
                  tinyIcon={vesselHeight < 14}
                >
                  {vessel.grey ? <Icon type="vessels-down-grey"></Icon> : <Icon type="vessels-down"></Icon>}
                </IconContainer>
              )}
              <VesselInfoContainer>
                <VesselNameContainer icon={modules.queue_module !== 'enabled'}>{vessel.name}</VesselNameContainer>
                {(zoomSliderValue > 15 || realOpen) && additionalData ? (
                  <VesselCargoContainer icon={modules.queue_module !== 'enabled'}>
                    {Object.keys(additionalData)
                      .map(k => additionalData[k].map(c => t(c.name)))
                      .join(', ')}
                  </VesselCargoContainer>
                ) : null}
              </VesselInfoContainer>
            </VesselInnerContainer>
            {predictiveBPTOn && !!craneList.length && !!craneRowAmount && drawPbp && (
              <CraneContainer craneHeight={craneContainerHeight + 'px'}>
                {craneList.map((d, i) => {
                  if (d.rowIndex > 2) {
                    return null;
                  }
                  let craneLeft =
                    dayjs.duration(dayjs(d.start_time).diff(dayjs(starttime))).asMilliseconds() * millisecondInPixels +
                    1;
                  let craneWidth =
                    dayjs.duration(dayjs(d.end_time).diff(d.start_time)).asMilliseconds() * millisecondInPixels - 1;

                  let showCraneName = (craneContainerHeight - craneRowAmount - 1) / craneRowAmount > 6;

                  let height = (craneContainerHeight - 1 - craneRowAmount) / craneRowAmount;
                  let weatherAdjustmentHeight = 0;
                  let weatherAdjustmentWidth = 0;
                  let weatherBg = false;

                  if (d.weather_adjusted_end_time) {
                    weatherAdjustmentWidth =
                      dayjs.duration(dayjs(d.weather_adjusted_end_time).diff(d.start_time)).asMilliseconds() *
                        millisecondInPixels -
                      1;
                    if (height < 4) {
                      weatherAdjustmentHeight = height;
                      weatherBg = true;
                    } else if (height >= 4 && height < 6) {
                      weatherAdjustmentHeight = 1;
                    } else if (height >= 6 && height < 9) {
                      weatherAdjustmentHeight = 2;
                    } else {
                      weatherAdjustmentHeight = 3;
                    }
                  }

                  return (
                    <div key={i}>
                      {!!weatherAdjustmentHeight && weatherBg && (
                        <WeatherCrane
                          data-id="weather-crane-bg"
                          onMouseEnter={() => setCraneHover(d)}
                          onMouseLeave={() => setCraneHover(undefined)}
                          style={{
                            height: weatherAdjustmentHeight + 'px',
                            left: craneLeft + 'px',
                            top: `calc(100% / ${craneRowAmount} * ${d.rowIndex})`,
                            width: weatherAdjustmentWidth + 'px',
                          }}
                        />
                      )}

                      {!!weatherAdjustmentHeight && !weatherBg && (
                        <WeatherCrane
                          data-id="weather-crane"
                          onMouseEnter={() => setCraneHover(d)}
                          onMouseLeave={() => setCraneHover(undefined)}
                          style={{
                            height: weatherAdjustmentHeight + 'px',
                            left: craneLeft + 'px',
                            top:
                              (craneContainerHeight / craneRowAmount) * d.rowIndex +
                              height -
                              (weatherAdjustmentHeight && !weatherBg ? weatherAdjustmentHeight : 0) +
                              'px',
                            width: weatherAdjustmentWidth + 'px',
                          }}
                        />
                      )}
                      <CraneBox
                        onMouseEnter={() => setCraneHover(d)}
                        onMouseLeave={() => setCraneHover(undefined)}
                        smallMargin={vesselHeight < 15}
                        style={{
                          width: craneWidth + 'px',
                          left: craneLeft + 'px',
                          height:
                            height - (weatherAdjustmentHeight && !weatherBg ? weatherAdjustmentHeight + 1 : 0) + 'px',
                          top: `calc(100% / ${craneRowAmount} * ${d.rowIndex})`,
                        }}
                      >
                        {d.minor_breaks &&
                          !!d.minor_breaks.length &&
                          d.minor_breaks.map((b, j) => {
                            let breakLeft =
                              dayjs.duration(dayjs(b.start_time).diff(dayjs(d.start_time))).asMilliseconds() *
                                millisecondInPixels +
                              (i === 0 ? 2 : 1);
                            let breakWidth =
                              dayjs.duration(dayjs(b.end_time).diff(b.start_time)).asMilliseconds() *
                              millisecondInPixels;

                            if (breakLeft < 0) {
                              breakWidth = breakWidth + breakLeft;
                              breakLeft = 0;
                            }

                            return <Break key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />;
                          })}
                        {d.major_breaks &&
                          !!d.major_breaks.length &&
                          d.major_breaks.map((b, j) => {
                            let breakLeft =
                              dayjs.duration(dayjs(b.start_time).diff(dayjs(d.start_time))).asMilliseconds() *
                                millisecondInPixels +
                              (i === 0 ? 2 : 1);
                            let breakWidth =
                              dayjs
                                .duration(dayjs.min([dayjs(b.end_time), dayjs(d.end_time)]).diff(b.start_time))
                                .asMilliseconds() * millisecondInPixels;

                            if (breakLeft < 0) {
                              breakWidth = breakWidth + breakLeft;
                              breakLeft = 0;
                            }

                            if (b.type === 'crane_reservation') {
                              return (
                                <Reservation key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />
                              );
                            } else {
                              return <Break key={j} style={{ width: breakWidth + 'px', left: breakLeft + 'px' }} />;
                            }
                          })}
                        <CraneNameContainer show={showCraneName}>
                          <CraneName
                            bigText={(craneContainerHeight - craneRowAmount - 1) / craneRowAmount > 12}
                            tiny={(craneContainerHeight - craneRowAmount - 1) / craneRowAmount < 10}
                          >
                            {d.crane_short_name}
                          </CraneName>
                        </CraneNameContainer>
                      </CraneBox>
                    </div>
                  );
                })}
              </CraneContainer>
            )}
          </MiddleDiv>
          {startQueueIndicatorWidth > 0 && <QueueIndicator style={{ width: startQueueIndicatorWidth + 'px' }} />}
          {endQueueIndicatorWidth > 0 && (
            <QueueIndicator style={{ width: endQueueIndicatorWidth + 'px', right: '0px' }} />
          )}
        </Tooltip>
      </VesselContainer>
    );
  };

  const VesselAnchoragesCreator = () => {
    return (
      <AnchorageContainers>
        {vessel.anchorages &&
          Object.keys(vessel.anchorages).map(anchorageLocation => {
            const anchorage = vessel.anchorages[anchorageLocation];
            return (
              <Tooltip
                key={anchorageLocation}
                placement="top"
                title={dragging.current || hiddenVessel ? null : AnchorageToolTip(anchorageLocation, anchorage)}
                color="white"
                overlayStyle={{ pointerEvents: 'none' }}
                open={bptModalOpen || dragging.current || multiselectedModalOpen ? false : undefined}
              >
                <AnchorageContainer
                  key={anchorageLocation}
                  style={{
                    height: vesselHeight + 'px',
                    top: top,
                    left:
                      dayjs.duration(dayjs(anchorage.start).diff(dayjs(canvasTimeStart))).asMilliseconds() *
                      millisecondInPixels,
                    width:
                      dayjs.duration(dayjs(anchorage.end).diff(anchorage.start)).asMilliseconds() * millisecondInPixels,
                  }}
                >
                  <AnchorageBox>
                    <AnchorageNameContainer realOpen={realOpen}>{t(anchorageLocation)}</AnchorageNameContainer>
                  </AnchorageBox>
                </AnchorageContainer>
              </Tooltip>
            );
          })}
      </AnchorageContainers>
    );
  };

  if (!vesselLayerOn) {
    return null;
  }

  let hiddenVessel = false;

  switch (vessel.state) {
    case 1:
      hiddenVessel = !vesselOptions.notPlanned;
      break;
    case 2:
      hiddenVessel = !vesselOptions.uncommitted;
      break;
    case 3:
      hiddenVessel = !vesselOptions.planned;
      break;
    case 4:
      hiddenVessel = !vesselOptions.reserved;
      break;
    case 5:
      hiddenVessel = !vesselOptions.accepted;
      break;
    case 6:
      hiddenVessel = !vesselOptions.offered;
      break;
    case 7:
      hiddenVessel = !vesselOptions.updated;
      break;

    default:
      break;
  }

  return (
    <>
      <Draggable
        nodeRef={draggableRef}
        onStop={onStop}
        onDrag={onDrag}
        positionOffset={{
          x: draggingArea && draggingArea.port_call_master_id === vessel.port_call_master_id ? draggingValue + 'px' : 0,
          y: 0,
        }}
        disabled={
          !user.permissions.includes('manage_bpt') ||
          vessel.locked ||
          vessel.state === VESSEL_STATE_RESERVED ||
          vessel.loading_master_etd_active
        }
        axis={axisRef.current}
        position={
          multiselect && vessel.port_call_master_id !== multiselectedMove.id
            ? { x: multiselectedMove.x, y: 0 }
            : undefined
        }
      >
        {VesselContainerCreator(hiddenVessel)}
      </Draggable>
      {VesselAnchoragesCreator()}
      {!!editReservationModalOpen && (
        <AddOrEditReservationModal
          vessel={vessel}
          type={'edit'}
          closeModal={closeModal}
          readOnly={!user.permissions.includes('manage_bpt')}
        />
      )}
      {!!editBerthBlockModalOpen && (
        <AddOrEditBerthBlockModal
          vessel={vessel}
          type={'edit'}
          closeModal={closeBerthBlockModal}
          readOnly={!user.permissions.includes('manage_berth_reservation')}
        />
      )}
      {(!!editSlotRequestModalOpen ||
        (!!queueModalTypeOpen &&
          queueModalTypeOpen.type === 'time' &&
          queueModalTypeOpen.port_call_master_id === vessel.port_call_master_id)) && (
        <AddOrEditJITSlotRequestModal
          vessel={vessel}
          type={'edit'}
          closeModal={closeSlotRequestModal}
          readOnly={vessel.locked || !user.permissions.includes('manage_queue_slot_reservation')}
          newTime={queueModalTypeOpen ? queueModalTypeOpen.newPta : undefined}
        />
      )}
      {!!editBerthPlanModalOpen && (
        <AddOrEditBerthPlanModal
          vessel={vessel}
          additionalData={additionalData}
          type={'edit'}
          closeModal={closeBerthPlanModal}
          readOnly={vessel.locked || !user.permissions.includes('manage_bpt')}
        />
      )}
      {!!editPredictiveBerthPlanModalOpen && (
        <AddOrEditPredictiveBerthPlanModal
          vessel={vessel}
          type={'edit'}
          closeModal={closePredictiveBerthPlanModal}
          readOnly={vessel.locked || !user.permissions.includes('manage_pbp')}
        />
      )}
      {(!!editNotPlannedJitModalOpen ||
        (!!queueModalTypeOpen &&
          queueModalTypeOpen.type === 'berth' &&
          queueModalTypeOpen.port_call_master_id === vessel.port_call_master_id)) && (
        <EditJITSlotRequestBerth
          vessel={vessel}
          closeModal={closeNotPlannedJitModal}
          readOnly={vessel.locked || !user.permissions.includes('add_manual_timestamp')}
          newBerth={queueModalTypeOpen ? queueModalTypeOpen.newBerthCode : undefined}
        />
      )}
    </>
  );
};

export default Vessel;
