import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import { Chart, registerables } from 'chart.js';
import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;
`;

const BarContainer = styled.div`
  height: ${props => (props.column1 ? 'calc(100vh / 3)' : 'calc(100vh / 4)')};
  max-height: ${props => (props.column1 ? '400px' : '300px')};
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
  padding-right: 4px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 2px;
`;

const Button = styled.div`
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px;
  color: ${props => props.disabled && '#e8e8e8'};
  cursor: ${props => props.disabled && 'default'};

  svg {
    margin-top: -2px;
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f3f3f3')};
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const StatisticsBar2ColumnCompare = props => {
  const buildQueryParams = (timeType, time) => {
    if (dayjs(time).isAfter(dayjs())) {
      time = dayjs().toDate();
      setSelectedTime(time);
    }
    let from = null;
    let to = null;
    if (comparisonOffset === 'month') {
      from = dayjs(time).startOf('month');
      to = dayjs(time).endOf('month');
    } else if (comparisonOffset === 'year') {
      from = dayjs(time).startOf('year');
      to = dayjs(time).endOf('year');
    }
    dayjs(to).isAfter(dayjs()) && (to = dayjs());

    return {
      ...props.data_source.parameters,
      time_option: timeType,
      timezone: dayjs.tz.guess(),
      from: from.toISOString(),
      to: to.toISOString(),
    };
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const comparisonOffset = props.data_source.parameters.comparison_offset;
  const timeOptions = props.time_options;
  const [timeType, setTimeType] = useState(timeOptions[0]); // month, year
  const [selectedTime, setSelectedTime] = useState(dayjs().toDate()); // week, month, year
  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useApi(
    'get',
    props.data_source.url,
    buildQueryParams(timeType, selectedTime)
  );
  const dataArray = error || !data ? [] : data.data;
  const oldDataArray = dataArray.old;
  const newDataArray = dataArray.new;
  const dataKey = props.data_options;
  const legendObject = props.legend;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.6,
    categoryPercentage: 0.6,
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 25,
      },
    },
    elements: {
      bar: {
        borderRadius: 6,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [8, 6],
          color: '#f4f4f4',
        },
      },
    },
  };

  let datasets = null;
  if (oldDataArray && newDataArray) {
    datasets = { labels: timeType === 'month' ? oldDataArray.map(g => dayjs(g.time).format('MMMM')) : [''] };
    datasets.datasets = [{}, {}];
    datasets.datasets[0].label = dayjs(oldDataArray[0].time).format('YYYY');
    datasets.datasets[0].data = oldDataArray.map(l => l[dataKey]);
    datasets.datasets[0].backgroundColor = legendObject.old.color;
    datasets.datasets[1].label = dayjs(newDataArray[0].time).format('YYYY');
    datasets.datasets[1].data = newDataArray.map(l => l[dataKey]);
    datasets.datasets[1].backgroundColor = legendObject.new.color;
  }

  const moveBackwards = () => {
    setStartLoader(false);
    if (comparisonOffset === 'month') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'month')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (comparisonOffset === 'year') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'year')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    }
  };

  const moveForwards = () => {
    setStartLoader(false);
    if (comparisonOffset === 'month') {
      const newTime = dayjs(selectedTime)
        .add(1, 'month')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (comparisonOffset === 'year') {
      const newTime = dayjs(selectedTime)
        .add(1, 'year')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    }
  };

  const moveToToday = () => {
    setStartLoader(false);
    setSelectedTime(dayjs().toDate());
    fetchData(false, buildQueryParams(timeType, dayjs().toDate()));
  };

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
          <Buttons>
            {loading && !startLoader && (
              <MiniLoader>
                <Spin size="small" />
              </MiniLoader>
            )}
            <Button onClick={moveToToday}>{t('Today')}</Button>
            <Button onClick={moveBackwards}>
              <RotatedIcon type="chevron-right-2" />
            </Button>
            <Button onClick={moveForwards} style={{ marginRight: timeOptions.length > 1 ? '12px' : '0px' }}>
              <Icon type="chevron-right-2" />
            </Button>
            {timeOptions.length > 1 && (
              <Select
                value={timeType}
                style={{ width: 100 }}
                onChange={e => {
                  setStartLoader(false);
                  setTimeType(e);
                  fetchData(false, buildQueryParams(e, selectedTime));
                }}
                size="small"
                options={[
                  {
                    value: 'month',
                    label: t('month'),
                  },
                  {
                    value: 'year',
                    label: 'Year',
                  },
                ]}
              />
            )}
          </Buttons>
        </HeaderRow>

        {startLoader && loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : datasets ? (
          <BarContainer column1={props.column1}>
            <Bar plugins={[ChartDataLabels]} options={options} data={datasets} />
          </BarContainer>
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default StatisticsBar2ColumnCompare;
