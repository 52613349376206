import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Modal from 'antd/es/modal';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import { AlertContext } from '../../context/AlertContext';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';
import MarkDownHandler from '../info/MarkDownHandler';

const IncidentAlert = styled.div`
  background: ${props =>
    props.event ? '#4990dd' : props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E'};
  border: ${props =>
    props.event
      ? '1px solid #417ebf'
      : props.level > 7
        ? '1px solid #eb3f34'
        : props.level < 4
          ? '1px solid #79c77d'
          : '1px solid #ebb53b'};
  width: 100%;
  height: 40px;
  z-index: 100;
  display: flex;
  color: ${props => (props.event || props.level > 7) && 'white'};
  cursor: ${props => (props.canView ? 'pointer' : 'default')};

  &:hover {
    background: ${props =>
    props.event ? '#3283db' : props.level > 7 ? '#eb3f34' : props.level < 4 ? '#79c77d' : '#ebb53b'};
  }

  @media print {
    display: none;
  }
`;

const AlertText = styled.div`
  width: calc(100% - 26px);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 18px;
  padding-right: 12px;
  padding-top: 9px;
  height: 48px;
  font-weight: 600;
`;

const AlertClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    margin-top: -10px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  padding: 12px 12px;
`;

const InfoText = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  padding: 24px 0;
  font-size: 16px;
`;

const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertBanner = () => {
  const { modules, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alerts, closeAlert } = useContext(AlertContext);
  const [generalModalOpen, setGeneralModalOpen] = useState(false);

  const history = useHistory();

  const clickBanner = (type, id) => {
    switch (type) {
      case 'incident':
      case 'event':
        history.push('/incident-and-event/' + id);
        break;
      case 'general':
        setGeneralModalOpen(id);
        break;
      default:
        break;
    }
  };

  if (!alerts) {
    return null;
  }

  const getGeneralBannerContent = alert => {
    if (alert.message_body) {
      return (
        <InfoText>
          <InfoBody>
            {t(alert.message)}
            <MarkDownHandler markDownData={alert.message_body} editable={false} />
          </InfoBody>
        </InfoText>
      );
    }
    return <InfoText>{t(alert.message)}</InfoText>;
  };

  return alerts.map((alert, index) => {
    switch (alert.type) {
      case 'event':
        if (alert.incident) {
          const canView = modules.incident_module === 'enabled' && user.permissions.includes('view_incident');
          const text =
            dayjs(alert.incident.end_date).isValid() && dayjs(alert.incident.end_date).isAfter(dayjs())
              ? `${alert.incident.details} ${dayjs(alert.incident.date).format(TIME_FORMAT)} - ${dayjs(
                alert.incident.end_date
              ).format(TIME_FORMAT)}`
              : alert.incident.details;
          return (
            <IncidentAlert canView={canView} key={index} event={true}>
              <AlertText onClick={canView ? () => clickBanner(alert.type, alert.incident.id) : undefined}>
                {text}
              </AlertText>
              <AlertClose onClick={() => closeAlert(index)}>
                <Icon type="close" />
              </AlertClose>
            </IncidentAlert>
          );
        }
        break;
      case 'incident':
        if (alert.incident) {
          const canView = modules.incident_module === 'enabled' && user.permissions.includes('view_incident');
          return (
            <IncidentAlert canView={canView} key={index} level={alert.incident.level_of_emergency}>
              <AlertText onClick={canView ? () => clickBanner(alert.type, alert.incident.id) : undefined}>
                {t('Alert')}: {alert.incident.incident_type_name}{' '}
                <span style={{ textTransform: 'lowercase' }}>{alert.incident.location_option_name}</span>
              </AlertText>
              <AlertClose onClick={() => closeAlert(index)}>
                <Icon type="close" />
              </AlertClose>
            </IncidentAlert>
          );
        }
        break;
      case 'general':
        return (
          <IncidentAlert canView key={index} level={alert.level}>
            <AlertText onClick={() => clickBanner(alert.type, alert.id)}>{t(alert.message)}</AlertText>
            <AlertClose onClick={() => closeAlert(index)}>
              <Icon type="close" />
            </AlertClose>
            <Modal
              title={t('Attention')}
              open={generalModalOpen === alert.id}
              footer={null}
              onCancel={() => setGeneralModalOpen(null)}
              centered
            >
              <ModalInner>{getGeneralBannerContent(alert)}</ModalInner>
            </Modal>
          </IncidentAlert>
        );
      default:
        return null;
    }
    return null;
  });
};

export default AlertBanner;
