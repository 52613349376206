import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import useLogin from '../../views/UseLogin';

import Form from '../ui/Form';
import Input from '../ui/Input';
import Button from '../ui/Button';
import Spin from 'antd/es/spin';
import LandingPageForgotPassword from './LandingPageForgotPassword';
import Footer from '../../images/footers/Footer-Sign_in.svg?react';
import Icon from '../ui/Icon';

const LeftInfoPanel = styled.div`
  width: 400px;
  position: absolute;
  z-index: 100;
  top: 20px;
  right: 20px;
  background-color: white;
  color: ${({ theme }) => theme.color.almost_black};
  overflow: hidden;

  @media (max-width: 899px) {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const CodeHeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding-left: 26px;
  padding-right: 48px;

  @media (min-width: 900px) {
    padding-right: 26px;
  }
`;

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledForm = styled(Form)`
  display: inline-block;
  margin: 50px 26px 40px 26px;
  width: calc(100% - 60px);
`;

const StyledInput = styled(Input)`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
  margin-bottom: 24px;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  margin-top: 48px;

  @media (max-width: 899px) {
    margin-bottom: 24px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.emerald_green)};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 0px;
  text-align: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: 36px;
  text-transform: uppercase;
  line-height: 26px;
  width: 100%;

  &:hover {
    background-color: ${props => (props.disabled ? '#a8a8a8' : '#5aa190')};
  }
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 48px;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    color: #8a8a8a;
  }

  i {
    height: 22px;
    width: 22px;
  }

  svg {
    height: 22px;
    width: 22px;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

const LoginLinkContainer = styled.div`
  margin-top: 12px;
`;

const LoginLink = styled.a`
  color: ${({ theme }) => theme.color.almost_black};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.emerald_green};
  }
`;

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const RegisterButton = styled.div`
  margin-left: 6px;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
`;

const LandingPageSignInPanel = ({ closeModal, openSignUpPanel }) => {
  const {
    handleSubmit,
    disabled,
    namespace,
    credentials,
    setCredentials,
    codeSubmitDisabled,
    handleSubmitCode,
    currentAuthView,
    setCurrentAuthView,
  } = useLogin();
  const { loginLoader, showLoginLoader } = useContext(UserContext);

  const { i18n } = useTranslation(namespace);
  const t = i18n.getFixedT(i18n.language, namespace);

  const preHandleSubmit = e => {
    handleSubmit(e);
    showLoginLoader(true);
  };

  const preHandleSubmitCode = e => {
    handleSubmitCode(e);
    showLoginLoader(true);
  };

  const codeMethod = currentAuthView.view === 'EMAIL_CODE_LOGIN' ? 'email' : '';

  return currentAuthView.view === 'EMAIL_CODE_LOGIN' ? (
    <LeftInfoPanel>
      <IconContainer onClick={closeModal}>
        <Icon type="close-2" />
      </IconContainer>
      <Header>
        <CodeHeaderText>
          {t('Please enter the 7 digit verification code found in your {{method}}', { method: codeMethod })}
        </CodeHeaderText>
      </Header>
      <StyledForm onSubmit={preHandleSubmitCode}>
        <Label>{t('Code')}</Label>
        <StyledInput
          name={t('Code')}
          type="password"
          value={credentials.code}
          onChange={e => setCredentials({ ...currentAuthView.params, code: e.target.value.trim() })}
          inline
          style={{ minWidth: 'auto' }}
        />
        <LoginLinkContainer>
          <LoginLink onClick={() => setCurrentAuthView('LOGIN')}>
            {t('Did not receive a code? Click here to try again')}
          </LoginLink>
        </LoginLinkContainer>
        <StyledButtonContainer>
          <Spin spinning={loginLoader}>
            <StyledButton disabled={codeSubmitDisabled} style={{ minWidth: 'auto' }}>
              {t('Verify')}
            </StyledButton>
          </Spin>
        </StyledButtonContainer>
      </StyledForm>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LeftInfoPanel>
  ) : (
    <LeftInfoPanel>
      <IconContainer onClick={closeModal}>
        <Icon type="close-2" />
      </IconContainer>
      <Header>
        <HeaderText>{t('Sign in')}</HeaderText>
      </Header>
      <StyledForm onSubmit={preHandleSubmit}>
        <Label>{t('Email address')}</Label>
        <StyledInput
          name={t('Email')}
          type="text"
          value={credentials.username}
          onChange={e => setCredentials({ ...credentials, username: e.target.value.trim() })}
          autoFocus
          inline
        />
        <Label>{t('Password')}</Label>
        <StyledInput
          name={t('Password')}
          type="password"
          value={credentials.password}
          onChange={e => setCredentials({ ...credentials, password: e.target.value.trim() })}
          inline
        />
        <StyledButtonContainer>
          <Spin spinning={loginLoader}>
            <StyledButton disabled={disabled}>{t('Continue')}</StyledButton>
          </Spin>
        </StyledButtonContainer>
        <RegisterContainer>
          {t("Don't have an account?")} {/* eslint-disable-line quotes */}
          <RegisterButton onClick={openSignUpPanel}>{t('Sign up here.')}</RegisterButton>
        </RegisterContainer>
      </StyledForm>
      <LandingPageForgotPassword />
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LeftInfoPanel>
  );
};

export default LandingPageSignInPanel;
