import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Switch from 'antd/es/switch';

import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import { TIME_FORMAT } from '../../utils/constants';
import Button from '../../components/ui/Button';
import PetroleumGrade from './PetroleumGrade';
import Heading from '../../components/ui/Heading';
import Icon from '../../components/ui/Icon';
import Input from '../../components/ui/Input';
import Label from '../../components/ui/Label';
import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import DateComponent from '../../components/ui/DateComponent';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const IconContainer = styled.div`
  line-height: 3rem;
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  svg {
    cursor: pointer;
    color: ${props => props.theme.color.success};
    height: 3rem;
    width: 3rem;
  }
`;

const ButtonContainer = styled.span`
  padding-right: ${({ theme }) => theme.sizing.gap_small};
`;

const BackLink = styled.a`
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.sizing.gap_small};
`;

const InfoContainer = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 10px;
`;

const CardWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: ${props => props.flexDir || 'column'};
  justify-content: center;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.style.border_radius};
  min-width: 360px;
  min-height: 80px;
  margin: ${({ theme }) => theme.sizing.gap_small};
  border: 1px solid ${props => props.theme.color.grey_light};
  box-shadow: 0px 1px ${props => props.theme.color.grey_light};
  input {
    min-width: 50px;
    width: ${props => props.inputWidth || '75px'};
  }
`;

const EtcText = styled(Heading)`
  margin-bottom: 0;
  padding: 0.5rem;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const InputGroup = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputContainer = styled.span`
  padding-top: 16px;
  padding-right: 32px;
`;

const FlowRateInfoContainer = styled.span`
  display: inline-flex;
`;

const LoadingMasterPetroleumJob = () => {
  const { t } = useTranslation();
  const { apiCall, user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const { loading: validGradesLoading, data: validGradesData, error: validGradesError } = useApi(
    'get',
    'lma-petroleum-grades',
    {}
  );

  const fetchedValidGrades = validGradesLoading || validGradesError || !validGradesData ? [] : validGradesData;
  let counter = 1;
  fetchedValidGrades.forEach(p => {
    p._row = counter;
    counter++;
    p.key = p.id;
    p.label = p.name;
    p.value = p.code;
  });

  const initJob = {
    status: 'not-started',
    port_call_id: null,
    port_call_master_id: null,
    additional_time_seconds: null,
    start_time: null,
    etc: null,
    total_pump_time_seconds: null,
    remaining_pump_time_seconds: null,
    last_calculation_time: null,
    etc_send_enabled: true,
    last_etc_sent: null,
    flow_rate_online: false,
    id: null,
    created_at: '',
    modified_at: '',
    vessel: {
      imo: null,
      name: '',
    },
    grades: [],
  };

  const [job, setJob] = useState(initJob);
  const [originalJob, setOriginalJob] = useState(initJob);
  const [jobLoaded, setJobLoaded] = useState(false);
  const [jobNotStarted, setJobNotStarted] = useState(true);
  const [jobOngoing, setJobOngoing] = useState(false);
  const [jobDone, setJobDone] = useState(false);
  const [grades, setGrades] = useState([]);
  const [gradesLoaded, setGradesLoaded] = useState(false);
  const [newGradeId, setNewGradeId] = useState(10000);
  const [apiCallPending, setApiCallPending] = useState(false);

  const params = new URLSearchParams(location.search);
  const id = params.get('id') ? params.get('id') : null;

  let jobId = id;
  if (!jobId) {
    jobId = 0;
  }
  jobId = parseInt(jobId);

  const { loading: jobLoading, data: jobData, error: jobError, fetchData } = useApi('get', 'lma-petroleum-jobs', {
    id: jobId,
    limit: 1,
  });
  const fetchedJob = jobLoading || jobError || !jobData ? null : jobData;

  if (!jobLoaded && !jobLoading && fetchedJob && (fetchedJob.data.length > 0 || jobId == 0)) {
    if (jobId != 0) {
      setJob(...fetchedJob.data);
      setOriginalJob(JSON.parse(JSON.stringify(...fetchedJob.data)));
    } else {
      setJob(initJob);
      setOriginalJob(JSON.parse(JSON.stringify(initJob)));
    }

    setJobLoaded(true);
    setGradesLoaded(false);
  }

  if (jobLoaded && !gradesLoaded) {
    if (job.status === 'not-started') {
      setJobNotStarted(true);
      setJobOngoing(false);
      setJobDone(false);
    } else if (job.status === 'ongoing') {
      setJobNotStarted(false);
      setJobOngoing(true);
      setJobDone(false);
    } else if (job.status === 'done' || job.status === 'completed') {
      setJobNotStarted(false);
      setJobOngoing(false);
      setJobDone(true);
    }
    setGradesLoaded(true);
    setGrades(job.grades);

    let additionalTimeSeconds = dayjs.duration(job.additional_time_seconds * 1000).format('HH:mm', { trim: false });

    setJob({ ...job, additional_time_seconds: additionalTimeSeconds });
  }

  useEffect(() => {
    document.title = t('Loading master');
  }, [t]);

  const setGradeValueById = (gradeId, key, value) => {
    let modified_grade = grades.find(obj => {
      return obj.id === gradeId;
    });

    if (key === 'code') {
      let valid_grade = fetchedValidGrades.find(obj => {
        return obj.code === value;
      });

      modified_grade.name = valid_grade.name;
    }

    modified_grade[key] = value;

    setGrades([...grades]);
  };

  const addGrade = () => {
    const newGrade = {
      job_id: null,
      code: fetchedValidGrades[0].code,
      name: fetchedValidGrades[0].name,
      loading_sequence: 1,
      current_flow_rate: 0,
      remaining_volume: 0,
      starting_volume: 0,
      total_pump_time_seconds: null,
      remaining_pump_time_seconds: null,
      start_time: null,
      etc: null,
      estimated_start_time: null,
      id: newGradeId,
      created_at: null,
      modified_at: null,
    };

    setNewGradeId(newGradeId + 1);
    setGrades([...grades, newGrade]);
  };

  const removeGrade = id => {
    setGrades(grades.filter(grade => grade.id !== id));
  };

  const handleToggle = (name, currentValue) => {
    setJob({ ...job, [name]: !currentValue });
  };

  const handleChange = e => {
    let name = null;
    let value = null;
    if (e.target.name === 'vessel_imo') {
      name = 'vessel';
      value = { imo: e.target.value, name: job.vessel.name };
    } else if (e.target.name === 'vessel_name') {
      name = 'vessel';
      value = { imo: job.vessel.imo, name: e.target.value };
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setJob({ ...job, [name]: value });
  };

  const handleSave = async () => {
    let saveJob = JSON.parse(JSON.stringify(job));

    const jobForceRemove = [
      'status',
      'port_call_id',
      'port_call_master_id',
      'start_time',
      'etc',
      'total_pump_time_seconds',
      'remaining_pump_time_seconds',
      'last_calculation_time',
      'last_etc_sent',
      'flow_rate_online',
      'created_at',
      'modified_at',
    ];
    jobForceRemove.forEach(function(key) {
      delete saveJob[key];
    });

    if (saveJob.additional_time_seconds) {
      saveJob.additional_time_seconds = dayjs.duration(saveJob.additional_time_seconds).asSeconds();
    }
    const jobCleanRemove = ['additional_time_seconds'];
    jobCleanRemove.forEach(function(key) {
      if (saveJob[key] === originalJob[key]) {
        delete saveJob[key];
      }
    });
    if (saveJob.additional_time_seconds) {
      saveJob.additional_time_seconds = parseInt(saveJob.additional_time_seconds);
    }

    if (saveJob.vessel.imo === originalJob.vessel.imo) {
      delete saveJob.vessel['imo'];
    }
    if (saveJob.vessel.imo) {
      saveJob.vessel.imo = parseInt(saveJob.vessel.imo);
    }
    if (saveJob.vessel.name === originalJob.vessel.name) {
      delete saveJob.vessel['name'];
    }
    if (Object.keys(saveJob.vessel).length === 0) {
      delete saveJob['vessel'];
    }

    const gradeForceRemove = [
      'job_id',
      'total_pump_time_seconds',
      'remaining_pump_time_seconds',
      'start_time',
      'etc',
      'estimated_start_time',
      'created_at',
      'modified_at',
    ];

    const gradeCleanRemove = [
      'code',
      'name',
      'loading_sequence',
      'current_flow_rate',
      'remaining_volume',
      'starting_volume',
    ];

    let saveGrades = JSON.parse(JSON.stringify(grades));
    saveGrades.forEach(function(grade) {
      gradeForceRemove.forEach(function(key) {
        delete grade[key];
      });

      let originalGrade = originalJob.grades.find(obj => {
        return obj.id === grade.id;
      });

      if (!originalGrade) {
        delete grade['id'];
      } else {
        gradeCleanRemove.forEach(function(key) {
          if (grade[key] === originalGrade[key]) {
            delete grade[key];
          }
        });
      }

      if (grade.loading_sequence) {
        grade.loading_sequence = parseInt(grade.loading_sequence);
      }
      if (grade.current_flow_rate) {
        grade.current_flow_rate = parseFloat(grade.current_flow_rate);
      }
      if (grade.remaining_volume) {
        grade.remaining_volume = parseFloat(grade.remaining_volume);
      }
      if (grade.starting_volume) {
        grade.starting_volume = parseFloat(grade.starting_volume);
      }
    });

    saveJob.grades = saveGrades;

    setApiCallPending(true);
    let newId = saveJob.id;
    try {
      if (saveJob.id) {
        await apiCall('put', 'lma-petroleum-jobs', { petroleum_job: saveJob });
      } else {
        let response = await apiCall('post', 'lma-petroleum-jobs', { petroleum_job: saveJob });
        newId = response.data.id;
      }
    } catch (e) {
      setApiCallPending(false);
      await fetchData(false, { id: newId, limit: 1 });
      setJobLoaded(false);
      throw e;
    }
    setApiCallPending(false);
    history.push(location.pathname + '?id=' + newId);
    await fetchData(false, { id: newId, limit: 1 });
    setJobLoaded(false);
  };

  const handleStart = async () => {
    setApiCallPending(true);
    try {
      await apiCall('get', 'lma-start-petroleum-job', { id: job.id });
    } catch (e) {
      setApiCallPending(false);
      await fetchData(false, { id: job.id, limit: 1 });
      setJobLoaded(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, { id: job.id, limit: 1 });
    setJobLoaded(false);
  };

  let readeableStatus = t('Unknown');
  if (job.status === 'not-started') {
    readeableStatus = t('Not started');
  } else if (job.status === 'ongoing') {
    readeableStatus = t('Ongoing');
  } else if (job.status === 'completed') {
    readeableStatus = t('Completed');
  } else if (job.status === 'done') {
    readeableStatus = t('Done');
  }

  let pageTitle = '';
  if (job.id === null) {
    pageTitle = t('New loading job');
  } else {
    pageTitle = t('Loading job') + ' ' + job.id + ', ' + readeableStatus;
  }

  return (
    <Layout pagename={t('Loading Master Job')}>
      <Page fullWidth title={pageTitle}>
        <Spin spinning={apiCallPending || !gradesLoaded}>
          <BackLink onClick={() => history.push('/loading-master-petroleum-jobs')}>
            {t('« Back to loading master jobs')}
          </BackLink>
          {grades?.length !== 0 ? (
            grades.map((grade, index) => (
              <PetroleumGrade
                key={index}
                setGradeValueById={setGradeValueById}
                removeGrade={removeGrade}
                index={index}
                data={grade}
                validGrades={fetchedValidGrades}
                jobNotStarted={jobNotStarted}
                jobOngoing={jobOngoing}
                jobDone={jobDone}
              />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {!jobDone && user.permissions.includes('manage_loading_job') && (
            <IconContainer>
              <Icon type="add" onClick={() => addGrade()} />
            </IconContainer>
          )}
          <InfoContainer>
            <CardWrapper flexDir="row">
              <InputGroup>
                <InputContainer>
                  {!job.flow_rate_online && (
                    <FlowRateInfoContainer>
                      <Icon type="dot-single" fill="red" align="none" />
                      <Label>{t('Real-time flow rate not available')}</Label>
                    </FlowRateInfoContainer>
                  )}
                  {job.flow_rate_online && (
                    <FlowRateInfoContainer>
                      <Icon type="dot-single" fill="green" align="none" />
                      <Label>{t('Real-time flow rate available')}</Label>
                    </FlowRateInfoContainer>
                  )}
                  <EtcText h2>
                    {t('ETC')}: {job.etc ? <DateComponent format={TIME_FORMAT} date={job.etc} /> : <span>N/A</span>}
                  </EtcText>
                </InputContainer>
              </InputGroup>
              {!jobDone && user.permissions.includes('manage_loading_job') && (
                <InputGroup>
                  <InputContainer>
                    <Label>{t('Send ETC to timeline')}</Label>
                  </InputContainer>
                  <InputContainer>
                    <Switch
                      defaultChecked={job.etc_send_enabled}
                      onChange={() => handleToggle('etc_send_enabled', job.etc_send_enabled)}
                      disabled={jobDone}
                    ></Switch>
                  </InputContainer>
                </InputGroup>
              )}
            </CardWrapper>
            <CardWrapper>
              <InputGroup>
                <InputContainer>
                  <Label>{t('Remaining pump time')}</Label>
                  <EtcText h2>
                    {job.remaining_pump_time_seconds ? (
                      dayjs.duration(job.remaining_pump_time_seconds * 1000).format('HH:mm', { trim: false })
                    ) : (
                      <span>N/A</span>
                    )}
                  </EtcText>
                </InputContainer>
                <InputContainer>
                  <Input
                    name="additional_time_seconds"
                    label={t('Additional time (HH:MM)')}
                    value={job.additional_time_seconds}
                    onChange={handleChange}
                    required
                    disabled={jobDone}
                  />
                </InputContainer>
              </InputGroup>
            </CardWrapper>
            <CardWrapper inputWidth="125px">
              <InputGroup>
                <InputContainer>
                  <Input
                    name="vessel_imo"
                    label={t('Imo')}
                    value={job.vessel?.imo}
                    onChange={handleChange}
                    required
                    disabled={jobDone || !user.permissions.includes('manage_loading_job')}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    name="vessel_name"
                    label={t('Vessel name')}
                    value={job.vessel?.name}
                    onChange={handleChange}
                    required
                    disabled={jobDone || !user.permissions.includes('manage_loading_job')}
                  />
                </InputContainer>
              </InputGroup>
            </CardWrapper>
          </InfoContainer>
          {!jobDone && user.permissions.includes('manage_loading_job') && (
            <Buttons>
              <ButtonContainer>
                <Button onClick={handleSave}>{t('Save')}</Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button disabled={!jobNotStarted || !job.id} onClick={handleStart}>
                  {t('Start')}
                </Button>
              </ButtonContainer>
            </Buttons>
          )}
        </Spin>
      </Page>
    </Layout>
  );
};

export default LoadingMasterPetroleumJob;
