import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import CommodityList from '../components/berthPlanningTool/cranePlanning/commodities/CommodityList';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

const Commodities = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Commodities | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Commodities')}>
      <PageWithoutHeaderAndMargins fullWidth>
        <CommodityList />
      </PageWithoutHeaderAndMargins>
    </Layout>
  );
};

export default Commodities;
