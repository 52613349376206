import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '../../utils/utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-width: 100px;
`;

const PortCallInvoiceRowEditableColumn = ({ cell, row, type, updateData, numeric }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [value, setValue] = useState(cell);

  useEffect(() => {
    setValue(cell);
  }, [cell]);

  const onChange = value => {
    setValue(value);
    updateData(row.port_call_id, type, value, cell);
  };

  return (
    <Container>
      {numeric ? (
        <InputNumber
          placeholder={t('Number')}
          min={0}
          value={value}
          onChange={onChange}
          style={{ color: '#4a4a4a', minWidth: '100px' }}
        />
      ) : (
        <Input
          placeholder={toTitleCase(type)}
          value={value}
          onChange={e => onChange(e.target.value)}
          style={{ color: '#4a4a4a' }}
        />
      )}
    </Container>
  );
};

export default PortCallInvoiceRowEditableColumn;
