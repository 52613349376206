import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Checkbox from 'antd/es/checkbox';
import { toTitleCase } from '../../utils/utils';
import { UserContext } from '../../context/UserContext';

const Wrapper = styled.div`
  padding: 2px;
`;

const ListWrapper = styled.div`
  padding: 6px;
  background-color: white;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  max-width: 200px;
  z-index: 10;
`;

const List = styled.div``;

const CheckBoxContainer = styled.div`
  margin: 1px;
`;

const OpenDropdownButton = styled.div`
  border: ${props => !props.open && '1px solid #d8d8d8'};
  border-radius: 0.25rem;
  height: 32px;
  position: relative;
`;

const ChosenAreas = styled.div`
  line-height: 30px;
  padding: ${props => (props.placeholderInPlace ? '0 4px 0 11px' : '0 4px')};
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.placeholderInPlace && '#bfbfbf'};
`;

const ListOpenButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
`;

const KeepListOpen = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 13px;
  margin-top: 12px;
`;

const CheckBoxList = ({ options, handleClick, chosen, uniqueId, filterName, listOpenSaved, setKeepOpen }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const inputRef = React.useRef(null);
  const openRef = React.useRef(null);

  useEffect(() => {
    if (openRef.current && !open) {
      setSearch('');
    }
    openRef.current = open;
  }, [open]);

  useEffect(() => {
    const handleClickOutside = event => {
      const list = document.querySelector('#' + uniqueId);
      const button = document.querySelector('#' + uniqueId + '-button');
      const input = document.querySelector('#' + uniqueId + '-input');

      if (
        (list ? !list.contains(event.target) : true) &&
        (button ? !button.contains(event.target) : true) &&
        (input ? !input.contains(event.target) : true)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [open, uniqueId]);

  const clickCheckBox = event => {
    handleClick(event, filterName);
  };

  const optionList = () => {
    return (
      <Wrapper id={uniqueId}>
        <ListWrapper>
          <List>
            {options
              .filter(option => {
                if (search && search.length > 0) {
                  return (
                    option.name.toLowerCase().includes(search.toLowerCase()) ||
                    option.display.toLowerCase().includes(search.toLowerCase())
                  );
                } else {
                  return true;
                }
              })
              .map((option, index) => {
                let checked = chosen?.[filterName] ? chosen[filterName].find(d => d === option.name) : false;
                return (
                  <CheckBoxContainer key={index}>
                    <Checkbox checked={checked} onChange={e => clickCheckBox(e)} name={option.name}>
                      <span>{filterName === 'agent' ? option.display : toTitleCase(option.display)}</span>
                    </Checkbox>
                  </CheckBoxContainer>
                );
              })}
          </List>
          <KeepListOpen>
            {listOpenSaved ? (
              <ListOpenButton onClick={() => setKeepOpen(false)}>{t('Close list automatically')}</ListOpenButton>
            ) : (
              <ListOpenButton onClick={() => setKeepOpen(true)}>{t('Keep list open')}</ListOpenButton>
            )}
          </KeepListOpen>
        </ListWrapper>
      </Wrapper>
    );
  };

  let chosenList = '';
  for (let i = 0; i < chosen[filterName].length; i++) {
    if (i === 4) {
      i = chosen[filterName].length;
    } else {
      if (i !== 0) {
        chosenList += ', ';
      }
      chosenList += chosen[filterName][i];
    }
  }

  const onClick = () => {
    !open && setOpen(!open);
    inputRef.current?.focus({
      cursor: 'end',
    });
  };

  return (
    <div>
      <OpenDropdownButton id={uniqueId + '-button'} onClick={onClick} open={open}>
        {listOpenSaved || open ? (
          <Input
            id={uniqueId + '-input'}
            ref={inputRef}
            value={search}
            onChange={e => setSearch(e.target.value)}
            autoComplete="off"
            allowClear={true}
            placeholder={t('Select or search')}
          />
        ) : (
          <ChosenAreas placeholderInPlace={chosenList.length === 0}>
            {chosenList.length > 0 ? chosenList : t('Select or search')}
          </ChosenAreas>
        )}
      </OpenDropdownButton>
      {(listOpenSaved || open) && optionList()}
    </div>
  );
};

export default CheckBoxList;
