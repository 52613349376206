import React, { useEffect, useState, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { Polygon, Polyline, TileLayer, Tooltip as LeafletTooltip, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';
import Switch from 'antd/es/switch';

import Button from '../ui/Button';
import Icon from '../ui/Icon';

import { getOptions } from '../map/MapUtils';
import IncidentMarker from './IncidentMarker';
import { TIME_FORMAT } from '../../utils/constants';
import useApi from '../../hooks/useApi';
import CircleMarkers from '../map/CircleMarkers';
import { UserContext } from '../../context/UserContext';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 24px;
  display: inline-grid;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 3px;
    margin-left: -1px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 6px;
  z-index: 400;
  background-color: white;
  padding: 6px;
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};

  button {
    margin-top: 3px;
  }
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-left: 12px;
`;

const IncidentsMapInnerContainer = ({
  zoom,
  setZoom,
  incidentData,
  setClickedIncident,
  clickedIncident,
  mapEnlarged,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();
  const map = useMap();

  const history = useHistory();

  const { data: helcomData, fetchData: fetchHelcomData } = useApi('get', 'ais/helcom', {}, null);

  const [showVessels, setShowVessels] = useState(false);

  useEffect(() => {
    let vesselInterval = undefined;
    vesselInterval = setInterval(() => {
      fetchHelcomData();
    }, 180000);

    return () => clearInterval(vesselInterval);
  }, [fetchHelcomData]);

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  useEffect(() => {
    map.invalidateSize();
  });

  useEffect(() => {
    if (clickedIncident || clickedIncident === 0) {
      const incident = incidentData.find(i => i.id === clickedIncident);
      if (incident) {
        if (incident?.data?.geojson.length === 1 && incident?.data?.geojson?.[0].geometry.type === 'Point') {
          map.setView(L.GeoJSON.coordsToLatLng(incident?.data?.geojson?.[0].geometry.coordinates), 9);
        } else if (incident?.data?.geojson.length > 0 && incident?.data?.geojson?.[0].geometry.type === 'Polygon') {
          let group = new L.FeatureGroup();
          incident?.data?.geojson.forEach(d => {
            group.addLayer(L.polygon(L.GeoJSON.coordsToLatLngs(d.geometry.coordinates, 1)));
          });
          map.fitBounds(group.getBounds(), { padding: [50, 50] });
        }
      }
      setClickedIncident(undefined);
    }
  }, [clickedIncident, incidentData, map, setClickedIncident, zoom]);

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  const clickMarker = id => {
    history.push('/incident-and-event/' + id);
  };

  const zoomPrevDiv = L.DomUtil.get('zoompreventer');
  if (zoomPrevDiv) {
    L.DomEvent.disableClickPropagation(zoomPrevDiv);
  }

  return (
    <>
      <ZoomButtonContainer id={'zoompreventer'}>
        <ZoomButton
          data-testid="map-zoom-in"
          copy
          onClick={() => handleZoomButton(zoom + 1)}
          style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
        >
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton
          data-testid="map-zoom-out"
          copy
          onClick={() => handleZoomButton(zoom - 1)}
          style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
        >
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      {mapEnlarged && (
        <Tooltip
          title={t('Show vessels in the map when it is enlarged')}
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center', maxWidth: '200px' }}
          placement="left"
        >
          <SwitchContainer>
            <Switch size="small" checked={showVessels} onChange={e => setShowVessels(e)} aria-label="Show vessels" />
            <SwitchLabel>{t('Show vessels')}</SwitchLabel>
          </SwitchContainer>
        </Tooltip>
      )}
      <WMSChart show={zoom >= 14} />

      <CircleMarkers vesselsData={!helcomData || !mapEnlarged || !showVessels ? {} : helcomData} zoom={zoom} />
      {incidentData
        ? incidentData.map((incident, i) =>
          incident?.data?.geojson[0]?.geometry.type === 'Point' ? (
            <IncidentMarker
              key={i}
              date={incident.date ? dayjs(incident.date).format(TIME_FORMAT) : '-'}
              coords={incident?.data?.geojson[0]?.geometry.coordinates}
              level={incident.level_of_emergency}
              titleType={incident.incident_type_name}
              titleLocation={incident.location_option_name}
              clickMarker={() => clickMarker(incident.id)}
              event={incident.type === 'event'}
            />
          ) : (
            incident?.data?.geojson.map((value, id) => {
              if (value.geometry.type === 'Polygon') {
                let color = '#2254c0';
                let color2 = '#052363';
                if (incident.type === 'incident') {
                  color =
                      incident.level_of_emergency > 7
                        ? '#F44336'
                        : incident.level_of_emergency < 4
                          ? '#4c914e'
                          : '#F5BD3E';
                  color2 =
                      incident.level_of_emergency > 7
                        ? '#800f06'
                        : incident.level_of_emergency < 4
                          ? '#0a400b'
                          : '#ab8329';
                }
                let coords = L.GeoJSON.coordsToLatLngs(value.geometry.coordinates, 1);
                if (
                  coords[0][0].lat !== coords[0][coords[0].length - 1].lat ||
                    coords[0][0].lng !== coords[0][coords[0].length - 1].lng
                ) {
                  coords[0].push(coords[0][0]);
                }
                return (
                  <Fragment key={id}>
                    <Polygon
                      positions={coords}
                      fillColor={color}
                      fillOpacity={0.5}
                      color={color}
                      opacity={0.7}
                      eventHandlers={{
                        click: () => {
                          if (clickMarker) {
                            clickMarker(incident.id);
                          }
                        },
                      }}
                    >
                      {' '}
                      <LeafletTooltip direction="top" offset={L.point(0, 0)}>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <h4>
                              {incident.incident_type_name}{' '}
                              <span style={{ textTransform: 'lowercase' }}>{incident.location_option_name}</span>
                            </h4>
                            <h4>{incident.date ? dayjs(incident.date).format(TIME_FORMAT) : '-'}</h4>
                          </div>
                        </div>
                      </LeafletTooltip>
                    </Polygon>

                    <Polyline
                      positions={coords}
                      pathOptions={{ dashArray: [4, 4], dashOffset: 4, color: color2, weight: '2', opacity: '0.85' }}
                    />
                  </Fragment>
                );
              }
            })
          )
        )
        : null}
    </>
  );
};

export default IncidentsMapInnerContainer;
