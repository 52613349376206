import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TimestampContext } from '../../context/TimestampContext';

import { UserContext } from '../../context/UserContext';
import CardV2 from './CardV2';
import Popover from 'antd/es/popover';
import Radio from 'antd/es/radio';
import Spin from 'antd/es/spin';

import { throttle } from 'throttle-debounce';
import useApi from '../../hooks/useApi';
import SideTimestamp from './SideTimestamp';
import { AlertContext } from '../../context/AlertContext';
import Icon from '../ui/Icon';
import { ServiceOrderContext } from '../../context/ServiceOrderContext';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Timestamps = styled.div`
  height: calc(100vh - 140px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  width: ${props => (props.sideTimestamp ? 'calc(100% - 330px)' : '100%')};
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
  position: relative;
  padding-top: 1rem;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Text = styled.div`
  text-align: center;
  font-size: 1.2rem;
`;

const Text2 = styled.div`
  text-align: center;
  font-size: 13px;
`;

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.useRows && 'column'};
  justify-content: space-between;
`;

const Column = styled.div`
  display: inline-block;
  background-color: #f2f2f2;
  margin: 0 10px;
  border-radius: 4px;
  margin-bottom: ${props => props.useRows && '24px'};
  padding-bottom: 6px;

  &:last-of-type {
    border-right: none;
  }
`;

const ColumnCards = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 6px;
`;

const Title = styled.div`
  margin: 6px 6px 12px 6px;
  border-bottom: 1px solid #c8c8c8;
`;

const TitleText = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-left: 16px;
`;

const FilterIcon = styled.div`
  position: absolute;
  top: 0;
  right: 28px;
  margin-top: -2px;
  cursor: pointer;
  margin-left: 12px;
  background-color: white;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid #e8e8e8;
  width: 30px;
  height: 30px;
  border-radius: 3px;

  &:hover {
    background-color: #f8f8f8;
  }

  svg {
    margin-left: 5px;
    margin-top: 6px;
    width: 18px;
    height: 18px;
  }
`;

const RadioButtons = styled.div`
  margin: 6px 12px;
`;

const Header = styled.div`
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
`;

const SmallHeader = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  margin-left: 6px;
`;

const ActivityCardsComponent = () => {
  const { timestamps, loader, totalVessels } = useContext(TimestampContext);
  const { namespace, user, modules, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);
  const { socketUpdate } = useContext(ServiceOrderContext);

  const [portcallsIds, setPortcallIds] = useState([]);
  const [jitDataRetrieved, setJitDataRetrieved] = useState(false);

  const [sideTimestampData, setSideTimestampData] = useState(null);

  const [width, setWidth] = useState(0);
  const [columnAmount, setColumnAmount] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [useRows, setUseRows] = useState(false);

  const [size, setSize] = useState('large'); // small, medium, large

  const timestampsRef = useRef();
  const sizeRef = useRef();

  let masterIdListWithCommas = '';

  const checkWidth = useCallback(
    sizeParam => {
      const usableSize = sizeParam || size;
      let columns = 0;
      let rows = false;

      if (timestampsRef?.current) {
        const origWidth = timestampsRef.current.getBoundingClientRect().width;
        let origContainerWidth = 0;
        if (width !== origWidth || sizeRef.current !== usableSize) {
          if (usableSize === 'large') {
            if (origWidth < 745) {
              rows = true;
              origContainerWidth = origWidth - 12;
              if (origContainerWidth < 480) {
                columns = 1;
              } else {
                columns = 2;
              }
            } else {
              origContainerWidth = origWidth - 36 - 28;
              if (origContainerWidth < 1200) {
                columns = 3;
              } else if (origContainerWidth < 1540) {
                columns = 4;
              } else if (origContainerWidth < 1880) {
                columns = 5;
              } else if (origContainerWidth < 2200) {
                columns = 6;
              } else {
                columns = 7;
              }
            }
          } else if (usableSize === 'medium') {
            if (origWidth < 745) {
              rows = true;
              origContainerWidth = origWidth - 12;
              if (origContainerWidth < 480) {
                columns = 1;
              } else {
                columns = 2;
              }
            } else {
              origContainerWidth = origWidth - 36 - 28;
              if (origContainerWidth < 1200) {
                columns = 3;
              } else if (origContainerWidth < 1540) {
                columns = 4;
              } else if (origContainerWidth < 1880) {
                columns = 5;
              } else if (origContainerWidth < 2200) {
                columns = 6;
              } else {
                columns = 7;
              }
            }
          } else {
            if (origWidth < 745) {
              rows = true;
              origContainerWidth = origWidth - 12;
              if (origContainerWidth < 480) {
                columns = 1;
              } else {
                columns = 2;
              }
            } else {
              origContainerWidth = origWidth - 36 - 28;
              if (origContainerWidth < 900) {
                columns = 3;
              } else if (origContainerWidth < 1140) {
                columns = 4;
              } else if (origContainerWidth < 1580) {
                columns = 5;
              } else if (origContainerWidth < 1800) {
                columns = 6;
              } else {
                columns = 7;
              }
            }
          }

          setColumnAmount(columns);
          setWidth(origWidth);
          setColumnWidth(origContainerWidth / columns);
          setUseRows(rows);
        }
      }
      sizeRef.current = usableSize;
    },
    [size, width]
  );

  const resize = useCallback(
    size => {
      setTimeout(() => {
        checkWidth(size);
      }, 1000);
    },
    [checkWidth]
  );

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let widthInterval = undefined;
    widthInterval = setInterval(() => {
      checkWidth();
    }, 5000);

    return () => clearInterval(widthInterval);
  }, [size]); // eslint-disable-line react-hooks/exhaustive-deps

  if (modules.queue_module === 'enabled' && user.permissions.includes('manage_queue_slot_reservation')) {
    timestamps?.forEach(portCall => (masterIdListWithCommas += portCall.ship.master_id + ','));
  }

  const { data: decisionData, error: decisionError, fetchData: fetchDecisions } = useApi(
    'get',
    'port-call-decisions-summary'
  );

  const { data: notificationData, error: notificationError, fetchData: fetchNotifications } = useApi(
    'get',
    'port-call-notifications-summary'
  );

  const { data: serviceOrderData, error: serviceOrderError, fetchData: fetchServiceOrders } = useApi(
    'post',
    'service-orders/service-order/search',
    { query: { text: portcallsIds.join('|') } },
    null,
    false
  );

  const { data: jitData, error: jitError, fetchData: fetchJitData } = useApi(
    'get',
    'jit-eta-vessels',
    { port_call_master_ids: masterIdListWithCommas.slice(0, -1) },
    null,
    modules.queue_module === 'enabled' &&
      user.permissions.includes('manage_queue_slot_reservation') &&
      masterIdListWithCommas.length > 0
  );

  useEffect(() => {
    setPortcallIds(previous => {
      const newPortcallIds = timestamps?.map?.(portcall => portcall.ship?.master_id) || [];
      if (newPortcallIds.length === previous.length && newPortcallIds.every(id => previous.includes(id))) {
        return previous;
      }
      return newPortcallIds;
    });
  }, [timestamps]);

  const reFetchJIT = useMemo(
    () =>
      throttle(20000, false, () => {
        // spread out request per client
        setTimeout(() => {
          if (
            modules.queue_module === 'enabled' &&
            user.permissions.includes('manage_queue_slot_reservation') &&
            masterIdListWithCommas.length > 0
          ) {
            fetchJitData(false, { port_call_master_ids: masterIdListWithCommas.slice(0, -1) });
          }
        }, Math.floor(Math.random() * 5000));
      }),
    [fetchJitData, masterIdListWithCommas, modules.queue_module, user.permissions]
  );

  useUserSocket('portcalls-changed', reFetchJIT);

  const getServiceOrders = useCallback(() => {
    if (
      modules.service_order_module === 'enabled' &&
      user.permissions.includes('view_service_order') &&
      portcallsIds.length
    ) {
      fetchServiceOrders(false, { query: { text: portcallsIds.join('|') } });
    }
  }, [fetchServiceOrders, modules.service_order_module, portcallsIds, user.permissions]);

  useEffect(() => {
    getServiceOrders();
  }, [getServiceOrders, portcallsIds]);

  useEffect(() => {
    if (
      !jitDataRetrieved &&
      modules.queue_module === 'enabled' &&
      user.permissions.includes('manage_queue_slot_reservation') &&
      masterIdListWithCommas.length > 0
    ) {
      setJitDataRetrieved(true);
      fetchJitData(false, { port_call_master_ids: masterIdListWithCommas.slice(0, -1) });
    }
  }, [fetchJitData, jitDataRetrieved, masterIdListWithCommas, modules.queue_module, user.permissions]);

  const getDecisions = useCallback(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  const getNotifications = useCallback(() => {
    const masterIds = timestamps?.map(portCall => portCall.ship.master_id);
    if (masterIds.length > 0) {
      fetchNotifications(false, { port_call_master_ids: masterIds });
    }
  }, [fetchNotifications, timestamps]);

  useUserSocket('port-call-decision-changed', getDecisions);
  useUserSocket('notifications-summary-common-changed', getNotifications);
  useUserSocket(`notifications-summary-changed-${user.id}`, getNotifications);

  const socketUpdateRef = useRef(socketUpdate);
  useEffect(() => {
    if (socketUpdate !== socketUpdateRef.current) {
      getServiceOrders();
    }
    socketUpdateRef.current = socketUpdate;
  }, [getServiceOrders, socketUpdate]);

  let notifications = notificationError ? [] : notificationData;
  let decisions = decisionError ? [] : decisionData;
  const serviceOrders = serviceOrderError || !serviceOrderData ? [] : serviceOrderData.results['service-orders'];
  const jitList = jitError ? [] : jitData;

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const reloadServiceOrderData = () => {
    getServiceOrders();
  };

  let noVessels = false;
  if (!loader && timestamps && timestamps.length === 0) {
    noVessels = true;
  }

  let selectedTimestamp = null;
  const timestampList = timestamps.map(({ ship, portcalls }) => {
    let newNotifications = null;
    let masterId = ship?.master_id;
    if (notifications && masterId && notifications[masterId]) {
      newNotifications = notifications[masterId];
    }
    let newDecisions = null;
    if (decisions && masterId && decisions[masterId]) {
      newDecisions = decisions[masterId];
    }
    let transitions = null;
    if (ship?.berth_transitions && ship.berth_transitions.length) {
      transitions = ship.berth_transitions;
    }
    let newServiceOrders = null;
    if (serviceOrders) {
      newServiceOrders = serviceOrders.filter(so => so.port_call_id === ship.master_id);
    }
    let jitForVessel = null;
    if (jitList && ship) {
      jitForVessel = jitList[ship.master_id];
    }

    if (sideTimestampData === ship.id) {
      selectedTimestamp = {
        ship,
        portcalls,
        newNotifications,
        newDecisions,
        transitions,
        newServiceOrders,
        jitForVessel,
      };
    }

    return {
      ship,
      portcalls,
      newNotifications,
      newDecisions,
      transitions,
      newServiceOrders,
      jitForVessel,
    };
  });

  let arrivingList = [];
  let atBerthList = [];
  let departedList = [];

  timestampList.forEach(item => {
    if (item.ship.status === 'arriving') {
      arrivingList.push(item);
    } else if (item.ship.status === 'at berth') {
      atBerthList.push(item);
    } else {
      departedList.push(item);
    }
  });

  const createFilterPopover = () => {
    return (
      <div style={{ padding: '12px' }}>
        <Header>{t('Options')}</Header>
        <SmallHeader>{t('Size')}</SmallHeader>
        <RadioButtons>
          <Radio.Group
            onChange={e => {
              setSize(e.target.value);
              resize(e.target.value);
            }}
            value={size}
          >
            <Radio value="small">{t('Small')}</Radio>
            <Radio value="medium">{t('Medium')}</Radio>
            <Radio value="large">{t('Large')}</Radio>
          </Radio.Group>
        </RadioButtons>
      </div>
    );
  };

  return (
    <Container>
      <Timestamps sideTimestamp={!!sideTimestampData} alertsHeight={alertsHeight} ref={timestampsRef}>
        <Popover content={createFilterPopover()} placement="leftBottom" trigger="click">
          <FilterIcon>
            <Icon type="options" />
          </FilterIcon>
        </Popover>
        {(loader || !columnWidth) && (
          <Loader>
            <Spin size="large" />
          </Loader>
        )}
        {!loader && !!columnWidth && !noVessels && (
          <Columns useRows={useRows}>
            <Column
              style={{
                width: useRows ? 'calc(100% - 20px)' : columnWidth * Math.ceil((columnAmount - 1) / 2) + 'px',
              }}
              useRows={useRows}
            >
              <Title>
                <TitleText>{t('Arriving') + ' (' + arrivingList.length + ')'}</TitleText>
              </Title>
              <ColumnCards>
                {arrivingList.map(
                  ({
                    ship,
                    portcalls,
                    newNotifications,
                    newDecisions,
                    transitions,
                    newServiceOrders,
                    jitForVessel,
                  }) => {
                    return (
                      <CardV2
                        totalVessels={totalVessels}
                        notifications={newNotifications || ship.notification_summary}
                        decisions={newDecisions || ship.decision_summary}
                        serviceOrders={newServiceOrders}
                        jitForVessel={jitForVessel}
                        key={ship.id}
                        data={ship}
                        cardOpen={!!localStorage.getItem(`vessel-notification-${ship.imo}`)}
                        transitions={transitions}
                        reloadServiceOrderData={reloadServiceOrderData}
                        hasWarnings={portcalls[0]?.has_warnings}
                        clickCard={() => setSideTimestampData(ship.id)}
                        portcalls={portcalls}
                        width={useRows ? columnWidth - 6 : columnWidth}
                        size={size}
                      />
                    );
                  }
                )}
                {arrivingList.length === 0 && (
                  <TextContainer>
                    <Text2>{t('No arriving vessels.')}</Text2>
                  </TextContainer>
                )}
              </ColumnCards>
            </Column>
            <Column
              style={{
                width: useRows ? 'calc(100% - 20px)' : columnWidth * Math.floor((columnAmount - 1) / 2) + 'px',
              }}
              useRows={useRows}
            >
              <Title>
                <TitleText>{t('At berth') + ' (' + atBerthList.length + ')'}</TitleText>
              </Title>
              <ColumnCards>
                {atBerthList.map(
                  ({
                    ship,
                    portcalls,
                    newNotifications,
                    newDecisions,
                    transitions,
                    newServiceOrders,
                    jitForVessel,
                  }) => {
                    return (
                      <CardV2
                        totalVessels={totalVessels}
                        notifications={newNotifications || ship.notification_summary}
                        decisions={newDecisions || ship.decision_summary}
                        serviceOrders={newServiceOrders}
                        jitForVessel={jitForVessel}
                        key={ship.id}
                        data={ship}
                        cardOpen={!!localStorage.getItem(`vessel-notification-${ship.imo}`)}
                        transitions={transitions}
                        reloadServiceOrderData={reloadServiceOrderData}
                        hasWarnings={portcalls[0]?.has_warnings}
                        clickCard={() => setSideTimestampData(ship.id)}
                        portcalls={portcalls}
                        width={useRows ? columnWidth - 6 : columnWidth}
                        size={size}
                      />
                    );
                  }
                )}
                {atBerthList.length === 0 && (
                  <TextContainer>
                    <Text2>{t('No vessels at berth.')}</Text2>
                  </TextContainer>
                )}
              </ColumnCards>
            </Column>
            <Column style={{ width: useRows ? 'calc(100% - 20px)' : columnWidth + 'px' }} useRows={useRows}>
              <Title>
                <TitleText>{t('Departed') + ' (' + departedList.length + ')'}</TitleText>
              </Title>
              <ColumnCards>
                {departedList.map(
                  ({
                    ship,
                    portcalls,
                    newNotifications,
                    newDecisions,
                    transitions,
                    newServiceOrders,
                    jitForVessel,
                  }) => {
                    return (
                      <CardV2
                        totalVessels={totalVessels}
                        notifications={newNotifications || ship.notification_summary}
                        decisions={newDecisions || ship.decision_summary}
                        serviceOrders={newServiceOrders}
                        jitForVessel={jitForVessel}
                        key={ship.id}
                        data={ship}
                        cardOpen={!!localStorage.getItem(`vessel-notification-${ship.imo}`)}
                        transitions={transitions}
                        reloadServiceOrderData={reloadServiceOrderData}
                        hasWarnings={portcalls[0]?.has_warnings}
                        clickCard={() => setSideTimestampData(ship.id)}
                        portcalls={portcalls}
                        width={useRows ? columnWidth - 6 : columnWidth}
                        size={size}
                      />
                    );
                  }
                )}
                {departedList.length === 0 && (
                  <TextContainer>
                    <Text2>{t('No departed vessels.')}</Text2>
                  </TextContainer>
                )}
              </ColumnCards>
            </Column>
          </Columns>
        )}
        {noVessels && (
          <TextContainer>
            <Text>{t('There are no vessels to be shown.')}</Text>
          </TextContainer>
        )}
      </Timestamps>

      {selectedTimestamp && (
        <SideTimestamp
          totalVessels={totalVessels}
          notifications={selectedTimestamp.newNotifications || selectedTimestamp.ship.notification_summary}
          decisions={selectedTimestamp.newDecisions || selectedTimestamp.ship.decision_summary}
          serviceOrders={selectedTimestamp.newServiceOrders}
          jitForVessel={selectedTimestamp.jitForVessel}
          data={selectedTimestamp.ship}
          cardOpen={!!localStorage.getItem(`vessel-notification-${selectedTimestamp.ship.imo}`)}
          transitions={selectedTimestamp.transitions}
          reloadServiceOrderData={reloadServiceOrderData}
          hasWarnings={selectedTimestamp.portcalls[0]?.has_warnings}
          portcalls={selectedTimestamp.portcalls}
          closePanel={() => setSideTimestampData(null)}
        />
      )}
    </Container>
  );
};

export default ActivityCardsComponent;
