import { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 87.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @supports (-ms-ime-align: auto) {
      font-size: 80.5%;
      .ant-layout-sider {
        background: ${({ theme }) => theme.color.white};
      }
      .ant-layout {
        background: ${({ theme }) => theme.color.white};
      }
    }
  }

  body {
    font-family: ${({ theme }) => theme.text.font_text};
    line-height: ${({ theme }) => theme.text.line_height};
    color: ${({ theme }) => theme.color.grey_dark};
    background: ${({ theme }) => theme.color.white};
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  .ant-pagination { }
  .ant-calendar { }
  .ant-timeline {
    &-item {
      position: relative;
      i {
        height: 2rem;
        width: 2rem;
        svg {
          margin-top: 4px;
        }
      }
      &-head { }
      &-left { }
      &-right {
        i {
          transform: scaleX(-1);
        }
      }
      &-content {
        display: flow-root;
        max-width: calc(50% - 32px);
        .ant-timeline-item-left & {
          left: calc(50% + 16px) !important;
        }
      }
    }
  }
  .ant-popover {
    &-message {
      margin: 0;
      padding: 0;
      &-title {
        color: ${({ theme }) => theme.color.grey_dark};
        font-weight: 700;
        padding: 0 !important;
        margin: 0 0 ${({ theme }) => theme.sizing.gap_small};
      }
    }
    &-buttons {
      margin: 0;
      .ant-btn {
        padding: 0;
        border: none;
        background: none;
        box-shadow: none;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color.secondary};
        margin-right: ${({ theme }) => theme.sizing.gap_small};
        &:hover {
          background: none;
        }
        & + .ant-btn {
          margin-right: 0;
          color: ${({ theme }) => theme.color.warning};
        }
      }
      .ant-btn-link {
        color: ${({ theme }) => theme.color.grey_dark};
      }
      .ant-btn-primary {
        color: ${({ theme }) => theme.color.secondary} !important;
      }
    }
  }
  .ant-collapse {
    &-item {
      transition: all .25s ease-in-out;
      background: ${({ theme }) => theme.color.white};
      &.ant-collapse-item-active {
        color: ${({ theme }) => theme.color.black};
        background: ${({ theme }) => theme.color.grey_lighter};
        p {
          color: inherit;
        }
      }
    }
    &-content {
      padding-left: 1.725rem;
    }
  }
  .ant-tabs {
    &-tab {
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      &-active {
        font-weight: 700 !important;
      }
    }
  }
  .ant-alert {
    &-banner {
      padding: ${({ theme }) => theme.sizing.gap} ${({ theme }) => theme.sizing.gap_big} !important;
      border-bottom: 1px solid ${({ theme }) => theme.color.grey_light} !important;
    }
    &-close-icon {
      top: 13px !important;
    }
  }
  .ant-message {
    top: 12px !important;
    &-notice-content {
      color: ${({ theme }) => theme.color.black};
      font-size: 0.8571rem;
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      box-shadow: ${({ theme }) => theme.fx.box_shadow};
    }
  }
  .ant-modal-title,
  .ant-modal-close {
     font-weight: 700;
  }

  .ant-modal-close:hover {
    color: ${({ theme }) => darken(0.1, theme.color.white)};
  }

  .ant-btn-primary:hover {
    background-color: ${({ theme }) => darken(0.05, theme.color.secondary)} !important;
    border-color: ${({ theme }) => darken(0.05, theme.color.secondary)} !important;
    color: white !important;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .ant-modal-body,
  .ant-modal-content {
    padding: 0;
  }

  .ant-tooltip {
    max-width: 420px;
  }

  .ant-empty-description {
    color: #686868 !important;
  }

  .custom-icon {
    cursor: grab;
  }

  .weather-header-row {
    > td {
      border-width: 2px !important;
    }
  }

  .map-vessel-tooltip {
    padding: 0 !important;
  }

  .pdf-page {
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }

  .custom-tooltip-div:before {
    border: solid;
    border-color: #D8D8D8 transparent;
    border-color: #D8D8D8 transparent;
    border-width: 0 8px 8px 8px;
    bottom: 100%;
    content:"";
    display: block;
    left: 50%;
    top: -10px;
    position: absolute;
    z-index: 99;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  
  .custom-tooltip-div:after {
    border: solid;
    border-color: #ffffff transparent;
    border-color: #ffffff transparent;
    border-width: 0 6px 6px 6px;
    bottom: 100%;
    content:"";
    display: block;
    left: 50%;
    top: -10px;
    position: absolute;
    z-index: 99;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .small-icon-font {
    font: 7px sans-serif;
    font-weight: 700;
  }

  .waste-pdf-file {
    display: flex;
    justify-content: center;

    div:first-child {
      border: 1px solid #e8e8e8;
      padding: 6px;
    }
  }

  .ant-popover-inner-content {
    padding: 0px;
  }

  .leaflet-circle-center-round {
    border-radius: 4px;
  }
`;

export default GlobalStyle;
