import React from 'react';
import styled from 'styled-components';

import ServiceDot from '../activity/serviceOrder/ServiceDot';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ServiceOrdersContainer = styled.div`
  align-item: center;
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-item: center;
`;

const RequestContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

const ServiceOrder = styled.div`
  height: 100%;
  width: 8px;
  position: absolute;
  border-radius: 2px;
  padding: 1px 2px;
`;

const ServiceOrderInner = styled.div`
  height: calc(100% - 2px);
  width: 4px;
  border-radius: 2px;
  margin-top: 1px;
`;

const ServiceOrderCircle = styled.div`
  height: 9px;
  width: 9px;
  position: absolute;
  border-radius: 50%;
  padding: 1px 2px;
  color: white;
  font-weight: 700;
  line-height: 7px;
  padding-left: 3px;
  font-size: 9px;
`;

const FleetTimelineServiceOrders = ({
  serviceOrders,
  setTooltipOpen,
  vesselStarttime,
  vesselEndtime,
  millisecondInPixels,
}) => {
  let requested = [];
  let acknowledged = [];
  let delivered = [];

  let requestedWithoutTime = [];
  let acknowledgedWithoutTime = [];
  let deliveredWithoutTime = [];

  if (serviceOrders && serviceOrders.length > 0) {
    serviceOrders.forEach(so => {
      if (so.order_lines) {
        so.order_lines.forEach(ol => {
          if (ol.deliveries.length > 0) {
            delivered.push(ol);
            if (!ol.requested_delivery_time) {
              deliveredWithoutTime.push(ol);
            }
          } else if (ol.ack) {
            acknowledged.push({ service_order_id: so.id, ...ol });
            if (!ol.requested_delivery_time) {
              acknowledgedWithoutTime.push({ service_order_id: so.id, ...ol });
            }
          } else {
            requested.push({ service_order_id: so.id, ...ol });
            if (!ol.requested_delivery_time) {
              requestedWithoutTime.push({ service_order_id: so.id, ...ol });
            }
          }
        });
      }
    });
  }

  return (
    <>
      {serviceOrders.map((so, i) => {
        return so.order_lines.map(ol => {
          const reqTimeString =
            ol.deliveries.length > 0 && ol.deliveries[ol.deliveries.length - 1].delivery_time
              ? ol.deliveries[ol.deliveries.length - 1].delivery_time
              : ol.requested_delivery_time;

          if (!reqTimeString || ol.is_cancelled) {
            return;
          }
          const reqTime = dayjs(reqTimeString);
          const color =
            ol.deliveries.length > 0
              ? '#407505'
              : ol.ack
                ? ol.ack.status === 'rejected'
                  ? '#868686'
                  : '#F5BD3E'
                : '#F44336';

          if (reqTime.isBefore(vesselStarttime)) {
            return (
              <ServiceOrderCircle
                key={i}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                style={{ left: '-11px', backgroundColor: color }}
              >
                !
              </ServiceOrderCircle>
            );
          } else if (reqTime.isAfter(vesselEndtime)) {
            return (
              <ServiceOrderCircle
                key={i}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                style={{ left: 'calc(100% + 2px)', backgroundColor: color }}
              >
                !
              </ServiceOrderCircle>
            );
          } else {
            const place = dayjs.duration(dayjs(reqTime).diff(vesselStarttime)).asMilliseconds() * millisecondInPixels;
            return (
              <ServiceOrder key={i} style={{ left: place }}>
                <ServiceOrderInner style={{ backgroundColor: color }} />
              </ServiceOrder>
            );
          }
        });
      })}
      <ServiceOrdersContainer>
        <DotContainer>
          {requestedWithoutTime && requestedWithoutTime.filter(r => !r.is_cancelled).length > 0 && (
            <RequestContainer onClick={() => null}>
              <ServiceDot status={'requested'} clickable={true} small={true} />
            </RequestContainer>
          )}
          {acknowledgedWithoutTime && acknowledgedWithoutTime.filter(a => !a.is_cancelled).length > 0 && (
            <RequestContainer onClick={() => null}>
              <ServiceDot
                status={
                  acknowledgedWithoutTime?.every(item => item.ack?.status === 'rejected') ? 'rejected' : 'acknowledged'
                }
                clickable={true}
                small={true}
              />
            </RequestContainer>
          )}
          {deliveredWithoutTime && deliveredWithoutTime.length > 0 && (
            <RequestContainer onClick={() => null}>
              <ServiceDot status={'delivered'} clickable={true} small={true} />
            </RequestContainer>
          )}
        </DotContainer>
      </ServiceOrdersContainer>
    </>
  );
};

export default FleetTimelineServiceOrders;
