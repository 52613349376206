import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Popconfirm from 'antd/es/popconfirm';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import ShipRoute from '../../components/activity/ShipRoute';

import AdminPortCallTimestamps from './AdminPortCallTimestamps';
import AdminNonPortCallTimestamps from './AdminNonPortCallTimestamps';

import NotificationRow from '../../components/activity/NotificationRow';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import SpeedModal from '../../components/activity/SpeedModal';
import DateComponent from '../../components/ui/DateComponent';

const ShipName = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const ShipIMO = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  cursor: pointer;
`;

const NotificationWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: -6px;
`;

const AdminPortCallList = () => {
  const { apiCall, namespace, user, setAlert, useUserSocket, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Port calls | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: 'atd DESC',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'port-calls', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const portCalls = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  const { data: decisionData, error: decisionError, fetchData: fetchDecisions } = useApi(
    'get',
    'port-call-decisions-summary'
  );

  const { data: notificationData, error: notificationError, fetchData: fetchNotifications } = useApi(
    'get',
    'port-call-notifications-summary'
  );

  const getDecisions = useCallback(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  const getNotifications = useCallback(() => {
    let masterIds = [];
    if (data?.data) {
      masterIds = data.data.map(portCall => portCall.master_id);
    }

    if (masterIds.length > 0) {
      fetchNotifications(false, { port_call_master_ids: masterIds });
    }
  }, [data, fetchNotifications]);

  useUserSocket('port-call-decision-changed', getDecisions);
  useUserSocket('notifications-summary-common-changed', getNotifications);
  useUserSocket(`notifications-summary-changed-${user.id}`, getNotifications);

  let notifications = notificationError ? [] : notificationData;
  let decisions = decisionError ? [] : decisionData;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleClosePortCall = async id => {
    await apiCall('get', 'force-close-port-call', { port_call_id: id });
    let params = newParams;
    fetchData(false, params);
  };

  const handleCancelPortCall = async ({ imo, vessel_name, master_id }) => {
    const time = dayjs.utc(dayjs().set({ second: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00');
    await apiCall('post', 'timestamps', {
      imo,
      vessel_name,
      time_type: 'Actual',
      state: 'PortVisit_Cancelled',
      time,
      payload: {
        source: 'ui',
        external_id: master_id,
      },
    });
    let params = newParams;
    fetchData(false, params);
  };

  const handleScanPortCall = async id => {
    await apiCall('get', 'force-scan-port-call', { port_call_id: id });
    let params = newParams;
    fetchData(false, params);
  };

  const columns = [
    {
      title: t('Ship and route'),
      dataIndex: 'imo',
      width: '30%',
      key: 'imo',
      sorter: (a, b) => a.vessel_name?.localeCompare?.(b.vessel_name),
      render: (text, record) => {
        let newNotifications = null;
        let masterId = record.master_id;
        if (notifications && masterId && notifications[masterId]) {
          newNotifications = notifications[masterId];
        }
        let newDecisions = null;
        if (decisions && masterId && decisions[masterId]) {
          newDecisions = decisions[masterId];
        }

        return (
          <>
            <ShipName>{record.vessel_name}</ShipName>
            <ShipIMO
              onClick={() =>
                user.permissions.includes('manage_port_call') &&
                history.push(`/vessels/vessel-timestamps/${record.imo}`)
              }
            >
              IMO {record.imo}
            </ShipIMO>
            <ShipRoute from={record.from_port} to={record.to_port} next={record.next_port} />
            <NotificationWrapper>
              <NotificationRow
                notifications={newNotifications || record.notification_summary}
                decisions={newDecisions || record.decision_summary}
                imo={record.imo}
                master_id={record.master_id}
                vessel_name={record.vessel_name}
              />
              {modules.port_call_dashboard_module === 'enabled' &&
                user.permissions.includes('view_port_call_dashboard') && (
                <SpeedModal
                  vesselName={record.vessel_name}
                  portcallId={record.id}
                  mmsi={record.mmsi}
                  showIconAlways={true}
                  statusDone={record.status === 'done'}
                />
              )}
            </NotificationWrapper>
          </>
        );
      },
    },
    {
      title: t('Port call ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('ETA'),
      dataIndex: 'current_eta',
      key: 'current_eta',
      sortableKey: 'current_eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('RTA'),
      dataIndex: 'rta',
      key: 'rta',
      sortableKey: 'rta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ATA'),
      dataIndex: 'ata',
      key: 'ata',
      sortableKey: 'ata',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ETD'),
      dataIndex: 'current_etd',
      key: 'current_etd',
      sortableKey: 'current_etd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ATD'),
      dataIndex: 'atd',
      key: 'atd',
      sortableKey: 'atd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      sortableKey: 'status',
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => history.push(`/admin/port-calls/${record.id}`)}>
          {t('Timesheet')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Really force port call close?')}
          onConfirm={() => handleClosePortCall(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              red
              disabled={
                !user.permissions.includes('manage_port_call') ||
                record.status === 'cancelled' ||
                record.status === 'done' ||
                record.status === 'arriving'
              }
            >
              {t('Close port call')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Really cancel port call?')}
          onConfirm={() => handleCancelPortCall(record)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-3"
        >
          <div>
            <ListActionButton
              red
              disabled={
                !user.permissions.includes('manage_port_call') ||
                record.status === 'done' ||
                record.status === 'cancelled'
              }
            >
              {t('Cancel port call')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Really force port call re-scan?')}
          onConfirm={() => handleScanPortCall(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-4"
        >
          <div>
            <ListActionButton red disabled={!user.permissions.includes('manage_port_call')}>
              {t('Re-scan port call')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const getButtonText = () => {
    if (newParams.search && newParams.search.length > 0) {
      if (portCalls && portCalls.length > 0) {
        let exactMatch = undefined;

        let searchResults = portCalls.filter(pc => {
          if (pc.vessel_name.toUpperCase() === newParams.search.toUpperCase()) {
            exactMatch = pc.imo;
          }

          if (pc.imo.toString() === newParams.search.toString()) {
            exactMatch = pc.imo;
          }
          return pc.vessel_name.toUpperCase().includes(newParams.search.toUpperCase());
        });

        if (searchResults.length === 1 || exactMatch) {
          return exactMatch || searchResults[0].imo;
        }
      }
    }
  };

  const buttonText = getButtonText();

  const additionalButtons = [
    {
      onClick: () => history.push(`/vessels/vessel-timestamps/${buttonText}`),
      disabled: !user.permissions.includes('manage_port_call') || !buttonText,
      text: t('Timestamps for {{imo}}', { imo: buttonText || '-' }),
    },
  ];

  return (
    <Layout pagename={t('Port Call Timesheets')}>
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={portCalls}
          actions={actions}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search by name or exact IMO')}
          expandedRowRender={record => (
            <>
              <AdminPortCallTimestamps port_call_id={record.id} />
              <AdminNonPortCallTimestamps imo={record.imo} />
            </>
          )}
          additionalButtons={additionalButtons}
        />
      </Page>
    </Layout>
  );
};

export default AdminPortCallList;
