import { TileLayer, WMSTileLayer, useMapEvents } from 'react-leaflet';
import React, { useContext } from 'react';

import { PublicContext } from '../../context/PublicContext';
import useMap from './UseMap';
import { getOptions } from './MapUtils';

const LandingPageMapInnerContainer = ({ zoom, setZoom }) => {
  const { allMarkers, allVessels } = useContext(PublicContext);

  const { markersOverlay, vesselsOverlay } = useMap({
    vessels: allVessels,
    markers: allMarkers,
    appliedZoom: zoom,
    isPublicMap: true,
    landing: true,
  });

  const options = getOptions();

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
    },
  });

  return (
    <>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      {vesselsOverlay}
      <WMSTileLayer
        url="https://julkinen.traficom.fi/s57/wms"
        layers="cells"
        transparent={true}
        minZoom={14}
        format="image/png"
      />
      {markersOverlay}
    </>
  );
};

export default LandingPageMapInnerContainer;
