import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import Radio from 'antd/es/radio';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';

const Container = styled.div`
  z-index: 999;
  position: absolute;
  width: 240px;
  right: 0;
  top: 0;
  display: flex;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(226px)')};
  transition: all 0.3s linear;
`;

const Opener = styled.div`
  position: absolute;
  width: 14px;
  height: 40px;
  top: 14px;
  cursor: pointer;
  z-index: 100;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 1px 0 0 1px;
`;

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grey_lighter};
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  position: relative;
  width: calc(100% - 14px);
  margin-left: 14px;
  border-radius: 0 0 4px 4px;
  cursor: default;
  padding: 24px;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
`;

const IconContainer = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  margin-left: 1px;
  color: ${({ theme }) => theme.color.grey};
`;

const IconContainerCrane = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  color: ${({ theme }) => theme.color.grey};
  margin-left: 12px;
  margin-right: 6px;

  svg {
    margin-top: -20px;
  }
`;

const Header = styled.div`
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 14px;
  color: ${({ theme }) => theme.color.dark_grey};
`;

const SmallHeader = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.color.dark_grey};
  margin-top: 12px;
`;

const RadioContainer = styled.div`
  padding: 6px 0;
`;

const SwitchesContainer = styled.div`
  padding-top: 6px;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-left: 12px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : '8px')};

  button {
    margin-top: 3px;
  }
`;

const Indentation = styled.div`
  margin-left: 40px;
`;

const AssetWrapper = styled.div`
  overflow-y: overlay;
  max-height: 200px;
`;

const LayersComponent = ({
  mapType,
  setMapType,
  vesselState,
  setVesselState,
  showVessels,
  setShowVessels,
  routesOn,
  setRoutesOn,
  showMarkers,
  setShowMarkers,
  showCranes,
  setShowCranes,
  geodataPermissions,
  fleetViewOn,
  setFleetViewOn,
  quickLinksOn,
  setQuickLinksOn,
  onlyFleet,
  fleetCardsOn,
  setFleetCardsOn,
  types,
  hiddenTypes,
  setHiddenTypes,
}) => {
  const { namespace, modules, user } = useContext(UserContext); // eslint-disable-line
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onMapTypeChange = e => {
    setMapType(e.target.value);
  };

  const onVesselClick = e => {
    setShowVessels(e);
  };

  const onVesselStateChange = e => {
    setVesselState(e.target.value);
  };

  const onRoutesClick = e => {
    setRoutesOn(e);
  };

  const onMarkersClick = e => {
    setShowMarkers(e);
  };

  const onCranesClick = e => {
    setShowCranes(e);
  };

  const onFleetCardsClick = e => {
    setFleetCardsOn(e);
  };

  const onQuickLinksClick = e => {
    setQuickLinksOn(e);
  };

  const onFleetViewClick = e => {
    setFleetViewOn(e);
    if (e) {
      history.replace(location.pathname + '?type=fleet');
    } else {
      history.replace(location.pathname);
    }
  };

  const toggleHiddenType = id => {
    let tempHiddenList = [...hiddenTypes];
    for (let index = 0; index < hiddenTypes.length; index++) {
      const element = hiddenTypes[index];
      if (element === id) {
        tempHiddenList.splice(index, 1);
        setHiddenTypes(tempHiddenList);
        localStorage.setItem(`${namespace + 'MapTypeFilterList'}`, tempHiddenList);
        return;
      }
    }
    tempHiddenList.push(id);
    setHiddenTypes(tempHiddenList);
    localStorage.setItem(`${namespace + 'MapTypeFilterList'}`, tempHiddenList);
  };

  const isSwedishVersion =
    window.location.host.includes('portactivity.se') || window.location.host.includes('se.ft-testing.dev');

  return (
    <Container open={open}>
      <Opener onClick={toggleOpen}>
        <Tooltip
          title={open ? t('Close layers panel') : t('Open layers panel')}
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
          placement="left"
        >
          {open ? (
            <IconContainer aria-label="map-layers-caret-right">
              <CaretRightOutlined />
            </IconContainer>
          ) : (
            <IconContainer aria-label="map-layers-caret-left">
              <CaretLeftOutlined />
            </IconContainer>
          )}
        </Tooltip>
      </Opener>
      <ListContainer>
        <Header>{t('Map layers')}</Header>
        {!isSwedishVersion && (
          <>
            <SmallHeader>{t('Type of the map')}</SmallHeader>
            <RadioContainer>
              <Radio.Group onChange={onMapTypeChange} value={mapType}>
                <Radio value="automatic" aria-label="Automatic map">
                  {t('Automatic map (standard + sea)')}
                </Radio>
                <Radio value="standard" aria-label="Standard map">
                  {t('Standard map')}
                </Radio>
                <Radio value="seamap" aria-label="Sea map">
                  {t('Sea map')}
                </Radio>
              </Radio.Group>
            </RadioContainer>
          </>
        )}
        <SmallHeader>{t('Vessels')}</SmallHeader>
        <RadioContainer>
          <Tooltip
            title={t('Show vessels on the map')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="top"
          >
            <SwitchContainer noMarginBottom={true}>
              <Switch size="small" checked={showVessels} onChange={onVesselClick} aria-label="Show vessels" />
              <SwitchLabel>{t('Show vessels')}</SwitchLabel>
            </SwitchContainer>
          </Tooltip>
          {!isSwedishVersion && (
            <Indentation>
              <Radio.Group onChange={onVesselStateChange} value={vesselState}>
                {namespace !== 'vts' && !onlyFleet && !fleetViewOn && (
                  <Radio disabled={!showVessels} value="port" aria-label="Port's vessels">
                    {/* eslint-disable-next-line quotes */}
                    {t("Port's vessels")}
                  </Radio>
                )}
                {namespace !== 'vts' && !onlyFleet && !fleetViewOn && (
                  <Radio disabled={!showVessels} value="all-fi" aria-label="All vessels">
                    {t('All vessels')}
                  </Radio>
                )}
                {namespace === 'vts' && (
                  <Radio disabled={!showVessels} value="all-fi" aria-label="Finnish port calls">
                    {t('Finnish port calls')}
                  </Radio>
                )}
                {namespace === 'vts' && (
                  <Tooltip
                    title={fleetViewOn ? t('Fleet view needs to be disabled to access this option.') : null}
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                  >
                    <Radio disabled={!showVessels || fleetViewOn} value="helcom" aria-label="Helcom">
                      {t('Northern Europe')}
                    </Radio>
                  </Tooltip>
                )}
                {namespace === 'vts' && (
                  <Tooltip
                    title={fleetViewOn ? t('Fleet view needs to be disabled to access this option.') : null}
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                  >
                    <Radio disabled={!showVessels || fleetViewOn} value="all-ais" aria-label="All">
                      {t('All AIS')}
                    </Radio>
                  </Tooltip>
                )}
              </Radio.Group>
            </Indentation>
          )}
        </RadioContainer>
        {modules.predictive_berth_planning_module === 'enabled' &&
          user.permissions.includes('view_crane_reservations') && (
          <Tooltip
            title={t('Show cranes on the map')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="top"
          >
            <SwitchContainer>
              <Switch size="small" checked={showCranes} onChange={onCranesClick} aria-label="Cranes" />
              <IconContainerCrane aria-label="map-layers-caret-right">
                <Icon type="crane-current" />
              </IconContainerCrane>
              <SwitchLabel>{t('Show crane live positions')}</SwitchLabel> {/* eslint-disable-line quotes */}
            </SwitchContainer>
          </Tooltip>
        )}
        <SmallHeader>{t('Others')}</SmallHeader>
        <SwitchesContainer>
          <Tooltip
            title={t('Show markers on the map')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="top"
          >
            <SwitchContainer>
              <Switch size="small" checked={showMarkers} onChange={onMarkersClick} aria-label="Markers" />
              <SwitchLabel>{t('Markers')}</SwitchLabel>
            </SwitchContainer>
          </Tooltip>
          {modules?.wiki_module === 'enabled' && user.permissions.includes('view_wiki') && (
            <SwitchContainer style={{ marginTop: '4px' }}>
              <Switch size="small" checked={quickLinksOn} onChange={onQuickLinksClick} aria-label="Quick links" />
              <SwitchLabel>{t('Quick links')}</SwitchLabel>
            </SwitchContainer>
          )}
          {geodataPermissions && (
            <Tooltip
              title={t('Show routes on the map')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="top"
            >
              <SwitchContainer>
                <Switch
                  disabled={vesselState === 'all'}
                  size="small"
                  checked={routesOn}
                  onChange={onRoutesClick}
                  aria-label="Routes"
                />
                <SwitchLabel>{t('Routes')}</SwitchLabel>
              </SwitchContainer>
            </Tooltip>
          )}
          {modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet') && (
            <>
              {/* <Tooltip
                title={t('Show fleet data on the map')}
                color="white"
                overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                placement="top"
              > */}
              <SwitchContainer style={{ marginTop: '20px' }} noMarginBottom={true}>
                <Switch
                  size="small"
                  checked={fleetViewOn}
                  onChange={onFleetViewClick}
                  aria-label="Fleet view layer switch"
                />
                <SwitchLabel>{t('Fleet view')}</SwitchLabel>
              </SwitchContainer>
              {/* </Toolatip> */}
              {fleetViewOn && (
                <Indentation>
                  {/* <Tooltip
                    title={t('Show port cards on the map')}
                    color="white"
                    overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                    placement="top"
                  > */}
                  <SwitchContainer style={{ marginTop: '4px' }}>
                    <Switch
                      size="small"
                      checked={fleetCardsOn}
                      onChange={onFleetCardsClick}
                      aria-label="Fleet port cards"
                    />
                    <SwitchLabel>{t('Port cards')}</SwitchLabel>
                  </SwitchContainer>
                  {/* </Tooltip> */}
                </Indentation>
              )}
            </>
          )}
        </SwitchesContainer>
        {types?.length > 0 && (
          <>
            <Tooltip
              title={t('Hide/show asset types on map')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="top"
            >
              <SmallHeader>{t('Geo assets')}</SmallHeader>
            </Tooltip>
            <AssetWrapper>
              <SwitchesContainer>
                {types.map(e => {
                  return (
                    <SwitchContainer key={'switch_' + e.type_id}>
                      <Switch
                        size="small"
                        checked={!hiddenTypes.includes(e.type_id)}
                        onChange={() => toggleHiddenType(e.type_id)}
                        aria-label={e.name}
                      />
                      <SwitchLabel>{e.name}</SwitchLabel>
                    </SwitchContainer>
                  );
                })}
              </SwitchesContainer>
            </AssetWrapper>
          </>
        )}
      </ListContainer>
    </Container>
  );
};

export default LayersComponent;
