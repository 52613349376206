import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { MapContainer as Map } from 'react-leaflet';

import { UserContext } from '../../context/UserContext';
import VesselLocationMapInnerContainer from './VesselLocationMapInnerContainer';

const Container = styled.div`
  width: 752px;
  height: 440px;
  display: flex;
  margin-left: 24px;
  padding: 8px 18px 24px 18px;
  justify-content: center;
  position: relative;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;

  .leaflet-control-container {
    right: 50px;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    align-content: auto;
  }

  @media (max-height: 800px) {
    height: 100%;
  }
`;

const VesselLocation = ({ mmsi }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [vesselData, setVesselData] = useState(null);
  const [startDataAvailable, setStartDataAvailable] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    const getStartData = async () => {
      setLoading(true);
      if (!dataFetched) {
        setDataFetched(true);
        let result;
        try {
          result = await apiCall('get', 'sea-chart/vessels', {});
        } catch (e) {
          setVesselData(null);
        }
        if (result?.data) {
          const data = result.data.find(d => {
            return d.properties.mmsi === mmsi;
          });
          if (data) {
            setVesselData(data);
          }
        }
      }
      setLoading(false);
    };

    if (!startDataAvailable) {
      getStartData();
      setStartDataAvailable(true);
    }
  }, [apiCall, dataFetched, mmsi, startDataAvailable]);

  return (
    <Container>
      {loading ? (
        <Loader>
          <Spin size="medium" />
        </Loader>
      ) : vesselData ? (
        <MapContainer>
          <Map
            center={[vesselData.geometry.coordinates[1], vesselData.geometry.coordinates[0]]}
            tap={false}
            zoom={zoom}
            zoomControl={false}
          >
            <VesselLocationMapInnerContainer data={vesselData} zoom={zoom} setZoom={setZoom} />
          </Map>
        </MapContainer>
      ) : (
        <NoData>{t('No data available.')}</NoData>
      )}
    </Container>
  );
};

export default VesselLocation;
