import React from 'react';
import { ReactSVG } from 'react-svg';
import { API_URL } from '../../utils/constants';
import LogoImage from '../../images/logo.svg?react';

const DynamicLogo = ({ src, ...rest }) => (
  <ReactSVG
    {...rest}
    fallback={() => <LogoImage alt="Port Activity App" />}
    loading={() => <span></span>}
    src={`${API_URL}${src}`}
    useRequestCache={true}
    wrapper="span"
  />
);

export default DynamicLogo;
