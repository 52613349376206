import React, { useRef } from 'react';
import styled from 'styled-components';
import SidebarHeader from './SidebarHeader';

const CalendarHeaderContainer = styled.div`
  box-shadow: 1px 2px 2px 0px rgb(0 0 0 / 20%);
  margin-bottom: 2px;
  padding-top: 6px;
  border-top: 1px solid #f8f8f8;
  padding-left: 3px;
  padding-top: 6px;
`;

const TimelineHeaders = props => {
  const scrollHeaderRef = useRef();

  const getRootStyle = () => {
    return {
      ...props.style,
      display: 'flex',
      width: '100%',
    };
  };

  const getCalendarHeaderStyle = () => {
    const { calendarHeaderStyle } = props;
    return {
      ...calendarHeaderStyle,
      overflow: 'hidden',
    };
  };

  let calendarHeaders = [];
  const children = Array.isArray(props.children) ? props.children.filter(c => c) : [props.children];
  React.Children.map(children, child => {
    calendarHeaders.push(child);
  });

  const refHandler = el => {
    scrollHeaderRef.current = el;
    props.scrollRef(el);
  };

  return (
    <div data-testid="headerRootDiv" style={getRootStyle()}>
      <SidebarHeader />
      <CalendarHeaderContainer ref={refHandler} style={getCalendarHeaderStyle()} data-testid="headerContainer">
        {calendarHeaders}
      </CalendarHeaderContainer>
    </div>
  );
};

export default TimelineHeaders;
