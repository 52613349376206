import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import App from 'antd/es/app';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import TemplateChoiceModalTemplate from './TemplateChoiceModalTemplate';
import useApi from '../../hooks/useApi';
import SmallPageSearch from '../page/SmallPageSearch';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }

  i {
    margin-top: -3px;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-top: -1px;
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
`;

const Header = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 16px;
`;

const TemplateList = styled.div`
  display: inline;
`;

const NoTemplates = styled.div`
  width: 100%;
  text-align: center;
  padding: 56px 0px;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e0e0e0;
`;

const TemplateChoiceModal = ({ closeModal, vessel, chooseTemplate, createEmpty }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [search, setSearch] = useState('');

  const params = {
    limit: 100,
    sort: 'name',
    select: 'only_non_automated',
  };

  const { loading, data, error } = useApi('get', 'invoicing/v2/templates', params);

  let templates = error || !data ? [] : data.results ? data.results.templates : [];

  if (error) {
    message.error(error);
  }

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const templateList = templates.filter(temp => {
    return (
      temp.customer?.name?.toUpperCase().includes(search.toUpperCase()) ||
      temp.customer?.customer_number?.toUpperCase().includes(search.toUpperCase()) ||
      temp.tax_class_name?.toUpperCase().includes(search.toUpperCase()) ||
      temp.name?.toUpperCase().includes(search.toUpperCase())
    );
  });

  const onTemplateChoice = template => {
    const modifiedTemplate = { ...template };
    modifiedTemplate.template_rows = template.template_rows.filter(r => r.product_code !== 'POLO_AUTOMATIC_CARGO');

    chooseTemplate(modifiedTemplate);
  };

  return (
    <Modal
      title={
        vessel
          ? t('Create new invoice for {{vessel_name}}', { vessel_name: vessel.vessel_name })
          : t('Create new invoice')
      }
      open={true}
      width={700}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <ModalInnerInput>
        <HeaderContainer>
          <Header>{t('Templates')}</Header>
          <SmallPageSearch
            value={search}
            placeholder={t('Search')}
            onChange={handleSearchChange}
            style={{ minWidth: '222px' }}
          />
        </HeaderContainer>
        <ScrollContainer>
          {!loading ? (
            templateList?.length ? (
              <TemplateList>
                {templateList.map(template => (
                  <TemplateChoiceModalTemplate
                    key={template.id}
                    chooseTemplate={onTemplateChoice}
                    template={template}
                  />
                ))}
              </TemplateList>
            ) : (
              <NoTemplates>{t('No available templates.')}</NoTemplates>
            )
          ) : (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          )}
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight onClick={() => createEmpty()}>
            <Icon type={'plus'} /> {t('Create new empty invoice')}
          </ButtonLight>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default TemplateChoiceModal;
