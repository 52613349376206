import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT } from '../../utils/constants';

import useApi from '../../hooks/useApi';

import List from '../../components/ui/List';
import FleetFilterContainer from '../filter/FleetFilterContainer';
import { FleetFilteringContext } from '../../context/FleetFilteringContext';
import FleetFilterSearch from '../filter/FleetFilterSearch';
import FleetDataColumnSelect from './FleetDataColumnSelect';
import { buildQuery } from '../filter/fleetFilterHelpers';
import DateComponent from '../ui/DateComponent';

const { RangePicker } = DatePicker;

const FleetDataViewContainer = styled.div`
  width: 100%;
  margin: 0 12px;
`;

const TopPanel = styled.div`
  height: 60px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ExportAndFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
`;

const AddItemButton = styled.div`
  background-color: white;
  height: 32px;
  min-width: 120px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  margin-left: 8px;

  color: ${props => props.disabled && '#a8a8a8'};
  background-color: ${props => props.disabled && '#f5f5f5'};
  cursor: ${props => props.disabled && 'default'};

  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }

  i {
    margin-top: -2px;
    margin-right: 6px;
    margin-left: -4px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  line-height: 21px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
  width: 220px;
`;

const FleetDataView = () => {
  const { apiCall, namespace, useUserSocket } = useContext(UserContext);
  const { fleetFilter, formData } = useContext(FleetFilteringContext);
  const { t } = useTranslation(namespace);

  const [timeStart, setTimeStart] = useState(
    dayjs()
      .add(-7, 'days')
      .format()
  );
  const [timeEnd, setTimeEnd] = useState(dayjs().format());

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const columns = [
    {
      title: t("Vessel's name"), // eslint-disable-line quotes
      dataIndex: 'vesselName',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('Nationality'),
      dataIndex: 'nationality',
      key: 'nationality',
      sortableKey: 'nationality',
    },
    {
      title: t('Port Call ID'),
      dataIndex: 'master_id',
      key: 'master_id',
      sortableKey: 'master_id',
    },
    {
      title: t('Global port Call ID'),
      dataIndex: 'port_call_id',
      key: 'port_call_id',
      sortableKey: 'port_call_id',
    },
    {
      title: t('ETA'),
      dataIndex: 'eta',
      key: 'eta',
      sortableKey: 'eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ETD'),
      dataIndex: 'etd',
      key: 'etd',
      sortableKey: 'etd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ATA'),
      dataIndex: 'ata',
      key: 'ata',
      sortableKey: 'ata',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('ATD'),
      dataIndex: 'atd',
      key: 'atd',
      sortableKey: 'atd',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Agent'),
      dataIndex: 'agent',
      key: 'agent',
      sortableKey: 'agent',
    },
    {
      title: t('Port area'),
      dataIndex: 'portarea',
      key: 'portarea',
      sortableKey: 'portarea',
    },
    {
      title: t('Shipping company'),
      dataIndex: 'shipping_company',
      key: 'shipping_company',
      sortableKey: 'shipping_company',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('MMSI'),
      dataIndex: 'mmsi',
      key: 'mmsi',
      sortableKey: 'mmsi',
    },
    {
      title: t('Vessel type name'),
      dataIndex: 'vessel_type_name',
      key: 'vessel_type_name',
      sortableKey: 'vessel_type_name',
    },
    {
      title: t('Port to Visit'),
      dataIndex: 'locode',
      key: 'locode',
      sortableKey: 'locode',
    },
    {
      title: t('Previous port name'),
      dataIndex: 'previous_port_name',
      key: 'previous_port_name',
      sortableKey: 'previous_port_name',
    },
    {
      title: t('Next port name'),
      dataIndex: 'next_port_name',
      key: 'next_port_name',
      sortableKey: 'next_port_name',
    },
    {
      title: t('Data source'),
      dataIndex: 'data_source',
      key: 'data_source',
      sortableKey: 'data_source',
    },
    {
      title: t('Is cancelled'),
      dataIndex: 'isCancelled',
      key: 'is_cancelled',
      sortableKey: 'is_cancelled',
    },
  ];

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'eta',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const [visibleColumns, setVisibleColumns] = useState(columns);

  const getParamsInRightForm = useCallback(
    parameters => {
      const startTime = dayjs(timeStart).format('YYYY-MM-DDTHH:mm:ssZ');
      const endTime = dayjs(timeEnd).format('YYYY-MM-DDTHH:mm:ssZ');

      let { query } = buildQuery(formData, startTime, endTime);

      if (query?.conditions) {
        // Remove is_cancelled from query
        // TODO: maybe allow user to filter
        let entry = query.conditions.and?.find(entry => entry.type === 'is_cancelled');
        if (entry) {
          query.conditions.and.pop(entry);
        } else {
          entry = query.conditions.or?.find(entry => entry.type === 'is_cancelled');
          if (entry) {
            query.conditions.or.pop(entry);
          }
        }
      }

      let correctParams = {
        query: {
          ...query,
          ...(fleetFilter.query?.text ? { text: fleetFilter.query.text } : {}),
        },
        pagination: {
          limit: parameters.limit,
          offset: parameters.offset,
        },
      };

      if (parameters.sort) {
        let sortList = parameters.sort.split(' ');
        if (sortList.length === 1) {
          correctParams.order_by = [
            {
              field: sortList[0],
              order: 'asc',
            },
          ];
        } else {
          correctParams.order_by = [
            {
              field: sortList[0],
              order: 'desc',
            },
          ];
        }
      } else {
        correctParams.order_by = [
          {
            field: 'eta',
            order: 'asc',
          },
        ];
      }

      return correctParams;
    },
    [fleetFilter, formData, timeEnd, timeStart]
  );

  const { loading, data, error, fetchData } = useApi('post', 'fleet/search/portcall', getParamsInRightForm(newParams));

  const portcallChanged = useCallback(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [fetchData, newParams, getParamsInRightForm]);
  useUserSocket('fleet-portcalls-changed', portcallChanged);

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [fetchData, newParams, getParamsInRightForm]);

  let vessels = error || !data ? [] : data.results ? data.results.portcalls : [];
  let showableVessels = [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  showableVessels = vessels.map((v, index) => {
    let agent = v.data.agentInfo && v.data.agentInfo.length === 1 ? v.data.agentInfo[0].name : null;
    if (agent === null && v.data.agentInfo) {
      const agentData = v.data.agentInfo.find(a => a.role === 1);
      if (agentData) {
        agent = agentData.name;
      }
    }

    return {
      vesselName: v.data.vesselName,
      nationality: v.data.nationality,
      eta: v.eta,
      etd: v.etd,
      ata: v.ata,
      atd: v.atd,
      agent,
      portarea: v.portarea,
      shipping_company: v.shipping_company,
      port_call_id: v.port_call_id,
      master_id: v.master_id,
      imo: v.imo,
      mmsi: v.mmsi,
      vessel_type_name: v.data?.vesselInfo?.vesselTypeName,
      previous_port_name: v.data.previousPortName,
      next_port_name: v.data.nextPortName,
      data_source: v.data.dataSource,
      locode: v.locode,
      isCancelled: v.data.isCancelled.toString(),
      id: index,
    };
  });

  const exportButtonClick = async () => {
    const params = getParamsInRightForm(newParams);
    const response = await apiCall(
      'post',
      'fleet/search/portcall',
      {
        query: params.query,
        order_by: params.order_by,
        //pagination: params.pagination,
        format: {
          type: 'csv',
          fields: visibleColumns.map(c => c.key),
        },
      },
      null,
      'blob'
    );

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = `portcalls-${dayjs().format('Y-MM-DDTHH.mm.ss')}.csv`;
    if (response.headers) {
      const header = response.headers['Content-Disposition'] || response.headers['content-disposition'];
      if (header?.split('filename=')[1]) {
        fileName = header.split('filename=')[1];
      }
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const disabledDate = current => {
    const start = dayjs(timeStart);
    const end = dayjs(timeEnd);

    const tooLate = current.diff(start, 'days') > 28;
    const tooEarly = end.diff(current, 'days') > 28;

    return !!tooEarly || !!tooLate;
  };

  const updateCustomDates = dates => {
    const start = dayjs(dates[0]).startOf('day');
    const end = dayjs(dates[1]).endOf('day');

    setTimeStart(start.format());
    setTimeEnd(end.format());
  };

  const showableColumns = columns.filter(c => visibleColumns.some(c2 => c2.key === c.key));

  return (
    <FleetDataViewContainer>
      <TopPanel>
        <ExportAndFilterContainer>
          <AddItemButton onClick={exportButtonClick}>{t('Export into Excel')}</AddItemButton>
          <FleetDataColumnSelect
            allColumns={columns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
          <DateContainer>
            <RangePicker
              format={'DD.MM.YYYY'}
              allowClear={false}
              value={[dayjs(timeStart), dayjs(timeEnd)]}
              onChange={dates => updateCustomDates(dates)}
              disabledDate={disabledDate}
            />
          </DateContainer>
          <FleetFilterContainer containerId="fleet-filter-in-data-view" />
        </ExportAndFilterContainer>
        <FleetFilterSearch />
      </TopPanel>
      <List
        rowKey="id"
        columns={showableColumns}
        dataSource={showableVessels}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        hideSearch
      />
    </FleetDataViewContainer>
  );
};

export default FleetDataView;
