import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FleetViewContext } from '../../../context/FleetViewContext';
import { UserContext } from '../../../context/UserContext';

import Icon from '../../ui/Icon';
import Tooltip from 'antd/es/tooltip';
import WeatherIcon from '../../ui/WeatherIcon';
import { getWindType } from '../../weather/weatherUtils';

const SidebarContainer = styled.div`
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px -1px 1px 0px rgb(0 0 0 / 20%);
  margin-right: 2px;
`;

const SidebarLine = styled.div`
  padding: 0 4px 0 0;
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SidebarPort = styled.div`
  padding: 0px 4px 6px 0px;
`;

const SidebarArea = styled.div`
  padding-top: 6px;
`;

const AreaName = styled.div`
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  color: black;
  font-weight: 700;
  margin-bottom: 6px;
  font-size: ${props => (props.rowHeight > 20 ? 14 : 12)}px;
  text-align: left;
  text-wrap: nowrap;
  height: 20px;
`;

const Name = styled.div`
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  color: black;
  font-weight: 600;
  font-size: ${props => (props.rowHeight > 20 ? 14 : 12)}px;
  display: flex;
  justify-content: flex-end;
`;

const NameWithBerths = styled(Name)`
  text-align: left;
  justify-content: flex-start;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #747d7d;
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
  line-height: ${props => (props.rowHeight > 20 ? 22 : 14)}px;
  white-space: nowrap;
`;

const Bold = styled.div`
  font-weight: 700;
  margin-left: 4px;
  color: #4a4a4a;
`;

const MapLink = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #4990dd;
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
  cursor: pointer;
  line-height: ${props => (props.rowHeight > 20 ? 22 : 14)}px;
  margin-right: 6px;
  margin-top: ${props => (props.rowHeight > 20 ? -1 : 0)}px;
  width: 19px;
`;

const MapLinkWithBerths = styled(MapLink)`
  justify-content: flex-start;
  margin-right: 0px;
`;

const NameText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 19px);
`;

const StyledIcon = styled(Icon)`
  margin-left: 2px;
  color: #d0011c;

  svg {
    height: 11px;
    width: 11px;
    margin-top: ${props => (props.rowHeight > 20 ? -2 : -3)}px;
  }
`;

const Incidents = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #747d7d;
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
  line-height: ${props => (props.rowHeight > 20 ? 22 : 14)}px;
  cursor: pointer;

  &:hover {
    color: #4a4a4a;
  }
`;

const IncidentsWithBerths = styled(Incidents)`
  justify-content: flex-start;
`;

const Weather = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #747d7d;
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
  line-height: ${props => (props.rowHeight > 20 ? 22 : 14)}px;
`;

const IncidentAmount = styled.div`
  display: flex;
  color: #747d7d;
  font-size: ${props => (props.rowHeight > 20 ? 11 : 9)}px;
  line-height: ${props => (props.rowHeight > 20 ? 14 : 11)}px;
  height: ${props => (props.rowHeight > 20 ? 14 : 12)}px;
  width: ${props => (props.rowHeight > 20 ? 14 : 12)}px;
  border-radius: 50%;
  background-color: ${props => (props.red ? '#D0011C' : '#747d7d')};
  color: white;
  justify-content: center;
  font-weight: 700;
  margin-left: 4px;
  margin-top: ${props => (props.rowHeight > 20 ? 5 : 2)}px;
`;

const Rows = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 1px 6px;
  border-radius: 3px;
`;

const WindInfo = styled.div`
  color: #4a4a4a;
  margin-left: 6px;
  font-weight: 600;
  margin-right: 12px;
`;

const Value = styled.div`
  color: #4a4a4a;
  margin-left: 3px;
  font-weight: 600;
  color: ${props => props.red && '#D0011C'};
`;

const StyledWeatherIcon = styled(WeatherIcon)`
  svg {
    width: ${props => (props.rowHeight > 20 ? 15 : 13)}px;
    margin-top: ${props => (props.rowHeight > 20 ? -3 : -5)}px;
  }
`;

const BerthContainer = styled.div`
  width: 100%;
`;

const BerthRow = styled.div`
  width: 100%;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: ${props => props.rowHeight}px;
  line-height: ${props => (props.defaultRowHeight > 20 ? props.defaultRowHeight - 6 : props.defaultRowHeight)}px;
  font-size: ${props => (props.defaultRowHeight > 20 ? 13 : 11)}px;
`;

const Containers = styled.div`
  width: 100%;
  display: flex;
`;

const InfoContainer = styled.div`
  width: 106px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 3px;
  padding: 4px;
  height: fit-content;
  margin-right: 4px;
  margin-top: ${props => (props.rowHeight > 20 ? 6 : 0)}px;
`;

const BerthName = styled.span`
  text-transform: capitalize;
`;

export const Sidebar = () => {
  const { namespace, apiCall, modules, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();

  const { rowHeight, ports = {}, sidebarWidth, sidebarSelection, portLocodes } = useContext(FleetViewContext);

  const [currentPortData, setCurrentPortData] = useState({});
  const [incidentData, setIncidentData] = useState({});

  const [weatherData, setWeatherData] = useState({});

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const sidebarStyle = {
    width: `${sidebarWidth}px`,
    minHeight: `${outerComponentHeight}px`,
  };

  const showOnMap = useCallback(
    name => {
      history.push('/map?port=' + name);
    },
    [history]
  );

  useEffect(() => {
    const getWeatherData = async () => {
      const result = await apiCall('post', 'fleet/observed-marine-weather', {
        locodes: portLocodes,
      });

      if (result?.status === 200 && result?.data) {
        let weatherDatas = {};
        result.data.forEach(d => {
          weatherDatas[Object.keys(d)[0]] = {
            seaLevel:
              d[Object.keys(d)[0]]?.['sea-level']?.fi_preferred_measurement === 'n2000'
                ? d[Object.keys(d)[0]]?.['sea-level']?.observation?.n2000
                : d[Object.keys(d)[0]]?.['sea-level']?.observation?.mw,
            seaLevelName:
              d[Object.keys(d)[0]]?.['sea-level']?.fi_preferred_measurement === 'n2000' ? t('N2000') : t('MW'),
            windSpeed: d[Object.keys(d)[0]]?.weather?.wind_speed,
            windDirection: d[Object.keys(d)[0]]?.weather?.wind_direction,
          };
        });
        setWeatherData(weatherDatas);
      }
    };

    if (portLocodes?.length) {
      getWeatherData();
    }
  }, [portLocodes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getIncidentData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useUserSocket('incidents-changed', () => getIncidentData());

  const getIncidentData = useCallback(async () => {
    const result = await apiCall('get', 'incidents', {
      limit: 500,
      offset: 0,
      sort: 'date DESC',
      search: '',
      status: 'open',
    });

    const incidentEventData = {};
    result?.data?.results?.incidents?.forEach(item => {
      let portname = item.port_name.replace('Port of ', '');

      if (incidentEventData[portname]) {
        if (item.read) {
          incidentEventData[portname].read += 1;
        } else {
          incidentEventData[portname].unread += 1;
        }
      } else {
        if (item.read) {
          incidentEventData[portname] = {
            read: 1,
            unread: 0,
          };
        } else {
          incidentEventData[portname] = {
            read: 0,
            unread: 1,
          };
        }
      }
    });

    if (mounted.current) {
      setIncidentData(incidentEventData);
    }
  }, [apiCall]);

  useEffect(() => {
    getCurrentData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentData = useCallback(async () => {
    const fourteenDaysAgo = dayjs().subtract(14, 'days');

    // eslint-disable-next-line no-unused-vars
    const result = await apiCall('post', 'fleet/search/portcall,manual-portcall', {
      query: {
        conditions: {
          and: [
            {
              type: 'eta',
              operator: 'gte',
              value: fourteenDaysAgo.format('YYYY-MM-DDTHH:mm:ssZ'),
            },
            {
              type: 'is_cancelled',
              operator: 'is',
              value: false,
            },
            {
              type: 'departed',
              operator: 'is',
              value: false,
            },
          ],
        },
      },
      order_by: [
        {
          field: 'eta',
          order: 'asc',
        },
      ],
      group_by: 'locode',
    });

    const portData = {};
    Object.entries(result?.data?.results?.portcalls || {}).forEach(([locode, item]) => {
      const inPort = item.filter(i => i.ata && !i.atd);
      const expected = item.filter(i => !i.ata);

      portData[locode] = {
        in_port: inPort,
        expected: expected,
      };
    });

    if (mounted.current) {
      setCurrentPortData(portData);
    }
  }, [apiCall]);

  const portcallChanged = useCallback(() => {
    getCurrentData();
  }, [getCurrentData]);
  useUserSocket('fleet-portcalls-changed', portcallChanged);

  const goToIncidents = useCallback(
    port => {
      history.push('/incidents-and-events?search=' + port);
    },
    [history]
  );

  const getAreaLines = useMemo(
    () =>
      Object.entries(ports).map(([areaName, ports], index) => {
        const portLines = ports.map((port, index) => {
          const portsStyle = {
            width: `${sidebarWidth}px`,
            height: `${Math.max(port.row_count, 4) * rowHeight + (sidebarSelection === 'port' ? 12 : 24)}px`,
          };

          const seaLevel = weatherData?.[port.locode]?.seaLevel;
          const seaLevelName = weatherData?.[port.locode]?.seaLevelName;
          const windSpeed = weatherData?.[port.locode]?.windSpeed;
          const windDirection = weatherData?.[port.locode]?.windDirection;

          const portCountData = currentPortData[port.locode];
          return (
            <SidebarPort key={`sidebar-port-${index}`} style={portsStyle}>
              {sidebarSelection === 'port' && (
                <SidebarLine>
                  <Name rowHeight={rowHeight}>
                    {modules?.map_module === 'enabled' && (
                      <Tooltip
                        title={t('Open in map')}
                        color="white"
                        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a' }}
                      >
                        <MapLink onClick={() => showOnMap(port.port_name)} rowHeight={rowHeight}>
                          <Icon type="map" style={{ marginLeft: '6px' }} />
                        </MapLink>
                      </Tooltip>
                    )}
                    <NameText>{port.port_name}</NameText>
                  </Name>
                  <Rows>
                    {port.port_name !== 'Anchorage' ? (
                      <Row rowHeight={rowHeight}>
                        {t('At berth')}: <Bold>{portCountData ? portCountData.in_port.length : 0}</Bold>
                      </Row>
                    ) : (
                      <Row rowHeight={rowHeight}>
                        {t('Anchored')}: <Bold>{portCountData ? portCountData.in_port.length : 0}</Bold>
                      </Row>
                    )}
                    <Row rowHeight={rowHeight} style={{ marginLeft: '6px' }}>
                      {t('Arriving')}: <Bold>{portCountData ? portCountData.expected.length : 0}</Bold>
                    </Row>
                  </Rows>
                  <Weather rowHeight={rowHeight}>
                    <StyledWeatherIcon
                      type={windSpeed || windSpeed === 0 ? getWindType(windSpeed) : null}
                      rotation={windDirection || 0}
                      rowHeight={rowHeight}
                    />
                    <WindInfo>{windSpeed || windSpeed === 0 ? Math.round(windSpeed) : '-'} m/s</WindInfo>
                    {seaLevelName}
                    <Value red={seaLevel < 0}>
                      {seaLevel || '-'} {t('cm')}
                    </Value>
                    {seaLevel < 0 && <StyledIcon type="exclamation-triangle" />}
                  </Weather>
                  <Incidents rowHeight={rowHeight} onClick={() => goToIncidents(port.port_name)}>
                    {t('Incidents and events')}{' '}
                    <IncidentAmount red={incidentData[port.port_name]?.unread > 0} rowHeight={rowHeight}>
                      {incidentData[port.port_name]?.unread > 0
                        ? incidentData[port.port_name]?.unread
                        : incidentData[port.port_name]?.read || 0}
                    </IncidentAmount>
                  </Incidents>
                </SidebarLine>
              )}
              {sidebarSelection === 'both' && (
                <SidebarLine>
                  <NameWithBerths rowHeight={rowHeight}>
                    {modules?.map_module === 'enabled' && (
                      <Tooltip
                        title={t('Open in map')}
                        color="white"
                        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a' }}
                      >
                        <MapLinkWithBerths onClick={() => showOnMap(port.port_name)} rowHeight={rowHeight}>
                          <Icon type="map" />
                        </MapLinkWithBerths>
                      </Tooltip>
                    )}
                    <NameText>{port.port_name}</NameText>
                  </NameWithBerths>
                  <Containers>
                    <InfoContainer rowHeight={rowHeight}>
                      <Weather rowHeight={0} style={{ display: 'inline-block' }}>
                        <div style={{ display: 'flex' }}>
                          <StyledWeatherIcon
                            type={windSpeed || windSpeed === 0 ? getWindType(windSpeed) : null}
                            rotation={windDirection || 0}
                            rowHeight={0}
                          />
                          <WindInfo>{windSpeed || windSpeed === 0 ? Math.round(windSpeed) : '-'} m/s</WindInfo>
                        </div>
                        <div style={{ display: 'flex', marginTop: '2px', whiteSpace: 'nowrap' }}>
                          {seaLevelName}
                          <Value red={seaLevel < 0}>
                            {seaLevel || '-'} {t('cm')}
                          </Value>
                          {seaLevel < 0 && <StyledIcon type="exclamation-triangle" />}
                        </div>
                      </Weather>
                      <IncidentsWithBerths rowHeight={0} onClick={() => goToIncidents(port.port_name)}>
                        {t('Incidents')}{' '}
                        <IncidentAmount red={incidentData[port.port_name]?.unread > 0} rowHeight={0}>
                          {incidentData[port.port_name]?.unread > 0
                            ? incidentData[port.port_name]?.unread
                            : incidentData[port.port_name]?.read || 0}
                        </IncidentAmount>
                      </IncidentsWithBerths>
                    </InfoContainer>
                    <BerthContainer rowHeight={rowHeight} style={{ width: 'calc(100% - 104px)' }}>
                      {port.berthList.map((r, index) => (
                        <Tooltip
                          key={index}
                          title={<BerthName>{r.berth_code.toLowerCase()}</BerthName>}
                          color="white"
                          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a' }}
                        >
                          <BerthRow defaultRowHeight={rowHeight} rowHeight={rowHeight * r.row_count}>
                            {<BerthName>{r.berth_code.toLowerCase()}</BerthName>}
                          </BerthRow>
                        </Tooltip>
                      ))}
                    </BerthContainer>
                  </Containers>
                </SidebarLine>
              )}
              {sidebarSelection === 'berths' && (
                <SidebarLine>
                  <NameWithBerths rowHeight={rowHeight}>
                    {modules?.map_module === 'enabled' && (
                      <Tooltip
                        title={t('Open in map')}
                        color="white"
                        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a' }}
                      >
                        <MapLinkWithBerths onClick={() => showOnMap(port.port_name)} rowHeight={rowHeight}>
                          <Icon type="map" />
                        </MapLinkWithBerths>
                      </Tooltip>
                    )}
                    <NameText>{port.port_name}</NameText>
                  </NameWithBerths>

                  <BerthContainer rowHeight={rowHeight}>
                    {port.berthList.map((r, index) => (
                      <Tooltip
                        key={index}
                        title={<BerthName>{r.berth_code.toLowerCase()}</BerthName>}
                        color="white"
                        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a' }}
                      >
                        <BerthRow defaultRowHeight={rowHeight} rowHeight={rowHeight * r.row_count}>
                          {<BerthName>{r.berth_code.toLowerCase()}</BerthName>}
                        </BerthRow>
                      </Tooltip>
                    ))}
                  </BerthContainer>
                </SidebarLine>
              )}
            </SidebarPort>
          );
        });

        return (
          <SidebarArea key={`sidebar-area-${index}`}>
            <AreaName rowHeight={rowHeight}>{areaName}</AreaName>
            {portLines}
          </SidebarArea>
        );
      }),
    [
      ports,
      rowHeight,
      sidebarWidth,
      sidebarSelection,
      weatherData,
      currentPortData,
      modules.map_module,
      t,
      incidentData,
      showOnMap,
      goToIncidents,
    ]
  );

  return <SidebarContainer style={sidebarStyle}>{getAreaLines}</SidebarContainer>;
};
