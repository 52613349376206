import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import MultiSelection from './MultiSelection';

const ScrollElementContainer = styled.div`
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
`;

const scrollAreaLimit = 100;

const ScrollElement = ({ width, draggingArea, disableScrolling, noScroll, multiselect = false, ...props }) => {
  const { user, modules } = useContext(UserContext);
  const scrollComponent = useRef();

  const [leftScroll, setLeftScroll] = useState(0);

  const [timer1On, setTimer1On] = useState(false);
  const [timer2On, setTimer2On] = useState(false);
  let timeInterval1 = useRef();
  let timeInterval2 = useRef();

  useEffect(() => {
    if (noScroll) {
      return;
    }

    const scrollLeft = () => {
      const scrollX = -20;
      props.scrollByDrag(scrollX);
    };

    const scrollRight = () => {
      const scrollX = 20;
      props.scrollByDrag(scrollX);
    };

    if (!draggingArea && (timer1On || timer2On)) {
      setTimer1On(false);
      setTimer2On(false);
      clearInterval(timeInterval1.current);
      clearInterval(timeInterval2.current);
    } else if (draggingArea) {
      let middle =
        (draggingArea.left + draggingArea.right) / 2 + (draggingArea.new ? props.scrollLeft : 0) + props.draggingValue;

      if (middle < props.scrollLeft + scrollAreaLimit) {
        if (!timer1On) {
          setTimer1On(true);
          timeInterval1.current = setInterval(scrollLeft, 100);

          setTimer2On(false);
          clearInterval(timeInterval2.current);
        }
      } else if (middle > props.scrollLeft + width - scrollAreaLimit) {
        if (!timer2On) {
          setTimer2On(true);
          timeInterval2.current = setInterval(scrollRight, 100);

          setTimer1On(false);
          clearInterval(timeInterval1.current);
        }
      } else {
        setTimer1On(false);
        setTimer2On(false);
        clearInterval(timeInterval1.current);
        clearInterval(timeInterval2.current);
      }
    }
  }, [draggingArea, noScroll, props, props.scrollLeft, timer1On, timer2On, width]);

  useEffect(() => {
    return () => {
      clearInterval(timeInterval1.current);
      clearInterval(timeInterval2.current);
    };
  }, []);

  const handleScroll = () => {
    const scrollX = scrollComponent.current.scrollLeft;
    props.onScroll(scrollX);
    setLeftScroll(scrollX);
  };

  const refHandler = el => {
    scrollComponent.current = el;
    props.scrollRef(el);
  };

  const { children } = props;

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  const scrollComponentStyle = {
    width: `${width - (props.scrollbarWidth || 0)}px`,
    position: 'relative',
    overflowX: draggingArea || disableScrolling ? 'hidden' : 'scroll',
    minHeight: `${outerComponentHeight}px`,
  };

  return (
    <ScrollElementContainer
      id="scroll-element"
      ref={refHandler}
      data-testid="scroll-element"
      style={scrollComponentStyle}
      onScroll={handleScroll}
    >
      {multiselect &&
        modules.queue_module === 'enabled' &&
        user.permissions.includes('manage_queue_slot_reservation') && (
        <MultiSelection
          leftScroll={leftScroll}
          scrollElementScrollTop={props.scrollElementScrollTop}
          scrollHeight={props.scrollHeight}
        />
      )}
      {children}
    </ScrollElementContainer>
  );
};

export default ScrollElement;
