import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Popover from 'antd/es/popover';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import InnerInvoiceModal from './InnerInvoiceModal';
import Icon from '../ui/Icon';
import { debounce } from 'throttle-debounce';

const ModalInner = styled.div`
  position: relative;
  overflow-x: auto;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;

  .ant-radio-disabled + span {
    color: #4a4a4a;
  }
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
  padding: 0 0 0 18px;
  min-width: 1000px;
`;

const TemplateContainer = styled.div`
  display: inline-block;
  width: calc(100% + 18px);
  background-color: #f0f0f0;
  margin-left: -18px;
  padding: 18px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  margin-bottom: 12px;
  width: 400px;
`;

const RowHeader = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
`;

const Required = styled.span`
  color: red;
`;

const ColorDivContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
`;

const ColorDiv = styled.div`
  width: 98px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  opacity: 40%;
`;

const IconContainer = styled.span`
  color: #4990dd;
  margin-left: 4px;

  i {
    width: 18px;
    height: 18px;
  }

  svg {
    margin-top: -2px;
  }
`;

const AutomatedInvoiceModal = ({ template, closeModal, readOnly, duplicateCurrent }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { defaultCurrency, invoiceLinks, daysWithoutHolidays, numbersInMonth, months } = useContext(
    NewInvoicingContext
  );

  const { message } = App.useApp();

  const getCombinedUnit = units => {
    let unitString = units[0].name;
    if (units[1]) {
      unitString += ' / ' + units[1].name;
    }

    return unitString;
  };

  const [rowInputChanged, setRowInputChanged] = useState(false);

  const [inputData, setInputData] = useState({
    name: template?.name,
    color: template?.color,
    timeframe: template?.automation?.timeframe || { from: null, to: null, at: null },
    trigger: {
      type: template?.automation?.trigger?.type,
      rule: template?.automation?.trigger?.rule,
    },
    links: template?.automation?.links || [],
    enabled: template?.automation?.enabled === false ? false : true,
    customer: template?.customer_number,
    customer_data: template?.customer,
    due_date_days: template?.due_date_days,
    currency: template?.currency_code || defaultCurrency || 'EUR',
    tax_class: template?.tax_class,
    description: template?.description,
    your_reference: template?.your_reference,
    rows:
      template?.template_rows?.map(r => {
        return {
          amounts: r.amounts?.length
            ? r.amounts
            : null ||
              r.product?.pricing?.units.map(u => {
                return {
                  amount: null,
                  unit_name: u.name,
                };
              }),
          product_data: r.product,
          info: r.product?.description,
          code: r.product_code,
          name: r.product?.name,
          price: r.calculated?.sum_excluding_vat,
          unit_amount: r.product?.pricing?.unit_price,
          combined_unit: getCombinedUnit(r.product?.pricing?.units),
          vat: r.product?.vat,
          timeframe: { from: null, to: null, at: null },
          modifications: r.modifiers.map(m => {
            return {
              code: m.modifier_code,
              name: m.modifier?.name,
              adjustment: m.modifier?.adjustment,
            };
          }),
        };
      }) || [],
  });

  const [totalWithoutTax, setTotalWithoutTax] = useState(template?.calculated?.total_sum_excluding_vat || 0);
  const [totalWithTax, setTotalWithTax] = useState(template?.calculated?.total_sum_including_vat || 0);

  const [sending, setSending] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const title = readOnly
    ? t('Automated invoice')
    : !template || duplicateCurrent
        ? t('Create new automated invoice')
        : t('Edit automated invoice');

  const dataIsValid = () => {
    return (
      inputData.name &&
      inputData.rows.length > 0 &&
      (inputData.trigger?.rule?.code || inputData.trigger?.rule?.type) &&
      inputData.tax_class &&
      inputData.currency
    );
  };

  const getTrigger = trigger => {
    if (trigger.type === 'link') {
      return {
        type: 'link',
        rule: {
          code: trigger.rule.code,
        },
      };
    }
    return trigger;
  };

  const sendData = async () => {
    setSending(true);
    let result;
    try {
      result = await apiCall(template && !duplicateCurrent ? 'put' : 'post', 'invoicing/v2/template', {
        name: inputData.name,
        id: template && !duplicateCurrent ? template.id : null,
        customer_number: inputData.customer,
        tax_class: inputData.tax_class,
        due_date_days: inputData.due_date_days,
        currency_code: inputData.currency,
        description: inputData.description || '',
        color: inputData.color || '',
        your_reference: inputData.your_reference || '',
        template_rows: inputData.rows.map(r => {
          return {
            product_code: r.code,
            amounts: r.amounts.filter(a => a.amount),
            modifiers: r.modifications.map(m => {
              return {
                modifier_code: m.code,
              };
            }),
          };
        }),
        automation: {
          enabled: inputData.enabled,
          timeframe: {
            from: inputData.timeframe.from
              ? dayjs(inputData.timeframe.from)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ssZ')
              : null,
            to: inputData.timeframe.to
              ? dayjs(inputData.timeframe.to)
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ssZ')
              : null,
            at: null,
          },
          trigger: getTrigger(inputData.trigger),
          links:
            inputData.links?.map(l => {
              return {
                code: l.code,
                parameter: l.parameter,
              };
            }) || [],
        },
      });
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal(true);
    } else {
      setSending(false);
    }
  };

  const getModifiedRows = useCallback(rows => {
    return rows.map(r => {
      return {
        timeframe: r.timeframe,
        product_code: r.code,
        amounts: r.amounts.map(a => {
          return {
            amount: !a.amount || a.amount === '' ? 0 : a.amount,
            unit_name: a.unit_name,
          };
        }),
        modifiers: r.modifications.map(m => {
          return {
            modifier_code: m.code,
          };
        }),
      };
    });
  }, []);

  const calculateRowPrices = useCallback(
    async rows => {
      setRowInputChanged(false);
      let dataToBeSent = {
        invoice_date: inputData.invoice_date ? dayjs(inputData.invoice_date).format('YYYY-MM-DD') : null,
        shipping_date: inputData.shipping_date ? dayjs(inputData.shipping_date).format('YYYY-MM-DD') : null,
        tax_class: inputData.tax_class,
        invoice_rows: getModifiedRows(rows),
      };

      let result;
      try {
        result = await apiCall('post', 'invoicing/v2/calculate-invoice', dataToBeSent);
      } catch (e) {
        message.error('Calculating prices failed', e);
      }
      if (result?.data?.invoice && result.status === 200) {
        setTotalWithTax(result.data.invoice.calculated?.total_sum_including_vat);
        setTotalWithoutTax(result.data.invoice.calculated?.total_sum_excluding_vat);
        setInputData(data => {
          return {
            ...data,
            rows: rows.map((r, i) => {
              return {
                ...r,
                price: result.data.invoice.invoice_rows[i].calculated.sum_excluding_vat,
                info: result.data.invoice.invoice_rows[i].calculated.description,
                used_timeframe: result.data.invoice.invoice_rows[i].calculated.used_timeframe,
                vat: result.data.invoice.invoice_rows[i].calculated.vat,
                used_modifications: result.data.invoice.invoice_rows[i].calculated.used_modifiers,
                unused_modifications: result.data.invoice.invoice_rows[i].calculated.unused_modifiers,
              };
            }),
          };
        });
      }
    },
    [apiCall, getModifiedRows, inputData.invoice_date, inputData.shipping_date, inputData.tax_class, message]
  );

  const debouncedCalculate = useMemo(() => debounce(800, calculateRowPrices), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputData.rows.length && rowInputChanged) {
      debouncedCalculate(inputData.rows);
    }
    if (inputData.rows.length === 0) {
      setTotalWithTax(0);
      setTotalWithoutTax(0);
    }
  }, [inputData.rows, rowInputChanged, inputData.invoice_date, inputData.shipping_date]); // eslint-disable-line react-hooks/exhaustive-deps

  const setTrigger = value => {
    let newInputData = { ...inputData };
    newInputData.trigger.rule = { code: value.target.value };
    newInputData.trigger.type = 'link';
    setInputData(newInputData);
  };

  const setPeriodicTrigger = value => {
    let newInputData = { ...inputData };
    newInputData.trigger.rule = {
      type: value.target.value,
    };
    newInputData.trigger.type = 'periodic';
    setInputData(newInputData);
  };

  const handleDaySelectChange = value => {
    let newInputData = { ...inputData };
    newInputData.trigger.rule.value = { day_name: value };
    setInputData(newInputData);
  };

  const handleDayNumberSelectChange = value => {
    let newInputData = { ...inputData };
    newInputData.trigger.rule.value = { ...newInputData.trigger.rule.value, day_number: value };
    setInputData(newInputData);
  };

  const handleMonthNumberSelectChange = value => {
    let newInputData = { ...inputData };
    newInputData.trigger.rule.value = { ...newInputData.trigger.rule.value, month_number: value };
    setInputData(newInputData);
  };

  const updateName = value => {
    let newInputData = { ...inputData };
    newInputData.name = value.target.value;
    setInputData(newInputData);
  };

  const updateColor = value => {
    let newInputData = { ...inputData };
    newInputData.color = value.hex;
    setInputData(newInputData);
  };

  const updateTimeframeValue = (value, key) => {
    let newInputData = { ...inputData };
    newInputData.timeframe[key] = value;
    setInputData(newInputData);
  };

  const triggerList = invoiceLinks.find(i => i.parameter === 'automation_trigger')?.links || [];

  return (
    <Modal title={title} open={true} width={1100} onCancel={() => closeModal(false)} footer={null} maskClosable={false}>
      <ModalInnerInput>
        <ScrollContainer>
          <TemplateContainer>
            <Row>
              <RowContainer style={{ width: 'calc((100% - 154px) * 0.6)' }}>
                <RowHeader>
                  {t('Automated invoice name')}
                  <Required>*</Required>
                </RowHeader>
                {readOnly ? (
                  <Input placeholder={t('Name')} value={inputData.name} disabled={true} style={{ color: '#4a4a4a' }} />
                ) : (
                  <Input
                    placeholder={t('Name')}
                    value={inputData.name || ''}
                    style={{ color: '#4a4a4a' }}
                    onChange={e => updateName(e)}
                  />
                )}
              </RowContainer>
              <RowContainer style={{ marginLeft: '18px', width: '100px' }}>
                <Tooltip
                  placement="top"
                  title={t(
                    'You can choose the color that will be shown as the background for the invoice type. The opacity is automatically set to 40%.'
                  )}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <div style={{ display: 'flex' }}>
                    <RowHeader>{t('Color')}</RowHeader>
                    <IconContainer>
                      <Icon type="info" />
                    </IconContainer>
                  </div>
                </Tooltip>
                <Popover
                  content={
                    <SketchPicker color={inputData.color || ''} onChange={e => updateColor(e)} disableAlpha={true} />
                  }
                  trigger="click"
                >
                  <ColorDivContainer>
                    <ColorDiv style={{ backgroundColor: inputData.color?.length > 0 ? inputData.color : 'white' }} />
                  </ColorDivContainer>
                </Popover>
              </RowContainer>
              <RowContainer style={{ marginLeft: '18px', width: 'calc((100% - 154px) * 0.2)' }}>
                <RowHeader>{t('Valid from')}</RowHeader>
                {readOnly ? (
                  <Input
                    placeholder={t('Valid from')}
                    value={inputData.name}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <DatePicker
                    format={'DD.MM.YYYY'}
                    style={{
                      width: '100%',
                      color: '#4a4a4a',
                    }}
                    value={inputData?.timeframe?.from ? dayjs(inputData.timeframe.from) : null}
                    onChange={value => updateTimeframeValue(value ? value.format() : null, 'from')}
                    onSelect={value => updateTimeframeValue(value ? value.format() : null, 'from')}
                    placeholder={t('Valid from')}
                  />
                )}
              </RowContainer>
              <RowContainer style={{ marginLeft: '18px', width: 'calc((100% - 154px) * 0.2)' }}>
                <RowHeader>{t('Valid to')}</RowHeader>
                {readOnly ? (
                  <Input
                    placeholder={t('Valid to')}
                    value={inputData.name}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <DatePicker
                    format={'DD.MM.YYYY'}
                    style={{
                      width: '100%',
                      color: '#4a4a4a',
                    }}
                    value={inputData?.timeframe?.to ? dayjs(inputData.timeframe.to) : null}
                    onChange={value => updateTimeframeValue(value ? value.format() : null, 'to')}
                    onSelect={value => updateTimeframeValue(value ? value.format() : null, 'to')}
                    placeholder={t('Valid to')}
                  />
                )}
              </RowContainer>
            </Row>
            <Row>
              <RowContainer style={{ width: '100%' }}>
                <RowHeader>
                  {t('Trigger')}
                  <Required>*</Required>
                </RowHeader>
                <Radio.Group
                  onChange={e => {
                    if (['weekly', 'monthly', 'yearly'].includes(e.target.value)) {
                      setPeriodicTrigger(e);
                    } else {
                      setTrigger(e);
                    }
                  }}
                  value={
                    inputData?.trigger?.type === 'link'
                      ? inputData?.trigger?.rule?.code
                      : inputData?.trigger?.rule?.type
                  }
                  disabled={readOnly}
                  style={{ marginTop: '8px', marginLeft: '12px' }}
                >
                  {triggerList.map(l => (
                    <Radio key={l.code} value={l.code}>
                      {t(l.name)}
                    </Radio>
                  ))}
                  <Radio key={'weekly'} value={'weekly'}>
                    {t('Weekly')}
                  </Radio>
                  <Radio key={'monthly'} value={'monthly'}>
                    {t('Monthly')}
                  </Radio>
                  <Radio key={'yearly'} value={'yearly'}>
                    {t('Yearly')}
                  </Radio>
                </Radio.Group>
              </RowContainer>
            </Row>
            <Row>
              {inputData?.trigger?.type === 'periodic' && inputData?.trigger?.rule?.type === 'weekly' && (
                <RowContainer style={{ width: '260px', marginLeft: '12px' }}>
                  <RowHeader>
                    {t('Day of the week')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Day of the week')}
                      value={inputData?.trigger?.rule?.value?.day_name}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <Select
                      style={{ width: '260px' }}
                      value={inputData?.trigger?.rule?.value?.day_name}
                      options={daysWithoutHolidays}
                      onChange={value => handleDaySelectChange(value)}
                      disabled={readOnly}
                    />
                  )}
                </RowContainer>
              )}
              {inputData?.trigger?.type === 'periodic' && inputData?.trigger?.rule?.type === 'yearly' && (
                <RowContainer style={{ width: '260px', marginLeft: '12px' }}>
                  <RowHeader>
                    {t('Month')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Month')}
                      value={inputData?.trigger?.rule?.value?.month_number}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <Select
                      style={{ width: '260px' }}
                      value={inputData?.trigger?.rule?.value?.month_number}
                      options={months}
                      onChange={value => handleMonthNumberSelectChange(value)}
                      disabled={readOnly}
                    />
                  )}
                </RowContainer>
              )}
              {inputData?.trigger?.type === 'periodic' &&
                (inputData?.trigger?.rule?.type === 'monthly' || inputData?.trigger?.rule?.type === 'yearly') && (
                <RowContainer style={{ width: '260px', marginLeft: '12px' }}>
                  <RowHeader>
                    {t('Day number')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Day number')}
                      value={inputData?.trigger?.rule?.value?.day_number}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <Select
                      style={{ width: '260px' }}
                      value={inputData?.trigger?.rule?.value?.day_number}
                      options={numbersInMonth}
                      onChange={value => handleDayNumberSelectChange(value)}
                      disabled={readOnly}
                    />
                  )}
                </RowContainer>
              )}
            </Row>
          </TemplateContainer>
          <InnerInvoiceModal
            inputData={inputData}
            setInputData={setInputData}
            readOnly={readOnly}
            automated={true}
            linksAllowed={true}
            setRowInputChanged={setRowInputChanged}
            totalWithoutTax={totalWithoutTax}
            totalWithTax={totalWithTax}
          />
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons style={{ justifyContent: 'flex-end' }}>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons style={{ justifyContent: 'flex-end' }}>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default AutomatedInvoiceModal;
