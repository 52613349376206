import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import objectScan from 'object-scan';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export function getUniqueObjects(arr, comp) {
  const uniq = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
  return uniq;
}

export function formatTime(date, timeOnly = false) {
  const dateObj = new Date(date);

  let d = dateObj.getDate();
  let m = dateObj.getMonth() + 1;
  let y = dateObj.getFullYear();
  let hh = dateObj.getHours();
  let mm = dateObj.getMinutes();

  // TODO Better handling for timezones
  let tz = Math.abs(dateObj.getTimezoneOffset() / 60);

  let timeString = `${d}.${m}.${y} ${hh}:${mm < 10 ? '0' + mm : mm} (GMT+0${tz})`;

  if (timeOnly) {
    timeString = `${hh}:${mm < 10 ? '0' + mm : mm} (GMT+0${tz})`;
  }

  return timeString;
}

export function UpperCase(string) {
  return string.substr(0, 1).toUpperCase() + string.substr(1);
}

export function randomCode() {
  return Math.floor(Math.random() * 90000) + 10000;
}

export const setSentryUser = (sessionId, modules, session, user) => {
  // TODO: don't send Personally Identifiable Information to sentry
  Sentry.setUser({
    id: user.id,
    username: user.email,
    modules: modules,
    permissions: user.permissions,
    role: user.role,
    sessionId: sessionId,
    session: session,
  });
  // Due to bug in sentrys setUser, only id and username are sent,
  // so tet the user info as extra data
  Sentry.setExtra('userInfo', {
    id: user.id,
    username: user.email,
    modules: modules,
    permissions: user.permissions,
    role: user.role,
    sessionId: sessionId,
    session: session,
  });
};

export const getEnvironmentName = hostname => {
  const map = {
    'qa-rauma.portactivity.fi': 'qa-rauma',
    'rauma.portactivity.fi': 'prod-rauma',
    'qa-gavle.portactivity.se': 'qa-gavle',
    'gavle.portactivity.se': 'prod-gavle',
    'www.portactivity.se': 'prod-gavle',
    'portactivity.se': 'prod-gavle',
    'spia-staging.ddns.net': 'staging',
    'spia-testing.ddns.net': 'testing',
    'portactivity.fi': 'prod-fi',
    'qa-fintraffic.portactivity.fi': 'qa-fintraffic',
    'ft-testing.ddns.net': 'ft-testing',
  };
  if (map[hostname]) {
    return map[hostname];
  }
  return 'development';
};

export const isPortUnikie = namespace => {
  return namespace === 'unikie';
};

const areOptionsFilled = (obj, filters) => {
  let fillsOptions = true;
  Object.keys(filters).forEach(d => {
    const value = d === 'status' && obj[d] === 'done' ? 'departed' : obj[d];
    if (filters[d]?.length && !filters[d].find(o => o === value)) {
      fillsOptions = false;
    }
  });

  return fillsOptions;
};

export const filterTimestampsForBPT = (timestamps, filteringStorageData) => {
  if (!filteringStorageData || !timestamps) {
    return timestamps;
  }

  return timestamps.map(item => {
    let updatedItem = { ...item };
    updatedItem.grey = !areOptionsFilled(item.ship ? item.ship : item, filteringStorageData);
    return updatedItem;
  });
};

export const filterTimestamps = (timestamps, filteringStorageData) => {
  if (!filteringStorageData || !timestamps) {
    return timestamps;
  }

  return timestamps.filter(item => {
    let ship = item.ship ? item.ship : item;
    return areOptionsFilled(ship, filteringStorageData);
  });
};

export const filterTimestampsToImos = (timestamps, filteringStorageData) => {
  if (!filteringStorageData || !timestamps) {
    return null;
  }

  let nothingSelected = true;
  Object.keys(filteringStorageData).forEach(d => {
    if (filteringStorageData[d].length) {
      nothingSelected = false;
    }
  });

  if (!nothingSelected) {
    return timestamps
      .filter(item => {
        let ship = item.ship ? item.ship : item;
        return areOptionsFilled(ship, filteringStorageData);
      })
      .map(item => item.ship.imo);
  }

  return null;
};

export const filterVessels = (vessels, imos) => {
  if (!imos || !vessels) {
    return vessels;
  }

  return vessels.map(item => {
    let newItem = { ...item };
    let imo = newItem.properties.imo;
    newItem.grey = !imos.find(i => i === imo);

    return newItem;
  });
};

export const getTotalPortcallAmount = (portcalls, filteringStorageData) => {
  if (!portcalls) {
    return 0;
  }
  if (!filteringStorageData) {
    return portcalls.length;
  }

  return portcalls.filter(item => {
    let ship = item.ship;
    return areOptionsFilled(ship, filteringStorageData);
  }).length;
};

export const formatSender = ({ first_name, last_name, email }) => {
  if (email.match(/@api.key$/)) {
    return `${last_name}`;
  }
  return `${first_name} ${last_name} (${email})`;
};

export const toTitleCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const fromUnderscore = str => {
  let newStr = str.toLowerCase().split('_');
  let firstWord = newStr[0];
  newStr.splice(0, 1, firstWord[0].toUpperCase() + firstWord.substring(1));
  return newStr.join(' ');
};

export const toUnderscore = str => {
  return str
    .toLowerCase()
    .split(' ')
    .join('_');
};

export const getDuration = (duration, onlyTwo = false) => {
  if (!duration) {
    return '-';
  }

  let durationWithMoment = dayjs.duration(duration);
  let durationString = '';

  let count = 0;

  if (durationWithMoment.days() > 0) {
    durationString += durationWithMoment.days() + 'd ';

    if (onlyTwo) count++;
  }
  if (durationWithMoment.hours() > 0) {
    durationString += durationWithMoment.hours() + 'h ';

    if (onlyTwo) count++;
  }
  if (durationWithMoment.minutes() > 0 && count < 2) {
    durationString += durationWithMoment.minutes() + 'min';
  }

  return durationString;
};

export const findFromData = (needles, data) => {
  const find = objectScan(needles, {
    useArraySelector: false,
    orderByNeedles: true,
    rtn: ({ value }) => {
      /*
      if (value && value instanceof Date) {
        return dayjs(value); //.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      }*/
      return value;
    },
    filterFn: ({ value }) => !!(value && value !== null && value !== ' '),
  });

  let resArray = find(data);

  return resArray?.[0];
};

export const isEmailValid = email => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
};
