import Spin from 'antd/es/spin';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { InvoicingContext } from '../../context/InvoicingContext';
import SummaryItem from './SummaryItem';
import VesselNetTonnageSelector from './VesselNetTonnageSelector';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 650px) {
    display: inline-block;
  }
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -31px;

  @media (max-width: 650px) {
    margin-top: 0px;
    margin-bottom: 10px;
    flex-direction: row;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 650px) {
    gap: 12px;
  }
`;

const PricingSummary = () => {
  const { categories, selectedNetTonnage, categoryData, isLoading } = useContext(InvoicingContext);

  return (
    <Container>
      <SelectorContainer>
        <VesselNetTonnageSelector />
      </SelectorContainer>
      <SummaryContainer>
        {!isLoading ? (
          categoryData[selectedNetTonnage] &&
          Object.values(categories).map(category => (
            <SummaryItem
              data={categoryData[selectedNetTonnage][category.id]}
              color={category.color}
              key={category.id}
            />
          ))
        ) : (
          <LoaderContainer>
            <Spin size="large" />
          </LoaderContainer>
        )}
      </SummaryContainer>
    </Container>
  );
};

export default PricingSummary;
