import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import Modal from 'antd/es/modal';
import UniversalEmissionsTimePicker from '../statistics/UniversalEmissionsTimePicker';

const TimePicker = styled(ButtonLight)`
  min-width: 32px;
  width: 32px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  border: 1px solid #d0d0d0;
  background-color: white;
  color: #4a4a4a;
  background-color: ${props => props.disabled && '#d3d2d8 !important'};
  color: ${props => props.disabled && '#747D7D !important'};
  margin-right: 16px;

  &:hover {
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
    background-color: white;
  }
`;

const IconContainer = styled.div`
  margin-right: 0px;

  i {
    margin-top: 0px;
    margin-left: 5px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const Container = styled.div`
  padding: 12px;
  display: flex;
`;

const MobileCommonStatisticsTimePicker = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TimePicker onClick={() => setModalOpen(true)}>
        <IconContainer>
          <Icon type="calendar-clock" />
        </IconContainer>
      </TimePicker>

      {modalOpen && (
        <Modal
          open={modalOpen}
          closable={false}
          destroyOnClose={true}
          footer={null}
          style={{ position: 'absolute', top: '98px', right: '10px' }}
          styles={{ mask: { backgroundColor: 'transparent' } }}
          onCancel={() => setModalOpen(false)}
          width={290}
        >
          <Container>
            <UniversalEmissionsTimePicker />
          </Container>
        </Modal>
      )}
    </>
  );
};

export default MobileCommonStatisticsTimePicker;
