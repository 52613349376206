import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';

import { UserContext } from '../../context/UserContext';

import { headerHeight } from '../commonCalendar/utility/constants';
import { isMobile } from './utility/calendar';

const ScrollBar = styled.div`
  right: 0;
  top: ${props => (props.tallerHeader ? '130' : props.headerHeight) + 'px'};
  width: 4px;
  height: calc(100% - ${props => (props.tallerHeader ? '130' : props.headerHeight)}px - 10px);
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 200;
`;

const ScrollBarThumb = styled.div`
  right: -8px;
  width: 20px;
  height: 30px;
  background-color: blue;
  position: absolute;
  z-index: 201;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const Line = styled.div`
  width: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  height: 1px;
  left: 4px;
  position: absolute;
`;

const VerticalScrollBar = ({ scrollTop, scrollWithScrollbBar, scrollElementScrollTop }) => {
  const { modules } = useContext(UserContext);

  const [settingOk, setSettingOk] = useState(true);

  const draggableRef = useRef(null);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const scrollComponent = document.querySelector('#scroll-element');
  const outerComponent = document.querySelector('#outer-component');
  const scrollBar = document.querySelector('#vertical-scroll-bar');

  let listHeight = 0;
  let scrollbarHeight = 0;
  let outerComponentHeight = 0;

  if (scrollComponent && scrollBar && outerComponent) {
    listHeight = scrollComponent.offsetHeight;
    scrollbarHeight = scrollBar.offsetHeight;
    outerComponentHeight = outerComponent.offsetHeight;
  }

  const throttledSet = position => {
    if (settingOk) {
      const place = (listHeight - outerComponentHeight) * (position.y / (scrollbarHeight - 30));
      scrollWithScrollbBar(place);
    }

    setSettingOk(false);
    setTimeout(() => {
      if (mounted.current) {
        setSettingOk(true);
      }
    }, 100);
  };

  const onDrag = (e, position) => {
    throttledSet(position);
    e.stopPropagation();
  };

  const thumbPlacement =
    scrollbarHeight > 0 && listHeight > 0
      ? (scrollbarHeight - 30) * (scrollTop / (listHeight - outerComponentHeight))
      : 0;

  if (isMobile()) {
    return (
      <ScrollBar
        data-id="vertical-scroll-bar"
        id="vertical-scroll-bar"
        tallerHeader={modules.weather_module === 'enabled'}
        headerHeight={headerHeight}
      >
        <Draggable
          onDrag={onDrag}
          axis="y"
          bounds="parent"
          position={{ x: 0, y: thumbPlacement }}
          nodeRef={draggableRef}
        >
          <ScrollBarThumb scrollElementScrollTop={scrollElementScrollTop} ref={draggableRef}>
            <Line style={{ top: '10px' }} />
            <Line style={{ top: '15px' }} />
            <Line style={{ top: '20px' }} />
          </ScrollBarThumb>
        </Draggable>
      </ScrollBar>
    );
  } else {
    return null;
  }
};

export default VerticalScrollBar;
