import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, Tooltip, Popup, Polygon, Polyline } from 'react-leaflet';
import L from 'leaflet';

import { UserContext } from '../../context/UserContext';

import { getOptions } from './MapUtils';

const MapMarker = ({ feature, appliedZoomLevel }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();

  const getMarkerIcon = () => {
    let markerStyleClass = undefined;

    if (feature.properties.type) {
      switch (feature.properties.type) {
        case 'port':
          markerStyleClass = 'div-map-marker-port';
          break;
        case 'vis-sync-point':
          markerStyleClass = 'div-map-marker-vis-sync-point';
          break;
        case 'rta-point':
        case 'berth':
        case 'anchorage':
        default:
          markerStyleClass = 'div-map-marker-generic';
          break;
      }
    }
    const iconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallMarkerIconPixelSize
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? options.mediumMarkerIconPixelSize
          : options.largeMarkerIconPixelSize;

    return L.divIcon({
      html: ` <div class="${markerStyleClass}" style="background-color:${feature.properties.color};
          height:${iconSize}px;
          width:${iconSize}px;
          background-size:${iconSize - options.markerIconSpacingPixels}px;!important;"/>`,
      iconSize: [iconSize, iconSize],
      iconAnchor: [0, 0],
      className: 'map-marker-icon-class',
    });
  };

  const markerStyle = () => {
    return feature.style
      ? feature.style
      : feature.geometry.type === 'Polygon'
        ? options.polygonMarkerDefaultStyle
        : options.lineMarkerDefaultStyle;
  };

  if (feature.geometry.type === 'Polygon') {
    return (
      <Polygon pathOptions={markerStyle()} positions={L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates, 1)}>
        {!!feature.properties && !!feature.properties.name && (
          <Tooltip direction="top">
            <h4>{t(feature.properties.name)}</h4>
          </Tooltip>
        )}
        {!!feature.properties && !!feature.properties.name && !!feature.properties.description && (
          <Popup>
            <h3>{t(feature.properties.name)}</h3>
            <div>{t(feature.properties.description)}</div>
          </Popup>
        )}
      </Polygon>
    );
  } else if (feature.geometry.type === 'LineString') {
    return (
      <Polyline pathOptions={markerStyle()} positions={L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates, 0)}>
        {!!feature.properties && !!feature.properties.name && (
          <Tooltip direction="top">
            <h4>{t(feature.properties.name)}</h4>
          </Tooltip>
        )}
        {!!feature.properties && !!feature.properties.name && !!feature.properties.description && (
          <Popup>
            <h3>{t(feature.properties.name)}</h3>
            <div>{t(feature.properties.description)}</div>
          </Popup>
        )}
      </Polyline>
    );
  } else {
    return (
      <Marker icon={getMarkerIcon()} position={L.GeoJSON.coordsToLatLng(feature.geometry.coordinates)}>
        {!!feature.properties && !!feature.properties.name && (
          <Tooltip direction="top">
            <h4>{t(feature.properties.name)}</h4>
          </Tooltip>
        )}
        {!!feature.properties && !!feature.properties.name && !!feature.properties.description && (
          <Popup>
            <h3>{t(feature.properties.name)}</h3>
            <div>{t(feature.properties.description)}</div>
          </Popup>
        )}
      </Marker>
    );
  }
};

export default MapMarker;
