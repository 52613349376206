import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fromEvent } from 'file-selector';

import { UserContext } from '../../context/UserContext';

import App from 'antd/es/app';
import Spin from 'antd/es/spin';

import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import useApi from '../../hooks/useApi';

const Border = styled.div`
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  max-width: 400px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 16px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const BannerBg = styled.div`
  background-color: #f8f8f8;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  font-size: 13px;
  text-align: center;
  color: #010101;
  padding: 12px 6px;
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3px;
`;

const PortStamp = styled.div`
  position: relative;
  max-height: 160px;
  display: flex;
  justify-content: center;

  img {
    max-height: 160px;
  }
`;

const NoPortStamp = styled.div`
  position: relative;
  padding: 12px 0;
`;

const Text = styled.div`
  margin-top: 2px;
`;

const StyledButtonLight = styled(ButtonLight)`
  font-size: 13px;
  display: flex;
  padding: 4px 10px;
  height: 28px;
  min-width: 60px;
`;

const PortStampComponent = () => {
  const { apiCall, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [uploading, setUploading] = useState(false);

  const inputFile = useRef();

  const { message } = App.useApp();

  const { data, error, loading, fetchData } = useApi('get', 'binary-assets/port-stamp');

  const portStampData = !error && data ? data.base64 : null;

  const handleUpload = async data => {
    setUploading(true);

    if (data) {
      try {
        await apiCall('post', 'binary-assets/port-stamp', {
          content_type: 'png',
          stamp_base64: data.replace('data:image/png;base64,', ''),
        });
      } catch (e) {
        message.error(t('Could not upload port stamp: {{message}}', { message: e.message }));
        setUploading(false);
      }
      fetchData();
    }
    setUploading(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const uploadFile = async evt => {
    try {
      const files = await fromEvent(evt);
      if (files?.[0]) {
        getBase64(files[0]).then(data => handleUpload(data));
      }
    } catch (e) {
      message.error(t('Could not upload port stamp: {{message}}', { message: e.message }));
    }
  };

  const deletePortStamp = async () => {
    try {
      await apiCall('delete', 'binary-assets/port-stamp');
      fetchData();
    } catch (e) {
      message.error(t('Could not delete port stamp: {{message}}', { message: e.message }));
      throw e;
    }
  };

  return (
    <Border>
      <BannerBg />
      <Title>{t('Port stamp')}</Title>
      <Container>
        {loading || uploading ? (
          <Loader>
            <Spin size="small" />
          </Loader>
        ) : portStampData ? (
          <PortStamp>
            <img src={'data:image/jpeg;base64,' + portStampData} alt="Port stamp" />
          </PortStamp>
        ) : (
          <NoPortStamp>{t('No port stamp available.')}</NoPortStamp>
        )}
      </Container>
      {!!user.permissions.includes('manage_port_binary_assets') && (
        <UploadContainer>
          {portStampData ? (
            <>
              <StyledButtonLight cancel onClick={() => inputFile.current.click()} style={{ marginRight: '10px' }}>
                <Icon type="upload" style={{ fontSize: '20px' }} />
                <Text>{t('Upload')}</Text>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  accept=".png"
                  onChange={e => uploadFile(e)}
                  onClick={e => (e.target.value = null)}
                />
              </StyledButtonLight>
              <StyledButtonLight lightred onClick={() => deletePortStamp()}>
                <Icon type="trash" style={{ fontSize: '20px' }} />
                <Text>{t('Delete')}</Text>
              </StyledButtonLight>
            </>
          ) : (
            <StyledButtonLight cancel onClick={() => inputFile.current.click()}>
              <Icon type="upload" style={{ fontSize: '20px' }} />
              <Text>{t('Upload stamp')}</Text>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                accept=".png"
                onChange={e => uploadFile(e)}
                onClick={e => (e.target.value = null)}
              />
            </StyledButtonLight>
          )}
        </UploadContainer>
      )}
    </Border>
  );
};

export default PortStampComponent;
