import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import NotificationMessageListItem from './NotificationMessageListItem';
import NotificationReplyMessage from './NotificationReplyMessage';

const NotificationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  margin-bottom: 3px;
`;

const NotificationGroup = ({
  group,
  replyOpened,
  viewPortTop,
  viewPortBottom,
  moveList,
  scrolling,
  onRead,
  messageSent,
}) => {
  let ref = useRef();
  let topRef = useRef();
  let heightRef = useRef();

  useEffect(() => {
    if (
      topRef.current < viewPortTop &&
      heightRef.current !== ref.current.getBoundingClientRect().height &&
      ref.current.getBoundingClientRect().top > viewPortTop
    ) {
      moveList(ref.current.getBoundingClientRect().height);
    }

    topRef.current = ref.current.getBoundingClientRect().top;
    heightRef.current = ref.current.getBoundingClientRect().height;
  });

  function reverse(array) {
    return array.map((item, idx) => array[array.length - 1 - idx]);
  }

  const createChildMessages = children => {
    if (!children || children.length === 0) {
      return undefined;
    } else {
      return (
        <>
          {reverse(children).map(c => (
            <NotificationMessageListItem
              key={c.id}
              child={true}
              data={c}
              viewPortTop={viewPortTop}
              viewPortBottom={viewPortBottom}
              onRead={onRead}
            />
          ))}
        </>
      );
    }
  };

  return (
    <NotificationGroupContainer ref={ref}>
      <NotificationMessageListItem
        child={false}
        data={group}
        viewPortTop={viewPortTop}
        viewPortBottom={viewPortBottom}
        scrolling={scrolling}
        onRead={onRead}
        messageSent={messageSent}
      />
      {createChildMessages(group.children)}
      <NotificationReplyMessage
        data={group}
        childrenAvailable={!!group.children && group.children.length > 0}
        replyOpened={replyOpened}
        messageSent={messageSent}
      />
    </NotificationGroupContainer>
  );
};

export default NotificationGroup;
