import dayjs from 'dayjs';
import React, { createContext, useState } from 'react';

export const EmissionsContext = createContext();

export const EmissionsProvider = ({ children }) => {
  const [universalTimePickerOn, setUniversalTimePickerOn] = useState(false);
  const [universalTimeType, setUniversalTimeType] = useState('month');
  const [universalSelectedTime, setUniversalSelectedTime] = useState(dayjs().toDate());

  return (
    <EmissionsContext.Provider
      value={{
        universalTimePickerOn,
        setUniversalTimePickerOn,
        universalTimeType,
        setUniversalTimeType,
        universalSelectedTime,
        setUniversalSelectedTime,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};
