import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tabs from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';
import Spin from 'antd/es/spin';

import { UserContext } from '../../../context/UserContext';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';

import GeoAssetToolEditTab from './GeoAssetToolEditTab';
import GeoJsonTab from './GeoJsonTab';
import Icon from '../../ui/Icon';
import { fromUnderscore } from '../../../utils/utils';

import ButtonLight from '../../ui/ButtonLight';
import { mobilePixelMaxWidthLimit } from '../../../utils/constants';
import { AlertContext } from '../../../context/AlertContext';

const PanelWrapperRight = styled.div`
  top: ${props => props.mapMargin}px;
  min-width: ${props => (props.newAsset ? '325px' : null)};
  max-width: ${props => (props.newAsset ? '325px' : null)};
  width: 340px;
  height: calc(
    100vh - ${props => (props.map ? 204 : 155)}px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')}
  );
  min-height: auto;
  z-index: 1;
  background-color: white;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 35%);
  padding-left: 15px;
  overflow-y: hidden;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(
      100vh - ${props => (props.map ? 190 : 141)}px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')}
    );
  }

  .ant-tabs-tab {
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }
`;

const TabBody = styled.div`
  height: ${props => (props.mainMap ? 'calc(100vh - 248px)' : 'calc(100vh - 200px)')};
`;

const Common = styled.div`
  display: flex;
  padding: 0 10 0 0px;
  font-size: 13px;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 200px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: 400;
  line-height: 26px;
  display: flex;
`;

const TooltipValue = styled.div`
  font-weight: 600;
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 8px 8px 0px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const CloseIcon = styled.a`
  top: 6px;
  left: 6px;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.color.grey_dark};
  }
`;

const JsonWrapper = styled.pre`
  height: ${props => (props.mainMap ? 'calc(100vh - 316px)' : 'calc(100vh - 270px)')};
  overflow: overlay;
  padding: 0 15px 0 0;
`;

const ColorDiv = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  width: 45px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
`;

const BreakLine = styled.div`
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #d8d8d8;
  margin-right: 12px;
`;

const CustomHeader = styled.div`
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const TabContainer = styled.div`
  overflow-y: auto;
  height: calc(
    ${props => (props.mainMap ? '100vh - 310px' : '100vh - 264px')}
      ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')}
  );
`;

const AssetList = styled.div`
  overflow: auto;
  max-height: 630px;
  border: 1px solid #e8e8e8;
  margin-right: 12px;
`;

const ListDataItem = styled.div`
  background-color: ${props => props.index === 0 && '#f8f8f8'};
  padding: 9px;
  cursor: pointer;
`;

const Name = styled.div`
  font-weight: 600;
`;

const Info = styled.div`
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const InfoRow = styled.div`
  display: flex;
`;

const MapMarker = styled.div`
  cursor: pointer;

  svg {
    fill: #aeaeae;
    margin-top: -2px;
    height: 18px;

    &:hover {
      fill: #8e8e8e;
    }
  }
`;

const NameRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButtonLight = styled(ButtonLight)`
  position: absolute;
  bottom: 16px;
  right: 30px;
`;

const GeoAssetToolRightPanel = ({
  rightPanelOpen,
  rightPanelData,
  rightPanelListData,
  closeRightPanel,
  mapMargin,
  mainMap = false,
}) => {
  const { namespace } = useContext(UserContext);
  const {
    typeData,
    setGeoJsonLayerData,
    setGeoJsonLayerUpdate,
    geoJsonLayerUpdate,
    filterList,
    setUpdateMapCenter,
    openRightPanel,
  } = useContext(GeoAssetToolContext);

  const { alertsHeight } = useContext(AlertContext);

  const { t } = useTranslation(namespace);

  const [currentTab, setCurrentTab] = useState('1');

  useEffect(() => {
    setGeoJsonLayerData();
    setGeoJsonLayerUpdate(!geoJsonLayerUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (rightPanelOpen && rightPanelData?.properties?.length === 0) rightPanelData.properties = {};
    if (rightPanelOpen && rightPanelData?.style?.length === 0) rightPanelData.style = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData]);

  const propertiesFormatter = data => {
    if (!data || Object.keys(data).length === 0) {
      return {};
    }
    const properties = Object.keys(data.properties);
    if (properties) {
      for (let index = 0; index < properties.length; index++) {
        const propertie = properties[index];
        if (typeof data.properties[propertie] === 'object' && propertie !== 'center')
          data.properties[propertie] = JSON.stringify(data.properties[propertie], null, 4);
      }
    }
    return data;
  };

  const emptyCharacter = '‎';

  const createJsonFile = () => {
    const fileData = JSON.stringify(rightPanelListData);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'kokkola-geoasset-' + dayjs().format('YYYY-MM-DD') + '.json';
    link.href = url;
    link.click();
    link.remove();
  };

  const AssetInfo = () => {
    if (!rightPanelData || rightPanelData.newAsset) {
      return null;
    }

    const customData = Object.keys(rightPanelData?.properties || []);
    const styleFields = Object.keys(rightPanelData?.style || []);

    const CustomInformationFirst = customData.map((key, index) => {
      if (key === 'center') return;
      return (
        <Tooltip
          key={index + key + 'Tooltip'}
          title={key.length > 13 ? key : null}
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
          placement="left"
        >
          <RowHeaderForTooltip key={'CustomFirst ' + key + ' ' + index}>
            <CustomHeader>{fromUnderscore(t(key))}</CustomHeader>:
          </RowHeaderForTooltip>
        </Tooltip>
      );
    });

    const CustomInformationSecond = customData.map((key, index) => {
      if (key === 'center') return;
      let fieldContent =
        typeof rightPanelData.properties[key] === 'object'
          ? JSON.stringify(rightPanelData.properties[key]).replace(/\s/g, '')
          : rightPanelData.properties[key];

      return <TooltipValue key={'CustomSecond ' + key + ' ' + index}>{`${fieldContent}` || '-'}</TooltipValue>;
    });

    const StyleInformationFirst = styleFields.map((key, index) => {
      return <RowHeaderForTooltip key={'CustomFirst ' + key + ' ' + index}>{t(key)}:</RowHeaderForTooltip>;
    });

    const StyleInformationSecond = styleFields.map((key, index) => {
      if (key === 'color' || key === 'fillColor') {
        return (
          <ColorDiv
            key={'CustomSecondColor ' + key + ' ' + index}
            style={{ backgroundColor: rightPanelData.style[key] }}
          />
        );
      } else {
        return (
          <TooltipValue key={'CustomSecond ' + key + ' ' + index}>{`${rightPanelData.style[key]}` || '-'}</TooltipValue>
        );
      }
    });

    const assetsType = typeData[rightPanelData?.type_id];
    const CoordinateHeader =
      rightPanelData?.geo_data?.type === 'Point'
        ? 'Coordinates'
        : rightPanelData?.geo_data?.coordinates.map((key, index) => {
          return (
            <RowHeaderForTooltip key={'PointCoordinatesHeader ' + index}>
              {index === 0 ? 'Coordinates' : emptyCharacter}
            </RowHeaderForTooltip>
          );
        });

    const CoordinateData =
      rightPanelData?.geo_data?.type === 'Point' ? (
        <TooltipValue key={'PointCoordinateValue'}>
          {Number(rightPanelData.geo_data.coordinates[0].toFixed(4)) +
            ', ' +
            Number(rightPanelData.geo_data.coordinates[1].toFixed(4))}
        </TooltipValue>
      ) : (
        rightPanelData?.geo_data?.coordinates.map((data, index) => {
          return (
            <TooltipValue key={'PolyCoordinateValue ' + index}>
              {Number(data[0].toFixed(4)) + ', ' + Number(data[1].toFixed(4))}
            </TooltipValue>
          );
        })
      );

    const CommonInformation = (
      <>
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Asset')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Type')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Geo type')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Namespace')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Permissions')}:</RowHeaderForTooltip>
            {CustomInformationFirst}
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{rightPanelData?.name || '-'}</TooltipValue>
            <TooltipValue>{assetsType ? assetsType.name : '-'}</TooltipValue>
            <TooltipValue>{rightPanelData?.geo_data?.type || '-'}</TooltipValue>
            <TooltipValue>{rightPanelData?.namespace || '-'}</TooltipValue>
            <TooltipValue>{rightPanelData?.permission || '-'}</TooltipValue>
            {CustomInformationSecond}
          </SecondColumn>
        </Common>
        <BreakLine> {t('Asset style')}</BreakLine>
        <Common>
          <FirstColumn>{StyleInformationFirst}</FirstColumn>
          <SecondColumn>{StyleInformationSecond}</SecondColumn>
        </Common>
        <BreakLine> {t('Coordinates (lat, lng)')}</BreakLine>
        <Common>
          <FirstColumn>{CoordinateHeader}</FirstColumn>
          <SecondColumn>{CoordinateData}</SecondColumn>
        </Common>
      </>
    );

    return <>{CommonInformation}</>;
  };

  const tabItems1 = [];
  const tabItems2 = [];

  if (rightPanelData.newAsset) {
    if (rightPanelData.fromGeoJson) {
      tabItems1.push({
        label: <span>{t('Import Geojson')}</span>,
        children: (
          <GeoJsonTab
            mainMap={mainMap}
            data={JSON.parse(JSON.stringify(rightPanelData))}
            changeTab={number => {
              setCurrentTab(number);
            }}
          />
        ),
        key: '1',
      });
    } else {
      tabItems1.push({
        label: <span>{t('Create new asset')}</span>,
        children: (
          <TabContainer mainMap={mainMap} alertsHeight={alertsHeight}>
            <GeoAssetToolEditTab data={JSON.parse(JSON.stringify(rightPanelData))} />
          </TabContainer>
        ),
        key: '1',
      });
    }
  } else {
    tabItems2.push({
      label: <span>{t("Asset's info")}</span>, // eslint-disable-line quotes
      children: (
        <TabContainer mainMap={mainMap} alertsHeight={alertsHeight}>
          {AssetInfo()}
        </TabContainer>
      ),
      key: '1',
      disabled: rightPanelData.newAsset,
    });
    tabItems2.push({
      label: <span>{t('Edit')}</span>,
      children: !rightPanelData.fromGeoJson && (
        <TabContainer mainMap={mainMap} alertsHeight={alertsHeight}>
          <GeoAssetToolEditTab
            data={propertiesFormatter(JSON.parse(JSON.stringify(rightPanelData)))}
            changeTab={() => {
              setCurrentTab('1');
            }}
          />
        </TabContainer>
      ),
      key: '2',
      disabled: rightPanelData.fromGeoJson,
    });
    tabItems2.push({
      label: <span>{t('JSON')}</span>,
      children: <JsonWrapper mainMap={mainMap}>{JSON.stringify(rightPanelData, null, 2)}</JsonWrapper>,
      key: '3',
      disabled: rightPanelData.newAsset,
    });
    tabItems2.push({
      label: <span>{t('GeoJSON')}</span>,
      children: (
        <GeoJsonTab
          data={JSON.parse(JSON.stringify(rightPanelData))}
          changeTab={number => {
            setCurrentTab(number);
          }}
          mainMap={mainMap}
        />
      ),
      key: '4',
      disabled: rightPanelData.newAsset,
    });
  }

  return (
    <>
      {rightPanelOpen &&
        (!rightPanelListData ? (
          <PanelWrapperRight
            mapMargin={mapMargin}
            map={mainMap}
            newAsset={rightPanelData.newAsset || rightPanelData.fromGeoJson}
            alertsHeight={alertsHeight}
          >
            <HeaderRow>
              <Header>
                {t('Details of') + ' '}
                <span style={{ fontWeight: 700 }}>
                  {rightPanelData.newAsset && !rightPanelData.fromGeoJson
                    ? rightPanelData.geo_data.type
                    : rightPanelData.fromGeoJson
                      ? 'GeoJSON'
                      : rightPanelData.name}
                </span>
                {filterList.includes(rightPanelData.type_id) && t(' (Hidden)')}
              </Header>
              <CloseIcon
                data-testid={'geojson-right-side-panel-close'}
                href="#"
                onClick={() => {
                  closeRightPanel();
                  setCurrentTab('1');
                }}
              >
                <Tooltip
                  title={t('Close side panel')}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                  placement="left"
                >
                  <div>
                    <Icon type="close" />
                  </div>
                </Tooltip>
              </CloseIcon>
            </HeaderRow>
            <Spin spinning={!rightPanelData} size="medium">
              <TabBody mainMap={mainMap}>
                {rightPanelData.newAsset ? (
                  <Tabs
                    activeKey={'1'}
                    size="small"
                    style={{ marginRight: '15px', overflow: 'hidden', height: '100%' }}
                    items={tabItems1}
                  />
                ) : (
                  <Tabs
                    activeKey={rightPanelData.newAsset ? '2' : currentTab}
                    onTabClick={key => setCurrentTab(key)}
                    size="small"
                    style={{ marginRight: '12px', overflow: 'hidden', height: '100%' }}
                    items={tabItems2}
                  />
                )}
              </TabBody>
            </Spin>
          </PanelWrapperRight>
        ) : (
          <PanelWrapperRight
            mapMargin={mapMargin}
            map={mainMap}
            newAsset={rightPanelData.newAsset || rightPanelData.fromGeoJson}
          >
            <HeaderRow>
              <Header>{t('Asset list')}</Header>
              <CloseIcon
                data-testid={'geojson-right-side-panel-close'}
                href="#"
                onClick={() => {
                  closeRightPanel();
                  setCurrentTab('1');
                }}
              >
                <Tooltip
                  title={t('Close side panel')}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                  placement="left"
                >
                  <div>
                    <Icon type="close" />
                  </div>
                </Tooltip>
              </CloseIcon>
            </HeaderRow>
            <AssetList>
              {rightPanelListData.map((d, index) => (
                <ListDataItem
                  key={index}
                  index={index % 2}
                  onClick={() => {
                    openRightPanel(d);
                    setUpdateMapCenter({ coords: d.geo_data.coordinates });
                  }}
                >
                  <NameRow>
                    <Name>{d.name}</Name>
                    <MapMarker onClick={() => setUpdateMapCenter({ coords: d.geo_data.coordinates })}>
                      <Icon type={'marker'} />
                    </MapMarker>
                  </NameRow>
                  <InfoRow>
                    <Info>{typeData?.[d.type_id]?.name || ''}</Info>
                    <Info>{d.permission}</Info>
                  </InfoRow>
                </ListDataItem>
              ))}
            </AssetList>
            <StyledButtonLight type="button" onClick={() => createJsonFile()}>
              <Icon type="download" fill="#ffffff !important" />
              {t('Export assets')}
            </StyledButtonLight>
          </PanelWrapperRight>
        ))}
    </>
  );
};

export default GeoAssetToolRightPanel;
