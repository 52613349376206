import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';
import dayjs from 'dayjs';

const RowContainerSplit = styled.div`
  display: flex;
  padding: 6px 0;
`;

const Header = styled.div`
  margin-right: 6px;
  white-space: nowrap;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
`;

const RowContainer = styled.div`
  width: 100%;
  padding: 0 0 9px 0;
`;

const InfoContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const DeliveryHeader = styled.div`
  font-weight: 600;
  margin-bottom: -4px;
`;

const Value = styled.div`
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ServiceOrderDeliveryInfo = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <InfoContainer>
      <DeliveryHeader>{t('Delivery')}</DeliveryHeader>
      <RowContainer>
        <Container style={{ width: 'calc((100% - 130px) * 0.2)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Email')}:</Header>
            <Tooltip
              title={data.deliveries[0].email}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <Value>{data.deliveries[0].email || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) * 0.18)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Phone')}:</Header>
            <Tooltip
              title={data.deliveries[0].phone}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <Value>{data.deliveries[0].phone || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) * 0.16)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Quantity')}:</Header>
            <Value>{data.deliveries[0].quantity}</Value>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) * 0.24)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Delivery time')}:</Header>
            <Value>
              {data.deliveries[0].delivery_time
                ? dayjs(data.deliveries[0].delivery_time).format('DD.MM.YYYY HH:mm')
                : '-'}
            </Value>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) / 20 * 6)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Note')}:</Header>
            <Tooltip
              title={data.deliveries[0].notes}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <Value>{data.deliveries[0].notes || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
      </RowContainer>
    </InfoContainer>
  );
};

export default ServiceOrderDeliveryInfo;
