import './MapContainer.css';

import { MapContainer as Map } from 'react-leaflet';
import React from 'react';
import L from 'leaflet';

import MapInnerContainer from './MapInnerContainer';

const MainMap = ({
  zoom,
  setZoom,
  appliedCoordinates,
  fleetViewOn,
  setFleetViewOn,
  quickLinksOn,
  setQuickLinksOn,
  setSideModalOpen,
  sideModalOpen,
  showRoutes,
  setShowRoutes,
  seaPassageData,
  vesselsState,
  setVesselsState,
  selectedAisVessel,
  setSelectedAisVessel,
  selectedPortVessel,
  setSelectedPortVessel,
  selectedPortVesselLocation,
  setSelectedPortVesselLocation,
  setMeasureDrawing,
  measureDrawing,
  setCircleDrawing,
  circleDrawing,
  setAppliedCoordinates,
}) => {
  return (
    <Map
      style={{
        height: '100%',
        width: '100%',
        zIndex: 0,
      }}
      className="markercluster-map"
      center={appliedCoordinates.split(',')}
      zoom={zoom}
      tap={false}
      zoomControl={false}
      renderer={L.canvas({ padding: 0.5 })}
    >
      <MapInnerContainer
        zoom={zoom}
        setZoom={setZoom}
        appliedCoordinates={appliedCoordinates}
        fleetViewOn={fleetViewOn}
        setFleetViewOn={setFleetViewOn}
        quickLinksOn={quickLinksOn}
        setQuickLinksOn={setQuickLinksOn}
        setSideModalOpen={setSideModalOpen}
        sideModalOpen={sideModalOpen}
        setShowRoutes={setShowRoutes}
        showRoutes={showRoutes}
        seaPassageData={seaPassageData}
        vesselsState={vesselsState}
        setVesselsState={setVesselsState}
        setSelectedAisVessel={setSelectedAisVessel}
        selectedAisVessel={selectedAisVessel}
        selectedPortVessel={selectedPortVessel}
        setSelectedPortVessel={setSelectedPortVessel}
        selectedPortVesselLocation={selectedPortVesselLocation}
        setSelectedPortVesselLocation={setSelectedPortVesselLocation}
        measureDrawing={measureDrawing}
        setMeasureDrawing={setMeasureDrawing}
        circleDrawing={circleDrawing}
        setCircleDrawing={setCircleDrawing}
        setAppliedCoordinates={setAppliedCoordinates}
      />
    </Map>
  );
};

export default MainMap;
