import React, { useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Alert from 'antd/es/alert';

import useApi from '../../hooks/useApi';
import { TIME_FORMAT, mobilePixelMaxWidthLimit } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import NotificationRow from '../../components/activity/NotificationRow';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const BackLink = styled.a`
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};

  @media print {
    display: none;
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-left: 1rem;
  margin-bottom: 1rem;

  @media print {
    display: none;
  }
`;

const Header = styled.div`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 15px;
  z-index: 100;

  @media print {
    position: relative;
    left: 0px;
    top: 0px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    top: 46px;
    font-size: 14px;
  }
`;

const PrintContainer = styled.div`
  @media print {
    margin-top: 40px;
  }
`;

const AdminPortCallTimesheet = props => {
  const { user, apiCall, namespace, alert, setAlert, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { id } = props.match.params;

  const { data, error } = useApi('get', 'port-call', { id });

  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  const { data: decisionData, error: decisionError, fetchData: fetchDecisions } = useApi(
    'get',
    'port-call-decisions-summary'
  );

  const { data: notificationData, error: notificationError, fetchData: fetchNotifications } = useApi(
    'get',
    'port-call-notifications-summary'
  );

  const getDecisions = useCallback(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  const getNotifications = useCallback(() => {
    let masterIds = [];
    if (data?.ship) {
      masterIds = [data.ship].map(portCall => portCall.master_id);
    }

    if (masterIds.length > 0) {
      fetchNotifications(false, { port_call_master_ids: masterIds });
    }
  }, [data, fetchNotifications]);

  useUserSocket('port-call-decision-changed', getDecisions);
  useUserSocket('notifications-summary-common-changed', getNotifications);
  useUserSocket(`notifications-summary-changed-${user.id}`, getNotifications);

  let notifications = notificationError ? [] : notificationData;
  let decisions = decisionError ? [] : decisionData;

  const handleDownloadCsv = async () => {
    let tz = dayjs.tz.guess();

    const response = await apiCall('get', 'port-call-csv', { id, time_zone: tz }, null, 'blob');

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = id + '-' + data.ship.imo + '-' + encodeURIComponent(data.ship.vessel_name) + '.csv';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const columns = [
    {
      title: t('Activity'),
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
  ];

  const portCall = error || !data ? { ship: {}, timestamps: [] } : data;

  let key = 1;

  const portCallTimestamps = portCall.timestamps.map(timestamp => {
    let maybeBerthLocation = timestamp.payload?.location;
    return {
      key: key++,
      activity: t(
        timestamp.time_type +
          ' ' +
          timestamp.state.replace(/_/g, ' ') +
          (maybeBerthLocation ? ' ' + maybeBerthLocation : '')
      ),
      time: timestamp.time,
    };
  });

  const startDateIso = portCallTimestamps.length ? dayjs(portCallTimestamps[0].time).format('YYYY-DD-MM') : 'n/a';

  useEffect(() => {
    document.title = `Date ${startDateIso}, Port call #${id}, IMO #${portCall.ship.imo} | Port Activity App`;
  }, [id, portCall.ship.imo, startDateIso]);

  let newNotifications = null;
  let masterId = portCall.ship.master_id;
  if (notifications && masterId && notifications[masterId]) {
    newNotifications = notifications[masterId];
  }
  let newDecisions = null;
  if (decisions && masterId && decisions[masterId]) {
    newDecisions = decisions[masterId];
  }

  const additionalButtons = [
    {
      disabled: !user.permissions.includes('manage_port_call') || !portCall.ship.imo,
      onClick: () => history.push(`/vessels/vessel-timestamps/${portCall.ship.imo}`),
      text: t('All timestamps'),
    },
    {
      disabled: !user.permissions.includes('manage_port_call') || !portCall.ship.imo,
      onClick: () => history.push(`/port-calls?offset=0&search=${portCall.ship.imo}`),
      text: t('All port calls'),
    },
    {
      onClick: handleDownloadCsv,
      text: t('Download as CSV'),
    },
    {
      onClick: () => window.print(),
      text: t('Print timesheet'),
    },
  ];

  return (
    <Layout pagename={t('Port Call')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page
        fullWidth
        title={
          <>
            {t('Port call #{{id}}', {
              id: portCall.ship.id,
            })}
            <br />
            {t('{{ship}}, IMO {{imo}}', {
              ship: portCall.ship.vessel_name,
              id: portCall.ship.id,
              imo: portCall.ship.imo,
            })}
          </>
        }
      >
        <Header>
          {t('Port call #{{id}}', {
            id: portCall.ship.id,
          })}
          <br />
          {t('{{ship}}, IMO {{imo}}', {
            ship: portCall.ship.vessel_name,
            id: portCall.ship.id,
            imo: portCall.ship.imo,
          })}
        </Header>
        <BackLink onClick={handleClick}>{t('« Back to previous page')}</BackLink>
        <NotificationWrapper>
          <NotificationRow
            notifications={newNotifications || portCall.ship.notification_summary}
            decisions={newDecisions || portCall.ship.decision_summary}
            imo={portCall.ship.imo}
            master_id={portCall.ship.master_id}
            vessel_name={portCall.ship.vessel_name}
          />
        </NotificationWrapper>
        <PrintContainer>
          <List
            rowKey="key"
            columns={columns}
            dataSource={portCallTimestamps}
            spinning={false}
            additionalButtons={additionalButtons}
          />
        </PrintContainer>
      </Page>
    </Layout>
  );
};

export default AdminPortCallTimesheet;
