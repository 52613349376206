import dayjs from 'dayjs';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { WeatherContext } from '../../context/WeatherContext';
import BerthPlanningWeatherCard from './BerthPlanningWeatherCard';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const BptWeatherContainer = styled.div`
  display: flex;
  width: 100%;
`;

const BerthPlanningWeather = ({ interval, type }) => {
  const { forecastWeather } = useContext(WeatherContext);

  let emptyCard = {
    description: null,
    lat: null,
    lon: null,
    temperature: null,
    time: null,
    wind_direction: null,
  };
  let dataList = [];
  let emptyBeforeAmount = 0;
  let emptyAfterAmount = 0;

  if (type === 'day') {
    const currentDayNumber = dayjs(interval.startTime).date();
    dataList = forecastWeather.filter(w => dayjs(w.time).date() === currentDayNumber);

    if (dataList.length < 24 && dataList.length > 0) {
      const starthour = dayjs(dataList[0].time).hour();
      if (starthour !== 0) {
        emptyBeforeAmount = starthour;
      } else {
        emptyAfterAmount = 24 - dayjs(dataList[dataList.length - 1].time).hour();
      }
    }
  } else if (type === 'week') {
    const currentDayNumber = dayjs(interval.startTime).date();

    dataList = forecastWeather.filter(
      w => dayjs(w.time).date() === currentDayNumber && [4, 12, 20].includes(dayjs(w.time).hour())
    );

    if (dataList.length < 3 && dataList.length > 0) {
      const starthour = dayjs(dataList[0].time).hour();
      if (starthour === 12) {
        emptyBeforeAmount = 1;
      } else if (starthour === 20) {
        emptyBeforeAmount = 2;
      } else {
        const endhour = dayjs(dataList[dataList.length - 1].time).hour();
        if (endhour === 4) {
          emptyAfterAmount = 2;
        } else if (endhour === 12) {
          emptyAfterAmount = 1;
        }
      }
    } else if (dataList.length === 0) {
      emptyBeforeAmount = 2;
      dataList = forecastWeather.filter(
        w => dayjs(w.time).date() === currentDayNumber && [23].includes(dayjs(w.time).hour())
      );
    }
  } else if (type === 'month') {
    dataList = forecastWeather.filter(
      (w, i) =>
        (dayjs(w.time).hour() === 15 ||
          (i === 0 && dayjs(w.time).hour() > 15) ||
          (i === forecastWeather.length - 1 && dayjs(w.time).hour() < 15)) &&
        dayjs(w.time).isBetween(dayjs(interval.startTime), dayjs(interval.endTime))
    );

    emptyBeforeAmount = dataList[0] ? dayjs(dataList[0].time).day() - 1 : 7;
    emptyAfterAmount = dataList[dataList.length - 1] ? 7 - (dayjs(dataList[dataList.length - 1].time).day() || 7) : 0;
  }

  let filledDataList = [];

  for (let i = 0; i < emptyBeforeAmount; i++) {
    filledDataList.push(emptyCard);
  }

  filledDataList = [...filledDataList, ...dataList];

  for (let i = 0; i < emptyAfterAmount; i++) {
    filledDataList.push(emptyCard);
  }

  return (
    <BptWeatherContainer>
      {filledDataList.map((data, i) => (
        <BerthPlanningWeatherCard
          key={data.time || dayjs().format() + i}
          data={data}
          type={type}
          index={type === 'week' ? i + 1 : 0}
        />
      ))}
    </BptWeatherContainer>
  );
};

export default BerthPlanningWeather;
