import React, { useContext, useEffect, useState } from 'react';
import { GeoJSON, Tooltip } from 'react-leaflet';
import hash from 'object-hash';

import { UserContext } from '../../context/UserContext';
import { FilteringContext } from '../../context/FilteringContext';

import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import { CraneContext } from '../../context/CraneContext';
import { color_planned, color_reserved, color_uncommitted, color_updated } from '../commonCalendar/utility/constants';
import MapCrane from '../map/MapCrane';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';

dayjs.extend(isBetween);

const featureStyle = {
  fill: true,
  fillOpacity: 0.5,
  fillColor: color_planned,
  stroke: true,
  color: 'green',
  weight: 2,
};

const BerthVesselsOverlay = ({ zoom, setCraneClicked, craneClicked, plannedCranes }) => {
  const { apiCall } = useContext(UserContext);
  const { indicatorLine, calendarVessels, predictiveBPTOn } = useContext(BerthPlanningToolContext);
  const { cranes } = useContext(CraneContext);
  const { filteringStorageData } = useContext(FilteringContext);
  const [vessels, setVessels] = useState(undefined);

  const getStyle = feature => {
    if (feature.properties.grey) {
      return { ...featureStyle, color: 'gray', fillColor: color_reserved };
    }

    if (feature.properties.state == 2) {
      return { ...featureStyle, color: 'blue', fillColor: color_uncommitted };
    }

    if (feature.properties.state == 4) {
      return { ...featureStyle, color: 'red', fillColor: color_reserved };
    }

    if (feature.properties.state == 6) {
      return { ...featureStyle, color: 'blue', fillColor: color_uncommitted };
    }

    if (feature.properties.state == 7) {
      return { ...featureStyle, color: 'purple', fillColor: color_updated };
    }

    return featureStyle;
  };

  const loadVessels = async vessels => {
    const response = await apiCall('post', 'heidi/vessels', { vessels });
    if (response.data) {
      for (let i = 0; i < vessels.length; i++) {
        let index = vessels[i].properties.index;

        if (index || index === 0) {
          let data = response.data.features.find(d => d.properties.index === index);

          if (data) {
            data.name = vessels[i].name;
            data.cranes = vessels[i].cranes;
          }
        }
      }
    }

    setVessels(response.data);
  };
  useEffect(() => {
    const currentVesselData = calendarVessels.filter(
      item =>
        indicatorLine.time.isBetween(item.pta, item.ptd) ||
        (item.slot_reservation_status && indicatorLine.time.isBetween(item.total_start, item.total_end))
    );
    const currentVessels = currentVesselData.map((item, index) => ({
      name: item.name,
      berth: item.berth_code,
      startBollard: item.start_bollard,
      endBollard: item.end_bollard,
      loa: item.loa,
      beam: item.beam,
      direction_down: item.direction_down,
      properties: {
        state: item.state,
        grey: item.grey,
        index: index,
      },
      cranes: item.pbp_data?.works
        ? item.pbp_data.works.filter(crane => indicatorLine.time.isBetween(crane.start_time, crane.end_time))
        : [],
    }));
    if (currentVessels.length > 0) {
      loadVessels(currentVessels);
    } else {
      setVessels(undefined);
    }
    return () => undefined;
    //eslint-disable-next-line
  }, [calendarVessels, indicatorLine, filteringStorageData]);

  return vessels
    ? vessels.features.map(vessel => {
      return (
        <div key={hash(vessel)}>
          <GeoJSON style={getStyle} data={vessel}>
            <Tooltip key={vessel.name} sticky>
              {vessel.name}
            </Tooltip>
          </GeoJSON>
          {predictiveBPTOn &&
              plannedCranes &&
              vessel.cranes.map((crane, index) => {
                let endCoordinates = undefined;
                if (craneClicked && craneClicked.toLowerCase() === crane.crane_short_name.toLowerCase()) {
                  let craneFromList = cranes.find(c => c.code.toLowerCase() === craneClicked.toLowerCase());
                  if (craneFromList) {
                    endCoordinates = [craneFromList.lon, craneFromList.lat];
                  }
                }
                return (
                  <MapCrane
                    key={crane.id}
                    coordinates={vessel.geometry.coordinates}
                    appliedZoomLevel={zoom}
                    name={crane.crane_short_name}
                    index={index}
                    craneAmount={vessel.cranes.length}
                    setCraneClicked={setCraneClicked}
                    endCoordinates={endCoordinates}
                  />
                );
              })}
        </div>
      );
    })
    : null;
};

export default BerthVesselsOverlay;
