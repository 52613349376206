import React, { useContext } from 'react';
import Checkbox from 'antd/es/checkbox';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { FilteringContext } from '../../context/FilteringContext';

import ButtonLight from '../ui/ButtonLight';
import { toTitleCase } from '../../utils/utils';
import CheckBoxList from './CheckBoxList';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 20px 0 10px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    padding: 4px 0;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
    paddign: 10px 0;
  }
`;

const PopOverContainer = styled.div`
  padding: 20px 38px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    max-height: calc(100vh - 120px);
  }
`;

const FiltersBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;

  button {
    margin-bottom: 0px;
    margin-right: 0.8rem;
    min-width: 80px;

    &:last-child {
      margin-right: 0;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
`;

const Filter = ({ togglePopover }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    filteringStorageData,
    setFilterStorageData,
    filteringStorageOptions,
    filteringListsOpenStorage,
    setFilterListsOpenData,
  } = useContext(FilteringContext);

  const onChange = (e, id) => {
    if (e.target.checked == true) {
      let listOfData = filteringStorageData[id].map(d => d);
      listOfData.push(e.target.name);
      let newData = { ...filteringStorageData };
      newData[id] = listOfData;
      setFilterStorageData(newData);
    } else {
      let listOfData = filteringStorageData[id].map(d => d).filter(d => d !== e.target.name);
      let newData = { ...filteringStorageData };
      newData[id] = listOfData;
      setFilterStorageData(newData);
    }
  };

  const clearForm = () => {
    let newForm = {};
    Object.keys(filteringStorageData).forEach(d => (newForm[d] = []));
    setFilterStorageData(newForm);
  };

  const onListOpenChange = (e, id) => {
    let obj = { ...filteringListsOpenStorage };
    obj[id] = e;
    setFilterListsOpenData(obj);
  };

  return (
    <PopOverContainer>
      {filteringStorageOptions.map(
        ({ name, display, values, type }) =>
          type === 'multi_select' && (
            <div key={name}>
              <Title>{display}</Title>
              {values.length < 5 ? (
                <CheckboxContainer>
                  {values.map((value, index) => (
                    <Checkbox
                      key={index}
                      onChange={e => onChange(e, name)}
                      checked={
                        filteringStorageData?.[name] ? filteringStorageData[name].find(d => d === value.name) : false
                      }
                      name={value.name}
                    >
                      {name === 'agent' ? value.display : toTitleCase(value.display)}
                    </Checkbox>
                  ))}
                </CheckboxContainer>
              ) : (
                <CheckboxContainer>
                  <CheckBoxList
                    options={values}
                    chosen={filteringStorageData}
                    handleClick={e => onChange(e, name)}
                    uniqueId={name + '-' + type}
                    filterName={name}
                    listOpenSaved={filteringListsOpenStorage ? filteringListsOpenStorage[name] : false}
                    setKeepOpen={e => onListOpenChange(e, name)}
                  />
                </CheckboxContainer>
              )}
            </div>
          )
      )}
      <FiltersBtn>
        <ButtonLight cancel onClick={clearForm}>
          {t('Clear all')}
        </ButtonLight>
        <ButtonLight cancel onClick={() => togglePopover(false)}>
          {t('Close')}
        </ButtonLight>
      </FiltersBtn>
    </PopOverContainer>
  );
};

export default Filter;
