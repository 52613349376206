import React, { useState, createContext, useEffect, useRef, useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import {
  calculateXPositionForTime,
  calculateTimeForXPosition,
  getCanvasBoundariesFromVisibleTime,
  getCanvasWidth,
  calculateScrollCanvas,
} from '../components/commonCalendar/utility/calendar';
import { createVesselList } from '../components/fleetView/vesselCalendar/utility/contextHelpers';

import { UserContext } from './UserContext';
import { FleetFilteringContext } from './FleetFilteringContext';

import { fleetVesselRowHeightConstant } from '../components/commonCalendar/utility/constants';
import { buildQuery } from '../components/filter/fleetFilterHelpers';
import {
  fleetTimelineTypeStorageKey,
  fleetTimelineTypeStorageKeyOptions,
} from '../components/fleetView/calendar/utility/contextHelpers';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const bufferMultiplier = 0.5;

const fleetVesselViewZoomStorage = 'fleetVesselView_zoom_value';

export const FleetVesselViewContext = createContext();

export const FleetVesselViewProvider = ({ children }) => {
  const defaultTimeStart = dayjs()
    .startOf('isoWeek')
    .toDate();

  const defaultTimeEnd = dayjs()
    .startOf('isoWeek')
    .add(1, 'week')
    .toDate();

  const initialIndicatorLine = {
    time: dayjs(),
    isUserSet: false,
  };

  const [visibleTimeStart, setVisibleTimeStart] = useState(defaultTimeStart.valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(defaultTimeEnd.valueOf());
  const [canvasTimeStart, setCanvasTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[0]
  );
  const [canvasTimeEnd, setCanvasTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[1]
  );
  const [bufferTimeStart, setBufferTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf(), bufferMultiplier)[0]
  );
  const [bufferTimeEnd, setBufferTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf(), bufferMultiplier)[1]
  );

  const [vessels, setVessels] = useState([]);
  const [width, setWidth] = useState(1000);
  const [canvasWidth, setCanvasWidth] = useState(getCanvasWidth(1000));
  const [timelineUnit, setTimelineUnit] = useState(null);
  const [indicatorLine, setIndicatorLine] = useState(initialIndicatorLine);
  const [rowHeight, setRowHeight] = useState(fleetVesselRowHeightConstant);
  const [millisecondInPixels, setMillisecondInPixels] = useState(0);

  const [loader, setLoader] = useState(true);
  const [secondLoader, setSecondLoader] = useState(true);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [sideModalOpen, setSideModalOpen] = useState(undefined);

  const [otherNamespaceData, setOtherNamespaceData] = useState({});

  const [zoomChecked, setZoomChecked] = useState(false);

  const timeIndicatorLineTimer = useRef();

  const { apiCall, namespaces, namespace, useUserSocket, modules } = useContext(UserContext);
  const { fleetFilter, formData } = useContext(FleetFilteringContext);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [zoomSliderValue, setZoomSliderValue] = useState(6);

  const setRowHeightFunction = rowHeight => {
    rowHeight < 14
      ? setRowHeight(14)
      : rowHeight > fleetVesselRowHeightConstant + 10
        ? setRowHeight(fleetVesselRowHeightConstant + 10)
        : setRowHeight(rowHeight);
    localStorage.setItem(fleetVesselViewZoomStorage, (rowHeight - 14) / 2);
  };

  useEffect(() => {
    const value = localStorage.getItem(fleetVesselViewZoomStorage);
    if (value && ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(value.toString())) {
      setRowHeightFunction(14 + value * 2);
      setZoomSliderValue(value);
    }
    setZoomChecked(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const type = localStorage.getItem(fleetTimelineTypeStorageKey);
    if (type && fleetTimelineTypeStorageKeyOptions.includes(type)) {
      setTimelineType(type, false);
    } else {
      setTimelineType('week', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMillisecondInPixels(
      canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds()
    );
  }, [canvasTimeEnd, canvasTimeStart, canvasWidth]);

  const updateTime = useCallback(() => {
    setIndicatorLine({ isUserSet: false, time: dayjs() });
  }, []);

  useEffect(() => {
    if (!indicatorLine.isUserSet) {
      timeIndicatorLineTimer.current = setInterval(updateTime, 30000);
    }
    return () => clearInterval(timeIndicatorLineTimer.current);
  }, [indicatorLine, updateTime]);

  useEffect(() => {
    (async () => {
      await updateCalendarVesselData(bufferTimeStart, bufferTimeEnd);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetFilter]);

  const getDataFromOtherNamespaces = async () => {
    let dataCollection = {};
    for (const ns of namespaces) {
      if (ns.namespace !== namespace) {
        const result = await apiCall('get', 'ongoing-port-calls', undefined, undefined, undefined, ns.namespace);

        if (result.data) {
          const { portcalls } = result.data || { portcalls: [] };
          dataCollection[ns.namespace] = portcalls;
        }
      }
    }
    setOtherNamespaceData(dataCollection);
  };

  const getStartData = async () => {
    getDataFromOtherNamespaces();
  };

  const updateCalendarVesselData = useCallback(
    async (startValue, endValue) => {
      if (!loader) {
        setSecondLoader(true);
      }
      let start = dayjs(startValue).format('YYYY-MM-DDTHH:mm:ssZ');
      let end = dayjs(endValue).format('YYYY-MM-DDTHH:mm:ssZ');

      let { query } = buildQuery(formData, start, end);

      const vesselData = await apiCall('post', 'fleet/search/portcall,manual-portcall', {
        query: {
          ...query,
          ...(fleetFilter.query?.text ? { text: fleetFilter.query.text } : {}),
        },
        order_by: [
          {
            field: 'eta',
            order: 'asc',
          },
        ],
        group_by: 'vessel_name',
      });

      if (mounted.current) {
        if (Object.keys(vesselData?.data?.results?.portcalls || {}).length) {
          const vesselList = createVesselList(vesselData.data.results.portcalls);
          setVessels(vesselList);
          if (loader) {
            setLoader(false);
          }
          setSecondLoader(false);
        }
      }
    },
    [apiCall, formData, fleetFilter, loader]
  );

  const portcallChanged = useCallback(() => {
    updateCalendarVesselData(bufferTimeStart, bufferTimeEnd);
  }, [updateCalendarVesselData, bufferTimeStart, bufferTimeEnd]);
  useUserSocket('fleet-portcalls-changed', portcallChanged);
  useUserSocket('fleet-manual-portcalls-changed', portcallChanged);

  const getLeftOffsetFromDate = date => {
    return calculateXPositionForTime(canvasTimeStart, canvasTimeEnd, canvasWidth, date);
  };

  const getDateFromLeftOffsetPosition = leftOffset => {
    return calculateTimeForXPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset);
  };

  const updateScrollCanvas = async (visibleTimeStart2, visibleTimeEnd2) => {
    const scrollCanvas = calculateScrollCanvas(visibleTimeStart2, visibleTimeEnd2, canvasTimeStart, bufferMultiplier);

    setVisibleTimeStart(scrollCanvas.visibleTimeStart);
    setVisibleTimeEnd(scrollCanvas.visibleTimeEnd);
    setCanvasTimeStart(scrollCanvas.canvasTimeStart);
    setCanvasTimeEnd(scrollCanvas.canvasTimeEnd);
    setBufferTimeStart(scrollCanvas.bufferTimeStart);
    setBufferTimeEnd(scrollCanvas.bufferTimeEnd);

    await updateCalendarVesselData(scrollCanvas.bufferTimeStart, scrollCanvas.bufferTimeEnd);
  };

  const changeCustomDate = dates => {
    const start = dayjs(dates[0]).startOf('day');
    const end = dayjs(dates[1]).endOf('day');
    const startValueOf = start.valueOf();

    let timespan = dayjs.duration(end.diff(start)).asMilliseconds();

    setVisibleTimeStart(startValueOf);
    setVisibleTimeEnd(startValueOf + timespan);
    setCanvasTimeStart(startValueOf - timespan);
    setCanvasTimeEnd(startValueOf + 2 * timespan);
    setBufferTimeStart(startValueOf - timespan * bufferMultiplier);
    setBufferTimeEnd(startValueOf + timespan + timespan * bufferMultiplier);

    updateCalendarVesselData(
      startValueOf - timespan * bufferMultiplier,
      start + timespan + timespan * bufferMultiplier
    );
  };

  const setTimelineType = async (type, keepPlace = true) => {
    setLoader(true);

    localStorage.setItem(fleetTimelineTypeStorageKey, type);

    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    let start = canvasTimeStart + (scrollOffset / width) * viewWidthInTime;
    let end = canvasTimeStart + (scrollOffset / width) * viewWidthInTime + viewWidthInTime;
    start = dayjs(start);
    end = dayjs(end);

    setTimelineUnit(type);

    let newVisibleStart = null;
    let newVisibleEnd = null;

    switch (type) {
      case 'custom': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = end
            .startOf('day')
            .toDate()
            .valueOf();
        } else {
          const days = dayjs.duration(end.diff(start)).asDays();
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(days, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'day': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'twodays': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(2, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(2, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'week': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(7, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .add(-2, 'days')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(4, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'month': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();

          newVisibleStart = start
            .startOf('isoWeek')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }
      default:
        break;
    }

    const [canvasStart, canvasEnd] = getCanvasBoundariesFromVisibleTime(newVisibleStart, newVisibleEnd);
    const [bufferStart, bufferEnd] = getCanvasBoundariesFromVisibleTime(
      newVisibleStart,
      newVisibleEnd,
      bufferMultiplier
    );

    setVisibleTimeStart(newVisibleStart);
    setVisibleTimeEnd(newVisibleEnd);
    setCanvasTimeStart(canvasStart);
    setCanvasTimeEnd(canvasEnd);
    setBufferTimeStart(bufferStart);
    setBufferTimeEnd(bufferEnd);

    setForceUpdate(true);

    await updateCalendarVesselData(bufferStart, bufferEnd);
    setLoader(false);
  };

  const setToday = () => {
    if (timelineUnit === 'day') {
      setTimelineType('day', false);
    } else if (timelineUnit === 'twodays') {
      setTimelineType('twodays', false);
    } else if (timelineUnit === 'week') {
      setTimelineType('week', false);
    } else if (timelineUnit === 'month') {
      setTimelineType('month', false);
    } else if (timelineUnit === 'custom') {
      setTimelineType('custom', false);
    }
    updateTime();
  };

  const moveForwards = () => {
    let days = 1;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      start = start.startOf('day');
    } else if (timelineUnit === 'twodays') {
      days = 2;
      start = start.startOf('day');
    } else if (timelineUnit === 'week') {
      days = 7;
      start = start.startOf('isoWeek');
    } else if (timelineUnit === 'month') {
      days = 28;
    }

    let ctStart = start.valueOf();
    let vtStart = start.add(days, 'days').valueOf();
    let vtEnd = start.add(2 * days, 'days').valueOf();
    let ctEnd = start.add(3 * days, 'days').valueOf();
    let bufferStart = dayjs(ctStart)
      .add(days * bufferMultiplier, 'days')
      .valueOf();
    let bufferEnd = dayjs(vtEnd)
      .add(days * bufferMultiplier, 'days')
      .valueOf();

    setVisibleTimeStart(vtStart);
    setVisibleTimeEnd(vtEnd);
    setCanvasTimeStart(ctStart);
    setCanvasTimeEnd(ctEnd);
    setBufferTimeStart(bufferStart);
    setBufferTimeEnd(bufferEnd);

    updateCalendarVesselData(bufferStart, bufferEnd);
  };

  const moveBackwards = () => {
    let timespan = 0;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      timespan = 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'twodays') {
      timespan = 2 * 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
      start = start.startOf('isoWeek').valueOf();
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
      start = start.valueOf();
    }

    setVisibleTimeStart(start - timespan);
    setVisibleTimeEnd(start);
    setCanvasTimeStart(start - 2 * timespan);
    setCanvasTimeEnd(start + timespan);
    setBufferTimeStart(start - timespan - timespan * bufferMultiplier);
    setBufferTimeEnd(start + timespan * bufferMultiplier);

    updateCalendarVesselData(start - timespan - timespan * bufferMultiplier, start + timespan * bufferMultiplier);
  };

  const changeDate = date => {
    const millisecondsDate = dayjs(date).valueOf();
    let timespan = 0;
    if (timelineUnit === 'day') {
      timespan = 86400000;
    } else if (timelineUnit === 'twodays') {
      timespan = 2 * 86400000;
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
    }

    setVisibleTimeStart(millisecondsDate);
    setVisibleTimeEnd(millisecondsDate + timespan);
    setCanvasTimeStart(millisecondsDate - timespan);
    setCanvasTimeEnd(millisecondsDate + 2 * timespan);
    setBufferTimeStart(millisecondsDate - timespan * bufferMultiplier);
    setBufferTimeEnd(millisecondsDate + timespan + timespan * bufferMultiplier);

    updateCalendarVesselData(
      millisecondsDate - timespan * bufferMultiplier,
      millisecondsDate + timespan + timespan * bufferMultiplier
    );
  };

  const loadMoreRight = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const loadMoreLeft = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  let adjustedRowHeight = rowHeight;
  let seaPassageEnabled = false;

  if (modules.sea_passage_module === 'enabled') {
    adjustedRowHeight = 1.5 * adjustedRowHeight;
    seaPassageEnabled = true;
  }

  return (
    <FleetVesselViewContext.Provider
      value={{
        visibleTimeStart,
        setVisibleTimeStart,
        visibleTimeEnd,
        setVisibleTimeEnd,
        canvasTimeStart,
        setCanvasTimeStart,
        canvasTimeEnd,
        setCanvasTimeEnd,
        getLeftOffsetFromDate,
        getDateFromLeftOffsetPosition,
        vessels,
        canvasWidth,
        setCanvasWidth,
        timelineUnit,
        setTimelineUnit,
        width,
        setWidth,
        updateScrollCanvas,
        setTimelineType,
        setToday,
        indicatorLine,
        setIndicatorLine,
        moveForwards,
        moveBackwards,
        changeDate,
        loadMoreRight,
        loadMoreLeft,
        loader,
        secondLoader,
        rowHeight: adjustedRowHeight,
        setRowHeightFunction,
        zoomSliderValue,
        setZoomSliderValue,
        setScrollOffset,
        forceUpdate,
        setForceUpdate,
        getStartData,
        otherNamespaceData,
        seaPassageEnabled,
        setSideModalOpen,
        sideModalOpen,
        millisecondInPixels,
        changeCustomDate,
        zoomChecked,
      }}
    >
      {children}
    </FleetVesselViewContext.Provider>
  );
};
