import React, { useContext } from 'react';
import styled from 'styled-components';

import { TimestampContext } from '../../context/TimestampContext';
import { AlertContext } from '../../context/AlertContext';

import Cards from './Cards';
import Timestamps from './Timestamps';

const StyledTimeline = styled.div`
  display: grid;
  grid-template-rows: 222px auto;
  height: ${props =>
    props.isIE || props.isEdge
      ? 'auto'
      : props.alertsHeight
        ? `calc(100vh - 113px - ${props.alertsHeight}px)`
        : 'calc(100vh - 113px)'};
  overflow-x: scroll;
  overflow-y: hidden;
  background: ${({ theme }) => theme.color.grey_lighter};
  display: -ms-grid;
  -ms-grid-rows: 220px auto;
`;

const Timeline = props => {
  const { unfilteredAmount } = useContext(TimestampContext);

  const { alertsHeight } = useContext(AlertContext);

  return (
    <StyledTimeline {...props} unfilteredAmount={unfilteredAmount} alertsHeight={alertsHeight}>
      <Cards />
      <Timestamps />
    </StyledTimeline>
  );
};

export default Timeline;
