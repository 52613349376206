import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { UserContext } from '../../context/UserContext';
import useFakeApi from './StatisticsContextData';

import { Chart, registerables } from 'chart.js';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;
`;

const BarContainer = styled.div`
  height: ${props => (props.column1 ? 'calc(100vh / 3)' : 'calc(100vh / 4)')};
  max-height: ${props => (props.column1 ? '400px' : '300px')};
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
  padding-right: 4px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  margin-top: 2px;
`;

const StatisticsBar1ColumnWithoutLegend = props => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [timeType, setTimeType] = useState('week'); // week, month, year
  const [dataType, setDataType] = useState(props.data_options ? props.data_options[0].value : '');
  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useFakeApi('get', props.data_url, {
    timeType: 'week',
    dataType: props.data_options ? props.data_options[0].value : '',
  });
  const graphData = error || !data ? [] : data.data;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.6,
    maxBarThickness: 30,
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 25,
      },
    },
    elements: {
      bar: {
        borderRadius: 6,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        display: false,
      },
    },
  };

  const datasets = graphData
    ? {
        labels: graphData.map(g => g.label),
        datasets: [
          {
            label: '',
            data: graphData.map(g => g.value),
            backgroundColor: '#A7C4F4',
          },
        ],
      }
    : {
        labels: [],
        datasets: [],
      };

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
          <SelectContainer>
            {loading && !startLoader && (
              <MiniLoader>
                <Spin size="small" />
              </MiniLoader>
            )}
            {!!dataType && (
              <Select
                value={dataType}
                style={{ width: 140, marginRight: '18px' }}
                onChange={e => {
                  setStartLoader(false);
                  setDataType(e);
                  fetchData(false, { timeType, dataType: e });
                }}
                size="small"
                options={props.data_options}
              />
            )}
            <Select
              value={timeType}
              style={{ width: 100 }}
              onChange={e => {
                setStartLoader(false);
                setTimeType(e);
                fetchData();
              }}
              size="small"
              options={[
                {
                  value: 'week',
                  label: t('Week'),
                },
                {
                  value: 'month',
                  label: 'Month',
                },
                {
                  value: 'year',
                  label: 'Year',
                },
              ]}
            />
          </SelectContainer>
        </HeaderRow>

        {startLoader && loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : graphData ? (
          <BarContainer column1={props.column1}>
            <Bar plugins={[ChartDataLabels]} options={options} data={datasets} />
          </BarContainer>
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default StatisticsBar1ColumnWithoutLegend;
