import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Tabs from 'antd/es/tabs';

import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';

import ButtonLight from '../../ui/ButtonLight';
import DeliverServiceOrderModal from './DeliverServiceOrderModal';
import AcknowledgeServiceOrderModal from './AcknowledgeServiceOrderModal';

const ModalInner = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;

  .ant-tabs-tab {
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }

  .ant-tabs-nav-wrap {
    margin-left: 16px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  border-radius: 2px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-bottom: -6px;

  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  padding-left: 8px;
  display: inline-block;
  height: 70px;
  margin-bottom: 24px;
`;

const InfoRowSplit = styled.div`
  width: auto;
  padding-right: 25px;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 12px;
`;

const ServiceOrderModal = ({
  closeModal,
  vessel,
  reloadData,
  requested,
  acknowledged,
  delivered,
  serviceOrderModalTab,
  setServiceOrderModalTab,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const title = t('Service orders for {{vesselName}}', { vesselName: vessel.vessel_name });

  const tabItems = [
    {
      label: 'Requested (' + requested.length + ')',
      children: <AcknowledgeServiceOrderModal vessel={vessel} lines={requested} reloadData={reloadData} />,
      key: '1',
    },
    {
      label: 'Acknowledged (' + acknowledged.length + ')',
      children: (
        <DeliverServiceOrderModal vessel={vessel} lines={acknowledged} reloadData={reloadData} update={false} />
      ),
      key: '2',
    },
    {
      label: 'Delivered (' + delivered.length + ')',
      children: <DeliverServiceOrderModal vessel={vessel} lines={delivered} reloadData={reloadData} update={true} />,
      key: '3',
    },
  ];

  return (
    <Modal title={title} open={true} width={1200} footer={null} onCancel={closeModal}>
      <ModalInner>
        <FirstColumnSplit>
          <TopContainer>
            <InfoRowSplit>
              {t('Vessel:')}
              <BoldText>{vessel.vessel_name}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('IMO:')}
              <BoldText>{vessel.imo}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('Length:')}
              <BoldText>{vessel.loa || '-'}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('Draft:')}
              <BoldText>{vessel.draft || '-'}</BoldText>
            </InfoRowSplit>
          </TopContainer>
          <TopContainer>
            <InfoRowSplit>
              {t('ETA:')}
              <BoldText>{vessel.current_eta ? dayjs(vessel.current_eta).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('ETD:')}
              <BoldText>{vessel.current_etd ? dayjs(vessel.current_etd).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
            </InfoRowSplit>
            <InfoRowSplit>
              {t('Agent:')}
              <BoldText>{vessel.agent || 'Unknown'}</BoldText>
            </InfoRowSplit>
          </TopContainer>
        </FirstColumnSplit>
        <Tabs
          style={{ marginLeft: '10px' }}
          activeKey={serviceOrderModalTab}
          onTabClick={key => setServiceOrderModalTab(key)}
          size="small"
          items={tabItems}
        />
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default ServiceOrderModal;
