import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: inline-block;
  color: ${({ theme }) => theme.color.grey_dark};
  background: transparent;
  color: ${props => props.disabled && '#a8a8a8'};
  background-color: ${props => props.disabled && '#f5f5f5'};
  border: none;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  cursor: ${props => props.disabled && 'default'};
  padding: 10px 12px;
  transition: ${({ theme }) => theme.transition.ease(0.2)};
  white-space: nowrap;
  line-height: 1.1rem;
  width: 100%;
  height: 36px;
  &:hover {
    background-color: #f2f2f2;
  }
  i {
    fill: currentColor;
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    top: -1px;
  }
  ${props => {
    if (props.red) {
      return `
        color: ${!props.disabled && props.theme.color.warning};

        i {
          color: ${!props.disabled && props.theme.color.warning};
        }
      `;
    }
  }}
`;

const ListActionButton = props => <StyledButton {...props} />;

export default ListActionButton;
