import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import App from 'antd/es/app';
import Modal from 'antd/es/modal';
import { MapContainer as Map } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT_DAY, TIME_FORMAT_HOURS_MINUTES, mobilePixelMaxWidthLimit } from '../../utils/constants';
import Icon from '../ui/Icon';
import IncidentMapInnerContainer from './IncidentMapInnerContainer';
import useApi from '../../hooks/useApi';
import { optionLists } from './incidentWizardData';
import { AlertContext } from '../../context/AlertContext';
import ListActionButton from '../ui/ListActionButton';
import ButtonLight from '../ui/ButtonLight';
import AddIncidentModal from './AddIncidentModal';
import IncidentHistory from './IncidentHistory';
import { darken } from 'polished';
import { NotificationContext } from '../../context/NotificationContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';
import dayjs from 'dayjs';

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  width: 100%;
`;

const BannerLoader = styled.div`
  margin-left: 12px;
  height: 10px;
`;

const NoIncidentData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const Structure = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 12px 0 24px 24px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  margin-right: 18px;

  table {
    padding-bottom: 30px !important;
  }
`;

const BackIconContainer = styled.div`
  margin-right: 8px;

  i {
    width: 18px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const IconContainer = styled.div`
  margin-right: 18px;
  margin-top: 3px;

  i {
    width: 44px;
  }

  svg {
    width: 44px;
    height: 44px;
    color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
`;

const Title = styled.div`
  font-size: 22px;
  margin-bottom: 16px;
  max-width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TextAndMap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 18px;
  min-height: 240px;

  @media (max-width: 650px) {
    display: inline-block;
  }
`;

const SideContainer = styled.div`
  width: calc(50% - 24px);
  margin-right: 24px;

  @media (max-width: 650px) {
    width: calc(100% - 24px);
  }
`;

const MapContainer = styled.div`
  width: calc(50% - 24px);
  margin-right: 24px;
  height: calc(100vh - 308px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 294px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 650px) {
    width: calc(100% - 24px);
    max-height: 400px;
  }
`;

const Time = styled.div`
  display: flex;
  margin-left: 74px;
  margin-top: -24px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
  background-color: white;
  padding: 12px 12px 6px 12px;
  display: flex;
  width: calc(100% - 24px);
`;

const InfoValue = styled.div`
  text-align: center;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
`;

const DetailsBox = styled.div`
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: 3px;
  padding: 24px;
  margin-bottom: 24px;
`;

const Details = styled.div`
  text-align: left;
  white-space: pre-line;
`;

const DetailsPart = styled.div`
  margin-bottom: 18px;
  display: flex;
`;

const DetailsTitle = styled.div`
  text-align: left;
  margin-right: 12px;
  margin-bottom: 12px;
  line-height: 27px;
`;

const ListOfVessels = styled.div`
  min-height: 34px;
`;

const SelectedVessel = styled.div`
  border-radius: 4px;
  padding: 2px 6px;
  border: 1px solid #b1b1b1;
  display: inline-flex;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const MiddleLine = styled.div`
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  margin-bottom: 12px;
`;

const MoreActions = styled.div`
  position: absolute;
  right: 36px;
  top: 36px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  z-index: 200;
  background-color: white;
`;

const TopRow = styled.div`
  padding: 4px 16px 2px 16px;
  display: flex;
  justify-content: space-between;
  min-width: 160px;
`;

const ChevronIconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 18px;

  svg {
    margin-top: 5px;
  }
`;

const PopoverInner = styled.div`
  display: inline-block;
  width: 100%;
  border-top: 1px solid #e8e8e8;
`;

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const InfoText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const MapIconContainer = styled.div`
  margin-top: -2px;
  cursor: pointer;
  margin-left: 12px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const GoBack = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.color.secondary)};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const StatusIconContainer = styled.i`
  display: inline-block;
  position: relative;
  height: ${props => props.height || '1em'};
  width: ${props => props.width || '1em'};
  svg {
    display: inline-block;
    fill: ${props => props.fill || 'currentColor'};
    color: ${props => props.color || 'currentColor'};
    text-align: center;
    height: ${props => props.height || 'unset'};
    width: ${props => props.width || 'unset'};
  }
`;

const EventIcon = styled.div`
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: ${props => props.theme.color.secondary};
  color: white;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 4px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Tag = styled.div`
  box-shadow: ${({ theme }) => theme.fx.box_shadow_darker};
  border-radius: 3px;
  padding: 6px 12px;
  display: flex;
  margin-bottom: 12px;
  margin-right: 12px;
  background-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.white};
  font-weight: 600;
`;

const LevelTag = styled.div`
  box-shadow: ${({ theme }) => theme.fx.box_shadow_darker};
  border-radius: 3px;
  padding: 6px 12px;
  display: flex;
  margin-bottom: 12px;
  margin-right: 12px;
  font-weight: 600;
  background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  color: ${props => (props.level > 7 ? '#ffffff' : props.level < 4 ? '#4a4a4a' : '#4a4a4a')};
`;

const InfoValueNumber = styled(InfoValue)`
  font-size: 20px;
  font-weight: 700;
  margin-left: 12px;
`;

const Incident = ({ id }) => {
  const { namespace, user, apiCall /*, refreshSession*/ } = useContext(UserContext);
  const { getSummaries } = useContext(NotificationContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { mapDefaultCoordinates, refreshingSession } = useContext(UserContext);
  let appliedCoordinates = mapDefaultCoordinates === undefined ? '59.129089,14.373028' : mapDefaultCoordinates;

  const { alerts, addAlert, alertsHeight, removeAlert } = useContext(AlertContext);

  const [appliedZoom, setAppliedZoom] = useState(7);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);

  const [handlingBanner, setHandlingBanner] = useState(false);

  const [areaMapCenter, setAreaMapCenter] = useState(null);

  const [actionsOpen, setActionsOpen] = useState(false);

  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const history = useHistory();

  const { loading, data, error, fetchData } = useApi('get', 'incident/' + id);

  const incident = error || !data ? {} : data.incident; //eslint-disable-line react-hooks/exhaustive-deps

  const refreshingRef = useRef();
  const incidentRead = useRef(incident?.read);

  useEffect(() => {
    if (handlingBanner && refreshingRef.current && !refreshingSession) {
      setHandlingBanner(false);
    }

    refreshingRef.current = refreshingSession;
  }, [handlingBanner, refreshingSession]);

  useEffect(() => {
    const checkIncidentRead = async () => {
      if (incident?.read) {
        incidentRead.current = true;
      }
      if (incident?.id && !incidentRead.current) {
        await apiCall('post', 'incidents-read', {
          incident_ids: [incident.id],
          is_read: true,
        });
        incidentRead.current = true;
        getSummaries();
      }
    };

    checkIncidentRead();
  }, [apiCall, getSummaries, incident]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#actions-modal');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setActionsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const PopoverContent = () => {
    return (
      <PopoverInner>
        <ButtonContainer style={{ marginTop: '4px' }}>
          {incident?.status === 'open' ? (
            <ListActionButton disabled={loading || loadingApi} onClick={() => setShowEdit(true)}>
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ) : null}
        </ButtonContainer>
        <ButtonContainer>
          <ListActionButton disabled={loading || loadingApi} onClick={() => setShowChangeStatus(true)}>
            <StatusIconContainer>
              {incident?.status === 'open' ? <LockOutlined /> : <UnlockOutlined />}
            </StatusIconContainer>
            {incident?.status === 'open' ? t('Close') : t('Reopen')}
          </ListActionButton>
        </ButtonContainer>
        <ButtonContainer>
          <ListActionButton disabled={loading || loadingApi} onClick={() => setShowDelete(true)} red>
            <Icon type="trash" />
            {t('Delete')}
          </ListActionButton>
        </ButtonContainer>
        {user.permissions.includes('manage_all_user') &&
          Object.keys(incident).length > 0 &&
          incident.status !== 'closed' &&
          currentAlert && (
          <ButtonContainer>
            <ListActionButton
              disabled={loading || loadingApi || handlingBanner}
              onClick={() => handleRemoveBanner(currentAlert)}
              red
              style={{ display: 'flex' }}
            >
              <Icon type="banner" />
              {t('Remove banner')}

              {handlingBanner && (
                <BannerLoader>
                  <Spin size="small" />
                </BannerLoader>
              )}
            </ListActionButton>
          </ButtonContainer>
        )}
        {user.permissions.includes('manage_all_user') &&
          Object.keys(incident).length > 0 &&
          incident.status !== 'closed' &&
          !currentAlert && (
          <ButtonContainer>
            <ListActionButton
              disabled={loading || loadingApi || handlingBanner}
              onClick={() => handleAddBanner(incident)}
              style={{ display: 'flex' }}
            >
              <Icon type="banner" />
              {t('Add banner')}
              {handlingBanner && (
                <BannerLoader>
                  <Spin size="small" />
                </BannerLoader>
              )}
            </ListActionButton>
          </ButtonContainer>
        )}
      </PopoverInner>
    );
  };

  const handleDelete = async () => {
    setDeleteDisabled(true);

    try {
      await apiCall('delete', 'incident/' + incident.id);
    } catch (e) {
      setDeleteDisabled(false);
    }

    history.push('/incidents-and-events');
  };

  const updateData = async data => {
    setLoadingApi(true);
    try {
      const result = await apiCall('put', 'incident', {
        incident: {
          ...data,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
        return false;
      }
    } catch (e) {
      setLoadingApi(false);
      return false;
    }
    setLoadingApi(false);
    fetchData();

    return true;
  };

  const changeStatus = async () => {
    setShowChangeStatus(false);
    setLoadingApi(true);
    try {
      const result = await apiCall('put', 'incident', {
        incident: {
          id: incident.id,
          status: incident.status === 'open' ? 'closed' : 'open',
          type: incident.type,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setLoadingApi(false);
      throw e;
    }
    setLoadingApi(false);
    await fetchData();
  };

  const handleAddBanner = async incident => {
    setHandlingBanner(true);
    await addAlert(incident.type, incident);
    setTimeout(() => {
      if (handlingBanner) {
        setHandlingBanner(false);
      }
    }, 10000);
  };

  const handleRemoveBanner = async alert => {
    setHandlingBanner(true);
    await removeAlert(alert);
    setTimeout(() => {
      if (handlingBanner) {
        setHandlingBanner(false);
      }
    }, 10000);
  };

  const createAreasList = () => {
    return (
      <ListOfVessels style={{ lineHeight: '21px' }}>
        {incident?.data?.geojson.map((area, index) => (
          <SelectedVessel key={index} onClick={() => setAreaMapCenter(area)} style={{ cursor: 'pointer' }}>
            {t('Area')} {index + 1}
            <MapIconContainer>
              <Icon type="marker" />
            </MapIconContainer>
          </SelectedVessel>
        ))}
      </ListOfVessels>
    );
  };

  const currentAlert = alerts.find(alert =>
    incident ? alert.type === incident.type && alert.incident?.id === incident.id : false
  );

  return (
    <>
      {user.permissions.includes('manage_incident') && !!incident && namespace !== 'vts' && (
        <MoreActions id="actions-modal" ref={ref}>
          <TopRow onClick={() => setActionsOpen(!actionsOpen)}>
            {t('Actions')}{' '}
            {!actionsOpen ? (
              <ChevronIconContainer>
                <Icon type="chevron-down" />
              </ChevronIconContainer>
            ) : (
              <ChevronIconContainer>
                <Icon type="chevron-up" />
              </ChevronIconContainer>
            )}
          </TopRow>
          {actionsOpen && PopoverContent()}
        </MoreActions>
      )}

      {loadingApi || loading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : incident ? (
        <Structure>
          <GoBack onClick={() => history.push('/incidents-and-events')}>
            <BackIconContainer>
              <Icon type="arrow-left" />
            </BackIconContainer>
            {t('Back to list view')}
          </GoBack>
          <Header>
            {incident.type === 'event' ? (
              <IconContainer style={{ marginRight: '24px' }}>
                <EventIcon>E</EventIcon>
              </IconContainer>
            ) : (
              <IconContainer>
                <Icon
                  type="exclamation-triangle"
                  fill={
                    incident.type === 'event'
                      ? '#d7b6f2'
                      : incident.incident_type_name
                        ? optionLists.incident_type.find(t => t.value === incident.incident_type_name)?.color
                        : '#4a4a4a'
                  }
                />
              </IconContainer>
            )}
            <Title style={{ marginTop: '0px' }}>
              {incident.type === 'incident' ? (
                <>
                  {incident.incident_type_name}{' '}
                  <span style={{ textTransform: 'lowercase' }}>{incident.location_option_name}</span>
                </>
              ) : incident.details ? (
                incident.details
              ) : (
                t('Event')
              )}
            </Title>
          </Header>
          <Time>
            {dayjs(incident.date).format(TIME_FORMAT_DAY)}
            <span style={{ fontWeight: 600, paddingLeft: '6px' }}>
              {dayjs(incident.date).format(TIME_FORMAT_HOURS_MINUTES)}
            </span>
            {incident.end_date && (
              <div style={{ marginLeft: '4px' }}>
                <span>-</span> {dayjs(incident.end_date).format(TIME_FORMAT_DAY)}
                <span style={{ fontWeight: 600, paddingLeft: '6px' }}>
                  {dayjs(incident.end_date).format(TIME_FORMAT_HOURS_MINUTES)}
                </span>
              </div>
            )}

            <span
              style={{
                paddingLeft: '30px',
                fontSize: '16px',
                lineHeight: '20px',
                fontStyle: 'italic',
              }}
            >
              {incident.status}
            </span>
          </Time>
          <TextAndMap>
            <SideContainer>
              <Tags>
                {incident.type === 'incident' && (
                  <LevelTag level={incident.level_of_emergency}>
                    {t('Level of emergency')}
                    <InfoValueNumber>{incident.level_of_emergency}</InfoValueNumber>
                  </LevelTag>
                )}
                {namespace === 'vts' && <Tag>{incident.port_name}</Tag>}
                {currentAlert && <Tag>{t('Banner visible')}</Tag>}
                {!!incident.people_in_danger && <Tag>{t('People in danger')}</Tag>}
                {!!incident.vessels_involved && <Tag>{t('Vessels involved')}</Tag>}
                {!!incident.visible_to_authority && namespace !== 'vts' && <Tag>{t('Visible to authority')}</Tag>}
              </Tags>
              <DetailsBox>
                {incident.vessels_involved === true && (
                  <DetailsPart>
                    <DetailsTitle>{t('Involved vessels')}:</DetailsTitle>
                    <ListOfVessels>
                      {incident?.data?.vessels?.map((vessel, i) => (
                        <SelectedVessel key={i}>
                          {vessel.name} ({vessel.imo})
                        </SelectedVessel>
                      ))}
                    </ListOfVessels>
                  </DetailsPart>
                )}
                <DetailsPart>
                  <DetailsTitle>
                    {incident?.data?.geojson.length > 0
                      ? incident?.data?.geojson[0]?.geometry.type === 'Point'
                        ? t('Coordinates (lat, lon)') + ': '
                        : t('Areas') + ': '
                      : t('No location set.')}
                  </DetailsTitle>
                  <ListOfVessels style={{ lineHeight: '27px' }}>
                    {incident?.data?.geojson.length > 0 &&
                      (incident?.data?.geojson[0]?.geometry.type !== 'Point'
                        ? createAreasList()
                        : '(' +
                          incident?.data?.geojson[0]?.geometry.coordinates[1] +
                          ', ' +
                          incident?.data?.geojson[0]?.geometry.coordinates[0] +
                          ')')}
                  </ListOfVessels>
                </DetailsPart>
                <MiddleLine />
                <Details>{incident.details || '-'}</Details>
                <Details style={{ display: 'flex', marginTop: '36px' }}>
                  <DetailsTitle style={{ marginRight: '18px' }}>{t('Added by')}:</DetailsTitle>
                  <DetailsTitle>{incident.data.created_by_email}</DetailsTitle>
                </Details>
              </DetailsBox>
              {incident.changelog.length > 1 && (
                <DetailsBox>
                  <IncidentHistory historyData={incident.changelog} type={incident.type} />
                </DetailsBox>
              )}
            </SideContainer>
            <MapContainer alertsHeight={alertsHeight}>
              <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small', t)} onError={logError}>
                <Map
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  center={
                    incident?.data?.geojson.length > 0
                      ? incident?.data?.geojson[0]?.geometry.type === 'Point'
                        ? [
                            incident?.data?.geojson[0]?.geometry.coordinates[1],
                            incident?.data?.geojson[0]?.geometry.coordinates[0],
                          ]
                        : [
                            incident?.data?.geojson[0]?.geometry.coordinates[0][0][1],
                            incident?.data?.geojson[0]?.geometry.coordinates[0][0][0],
                          ]
                      : appliedCoordinates.split(',')
                  }
                  zoom={appliedZoom}
                  tap={false}
                  zoomControl={false}
                >
                  <IncidentMapInnerContainer
                    zoom={appliedZoom}
                    setZoom={setAppliedZoom}
                    incidentData={incident}
                    level={incident.level_of_emergency}
                    titleType={incident.incident_type_name}
                    titleLocation={incident.location_option_name}
                    date={
                      dayjs(incident.date).format(TIME_FORMAT_DAY) +
                      ' ' +
                      dayjs(incident.date).format(TIME_FORMAT_HOURS_MINUTES)
                    }
                    setAreaMapCenter={setAreaMapCenter}
                    areaMapCenter={areaMapCenter}
                  />
                </Map>
              </ErrorBoundary>
            </MapContainer>
          </TextAndMap>
        </Structure>
      ) : (
        <NoIncidentData>{t('No data available.')}</NoIncidentData>
      )}

      {showDelete && (
        <Modal
          title={t('Delete {{type}}', { type: incident.type })}
          open={true}
          width={600}
          footer={null}
          headStyle={{ backgroundColor: 'red' }}
          onCancel={() => setShowDelete(false)}
        >
          <ModalInner>
            <DeleteIconContainer>
              <Icon type="remove-illustration"></Icon>
            </DeleteIconContainer>
            <InfoText>{t('Are you sure you want to delete this {{type}}?', { type: incident.type })}</InfoText>
            <MiddleLine />
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={() => setShowDelete(false)}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight red onClick={handleDelete} disabled={deleteDisabled}>
                {t('Delete')}
              </ButtonLight>
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}
      {!!incident && showEdit && (
        <AddIncidentModal
          closeModal={() => setShowEdit(false)}
          addData={updateData}
          editData={incident}
          sendDisabled={loadingApi}
        />
      )}
      {showChangeStatus && (
        <Modal
          title={
            incident?.status === 'open'
              ? t('Close {{type}}', { type: incident.type })
              : t('Reopen {{type}}', { type: incident.type })
          }
          open={true}
          width={600}
          footer={null}
          onCancel={() => setShowChangeStatus(false)}
        >
          <ModalInner>
            <InfoText style={{ height: 'auto', marginBottom: '12px', padding: '24px 0' }}>
              {t('Are you sure you want to {{action}} this {{type}}?', {
                action: incident.status === 'open' ? 'close' : 'reopen',
                type: incident.type,
              })}
            </InfoText>
            <MiddleLine />
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={() => setShowChangeStatus(false)}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight onClick={changeStatus}>{incident?.status === 'open' ? t('Close') : t('Reopen')}</ButtonLight>
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}
    </>
  );
};
export default Incident;
