import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import { sideModalWidth } from '../commonCalendar/utility/constants';
import Icon from '../ui/Icon';
import PortcallCarousel from './PortcallCarousel';
import PortcallData from './PortcallData';
import AddEditPortcall from './AddEditPortcall';

const SidePanelContainer = styled.div`
  width: ${sideModalWidth}px;
  min-height: 100%;
  overflow-y: auto;
`;

const SidePanel = styled.div`
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 35%);
  margin-left: 2px;
  width: calc(100% - 2px);
  padding: 7px 0px 4px 12px;
  min-height: 100%;

  .ant-tabs-tab {
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const CloseIcon = styled.a`
  top: 6px;
  left: 6px;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.color.grey_dark};
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const Common = styled.div`
  display: flex;
  padding: 0 10 0 0px;
  font-size: 13px;
`;

const SecondColumn = styled.div`
  max-width: 200px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: 400;
  line-height: 26px;
`;

const TooltipValue = styled.div`
  font-weight: 600;
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
`;

const AddContainer = styled.div`
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 3px;
`;

const AddPortCall = styled.div`
  cursor: pointer;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.secondary)};

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -2px;
    height: 14px;
    width: 14px;
  }
`;

const ShowRawAisDataLink = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #4990dd;
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
  cursor: pointer;
  line-height: ${props => (props.rowHeight > 20 ? 22 : 14)}px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const VesselSidePanel = ({
  sideModalOpen,
  setSideModalOpen,
  otherNamespaceData,
  vesselView = false,
  showSeaPassage,
  setShowSeaPassage,
  setSeaPassageData,
  innerMap = false,
  startTime,
}) => {
  const { namespace, apiCall, modules, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [dataFetched, setDataFetched] = useState(false);
  const [data, setData] = useState(undefined);
  const [vesselInfoData, setVesselInfoData] = useState(undefined);
  const [currentTab, setCurrentTab] = useState('2');

  const [currentImo, setCurrentImo] = useState(sideModalOpen.imo);
  const [currentPortcallId, setCurrentPortcallId] = useState(sideModalOpen.port_call_id);
  const [currentVesselName, setCurrentVesselName] = useState(sideModalOpen.name);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [portList, setPortList] = useState([]);

  const [scrolled, setScrolled] = useState(false);

  const portcallRef = useRef();
  const currentPortcallIdRef = useRef();

  const [rawAisDataVisible, setRawAisDataVisible] = useState(false);

  useEffect(() => {
    if (currentImo !== sideModalOpen.imo || currentVesselName != sideModalOpen.name) {
      setCurrentImo(sideModalOpen.imo);
      setCurrentVesselName(sideModalOpen.name);
      setCurrentPortcallId(sideModalOpen.port_call_id);
      setData(undefined);
      setVesselInfoData(undefined);
      setDataFetched(false);
      setScrolled(false);
    }
  }, [
    currentImo,
    currentPortcallId,
    currentVesselName,
    sideModalOpen.imo,
    sideModalOpen.name,
    sideModalOpen.port_call_id,
  ]);

  useEffect(() => {
    if (portcallRef.current !== sideModalOpen.port_call_id) {
      setCurrentPortcallId(sideModalOpen.port_call_id);
      setData(undefined);
      setVesselInfoData(undefined);
      setDataFetched(false);
      setScrolled(false);
    }
    portcallRef.current = sideModalOpen.port_call_id;
  }, [setShowSeaPassage, sideModalOpen.port_call_id]);

  useEffect(() => {
    if (!innerMap && currentPortcallIdRef.current !== currentPortcallId && showSeaPassage && data?.length > 0) {
      const currPortcall = data.find(d => d.port_call_id === currentPortcallId);
      if (currPortcall?.sea_passage?.route) {
        setSeaPassageData(currPortcall.sea_passage.route);
      } else {
        setSeaPassageData(undefined);
      }
    }
    currentPortcallIdRef.current = currentPortcallId;
  }, [currentPortcallId, data, innerMap, setSeaPassageData, showSeaPassage]);

  useEffect(() => {
    if (!currentPortcallId && data?.length > 0) {
      setCurrentPortcallId(data[0].port_call_id);
    }
  }, [currentPortcallId, data]);

  useEffect(() => {
    const getData = async () => {
      const vesselData = await apiCall('post', 'fleet/search/portcall,manual-portcall', {
        query: {
          conditions: {
            and: [
              currentImo
                ? {
                    type: 'imo',
                    operator: 'eq',
                    value: currentImo,
                  }
                : {
                    type: 'vessel_name',
                    operator: 'is',
                    value: currentVesselName,
                  },
              {
                type: 'is_cancelled',
                operator: 'is',
                value: false,
              },
              {
                or: [
                  {
                    type: 'eta',
                    operator: 'gte',
                    value: dayjs(startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
                  },
                  {
                    and: [
                      {
                        type: 'etd',
                        operator: 'gte',
                        value: dayjs(startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
                      },
                      {
                        type: 'eta',
                        operator: 'lte',
                        value: dayjs(startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        pagination: {
          limit: 100,
        },
        order_by: [
          {
            field: 'eta',
            order: 'asc',
          },
        ],
        group_by: 'vessel_name',
      });

      if (vesselData) {
        if (Object.keys(vesselData?.data?.results?.portcalls || {}).length === 0) {
          setData([]);
        } else {
          setData(Object.values(vesselData.data.results.portcalls)[0]);
        }
      }

      const infoData = await apiCall('post', 'fleet/search/portcall,manual-portcall', {
        query: {
          conditions: {
            and: [
              currentImo
                ? {
                    type: 'imo',
                    operator: 'eq',
                    value: currentImo,
                  }
                : {
                    type: 'vessel_name',
                    operator: 'is',
                    value: currentVesselName,
                  },
              {
                type: 'is_cancelled',
                operator: 'is',
                value: false,
              },
            ],
          },
        },
        pagination: {
          limit: 1,
        },
        order_by: [
          {
            field: 'eta',
            order: 'desc',
          },
        ],
        group_by: 'vessel_name',
      });

      if (infoData) {
        if (Object.keys(infoData?.data?.results?.portcalls || {}).length === 0) {
          setVesselInfoData([]);
        } else {
          setVesselInfoData(Object.values(infoData.data.results.portcalls)[0]);
        }
      }

      const listOfPorts = await apiCall('post', 'fleet/search/port', {
        order_by: [
          {
            field: 'port_name',
            order: 'asc',
          },
        ],
      });

      if (listOfPorts?.data?.results?.ports) {
        setPortList(listOfPorts?.data?.results?.ports);
      }
    };

    if (!dataFetched) {
      setDataFetched(true);
      getData();
    }
  }, [apiCall, currentImo, currentVesselName, dataFetched, setShowSeaPassage, sideModalOpen, startTime]);

  const portcallChanged = useCallback(() => {
    setData(undefined);
    setVesselInfoData(undefined);
    setDataFetched(false);
    setScrolled(false);
  }, []);
  useUserSocket('fleet-portcalls-changed', portcallChanged);
  useUserSocket('fleet-manual-portcalls-changed', portcallChanged);

  const VesselInfo = () => {
    if (!vesselInfoData || vesselInfoData.length === 0) {
      return <NoData>{t('No portcalls found.')}</NoData>;
    }

    let vessel = vesselInfoData[0];
    let aisEtaToDisplay = null;
    let aisUpdatedToDisplay = null;
    let rawAisDataKeys = [];
    let rawAisDataValues = [];
    if (vessel?.data?.aisData) {
      const aisData = vessel.data.aisData;
      if (aisData.formattedEta) {
        aisEtaToDisplay =
          dayjs(aisData.formattedEta)
            .utc()
            .format('DD.MM. HH:mm') + ' UTC';
      }
      if (aisData.updated_at) {
        aisUpdatedToDisplay =
          dayjs(aisData.updated_at)
            .utc()
            .format('DD.MM. HH:mm') + ' UTC';
      }

      for (const aisDataKey of Object.keys(aisData).sort((a, b) => {
        return a.localeCompare(b);
      })) {
        rawAisDataKeys.push(aisDataKey);
        let storeValue = aisData[aisDataKey];
        if (storeValue !== null || storeValue !== undefined) {
          storeValue = '' + storeValue;
        }
        rawAisDataValues.push(storeValue);
      }
    }

    return (
      <Common>
        <FirstColumn>
          <RowHeaderForTooltip>{t('Type')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('MMSI')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('IMO')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('LOA')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('Draft')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('Beam')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('AIS destination')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('AIS ETA')}:</RowHeaderForTooltip>
          <RowHeaderForTooltip>{t('AIS updated')}:</RowHeaderForTooltip>
          {!rawAisDataVisible && (
            <ShowRawAisDataLink onClick={() => setRawAisDataVisible(true)}>{t('Show raw AIS data')}</ShowRawAisDataLink>
          )}
          {rawAisDataVisible && (
            <ShowRawAisDataLink onClick={() => setRawAisDataVisible(false)}>
              {t('Hide raw AIS data')}
            </ShowRawAisDataLink>
          )}
          {rawAisDataVisible &&
            rawAisDataKeys.length > 0 &&
            rawAisDataKeys.map((value, index) => <RowHeaderForTooltip key={index}>{value}:</RowHeaderForTooltip>)}
        </FirstColumn>
        <SecondColumn>
          <TooltipValue>{vessel?.data?.vesselInfo?.vesselTypeName || '-'}</TooltipValue>
          <TooltipValue>{vessel?.mmsi || '-'}</TooltipValue>
          <TooltipValue>{vessel?.imo || '-'}</TooltipValue>
          <TooltipValue>{vessel?.data?.loa || '-'}</TooltipValue>
          <TooltipValue>{vessel?.data?.draft || '-'}</TooltipValue>
          <TooltipValue>{vessel?.data?.beam || '-'}</TooltipValue>
          <TooltipValue>{vessel?.data?.aisData?.destination || '-'}</TooltipValue>
          <TooltipValue>{aisEtaToDisplay || '-'}</TooltipValue>
          <TooltipValue>{aisUpdatedToDisplay || '-'}</TooltipValue>
          <ShowRawAisDataLink>&nbsp;</ShowRawAisDataLink>
          {rawAisDataVisible &&
            rawAisDataKeys.length > 0 &&
            rawAisDataValues.map((value, index) => <TooltipValue key={index}>{value || '-'}</TooltipValue>)}
        </SecondColumn>
      </Common>
    );
  };

  const onAddPortCall = () => {
    if (!addModalOpen) {
      setAddModalOpen(true);
    }
  };

  const tabItems = [
    {
      label: <span>{t("Vessel's info")}</span>, // eslint-disable-line quotes
      children: VesselInfo(),
      key: '1',
    },
    {
      label: <span>{t("Port call's info")}</span>, // eslint-disable-line quotes
      children: (
        <>
          {data?.length === 0 && <NoData>{t('No portcalls found.')}</NoData>}
          <PortcallCarousel
            data={data}
            currentPortcall={currentPortcallId}
            setCurrentPortcall={setCurrentPortcallId}
            scrolled={scrolled}
            setScrolled={setScrolled}
          />
          {modules.fleet_planning_module === 'enabled' && (
            <Row>
              <AddPortCall onClick={onAddPortCall} disabled={addModalOpen}>
                <Icon type="circle-plus" />
                {t('Add port call')}
              </AddPortCall>
            </Row>
          )}
          {addModalOpen && data?.length === 0 && (
            <AddContainer>
              <AddEditPortcall
                add={addModalOpen}
                portcall={undefined}
                imo={currentImo}
                portList={portList}
                setAddModalOpen={setAddModalOpen}
              />
            </AddContainer>
          )}
          <PortcallData
            portcall={data ? data.find(d => d.port_call_id === currentPortcallId) : null}
            otherNamespaceData={otherNamespaceData}
            addModalOpen={addModalOpen}
            setAddModalOpen={setAddModalOpen}
            portList={portList}
            vesselView={vesselView}
            imo={currentImo}
            currentPortcall={currentPortcallId}
            setShowSeaPassage={setShowSeaPassage}
            showSeaPassage={showSeaPassage}
            setSeaPassageData={setSeaPassageData}
            innerMap={innerMap}
          />
        </>
      ),
      key: '2',
    },
  ];

  return (
    <SidePanelContainer id="vessel-side-panel">
      <SidePanel>
        <Spin spinning={!data || !vesselInfoData} size="medium">
          <HeaderRow>
            <Header>
              {t('Details of')} <span style={{ fontWeight: 700 }}>{currentVesselName}</span>
            </Header>
            <CloseIcon data-testid={'vessel-side-panel-close'} href="#" onClick={() => setSideModalOpen(undefined)}>
              <Tooltip
                title={t('Close side panel')}
                color="white"
                overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                placement="left"
              >
                <div>
                  <Icon type="close" />
                </div>
              </Tooltip>
            </CloseIcon>
          </HeaderRow>

          <Tabs activeKey={currentTab} onTabClick={key => setCurrentTab(key)} size="small" items={tabItems} />
        </Spin>
      </SidePanel>
    </SidePanelContainer>
  );
};

export default VesselSidePanel;
