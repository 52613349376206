import React, { useRef, useState } from 'react';
import { Marker, Tooltip, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';

import VesselTooltip from './VesselTooltip';
import VesselPopup from './VesselPopup';

import { getOptions } from './MapUtils';
import { onMarkerMouseOver } from './MapHelpers';

const CurrentMapVessel = ({
  feature,
  isPublicMap,
  appliedZoom,
  setRoute,
  gray = false,
  setSideModalOpen,
  fleetViewOn,
  fleetData,
}) => {
  const [direction, setDirection] = useState('top');

  const map = useMap();

  const vesselRef = useRef();
  const options = getOptions();

  const markerClass = gray ? 'vessel_gray' : feature.properties.marker_class;

  const getVesselIcon = (feature, appliedZoomLevel) => {
    const iconSizeStyle =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? '-small'
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? '-medium'
          : '-large';

    const vesselIconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselIconPixelSize
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? options.mediumVesselIconPixelSize
          : options.largeVesselIconPixelSize;

    const courseIconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselCourseIconPixelSize
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? options.mediumVesselCourseIconPixelSize
          : options.largeVesselCourseIconPixelSize;

    let markerStyleClass = 'marker-image-vessel' + iconSizeStyle;
    let markerCourseStyleClass = 'marker-image-course-over-ground' + iconSizeStyle;
    let markerIconFile =
      options.vesselColorCurrentToIconMappings[markerClass] !== undefined
        ? options.vesselColorCurrentToIconMappings[markerClass]
        : options.vesselColorCurrentToIconMappings['defaultColor'];

    let vesselMarkerProperties = {
      markerStyle: markerStyleClass,
      markerCourseStyle: markerCourseStyleClass,
      markerIconSize: vesselIconSize * 1.7,
      courseIconSize: courseIconSize * 1.0,
      courseIconOffsetTop: vesselIconSize * 1.7 - courseIconSize * 2.95,
      courseIconOffsetLeft: vesselIconSize * 1.7 - courseIconSize * 3,
      markerIconFile: markerIconFile,
    };

    let vesselRotationDegrees = feature.properties.heading_degrees ? feature.properties.heading_degrees : '0';

    let htmlContent = `<div class="div-map-marker-vessel">
        <img class="${vesselMarkerProperties.markerStyle}"
          src="${vesselMarkerProperties.markerIconFile}"
          style="transform:rotate(${vesselRotationDegrees}deg);
          height:${vesselMarkerProperties.markerIconSize}px;
          left: 0%;
          important;" />`;
    if (feature.properties.course_over_ground_degrees) {
      htmlContent += `<div class="${vesselMarkerProperties.markerCourseStyle}"
          style="transform:rotate(${feature.properties.course_over_ground_degrees}deg);
          height:${vesselMarkerProperties.courseIconSize}px;
          width:${vesselMarkerProperties.courseIconSize}px;
          top:${vesselMarkerProperties.courseIconOffsetTop}px;
          left:${vesselMarkerProperties.courseIconOffsetLeft}px;
          important;" />`;
    }
    htmlContent += '</div>';

    return L.divIcon({
      html: htmlContent,
      iconSize: [vesselMarkerProperties.markerIconSize, vesselMarkerProperties.markerIconSize],
      iconAnchor: [vesselMarkerProperties.markerIconSize / 2, vesselMarkerProperties.markerIconSize / 2],
      className: 'map-vessel-icon-class',
    });
  };

  const setRouteOnClick = () => {
    if (setRoute) {
      setRoute(feature.properties.imo, markerClass);
    }
    if (setSideModalOpen) {
      setSideModalOpen({
        imo: feature.properties.imo,
        name: feature.properties.name,
        port_call_id: undefined,
        eta: undefined,
      });
    }
  };

  let fleetTooltipData = undefined;

  if (fleetViewOn) {
    fleetTooltipData = fleetData
      .filter(vessel => vessel.imo === feature.properties.imo)
      .sort((vessel1, vessel2) => vessel1.eta - vessel2.eta);
  }

  return (
    <Marker
      icon={getVesselIcon(feature, appliedZoom)}
      position={L.GeoJSON.coordsToLatLng(feature.geometry.coordinates)}
      eventHandlers={{
        click: setRouteOnClick,
        mouseover: () => onMarkerMouseOver(map, feature.geometry.coordinates, direction, setDirection),
      }}
      ref={vesselRef}
    >
      <Tooltip
        direction={direction}
        className={fleetViewOn ? 'fleet-tooltip' : undefined}
        opacity={fleetViewOn ? 1 : 0.9}
        key={`${feature.properties.imo}-${direction}`}
      >
        <VesselTooltip
          feature={feature}
          fleetViewOn={fleetViewOn}
          fleetData={fleetTooltipData ? fleetTooltipData[0] : undefined}
        />
      </Tooltip>
      {!setSideModalOpen && (
        <Popup>
          <VesselPopup feature={feature} isPublicMap={isPublicMap} />
        </Popup>
      )}
    </Marker>
  );
};

export default CurrentMapVessel;
