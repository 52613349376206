import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from './../ui/Icon';

import { LoadingOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import CommitAllModal from './modals/CommitAllModal';

const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;

  @media (max-width: 1500px) {
    display: ${props => props.predictiveBPT && 'block'};
  }

  @media (max-width: 1140px) {
    margin-bottom: 24px;
    margin-right: 12px;
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  line-height: 13px;
  font-size: 12px;
  margin: -4px 6px;
  padding: 8px 8px;
  color: ${props => (props.disabled ? props.theme.color.grey_light : props.theme.color.grey)};

  svg {
    height: 14px;
    width: 14px;
  }

  @media (max-width: 1400px) {
    font-size: 11px;
    line-height: 12px;
    margin: 0 3px;
    padding: 4px 4px;

    svg {
      height: 12px;
      width: 12px;
      margin-top: -2px;
    }
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f3f3f3')};
    border-radius: 4px;
  }
`;

const IconText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 12px;
  }
`;

const LineBetween = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.color.grey};
  margin: 4px 2px;
`;

const LoadingContainer = styled.div`
  height: 18px;
  width: 18px;
  overflow: hidden;
  padding-left: 3px;
  margin-top: -3px;
  margin-left: 6px;
`;

const Part = styled.div`
  display: flex;
`;

const CommittingActions = () => {
  const { namespace, user, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { onRedo, onUndo, undoDisabled, redoDisabled, undoLoader, redoLoader, commitLoader } = useContext(
    BerthPlanningToolContext
  );

  const [commitModalOpen, setCommitModalOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const onClickRedo = () => {
    if (!redoDisabled) {
      onRedo();
    }
  };

  const onClickUndo = () => {
    if (!undoDisabled) {
      onUndo();
    }
  };

  const onClickCommitAll = () => {
    if (user.permissions.includes('manage_bpt') && !undoDisabled) {
      setCommitModalOpen(true);
    }
  };

  const closeCommitAllModal = () => {
    setCommitModalOpen(false);
  };

  return (
    <Container predictiveBPT={modules.predictive_berth_planning_module === 'enabled'}>
      <Part>
        <IconTextContainer onClick={onClickUndo} disabled={!user.permissions.includes('manage_bpt') || undoDisabled}>
          <Icon type="undo-alt"></Icon>
          <IconText>{t('Undo')}</IconText>
        </IconTextContainer>
        <LineBetween />
        <IconTextContainer onClick={onClickRedo} disabled={!user.permissions.includes('manage_bpt') || redoDisabled}>
          <Icon type="redo-alt"></Icon>
          <IconText>{t('Redo')}</IconText>
        </IconTextContainer>
        <LineBetween />
      </Part>
      <Part>
        <IconTextContainer
          onClick={onClickCommitAll}
          disabled={!user.permissions.includes('manage_bpt') || undoDisabled}
        >
          <Icon type="check-dark"></Icon>
          <IconText>{t('Commit all changes')}</IconText>
        </IconTextContainer>

        {undoLoader || redoLoader || commitLoader ? (
          <LoadingContainer>
            <LoadingOutlined />
          </LoadingContainer>
        ) : (
          <LoadingContainer />
        )}
      </Part>

      {commitModalOpen && <CommitAllModal closeModal={closeCommitAllModal} />}
    </Container>
  );
};

export default CommittingActions;
