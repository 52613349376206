import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import Radio from 'antd/es/radio';
import styled from 'styled-components';

import { UserContext } from '../../../../context/UserContext';
import ButtonLight from '../../../ui/ButtonLight';

const ModalInner = styled.div`
  min-height: 150px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RowContainer = styled.div`
  padding: 8px 0 12px 0;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const ChangePublicHolidayStatusModal = ({ closeModal, data, refetchData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    excluded: data.excluded || false,
  });

  const title = t('Change holiday status for ') + data.name;
  const buttonText = t('Save');

  const dataIsValid = () => {
    return true;
  };

  const updateStatus = e => {
    setInputData({ ...inputData, excluded: !e.target.value });
  };

  const sendData = async () => {
    if (!data.excluded && inputData.excluded) {
      await apiCall('post', 'calendar/exclude-public-holiday', {
        local_name: data.localName,
        name: data.name,
      });
    } else if (data.excluded && !inputData.excluded) {
      await apiCall('delete', 'calendar/exclude-public-holiday', {
        id: data.id,
      });
    }

    refetchData();
    closeModal();
  };

  return (
    <Modal title={title} open={true} width={600} footer={null} onCancel={closeModal}>
      <ModalInner>
        <RowContainer>
          <RowHeader style={{ marginBottom: '12px' }}>{t('Holiday status')}</RowHeader>
          <Radio.Group onChange={updateStatus} value={!inputData.excluded}>
            <Radio value={true}>{t('Active')}</Radio>
            <Radio value={false}>{t('Disabled')}</Radio>
          </Radio.Group>
        </RowContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid()} onClick={sendData}>
            {buttonText}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default ChangePublicHolidayStatusModal;
