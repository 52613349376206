import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DatePicker from 'antd/es/date-picker';

import Icon from '../../../../components/ui/Icon';
import { BerthPlanningToolContext } from '../../../../context/BerthPlanningToolContext';
import { UserContext } from '../../../../context/UserContext';

import ButtonLight from '../../../ui/ButtonLight';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.bptManaged ? 'space-around' : 'space-between')};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light2};
  margin-bottom: 1px;
`;

const DatePickerContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 0px;
  border-radius: 3px;
  background-color: white;
  z-index: 81;
  padding: 12px;
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.15);
  margin-left: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => (props.weatherOn ? '12px' : '6px')};
  margin-top: ${props => (props.weatherOn ? '0px' : '6px')};

  @media (max-width: 1099px) {
    margin-top: ${props => (props.weatherOn ? '12px' : '0px')};
    margin-bottom: ${props => (props.weatherOn ? '12px' : '0px')};
  }
`;

const CollapseAll = styled(ButtonLight)`
  font-size: 12px;
  letter-spacing: 0;
  border-radius: 2px;
  width: 68px;
  min-width: 68px;
  height: 19px;
  min-height: 19px;
  padding: 0;
  margin 0;
  margin-left: 5px;
  margin-right: 4px;

  @media (max-width: 1099px) {
    margin-left: 0px;
    margin-right: 4px;
    width: 116px;
    height: 22px;
    margin-bottom: 4px;
  }
`;

const ExpandAll = styled(ButtonLight)`
  border-color: ${({ theme }) => theme.color.grey_light};
  font-size: 12px;
  border-radius: 2px;
  width: 68px;
  min-width: 68px;
  height: 19px;
  min-height: 19px;
  padding: 0;
  margin: 0;

  @media (max-width: 1099px) {
    margin-left: 0px;
    margin-right: 4px;
    width: 116px;
    height: 22px;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 4px;
  display: inline-flex;
  margin-bottom: 18px;

  @media (max-width: 1099px) {
    display: inline-block;
    margin-bottom: 12px;
  }
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: pointer;
`;

const IconContainer2 = styled.div`
  line-height: 21px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
`;

const Text = styled.div`
  margin-right: 2px;

  @media (max-width: 1099px) {
    font-size: 12px;
    margin-right: 0px;
    line-height: 24px;
  }
`;

const MonthContainer = styled.div`
  display: flex;
  line-height: 21px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;

  @media (max-width: 1099px) {
    margin-left: 8px;
  }
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const SidebarHeader = () => {
  const {
    sidebarWidth,
    visibleTimeStart,
    moveForwards,
    moveBackwards,
    changeDate,
    toggleAllRowsOpen,
    setRowsOpen,
  } = useContext(BerthPlanningToolContext);
  const { namespace, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [berthsOpen, toggleBerthsOpen] = useState(false); //eslint-disable-line

  const getRootProps = () => {
    const width = sidebarWidth;
    return {
      style: {
        width,
      },
    };
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  const goBackwards = () => {
    moveBackwards();
  };

  const goForwards = () => {
    moveForwards();
  };

  const onDateChange = date => {
    changeDate(date);
    setCalendarOpen(false);
  };

  const onExpand = () => {
    toggleBerthsOpen(true);
    toggleAllRowsOpen(true);
    setRowsOpen(true);
  };

  const onCollapse = () => {
    toggleBerthsOpen(false);
    toggleAllRowsOpen(false);
    setRowsOpen(false);
  };

  return (
    <Container>
      <HeaderContainer
        data-testid="sidebarHeader"
        {...getRootProps()}
        weatherOn={modules.weather_module === 'enabled'}
        bptManaged={user.permissions.includes('manage_bpt')}
      >
        <IconContainer onClick={goBackwards} style={{ marginLeft: 0 }}>
          <Icon type="chevron-left" />
        </IconContainer>
        <IconContainer onClick={goForwards}>
          <Icon type="chevron-right" />
        </IconContainer>
        <MonthContainer onClick={toggleCalendar}>
          <Text>{dayjs(visibleTimeStart).format('MMM YYYY')}</Text>
          <IconContainer2 id="timeline-calendar-chevron-down">
            <Icon type="chevron-down" />
          </IconContainer2>
        </MonthContainer>
        {calendarOpen && (
          <div>
            <Blocker onClick={closeCalendar} />
            <DatePickerContainer>
              <DatePicker
                format="DD.MM.YYYY"
                style={{
                  width: '288px',
                }}
                defaultValue={visibleTimeStart ? dayjs(visibleTimeStart) : null}
                onChange={onDateChange}
                maxDate={dayjs('2031-01-01T00:00:00')}
                minDate={dayjs('2021-01-01T00:00:00')}
                open={true}
                allowClear={false}
              />
            </DatePickerContainer>
          </div>
        )}
      </HeaderContainer>

      <ButtonContainer bptManaged={user.permissions.includes('manage_bpt')}>
        <CollapseAll cancel onClick={onCollapse}>
          {t('Collapse all')}
        </CollapseAll>
        <ExpandAll cancel onClick={onExpand}>
          {t('Expand all')}
        </ExpandAll>
      </ButtonContainer>
    </Container>
  );
};

export default SidebarHeader;
