import React from 'react';
import styled from 'styled-components';

const StyledPageAction = styled.div`
  @media (min-width: 768px) {
    display: flow-root;
    position: relative;
    margin-bottom: ${({ theme }) => theme.sizing.gap_medium};
    @media print {
      display: none;
    }
    & > button {
      float: right;
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: ${({ theme }) => theme.sizing.gap_medium};
    @media print {
      display: none;
    }
    & > button {
      margin-top: ${({ theme }) => theme.sizing.gap_tiny};
    }
  }
`;

const PageAction = ({ children, ...props }) => {
  return <StyledPageAction {...props}>{children}</StyledPageAction>;
};

export default PageAction;
