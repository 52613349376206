import React, { useContext, useEffect, useState, useRef } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import jwt_decode from 'jwt-decode';

import { UserContext } from '../../context/UserContext';

const WMSChart = ({ show }) => {
  const { apiCall } = useContext(UserContext);

  const [mapProps, setMapProps] = useState(null);

  const [tokenExp, setTokenExp] = useState(null);
  const [newToken, setNewToken] = useState(true);

  const newTokenRef = useRef();

  useEffect(() => {
    const getChartData = async () => {
      let result;
      try {
        result = await apiCall('get', 'charts');
      } catch (e) {
        setMapProps(null);
      }
      if (result?.data && result.status === 200) {
        setMapProps(result.data[0]);

        if (result.data[0].token) {
          const { exp: jwtExp } = jwt_decode(result.data[0].token);
          setTokenExp(jwtExp * 1000);
          setNewToken(false);
        }
      }
    };

    if (newToken && !newTokenRef.current) {
      getChartData();
    }

    newTokenRef.current = newToken;
  }, [newToken]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line
  useEffect(() => {
    if (!newToken && tokenExp && tokenExp - new Date().getTime() < 3600 * 1000) {
      setNewToken(true);
    }
  });

  if (!mapProps || !show) {
    return null;
  }

  return (
    <WMSTileLayer
      url={mapProps.base}
      layers={mapProps.layers}
      maxZoom={20}
      maxNativeZoom={20}
      format="image/png"
      transparent={true}
    />
  );
};

export default WMSChart;
