import React, { useContext, createContext, useCallback } from 'react';
import useApi from '../hooks/useApi';
import App from 'antd/es/app';

import { UserContext } from '../context/UserContext';

export const SeaChartVesselsContext = createContext();

export const SeaChartVesselsProvider = ({ children }) => {
  const { useUserSocket } = useContext(UserContext);
  const { loading, data, error, fetchData } = useApi('get', 'sea-chart/vessels', {});

  const { message } = App.useApp();

  if (error) {
    message.error(error, 4);
  }

  const getVessels = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const seaChartVesselsChanged = useCallback(
    data => {
      if (data) {
        getVessels();
      }
    },
    [getVessels]
  );

  useUserSocket('sea-chart-vessels-changed', seaChartVesselsChanged);

  const vessels = error || !data ? undefined : data;

  return (
    <SeaChartVesselsContext.Provider
      value={{
        getVessels: getVessels,
        vessels: vessels,
        loading: loading,
      }}
    >
      {children}
    </SeaChartVesselsContext.Provider>
  );
};
