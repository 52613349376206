import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Select from 'antd/es/select';
import ButtonLight from '../../ui/ButtonLight';
import { UserContext } from '../../../context/UserContext';

const ItemLinkerTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 16px;
  font-weight: 600;
`;

const ItemLinkerBottom = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 2px 16px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-bottom: 10px;
  }
`;

const ItemLinkerBody = styled.div`
  padding: 16px 24px;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(212, 212, 212, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const ItemLinkerContainer = styled.div`
  width: 400px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e8e8e8;
  display: block;
  height: fit-content;
`;

const Info = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const ItemLinker = ({ value, options, text, closeModal, changeLink }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [input, setInput] = useState(value);

  const setLink = () => {
    changeLink(input);
    closeModal();
  };

  return (
    <Container>
      <ItemLinkerContainer>
        <ItemLinkerTitle>{t('Input link')}</ItemLinkerTitle>
        <ItemLinkerBody>
          <Info>{text}</Info>
          <Select
            placeholder={t('Link')}
            optionFilterProp="label"
            onChange={e => {
              setInput(e);
            }}
            style={{ width: '100%' }}
            options={options.map(option => {
              return {
                label: option.name,
                value: option.code,
              };
            })}
            value={input}
            allowClear
          />
        </ItemLinkerBody>
        <ItemLinkerBottom>
          <ButtonLight style={{ marginRight: '12px' }} cancel onClick={() => closeModal()}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight onClick={setLink}>{t('Save')}</ButtonLight>
        </ItemLinkerBottom>
      </ItemLinkerContainer>
    </Container>
  );
};

export default ItemLinker;
