import React, { useState, useContext, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';

const Container = styled.div`
  z-index: 999;
  position: absolute;
  width: 350px;
  left: -336px;
  top: 0;
  display: flex;
  transform: ${props => (props.open ? 'translateX(336px)' : 'translateX(0)')};
  transition: all 0.3s linear;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Header = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
`;

const Opener = styled.div`
  position: absolute;
  width: 14px;
  height: 40px;
  top: 14px;
  right: 0;
  cursor: pointer;
  z-index: 100;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  border-radius: 0 1px 1px 0;
`;

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grey_lighter};
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  position: relative;
  width: calc(100% - 14px);
  border-radius: 0 0 4px 4px;
  cursor: default;
  padding: 18px;
  height: 560px;

  .ant-tabs-tab {
    font-weight: 400 !important;
    font-size: 16px !important;
    text-transform: initial !important;
  }

  .ant-tabs-nav-operations {
    display: none;
  }
`;

const IconContainer = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  margin-left: 1px;
  color: ${({ theme }) => theme.color.grey};
`;

const NoPortTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const NoPortText = styled.div`
  font-size: 14px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 400px;
`;

const ShadowContainer = styled.div`
  padding: 0 3px;
  font-size: 12px;
`;

const ListItemContainer = styled.div`
  background-color: ${props => (props.current ? '#4990DD' : '#fff')};
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  padding: 6px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
`;

const ListItemPart = styled.div`
  display: flex;
`;

const ListItemHeader = styled.div`
  font-weight: 600;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  color: ${props => props.current && '#fff'};
`;

const ListItemRow = styled.div`
  color: gray;
  width: 128px;
  padding-left: 6px;
  line-height: 22px;
  color: ${props => props.current && '#fff'};
`;

const BoldPart = styled.span`
  font-weight: 600;
`;

const NoVessels = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
`;

const AnchoredLink = styled.div`
  text-align: left;
  font-size: 14px;
  margin: 12px 0;
  font-weight: 600;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
`;

const Button = styled.button`
  border-radius: ${({ theme }) => theme.style.border_radius};
  display: inline-block;
  background: transparent;
  border: none;
  box-shadow: none;
  min-width: 0px;
  width: 24px;
  height: 24px;
  color: #b9b9b9;
  cursor: pointer;

  i {
    left: -4px;
    width: 20px;
    height: 20px;
  }
`;

const DeselectButton = styled(Button)`
  width: 14px;
  height: 14px;
  color: #5a5a5a;
  svg {
    left: -4px;
    width: 14px;
    height: 14px;
  }
  i {
    top: -4px;
  }
`;

const PortButton = styled(Button)`
  width: 20px;
  height: 20px;
  margin-right: 6px;

  svg {
    left: -4px;
    width: 20px !important;
    height: 20px !important;
  }
  i {
    top: 1px;
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
  position: relative;
`;

const Common = styled.div`
  display: flex;
  position: relative;
  font-size: 12px;
  padding: 4px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
  line-height: 21px;
`;

const SecondColumn = styled.div`
  max-width: 240px;
  position: relative;
`;

const TooltipValue = styled.div`
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const SourceTag = styled.div`
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  height: 18px;
  line-height: 16px;
  padding: 0 4px;
  display: inline-block;
  border-radius: 4px;
  color: #4a4a4a;
  font-weight: 700;
  font-size: 11px;
`;

const ListHeaderRow = styled.div`
  display: flex;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 10px;
`;

const ListHeader = styled.div`
  font-size: 14px;
`;

const FleetViewComponent = ({
  open,
  setOpen,
  data,
  clickPort,
  lastPortName,
  deselectPort,
  allVessels,
  zoomToVessel,
  zoomToPort,
  setSideModalOpen,
  sideModalOpen,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const openRef = useRef(open);
  const dataNameRef = useRef(undefined);

  const [selectedTab, setSelectedTab] = useState('in_port');

  const toggleOpen = () => {
    setOpen(open => !open);
  };

  const handleTabChange = e => {
    setSelectedTab(e);
  };

  useEffect(() => {
    if ((open && !openRef.current) || data?.port_name !== dataNameRef.current) {
      setSelectedTab('in_port');
    }

    openRef.current = open;
    if (data) {
      dataNameRef.current = data.port_name;
    }
  }, [data, open]);

  const clickOpenModal = vessel => {
    setSideModalOpen({
      imo: vessel.imo,
      name: vessel.data.vesselName,
      port_call_id: vessel.port_call_id,
      eta: vessel.eta,
    });
  };

  const clickShowOnMap = (e, data) => {
    e.stopPropagation();
    zoomToVessel(data.coordinates);
  };

  const clickShowPortOnMap = (e, data) => {
    e.stopPropagation();
    zoomToPort(data.coordinates);
  };

  const TimestampTooltip = item => {
    return (
      <>
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('ETA')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip style={{ marginBottom: '6px' }}>{t('ETD')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ATA')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('ATD')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{item.eta ? dayjs(item.eta).format('DD.MM.YYYY HH:mm') : '-'}</TooltipValue>
            <TooltipValue style={{ marginBottom: '6px' }}>
              {item.etd ? dayjs(item.etd).format('DD.MM.YYYY HH:mm') : '-'}
            </TooltipValue>
            <TooltipValue>{item.ata ? dayjs(item.ata).format('DD.MM.YYYY HH:mm') : '-'}</TooltipValue>
            <TooltipValue>{item.atd ? dayjs(item.atd).format('DD.MM.YYYY HH:mm') : '-'}</TooltipValue>
          </SecondColumn>
        </Common>
        <div style={{ paddingLeft: '4px', paddingRop: '6px' }}>
          {t('Source')}: <SourceTag>{item.data.dataSource}</SourceTag>
        </div>
      </>
    );
  };

  const ListItem = ({ feature }) => {
    let agent = feature.data.agentInfo && feature.data.agentInfo.length === 1 ? feature.data.agentInfo[0].name : null;
    if (agent === null && feature.data.agentInfo) {
      const agentData = feature.data.agentInfo.find(a => a.role === 1);
      if (agentData) {
        agent = agentData.name;
      }
    }

    const clickableMarker = allVessels.find(v => v.imo === feature.imo);

    return (
      <ShadowContainer>
        <ListItemContainer
          onClick={() => clickOpenModal(feature)}
          current={!!sideModalOpen && feature.imo === sideModalOpen.imo}
        >
          <ListItemPart style={{ width: clickableMarker ? 'calc(100% - 16px)' : '100%' }}>
            <Tooltip
              placement="top"
              title={feature.data.vesselName}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              <ListItemHeader current={!!sideModalOpen && feature.imo === sideModalOpen.imo}>
                {feature.data.vesselName}
              </ListItemHeader>
            </Tooltip>
            {feature.ata ? (
              <Tooltip
                placement="top"
                title={TimestampTooltip(feature)}
                color="#ffffff"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
              >
                <ListItemRow current={!!sideModalOpen && feature.imo === sideModalOpen.imo}>
                  {dayjs(feature.etd).format('DD.MM.YYYY')}{' '}
                  <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(feature.etd).format('HH:mm')}</BoldPart>
                </ListItemRow>
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title={TimestampTooltip(feature)}
                color="#ffffff"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
              >
                <ListItemRow current={!!sideModalOpen && feature.imo === sideModalOpen.imo}>
                  {dayjs(feature.eta).format('DD.MM.YYYY')}{' '}
                  <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(feature.eta).format('HH:mm')}</BoldPart>
                </ListItemRow>
              </Tooltip>
            )}
          </ListItemPart>
          {!!clickableMarker && (
            <ListItemPart
              style={{ width: '24px', paddingRight: 0, overflow: 'hidden', height: '22px', marginTop: '-1px' }}
            >
              <Button onClick={e => clickShowOnMap(e, clickableMarker)} port={false}>
                <Icon type={'marker'} />
              </Button>
            </ListItemPart>
          )}
        </ListItemContainer>
      </ShadowContainer>
    );
  };

  const tabItems = [];

  if (data) {
    tabItems.push({
      label:
        data.port_name !== 'Anchorage'
          ? t('At berth ({{number}})', { number: data.in_port ? data.in_port.length : 0 })
          : t('Anchored ({{number}})', { number: data.in_port ? data.in_port.length : 0 }), // eslint-disable-line quotes
      key: 'in_port',
    });
    tabItems.push({
      label: t('Arriving ({{number}})', { number: data.expected ? data.expected.length : 0 }),
      key: 'expected',
    });
  }

  return (
    <Container open={open}>
      <Opener onClick={toggleOpen}>
        <Tooltip
          title={open ? t('Close fleet panel') : t('Open fleet panel')}
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
          placement="right"
        >
          {!open ? (
            <IconContainer aria-label="fleet-component-caret-right">
              <CaretRightOutlined />
            </IconContainer>
          ) : (
            <IconContainer aria-label="fleet-component-caret-left">
              <CaretLeftOutlined />
            </IconContainer>
          )}
        </Tooltip>
      </Opener>
      {data ? (
        <ListContainer>
          {data.port_name !== 'Anchorage' ? (
            <HeaderContainer>
              <PortButton onClick={e => clickShowPortOnMap(e, data)}>
                <Icon type={'fleet-port'} />
              </PortButton>
              <Header>{t('Port of {{port}}', { port: data.port_name })}</Header>

              <DeselectButton onClick={deselectPort}>
                <Tooltip
                  placement="top"
                  title={t('Deselect port')}
                  color="#ffffff"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
                >
                  <Icon type={'x-circle'} fill="none" />
                </Tooltip>
              </DeselectButton>
            </HeaderContainer>
          ) : (
            <Header>{t('Anchored vessels')}</Header>
          )}
          <Tabs activeKey={selectedTab} animated={false} size="small" onChange={handleTabChange} items={tabItems} />
          {selectedTab === 'in_port' && (
            <ScrollContainer>
              <ListHeaderRow>
                {/* eslint-disable-next-line quotes */}
                <ListHeader style={{ width: '120px' }}>{t("Vessel's name")}</ListHeader>
                <ListHeader style={{ paddingLeft: '6px' }}>{t('ETD')}</ListHeader>
              </ListHeaderRow>
              {data?.in_port?.length > 0 ? (
                data.in_port.map((d, i) => <ListItem key={i} feature={d} />)
              ) : (
                <NoVessels>{t('No vessels in port')}</NoVessels>
              )}
            </ScrollContainer>
          )}
          {selectedTab === 'expected' && (
            <ScrollContainer>
              <ListHeaderRow>
                {/* eslint-disable-next-line quotes */}
                <ListHeader style={{ width: '120px' }}>{t("Vessel's name")}</ListHeader>
                <ListHeader style={{ paddingLeft: '6px' }}>{t('ETA')}</ListHeader>
              </ListHeaderRow>
              {data?.expected?.length > 0 ? (
                data.expected.map((d, i) => <ListItem key={i} feature={d} />)
              ) : (
                <NoVessels>{t('No vessels arriving')}</NoVessels>
              )}
            </ScrollContainer>
          )}
          {data.port_name !== 'Anchorage' ? (
            <AnchoredLink onClick={() => clickPort('Anchorage')}>{t('See anchored vessels')}</AnchoredLink>
          ) : (
            <AnchoredLink onClick={() => clickPort(lastPortName)}>{t('Back')}</AnchoredLink>
          )}
        </ListContainer>
      ) : (
        <ListContainer>
          <NoPortTextContainer>
            <NoPortText>{t('Select a port to see details')}</NoPortText>
          </NoPortTextContainer>
          <AnchoredLink style={{ position: 'absolute', bottom: '-1px' }} onClick={() => clickPort('Anchorage')}>
            {t('See anchored vessels')}
          </AnchoredLink>
        </ListContainer>
      )}
    </Container>
  );
};

export default FleetViewComponent;
