import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

import { UserContext } from './UserContext';

export const FilteringContext = createContext();

export const FilteringProvider = ({ children }) => {
  const { namespace, apiCall } = useContext(UserContext);

  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const [mobileFilterPopoverOpen, setMobileFilterPopoverOpen] = useState(false);

  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [filteringStorageData, setFilteringStorageData] = useState(undefined);
  const [filteringStorageOptions, setFilteringStorageOptions] = useState(undefined);
  const [filteringListsOpenStorage, setFilteringListsOpenStorage] = useState(undefined);
  const [recenterActivity, setRecenterActivity] = useState(false);
  const [tags, setTags] = useState([]);
  const [updateBPTData, setUpdateBPTData] = useState(false);

  const storageKey = 'selectedFilteringItems_' + namespace;
  const storageListOpenKey = 'filteringListsKeptOpen_' + namespace;

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const getTagListInEffect = (obj, options) => {
      let tags = [];
      if (obj && options) {
        for (let d in obj) {
          obj[d].forEach(f => {
            const folder = options.find(st => st.name === d);
            let item = folder?.values.find(o => o.name === f);
            if (item) {
              tags.push({ folder: d, display: item.display, name: item.name });
            }
          });
        }
      }

      return tags;
    };

    const getCheckedFilteringStorageData = (obj, options) => {
      if (obj) {
        let checkedObj = {};

        for (let d in obj) {
          const l = [];
          obj[d].forEach(f => {
            const folder = options.find(st => st.name === d);
            let item = folder?.values.find(o => o.name === f);
            if (item) {
              l.push(f);
            }
          });
          checkedObj[d] = l;
        }

        return checkedObj;
      } else {
        return undefined;
      }
    };

    const getItemsFromLocalStorage = () => {
      try {
        const value = localStorage.getItem(storageKey);
        if (!value) {
          return undefined;
        }

        const parsedValue = JSON.parse(value);
        return parsedValue;
      } catch (e) {
        console.log('error parsing filter local storage', e);
        localStorage.removeItem(storageKey);
        return undefined;
      }
    };

    const getListsOpenFromLocalStorage = () => {
      try {
        const value = localStorage.getItem(storageListOpenKey);
        if (!value) {
          return undefined;
        }

        const parsedValue = JSON.parse(value);
        return parsedValue;
      } catch (e) {
        console.log('error parsing filter list open local storage', e);
        localStorage.removeItem(storageListOpenKey);
        return undefined;
      }
    };

    const getFilteringOptions = async () => {
      const result = await apiCall('get', 'filters', {});
      if (result?.status === 200 && result?.data) {
        let selectedItemsInObject = getItemsFromLocalStorage();
        let dataObj = {};

        result.data.forEach(d => {
          dataObj[d.name] = [];

          if (selectedItemsInObject && Object.keys(selectedItemsInObject).length && selectedItemsInObject[d.name]) {
            selectedItemsInObject[d.name].forEach(o => dataObj[d.name].push(o));
          }
        });

        let openLists = getListsOpenFromLocalStorage();

        if (mounted.current) {
          setFilteringStorageData(getCheckedFilteringStorageData(dataObj, result.data));
          setTags(getTagListInEffect(dataObj, result.data));
          setFilteringStorageOptions(result.data);
          setFilteringListsOpenStorage(openLists);
        }
      }
    };

    if (!dataRetrieved && mounted.current) {
      setDataRetrieved(true);
      getFilteringOptions();
    }
  }, [apiCall, dataRetrieved, storageKey, storageListOpenKey]);

  const getTagList = obj => {
    let tags = [];

    if (obj && filteringStorageOptions) {
      for (let d in obj) {
        obj[d].forEach(f => {
          const folder = filteringStorageOptions.find(st => st.name === d);
          let item = folder ? folder.values.find(o => o.name === f) : { display: f, name: f };
          tags.push({ folder: d, display: item.display, name: item.name });
        });
      }
    }

    return tags;
  };

  const setFilterStorageData = obj => {
    localStorage.setItem(storageKey, JSON.stringify(obj));
    setFilteringStorageData(obj);
    setTags(getTagList(obj));
    setUpdateBPTData(true);

    // Moves the activity view to center
    setRecenterActivity(true);
    setTimeout(() => {
      setRecenterActivity(false);
    }, 100);
  };

  const setFilterListsOpenData = obj => {
    localStorage.setItem(storageListOpenKey, JSON.stringify(obj));
    setFilteringListsOpenStorage(obj);
  };

  const removeStorageData = ({ folder, name }) => {
    let listOfData = filteringStorageData[folder].map(d => d).filter(d => d !== name);
    let newData = { ...filteringStorageData };
    newData[folder] = listOfData;
    setFilterStorageData(newData);
    setTags(getTagList(newData));
    setUpdateBPTData(true);
  };

  return (
    <FilteringContext.Provider
      value={{
        filteringStorageData,
        setFilterStorageData,
        removeStorageData,
        filteringStorageOptions,
        tags,
        recenterActivity,
        updateBPTData,
        setUpdateBPTData,
        setFilterPopoverOpen,
        filterPopoverOpen,
        filteringListsOpenStorage,
        setFilterListsOpenData,
        setMobileFilterPopoverOpen,
        mobileFilterPopoverOpen,
      }}
    >
      {children}
    </FilteringContext.Provider>
  );
};
