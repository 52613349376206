import React, { useContext } from 'react';
import styled from 'styled-components';
import Tag from 'antd/es/tag';

import { FleetFilteringContext } from '../../context/FleetFilteringContext';

import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { setFleetFilterStorageData } from './fleetFilterHelpers';
import { getDateFromSliderValue } from './FleetFilterDateSlider';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

const InnerContainer = styled.div`
  flex-direction: column;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 1px;
  overflow-x: auto;
  min-height: 40px;
`;

const NameAndClearContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const FilterName = styled.span`
  margin-right: 30px;
  cursor: default;
  font-size: ${({ theme }) => theme.text.small};
`;

const ClearContainer = styled.span`
  margin-right: 20px;
  font-size: ${({ theme }) => theme.text.small};
  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`;

const FleetFilterTagList = () => {
  const { namespace } = useContext(UserContext);
  const { activeFilter, setActiveFilter, formData, handleFormChange, clearFormData, setFleetFilter } = useContext(
    FleetFilteringContext
  );

  const { t } = useTranslation(namespace);

  // let locodes = formData.locode?.map(locode => ({ key: 'locode', value: locode.value, label: locode.label }));
  // let portareas = formData.portarea?.map(portarea => ({ key: 'portarea', value: portarea.value }));
  let portareaSelect = formData.portAreaSelect?.map(area => ({
    key: 'portAreaSelect',
    value: area.value,
    label: area.label,
  }));
  let agents = formData.agent?.map(agent => ({ key: 'agent', value: agent }));
  let vesselTypes = formData.vesselType?.map(vesselType => ({ key: 'vesselType', value: vesselType }));
  let vessels = formData.vessel?.map(vessel => ({ key: 'vessel', value: vessel.value, label: vessel.label }));
  let nationalities = formData.nationality?.map(nationality => ({
    key: 'nationality',
    value: nationality.value,
    label: nationality.label,
  }));
  let shippingCompanies = formData.shippingCompany?.map(company => ({
    key: 'shippingCompany',
    value: company.value,
    label: company.label,
  }));
  let vtsAreas = formData.vtsArea?.map(area => ({ key: 'vtsArea', value: area.value, label: area.label }));

  let tags = [];

  if (formData.arriving) {
    tags.push({
      key: 'arriving',
      value: 'Arriving',
    });
  }
  if (formData.departed) {
    tags.push({
      key: 'departed',
      value: 'Departed',
    });
  }
  if (vessels?.length > 0) {
    tags.push(...vessels);
  }
  if (agents && agents.length > 0) {
    tags.push(...agents);
  }
  if (vesselTypes && vesselTypes.length > 0) {
    tags.push(...vesselTypes);
  }
  if (nationalities && nationalities.length > 0) {
    tags.push(...nationalities);
  }
  if (shippingCompanies && shippingCompanies.length > 0) {
    tags.push(...shippingCompanies);
  }
  /*   if (locodes && locodes.length > 0) {
    tags.push(...locodes);
  }
  if (portareas && portareas.length > 0) {
    tags.push(...portareas);
  } */
  if (portareaSelect && portareaSelect.length > 0) {
    tags.push(...portareaSelect);
  }
  if (vtsAreas && vtsAreas.length > 0) {
    tags.push(...vtsAreas);
  }

  /*   if (formData.etaStart) {
    tags.push({
      key: 'etaStart',
      value: `${t('Eta-start')}: ${dayjs(formData.etaStart).format('DD.MM.YYYY HH:mm')}`,
    });
  }
  if (formData.etaEnd) {
    tags.push({
      key: 'etaEnd',
      value: `${t('Eta-end')}: ${dayjs(formData.etaEnd).format('DD.MM.YYYY HH:mm')}`,
    });
  }
  if (formData.etdStart) {
    tags.push({
      key: 'etdStart',
      value: `${t('Etd-start')}:  ${dayjs(formData.etdStart).format('DD.MM.YYYY HH:mm')}`,
    });
  }
  if (formData.etdEnd) {
    tags.push({
      key: 'etdEnd',
      value: `${t('Etd-end')}: ${dayjs(formData.etdEnd).format('DD.MM.YYYY HH:mm')}`,
    });
  } */

  if (formData?.etaSlider?.length > 0) {
    tags.push({
      key: 'etaSlider',
      value: `${t('Eta')}: ${getDateFromSliderValue(formData?.etaSlider[0]).format(
        'MMMM DD'
      )} - ${getDateFromSliderValue(formData?.etaSlider[1]).format('MMMM DD, YYYY')}`,
    });
  }

  if (formData?.etdSlider?.length > 0) {
    tags.push({
      key: 'etdSlider',
      value: `${t('Etd')}: ${getDateFromSliderValue(formData?.etdSlider[0]).format(
        'MMMM DD'
      )} - ${getDateFromSliderValue(formData?.etdSlider[1]).format('MMMM DD, YYYY')}`,
    });
  }

  if (formData?.imo) {
    tags.push({
      key: 'imo',
      value: `IMO: ${formData.imo}`,
    });
  }

  const crop = text => {
    if (text.length > 32) {
      return text.substring(0, 32) + '...';
    }
    return text;
  };

  return (
    <Container>
      {tags && tags.length > 0 && (
        <InnerContainer>
          <NameAndClearContainer>
            <ClearContainer
              onClick={() => {
                clearFormData();
                setFleetFilter({});
                setActiveFilter(null);
                setFleetFilterStorageData(namespace, null);
              }}
            >
              Clear all
            </ClearContainer>
            {activeFilter && <FilterName>{activeFilter.name}</FilterName>}
          </NameAndClearContainer>
          <TagContainer>
            {tags.map((item, index) => (
              <Tag
                style={{ marginTop: '1px', marginBottom: '1px', height: '22px' }}
                closable
                open={true}
                key={index}
                data-testid={`fleet-filter-tag-${index}`}
                onClose={() => handleFormChange(item)}
              >
                {crop(item.label || item.value)}
              </Tag>
            ))}
          </TagContainer>
        </InnerContainer>
      )}
    </Container>
  );
};

export default FleetFilterTagList;
