import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { TimestampContext } from '../../context/TimestampContext';

import Popover from 'antd/es/popover';
import Tooltip from 'antd/es/tooltip';

import Heading from '../ui/Heading';
import Icon from '../ui/Icon';
import Button from '../ui/Button';

import ShipRoute from './ShipRoute';
import ShipBadges from './ShipBadges';
import CardActions from './CardActions';
import CardInfo from './CardInfo';
import { TIME_FORMAT } from '../../utils/constants';
import CardWeather from '../weather/CardWeather'; // eslint-disable-line
import NotificationRow from './NotificationRow';
import AddServiceOrderModal from './serviceOrder/AddServiceOrderModal';
import ServiceOrderModal from './serviceOrder/ServiceOrderModal';
import ServiceOrderTooltip from './serviceOrder/ServiceOrderTooltip';
import ServiceDot from './serviceOrder/ServiceDot';
import SpeedModal from './SpeedModal';
import DateComponent from '../ui/DateComponent';

function createCSS({ totalVessels }) {
  let styles = '';
  for (let i = 1; i <= totalVessels + 1; i++) {
    styles += `
          &:nth-child(${i}) {
            grid-column: ${i};
            grid-row: 1;
            -ms-grid-column: ${i};
            -ms-grid-row: 1;
          }
       `;
  }
  return css`
    ${styles}
  `;
}

const CardWrapper = styled.div`
  position: relative;
  z-index: ${props => (props.open ? 16 : 15)};
  padding: 12px ${({ theme }) => theme.sizing.gap} 3px ${({ theme }) => theme.sizing.gap};
  background: ${props => (props.pinned ? props.theme.color.highlight : props.theme.color.white)};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.style.border_radius};
  width: 300px;
  height: 196px;
  margin-right: ${({ theme }) => theme.sizing.gap_medium};
  transition: ${({ theme }) => theme.transition.cubic(0.2)};
  ${props => {
    if (props.open) {
      return `
        box-shadow: none;
        border-color: ${props.theme.color.grey_light};
        ${ToggleCard} {
          opacity: 1;
          pointer-events: auto;
        }
      `;
    } else {
      return `
        &:hover {
          transform: ${props.noScale ? null : 'scale(1.03)'};
          box-shadow: ${props.theme.fx.box_shadow_soft};
        }
      `;
    }
  }}

  &:hover {
    .speed-icon-container {
      display: flex;
    }
  }

  ${createCSS}
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const CardInnerWrap = styled.div``;

const ShipName = styled(Heading)`
  display: flex;
  margin-bottom: 1px;
  margin-top: -4px;
  font-size: 17px;
  line-height: 22px;
  white-space: nowrap;
`;

const VesselName = styled.span`
  max-width: ${props =>
    'calc(196px ' + (props.nationality ? '- 18px ' : '- 0px ') + (props.decisions ? '- 18px' : '- 0px') + ')'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Agent = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IMORow = styled.div`
  display: flex;
  flex-wrap: none;
  height: 18px;
  line-height: 18px;
`;

const ShipIMOLinkIconContainer = styled.span`
  display: none;
  margin-left: 4px;
  cursor: pointer;
`;

const StaticShipIMOLinkIconContainer = styled.div`
  width: 18px;
  margin-top: -1px;
`;

const ShipIMO = styled.div`
  margin-right: 8px;
  display: flex;
  &:hover {
    ${ShipIMOLinkIconContainer} {
      display: inline;
    }
  }
`;

const ShipIMOText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
`;

const NextEvent = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.small};
`;

const NextEventTitle = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
`;

const NextEventTimestamp = styled(DateComponent)``;

const Nationality = styled.sup`
  margin-left: ${({ theme }) => theme.sizing.gap_tiny};
  font-size: ${({ theme }) => theme.text.small};
  user-select: none;
  margin-top: 10px;
  top: -1.5em;
  position: relative;
  height: 10px;
`;

const Placeholder = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.text.small};
`;

const DataNotAvailable = styled.span`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.text.small};
`;

const ToggleCard = styled(Button)`
  display: ${props => (props.show ? 'inline-block' : 'none')};
  color: ${props => (props.open ? props.theme.color.grey : props.theme.color.secondary)};
  text-align: right;
  position: absolute;
  // right : 1rem;
  // top : 0;
  right: ${({ theme }) => theme.sizing.gap_small};
  bottom: -${({ theme }) => theme.sizing.gap_small};
  opacity: 0;
  pointer-events: none;
  margin: ${({ theme }) => theme.sizing.gap_small} 0 0 0;
  ${props => {
    if (props.open) {
      return `
        &:hover {
          color: ${props.theme.color.grey};
        }
      `;
    }
  }}
  i {
    width: 2em;
    height: 1.5em;
    top: -1px;
    svg {
      transition: transform 0.125s ease-in;
      transform: ${props => (props.open ? 'rotate(180deg)' : 'none')};
    }
  }
  ${CardWrapper}:hover & {
    opacity: 1;
    pointer-events: auto;
  }
`;

const LowerRowWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  cursor: default;
`;

const AddServiceOrder = styled.a`
  text-transform: none;
  letter-spacing: 0;
  font-weight: 700;
`;

const CardActionPanel = styled.div`
  position: absolute;
  right: 0.4rem;
  top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-item: center;
  width: 75px;
  gap: 0.2rem;
`;

const ThreeDotContainer = styled.div`
  height: 17px;
  width: 26px;
  padding-left: 2px;
  @media (min-width: 1110px) {
    right: 10px;
  }
  @media (min-width: 900px) and (max-width: 1109px) {
    right: calc(6rem + 1038px - 100vw);
  }
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  z-index: 62;
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
  i {
    margin-top: -8px;
    svg {
      margin-top: -6px;
    }
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
`;

const PopoverContent = styled.div`
  z-index: 1;
  background: ${({ theme }) => theme.color.white};
`;

const ServicesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  width: 100%;
  padding: 0px 10px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: start;
  align-item: center;
`;

const RequestContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 2px;
  margin-bottom: 2px;

  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 600;
`;

const AgentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 164px;
`;

const Warning = styled.div`
  color: #db3535;
  margin-left: 6px;
  margin-right: -2px;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
  }
`;

const Warning2 = styled.div`
  color: #db3535;
  margin-right: 8px;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
  }
`;

const Card = ({
  data,
  cardOpen,
  notifications,
  decisions,
  transitions,
  serviceOrders,
  reloadServiceOrderData,
  jitForVessel,
  hasWarnings,
  noScale = false,
  ...props
}) => {
  const { apiCall, namespace, user, modules } = useContext(UserContext); // eslint-disable-line
  const { pinnedVessels, fetchData } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();
  const {
    id,
    imo,
    mmsi,
    call_sign,
    vessel_name,
    nationality,
    from_port,
    to_port,
    next_port,
    // first_eta,
    // current_eta,
    // planned_eta,
    // first_etd,
    // current_etd,
    // planned_etd,
    // ata,
    // atd,
    // rta,
    is_vis,
    vis_service_id,
    port_area_name,
    type,
    status,
    loa,
    beam,
    draft,
    net_tonnage,
    gross_weight,
    badges,
    next_event,
    next_events,
    vessel_type_name,
    master_id,
    agent,
    dead_weight,
    previous_port_atd,
    previous_port_etd,
  } = data;

  const [open, setOpen] = useState(cardOpen);
  const [pinned, setPinned] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.querySelector('#popover-content');
      const jitmodal = document.querySelector('#edit-jit-content');

      if (modal ? !modal.contains(event.target) : true) {
        if (
          !(
            jitmodal?.contains(event.target) ||
            event.target.outerHTML.includes('ant-picker') ||
            (event.target.outerHTML.substring(0, 6) === '<span>' &&
              event.target.outerHTML.substring(event.target.outerHTML.length - 7, event.target.outerHTML.length - 0) ===
                '</span>') ||
            event.target.outerHTML.includes('activity-info-drag-handle')
          )
        ) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [addServiceOrderModalOpen, setAddServiceOrderModalOpen] = useState(false);
  const [serviceOrderModalTab, setServiceOrderModalTab] = useState(undefined);

  useEffect(() => {
    if (pinnedVessels.includes(imo)) {
      setPinned(true);
    } else {
      setPinned(false);
    }
  }, [imo, pinnedVessels, badges, is_vis]);

  const handleClick = () => {
    if (open && localStorage.getItem(`vessel-notification-${imo}`)) {
      localStorage.removeItem(`vessel-notification-${imo}`);
    }
    setOpen(!open);
  };

  const hasPermissions =
    user.permissions.includes('add_manual_timestamp') ||
    user.permissions.includes('send_push_notification') ||
    user.permissions.includes('send_rta_web_form') ||
    user.permissions.includes('send_vis_message')
      ? true
      : false;

  const hasData =
    mmsi ||
    call_sign ||
    net_tonnage ||
    gross_weight ||
    dead_weight ||
    type ||
    status ||
    loa ||
    beam ||
    draft ||
    vessel_type_name
      ? true
      : false;

  // const getVesselShortName = (name, transitionsAvailable) => {
  //   const nameLength = name.length;

  //   const maxLength = transitionsAvailable ? 18 : 14;

  //   if (nameLength > maxLength) {
  //     if (isNaN(name.slice(-1))) {
  //       return name.substring(0, maxLength - 2) + '...';
  //     } else {
  //       return name.substring(0, maxLength - 6) + '...' + name.substring(name.length - 4, name.length);
  //     }
  //   } else {
  //     return name;
  //   }
  // };

  let requested = [];
  let acknowledged = [];
  let delivered = [];

  if (serviceOrders) {
    serviceOrders.forEach(so => {
      if (so.order_lines) {
        so.order_lines.forEach(ol => {
          if (ol.deliveries.length > 0) {
            delivered.push(ol);
          } else if (ol.ack) {
            acknowledged.push({ service_order_id: so.id, ...ol });
          } else {
            requested.push({ service_order_id: so.id, ...ol });
          }
        });
      }
    });
  }

  const DotClickTooltip = type => {
    return (
      <span>
        {t('Click to open')}
        <span style={{ fontWeight: 600, padding: '0 6px' }}>{t(type)}</span>
        {t('details')}
      </span>
    );
  };

  return (
    <CardWrapper open={open} pinned={pinned} {...props} noScale={noScale}>
      <CardInner>
        <CardInnerWrap>
          <CardActionPanel>
            {/* <Tooltip placement="left" title={pinned ? t('Unpin') : t('Pin to filter notifications')}>
              <span>
                <Pin
                  data-icon={pinned ? 'unpin' : 'pin'}
                  pinned={pinned}
                  type={pinned ? 'pinned' : 'pin'}
                  onClick={handlePin}
                />
              </span>
            </Tooltip> */}
            <NotificationRow
              notifications={notifications}
              decisions={decisions}
              imo={imo}
              vessel_name={vessel_name}
              master_id={master_id}
            />
            <ThreeDotContainer
              link
              show={hasPermissions || hasData}
              open={open}
              onClick={handleClick}
              aria-label={open ? 'close-activity-view-card-button' : 'open-activity-view-card-button'}
            >
              <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
            </ThreeDotContainer>
          </CardActionPanel>

          <CardInfo show={open} setShow={setOpen}>
            <BackgroundContainer>
              <PopoverContent id="popover-content">
                <InfoHeader>{vessel_name}</InfoHeader>
                <CardActions
                  portCallId={id}
                  pinned={pinned}
                  imo={imo}
                  setOpen={setOpen}
                  vesselName={vessel_name}
                  isVis={is_vis}
                  visServiceId={vis_service_id}
                  masterId={master_id}
                  fetchData={fetchData}
                  setPinned={setPinned}
                  pinnedVessels={pinnedVessels}
                  jitForVessel={jitForVessel}
                />
              </PopoverContent>
            </BackgroundContainer>
          </CardInfo>
          <ShipName h3>
            <Tooltip
              placement="top"
              title={vessel_name}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              <VesselName
                nationality={!!nationality}
                transitions={!!transitions}
                decisions={!!decisions && !!decisions.total}
              >
                {vessel_name}
              </VesselName>
            </Tooltip>
            <Nationality>{nationality}</Nationality>
            <SpeedModal vesselName={vessel_name} portcallId={id} statusDone={status === 'done'} mmsi={mmsi} />
          </ShipName>
          <IMORow>
            <ShipIMO>
              <ShipIMOText>
                IMO <span style={{ fontWeight: 400 }}>{imo}</span>
              </ShipIMOText>
              <StaticShipIMOLinkIconContainer>
                <ShipIMOLinkIconContainer>
                  {user.permissions.includes('manage_port_call') && (
                    <Icon onClick={() => history.push(`/admin/port-calls/${id}`)} type="external-link" fill="none" />
                  )}
                </ShipIMOLinkIconContainer>
              </StaticShipIMOLinkIconContainer>
            </ShipIMO>
            <AgentContainer>
              <Tooltip
                placement="bottom"
                title={agent}
                color="#ffffff"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
              >
                <div>
                  <Agent>{agent}</Agent>
                </div>
              </Tooltip>
              {hasWarnings && (
                <Tooltip
                  title={
                    <div style={{ display: 'flex' }}>
                      <Warning2>
                        <Icon type="warning" />
                      </Warning2>
                      {t('Check warning details from below.')}
                    </div>
                  }
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                >
                  <Warning>
                    <Icon type="warning" />
                  </Warning>
                </Tooltip>
              )}
            </AgentContainer>
          </IMORow>
          <ShipBadges badges={badges} vessel_name={vessel_name} transitions={transitions} />
          {from_port ? (
            <ShipRoute
              from={from_port ? from_port : t('Unknown port')}
              to={to_port ? to_port : t('Unknown port')}
              portArea={port_area_name}
              next={next_port ? next_port : t('Unknown port')}
              aisDestination={data.ais_data ? data.ais_data.destination : undefined}
              aisETA={data.ais_data ? data.ais_data.formattedEta : undefined}
              previousPortTimeLabel={previous_port_atd ? t('ATD') : t('ETD')}
              previousPortTime={previous_port_atd ? previous_port_atd : previous_port_etd}
            />
          ) : (
            <Placeholder style={{ marginBottom: '3px' }}>{t('Trip details unknown')}</Placeholder>
          )}
          {!next_events &&
            (next_event?.ts && next_event?.title ? (
              <NextEvent>
                <NextEventTitle>{t(next_event.title)}</NextEventTitle>
                <NextEventTimestamp format={TIME_FORMAT} date={next_event.ts} />
              </NextEvent>
            ) : (
              <Placeholder>{t('ETA unknown')}</Placeholder>
            ))}
          {!!next_events &&
            next_events.map((e, i) => (
              <NextEvent key={i}>
                <NextEventTitle>{t(e.title)}</NextEventTitle>
                {e.ts ? (
                  <NextEventTimestamp format={TIME_FORMAT} date={e.ts} />
                ) : (
                  <DataNotAvailable>-</DataNotAvailable>
                )}
              </NextEvent>
            ))}
          {/* We don't have proper data yet */}
          {/* {modules.weather_module === 'enabled' && <CardWeather />} */}
        </CardInnerWrap>
        <LowerRowWrapper>
          {modules.service_order_module === 'enabled' &&
            user.permissions.includes('view_service_order') &&
            ((requested && requested.filter(r => !r.is_cancelled).length > 0) ||
            (acknowledged &&
              acknowledged.filter(a => !a.is_cancelled).length > 0) ||
            (delivered && delivered.length > 0) ? (
                  <ServicesContainer>
                    <DotContainer>
                      {requested && requested.filter(r => !r.is_cancelled).length > 0 && (
                        <Popover
                          content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                          placement="bottom"
                          trigger="hover"
                        >
                          <Tooltip title={DotClickTooltip('Request')}>
                            <RequestContainer onClick={() => setServiceOrderModalTab('1')}>
                              <ServiceDot status={'requested'} clickable={true} />
                            </RequestContainer>
                          </Tooltip>
                        </Popover>
                      )}
                      {acknowledged &&
                    acknowledged.filter(a => !a.is_cancelled).length >
                      0 && (
                        <Popover
                          content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                          placement="bottom"
                          trigger="hover"
                        >
                          <Tooltip title={DotClickTooltip('Acknowledgement')}>
                            <RequestContainer onClick={() => setServiceOrderModalTab('2')}>
                              <ServiceDot
                                status={
                                  acknowledged?.every(item => item.ack?.status === 'rejected')
                                    ? 'rejected'
                                    : 'acknowledged'
                                }
                                clickable={true}
                              />
                            </RequestContainer>
                          </Tooltip>
                        </Popover>
                      )}
                      {delivered && delivered.length > 0 && (
                        <Popover
                          content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                          trigger="hover"
                          placement="bottom"
                        >
                          <Tooltip title={DotClickTooltip('Delivery')}>
                            <RequestContainer onClick={() => setServiceOrderModalTab('3')}>
                              <ServiceDot status={'delivered'} clickable={true} />
                            </RequestContainer>
                          </Tooltip>
                        </Popover>
                      )}
                    </DotContainer>

                    {user.permissions.includes('manage_service_order') && (
                      <Tooltip title={'Add new service request'}>
                        <div>
                          <Button
                            normal
                            style={{
                              marginLeft: '0px',
                              color: '#4990DD',
                              marginRight: '-10px',
                            }}
                            onClick={() => setAddServiceOrderModalOpen(true)}
                          >
                            <Icon type={'circle-plus'} />
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </ServicesContainer>
                ) : (
                  user.permissions.includes('manage_service_order') && (
                    <Button
                      normal
                      style={{ zIndex: '5', marginLeft: '8px', color: '#4990DD' }}
                      onClick={() => setAddServiceOrderModalOpen(true)}
                    >
                      <Icon type={'circle-plus'} />
                      <AddServiceOrder href="#" style={{ fontSize: '14px', fontWeight: '400' }}>
                        {t('Service order')}
                      </AddServiceOrder>
                    </Button>
                  )
                ))}
        </LowerRowWrapper>
      </CardInner>
      {addServiceOrderModalOpen && (
        <AddServiceOrderModal
          vessel={data}
          closeModal={() => setAddServiceOrderModalOpen(false)}
          reloadData={reloadServiceOrderData}
        />
      )}
      {!!serviceOrderModalTab && (
        <ServiceOrderModal
          vessel={data}
          closeModal={() => setServiceOrderModalTab(undefined)}
          reloadData={reloadServiceOrderData}
          requested={requested}
          acknowledged={acknowledged}
          delivered={delivered}
          serviceOrderModalTab={serviceOrderModalTab}
          setServiceOrderModalTab={setServiceOrderModalTab}
        />
      )}
    </CardWrapper>
  );
};

export default Card;
