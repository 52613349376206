import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FleetVesselViewContext, FleetVesselViewProvider } from '../context/FleetVesselViewContext';
import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import FleetVesselViewCalendarContainer from '../components/fleetView/FleetVesselViewCalendarContainer';
import { FleetServiceOrderProvider } from '../context/FleetServiceOrderContext';
import { AlertContext } from '../context/AlertContext';
import { mobilePixelMaxWidthLimit } from '../utils/constants';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  max-height: 100%;
`;

const FleetVesselViewContainer = styled.div`
  display: flex;
  height: calc(100vh - 141px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow: hidden;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 127px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const FleetVesselView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  useEffect(() => {
    document.title = 'Fleet Vessel View | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Fleet Vessel View')}>
      <AlteredPage fullWidth>
        <FleetVesselViewProvider>
          <FleetServiceOrderProvider context={FleetVesselViewContext}>
            <FleetVesselViewContainer alertsHeight={alertsHeight}>
              <FleetVesselViewCalendarContainer />
            </FleetVesselViewContainer>
          </FleetServiceOrderProvider>
        </FleetVesselViewProvider>
      </AlteredPage>
    </Layout>
  );
};

export default FleetVesselView;
