import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';

const WeatherText = ({ type, descriptionText, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  if (descriptionText) {
    return <label {...props}>{descriptionText}</label>;
  }

  switch (type) {
    case 'clear':
      return <label {...props}>{t('Clear')}</label>;
    case 'mostly_clear':
      return <label {...props}>{t('Mostly clear')}</label>;
    case 'partly_cloudy':
      return <label {...props}>{t('Partly cloudy')}</label>;
    case 'mostly_cloudy':
      return <label {...props}>{t('Mostly cloudy')}</label>;
    case 'overcast':
      return <label {...props}>{t('Overcast')}</label>;
    case 'fog':
      return <label {...props}>{t('Fog')}</label>;
    case 'drizzle':
      return <label {...props}>{t('Drizzle')}</label>;
    case 'freezing_drizzle':
      return <label {...props}>{t('Freezing drizzle')}</label>;
    case 'freezing_rain':
      return <label {...props}>{t('Freezing rain')}</label>;
    case 'isolated_showers':
      return <label {...props}>{t('Isolated showers')}</label>;
    case 'scattered_showers':
      return <label {...props}>{t('Scattered showers')}</label>;
    case 'showers':
      return <label {...props}>{t('Showers')}</label>;
    case 'partly_cloudy_and_periods_of_light_rain':
      return <label {...props}>{t('Partly cloudy and periods of light rain')}</label>;
    case 'partly_cloudy_and_periods_of_moderate_rain':
      return <label {...props}>{t('Partly cloudy and periods of moderate rain')}</label>;
    case 'partly_cloudy_and_periods_of_heavy_rain':
      return <label {...props}>{t('Partly cloudy and periods of heavy rain')}</label>;
    case 'mostly_cloudy_and_periods_of_light_rain':
      return <label {...props}>{t('Mostly cloudy and periods of light rain')}</label>;
    case 'mostly_cloudy_and_periods_of_moderate_rain':
      return <label {...props}>{t('Mostly cloudy and periods of moderate rain')}</label>;
    case 'mostly_cloudy_and_periods_of_heavy_rain':
      return <label {...props}>{t('Mostly cloudy and periods of heavy rain')}</label>;
    case 'light_rain':
      return <label {...props}>{t('Light rain')}</label>;
    case 'moderate_rain':
      return <label {...props}>{t('Moderate rain')}</label>;
    case 'heavy_rain':
      return <label {...props}>{t('Heavy rain')}</label>;
    case 'isolated_light_sleet_showers':
      return <label {...props}>{t('Isolated light sleet showers')}</label>;
    case 'isolated_moderate_sleet_showers':
      return <label {...props}>{t('Isolated moderate sleet showers')}</label>;
    case 'isolated_heavy_sleet_showers':
      return <label {...props}>{t('Isolated heavy sleet showers')}</label>;
    case 'scattered_light_sleet_showers':
      return <label {...props}>{t('Scattered light sleet showers')}</label>;
    case 'scattered_moderate_sleet_showers':
      return <label {...props}>{t('Scattered moderate sleet showers')}</label>;
    case 'scattered_heavy_sleet_showers':
      return <label {...props}>{t('Scattered heavy sleet showers')}</label>;
    case 'light_sleet':
      return <label {...props}>{t('Light sleet')}</label>;
    case 'moderate_sleet':
      return <label {...props}>{t('Moderate sleet')}</label>;
    case 'heavy_sleet':
      return <label {...props}>{t('Heavy sleet')}</label>;
    case 'isolated_light_snow_showers':
      return <label {...props}>{t('Isolated light snow showers')}</label>;
    case 'isolated_moderate_snow_showers':
      return <label {...props}>{t('Isolated moderate snow showers')}</label>;
    case 'isolated_heavy_snow_showers':
      return <label {...props}>{t('Isolated heavy snow showers')}</label>;
    case 'scattered_light_snow_showers':
      return <label {...props}>{t('Scattered light snow showers')}</label>;
    case 'scattered_moderate_snow_showers':
      return <label {...props}>{t('Scattered moderate snow showers')}</label>;
    case 'scattered_heavy_snow_showers':
      return <label {...props}>{t('Scattered heavy snow showers')}</label>;
    case 'light_snowfall':
      return <label {...props}>{t('Light snowfall')}</label>;
    case 'moderate_snowfall':
      return <label {...props}>{t('Moderate snowfall')}</label>;
    case 'heavy_snowfall':
      return <label {...props}>{t('Heavy snowfall')}</label>;
    case 'isolated_hail_showers':
      return <label {...props}>{t('Isolated hail showers')}</label>;
    case 'scattered_hail_showers':
      return <label {...props}>{t('Scattered hail showers')}</label>;
    case 'hail_showers':
      return <label {...props}>{t('Hail showers')}</label>;
    case 'isolated_thundershowers':
      return <label {...props}>{t('Isolated thundershowers')}</label>;
    case 'scattered_thundershowers':
      return <label {...props}>{t('Scattered thundershowers')}</label>;
    case 'thundershowers':
      return <label {...props}>{t('Thundershowers')}</label>;
    default:
      return null;
  }
};

export default WeatherText;
