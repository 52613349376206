import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import Page from '../../components/ui/Page';
import CustomersList from '../../components/invoicing/CustomersList';

const CustomersView = () => {
  const { namespace } = useContext(UserContext);
  const { invoicePageVisited, setInvoicePageVisited } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Customers | Port Activity App';

    if (!invoicePageVisited) {
      setInvoicePageVisited(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout pagename={t('Customers')}>
      <Page fullWidth>
        <CustomersList />
      </Page>
    </Layout>
  );
};

export default CustomersView;
