import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import CranePlanningToolSidePanel from '../components/cranePlanningTool/CranePlanningToolSidePanel';

import { CranePlanningToolProvider } from '../context/CranePlanningToolContext';
import { BerthPlanningToolProvider } from '../context/BerthPlanningToolContext';
import { SeaChartMarkersProvider } from '../context/SeaChartMarkersContext';
import { SeaChartVesselsProvider } from '../context/SeaChartVesselsContext';
import CranePlanningToolCalendarContainer from '../components/cranePlanningTool/CranePlanningToolCalendarContainer';
import { CraneProvider } from '../context/CraneContext';
import BerthPlanningToolMapResizableContainer from '../components/berthPlanningTool/BerthPlanningToolMapResizableContainer';
import { mobilePixelMaxWidthLimit } from '../utils/constants';
import { AlertContext } from '../context/AlertContext';

const CranePlanningToolContainer = styled.div`
  display: flex;
  height: calc(100vh - 141px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow: hidden;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 127px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  height: 100%;
`;

const CranePlanningTool = () => {
  const { user, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  useEffect(() => {
    document.title = 'Crane Planning Tool | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Crane Planning Tool')}>
      <div className="offsetParent" style={{ height: '100%' }}>
        <AlteredPage fullWidth>
          <BerthPlanningToolProvider>
            <CranePlanningToolProvider>
              <SeaChartMarkersProvider>
                <SeaChartVesselsProvider>
                  <CraneProvider>
                    <CranePlanningToolContainer alertsHeight={alertsHeight}>
                      <CranePlanningToolCalendarContainer />
                      {user.permissions.includes('manage_pbp') && <CranePlanningToolSidePanel />}
                    </CranePlanningToolContainer>
                    {user.permissions.includes('manage_pbp') && (
                      <BerthPlanningToolMapResizableContainer craneView={true} />
                    )}
                  </CraneProvider>
                </SeaChartVesselsProvider>
              </SeaChartMarkersProvider>
            </CranePlanningToolProvider>
          </BerthPlanningToolProvider>
        </AlteredPage>
      </div>
    </Layout>
  );
};

export default CranePlanningTool;
