import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FleetViewContext, FleetViewProvider } from '../context/FleetViewContext';
import { FleetServiceOrderProvider } from '../context/FleetServiceOrderContext';
import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import FleetViewCalendarContainer from '../components/fleetView/FleetViewCalendarContainer';
import { mobilePixelMaxWidthLimit } from '../utils/constants';
import { AlertContext } from '../context/AlertContext';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  max-height: 100%;
`;

const FleetViewContainer = styled.div`
  display: flex;
  height: calc(100vh - 141px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow: hidden;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 127px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const FleetView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  useEffect(() => {
    document.title = 'Fleet View | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Fleet View')}>
      <AlteredPage fullWidth>
        <FleetViewProvider>
          <FleetServiceOrderProvider context={FleetViewContext}>
            <FleetViewContainer alertsHeight={alertsHeight}>
              <FleetViewCalendarContainer />
            </FleetViewContainer>
          </FleetServiceOrderProvider>
        </FleetViewProvider>
      </AlteredPage>
    </Layout>
  );
};

export default FleetView;
