import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import { darken } from 'polished';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../../context/UserContext';
import Icon from '../../ui/Icon';

const Links = styled.div`
  display: flex;
  margin-left: 16px;
`;

const LinkButton = styled.div`
  border: 1px solid ${props => (props.disabled ? props.theme.color.grey_light : props.theme.color.secondary)};
  border-radius: 3px;
  color: ${props => (props.disabled ? props.theme.color.grey_light : props.theme.color.secondary)};
  height: 28px;
  width: 28px;
  margin-top: 15px;
  margin-right: 8px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  i {
    height: 20px;
    width: 20px;
    margin-top: 2px;
    margin-left: 3px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    color: ${props => (!props.disabled ? darken(0.1, props.theme.color.secondary) : props.theme.color.grey_light)};
    border-color: ${props =>
    !props.disabled ? darken(0.1, props.theme.color.secondary) : props.theme.color.grey_light};
  }
`;

const ModalLinks = ({ linksDisabled, vessel }) => {
  const { namespace, setShowableVesselOnMap, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();

  return (
    <Links>
      {modules?.activity_module === 'enabled' && (
        <Tooltip
          placement="top"
          title={linksDisabled ? t('Something has been edited') : t('Show this vessel in Activity view')}
          color="#ffffff"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <LinkButton
            disabled={linksDisabled}
            onClick={() => {
              if (!linksDisabled) {
                history.push('/?search=' + vessel.name);
              }
            }}
          >
            <Icon type="port" />
          </LinkButton>
        </Tooltip>
      )}
      {modules?.map_module === 'enabled' && (
        <Tooltip
          placement="top"
          title={linksDisabled ? t('Something has been edited') : t('Show this vessel in Map view')}
          color="#ffffff"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <LinkButton
            disabled={linksDisabled}
            onClick={() => {
              if (!linksDisabled) {
                setShowableVesselOnMap({ imo: vessel.imo, vesselName: vessel.name });
                history.push('/map');
              }
            }}
          >
            <Icon type="map" />
          </LinkButton>
        </Tooltip>
      )}
    </Links>
  );
};

export default ModalLinks;
