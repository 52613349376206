import React, { useCallback, useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import ProgressLine from './ProgressLine';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';

const Container = styled.div`
  margin-left: 6px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`;

const TextContainer = styled.div`
  display: inline-block;
`;

const Text = styled.div`
  font-size: 11px;
  margin-left: 23px;
  margin-top: -5px;
  color: #a8a8a8;
`;

const Category = styled.div`
  margin-bottom: 12px;
`;

const WorkItem = styled.div`
  display: flex;

  .ant-checkbox-wrapper {
    font-size: 13px !important;
  }

  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
  }

  .ant-checkbox-inner::after {
    width: 5px;
    height: 8px;
  }
`;

const WorkFlow = ({ currentPortcall }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [workflowData, setWorkflowData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clickCheckbox = (index, index2, item) => {
    updateWorkItem(index, index2, item);
  };

  const createWorkflowFromTemplate = useCallback(async () => {
    const res = await apiCall('post', 'fleet/workflow', {
      portcallId: currentPortcall,
      templateId: 1,
    });

    if (res.status === 200) {
      const workflow = res?.data?.results;
      setWorkflowData(workflow);
    }
  }, [apiCall, currentPortcall]);

  const getWorkflowData = useCallback(async () => {
    const res = await apiCall('get', `fleet/workflow?port_call_id=${currentPortcall}`);

    if (res.status === 200) {
      const workflow = res?.data?.results;
      if (workflow) {
        setWorkflowData(workflow);
      } else {
        createWorkflowFromTemplate();
      }
    }
  }, [apiCall, currentPortcall, createWorkflowFromTemplate]);

  const updateWorkItem = async (index, index2, item) => {
    setIsLoading(true);
    const updatedWorkItem = {
      data: {
        id: item.id,
        completed: !item.completed,
        completedBy: !item.completed ? `${user.first_name} ${user.last_name}` : null,
        completedTs: !item.completed ? new Date().toISOString() : null,
      },
    };

    const res = await apiCall('post', 'fleet/work-item', updatedWorkItem);

    if (res.status === 200 && res.data[1][0]) {
      const newItem = res.data[1][0];

      const updatedWorkflowData = workflowData;
      updatedWorkflowData.workSteps[index].workItems[index2] = newItem;
      setWorkflowData(updatedWorkflowData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getWorkflowData(currentPortcall);
  }, [currentPortcall]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="portcall-work-flow">
      <Spin style={{ width: '100%' }} spinning={!workflowData || isLoading} size="medium">
        {workflowData?.workSteps.map((category, index) => (
          <Category key={index}>
            <Header>{category.name}</Header>
            {category.workItems.map((item, index2) => (
              <WorkItem key={index2}>
                <ProgressLine
                  start={index2 === 0}
                  end={index2 === category.workItems.length - 1}
                  thisChecked={item.completed}
                  nextChecked={
                    index2 < category.workItems.length - 1 ? category.workItems[index2 + 1].completed : false
                  }
                  long={!!item.completedTs}
                  fill="white"
                />
                <TextContainer>
                  <Checkbox
                    style={{ width: '100%', marginBottom: '2px', marginTop: '2px' }}
                    checked={item.completed}
                    onChange={() => clickCheckbox(index, index2, item)}
                  >
                    <span>{item.name}</span>
                  </Checkbox>
                  {!!item.completedTs && (
                    <Text>
                      {t('by')} <span style={{ fontWeight: 600, fontSize: '11px' }}>{item.completedBy}</span> {t('at')}{' '}
                      {dayjs(item.completedTs).format(TIME_FORMAT)}
                    </Text>
                  )}
                </TextContainer>
              </WorkItem>
            ))}
          </Category>
        ))}
      </Spin>
    </Container>
  );
};

export default WorkFlow;
