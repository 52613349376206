import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import dayjs from 'dayjs';
import { FleetVesselViewContext } from '../../../../context/FleetVesselViewContext';
import { iterateTimes } from '../../../commonCalendar/utility/calendar';
import { defaultHeaderFormats } from '../../../commonCalendar/utility/default-config';
import Interval from './Interval';
import { headerHeight } from '../../../commonCalendar/utility/constants';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(duration);
dayjs.extend(isBetween);

function formatLabel([timeStart], range, labelWidth, formatOptions = defaultHeaderFormats) {
  let format;
  const unit = range < 2 ? 'day' : range < 9 ? 'week' : 'month';
  if (labelWidth >= 150) {
    format = formatOptions[unit]['long'];
  } else if (labelWidth >= 100) {
    format = formatOptions[unit]['mediumLong'];
  } else if (labelWidth >= 50) {
    format = formatOptions[unit]['medium'];
  } else {
    format = formatOptions[unit]['short'];
  }
  return dayjs(timeStart).format(format);
}

export const DateHeader = () => {
  const { getLeftOffsetFromDate, timelineUnit: unit, canvasTimeStart, canvasTimeEnd, canvasWidth } = useContext(
    FleetVesselViewContext
  );

  let canvasTimeStartRef = useRef();
  let canvasTimeEndRef = useRef();
  let canvasWidthRef = useRef();
  let unitRef = useRef();

  const range = dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asDays();
  const visibleRange = range / 3;

  const getHeaderIntervals = useCallback(
    ({ canvasTimeStart, canvasTimeEnd, getLeftOffsetFromDate }) => {
      const intervalList = [];

      iterateTimes(canvasTimeStart, canvasTimeEnd, range, (startTime, endTime) => {
        const left = getLeftOffsetFromDate(startTime.valueOf());
        const right = getLeftOffsetFromDate(endTime.valueOf());
        const width = right - left;

        let values = {
          startTime: startTime.valueOf(),
          endTime: endTime.valueOf(),
          labelWidth: width,
          left,
          bold: dayjs().isBetween(startTime, endTime),
        };
        intervalList.push(values);
      });

      return intervalList;
    },
    [range]
  );

  const [intervals, setIntervals] = useState([]);

  useEffect(() => {
    if (
      canvasTimeStart !== canvasTimeStartRef.current ||
      canvasTimeEnd !== canvasTimeEndRef.current ||
      canvasWidth !== canvasWidthRef.current ||
      unit !== unitRef.current
    ) {
      setIntervals(
        getHeaderIntervals({
          canvasTimeStart: canvasTimeStart,
          canvasTimeEnd: canvasTimeEnd,
          getLeftOffsetFromDate,
        })
      );
    }

    canvasTimeStartRef.current = canvasTimeStart;
    canvasTimeEndRef.current = canvasTimeEnd;
    canvasWidthRef.current = canvasWidth;
    unitRef.current = unit;
  }, [getLeftOffsetFromDate, unit, canvasTimeStart, canvasTimeEnd, canvasWidth, getHeaderIntervals]);

  const getRootProps = (props2 = {}) => {
    const { style } = props2;
    return {
      style: Object.assign({}, style ? style : {}, {
        position: 'relative',
        width: canvasWidth,
        height: headerHeight,
      }),
    };
  };

  const getIntervalProps = (props2 = {}) => {
    const { interval, style } = props2;
    if (!interval) throw new Error('you should provide interval to the prop getter');
    const { labelWidth, left } = interval;

    return {
      style: getIntervalStyle({
        style,
        labelWidth,
        left,
      }),
    };
  };

  const getIntervalStyle = ({ left, labelWidth, style }) => {
    return {
      ...style,
      left,
      width: labelWidth,
      position: 'absolute',
    };
  };

  return (
    <div data-testid={'dateHeader'} {...getRootProps({})}>
      {intervals.map(interval => {
        let intervalText = formatLabel([interval.startTime], visibleRange, interval.labelWidth);

        if (visibleRange >= 9) {
          intervalText = dayjs(dayjs(interval.startTime), 'MMDDYYYY').isoWeek();
        }
        return (
          <Interval
            key={`label-${interval.startTime.valueOf()}`}
            interval={interval}
            intervalText={intervalText}
            getIntervalProps={getIntervalProps}
            unit={unit}
            bold={interval.bold}
            range={range}
          />
        );
      })}
    </div>
  );
};

export default DateHeader;
