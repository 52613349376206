import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { TimestampContext } from '../../context/TimestampContext';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { TIME_FORMAT } from '../../utils/constants';

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 18px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

const ListContainer = styled.div`
  @media (max-width: 699px) {
    display: none;
  }
`;

const AtBerth = styled.div`
  font-size: 18px;
  line-height: 16px;
  padding-top: 5px;
  padding-right: 5px;
`;

const ActivityListComponentV2 = () => {
  const { timestamps, loader, setSearch } = useContext(TimestampContext);
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    setSearch('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const thisday = dayjs().format('YYYY-MM-DDTHH:mm:ss');
  const arrangedTimestamps = [];
  timestamps.forEach(ts => {
    ts.portcalls[0].events.forEach(e =>
      e.timestamps.forEach(ts2 => {
        if (
          ts2.time_type === 'Estimated' &&
          ts2.time &&
          (ts2.state === 'Arrival_Vessel_PortArea' || ts2.state === 'Departure_Vessel_Berth')
        ) {
          const timeWithZone = dayjs(ts2.time).format('YYYY-MM-DDTHH:mm:ss');
          if (timeWithZone >= thisday) {
            if (ts2.state === 'Arrival_Vessel_PortArea') {
              const newTs = { ...ts.ship };
              newTs.foundEta = ts2.time;
              newTs.foundTime = ts2.time;
              newTs.newId = newTs.id + 'eta';
              arrangedTimestamps.push(newTs);
            } else if (ts2.state === 'Departure_Vessel_Berth') {
              const newTs2 = { ...ts.ship };
              newTs2.foundEtd = ts2.time;
              newTs2.foundTime = ts2.time;
              newTs2.newId = newTs2.id + 'etd';
              arrangedTimestamps.push(newTs2);
            }
          }
        }
      })
    );
  });

  const sortedTimestamps = arrangedTimestamps.sort((a, b) => {
    return new Date(a.foundTime) - new Date(b.foundTime);
  });

  const columns = [
    {
      title: t('State'),
      dataIndex: 'status',
      key: 'status',
      render: (record, item) => {
        if (item.foundEta) {
          return t('Arriving');
        } else {
          return t('Departing');
        }
      },
    },
    {
      title: t('Vessel'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      render: (record, item) => {
        return (
          <div style={{ display: 'flex' }}>
            {item.status === 'at berth' ? <AtBerth>*</AtBerth> : <AtBerth style={{ color: 'transparent' }}>*</AtBerth>}{' '}
            {record}
          </div>
        );
      },
    },
    {
      title: t('Port'),
      dataIndex: 'to_port',
      key: 'to_port',
      render: record => {
        let text = record;
        if (text) {
          text = record.charAt(0).toUpperCase() + record.toLowerCase().slice(1);
        }
        return text;
      },
    },
    {
      title: t('Port area'),
      dataIndex: 'port_area_name',
      key: 'port_area_name',
      render: record => {
        let text = record;
        if (text) {
          text = record.charAt(0).toUpperCase() + record.toLowerCase().slice(1);
        }
        return text;
      },
    },
    {
      title: t('Berth'),
      dataIndex: 'berth_name',
      key: 'berth_name',
      render: record => {
        let text = record;
        if (text) {
          text = record.charAt(0).toUpperCase() + record.toLowerCase().slice(1);
        }
        return text;
      },
    },
    {
      title: t('Start time'),
      dataIndex: 'foundEta',
      key: 'foundEta',
      render: (record, item) => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT);
        } else {
          return dayjs(item.foundEtd).format(TIME_FORMAT);
        }
      },
    },
    {
      title: t('Confirmed'),
      dataIndex: 'confirmed',
      key: 'confirmed',
    },
    {
      title: t('Possible'),
      dataIndex: 'possible',
      key: 'possible',
    },
    {
      title: t('Preview'),
      dataIndex: 'preview',
      key: 'preview',
    },
    {
      title: t('No need'),
      dataIndex: 'no_need',
      key: 'no_need',
    },
  ];

  return (
    <Container>
      <ListContainer>
        <List rowKey="newId" columns={columns} dataSource={sortedTimestamps} apiCallPending={loader} />
      </ListContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-130px', fontWeight: 600 }}>
        <AtBerth>*</AtBerth> {t('These vessels are at berth.')}
      </div>
    </Container>
  );
};

export default ActivityListComponentV2;
