import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';

const BG = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 55px);
  z-index: 100;
  background-color: rgba(240, 240, 240, 0.65);
`;

const Confirmation = styled.div`
  position: absolute;
  padding: 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-size: 13px;
  max-width: 300px;
  text-align: center;
  top: 40px;
  left: calc((100% - 300px) / 2);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ShortText = styled.div`
  textarea {
    resize: none;
    height: 94px;
  }

  margin-top: 12px;
`;

const Button = styled(ButtonLight)`
  height: 26px;
  line-height: 22px;
  padding: 0;
`;

const { TextArea } = Input;

const EditShortText = ({ editTitle, refetchData, setEditTitle }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [shortText, setShortText] = useState(editTitle.shortText || '');

  const saveEdit = async () => {
    await apiCall('post', 'wiki/link', {
      markdown_id: editTitle.markdownId,
      object_type: editTitle.objectType,
      object_id: editTitle.objectId,
      markdown_anchor: editTitle.titleKey,
      lat: editTitle.coordinates?.lat,
      lon: editTitle.coordinates?.lng,
      short_text: shortText,
    });
    setEditTitle(null);
    refetchData();
  };

  return (
    <BG>
      <Confirmation>
        {t('Do you want to edit the short description?')}
        <ShortText>
          <TextArea value={shortText} onChange={e => setShortText(e.target.value)} />
        </ShortText>
        <Buttons>
          <Button cancel type="button" onClick={() => setEditTitle(null)}>
            {t('Close')}
          </Button>
          <Button type="button" onClick={saveEdit}>
            {t('Save')}
          </Button>
        </Buttons>
      </Confirmation>
    </BG>
  );
};

export default EditShortText;
