import React, { useState, useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'; // eslint-disable-line
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import LogoCentralBalticImage from '../images/logo-central-baltic-color.svg?react';
import LogoEUImage from '../images/logo-eu-flag.svg?react';

const HelpDiv = styled.div`
  h2 {
    margin-top: ${({ theme }) => theme.sizing.gap_medium};
  }
  h3 {
    margin-top: ${({ theme }) => theme.sizing.gap_medium};
  }
  h4 {
    margin-top: ${({ theme }) => theme.sizing.gap_medium};
  }
`;

const LogoContainer = styled.span`
  display: flex;
  align-items: left;
  justify-content: left;
  margin-top: 32px;
`;

const LogoWrapper = styled.a`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  opacity: 0.5;
  transition: ${({ theme }) => theme.transition.cubic(0.4)};
  max-width: 10rem;
  &:hover {
    opacity: 0.7;
  }
`;

const HelpPage = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [helpText, setHelpText] = useState(''); // eslint-disable-line

  useEffect(() => {
    document.title = 'Help | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Help')}>
      <Page fullWidth>
        <HelpDiv>
          <h2>{t('Support')}</h2>
          {t('Any problems related to the software please contact')}
          <br />
          <a href={`mailto:${t('Support e-mail')}`}>{t('Support e-mail')}</a>
        </HelpDiv>

        <LogoContainer>
          <LogoWrapper href="https://europa.eu/">
            <LogoEUImage alt="EU" />
          </LogoWrapper>
          <LogoWrapper href="https://centralbaltic.eu">
            <LogoCentralBalticImage alt="Central Baltic" />
          </LogoWrapper>
        </LogoContainer>
      </Page>
    </Layout>
  );
};

export default HelpPage;
