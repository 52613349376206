import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { TIME_FORMAT, TIME_FORMAT_DAY } from '../../utils/constants';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import CreditInvoiceList from './CreditInvoiceList';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Sent = styled.div`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const IconContainer = styled.span`
  color: #4990dd;
  margin-left: 4px;

  i {
    width: 18px;
    height: 18px;
  }

  svg {
    margin-top: -2px;
  }
`;

const ColorContainer = styled.div`
  position: relative;
`;

const TypeColorWithoutColor = styled.div`
  text-transform: capitalize;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px 8px;
  opacity: 100%;
  position: absolute;
  top: 0px;
`;

const TypeColor = styled.div`
  text-transform: capitalize;
  background-color: ${props => props.color || 'transparent'};
  border-radius: 4px;
  padding: 1px 8px;
  opacity: 40%;
`;

const ColorText = styled.div`
  margin: auto;
`;

const PortcallInvoiceList = ({
  invoices,
  handleDelete,
  setInvoiceModalOpen,
  setModalInvoice,
  setReadOnlyAndSend,
  setReadOnly,
  setDuplicateCurrent,
  setCreditInvoice,
  setCreditInvoiceModalOpen,
  setCreditInvoiceData,
}) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const getTypeTooltip = item => {
    if (item.type === 'manual') {
      return null;
    }

    if (item.type === 'automatic' && !!item.template?.name) {
      return (
        <div>
          <span>{t('Automatic invoice name')}:</span> <span style={{ fontWeight: 600 }}>{item.template.name}</span>
        </div>
      );
    }

    return null;
  };

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Customer name'),
      dataIndex: 'customer_number',
      key: 'customer_number',
      render: (record, item) => {
        return item.customer.name;
      },
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (record, item) => {
        return (
          <Tooltip placement="top" title={getTypeTooltip(item)} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            <div style={{ display: 'flex' }}>
              <ColorContainer>
                <TypeColor color={item.template?.color?.length > 0 ? item.template.color : null}>
                  <div style={{ color: 'transparent' }}>{record}</div>
                </TypeColor>
                <TypeColorWithoutColor>
                  <ColorText>{record}</ColorText>
                </TypeColorWithoutColor>
              </ColorContainer>

              {item.type === 'automatic' && !!item.template?.name && (
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('Date'),
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: record => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Due date'),
      dataIndex: 'due_date',
      key: 'due_date',
      render: (record, item) => {
        if (item.calculated?.used_due_date) {
          return dayjs(item.calculated.used_due_date).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Total'),
      dataIndex: 'price',
      key: 'price',
      render: (record, item) => {
        return item.calculated.total_sum_including_vat + ' ' + item.currency_code;
      },
    },
    {
      title: t('Tax class'),
      dataIndex: 'tax_class_name',
      key: 'tax_class_name',
    },
    {
      title: t('Sent'),
      dataIndex: 'is_sent',
      key: 'is_sent',
      render: record =>
        record && (
          <Sent>
            <Icon type="check-light" />
          </Sent>
        ),
    },
    {
      title: t('Credit invoices'),
      dataIndex: 'credit_invoices',
      key: 'credit_invoices',
      render: record => {
        if (record.length) {
          const sent = record.filter(i => i.is_sent);
          return (
            <div>
              {sent.length} / {record.length}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => {
            if (!record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-3"
                onClick={() => {
                  setReadOnly(true);
                  setModalInvoice(record);
                  setInvoiceModalOpen(true);
                }}
              >
                <Icon type="page" />
                {t('Open')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-3"
                onClick={() => {
                  if (!record.is_sent) {
                    setReadOnlyAndSend(true);
                    setModalInvoice(record);
                    setInvoiceModalOpen(true);
                  }
                }}
                disabled={record.is_sent}
              >
                <Icon type="page" />
                {t('Preview and send')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-1"
                onClick={() => {
                  if (!record.is_sent) {
                    setModalInvoice(record);
                    setInvoiceModalOpen(true);
                  }
                }}
                disabled={record.is_sent}
              >
                <Icon type="edit" />
                {t('Edit')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            return (
              <ListActionButton
                key="action-2"
                onClick={() => {
                  setDuplicateCurrent(true);
                  setModalInvoice(record);
                  setInvoiceModalOpen(true);
                }}
              >
                <Icon type="duplicate" />
                {t('Duplicate')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (!record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-22"
                onClick={() => {
                  setCreditInvoice(record);
                  setCreditInvoiceModalOpen(true);
                }}
              >
                <Icon type="page" />
                {t('Create credit invoice')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <Popconfirm
                title={t('Are you sure you want to remove the invoice?')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('Yes')}
                okType="danger"
                cancelText={t('No')}
                icon={null}
                id="pop-confirm-for-new-list"
                key="action-5"
              >
                <ListActionButton red title={t('Delete')} disabled={record.is_sent}>
                  <Icon type="trash" />
                  {t('Delete')}
                </ListActionButton>
              </Popconfirm>
            );
          },
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setReadOnly(true);
                setModalInvoice(record);
                setInvoiceModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={invoices}
        actions={actionList}
        noUrlUpdate={true}
        expandererList={true}
        expandedRowRender={record => (
          <CreditInvoiceList
            invoices={record.credit_invoices}
            customer={record.customer}
            handleDelete={handleDelete}
            setInvoiceModalOpen={setInvoiceModalOpen}
            setModalInvoice={setModalInvoice}
            setReadOnlyAndSend={setReadOnlyAndSend}
            setReadOnly={setReadOnly}
            setCreditInvoiceData={setCreditInvoiceData}
            setCreditInvoice={setCreditInvoice}
            setCreditInvoiceModalOpen={setCreditInvoiceModalOpen}
            invoiceRecord={record}
          />
        )}
      />
    </Container>
  );
};

export default PortcallInvoiceList;
