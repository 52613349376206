import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { TIME_FORMAT_DAY, TIME_FORMAT_HOURS_MINUTES } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { darken } from 'polished';
import AutoComplete from 'antd/es/auto-complete';
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import List from '../../components/ui/List';
import WasteCategorySelect from './WasteCategorySelect';
import ButtonLight from '../ui/ButtonLight';

import WasteReceiptModal from './WasteReceiptModal';

const Container = styled.div`
  position: relative;
`;

const ExtraRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Buttons = styled.div`
  display: flex;
  position: sticky;
  right: 0px;
  padding-right: 12px;
`;

const Button = styled(ButtonLight)`
  border: 1px solid #4990dd;
  color: #4990dd;
  margin-right: 12px;

  &:hover {
    color: ${props => darken(0.2, props.theme.color.secondary)};
    border-color: ${props => darken(0.2, props.theme.color.secondary)};
  }
`;

const AutomaticValue = styled.div`
  color: ${props => props.theme.color.secondary};
  text-align: center;
  padding-top: 2px;
  height: 1rem;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WasteList = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [changedValues, setChangedValues] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);

  const [portcallData, setPortcallData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(undefined);
  const [modalData, setModalData] = useState({});

  const defaultParams = {
    limit: 20,
    offset: 0,
    sort: 'ata DESC',
    search: 'have:ata',
  };

  const updateValue = (value, type, id) => {
    const open = expandedRows.findIndex(r => r === id) > -1;
    if (!open) {
      const rows = [...expandedRows];
      rows.push(id);
      setExpandedRows(rows);
    }

    const newValue = value.target.value;
    setChangedValues(v => {
      const values = { ...v };
      if (!values[id]) {
        values[id] = {};
      }
      if (!values[id][type]) {
        values[id][type] = {
          value: '',
        };
      }
      values[id][type].value = newValue?.trim();
      return values;
    });
  };

  const handleProviderChange = (value, id) => {
    const values = { ...changedValues };
    if (!values[id]) {
      values[id] = {};
    }
    values[id].treatment_facility_provider = value;
    setChangedValues(values);
  };

  const resetChangedValues = id => {
    const values = { ...changedValues };
    delete values[id];
    setChangedValues(values);
  };

  const { data: categoryData, error: categoryError } = useApi('get', 'waste/categories');
  const { data: providerData, error: providerError } = useApi('get', 'waste/treatment-facility-providers');

  const categories = categoryError || !categoryData ? [] : categoryData;
  const providers = providerError || !providerData ? [] : providerData;

  const calculateAutomaticValue = (code, data) => {
    const categeryItem = categories.find(item => item.code === code);
    let value = 0;
    const orderLines = [];
    // Handle all supported types
    if (data?.service_orders?.length) {
      data.service_orders.forEach(serviceOrder => {
        serviceOrder.order_lines?.forEach?.(orderLine => {
          if (orderLine.service_type_code === categeryItem.service_type_code) {
            orderLine.deliveries?.forEach?.(delivery => {
              const floatVal = parseFloat(delivery.quantity);
              if (!isNaN(floatVal)) {
                value += floatVal;
                if (!orderLines.includes(orderLine.id)) {
                  orderLines.push(orderLine.id);
                }
              }
            });
          }
        });
      });
    }
    const sources = orderLines.length
      ? orderLines.length > 1
        ? t('service order lines {{orderLines}}', { orderLines: orderLines.join(', ') })
        : t('service order line {{orderLines}}', { orderLines: orderLines.join(', ') })
      : '';
    return {
      value,
      sources,
    };
  };

  const oilCategories = categories
    .filter(data => data.definition === 'MARPOL Annex I - related')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={
                  changedValues[pc.id] &&
                  (changedValues[pc.id][code]?.value || changedValues[pc.id][code]?.value === '')
                    ? changedValues[pc.id][code].value
                    : record?.value
                }
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const nlsCategories = categories
    .filter(data => data.definition === 'MARPOL Annex II - related')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={
                  changedValues[pc.id] &&
                  (changedValues[pc.id][code]?.value || changedValues[pc.id][code]?.value === '')
                    ? changedValues[pc.id][code].value
                    : record?.value
                }
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const sewageCategories = categories
    .filter(data => data.definition === 'MARPOL Annex IV - related')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={
                  changedValues[pc.id] &&
                  (changedValues[pc.id][code]?.value || changedValues[pc.id][code]?.value === '')
                    ? changedValues[pc.id][code].value
                    : record?.value
                }
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const garbageCategories = categories
    .filter(data => data.definition === 'MARPOL Annex V - related')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={
                  changedValues[pc.id] &&
                  (changedValues[pc.id][code]?.value || changedValues[pc.id][code]?.value === '')
                    ? changedValues[pc.id][code].value
                    : record?.value
                }
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const gasCategories = categories
    .filter(data => data.definition === 'MARPOL Annex VI - related')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={
                  changedValues[pc.id] &&
                  (changedValues[pc.id][code]?.value || changedValues[pc.id][code]?.value === '')
                    ? changedValues[pc.id][code].value
                    : record?.value
                }
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const otherCategories = categories
    .filter(data => data.definition === 'Other waste, not covered by MARPOL')
    .map(({ code, label }) => {
      return {
        title: `${label} (m3)`,
        key: code,
        dataIndex: ['waste_data', code],
        render: (record, pc) => {
          const automaticValue = calculateAutomaticValue(code, record?.automatic_sources);
          return (
            <>
              <Input
                disabled={pc.waste_signed}
                style={{ minWidth: '80px' }}
                placeholder={t(label)}
                value={changedValues[pc.id]?.[code]?.value || record?.value}
                onChange={e => updateValue(e, code, pc.id)}
              />
              <Tooltip
                title={
                  automaticValue.value
                    ? t('Automatically calculated value from {{sources}}', { sources: automaticValue.sources })
                    : undefined
                }
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <AutomaticValue>{automaticValue.value ? automaticValue.value : ''}</AutomaticValue>
              </Tooltip>
            </>
          );
        },
      };
    });

  const handleSave = async id => {
    setLoading(true);

    try {
      if (Object.keys(changedValues?.[id] || {}).length) {
        const updateData = [
          {
            port_call_id: `${id}`,
            data: changedValues[id],
          },
        ];
        await apiCall('post', 'waste/save', { port_calls: updateData });
        await fetchData(false, newParams);
        resetChangedValues(id);
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }

    setLoading(false);
  };

  const saveManualReportSwitchChange = async (id, checked) => {
    setLoading(true);

    try {
      const updateData = [
        {
          port_call_id: `${id}`,
          data: {
            manually_reported: checked,
          },
        },
      ];
      await apiCall('post', 'waste/save', { port_calls: updateData });
      await fetchData(false, newParams);
    } catch (e) {
      setLoading(false);
      throw e;
    }

    setLoading(false);
  };

  const handleGenerateReceipt = id => {
    const portcall = portcallData.find(pc => pc.id === id) || {};
    const propertiesToPickForReceipt = [
      'agent',
      'ata',
      'atd',
      'id',
      'imo',
      'mmsi',
      'nationality',
      'net_tonnage',
      'to_port',
      'vessel_name',
      'vessel_type_name',
    ];
    const receiptPortcallData = Object.fromEntries(propertiesToPickForReceipt.map(key => [key, portcall[key]]));
    setModalData(receiptPortcallData);
    setModalOpen(id);
  };

  const columns = [
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('ATA'),
      dataIndex: 'ata',
      key: 'ata',
      sortableKey: 'ata',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('ATD'),
      dataIndex: 'atd',
      key: 'atd',
      sortableKey: 'atd',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('Agent'),
      dataIndex: 'agent',
      key: 'agent',
      sortableKey: 'agent',
      render: record => (record ? <div style={{ width: 110 }}>{record}</div> : '-'),
    },
    {
      title: t('Provider'),
      dataIndex: ['waste_data', 'treatment_facility_provider'],
      key: 'provider',
      sortableKey: '',
      render: (record, item) => (
        <AutoComplete
          listHeight={80}
          showSearch
          disabled={item.waste_signed}
          placeholder={t('Provider')}
          optionFilterProp="value"
          onChange={value => handleProviderChange(value, item.id)}
          onSelect={value => handleProviderChange(value, item.id)}
          style={{ width: '180px' }}
          options={providers.map(p => {
            return { label: p.name, value: p.name };
          })}
          value={
            changedValues[item.id] && Object.keys(changedValues[item.id]).includes('treatment_facility_provider')
              ? changedValues[item.id].treatment_facility_provider
              : record || ''
          }
          size="medium"
          allowClear
          onClear={() => handleProviderChange(null, item.id)}
        />
      ),
    },
    {
      title: t('Report sent manually'),
      dataIndex: ['waste_data', 'manually_reported'],
      key: 'manually_reported',
      render: (record, item) => {
        const checked = Object.keys(changedValues?.[item.id] || {}).includes('manually_reported')
          ? changedValues[item.id].manually_reported
          : record || false;
        return (
          <Popconfirm
            title={t('Are you sure you want to change the value?')}
            onConfirm={() => saveManualReportSwitchChange(item.id, !checked)}
            okText={t('Yes')}
            okType="danger"
            cancelText={t('No')}
            icon={null}
          >
            <Tooltip
              title={t('Indicates the waste report for this port call has been sent. Must be manually set.')}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              <CheckBoxContainer>
                <Checkbox
                  disabled={item.waste_signed}
                  defaultChecked={checked}
                  checked={checked}
                  onClick={e => e.preventDefault()}
                />
              </CheckBoxContainer>
            </Tooltip>
          </Popconfirm>
        );
      },
    },
  ];

  const [newParams, setNewParams] = useState(defaultParams);
  const [visibleOilCategories, setVisibleOilCategories] = useState([]);
  const [visibleNLSCategories, setVisibleNLSCategories] = useState([]);
  const [visibleSewageCategories, setVisibleSewageCategories] = useState([]);
  const [visibleGarbageCategories, setVisibleGarbageCategories] = useState([]);
  const [visibleGasCategories, setVisibleGasCategories] = useState([]);
  const [visibleOtherCategories, setVisibleOtherCategories] = useState([]);

  const portCallsChanged = async data => {
    if (data) {
      const result = await apiCall('post', 'waste/search', { query: { id: data.data.map(item => item.id) } });
      if (result?.status === 200 && result?.data) {
        const stuff = data.data.map(pc => {
          const waste = result.data.find(w => w.port_call_id == pc.id);

          if (waste) {
            pc.waste_data = waste.data;
            pc.waste_signed = waste.signed;
          }

          return pc;
        });
        const newData = data;
        newData.data = stuff;

        setPortcallData(newData.data);
      }
    } else {
      setPortcallData([]);
    }
    setLoading(false);
  };

  const { data, error, fetchData } = useApi('get', 'port-calls', newParams, portCallsChanged, false);

  useEffect(() => {
    setLoading(true);
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  const showableOilCategories = oilCategories.filter(c => visibleOilCategories.some(c2 => c2.key === c.key));
  const showableNLSCategories = nlsCategories.filter(c => visibleNLSCategories.some(c2 => c2.key === c.key));
  const showableSewageCategories = sewageCategories.filter(c => visibleSewageCategories.some(c2 => c2.key === c.key));
  const showableGarbageCategories = garbageCategories.filter(c =>
    visibleGarbageCategories.some(c2 => c2.key === c.key)
  );
  const showableGasCategories = gasCategories.filter(c => visibleGasCategories.some(c2 => c2.key === c.key));
  const showableOtherCategories = otherCategories.filter(c => visibleOtherCategories.some(c2 => c2.key === c.key));

  return (
    <Container>
      {categories.length > 0 && (
        <>
          <List
            rowKey="id"
            columns={[
              ...columns,
              ...showableOilCategories,
              ...showableNLSCategories,
              ...showableSewageCategories,
              ...showableGarbageCategories,
              ...showableGasCategories,
              ...showableOtherCategories,
            ]}
            dataSource={portcallData}
            spinning={loading}
            setParams={setNewParams}
            newParams={newParams}
            start={start}
            total={total}
            searchPlaceHolder={t('Search')}
            expandedRowRender={record => (
              <ExtraRow>
                <Buttons>
                  {!record.waste_signed && (
                    <Button
                      disabled={!Object.keys(changedValues[record.id] || {}).length}
                      cancel
                      style={{ marginBottom: '0px' }}
                      onClick={() => handleSave(record.id)}
                    >
                      {t('Save')}
                    </Button>
                  )}
                  <ButtonLight
                    disabled={!record.waste_data}
                    cancel
                    style={{ marginBottom: '0px' }}
                    onClick={() => handleGenerateReceipt(record.id)}
                  >
                    {record.waste_signed ? t('View receipt') : t('Generate receipt')}
                  </ButtonLight>
                </Buttons>
              </ExtraRow>
            )}
            noUrlUpdate
            wrapHeaderText
            expandedRowKeys={expandedRows}
            onExpandedRowsChange={rows => setExpandedRows(rows)}
          />
          <WasteCategorySelect
            oilCategories={oilCategories}
            visibleOilCategories={visibleOilCategories}
            setVisibleOilCategories={setVisibleOilCategories}
            nlsCategories={nlsCategories}
            visibleNLSCategories={visibleNLSCategories}
            setVisibleNLSCategories={setVisibleNLSCategories}
            sewageCategories={sewageCategories}
            visibleSewageCategories={visibleSewageCategories}
            setVisibleSewageCategories={setVisibleSewageCategories}
            garbageCategories={garbageCategories}
            visibleGarbageCategories={visibleGarbageCategories}
            setVisibleGarbageCategories={setVisibleGarbageCategories}
            gasCategories={gasCategories}
            visibleGasCategories={visibleGasCategories}
            setVisibleGasCategories={setVisibleGasCategories}
            otherCategories={otherCategories}
            visibleOtherCategories={visibleOtherCategories}
            setVisibleOtherCategories={setVisibleOtherCategories}
          />
          {!!modalOpen && (
            <WasteReceiptModal
              portCallData={modalData}
              fetchListData={() => fetchData(false, newParams)}
              closeModal={() => setModalOpen(undefined)}
            />
          )}
        </>
      )}
    </Container>
  );
};
export default WasteList;
