import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import versionData from '../version';

import { UserContext } from '../context/UserContext';
import { TimestampContext } from '../context/TimestampContext';

import Icon from './ui/Icon';
import { darken } from 'polished';
import Profile from './Profile';
import HeaderWeather from './weather/HeaderWeather';
import HeaderSeaLevel from './weather/HeaderSeaLevel';
import AllNotifications from './AllNotifications';
import ServiceOrderTaskListButton from './ServiceOrderTaskListButton';
import IncidentSummaryButton from './IncidentSummaryButton';
import { mobilePixelMaxWidthLimit } from '../utils/constants';

const Navigation = styled.div`
  position: relative;
  z-index: 2;

  @media print {
    display: none;
  }
`;

const WholeContainer = styled.div`
  position: absolute;
  top: 42px;
  right: -0.8rem;
`;

const NavContainer = styled.div`
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
  height: calc(100vh - 50px);

  @media (max-width: 500px) {
    height: calc(100vh - 95px);
  }
`;

const Container = styled.div`
  display: flex;
`;

const BottomInfo = styled.div`
  margin-top: 32px;
  padding: 3px 42px;
  color: ${({ theme }) => theme.color.grey_light2};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.color.grey_light2)};
  }
`;

const StaticList = styled.div`
  width: 300px;
  height: 100%;
  position: relative;
  z-index: 5;
  padding-top: 14px;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  transition: width 0.3s;
`;

const FolderIcon = styled.div`
  position: absolute;
  right: 28px;
  top: 2px;
  line-height: 21px;
  margin-left: 3px;
  margin-top: -2px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const BaseMenuItem = styled.div`
  display: flex;
  height: 38px;
  background-color: ${props => (props.current ? props.theme.color.secondary : 'transparent')};
  margin-bottom: 12px;
  padding-left: 10px;
  color: ${props => (props.current ? props.theme.color.white : props.theme.color.grey_dark)};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const MenuItem = styled(BaseMenuItem)`
  margin-left: 8px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-top: 6px;
  margin-right: -1px;
  margin-top: ${props => props.sub && '-12px'};
  background-color: ${props => props.hover && !props.current && '#eaeaea'};

  i {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 100%;
  }
`;

const SliderMenuItem = styled(BaseMenuItem)`
  padding-top: 1px;
  width: 100%;
  margin-top: ${props => props.sub && '4px'};
  border-bottom-left-radius: ${props => props.sub && '4px'};
  border-top-left-radius: ${props => props.sub && '4px'};
  background-color: ${props => props.hover && !props.current && '#eaeaea'};

  &:hover {
    background-color: ${props => props.sub && '#eaeaea'};
  }
`;

const VersionNumber = styled.span``;
const VersionBuild = styled.span``;
const VersionTimestamp = styled.span`
  display: block;
  font-style: italic;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 1;
`;

const MenuIcon = styled(Icon)`
  position: relative;
  width: 32px;
  height: 32px;
  margin-top: 0px;

  svg {
    fill: white !important;
    width: 32px;
    height: 32px;
  }
`;

const ProfileContainer = styled.div`
  margin-top: 6px;
`;

const ProfileWeathersContainer = styled.div`
  margin-top: 6px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
`;

const Weathers = styled.div`
  margin-top: 6px;
`;

const Line = styled.div`
  border-bottom: 1px solid #aeaeae;
  margin: 0 12px;
  margin-top: 12px;
`;

const Icons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  align-items: center;
  background: ${({ theme }) => theme.color.primary};
  padding: 12px;

  @media (min-width: 501px) {
    display: none;
  }
`;

const HelpIcon = styled.div`
  width: 30px;
  margin-right: 10px;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-right: 12px;
    margin-top: -4px;
    width: 24px;
  }
`;

const MobileMenu = ({ navStructure, setNavStructure, sideHelpOpen, setSideHelpOpen }) => {
  const { namespace, logout, user, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { setSearch } = useContext(TimestampContext);

  const { version, build, ts } = versionData;

  const [navOpen, setNavOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const currentPath = location.pathname.includes(namespace)
    ? location.pathname.substring(1 + namespace.length, location.pathname.length)
    : location.pathname.substring(0, location.pathname.length);

  const handleClick = path => {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('vessel-notification-')) {
        localStorage.removeItem(key);
      }
    });
    if (path === '/') {
      setSearch('');
    }
    history.push(path);
  };

  const toggleFolder = (event, index) => {
    const newStructure = [...navStructure];
    if (!newStructure[index].open) {
      setNavOpen(true);
    }
    newStructure[index].open = !newStructure[index].open;
    setNavStructure(newStructure);
    event.stopPropagation();
  };

  const goToFirst = index => {
    const firstLink = navStructure[index].links[0];
    if (firstLink?.checks) {
      history.push(firstLink.path);
    } else {
      const secondLink = navStructure[index].links[1];
      if (secondLink?.checks) {
        history.push(secondLink.path);
      }
    }
  };

  const handleLogout = () => {
    history.entries = [];
    history.index = -1;
    history.push('/');
    logout();
  };

  return (
    <>
      {navOpen && <Blocker onClick={() => setNavOpen(false)} />}
      <Navigation data-id="navigation">
        <MenuIcon type="menu" onClick={() => setNavOpen(!navOpen)} />
        {navOpen && (
          <WholeContainer data-id="navigation-container" id="navigation-container">
            <Icons>
              {modules &&
                user &&
                modules.service_order_module === 'enabled' &&
                user.permissions.includes('manage_service_order_ack_delivery') && <ServiceOrderTaskListButton />}
              {modules &&
                user &&
                modules.incident_module === 'enabled' &&
                user.permissions.includes('view_incident') && <IncidentSummaryButton />}
              <AllNotifications />
              {(document.location.hostname === 'localhost' ||
                document.location.hostname.includes('ft-testing.dev')) && (
                <HelpIcon onClick={() => setSideHelpOpen(!sideHelpOpen)}>
                  <Icon type="help-circle" color="white" fill="none" />
                </HelpIcon>
              )}
            </Icons>
            <NavContainer>
              <ProfileWeathersContainer>
                <Weathers>
                  {modules.weather_module === 'enabled' && <HeaderWeather />}
                  {modules.sea_level_module === 'enabled' && <HeaderSeaLevel />}
                </Weathers>
                <ProfileContainer>
                  <Profile user={user} />
                </ProfileContainer>
              </ProfileWeathersContainer>
              <Line />
              <Container>
                <StaticList>
                  {navStructure.map((item, index) => {
                    if (item.type === 'link' && item.checks) {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handleClick(item.path)}
                          current={item.path === currentPath}
                        >
                          <Tooltip
                            placement="right"
                            title={t(item.title)}
                            color="white"
                            overlayInnerStyle={{ color: '#4a4a4a' }}
                          >
                            <Icon type={item.icon} />
                          </Tooltip>
                          <SliderMenuItem
                            key={index}
                            onClick={() => handleClick(item.path)}
                            current={item.path === currentPath}
                          >
                            {t(item.title)}
                          </SliderMenuItem>
                        </MenuItem>
                      );
                    } else if (item.type === 'folder' && item.links && item.links.findIndex(l => l.checks) > -1) {
                      let current = false;

                      if (
                        item.links.findIndex(
                          l => l.path === currentPath || l.alternativePaths?.findIndex(p => p === currentPath) > -1
                        ) > -1
                      ) {
                        current = true;
                      }

                      return (
                        <div key={index}>
                          <MenuItem
                            onClick={e => (item.type === 'folder' ? goToFirst(index) : toggleFolder(e, index))}
                            current={current}
                          >
                            <Tooltip
                              placement="right"
                              title={t(item.title)}
                              color="white"
                              overlayInnerStyle={{ color: '#4a4a4a' }}
                            >
                              <Icon type={item.icon} />
                            </Tooltip>
                            <SliderMenuItem current={current} onClick={e => toggleFolder(e, index)}>
                              {t(item.title)}
                              {item.open ? (
                                <FolderIcon>
                                  <Icon type="chevron-down" />
                                </FolderIcon>
                              ) : (
                                <FolderIcon>
                                  <Icon type="chevron-right-2" />
                                </FolderIcon>
                              )}
                            </SliderMenuItem>
                          </MenuItem>
                          {item.open && (
                            <>
                              {item.links.map((subItem, i) => {
                                if (subItem.checks) {
                                  if (subItem.type === 'sign-out') {
                                    return (
                                      <MenuItem sub={true} key={i} onClick={handleLogout}>
                                        <SliderMenuItem sub={true} key={i} onClick={handleLogout}>
                                          {t(subItem.title)}
                                        </SliderMenuItem>
                                      </MenuItem>
                                    );
                                  } else {
                                    return (
                                      <MenuItem sub={true} key={i} onClick={() => handleClick(subItem.path)}>
                                        <SliderMenuItem sub={true} key={i} onClick={() => handleClick(subItem.path)}>
                                          {t(subItem.title)}
                                        </SliderMenuItem>
                                      </MenuItem>
                                    );
                                  }
                                } else {
                                  return null;
                                }
                              })}
                            </>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </StaticList>
              </Container>

              <BottomInfo onClick={() => history.push('/release-notes')}>
                <VersionNumber>{version}</VersionNumber>,{' '}
                <VersionBuild>
                  {t('Build')} {build}
                </VersionBuild>
                <VersionTimestamp>{ts}</VersionTimestamp>
              </BottomInfo>
            </NavContainer>
          </WholeContainer>
        )}
      </Navigation>
    </>
  );
};

export default MobileMenu;
