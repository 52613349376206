import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CardNotificationModal from './CardNotification/CardNotificationModal';

import { UserContext } from '../../context/UserContext';
import Tooltip from 'antd/es/tooltip';

const CardDecisionWrap = styled.div`
  align-items: center;
  cursor: pointer;
`;

const CardNotificationWrap = styled.div`
  align-items: center;
  bottom: 0;
  cursor: pointer;
`;

const CardDecision = styled.div`
  white-space: nowrap;
`;

const CardDecisionLink = styled.span`
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey_dark};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  height: 25px;
  cursor: default;
  display: flex;
`;

const ColorBackground = styled.div`
  line-height: ${props => (props.small ? '15px' : '18px')};
  height: ${props => (props.small ? '15px' : '18px')};
  width: ${props => (props.small ? '15px' : '18px')};
  padding-bottom: 1px;
  border-radius: 45%;
  text-align: center;
  margin-left: 0.4rem;
  color: ${({ theme }) => theme.color.white};
  font-size: 10px;
`;

const RedBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning};
`;
const WarningYellow = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning_yellow};
`;

const GreyBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.grey};
`;

const NotificationRow = ({ notifications, decisions, imo, master_id, vessel_name, small = false }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const notification_summary = notifications;
  const decision_summary = decisions;

  const [modalOpen, setModalOpen] = useState(false);

  const handleNotificationClick = () => {
    setModalOpen(true);
  };

  return (
    <Wrapper>
      <Tooltip
        placement="top"
        title={t('Notification')}
        color="#ffffff"
        overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px' }}
      >
        <CardNotificationWrap>
          {notification_summary && (
            <CardDecision>
              <CardDecisionLink onClick={() => handleNotificationClick()}>
                {notification_summary.unread > 0 && (
                  <RedBackground small={small}>{notification_summary.unread}</RedBackground>
                )}
                {notification_summary.unread === 0 && (
                  <GreyBackground small={small}>{notification_summary.total}</GreyBackground>
                )}
              </CardDecisionLink>
            </CardDecision>
          )}
        </CardNotificationWrap>
      </Tooltip>
      {decision_summary && (
        <Tooltip
          placement="top"
          title={decision_summary.unanswered > 0 ? t('Open issues') : t('Issues')}
          color="#ffffff"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px' }}
        >
          <CardDecisionWrap>
            <CardDecision>
              <CardDecisionLink onClick={() => handleNotificationClick()}>
                {decision_summary.unanswered > 0 && (
                  <WarningYellow small={small}>{decision_summary.unanswered}</WarningYellow>
                )}
                {decision_summary.unanswered === 0 && (
                  <GreyBackground small={small}>{decision_summary.total}</GreyBackground>
                )}
              </CardDecisionLink>
            </CardDecision>
          </CardDecisionWrap>
        </Tooltip>
      )}
      {modalOpen && (
        <CardNotificationModal imo={imo} master_id={master_id} vessel_name={vessel_name} close={setModalOpen} />
      )}
    </Wrapper>
  );
};

export default NotificationRow;
