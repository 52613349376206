import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT } from '../../utils/constants';
import useApi from '../../hooks/useApi';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import InvoiceAlterationModal from './InvoiceAlterationModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
`;

const Imo = styled.div`
  margin-right: 4px;
`;

const IconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 18px;
    width: 18px;
    margin-left: 4px;
    margin-top: -2px;
  }
`;

const RowContainer = styled.div`
  margin: 0px 12px;
  max-height: 400px;
  overflow-y: auto;
`;

const Rows = styled.div`
  margin: 16px 0;
`;

const Row = styled.div`
  display: flex;
`;

const Value = styled.div`
  font-weight: 600;
  margin-left: 12px;
`;

const AutomatedInvoiceExceptionsList = () => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [apiCallPending, setApiCallPending] = useState(false);

  const [alterationModalOpen, setAlterationModalOpen] = useState(false);
  const [alterationModalReadOnly, setAlterationModalReadOnly] = useState(false);
  const [modalAlteration, setModalAlteration] = useState(undefined);

  const [duplicateCurrent, setDuplicateCurrent] = useState(false);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'id',
    search: '',
  };

  const getParamsInRightForm = useCallback(parameters => {
    let correctParams = {
      query: {
        text: parameters.search,
      },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'id',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi(
    'post',
    'invoicing/v2/invoice-alteration/search',
    getParamsInRightForm(newParams),
    null,
    false
  );

  let alterations = error || !data ? [] : data.results ? data.results.invoice_alterations : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [fetchData, getParamsInRightForm, newParams]);

  const types = [
    {
      key: 'add_product',
      label: t('Add product'),
    },
    {
      key: 'remove_product',
      label: t('Remove product'),
    },
    {
      key: 'add_modifier',
      label: t('Add modifier'),
    },
    {
      key: 'remove_modifier',
      label: t('Remove modifier'),
    },
  ];

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Targets'),
      dataIndex: 'targets',
      key: 'targets',
      render: record => {
        const imos = record.find(r => r.type === 'imos')?.value.imos;
        return (
          <ImoList>
            {imos.map((r, i) => (
              <Imo key={i}>{r}</Imo>
            ))}
          </ImoList>
        );
      },
    },
    {
      title: t('Operations'),
      dataIndex: 'action_list',
      key: 'action_list',
      render: (value, item) => {
        const record = item.actions;
        return (
          <Tooltip
            placement="left"
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px', padding: '4px 12px 12px 12px' }}
            title={
              <RowContainer>
                {record.map((r, i) => {
                  const type = types.find(a => a.key === r.type)?.label || r.type;
                  return (
                    <Rows key={i}>
                      <Row>
                        <Value style={{ marginLeft: '0px' }}>{type}</Value>
                      </Row>
                      {!!r.value.product && (
                        <Row style={{ marginLeft: '14px' }}>
                          {t('Product')}: <Value>{r.value.product.name}</Value>
                        </Row>
                      )}
                      {!!r.value.modifier && (
                        <Row style={{ marginLeft: '14px' }}>
                          {t('Modification')}: <Value>{r.value.modifier.name}</Value>
                        </Row>
                      )}
                    </Rows>
                  );
                })}
              </RowContainer>
            }
          >
            {record.length} {t('operation(s)')}
            <IconContainer>
              <Icon type="info" />
            </IconContainer>
          </Tooltip>
        );
      },
    },
  ];

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/invoice-alteration/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, getParamsInRightForm(newParams));
  };

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalAlteration(record);
                setAlterationModalOpen(true);
              }}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <Popconfirm
              title={t('Are you sure you want to remove the alteration?')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-new-list"
              key="action-5"
            >
              <ListActionButton red title={t('Delete')}>
                <Icon type="trash" />
                {t('Delete')}
              </ListActionButton>
            </Popconfirm>
          ),
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setAlterationModalReadOnly(true);
                setModalAlteration(record);
                setAlterationModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          onClick: () => {
            setModalAlteration(undefined);
            setAlterationModalOpen(true);
          },
          text: t('Create new exception'),
          icon: 'plus',
        },
      ]
    : [];

  const closeAlterationModal = () => {
    setModalAlteration(undefined);
    setAlterationModalOpen(false);
    setAlterationModalReadOnly(false);
    setDuplicateCurrent(false);
  };

  const closeAlterationModalAndReload = () => {
    closeAlterationModal();
    fetchData(false, getParamsInRightForm(newParams));
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={alterations}
        apiCallPending={apiCallPending}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        actions={actionList}
        noUrlUpdate={true}
      />

      {alterationModalOpen && (
        <InvoiceAlterationModal
          alteration={modalAlteration}
          closeModal={closeAlterationModal}
          closeAndReload={closeAlterationModalAndReload}
          readOnly={alterationModalReadOnly}
          duplicateCurrent={duplicateCurrent}
        />
      )}
    </Container>
  );
};

export default AutomatedInvoiceExceptionsList;
