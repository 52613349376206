import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import IncidentHistoryItem from './IncidentHistoryItem';

const HistoryHeader = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 4px;
  padding: 3px 0;
  padding-left: 3px;
  margin-left: -3px;
  align-items: center;

  &:hover {
    background-color: #f7f7f7;
  }

  span:first-letter {
    text-transform: capitalize;
  }
`;

const ChevronIconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 18px;

  svg {
    margin-top: 7px;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const IncidentHistory = ({ historyData, type }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [historyOpen, setHistoryOpen] = useState(true);

  return (
    <>
      <HistoryHeader onClick={() => setHistoryOpen(!historyOpen)}>
        <span>{t('{{type}} history', { type })}</span>
        {!historyOpen ? (
          <ChevronIconContainer>
            <Icon type="chevron-down" />
          </ChevronIconContainer>
        ) : (
          <ChevronIconContainer>
            <Icon type="chevron-up" />
          </ChevronIconContainer>
        )}
      </HistoryHeader>
      <Items>
        {historyOpen && historyData.map(incident => <IncidentHistoryItem key={incident.id} data={incident} />)}
      </Items>
    </>
  );
};
export default IncidentHistory;
