import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';
import Icon from './ui/Icon';
import ButtonLight from './ui/ButtonLight';
import Select from 'antd/es/select';
import ProfileCircle from './ui/ProfileCircle';

const Container = styled.div`
  display: inline;
  position: relative;
`;

const Popover = styled.div`
  background-color: white;
  position: absolute;
  right: 0px;
  top: 6px;
  padding: 22px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 80;
  width: 280px;
`;

const NameRow = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  margin-top: 12px;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
`;

const InfoRow = styled.div`
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  font-size: 13px;
  line-height: 22px;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const ActionButtons = styled.div`
  text-align: center;
  margin-top: 1rem;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    margin-bottom: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
    svg {
      fill: #4a4a4a;
    }
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
`;

const LanguageSelection = styled.div`
  margin-bottom: 24px;
  margin-top: 8px;
`;

const Label = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.black};
`;

const LANGUAGES = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
];

const WeatherStationDropdown = ({ user }) => {
  const { namespace, logout } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const closePopover = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    history.entries = [];
    history.index = -1;
    history.push('/');
    logout();
  };

  const setLanguage = e => {
    i18n.changeLanguage(e, err => {
      if (err) return console.log('Translation change failed', err);
    });
  };

  let usedLanguage = LANGUAGES.find(l => l.code === i18n.language);

  return (
    <>
      <Container>
        <ProfileCircle clickFunction={toggleOpen} />

        {open && (
          <BackgroundContainer>
            <Blocker onClick={closePopover} />
            <Popover>
              <ProfileCircle />
              <NameRow>
                {t('Name')}: {user.first_name} {user.last_name}
              </NameRow>
              <InfoRow>
                {t('Email')}: {user.email}
              </InfoRow>
              <InfoRow>
                {t('Role')}: {user.role}
              </InfoRow>
              <InfoRow>
                {t('ID')}: {user.id}
              </InfoRow>
              {(document.location.hostname === 'localhost' ||
                document.location.hostname.includes('ft-testing.dev')) && (
                <LanguageSelection>
                  <Label>{t('Language')}:</Label>
                  <Select
                    placeholder={t('Duration')}
                    optionFilterProp="label"
                    onChange={e => {
                      setLanguage(e);
                    }}
                    style={{ width: '100%' }}
                    options={LANGUAGES.map(option => {
                      return {
                        label: option.name + ' (' + option.code + ')',
                        value: option.code,
                      };
                    })}
                    value={usedLanguage ? usedLanguage.code : 'en'}
                  />
                </LanguageSelection>
              )}

              <ActionButtons>
                <ButtonLight type="button" cancel onClick={handleLogout}>
                  <Icon type="logout" />
                  {t('Sign out')}
                </ButtonLight>
                <ButtonLight type="button" onClick={() => history.push('/profile')}>
                  <Icon type="profile" fill="#ffffff !important" />
                  {t('Profile')}
                </ButtonLight>
              </ActionButtons>
            </Popover>
          </BackgroundContainer>
        )}
      </Container>
    </>
  );
};

export default WeatherStationDropdown;
