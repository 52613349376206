import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

const Header = styled.div`
  font-size: 18px;
`;

const NotFound = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const timerRef = useRef();

  useEffect(() => {
    document.title = 'Port Activity App';
    timerRef.current = setTimeout(() => {
      document.title = '404 | Port Activity App';
    }, 1200);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Layout pagename={t('Not Found')}>
      <Page fullWidth>
        <Header>{t('404 - Page Not Found')}</Header>
      </Page>
    </Layout>
  );
};

export default NotFound;
