import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import Popconfirm from 'antd/es/popconfirm';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../../components/ui/Icon';
import Layout from '../../components/Layout';

import Page from '../../components/ui/Page';
import { PAGINATION_LIMIT } from '../../utils/constants';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const LoadingMasterPetroleumJobs = () => {
  const { useUserSocket, apiCall, namespace, user, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [apiCallPending, setApiCallPending] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = t('Loading master');
  }, [t]);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'id DESC',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { data, error, fetchData } = useApi('get', 'lma-petroleum-jobs', newParams); // VAIHDA TÄMÄ TAKASIN

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const jobs = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  jobs.forEach(p => {
    p._row = start + counter;
    counter++;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', 'lma-petroleum-jobs', { id: id });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const columns = [
    {
      title: t('Job ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('IMO'),
      key: 'imo',
      sortableKey: 'imo',
      render: record => record.vessel?.imo,
    },
    {
      title: t('Vessel name'),
      key: 'vessel_name',
      sortableKey: 'vessel_name',
      render: record => record.vessel?.name,
    },
    {
      title: t('Status'),
      key: 'status',
      sortableKey: 'status',
      render: record => {
        let readeableStatus = t('Unknown');
        if (record.status === 'not-started') {
          readeableStatus = t('Not started');
        } else if (record.status === 'ongoing') {
          readeableStatus = t('Ongoing');
        } else if (record.status === 'completed') {
          readeableStatus = t('Completed');
        } else if (record.status === 'done') {
          readeableStatus = t('Done');
        }
        return readeableStatus;
      },
    },
    {
      title: t('ETC'),
      key: 'etc',
      sortableKey: 'etc',
      render: record => {
        return record.etc === null ? 'N/A' : <DateComponent format={TIME_FORMAT} date={record.etc} />;
      },
    },
    {
      title: t('Remaining pump time'),
      key: 'remaining_pump_time_seconds',
      sortableKey: 'remaining_pump_time_seconds',
      render: record => {
        return record.remaining_pump_time_seconds === null
          ? 'N/A'
          : dayjs.duration(record.remaining_pump_time_seconds * 1000).format('HH:mm', { trim: false });
      },
    },
  ];

  const actionList = [
    {
      render: record => {
        let editJob =
          user.permissions.includes('manage_loading_job') && record.status !== 'done' && record.status !== 'completed';
        return (
          editJob && (
            <ListActionButton
              key="action-1"
              onClick={() => history.push(`/loading-master-petroleum-job?id=${record.id}`)}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          )
        );
      },
    },
    {
      render: record => {
        let viewJob =
          !user.permissions.includes('manage_loading_job') || record.status === 'done' || record.status === 'completed';
        return (
          viewJob && (
            <ListActionButton
              key="action-2"
              onClick={() => history.push(`/loading-master-petroleum-job?id=${record.id}`)}
            >
              <Icon type="view" />
              {t('View')}
            </ListActionButton>
          )
        );
      },
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete job {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-4"
        >
          <div>
            <ListActionButton red disabled={record.status === 'done' || record.status === 'completed'}>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const reFetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useUserSocket('petroleum-jobs-changed', reFetch);

  const additionalButtons = [
    {
      onClick: () => history.push('/loading-master-petroleum-job'),
      disabled: !user.permissions.includes('manage_loading_job'),
      text: t('Add new'),
      icon: 'plus',
    },
  ];

  return (
    <Layout pagename={t('Loading Master Jobs')}>
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={jobs}
          apiCallPending={apiCallPending}
          actions={actionList}
          spinning={false}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search by name or exact IMO')}
          additionalButtons={additionalButtons}
        />
      </Page>
    </Layout>
  );
};

export default LoadingMasterPetroleumJobs;
