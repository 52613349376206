import React, { useEffect, useRef, useContext, useState } from 'react';
import styled from 'styled-components';
import { TileLayer, useMap, useMapEvents, Polygon, FeatureGroup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';
import L from 'leaflet';

import { v4 as uuid } from 'uuid';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import DrawControl from './DrawControl';

import { getOptions } from '../map/MapUtils';
import { UserContext } from '../../context/UserContext';
import IncidentMarker from './IncidentMarker';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 24px;
  display: inline-grid;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 3px;
    margin-left: -1px;
  }
`;

const Tools = styled.div`
  background-color: white;
  align-items: center;
  width: 34px;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  top: 80px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1000;
`;

const ToolButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
  i {
    width: 60%;
    height: 60%;
  }
`;

const IncidentsModalMapInnerContainer = ({
  zoom,
  setZoom,
  setPoint,
  setAreas,
  mapOn,
  values,
  deleteMapId,
  pointMap,
  editOn,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();
  const map = useMap();

  const mapOnRef = useRef();
  const pointMapRef = useRef();

  const [deleteAssets, setDeleteAssets] = useState(false);
  const [editCentering, setEditCentering] = useState(editOn);

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      if (mapOn) {
        setZoom(mapWithEvents.getZoom());
        mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
      }
    },
    click: e => {
      if (mapOn && pointMap) {
        setPoint(map.layerPointToLatLng(e.layerPoint));
      }
    },
  });

  useEffect(() => {
    map.invalidateSize();
  });

  useEffect(() => {
    if (editCentering) {
      setEditCentering(false);
      if (values && values.length > 0) {
        if (values[0].geometry.type === 'Point') {
          mapWithEvents.setView(
            [values[0].geometry.coordinates[1], values[0].geometry.coordinates[0]],
            mapWithEvents.getZoom()
          );
        } else {
          let group = new L.FeatureGroup();
          values.forEach(d => {
            group.addLayer(L.polygon(L.GeoJSON.coordsToLatLngs(d.geometry.coordinates, 1)));
          });
          map.fitBounds(group.getBounds(), { padding: [50, 50] });
        }
      }
    }

    mapOnRef.current = mapOn;
    pointMapRef.current = pointMap;
  }, [mapOn, pointMap]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  const drawAssets = coords => {
    setAreas({
      type: 'Feature',
      properties: {
        id: uuid(),
      },
      style: {},
      geometry: {
        type: 'Polygon',
        coordinates: coords,
      },
    });
  };

  const deleteAssetFunction = data => {
    deleteMapId(data);
  };

  const zoomPrevDiv = L.DomUtil.get('zoompreventer');
  if (zoomPrevDiv) {
    L.DomEvent.disableClickPropagation(zoomPrevDiv);
  }

  return (
    <>
      <ZoomButtonContainer id={'zoompreventer'}>
        <ZoomButton
          data-testid="map-zoom-in"
          copy
          onClick={() => handleZoomButton(zoom + 1)}
          style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
        >
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton
          data-testid="map-zoom-out"
          copy
          onClick={() => handleZoomButton(zoom - 1)}
          style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
        >
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      {pointMap && values[0]?.geometry.type === 'Point' && (
        <IncidentMarker coords={values[0]?.geometry.coordinates} level={1} />
      )}
      {!pointMap && (
        <>
          <FeatureGroup>
            <DrawControl drawAssets={drawAssets} />
          </FeatureGroup>

          <Tools>
            <Tooltip
              title={t('Delete assets/points')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="left"
            >
              <ToolButton
                style={{
                  color: '#4A4A4A',
                  backgroundColor: deleteAssets ? '#FF0000' : '#FFF',
                  borderBottom: '1px solid #ccc',
                }}
                onClick={() => {
                  setDeleteAssets(!deleteAssets);
                }}
              >
                <Icon type="trash-bold" />
              </ToolButton>
            </Tooltip>
          </Tools>
        </>
      )}
      {!pointMap &&
        !!values &&
        values.length > 0 &&
        values.map((value, id) => {
          if (value.geometry.type === 'Polygon') {
            return (
              <Polygon
                key={id}
                positions={L.GeoJSON.coordsToLatLngs(value.geometry.coordinates, 1)}
                fillColor="#2254c0"
                fillOpacity={0.5}
                color="#2254c0"
                opacity={0.7}
                eventHandlers={{
                  click: () => {
                    if (deleteAssets) {
                      deleteAssetFunction(value.properties.id);
                    }
                  },
                }}
              />
            );
          }
        })}
    </>
  );
};

export default IncidentsModalMapInnerContainer;
