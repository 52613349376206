import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import { Chart, registerables } from 'chart.js';
import Icon from '../ui/Icon';
import { TIME_FORMAT_DAY, mobilePixelMaxWidthLimit } from '../../utils/constants';
Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;

  .ant-select-show-arrow {
    height: 24px !important;
  }
`;

const BarContainer = styled.div`
  height: ${props => (props.column1 ? 'calc(100vh / 3)' : 'calc(100vh / 4)')};
  max-height: ${props => (props.column1 ? '400px' : '300px')};

  ${props => {
    if (props.extraSpace) {
      return `
        max-height: 500px;
        height: calc(100vh / 3);
      `;
    }
  }}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
  padding-right: 4px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const Button = styled.div`
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px;
  color: ${props => props.disabled && '#e8e8e8'};
  cursor: ${props => props.disabled && 'default'};

  svg {
    margin-top: -2px;
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f3f3f3')};
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const EndOfFirstRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const StatisticsBar1Column = ({ yAxel, ...props }) => {
  const buildQueryParams = (timeType, time) => {
    if (dayjs(time).isAfter(dayjs())) {
      time = dayjs().toDate();
      setSelectedTime(time);
    }
    let from = null;
    let to = null;
    if (timeType === 'week') {
      from = dayjs(time).startOf('isoweek');
      to = dayjs(time).endOf('isoweek');
    } else if (timeType === 'month') {
      from = dayjs(time).startOf('month');
      to = dayjs(time).endOf('month');
    } else if (timeType === 'year') {
      from = dayjs(time).startOf('year');
      to = dayjs(time).endOf('year');
    }
    to.isAfter(dayjs()) && (to = dayjs());
    return {
      ...props.data_source.parameters,
      time_option: timeType,
      timezone: dayjs.tz.guess(),
      from: from.toISOString(),
      to: to.toISOString(),
    };
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [timeType, setTimeType] = useState('week'); // week, month, year
  const [selectedTime, setSelectedTime] = useState(dayjs().toDate());
  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useApi(
    'get',
    props.data_source.url,
    buildQueryParams(timeType, selectedTime)
  );

  const dataObject = error || !data ? {} : data.data;
  const legendObject = props.legend;
  const legendKeys = Object.keys(legendObject);

  const getTimeSpan = () => {
    let time = selectedTime;
    if (dayjs(time).isAfter(dayjs())) {
      time = dayjs().toDate();
      setSelectedTime(time);
    }
    let from = null;
    let to = null;
    if (timeType === 'week') {
      from = dayjs(time).startOf('isoweek');
      to = dayjs(time).endOf('isoweek');
    } else if (timeType === 'month') {
      from = dayjs(time).startOf('month');
      to = dayjs(time).endOf('month');
    } else if (timeType === 'year') {
      from = dayjs(time).startOf('year');
      to = dayjs(time).endOf('year');
    }
    to.isAfter(dayjs()) && (to = dayjs());

    return dayjs(from).format(TIME_FORMAT_DAY) + ' - ' + dayjs(to).format(TIME_FORMAT_DAY);
  };

  const moveBackwards = () => {
    setStartLoader(false);
    if (timeType === 'week') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'week')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (timeType === 'month') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'month')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (timeType === 'year') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'year')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    }
  };

  const moveForwards = () => {
    setStartLoader(false);
    if (timeType === 'week') {
      const newTime = dayjs(selectedTime)
        .add(1, 'week')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (timeType === 'month') {
      const newTime = dayjs(selectedTime)
        .add(1, 'month')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    } else if (timeType === 'year') {
      const newTime = dayjs(selectedTime)
        .add(1, 'year')
        .toDate();
      setSelectedTime(newTime);
      fetchData(false, buildQueryParams(timeType, newTime));
    }
  };

  const moveToToday = () => {
    setStartLoader(false);
    setSelectedTime(dayjs().toDate());
    fetchData(false, buildQueryParams(timeType, dayjs().toDate()));
  };

  let datasets = null;
  for (const key of legendKeys) {
    const value = dataObject[key];
    if (value) {
      datasets === null && (datasets = { labels: [''], datasets: [] });
      datasets.datasets.push({
        label: legendObject[key].title,
        data: value ? [value] : [0],
        backgroundColor: legendObject[key].color,
      });
    }
  }

  let extraSpace = false;
  if (datasets?.datasets?.length > 7) {
    extraSpace = true;
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.6,
    categoryPercentage: 0.8,
    maxBarThickness: 30,
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: extraSpace ? 40 : 20,
        top: 25,
      },
    },
    elements: {
      bar: {
        borderRadius: 6,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
        },
      },
      title: {
        text: getTimeSpan(),
        display: true,
        padding: {
          bottom: 32,
        },
        font: {
          weight: 'normal',
          size: 14,
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: yAxel
        ? {
            grid: {
              borderDash: [8, 6],
              color: '#f4f4f4',
            },
          }
        : {
            grid: {
              display: false,
            },
            display: false,
          },
    },
  };

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
          <EndOfFirstRow>
            {loading && !startLoader && (
              <MiniLoader>
                <Spin size="small" />
              </MiniLoader>
            )}
            <Button onClick={moveToToday}>{t('Today')}</Button>
            <Button onClick={moveBackwards}>
              <RotatedIcon type="chevron-right-2" />
            </Button>
            <Button onClick={moveForwards} style={{ marginRight: '12px' }}>
              <Icon type="chevron-right-2" />
            </Button>
            <Select
              value={timeType}
              style={{ width: 100 }}
              onChange={e => {
                setStartLoader(false);
                setTimeType(e);
                fetchData(false, buildQueryParams(e, selectedTime));
              }}
              size="small"
              options={[
                {
                  value: 'week',
                  label: t('Week'),
                },
                {
                  value: 'month',
                  label: 'Month',
                },
                {
                  value: 'year',
                  label: 'Year',
                },
              ]}
            />
          </EndOfFirstRow>
        </HeaderRow>

        {startLoader && loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : datasets ? (
          <BarContainer column1={props.column1} extraSpace={extraSpace}>
            <Bar plugins={[ChartDataLabels]} options={options} data={datasets} />
          </BarContainer>
        ) : (
          <NoData>{t('No data available between {{timespan}}.', { timespan: getTimeSpan() })}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default StatisticsBar1Column;
