import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import useApi from '../../hooks/useApi';
import TemplateModal from './TemplateModal';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const IconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 16px;
    width: 16px;
    margin-left: 4px;
    margin-top: -2px;
  }
`;

const AsteriskContainer = styled.span`
  color: ${props => props.theme.color.dark_grey};

  svg {
    height: 8px;
    width: 8px;
    margin-top: -2px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;

const Value = styled.div`
  font-weight: 400;
`;

const TemplateList = () => {
  const { namespace, useUserSocket, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(undefined);

  const [readOnly, setReadOnly] = useState(false);
  const [duplicateCurrent, setDuplicateCurrent] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'id',
    search: '',
  };

  const getParamsInRightForm = useCallback(parameters => {
    let correctParams = {
      query: {
        text: parameters.search,
        conditions: {
          and: [
            {
              type: 'automation_exists',
              operator: 'exists',
              value: false,
            },
          ],
        },
      },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'invoice_date',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi(
    'post',
    'invoicing/v2/template/search',
    getParamsInRightForm(newParams),
    null,
    false
  );

  useUserSocket('invoicing-templates-changed', () => fetchData(false, getParamsInRightForm(newParams)));

  let templateList = error || !data ? [] : data.results ? data.results.templates : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [newParams, fetchData, getParamsInRightForm]);

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Template name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Customer name'),
      dataIndex: 'customer_number',
      key: 'customer_number',
      render: (record, item) => {
        return item.customer?.name;
      },
    },
    {
      title: t('Customer number'),
      dataIndex: 'customer_number',
      key: 'customer_number',
      sortableKey: 'customer_number',
    },
    {
      title: t('Due in'),
      dataIndex: 'due_date_days',
      key: 'due_date_days',
      render: record => {
        return record ? record + ' ' + t('days') : '-';
      },
    },
    {
      title: t('Tax class'),
      dataIndex: 'tax_class_name',
      key: 'tax_class_name',
      render: record => {
        if (record) {
          return record;
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Rows'),
      dataIndex: 'template_rows',
      key: 'template_rows',
      render: record => {
        return (
          <div>
            {record.length}
            <Tooltip
              placement="left"
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px', padding: '12px' }}
              title={
                <Rows>
                  {record.map((r, i) => {
                    return (
                      <Rows key={i} style={{ marginTop: '3px', marginBottom: '3px' }}>
                        <Value>
                          {r.product.name} ({r.product.code})
                        </Value>
                        {r.modifiers.map((m, i) => {
                          return (
                            <Rows key={i}>
                              <Value style={{ marginLeft: '24px' }}>
                                <AsteriskContainer>
                                  <Icon type="asterisk" />
                                </AsteriskContainer>
                                {m.modifier.name} ({m.modifier.code})
                              </Value>
                            </Rows>
                          );
                        })}
                      </Rows>
                    );
                  })}
                </Rows>
              }
            >
              <IconContainer>
                <Icon type="info" />
              </IconContainer>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/template/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, getParamsInRightForm(newParams));
  };

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalTemplate(record);
                setTemplateModalOpen(true);
              }}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setDuplicateCurrent(true);
                setModalTemplate(record);
                setTemplateModalOpen(true);
              }}
            >
              <Icon type="duplicate" />
              {t('Duplicate')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <Popconfirm
              title={t('Are you sure you want to remove the template?')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-new-list"
              key="action-5"
            >
              <ListActionButton red title={t('Delete')}>
                <Icon type="trash" />
                {t('Delete')}
              </ListActionButton>
            </Popconfirm>
          ),
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setReadOnly(true);
                setModalTemplate(record);
                setTemplateModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          onClick: () => {
            setModalTemplate(undefined);
            setTemplateModalOpen(true);
          },
          text: t('Create new template'),
          icon: 'plus',
        },
      ]
    : [];

  const closeTemplateModal = reload => {
    setModalTemplate(undefined);
    setTemplateModalOpen(false);
    setDuplicateCurrent(false);
    setReadOnly(false);

    if (reload) {
      fetchData(false, getParamsInRightForm(newParams));
    }
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={templateList}
        spinning={loading}
        apiCallPending={apiCallPending}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        actions={actionList}
        noUrlUpdate={true}
      />

      {templateModalOpen && (
        <TemplateModal
          template={modalTemplate}
          closeModal={closeTemplateModal}
          readOnly={readOnly}
          duplicateCurrent={duplicateCurrent}
        />
      )}
    </Container>
  );
};

export default TemplateList;
