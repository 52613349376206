import React, { useContext, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Popover from 'antd/es/popover';

import { FleetViewContext } from '../../../context/FleetViewContext';

import { color_planned, color_not_planned, color_uncommitted } from '../../commonCalendar/utility/constants';
import FleetTimelineServiceOrders from '../FleetTimelineServiceOrders';
import VesselTimestampFleetModalCard from '../modals/VesselTimestampFleetModalCard';
import ServiceOrderTooltip from '../../activity/serviceOrder/ServiceOrderTooltip';
import { UserContext } from '../../../context/UserContext';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

const VesselContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  z-index: 50;
  opacity: 1;
  box-shadow: none;
  cursor: pointer;
  border: solid 1px white;

  ${props => {
    if (props.inPilotCompleted || !props.inPilotEstimated) {
      if (props.outPilotCompleted) {
        return `
            background-color: ${color_planned};
          `;
      } else if (props.outPilotCommenced) {
        return `
            background-color: ${color_planned};
          `;
      } else if (props.outPilotOrdered) {
        return `
            background-color: ${color_not_planned};
          `;
      } else if (props.outPilotEstimated) {
        return `
            background-color: ${color_uncommitted};
          `;
      } else if (props.inPilotCompleted) {
        return `
            background-color: ${color_planned};
          `;
      } else {
        return `
          background-color: #f7fbfc;
        `;
      }
    } else {
      if (props.inPilotCommenced) {
        return `
            background-color: ${color_planned};
          `;
      } else if (props.inPilotOrdered) {
        return `
            background-color: ${color_not_planned};
          `;
      } else if (props.inPilotEstimated) {
        return `
            background-color: ${color_uncommitted};
          `;
      } else {
        return `
          background-color: #f7fbfc;
        `;
      }
    }
  }};
`;

const VesselInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 3px;

  ${props => {
    if (props.inPilotCompleted) {
      if (props.outPilotCompleted) {
        return `
          border: 1px solid #0a4726;
        `;
      } else if (props.outPilotCommenced) {
        return `
          border: 1px solid #2ea363;
        `;
      } else if (props.outPilotOrdered) {
        return `
          border: 1px solid #6f97de;
        `;
      } else if (props.outPilotEstimated) {
        return `
          border: 1px solid #edca1a;
        `;
      } else if (props.inPilotCompleted) {
        return `
          border: 1px solid #0a4726;
        `;
      } else {
        return `
          border: 1px solid #a0a0a0;
        `;
      }
    } else {
      if (props.inPilotCommenced) {
        return `
          border: 1px solid #2ea363;
        `;
      } else if (props.inPilotOrdered) {
        return `
          border: 1px solid #6f97de;
        `;
      } else if (props.inPilotEstimated) {
        return `
          border: 1px solid #edca1a;
        `;
      } else {
        return `
          border: 1px solid #a0a0a0;
        `;
      }
    }
  }}
`;

const VesselNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px 0 2px;
  color: #000000;
  font-weight: 600;
  z-index: 150;
`;

const TooltipContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  padding: ${props => (props.serviceOrdersAvailable ? '12px' : '0px')};
`;

const StartStripes = styled.div`
  position: absolute;
  left: -1px;
  height: 100%;

  ${props => {
    if (props.inPilotCompleted) {
      if (props.outPilotCompleted) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.outPilotCommenced) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.outPilotOrdered) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #6f97de 1.5px, #6f97de 3px);
        `;
      } else if (props.outPilotEstimated) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #edca1a 1.5px, #edca1a 3px);
        `;
      } else if (props.inPilotCompleted) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      }
    } else {
      if (props.inPilotCommenced) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.inPilotOrdered) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #6f97de 1.5px, #6f97de 3px);
        `;
      } else if (props.inPilotEstimated) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #edca1a 1.5px, #edca1a 3px);
        `;
      }
    }
  }}
`;

const EndStripes = styled.div`
  position: absolute;
  right: -1px;
  height: 100%;

  ${props => {
    if (props.inPilotCompleted) {
      if (props.outPilotCompleted) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.outPilotCommenced) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.outPilotOrdered) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #6f97de 1.5px, #6f97de 3px);
        `;
      } else if (props.outPilotEstimated) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #edca1a 1.5px, #edca1a 3px);
        `;
      } else if (props.inPilotCompleted) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      }
    } else {
      if (props.inPilotCommenced) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #2ea363 1.5px, #2ea363 3px);
        `;
      } else if (props.inPilotOrdered) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #6f97de 1.5px, #6f97de 3px);
        `;
      } else if (props.inPilotEstimated) {
        return `
          background: repeating-linear-gradient(-45deg, transparent, transparent 1.5px, #edca1a 1.5px, #edca1a 3px);
        `;
      }
    }
  }}
`;

const Vessel = ({ vessel, rowIndex, serviceOrders }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    rowHeight,
    setSideModalOpen,
    sidebarSelection,
    viewWidthInPixels,
    scrollOffset,
    millisecondInPixels,
    momentCanvasStartTime,
    momentCanvasEndTime,
  } = useContext(FleetViewContext);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  if (!(vessel.eta && (vessel.ptd || vessel.etd))) {
    return null;
  }

  let starttime = null;
  let endtime = null;

  let startPart = 0;
  let endPart = 0;

  if (vessel.startInTimestamp) {
    starttime = dayjs.max([dayjs.min([dayjs(vessel.startInTimestamp), dayjs(vessel.eta)]), momentCanvasStartTime]);
    startPart = dayjs.duration(dayjs(vessel.eta).diff(vessel.startInTimestamp)).asMilliseconds() * millisecondInPixels;
  } else {
    starttime = dayjs.max([dayjs(vessel.eta), momentCanvasStartTime]);
  }

  if (vessel.startOutTimestamp) {
    endtime = dayjs.min([
      dayjs.max([dayjs(vessel.ptd || vessel.etd), dayjs(vessel.startOutTimestamp)]),
      momentCanvasEndTime,
    ]);
    endPart =
      dayjs.duration(dayjs(vessel.startOutTimestamp).diff(vessel.ptd || vessel.etd)).asMilliseconds() *
      millisecondInPixels;
  } else {
    endtime = dayjs.min([dayjs(vessel.ptd || vessel.etd), momentCanvasEndTime]);
  }

  const left = dayjs.duration(dayjs(starttime).diff(momentCanvasStartTime)).asMilliseconds() * millisecondInPixels;
  const width = dayjs.duration(dayjs(endtime).diff(starttime)).asMilliseconds() * millisecondInPixels;
  const right = left + width;

  const row = vessel.row || 0;
  let top = (sidebarSelection === 'port' ? 6 : 18) + row * rowHeight + rowIndex * rowHeight;

  const getVesselProps = () => {
    const fontSize = rowHeight > 20 ? '12px' : '9px';

    return {
      style: {
        width: isNaN(width) ? 0 : width > 16 ? width : 16,
        top,
        left,
        height: rowHeight,
        fontSize,
      },
    };
  };

  const showVesselModal = () => {
    setSideModalOpen({
      imo: vessel.imo,
      name: vessel.data.vesselName,
      port_call_id: vessel.port_call_id,
      eta: vessel.eta,
    });
  };

  const VesselToolTip = () => {
    let requested = [];
    let acknowledged = [];
    let delivered = [];
    if (serviceOrders && serviceOrders.length > 0) {
      serviceOrders.forEach(so => {
        if (so.order_lines) {
          so.order_lines.forEach(ol => {
            if (ol.deliveries.length > 0) {
              delivered.push(ol);
            } else if (ol.ack) {
              acknowledged.push({ service_order_id: so.id, ...ol });
            } else {
              requested.push({ service_order_id: so.id, ...ol });
            }
          });
        }
      });
    }

    const serviceOrdersAvailable = requested.length > 0 || acknowledged.length > 0 || delivered.length > 0;

    return (
      <TooltipContainer serviceOrdersAvailable={serviceOrdersAvailable}>
        <VesselTimestampFleetModalCard
          data={vessel.data}
          imo={vessel.imo}
          master_id={vessel.master_id}
          notifications={undefined}
          decisions={undefined}
          transitions={undefined}
          privateData={undefined}
          map={true}
          fleetAisData={vessel}
          marineTraffic={true}
          inPilot={vessel.pilotages?.inTimestamp}
          outPilot={vessel.pilotages?.outTimestamp}
        />
        {serviceOrdersAvailable ? ServiceOrderTooltip(requested, acknowledged, delivered, t, true) : null}
      </TooltipContainer>
    );
  };

  const scroll = viewWidthInPixels - scrollOffset;

  const getXAlign = () => {
    const leftLimit = viewWidthInPixels - scroll;
    const rightLimit = 2 * viewWidthInPixels - scroll;
    const leftVisible = right - leftLimit;
    const rightVisible = left - rightLimit;
    const vesselMiddle = left + width / 2;

    if ((vesselMiddle > leftLimit && vesselMiddle < rightLimit) || (left > leftLimit && right < rightLimit)) {
      return 0;
    }

    if ((left > leftLimit && left < rightLimit) || (right > leftLimit && right < rightLimit)) {
      if (left < leftLimit) {
        if (leftVisible < viewWidthInPixels * 0.99) {
          return width / 2;
        }
      }
      if (right > rightLimit) {
        if (rightVisible < viewWidthInPixels * 0.99) {
          return width / 2;
        }
      }
    }

    return 0;
  };

  return (
    <>
      <VesselContainer
        data-testid={`vessel-${vessel.master_id}`}
        {...getVesselProps()}
        locked={false /* vessel.ship.locked */}
        index={rowIndex}
        onClick={showVesselModal}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        outPilotEstimated={vessel.pilotages?.outPilotEstimated}
        outPilotOrdered={vessel.pilotages?.outPilotOrdered}
        outPilotCommenced={vessel.pilotages?.outPilotCommenced}
        outPilotCompleted={vessel.pilotages?.outPilotCompleted}
        inPilotEstimated={vessel.pilotages?.inPilotEstimated}
        inPilotOrdered={vessel.pilotages?.inPilotOrdered}
        inPilotCommenced={vessel.pilotages?.inPilotCommenced}
        inPilotCompleted={vessel.pilotages?.inPilotCompleted}
      >
        <Popover
          trigger="hover"
          placement="top"
          content={VesselToolTip}
          color="white"
          overlayInnerStyle={{ padding: '0' }}
          open={tooltipOpen}
          zIndex={1000}
          align={{ offset: [getXAlign(), 0] }}
          overlayClassName="popover-no-padding"
        >
          <VesselInnerContainer
            outPilotEstimated={vessel.pilotages?.outPilotEstimated}
            outPilotOrdered={vessel.pilotages?.outPilotOrdered}
            outPilotCommenced={vessel.pilotages?.outPilotCommenced}
            outPilotCompleted={vessel.pilotages?.outPilotCompleted}
            inPilotEstimated={vessel.pilotages?.inPilotEstimated}
            inPilotOrdered={vessel.pilotages?.inPilotOrdered}
            inPilotCommenced={vessel.pilotages?.inPilotCommenced}
            inPilotCompleted={vessel.pilotages?.inPilotCompleted}
          >
            {startPart > 0 && (
              <StartStripes
                style={{ width: (startPart > width - 2 ? startPart - 2 : startPart) + 'px' }}
                outPilotEstimated={vessel.pilotages?.outPilotEstimated}
                outPilotOrdered={vessel.pilotages?.outPilotOrdered}
                outPilotCommenced={vessel.pilotages?.outPilotCommenced}
                outPilotCompleted={vessel.pilotages?.outPilotCompleted}
                inPilotEstimated={vessel.pilotages?.inPilotEstimated}
                inPilotOrdered={vessel.pilotages?.inPilotOrdered}
                inPilotCommenced={vessel.pilotages?.inPilotCommenced}
                inPilotCompleted={vessel.pilotages?.inPilotCompleted}
              />
            )}
            {endPart > 0 && (
              <EndStripes
                style={{ width: (endPart > width - 2 ? endPart - 2 : endPart) + 'px' }}
                outPilotEstimated={vessel.pilotages?.outPilotEstimated}
                outPilotOrdered={vessel.pilotages?.outPilotOrdered}
                outPilotCommenced={vessel.pilotages?.outPilotCommenced}
                outPilotCompleted={vessel.pilotages?.outPilotCompleted}
                inPilotEstimated={vessel.pilotages?.inPilotEstimated}
                inPilotOrdered={vessel.pilotages?.inPilotOrdered}
                inPilotCommenced={vessel.pilotages?.inPilotCommenced}
                inPilotCompleted={vessel.pilotages?.inPilotCompleted}
              />
            )}
            <VesselNameContainer>{vessel.data.vesselName}</VesselNameContainer>
            {serviceOrders && (
              <FleetTimelineServiceOrders
                serviceOrders={serviceOrders}
                setTooltipOpen={setTooltipOpen}
                vesselStarttime={starttime}
                vesselEndtime={endtime}
                millisecondInPixels={millisecondInPixels}
              />
            )}
          </VesselInnerContainer>
        </Popover>
      </VesselContainer>
    </>
  );
};

export default Vessel;
