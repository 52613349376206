import React, { useContext } from 'react';
import Modal from 'antd/es/modal';
import styled from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Filter from './Filter';
import ButtonLight from '../ui/ButtonLight';

import { UserContext } from '../../context/UserContext';
import { FilteringContext } from '../../context/FilteringContext';

const FiltersBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${props => (props.mobile ? '16px' : '24px')};
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const MobileIconContainer = styled.div`
  margin-right: 1px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ButtonVersion = styled(ButtonLight)`
  border-color: ${({ theme }) => theme.color.secondary};
  font-size: 14px;
  min-width: 88px;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  background-color: ${props => props.disabled && '#d3d2d8'};
  color: ${props => props.disabled && '#747D7D'};
`;

const MobileButton = styled(ButtonLight)`
  min-width: 32px;
  width: 32px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  border: 1px solid #d0d0d0;
  background-color: white;
  color: #4a4a4a;
  background-color: ${props => props.disabled && '#d3d2d8 !important'};
  color: ${props => props.disabled && '#747D7D !important'};

  &:hover {
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
    background-color: white;
  }
`;

const FilterContainer = ({ mobile }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const location = useLocation();

  const {
    filteringStorageOptions,
    filterPopoverOpen,
    setFilterPopoverOpen,
    setMobileFilterPopoverOpen,
    mobileFilterPopoverOpen,
  } = useContext(FilteringContext);

  const handleVisibleChange = visible => {
    setFilterPopoverOpen(visible);
  };

  const handleMobileVisibleChange = visible => {
    setMobileFilterPopoverOpen(visible);
  };

  const closePopover = () => {
    setFilterPopoverOpen(false);
  };

  const closeMobilePopover = () => {
    setMobileFilterPopoverOpen(false);
  };

  const togglePopover = () => {
    setFilterPopoverOpen(!filterPopoverOpen);
  };

  const toggleMobilePopover = () => {
    setMobileFilterPopoverOpen(!mobileFilterPopoverOpen);
  };

  if (!filteringStorageOptions) {
    return null;
  }

  const filtersAllowed =
    location.pathname === '/' + namespace + '/' ||
    location.pathname === '/' + namespace ||
    location.pathname === '/' + namespace + '/activity-list' ||
    location.pathname === '/' + namespace + '/activity-cards' ||
    location.pathname === '/' + namespace + '/activity-list-v2' ||
    location.pathname === '/' + namespace + '/berth-planning-tool' ||
    location.pathname === '/' + namespace + '/vessel-calendar' ||
    (location.pathname === '/' + namespace + '/map' && !location.search.includes('type=fleet'));

  return (
    <FiltersBtnContainer mobile={mobile}>
      {!mobile ? (
        <ButtonVersion onClick={togglePopover} disabled={!filtersAllowed}>
          <IconContainer>
            <FilterOutlined />
          </IconContainer>
          {t('Filters')}
        </ButtonVersion>
      ) : (
        <MobileButton onClick={toggleMobilePopover} disabled={!filtersAllowed}>
          <MobileIconContainer>
            <FilterOutlined />
          </MobileIconContainer>
        </MobileButton>
      )}

      {mobileFilterPopoverOpen && (
        <Modal
          open={mobileFilterPopoverOpen}
          closable={false}
          destroyOnClose={true}
          footer={null}
          style={{ position: 'absolute', top: '98px', right: '10px' }}
          styles={{ mask: { backgroundColor: 'transparent' } }}
          onCancel={closeMobilePopover}
          width={300}
        >
          <Filter togglePopover={handleMobileVisibleChange} />
        </Modal>
      )}
      {filterPopoverOpen && (
        <Modal
          open={filterPopoverOpen}
          closable={false}
          destroyOnClose={true}
          footer={null}
          style={{ position: 'absolute', top: '50px', right: '125px' }}
          styles={{ mask: { backgroundColor: 'transparent' } }}
          onCancel={closePopover}
          width={300}
        >
          <Filter togglePopover={handleVisibleChange} />
        </Modal>
      )}
    </FiltersBtnContainer>
  );
};

export default FilterContainer;
