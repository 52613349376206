import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from '../../../ui/Icon';
import { UserContext } from '../../../../context/UserContext';
import Empty from 'antd/es/empty';
import Input from 'antd/es/input';

import dayjs from 'dayjs';
import ProfileCircle from '../../../ui/ProfileCircle';
import ButtonLight from '../../../ui/ButtonLight';
import Form from '../../../ui/Form';

const Modal = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  width: 540px;
  height: calc(100% - 55px);
  background-color: white;
  border-left: 2px solid #f0f0f0;
  color: ${({ theme }) => theme.color.grey_dark};
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const IconContainer = styled.div`
  padding: 0 6px 2px 3px;
  cursor: pointer;
  margin-right: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 24px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: black;
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f0f0f0;
  flex-grow: 1;
  height: calc(100% - 255px);
`;
const Container = styled.div`
  padding: 0 24px;
  overflow-y: auto;
  flex-direction: column-reverse;
  display: flex;
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 24px;
`;

const CommentRow = styled.div`
  width: ${props => (props.child ? '95%' : '100%')};
  align-self: flex-start;
  margin-left: ${props => (props.child ? '5%' : '0')};
  display: inline-block;
  margin-top: ${props => (props.child ? '15px' : '24px')};
`;

const CommentTime = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.tiny};
  width: 100%;
`;

const SenderRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const Rows = styled.div`
  margin-left: 12px;
  width: calc(100% - 42px);
`;

const Sender = styled.div`
  color: ${({ theme }) => theme.color.grey_dark};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const SenderSmall = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 14px;
`;

const CommentText = styled.div`
  font-size: 13px;
  border-radius: ${props => (props.decision ? '4px 4px 0 0' : '4px')};
  padding: 10px;
  font-weight: ${props => (props.newItem ? 700 : 400)};
  background-color: ${({ theme }) => theme.color.beige};
  position: relative;

  &:hover {
    .more-button {
      display: flex;
    }
  }
`;

const Buttons = styled.div`
  padding-top: 8px;
  position: relative;
  border-top: 1px solid #f0f0f0;
`;

const { TextArea } = Input;

const CommentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
`;

const StyledButton = styled(ButtonLight)`
  height: 22px;
  line-height: 2px;
  padding: 0 4px !important;
  min-width: 76px !important;
  margin-top: 6px;
`;

const Unclicked = styled.div`
  background-color: #f7f7f7;
  height: 30px;
  width: calc(100% - 36px);
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.small};
  line-height: 30px;
  padding-left: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

const ActionButtons = styled.div`
  text-align: right;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const CommentForm = styled(Form)`
  padding: 6px 0 0 0;
  select,
  input,
  textarea,
  span {
    width: 100%;
  }
  label {
    text-align: center;
  }
`;

const SubmitCommentContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  display: flex;
  padding-bottom: 0.5px;
  width: 100%;

  div {
    margin-bottom: 0;
  }
  textarea {
    min-height: 50px;
    resize: none;
    margin-left: 6px;
  }
`;

const UnclickedContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  display: flex;
  padding-bottom: 0.5px;
`;

const FinnpilotComments = ({ close, data, loading, orderLine, sendData, isTaskList }) => {
  const { namespace } = useContext(UserContext);

  const { t } = useTranslation(namespace);

  function reverse(array) {
    return array.map((item, idx) => array[array.length - 1 - idx]);
  }

  let comments = !data?.comments ? [] : data.comments;

  const createDataToBeSent = text => {
    if (!text?.trim()) {
      return;
    }
    sendData(
      {
        order_line_id: orderLine.id,
        external_type_action: 'comment',
        external_data: {
          text: text,
        },
      },
      data,
      'comment',
      true
    );
  };

  const offset = isTaskList ? 0 : 55;

  return (
    <Modal id="finnpilot-comments" style={{ top: `${offset}px`, height: `calc(100% - ${offset}px)` }}>
      <HeaderRow>
        <Header>{t('Comments')}</Header>
        <IconContainer onClick={close}>
          <Icon type="close" />
        </IconContainer>
      </HeaderRow>
      <CommentsContainer>
        <Container>
          <Comments>
            {comments.length > 0 ? (
              reverse(comments).map(comment => <Comment key={comment.id} child={false} data={comment} />)
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No comments.')} />
            )}
          </Comments>
        </Container>
      </CommentsContainer>
      {orderLine.canEdit ? (
        <Buttons>
          <SubmitComment handleSubmit={createDataToBeSent} loading={loading}></SubmitComment>
        </Buttons>
      ) : null}
    </Modal>
  );
};

const Comment = ({ data, child }) => {
  const [first_name, last_name] = data.authorName ? data.authorName.split(' ') : ['', ''];
  const sender = data.authorName
    ? {
        email: data.authorName,
        first_name: first_name || '',
        last_name: last_name || '',
      }
    : null;
  return (
    <CommentRow child={child}>
      <CommentTime>
        <SenderRow>
          <ProfileCircle small={child} givenUser={sender} />
          <Rows>
            <Sender>
              <div>
                <span style={{ fontWeight: 600 }}>{data.authorName}</span>{' '}
              </div>
            </Sender>
            <SenderSmall>
              <div>{data.authorType}</div>
              <div>
                {dayjs(data.timestamp).format('DD.MM.YYYY')}
                <span style={{ fontWeight: 600, marginLeft: '3px' }}>{dayjs(data.timestamp).format('HH:mm')}</span>
              </div>
            </SenderSmall>
          </Rows>
        </SenderRow>
      </CommentTime>
      <CommentText>{data.text}</CommentText>
    </CommentRow>
  );
};

const SubmitComment = ({ handleSubmit, loading }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [replyComment, setReplyComment] = useState(undefined);

  const openRef = useRef();

  useEffect(() => {
    if (!openRef.current && open && labelRef.current) {
      labelRef.current.focus();
    }

    openRef.current = open;
  }, [open]);

  let labelRef = useRef();

  const handleCommentChange = e => {
    setReplyComment(e.target.value);
  };

  const handleCancel = () => {
    setReplyComment(undefined);
    setOpen(false);
  };

  const handleReplySend = e => {
    e.preventDefault();
    setOpen(false);
    handleSubmit(replyComment);
    setReplyComment(undefined);
  };

  return (
    <CommentContainer>
      <CommentForm onSubmit={handleReplySend}>
        {open ? (
          <div>
            <SubmitCommentContainer>
              <ProfileCircle small />
              <TextArea
                style={{
                  width: 'calc(100% - 36px)',
                }}
                placeholder={t('Write a comment...')}
                value={replyComment}
                onChange={handleCommentChange}
                ref={input => {
                  labelRef.current = input;
                }}
              />
            </SubmitCommentContainer>
            <ActionButtons>
              <StyledButton cancel onClick={handleCancel}>
                {t('Cancel')}
              </StyledButton>
              <StyledButton disabled={!replyComment?.trim() || loading}>{t('Send')}</StyledButton>
            </ActionButtons>
          </div>
        ) : (
          <UnclickedContainer>
            <ProfileCircle small />
            <Unclicked onClick={() => setOpen(true)}>{t('Write a comment...')}</Unclicked>
          </UnclickedContainer>
        )}
      </CommentForm>
    </CommentContainer>
  );
};

export default FinnpilotComments;
