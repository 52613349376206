import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  overflow-x: auto;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / ${props => props.amount});
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
  min-width: 950px;
`;

const CustomerModal = ({ customer, closeModal, readOnly, closeAndReload }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { accountTypes, invoiceDeliveryMethods, isoCountries, defaultCountry } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  const type = customer ? 'edit' : 'new';

  // eslint-disable-next-line no-unused-vars
  const [inputData, setInputData] = useState({
    account_type: customer?.account_type,
    address: customer?.address,
    business_id: customer?.business_id,
    city: customer?.city,
    contact_person: customer?.contact_person,
    country_code: customer?.country_code || defaultCountry,
    customer_number: customer?.customer_number,
    einvoice_address: customer?.einvoice_address,
    einvoice_operator_id: customer?.einvoice_operator_id,
    email: customer?.email,
    name: customer?.name,
    phone: customer?.phone,
    postalcode: customer?.postalcode,
    sales_invoice_delivery_method: customer?.sales_invoice_delivery_method,
    vat_number: customer?.vat_number,
  });

  const [sending, setSending] = useState(false);

  const title = readOnly ? t('Customer') : !customer ? t('Create new customer') : t('Edit customer');

  const dataIsValid = () => {
    if (!inputData.name || !inputData.address || !inputData.postalcode || !inputData.city || !inputData.country_code) {
      return false;
    }
    return true;
  };

  const updateField = (e, name, noTarget = false) => {
    let newInputData = { ...inputData };
    if (noTarget) {
      newInputData[name] = e;
    } else {
      newInputData[name] = e.target.value;
    }
    setInputData(newInputData);
  };

  const sendData = async () => {
    setSending(true);
    let result;
    try {
      result = await apiCall(inputData.customer_number ? 'put' : 'post', 'invoicing/v2/external-customer', inputData);
    } catch (e) {
      setSending(false);
    }

    if (inputData.customer_number ? result?.data?.success : result?.data?.customer) {
      closeAndReload();
    }
    setSending(false);
  };

  return (
    <Modal title={title} open={true} width={1000} onCancel={closeModal} footer={null} maskClosable={false}>
      <ModalInnerInput>
        <ScrollContainer>
          <Row>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={customer.name} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Name')}
                  value={inputData.name || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'name')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Country')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Select
                  placeholder={t('Country')}
                  value={customer.country_code}
                  disabled={true}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  options={isoCountries}
                />
              ) : (
                <Select
                  placeholder={t('Country')}
                  value={inputData.country_code}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  onChange={e => updateField(e, 'country_code', true)}
                  options={isoCountries}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Address')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Address')}
                  value={customer.address}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Address')}
                  value={inputData.address || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'address')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Postal code')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Postal code')}
                  value={customer.postalcode}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Postal code')}
                  value={inputData.postalcode || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'postalcode')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>
                {t('City')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('City')} value={customer.city} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('City')}
                  value={inputData.city || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'city')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}></RowContainer>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <RowContainer amount={3}>
              <RowHeader>{t('Contact person')}</RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Contact person')}
                  value={customer.contact_person}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Contact person')}
                  value={inputData.contact_person || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'contact_person')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('Email')}</RowHeader>
              {readOnly ? (
                <Input placeholder={t('Email')} value={customer.email} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Email')}
                  value={inputData.email || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'email')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('Phone')}</RowHeader>
              {readOnly ? (
                <Input placeholder={t('Phone')} value={customer.phone} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Phone')}
                  value={inputData.phone || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'phone')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={3}>
              <RowHeader>{t('Business ID')}</RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Business ID')}
                  value={customer.business_id}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Business ID')}
                  value={inputData.business_id || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'business_id')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('Account type')}</RowHeader>
              {readOnly ? (
                <Select
                  placeholder={t('Account type')}
                  value={customer.account_type}
                  disabled={true}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  options={accountTypes}
                />
              ) : (
                <Select
                  placeholder={t('Account type')}
                  value={inputData.account_type}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  onChange={e => updateField(e, 'account_type', true)}
                  options={accountTypes}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('VAT number')}</RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('VAT number')}
                  value={customer.vat_number}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('VAT number')}
                  value={inputData.vat_number || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'vat_number')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={3}>
              <RowHeader>{t('Sales invoice delivery method')}</RowHeader>
              {readOnly ? (
                <Select
                  placeholder={t('Sales invoice delivery method')}
                  value={customer.sales_invoice_delivery_method}
                  disabled={true}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  options={invoiceDeliveryMethods}
                />
              ) : (
                <Select
                  placeholder={t('Sales invoice delivery method')}
                  value={inputData.sales_invoice_delivery_method}
                  style={{ color: '#4a4a4a', width: '100%' }}
                  onChange={e => updateField(e, 'sales_invoice_delivery_method', true)}
                  options={invoiceDeliveryMethods}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('E-invoice operator ID')}</RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('E-invoice operator ID')}
                  value={customer.einvoice_operator_id}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('E-invoice operator ID')}
                  value={inputData.einvoice_operator_id || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'einvoice_operator_id')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>{t('E-invoice address')}</RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('E-invoice address')}
                  value={customer.einvoice_address}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('E-invoice address')}
                  value={inputData.einvoice_address || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'einvoice_address')}
                />
              )}
            </RowContainer>
          </Row>
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {type === 'new' ? t('Add') : t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default CustomerModal;
