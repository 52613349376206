import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import SmallPageSearch from '../page/SmallPageSearch';
import useApi from '../../hooks/useApi';
import TemplateChoiceModalTemplate from './TemplateChoiceModalTemplate';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import Icon from '../ui/Icon';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: auto;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;

  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const SpinContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const ErrorContainer = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e0e0e0;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
`;

const Header = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 16px;
`;

const TemplateList = styled.div`
  display: inline;
`;

const NoTemplates = styled.div`
  width: 100%;
  text-align: center;
  padding: 56px 0px;
`;

const TemplateContainer = styled.div`
  width: 540px;
  margin: auto;
  min-height: 300px;
  padding-top: 18px;
`;

const VesselInvoice = ({ id, closeModal, setInvoiceData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { customers } = useContext(NewInvoicingContext);

  const [showError, setShowError] = useState(false);
  const [portcallData, setPortcallData] = useState(null);

  const [search, setSearch] = useState('');

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const title = t('New vessel invoice');

  const params = {
    limit: 100,
    sort: 'name',
    select: 'only_non_automated',
  };

  const { loading, data, error } = useApi('get', 'invoicing/v2/templates', params);

  let templates = error || !data ? [] : data.results ? data.results.templates : [];

  useEffect(() => {
    const getInvoiceData = async () => {
      let result;
      try {
        result = await apiCall('post', 'invoicing/v2/prefetch-port-calls', {
          port_call_ids: [id],
        });
      } catch (e) {
        setShowError(true);
      }

      if (result?.status === 200 && result.data?.port_call_datas?.length) {
        setPortcallData(result.data.port_call_datas[0]);
      } else {
        setShowError(true);
      }
    };

    if (id) {
      getInvoiceData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const chooseTemplate = template => {
    let dataToBeSent = {
      ...template,
      description: portcallData.invoice_description,
      from_template: true,
      invoice_rows: template.template_rows,
      port_call_data: portcallData,
    };

    delete dataToBeSent.template_rows;

    if (portcallData.port_call_agent_customer_number) {
      dataToBeSent.customer_number = portcallData.port_call_agent_customer_number;
      dataToBeSent.customer = customers.find(c => c.value === portcallData.port_call_agent_customer_number)?.data;
    }

    let findCargoIndex = template.template_rows.findIndex(r => r.product_code === 'POLO_AUTOMATIC_CARGO');

    if (findCargoIndex > -1 && portcallData.cargo_invoice_rows && Object.keys(portcallData.cargo_invoice_rows).length) {
      let cargoRows = [];
      Object.values(portcallData.cargo_invoice_rows).forEach(list => {
        list.forEach(v => {
          cargoRows.push(v);
        });
      });

      dataToBeSent.invoice_rows = [...dataToBeSent.invoice_rows, ...cargoRows];
    }

    if (findCargoIndex > -1) {
      dataToBeSent.invoice_rows.splice(findCargoIndex, 1);
    }

    setInvoiceData(dataToBeSent);
  };

  const createEmpty = () => {
    let dataToBeSent = {
      description: portcallData.invoice_description,
      from_template: true,
      port_call_data: portcallData,
    };

    if (portcallData.port_call_agent_customer_number) {
      dataToBeSent.customer_number = portcallData.port_call_agent_customer_number;
      dataToBeSent.customer = customers.find(c => c.value === portcallData.port_call_agent_customer_number)?.data;
    }

    setInvoiceData(dataToBeSent);
  };

  const templateList = templates.filter(temp => {
    return (
      temp.customer?.name?.toUpperCase().includes(search.toUpperCase()) ||
      temp.customer?.customer_number?.toUpperCase().includes(search.toUpperCase()) ||
      temp.tax_class_name?.toUpperCase().includes(search.toUpperCase()) ||
      temp.name?.toUpperCase().includes(search.toUpperCase())
    );
  });

  return (
    <Modal title={title} open={true} width={600} onCancel={() => closeModal(false)} footer={null} maskClosable={false}>
      <ModalInnerInput>
        {!portcallData ? (
          !showError ? (
            <SpinContainer>
              <Spin size="medium" />
            </SpinContainer>
          ) : (
            <ErrorContainer>{t('Vessel data fetch failed.')}</ErrorContainer>
          )
        ) : (
          <TemplateContainer>
            <HeaderContainer>
              <Header>{t('Templates')}</Header>
              <SmallPageSearch
                value={search}
                placeholder={t('Search')}
                onChange={handleSearchChange}
                style={{ minWidth: '222px' }}
              />
            </HeaderContainer>
            <ScrollContainer>
              {!loading ? (
                templateList?.length ? (
                  <TemplateList>
                    {templateList.map(template => (
                      <TemplateChoiceModalTemplate
                        key={template.id}
                        chooseTemplate={chooseTemplate}
                        template={template}
                        allowCargo={true}
                      />
                    ))}
                  </TemplateList>
                ) : (
                  <NoTemplates>{t('No available templates.')}</NoTemplates>
                )
              ) : (
                <SpinContainer>
                  <Spin />
                </SpinContainer>
              )}
            </ScrollContainer>
          </TemplateContainer>
        )}
        <MiddleLine />
        <ActionButtons style={{ justifyContent: 'space-between' }}>
          <ButtonLight onClick={() => createEmpty()}>
            <Icon type={'plus'} /> {t('Create new empty invoice')}
          </ButtonLight>
          <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default VesselInvoice;
