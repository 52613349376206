import React from 'react';
import WeatherIcon from '../ui/WeatherIcon';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import WeatherText from '../ui/WeatherText';

const IconContainer = styled.div`
  svg {
    width: 28px;
  }
`;

const TempStatus = styled.div`
  margin: 3px 16px;
  font-size: 14px;
  width: 200px;
  a {
    color: #000000;
  }
`;

const WeatherSymbol = styled.div`
  display: flex;
  cursor: pointer;
`;

const CardWeather = () => {
  return (
    <WeatherSymbol>
      <IconContainer>
        <WeatherIcon type="partly_cloudy" width="30" />
      </IconContainer>
      <TempStatus>
        <Link to="/weather-map-view">
          <WeatherText type="partly_cloudy" /> 10 °C
        </Link>
      </TempStatus>
    </WeatherSymbol>
  );
};

export default CardWeather;
