import React, { useContext, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popover from 'antd/es/popover';
import Input from 'antd/es/input';

import { UserContext } from '../context/UserContext';
import { lighten } from 'polished';

import DynamicLogo from './ui/DynamicLogo';
import Icon from './ui/Icon';
import { mobilePixelMaxWidthLimit } from '../utils/constants';

const PortSelect = styled.span`
  margin-bottom: ${({ theme }) => theme.sizing.gap};
  margin-left: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.secondary};
`;

const MenuItemSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  vertical-align: middle;
  padding-right: ${({ theme }) => theme.sizing.gap};
  svg {
    width: 32px;
    height: 32px;
    margin-right: ${({ theme }) => theme.sizing.gap};
  }
  ${({ active, theme }) =>
    active &&
    `
    border-bottom: 1px solid ${theme.color.grey_light};
  `}
`;

const PortText = styled.p`
  color: inherit;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  color: ${({ theme }) => theme.color.black};
  font-weight: 900;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  text-transform: uppercase;
  ${({ locked, theme }) =>
    locked &&
    `
  color: ${theme.color.grey};
  `}
`;

const DropdownSpan = styled.span`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => lighten(0.05, theme.color.secondary)};
  }
  i {
    svg {
      width: 22px;
      height: 22px;
      fill: currentColor;
    }
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    i {
      svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
    }
  }
`;

const UserTextWrapper = styled.span`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  line-height: 1.25;
`;

const UserEmailText = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const LockedText = styled.div`
  margin-left: 45px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};
  i {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const Menu = styled.div`
  background-color: white;
  border-radius: 4px;
`;

const MenuItem = styled.div`
  padding: 5px 12px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ItemContainer = styled.div`
  margin: 2px 0;
`;

const PortSearch = styled.div`
  margin: 5px 12px 10px 12px;
  display: flex;
  justify-content: center;

  .ant-input-affix-wrapper {
    padding: 0px;
  }

  .ant-input-clear-icon {
    margin-right: 4px !important;
  }
`;

const IconContainer = styled.div`
  margin-right: 6px;
  padding-left: 4px;
  margin-top: -1px;

  svg {
    width: 17px;
    height: 17px;
  }
`;

const HeaderPortSelect = ({ user, match }) => {
  const { namespace, namespaces, setNamespace } = useContext(UserContext);

  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const history = useHistory();

  let portList = namespaces || [];
  if (portList.length > 0) {
    portList.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    const currentPortIndex = portList.findIndex(port => port.key === namespace);
    portList.unshift(portList.splice(currentPortIndex, 1)[0]);
  }

  if (search && search.length > 0) {
    portList = portList.filter((port, index) => {
      if (index === 0) {
        return true;
      } else {
        if (port.key === 'vts' || port.key === 'neste') {
          const lowercaseKey = port.key.toLowerCase();
          return lowercaseKey.includes(search.toLowerCase());
        } else {
          const lowercaseKey = t('Port of {{portname}}', { portname: port.label }).toLowerCase();
          return lowercaseKey.includes(search.toLowerCase());
        }
      }
    });
  }

  const handleClick = (e, ns, disabled) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    if (match.path.includes(':urlnamespace')) {
      const index = match.url.substring(1, match.url.length).indexOf('/');
      const restOfPath = match.url.substring(index + 1, match.url.length);
      let newPath = '/' + ns + restOfPath;

      if (window?.location?.search?.length) {
        newPath = newPath + window.location.search;
      }

      history.push(newPath);
      window.location.reload(false);
    } else {
      setNamespace(ns).then(window.location.reload(false));
    }
  };

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const handleOpenChange = flag => {
    setOpen(flag);
    setSearch('');
  };

  const menu = (
    <Menu style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
      {portList.map(port => {
        const isActivePort = port.key === namespace;
        const locked = port.locked;
        return (
          <ItemContainer key={port.namespace}>
            <MenuItem disabled={locked} onClick={e => handleClick(e, port.namespace, locked)}>
              <MenuItemSpan active={isActivePort}>
                <DynamicLogo src={port.icon} />
                <div>
                  {port.key === 'vts' || port.key === 'neste' ? (
                    <PortText locked={locked}>{port.label}</PortText>
                  ) : (
                    <PortText locked={locked}>{t('Port of {{portname}}', { portname: port.label })}</PortText>
                  )}
                  {isActivePort && (
                    <UserTextWrapper>
                      <div>{`${user.first_name} ${user.last_name} (${user.role})`}</div>
                      <UserEmailText>{user.email}</UserEmailText>
                    </UserTextWrapper>
                  )}
                </div>
              </MenuItemSpan>
              {locked && (
                <LockedText>
                  <Icon type="lock" />
                  {t('LOCKED')}
                </LockedText>
              )}
            </MenuItem>
            {isActivePort && namespaces && namespaces.length > 3 && (
              <PortSearch>
                <Input
                  size="small"
                  value={search}
                  placeholder={t('Search')}
                  onChange={handleSearchChange}
                  prefix={
                    <IconContainer>
                      <Icon type="search" />
                    </IconContainer>
                  }
                  allowClear={true}
                />
              </PortSearch>
            )}
          </ItemContainer>
        );
      })}
    </Menu>
  );

  return (
    <PortSelect>
      <Popover trigger="click" content={menu} placement="bottom" open={open} onOpenChange={handleOpenChange}>
        <DropdownSpan>
          <Icon type="swap" />
        </DropdownSpan>
      </Popover>
    </PortSelect>
  );
};

export default withRouter(HeaderPortSelect);
