import React, { useContext, useRef, useState, useEffect } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useToggle from '../../hooks/useToggle';
import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Tabs from 'antd/es/tabs';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';
import DatePicker from 'antd/es/date-picker';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import PageActionForm from '../../components/page/PageActionForm';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Label from '../../components/ui/Label';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const StyledPage = styled(Page)`
  .ant-tabs-tab {
    font-size: 16px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }
`;

const ReservationCalendar = styled.div`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AdminBerthReservations = () => {
  const { apiCall, namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);
  const [apiCallPending, setApiCallPending] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentBerthCode, setCurrentBerthCode] = useState(null);
  const [currentBerthName, setCurrentBerthName] = useState('Unknown');

  const history = useHistory();
  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Berth reservations | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    berth_code: params.get('berth_code') ? params.get('berth_code') : 0,
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'reservation_start',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'berth-reservations', newParams);
  const { loading: berthsLoading, data: berthsData, error: berthsError } = useApi('get', 'all-nominatable-berths', {});

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const reservations = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  reservations.forEach(p => {
    p._row = start + counter;
    counter++;
  });

  const fetchedNominatableBerths = berthsError || !berthsData ? [] : berthsData;
  let nominatableBerths = [...fetchedNominatableBerths];
  counter = 1;
  nominatableBerths.forEach(p => {
    p._row = counter;
    counter++;
    p.key = p.id;
    p.label = p.name;
    p.value = p.code;
  });

  if (!berthsLoading && currentBerthCode === 0 && fetchedNominatableBerths.length > 0) {
    let berthCode = fetchedNominatableBerths[0].code;
    let berthName = fetchedNominatableBerths[0].name;
    setCurrentBerthCode(berthCode);
    setCurrentBerthName(berthName);
    let params = newParams;
    params.berth_code = berthCode;
    history.push(location.pathname + '?berth_code=' + params.berth_code);
    fetchData(false, params);
  }

  const handleTabChange = e => {
    setCurrentBerthCode(e);
    let berth = fetchedNominatableBerths.find(berth => berth.code === e);
    setCurrentBerthName(berth.name);
    let params = newParams;
    params.berth_code = e;
    history.push(location.pathname + '?berth_code=' + params.berth_code);
    fetchData(false, params);
  };

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const initModal = {
    visible: false,
    confirmLoading: false,
    datesDirty: false,
    berthsDirty: false,
  };

  const [modal, setModal] = useState(initModal);

  const initReservation = {
    id: null,
    reservation_start: '',
    reservation_end: '',
    readable_berth_reservation_type: '',
    vessel_name: '',
  };

  const [reservation, setReservation] = useState(initReservation);

  const showModal = async id => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', 'berth-reservation-by-id', { id });
      let reservation_start = dayjs.utc(data['reservation_start']).format('YYYY-MM-DDTHH:mm:ss+00:00');
      let reservation_end = dayjs.utc(data['reservation_end']).format('YYYY-MM-DDTHH:mm:ss+00:00');
      setStartDate(dayjs(data['reservation_start']).toDate());
      setEndDate(dayjs(data['reservation_end']).toDate());
      setReservation({ ...data, reservation_start, reservation_end });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      await apiCall('put', 'berth-reservations', {
        id: reservation.id,
        reservation_start: reservation.reservation_start,
        reservation_end: reservation.reservation_end,
      });
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    resetDates();
    let params = newParams;
    await fetchData(false, params);
  };

  const handleModalCancel = () => {
    setModal(initModal);
    resetDates();
  };

  const handleSave = async values => {
    const { reservation_start, reservation_end } = values;
    setApiCallPending(true);
    try {
      await apiCall('post', 'berth-reservations', {
        berth_code: currentBerthCode,
        berth_reservation_type: 'port_blocked',
        reservation_start,
        reservation_end,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    showActions(false);
    resetDates();

    let params = newParams;
    await fetchData(false, params);
  };

  const fields = ['reservation_start', 'reservation_end'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, {
    reservation_start: dayjs.utc(dayjs().set({ second: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00'),
    reservation_end: dayjs.utc(dayjs().set({ second: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00'),
  });

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    resetDates();
  };

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', 'berth-reservations', { id });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const resetDates = () => {
    setStartDate(dayjs().toDate());
    setEndDate(dayjs().toDate());
    values.reservation_start = dayjs.utc(dayjs().set({ second: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00');
    values.reservation_end = dayjs.utc(dayjs().set({ second: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00');
  };

  const handleReservationStartChange = e => {
    setStartDate(e);
    let startMoment = dayjs(e);
    startMoment.set({ second: 0 });
    if (modal.visible) {
      modal.datesDirty = true;
      reservation.reservation_start = dayjs.utc(startMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    } else {
      values.reservation_start = dayjs.utc(startMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const handleReservationEndChange = e => {
    setEndDate(e);
    let endMoment = dayjs(e);
    endMoment.set({ second: 0 });
    if (modal.visible) {
      modal.datesDirty = true;
      reservation.reservation_end = dayjs.utc(endMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    } else {
      values.reservation_end = dayjs.utc(endMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Start'),
      dataIndex: 'reservation_start',
      key: 'reservation_start',
      sortableKey: 'reservation_start',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('End'),
      dataIndex: 'reservation_end',
      key: 'reservation_end',
      sortableKey: 'reservation_end',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Reservation type'),
      dataIndex: 'readable_berth_reservation_type',
      key: 'readable_berth_reservation_type',
      sortableKey: 'readable_berth_reservation_type',
    },
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={
            !user.permissions.includes('manage_berth_reservation') ||
            record.readable_berth_reservation_type !== 'Blocked by port'
          }
          style={{ marginRight: '16px' }}
          key="action-1"
          onClick={() => showModal(record.id)}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete berth reservation {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              red
              disabled={
                !user.permissions.includes('manage_berth_reservation') ||
                record.readable_berth_reservation_type !== 'Blocked by port'
              }
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: loading || berthsLoading || !user.permissions.includes('manage_berth_reservation'),
      text: t('Add new berth block'),
      icon: 'plus',
    },
  ];

  const tabItems = [];
  nominatableBerths.forEach(nominatableBerth => {
    tabItems.push({
      label: nominatableBerth.name,
      key: nominatableBerth.code,
    });
  });

  return (
    <Layout pagename={t('Berth Reservations')}>
      <Modal
        title={t('Edit reservation {{id}}', { id: reservation.id })}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <RowHeader>{currentBerthName}</RowHeader>
            <ReservationCalendar>
              <Label>{t('Reservation start')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 30 }}
                style={{
                  width: '100%',
                }}
                value={startDate ? dayjs(startDate) : null}
                onChange={handleReservationStartChange}
                placeholder={t('Start time')}
              />
            </ReservationCalendar>
            <ReservationCalendar>
              <RowHeader>{t('Reservation end')}</RowHeader>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 30 }}
                style={{
                  width: '100%',
                }}
                value={endDate ? dayjs(endDate) : null}
                onChange={handleReservationEndChange}
                placeholder={t('End time')}
              />
            </ReservationCalendar>
            <Label>{reservation.readable_berth_reservation_type}</Label>
            <Label>{reservation.vessel_name}</Label>
          </Spin>
        </ModalInner>
      </Modal>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <StyledPage fullWidth>
        <Tabs defaultActiveKey="0" animated={false} size="large" onChange={handleTabChange} items={tabItems} />
        <div ref={actionsRef}>
          <PageActionForm
            title={t('Add new berth block')}
            icon="plus"
            show={actions}
            style={{ top: '80px', right: '10px' }}
          >
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Label>{currentBerthName}</Label>
              {fields.map((field, index) => {
                if (field === 'reservation_start') {
                  return (
                    <ReservationCalendar key={index}>
                      <RowHeader>{t('Block start')}</RowHeader>
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 30 }}
                        style={{
                          width: '100%',
                        }}
                        value={startDate ? dayjs(startDate) : null}
                        onChange={handleReservationStartChange}
                        placeholder={t('Start time')}
                      />
                    </ReservationCalendar>
                  );
                } else if (field === 'reservation_end') {
                  return (
                    <ReservationCalendar key={index}>
                      <RowHeader>{t('Block end')}</RowHeader>
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 30 }}
                        style={{
                          width: '100%',
                        }}
                        value={endDate ? dayjs(endDate) : null}
                        onChange={handleReservationEndChange}
                        placeholder={t('End time')}
                      />
                    </ReservationCalendar>
                  );
                } else {
                  return (
                    <Input
                      label={field.replace(/_/g, ' ')}
                      key={field}
                      name={field}
                      field={field}
                      value={values.field}
                      onChange={handleChange}
                    />
                  );
                }
              })}
              <FormActions>
                <Button link>{t('Add berth block')}</Button>
                <Button link onClick={e => handleCancel(e)}>
                  {t('Cancel')}
                </Button>
              </FormActions>
            </Form>
          </PageActionForm>
        </div>

        <List
          rowKey="id"
          columns={columns}
          dataSource={reservations}
          apiCallPending={apiCallPending}
          actions={actionList}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search by vessel name')}
          additionalButtons={additionalButtons}
        />
      </StyledPage>
    </Layout>
  );
};

export default AdminBerthReservations;
