import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { UserContext } from '../../../context/UserContext';
import { TimestampContext } from '../../../context/TimestampContext';
import useApi from '../../../hooks/useApi';

import VesselTimestampFleetModalCard from './VesselTimestampFleetModalCard';
import TimestampGroup from '../../activity/TimestampGroup'; // eslint-disable-line
import Timestamp from '../../activity/Timestamp'; // eslint-disable-line

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
`;

// eslint-disable-next-line
const Timestamps = styled.div`
`;

const VesselTimestampModal = ({ vessel, dataFromOtherNamespaces }) => {
  const { useUserSocket, user } = useContext(UserContext);

  const { timestamps } = useContext(TimestampContext);

  let privateData = timestamps.find(ts => ts.ship.master_id === vessel.master_id.toString());
  let ownData = !!privateData;

  const timestampList = [];
  if (privateData) {
    privateData.portcalls[0].events.forEach(event => {
      event.timestamps.forEach(ts => timestampList.push(ts));
    });
  }
  if (!privateData && dataFromOtherNamespaces) {
    Object.keys(dataFromOtherNamespaces).forEach(key => {
      if (!privateData) {
        privateData = dataFromOtherNamespaces[key].find(ts => ts.ship.master_id === vessel.master_id.toString());
        if (privateData) {
          privateData.portcalls[0].events.forEach(event => {
            event.timestamps.forEach(ts => timestampList.push(ts));
          });
        }
      }
    });
  }

  const { data: decisionData, error: decisionError, fetchData: fetchDecisions } = useApi(
    'get',
    'port-call-decisions-summary'
  );

  const { data: notificationData, error: notificationError, fetchData: fetchNotifications } = useApi(
    'get',
    'port-call-notifications-summary',
    { port_call_master_ids: [vessel.master_id] }
  );

  const getDecisions = useCallback(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  const getNotifications = useCallback(() => {
    fetchNotifications(false, { port_call_master_ids: [vessel.master_id] });
  }, [fetchNotifications, vessel.master_id]);

  useUserSocket('port-call-decision-changed', getDecisions);
  useUserSocket('notifications-summary-common-changed', getNotifications);
  useUserSocket(`notifications-summary-changed-${user.id}`, getNotifications);

  let notifications = notificationError ? [] : notificationData;
  let decisions = decisionError ? [] : decisionData;

  let newNotifications = null;
  let masterId = vessel.master_id;
  if (notifications && masterId && notifications[masterId]) {
    newNotifications = notifications[masterId];
  }
  let newDecisions = null;
  if (decisions && masterId && decisions[masterId]) {
    newDecisions = decisions[masterId];
  }
  let transitions = null;
  if (vessel?.ship?.berth_transitions?.length) {
    transitions = vessel.ship.berth_transitions;
  }

  return (
    <ModalInner privateData={!!privateData}>
      <VesselTimestampFleetModalCard
        data={vessel.data}
        imo={vessel.imo}
        master_id={vessel.master_id}
        notifications={newNotifications}
        decisions={newDecisions}
        transitions={transitions}
        privateData={privateData}
        ownData={ownData}
        fleetAisData={vessel}
      />
      {privateData ? (
        <Timestamps>
          <TimestampGroup
            at={vessel.location}
            key={vessel.master_id}
            style={{ width: '254px', marginLeft: '6px', marginTop: 0 }}
          >
            {timestampList ? timestampList.map((timestamp, index) => <Timestamp data={timestamp} key={index} />) : null}
          </TimestampGroup>
        </Timestamps>
      ) : null}
    </ModalInner>
  );
};

export default VesselTimestampModal;
