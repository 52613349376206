import React from 'react';
import { Redirect as RRRedirect } from 'react-router-dom';

const Redirect = ({ location, ...props }) => {
  if (!location.search) {
    return <RRRedirect {...props} />;
  }

  const { to, ...rest } = props;
  let nextLocation = to + location.search;

  nextLocation = nextLocation.replaceAll('(', '\\(');
  nextLocation = nextLocation.replaceAll(')', '\\)');

  return <RRRedirect to={nextLocation} {...rest} />;
};

export default Redirect;
