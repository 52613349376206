import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Popover from 'antd/es/popover';
import Checkbox from 'antd/es/checkbox';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';

const ColumnSelect = styled.div`
  margin: 0 0 0 14px;
  display: flex;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 4px 4px 0px;
  border-radius: 3px;
  color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_dark)};
  background-color: ${props => (props.modalOpen ? '#f3f3f3' : 'transparent')};
  position: absolute;
  top: 2px;
  left: 220px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -2px;
  i {
    height: 24px;
    width: 28px;
  }
  svg {
    height: 24px;
    width: 24px;
    margin-top: 1px;
  }
`;

const OuterContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
`;

const InnerContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding: 14px 38px 10px 38px;
  display: inline-block;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  margin: 8px 0;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 0 7px 0;
`;

const Info = styled.div`
  font-size: 14px;
  max-width: 360px;
  margin-bottom: 0px;
  margin-top: 6px;
`;

const ColumnContainer = styled.div`
  display: flex;
  padding: 6px 0px;
`;

const Column = styled.div`
  margin-right: ${props => (props.first ? '100px' : '10px')};
`;

const Header = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 12px;
`;

const WasteCategorySelect = ({
  oilCategories,
  visibleOilCategories,
  setVisibleOilCategories,
  nlsCategories,
  visibleNLSCategories,
  setVisibleNLSCategories,
  sewageCategories,
  visibleSewageCategories,
  setVisibleSewageCategories,
  garbageCategories,
  visibleGarbageCategories,
  setVisibleGarbageCategories,
  gasCategories,
  visibleGasCategories,
  setVisibleGasCategories,
  otherCategories,
  visibleOtherCategories,
  setVisibleOtherCategories,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const storageKey = 'wasteCategories_' + namespace;

  const [modalOpen, setModalOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const value = localStorage.getItem(storageKey);
    if (!value) {
      setVisibleOilCategories(oilCategories);
      localStorage.setItem(storageKey, JSON.stringify([...oilCategories.map(c => c.key)]));
      return;
    }
    try {
      const parsedArray = JSON.parse(value);
      if (Array.isArray(parsedArray)) {
        if (parsedArray.length > 0) {
          let filteredOily = oilCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleOilCategories(filteredOily);
          let filteredNLS = nlsCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleNLSCategories(filteredNLS);
          let filteredGarbage = garbageCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleGarbageCategories(filteredGarbage);
          let filteredSewage = sewageCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleSewageCategories(filteredSewage);
          let filteredGas = gasCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleGasCategories(filteredGas);
          let filteredOther = otherCategories.filter(c => parsedArray.some(item => item === c.key));
          setVisibleOtherCategories(filteredOther);
        }
      }
    } catch {
      localStorage.removeItem(storageKey);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalChange = () => {
    setModalOpen(open => !open);
  };

  const oilCheckboxClicked = (key, event) => {
    let vColumns = [...visibleOilCategories];
    if (event.target.checked) {
      vColumns.push(oilCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleOilCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...vColumns.map(c => c.key),
        ...visibleNLSCategories.map(c => c.key),
        ...visibleSewageCategories.map(c => c.key),
        ...visibleGarbageCategories.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const nlsCheckboxClicked = (key, event) => {
    let vColumns = [...visibleNLSCategories];
    if (event.target.checked) {
      vColumns.push(nlsCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleNLSCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...visibleOilCategories.map(c => c.key),
        ...vColumns.map(c => c.key),
        ...visibleSewageCategories.map(c => c.key),
        ...visibleGarbageCategories.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const sewageCheckboxClicked = (key, event) => {
    let vColumns = [...visibleSewageCategories];
    if (event.target.checked) {
      vColumns.push(sewageCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleSewageCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...visibleSewageCategories.map(c => c.key),
        ...visibleOilCategories.map(c => c.key),
        ...visibleNLSCategories.map(c => c.key),
        ...vColumns.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const garbageCheckboxClicked = (key, event) => {
    let vColumns = [...visibleGarbageCategories];
    if (event.target.checked) {
      vColumns.push(garbageCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleGarbageCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...visibleSewageCategories.map(c => c.key),
        ...visibleOilCategories.map(c => c.key),
        ...visibleNLSCategories.map(c => c.key),
        ...vColumns.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const gasCheckboxClicked = (key, event) => {
    let vColumns = [...visibleGasCategories];
    if (event.target.checked) {
      vColumns.push(gasCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleGasCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...visibleSewageCategories.map(c => c.key),
        ...visibleOilCategories.map(c => c.key),
        ...visibleNLSCategories.map(c => c.key),
        ...vColumns.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const otherCheckboxClicked = (key, event) => {
    let vColumns = [...visibleOtherCategories];
    if (event.target.checked) {
      vColumns.push(otherCategories.find(c => c.key === key));
    } else {
      let index = vColumns.findIndex(c => c.key === key);
      vColumns.splice(index, 1);
    }
    setVisibleOtherCategories(vColumns);
    localStorage.setItem(
      storageKey,
      JSON.stringify([
        ...visibleSewageCategories.map(c => c.key),
        ...visibleOilCategories.map(c => c.key),
        ...visibleNLSCategories.map(c => c.key),
        ...vColumns.map(c => c.key),
        ...visibleGasCategories.map(c => c.key),
        ...visibleOtherCategories.map(c => c.key),
      ])
    );
  };

  const columnSelect = (
    <OuterContainer>
      <InnerContainer>
        <Title>{t('Category selection')}</Title>
        <Info>{t('Select categories to be visible in the table.')}</Info>
        <ColumnContainer>
          <Column first={true}>
            <Header>{t('Oil')}</Header>
            {oilCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleOilCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => oilCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
            <Header>{t('NLS')}</Header>
            {nlsCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleNLSCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => nlsCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
            <Header>{t('Sewage')}</Header>
            {sewageCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleSewageCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => sewageCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
          </Column>
          <Column first={false}>
            <Header>{t('Garbage')}</Header>
            {garbageCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleGarbageCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => garbageCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
            <Header>{t('Gas')}</Header>
            {gasCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleGasCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => gasCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
            <Header>{t('Other')}</Header>
            {otherCategories.map((column, index) => (
              <CheckBoxContainer key={`column-${index}`}>
                <Checkbox
                  checked={visibleOtherCategories.find(c => c.key === column.key)}
                  name={column.title}
                  onClick={e => otherCheckboxClicked(column.key, e)}
                >
                  {column.title}
                </Checkbox>
              </CheckBoxContainer>
            ))}
          </Column>
        </ColumnContainer>
      </InnerContainer>
    </OuterContainer>
  );

  return (
    <Popover
      open={modalOpen}
      onOpenChange={handleModalChange}
      trigger="click"
      content={columnSelect}
      placement="bottomLeft"
    >
      <ColumnSelect
        active={
          visibleOilCategories.length > 0 ||
          visibleNLSCategories.length > 0 ||
          visibleSewageCategories.length > 0 ||
          visibleGarbageCategories.length > 0 ||
          visibleGasCategories.length > 0 ||
          visibleOtherCategories.length > 0
        }
        modalOpen={modalOpen}
      >
        <IconContainer aria-label="open-side-menu-icon">
          <Icon type="columns" />
        </IconContainer>
        {t('Select columns')}
      </ColumnSelect>
    </Popover>
  );
};

export default WasteCategorySelect;
