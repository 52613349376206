import React, { useEffect, useContext } from 'react';

import { UserContext } from '../context/UserContext';

const RedirectionComponent = props => {
  const { namespace, namespaces, setNamespaceFromURL, namespaceFromURL } = useContext(UserContext);

  const { urlnamespace } = props.match.params;

  useEffect(() => {
    if (!namespaceFromURL && namespaces.length) {
      const namespaceList = namespaces.map(s => s.namespace);
      setNamespaceFromURL(!namespaceList.includes(urlnamespace) || !urlnamespace ? 'common' : urlnamespace);
    } else if (namespaceFromURL && namespace && namespace !== urlnamespace) {
      window.location.reload();
    }
  }, [namespace, namespaceFromURL, namespaces, setNamespaceFromURL, urlnamespace]);

  return <div></div>;
};

export default RedirectionComponent;
