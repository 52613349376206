import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fromEvent } from 'file-selector';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/browser';

import i18n from '../../i18n';

import { UserContext } from '../../context/UserContext';

import Input from 'antd/es/input';
import App from 'antd/es/app';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Text from 'antd/lib/typography/Text';

import Icon from '../ui/Icon';
import List from '../../components/ui/List';

const { confirm } = Modal;
const { TextArea } = Input;

const RowText = styled(Text)`
  white-space: pre-line;
`;

const RowInput = styled(TextArea)`
  border: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  padding: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.grey_dark};
  background: ${({ theme }) => theme.color.white};
  min-width: 100%;
  min-height: 2.8rem;
`;

const AddItemButton = styled.div`
  background-color: white;
  height: 32px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: 2px;

  color: ${props => props.disabled && '#a8a8a8'};
  background-color: ${props => props.disabled && '#f5f5f5'};
  cursor: ${props => props.disabled && 'default'};

  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }

  i {
    margin-top: -2px;
    margin-right: 6px;
    margin-left: -4px;
  }
`;

const LanguageSelection = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
`;

const Label = styled.div`
  margin-right: 12px;
  line-height: 30px;
`;

const LANGUAGES = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
];

const Translations = () => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const inputFile = useRef(null);

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDataAvailable, setStartDataAvailable] = useState(false);
  const [strings, setStrings] = useState(false);

  const { message } = App.useApp();

  useEffect(() => {
    const getStartData = async () => {
      setStartDataAvailable(true);
      const result = await apiCall('get', `translations/${namespace}/${'en'}`);
      if (result?.data && result.status === 200) {
        setStrings(result.data);
      } else {
        setStrings([]);
      }
      setLoading(false);
    };

    if (!startDataAvailable) {
      getStartData();
    }
  }, [apiCall, namespace, startDataAvailable]);

  const getData = async language => {
    setLoading(true);
    const usedLanguage = language || selectedLanguage;
    const result = await apiCall('get', `translations/${namespace}/${usedLanguage}`);
    if (result?.data && result.status === 200) {
      setStrings(result.data);
    } else {
      setStrings([]);
    }
    setLoading(false);
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  let stringsArr = strings ? Object.keys(strings).map(key => [key, strings[key]]) : [];
  stringsArr.sort((a, b) => a[0].localeCompare(b[0], undefined, { sensitivity: 'base' }));
  let data = [];
  stringsArr.forEach((e, i) => {
    data.push({
      id: selectedLanguage + e[0] + i,
      key: e[0],
      value: e[1],
    });
  });

  const handleChange = (r, text) => {
    const objIndex = updated.findIndex(obj => obj.key === r.key);
    const updatedObj = { key: r.key, ...updated[objIndex], value: text };
    if (strings[r.key] !== text) {
      const newItems = [...updated.slice(0, objIndex), updatedObj, ...updated.slice(objIndex + 1)];
      setUpdated(newItems);
    } else {
      let newItems = [...updated];
      newItems.splice(objIndex, 1);
      setUpdated(newItems);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    if (updated?.length) {
      await apiCall('put', `translations/${namespace}/${selectedLanguage}`, { updated: updated });
      // TODO: investigate if translations could be loaded in single call (current list & i18next translations)
      await getData();
      await i18n.reloadResources();
      setUpdated([]);
    }
    setLoading(false);
  };

  const handleUpload = async data => {
    setLoading(true);
    if (data?.length) {
      await apiCall('post', `translations/${namespace}/${selectedLanguage}`, { translations: data });
      window.location.reload();
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    await getData();
    if (strings) {
      const json = JSON.stringify(
        strings,
        Object.keys(strings).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })),
        2
      );
      const blob = new Blob([json], {
        type: 'application/json; charset=utf-8',
      });
      saveAs(blob, `translations_${namespace}_${selectedLanguage}.json`);
    }
  };

  const uploadFile = async evt => {
    try {
      const files = await fromEvent(evt);
      if (files?.[0]) {
        // TODO: Replace fileReader with Blob.text() when all browsers are supported
        const reader = new FileReader();
        reader.addEventListener('loadend', function() {
          processFile(reader.result);
        });
        return reader.readAsText(files[0]);
      }
    } catch (e) {
      Sentry.captureException(e);
      message.error(t('Could not upload translations file: {{message}}', { message: e.message }));
    }
  };

  const processFile = async data => {
    try {
      if (data) {
        // Check that file contains data and parse
        const json = data ? JSON.parse(data) : {};
        const translations = Object.entries(json).reduce((acc, pair) => {
          const [key, value] = pair;
          if (typeof key === 'string' && typeof value === 'string') {
            acc.push({
              key: key,
              value: value,
            });
          }
          return acc;
        }, []);
        if (translations?.length) {
          return confirm({
            title: t('Are you sure you want to upload translations?'),
            content: t('The current translations ({{namespace}} / {{lang}}) will be replaced with this one', {
              namespace: namespace,
              lang: selectedLanguage,
            }),
            okType: 'danger',
            onOk() {
              handleUpload(translations);
            },
            onCancel() {},
          });
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      return message.error(t('Could not upload translations file: {{message}}', { message: e.message }));
    }
    message.error(t('Invalid translations file: No valid translations found'));
  };

  const columns = [
    {
      title: t('Text'),
      dataIndex: 'key',
      key: 'string',
      width: '40%',
      render: (text, record) => {
        if (record.key === record.value) {
          return <RowText>{text}</RowText>;
        } else {
          return <RowText strong>{text} *</RowText>;
        }
      },
    },
    {
      title: t('Translation'),
      dataIndex: 'value',
      key: 'translation',
      width: '60%',
      render: (text, record) => (
        <RowInput defaultValue={record.value} onChange={e => handleChange(record, e.target.value)} autoSize />
      ),
    },
  ];

  const additionalButtons = [
    {
      render: index => (
        <AddItemButton key={index} link onClick={() => inputFile.current.click()}>
          <Icon type="upload" style={{ fontSize: '20px' }} />
          {t('Upload new translations')}
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            accept="application/json"
            onChange={e => uploadFile(e)}
            onClick={e => (e.target.value = null)}
          />
        </AddItemButton>
      ),
    },
    {
      onClick: handleDownload,
      text: t('Download current translations'),
      icon: 'download',
    },
  ];

  const setLanguage = e => {
    setSelectedLanguage(e);
    getData(e);
  };

  return (
    <>
      {(document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
        <LanguageSelection>
          <Label>{t('Choose translation language')}:</Label>
          <Select
            placeholder={t('Duration')}
            optionFilterProp="label"
            onChange={e => {
              setLanguage(e);
            }}
            style={{ width: '222px' }}
            options={LANGUAGES.map(option => {
              return {
                label: option.name + ' (' + option.code + ')',
                value: option.code,
              };
            })}
            value={selectedLanguage}
          />
        </LanguageSelection>
      )}
      <List
        rowKey="id"
        columns={columns}
        dataSource={data}
        spinning={loading}
        searchPlaceHolder={t('Search translations')}
        additionalButtons={additionalButtons}
        localPagination={true}
        localPaginationFields={['key', 'value']}
        infoText={
          <div style={{ padding: '3px 6px 12px 6px' }}>
            <Text strong>* </Text>
            <Text>{t('Text was translated.')}</Text>
          </div>
        }
      />
      <AddItemButton
        style={{ display: 'inline-block', position: 'absolute', right: '27px', top: '132px', zIndex: 80 }}
        onClick={handleSave}
      >
        {t('Save translations')}
      </AddItemButton>
    </>
  );
};

export default Translations;
