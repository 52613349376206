import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonLight from '../../ui/ButtonLight';
import { UserContext } from '../../../context/UserContext';
import SelectWithSearch from '../../ui/SelectWithSearch';

const RowAdderTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 16px;
  font-weight: 600;
`;

const RowAdderBottom = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 2px 16px;
  display: flex;
  justify-content: space-between;

  button {
    margin-bottom: 10px;
  }
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: 100%;
`;

const RowAdderBody = styled.div`
  padding: 16px 24px;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(212, 212, 212, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const RowAdderContainer = styled.div`
  width: 500px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e8e8e8;
  display: block;
  height: fit-content;
`;

const CreditRowAdder = ({
  setRowAdderOpen,
  rowAdderSelection,
  setRowAdderSelection,
  setInputData,
  inputData,
  creditableProducts,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const handleProductAdderChange = e => {
    const product = creditableProducts.find(p => p.code === e);
    setRowAdderSelection(product);
  };

  const handleAddRow = () => {
    let combUnit = '';
    rowAdderSelection.pricing.units.forEach((u, i) => {
      if (i > 0) {
        combUnit += ' / ';
      }
      combUnit += u.name;
    });
    setInputData({
      ...inputData,
      rows: [
        ...inputData.rows,
        {
          modifications: [],
          amounts: rowAdderSelection.pricing.units.map(u => {
            return {
              amount: 0,
              unit_name: u.name,
            };
          }),
          code: rowAdderSelection.code,
          name: rowAdderSelection.name,
          price: 0,
          unit_amount: rowAdderSelection.pricing.unit_price,
          combined_unit: combUnit,
          vat: rowAdderSelection.vat,
          timeframe: { from: null, to: null, at: null },
          product_data: rowAdderSelection,
        },
      ],
    });
    setRowAdderSelection(null);
    setRowAdderOpen(null);
  };

  return (
    <Container>
      <RowAdderContainer>
        <RowAdderTitle>{t('Add new product row')}</RowAdderTitle>
        <RowAdderBody>
          <RowContainer>
            <RowHeader>{t('Product')}</RowHeader>
            <SelectWithSearch
              placeholder={t('Link')}
              optionFilterProp="label"
              onChange={value => handleProductAdderChange(value)}
              onSelect={value => handleProductAdderChange(value)}
              style={{ width: '100%' }}
              options={creditableProducts.map(option => {
                return {
                  key: option.name,
                  label: option.name,
                  value: option.code,
                };
              })}
              value={rowAdderSelection?.code}
              allowClear
            />
          </RowContainer>
        </RowAdderBody>
        <RowAdderBottom>
          <div />
          <div>
            <ButtonLight
              style={{ marginRight: '12px' }}
              cancel
              onClick={() => {
                setRowAdderOpen(false);
                setRowAdderSelection(null);
              }}
            >
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight onClick={handleAddRow} disabled={!rowAdderSelection}>
              {t('Add')}
            </ButtonLight>
          </div>
        </RowAdderBottom>
      </RowAdderContainer>
    </Container>
  );
};

export default CreditRowAdder;
