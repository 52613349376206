import Tooltip from 'antd/es/tooltip';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import { darken } from 'polished';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';

const Container = styled.div`
  width: ${props => (props.listExpanded ? '300px' : '20px')};
  height: calc(100vh - 152px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  border-right: 2px solid #f0f0f0;
  position: relative;
  padding: 8px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 138px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? '250px' : '10px')};
  }
`;

const List = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
`;

const Row = styled.div`
  border-radius: 3px;
  background-color: ${props => (props.chosen ? '#eaeaea' : '#f7f7f7')};
  margin: 12px 0;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.03, '#f7f7f7')};
  }
`;

const RowHeader = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  border-bottom: ${props => (props.chosen ? '1px solid #d8d8d8' : '1px solid #e8e8e8')};
`;

const RowDescription = styled.div`
  margin-bottom: 12px;
  max-height: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const FilterOptions = styled.div`
  margin-bottom: 12px;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`;

const FilterHeader = styled.div`
  margin-right: 12px;
  font-weight: 600;
`;

const FilterTag = styled.div`
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  height: 18px;
  padding: 0 4px;
  display: inline-block;
  border-radius: 4px;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 600;
  margin-right: 6px;
  margin-bottom: 3px;
  margin-top: 2px;
`;

const IconContainer = styled.div`
  line-height: 24px;
  height: 24px;
  width: 14px;
  margin-top: 7px;
  margin-left: 1px;
  color: ${({ theme }) => theme.color.grey};
`;

const Opener = styled.div`
  position: absolute;
  width: 14px;
  height: 40px;
  top: 14px;
  cursor: pointer;
  z-index: 100;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 1px 1px 0;
  right: -14px;
`;

const ExportList = ({ listData, chosenExport, setChosenExport, listExpanded, setListExpanded }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const toggleOpen = () => {
    setListExpanded(!listExpanded);
  };

  return (
    <Container listExpanded={listExpanded} alertsHeight={alertsHeight}>
      <Opener onClick={toggleOpen}>
        <Tooltip
          title={listExpanded ? t('Close data selection') : t('Open data selection')}
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
          placement="left"
        >
          {!listExpanded ? (
            <IconContainer aria-label="map-layers-caret-right">
              <CaretRightOutlined />
            </IconContainer>
          ) : (
            <IconContainer aria-label="map-layers-caret-left">
              <CaretLeftOutlined />
            </IconContainer>
          )}
        </Tooltip>
      </Opener>
      <List>
        {listExpanded && <Header>{t('Export options')}</Header>}
        {listExpanded &&
          listData.map((l, i) => {
            const filters = l.filters && Object.keys(l.filters).length > 0;

            return (
              <Row key={i} onClick={() => setChosenExport(l)} chosen={chosenExport?.id === l.id}>
                <RowHeader chosen={chosenExport?.id === l.id}>{t(l.header)}</RowHeader>
                <RowDescription>{t(l.description)}</RowDescription>
                {filters && (
                  <FilterOptions>
                    <FilterHeader>{t('Filters')}:</FilterHeader>
                    {Object.keys(l.filters).map((filter, j) => (
                      <FilterTag key={j}>{t(filter.replace(/_/g, ' '))}</FilterTag>
                    ))}
                  </FilterOptions>
                )}
              </Row>
            );
          })}
      </List>
    </Container>
  );
};
export default ExportList;
