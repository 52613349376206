import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import WasteViewDocument from '../components/waste/WasteViewDocument';

const WasteDocumentView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'View Document | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('View Document')}>
      <Page fullWidth>
        <WasteViewDocument />
      </Page>
    </Layout>
  );
};

export default WasteDocumentView;
