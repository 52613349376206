import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExclamationOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import Input from 'antd/es/input';
import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Spin from 'antd/es/spin';

import Icon from '../../components/ui/Icon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useApi from '../../hooks/useApi';
import { darken } from 'polished';
import ButtonLight from '../../components/ui/ButtonLight';
import { TIME_FORMAT } from '../../utils/constants';
import dayjs from 'dayjs';

const { TextArea } = Input;

const AdminPortCallRequestsPage = styled(Page)`
  background-color: #ffffff;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 12px 0 24px 24px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  margin-right: 18px;

  table {
    padding-bottom: 30px !important;
  }
`;

const GoBack = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  display: inline-flex;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.color.secondary)};
  }
`;

const BackIconContainer = styled.div`
  margin-right: 8px;

  i {
    width: 18px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0 16px 0;
  @media (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  :last-child {
    padding-right: 0;
  }
  width: 100%;
`;

const Header = styled.div`
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const Row = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Key = styled.dt`
  width: ${props => (props.warning ? '35%' : '50%')};
`;

const Value = styled.dd`
  width: 50%;
  margin-left: auto;
  font-weight: 550;
  overflow-wrap: break-word;
  color: ${props => (props.warning ? '#D0011C' : '#4A4A4A')};
`;

const Warning = styled.dd`
  width: 15%;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  margin-bottom: 0.25rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  button {
    margin-bottom: 0px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Link = styled.div`
  color: #4990dd;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const ShipIcon = styled(Icon)`
  svg {
    width: 14px;
    height: 14px;
  }
`;

const AdminPortCallRequest = props => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();

  const { message: alertMessage } = App.useApp();

  const { id } = props.match.params;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initRequest = {
    notes: null,
    data: null,
  };

  const [request, setRequest] = useState(initRequest);

  const [portCallRequest, setPortCallRequest] = useState({});
  const [vessel, setVessel] = useState({});
  const [berth, setBerth] = useState({});
  const [berthUsage, setBerthUsage] = useState({});

  const [apiCallPending, setApiCallPending] = useState(false);

  const requestChanged = useCallback(data => {
    if (mounted.current) {
      if (data) {
        setPortCallRequest(data['port-call-request']);
        setVessel(data['vessel']);
        setBerth(data['berth']);
        setBerthUsage(data['berth_usage']);
        if (data['port-call-request']?.notes) {
          setRequest(previous => ({ ...previous, notes: data['port-call-request'].notes }));
        }
      } else {
        setPortCallRequest({});
        setVessel({});
        setBerth({});
        setBerthUsage({});
        setRequest({
          notes: null,
          data: null,
        });
      }
    }
  }, []);

  const { loading, error, fetchData } = useApi('get', 'port-call-request/' + id, null, requestChanged, false);

  useEffect(() => {
    if (mounted.current) {
      fetchData();
    }
  }, [fetchData]);

  if (error) {
    alertMessage.error(error, 4);
  }

  useEffect(() => {
    document.title = 'Port call request | Port Activity App';
  }, []);

  const handleAcceptRequest = async () => {
    setApiCallPending(true);
    try {
      await apiCall('put', `port-call-request/status/${id}`, {
        status: 'accepted_by_port',
        notes: request.notes,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);

    //fetchData();
    history.push('/port-call-requests');
  };

  const handleRejectRequest = async () => {
    /* TODO: add info about fields that are conflicting
     * data: {
     *   conflicting_fields: [
     *     {
     *       name: 'eta',
     *       reason: 'overlapping, available at xxx',
     *     }
     *   ]
     *  }
     */
    setApiCallPending(true);
    try {
      await apiCall('put', `port-call-request/status/${id}`, {
        status: 'rejected_by_port',
        notes: request.notes,
        data: null,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);

    //fetchData();
    history.push('/port-call-requests');
  };

  const handleInputChange = (name, value) => {
    setRequest(previous => ({ ...previous, [name]: value }));
  };

  const getConflictingRows = (title, items) => {
    return (
      <ColumnContainer style={{ width: '50%' }}>
        <Row>
          <InputLabel>{title}</InputLabel>
        </Row>
        {items.map(item => {
          return item.imo ? (
            <div key={`row-${item.imo}`}>
              <Row>
                <Key>{t('Vessel name')}</Key>
                <Value>{item.name}</Value>
              </Row>
              <Row>
                <Key>{t('ETA')}</Key>
                <Value>{dayjs(item.eta).format(TIME_FORMAT)}</Value>
              </Row>
              <Row>
                <Key>{t('ETD')}</Key>
                <Value>{dayjs(item.etd).format(TIME_FORMAT)}</Value>
              </Row>
            </div>
          ) : null;
        })}
      </ColumnContainer>
    );
  };

  const openMarineTraffic = () => {
    window.open(
      'https://www.marinetraffic.com/en/ais/details/ships/imo:' + portCallRequest.imo,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const disabled =
    portCallRequest.port_call_request_status === 'completed_by_port' ||
    portCallRequest.port_call_request_status === 'cancelled_by_port' ||
    portCallRequest.port_call_request_status === 'cancelled_by_vessel' ||
    portCallRequest.port_call_request_status === 'accepted_by_port' ||
    portCallRequest.port_call_request_status === 'rejected_by_port';

  const planned = berthUsage?.planned?.[portCallRequest.primary_berth]
    ? Object.values(berthUsage?.planned?.[portCallRequest.primary_berth])?.filter?.(
      item =>
        item.to_port !== portCallRequest.to_port &&
          item.imo !== portCallRequest.imo &&
          item.eta !== portCallRequest.eta &&
          item.etd !== portCallRequest.etd
    )
    : [];
  const notPlanned = berthUsage?.not_planned?.[portCallRequest.primary_berth]
    ? Object.values(berthUsage?.not_planned?.[portCallRequest.primary_berth])?.filter?.(
      item =>
        item.to_port !== portCallRequest.to_port &&
          item.imo !== portCallRequest.imo &&
          item.eta !== portCallRequest.eta &&
          item.etd !== portCallRequest.etd
    )
    : [];
  const reserved = berthUsage?.reserved?.[portCallRequest.primary_berth]
    ? Object.values(berthUsage?.reserved?.[portCallRequest.primary_berth])?.filter?.(
      item =>
        item.to_port !== portCallRequest.to_port &&
          item.imo !== portCallRequest.imo &&
          item.eta !== portCallRequest.eta &&
          item.etd !== portCallRequest.etd
    )
    : [];

  const draftAlert = berth?.draft ? portCallRequest.draft > berth.draft : false;
  const etaAlert =
    notPlanned.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.eta) && dayjs(item.etd) >= dayjs(portCallRequest.eta)
    ) ||
    planned.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.eta) && dayjs(item.etd) >= dayjs(portCallRequest.eta)
    ) ||
    reserved.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.eta) && dayjs(item.etd) >= dayjs(portCallRequest.eta)
    );
  const etdAlert =
    notPlanned.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.etd) && dayjs(item.etd) >= dayjs(portCallRequest.etd)
    ) ||
    planned.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.etd) && dayjs(item.etd) >= dayjs(portCallRequest.etd)
    ) ||
    reserved.some(
      item => dayjs(item.eta) <= dayjs(portCallRequest.etd) && dayjs(item.etd) >= dayjs(portCallRequest.etd)
    );
  const berthAlert = berth ? !berth.reservable : false;
  const loaAlert = berth?.length ? portCallRequest.loa > berth.length : false;

  return (
    <Layout pagename={t('Port call request')}>
      <AdminPortCallRequestsPage fullWidth>
        <Container>
          <GoBack onClick={() => history.push('/port-call-requests')}>
            <BackIconContainer>
              <Icon type="arrow-left" />
            </BackIconContainer>
            {t('Back to list view')}
          </GoBack>
          {apiCallPending || loading ? (
            <Loader>
              <Spin size="large" />
            </Loader>
          ) : request ? (
            <>
              <Section>
                <RowContainer>
                  <ColumnContainer>
                    <Header>{t('Port call request')}</Header>
                    <Row>
                      <Key>{t('Vessel name')}</Key>
                      <Value>{portCallRequest.vessel_name}</Value>
                    </Row>
                    <Row>
                      <Key>{t('IMO')}</Key>
                      <Value>{portCallRequest.imo}</Value>
                    </Row>
                    <Row>
                      <Key warning={etaAlert}>{t('ETA')}</Key>
                      {etaAlert ? (
                        <Warning>
                          <ExclamationOutlined style={{ alignSelf: 'center', color: '#D0011C', fontSize: '18px' }} />
                        </Warning>
                      ) : null}
                      <Value warning={etaAlert}>{dayjs(portCallRequest.eta).format(TIME_FORMAT)}</Value>
                    </Row>
                    <Row>
                      <Key warning={etdAlert}>{t('ETD')}</Key>
                      {etdAlert ? (
                        <Warning>
                          <ExclamationOutlined style={{ alignSelf: 'center', color: '#D0011C', fontSize: '18px' }} />
                        </Warning>
                      ) : null}
                      <Value warning={etdAlert}>{dayjs(portCallRequest.etd).format(TIME_FORMAT)}</Value>
                    </Row>
                    <Row>
                      <Key warning={berthAlert}>{t('Primary berth')}</Key>
                      {berthAlert ? (
                        <Warning>
                          <ExclamationOutlined style={{ alignSelf: 'center', color: '#D0011C', fontSize: '18px' }} />
                        </Warning>
                      ) : null}
                      <Value warning={berthAlert}>{portCallRequest.primary_berth_name}</Value>
                    </Row>
                    <Row>
                      <Key warning={loaAlert}>{t('LOA')}</Key>
                      {loaAlert ? (
                        <Warning>
                          <ExclamationOutlined style={{ alignSelf: 'center', color: '#D0011C', fontSize: '18px' }} />
                        </Warning>
                      ) : null}
                      <Value warning={loaAlert}>{portCallRequest.loa}</Value>
                    </Row>
                    <Row>
                      <Key>{t('Beam')}</Key>
                      <Value>{portCallRequest.beam}</Value>
                    </Row>
                    <Row>
                      <Key warning={draftAlert}>{t('Draft')}</Key>
                      {draftAlert ? (
                        <Warning>
                          <ExclamationOutlined style={{ alignSelf: 'center', color: '#D0011C', fontSize: '18px' }} />
                        </Warning>
                      ) : null}
                      <Value warning={draftAlert}>{portCallRequest.draft}</Value>
                    </Row>
                    <Row>
                      <Key>{t('Net tonnage')}</Key>
                      <Value>{portCallRequest.net_tonnage}</Value>
                    </Row>
                    <Row>
                      <Key>{t('Status')}</Key>
                      <Value>{portCallRequest.readable_port_call_request_status}</Value>
                    </Row>
                    <Row>
                      <Key>{t('Emails')}</Key>
                      <Value>{portCallRequest.emails}</Value>
                    </Row>
                    {portCallRequest.port_call_id ? (
                      <Row>
                        <Key>{t('Port call ID')}</Key>
                        <Value>{portCallRequest.port_call_id}</Value>
                      </Row>
                    ) : null}
                  </ColumnContainer>
                  <ColumnContainer>
                    <Header>{t('Vessel info from global registry')}</Header>
                    {vessel ? (
                      <>
                        <Row>
                          <Key>{t('Name')}</Key>
                          <Value>{vessel.name}</Value>
                        </Row>
                        <Row>
                          <Key>{t('IMO')}</Key>
                          <Value>{vessel.imo}</Value>
                        </Row>
                        <Row>
                          <Key>{t('MMSI')}</Key>
                          <Value>{vessel.mmsi}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Callsign')}</Key>
                          <Value>{vessel.callsign}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Nationality')}</Key>
                          <Value>{vessel.data?.nationality_string}</Value>
                        </Row>
                        <Row>
                          <Key>{t('LOA')}</Key>
                          <Value>{vessel.data?.loa}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Beam')}</Key>
                          <Value>{vessel.data?.beam}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Draft')}</Key>
                          <Value>{vessel.data?.draft}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Net tonnage')}</Key>
                          <Value>{vessel.data?.net_tonnage}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Height')}</Key>
                          <Value>{vessel.data?.height}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Length')}</Key>
                          <Value>{vessel.data?.length}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Gross tonnage')}</Key>
                          <Value>{vessel.data?.gross_tonnage}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Vessel type')}</Key>
                          <Value>{vessel.vessel_type?.name}</Value>
                        </Row>
                        <Row>
                          <Link outline onClick={openMarineTraffic}>
                            <ShipIcon type="ship" style={{ marginRight: '.8rem' }} />
                            {t('Open in Marine Traffic')}
                          </Link>
                        </Row>
                      </>
                    ) : (
                      <InputLabel>{t('Vessel info not available')}</InputLabel>
                    )}
                  </ColumnContainer>
                  <ColumnContainer>
                    <Header>{t('Berth info')}</Header>
                    {berth ? (
                      <>
                        <Row>
                          <Key>{t('Name')}</Key>
                          <Value>{berth.name}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Length')}</Key>
                          <Value>{berth.length}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Draft')}</Key>
                          <Value>{berth.draft}</Value>
                        </Row>
                        <Row>
                          <Key>{t('Reservable')}</Key>
                          <Value>{berth.reservable ? 'true' : 'false'}</Value>
                        </Row>
                      </>
                    ) : (
                      <InputLabel>{t('Berth info not available')}</InputLabel>
                    )}
                  </ColumnContainer>
                </RowContainer>
              </Section>
              <Section>
                <RowContainer>
                  <ColumnContainer>
                    <Header style={{ width: '100%' }}>{t('Conflicting port calls')}</Header>
                    <RowContainer>
                      {notPlanned ? getConflictingRows(t('Not planned'), notPlanned) : null}
                      {planned ? getConflictingRows(t('Planned'), planned) : null}
                      {reserved ? getConflictingRows(t('Reserved'), reserved) : null}
                    </RowContainer>
                  </ColumnContainer>
                </RowContainer>
              </Section>
              <Section>
                <RowContainer>
                  <ColumnContainer>
                    <Header style={{ width: '100%' }}>{t('Actions')}</Header>
                    <Row>
                      <InputWrapper style={{ width: '100%' }}>
                        <InputLabel>{t('Notes')}</InputLabel>
                        <TextArea
                          disabled={disabled}
                          label={t('Notes')}
                          name="notes"
                          field="notes"
                          value={request.notes}
                          type="text"
                          onChange={({ target }) => handleInputChange(target.name, target.value)}
                          rows={3}
                          //autoSize
                        />
                      </InputWrapper>
                    </Row>
                  </ColumnContainer>
                </RowContainer>
              </Section>
              <Section>
                <RowContainer>
                  <ButtonContainer>
                    <Popconfirm
                      title={t('Are you sure you want to accept port call registration')}
                      onConfirm={() => handleAcceptRequest()}
                      okText={t('Yes')}
                      okType="primary"
                      cancelText={t('No')}
                      icon={null}
                      cancelButtonProps={{ type: 'link' }}
                    >
                      <ButtonLight style={{ height: '38px', lineHeight: 'unset ' }} disabled={disabled}>
                        {t('Accept')}
                      </ButtonLight>
                    </Popconfirm>
                    <Popconfirm
                      title={t('Are you sure you want to reject port call registration')}
                      onConfirm={() => handleRejectRequest()}
                      okText={t('Yes')}
                      okType="primary"
                      cancelText={t('No')}
                      icon={null}
                      cancelButtonProps={{ type: 'link' }}
                    >
                      <ButtonLight style={{ height: '38px', lineHeight: 'unset ' }} disabled={disabled}>
                        {t('Reject')}
                      </ButtonLight>
                    </Popconfirm>
                  </ButtonContainer>
                </RowContainer>
              </Section>
            </>
          ) : null}
        </Container>
      </AdminPortCallRequestsPage>
    </Layout>
  );
};

export default AdminPortCallRequest;
