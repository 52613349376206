import React, { useContext } from 'react';
import styled from 'styled-components';

import BerthPlanningToolTopPanel from './BerthPlanningToolTopPanel';
import BerthPlanningToolCalendar from './BerthPlanningToolCalendar';

import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import BptMobileTopPanel from './BptMobileTopPanel';

const CalendarContainer = styled.div`
  height: 100%;
  min-width: 790px;

  @media (max-width: 1140px) {
    width: 100% !important;
  }
`;

const BerthPlanningToolCalendarContainer = () => {
  const { sidePanelWidth } = useContext(BerthPlanningToolContext);

  return (
    <CalendarContainer style={{ width: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <BerthPlanningToolTopPanel />
      <BptMobileTopPanel />
      <BerthPlanningToolCalendar />
    </CalendarContainer>
  );
};

export default BerthPlanningToolCalendarContainer;
