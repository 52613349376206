import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import { WeatherContext } from '../../context/WeatherContext';

import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';
import { getWindType } from './weatherUtils';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Wrapper = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  width: calc(100% - 44% - 40px);
  margin: 5px 10px;
  height: 198px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    width: 100%;
    margin: 5px 0px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 18px;
`;

const CardDetails = styled.div`
  display: flex;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
`;

const WeatherPanel = styled.div`
  width: 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wind = styled.div`
  display: flex;
  padding: 4px 0;
  justify-content: center;
  margin-top: 30px;

  svg {
    width: 18px;
    margin-top: -3px;
  }

  @media (max-width: 440px) {
    svg {
      width: 16px;
    }
  }
`;

const WindInfo = styled.div`
  padding-left: 15px;
  color: black;
  font-weight: 600;
  display: block;
  text-align: center;

  @media (max-width: 440px) {
    padding-left: 6px;
  }
`;

const TempDegree = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 5px 0;

  @media (max-width: 440px) {
    font-size: 20px;
  }
`;

const TimeStatus = styled.div`
  font-size: 16px;
  text-align: center;

  @media (max-width: 440px) {
    font-size: 14px;
  }
`;

const WeatherSymbol = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -18px;

  svg {
    width: 50px;
    height: 50px;
    margin-top: -8px;
    margin-left: -10px;
  }
`;

const PortWeatherForecastCard = () => {
  const { namespace } = useContext(UserContext);
  const { forecastForDayWeather } = useContext(WeatherContext);
  const { t } = useTranslation(namespace);

  const getHeaderText = time => {
    const hour = dayjs(time).hour();

    if (hour < 6) {
      return t('Overnight');
    } else if (hour < 12) {
      return t('Morning');
    } else if (hour < 18) {
      return t('Afternoon');
    } else {
      return t('Evening');
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <Title>{t('24h Forecast')}</Title>
      </CardHeader>
      <CardDetails>
        {forecastForDayWeather.map(forecast => (
          <WeatherPanel key={forecast.time}>
            <TimeStatus>{getHeaderText(forecast.time)}</TimeStatus>
            <TempDegree>
              {forecast.temperature || forecast.temperature === 0 ? Math.round(forecast.temperature) : '-'} °C
            </TempDegree>
            <WeatherSymbol>
              <WeatherIconCombined type={forecast.description} descriptionObj={forecast.description_object} />
            </WeatherSymbol>
            <Wind>
              <WeatherIcon
                type={forecast.wind_speed || forecast.wind_speed === 0 ? getWindType(forecast.wind_speed) : null}
                rotation={forecast.wind_direction || 0}
              />
              <WindInfo>
                {forecast.wind_speed || forecast.wind_speed === 0 ? Math.round(forecast.wind_speed) : '-'} m/s
              </WindInfo>
            </Wind>
          </WeatherPanel>
        ))}
      </CardDetails>
    </Wrapper>
  );
};

export default PortWeatherForecastCard;
