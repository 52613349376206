import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Heading from '../components/ui/Heading';
import Icon from '../components/ui/Icon';
import LandingPage from './LandingPage';
import { FormsContext, FormsProvider } from '../context/FormContext';
import ServiceOrderLineForm from '../components/service-order/ServiceOrderLineForm';
import RegisterPortcallForm from '../components/port-call/RegisterPortcallForm';
import ButtonLight from '../components/ui/ButtonLight';
import DynamicLogo from '../components/ui/DynamicLogo';
import Logo from '../components/ui/Logo';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';
import UpdatePortcallForm from '../components/port-call/UpdatePortcallForm';

const StyledWrapper = styled.div`
  width: 100%;
  background: #fff;
`;

const BackgroundBrand = styled.div`
  background-color: #11195b;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: start;
`;

const FormContainer = styled.div`
  max-width: 900px;
  width: 80%;
  margin: 80px auto;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 160px);
  padding: 56px 0;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`;

const TitleText = styled(Heading)`
  color: #000;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  svg {
    width: 64px;
    height: 64px;
    margin-bottom: 12px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe1c0;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  border-color: #ffa319;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
`;

const ErrorText = styled.div`
  color: #000;
  margin: 12px 0;
`;

const FormNotification = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
`;

const CodeMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 30px 30px 30px;
`;

const CodeTitleText = styled(Heading)`
  color: #000;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const CodeSubTitleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const CodeInputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const CodeButtonContainer = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: 16px;
  button {
    margin-bottom: 0px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyleInput = styled(Input)`
  padding: ${({ theme }) => theme.sizing.gap_small};
  min-width: 200px;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Required = styled.span`
  color: red;
`;

const FormsView = props => {
  const { formNotification, formParams, formType, namespace, token, tokenChecked, validToken } = useContext(
    FormsContext
  );
  const { t } = useTranslation(namespace);

  const renderForm = formType => {
    switch (formType) {
      case 'service-order-ack-delivery':
        return validToken && formParams.order_line_id ? (
          <ServiceOrderLineForm />
        ) : (
          renderError(t('The token is invalid or has expired'))
        );
      case 'register-port-call':
        return token ? (
          validToken ? (
            <RegisterPortcallForm />
          ) : (
            renderError(t('The token is invalid or has expired'))
          )
        ) : (
          <FormsCodeView title={t('Register port call')} />
        );
      case 'update-port-call':
        return validToken && formParams.port_call_request_id ? (
          <UpdatePortcallForm />
        ) : (
          renderError(t('The token is invalid or has expired'))
        );
      default:
        break;
    }
    return renderError(t('Unsupported form type'));
  };

  const renderError = title => {
    return (
      <ErrorContainer>
        <Icon
          type="exclamation-triangle"
          fill="#ff9900"
          align="middle"
          height="28px"
          width="28px"
          style={{ marginRight: '16px' }}
        />
        <ErrorText>{title}</ErrorText>
      </ErrorContainer>
    );
  };

  if (tokenChecked) {
    return (
      <StyledWrapper>
        <BackgroundBrand>
          <FormContainer>
            <LogoContainer {...props}>
              {formParams?.port_icon ? <DynamicLogo src={formParams.port_icon} /> : <Logo port={namespace} />}
              <TitleText h4>
                {formParams?.port_name
                  ? t('Port of {{portName}}', { portName: formParams.port_name })
                  : t('Port Activity')}
              </TitleText>
            </LogoContainer>
            {renderForm(formType)}
            {formNotification && <FormNotification>{formNotification}</FormNotification>}
          </FormContainer>
        </BackgroundBrand>
      </StyledWrapper>
    );
  }

  return <LandingPage />;
};

const FormsCodeView = ({ title }) => {
  const { apiCallPending, namespace, verifyFormCode } = useContext(FormsContext);
  const { t } = useTranslation(namespace);

  const [verificationCode, setVerificationCode] = useState(undefined);

  const handleInputChange = e => {
    setVerificationCode(e.target?.value || undefined);
  };

  return (
    <Spin spinning={apiCallPending} wrapperClassName="spinner">
      <CodeMainContainer>
        <CodeTitleText h4>{t(title)}</CodeTitleText>
        <CodeSubTitleText>{t('Please enter the verification code received from port')}</CodeSubTitleText>

        <CodeInputRow style={{ alignItems: 'center', width: '100%' }}>
          <InputWrapper>
            <InputLabel>
              {t('Verification code')}
              <Required>*</Required>
            </InputLabel>
            <StyleInput
              name="code"
              placeholder={t('Verification code')}
              value={verificationCode || ''}
              onChange={handleInputChange}
              style={{ width: '100%' }}
            />
          </InputWrapper>
          <CodeButtonContainer>
            <ButtonLight
              disabled={apiCallPending || !verificationCode}
              onClick={() => verifyFormCode(verificationCode)}
              style={{ height: '38px', lineHeight: 'unset ' }}
            >
              {t('Verify')}
            </ButtonLight>
          </CodeButtonContainer>
        </CodeInputRow>
      </CodeMainContainer>
    </Spin>
  );
};

const Forms = props => {
  return (
    <FormsProvider {...props}>
      <FormsView {...props} />
    </FormsProvider>
  );
};

export default Forms;
