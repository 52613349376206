import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';
import ProductModal from './ProductModal';
import useApi from '../../hooks/useApi';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import ModLinksModal from './ModLinksModal';
import dayjs from 'dayjs';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import ButtonLight from '../ui/ButtonLight';
import PriceMatrixModal from './PriceMatrixModal';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const EditIconContainer = styled.div`
  color: ${props => props.theme.color.secondary};
  cursor: pointer;
  margin-left: 6px;
  padding: 0 ${props => (props.sizeUp ? '6px' : '4px')} 0 ${props => (props.sizeUp ? '1px' : '4px')};

  &:hover {
    background-color: #dddddd;
    border-radius: 3px;
  }

  i {
    margin-top: -2px;
  }

  svg {
    margin-top: -3px;
    height: ${props => (props.sizeUp ? '20px' : '16px')};
    width: ${props => (props.sizeUp ? '20px' : '16px')};
  }
`;

const IconContainer = styled.span`
  color: #4990dd;
  margin-left: 4px;

  i {
    width: 18px;
    height: 18px;
  }

  svg {
    margin-top: -2px;
  }
`;

const Table = styled.table`
  td {
    padding: 4px 12px 4px 1px;
  }
`;

const DynamicTable = styled.table`
  td {
    padding: 4px 4px 4px 1px;
  }
`;

const Fees = styled.div`
  display: flex;
  padding: 6px 4px;
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

const Fee = styled.div`
  font-weight: 700;
  padding-top: 1px;
  margin-right: 24px;
`;

const MatrixTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputName = styled.div`
  white-space: nowrap;
  display: flex;
`;

const ProductList = () => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { defaultCurrency } = useContext(NewInvoicingContext);

  const [apiCallPending, setApiCallPending] = useState(false);

  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productModalReadOnly, setProductModalReadOnly] = useState(false);
  const [modalProduct, setModalProduct] = useState(undefined);
  const [modLinksModalProduct, setModLinksModalProduct] = useState(null);
  const [modLinksModalReadOnly, setModLinksModalReadOnly] = useState(false);

  const [duplicateCurrent, setDuplicateCurrent] = useState(false);

  const [priceMatrix, setPriceMatrix] = useState(null);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'name',
    search: '',
  };

  const currency = defaultCurrency || 'EUR';

  const getParamsInRightForm = useCallback(parameters => {
    let correctParams = {
      query: { text: parameters.search },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'name',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi(
    'post',
    'invoicing/v2/product/search',
    getParamsInRightForm(newParams),
    null,
    false
  );

  let products = error || !data ? [] : data.results ? data.results.products : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [fetchData, getParamsInRightForm, newParams]);

  const createTimingTooltip = feeValues => {
    return feeValues.map((fee, i) => {
      return (
        <div key={i}>
          {!!fee.description && <Fee style={{ marginLeft: '4px', marginBottom: '-6px' }}>{fee.description}</Fee>}
          <Fees>
            <Fee>
              {fee.value} {currency}:
            </Fee>
            {fee.timing.type === 'always' && <Fee>{t('Always')}</Fee>}
            {fee.timing.type !== 'always' && (
              <Table>
                <thead>
                  <tr>
                    {fee.timing.type === 'schedule' && <th>{t('Day')}</th>}
                    <th>{t('From')}</th>
                    <th>{t('To')}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(fee.timing.values) &&
                    fee.timing.values.map((v, i) => (
                      <tr key={i}>
                        {fee.timing.type === 'schedule' && (
                          <td style={{ textTransform: 'capitalize' }}>
                            {v.day === 'public_holiday' ? t('Holidays') : t(v.day)}
                          </td>
                        )}
                        <td>
                          {fee.timing.type === 'range'
                            ? dayjs(v.from).format(TIME_FORMAT)
                            : fee.timing.type === 'range_without_year'
                              ? dayjs(dayjs().year() + '-' + v.from).format('DD.MM HH:mm')
                              : dayjs(v.from, 'HH:mm:ss').format('HH:mm')}
                        </td>
                        <td>
                          {fee.timing.type === 'range'
                            ? dayjs(v.to)
                              .add(1, 'second')
                              .format(TIME_FORMAT)
                            : fee.timing.type === 'range_without_year'
                              ? dayjs(dayjs().year() + '-' + v.to)
                                .add(1, 'second')
                                .format('DD.MM HH:mm')
                              : dayjs(v.to, 'HH:mm:ss')
                                .add(1, 'second')
                                .format('HH:mm')}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </Fees>
        </div>
      );
    });
  };

  const createDynamicTooltip = (list, units) => {
    let unitString = ' / ' + units[0].name;
    if (units[1]) {
      unitString += ' / ' + units[1].name;
    }
    return (
      <DynamicTable>
        <thead>
          <tr>
            <th colSpan="3">{t('Range')}</th>
            <th>{t('Price')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((l, i) => (
            <tr key={i}>
              <td>{l.limits.lower}</td>
              <td>-</td>
              <td style={{ paddingRight: '18px' }}>
                {l.limits.upper}
                {l.limits.upper ? ':' : ''}
              </td>
              <td>
                {l.price} {currency} {unitString}
              </td>
            </tr>
          ))}
        </tbody>
      </DynamicTable>
    );
  };

  const createModLinksTooltip = list => {
    return (
      <div style={{ padding: '6px' }}>
        {list.map((l, i) => (
          <div key={i}>{l.name}</div>
        ))}
      </div>
    );
  };

  const createMatrixTooltip = item => {
    let unitString = ' / ' + item.pricing.units[0].name;
    if (item.pricing.units[1]) {
      unitString += ' / ' + item.pricing.units[1].name;
    }
    return (
      <MatrixTooltip>
        <DynamicTable>
          <thead>
            <tr>
              <th colSpan="3">{t('Range')}</th>
              <th>{t('Prices')}</th>
            </tr>
          </thead>
          <tbody>
            {item.pricing.unit_prices.map((l, i) => {
              let priceString = '';
              l.timings.forEach((timing, i) => {
                if (i > 0) {
                  priceString += ' / ';
                }
                priceString += timing.price;
              });

              return (
                <tr key={i}>
                  <td>{l.limits.lower}</td>
                  <td>-</td>
                  <td style={{ paddingRight: '18px' }}>
                    {l.limits.upper}
                    {l.limits.upper ? ':' : ''}
                  </td>
                  <td>
                    {priceString}
                    <span
                      style={{
                        paddingRight: '10px',
                      }}
                    />
                    {currency} {unitString}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DynamicTable>

        <ButtonLight onClick={() => setPriceMatrix(item)} style={{ marginTop: '12px' }}>
          {t('Show price matrix')}
        </ButtonLight>
      </MatrixTooltip>
    );
  };

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Product name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
      render: (value, item) => (
        <Tooltip placement="top" title={item.description} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
          {value}
          <IconContainer>
            <Icon type="info" />
          </IconContainer>
        </Tooltip>
      ),
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      sortableKey: 'code',
    },
    {
      title: t('Unit price'),
      dataIndex: 'unit_price',
      key: 'unit_price',
      render: (price, item) => {
        if (item?.pricing?.type === 'simple') {
          let value = item.pricing.unit_price + ' ' + currency + ' / ';
          if (item.pricing.units[0]) {
            value += item.pricing.units[0].name;
          }
          if (item.pricing.units[1]) {
            value += ' / ' + item.pricing.units[1].name;
          }
          return <div style={{ textWrap: 'nowrap' }}>{value}</div>;
        } else if (item?.pricing?.type === 'dynamic') {
          return (
            <Tooltip
              placement="top"
              title={createDynamicTooltip(item.pricing.unit_prices, item.pricing.units)}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              <div style={{ display: 'flex' }}>
                {t('Dynamic')}
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              </div>
            </Tooltip>
          );
        } else if (item?.pricing?.type === 'dynamic_time_based') {
          return (
            <Tooltip
              placement="top"
              title={createMatrixTooltip(item)}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              <div style={{ display: 'flex' }}>
                {t('Dynamic time based')}
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              </div>
            </Tooltip>
          );
        }
        return '-';
      },
    },
    {
      title: t('Input(s)'),
      dataIndex: 'inputs',
      key: 'inputs',
      render: (price, item) => {
        const inputValue1 = item.pricing.units[0] ? item.pricing.units[0].description : '';
        const inputValue2 = item.pricing.units[1] ? item.pricing.units[1].description : '';

        const linkName1 = item.pricing.units[0]?.links?.[0] ? item.pricing.units[0]?.links?.[0].name : '';
        const linkName2 = item.pricing.units[1]?.links?.[0] ? item.pricing.units[1]?.links?.[0].name : '';

        return (
          <div>
            <InputName>
              <Tooltip
                placement="top"
                title={linkName1 ? t('Link') + ': ' + linkName1 : null}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <div style={{ display: 'flex', textWrap: 'nowrap' }}>
                  {inputValue1}
                  {!!linkName1 && (
                    <IconContainer>
                      <Icon type="info" />
                    </IconContainer>
                  )}
                </div>
              </Tooltip>
            </InputName>
            <InputName>
              {!!inputValue2 && (
                <Tooltip
                  placement="top"
                  title={linkName2 ? t('Link') + ': ' + linkName2 : null}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <div style={{ display: 'flex', textWrap: 'nowrap' }}>
                    {inputValue2}
                    {!!linkName2 && (
                      <IconContainer>
                        <Icon type="info" />
                      </IconContainer>
                    )}
                  </div>
                </Tooltip>
              )}
            </InputName>
          </div>
        );
      },
    },
    {
      title: t('Min'),
      dataIndex: 'min_fee',
      key: 'min_fee',
      render: (value, item) => {
        const values = item.min_fees;
        let valuesString = '';
        let asterisk = false;
        if (values.length === 0) {
          valuesString = '-';
        } else if (values.length === 1) {
          if (values[0].timing.type === 'always') {
            valuesString = values[0].value;
          } else {
            valuesString = values[0].value;
            asterisk = true;
          }
        } else if (values.length === 2) {
          valuesString = values[0].value + ' / ' + values[1].value;
          asterisk = true;
        } else {
          valuesString = t('Multiple');
          asterisk = true;
        }

        return (
          <Tooltip
            placement="top"
            title={asterisk ? createTimingTooltip(values) : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
          >
            <div style={{ display: 'flex', textWrap: 'nowrap' }}>
              {valuesString} {valuesString !== '-' ? currency : ''}
              {asterisk && (
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('Max'),
      dataIndex: 'max_fee',
      key: 'max_fee',
      render: (value, item) => {
        const values = item.max_fees;
        let valuesString = '';
        let asterisk = false;
        if (values.length === 0) {
          valuesString = '-';
        } else if (values.length === 1) {
          if (values[0].timing.type === 'always') {
            valuesString = values[0].value;
          } else {
            valuesString = values[0].value;
            asterisk = true;
          }
        } else if (values.length === 2) {
          valuesString = values[0].value + ' / ' + values[1].value;
          asterisk = true;
        } else {
          valuesString = t('Multiple');
          asterisk = true;
        }

        return (
          <Tooltip
            placement="top"
            title={asterisk ? createTimingTooltip(values) : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
          >
            <div style={{ display: 'flex', textWrap: 'nowrap' }}>
              {valuesString} {valuesString !== '-' ? currency : ''}
              {asterisk && (
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('VAT %'),
      dataIndex: 'vat',
      key: 'vat',
      render: value => <div style={{ textWrap: 'nowrap' }}>{value} %</div>,
    },
    {
      title: t('Link'),
      dataIndex: 'link',
      key: 'link',
      render: (value, record) => <div>{record.links.find(l => l.parameter === 'product_at')?.name || '-'}</div>,
    },
    {
      title: t('Modification links'),
      dataIndex: 'modifiers',
      key: 'modifiers',
      render: (value, item) => (
        <div style={{ display: 'flex' }}>
          <Tooltip
            placement="top"
            title={value?.length ? createModLinksTooltip(value) : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
          >
            {value?.length || 0}
            {!!value?.length && (
              <IconContainer style={{ marginLeft: '6px' }}>
                <Icon type="info" />
              </IconContainer>
            )}
          </Tooltip>
          {user.permissions.includes('manage_invoicing') ? (
            <EditIconContainer onClick={() => setModLinksModalProduct(item)} sizeUp={value?.length === 0}>
              <Icon type={value?.length ? 'edit' : 'plus'} />
            </EditIconContainer>
          ) : (
            <EditIconContainer
              onClick={() => {
                setModLinksModalReadOnly(true);
                setModLinksModalProduct(item);
              }}
            >
              <Icon type="view" />
            </EditIconContainer>
          )}
        </div>
      ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/product/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, getParamsInRightForm(newParams));
  };

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalProduct(record);
                setProductModalOpen(true);
              }}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setDuplicateCurrent(true);
                setModalProduct(record);
                setProductModalOpen(true);
              }}
            >
              <Icon type="duplicate" />
              {t('Duplicate')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <Popconfirm
              title={t('Are you sure you want to remove the product?')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-new-list"
              key="action-5"
            >
              <div>
                <ListActionButton red title={t('Delete')}>
                  <Icon type="trash" />
                  {t('Delete')}
                </ListActionButton>
              </div>
            </Popconfirm>
          ),
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalProduct(record);
                setProductModalReadOnly(true);
                setProductModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          onClick: () => {
            setModalProduct(undefined);
            setProductModalOpen(true);
          },
          text: t('Create new product'),
          icon: 'plus',
        },
      ]
    : [];

  const closeProductModal = () => {
    setModalProduct(undefined);
    setProductModalOpen(false);
    setProductModalReadOnly(false);
    setDuplicateCurrent(false);
  };

  const closeProductModalAndReload = () => {
    closeProductModal();
    fetchData(false, getParamsInRightForm(newParams));
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={products}
        apiCallPending={apiCallPending}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        actions={actionList}
        noUrlUpdate={true}
      />

      {productModalOpen && (
        <ProductModal
          product={modalProduct}
          closeModal={closeProductModal}
          closeAndReload={closeProductModalAndReload}
          readOnly={productModalReadOnly}
          duplicateCurrent={duplicateCurrent}
        />
      )}

      {!!modLinksModalProduct && (
        <ModLinksModal
          closeModal={() => {
            setModLinksModalReadOnly(false);
            setModLinksModalProduct(null);
          }}
          product={modLinksModalProduct}
          closeAndReload={() => {
            setModLinksModalReadOnly(false);
            setModLinksModalProduct(null);
            fetchData(false, getParamsInRightForm(newParams));
          }}
          readOnly={modLinksModalReadOnly}
        />
      )}

      {!!priceMatrix && <PriceMatrixModal product={priceMatrix} closeModal={() => setPriceMatrix(null)} />}
    </Container>
  );
};

export default ProductList;
