import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MapContainer as Map } from 'react-leaflet';
import App from 'antd/es/app';
import Radio from 'antd/es/radio';
import Checkbox from 'antd/es/checkbox';
import { useHistory } from 'react-router-dom';
import L from 'leaflet';
import { FilterOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/UserContext';
import {
  PAGINATION_LIMIT,
  TIME_FORMAT,
  TIME_FORMAT_DAY,
  TIME_FORMAT_HOURS_MINUTES,
  mobilePixelMaxWidthLimit,
} from '../../utils/constants';
import List from '../ui/List';
import Icon from '../ui/Icon';
import IncidentStatistics from './IncidentStatistics';
import IncidentsMapInnerContainer from './IncidentsMapInnerContainer';
import AddIncidentModal from './AddIncidentModal';
import { optionLists } from './incidentWizardData';
import useApi from '../../hooks/useApi';
import { AlertContext } from '../../context/AlertContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';
import dayjs from 'dayjs';

const Container = styled.div`
  display: flex;
`;

const NoIncidentData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const Structure = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const FirstPart = styled.div`
  width: calc(100% - (100vh - 168px) / 2);
  padding: 12px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  margin-right: 18px;
  height: calc(100vh - 168px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow-y: auto;

  @media (min-width: 801px) and (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 154px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
    height: unset;
    overflow-y: unset;
  }

  table {
    padding-bottom: 30px !important;
  }

  tr {
    cursor: pointer;
  }

  .ant-pagination {
    margin-top: 0;
  }

  .additional-buttons {
    justify-content: space-between;
    flex-direction: row;
    flex: 1;
  }

  .ant-table-expanded-row {
    cursor: default;
  }
`;

const SecondPart = styled.div`
  width: calc((100vh - 168px) / 2);
  height: calc(100vh - 168px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 154px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 18px;
  }
`;

const FirstColumn = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-right: 18px;
  align-self: center;
  height: 22px;
  margin-left: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const UnreadIndicator = styled.div`
  background-color: #d0011c;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
  top: -15px;
  right: 20px;
`;

const MapIconContainer = styled.div`
  margin-top: -2px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const FiltersBtnContainer = styled.div`
  display: flex;
  position: relative;
`;

const FiltersBtn = styled.div`
  align-items: center;
  display: flex;
  color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_dark)};
  padding: 3px 6px 4px 6px;
  border-radius: 3px;
  background-color: ${props => (props.dropDownOpen ? '#f3f3f3' : 'transparent')};
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  font-size: ${({ theme }) => theme.text.medium};
`;

const MapContainer = styled.div`
  height: 50%;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  background-color: white;

  ${props => {
    if (props.enlarged) {
      return `
        height: calc(100% - 130px);
        width: calc(100% - 80px);
        position: fixed;
        top: 130px;
        left: 80px;
        box-shadow: 0px 0px 15px lightgrey;
        z-index: 999;
      `;
    }
  }}
`;

const Rows = styled.div`
  margin-left: 8px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EnlargeButton = styled.div`
  cursor: pointer;
  margin: 12px;

  svg {
    height: 15px;
    width: 15px;
    margin-left: -6px;
    margin-top: -3px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20vw;
`;

const PlaceAndTime = styled.div`
  display: flex;
`;

const Location = styled.div`
  color: #9d9d9d;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const Level = styled.div`
  color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  font-weight: 600;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
  padding: 12px;
`;

const statisticsData = {
  id: 'incident-types',
  type: 'bar-1-column-without-y-axel',
  header: 'Incident types',
  time_options: ['week', 'month', 'year'],
  legend: [
    {
      title: 'Collision',
      color: '#FFAB91',
      key: 'value1',
    },
    {
      title: 'Fire explosion',
      color: '#A5D6A7',
      key: 'value2',
    },
    {
      title: 'Machinery damage',
      color: '#efb3d5',
      key: 'value3',
    },
    {
      title: 'Grounding',
      color: '#FBE6A2',
      key: 'value4',
    },
    {
      title: 'Pollution',
      color: '#A7C4F4',
      key: 'value5',
    },
    {
      title: 'Other',
      color: '#D9D9D9',
      key: 'value6',
    },
  ],
};

const FilterModal = styled.div`
  position: absolute;
  padding: 24px;
  top: 38px;
  left: 4px;
  z-index: 999;
  background-color: white;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  min-width: 200px;
`;

const ModalHeader = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const ModalSmallHeader = styled.div`
  margin-top: 18px;
  margin-bottom: 4px;
`;

const CheckboxContainer = styled.div`
  display: flex;
`;

const EventIcon = styled.div`
  border-radius: 50%;
  height: 22px;
  width: 22px;
  background-color: ${props => props.theme.color.secondary};
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-right: -8px;
`;

const Incidents = () => {
  const { apiCall, namespace, user, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { alerts, alertsHeight } = useContext(AlertContext);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [incidentsData, setIncidentsData] = useState(null);
  const [incidentsError, setIncidentsError] = useState(null);
  const [incidentsLoading, setIncidentsLoading] = useState(null);

  const [appliedZoom, setAppliedZoom] = useState(5);

  const [clickedIncident, setClickedIncident] = useState(undefined);
  const [mapEnlarged, setMapEnlarged] = useState(false);

  const [incidentModalOpen, setIncidentModalOpen] = useState(false);

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [status, setStatus] = useState('open');
  const [showIncidents, setShowIncidents] = useState(true);
  const [showEvents, setShowEvents] = useState(true);

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'date DESC',
    search: params.get('search') ? params.get('search') : '',
    status: status,
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#filter-modal');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setFilterModalOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const incidentsChanged = useCallback(data => {
    if (data?.results) {
      setIncidentsData(data.results);
    } else {
      setIncidentsData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: incidentsFetchLoading, error: incidentsFetchError, fetchData: incidentsFetchData } = useApi(
    'get',
    'incidents',
    newParams,
    incidentsChanged,
    false
  );

  const { loading: incidentsSearchLoading, error: incidentsSearchError, fetchData: incidentsFetchSearchData } = useApi(
    'post',
    'incident/search',
    {},
    incidentsChanged,
    false
  );

  const fetchData = useCallback(
    params => {
      const { search, ...rest } = params;
      if (search) {
        const [order_by_field, order_by_order] = params.sort.split(' ');
        incidentsFetchSearchData(false, {
          query: {
            text: params.search,
            conditions: {
              and: [
                {
                  type: 'status',
                  operator: 'is',
                  value: params.status,
                },
              ],
            },
          },
          pagination: {
            limit: params.limit,
            offset: params.offset,
          },
          order_by: [
            {
              field: order_by_field,
              order: order_by_order ? order_by_order : 'DESC',
            },
          ],
        });
      } else {
        incidentsFetchData(false, rest);
      }
    },
    [incidentsFetchData, incidentsFetchSearchData]
  );

  useEffect(() => {
    fetchData(newParams);
  }, [fetchData, newParams]);

  useEffect(() => {
    setIncidentsError(incidentsFetchError);
  }, [incidentsFetchError]);

  useEffect(() => {
    setIncidentsError(incidentsSearchError);
  }, [incidentsSearchError]);

  useEffect(() => {
    setIncidentsLoading(incidentsFetchLoading);
  }, [incidentsFetchLoading]);

  useEffect(() => {
    setIncidentsLoading(incidentsSearchLoading);
  }, [incidentsSearchLoading]);

  useEffect(() => {
    setNewParams(previous => {
      return {
        ...previous,
        status,
      };
    });
  }, [status]);

  useEffect(() => {
    if (showEvents && !showIncidents) {
      setNewParams(previous => {
        return {
          ...previous,
          type: 'event',
        };
      });
    } else if (!showEvents && showIncidents) {
      setNewParams(previous => {
        return {
          ...previous,
          type: 'incident',
        };
      });
    } else {
      setNewParams(previous => {
        const next = { ...previous };
        delete next.type;
        return {
          ...next,
        };
      });
    }
  }, [showEvents, showIncidents]);

  if (incidentsError) {
    message.error(incidentsError, 4);
  }

  const addData = async data => {
    setApiCallPending(true);
    try {
      const result = await apiCall('post', 'incident', {
        incident: {
          ...data,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
        return false;
      }
    } catch (e) {
      setApiCallPending(false);
      return false;
    }
    setApiCallPending(false);
    fetchData(newParams);
    return true;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incidents = incidentsLoading || !incidentsData?.['incidents'] ? [] : incidentsData['incidents'];

  const { offset, total } = incidentsError || !incidentsData ? { offset: 0, total: 0 } : incidentsData.pagination;

  useUserSocket('incidents-changed', () => fetchData(newParams));
  useUserSocket(`incidents-changed-${user?.id}`, () => fetchData(newParams));

  const createStatData = data => {
    return {
      value1: data.filter(d => d.incident_type_key === 'collision').length,
      value2: data.filter(d => d.incident_type_key === 'fire_explosion').length,
      value3: data.filter(d => d.incident_type_key === 'machinery_damage').length,
      value4: data.filter(d => d.incident_type_key === 'grounding_stranding').length,
      value5: data.filter(d => d.incident_type_key === 'pollution').length,
      value6: data.filter(d => d.incident_type_key === 'other').length,
    };
  };

  const columns = [
    {
      title: t('Description'),
      dataIndex: 'title',
      key: 'title',
      render: (record, item) => {
        return item.type === 'event' ? (
          <FirstColumn>
            <IconContainer>
              <EventIcon>E</EventIcon>
              {!item?.read ? <UnreadIndicator /> : null}
            </IconContainer>
            <Rows>
              <TitleContainer>
                <Title>{item.details}</Title>
              </TitleContainer>
              <PlaceAndTime>
                {item.date ? dayjs(item.date).format(TIME_FORMAT_DAY) : ''}
                <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                  {item.date ? dayjs(item.date).format(TIME_FORMAT_HOURS_MINUTES) : '-'}
                </span>{' '}
                <span>
                  {item.end_date && (
                    <>
                      <span style={{ marginLeft: '4px', marginRight: '4px' }}>-</span>
                      {dayjs(item.end_date).format(TIME_FORMAT_DAY)}
                      <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                        {dayjs(item.end_date).format(TIME_FORMAT_HOURS_MINUTES)}
                      </span>
                    </>
                  )}
                </span>
              </PlaceAndTime>
              <Location>{item.location_option_name || '-'}</Location>
            </Rows>
          </FirstColumn>
        ) : (
          <FirstColumn>
            <IconContainer>
              <Icon
                type="exclamation-triangle"
                fill={
                  item.incident_type_key
                    ? optionLists.incident_type.find(t => t.value === item.incident_type_key)?.color
                    : '#4a4a4a'
                }
              />
              {!item?.read ? <UnreadIndicator /> : null}
            </IconContainer>
            <Rows>
              <TitleContainer>
                <Title>{item.incident_type_name || '-'}</Title>
              </TitleContainer>
              <PlaceAndTime>
                {item.date ? dayjs(item.date).format(TIME_FORMAT_DAY) : ''}
                <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                  {item.date ? dayjs(item.date).format(TIME_FORMAT_HOURS_MINUTES) : '-'}
                </span>
              </PlaceAndTime>
              <Location>{item.location_option_name || '-'}</Location>
            </Rows>
          </FirstColumn>
        );
      },
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: record => {
        return record.charAt(0).toUpperCase() + record.toLowerCase().slice(1);
      },
    },
    {
      title: t('People in danger'),
      dataIndex: 'people_in_danger',
      key: 'people_in_danger',
      width: 100,
      render: record => {
        return record !== undefined ? optionLists.people_in_danger.find(t => t.value === record)?.label : '-';
      },
    },
    {
      title: t('Ships involved'),
      dataIndex: 'vessels_involved',
      key: 'vessels_involved',
      width: 100,
      render: record => {
        return record !== undefined ? optionLists.people_in_danger.find(t => t.value === record)?.label : '-';
      },
    },
    {
      title: t('Level of emergency'),
      dataIndex: 'level_of_emergency',
      key: 'level_of_emergency',
      render: record => {
        if (!record) {
          return '-';
        }
        return (
          <Level level={record}>
            {t('Level')} {record} ({record > 7 ? t('Major') : record < 4 ? t('Minor') : t('Medium')})
          </Level>
        );
      },
    },
    {
      title: t('Close automatically'),
      dataIndex: 'auto_close_ts',
      key: 'auto_close_ts',
      render: record => {
        return record ? dayjs(record).format(TIME_FORMAT) : t('No');
      },
    },
    {
      title: t('Map'),
      dataIndex: 'id',
      key: 'id',
      render: (record, item) => {
        return item?.data?.geojson?.length ? (
          <MapIconContainer
            onClick={event => {
              event.stopPropagation();
              setClickedIncident(record);
            }}
          >
            <Icon type="marker" />
          </MapIconContainer>
        ) : null;
      },
    },
  ];

  if (namespace === 'vts') {
    columns.splice(1, 0, {
      title: t('Port name'),
      dataIndex: 'port_name',
      key: 'port_name',
    });
  } else {
    columns.splice(3, 0, {
      title: t('Visible to Authority'),
      dataIndex: 'visible_to_authority',
      key: 'visible_to_authority',
      width: 100,
      render: record => {
        return record ? <Icon type="check-light" /> : null;
      },
    });
    columns.splice(4, 0, {
      title: t('Banner'),
      key: 'banner',
      width: 100,
      render: (record, item) => {
        const currentAlert = alerts.find(alert => alert.type === item.type && alert.incident?.id === record.id);
        return currentAlert ? <Icon type="check-light" /> : null;
      },
    });
  }

  const additionalButtons = [
    {
      render: index => (
        <FiltersBtnContainer key={index}>
          <FiltersBtn link onClick={() => setFilterModalOpen(true)}>
            <FilterIconContainer>
              <FilterOutlined />
            </FilterIconContainer>
            {t('Filters')}
          </FiltersBtn>

          {filterModalOpen && (
            <FilterModal id="filter-modal" ref={ref}>
              <ModalHeader>{t('Filters')}</ModalHeader>
              <ModalSmallHeader>{t('Status')}</ModalSmallHeader>
              <Radio.Group onChange={e => setStatus(e.target.value)} value={status}>
                <Radio value="open">{t('Open')}</Radio>
                <Radio value="closed">{t('Closed')}</Radio>
              </Radio.Group>
              <ModalSmallHeader>{t('Type')}</ModalSmallHeader>
              <CheckboxContainer>
                <Checkbox size="small" checked={showIncidents} onChange={e => setShowIncidents(e.target.checked)}>
                  {t('Incidents')}
                </Checkbox>
                <Checkbox size="small" checked={showEvents} onChange={e => setShowEvents(e.target.checked)}>
                  {t('Events')}
                </Checkbox>
              </CheckboxContainer>
            </FilterModal>
          )}
        </FiltersBtnContainer>
      ),
    },
    {
      onClick: () => setIncidentModalOpen(true),
      text: t('Announce incident or event'),
      blue: true,
      disabled: !user.permissions.includes('manage_incident'),
    },
  ];

  if (namespace === 'vts') {
    const index = additionalButtons.findIndex(a => a.text === t('Announce incident or event'));
    additionalButtons.splice(index, 1);
  }

  return (
    <Container>
      {incidents ? (
        <Structure>
          <FirstPart alertsHeight={alertsHeight}>
            <List
              rowKey="id"
              columns={columns}
              dataSource={incidents}
              apiCallPending={apiCallPending}
              //actions={actionList}
              spinning={incidentsLoading}
              setParams={setNewParams}
              newParams={newParams}
              start={offset}
              total={total}
              searchPlaceHolder={t('Search')}
              additionalButtons={additionalButtons}
              wrapHeaderText={true}
              transparentBgs
              noUrlUpdate={true}
              onRow={record => ({
                style: {
                  backgroundColor: !record.read ? '#F0F0F0' : 'default',
                },
                onClick: () => {
                  history.push('/incident-and-event/' + record.id);
                },
              })}
            />
          </FirstPart>
          <SecondPart alertsHeight={alertsHeight}>
            <IncidentStatistics
              {...{ ...statisticsData, data: createStatData(incidents) }}
              yAxel={false}
              loading={incidentsLoading}
            />
            <MapContainer enlarged={mapEnlarged}>
              <TopRow>
                <Header>{t('Incident and event map')}</Header>
                <EnlargeButton onClick={() => setMapEnlarged(!mapEnlarged)}>
                  {!mapEnlarged ? <Icon type="expand" /> : <Icon type="close" />}
                  {!mapEnlarged ? t('See full view') : ''}
                </EnlargeButton>
              </TopRow>
              <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small', t)} onError={logError}>
                <Map
                  style={{
                    height: 'calc(100% - 52px)',
                    width: 'calc(100% - 8px)',
                    marginLeft: '4px',
                  }}
                  center={'62.57, 26.00'.split(',')}
                  zoom={appliedZoom}
                  tap={false}
                  zoomControl={false}
                  renderer={L.canvas({ padding: 0.5 })}
                >
                  <IncidentsMapInnerContainer
                    zoom={appliedZoom}
                    setZoom={setAppliedZoom}
                    incidentData={incidents}
                    setClickedIncident={setClickedIncident}
                    clickedIncident={clickedIncident}
                    optionLists={optionLists}
                    mapEnlarged={mapEnlarged}
                  />
                </Map>
              </ErrorBoundary>
            </MapContainer>
          </SecondPart>
        </Structure>
      ) : (
        <NoIncidentData>{t('No incident data available.')}</NoIncidentData>
      )}

      {incidentModalOpen && (
        <AddIncidentModal
          closeModal={() => setIncidentModalOpen(false)}
          addData={addData}
          sendDisabled={apiCallPending}
        />
      )}
    </Container>
  );
};
export default Incidents;
