import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import { getWindType } from './weatherUtils';
import WeatherText from '../ui/WeatherText';
import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';

const DateContainer = styled.div`
  font-size: 14px;
`;

const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px;
  min-height: 72px;
  margin: 0 0;
  width: 220px;

  @media (max-width: 440px) {
    width: 180px;
  }
`;

const WeatherDetails = styled.div`
  width: 33.33%;
`;

const Wind = styled.div`
  display: flex;
  padding: 4px 0;
  font-size: 12px;

  svg {
    width: 18px;
    margin-top: -3px;
    margin-left: 6px;
  }
`;

const WindInfo = styled.div`
  padding-left: 18px;
  font-weight: 600;
`;

const TempDegree = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const WeatherSymbol = styled.div`
  font-size: 12px;
  width: 40%;

  svg {
    width: 44px;
    height: 44px;
    margin-top: -4px;
    margin-left: -17px;
  }
`;

const RowContainer = styled.div`
  text-align: center;

  label {
    white-space: normal;
  }

  @media (max-width: 440px) {
    margin-top: 10px;
  }
`;

const RowContainer2 = styled.div`
  text-align: center;

  label {
    white-space: normal;
  }

  @media (max-width: 440px) {
    display: none;
  }
`;

const DayWeatherCard = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <CardDetails>
      <WeatherDetails>
        <DateContainer> {dayjs(data.time).format('ddd DD, MMM')}</DateContainer>
        <TempDegree>{data.temperature || data.temperature === 0 ? Math.round(data.temperature) : '-'} °C</TempDegree>
        <Wind>
          {t('Wind')}{' '}
          <WeatherIcon
            type={data.wind_speed || data.wind_speed === 0 ? getWindType(data.wind_speed) : null}
            rotation={data.wind_direction || 0}
          />
          <WindInfo>{data.wind_speed || data.wind_speed === 0 ? Math.round(data.wind_speed) : '-'} m/s</WindInfo>
        </Wind>
      </WeatherDetails>
      <WeatherSymbol>
        <RowContainer style={{ height: '40px' }}>
          <WeatherIconCombined type={data.description} descriptionObj={data.description_object} />
        </RowContainer>
        <RowContainer2>
          <WeatherText
            style={{ fontSize: data.description && data.description.length > 20 ? '10px' : '12px' }}
            type={data.description}
            descriptionText={data.description_object ? data.description_object.description_text : null}
          />
        </RowContainer2>
      </WeatherSymbol>
    </CardDetails>
  );
};

export default DayWeatherCard;
