import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Popconfirm from 'antd/es/popconfirm';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';

import Heading from '../ui/Heading';
import ButtonLight from '../ui/ButtonLight';
import { FORMS_URL, FormsContext } from '../../context/FormContext';
import './RegisterPortcallForm.css';
import { isEmailValid } from '../../utils/utils';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 30px 30px 30px;
`;

const TitleText = styled(Heading)`
  color: #000;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SubTitleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 2rem;
  text-align: center;
`;

const Legend = styled.legend``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  padding-bottom: 4px;
  width: 100%;
`;

const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-right: 32px;
  width: 50%;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 50%;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  margin-bottom: 0.25rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  button {
    margin-bottom: 0px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Required = styled.span`
  color: red;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyleInput = styled(Input)`
  padding: ${({ theme }) => theme.sizing.gap_small};
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    height: auto;
  }
  border: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  padding: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.grey_dark};
  background: ${({ theme }) => theme.color.white};
  min-width: 100px;
  min-height: 2rem;
  max-width: 100%;
  &:disabled {
    border: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(73, 144, 221, 0.2);
    border: 1px solid rgba(73, 144, 221, 0.7);
  }
  &:focus-visible {
    outline: none;
  }
  ::placeholder {
    color: #bfbfbf;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: auto !important;
    padding: 3px 8px !important;
  }
`;

const SEND_BUTTON_TEXT = 'Send registration request';

const RegisterPortcallForm = () => {
  const { apiCall, apiCallPending, formParams, formType, namespace, setFormToken, updateFormNotification } = useContext(
    FormsContext
  );

  const { t } = useTranslation(namespace);

  const sendButtonText = t(SEND_BUTTON_TEXT);
  const initPortcall = {
    imo: '',
    confirm_imo: '',
    vessel_name: '',
    loa: null,
    beam: null,
    draft: null,
    eta: null,
    etd: null,
    primary_berth: '',
    emails: formParams?.user_email || '',
    net_tonnage: null,
    to_port: '',
  };

  const [portcall, setPortcall] = useState(initPortcall);
  const [formData, setFormData] = useState(null);
  const [formDataFetched, setFormDataFetched] = useState(false);

  const fetchFormData = useCallback(async () => {
    const result = await apiCall('get', FORMS_URL);
    if (result?.status === 200) {
      if (formType === 'register-port-call') {
        setFormData(result.data?.['port-call-fields']);
        if (result.data?.['port-call-fields'].locodes?.length === 1) {
          setPortcall(previous => ({
            ...previous,
            to_port: result.data?.['port-call-fields'].locodes?.[0]?.value || null,
          }));
        }
      } else {
        setFormDataFetched(false);
      }
    }
  }, [apiCall, formType]);

  useEffect(() => {
    if (!formDataFetched) {
      fetchFormData();
      setFormDataFetched(true);
    }
  }, [fetchFormData, formDataFetched, formType]);

  const handleInputChange = (name, value) => {
    setPortcall(previous => ({ ...previous, [name]: value }));
  };

  const handleRegisterPortcall = async () => {
    let toPort = portcall.to_port;
    if (!toPort && formData.locodes.length === 1) {
      toPort = formData.locodes[0].value;
    }
    const result = await apiCall('post', FORMS_URL, {
      type: 'register-port-call',
      data: {
        ...(portcall.imo ? { imo: portcall.imo } : {}),
        ...(portcall.vessel_name ? { vessel_name: portcall.vessel_name } : {}),
        ...(portcall.loa ? { loa: portcall.loa } : {}),
        ...(portcall.beam ? { beam: portcall.beam } : {}),
        ...(portcall.draft ? { draft: portcall.draft } : {}),
        ...(portcall.eta ? { eta: dayjs(portcall.eta).toISOString() } : {}),
        ...(portcall.etd ? { etd: dayjs(portcall.etd).toISOString() } : {}),
        ...(portcall.emails ? { emails: portcall.emails } : {}),
        ...(portcall.primary_berth ? { primary_berth: portcall.primary_berth } : {}),
        ...(portcall.net_tonnage ? { net_tonnage: portcall.net_tonnage } : {}),
        ...(toPort ? { to_port: toPort } : {}),
      },
    });
    if (result?.status === 200) {
      updateFormNotification(
        t(
          'Port call registered successfully. You will get notification to the email(s) you entered after request has been processed'
        )
      );
      setFormToken(result.data.token);
    }
  };

  const isValid = () => {
    // TODO: validate imo
    let isValid = true;
    if (formData?.required_fields?.includes?.('emails')) {
      const emailsArray = portcall.emails.replaceAll(' ', '').split(',');
      isValid = portcall.emails.length && emailsArray.every(isEmailValid);
    }
    if (isValid && formData?.required_fields?.includes?.('imo')) {
      isValid = portcall.imo && portcall.confirm_imo && portcall.imo === portcall.confirm_imo;
    }
    if (isValid && formData?.required_fields?.includes?.('vessel_name')) {
      isValid = portcall.vessel_name.length > 0;
    }
    if (isValid && formData?.required_fields?.includes?.('loa')) {
      isValid = portcall.loa !== null;
    }
    if (isValid && formData?.required_fields?.includes?.('beam')) {
      isValid = portcall.beam !== null;
    }
    if (isValid && formData?.required_fields?.includes?.('draft')) {
      isValid = portcall.draft !== null;
    }
    if (isValid && formData?.required_fields?.includes?.('eta')) {
      isValid = !!(portcall.eta && dayjs(portcall.eta).isValid());
    }
    if (isValid && formData?.required_fields?.includes?.('etd')) {
      isValid = !!(portcall.etd && dayjs(portcall.etd).isValid());
    }
    if (isValid && formData?.required_fields?.includes?.('primary_berth')) {
      isValid = portcall.primary_berth?.length > 0;
    }
    if (isValid && formData?.required_fields?.includes?.('net_tonnage')) {
      isValid = portcall.net_tonnage !== null;
    }
    if (isValid && formData?.required_fields?.includes?.('to_port')) {
      isValid = portcall.to_port.length > 0;
    }

    return isValid;
  };

  return (
    <Spin spinning={apiCallPending} wrapperClassName="spinner">
      <MainContainer>
        <TitleText h3>{t('Register port call')}</TitleText>
        <SubTitleText>
          {t(
            'Please fill this form and press "{{buttonText}}". You will receive an e-mail to the address(es) given below after the request has been processed.',
            { buttonText: sendButtonText }
          )}
        </SubTitleText>
        <Section>
          <RowContainer>
            <InputWrapper style={{ width: '100%' }}>
              <InputLabel>
                {t('E-mail(s). Comma to separate addresses')}
                {formData?.required_fields?.includes?.('emails') ? <Required>*</Required> : null}
              </InputLabel>
              <StyleInput
                name="emails"
                placeholder={t('E-mail(s). Comma to separate addresses.')}
                value={portcall.emails}
                onChange={({ target }) => handleInputChange(target.name, target.value.replaceAll(' ', ''))}
                style={{ minWidth: '100px', width: '100%' }}
              />
            </InputWrapper>
          </RowContainer>
        </Section>
        <Section>
          <Legend>{t('Vessel info')}</Legend>
          <RowContainer>
            <Column1>
              <InputWrapper>
                <InputLabel>
                  {t('IMO')}
                  {formData?.required_fields?.includes?.('imo') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="imo"
                  placeholder={t('IMO')}
                  value={portcall.imo}
                  onChange={value => handleInputChange('imo', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                  precision={0}
                />
              </InputWrapper>
            </Column1>
            <Column2>
              <InputWrapper>
                <InputLabel>
                  {t('Confirm IMO')}
                  {formData?.required_fields?.includes?.('imo') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="confirm_imo"
                  placeholder={t('Confirm IMO')}
                  value={portcall.confirm_imo}
                  onChange={value => handleInputChange('confirm_imo', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column2>
          </RowContainer>
          <RowContainer>
            <Column1>
              <InputWrapper>
                <InputLabel>
                  {t('Vessel name')}
                  {formData?.required_fields?.includes?.('vessel_name') ? <Required>*</Required> : null}
                </InputLabel>
                <StyleInput
                  name="vessel_name"
                  placeholder={t('Vessel name')}
                  value={portcall.vessel_name}
                  onChange={({ target }) => handleInputChange(target.name, target.value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column1>
            <Column2>
              <InputWrapper>
                <InputLabel>
                  {t('LOA')}
                  {formData?.required_fields?.includes?.('loa') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="loa"
                  placeholder={t('LOA')}
                  value={portcall.loa}
                  onChange={value => handleInputChange('loa', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column2>
          </RowContainer>
          <RowContainer>
            <Column1>
              <InputWrapper>
                <InputLabel>
                  {t('Beam')}
                  {formData?.required_fields?.includes?.('beam') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="beam"
                  placeholder={t('Beam')}
                  value={portcall.beam}
                  onChange={value => handleInputChange('beam', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column1>
            <Column2>
              <InputWrapper>
                <InputLabel>
                  {t('Draft')}
                  {formData?.required_fields?.includes?.('draft') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="draft"
                  placeholder={t('Draft')}
                  value={portcall.draft}
                  onChange={value => handleInputChange('draft', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column2>
          </RowContainer>
          <RowContainer>
            <Column1>
              <InputWrapper>
                <InputLabel>
                  {t('Net tonnage')}
                  {formData?.required_fields?.includes?.('net_tonnage') ? <Required>*</Required> : null}
                </InputLabel>
                <StyledInputNumber
                  name="net_tonnage"
                  placeholder={t('Net tonnage')}
                  value={portcall.net_tonnage}
                  onChange={value => handleInputChange('net_tonnage', value)}
                  style={{ minWidth: '100px', width: '100%' }}
                />
              </InputWrapper>
            </Column1>
          </RowContainer>
        </Section>
        <Section>
          <Legend>{t('Timestamps')}</Legend>
          <RowContainer>
            <Column1>
              <InputContainer>
                <InputLabel>
                  {t('ETA in local time ({{ name }})', { name: formParams.port_name })}
                  {formData?.required_fields?.includes?.('eta') ? <Required>*</Required> : null}
                </InputLabel>
                <DatePicker
                  name="eta"
                  format="DD.MM.YYYY HH:mm"
                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                  style={{
                    color: '#4a4a4a',
                    marginRight: '8px',
                    padding: '0.5rem',
                    display: 'flex',
                    width: '96%',
                  }}
                  value={portcall.eta ? dayjs(portcall.eta) : null}
                  onChange={value => handleInputChange('eta', value ? dayjs(value).toISOString() : null)}
                  placeholder={t('ETA')}
                />
              </InputContainer>
            </Column1>
          </RowContainer>
          <RowContainer>
            <Column1>
              <InputContainer>
                <InputLabel>
                  {t('ETD in local time ({{ name }})', { name: formParams.port_name })}
                  {formData?.required_fields?.includes?.('etd') ? <Required>*</Required> : null}
                </InputLabel>
                <DatePicker
                  name="etd"
                  format="DD.MM.YYYY HH:mm"
                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                  style={{
                    color: '#4a4a4a',
                    marginRight: '8px',
                    padding: '0.5rem',
                    display: 'flex',
                    width: '96%',
                  }}
                  value={portcall.etd ? dayjs(portcall.etd) : null}
                  onChange={value => handleInputChange('etd', value ? dayjs(value).toISOString() : null)}
                  placeholder={t('ETD')}
                />
              </InputContainer>
            </Column1>
          </RowContainer>
          {formData?.berths?.length ? (
            <RowContainer>
              <Column1>
                <InputWrapper>
                  <InputLabel>
                    {t('Primary berth')}
                    {formData?.required_fields?.includes?.('primary_berth') ? <Required>*</Required> : null}
                  </InputLabel>
                  <StyledSelect
                    allowClear
                    placeholder={t('Not selected')}
                    optionFilterProp="label"
                    onChange={value => handleInputChange('primary_berth', value)}
                    style={{ minWidth: '100px', width: '96%' }}
                    options={formData.berths}
                    value={portcall.primary_berth}
                  />
                </InputWrapper>
              </Column1>
              {formData.locodes.length > 1 ? (
                <Column2>
                  <InputWrapper>
                    <InputLabel>
                      {t('Port locode')}
                      {formData?.required_fields?.includes?.('to_port') ? <Required>*</Required> : null}
                    </InputLabel>
                    <StyledSelect
                      allowClear
                      placeholder={t('Not selected')}
                      optionFilterProp="label"
                      onChange={value => handleInputChange('to_port', value)}
                      style={{ minWidth: '100px', width: '96%' }}
                      options={formData.locodes}
                      value={portcall.to_port}
                    />
                  </InputWrapper>
                </Column2>
              ) : null}
            </RowContainer>
          ) : null}
        </Section>
        <Section>
          <RowContainer>
            <ButtonContainer>
              <Popconfirm
                title={t('Are you sure you want to send port call registration')}
                onConfirm={() => handleRegisterPortcall()}
                okText={t('Yes')}
                okType="primary"
                cancelText={t('No')}
                icon={null}
                cancelButtonProps={{ type: 'link' }}
              >
                <ButtonLight style={{ height: '38px', lineHeight: 'unset ' }} disabled={!isValid()}>
                  {sendButtonText}
                </ButtonLight>
              </Popconfirm>
            </ButtonContainer>
          </RowContainer>
        </Section>
      </MainContainer>
    </Spin>
  );
};

export default RegisterPortcallForm;
