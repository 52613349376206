import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Slider from 'antd/es/slider';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import VesselsSelection from './VesselsSelection';
import IncidentModalMap from './IncidentModalMap';

const { TextArea } = Input;

const Container = styled.div`
  display: block;
  margin: 6px 0;
`;

const Required = styled.span`
  color: #4a4a4a;
`;

const InnerContainer = styled.div`
  padding: 16px 16px 8px 16px;
`;

const TopRow = styled.div`
  display: flex;
  background-color: #f2f2f2;
  width: calc(100% - 3px);
  padding: 8px 6px;
  font-weight: 600;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
  margin-right: 6px;
`;

const Chevron = styled.div`
  margin-right: 6px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const Option = styled.div`
  background-color: ${props => (props.selected ? props.theme.color.secondary : props.theme.color.white)};
  border: 1px solid ${props => (props.selected ? props.theme.color.secondary : '#b1b1b1')};
  color: ${props => (props.selected ? props.theme.color.white : props.theme.color.grey_dark)};
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 12px;
  margin-bottom: 6px;
  min-width: 60px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border: 1px solid ${props => props.theme.color.secondary};
    color: ${props => (props.selected ? props.theme.color.white : props.theme.color.secondary)};
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const Datas = styled.div`
  display: block;
`;

const NextButton = styled(ButtonLight)`
  min-width: 60px;
  margin-bottom: 0px;
`;

const RowContainer = styled.div`
  width: 100%;
  position: relative;

  textarea {
    resize: none;
  }
`;

const RowHeader = styled.div`
  margin-bottom: 6px;
`;

const IntSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0 4px 0;
  gap: 10px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 50%;
  .ant-slider-track {
    background-color: ${props => (props.level > 7 ? '#ffa0a0' : props.level < 4 ? '#96e696' : '#ffdb64')};
  }
  .ant-slider-track:hover {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    color: ${props => (props.level > 7 ? '#ffa0a0' : props.level < 4 ? '#96e696' : '#ffdb64')};
  }
  .ant-slider-handle {
    color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    border: solid 2px ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle:hover {
    color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    border: solid 2px ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle:focus {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
    outline: none;
    box-shadow: 0 0 0 5px #1890ff1f;
  }
  .ant-slider-mark {
    font-size: 11px;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-dot {
    position: absolute;
    width: 1px;
    height: 8px;
    margin-left: -2px;
    background-color: #cfebff;
    border: 0;
    border-radius: 0%;
    cursor: pointer;
  }
  .ant-slider-dot-active {
    background-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  }
`;

const Line = styled.div`
  margin: 3px 12px;
`;

const AddIncidentPathItem = ({
  data,
  index,
  inputData,
  setInputData,
  openData,
  setOpenData,
  goNext,
  last,
  mapOn,
  setMapOn,
  pointMap,
  setPointMap,
  editOn,
  type,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const dataKey = data.data_model?.name;
  const selected = dataKey ? inputData[dataKey] : null;

  const updateSelected = value => {
    if (dataKey) {
      if (selected === value) {
        setInputData({ ...inputData, [dataKey]: null });
      } else {
        setInputData({ ...inputData, [dataKey]: value });
      }
    }
  };

  const onDateTimeChange = value => {
    if (dataKey) {
      setInputData({ ...inputData, [dataKey]: value || null });
    }
  };

  const onEndDateTimeChange = value => {
    if (data.addition.data_model.name) {
      setInputData({ ...inputData, [data.addition.data_model.name]: value || null });
    }
  };

  const onSliderChange = value => {
    if (dataKey) {
      setInputData({ ...inputData, [dataKey]: value || 1 });
    }
  };

  const handleTextAreaChange = value => {
    const dataKeyForAddition = data.addition ? data.addition.data_model.name : data.data_model.name;
    if (dataKeyForAddition) {
      setInputData({ ...inputData, [dataKeyForAddition]: value.target.value });
    }
  };

  const toggleOpen = () => {
    let openList = [...openData];
    openList.splice(index, 1, !openData[index]);
    setOpenData(openList);
  };

  const setMap = value => {
    if (!value) {
      const dataKeyForAddition = data.addition ? data.addition.data_model.name : data.data_model.name;
      if (dataKeyForAddition) {
        setInputData({ ...inputData, [dataKeyForAddition]: [] });
      }
    }
    setMapOn(value);
  };

  const setPoint = value => {
    const dataKeyForAddition = data.addition ? data.addition.data_model.name : data.data_model.name;
    if (dataKeyForAddition) {
      if (!value) {
        setInputData({
          ...inputData,
          [dataKeyForAddition]: null,
        });
        return;
      }

      setInputData({
        ...inputData,
        [dataKeyForAddition]: [
          {
            type: 'Feature',
            style: [],
            geometry: {
              type: 'Point',
              coordinates: [value.lng, value.lat],
            },
            properties: [],
          },
        ],
      });
    }
  };

  const setAreas = values => {
    const dataKeyForAddition = data.addition.data_model.name;
    if (dataKeyForAddition) {
      setInputData(data => {
        let list = data[dataKeyForAddition]?.length > 0 ? [...data[dataKeyForAddition]] : [];
        if (list.length === 1 && list[0].geometry.type === 'Point') {
          list = [];
        }
        list.push(values);
        return {
          ...inputData,
          [dataKeyForAddition]: list,
        };
      });
    }
  };

  const deleteMapId = id => {
    const dataKeyForAddition = data.addition.data_model.name;
    if (dataKeyForAddition) {
      setInputData(data => {
        let list = data[dataKeyForAddition]?.length > 0 ? [...data[dataKeyForAddition]] : [];
        const index = list.findIndex(t => t.properties.id === id);
        list.splice(index, 1);
        return {
          ...inputData,
          [dataKeyForAddition]: list,
        };
      });
    }
  };

  const isDataValid = () => {
    if (data.mandatory_obj[type] && (inputData[dataKey] === null || inputData[dataKey] === '')) {
      return false;
    }
    if (
      (data.addition?.when === 'any' || data.addition?.when === inputData[dataKey]) &&
      data.addition.mandatory_obj[type]
    ) {
      if (data.addition.data_model.type === 'array' && !inputData[data.addition.data_model.name].length) {
        return false;
      } else if (inputData[data.addition.data_model.name] === null) {
        return false;
      }
    }
    return true;
  };

  const tipFormatter = value => (value > 7 ? t('Major') : value < 4 ? t('Minor') : t('Medium'));

  const open = openData[index];
  return (
    <Container id={'incident-path-item-' + index}>
      <TopRow onClick={() => toggleOpen()} open={open}>
        <Header>
          {index + 1}. {t(data.header, { type })}{' '}
          {type === 'event' && data.mandatory_obj[type] && <Required>*</Required>}
        </Header>
        <Chevron>{open ? <Icon type="chevron-up" /> : <Icon type="chevron-down" />}</Chevron>
      </TopRow>
      {open && (
        <InnerContainer>
          <Datas>
            {data.type === 'options' && (
              <Options>
                {data.options.map((o, i) => (
                  <Option key={i} selected={selected === o.value} onClick={() => updateSelected(o.value)}>
                    {t(o.label)}
                  </Option>
                ))}
              </Options>
            )}
            {data.addition?.type === 'multiselect_vessels' && (
              <VesselsSelection
                data={data.addition}
                disabled={selected !== true}
                inputData={inputData}
                setInputData={setInputData}
                dataKey={data.addition.data_model.name}
                upperDataKey={dataKey}
              />
            )}
            {(((data.addition?.when === 'any' || data.addition?.when === inputData[dataKey]) &&
              data.addition?.type === 'textArea') ||
              data.type === 'textArea') && (
              <RowContainer>
                <RowHeader style={{ marginTop: '12px' }}>
                  {data.addition ? t(data.addition.info_text, { type }) : t(data.info_text, { type })}
                </RowHeader>
                <TextArea
                  style={{
                    width: '100%',
                    height: '96px',
                  }}
                  value={
                    data.addition
                      ? data.addition.data_model.name
                        ? inputData[data.addition.data_model.name]
                        : ''
                      : data.data_model.name
                        ? inputData[data.data_model.name]
                        : ''
                  }
                  onChange={handleTextAreaChange}
                />
              </RowContainer>
            )}
            {(data.addition?.type === 'map' || data.type === 'map') && (
              <IncidentModalMap
                data={data}
                inputData={inputData}
                mapOn={mapOn}
                setMap={setMap}
                setPoint={setPoint}
                setAreas={setAreas}
                deleteMapId={deleteMapId}
                pointMap={pointMap}
                setPointMap={setPointMap}
                editOn={editOn}
              />
            )}
            {data.type === 'date' && (
              <RowContainer>
                <RowHeader>{t('Time of the {{type}}', { type })}</RowHeader>
                <div style={{ display: 'flex' }}>
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    showTime={{ format: 'HH:mm', minuteStep: 1 }}
                    value={inputData[dataKey] ? dayjs(inputData[dataKey]) : null}
                    style={{
                      width: '240px',
                      height: '32px',
                    }}
                    onChange={onDateTimeChange}
                    onSelect={onDateTimeChange}
                    placeholder={t('Time')}
                  />
                  {data.addition?.type === 'date' && data.addition.available_obj[type] && (
                    <>
                      <Line>-</Line>
                      <div style={{ display: 'inline-block' }}>
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime={{ format: 'HH:mm', minuteStep: 1 }}
                          value={
                            inputData[data.addition.data_model.name]
                              ? dayjs(inputData[data.addition.data_model.name])
                              : null
                          }
                          style={{
                            width: '240px',
                            height: '32px',
                          }}
                          onChange={onEndDateTimeChange}
                          onSelect={onEndDateTimeChange}
                          placeholder={t('Time')}
                        />
                        <div style={{ fontSize: '11px', textAlign: 'right' }}>{t('Optional')}</div>
                      </div>
                    </>
                  )}
                </div>
              </RowContainer>
            )}
            {data.type === 'slider' && (
              <RowContainer>
                <RowHeader>{t('Select the severity of the incident')}</RowHeader>
                <IntSliderContainer>
                  <SliderContainer level={inputData[dataKey] ? dayjs(inputData[dataKey]) : 1} active>
                    <Slider
                      min={data.options.min}
                      max={data.options.max}
                      marks={data.options.marks}
                      value={inputData[dataKey] ? dayjs(inputData[dataKey]) : 1}
                      step={data.options.step}
                      onChange={onSliderChange}
                      tooltip={{ open: true, tipFormatter }}
                    />
                  </SliderContainer>
                </IntSliderContainer>
              </RowContainer>
            )}
          </Datas>
          {!last && (
            <Buttons>
              <NextButton disabled={!isDataValid()} type="button" cancel onClick={() => goNext(index)}>
                {t('Next')}
              </NextButton>
            </Buttons>
          )}
        </InnerContainer>
      )}
    </Container>
  );
};
export default AddIncidentPathItem;
