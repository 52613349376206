import React from 'react';
import FinnpilotServiceOrderLine from './FinnPilot';

export const ExternalTypeServiceOrderLine = ({ vessel, line, reloadData, externalType }) => {
  switch (externalType) {
    case 'finnpilot':
      return <FinnpilotServiceOrderLine vessel={vessel} line={line} reloadData={reloadData} />;
    default:
      return null;
  }
};

export const ExternalTypeServiceOrderTaskLine = ({ vessel, line, reloadData, externalType }) => {
  switch (externalType) {
    case 'finnpilot':
      return <FinnpilotServiceOrderLine vessel={vessel} line={line} reloadData={reloadData} isTaskList={true} />;
    default:
      return null;
  }
};
