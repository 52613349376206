import React, { useRef, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useToggle from '../../hooks/useToggle';
import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';
import DatePicker from 'antd/es/date-picker';
import Popconfirm from 'antd/es/popconfirm';
import Text from 'antd/lib/typography/Text';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import styled from 'styled-components';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Label from '../../components/ui/Label';

import List from '../../components/ui/List';
import ListActionButton from '../../components/ui/ListActionButton';

import './VesselTimestamps.css';
import DateComponent from '../../components/ui/DateComponent';

const PortCallIdWrapper = styled.div`
  white-space: nowrap;
  text-align: right;
  margin-right: 8px;
`;

const Container = styled.div`
  td {
    padding: 0px 0px 0px 2px !important;
  }
  && tbody > tr:hover > td {
    background: #f2f2f0;
  }
`;

const MarginButton = styled(Button)`
  margin-left: ${({ theme }) => theme.sizing.gap_small};
`;

const WindowCalendar = styled.div`
  min-width: 350px;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const BackLink = styled.a`
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
`;

const Header = styled.div`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 15px;
`;

const PortCallEdit = ({ record, refresh, pending }) => {
  const { user, apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [masterId, setMasterId] = useState('<not set>');
  const [masterManual, setMasterManual] = useState(false);
  const portCallId = record.port_call_id;

  const fetchRange = async () => {
    const { data } = await apiCall('get', 'port-call-range', { port_call_id: portCallId });
    data['start'] ? setStartDate(dayjs(data['start']).toDate()) : setStartDate(undefined);
    data['end'] ? setEndDate(dayjs(data['end']).toDate()) : setEndDate(undefined);
    data['master_id'] ? setMasterId(data['master_id']) : setMasterId('<not set>');
    data['master_manual'] ? setMasterManual(data['master_manual']) : setMasterManual(false);
    showActions(true);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
  };

  const handleRangeStartChange = e => {
    setStartDate(e);
  };

  const handleRangeEndChange = e => {
    setEndDate(e);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    showActions(false);
    pending(true);
    let saveStart = dayjs.utc(dayjs(startDate)).format('YYYY-MM-DDTHH:mm:ss+00:00');
    let saveEnd = dayjs.utc(dayjs(endDate)).format('YYYY-MM-DDTHH:mm:ss+00:00');
    await apiCall('post', 'port-call-range', { port_call_id: portCallId, start: saveStart, end: saveEnd });
    pending(false);
    refresh();
  };

  if (actions) {
    return (
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Label>{t('Port call ID: {{id}}', { id: portCallId })}</Label>
        <Label>{t('Master ID: {{id}}', { id: masterId })}</Label>
        <Label>{t('Manual: {{value}}', { value: masterManual })}</Label>
        <WindowCalendar>
          <Label>{t('Port call end')}</Label>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 5 }}
            style={{
              width: 'calc(100% - 24px)',
              marginRight: '24px',
            }}
            value={endDate ? dayjs(endDate) : null}
            onChange={handleRangeEndChange}
            placeholder={t('Port call end')}
          />
        </WindowCalendar>
        <WindowCalendar>
          <Label>{t('Port call start')}</Label>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 5 }}
            style={{
              width: 'calc(100% - 24px)',
              marginRight: '24px',
            }}
            value={startDate ? dayjs(startDate) : null}
            onChange={handleRangeStartChange}
            placeholder={t('Port call start')}
          />
        </WindowCalendar>
        <Button disabled={!user.permissions.includes('manage_port_call')} link>
          {t('Save')}
        </Button>
        <MarginButton link onClick={e => handleCancel(e)}>
          {t('Cancel')}
        </MarginButton>
      </Form>
    );
  }

  return (
    <div ref={actionsRef}>
      <PortCallIdWrapper>
        <Button link onClick={fetchRange}>
          {portCallId}
        </Button>
      </PortCallIdWrapper>
    </div>
  );
};

const VesselTimestamps = props => {
  const { apiCall, user, namespace, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [apiCallPending, setApiCallPending] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { imo } = props.match.params;

  const params = new URLSearchParams(location.search);

  const defaultParams = {
    imo: imo,
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'time DESC',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'timestamps', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const timestamps = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  let prevPortCall = -1;
  let styleIndex = 1;
  let styleObj = {};
  timestamps.forEach(p => {
    p._row = start + counter;

    if (p.port_call_id) {
      p.showPortCallModify = false;
      if (p.port_call_id !== prevPortCall) {
        p.showPortCallModify = true;
      }

      let rowClass = styleObj[p.port_call_id];
      if (rowClass) {
        p.rowClass = rowClass;
      } else {
        p.rowClass = 'port_call_' + styleIndex;
        styleObj[p.port_call_id] = 'port_call_' + styleIndex;
        styleIndex++;

        if (styleIndex === 9) {
          styleIndex = 1;
        }
      }

      prevPortCall = p.port_call_id;
    } else {
      p.rowClass = 'no_port_call';
    }

    counter++;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleDelete = async id => {
    setApiCallPending(true);
    await apiCall('delete', 'timestamps', { id: id });
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleRestore = async id => {
    setApiCallPending(true);
    await apiCall('post', 'restore-timestamp', { id: id });
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleRefresh = async () => {
    let params = newParams;
    await fetchData(false, params);
  };

  function handleBackToVesselList() {
    history.push('/vessels');
  }

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
    },
    {
      title: t('Port call ID'),
      dataIndex: 'port_call_id',
      key: 'port_call_id',
      render: (text, record) => {
        if (record.showPortCallModify) {
          return <PortCallEdit record={record} refresh={handleRefresh} pending={setApiCallPending} />;
        } else {
          return <PortCallIdWrapper>{text}</PortCallIdWrapper>;
        }
      },
    },
    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
      sortableKey: 'time',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Time type'),
      dataIndex: 'time_type',
      key: 'time_type',
    },
    {
      title: t('State'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('Trash'),
      dataIndex: 'is_trash',
      key: 'is_trash',
      render: record => {
        if (record === 't') {
          return <Text>T</Text>;
        } else {
          return <Text>F</Text>;
        }
      },
    },
    {
      title: t('Created at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Created by'),
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: t('Source'),
      dataIndex: 'source',
      key: 'source',
      render: (record, item) => {
        let json = typeof item.payload === 'string' ? JSON.parse(item.payload) : item.payload;
        let ret = '';
        if (json.source) {
          ret += json.source;
        }
        return <Text>{ret}</Text>;
      },
    },
    {
      title: t('External ID'),
      dataIndex: 'source',
      key: 'source',
      render: (record, item) => {
        let json = typeof item.payload === 'string' ? JSON.parse(item.payload) : item.payload;
        let ret = '';
        if (json.external_id) {
          ret += json.external_id;
        }
        return <Text>{ret}</Text>;
      },
    },
    {
      title: t('Data'),
      dataIndex: 'payload',
      key: 'payload',
      render: record => {
        let json = typeof record === 'string' ? JSON.parse(record) : record;
        let ret = '';
        if (json.direction) {
          ret += json.direction;
        }
        return <Text>{ret}</Text>;
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <Popconfirm
          title={t('Really delete timestamp permanently?')}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-1"
        >
          <div>
            <ListActionButton red disabled={!user.permissions.includes('manage_port_call')}>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
    {
      render: record => (
        <ListActionButton
          disabled={!user.permissions.includes('manage_port_call')}
          key="action-2"
          onClick={() => history.push(`/vessels/vessel-timestamp/${record.id}`)}
        >
          <Icon type="action" />
          {t('Show')}
        </ListActionButton>
      ),
    },
    {
      render: record =>
        record.is_trash !== 'f' ? (
          <ListActionButton
            disabled={!user.permissions.includes('manage_port_call')}
            key="action-3"
            onClick={() => handleRestore(record.id)}
          >
            <Icon type="action" />
            {t('Restore')}
          </ListActionButton>
        ) : null,
    },
  ];

  const additionalButtons = [
    {
      onClick: () => history.push(`/port-calls?offset=0&search=${imo}`),
      disabled: !user.permissions.includes('manage_port_call') || !timestamps || timestamps.length === 0,
      text: t('Port calls for {{imo}}', { imo: imo }),
    },
  ];

  return (
    <Layout pagename={t('Timestamps')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <BackLink disabled={!user.permissions.includes('basic_user_action')} onClick={handleBackToVesselList}>
          {t('« Back to vessel list')}
        </BackLink>
        <Header>
          {t('Timestamps for IMO {{imo}}', {
            imo: imo,
          })}
        </Header>
        <Container>
          <List
            rowKey="id"
            columns={columns}
            dataSource={timestamps}
            apiCallPending={apiCallPending}
            actions={actionList}
            spinning={loading}
            setParams={setNewParams}
            newParams={newParams}
            start={start}
            total={total}
            hideSearch={true}
            additionalButtons={additionalButtons}
            rowClassName={record => record.rowClass}
            transparentBgs={true}
          />
        </Container>
      </Page>
    </Layout>
  );
};

export default VesselTimestamps;
