import React from 'react';
import styled from 'styled-components';
import DateComponent from '../ui/DateComponent';

const NotificationTime = styled(DateComponent)`
  color: #747d7d;
  font-weight: 700;
  font-size: 0.8571rem;
  font-style: italic;
  display: block;
  margin-bottom: 0;
`;

const NotificationMessage = styled.div`
  margin-bottom: 0;
`;

const Title = styled.div`
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const Message = styled.div`
  font-size: 1rem;
  color: #000000;
  margin-bottom: 0.25rem;
`;

const ServiceOrderNotificationTitle = ({ title, message }) => {
  const today = new Date();

  return (
    <>
      <NotificationTime format="HH:mm" date={today} />
      <NotificationMessage>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </NotificationMessage>
    </>
  );
};

export default ServiceOrderNotificationTitle;
