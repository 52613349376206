import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';
import { TimestampContext } from '../context/TimestampContext';
import { TimestampFilteringContext } from '../context/TimestampFilteringContext';

import Layout from '../components/Layout';
import Timeline from '../components/activity/Timeline';

const SearchAmountContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 2px;
  right: 34px;
  z-index: 30;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const FilteredAmount = styled.div`
  font-size: 12px;
  text-align: left;
  margin: 0px 8px;
`;

const Activity = () => {
  const { isIE, isEdge, namespace } = useContext(UserContext);
  const { timestamps, unfilteredAmount } = useContext(TimestampContext);
  const { setTimestampFilteringListSet } = useContext(TimestampFilteringContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Activity | Port Activity App';
    setTimestampFilteringListSet(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout filtering pagename={t('Activity')}>
      <SearchAmountContainer>
        <FilteredAmount>
          {t('Showing {{showingAmount}} / {{unfilteredAmount}} port calls', {
            unfilteredAmount: unfilteredAmount,
            showingAmount: timestamps.length,
          })}
        </FilteredAmount>
      </SearchAmountContainer>
      <Timeline isIE={isIE} isEdge={isEdge} />
    </Layout>
  );
};

export default Activity;
