import AutoComplete from 'antd/es/auto-complete';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';
import CircleMarkers from './CircleMarkers';

const CircleMarkersData = ({
  showVessels,
  vesselsState,
  fleetViewOn,
  zoom,
  setSelectedAisVessel,
  selectedAisVessel,
  setSelectedAisVesselLocation,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [aisSearch, setAisSearch] = useState('');

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const { data: aisData, fetchData: fetchAisData } = useApi('get', 'ais/all', {}, null, namespace === 'vts');
  const { data: helcomData, fetchData: fetchHelcomData } = useApi('get', 'ais/helcom', {}, null, namespace === 'vts');

  useEffect(() => {
    let vesselInterval = undefined;

    if (namespace === 'vts') {
      vesselInterval = setInterval(() => {
        fetchAisData();
        fetchHelcomData();
      }, 180000);
    }

    return () => clearInterval(vesselInterval);
  }, [fetchAisData, fetchHelcomData, namespace, vesselsState]);

  let nameIndex = helcomData?.headers?.findIndex(h => h === 'name');
  let mmsiIndex = helcomData?.headers?.findIndex(h => h === 'mmsi');
  let lonIndex = helcomData?.headers?.findIndex(h => h === 'lon');
  let latIndex = helcomData?.headers?.findIndex(h => h === 'lat');

  return (
    <>
      <AutoComplete
        style={{
          width: 200,
        }}
        options={
          vesselsState === 'all-ais'
            ? aisData?.data?.map((v, i) => {
              return { label: v[2], value: v[2] + i, data: v };
            }) || []
            : helcomData?.data?.map((v, i) => {
              return { label: v[2], value: v[2] + i, data: v };
            }) || []
        }
        filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        onSelect={(value, option) => {
          setSelectedAisVessel({
            name: option.data[nameIndex],
            mmsi: option.data[mmsiIndex],
            lat: option.data[latIndex],
            lon: option.data[lonIndex],
          });
          setSelectedAisVesselLocation(true);
          setAisSearch('');
        }}
        value={aisSearch}
        onChange={e => setAisSearch(e)}
        placeholder={t('Search vessel by name')}
      />
      <CircleMarkers
        vesselsData={
          !showVessels || fleetViewOn
            ? {}
            : vesselsState === 'all-ais' && aisData
              ? aisData
              : vesselsState === 'helcom' && helcomData
                ? helcomData
                : {}
        }
        zoom={zoom}
        selectedAisVessel={selectedAisVessel}
        setSelectedAisVessel={setSelectedAisVessel}
      />
    </>
  );
};

export default CircleMarkersData;
