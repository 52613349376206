import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import Alerts from '../../components/admin/Alerts';

const AdminAlerts = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Alerts | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Alerts')}>
      <Page fullWidth>
        <Alerts />
      </Page>
    </Layout>
  );
};

export default AdminAlerts;
