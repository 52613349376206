import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import ButtonLight from '../components/ui/ButtonLight';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../components/ui/ErrorPlaceHolder';
import { logError } from '../components/ui/errorLogging';
import ErrorTestingSmall from '../components/errorHandling/ErrorTestingSmall';
import ErrorTestingMedium from '../components/errorHandling/ErrorTestingMedium';

const Columns = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  width: 50%;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  margin-bottom: 24px;
`;

const LargeError = styled.div`
  margin-top: -30px;
  margin-bottom: 24px;
`;

const ErrorTestingPage = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [smallErrorState, setSmallErrorState] = useState({ error: 'This is a container for small error.' });
  const [mediumErrorState, setMediumErrorState] = useState({ error: 'This is a container for medium error.' });
  const [largeErrorState, setLargeErrorState] = useState({ error: 'The whole page will break with large error.' });

  useEffect(() => {
    document.title = 'Error Testing | Port Activity App';
  }, []);

  const createLargeError = () => {
    setLargeErrorState(null);
  };

  const createMediumError = () => {
    setMediumErrorState(null);
  };

  const createSmallError = () => {
    setSmallErrorState(null);
  };

  return (
    <Layout pagename={t('Error Testing')}>
      <Page fullWidth>
        <ErrorTestingMedium state={mediumErrorState} />
        <Columns>
          <Column>
            <ButtonContainer>
              <ButtonLight onClick={() => createLargeError()}>Create large error</ButtonLight>
            </ButtonContainer>
            <LargeError>{largeErrorState.error}</LargeError>
            <ButtonContainer>
              <ButtonLight onClick={() => createMediumError()}>Create medium error</ButtonLight>
            </ButtonContainer>
            <ButtonContainer>
              <ButtonLight onClick={() => createSmallError()}>Create small error</ButtonLight>
            </ButtonContainer>
          </Column>
          <Column>
            <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small', t)} onError={logError}>
              <ErrorTestingSmall state={smallErrorState} />
            </ErrorBoundary>
          </Column>
        </Columns>
      </Page>
    </Layout>
  );
};

export default ErrorTestingPage;
