import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';
import WasteException from '../components/invoicing/WasteException';
import PricingCalculations from '../components/invoicing/PricingCalculations';
import PricingTabbedView from '../components/invoicing/PricingTabbedView';
import PortCallInvoices from '../components/invoicing/PortCallInvoices';
import { Link } from 'react-router-dom';
import EditPricingCategoriesModal from '../components/invoicing/EditPricingCategoriesModal';
import Icon from '../components/ui/Icon';
import { InvoicingContext } from '../context/InvoicingContext';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  display: flex;
  gap: 20px;
  box-shadow: none;

  @media (max-width: 1200px) {
    display: inline-block;
  }
`;

const RestContainer = styled.div`
  width: 45%;

  @media (max-width: 1200px) {
    width: calc(100vw - 102px);
    margin-top: 20px;
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 42px);
  }
`;

const InvoicingContainer = styled.div`
  width: 55%;

  @media (min-width: 1001px) and (max-width: 1200px) {
    width: calc(100vw - 102px);
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 42px);
  }
`;

const PortCallInvoicesContainer = styled.div`
  grid-area: top1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
`;

const WasteAndPrice = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 500px) {
    display: inline-block;
  }
`;

const PricingCalculationsContainer = styled.div`
  width: 54%;
  overflow: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

  @media (max-width: 500px) {
    width: calc(100vw - 42px);
  }
`;

const WasteExceptionContainer = styled.div`
  width: 44%;
  overflow: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

  @media (max-width: 500px) {
    width: calc(100vw - 42px);
    margin-top: 20px;
  }
`;

const PricingSummaryContainer = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  overflow: auto;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const FullViewButton = styled.div`
  display: flex;
  gap: 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary};
  color: #4a4a4a;
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.color.secondary};
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.sizing.gap_huge};
    margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  }
`;

const InvoicingView = () => {
  const { namespace } = useContext(UserContext);
  const { getStartData } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    getStartData();
    document.title = 'Invoicing | Port Activity App';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pagename={t('Invoicing')}>
      <AlteredPage fullWidth>
        <InvoicingContainer>
          <PortCallInvoicesContainer>
            <Header>
              {t('Invoice per port call')}
              <Link to="/invoicing/invoices">
                <FullViewButton>
                  <Icon type={'expand'} />
                  {t('See full view')}
                </FullViewButton>
              </Link>
            </Header>
            <PortCallInvoices />
          </PortCallInvoicesContainer>
        </InvoicingContainer>
        <RestContainer>
          <WasteAndPrice>
            <PricingCalculationsContainer>
              <PricingCalculations />
            </PricingCalculationsContainer>
            <WasteExceptionContainer>
              <WasteException />
            </WasteExceptionContainer>
          </WasteAndPrice>
          <PricingSummaryContainer>
            <PricingTabbedView />
          </PricingSummaryContainer>
        </RestContainer>
        <EditPricingCategoriesModal />
      </AlteredPage>
    </Layout>
  );
};

export default InvoicingView;
