import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import Dots from './Dots';

const Carousel = styled.div`
  background-color: transparent;
`;

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 6px;
`;

const PortCall = styled.div`
  background-color: ${props => (props.current ? props.theme.color.secondary : 'transparent')};
  color: ${props => (props.current ? 'white' : props.theme.color.grey_dark)};
  border: 1px solid ${({ theme }) => theme.color.grey_lighter3};
  border: ${props => props.manual && (props.current ? '2px dotted #103a66' : '2px dotted #bebebe')};
  margin: 0 3px 0 0;
  border-radius: 3px;
  padding: 4px 6px;
  cursor: pointer;
  height: 74px;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const Common = styled.div`
  display: flex;
  padding: 0 10 0 0px;
  font-size: 13px;
`;

const SecondColumn = styled.div`
  max-width: 200px;
`;

const Text = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
`;

const DottedIndicator = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -2px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.primary)};

  svg {
    height: 10px;
    width: 10px;
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const PortcallCarousel = ({ data, currentPortcall, setCurrentPortcall, scrolled, setScrolled }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    if (!scrolled && data) {
      const indexToScroll = data.findIndex(d => d.port_call_id === currentPortcall);
      let element = document.getElementById('carousel-portcall-' + indexToScroll);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setScrolled(true);
      }
    }
  }, [currentPortcall, data, scrolled, setScrolled]);

  if (!data) {
    return null;
  }

  const total = data.length;
  const currentIndex = data.findIndex(d => d.port_call_id === currentPortcall);

  const showPrev = () => {
    if (currentIndex > 0) {
      setCurrentPortcall(data[currentIndex - 1].port_call_id);
      let element = document.getElementById('carousel-portcall-' + (currentIndex - 1));
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  const showNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentPortcall(data[currentIndex + 1].port_call_id);
      let element = document.getElementById('carousel-portcall-' + (currentIndex + 1));
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  const onClickPortcall = portcallID => {
    setCurrentPortcall(portcallID);
    const index = data.findIndex(d => d.port_call_id === portcallID);
    let element = document.getElementById('carousel-portcall-' + index);
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  return (
    <Carousel id="vessel-side-panel-carousel">
      <Container id="carousel-container">
        {data.map((portcall, index) => (
          <PortCall
            key={portcall.port_call_id}
            current={portcall.port_call_id === currentPortcall}
            onClick={() => onClickPortcall(portcall.port_call_id)}
            id={'carousel-portcall-' + index}
            manual={portcall.data.dataSource === 'manual'}
          >
            <Common style={{ marginBottom: '2px' }}>
              <FirstColumn>
                <Text>{t('Port')}:</Text>
              </FirstColumn>
              <SecondColumn>
                <Tooltip title={portcall.data.portName.replace(/ *\([^)]*\) */g, '')}>
                  <Text style={{ fontWeight: 600, overflow: 'hidden', width: '100px', textOverflow: 'ellipsis' }}>
                    {portcall.data.portName.replace(/ *\([^)]*\) */g, '')}
                  </Text>
                </Tooltip>
              </SecondColumn>
            </Common>
            <Common>
              <FirstColumn>
                <Text>{t('ETA')}:</Text>
                <Text>{t('ETD')}:</Text>
              </FirstColumn>
              <SecondColumn>
                {portcall.eta ? (
                  <Text style={{ fontWeight: 600 }}>
                    {dayjs(portcall.eta).format('DD.MM.YYYY')} {dayjs(portcall.eta).format('HH:mm')}
                  </Text>
                ) : (
                  '-'
                )}
                {portcall.etd ? (
                  <Text style={{ fontWeight: 600 }}>
                    {dayjs(portcall.etd).format('DD.MM.YYYY')} {dayjs(portcall.etd).format('HH:mm')}
                  </Text>
                ) : (
                  '-'
                )}
              </SecondColumn>
            </Common>
          </PortCall>
        ))}
      </Container>

      {data?.length > 0 && (
        <DottedIndicator>
          <IconContainer aria-label="open-side-menu-icon" onClick={showPrev} disabled={currentIndex === 0}>
            <RotatedIcon type="chevron-right-2" />
          </IconContainer>
          <Dots total={total} currentIndex={currentIndex} />
          <IconContainer
            aria-label="open-side-menu-icon"
            onClick={showNext}
            disabled={currentIndex === data.length - 1}
          >
            <Icon type="chevron-right-2" />
          </IconContainer>
        </DottedIndicator>
      )}
    </Carousel>
  );
};

export default PortcallCarousel;
