import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import dayjs from 'dayjs';

import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';

import { UserContext } from '../../context/UserContext';
import { FleetVesselViewContext } from '../../context/FleetVesselViewContext';
import FleetFilterContainer from '../filter/FleetFilterContainer';
import FleetFilterSearch from '../filter/FleetFilterSearch';

const { RangePicker } = DatePicker;

import { sidebarWidth } from '../commonCalendar/utility/constants';
import DelayedVessels from './DelayedVessels';

const TopPanel = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const FleetFilterAndSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  font-size: 22px;
  color: black;
  width: calc(${sidebarWidth}px - 12px);
  padding-left: 12px;
  flex-shrink: 0;
`;

const CalendarButtons = styled.div`
  display: flex;
  margin-right: 12px;
  margin-left: 12px;
  position: relative;

  button {
    margin-bottom: 4px;
  }
`;

const CalendarTypeButtons = styled.div`
  margin-right: 12px;
  margin-left: 12px;
`;

const ButtonVersion = styled(ButtonLight)`
  font-size: 14px;
  min-width: 60px;
  height: 24px;
  line-height: 8px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  margin-left: 6px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 0 4px;
  color: #626262;
`;

const Container = styled.div`
  display: flex;
`;

const Legend = styled.div`
  margin-left: 24px;
  display: flex;
`;

const ColorSquare = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 8px;
`;

const TextAndAmount = styled.div`
  font-size: 11.5px;
  color: ${({ theme }) => theme.color.grey};
  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 10.5px;
  }
`;

const Column = styled.div`
  display: inline-block;
`;

const CustomContainer = styled.div`
  display: flex;
  line-height: 21px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
  width: 240px;
`;

const Buttons = styled.div`
  display: inline-block;
`;

const SpinContainer = styled.div`
  width: 26px;
  min-height: 20px;
`;

const FleetVesselViewTopPanel = () => {
  const { namespace, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  const [currentDates, setCurrentDates] = useState([null, null]);

  const {
    setTimelineType,
    setToday,
    timelineUnit,
    moveForwards,
    moveBackwards,
    visibleTimeStart,
    visibleTimeEnd,
    changeDate,
    changeCustomDate,
    secondLoader,
  } = useContext(FleetVesselViewContext);

  const setCalendarTypeDay = () => {
    setTimelineType('day');
  };

  const setCalendarTypeTwoDays = () => {
    setTimelineType('twodays');
  };

  const setCalendarTypeWeek = () => {
    setTimelineType('week');
  };

  const setCalendarTypeMonth = () => {
    setTimelineType('month');
  };

  const setCalendarTypeCustom = () => {
    setTimelineType('custom');
  };

  const { Option } = Select;
  const handleChange = value => {
    switch (value) {
      case 'day':
        setCalendarTypeDay();
        break;
      case 'twodays':
        setCalendarTypeTwoDays();
        break;
      case 'week':
        setCalendarTypeWeek();
        break;
      case 'month':
        setCalendarTypeMonth();
        break;
      case 'custom':
        setCalendarTypeCustom();
        break;

      default:
        break;
    }
  };

  const updateCustomDates = (dates, info) => {
    setCurrentDates(dates);
    if (timelineUnit === 'custom') {
      if (dates?.[0] && dates?.[1]) {
        changeCustomDate(dates);
        setRangePickerOpen(false);
      }
    } else {
      if (dates?.[0] || dates?.[1]) {
        const range = timelineUnit === 'day' ? 1 : timelineUnit === 'week' ? 7 : 28;
        if (info.range === 'end') {
          const first = dates[1].clone();
          changeDate(first.add(-range, 'days').startOf('day'), dates[1].startOf('day'));
        } else {
          const second = dates[0].clone();
          changeDate(dates[0].startOf('day'), second.add(range, 'days').startOf('day'));
        }
        setRangePickerOpen(false);
      }
    }
  };

  const disabledDate = current => {
    if (timelineUnit !== 'custom' || (!currentDates[0] && !currentDates[1])) {
      return false;
    }

    const comparison = currentDates[0] || currentDates[1];

    const tooLate = Math.abs(current.diff(comparison, 'days')) > 28;

    return !!tooLate;
  };

  return (
    <TopPanel>
      <Header>{t('Vessel Timeline')}</Header>
      <Buttons>
        <CalendarButtons>
          <ButtonVersion cancel onClick={setToday}>
            {t('Today')}
          </ButtonVersion>
          <CalendarTypeButtons>
            <div id="calendar-type-select">
              <Select value={timelineUnit} style={{ width: 102 }} onChange={handleChange} size="small">
                <Option value="day">{t('Day')}</Option>
                <Option value="twodays">{t('2 days')}</Option>
                <Option value="week">{t('Week')}</Option>
                <Option value="month">{t('4 weeks')}</Option>
                <Option value="custom">{t('Custom')}</Option>
              </Select>
            </div>
          </CalendarTypeButtons>
          <IconContainer onClick={moveBackwards} style={{ marginLeft: 0 }}>
            <Icon type="chevron-left" />
          </IconContainer>
          <IconContainer onClick={moveForwards}>
            <Icon type="chevron-right" />
          </IconContainer>
        </CalendarButtons>
        <CustomContainer>
          <RangePicker
            format={'DD.MM.YYYY'}
            allowClear={true}
            value={rangePickerOpen ? [null, null] : [dayjs(visibleTimeStart), dayjs(visibleTimeEnd)]}
            onCalendarChange={(dates, datesStr, info) => updateCustomDates(dates, info)}
            disabledDate={disabledDate}
            onOpenChange={e => {
              setRangePickerOpen(e);

              if (e) {
                setCurrentDates([null, null]);
              }
            }}
            allowEmpty={[true, true]}
            open={rangePickerOpen}
          />
        </CustomContainer>
      </Buttons>
      <SpinContainer>{secondLoader && <Spin size="small" />}</SpinContainer>

      <Legend>
        <Column style={{ marginRight: '12px' }}>
          <Container>
            <ColorSquare color="#A5D6A7" />
            <TextAndAmount>{t('Planned')}</TextAndAmount>
          </Container>
          <Container>
            <ColorSquare color="#A5D6A7" style={{ border: '2px dotted #639665' }} />
            <TextAndAmount>{t('Manual')}</TextAndAmount>
          </Container>
        </Column>
        {modules.sea_passage_module === 'enabled' && (
          <Column>
            <Container>
              <ColorSquare color="#A7C4F4" />
              <TextAndAmount>{t('Actual')}</TextAndAmount>
            </Container>
            <Container>
              <ColorSquare color="#e3edff" />
              <TextAndAmount>{t('Calculated')}</TextAndAmount>
            </Container>
          </Column>
        )}
      </Legend>
      {modules.sea_passage_module === 'enabled' && <DelayedVessels />}
      <FleetFilterAndSearchContainer>
        <FleetFilterContainer containerId="fleet-filter-in-vessel-view" />
        <FleetFilterSearch />
      </FleetFilterAndSearchContainer>
    </TopPanel>
  );
};

export default FleetVesselViewTopPanel;
