import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import DatePicker from 'antd/es/date-picker';
import InputNumber from 'antd/es/input-number';
import Popconfirm from 'antd/es/popconfirm';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import Heading from '../ui/Heading';
import Input from '../ui/Input';
import ButtonLight from '../ui/ButtonLight';
import { FORMS_URL, FormsContext } from '../../context/FormContext';
import { TIME_FORMAT } from '../../utils/constants';
import './ServiceOrderLineForm.css';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TitleText = styled(Heading)`
  color: #000;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const SubTitleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const OrderLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`;

const OrderLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 28px;
`;

const OrderLineColumn1 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-end;
  text-align: right;
  margin-right: 32px;
  /*max-width: 50%;*/
  width: 50%;
`;

const OrderLineColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-start;
  text-align: left;
  /*max-width: 45%;*/
  width: 50%;
`;

const OrderLineRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  padding-bottom: 4px;
  width: 100%;
`;

const OrderLineText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 2px;
  overflow-wrap: anywhere;
`;

const OrderLineTextHighlighted = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  margin-bottom: 2px;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 4px;
`;

const OrderLineTimestamp = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #f44336;
  align-items: center;
  align-self: flex-end;
`;

const OrderLineTimestampText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #f44336;
`;

const Ack = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

const AckTitle = styled.div`
  padding-bottom: 24px;
`;

const AckTitleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 2px;
  text-align: center;
`;

const AckTimestamp = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #f5bd3e;
  align-items: center;
`;

const AckTimestampText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #f5bd3e;
`;

const AckContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

const AckEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  border-top: 1px solid #d8d8d8;
  max-width: 450px;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  button {
    margin-bottom: 0px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Delivery = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeliveryTimestamp = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #407505;
  background-color: #407505;
  align-items: center;
  gap: 16px;
`;

const DeliveryTimestampText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
`;

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

const DeliveryEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  border-top: 1px solid #d8d8d8;
  max-width: 450px;
  width: 100%;
`;

const Button = styled(ButtonLight)`
  min-width: 76px !important;
`;

const InputWrapper = styled.div`
  margin-bottom: ${props => (props.inline ? 0 : props.theme.sizing.gap)};
  margin-right: 8px;
  flex: 1;
  display: flex;
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    height: 1rem;
  }
  border: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  padding: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.grey_dark};
  background: ${({ theme }) => theme.color.white};
  min-width: 100px;
  flex: 1;
  display: flex;
  min-height: 2rem;
  max-width: 100%;
  margin-left: 2px;
  &:disabled {
    border: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(73, 144, 221, 0.2);
    border: 1px solid rgba(73, 144, 221, 0.7);
  }
  &:focus-visible {
    outline: none;
  }
  ::placeholder {
    color: #bfbfbf;
  }
`;

const ServiceOrderLineForm = () => {
  const { apiCall, apiCallPending, formParams, formType, namespace, updateFormNotification } = useContext(FormsContext);

  const { t } = useTranslation(namespace);

  const [formData, setFormData] = useState(null);
  const [formDataFetched, setFormDataFetched] = useState(false);

  const fetchFormData = useCallback(async () => {
    const result = await apiCall('get', FORMS_URL);
    if (result?.status === 200) {
      if (formType === 'service-order-ack-delivery') {
        setFormData(result.data?.['service-order-line']);
      } else {
        // TODO?
        setFormDataFetched(false);
      }
    }
  }, [apiCall, formType]);

  useEffect(() => {
    if (!formDataFetched && formParams.order_line_id) {
      fetchFormData();
      setFormDataFetched(true);
    }
  }, [fetchFormData, formDataFetched, formParams.order_line_id]);

  const handleAckSave = async (data, status) => {
    const result = await apiCall('post', FORMS_URL, {
      type: 'service-order-ack',
      data,
    });
    if (result?.status === 200) {
      if (status === 'accepted') {
        updateFormNotification(
          t('{{service}} acknowledged successfully.', {
            service: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
          })
        );
      } else {
        updateFormNotification(
          t('{{service}} rejected successfully.', {
            service: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
          })
        );
      }
      fetchFormData();
    }
  };

  const handleDeliverySave = async (data, status) => {
    const result = await apiCall(orderLine?.deliveries.length > 0 ? 'put' : 'post', FORMS_URL, {
      type: 'service-order-delivery',
      data,
    });
    if (result?.status === 200) {
      if (status === 'done') {
        updateFormNotification(
          t('{{service}} delivered successfully.', {
            service: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
          })
        );
      } else {
        updateFormNotification(
          t('{{service}} cancelled successfully.', {
            service: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
          })
        );
      }

      fetchFormData();
    }
  };

  const orderLine = formData;
  if (!orderLine) {
    return null;
  }

  let updated_by = orderLine.updated_by || '';
  if (orderLine.data?.updated_by_name && orderLine.data?.updated_by_email) {
    updated_by = `${orderLine.data.updated_by_name} (${orderLine.data.updated_by_email})`;
  } else if (orderLine.data?.updated_by_name) {
    updated_by = orderLine.data.updated_by_name;
  } else if (orderLine.data?.updated_by_email) {
    updated_by = orderLine.data.updated_by_email;
  }
  const tooltipText = t('Requested by {{updated_by}}', { updated_by });

  return (
    <Spin spinning={apiCallPending} wrapperClassName="spinner">
      <MainContainer>
        <TitleText h4>{t('Service order')}</TitleText>
        <SubTitleText>
          {t('Following service requested by {{name}}', { name: orderLine.service_order?.vessel_name })}
        </SubTitleText>
        <OrderLineContainer>
          <OrderLine>
            <OrderLineRow>
              <OrderLineColumn1>
                <OrderLineText>{t('Vessel name')}:</OrderLineText>
              </OrderLineColumn1>
              <OrderLineColumn2>
                <OrderLineTextHighlighted>{orderLine.service_order?.vessel_name}</OrderLineTextHighlighted>
              </OrderLineColumn2>
            </OrderLineRow>
            <OrderLineRow>
              <OrderLineColumn1>
                <OrderLineText>{t('Requested service')}:</OrderLineText>
              </OrderLineColumn1>
              <OrderLineColumn2>
                <OrderLineTextHighlighted>{orderLine.service_type_name}</OrderLineTextHighlighted>
              </OrderLineColumn2>
            </OrderLineRow>
            <OrderLineRow>
              <OrderLineColumn1>
                <OrderLineText>{t('Amount')}:</OrderLineText>
              </OrderLineColumn1>
              <OrderLineColumn2>
                {orderLine.quantity ? (
                  <OrderLineTextHighlighted>{orderLine.quantity}</OrderLineTextHighlighted>
                ) : (
                  <OrderLineTextHighlighted> </OrderLineTextHighlighted>
                )}
              </OrderLineColumn2>
            </OrderLineRow>
            <OrderLineRow>
              <OrderLineColumn1>
                <OrderLineText>{t('When')}:</OrderLineText>
              </OrderLineColumn1>
              <OrderLineColumn2>
                <OrderLineTextHighlighted>{orderLine.when || ' '}</OrderLineTextHighlighted>
                {orderLine.requested_delivery_time ? (
                  <OrderLineTextHighlighted>
                    {dayjs(orderLine.requested_delivery_time).format(TIME_FORMAT)}
                  </OrderLineTextHighlighted>
                ) : (
                  <OrderLineTextHighlighted> </OrderLineTextHighlighted>
                )}
              </OrderLineColumn2>
            </OrderLineRow>
            <OrderLineRow>
              <OrderLineColumn1>
                <OrderLineText>{t('Request note')}:</OrderLineText>
              </OrderLineColumn1>
              <OrderLineColumn2>
                <OrderLineText>{orderLine.notes || ' '}</OrderLineText>
              </OrderLineColumn2>
            </OrderLineRow>
            <OrderLineRow style={{ alignItems: 'center' }}>
              <OrderLineColumn1>{t('Requested at')}:</OrderLineColumn1>
              <OrderLineColumn2>
                <Tooltip placement="top" title={tooltipText} overlayStyle={{ maxWidth: '100%' }}>
                  <BadgeContainer>
                    <OrderLineTimestamp>
                      <OrderLineTimestampText>{dayjs(orderLine.updated_at).format(TIME_FORMAT)}</OrderLineTimestampText>
                    </OrderLineTimestamp>
                  </BadgeContainer>
                </Tooltip>
              </OrderLineColumn2>
            </OrderLineRow>
          </OrderLine>
          <ServiceOrderAck handleAckSave={handleAckSave} namespace={namespace} orderLine={orderLine} />
          <ServiceOrderDeliveries handleDeliverySave={handleDeliverySave} namespace={namespace} orderLine={orderLine} />
        </OrderLineContainer>
      </MainContainer>
    </Spin>
  );
};

const ServiceOrderAck = ({ handleAckSave, namespace, orderLine }) => {
  const { t } = useTranslation(namespace);
  const [notes, setNotes] = useState('');

  const createDataToBeSent = status => {
    handleAckSave(
      {
        status: status,
        notes,
        email: orderLine.service_provider_email,
        phone: orderLine.service_provider_phone,
      },
      status
    );
  };

  if (orderLine?.ack) {
    let created_by = orderLine.ack.created_by || '';
    const emailOrPhone = orderLine.ack.data?.created_by_email || orderLine.ack.data?.created_by_phone;
    if (orderLine.ack.data?.created_by_name && emailOrPhone) {
      created_by = `${orderLine.ack.data.created_by_name} (${emailOrPhone})`;
    } else if (orderLine.ack.data?.created_by_name) {
      created_by = orderLine.ack.data.created_by_name;
    } else if (emailOrPhone) {
      created_by = emailOrPhone;
    }
    const tooltipText = t('{{action}} by {{created_by}}', {
      action: orderLine.ack.status === 'rejected' ? 'Rejected' : 'Acknowledged',
      created_by,
    });
    return (
      <Ack>
        <OrderLineRow>
          <OrderLineColumn1>
            <OrderLineText>{t('Acknowledgement note')}:</OrderLineText>
          </OrderLineColumn1>
          <OrderLineColumn2>
            <OrderLineText>{orderLine.ack.notes || ' '}</OrderLineText>
          </OrderLineColumn2>
        </OrderLineRow>
        <OrderLineRow style={{ alignItems: 'center' }}>
          <OrderLineColumn1>
            {orderLine.ack.status === 'rejected' ? t('Rejected at') : t('Acknowledged at')}:
          </OrderLineColumn1>
          <OrderLineColumn2>
            <Tooltip placement="top" title={tooltipText} overlayStyle={{ maxWidth: '100%' }}>
              <BadgeContainer>
                <AckTimestamp style={orderLine.ack.status === 'rejected' ? { borderColor: '#D8D8D8' } : undefined}>
                  <AckTimestampText style={orderLine.ack.status === 'rejected' ? { color: '#868686' } : undefined}>
                    {dayjs(orderLine.ack.updated_at).format(TIME_FORMAT)}
                  </AckTimestampText>
                </AckTimestamp>
              </BadgeContainer>
            </Tooltip>
          </OrderLineColumn2>
        </OrderLineRow>
      </Ack>
    );
  }

  return (
    <AckContainer>
      <AckEdit>
        <AckTitle>
          <AckTitleText>
            {t('Acknowledge service request ({{name}}, {{quantity}})', {
              quantity: orderLine.quantity,
              name: orderLine.service_type_name,
            })}
          </AckTitleText>
        </AckTitle>
        <InputRow>
          <Input
            autocomplete="off"
            name="notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            placeholder={t('Write a note')}
            style={{ minWidth: '100px', width: '100%' }}
            inline
            wrapperStyle={{
              width: '100%',
              marginRight: '8px',
            }}
          />
          <ButtonContainer>
            <Popconfirm
              title={t('Are you sure you want to acknowledge service order line {{name}}?', {
                name: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
              })}
              onConfirm={() => createDataToBeSent('accepted')}
              okText={t('Yes')}
              okType="primary"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery"
              key="action-1"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight>{t('Acknowledge')}</ButtonLight>
            </Popconfirm>
            <Popconfirm
              title={t('Are you sure you want to reject service order line {{name}}?', {
                name: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
              })}
              onConfirm={() => createDataToBeSent('rejected')}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-cancel"
              key="action-2"
              cancelButtonProps={{ type: 'link' }}
            >
              <span>
                <Button cancel>{t('Reject')}</Button>
              </span>
            </Popconfirm>
          </ButtonContainer>
        </InputRow>
      </AckEdit>
    </AckContainer>
  );
};

const ServiceOrderDeliveries = ({ handleDeliverySave, namespace, orderLine }) => {
  const { t } = useTranslation(namespace);
  const [notes, setNotes] = useState('');
  const [quantity, setQuantity] = useState(orderLine?.quantity ? `${orderLine.quantity}` : null);
  const [deliveryTime, setDeliveryTime] = useState(dayjs().format());

  const createDataToBeSent = status => {
    if (orderLine?.deliveries?.length > 0) {
      handleDeliverySave(
        {
          id: orderLine?.deliveries[0].id,
          status: status,
          quantity: parseFloat(quantity),
          delivery_time: dayjs(deliveryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          notes,
          email: orderLine.ack?.email || orderLine.service_provider_email,
          phone: orderLine.ack?.phone || orderLine.service_provider_phone,
        },
        status
      );
    } else {
      handleDeliverySave(
        {
          status: status,
          quantity: parseFloat(quantity),
          delivery_time: dayjs(deliveryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          notes,
          email: orderLine?.ack?.email || orderLine?.service_provider_email,
          phone: orderLine?.ack?.phone || orderLine?.service_provider_phone,
        },
        status
      );
    }
  };

  if (!orderLine?.ack || orderLine.ack?.status === 'rejected') {
    return null;
  }
  if (orderLine?.deliveries?.length) {
    return orderLine.deliveries.map(delivery => {
      const deliveryTime = delivery.delivery_time ? delivery.delivery_time : delivery.updated_at;
      let updated_by = delivery.updated_by || '';
      if (delivery.data?.updated_by_name && delivery.data?.updated_by_email) {
        updated_by = `${delivery.data.updated_by_name} (${delivery.data.updated_by_email})`;
      } else if (delivery.data?.updated_by_name) {
        updated_by = delivery.data.updated_by_name;
      } else if (delivery.data?.updated_by_email) {
        updated_by = delivery.data.updated_by_email;
      }
      const tooltipText = t('{{action}} by {{updated_by}}', {
        action: delivery.status === 'cancelled' ? 'Cancelled' : 'Delivered',
        updated_by,
      });
      return (
        <Delivery key={`delivery-${delivery.id}`}>
          <OrderLineRow>
            <OrderLineColumn1>
              <OrderLineText>{t('Delivery note')}:</OrderLineText>
            </OrderLineColumn1>
            <OrderLineColumn2>
              <OrderLineText>{delivery.notes || ' '}</OrderLineText>
            </OrderLineColumn2>
          </OrderLineRow>
          <OrderLineRow style={{ alignItems: 'center' }}>
            <OrderLineColumn1>
              {delivery.status === 'cancelled' ? t('Delivery cancelled at') : t('Delivered at')}:
            </OrderLineColumn1>
            <OrderLineColumn2>
              <Tooltip placement="top" title={tooltipText} overlayStyle={{ maxWidth: '100%' }}>
                <BadgeContainer>
                  <DeliveryTimestamp
                    style={
                      delivery.status === 'cancelled' ? { borderColor: '#D8D8D8', backgroundColor: '#fff' } : undefined
                    }
                  >
                    <DeliveryTimestampText style={delivery.status === 'cancelled' ? { color: '#868686' } : undefined}>
                      {dayjs(deliveryTime).format(TIME_FORMAT)}
                    </DeliveryTimestampText>
                    <DeliveryTimestampText style={delivery.status === 'cancelled' ? { color: '#868686' } : undefined}>
                      {delivery.quantity}
                    </DeliveryTimestampText>
                  </DeliveryTimestamp>
                </BadgeContainer>
              </Tooltip>
            </OrderLineColumn2>
          </OrderLineRow>
        </Delivery>
      );
    });
  }

  return (
    <DeliveryContainer>
      <DeliveryEdit>
        <InputRow style={{ alignItems: 'center', marginBottom: '1rem' }}>
          <InputWrapper inline>
            <StyledInputNumber
              name="quantity"
              placeholder={t('Quantity')}
              value={quantity}
              decimalSeparator={','}
              min={0}
              onChange={e => setQuantity(e)}
            />
          </InputWrapper>
          <DatePicker
            name="delivery_time"
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 15 }}
            style={{
              color: '#4a4a4a',
              marginRight: '8px',
              height: '100%',
              padding: '0.5rem',
              display: 'flex',
            }}
            value={deliveryTime ? dayjs(deliveryTime) : null}
            onChange={value => setDeliveryTime(value ? value.format() : value)}
            placeholder={t('Delivery time')}
          />
          <ButtonContainer>
            <Popconfirm
              title={t('Are you sure you want to deliver service {{name}}?', {
                name: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
              })}
              onConfirm={() => createDataToBeSent('done')}
              okText={t('Yes')}
              okType="primary"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery-done"
              key="delivery-action-1"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight>{t('Deliver')}</ButtonLight>
            </Popconfirm>
          </ButtonContainer>
        </InputRow>
        <InputRow style={{ alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          <Input
            name="notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            placeholder={t('Write a note')}
            style={{ minWidth: '100px', width: '100%' }}
            inline
            wrapperStyle={{
              width: '100%',
              marginRight: '8px',
            }}
          />
          <ButtonContainer>
            <Popconfirm
              title={t('Are you sure you want to cancel service {{name}} delivery?', {
                name: orderLine.service_provider_name + ' ' + orderLine.service_type_name,
              })}
              onConfirm={() => createDataToBeSent('cancelled')}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery-cancel"
              key="delivery-action-2"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight cancel>{t('Cancel')}</ButtonLight>
            </Popconfirm>
          </ButtonContainer>
        </InputRow>
      </DeliveryEdit>
    </DeliveryContainer>
  );
};

export default ServiceOrderLineForm;
