import React, { useContext } from 'react';
import styled from 'styled-components';
import { FleetServiceOrderContext } from '../../../context/FleetServiceOrderContext';

import { FleetViewContext } from '../../../context/FleetViewContext';

import Vessel from './Vessel';

const RowContainer = styled.div`
  position: relative;
`;

const CalendarChildRow = styled.div`
  box-sizing: border-box;
  z-index: 40;

  ${props => {
    if (props.isEvenRow) {
      return 'background-color: ' + props.theme.color.grey_lighter4;
    } else {
      return 'background-color: white;';
    }
  }}
`;

const PaddingRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  height: ${props => (props.smaller ? 6 : 18)}px;
  background-color: white;
`;

export const CalendarRow = ({ port, index }) => {
  const { canvasWidth, rowHeight, sidebarSelection } = useContext(FleetViewContext);
  const { serviceOrders = [] } = useContext(FleetServiceOrderContext);

  const createRows = () => {
    let rows = [];

    rows.push(
      <PaddingRow
        key={'padding-row-1'}
        style={{
          width: `${canvasWidth}px`,
        }}
        index={index}
        smaller={sidebarSelection === 'port'}
      />
    );

    for (let i = 0; i < Math.max(port.row_count, 4); i++) {
      const berth = port.berthList.find(b => {
        return b.row_start <= i && i <= b.row_end;
      });
      rows.push(
        <CalendarChildRow
          key={`row-child-${index}-${i}`}
          style={{
            width: `${canvasWidth}px`,
            height: `${rowHeight}px`,
          }}
          isEvenRow={berth?.even || false}
        />
      );
    }

    rows.push(
      <PaddingRow
        key={'padding-row-2'}
        style={{
          width: `${canvasWidth}px`,
        }}
        index={index}
        smaller={true}
      />
    );

    return rows;
  };

  return (
    <RowContainer key={`row-container-${index}`}>
      {createRows()}
      {port.berthList.map(b => {
        return b.portcalls.map((v, j) => (
          <Vessel
            key={j}
            vessel={v}
            rowIndex={b.row_start}
            serviceOrders={serviceOrders.filter(so => so.port_call_id == v.master_id)}
          />
        ));
      })}
    </RowContainer>
  );
};
