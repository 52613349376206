import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMapEvents, useMap, Marker, Tooltip, CircleMarker } from 'react-leaflet';
import L from 'leaflet';

import Button from '../ui/Button';
import Icon from '../ui/Icon';

import { getOptions } from '../map/MapUtils';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';

import shipIcon from '../../images/icons/ship-bg.svg';
import MapMarker from '../map/MapMarker';
import { onMarkerMouseOver } from '../map/MapHelpers';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 8px;
  bottom: 8px;
  display: inline-grid;
  margin: 0;
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
`;

const ColorLegend = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  z-index: 400;
  width: 42px;
`;

const ColorContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 2px;
`;

const Color = styled.div`
  background-color: ${props => props.color};
  height: 10px;
  width: 24px;
  border-radius: 3px;
`;

const Limit = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0px;
`;

const ColorHeader = styled.div`
  font-weight: 600;
`;

const COLORS = [
  {
    color: '#f10e2e',
    limit: 15,
  },
  {
    color: '#9d2f32',
    limit: 14,
  },
  {
    color: '#815843',
    limit: 13,
  },
  {
    color: '#70822b',
    limit: 12,
  },
  {
    color: '#51a342',
    limit: 11,
  },
  {
    color: '#55d366',
  },
];

const MapWithRouteMapInnerContainer = ({ zoom, setZoom, dataList, hoverData, setHoverData, markerData }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();
  const map = useMap();

  const [createDrawings, setCreateMapDrawings] = useState(true);
  const [direction, setDirection] = useState('top');

  useEffect(() => {
    const getColor = speed => {
      for (let i = 0; i <= COLORS.length - 1; i++) {
        if (!COLORS[i].limit) {
          return COLORS[i].color;
        } else if (speed > COLORS[i].limit) {
          return COLORS[i].color;
        }
      }
    };

    if (map && createDrawings && dataList && dataList.length > 0) {
      setCreateMapDrawings(false);
      let dots = dataList.map((d, index) => {
        return L.circleMarker([d.lat, d.lon], {
          color: getColor(d.speed),
          radius: 0.25,
          opacity: '0.85',
          time: d.timestamp,
        })
          .on('mouseover', function() {
            setHoverData(index);
          })
          .on('mouseout', function() {
            setHoverData(undefined);
          })
          .bindTooltip(
            `<div>
            <div>${t('Time')}: <b>${d.time ? dayjs(d.time).format(TIME_FORMAT) : '-'}</b></div>
            <div>${t('Speed')}: <b>${d.speed} ${t('knots')}</b></div>
            <div>${t('Latitude')}: <b>${d.lat}</b></div>
            <div>${t('Longitude')}: <b>${d.lon}</b></div>
          </div>`,
            { direction: 'top' }
          );
      });

      let polyline = L.polyline(
        dataList.map(d => {
          return [d.lat, d.lon];
        }),
        {
          color: '#4990DD',
          weight: '2',
          opacity: '0.85',
        }
      ).addTo(map);

      L.layerGroup(dots).addTo(map);
      map.fitBounds(polyline.getBounds());
      setZoom(map.getZoom());
    }
  }, [createDrawings, dataList, map, setHoverData, setZoom, t]);

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  useEffect(() => {
    map.invalidateSize();
  });

  const getVesselIcon = () => {
    return L.divIcon({
      html: `<div class="div-map-marker-vessel">
      <img src="${shipIcon}"
        style="height:16px;" />`,
      iconSize: [16, 16],
      iconAnchor: [8, 8],
      className: 'map-vessel-icon-class',
    });
  };

  const latestSpot = dataList && dataList.length > 0 ? dataList[dataList.length - 1] : undefined;

  return (
    <>
      <ZoomButtonContainer>
        <ZoomButton data-testid="map-zoom-in" copy onClick={() => handleZoomButton(zoom + 1)}>
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton data-testid="map-zoom-out" copy onClick={() => handleZoomButton(zoom - 1)}>
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      <ColorLegend>
        <ColorHeader>{t('Knots')}</ColorHeader>
        {COLORS.map((color, i) => (
          <ColorContainer key={i}>
            <Color color={color.color} />
            <Limit>{color.limit}</Limit>
          </ColorContainer>
        ))}
      </ColorLegend>
      {markerData?.map((m, i) => (
        <MapMarker key={i} feature={m} appliedZoomLevel={zoom} />
      ))}
      {!!latestSpot && (
        <Marker
          icon={getVesselIcon()}
          position={[latestSpot.lat, latestSpot.lon]}
          eventHandlers={{
            mouseover: e => {
              setHoverData(e.target.options.time);
              onMarkerMouseOver(map, [latestSpot.lon, latestSpot.lat], direction, setDirection);
            },
            mouseout: () => {
              setHoverData(undefined);
            },
          }}
        >
          <Tooltip direction={direction} key={direction} opacity={1}>
            <div>
              <div style={{ marginBottom: '6px' }}>
                <b>{t('Latest position')}</b>
              </div>
              <div>
                {t('Time')}: <b>{latestSpot.time ? dayjs(latestSpot.time).format(TIME_FORMAT) : '-'}</b>
              </div>
              <div>
                {t('Speed')}:{' '}
                <b>
                  {latestSpot.speed} {t('knots')}
                </b>
              </div>
              <div>
                {t('Latitude')}: <b>{latestSpot.lat}</b>
              </div>
              <div>
                {t('Longitude')}: <b>{latestSpot.lon}</b>
              </div>
            </div>
          </Tooltip>
        </Marker>
      )}
      {!!hoverData && !!dataList[hoverData] && (
        <CircleMarker
          center={[dataList[hoverData].lat, dataList[hoverData].lon]}
          radius={4}
          pathOptions={{
            color: '#4990DD',
            opacity: 1,
          }}
        />
      )}
    </>
  );
};

export default MapWithRouteMapInnerContainer;
