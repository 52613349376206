import React from 'react';

import styled from 'styled-components';

import LogoFintraffic from '../images/logo-fintraffic.svg?react';
import LogoSwe from '../images/logo-port-activity-app-swe.svg?react';
import Login from '../views/Login';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 50;
  height: 100px;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.sizing.gap_tiny};
  box-shadow: ${({ theme }) => theme.fx.box_shadow_sharp};
  svg {
    fill: ${({ theme }) => theme.color.white};
  }
  @media print {
    display: none;
  }
  @media (min-width: 900px) {
    padding: ${({ theme }) => theme.sizing.gap_medium};
    min-width: 100px;
  }
`;

const LandingHeader = ({ isSwedishVersion }) => {
  return (
    <HeaderContainer>
      {isSwedishVersion ? <LogoSwe /> : <LogoFintraffic />}
      <Login />
    </HeaderContainer>
  );
};

export default LandingHeader;
