import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { WikiContext } from '../../context/WikiContext';
import HelpModal from '../info/HelpModal';
import { SideHelpType } from '../info/WikiConstants';
import MarkDownEditor from '../info/MarkDownEditor';
import ButtonLight from '../ui/ButtonLight';
import { AlertContext } from '../../context/AlertContext';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import Icon from '../ui/Icon';
import ListActionButton from '../ui/ListActionButton';

const Container = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const CreateInfo = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

const Info = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

const Pages = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 6px;
  width: 100%;
`;

const PagesTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding-top: 5px;
  padding-left: 10px;
  position: relative;
  cursor: pointer;
`;

const PagesText = styled.div`
  margin-right: 12px;
`;

const FolderIcon = styled.div`
  position: absolute;
  right: 6px;
  top: 8px;
  line-height: 18px;
  margin-left: 3px;
  margin-top: -2px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const PagesList = styled.div`
  padding-top: 2px;
  border-top: 1px solid #d8d8d8;
  max-height: 200px;
  overflow-y: auto;
`;

const Link = styled.div`
  min-width: 12px;
  min-height: 12px;
`;

const PageListRow = styled.div`
  padding: 6px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Title = styled.div`
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  margin-top: 12px;
  padding-bottom: 3px;
  font-weight: 6;
  font-size: 16px;
`;

const Actions = styled.div`
  justify-content: space-between;
  display: flex;
  position: relative;
`;

const IconContainer = styled.div`
  svg {
    width: 22px;
    height: 22px;
    margin-top: 8px;
  }
`;

const ActionsText = styled.div`
  font-size: 14px;
  padding: 3px 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    background-color: #e8e8e8;
  }
`;

const Popover = styled.div`
  position: absolute;
  padding: 2px;
  top: 32px;
  right: 0px;
  z-index: 999;
  background-color: white;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  max-width: 300px;
  display: inline-grid;
`;

const SideHelp = () => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { availableWikiLinks, sideHelpPages, fetchWikiLinkData, fetchWikiData } = useContext(WikiContext);
  const { alertsHeight } = useContext(AlertContext);

  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [startLoader, setStartLoader] = useState(false);
  const [shouldRefetchData, setShouldRefetchData] = useState(false);

  // const [search, setSearch] = useState('');
  const [pagesOpen, setPagesOpen] = useState(false);

  const [chosenPage, setChosenPage] = useState(undefined);

  const findPath = txt => {
    const secondSlash = txt.indexOf('/', txt.indexOf('/') + 1);
    if (secondSlash === -1) {
      return '/';
    }

    const splitted = txt.split('/');
    let splitLength = 0;
    if (!isNaN(splitted[splitted.length - 1])) {
      splitLength = splitted[splitted.length - 1].length;
    }

    let currentPath = txt.substring(secondSlash, txt.length - splitLength);

    if (currentPath.charAt(currentPath.length - 1) !== '/') {
      currentPath += '/';
    }
    return currentPath;
  };

  const path = findPath(window.location.pathname);
  const origMarkdownId = availableWikiLinks?.find(l => l.object_type === SideHelpType && l.object_id === 'path:' + path)
    ?.markdown_id;
  const markdownId = chosenPage || origMarkdownId;

  const markdownTitle = sideHelpPages?.find(p => p.id === markdownId)?.title;

  const linkPage = async () => {
    const idsToBeDeleted =
      availableWikiLinks?.filter(l => l.object_type === SideHelpType && l.object_id === 'path:' + path) || [];

    for (const link of idsToBeDeleted) {
      await apiCall('delete', 'wiki/unlink', {
        markdown_id: link.markdown_id,
        object_type: link.object_type,
        object_id: link.object_id,
      });
    }

    await apiCall('post', 'wiki/link', {
      markdown_id: markdownId,
      object_type: SideHelpType,
      object_id: 'path:' + path,
      markdown_anchor: undefined,
      lat: undefined,
      lon: undefined,
      short_text: undefined,
    });
    fetchWikiLinkData();
  };

  const removeLink = async () => {
    if (markdownId) {
      await apiCall('delete', 'wiki/unlink', {
        markdown_id: markdownId,
        object_type: SideHelpType,
        object_id: 'path:' + path,
      });
    }
    fetchWikiLinkData();
  };

  const refetchWikiData = () => {
    fetchWikiData();
    setShouldRefetchData(true);
  };

  return (
    <Container alertsHeight={alertsHeight}>
      {/* <Input placeholder={t('Search')} value={search} onChange={e => setSearch(e)} /> */}

      <Pages>
        <PagesTopRow onClick={() => setPagesOpen(!pagesOpen)}>
          <PagesText>{t('Pages')}</PagesText>
          {pagesOpen ? (
            <FolderIcon>
              <Icon type="chevron-down" />
            </FolderIcon>
          ) : (
            <FolderIcon>
              <Icon type="chevron-right-2" />
            </FolderIcon>
          )}
        </PagesTopRow>
        {pagesOpen && (
          <PagesList>
            {sideHelpPages?.map(p => {
              return (
                <PageListRow
                  key={p.id}
                  onClick={() => {
                    setChosenPage(p.id);
                    setPagesOpen(false);
                  }}
                >
                  {p.title}
                </PageListRow>
              );
            })}
          </PagesList>
        )}
      </Pages>

      {user.permissions.includes('manage_wiki') && !!markdownId && (
        <Actions>
          <Link>
            {origMarkdownId === markdownId && (
              <Tooltip
                title={t('This page is linked to current URL.')}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
              >
                <IconContainer>
                  <Icon type="link" />
                </IconContainer>
              </Tooltip>
            )}
          </Link>
          <ActionsText onClick={() => setPopoverOpen(!popoverOpen)}>{t('Actions')}</ActionsText>

          {popoverOpen && (
            <Popover id="side-help-actions">
              <ListActionButton
                disabled={false}
                key="action-1"
                onClick={() => {
                  setHelpModalOpen(true);
                  setPopoverOpen(false);
                }}
              >
                <Icon type="edit" />
                {t('Edit page')}
              </ListActionButton>
              {origMarkdownId === markdownId ? (
                <Popconfirm
                  title={t('Are you sure you want to remove the link from this help page?')}
                  onConfirm={() => {
                    setPopoverOpen(false);
                    removeLink();
                  }}
                  okText={t('Yes')}
                  okType="danger"
                  cancelText={t('No')}
                  icon={null}
                  id="pop-confirm-for-new-list"
                  key="action-2"
                >
                  <ListActionButton disabled={false}>
                    <Icon type="link" />
                    {t('Unlink current URL from this page')}
                  </ListActionButton>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title={t('Are you sure you want to link this URL to this help page?')}
                  onConfirm={() => linkPage()}
                  okText={t('Yes')}
                  okType="danger"
                  cancelText={t('No')}
                  icon={null}
                  id="pop-confirm-for-new-list"
                  key="action-2"
                >
                  <ListActionButton disabled={false}>
                    <Icon type="link" />
                    {t('Link current URL to this page')}
                  </ListActionButton>
                </Popconfirm>
              )}
            </Popover>
          )}
        </Actions>
      )}

      {markdownId ? (
        <>
          <Title>{markdownTitle}</Title>
          <MarkDownEditor
            markdownId={markdownId}
            editable={false}
            startLoader={startLoader}
            setStartLoader={setStartLoader}
            shouldRefetchData={shouldRefetchData}
            setShouldRefetchData={setShouldRefetchData}
            canBeSmall={true}
          />
        </>
      ) : (
        <CreateInfo>
          <Info>{t('No info page available.')}</Info>
          {user.permissions.includes('manage_wiki') && (
            <ButtonLight onClick={() => setHelpModalOpen(true)}>{t('Create new info page')}</ButtonLight>
          )}
        </CreateInfo>
      )}

      {!!helpModalOpen && (
        <HelpModal
          open={!!helpModalOpen}
          setOpen={() => {
            setHelpModalOpen(false);
          }}
          objectType={SideHelpType}
          objectId={'path:' + path}
          backUpId={'path:do_not_remove_this_link'}
          coordinates={undefined}
          onlyFullMatch={true}
          usableMarkdownId={markdownId}
          refetchWikiData={refetchWikiData}
          openEditFirst={true}
        />
      )}
    </Container>
  );
};

export default SideHelp;
