import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import { TIME_FORMAT } from '../../../utils/constants';

import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import DebounceAutocomplete from '../../ui/DebounceAutocomplete';

import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import ModalLinks from './ModalLinks';
import MovableModal from '../../activity/MovableModal';

/* Vessel states: notPlanned=1, uncommitted=2, planned=3, reserved=4 */

// const VESSEL_STATE_NOT_PLANNED = 1;
// const VESSEL_STATE_UNCOMMITTED = 2;
const VESSEL_STATE_PLANNED = 3;
// const VESSEL_STATE_RESERVED = 4;
// const VESSEL_STATE_ACCEPTED = 5;
// const VESSEL_STATE_OFFERED = 6;
// const VESSEL_STATE_UPDATED = 7;

const Blocker = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
`;

const ColumnsSplit = styled.div`
  display: block;
  width: 100%;
  padding-right: 9px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-bottom: 2px;
  min-width: 800px;
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  padding-left: 16px;
  display: inline-block;
  margin-bottom: 8px;
`;

const SecondColumnSplit = styled.div`
  width: 100%;
  padding-left: 16px;
  height: auto;
  display: inline-block;
  overflow-x: hidden;
`;

const ModalInnerInput = styled.div`
  min-height: 195px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const RowContainerSplit = styled.div`
  padding: ${props => (props.dataLength === 1 ? '0 7px 7px 0' : '0 7px 15px 0')};
  width: 100%;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-right: 12px;
  margin-bottom: -6px;

  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: 1px solid transparent;
    }
  }
`;

const ActionButtonsReadOnly = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-right: 12px;
  margin-bottom: -6px;

  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
  }
`;

const Bollards = styled.div`
  display: flex;

  div {
    width: 100%;
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const InfoRowSplit = styled.div`
  margin: 0 0 8px;
  width: auto;
  padding-right: 25px;
`;

const InfoContainer = styled.div`
  width: auto;
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
`;

const ShipInfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 12px;
`;

const TrashButton = styled(Button)`
  margin-left: 7px;
  margin-right: 5px;
  color: #1c1c1c;
  :hover {
    color: #d0011c;
  }
`;

const AddButton = styled(Button)`
  margin-left: 0px;
  padding-right: 0px;
  color: #4990dd;
  :hover {
    color: #3483d9;
  }
`;

const RowContainerSplitButtons = styled.div`
  padding: 0px 0px 7px 7px;
  display: inline;
  min-width: 10px;
`;

const AddBerthTransitionsLink = styled.a`
  text-transform: none;
  letter-spacing: 0;
`;

const DeleteModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const Warning = styled.div`
  color: #d0011c;
  margin-left: 38px;
  font-size: 13px;
  height: 24px;
`;

const InfoText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const DeleteActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ExtraRow = styled.div`
  display: flex;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AdditionalDataWithHeader = styled.div`
  padding: 8px 0px;
  padding-left: 16px;
  margin-left: 8px;
  background-color: #f0f0f0;
  border-radius: 3px;
`;

const AdditionalDataHeader = styled.div`
  display: block;
  margin-top: 6px;
  font-weight: 600;
`;

const AdditionalData = styled.div`
  display: block;
  margin-top: 6px;
`;

const AdditionalDataContainer = styled.div`
  display: flex;
  margin-left: 36px;
`;

const FullRow = styled.div`
  min-width: 200px;
  display: flex;
`;

const AddOrEditBerthPlanModal = ({ type, closeModal, vessel, readOnly, additionalData }) => {
  const { berths, updateVesselLocation, handlePortcallDelete, handleAdditionalData, dynamicBollards } = useContext(
    BerthPlanningToolContext
  );
  const { namespace, apiCall, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const additionalDataRef = useRef();

  const [linksDisabled, setLinksDisabled] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [additionalTypeList, setAdditionalTypeList] = useState(['Cargo']); // eslint-disable-line no-unused-vars

  const [inputData, setInputData] = useState(
    vessel.berth_transitions?.length
      ? vessel.berth_transitions.reduce((obj, bt, i) => {
        obj[i] = {
          agent: bt ? bt.agent : null,
          pta: bt ? (bt.pta ? dayjs(bt.pta) : dayjs(bt.eta)) : '',
          ptd: bt ? (bt.ptd ? dayjs(bt.ptd) : dayjs(bt.etd)) : '',
          berth_code: bt ? bt.berth_code : null,
          start_bollard: bt ? bt.start_bollard : null,
          end_bollard: bt ? bt.end_bollard : null,
          direction_down: !!bt.directionDown,
        };
        return obj;
      }, {})
      : {
          0: {
            agent: vessel ? vessel.agent : null,
            pta: vessel ? (vessel.pta ? dayjs(vessel.pta) : dayjs(vessel.eta)) : '',
            ptd: vessel ? (vessel.ptd ? dayjs(vessel.ptd) : dayjs(vessel.etd)) : '',
            berth_code: vessel ? vessel.berth_code : null,
            start_bollard: vessel ? vessel.start_bollard : null,
            end_bollard: vessel ? vessel.end_bollard : null,
            direction_down: !!vessel.directionDown,
          },
        }
  );

  const [sending, setSending] = useState(false);

  const createAdditionalInput = data => {
    let newData = [];

    if (data) {
      Object.keys(data).forEach(category => {
        let item = {};
        item.type = category;
        item.items = data[category].map(e => {
          return {
            name: e.name,
            value: e.value,
          };
        });
        newData.push(item);
      });
    }

    return newData;
  };

  const [additionalInput, setAdditionalInput] = useState(createAdditionalInput(additionalData));

  useEffect(() => {
    if (!additionalDataRef.current && additionalData) {
      setAdditionalInput(createAdditionalInput(additionalData));
    }

    additionalDataRef.current = additionalData;
  }, [additionalData]);

  const title = type === 'add' || readOnly ? t('Berth plan') : t('Edit berth plan');

  const findDuplicates = list => {
    return list.filter((item, index) => list.indexOf(item) !== index).length > 0;
  };

  const listDataIsValid = () => {
    let validData = true;

    for (let index in inputData) {
      let transition = inputData[index];

      if (!transition.pta || !transition.ptd || !transition.berth_code) {
        validData = false;
        break;
      }

      if (validData && transition.berth_code !== 'unknown') {
        let validStartBollard = !!transition.start_bollard && !!transition.start_bollard.length;
        let validEndBollard = !!transition.end_bollard && !!transition.end_bollard.length;

        if ((validStartBollard && !validEndBollard) || (!validStartBollard && validEndBollard)) {
          validData = false;
          break;
        }
      }
    }

    if (validData && findDuplicates(additionalInput.map(i => i.type))) {
      validData = false;
    }

    if (validData) {
      for (let index in additionalInput) {
        let category = additionalInput[index];

        if (validData && findDuplicates(category.items.filter(i => !i.deleted).map(i => i.name))) {
          validData = false;
          break;
        }

        if (category.items.findIndex(i => !i.deleted && (!i.name || !i.value)) > -1) {
          validData = false;
          break;
        }

        if (!category.deleted && category.items.filter(i => !i.deleted).length === 0) {
          validData = false;
          break;
        }
      }
    }

    return validData;
  };

  const setInputDataToState = stateData => {
    if (!linksDisabled) {
      setLinksDisabled(true);
    }
    setInputData(stateData);
  };

  const handleBerthChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['berth_code'] = value;
    newInputData[index]['start_bollard'] = null;
    newInputData[index]['end_bollard'] = null;
    setInputDataToState(newInputData);
  };

  const handleFromBollardChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['start_bollard'] = value;
    setInputDataToState(newInputData);
  };

  const handleToBollardChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['end_bollard'] = value;
    setInputDataToState(newInputData);
  };

  const updateAgentSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['agent'] = value;
    setInputDataToState(newInputData);
  };

  const onPTAChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['pta'] = value;
    setInputDataToState(newInputData);
  };

  const onPTDChangeSplit = (value, index) => {
    let newInputData = { ...inputData };
    newInputData[index]['ptd'] = value;

    if (newInputData[Number(index) + 1] && !newInputData[Number(index) + 1]['pta']) {
      newInputData[Number(index) + 1]['pta'] = value;
    }

    setInputDataToState(newInputData);
  };

  const closeDeleteModal = () => {
    setDeleteConfirmationOpen(false);
  };

  const sendData = async () => {
    setSending(true);

    let result;
    try {
      if (Object.keys(inputData).length === 1) {
        let element = inputData[0];
        let bollards = berths.find(g => g.id === element.berth_code).subRows;
        const startBollardIndex = bollards.findIndex(b => b.id === element.start_bollard);
        const endBollardIndex = bollards.findIndex(b => b.id === element.end_bollard);
        const directionDown = startBollardIndex <= endBollardIndex;
        result = await updateVesselLocation({
          ...inputData[0],
          pta: element.pta.format(),
          ptd: element.ptd.format(),
          port_call_master_id: vessel.port_call_master_id.split('___transition_')[0],
          berth_transitions: [],
          direction_down: directionDown,
        });
      } else {
        const splitDataList = [];
        Object.keys(inputData).forEach(elementId => {
          let element = inputData[elementId];
          let bollards = berths.find(g => g.id === element.berth_code).subRows;
          const startBollardIndex = bollards.findIndex(b => b.id === element.start_bollard);
          const endBollardIndex = bollards.findIndex(b => b.id === element.end_bollard);
          const directionDown = startBollardIndex <= endBollardIndex;
          splitDataList.push({
            agent: element.agent,
            pta: element.pta.format(),
            ptd: element.ptd.format(),
            berth_code: element.berth_code !== 'unknown' ? element.berth_code : null,
            start_bollard: dynamicBollards ? 'B1' : element.start_bollard,
            end_bollard: dynamicBollards ? 'B1' : element.end_bollard,
            direction_down: directionDown,
          });
        });
        result = await updateVesselLocation({
          port_call_master_id: vessel.port_call_master_id.split('___transition_')[0],
          berth_transitions: splitDataList,
        });
      }

      if (user.permissions.includes('manage-additional-data')) {
        handleAdditionalData(additionalInput, vessel.port_call_id);
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal();
    } else {
      setSending(false);
    }
  };

  const addSplitButton = index => {
    let newInputData = { ...inputData };
    let objLength = Object.keys(newInputData).length;

    if (Number(index) === objLength - 1) {
      newInputData[objLength] = {
        agent: newInputData[objLength - 1].agent,
        pta: null,
        ptd: newInputData[objLength - 1].ptd,
        berth_code: newInputData[objLength - 1].berth_code,
        start_bollard: newInputData[objLength - 1].start_bollard,
        end_bollard: newInputData[objLength - 1].end_bollard,
      };

      newInputData[objLength - 1].ptd = null;
    } else {
      for (let i = objLength - 1; i > -1; i--) {
        if (i > Number(index)) {
          newInputData[i + 1] = { ...newInputData[i] };
        } else if (i === Number(index)) {
          newInputData[i + 1] = {
            agent: newInputData[i].agent,
            pta: null,
            ptd: null,
            berth_code: newInputData[i].berth_code,
            start_bollard: newInputData[i].start_bollard,
            end_bollard: newInputData[i].end_bollard,
          };
        }
      }
    }

    setInputDataToState(newInputData);
  };

  const removeSplitButton = value => {
    let inputDataForDeleting = { ...inputData };
    let savedPTD = inputDataForDeleting[value].ptd;
    let savedPTA = inputDataForDeleting[value].pta;

    delete inputDataForDeleting[value];

    let objLength = Object.keys(inputDataForDeleting).length;

    if (Number(value) === objLength && !inputDataForDeleting[objLength - 1].ptd) {
      inputDataForDeleting[objLength - 1].ptd = savedPTD;
    }

    if (Number(value) === 0 && !inputDataForDeleting[1].pta) {
      inputDataForDeleting[1].pta = savedPTA;
    }

    let newInputData = {};

    let deletedFound = false;

    for (let i = 0; i < objLength + 1; i++) {
      if (inputDataForDeleting[i] && !deletedFound) {
        newInputData[i] = { ...inputDataForDeleting[i] };
      } else {
        deletedFound = true;
        newInputData[i] = { ...inputDataForDeleting[i + 1] };
      }
    }

    delete newInputData[objLength];

    setInputDataToState(newInputData);
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDelete = async () => {
    handlePortcallDelete(vessel);
    closeModal();
  };

  const fetchAgentList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    const result = await apiCall('get', 'agents', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  const fetchNameList = async value => {
    const params = {
      category: 'Cargo',
      search: value,
      limit: 20,
      offset: 0,
    };
    const result = await apiCall('get', 'additional/category-names', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  const addAdditionalSplitButton = () => {
    const newAdditionalInput = [...additionalInput];
    if (additionalTypeList.length === 1) {
      newAdditionalInput.push({ type: additionalTypeList[0], items: [] });
    } else {
      newAdditionalInput.push({ type: 'new_type', items: [] });
    }
    setAdditionalInput(newAdditionalInput);
  };

  const addAdditionalDataRowButton = index => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].items.push({ name: null, value: null });

    setAdditionalInput(newAdditionalInput);
  };

  const setTypeName = (index, value) => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].type = value;

    setAdditionalInput(newAdditionalInput);
  };

  const setName = (index, index2, value) => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].items[index2].name = value;

    setAdditionalInput(newAdditionalInput);
  };

  const setValue = (index, index2, value) => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].items[index2].value = value.target.value;

    setAdditionalInput(newAdditionalInput);
  };

  const removeAdditionalType = index => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].deleted = true;

    setAdditionalInput(newAdditionalInput);
  };

  const removeAdditionalRow = (index, index2) => {
    const newAdditionalInput = [...additionalInput];
    newAdditionalInput[index].items[index2].deleted = true;

    setAdditionalInput(newAdditionalInput);
  };

  const getExtraInfoValues = () => {
    const keys = Object.keys(vessel.extra_info);

    return keys.map(key => {
      return (
        <InfoRowSplit key={key}>
          {key[0].toUpperCase() + key.substring(1).replace(/[A-Z]/g, s => ' ' + s.toLowerCase())}:
          <BoldText>{vessel.extra_info[key] ? vessel.extra_info[key].toString() : '-'}</BoldText>
        </InfoRowSplit>
      );
    });
  };

  let dataLength = Object.keys(inputData).length;

  const getModalContent = () => {
    return (
      <ModalInnerInput>
        <ColumnsSplit>
          <FirstColumnSplit>
            <InfoContainer>
              <InfoRowSplit>
                {t('Vessel:')}
                <BoldText>{vessel.name}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('IMO:')}
                <BoldText>{vessel.imo}</BoldText>
              </InfoRowSplit>
            </InfoContainer>
            <InfoContainer>
              <InfoRowSplit>
                {t('ETA:')}
                <BoldText>{vessel.eta ? dayjs(vessel.eta).format(TIME_FORMAT) : '-'}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('ETD:')}
                <BoldText>{vessel.etd ? dayjs(vessel.etd).format(TIME_FORMAT) : '-'}</BoldText>
              </InfoRowSplit>
            </InfoContainer>
            <InfoContainer>
              <InfoRowSplit>
                {t('Length:')}
                <BoldText>{vessel.loa}</BoldText>
              </InfoRowSplit>
              <InfoRowSplit>
                {t('Draft:')}
                <BoldText>{vessel.draft}</BoldText>
              </InfoRowSplit>
            </InfoContainer>
            <InfoContainer>
              <InfoRowSplit>
                {t('Master source id:')}
                <BoldText>{vessel.port_call_master_id.split('___transition_')[0]}</BoldText>
              </InfoRowSplit>
            </InfoContainer>
            <ExtraRow>{vessel.extra_info && getExtraInfoValues()}</ExtraRow>
          </FirstColumnSplit>
          <SecondColumnSplit disableScrolling={dataLength < 3}>
            {Object.keys(inputData)
              .sort(function(a, b) {
                return a - b;
              })
              .map(index => {
                let rowObject = inputData[index];

                let bollardOptions =
                  rowObject.berth_code && rowObject.berth_code !== 'unknown'
                    ? berths.find(g => g.id === rowObject.berth_code).subRows
                    : [];

                return (
                  <ShipInfoContainer key={vessel.name + index}>
                    <RowContainerSplit dataLength={dataLength}>
                      {index === '0' ? <RowHeader>{t('Agent')}</RowHeader> : null}
                      {readOnly ? (
                        <Input
                          placeholder={t('Agent name')}
                          value={rowObject.agent || ''}
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <DebounceAutocomplete
                          placeholder={t('Agent name')}
                          fetchOptions={fetchAgentList}
                          onChange={value => updateAgentSplit(value, index)}
                          onSelect={value => updateAgentSplit(value, index)}
                          value={rowObject.agent || ''}
                          style={{ width: '100%' }}
                          allowClear={true}
                          onClear={() => updateAgentSplit('', index)}
                        />
                      )}
                    </RowContainerSplit>
                    <RowContainerSplit dataLength={dataLength}>
                      {index === '0' ? (
                        <RowHeader>
                          {t('PTA')}
                          <Required>*</Required>
                        </RowHeader>
                      ) : null}
                      {readOnly ? (
                        <Input
                          placeholder={t('Planned time of arrival')}
                          value={rowObject.pta ? dayjs(rowObject.pta).format('DD.MM.YYYY HH:mm') : ''}
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime={{ format: 'HH:mm', minuteStep: 15 }}
                          value={rowObject.pta}
                          style={{ width: '100%' }}
                          onChange={value => onPTAChangeSplit(value, index)}
                          placeholder={t('Planned time of arrival')}
                        />
                      )}
                    </RowContainerSplit>
                    <RowContainerSplit dataLength={dataLength}>
                      {index === '0' ? (
                        <RowHeader>
                          {t('PTD')}
                          <Required>*</Required>
                        </RowHeader>
                      ) : null}
                      {readOnly ? (
                        <Input
                          placeholder={t('Planned time of departure')}
                          value={rowObject.ptd ? dayjs(rowObject.ptd).format('DD.MM.YYYY HH:mm') : ''}
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime={{ format: 'HH:mm', minuteStep: 15 }}
                          value={rowObject.ptd}
                          style={{ width: '100%' }}
                          onChange={value => onPTDChangeSplit(value, index)}
                          placeholder={t('Planned time of departure')}
                        />
                      )}
                    </RowContainerSplit>
                    <RowContainerSplit dataLength={dataLength}>
                      {index === '0' ? <RowHeader>{t('Berth')}</RowHeader> : null}
                      {readOnly ? (
                        <Input
                          placeholder={t('Berth name')}
                          value={
                            berths && rowObject.berth_code
                              ? berths.find(b => b.id === rowObject.berth_code)?.short_name
                              : ''
                          }
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <Select
                          listHeight={80}
                          showSearch
                          placeholder={t('Berth name')}
                          optionFilterProp="value"
                          onChange={value => handleBerthChangeSplit(value, index)}
                          style={{ width: '100%' }}
                          options={berths.map(g => {
                            return { label: g.id === 'unknown' ? t('No berth') : g.short_name, value: g.id };
                          })}
                          value={rowObject.berth_code || null}
                        />
                      )}
                    </RowContainerSplit>
                    <Bollards>
                      {!dynamicBollards && (
                        <>
                          <RowContainerSplit dataLength={dataLength}>
                            {index === '0' ? <RowHeader>{t('From bollard')}</RowHeader> : null}
                            {readOnly ? (
                              <Input
                                placeholder={t('Bollard name')}
                                value={
                                  rowObject.start_bollard
                                    ? bollardOptions.find(g => g.id === rowObject.start_bollard)?.short_name
                                    : ''
                                }
                                disabled={true}
                                style={{ width: '100%', minWidth: '131px', color: '#4a4a4a' }}
                              />
                            ) : (
                              <Select
                                showSearch
                                listHeight={80}
                                placeholder={t('Bollard name')}
                                optionFilterProp="value"
                                onChange={value => handleFromBollardChangeSplit(value, index)}
                                style={{ width: '100%', minWidth: '131px' }}
                                options={bollardOptions.map(g => {
                                  return { label: g.short_name, value: g.id };
                                })}
                                name={index}
                                disabled={!rowObject.berth_code || rowObject.berth_code === 'unknown'}
                                value={rowObject.start_bollard}
                                allowClear={true}
                                onClear={() => handleFromBollardChangeSplit('', index)}
                              />
                            )}
                          </RowContainerSplit>
                          <RowContainerSplit dataLength={dataLength}>
                            {index === '0' ? <RowHeader>{t('To bollard')}</RowHeader> : null}
                            {readOnly ? (
                              <Input
                                placeholder={t('Bollard name')}
                                value={
                                  rowObject.end_bollard
                                    ? bollardOptions.find(g => g.id === rowObject.end_bollard)?.short_name
                                    : ''
                                }
                                disabled={true}
                                style={{ width: '100%', minWidth: '131px', color: '#4a4a4a' }}
                              />
                            ) : (
                              <Select
                                listHeight={80}
                                showSearch
                                placeholder={t('Bollard name')}
                                optionFilterProp="value"
                                onChange={value => handleToBollardChangeSplit(value, index)}
                                style={{ width: '100%', minWidth: '131px' }}
                                options={bollardOptions.map(g => {
                                  return { label: g.short_name, value: g.id };
                                })}
                                disabled={!rowObject.berth_code || rowObject.berth_code === 'unknown'}
                                value={rowObject.end_bollard}
                                allowClear={true}
                                onClear={() => handleToBollardChangeSplit('', index)}
                              />
                            )}
                          </RowContainerSplit>
                        </>
                      )}
                      {dataLength > 1 && !readOnly && (
                        <RowContainerSplitButtons>
                          <TrashButton
                            copy
                            style={{
                              zIndex: '5',
                              marginTop: `${index === '0' ? 23 : 0}px`,
                              marginLeft: '0',
                              color: '#D0011C',
                            }}
                            onClick={() => removeSplitButton(index)}
                          >
                            <Icon type={'trash'} />
                          </TrashButton>
                        </RowContainerSplitButtons>
                      )}
                      {dataLength > 1 && !readOnly && (
                        <RowContainerSplitButtons>
                          <AddButton
                            copy
                            style={{
                              zIndex: '5',
                              marginTop: `${index === '0' ? 23 : 0}px`,
                              marginLeft: '0',
                              color: '#4990DD',
                            }}
                            onClick={() => addSplitButton(index)}
                          >
                            <Icon type={'circle-plus'} />
                          </AddButton>
                        </RowContainerSplitButtons>
                      )}
                    </Bollards>
                  </ShipInfoContainer>
                );
              })}
          </SecondColumnSplit>
          {dataLength === 1 && !readOnly && (
            <RowContainerSplitButtons style={{ marginLeft: 'calc(100% - 186px)' }}>
              <Button
                normal
                style={{ zIndex: '5', marginTop: '-5px', marginLeft: '0px', color: '#4990DD' }}
                onClick={() => addSplitButton(0)}
              >
                <Icon type={'circle-plus'} />
                <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                  {t('Add berth transition')}
                </AddBerthTransitionsLink>
              </Button>
            </RowContainerSplitButtons>
          )}
          {dataLength === 1 && readOnly && <div style={{ width: '100%', height: '28px' }} />}
          {modules.additional_data_module === 'enabled' &&
            (user.permissions.includes('view-additional-data') ||
              user.permissions.includes('manage-additional-data')) && (
            <AdditionalDataWithHeader>
              <AdditionalDataHeader>{t('Additional data')}</AdditionalDataHeader>
              {additionalInput.map((k, index) =>
                k.deleted ? null : (
                  <AdditionalData key={index}>
                    <FullRow>
                      <RowContainerSplit dataLength={1} style={{ width: 'calc(100% / 4)' }}>
                        {readOnly || !user.permissions.includes('manage-additional-data') ? (
                          <Input
                            placeholder={t('Name')}
                            value={k.type}
                            disabled={true}
                            style={{ color: '#4a4a4a' }}
                          />
                        ) : (
                          <Select
                            value={k.type === 'new_type' ? '' : k.type}
                            options={[
                              ...additionalTypeList.map(v => {
                                return { value: v, label: v };
                              }),
                            ]}
                            onChange={e => setTypeName(index, e)}
                            placeholder={t('Type')}
                            style={{ width: '100%' }}
                          />
                        )}
                      </RowContainerSplit>
                      {!readOnly &&
                          user.permissions.includes('manage-additional-data') &&
                          k.items.filter(i => !i.deleted).length === 0 && (
                        <RowContainerSplitButtons>
                          <TrashButton
                            copy
                            style={{
                              zIndex: '5',
                              marginTop: '0px',
                              marginLeft: '0',
                              color: '#D0011C',
                            }}
                            onClick={() => removeAdditionalType(index)}
                          >
                            <Icon type={'trash'} />
                          </TrashButton>
                        </RowContainerSplitButtons>
                      )}
                    </FullRow>
                    {additionalInput[index].items.map((d, j) => (
                      <AdditionalDataContainer key={j}>
                        <RowContainerSplit
                          dataLength={2}
                          style={{
                            width: 'calc(100% / 4)',
                            minWidth: '200px',
                            paddingBottom: additionalInput[index].items.filter(i => !i.deleted).length === 0 && '0px',
                          }}
                        >
                          {j === 0 && additionalInput[index].items.filter(i => !i.deleted).length > 0 ? (
                            <RowHeader>
                              {t('Name')}
                              <Required>*</Required>
                            </RowHeader>
                          ) : null}
                          {d.deleted ? null : readOnly || !user.permissions.includes('manage-additional-data') ? (
                            <Input
                              placeholder={t('Name')}
                              value={d.name}
                              disabled={true}
                              style={{ color: '#4a4a4a' }}
                            />
                          ) : (
                            <DebounceAutocomplete
                              placeholder={t('Name')}
                              fetchOptions={fetchNameList}
                              onChange={e => setName(index, j, e)}
                              onSelect={e => setName(index, j, e)}
                              value={d.name || ''}
                              style={{ width: '100%' }}
                              allowClear={true}
                              onClear={() => setName(index, j, '')}
                            />
                          )}
                        </RowContainerSplit>
                        <RowContainerSplit
                          dataLength={2}
                          style={{
                            width: 'calc(100% / 4)',
                            minWidth: '200px',
                            paddingBottom: additionalInput[index].items.filter(i => !i.deleted).length === 0 && '0px',
                          }}
                        >
                          {j === 0 && additionalInput[index].items.filter(i => !i.deleted).length > 0 ? (
                            <RowHeader>
                              {t('Value')}
                              <Required>*</Required>
                            </RowHeader>
                          ) : null}
                          {d.deleted ? null : readOnly || !user.permissions.includes('manage-additional-data') ? (
                            <Input
                              placeholder={t('Value')}
                              value={d.value}
                              disabled={true}
                              style={{ color: '#4a4a4a' }}
                            />
                          ) : (
                            <Input value={d.value} onChange={e => setValue(index, j, e)} placeholder={t('Value')} />
                          )}
                        </RowContainerSplit>
                        {d.deleted
                          ? null
                          : !readOnly &&
                              user.permissions.includes('manage-additional-data') && (
                              <RowContainerSplitButtons>
                                <TrashButton
                                  copy
                                  style={{
                                    zIndex: '5',
                                    marginTop: `${j === 0 ? 23 : 0}px`,
                                    marginLeft: '0',
                                    color: '#D0011C',
                                  }}
                                  onClick={() => removeAdditionalRow(index, j)}
                                >
                                  <Icon type={'trash'} />
                                </TrashButton>
                              </RowContainerSplitButtons>
                            )}
                      </AdditionalDataContainer>
                    ))}

                    {!readOnly && user.permissions.includes('manage-additional-data') && (
                      <RowContainerSplitButtons
                        style={{
                          marginLeft: '36px',
                        }}
                      >
                        <Button
                          normal
                          style={{
                            zIndex: '5',
                            marginLeft: '0px',
                            color: '#4990DD',
                            marginTop: '-4px',
                          }}
                          onClick={() => addAdditionalDataRowButton(index)}
                        >
                          <Icon type={'circle-plus'} />
                          <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                            {t('Add data row')}
                          </AddBerthTransitionsLink>
                        </Button>
                      </RowContainerSplitButtons>
                    )}

                    <Warning>
                      {findDuplicates(additionalInput[index].items.filter(i => !i.deleted).map(i => i.name))
                        ? t('There cannot be two items with the same name.')
                        : ''}
                    </Warning>
                  </AdditionalData>
                )
              )}
              {!readOnly &&
                  user.permissions.includes('manage-additional-data') &&
                  additionalInput.filter(i => !i.deleted).length < additionalTypeList.length && (
                <RowContainerSplitButtons style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                  <Button
                    normal
                    style={{ zIndex: '5', marginLeft: '0px', color: '#4990DD' }}
                    onClick={() => addAdditionalSplitButton()}
                  >
                    <Icon type={'circle-plus'} />
                    <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                      {t('Add additional data')}
                    </AddBerthTransitionsLink>
                  </Button>
                </RowContainerSplitButtons>
              )}

              {!user.permissions.includes('manage-additional-data') &&
                  user.permissions.includes('view-additional-data') &&
                  additionalInput.length === 0 && (
                <RowHeader style={{ marginTop: '6px' }}>{t('No additional data.')}</RowHeader>
              )}
            </AdditionalDataWithHeader>
          )}
        </ColumnsSplit>
        <MiddleLine />
        <BottomRow>
          <ModalLinks vessel={vessel} linksDisabled={linksDisabled} />
          {readOnly ? (
            <ActionButtonsReadOnly>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtonsReadOnly>
          ) : (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight type="button" lightred onClick={openDeleteConfirmation}>
                {t('Delete')}
              </ButtonLight>
              <ButtonLight disabled={!listDataIsValid() || sending} sending={sending} onClick={() => sendData()}>
                {t('Confirm')}
              </ButtonLight>
            </ActionButtons>
          )}
        </BottomRow>
      </ModalInnerInput>
    );
  };

  return (
    <>
      {!deleteConfirmationOpen && <Blocker />}

      <MovableModal title={t(title)} open={true} width={1000} onCancel={closeModal} footer={null}>
        {getModalContent()}
      </MovableModal>

      {deleteConfirmationOpen && (
        <Modal
          title={
            vessel.state === VESSEL_STATE_PLANNED
              ? `${t('Remove BPT generated timestamps for')} ${vessel.name}`
              : `${t('Remove portcall for')} ${vessel.name}`
          }
          open={deleteConfirmationOpen}
          width={800}
          footer={null}
          headStyle={{ backgroundColor: 'red' }}
          onCancel={closeDeleteModal}
        >
          <DeleteModalInner>
            <IconContainer>
              <Icon type="remove-illustration"></Icon>
            </IconContainer>
            {vessel.state === 3 ? (
              <InfoText>{t('Are you sure you want to delete the bpt generated timestamps?')}</InfoText>
            ) : (
              <InfoText>{t('Are you sure you want to delete the portcall?')}</InfoText>
            )}
            <MiddleLine />
            <DeleteActionButtons>
              <ButtonLight type="button" cancel onClick={closeDeleteModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight red onClick={handleDelete}>
                {t('Delete')}
              </ButtonLight>
            </DeleteActionButtons>
          </DeleteModalInner>
        </Modal>
      )}
    </>
  );
};

export default AddOrEditBerthPlanModal;
