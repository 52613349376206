import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NotificationDecision from './NotificationDecision';
import NotificationChildren from './NotificationChildren';

import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT } from '../../utils/constants';
import { formatSender } from '../../utils/utils';
import Icon from '../ui/Icon';
import Modal from 'antd/es/modal';
import DateComponent from '../ui/DateComponent';

const { confirm } = Modal;

const Time = styled(DateComponent)`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-size: 0.8571rem;
`;

const DeleteButton = styled.span`
  display: none;
`;

const DeleteButtonIcon = styled.span`
  cursor: pointer;
`;

const NotificationContent = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  &:hover {
    ${DeleteButton} {
      display: inline;
    }
  }
`;

const Title = styled.div`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
`;

const SentBy = styled.span`
  margin-bottom: 0;
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-size: 0.8571rem;
  color: ${({ theme }) => theme.color.grey};
`;

const StyledNotification = styled.div`
  width: 100%;
`;

const NotificationListItem = ({ data, notificationChildren }) => {
  const [children, setChildren] = useState([]);
  const { apiCall, user } = useContext(UserContext);

  const { namespace, portName } = useContext(UserContext);

  const { t } = useTranslation(namespace);

  const { id, created_at, message, type, sender, ship, ship_imo, decision } = data;

  useEffect(() => {
    setChildren(notificationChildren[id]);
  }, [id, notificationChildren]);

  let name = '';

  if (type === 'ship' || type === 'port_call_decision') {
    name = ship?.vessel_name || `IMO #${ship_imo}`;
  } else {
    name = t('Port of {{portname}}', { portname: portName });
  }

  const handleDelete = async id => {
    await apiCall('delete', 'notifications', { id: id });
  };

  const showDeleteConfirm = ({ message, id }) => {
    confirm({
      title: 'Delete notification?',
      content: message,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(id);
      },
    });
  };

  return (
    <StyledNotification id={ship_imo}>
      <NotificationContent>
        <Time format={TIME_FORMAT} date={created_at} />
        <Title>{message}</Title>
        <SentBy>{t('Sent by {{sender}} ({{name}})', { sender: formatSender(sender), name: name })}</SentBy>
        {user.role === 'admin' && (
          <DeleteButton>
            <DeleteButtonIcon onClick={() => showDeleteConfirm({ message, id })}>
              <Icon type="trash" />
            </DeleteButtonIcon>
          </DeleteButton>
        )}
      </NotificationContent>
      {type === 'port_call_decision' && <NotificationDecision data={decision} />}
      {children && children.length !== 0 && (
        <NotificationChildren data={children} parentType={type} parentNotificationId={id} imo={ship_imo} />
      )}
      {!children && <NotificationChildren parentType={type} parentNotificationId={id} imo={ship_imo} />}
    </StyledNotification>
  );
};

export default NotificationListItem;
