import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import { StatisticsContext } from '../../context/StatisticsContext';
import StatisticsStructure from './StatisticsStructure';

const Container = styled.div`
  width: 100%;
  min-height: 300px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const NoGraphs = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const StatisticsGraph = () => {
  const { getStartData, dashboardData, isDashboardDataLoading } = useContext(StatisticsContext);
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startDataAvailable, setStartDataAvailable] = useState(false);

  useEffect(() => {
    if (!startDataAvailable) {
      getStartData();
      setStartDataAvailable(true);
    }
  }, [startDataAvailable, getStartData]);

  return (
    <Container>
      {isDashboardDataLoading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : dashboardData.structure?.length === 2 ? (
        <StatisticsStructure elements={dashboardData.elements} structure={dashboardData.structure} />
      ) : (
        <NoGraphs>{t('No statistics available.')}</NoGraphs>
      )}
    </Container>
  );
};
export default StatisticsGraph;
