export const invoicingCategoryColors = [
  'rgba(87, 140, 30, 0.3)',
  'rgba(42, 114, 193, 0.3)',
  'rgba(235, 208, 68, 0.3)',
  'rgba(223, 124, 64, 0.3)',
  'rgba(166, 77, 226, 0.3)',
  'rgba(213, 107, 168, 0.3)',
  'rgba(42, 173, 155, 0.3)',
  'rgba(42, 192, 225, 0.3)',
  'rgba(235, 77, 93, 0.3)',
  'rgba(169, 208, 68, 0.3)',
];

export const invoicingCategories = ['Day', 'Night', 'Weekend', 'Holidays'];
