import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../../context/UserContext';
import { NotificationContext } from '../../../context/NotificationContext';

import Textarea from '../../ui/Textarea';
import Form from '../../ui/Form';
import ButtonLight from '../../ui/ButtonLight';
import ProfileCircle from '../../ui/ProfileCircle';

const NotificationMessageContainer = styled.div`
  margin-left: ${props => (props.childrenAvailable ? '5%' : '0')};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ActionButtons = styled.div`
  text-align: right;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NotificationForm = styled(Form)`
  padding: 6px 0 0 0;
  select,
  input,
  textarea,
  span {
    width: 100%;
  }
  label {
    text-align: center;
  }
`;

const LabelRow = styled.div`
  display: flex;
  justify-content: left;
  padding-left: 48px;
`;

const MessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.gap_small};
  padding-bottom: 0.5px;
  div {
    margin-bottom: 0;
  }
  textarea {
    min-height: 50px;
    resize: none;
  }
`;

const Label = styled.div`
  font-weight: 700;
  font-size: ${({ theme }) => theme.text.smaller};
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
`;

const UserEmail = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.tiny};
`;

const Message = styled.div`
  display: inline-block;
  width: calc(100% - 40px);
  padding-left: 6px;
`;

const FirstRow = styled.div`
  display: flex;
  width: 100%;
`;

const ProfileCircleContainer = styled.div`
  display: flex;
  height: 62px;
`;

const NotificationReplyMessage = ({ data, childrenAvailable, replyOpened, messageSent }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState(undefined);

  const { apiSendNotification } = useContext(NotificationContext);

  let labelRef = useRef();

  const handleMessageChange = e => {
    setReplyMessage(e.target.value);
  };

  const handleCancel = () => {
    setReplyMessage(undefined);
    setOpen(false);
  };

  const toggleOpen = () => {
    replyOpened(128, labelRef.current.getBoundingClientRect().bottom, !open);
    setOpen(!open);
  };

  const handleReplySend = e => {
    e.preventDefault();

    let type = 'ship';

    if (data.type === 'port') {
      type = 'port';
    }

    setOpen(false);
    apiSendNotification(type, replyMessage, data.ship_imo, null, null, data.id);
    setReplyMessage(undefined);
    messageSent();
  };

  if (!user.permissions.includes('send_push_notification')) {
    return null;
  }

  return (
    <NotificationMessageContainer childrenAvailable={childrenAvailable}>
      <NotificationForm onSubmit={handleReplySend}>
        <LabelRow ref={labelRef}>
          <Label onClick={toggleOpen}>{t('Reply')}</Label>
        </LabelRow>
        {open && (
          <MessageContainer>
            <FirstRow>
              <ProfileCircleContainer>
                <ProfileCircle />
              </ProfileCircleContainer>
              <Message>
                <UserEmail>{user.email}</UserEmail>
                <Textarea placeholder={t('Write a reply...')} value={replyMessage} onChange={handleMessageChange} />
              </Message>
            </FirstRow>
            <ActionButtons>
              <ButtonLight cancel onClick={handleCancel}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight disabled={!replyMessage}>{t('Send')}</ButtonLight>
            </ActionButtons>
          </MessageContainer>
        )}
      </NotificationForm>
    </NotificationMessageContainer>
  );
};

export default NotificationReplyMessage;
