import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../context/UserContext';
export const GeoAssetToolContext = createContext();

export const GeoAssetToolProvider = ({ children }) => {
  const { apiCall } = useContext(UserContext);
  const [assetData, setAssetData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [rightPanelData, setRightPanelData] = useState({});
  const [newRotatedCoords, setNewRotatedCoords] = useState(null);
  const [rightPanelListData, setRightPanelListData] = useState(null);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [draggableAssets, setDraggableAssets] = useState(false);
  const [deleteAssets, setDeleteAssets] = useState(false);
  const [rotateAssets, setRotateAssets] = useState(false);
  const [rotateAssetsCount, setRotateAssetsCount] = useState(0);
  const [deletedAssetsList, setDeletedAssetsList] = useState([]);
  const [currentNamespace, setCurrentNamespace] = useState();
  const [globalSave, setGlobalSave] = useState(false);
  const [globalsaveLoading, setGlobalsaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAssetLayer, setNewAssetLayer] = useState(null);
  const [newAssetPoints, setNewAssetPoints] = useState(null);
  const [newAssetStyle, setNewAssetStyle] = useState({});
  const [oldAssetStyle, setOldAssetStyle] = useState({});
  const [newAssetCoords, setNewAssetCoords] = useState([]);
  const [newAssetRotation, setNewAssetRotation] = useState(false);
  const [styleUpdate, setStyleUpdate] = useState(false);
  const [updateMapCenter, setUpdateMapCenter] = useState({ coords: [], zoom: 6 });
  const [newPostedType, setNewPostedType] = useState(null);
  const [geoJsonLayerData, setGeoJsonLayerData] = useState(null);
  const [geoJsonLayerUpdate, setGeoJsonLayerUpdate] = useState(false);

  const [undoRedoIndex, setUndoRedoIndex] = useState(0);
  const [assetDataHistory, setAssetDataHistory] = useState([]);
  const [undoAvailable, setUndoAvailable] = useState(false);
  const [redoAvailable, setRedoAvailable] = useState(false);
  const [elementsDeletedFromHistory, setElementsDeletedFromHistory] = useState(false);
  const [filterList, setFilterList] = useState([]);

  const [editPointsInCreate, setEditPointsInCreate] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const updateHistory = newData => {
    const historyCopy = JSON.parse(JSON.stringify(assetDataHistory));
    historyCopy.splice(undoRedoIndex + 1, assetDataHistory.length);
    historyCopy.push(JSON.parse(JSON.stringify(newData)));
    setAssetDataHistory(historyCopy);
    if (historyCopy.length > 40) {
      historyCopy.splice(0, 1);
      setElementsDeletedFromHistory(true);
    }
    setRedoAvailable(false);
    setUndoAvailable(true);
    setUndoRedoIndex(historyCopy.length - 1);
  };

  const undoFunction = async () => {
    if (undoRedoIndex > 0) {
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
      setAssetData(assetDataHistory[undoRedoIndex - 1]);
      setTreeData(
        await buildHierarchy(await assingKeysAndTitles(JSON.parse(JSON.stringify(assetDataHistory[undoRedoIndex - 1]))))
      );
      deleteArrayValidator(assetDataHistory[undoRedoIndex - 1], assetDataHistory[undoRedoIndex]);
      if (undoRedoIndex - 1 === 0) {
        if (!elementsDeletedFromHistory) setGlobalSave(false);
        setUndoAvailable(false);
      }
      setRedoAvailable(true);
      setUndoRedoIndex(undoRedoIndex - 1);
    }
  };

  const redoFunction = async () => {
    if (undoRedoIndex < assetDataHistory.length - 1) {
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
      setAssetData(JSON.parse(JSON.stringify(assetDataHistory[undoRedoIndex + 1])));
      setTreeData(
        await buildHierarchy(await assingKeysAndTitles(JSON.parse(JSON.stringify(assetDataHistory[undoRedoIndex + 1]))))
      );
      deleteArrayValidator(assetDataHistory[undoRedoIndex + 1], assetDataHistory[undoRedoIndex]);
      if (undoRedoIndex + 1 === assetDataHistory.length - 1) {
        setRedoAvailable(false);
      }
      setGlobalSave(true);
      setUndoAvailable(true);
      setUndoRedoIndex(undoRedoIndex + 1);
    }
  };

  const deleteArrayValidator = (newState, previousState) => {
    if (newState.length !== previousState.length) {
      if (newState.length > previousState.length) {
        for (let index = 0; index < newState.length; index++) {
          const id = newState[index].geo_asset_id;
          const deletedIndex = deletedAssetsList.findIndex(element => element === id);
          if (deletedIndex !== -1) {
            const deleteArrayCopy = [...deletedAssetsList];
            deleteArrayCopy.splice(deletedIndex, 1);
            setDeletedAssetsList(deleteArrayCopy);
            break;
          }
        }
      } else if (newState.length < previousState.length) {
        for (let index = 0; index < previousState.length; index++) {
          const id = previousState[index].geo_asset_id;
          const deletedIndex = newState.findIndex(element => element.geo_asset_id === id);
          if (deletedIndex === -1) {
            const deleteArrayCopy = [...deletedAssetsList];
            deleteArrayCopy.push(id);
            setDeletedAssetsList(deleteArrayCopy);
            break;
          }
        }
      }
    }
  };

  const assingKeysToObjects = (data, key) => {
    const newData = {};
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      newData[element[key]] = element;
    }
    return newData;
  };

  let assetTreeVisible = useRef(false);

  const nullAssetsAndTypes = async () => {
    setCurrentNamespace(undefined);
    setGlobalSave(false);
    setLoading(false);
    setDeletedAssetsList([]);
    setFilterList([]);
    setUndoRedoIndex(0);
    setRedoAvailable(false);
    setUndoAvailable(false);
    setAssetData([]);
    setTreeData([]);
    setLoading(false);
    setTypeData([]);
    assetTreeVisible.current = false;
    setRightPanelOpen(false);
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    setAssetDataHistory([]);
  };

  const getAssetsAndTypes = async namespace => {
    setCurrentNamespace(namespace);
    setGlobalSave(false);
    setLoading(true);
    setDeletedAssetsList([]);

    const fetchedTypes = await apiCall('get', 'floora/type/namespace', { namespace: namespace });
    const fetchedAssets = await apiCall('get', 'floora/namespace', { namespace: namespace });
    if (fetchedTypes.status === 200 && fetchedAssets.status === 200 && fetchedAssets?.data?.data) {
      for (let index = 0; index < fetchedAssets.data?.data.length; index++) {
        const asset = fetchedAssets.data?.data[index];
        if (asset.geo_data.type === 'Polygon') {
          asset.geo_data.coordinates = asset.geo_data.coordinates.flat();
          asset.geo_data.coordinates.splice(asset.geo_data.coordinates.length - 1, 1);
        }
      }
      const filterListContent = localStorage.getItem(`${namespace + 'FilterList'}`);
      if (fetchedTypes?.data && filterListContent) {
        let originalFilterList = filterListContent.split(',');
        const newFilterList = [];
        for (let index = 0; index < fetchedTypes.data.length; index++) {
          const element = fetchedTypes.data[index];
          if (originalFilterList.includes(element.type_id)) newFilterList.push(element.type_id);
        }
        setFilterList(newFilterList);
        localStorage.setItem(`${namespace + 'FilterList'}`, newFilterList);
      } else setFilterList([]);
      if (fetchedAssets.data?.namespaceData) {
        setUpdateMapCenter({
          coords: fetchedAssets.data.namespaceData.coordinates.split(','),
          zoom: fetchedAssets.data.namespaceData.zoom,
        });
      }
      setUndoRedoIndex(0);
      setRedoAvailable(false);
      setUndoAvailable(false);
      setAssetData(fetchedAssets.data?.data);
      setTreeData(await buildHierarchy(await assingKeysAndTitles(fetchedAssets.data?.data)));
      setLoading(false);
      setTypeData(assingKeysToObjects(fetchedTypes.data, 'type_id'));
      assetTreeVisible.current = true;
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
      setAssetDataHistory([JSON.parse(JSON.stringify(fetchedAssets.data?.data))]);
    } else {
      setLoading(false);
    }
  };

  const findParentId = async (uuid, data) => {
    if (uuid === null) return;
    for (let index = 0; index < data.length; index++) {
      const asset = data[index];
      if (asset.geo_asset_id === uuid) return await asset.id;
    }
    return;
  };

  const buildHierarchy = async data => {
    const assetDataCopy = JSON.parse(JSON.stringify(data));
    const tree = [];
    const childOf = {};
    for (let index = 0; index < assetDataCopy.length; index++) {
      const item = assetDataCopy[index];
      const { id } = item;
      const parentId = await findParentId(item.parent, assetDataCopy);
      childOf[id] = childOf[id] || [];
      item.children = childOf[id];
      parentId ? (childOf[parentId] = childOf[parentId] || []).push(item) : tree.push(item);
    }
    tree.sort(function(a, b) {
      return a.order_number - b.order_number;
    });
    return tree;
  };

  const assingKeysAndTitles = async tree => {
    const treeCopy = JSON.parse(JSON.stringify(tree));
    const newTree = [];
    for (let index = 0; index < treeCopy.length; index++) {
      const element = treeCopy[index];
      element.key = element.geo_asset_id;
      element.title = element.name;
      element.value = element.name;
      element.defaultValue = element.name;
      delete element.style;
      newTree.push(element);
    }
    newTree.sort(function(a, b) {
      return a.order_number - b.order_number;
    });
    return newTree;
  };

  const openRightPanel = node => {
    setRightPanelListData(null);
    for (let index = 0; index < assetData.length; index++) {
      const element = assetData[index];
      if (element.geo_asset_id === node.geo_asset_id) {
        setRightPanelData(element);
        setRightPanelOpen(true);
      }
    }
  };
  const closeRightPanel = () => {
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    setRightPanelListData(null);
    setRightPanelOpen(false);
  };

  const updateAssetdataOrder = updateArray => {
    const assetDataCopy = [...assetData];
    for (let index = 0; index < assetDataCopy.length; index++) {
      const asset = assetDataCopy[index];
      for (let i = 0; i < updateArray.length; i++) {
        const updateData = updateArray[i];
        if (updateData.geo_asset_id === asset.geo_asset_id) {
          asset.order_number = updateData.order_number;
          asset.parent = updateData.parent;
          break;
        }
      }
    }
    setGlobalSave(true);
    setAssetData(assetDataCopy);
    updateHistory(assetDataCopy);
  };

  const updateAssetCoodinates = (geo_asset_id, coords, fromGeoJsonTab, newCenter) => {
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const asset = assetDataCopy[index];
      if (asset.geo_asset_id === geo_asset_id) {
        if (asset.geo_data.type === 'Point' && !fromGeoJsonTab) {
          asset.geo_data.coordinates = [coords.lat, coords.lng];
          if (rightPanelOpen && rightPanelData.geo_asset_id === geo_asset_id) {
            rightPanelData.geo_data.coordinates = [coords.lat, coords.lng];
          }
          break;
        } else if (asset.geo_data.type === 'Polygon' || asset.geo_data.type === 'LineString') {
          const flatCoords = asset.geo_data.type === 'Polygon' ? coords.flat() : coords;
          const newCoords = [];
          for (let index = 0; index < flatCoords.length; index++) {
            const element = flatCoords[index];
            if (fromGeoJsonTab) newCoords.push([element[0], element[1]]);
            else newCoords.push([element.lat, element.lng]);
          }
          const isCirclePolygon = !!(asset.properties?.center && asset.style?.segments && asset.style?.radius);
          if (isCirclePolygon) asset.properties.center = newCenter;
          if (rightPanelOpen && rightPanelData.geo_asset_id === geo_asset_id) {
            rightPanelData.geo_data.coordinates = newCoords;
          }
          asset.geo_data.coordinates = newCoords;
          break;
        }
      }
    }
    setGlobalSave(true);
    setAssetData(assetDataCopy);
    updateHistory(assetDataCopy);
  };

  const updatePolygonPoint = async (pointIndex, coords, geo_asset_id) => {
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const element = assetDataCopy[index];
      if (element.geo_asset_id === geo_asset_id) {
        element.geo_data.coordinates[pointIndex] = [coords.lat, coords.lng];
        if (element.geo_data.type === 'Polygon' && pointIndex === 0) {
          element.geo_data.coordinates[element.geo_data.coordinates.length - 1] = [coords.lat, coords.lng];
        }
        if (rightPanelOpen && rightPanelData.geo_asset_id === geo_asset_id) {
          rightPanelData.geo_data.coordinates = element.geo_data.coordinates;
        }
        break;
      }
    }
    setGlobalSave(true);
    setAssetData(await assetDataCopy);
    updateHistory(assetDataCopy);
  };

  const deletePolygonPoint = (pointIndex, geo_asset_id) => {
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const element = assetDataCopy[index];
      if (element.geo_asset_id === geo_asset_id) {
        if (element.geo_data.coordinates.length <= 3 && element.geo_data.type === 'Polygon') break;
        else if (element.geo_data.coordinates.length <= 2) break;
        element.geo_data.coordinates.splice(pointIndex, 1);
        if (rightPanelOpen && rightPanelData.geo_asset_id === geo_asset_id) {
          rightPanelData.geo_data.coordinates = element.geo_data.coordinates;
        }
        setGlobalSave(true);
        setAssetData(assetDataCopy);
        updateHistory(assetDataCopy);
        break;
      }
    }
  };

  const deleteAssetFunction = async asset => {
    if (asset.newAsset) {
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
    } else {
      const assetDataCopy = JSON.parse(JSON.stringify(assetData));
      for (let index = 0; index < assetDataCopy.length; index++) {
        const element = assetDataCopy[index];
        if (element.geo_asset_id === asset.geo_asset_id) {
          if (rightPanelData.geo_asset_id === asset.geo_asset_id) {
            setRightPanelOpen(false);
            setRightPanelData({});
            setEditPointsInCreate(false);
            setNewRotatedCoords(null);
          }
          assetDataCopy.splice(index, 1);
          break;
        }
      }
      setGlobalSave(true);
      setTreeData(await buildHierarchy(await assingKeysAndTitles(assetDataCopy)));
      setAssetData(assetDataCopy);
      setDeletedAssetsList([...deletedAssetsList, asset.geo_asset_id]);
      updateHistory(JSON.parse(JSON.stringify(assetDataCopy)));
    }
  };

  const addPointToPolygon = async (point, pointIndex, geo_asset_id) => {
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const element = assetDataCopy[index];
      if (element.geo_asset_id === geo_asset_id) {
        element.geo_data.coordinates.splice(pointIndex, 0, [point.lat, point.lng]);
        if (rightPanelOpen && rightPanelData.geo_asset_id === geo_asset_id) {
          rightPanelData.geo_data.coordinates = element.geo_data.coordinates;
        }
        break;
      }
    }
    setGlobalSave(true);
    setAssetData(await assetDataCopy);
    updateHistory(assetDataCopy);
  };

  const drawAssets = (coords, type, style, fromGeoJson, createdFromGeoJsonLayer, circleObject) => {
    setRightPanelListData(null);
    const newAsset = {
      newAsset: true,
      fromGeoJson: fromGeoJson,
      type_id: '',
      name: '',
      namespace: currentNamespace,
      parent: null,
      geo_data: {
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326',
          },
        },
        type: type,
        coordinates: type === 'Polygon' || type === 'Point' ? coords.flat() : coords,
      },
      properties: {},
      style: {
        stroke: style.stroke,
        color: style.color,
        weight: style.weight,
        opacity: style.opacity,
        fillColor: style.color,
        fillOpacity: style.fillOpacity,
      },
      permission: 'restricted',
    };
    if (circleObject?.isCircle) {
      newAsset.style.segments = circleObject.segments;
      newAsset.style.radius = parseInt(style.radius);
      newAsset.properties.center = circleObject.center;
    }

    if (createdFromGeoJsonLayer) {
      newAsset.createdFromGeoJsonLayer = true;
      if (type === 'Polygon' && circleObject?.isCircle) {
        newAsset.geo_data.coordinates.splice(newAsset.geo_data.coordinates.length - 1, 1);
      }
    }
    setRightPanelData(newAsset);
    setRightPanelOpen(true);
  };

  const postAsset = async data => {
    data.order_number = 0; //TODO: Set this to be last from treeData.
    if (data.geo_data.type === 'Polygon') {
      data.geo_data.coordinates.splice(data.geo_data.coordinates.length, 0, data.geo_data.coordinates[0]);
      data.geo_data.coordinates = [data.geo_data.coordinates];
    }
    const postResult = await apiCall('post', 'floora/new', data);
    if (postResult.status === 200) {
      if (postResult.data.geo_data.type === 'Polygon') {
        postResult.data.geo_data.coordinates = postResult.data.geo_data.coordinates.flat();
        postResult.data.geo_data.coordinates.splice(postResult.data.geo_data.coordinates.length - 1, 1);
      }
      postResult.data.properties = { ...postResult.data.properties };
      postResult.data.style = { ...postResult.data.style };
      setAssetData([...assetData, postResult.data]);
      setTreeData(await buildHierarchy(await assingKeysAndTitles([...assetData, postResult.data])));
      setRightPanelData(postResult.data);
      updateHistory([...assetData, postResult.data]);
    }
  };

  const deleteAsset = async id => {
    setRightPanelOpen(false);
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    await apiCall('delete', 'floora', { geo_asset_id: id });
  };

  const updateAsset = async data => {
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const element = assetDataCopy[index];
      if (element.geo_asset_id === data.geo_asset_id) {
        assetDataCopy.splice(index, 1);
        break;
      }
    }
    setAssetData([...assetDataCopy, data]);
    setTreeData(await buildHierarchy(await assingKeysAndTitles([...assetDataCopy, data])));
    setRightPanelData(data);
    setGlobalSave(true);
    updateHistory([...assetDataCopy, data]);
  };

  const globalSaveFunction = async () => {
    setGlobalsaveLoading(true);
    setRightPanelOpen(false);
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    const updateArray = [];
    const assetDataCopy = JSON.parse(JSON.stringify(assetData));
    for (let index = 0; index < assetDataCopy.length; index++) {
      const data = assetDataCopy[index];
      if (data.geo_data.type === 'Polygon') {
        data.geo_data.coordinates.splice(data.geo_data.coordinates.length, 0, data.geo_data.coordinates[0]);
        data.geo_data.coordinates = [data.geo_data.coordinates];
      }
      let updateObject = {
        geo_asset_id: data.geo_asset_id,
        type_id: data.type_id,
        name: data.name,
        namespace: data.namespace,
        parent: data.parent,
        properties: data.properties,
        style: data.style,
        geo_data: {
          type: 'Feature',
          properties: {},
          geometry: { type: data.geo_data.type, coordinates: data.geo_data.coordinates },
        },
        order_number: data.order_number,
        permission: data.permission ? data.permission : 'hidden',
      };
      updateArray.push(JSON.parse(JSON.stringify(updateObject)));
    }
    try {
      await apiCall('put', 'floora/bulk', { data: updateArray });
    } catch {
      setGlobalsaveLoading(false);
    }

    for (let index = 0; index < deletedAssetsList.length; index++) {
      const assetId = deletedAssetsList[index];
      await deleteAsset(assetId);
    }
    setGlobalsaveLoading(false);
    setGlobalSave(false);
    setDeletedAssetsList([]);
    setAssetDataHistory([JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(assetData))))]);
    setUndoRedoIndex(0);
    setRedoAvailable(false);
    setUndoAvailable(false);
  };

  const postType = async data => {
    const postResult = await apiCall('post', 'floora/type/new', data);
    if (postResult.status === 200) {
      const typeDataCopy = JSON.parse(JSON.stringify(typeData));
      typeDataCopy[postResult.data.type_id] = postResult.data;
      setNewPostedType(postResult.data);
      setTypeData(typeDataCopy);
      if (data.createNew) return true;
    }
    return false;
  };

  const saveTypeFunction = async data => {
    const saveResult = await apiCall('put', 'floora/type', data);
    if (saveResult.status === 200) {
      const typeDataCopy = JSON.parse(JSON.stringify(typeData));
      typeDataCopy[data.type_id] = data;
      setTypeData(typeDataCopy);
      return true;
    }
    return false;
  };

  const getTypeAssetCount = async dataArray => {
    const result = await apiCall('post', 'floora/type/count', { type_ids: dataArray });
    if (result.status === 200) {
      return result.data;
    }
  };

  const deleteTypeFunction = async id => {
    const typeDataCopy = JSON.parse(JSON.stringify(typeData));
    const result = await apiCall('delete', 'floora/type', { type_id: id });
    if (result.status === 200) {
      delete typeDataCopy[id];
      setTypeData(typeDataCopy);
      return true;
    }
    return false;
  };

  const listAssets = () => {
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    setRightPanelListData(assetData || []);
    setRightPanelOpen(true);
  };

  return (
    <GeoAssetToolContext.Provider
      value={{
        treeData: treeData,
        setTreeData: setTreeData,
        getAssetsAndTypes: getAssetsAndTypes,
        assetData: assetData,
        setAssetData: setAssetData,
        typeData: typeData,
        buildHierarchy: buildHierarchy,
        assetTreeVisible: assetTreeVisible,
        openRightPanel: openRightPanel,
        closeRightPanel: closeRightPanel,
        rightPanelOpen: rightPanelOpen,
        setRightPanelOpen: setRightPanelOpen,
        rightPanelData: rightPanelData,
        setRightPanelData: setRightPanelData,
        updateAssetdataOrder: updateAssetdataOrder,
        updateAssetCoodinates: updateAssetCoodinates,
        draggableAssets: draggableAssets,
        setDraggableAssets: setDraggableAssets,
        deleteAssets: deleteAssets,
        setDeleteAssets: setDeleteAssets,
        rotateAssets,
        setRotateAssets,
        deleteAssetFunction: deleteAssetFunction,
        updatePolygonPoint: updatePolygonPoint,
        deletePolygonPoint: deletePolygonPoint,
        addPointToPolygon: addPointToPolygon,
        drawAssets: drawAssets,
        currentNamespace: currentNamespace,
        setCurrentNamespace: setCurrentNamespace,
        postAsset: postAsset,
        postType: postType,
        deleteAsset: deleteAsset,
        updateAsset: updateAsset,
        globalSave: globalSave,
        globalsaveLoading: globalsaveLoading,
        globalSaveFunction: globalSaveFunction,
        setGlobalSave: setGlobalSave,
        loading: loading,
        setLoading: setLoading,
        newAssetLayer: newAssetLayer,
        setNewAssetLayer: setNewAssetLayer,
        newAssetStyle: newAssetStyle,
        setNewAssetStyle: setNewAssetStyle,
        oldAssetStyle: oldAssetStyle,
        setOldAssetStyle: setOldAssetStyle,
        newAssetCoords: newAssetCoords,
        setNewAssetCoords: setNewAssetCoords,
        styleUpdate: styleUpdate,
        setStyleUpdate: setStyleUpdate,
        updateMapCenter: updateMapCenter,
        setUpdateMapCenter: setUpdateMapCenter,
        undoFunction: undoFunction,
        redoFunction: redoFunction,
        undoAvailable: undoAvailable,
        redoAvailable: redoAvailable,
        getTypeAssetCount: getTypeAssetCount,
        deleteTypeFunction: deleteTypeFunction,
        saveTypeFunction: saveTypeFunction,
        newPostedType: newPostedType,
        setNewPostedType: setNewPostedType,
        geoJsonLayerData: geoJsonLayerData,
        setGeoJsonLayerData: setGeoJsonLayerData,
        geoJsonLayerUpdate: geoJsonLayerUpdate,
        setGeoJsonLayerUpdate: setGeoJsonLayerUpdate,
        filterList: filterList,
        setFilterList: setFilterList,
        nullAssetsAndTypes,
        listAssets,
        rightPanelListData,
        assetDataHistory,
        newRotatedCoords,
        setNewRotatedCoords,
        rotateAssetsCount,
        setRotateAssetsCount,
        setNewAssetRotation,
        newAssetRotation,
        editPointsInCreate,
        setEditPointsInCreate,
        newAssetPoints,
        setNewAssetPoints,
      }}
    >
      {children}
    </GeoAssetToolContext.Provider>
  );
};
