import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from '../ui/Icon';
import Tooltip from 'antd/es/tooltip';
import { UserContext } from '../../context/UserContext';

const Container = styled.div`
  margin-left: 6px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const EditShortTextButton = ({ data, setEditTitle }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const createAnchorData = async () => {
    setEditTitle(data);
  };

  const tooltip = (
    <div style={{ padding: '6px' }}>
      <div>{t('Edit short description')}</div>
      {!!data.shortText && (
        <div style={{ border: '1px solid #e8e8e8', padding: '6px', borderRadius: '3px', margin: '12px' }}>
          {data.shortText}
        </div>
      )}
    </div>
  );

  return (
    <Container>
      <Tooltip
        title={tooltip}
        color="white"
        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
        placement="top"
      >
        <IconContainer onClick={createAnchorData}>
          <Icon type="edit" />
        </IconContainer>
      </Tooltip>
    </Container>
  );
};

export default EditShortTextButton;
