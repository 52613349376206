import React, { useContext, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

import { UserContext } from '../../context/UserContext';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';

import SmallPageSearch from '../page/SmallPageSearch';
import Icon from './Icon';
import InfoTooltip from '../info/InfoTooltip';

const StyledTable = styled(Table)`
  && {
    width: 100%;
    margin: 0 0 ${({ theme }) => theme.sizing.gap};
    th,
    td {
      padding: ${({ theme }) => theme.sizing.gap};
      background: ${({ theme }) => theme.color.white};
      border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
      @media print {
        border-bottom: 1pt solid black;
      }
    }
    thead {
      tr {
        th {
          white-space: ${props => (props.wrapHeaderText ? 'normal' : 'nowrap')};
          padding: 12px 1rem;
          border-bottom: 1px solid transparent;
        }
      }
    }
    th {
      font-size: 0.95rem;
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      white-space: nowrap;
      padding: ${({ theme }) => theme.sizing.gap_small} ${({ theme }) => theme.sizing.gap};
    }
    table,
    .ant-table {
      background: none;

      th {
        background: #fbfbfb;
      }
      td {
        background: ${props => (props.transparentBgs ? 'transparent' : '#ffffff')};
      }
      table {
        padding-bottom: ${props => props.addItemButton && '150px'};
      }
    }
    .ant-table-wrapper + .ant-table-wrapper {
      margin-top: ${({ theme }) => theme.sizing.gap_medium};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;

const StyledButton = styled.div`
  box-shadow: none;
  height: 16px;
  width: 24px !important;
  border: none;
  background-color: transparent !important;
  overflow: hidden;
  border-radius: 3px;
  padding-left: 1px;

  &:hover {
    background-color: #dddddd !important;

    i {
      color: #777777 !important;
    }
  }
`;

const MoreIcon = styled.span`
  cursor: pointer;
  height: 16px;

  svg {
    margin-top: -22px;
    margin-right: 1px;
  }
`;

const Popover = styled.div`
  background-color: white;
  position: absolute;
  right: 8px;
  bottom: ${props => (!props.smallIndex ? '30px' : 'unset')};
  top: ${props => (props.smallIndex ? '30px' : 'unset')};
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 80;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props => {
    if (props.smallIndex) {
      return `
        &:before {
          border: solid;
          border-color: #eeeeee transparent;
          border-width: 0 8px 8px 8px;
          bottom: 100%;
          content: '';
          display: block;
          right: 16px;
          top: -10px;
          position: absolute;
          z-index: 99;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        }
      `;
    } else {
      return `
        &:after {
          border: solid;
          border-color: #eeeeee transparent;
          border-width: 8px 8px 0px 8px;
          bottom: 100%;
          content: '';
          display: block;
          right: 16px;
          bottom: -8px;
          position: absolute;
          z-index: 99;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        }
      `;
    }
  }}
`;

const TableContainer = styled.div`
  clear: both;
  position: relative;

  .ant-table-content {
    overflow-x: ${props => (props.expandererList ? 'unset' : 'auto')};
    min-height: ${props => (props.expandererList ? 'unset' : !props.givenMinHeight ? '300px' : props.givenMinHeight)};
  }

  .ant-pagination {
    margin-top: ${props => props.addItemButton && '-134px'};
    z-index: 79;
    position: relative;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => props.noHeader && '0px'};
`;

const AdditionalButtons = styled.div.attrs({
  className: 'additional-buttons',
})`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizing.gap};

  @media print {
    display: none;
  }
`;

const AddItemButton = styled.div`
  background-color: white;
  height: 32px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: 2px;

  ${props => {
    if (props.blue) {
      return `
        background-color: ${props.theme.color.secondary};
        color: white;
      `;
    }
  }}

  color: ${props => props.disabled && '#a8a8a8'};
  background-color: ${props => props.disabled && '#f5f5f5'};
  cursor: ${props => props.disabled && 'default'};

  &:hover {
    border-color: ${props =>
    !props.disabled && (props.blue ? darken(0.05, props.theme.color.secondary) : props.theme.color.secondary)};
    color: ${props => !props.disabled && (props.blue ? 'white' : props.theme.color.secondary)};
    background-color: ${props => !props.disabled && (props.blue ? darken(0.05, props.theme.color.secondary) : 'white')};
  }

  i {
    margin-top: -2px;
    margin-right: 6px;
    margin-left: -4px;
  }
`;

const AddItemBottomButton = styled.div`
  height: 32px;
  line-height: 26px;
  padding: 0 8px;
  font-size: 12px;
  cursor: pointer;
  bottom: ${props => (props.pagination ? '152px' : '150px')};
  display: inline-block;
  position: ${props => (props.pagination ? 'absolute' : 'relative')};
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};

  &:hover {
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.secondary};
  }

  i {
    margin-right: 6px;
    margin-left: -4px;
  }

  svg {
    padding-top: 2px;
  }
`;

const List = ({
  rowKey,
  columns,
  dataSource,
  apiCallPending = false,
  actions,
  spinning = false,
  searchPlaceHolder,
  newParams,
  setParams,
  start,
  total,
  addItemButton,
  additionalButtons,
  expandedRowRender,
  expandedRowKeys,
  hideSearch = false,
  localPagination = false,
  localPaginationFields = [],
  title,
  rowClassName,
  noUrlUpdate = false,
  scrollToTop = undefined,
  infoText = undefined,
  transparentBgs = false,
  expandRowByClick = false,
  onExpandedRowsChange,
  onExpand,
  onRow,
  wrapHeaderText = false,
  defaultExpandAllRows = false,
  rowExpandable = undefined,
  expandIconColumnIndex = undefined,
  scrollAmount = null,
  showHeader = true,
  givenMinHeight = null,
  expandererList = false,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);

  const [popoverOpen, setPopoverOpen] = useState(undefined);
  const [search, setSearch] = useState(params.get('search') ? params.get('search') : '');

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    const checkPopconfirm = (pop, target) => {
      if (pop.contains(target)) {
        const targetString = target.outerHTML;
        if (
          targetString.substring(0, 6) === '<span>' &&
          targetString.substring(targetString.length - 7, targetString.length) === '</span>'
        ) {
          return true;
        }
      }
      return false;
    };

    const handleClickOutside = event => {
      const popconfirm = document.querySelector('#pop-confirm-for-new-list');
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (popconfirm ? !popconfirm.contains(event.target) : true)
      ) {
        setPopoverOpen(undefined);
      }

      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (popconfirm ? checkPopconfirm(popconfirm, event.target) : true)
      ) {
        setTimeout(() => {
          setPopoverOpen(undefined);
        }, 200);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [popoverOpen]);

  const pagination = !localPagination
    ? newParams && (total || total === 0) && (start || start === 0)
      ? {
          pageSize: newParams.limit,
          current: Math.round(start / newParams.limit) + 1,
          total: total,
          hideOnSinglePage: true,
        }
      : false
    : { defaultPageSize: 50, hideOnSinglePage: true };

  const handleTableChange = async pagination => {
    if (newParams) {
      let params = { ...newParams };
      params.offset = newParams.limit * (pagination.current - 1);
      params.limit = pagination.pageSize;
      if (!noUrlUpdate) {
        history.push(
          location.pathname +
            '?offset=' +
            params.offset +
            '&sort=' +
            params.sort +
            '&search=' +
            encodeURIComponent(params.search)
        );
      }
      if (scrollToTop) {
        scrollToTop();
      }
      setParams(params);
    }
  };

  const handleColumnClick = async id => {
    if (newParams) {
      let params = { ...newParams };
      params.offset = 0;
      if (params.sort === id) {
        params.sort = id + ' DESC';
      } else {
        params.sort = id;
      }
      if (!noUrlUpdate) {
        history.push(
          location.pathname +
            '?offset=' +
            params.offset +
            '&sort=' +
            params.sort +
            '&search=' +
            encodeURIComponent(params.search)
        );
      }
      setParams(params);
    }
  };

  const doSearch = useCallback(
    params => {
      if (mounted.current && newParams) {
        if (!noUrlUpdate) {
          history.push(
            location.pathname +
              '?offset=' +
              params.offset +
              '&sort=' +
              params.sort +
              '&search=' +
              encodeURIComponent(params.search)
          );
        }
        setParams(params);
      }
    },
    [history, location.pathname, newParams, noUrlUpdate, setParams]
  );

  const debouncedSearch = useMemo(() => debounce(800, doSearch), [doSearch]);

  const handleSearchChange = e => {
    setSearch(e.target.value);
    e.preventDefault();
    let params = { ...newParams };
    params.search = e.target.value;
    params.offset = 0;
    debouncedSearch(params);
  };

  const columnsWithSort = columns.map(column => {
    return column.sortableKey
      ? {
          ...column,
          title: (
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              {column.title}
              <span style={{ marginLeft: '5px' }}>
                {newParams.sort === column.sortableKey ? (
                  <Icon type={'sorter-asc'}></Icon>
                ) : newParams.sort === column.sortableKey + ' DESC' ? (
                  <Icon type={'sorter-desc'}></Icon>
                ) : (
                  <Icon type={'sorter-false'}></Icon>
                )}
              </span>
              {!!column.infoKey && (
                <div style={{ marginLeft: '6px', cursor: 'default' }}>
                  <InfoTooltip textKey={column.infoKey} small />
                </div>
              )}
            </span>
          ),
          onHeaderCell: column => {
            return {
              onClick: () => {
                handleColumnClick(column.sortableKey);
              },
            };
          },
        }
      : {
          ...column,
          title: (
            <span style={{ display: 'flex' }}>
              {column.title}
              {!!column.infoKey && (
                <div style={{ marginLeft: '6px', cursor: 'default' }}>
                  <InfoTooltip textKey={column.infoKey} small />
                </div>
              )}
            </span>
          ),
        };
  });

  let showableColumns = [...columnsWithSort];
  if (actions && actions.length > 0) {
    showableColumns = showableColumns.filter(c => c.key !== 'actions');
    showableColumns.push({
      title: t('Actions'),
      key: 'actions',
      width: '80px',
      render: (text, record, index) => (
        <div style={{ position: 'relative' }}>
          <ButtonContainer>
            <StyledButton
              type="text"
              onClick={e => {
                e.stopPropagation();
                setPopoverOpen(index);
              }}
            >
              <MoreIcon>
                <Icon type="ellipsis" style={{ fontSize: '22px', color: '#999999' }} />
              </MoreIcon>
            </StyledButton>
          </ButtonContainer>
          {popoverOpen === index && (
            <>
              <Popover ref={ref} smallIndex={index < 2}>
                {actions.map(action => action.render(record))}
              </Popover>
            </>
          )}
        </div>
      ),
    });
  }

  let showableData = [];

  if (localPagination && localPaginationFields.length && search !== '') {
    const searchResult = dataSource.filter(item => {
      return localPaginationFields.some(f => item[f]?.toUpperCase().includes(search.toUpperCase()));
    });
    showableData = searchResult;
  } else {
    showableData = dataSource;
  }

  return (
    <Spin spinning={apiCallPending}>
      <TopRow noHeader={!showHeader}>
        {!hideSearch && (newParams || localPagination) ? (
          <SmallPageSearch
            value={search}
            placeholder={searchPlaceHolder || t('Search')}
            onChange={handleSearchChange}
            style={{ minWidth: '222px' }}
          />
        ) : (
          <div></div>
        )}
        <AdditionalButtons>
          {!!additionalButtons &&
            additionalButtons.map((b, index) =>
              b.render ? (
                b.render(index)
              ) : (
                <Tooltip
                  placement="left"
                  title={b.disabled ? (b.tooltipWhenDisabled ? t(b.tooltipWhenDisabled) : '') : ''}
                  color="#ffffff"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                  overlayStyle={{ maxWidth: '260px' }}
                  key={index}
                >
                  <AddItemButton onClick={!b.disabled ? b.onClick : undefined} disabled={b.disabled} blue={b.blue}>
                    {b.icon ? <Icon type={b.icon} style={{ fontSize: '20px' }} /> : null}
                    {b.text}
                  </AddItemButton>
                </Tooltip>
              )
            )}
          {!!addItemButton && (
            <AddItemButton onClick={addItemButton.onClick}>
              <Icon type="plus" style={{ fontSize: '20px' }} />
              {addItemButton.text}
            </AddItemButton>
          )}
        </AdditionalButtons>
      </TopRow>
      {!!infoText && infoText}
      <TableContainer givenMinHeight={givenMinHeight} expandererList={expandererList}>
        <Spin spinning={spinning}>
          <StyledTable
            rowKey={rowKey}
            columns={showableColumns}
            dataSource={showableData}
            pagination={pagination}
            onChange={handleTableChange}
            expandedRowRender={expandedRowRender}
            expandedRowKeys={expandedRowKeys}
            expandRowByClick={expandRowByClick}
            title={title}
            rowClassName={rowClassName}
            transparentBgs={transparentBgs}
            onExpandedRowsChange={onExpandedRowsChange}
            onExpand={onExpand}
            onRow={onRow}
            wrapHeaderText={wrapHeaderText}
            rowExpandable={rowExpandable}
            defaultExpandAllRows={defaultExpandAllRows}
            expandIconColumnIndex={expandIconColumnIndex}
            scroll={scrollAmount ? { y: scrollAmount } : null}
            addItemButton={!!addItemButton}
            showHeader={showHeader}
          />
        </Spin>
        {!!addItemButton && (
          <AddItemBottomButton pagination={!!pagination} onClick={addItemButton.onClick}>
            <Icon type="plus" style={{ fontSize: '20px' }} />
            {addItemButton.text}
          </AddItemBottomButton>
        )}
      </TableContainer>
    </Spin>
  );
};

export default List;
