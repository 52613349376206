import React, { useState, useEffect, createContext, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';

import { API_URL } from '../utils/constants';

import App from 'antd/es/app';

export const FORMS_URL = 'forms/form';

export const FormsContext = createContext();

export const FormsProvider = ({ children, location, match }) => {
  const initSession = {
    namespace: undefined,
    token: undefined,
    tokenData: undefined,
  };
  const [token, setToken] = useState(!match.params?.formType ? queryString.parse(location?.search)?.token : undefined);
  const [formType, setFormType] = useState(match.params?.formType);

  const [alert, setAlert] = useState(null);
  const [session, setSession] = useState(initSession);
  const [tokenChecked, setTokenChecked] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [apiCallPending, setApiCallPending] = useState(false);
  const [formNotification, setFormNotification] = useState(undefined);
  const [tokenFormType, setTokenFormType] = useState(undefined);

  const { i18n } = useTranslation(session.namespace);
  const t = i18n.getFixedT(i18n.language, session.namespace);

  const { message } = App.useApp();

  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (!tokenChecked && (formType || token)) {
      setTokenChecked(true);
      // ignore token if formType is present
      if (!formType) {
        const decodedToken = jwt_decode(token);

        if (decodedToken?.exp && decodedToken.data) {
          if (decodedToken.data.type) {
            setTokenFormType(decodedToken.data.type);
          }
          if (new Date().getTime() < (decodedToken.exp - 300) * 1000) {
            setSession(previous => {
              if (previous?.token !== token) {
                return {
                  namespace: decodedToken.data.namespace,
                  token: token,
                  tokenData: decodedToken.data,
                };
              }
              return previous;
            });
            setValidToken(true);
          }
        }
      }
    }
  }, [formType, token, tokenChecked]);

  const apiCall = useCallback(
    async (method, path, data, tests, responseType) => {
      if (!session) {
        return;
      }
      if (path.match(/^\//)) {
        throw new Error('Paths must not begin with slash "/"');
      }
      const url = `${API_URL}/${path}`;
      const { namespace, token } = session;
      let headers = {};
      if (namespace) {
        headers['Namespace'] = namespace;
      }
      if (token) {
        headers['Authorization'] = 'Token ' + token;
      }

      let queryString = '';
      if (method === 'get' && data) {
        const keys = Object.keys(data);
        if (keys.length > 0) {
          queryString = '?';
          keys.forEach(k => {
            if (Array.isArray(data[k])) {
              queryString += data[k].map((value, index) => `${k}[${index}]=${encodeURIComponent(value)}&`).join('');
            } else {
              queryString = `${queryString}${k}=${encodeURIComponent(data[k])}&`;
            }
          });
        }
      }
      try {
        setApiCallPending(true);
        const result = await axios({
          method,
          url: url + queryString,
          data,
          headers,
          ...(responseType ? { responseType: responseType } : {}),
        });
        setApiCallPending(false);
        return result;
      } catch (error) {
        setApiCallPending(false);
        if (
          error.message.match(/Request failed with status code 403/) &&
          error.response?.data?.error?.toUpperCase?.() === 'invalid access'.toUpperCase()
        ) {
          setValidToken(false);
        } else {
          const responseErrorMessage = error.response?.data && error.response.data.error;
          message.error(responseErrorMessage || t('Something unexpected happened'), 4);
          Sentry.captureException(error);
          throw error;
        }
      }
    },
    [message, session, t]
  );

  const verifyFormCode = useCallback(
    async code => {
      if (code) {
        try {
          setApiCallPending(true);
          const result = await apiCall('post', 'forms-verify-code', {
            code,
          });
          if (result?.status === 200) {
            setToken(result.data.token);
            setFormType(undefined);
            setTokenChecked(false);
            setApiCallPending(false);
            return true;
          }
        } catch (error) {
          setApiCallPending(false);
        }
      }
      return false;
    },
    [apiCall]
  );

  const updateFormNotification = message => {
    setFormNotification(message);
    timer.current = setTimeout(() => {
      setFormNotification(undefined);
    }, 5000);
  };

  const setFormToken = token => {
    setToken(token);
    setFormType(undefined);
    setTokenChecked(false);
    setApiCallPending(false);
  };

  return (
    <FormsContext.Provider
      value={{
        alert: alert,
        apiCall: apiCall,
        apiCallPending: apiCallPending,
        setAlert: setAlert,
        formNotification: formNotification,
        formParams: session.tokenData,
        formType: formType ? formType : tokenFormType,
        namespace: session.namespace,
        tokenChecked: tokenChecked,
        updateFormNotification: updateFormNotification,
        validToken: validToken,
        token: token,
        verifyFormCode: verifyFormCode,
        setFormToken: setFormToken,
      }}
    >
      {children}
    </FormsContext.Provider>
  );
};
