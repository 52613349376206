import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, firstLoad, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (mounted.current) {
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then(newOptions => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          if (mounted.current) {
            setOptions(newOptions);
            setFetching(false);
          }
        });
      }
    };

    return debounce(debounceTimeout, loadOptions);
  }, [fetchOptions, debounceTimeout]);

  const fetcher = e => {
    e.persist();
    let searchValue = '';

    if (e.target.value.length) {
      searchValue = e.target.value;
    }

    setOptions([]);
    setFetching(true);
    fetchOptions(searchValue).then(newOptions => {
      if (mounted.current) {
        setOptions(newOptions);
        setFetching(false);
      }
    });
  };

  useEffect(() => {
    if (firstLoad && props.value) {
      setOptions([]);
      setFetching(true);
      fetchOptions(props.value).then(newOptions => {
        if (mounted.current) {
          setOptions(newOptions);
          setFetching(false);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      filterOption={false}
      showSearch
      onSearch={debounceFetcher}
      onFocus={fetcher}
      notFoundContent={fetching ? <Spin size="small" /> : t('No results')}
      {...props}
      options={options}
    />
  );
};

export default DebounceSelect;
