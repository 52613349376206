import React, { useState, createContext, useEffect, useRef, useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  calculateXPositionForTime,
  calculateTimeForXPosition,
  getCanvasBoundariesFromVisibleTime,
  getCanvasWidth,
  calculateScrollCanvas,
} from '../components/commonCalendar/utility/calendar';
import { UserContext } from './UserContext';

import { craneRowHeightConstant } from '../components/commonCalendar/utility/constants';
import useApi from '../hooks/useApi';

export const CranePlanningToolContext = createContext();

export const CranePlanningToolProvider = ({ children }) => {
  const defaultTimeStart = dayjs()
    .startOf('isoWeek')
    .toDate();

  const defaultTimeEnd = dayjs()
    .startOf('isoWeek')
    .add(1, 'week')
    .toDate();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initialIndicatorLine = {
    time: dayjs(),
    isUserSet: false,
  };

  const [visibleTimeStart, setVisibleTimeStart] = useState(defaultTimeStart.valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(defaultTimeEnd.valueOf());
  const [canvasTimeStart, setCanvasTimeStart] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[0]
  );
  const [canvasTimeEnd, setCanvasTimeEnd] = useState(
    getCanvasBoundariesFromVisibleTime(defaultTimeStart.valueOf(), defaultTimeEnd.valueOf())[1]
  );

  const [craneList, setCraneList] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [width, setWidth] = useState(1000);
  const [sidebarWidth, setSidebarWidth] = useState(174);
  const [canvasWidth, setCanvasWidth] = useState(getCanvasWidth(1000));
  const [timelineUnit, setTimelineUnit] = useState('week');
  const [indicatorLine, setIndicatorLine] = useState(initialIndicatorLine);
  const [rowHeight, setRowHeight] = useState(craneRowHeightConstant);
  const [sidePanelWidth, setSidePanelWidth] = useState(255);

  const [loader, setLoader] = useState(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const timeIndicatorLineTimer = useRef();

  const { apiCall, user, useUserSocket, modules } = useContext(UserContext);

  const timerRef = useRef();
  const firstTimerRef = useRef();
  const firstAllowedRef = useRef(true);

  const createCraneList = useCallback(list => {
    if (list.constructor.name !== 'Object') {
      return [];
    }

    return Object.keys(list).map(id => {
      return {
        craneId: id,
        craneName: list[id][0].crane_name,
        works: list[id],
      };
    });
  }, []);

  useEffect(() => {
    if (!user.permissions.includes('manage_bpt')) {
      setSidePanelWidth(0);
    } else {
      setSidePanelWidth(255);
    }
  }, [user]);

  const getStartData = async () => {
    setLoader(true);

    const craneData = await apiCall('post', 'pbp/get-crane-plans', {
      start_time: dayjs(defaultTimeStart)
        .add(-1, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      end_time: dayjs(defaultTimeStart)
        .add(2, 'week')
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      if (craneData.data) {
        setCraneList(createCraneList(craneData.data));
      }

      updateReservationData(
        dayjs(defaultTimeStart)
          .add(-1, 'week')
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        dayjs(defaultTimeStart)
          .add(2, 'week')
          .format('YYYY-MM-DDTHH:mm:ssZ')
      );

      setLoader(false);
    }
  };

  const debouncedUpdateCraneData = useCallback(
    async (start, end) => {
      const craneData = await apiCall('post', 'pbp/get-crane-plans', {
        start_time: dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ'),
        end_time: dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ'),
      });

      if (mounted.current) {
        setCraneList(createCraneList(craneData.data));
      }
    },
    [apiCall, createCraneList]
  );

  const updateCranes = () => {
    setTimeout(() => {
      updateCraneData(canvasTimeStart, canvasTimeEnd);
      updateReservationData(canvasTimeStart, canvasTimeEnd);
    }, 5000);
  };

  const updateCraneData = useCallback(
    (start, end) => {
      if (firstAllowedRef.current) {
        firstAllowedRef.current = false;
        debouncedUpdateCraneData(start, end);
        firstTimerRef.current = setTimeout(() => {
          firstAllowedRef.current = true;
        }, 500);

        return;
      }

      clearTimeout(firstTimerRef.current);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        debouncedUpdateCraneData(start, end);
        timerRef.current = undefined;
        firstTimerRef.current = true;
        firstAllowedRef.current = true;
      }, 500);
    },
    [debouncedUpdateCraneData]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(firstTimerRef.current);
    };
  }, []);

  const updateReservationData = useCallback(
    async (start, end) => {
      const reservationsData = await apiCall('post', 'pbp/get-crane-reservations', {
        start_time: dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ'),
        end_time: dayjs(end).format('YYYY-MM-DDTHH:mm:ssZ'),
      });
      if (mounted.current) {
        setReservations(reservationsData.data);
      }
    },
    [apiCall]
  );

  const updateTime = useCallback(() => {
    setIndicatorLine({ isUserSet: false, time: dayjs() });
  }, []);

  useEffect(() => {
    if (!indicatorLine.isUserSet) {
      timeIndicatorLineTimer.current = setInterval(updateTime, 30000);
    }
    return () => clearInterval(timeIndicatorLineTimer.current);
  }, [indicatorLine, updateTime]);

  const getLeftOffsetFromDate = date => {
    return calculateXPositionForTime(canvasTimeStart, canvasTimeEnd, canvasWidth, date);
  };

  const getDateFromLeftOffsetPosition = leftOffset => {
    return calculateTimeForXPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset);
  };

  const updateScrollCanvas = async (visibleTimeStart2, visibleTimeEnd2) => {
    const scrollCanvas = calculateScrollCanvas(visibleTimeStart2, visibleTimeEnd2, canvasTimeStart);

    setVisibleTimeStart(scrollCanvas.visibleTimeStart);
    setVisibleTimeEnd(scrollCanvas.visibleTimeEnd);
    setCanvasTimeStart(scrollCanvas.canvasTimeStart);
    setCanvasTimeEnd(scrollCanvas.canvasTimeEnd);

    await updateCraneData(scrollCanvas.canvasTimeStart, scrollCanvas.canvasTimeEnd);
    await updateReservationData(scrollCanvas.canvasTimeStart, scrollCanvas.canvasTimeEnd);
  };

  useUserSocket(
    'bpt-calendar-changed',
    useCallback(() => {
      updateCraneData(canvasTimeStart, canvasTimeEnd);
      updateReservationData(canvasTimeStart, canvasTimeEnd);
    }, [canvasTimeEnd, canvasTimeStart, updateCraneData, updateReservationData])
  );

  const setTimelineType = async (type, keepPlace = true) => {
    setLoader(true);
    setTimelineUnit(type);

    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    let start = canvasTimeStart + (scrollOffset / width) * viewWidthInTime;
    start = dayjs(start);

    let newVisibleStart = null;
    let newVisibleEnd = null;

    switch (type) {
      case 'day': {
        if (keepPlace) {
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .startOf('day')
            .add(1, 'day')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'week': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(7, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();
          newVisibleStart = start
            .startOf('day')
            .add(-2, 'days')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(4, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }

      case 'month': {
        if (keepPlace) {
          newVisibleStart = start.toDate().valueOf();
          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        } else {
          start = dayjs();

          newVisibleStart = start
            .startOf('isoWeek')
            .toDate()
            .valueOf();

          newVisibleEnd = start
            .add(28, 'days')
            .toDate()
            .valueOf();
        }

        break;
      }
      default:
        break;
    }

    const [canvasStart, canvasEnd] = getCanvasBoundariesFromVisibleTime(newVisibleStart, newVisibleEnd);
    setVisibleTimeStart(newVisibleStart);
    setVisibleTimeEnd(newVisibleEnd);
    setCanvasTimeStart(canvasStart);
    setCanvasTimeEnd(canvasEnd);

    setForceUpdate(true);

    await updateCraneData(canvasStart, canvasEnd);
    await updateReservationData(canvasStart, canvasEnd);
    setLoader(false);
  };

  const sendDeleteReservation = async id => {
    await apiCall('delete', 'pbp/crane-reservations', {
      id: id,
    });

    if (mounted.current) {
      await updateCraneData(canvasTimeStart, canvasTimeEnd);
      await updateReservationData(canvasTimeStart, canvasTimeEnd);
    }
  };

  const sendAddReservation = async reservationData => {
    let result = await apiCall('post', 'pbp/crane-reservations', {
      notes: reservationData.notes,
      crane_id: reservationData.crane_id,
      start_time: dayjs(reservationData.start_time)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      end_time: dayjs(reservationData.end_time)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      await updateCraneData(canvasTimeStart, canvasTimeEnd);
      await updateReservationData(canvasTimeStart, canvasTimeEnd);
    }

    return result;
  };

  const sendUpdateReservation = async reservationData => {
    let result = await apiCall('put', 'pbp/crane-reservations', {
      id: reservationData.id,
      notes: reservationData.notes,
      crane_id: reservationData.crane_id,
      start_time: dayjs(reservationData.start_time)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      end_time: dayjs(reservationData.end_time)
        .second(0)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    if (mounted.current) {
      await updateCraneData(canvasTimeStart, canvasTimeEnd);
      await updateReservationData(canvasTimeStart, canvasTimeEnd);
    }

    return result;
  };

  const setToday = () => {
    if (timelineUnit === 'day') {
      setTimelineType('day', false);
    } else if (timelineUnit === 'week') {
      setTimelineType('week', false);
    } else if (timelineUnit === 'month') {
      setTimelineType('month', false);
    }
    updateTime();
  };

  const moveForwards = async () => {
    let days = 1;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      days = 1;
      start = start.startOf('day');
    } else if (timelineUnit === 'week') {
      days = 7;
      start = start.startOf('isoWeek');
    } else if (timelineUnit === 'month') {
      days = 28;
    }

    let ctStart = start.valueOf();
    let vtStart = start.add(days, 'days').valueOf();
    let vtEnd = start.add(2 * days, 'days').valueOf();
    let ctEnd = start.add(3 * days, 'days').valueOf();

    setVisibleTimeStart(vtStart);
    setVisibleTimeEnd(vtEnd);
    setCanvasTimeStart(ctStart);
    setCanvasTimeEnd(ctEnd);

    await updateCraneData(ctStart, ctEnd);
    await updateReservationData(ctStart, ctEnd);
  };

  const moveBackwards = async () => {
    let timespan = 0;
    let start = dayjs(visibleTimeStart);
    if (timelineUnit === 'day') {
      timespan = 86400000;
      start = start.startOf('day').valueOf();
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
      start = start.startOf('isoWeek').valueOf();
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
      start = start.valueOf();
    }

    setVisibleTimeStart(start - timespan);
    setVisibleTimeEnd(start);
    setCanvasTimeStart(start - 2 * timespan);
    setCanvasTimeEnd(start + timespan);

    await updateCraneData(start - 2 * timespan, start + timespan);
    await updateReservationData(start - 2 * timespan, start + timespan);
  };

  const changeDate = async date => {
    const millisecondsDate = dayjs(date).valueOf();
    let timespan = 0;
    if (timelineUnit === 'day') {
      timespan = 86400000;
    } else if (timelineUnit === 'week') {
      timespan = 7 * 86400000;
    } else if (timelineUnit === 'month') {
      timespan = 28 * 86400000;
    }

    setVisibleTimeStart(millisecondsDate);
    setVisibleTimeEnd(millisecondsDate + timespan);
    setCanvasTimeStart(millisecondsDate - timespan);
    setCanvasTimeEnd(millisecondsDate + 2 * timespan);

    await updateCraneData(millisecondsDate - timespan, millisecondsDate + 2 * timespan);
    await updateReservationData(millisecondsDate - timespan, millisecondsDate + 2 * timespan);
  };

  const loadMoreRight = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const loadMoreLeft = scrollX => {
    const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
    const start = canvasTimeStart + (scrollX / width) * viewWidthInTime;
    const end = canvasTimeStart + (scrollX / width) * viewWidthInTime + viewWidthInTime;

    updateScrollCanvas(start, end);
  };

  const [zoomSliderValue, setZoomSliderValue] = useState(6);

  const { data: craneData, error: craneError } = useApi(
    'get',
    'pbp/cranes',
    {},
    null,
    modules.predictive_berth_planning_module === 'enabled'
  );

  const cranes = craneError || !craneData ? [] : craneData;

  const reservationCraneList = [];

  reservations.forEach(res => {
    if (
      craneList.findIndex(c => Number(c.craneId) === res.crane_id) === -1 &&
      reservationCraneList.findIndex(c => Number(c.craneId) === res.crane_id) === -1
    ) {
      reservationCraneList.push({
        craneId: res.crane_id,
        craneName: res.crane_name,
        works: [],
      });
    }
  });

  return (
    <CranePlanningToolContext.Provider
      value={{
        visibleTimeStart,
        setVisibleTimeStart,
        visibleTimeEnd,
        setVisibleTimeEnd,
        canvasTimeStart,
        setCanvasTimeStart,
        canvasTimeEnd,
        setCanvasTimeEnd,
        getLeftOffsetFromDate,
        getDateFromLeftOffsetPosition,
        canvasWidth,
        setCanvasWidth,
        timelineUnit,
        setTimelineUnit,
        width,
        setWidth,
        updateScrollCanvas,
        setTimelineType,
        setToday,
        indicatorLine,
        setIndicatorLine,
        moveForwards,
        moveBackwards,
        changeDate,
        getStartData,
        loadMoreRight,
        loadMoreLeft,
        loader,
        rowHeight,
        setRowHeight,
        zoomSliderValue,
        setZoomSliderValue,
        setScrollOffset,
        forceUpdate,
        setForceUpdate,
        sidePanelWidth,
        setSidePanelWidth,
        craneList,
        setSidebarWidth,
        sidebarWidth,
        reservations,
        setReservations,
        sendDeleteReservation,
        sendAddReservation,
        sendUpdateReservation,
        cranes,
        reservationCraneList,
        updateCranes,
      }}
    >
      {children}
    </CranePlanningToolContext.Provider>
  );
};
