import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Switch from 'antd/es/switch';
import { MapContainer as Map } from 'react-leaflet';

import { UserContext } from '../../context/UserContext';
import IncidentsModalMapInnerContainer from './IncidentsModalMapInnerContainer';

const Options = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const Option = styled.div`
  background-color: ${props => (props.selected ? props.theme.color.secondary : props.theme.color.white)};
  border: 1px solid ${props => (props.selected ? props.theme.color.secondary : '#b1b1b1')};
  color: ${props => (props.selected ? props.theme.color.white : props.theme.color.grey_dark)};
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 12px;
  margin-bottom: 6px;
  min-width: 60px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin: ${props => (props.smallMargin ? '12px 0 8px 0' : '24px 0 8px 0')};

  button {
    margin-top: 3px;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  position: relative;

  textarea {
    resize: none;
  }

  ${props => {
    if (props.grab) {
      return `
        .leaflet-grab {
          cursor: grab;
        }
      `;
    } else {
      return `
        .leaflet-grab {
          cursor: default;
        }
      `;
    }
  }}
`;

const MapBlocker = styled.div`
  position: absolute;
  z-index: 600;
  width: 100%;
  height: 278px;
  top: 30px;
  left: 0;
  background-color: #fbfbfb;
  opacity: 0.8;
`;

const SwitchLabel = styled.div`
  margin-left: 12px;
`;

const Coordinates = styled.div`
  margin-top: 12px;
`;

const IncidentModalMap = ({
  data,
  inputData,
  mapOn,
  setMap,
  setPoint,
  setAreas,
  deleteMapId,
  pointMap,
  setPointMap,
  editOn,
}) => {
  const { namespace, mapDefaultCoordinates } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [mouseDown, setMouseDown] = useState(false);
  const [grabbing, setGrabbing] = useState(false);

  const [appliedZoom, setAppliedZoom] = useState(9);
  let appliedCoordinates = mapDefaultCoordinates === undefined ? '59.129089,14.373028' : mapDefaultCoordinates;

  const inputDataMap = inputData[data.addition ? data.addition.data_model.name : data.data_model.name] || [];

  const onMouseDown = () => {
    if (pointMap) {
      setMouseDown(true);
    }
  };

  const onMouseMove = () => {
    if (pointMap && !grabbing && mouseDown) setGrabbing(true);
  };

  const onMouseUp = () => {
    if (pointMap) {
      setGrabbing(false);
      setMouseDown(false);
    }
  };

  return (
    <RowContainer grab={!pointMap ? true : grabbing}>
      <SwitchContainer smallMargin={!data.addition}>
        <Switch size="small" checked={mapOn} onChange={e => setMap(e)} />
        <SwitchLabel>{t('Choose location in the map')}</SwitchLabel>
      </SwitchContainer>
      <Options>
        <Option selected={pointMap} onClick={() => setPointMap(true)}>
          {t('Point')}
        </Option>
        <Option selected={!pointMap} onClick={() => setPointMap(false)}>
          {t('Area')}
        </Option>
      </Options>
      <div onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
        <Map
          style={{
            height: '240px',
            width: '100%',
          }}
          center={appliedCoordinates.split(',')}
          zoom={appliedZoom}
          tap={false}
          zoomControl={false}
        >
          <IncidentsModalMapInnerContainer
            zoom={appliedZoom}
            setZoom={setAppliedZoom}
            setPoint={setPoint}
            setAreas={setAreas}
            mapOn={mapOn}
            values={inputDataMap}
            deleteMapId={deleteMapId}
            pointMap={pointMap}
            editOn={editOn}
          />
        </Map>
      </div>
      {pointMap ? (
        <Coordinates>
          <span style={{ paddingRight: '12px' }}>{t('Selected coordinates (latitude, longitude):')}</span>
          {inputDataMap[0]?.geometry.type === 'Point'
            ? '(' + inputDataMap[0].geometry.coordinates[1] + ', ' + inputDataMap[0].geometry.coordinates[0] + ')'
            : '-'}
        </Coordinates>
      ) : (
        <Coordinates>
          <span style={{ paddingRight: '12px' }}>{t('Amount of selected areas:')}</span>
          {inputDataMap[0]?.geometry.type === 'Point' ? '0' : inputDataMap.length}
        </Coordinates>
      )}
      {!mapOn && <MapBlocker />}
    </RowContainer>
  );
};
export default IncidentModalMap;
