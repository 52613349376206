import React, { useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';

import { TIME_FORMAT } from '../../../utils/constants';

import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';
import { UserContext } from '../../../context/UserContext';

import { color_reserved } from '../../commonCalendar/utility/constants';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

const ReservationContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  z-index: 50;
  border: 1px solid white;
  opacity: 1;
  cursor: default;
`;

const ReservationInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TooltipContainer = styled.div`
  max-width: 400px;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey_medium};
  padding: 5px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
  line-height: 21px;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
  position: relative;
`;

const Common = styled.div`
  display: flex;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.beige};
  width: calc(100% + 26px);
  left: -13px;
  height: 22px;
`;

const SecondColumn = styled.div`
  max-width: 240px;
  position: relative;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const NotesValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const ReservationNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px;
  color: #000000;
  font-weight: 600;
`;

const Reservation = ({ reservation }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { canvasTimeStart, canvasTimeEnd, canvasWidth, rowHeight } = useContext(CranePlanningToolContext);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();

  const starttime = dayjs.max([dayjs(reservation.start_time), dayjs(canvasTimeStart)]);
  const endtime = dayjs.min([dayjs(reservation.end_time), dayjs(canvasTimeEnd)]);

  const left = dayjs.duration(dayjs(starttime).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels - 2;
  const width = dayjs.duration(dayjs(endtime).diff(starttime)).asMilliseconds() * millisecondInPixels + 4;

  const getReservationProps = () => {
    let fontSize = '12px';

    return {
      style: {
        width,
        top: 0,
        left,
        backgroundColor: color_reserved,
        height: rowHeight,
        fontSize,
      },
    };
  };

  const ReservationToolTip = () => {
    return (
      <TooltipContainer data-id="reservation-tooltip">
        <Common>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Crane')}:</RowHeaderForTooltip>
            <RowHeaderForTooltip>{t('Notes')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>
              {reservation.crane_name} ({reservation.crane_short_name})
            </TooltipValue>
            <NotesValue>{reservation.notes ? reservation.notes : '-'}</NotesValue>
          </SecondColumn>
        </Common>
        <Common style={{ marginTop: '6px' }}>
          <Background />
          <FirstColumn style={{ marginRight: '18px' }}>
            <RowHeaderForTooltip style={{ fontWeight: 600 }}>{t('Time')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue style={{ fontWeight: 600 }}>
              {dayjs(reservation.start_time).format(TIME_FORMAT)} - {dayjs(reservation.end_time).format(TIME_FORMAT)}
            </TooltipValue>
          </SecondColumn>
        </Common>
      </TooltipContainer>
    );
  };

  return (
    <ReservationContainer data-testid={`reservation-${reservation.id}`} {...getReservationProps()}>
      <Tooltip placement="top" title={ReservationToolTip()} color="white" overlayStyle={{ pointerEvents: 'none' }}>
        <div style={{ width: '100%', height: '100%' }}>
          <ReservationInnerContainer>
            <ReservationNameContainer>{reservation.notes}</ReservationNameContainer>
          </ReservationInnerContainer>
        </div>
      </Tooltip>
    </ReservationContainer>
  );
};

export default Reservation;
