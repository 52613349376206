import React, { useState, useContext, useRef, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import { SketchPicker } from 'react-color';
import Icon from '../../ui/Icon';
import ButtonLight from '../../ui/ButtonLight';
import TypeCreationModal from './TypeCreationModal';
import { fromUnderscore } from '../../../utils/utils';
import circleToPolygon from 'circle-to-polygon';

import Input from 'antd/es/input';
import Popover from 'antd/es/popover';
import Checkbox from 'antd/es/checkbox';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';
import Switch from 'antd/es/switch';
import InputNumber from 'antd/es/input-number';

const { TextArea } = Input;

const { Option } = Select;

const EditTabWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
`;

const CommonEdit = styled.div`
  display: flex;
  font-size: 13px;
`;

const FirstColumnEdit = styled.div`
  margin-right: 10px;
  height: 32px;
`;

const SecondColumnEdit = styled.div`
  max-width: 198px;
`;

const RowHeaderForTooltipEdit = styled.div`
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 5px;
  width: max-content;
  min-width: 86.2px;
  display: flex;
`;

const CustomHeader = styled.div`
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const ColorDiv = styled.div`
  margin-top: 1px;
  margin-bottom: 7px;
  margin-right: 5px;
  width: calc(50% - 3.5px);
  height: 30px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  &:hover {
    cursor: pointer;
  }
`;

const ColorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BreakLine = styled.div`
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #d8d8d8;
`;

const NewTypeOption = styled.span`
  color: #4990dddd;
`;

const JsonSort = styled.span`
  color: ${props => (props.sort ? '#4990dddd' : '#d0011cdd')};
  padding-left: 5px;
  &:hover {
    cursor: pointer;
    color: ${props => (props.sort ? '#4990dd88' : '#d0011c88')};
  }
`;

const JsonFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flexwrap: nowrap;
`;

const NewTypeWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CoordHeader = styled.div`
  width: 50%;
`;

const RowHeaderForTooltipCoords = styled.div`
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 3px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const SecondColumnCoords = styled.div``;

const CoordWrapper = styled.div`
  width: 100%;
`;

const Required = styled.span`
  color: red;
`;

const SwitchLabel = styled.div`
  margin-left: 12px;
  line-height: 22px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;

  button {
    margin-top: 3px;
  }
`;

const GeoAssetToolEditTab = ({ data, changeTab }) => {
  const { namespace } = useContext(UserContext);
  const {
    rightPanelData,
    typeData,
    postAsset,
    deleteAssetFunction,
    updateAsset,
    currentNamespace,
    setNewAssetStyle,
    setOldAssetStyle,
    setNewAssetCoords,
    setStyleUpdate,
    styleUpdate,
    newPostedType,
    setNewPostedType,
    setGeoJsonLayerUpdate,
    geoJsonLayerUpdate,
    setGeoJsonLayerData,
    newRotatedCoords,
    setRotateAssetsCount,
    setNewAssetRotation,
    editPointsInCreate,
    setEditPointsInCreate,
  } = useContext(GeoAssetToolContext);
  const { t } = useTranslation(namespace);

  const [update, setUpdate] = useState(false);
  const [circleUpdate, setCircleUpdate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [newTypeText, setNewTypeText] = useState('');
  const [coordsUpdate, setCoordsUpdate] = useState(false);
  const [oldAssetUpdate, setOldAssetUpdate] = useState(false);
  const typeRef = useRef(typeData[data.type_id]?.type_fields || null);
  const dataRef = useRef(data);
  const [fieldsAreValid, setFieldsAreValid] = useState(false);
  const firstUpdate = useRef(true);
  // eslint-disable-next-line no-unused-vars
  const [typeKeys, setTypeKeys] = useState(typeRef.current ? Object.keys(typeRef.current) : []);
  //TODO: Unique key is missing somewhere.

  const { message } = App.useApp();

  const VisibilityOptions = () => {
    return (
      <>
        <Option value={'hidden'}>{t('Hidden')}</Option>
        <Option value={'restricted'}>{t('Restricted')}</Option>
        <Option value={'public'}>{t('Public')}</Option>
      </>
    );
  };

  const validateFields = data => {
    if (!data.name) return false;
    if (!data.type_id) return false;
    for (let index = 0; index < typeKeys.length; index++) {
      const key = typeKeys[index];
      if (!typeRef.current) return false;
      const required = typeRef.current[key].required && typeRef.current[key].type !== 'boolean';
      if (required) {
        if (!data.properties[key]) return false;
      }
    }
    if (data.geo_data.type === 'Point') {
      if (!data.geo_data.coordinates[0] || !data.geo_data.coordinates[1]) return false;
    } else {
      for (let index = 0; index < data.geo_data.coordinates.length; index++) {
        const coord = data.geo_data.coordinates[index];
        if (!coord[0] || !coord[1]) return false;
        break;
      }
    }
    return true;
  };

  useLayoutEffect(() => {
    const typeIds = Object.keys(typeData || []);
    let options = [];
    for (let index = 0; index < typeIds.length; index++) {
      const typeid = typeIds[index];
      options.push({ key: typeid, value: typeid, label: typeData[typeid].name, title: typeData[typeid].name });
    }
    setTypeOptions(options);
    if (newPostedType) {
      setNewTypeText('');
      dataRef.current.type_id = newPostedType.type_id;
      typeRef.current = newPostedType.type_fields;
      setTypeKeys(Object.keys(newPostedType.type_fields));
      if (dataRef.current.properties.length === 0) dataRef.current.properties = {};
      const validProperties = [...Object.keys(newPostedType.type_fields)];
      const previousProperties = Object.keys(dataRef.current.properties);
      for (let index = 0; index < previousProperties.length; index++) {
        const key = previousProperties[index];
        if (!validProperties.includes(key) || key !== 'center') {
          delete dataRef.current.properties[key];
        }
      }
      setNewPostedType(null);
      setUpdate(!update);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeData]);

  useLayoutEffect(() => {
    const isCirclePolygon =
      !!dataRef.current.properties.center && dataRef.current.style.segments && dataRef.current.style.radius
        ? true
        : false;
    if (data.createdFromGeoJsonLayer) {
      setGeoJsonLayerData({
        geo_asset_id: data?.geo_asset_id,
        type: 'Feature',
        properties: dataRef.current.properties,
        style: dataRef.current.style,
        geometry: {
          type: dataRef.current.geo_data.type,
          coordinates:
            dataRef.current.geo_data.type === 'Polygon'
              ? [[...dataRef.current.geo_data.coordinates, dataRef.current.geo_data.coordinates[0]]]
              : dataRef.current.geo_data.coordinates,
        },
      });
      setGeoJsonLayerUpdate(!geoJsonLayerUpdate);
    } else if (data.newAsset && !isCirclePolygon) {
      setNewAssetCoords(dataRef.current.geo_data.coordinates);
      setNewAssetRotation(dataRef.current.geo_data.rotation);
      setNewAssetStyle(dataRef.current.style);
      setStyleUpdate(!styleUpdate);
    } else {
      setOldAssetStyle(dataRef.current);
      setOldAssetUpdate(!oldAssetUpdate);
    }
    setFieldsAreValid(validateFields(dataRef.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, rightPanelData, coordsUpdate]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const isCirclePolygon =
      !!dataRef.current.properties.center && dataRef.current.style.segments && dataRef.current.style.radius
        ? true
        : false;
    if (isCirclePolygon) {
      const { lng, lat } = dataRef.current.properties.center;
      const newCircle = circleToPolygon([lng, lat], parseInt(dataRef.current.style.radius), {
        numberOfEdges: dataRef.current.style.segments,
      });
      let transformedCoords = [];
      const flatCircleCoords = newCircle.coordinates.flat();
      for (let index = 0; index < flatCircleCoords.length; index++) {
        const element = flatCircleCoords[index];
        transformedCoords.push([element[1], element[0]]);
      }
      const coordsCopy = [...transformedCoords];
      coordsCopy.splice(0, 1);
      dataRef.current.geo_data.coordinates = coordsCopy;
      dataRef.current.geo_data.rotation = false;
      if (data.createdFromGeoJsonLayer && isCirclePolygon) {
        setGeoJsonLayerData({
          geo_asset_id: data?.geo_asset_id,
          type: 'Feature',
          properties: dataRef.current.properties,
          style: dataRef.current.style,
          geometry: {
            type: dataRef.current.geo_data.type,
            coordinates: [transformedCoords],
          },
        });

        setGeoJsonLayerUpdate(!geoJsonLayerUpdate);
      } else {
        setOldAssetStyle(dataRef.current);
        setOldAssetUpdate(!oldAssetUpdate);
      }
      setFieldsAreValid(validateFields(dataRef.current));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleUpdate]);

  useLayoutEffect(() => {
    if (!data.newAsset) {
      setStyleUpdate(!styleUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldAssetUpdate]);

  useEffect(() => {
    if (!data.fromGeoJson) {
      if (data.newAsset) {
        setNewAssetCoords(data.geo_data.coordinates);
      }
      dataRef.current = data;
      if (dataRef.current.properties.length === 0) dataRef.current.properties = {};
      typeRef.current = typeData[data.type_id]?.type_fields || null;
      setTypeKeys(typeRef.current ? Object.keys(typeRef.current) : []);
      setCoordsUpdate(!coordsUpdate);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData]);

  useEffect(() => {
    if (newRotatedCoords) {
      dataRef.current.geo_data.coordinates = newRotatedCoords;
      dataRef.current.geo_data.rotation = true;
      setUpdate(!update);
    }
  }, [newRotatedCoords]); // eslint-disable-line react-hooks/exhaustive-deps

  const geoTypeSpecificStyle = top => {
    const isCirclePolygon =
      !!dataRef.current.properties.center && dataRef.current.style.segments && dataRef.current.style.radius
        ? true
        : false;
    if (dataRef.current.fromGeoJson) return;
    if (isCirclePolygon || dataRef.current.geo_data.type === 'Polygon') {
      if (top) {
        return (
          <>
            <RowHeaderForTooltipEdit>{t('Fill color')}:</RowHeaderForTooltipEdit>
            {isCirclePolygon && (
              <>
                <RowHeaderForTooltipEdit>{t('Radius')}:</RowHeaderForTooltipEdit>
                <RowHeaderForTooltipEdit>{t('Segments')}:</RowHeaderForTooltipEdit>
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            <ColorWrapper>
              <Popover
                content={
                  <SketchPicker
                    color={dataRef.current.style.fillColor}
                    onChange={e => {
                      dataRef.current.style.fillColor = e.hex;
                      setUpdate(!update);
                    }}
                  />
                }
                trigger="click"
              >
                <ColorDiv style={{ backgroundColor: dataRef.current.style.fillColor }} />
              </Popover>
              <InputNumber
                style={{ marginBottom: '5px', height: '32px', width: 'calc(50% - 1.5px)' }}
                value={(dataRef.current.style.fillOpacity * 100).toFixed(0)}
                step={5}
                onChange={e => {
                  dataRef.current.style.fillOpacity = e / 100;
                  setUpdate(!update);
                }}
                min={0}
                max={100}
                formatter={value => `${value}%`}
              />
            </ColorWrapper>
            {isCirclePolygon && (
              <>
                <Tooltip
                  title={
                    <>
                      <span style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <Icon type="vessel-warning" />
                        <p style={{ marginLeft: '5px' }}> {t('Warning: Editing this will reset the circles shape')} </p>
                      </span>
                    </>
                  }
                  color="white"
                  isOpen={true}
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                  placement="top"
                >
                  <InputNumber
                    style={{ marginBottom: '5px', height: '32px', width: '100%' }}
                    value={dataRef.current.style.radius}
                    onChange={e => {
                      if (!e) return;
                      dataRef.current.style.radius = e;
                      setCircleUpdate(!circleUpdate);
                    }}
                    min={1}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    <>
                      <span style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <Icon type="vessel-warning" />
                        <p style={{ marginLeft: '5px' }}> {t('Warning: Editing this will reset the circles shape')} </p>
                      </span>
                    </>
                  }
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                  placement="top"
                >
                  <InputNumber
                    style={{ marginBottom: '5px', height: '32px', width: '100%' }}
                    value={dataRef.current.style.segments}
                    onChange={e => {
                      if (!e) return;
                      dataRef.current.style.segments = e;
                      setCircleUpdate(!circleUpdate);
                    }}
                    min={3}
                    max={
                      dataRef.current.style.radius > 12
                        ? dataRef.current.style.radius > 40
                          ? 40
                          : dataRef.current.style.radius + 6 > 40
                            ? 40
                            : dataRef.current.style.radius + 6
                        : 6 + dataRef.current.style.radius
                    }
                  />
                </Tooltip>
              </>
            )}
          </>
        );
      }
    }
  };

  const changeType = typeID => {
    setNewTypeText('');
    if (typeID === dataRef.current.type_id) return;
    dataRef.current.type_id = typeID;
    typeRef.current = typeData[typeID].type_fields;
    setTypeKeys(Object.keys(typeData[typeID].type_fields));
    const validProperties = [...Object.keys(typeData[typeID].type_fields)];
    const previousProperties = Object.keys(dataRef.current.properties);
    for (let index = 0; index < previousProperties.length; index++) {
      const key = previousProperties[index];
      if (!validProperties.includes(key) && key !== 'center') {
        delete dataRef.current.properties[key];
      }
    }
    setUpdate(!update);
  };

  const sendData = () => {
    if (dataRef.current.properties.length === 0) dataRef.current.properties = {};
    for (let index = 0; index < typeKeys.length; index++) {
      const typeField = typeKeys[index];
      const { required, type } = typeRef.current[typeField];
      if (type === 'json') {
        try {
          if (!(!required && !dataRef.current.properties[typeField])) {
            dataRef.current.properties[typeField] = JSON.parse(dataRef.current.properties[typeField]);
          }
        } catch {
          message.error('Content of ' + typeField + ' is invalid');
          return;
        }
      }
    }
    dataRef.current.namespace = currentNamespace;
    dataRef.current.newAsset
      ? postAsset(JSON.parse(JSON.stringify(dataRef.current)))
      : updateAsset(JSON.parse(JSON.stringify(dataRef.current)));
  };

  return dataRef.current.fromGeoJson ? null : (
    <EditTabWrapper>
      <CommonEdit>
        <FirstColumnEdit>
          <RowHeaderForTooltipEdit>
            {t('Name')}
            <Required>*</Required>
          </RowHeaderForTooltipEdit>
          <RowHeaderForTooltipEdit>
            {t('Permissions')}
            <Required></Required>
          </RowHeaderForTooltipEdit>
          <RowHeaderForTooltipEdit>
            {t('Asset type')}
            <Required>*</Required>
          </RowHeaderForTooltipEdit>
          {typeKeys.map((type, index) => {
            const required = typeRef.current[type].required && typeRef.current[type].type !== 'boolean';
            return (
              <Tooltip
                key={index + ' Header ' + type + 'Tooltip'}
                title={type.length > 13 ? type : null}
                color="white"
                overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                placement="left"
              >
                <RowHeaderForTooltipEdit key={index + ' Header ' + type + index} id={type + index}>
                  <CustomHeader>{fromUnderscore(t(type))}</CustomHeader>
                  {required && <Required>*</Required>}
                </RowHeaderForTooltipEdit>
              </Tooltip>
            );
          })}
        </FirstColumnEdit>
        <SecondColumnEdit>
          <Input
            style={{ marginBottom: '5px' }}
            defaultValue={dataRef.current.name}
            value={dataRef.current.name}
            onChange={e => {
              dataRef.current.name = e.target.value;
              setUpdate(!update);
            }}
          />
          <Select
            style={{ marginBottom: '5px', width: '-webkit-fill-available' }}
            defaultValue={'hidden'}
            value={dataRef.current.permission}
            onChange={e => {
              dataRef.current.permission = e;
              setUpdate(!update);
            }}
          >
            {VisibilityOptions()}
          </Select>
          <Select
            showSearch
            placeholder={t('Select type')}
            optionFilterProp="label"
            onChange={e => changeType(e)}
            onSearch={e => {
              setNewTypeText(e);
            }}
            style={{ marginBottom: '5px', width: '-webkit-fill-available' }}
            value={typeData[dataRef.current.type_id] && dataRef.current.type_id}
            dropdownRender={menu => (
              <>
                {menu}
                <NewTypeWrapper
                  key={'newTypeCreation'}
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  <NewTypeOption>
                    {t('Create')} {newTypeText || t('new type')} <Icon type="plus" />
                  </NewTypeOption>
                </NewTypeWrapper>
              </>
            )}
          >
            {typeOptions.map(type => (
              <Option key={type.key} label={type.label}>
                {type.title}
              </Option>
            ))}
          </Select>
          {typeKeys.map((type, index) => {
            const currentType = typeRef.current[type];
            if (currentType.type === 'boolean') {
              return (
                <Checkbox
                  key={dataRef.current.name + type + 'boolean' + ' ' + index}
                  style={{ height: '32px', paddingTop: '6px', marginBottom: '5px' }}
                  onChange={e => {
                    dataRef.current.properties[type] = e.target.checked;
                    setUpdate(!update);
                  }}
                  checked={dataRef.current.properties[type]}
                >
                  {t('Enabled')}
                </Checkbox>
              );
            } else if (currentType.type === 'number') {
              return (
                <InputNumber
                  key={dataRef.current.name + type + 'number' + ' ' + index}
                  style={{ marginBottom: '5px', height: '32px', width: '100%' }}
                  value={dataRef.current.properties[type]}
                  onChange={e => {
                    dataRef.current.properties[type] = e;
                    setUpdate(!update);
                  }}
                />
              );
            } else if (currentType.type === 'string') {
              return (
                <Input
                  key={dataRef.current.name + type + 'string' + ' ' + index}
                  style={{ marginBottom: '5px' }}
                  value={dataRef.current.properties[type]}
                  onChange={e => {
                    dataRef.current.properties[type] = e.target.value;
                    setUpdate(!update);
                  }}
                />
              );
            } else if (currentType.type === 'json') {
              let valid = false;
              try {
                JSON.parse(dataRef.current.properties[type]);
                valid = true;
              } catch {
                valid = !dataRef.current.properties[type];
              }
              return (
                <>
                  <JsonFieldWrapper>
                    <TextArea
                      key={dataRef.current.name + type + 'json' + ' ' + index}
                      style={{
                        marginBottom: '5px',
                        borderColor: valid ? null : 'red',
                        width: '90%',
                        display: 'flex',
                        minHeight: '40px',
                      }}
                      value={dataRef.current.properties[type]}
                      onChange={e => {
                        dataRef.current.properties[type] = e.target.value;
                        setUpdate(!update);
                      }}
                      placeholder={'Insert valid JSON'}
                      row={30}
                      onResize={area => {
                        window.document.getElementById(type + index).style.marginBottom = `${area.height - 27}px`;
                      }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Tooltip
                        placement="left"
                        title={t(valid ? 'Format JSON' : 'Field content needs to be valid to format')}
                        color="white"
                        overlayInnerStyle={{ color: '#4a4a4a' }}
                      >
                        <JsonSort
                          sort={true}
                          key={dataRef.current.name + type + 'json' + ' ' + index + 'buttons'}
                          onClick={() => {
                            if (valid && !!dataRef.current.properties[type]) {
                              dataRef.current.properties[type] = JSON.stringify(
                                JSON.parse(dataRef.current.properties[type]),
                                null,
                                4
                              );
                              setUpdate(!update);
                            }
                          }}
                        >
                          <Icon type="swap" align="right" style={{ width: '1.3em', height: '1.3em' }} />
                          {/* TODO: Select better icon */}
                        </JsonSort>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={t('Empty field content')}
                        color="white"
                        overlayInnerStyle={{ color: '#4a4a4a' }}
                      >
                        <JsonSort
                          sort={false}
                          key={dataRef.current.name + type + 'json' + ' ' + index + 'buttons'}
                          onClick={() => {
                            dataRef.current.properties[type] = null;
                            setUpdate(!update);
                          }}
                        >
                          <Icon type="trash" align="right" style={{ width: '1.3em', height: '1.3em' }} />
                        </JsonSort>
                      </Tooltip>
                    </div>
                  </JsonFieldWrapper>
                </>
              );
            }
          })}
        </SecondColumnEdit>
      </CommonEdit>
      <BreakLine> {t('Asset style')}</BreakLine>
      <CommonEdit>
        <FirstColumnEdit>
          <RowHeaderForTooltipEdit>{t('Stroke color')}:</RowHeaderForTooltipEdit>
          <RowHeaderForTooltipEdit>{t('Stroke weight')}:</RowHeaderForTooltipEdit>
          {geoTypeSpecificStyle(true)}
        </FirstColumnEdit>
        <SecondColumnEdit>
          <ColorWrapper>
            <Popover
              content={
                <SketchPicker
                  color={dataRef.current.style.color}
                  onChange={e => {
                    dataRef.current.style.color = e.hex;
                    setUpdate(!update);
                  }}
                />
              }
              trigger="click"
            >
              <ColorDiv style={{ backgroundColor: dataRef.current.style.color }} />
            </Popover>
            <InputNumber
              style={{ marginBottom: '5px', height: '32px', width: 'calc(50% - 1.5px)' }}
              value={(dataRef.current.style.opacity * 100).toFixed(0)}
              step={5}
              onChange={e => {
                dataRef.current.style.opacity = e / 100;
                setUpdate(!update);
              }}
              min={0}
              max={100}
              formatter={value => `${value}%`}
            />
          </ColorWrapper>

          <InputNumber
            style={{ marginBottom: '5px', height: '32px', width: '100%' }}
            min={0}
            defaultValue={dataRef.current.style.weight || 2}
            value={dataRef.current.style.weight}
            onChange={e => {
              dataRef.current.style.weight = e;
              setUpdate(!update);
            }}
          />
          {geoTypeSpecificStyle()}
        </SecondColumnEdit>
      </CommonEdit>
      <BreakLine> {t('Coordinates')}</BreakLine>
      {!dataRef.current.properties.center && dataRef.current.newAsset && (
        <SwitchContainer>
          <Switch
            size="small"
            checked={editPointsInCreate}
            onChange={() => {
              setNewAssetRotation(null);
              setEditPointsInCreate(!editPointsInCreate);
            }}
          />
          <SwitchLabel>{t('Edit points in map')}</SwitchLabel>
        </SwitchContainer>
      )}
      <CoordWrapper>
        <RowHeaderForTooltipCoords>
          <CoordHeader>{t('Latitude')}</CoordHeader>
          <CoordHeader>{t('Longitude')}</CoordHeader>
        </RowHeaderForTooltipCoords>
      </CoordWrapper>
      <CommonEdit>
        <CoordWrapper>
          <SecondColumnCoords>
            {dataRef.current.geo_data.type === 'Point' ? (
              <>
                <InputNumber
                  key={dataRef.current.geo_asset_id + ' Coordinates Lat'}
                  step={0.000005}
                  style={{ marginBottom: '5px', height: '30px', marginRight: '5px', width: 'calc(50% - 2.5px)' }}
                  value={dataRef.current.geo_data.coordinates[0]}
                  onChange={e => {
                    dataRef.current.geo_data.coordinates[0] = e;
                    setUpdate(!update);
                  }}
                />
                <InputNumber
                  key={dataRef.current.geo_asset_id + ' Coordinates Lng'}
                  step={0.000005}
                  style={{ marginBottom: '5px', height: '30px', width: 'calc(50% - 2.5px)' }}
                  value={dataRef.current.geo_data.coordinates[1]}
                  onChange={e => {
                    dataRef.current.geo_data.coordinates[1] = e;
                    setUpdate(!update);
                  }}
                />
              </>
            ) : (
              dataRef.current.geo_data.coordinates.map((coordinate, index) => {
                return (
                  <div key={index}>
                    <InputNumber
                      key={'Coordinates ' + index + ' Lat'}
                      step={0.000005}
                      style={{ marginBottom: '5px', height: '30px', marginRight: '5px', width: 'calc(50% - 2.5px)' }}
                      value={dataRef.current.geo_data.coordinates[index][0]}
                      onChange={e => {
                        dataRef.current.geo_data.coordinates[index][0] = e;
                        setUpdate(!update);
                        setRotateAssetsCount(count => count + 1);
                      }}
                    />
                    <InputNumber
                      key={'Coordinates ' + index + ' Lng'}
                      step={0.000005}
                      style={{ marginBottom: '5px', height: '30px', width: 'calc(50% - 2.5px)' }}
                      value={dataRef.current.geo_data.coordinates[index][1]}
                      onChange={e => {
                        dataRef.current.geo_data.coordinates[index][1] = e;
                        setUpdate(!update);
                        setRotateAssetsCount(count => count + 1);
                      }}
                    />
                  </div>
                );
              })
            )}
          </SecondColumnCoords>
        </CoordWrapper>
      </CommonEdit>
      <ButtonWrapper>
        <ButtonLight
          type="button"
          lightred
          style={{ marginRight: '5px' }}
          onClick={() => {
            deleteAssetFunction(dataRef.current);
          }}
        >
          {rightPanelData.newAsset ? t('Cancel') : t('Delete')}
        </ButtonLight>
        <ButtonLight
          disabled={!fieldsAreValid}
          type="button"
          onClick={() => {
            sendData();
            try {
              changeTab();
            } catch {
              console.error('changeTab failed.');
            }
          }}
        >
          {dataRef.current.newAsset ? t('Save') : t('Apply')}
        </ButtonLight>
      </ButtonWrapper>
      <TypeCreationModal
        isOpen={isModalVisible}
        initialName={newTypeText}
        typeOptions={typeOptions}
        initialNamespace={currentNamespace}
        handleVisibility={() => {
          setIsModalVisible(!isModalVisible);
        }}
      />
    </EditTabWrapper>
  );
};

export default GeoAssetToolEditTab;
