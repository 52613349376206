import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { UserContext } from '../../context/UserContext';

import styled from 'styled-components';
import MarkDownHandler from '../info/MarkDownHandler';
import { gdprMarkdownData } from './gdpr';

const PopupWrapper = styled.div`
  position: relative;
`;

const ModalInner = styled.div`
  max-height: 70vh;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const PopupButton = styled.button`
  background-color: ${props => (props.orig ? 'transparent' : 'white')};
  color: ${props => props.orig && 'white'};
  border: none;
  cursor: pointer;
  text-decoration: underline;
  min-width: 156px;
  max-width: 236px;
  font-size: 16px;

  &:hover {
    color: #8a8a8a;
  }
`;

const ActionButtons = styled.div`
  text-align: center;
  margin-top: ${props => (props.scrolledToBottom ? '12px' : '9px')};
  margin-bottom: -6px;

  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    margin-bottom: ${props => (props.scrolledToBottom ? '14px' : '1px')};
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 400px;
  max-height: calc(70vh - 124px);
`;

const StyledButton = styled.button`
  background-color: ${props => (props.disabled ? '#a8a8a8' : props.theme.color.deep_blue)};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 24px;
  text-align: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: 36px;
  text-transform: uppercase;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    background-color: ${props => (props.disabled ? '#a8a8a8' : '#729bc0')};
  }

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

const InfoText = styled.div`
  font-size: 11px;
`;

const CheckBottom = styled.div`
  display: flex;
  justify-content: center;
  min-height: 60px;
  min-width: 100px;
  padding-top: 30px;
`;

const CheckBottomButton = styled.div`
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  font-weight: 600;
  padding: 3px 12px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.color.secondary};
`;

const GdprPopup = ({ isOpen, setIsOpen, handleChecked, checked, orig }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const scrollRef = useRef();

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 110;
    if (bottom) {
      setScrolledToBottom(true);
    }
  };

  if (!scrolledToBottom) {
    setTimeout(() => {
      if (scrollRef.current && scrollRef.current.scrollHeight === scrollRef.current.clientHeight) {
        setScrolledToBottom(true);
      }
    }, 2000);
  }

  return (
    <PopupWrapper>
      <PopupButton orig={orig} type="button" className="button" onClick={() => setIsOpen(true)}>
        {t('Click here to read the terms and conditions')}
      </PopupButton>
      {isOpen && (
        <Modal
          title={t('PRIVACY NOTICE AND USER CONCENT FORM')}
          open={isOpen}
          width={1000}
          onCancel={() => {
            setIsOpen(false);
            setScrolledToBottom(false);
          }}
          footer={null}
        >
          <ModalInner>
            <ScrollContainer ref={scrollRef} onScroll={handleScroll}>
              <MarkDownHandler markDownData={gdprMarkdownData} editable={false} />
              <CheckBottom>
                {!scrolledToBottom && (
                  <CheckBottomButton>{t('I have read the terms and conditions.')}</CheckBottomButton>
                )}
              </CheckBottom>
            </ScrollContainer>
            <MiddleLine />
            <ActionButtons scrolledToBottom={!(!checked && !scrolledToBottom)}>
              <StyledButton
                disabled={!checked && !scrolledToBottom}
                type="button"
                cancel
                onClick={() => {
                  handleChecked();
                  setIsOpen(false);
                  setScrolledToBottom(false);
                }}
              >
                {t('I accept the terms and conditions')}
              </StyledButton>
              {!checked && !scrolledToBottom && <InfoText>{t('Scroll to the bottom to enable the button.')}</InfoText>}
            </ActionButtons>
          </ModalInner>
        </Modal>
      )}
    </PopupWrapper>
  );
};

export default GdprPopup;
