import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InvoicingContext } from '../../context/InvoicingContext';
import { UserContext } from '../../context/UserContext';

const Button = styled.div`
  background-color: ${props => (props.selected ? props.theme.color.secondary : 'white')};
  border-radius: 4px;
  border: 1px solid ${props => (props.selected ? props.theme.color.secondary : props.theme.color.grey)};
  color: ${props => (props.selected ? 'white' : props.theme.color.grey_medium)};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
  text-align: center;
  width: 125px;

  @media (max-width: 650px) {
    width: 53px;
    font-size: 12px;
    padding: 2px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #4a4a4a;
  font-weight: 550;
  text-transform: uppercase;

  @media (max-width: 650px) {
    display: none;
  }
`;

const netTonnageRangeText = {
  0: '< 1000',
  1000: '1000 - 2999',
  3000: '3000 - 5999',
  6000: '6000 - 9999',
  10000: '> 10000',
};

const VesselNetTonnageSelector = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { selectedNetTonnage, setSelectedNetTonnage, contractNetTonnages } = useContext(InvoicingContext);

  return (
    contractNetTonnages.length > 0 && (
      <>
        <Header>{t('Net Tonnage')}</Header>
        {contractNetTonnages.map((netTonnage, index) => (
          <Button
            key={index}
            selected={netTonnage === selectedNetTonnage}
            onClick={() => setSelectedNetTonnage(netTonnage)}
          >
            {netTonnageRangeText[netTonnage]}
          </Button>
        ))}
      </>
    )
  );
};

export default VesselNetTonnageSelector;
