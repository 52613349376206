import * as React from "react";
const SvgLogoEuFlag = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 1234 838", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(4.16667,0,0,4.16667,0,0)" }, /* @__PURE__ */ React.createElement("rect", { x: 0.85, y: 0.442, width: 294.945, height: 200.344, style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("rect", { x: 6.522, y: 5.934, width: 283.602, height: 189.07, style: {
  fill: "rgb(7,78,162)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.28,47.129L148.264,42.762L154.249,47.129L151.971,40.067L158.049,35.7L150.557,35.7L148.264,28.551L145.971,35.707L138.483,35.7L144.557,40.067L142.28,47.129Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.311,55.45L117.295,51.082L123.276,55.45L121.002,48.387L127.077,44.02L119.585,44.02L117.292,36.864L114.999,44.028L107.51,44.02L113.584,48.387L111.311,55.45Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M94.635,59.575L92.342,66.739L84.854,66.731L90.928,71.098L88.651,78.161L94.635,73.793L100.62,78.161L98.342,71.098L104.417,66.731L96.928,66.731L94.635,59.575Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.323,104.723L92.303,109.09L90.03,102.028L96.104,97.661L88.616,97.661L86.323,90.504L84.03,97.676L76.538,97.661L82.616,102.028L80.338,109.09L86.323,104.723Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.928,128.684L94.635,121.528L92.342,128.692L84.854,128.684L90.928,133.051L88.651,140.114L94.635,135.739L100.62,140.114L98.342,133.051L104.417,128.684L96.928,128.684Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M119.631,151.387L117.338,144.239L115.049,151.395L107.557,151.387L113.631,155.754L111.358,162.817L117.342,158.45L123.323,162.817L121.049,155.754L127.124,151.387L119.631,151.387Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.561,159.614L148.268,152.457L145.979,159.622L138.487,159.614L144.561,163.981L142.288,171.043L148.272,166.676L154.252,171.043L151.975,163.981L158.053,159.614L150.561,159.614Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.491,151.387L179.202,144.239L176.905,151.395L169.42,151.387L175.491,155.754L173.217,162.817L179.202,158.45L185.186,162.817L182.905,155.754L188.983,151.387L181.491,151.387Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.202,128.684L201.905,121.528L199.608,128.692L192.124,128.684L198.202,133.051L195.92,140.114L201.905,135.739L207.889,140.114L205.608,133.051L211.686,128.684L204.202,128.684Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.913,97.575L212.42,97.575L210.124,90.418L207.835,97.582L200.342,97.575L206.42,101.942L204.147,109.004L210.124,104.629L216.108,109.004L213.835,101.942L219.913,97.575Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M195.92,78.067L201.905,73.7L207.889,78.067L205.608,71.004L211.686,66.645L204.202,66.645L201.905,59.489L199.608,66.653L192.124,66.645L198.202,71.004L195.92,78.067Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M179.288,36.872L176.999,44.036L169.506,44.02L175.577,48.395L173.303,55.457L179.295,51.082L185.272,55.457L182.999,48.395L189.077,44.02L181.577,44.02L179.288,36.872Z", style: {
  fill: "rgb(255,242,0)",
  fillRule: "nonzero"
} })));
export default SvgLogoEuFlag;
