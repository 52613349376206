import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';

import { UserContext } from '../../../../context/UserContext';
import { CranePlanningToolContext } from '../../../../context/CranePlanningToolContext';

import ButtonLight from '../../../ui/ButtonLight';
import Icon from '../../../../components/ui/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light2};
  margin-bottom: 1px;
`;

const DatePickerContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 0px;
  border-radius: 3px;
  background-color: white;
  z-index: 81;
  padding: 12px;
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.15);
  margin-left: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  margin-top: 1px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: pointer;
`;

const IconContainer2 = styled.div`
  line-height: 21px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
`;

const Text = styled.div`
  margin-right: 2px;

  @media (max-width: 1099px) {
    font-size: 12px;
    margin-right: 0px;
    line-height: 24px;
  }
`;

const MonthContainer = styled.div`
  display: flex;
  line-height: 21px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const CalendarButtons = styled.div`
  margin-top: 13px;
  margin-right: 4px;
  margin-left: 4px;
  position: relative;
  display: flex;
`;

const ButtonVersion = styled(ButtonLight)`
  border-color: ${({ theme }) => theme.color.grey_light};
  font-size: 14px;
  min-width: 70px;
  width: 70px;
  margin-bottom: 8px;
`;

const Column = styled.div`
  display: inline-block;
  margin-left: 43px;
  margin-top: 5px;
`;

const ColorContainer = styled.div`
  display: flex;
`;

const ColorSquare = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 8px;
`;

const TextAndAmount = styled.div`
  font-size: 11.5px;
  color: ${({ theme }) => theme.color.grey};
  white-space: nowrap;
`;

const SidebarHeader = () => {
  const { namespace, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    sidebarWidth,
    visibleTimeStart,
    moveForwards,
    moveBackwards,
    changeDate,
    timelineUnit,
    setTimelineType,
    setToday,
  } = useContext(CranePlanningToolContext);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const setCalendarTypeDay = () => {
    setTimelineType('day');
  };

  const setCalendarTypeWeek = () => {
    setTimelineType('week');
  };

  const setCalendarTypeMonth = () => {
    setTimelineType('month');
  };

  const { Option } = Select;
  const handleChange = value => {
    switch (value) {
      case 'day':
        setCalendarTypeDay();
        break;
      case 'week':
        setCalendarTypeWeek();
        break;
      case 'month':
        setCalendarTypeMonth();
        break;

      default:
        break;
    }
  };

  const getRootProps = () => {
    const width = sidebarWidth;
    return {
      style: {
        width,
      },
    };
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  const goBackwards = () => {
    moveBackwards();
  };

  const goForwards = () => {
    moveForwards();
  };

  const onDateChange = date => {
    changeDate(date);
    setCalendarOpen(false);
  };

  return (
    <Container>
      <Column>
        <ColorContainer>
          <ColorSquare color="#A5D6A7" />
          <TextAndAmount>{t('Crane work')}</TextAndAmount>
        </ColorContainer>
        <ColorContainer>
          <ColorSquare color="#FFAB91" />
          <TextAndAmount>{t('Reservation')}</TextAndAmount>
        </ColorContainer>
      </Column>
      <CalendarButtons>
        <ButtonVersion cancel onClick={setToday}>
          {t('Today')}
        </ButtonVersion>
        <div id="calendar-type-select">
          <Select value={timelineUnit} style={{ width: '90px', marginLeft: '6px' }} onChange={handleChange}>
            <Option value="day">{t('Day')}</Option>
            <Option value="week">{t('Week')}</Option>
            <Option value="month">{t('Month')}</Option>
          </Select>
        </div>
      </CalendarButtons>
      <HeaderContainer
        data-testid="sidebarHeader"
        {...getRootProps()}
        weatherOn={modules.weather_module === 'enabled'}
        bptManaged={user.permissions.includes('manage_bpt')}
      >
        <IconContainer onClick={goBackwards} style={{ marginLeft: 0 }}>
          <Icon type="chevron-left" />
        </IconContainer>
        <IconContainer onClick={goForwards}>
          <Icon type="chevron-right" />
        </IconContainer>
        <MonthContainer onClick={toggleCalendar}>
          <Text>{dayjs(visibleTimeStart).format('MMM YYYY')}</Text>
          <IconContainer2 id="timeline-calendar-chevron-down">
            <Icon type="chevron-down" />
          </IconContainer2>
        </MonthContainer>
        {calendarOpen && (
          <div>
            <Blocker onClick={closeCalendar} />
            <DatePickerContainer>
              <DatePicker
                format="DD.MM.YYYY"
                style={{
                  width: '288px',
                }}
                defaultValue={visibleTimeStart ? dayjs(visibleTimeStart) : null}
                onChange={onDateChange}
                maxDate={dayjs('2031-01-01T00:00:00')}
                minDate={dayjs('2021-01-01T00:00:00')}
                open={true}
                allowClear={false}
              />
            </DatePickerContainer>
          </div>
        )}
      </HeaderContainer>
    </Container>
  );
};

export default SidebarHeader;
