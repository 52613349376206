import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { UserContext } from '../../context/UserContext';

import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';
import dayjs from 'dayjs';
import useApi from '../../hooks/useApi';
import { TIME_FORMAT_DAY } from '../../utils/constants';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const ModalInner = styled.div`
  min-height: 230px;
  padding: 12px 12px 12px 12px;
  display: flex;
  position: relative;

  textarea {
    resize: none;
    height: 94px;
  }
`;

const Column1 = styled.div`
  width: calc(100% - 260px);
`;

const Column2 = styled.div`
  width: 260px;
  padding: 0px 24px;
  border-left: 1px solid #e8e8e8;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const PageNumber = styled.div`
  margin-right: 12px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.disabled && props.theme.color.grey_light2};
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: flex;
  justify-content: center;
`;

const Reloader = styled.div`
  width: 100%;
  height: 90vh;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: rgba(249, 249, 249, 0.84);
  z-index: 100;
  top: 0;
  left: 0;
`;

const StyledButton = styled(ButtonLight)`
  color: ${props => props.disabled && '#D8D8D8'} !important;
  background-color: ${props => props.disabled && '#f5f5f5'} !important;
`;

const WasteReceiptModal = ({ closeModal, portCallData = {}, fetchListData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [isSigned, setIsSigned] = useState(false);
  const [canUnsign, setCanUnsign] = useState(false);
  const [dlText, setDlText] = useState(null);
  const [blob, setBlob] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onDownload = () => {
    const { vessel_name, atd } = portCallData;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const fileName = `${vessel_name ? `${vessel_name} ` : ''}waste receipt${
      atd ? ` ${dayjs(atd).format(TIME_FORMAT_DAY)}` : ''
    }.pdf`;

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const onSign = async () => {
    setLoading(true);
    try {
      await apiCall('post', 'waste/sign-pdf', { port_call: portCallData });
      await fetchData();

      fetchListData();
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
  };

  const onUnsign = async () => {
    setLoading(true);
    try {
      await apiCall('post', 'waste/unsign-pdf', { port_call: portCallData });
      await fetchData();

      fetchListData();
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
  };

  const createBlob = async data => {
    if (data) {
      setIsSigned(data.signed);
      setCanUnsign(data.can_unsign);
      setDlText(data.signed ? 'Download signed receipt' : 'Download draft receipt');
      setBlob(error || !data ? null : new Blob([new Uint8Array(data.data).buffer]));
    }
  };

  const { data: pdfData, error, fetchData } = useApi('post', 'waste/pdf', { port_call: portCallData }, createBlob);

  return (
    <Modal title={t('Waste announcement receipt')} open={true} width={900} footer={null} onCancel={closeModal}>
      <ModalInner>
        <Column1>
          <Buttons>
            <PageNumber>{t('Page {{pageNumber}} of {{numPages}}', { pageNumber, numPages })}</PageNumber>
            {numPages > 1 && (
              <IconContainer onClick={previousPage} disabled={pageNumber <= 1} style={{ marginLeft: 0 }}>
                <Icon type="chevron-left" />
              </IconContainer>
            )}
            {numPages > 1 && (
              <IconContainer onClick={nextPage} disabled={pageNumber >= numPages}>
                <Icon type="chevron-right" />
              </IconContainer>
            )}
          </Buttons>
          {blob ? (
            <Document loading={null} file={blob} onLoadSuccess={onDocumentLoadSuccess}  options={options}>
              <Page loading={null} className="pdf-page" pageNumber={pageNumber} width={600} />
            </Document>
          ) : (
            <Loader>
              <Spin spinning={true} size={'large'} />
            </Loader>
          )}
          {loading && blob && (
            <Reloader>
              <Spin size="large" />
            </Reloader>
          )}
        </Column1>
        <Column2>
          <ButtonLight style={{ width: '100%', marginTop: '12px' }} onClick={onDownload}>
            {t(dlText)}
          </ButtonLight>
          {!!pdfData && !isSigned && (
            <ButtonLight style={{ width: '100%', marginTop: '12px' }} onClick={onSign}>
              {t('Sign')}
            </ButtonLight>
          )}
          {!!pdfData && isSigned && (
            <StyledButton style={{ width: '100%', marginTop: '12px' }} onClick={onUnsign} disabled={!canUnsign}>
              {t('Unsign')}
            </StyledButton>
          )}
        </Column2>
      </ModalInner>
    </Modal>
  );
};
export default WasteReceiptModal;
