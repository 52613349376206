import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import App from 'antd/es/app';

import { TIME_FORMAT } from '../../utils/constants';
import { UserContext } from '../../context/UserContext';
import List from '../../components/ui/List';
import DateComponent from '../ui/DateComponent';

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const FormsCodeSentEmail = ({ data, loading, error }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  if (error) {
    message.error(error, 4);
  }

  const columns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Sent by'),
      key: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
  ];

  return (
    <>
      <List
        rowKey="id"
        columns={columns}
        dataSource={data}
        apiCallPending={loading}
        spinning={loading}
        hideSearch={true}
      />
    </>
  );
};

export default FormsCodeSentEmail;
