import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';

const ModalInner = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const SelectContainer = styled.div`
  padding: 12px;
`;

const Label = styled.div`
  margin-bottom: 1px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const EditVesselPortcallToleranceModal = ({ vessel, closeModal, closeModalAndUpdate }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [modalState, setModalState] = useState({
    imo: vessel.imo,
    start_tolerance: vessel.start_tolerance,
    end_tolerance: vessel.end_tolerance,
  });

  const handleOk = async () => {
    const request = await apiCall('put', 'vessel-port-call-tolerances', modalState);

    if (request && request.status === 200) {
      closeModalAndUpdate();
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleStartToleranceSelect = value => {
    setModalState({ ...modalState, start_tolerance: value });
  };

  const handleEndToleranceSelect = value => {
    setModalState({ ...modalState, end_tolerance: value });
  };

  const getHourOptions = () => {
    let list = [];

    for (let i = 0; i < 49; i++) {
      list.push({ value: 'PT' + i + (i === 0 ? 'S' : 'H'), label: i + 'h' });
    }

    return list;
  };

  const isOkDisabled = () => {
    if (!modalState.imo || !modalState.start_tolerance || !modalState.end_tolerance) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      title={t('Edit tolerances for ' + vessel.vessel_name + ' (' + vessel.imo + ')')}
      open={true}
      footer={null}
      onCancel={handleCancel}
    >
      <ModalInner>
        <SelectContainer>
          <Label>{t('Start tolerance')}</Label>
          <Select
            value={modalState.start_tolerance || ''}
            style={{ width: '100%' }}
            onChange={handleStartToleranceSelect}
            options={getHourOptions()}
            placeholder={t('Tolerance')}
            allowClear={true}
            onClear={() => handleStartToleranceSelect(undefined)}
          />
        </SelectContainer>
        <SelectContainer>
          <Label>{t('End tolerance')}</Label>
          <Select
            value={modalState.end_tolerance || ''}
            style={{ width: '100%' }}
            onChange={handleEndToleranceSelect}
            options={getHourOptions()}
            placeholder={t('Tolerance')}
            allowClear={true}
            onClear={() => handleEndToleranceSelect(undefined)}
          />
        </SelectContainer>

        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={handleCancel}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight onClick={handleOk} disabled={isOkDisabled()}>
            {t('OK')}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default EditVesselPortcallToleranceModal;
