import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import { Chart, registerables } from 'chart.js';
import Icon from '../ui/Icon';
import StatisticsBarMultiColumnCluster from '../statistics/StatisticsBarMultiColumnCluster';

Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: inline-block;
  justify-content: center;
  padding: 0 12px;

  .ant-select-show-arrow {
    height: 24px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fbfbfb;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
  width: 38px;
  min-width: 38px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-left: 12px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
  margin-right: 8px;
  white-space: nowrap;
  margin-top: 1px;
`;

const EndOfFirstRow = styled.div`
  display: flex;
  margin-top: 2px;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const FilterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const FilterContainer = styled.div`
  display: inline-block;
  flex-wrap: wrap;
  margin-left: 12px;
  max-width: 608px;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const FiltersHeader = styled.div`
  display: flex;
  margin-right: 12px;
  margin-bottom: 6px;
  justify-content: flex-end;
  cursor: pointer;
  color: ${props => (props.filtered ? props.theme.color.secondary : props.theme.color.grey_dark)};

  &:hover {
    text-decoration: underline;
  }
`;

const FilterHeaderText = styled.div`
  margin-left: 6px;
`;

const IconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 6px;

  svg {
    margin-top: 4px;
  }
`;

const ClusterGraphsMini = ({ portcallId, ...props }) => {
  const buildQueryParams = () => {
    let newParams = {
      ...props.data_source.parameters,
      group_by: groupBy,
      port_call_id: portcallId,
    };

    for (let filter of Object.keys(filterValues)) {
      if (props.complex_filters.find(f => f.key === filter).type === 'multi_select') {
        if (filterValues[filter].length > 0) {
          for (let i = 0; i < filterValues[filter].length; i++) {
            if (i === 0) {
              newParams[filter] = filterValues[filter][i];
            } else {
              newParams[filter] = newParams[filter] + filterValues[filter][i];
            }

            if (i < filterValues[filter].length - 1) {
              newParams[filter] = newParams[filter] + '|';
            }
          }
        }
      } else {
        if (filterValues[filter]) {
          newParams[filter] = filterValues[filter];
        }
      }
    }
    return newParams;
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const groupByOptions = props.group_by;

  const [groupBy, setGroupBy] = useState(groupByOptions[0].key);

  const [filterValues, setFilterValues] = useState(
    props.complex_filters.reduce((o, item) => ({ ...o, [item.key]: item.type === 'multi_select' ? [] : undefined }), {})
  );
  const [startLoader, setStartLoader] = useState(true);
  const [neededParametersAdded, setNeededParametersAdded] = useState(false);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const { loading: loadingFilters, data: dataFilters, error: errorFilters } = useApi(
    'get',
    props.filter_values_source.url,
    props.filter_values_source.parameters
  );

  const { loading, data, error, fetchData } = useApi(
    'get',
    props.data_source.url,
    buildQueryParams(),
    undefined,
    props.complex_filters.findIndex(f => !f.optional) === -1
  );

  const dataObjectFilters = useMemo(() => (errorFilters || !dataFilters ? {} : dataFilters.data), [
    dataFilters,
    errorFilters,
  ]);
  const dataObject = error || !data ? {} : data.data;

  useEffect(() => {
    if (!neededParametersAdded) {
      if (props.complex_filters.findIndex(f => !f.optional) > -1) {
        if (Object.keys(dataObjectFilters).length > 0) {
          let neededFilters = props.complex_filters.filter(f => !f.optional);
          let newFilters = { ...filterValues };
          for (let filter of neededFilters) {
            if (filter.default_value && dataObjectFilters[filter.key].findIndex(f => f === filter.default_value) > -1) {
              newFilters[filter.key] = filter.default_value;
            } else {
              newFilters[filter.key] = dataObjectFilters[filter.key][0];
            }
          }
          setFilterValues(newFilters);
          setNeededParametersAdded(true);
        }
      } else {
        setNeededParametersAdded(true);
      }
    }
  }, [dataObjectFilters, filterValues, neededParametersAdded, props.complex_filters]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let neededFilters = props.complex_filters.filter(f => !f.optional);
    if (neededFilters.length > 0) {
      let allowed = true;

      if (allowed) {
        for (let filter of neededFilters) {
          if (!filterValues[filter.key]) {
            allowed = false;
            break;
          }
        }
      }

      if (allowed) {
        fetchData(false, buildQueryParams());
      }
    } else {
      fetchData(false, buildQueryParams());
    }
  }, [
    filterValues,
    groupBy,
    fetchData,
    props.complex_filters,
    props.comparison?.allowed,
    props.comparison?.default_value,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const optionalFilters = props.complex_filters ? props.complex_filters.filter(f => f.optional) : [];
  const mandatoryFilters = props.complex_filters ? props.complex_filters.filter(f => !f.optional) : [];

  const somethingFiltered =
    optionalFilters.findIndex(f => {
      const stateValue = filterValues[f.key];
      if (Array.isArray(stateValue)) {
        return stateValue.length > 0;
      } else {
        return !!stateValue;
      }
    }) > -1;

  return (
    <Container>
      <HeaderRow>
        <Header></Header>
        <EndOfFirstRow>
          {(loading || loadingFilters) && !startLoader ? (
            <MiniLoader>
              <Spin size="small" />
            </MiniLoader>
          ) : (
            <MiniLoader />
          )}
          <Select
            value={groupBy}
            style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
            onChange={e => {
              setStartLoader(false);
              setGroupBy(e);
            }}
            size="small"
            options={groupByOptions.map(o => {
              return {
                value: o.key,
                label: o.label,
              };
            })}
          />
          {mandatoryFilters.map((filter, i) => (
            <Select
              key={i}
              mode={filter.type === 'multi_select' ? 'multiple' : 'default'}
              maxTagCount={1}
              maxTagTextLength={6}
              maxTagPlaceholder="+"
              value={filterValues[filter.key]}
              style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
              onChange={e => {
                setStartLoader(false);
                setFilterValues(filters => {
                  const newFilters = { ...filters };
                  newFilters[filter.key] = e;

                  return newFilters;
                });
              }}
              placeholder={filter.label}
              size="small"
              options={
                dataObjectFilters[filter.key]
                  ? dataObjectFilters[filter.key].map(f => {
                    return {
                      value: f,
                      label: f,
                    };
                  })
                  : []
              }
            />
          ))}
        </EndOfFirstRow>
      </HeaderRow>

      {optionalFilters.length > 0 && (
        <FilterRow>
          <FilterContainer>
            <FiltersHeader onClick={() => setFiltersOpen(!filtersOpen)} filtered={somethingFiltered}>
              {!filtersOpen ? (
                <IconContainer>
                  <Icon type="chevron-down" />
                </IconContainer>
              ) : (
                <IconContainer>
                  <Icon type="chevron-up" />
                </IconContainer>
              )}
              <FilterHeaderText>{t('Filters')}</FilterHeaderText>
            </FiltersHeader>
            {filtersOpen && (
              <Filters>
                {optionalFilters.map((filter, i) => (
                  <Select
                    key={i}
                    mode={filter.type === 'multi_select' ? 'multiple' : 'default'}
                    maxTagCount={1}
                    maxTagTextLength={6}
                    maxTagPlaceholder="+"
                    value={filterValues[filter.key]}
                    style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
                    onChange={e => {
                      setStartLoader(false);
                      setFilterValues(filters => {
                        const newFilters = { ...filters };
                        newFilters[filter.key] = e;

                        return newFilters;
                      });
                    }}
                    placeholder={filter.label}
                    size="small"
                    options={
                      dataObjectFilters[filter.key]
                        ? dataObjectFilters[filter.key].map(f => {
                          return {
                            value: f,
                            label: f,
                          };
                        })
                        : []
                    }
                  />
                ))}
              </Filters>
            )}
          </FilterContainer>
        </FilterRow>
      )}

      {startLoader && (loading || loadingFilters) ? (
        <Loader>
          <Spin size="medium" />
        </Loader>
      ) : (
        <StatisticsBarMultiColumnCluster
          column1={false}
          oneColumn={false}
          dataObject={dataObject}
          universalTimePickerOn={false}
          {...props}
        />
      )}
    </Container>
  );
};
export default ClusterGraphsMini;
