import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';
import HolidayList from '../components/berthPlanningTool/cranePlanning/holidays/HolidayList';

const Holidays = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Holidays | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Holidays')}>
      <PageWithoutHeaderAndMargins fullWidth>
        <HolidayList />
      </PageWithoutHeaderAndMargins>
    </Layout>
  );
};

export default Holidays;
