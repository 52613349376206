import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const TimeIndicatorLineText = styled.div`
  background-color: ${({ theme }) => theme.color.beige2};
  width: 36px;
  height: 12px;
  font-size: 10px;
  top: 44px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  line-height: 12px;
  cursor: default;
`;

const TimeIndicatorText = ({
  start,
  indicatorLine,
  timelineUnit,
  width,
  fleet = false,
  visibleTimeStart,
  visibleTimeEnd,
}) => {
  const { modules } = useContext(UserContext);

  const getTextStyle = timeLeft => {
    return {
      style: {
        left: timeLeft - 18,
        top: `${modules.weather_module === 'enabled' && !fleet && '109px'}`,
      },
    };
  };

  const onClick = () => {
    console.log('Clicking time indicator!');
  };

  const diffMinutes = indicatorLine.time.diff(start, 'minutes');

  let minutes = 0;

  switch (timelineUnit) {
    case 'day':
      minutes = 24 * 60;
      break;

    case 'twodays':
      minutes = 2 * 24 * 60;
      break;

    case 'week':
      minutes = 7 * 24 * 60;
      break;

    case 'month':
      minutes = 28 * 24 * 60;
      break;

    case 'custom':
      minutes = dayjs.duration(dayjs(visibleTimeEnd).diff(dayjs(visibleTimeStart))).asMinutes();
      break;

    default:
      break;
  }

  let percentage = diffMinutes / minutes;

  const timeLeft = width * percentage;
  const time = indicatorLine.time.format('HH:mm');

  return (
    <TimeIndicatorLineText data-testid={'time-indicator-text'} {...getTextStyle(timeLeft)} onClick={onClick}>
      {time}
    </TimeIndicatorLineText>
  );
};

export default TimeIndicatorText;
