import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../../../context/UserContext';

import Popover from 'antd/es/popover';
import { FleetViewContext } from '../../../../context/FleetViewContext';
import Icon from '../../../ui/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light2};
  margin-bottom: 1px;
`;

const Header = styled.div`
  width: ${props => props.sidebarWidth}px;
  text-align: right;
  padding-right: 8px;
  padding-bottom: 4px;
`;

const HeaderText = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  display: inline-flex;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px 6px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const List = styled.div`
  background-color: white;
  padding: 3px 0;
  border-radius: 4px;
  width: 160px;
`;

const Option = styled.div`
  padding: 6px 12px;
  cursor: pointer;
  color: #4a4a4a;
  font-weight: ${props => (props.chosen ? 700 : 400)};
  background-color: ${props => (props.chosen ? '#f0fbff' : 'white')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: ${props => (props.chosen ? '#f0fbff' : '#f2f2f2')};
  }
`;

const IconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 6px;

  i {
    margin-top: 2px;
  }
`;

const fleetSidebarLocalStorage = 'fleet_sidebar_selection';

const SidebarHeader = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sidebarSelection, setSidebarSelection, sidebarWidth, setSidebarWidth } = useContext(FleetViewContext);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#sidebar-header-list');

      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (dropdown ? !dropdown.contains(event.target) : true) &&
        (event.target.outerHTML.includes('sidebar-header-container')
          ? true
          : !event.target.outerHTML.includes('sidebar-header-text'))
      ) {
        setPopoverOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const options = [
    { label: t('Port info'), value: 'port', width: 170 },
    { label: t('Port and berths'), value: 'both', width: 220 },
    { label: t('Berths'), value: 'berths', width: 170 },
  ];

  useEffect(() => {
    const value = localStorage.getItem(fleetSidebarLocalStorage);
    if (value) {
      let option = options.find(o => o.value === value);
      if (option) {
        setSidebarSelection(option.value);
        setSidebarWidth(option.width);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentChoise = options.find(o => o.value === sidebarSelection);

  return (
    <Container>
      <Popover
        content={
          <List id="sidebar-header-list" ref={ref}>
            {options.map(o => (
              <Option
                key={o.label}
                onClick={() => {
                  localStorage.setItem(fleetSidebarLocalStorage, o.value);
                  setSidebarSelection(o.value);
                  setSidebarWidth(o.width);
                  setPopoverOpen(false);
                }}
                chosen={sidebarSelection ? o.value === sidebarSelection : false}
              >
                {t(o.label)}
              </Option>
            ))}
          </List>
        }
        trigger="click"
        placement="bottom"
        overlayClassName="fleet-sidebar-header-class"
        open={popoverOpen}
      >
        <Header sidebarWidth={sidebarWidth} id="sidebar-header-container">
          <HeaderText id="sidebar-header-text" onClick={() => setPopoverOpen(!popoverOpen)} p>
            {t(currentChoise?.label) || '-'}
            {!popoverOpen ? (
              <IconContainer>
                <Icon type="chevron-down" />
              </IconContainer>
            ) : (
              <IconContainer>
                <Icon type="chevron-up" />
              </IconContainer>
            )}
          </HeaderText>
        </Header>
      </Popover>
    </Container>
  );
};

export default SidebarHeader;
