import React from 'react';
import VesselTimestampFleetModalCard from '../fleetView/modals/VesselTimestampFleetModalCard';
import VesselTimestampModalCard from './VesselTimestampModalCard';

const VesselTooltip = ({ feature, fleetData, fleetAisData, timestampData }) => {
  return fleetData ? (
    <VesselTimestampFleetModalCard
      data={fleetData.data}
      imo={fleetData.imo}
      master_id={fleetData.master_id}
      notifications={undefined}
      decisions={undefined}
      transitions={undefined}
      privateData={undefined}
      ownData={true}
      map={true}
      fleetAisData={fleetAisData}
    />
  ) : timestampData ? (
    <VesselTimestampModalCard data={timestampData.ship} />
  ) : (
    <div style={{ padding: '6px' }}>
      <h4>{feature.properties.name}</h4>
    </div>
  );
};

export default VesselTooltip;
