import React from 'react';
import styled from 'styled-components';

const SmallContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: #4990dd;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorTestingSmall = ({ state }) => {
  return <SmallContainer>{state.error}</SmallContainer>;
};

export default ErrorTestingSmall;
