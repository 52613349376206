import React, { useContext, useState, useEffect } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Icon from '../../components/ui/Icon';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const Title = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.color.success};
  i {
    width: 24px;
    height: 24px;
  }
  svg {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const AdminPortCallTimestamps = ({ port_call_id }) => {
  const { namespace, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const defaultParams = {
    limit: 50,
    offset: 0,
    sort: 'id',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'port-call-timestamps', { ...newParams, port_call_id });

  useEffect(() => {
    fetchData(false, { ...newParams, port_call_id });
  }, [newParams, fetchData, port_call_id]);

  const portCalls = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  let counter = 1;
  portCalls.forEach(p => {
    p._row = start + counter;
    counter++;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'timestamp_id',
    },
    {
      title: t('Port call ID'),
      dataIndex: 'port_call_id',
      key: 'port_call_id',
    },
    {
      title: t('Type'),
      dataIndex: 'time_type',
      key: 'time_type',
    },
    {
      title: t('State'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Trash'),
      dataIndex: 'is_trash',
      key: 'is_trash',
    },
    {
      title: t('Created at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Created by'),
      dataIndex: 'created_by',
      key: 'created_by',
    },
  ];

  return (
    <List
      rowKey="id"
      columns={columns}
      dataSource={portCalls}
      spinning={loading}
      setParams={setNewParams}
      newParams={newParams}
      start={start}
      total={total}
      hideSearch={true}
      searchPlaceHolder={t('Search by name or email')}
      title={() => (
        <Title>
          <Icon type="check" />
          {t('Timestamp in this port call')}
        </Title>
      )}
    />
  );
};

export default AdminPortCallTimestamps;
