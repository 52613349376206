import React, { useContext } from 'react';

import { UserContext } from '../../../context/UserContext';

import styled from 'styled-components';

import Icon from '../../ui/Icon';
import Label from '../../ui/Label';
import ButtonLight from '../../ui/ButtonLight';
import { darken } from 'polished';

const DecisionLabel = styled(Label)`
  text-align: left;
  text-transform: none;
  margin: 0 0 0 0;
  i {
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    margin-bottom: 2px;
  }
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
`;

const Decision = styled.span`
  align-items: center;
  flex: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  max-width: 500px;
`;

const ButtonContainer = styled.span`
  text-align: center;
`;

const DecisionEntry = styled.span`
  padding-right: ${({ theme }) => theme.sizing.gap_medium};
  text-align: center;
  flex: auto;
  display: inline-flex;
  justify-content: space-between;
`;

const DecisionButton = styled(ButtonLight)`
  margin: ${({ theme }) => theme.sizing.gap_tiny};
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 13px;
  font-weight: 700;
  height: 24px;

  color: ${props => props.blue && props.theme.color.secondary};

  &:hover {
    color: ${props => props.blue && darken(0.15, props.theme.color.secondary)};
    color: ${props => props.warning && darken(0.15, props.theme.color.warning)};
    color: ${props => props.cancel && darken(0.15, props.theme.color.grey_dark)};

    svg {
      fill: ${props => props.blue && darken(0.15, props.theme.color.secondary)};
      fill: ${props => props.warning && darken(0.15, props.theme.color.warning)};
      fill: ${props => props.cancel && darken(0.15, props.theme.color.grey_dark)};
    }
  }
`;

const ResponseIcon = ({ responseType }) => {
  if (responseType) {
    if (responseType === 'positive') {
      return <Icon type="check-circle" color="#007AFF" fill="none" />;
    } else if (responseType === 'negative') {
      return <Icon type="x-circle" color="#D0011C" fill="none" />;
    } else if (responseType === 'neutral') {
      return <Icon type="circle" color="#407505" fill="none" />;
    }
  }
  return <Icon type="help-circle" color="#4A4A4A" fill="none" />;
};

const NotificationMessageDecisionItem = ({ data, messageSent }) => {
  const { apiCall, user } = useContext(UserContext);
  const { id, label, response_name, response_options, response_type } = data;
  const responseNames = Object.keys(response_options);

  const handleButton = async (e, id, responseName) => {
    e.preventDefault();
    await apiCall('post', 'decision-item-response', { id: id, response: responseName });
    messageSent();
  };

  return (
    <Decision>
      <DecisionEntry>
        <DecisionLabel>
          <ResponseIcon responseType={response_type} />
          {label}
        </DecisionLabel>
      </DecisionEntry>

      <ButtonContainer>
        {user.permissions.includes('send_push_notification') && response_name && (
          <DecisionButton small cancel onClick={e => handleButton(e, id, '')}>
            <Icon type="close" />
            Cancel
          </DecisionButton>
        )}

        {responseNames.map(value => {
          return (
            <span key={value}>
              {user.permissions.includes('send_push_notification') &&
                !response_name &&
                response_options[value].type === 'positive' && (
                <DecisionButton small blue onClick={e => handleButton(e, id, value)}>
                  <Icon type="check-light" />
                  {value}
                </DecisionButton>
              )}
              {user.permissions.includes('send_push_notification') &&
                !response_name &&
                response_options[value].type === 'negative' && (
                <DecisionButton small warning onClick={e => handleButton(e, id, value)}>
                  <Icon type="close" />
                  {value}
                </DecisionButton>
              )}
            </span>
          );
        })}
      </ButtonContainer>
    </Decision>
  );
};

export default NotificationMessageDecisionItem;
