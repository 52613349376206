import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';

import dayjs from 'dayjs';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const ExternalField = ({ type, index, field, value, handleUpdate, placeHolder, options }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  switch (type) {
    case 'select':
      return (
        <Select
          allowClear
          //placeholder={placeHolder}
          optionFilterProp="label"
          onChange={value => handleUpdate(value, index, field)}
          style={{ width: '100%', minWidth: '160px' }}
          options={options?.map(option => {
            return {
              label: option.description ? `${t(option.description)} (${t(option.name)})` : t(option.name),
              value: option.value,
            };
          })}
          value={value}
          defaultValue={options?.find(option => option.default)?.value || undefined}
        />
      );
    case 'string':
      return (
        <Input
          style={{ width: '100%' }}
          placeholder={placeHolder}
          value={value}
          onChange={e => handleUpdate(e?.target?.value, index, field)}
        />
      );
    case 'float':
      return (
        <StyledInputNumber
          style={{
            width: '100px',
            maxWidth: '100px',
          }}
          placeholder={placeHolder}
          value={value}
          decimalSeparator={','}
          min={0}
          onChange={value => handleUpdate(value, index, field)}
        />
      );
    case 'time':
      return (
        <DatePicker
          format="DD.MM.YYYY HH:mm"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          style={{ width: '100%' }}
          value={value ? dayjs(value) : null}
          onChange={value =>
            handleUpdate(
              value
                ?.utc()
                .second(0)
                .format() || null,
              index,
              field
            )
          }
          placeholder={placeHolder}
        />
      );
    default:
      return null;
  }
};

export default ExternalField;
