import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { WikiContext } from '../../context/WikiContext';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import LinkToThisHeader from './LinkToThisHeader';
import EditShortText from './EditShortText';
import Popover from 'antd/es/popover';
import WikiListSelect from './WikiListSelect';
import MarkDownEditor from './MarkDownEditor';

const EditRow = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  position: absolute;
  right: 18px;
  z-index: 10;
`;

const StyledButton = styled.div`
  box-shadow: none;
  height: 22px;
  width: 30px !important;
  border: 1px solid #c8c8c8;
  background-color: white !important;
  overflow: hidden;
  border-radius: 3px;
  margin-right: 30px;
  margin-top: 6px;

  &:hover {
    border-color: ${props => props.theme.color.secondary};

    i {
      color: ${props => props.theme.color.secondary} !important;
    }
  }
`;

const MoreIcon = styled.span`
  cursor: pointer;
  height: 16px;

  i {
    margin-top: -3px;
    margin-left: 3px;
  }

  svg {
    margin-top: -12px;
  }
`;

const EditIconContainer = styled.div`
  i {
    margin-right: 10px;
    margin-top: -2px;
  }

  svg {
    margin-top: -3px;
    height: 16px;
    widht: 16px;
  }
`;

const IconContainer = styled.div`
  i {
    margin-right: 10px;
    margin-top: -2px;
  }

  svg {
    margin-top: -2px;
    height: 18px;
    width: 18px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${props => (props.sideOfPage ? '0 0 0 4px' : '0 12px 12px 12px')};
  max-height: ${props => (props.sideOfPage ? 'calc(100% - 32px)' : 'calc(100vh - 314px)')};
  overflow-y: auto;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const AreYouSureBg = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(232, 232, 232, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AreYouSure = styled.div`
  padding: 24px;
  background: white;
  border-radius: 4px;
`;

const AreYouSureText = styled.div`
  margin-right: 4px;
`;

const AreYouSureButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  button {
    margin-left: 12px;
    margin-bottom: 0;
  }
`;

const Link = styled.div`
  font-size: 13px;
  padding: 6px 12px;
  cursor: pointer;
  color: #4a4a4a;
  font-weight: ${props => (props.current ? 700 : 400)};
  background-color: ${props => (props.current ? '#f0fbff' : 'white')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;

  &:hover {
    background-color: ${props => (props.chosen ? '#f0fbff' : '#f2f2f2')};
  }
`;

const HelpModalInner = ({
  objectType,
  objectId,
  open,
  setOpen,
  coordinates,
  usableMarkdownId,
  onlyFullMatch = false,
  refetchWikiData,
  backUpId,
  setTitle,
  startLoader,
  setStartLoader,
  closeConfirmation,
  setEditModeOn,
  editModeOn,
  setCloseConfirmation,
  sideOfPage = false,
}) => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { availableWikiLinks, availableWikis } = useContext(WikiContext);

  const [anchorTitle, setAnchorTitle] = useState(null);
  const [editTitle, setEditTitle] = useState(null);

  const [shouldRefetchData, setShouldRefetchData] = useState(false);

  const [wikiListOpen, setWikiListOpen] = useState(false);
  const [addNewOpen, setAddNewOpen] = useState(false);

  let anchorAvailable = true;
  let wikiLink = null;

  if (!usableMarkdownId) {
    // full link
    wikiLink = availableWikiLinks.find(
      w =>
        w.object_type === objectType &&
        (objectId ? w.object_id === objectId : w.lat === coordinates.lat && w.lon === coordinates.lng)
    );

    if (!wikiLink && !onlyFullMatch) {
      wikiLink = availableWikiLinks.find(w => w.object_type === objectType);
      anchorAvailable = false;
    }
  } else {
    anchorAvailable = false;
  }

  const ActionsInPopover = () => {
    return (
      <div>
        <Link
          onClick={() => {
            setEditModeOn(true);
          }}
        >
          <EditIconContainer>
            <Icon type="edit" />
          </EditIconContainer>
          {t('Edit current page')}
        </Link>
        {!usableMarkdownId && (
          <Link
            onClick={() => {
              setWikiListOpen(true);
            }}
          >
            <IconContainer>
              <Icon type="swap-without-border" />
            </IconContainer>
            {t('Link different page')}
          </Link>
        )}
        {!usableMarkdownId && (
          <Link
            onClick={() => {
              setAddNewOpen(true);
            }}
          >
            <IconContainer>
              <Icon type="plus" />
            </IconContainer>
            {t('Add new page')}
          </Link>
        )}
      </div>
    );
  };

  const removePreviousLink = async () => {
    if (wikiLink?.markdown_id) {
      await apiCall('delete', 'wiki/unlink', {
        markdown_id: wikiLink.markdown_id,
        object_type: objectType,
        object_id: objectId,
      });
    }
  };

  const selectMarkdown = async data => {
    if (wikiLink?.markdown_id) {
      await apiCall('delete', 'wiki/unlink', {
        markdown_id: wikiLink.markdown_id,
        object_type: objectType,
        object_id: objectId,
      });
    }

    await apiCall('post', 'wiki/link', {
      markdown_id: data,
      object_type: objectType,
      object_id: objectId,
      markdown_anchor: undefined,
      lat: coordinates?.lat,
      lon: coordinates?.lng,
      short_text: '',
    });
    setShouldRefetchData(true);
  };

  return open ? (
    <>
      {!startLoader &&
        !editModeOn &&
        !addNewOpen &&
        user.permissions.includes('manage_wiki') &&
        (wikiLink || usableMarkdownId) && (
        <EditRow>
          <Popover
            content={ActionsInPopover()}
            placement="leftTop"
            trigger="click"
            overlayClassName="wiki-actions-class"
            open={wikiListOpen || addNewOpen ? false : undefined}
          >
            <StyledButton type="text">
              <MoreIcon>
                <Icon type="ellipsis" style={{ fontSize: '22px', color: '#999999' }} />
              </MoreIcon>
            </StyledButton>
          </Popover>
        </EditRow>
      )}
      {anchorTitle && (
        <LinkToThisHeader
          anchorTitle={anchorTitle}
          refetchData={() => setShouldRefetchData(true)}
          setAnchorTitle={setAnchorTitle}
        />
      )}
      {editTitle && (
        <EditShortText
          editTitle={editTitle}
          refetchData={() => setShouldRefetchData(true)}
          setEditTitle={setEditTitle}
        />
      )}
      {wikiListOpen && (
        <WikiListSelect
          availableWikis={availableWikis}
          selectMarkdown={selectMarkdown}
          setWikiListOpen={setWikiListOpen}
          sideOfPage={sideOfPage}
        />
      )}
      <Container sideOfPage={sideOfPage}>
        <MarkDownEditor
          markdownId={usableMarkdownId ? usableMarkdownId : wikiLink ? wikiLink.markdown_id : null}
          markdownAnchor={wikiLink && anchorAvailable ? wikiLink.markdown_anchor : null}
          newWiki={!usableMarkdownId && !wikiLink}
          setEditModeOn={setEditModeOn}
          editModeOn={editModeOn}
          startLoader={startLoader}
          setStartLoader={setStartLoader}
          objectType={objectType}
          objectId={objectId}
          backUpId={backUpId}
          coordinates={coordinates}
          shortText={wikiLink && anchorAvailable ? wikiLink.short_text : null}
          setModalTitle={setTitle}
          setAnchorTitle={setAnchorTitle}
          setEditTitle={setEditTitle}
          shouldRefetchData={shouldRefetchData}
          setShouldRefetchData={setShouldRefetchData}
          addNewOpen={addNewOpen}
          setAddNewOpen={setAddNewOpen}
          removePreviousLink={removePreviousLink}
          refetchWikiData={refetchWikiData}
        />
        {closeConfirmation && (
          <AreYouSureBg>
            <AreYouSure>
              <AreYouSureText>{t('Are you sure you want to close this modal while editing?')}</AreYouSureText>
              <AreYouSureButtons>
                <ButtonLight type="button" onClick={() => setCloseConfirmation(false)}>
                  {t('Go back')}
                </ButtonLight>
                <ButtonLight
                  type="button"
                  cancel
                  onClick={() => {
                    setCloseConfirmation(false);
                    setOpen(false);
                    setStartLoader(true);
                    setEditModeOn(false);
                  }}
                >
                  {t('Close modal')}
                </ButtonLight>
              </AreYouSureButtons>
            </AreYouSure>
          </AreYouSureBg>
        )}
      </Container>
    </>
  ) : null;
};

export default HelpModalInner;
