import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TimestampContext } from '../../context/TimestampContext';

import { UserContext } from '../../context/UserContext';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../ui/Icon';
import SpeedModalInMap from '../map/SpeedModalInMap';

const dateWidth = '120px';

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 18px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

const ListContainer = styled.div`
  @media (max-width: 699px) {
    display: none;
  }
`;

const MobileListContainer = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const DateHeader = styled(Header)`
  width: ${dateWidth};

  > div {
    background-color: #353835;
    color: white;
    padding: 4px 0;
    padding-left: 18px;
    margin-right: 9px;
  }

  @media (min-width: 700px) and (max-width: 850px) {
    display: none;
  }
`;

const ArrivalHeader = styled(Header)`
  width: calc((100% - ${dateWidth}) / 2);
  text-align: center;
  color: white;

  > div {
    background-color: #163b75;
    color: white;
    padding: 4px 0;
    padding-left: 18px;
    margin: 0 9px;
  }

  @media (min-width: 700px) and (max-width: 850px) {
    width: 50%;

    > div {
      margin-left: 0px;
    }
  }

  @media (max-width: 699px) {
    width: 100%;

    > div {
      margin: 0px;
    }
  }
`;

const DepartureHeader = styled(Header)`
  width: calc((100% - ${dateWidth}) / 2);
  text-align: center;
  color: white;

  > div {
    background-color: #12540e;
    color: white;
    padding: 4px 0;
    padding-left: 18px;
    margin-left: 9px;
  }

  @media (min-width: 700px) and (max-width: 850px) {
    width: 50%;
  }

  @media (max-width: 699px) {
    width: 100%;

    > div {
      margin-left: 0px;
    }
  }
`;

const DayRow = styled.div`
  display: flex;
  background-color: ${props => (props.odd ? '#f4f4f4' : 'white')};
`;

const DatePart = styled.div`
  width: ${dateWidth};
  padding: 0 18px;
  margin: 6px 0;

  @media (min-width: 700px) and (max-width: 850px) {
    display: none;
  }
`;

const ArrivalPart = styled.div`
  width: calc((100% - ${dateWidth}) / 2);
  margin: 6px 0;
  padding: 0 18px;

  @media (min-width: 700px) and (max-width: 850px) {
    width: 50%;
  }

  @media (max-width: 699px) {
    width: 100%;
  }
`;

const DeparturePart = styled.div`
  width: calc((100% - ${dateWidth}) / 2);
  margin: 6px 0;
  padding: 0 18px;

  @media (min-width: 700px) and (max-width: 850px) {
    width: 50%;
  }

  @media (max-width: 699px) {
    width: 100%;
  }
`;

const TimestampContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  padding-right: 12px;
  cursor: default;
  width: calc((100% - 145px) * 0.35);
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: ${props => props.bold && 'underline'};

  @media (max-width: 1100px) {
    width: calc(100% - 130px);
  }
`;

const TimeDate = styled.div`
  display: flex;
  width: 120px;
  text-decoration: ${props => props.bold && 'underline'};
`;

const Berth = styled.div`
  display: flex;
  width: calc((100% - 140px) * 0.65);
  margin-right: 10px;
  text-decoration: ${props => props.bold && 'underline'};

  @media (min-width: 700px) and (max-width: 1100px) {
    display: none;
    width: 0px;
  }

  @media (max-width: 500px) {
    display: none;
    width: 0px;
  }
`;

const DateContainer = styled.div`
  padding-right: 6px;
`;

const Time = styled.div`
  padding-left: 6px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const Label = styled.td`
  padding-right: 9px;
  vertical-align: top;
`;

const Value = styled.td`
  font-weight: 600;
  vertical-align: top;
  text-transform: capitalize;
`;

const Link = styled.div`
  color: #4990dd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 6px 0;
`;

const AtBerth = styled.div`
  font-size: 18px;
  line-height: 16px;
  padding-top: 5px;
  padding-right: 5px;
`;

const ActivityListComponent = () => {
  const { timestamps, loader, setSearch } = useContext(TimestampContext);
  const { namespace, modules, setShowableVesselOnMap, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [speedModalOpen, setSpeedModalOpen] = useState(false);

  const [hoverImo, setHoverImo] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setSearch('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const thisday = dayjs().format('YYYY-MM-DDTHH:mm:ss');
  const arrangedTimestamps = {};
  timestamps.forEach(ts => {
    const newTs = { ...ts };
    ts.portcalls[0].events.forEach(e =>
      e.timestamps.forEach(ts2 => {
        if (
          ts2.time_type === 'Estimated' &&
          ts2.time &&
          (ts2.state === 'Arrival_Vessel_PortArea' || ts2.state === 'Departure_Vessel_Berth')
        ) {
          const timeWithZone = dayjs(ts2.time).format('YYYY-MM-DDTHH:mm:ss');
          if (timeWithZone >= thisday) {
            if (!arrangedTimestamps[timeWithZone.substring(0, 10)]) {
              arrangedTimestamps[timeWithZone.substring(0, 10)] = { etas: [], etds: [] };
            }

            if (ts2.state === 'Arrival_Vessel_PortArea') {
              newTs.ship.foundEta = ts2.time;
              arrangedTimestamps[timeWithZone.substring(0, 10)].etas.push(newTs);
            } else if (ts2.state === 'Departure_Vessel_Berth') {
              newTs.ship.foundEtd = ts2.time;
              arrangedTimestamps[timeWithZone.substring(0, 10)].etds.push(newTs);
            }
          }
        }
      })
    );

    return newTs;
  });

  const showOnMap = (imo, vesselName) => {
    setShowableVesselOnMap({ imo, vesselName });
    history.push('/map');
  };

  const showInActivity = vesselName => {
    history.push('/?search=' + vesselName);
  };

  const TooltipForVessel = ({ ts }) => (
    <>
      <table>
        <tbody>
          <tr>
            <Label>{t('Name')}:</Label>
            <Value style={{ paddingRight: '24px' }}>{ts.ship.vessel_name}</Value>
            <Label>{t('ETA')}:</Label>
            <Value>{ts.ship.foundEta ? dayjs(ts.ship.foundEta).format(TIME_FORMAT) : '-'}</Value>
          </tr>
          <tr>
            <Label>{t('MMSI')}:</Label>
            <Value style={{ paddingRight: '24px' }}>{ts.ship.mmsi}</Value>
            <Label>{t('ETD')}:</Label>
            <Value>{ts.ship.foundEtd ? dayjs(ts.ship.foundEtd).format(TIME_FORMAT) : '-'}</Value>
          </tr>
          <tr>
            <Label>{t('IMO')}:</Label>
            <Value style={{ paddingRight: '24px' }}>{ts.ship.imo}</Value>
            <Label>{t('Berth')}:</Label>
            <Value>{ts.ship.berth_name ? ts.ship.berth_name.toLowerCase() : '-'}</Value>
          </tr>
          <tr>
            <Label>{t('Agent')}:</Label>
            <Value style={{ paddingRight: '24px' }}>{ts.ship.agent}</Value>
            <Label>{t('Bollards')}:</Label>
            <Value style={{ paddingRight: '24px' }}>
              {ts.ship.start_bollard && ts.ship.end_bollard ? ts.ship.start_bollard + ' - ' + ts.ship.end_bollard : '-'}
            </Value>
          </tr>
        </tbody>
      </table>
      {modules.map_module === 'enabled' && (
        <Link onClick={() => showOnMap(ts.ship.imo, ts.ship.vessel_name)}>
          <Icon type="map" style={{ marginRight: '.8rem' }} />
          {t('Show on map')}
        </Link>
      )}
      <Link onClick={() => showInActivity(ts.ship.vessel_name)}>
        <Icon type="port" style={{ marginRight: '.8rem' }} />
        {t('Show in Activity View')}
      </Link>
      {modules.port_call_dashboard_module === 'enabled' && user.permissions.includes('view_port_call_dashboard') && (
        <Link
          onClick={() =>
            setSpeedModalOpen({
              name: ts.ship.vessel_name,
              portcallId: ts.ship.id,
              mmsi: ts.ship.mmsi,
            })
          }
        >
          <Icon type="portcall-stats" style={{ marginRight: '.8rem' }} />
          {t('Open port call dashboard')}
        </Link>
      )}
    </>
  );

  const Timestamp = (ts, value, i, departing) => {
    const momentTime = dayjs(value);

    return (
      <Tooltip
        placement="bottom"
        color="white"
        overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
        title={<TooltipForVessel ts={ts} />}
        key={i}
      >
        <TimestampContainer onMouseEnter={() => setHoverImo(ts.ship.imo)} onMouseLeave={() => setHoverImo(null)}>
          {departing &&
            (ts.ship.status === 'at berth' ? (
              <AtBerth>*</AtBerth>
            ) : (
              <AtBerth style={{ color: 'transparent' }}>*</AtBerth>
            ))}
          <Name bold={ts.ship.imo === hoverImo}>{ts.ship.vessel_name}</Name>
          <Berth bold={ts.ship.imo === hoverImo}>
            {ts.ship.berth_name}
            {ts.ship.start_bollard && ts.ship.end_bollard
              ? ', ' + ts.ship.start_bollard + ' - ' + ts.ship.end_bollard
              : ''}
          </Berth>
          <TimeDate bold={ts.ship.imo === hoverImo}>
            <DateContainer>{momentTime.format('DD.MM.YYYY')}</DateContainer>
            <Time>{momentTime.format('HH:mm')}</Time>
          </TimeDate>
        </TimestampContainer>
      </Tooltip>
    );
  };

  const sortedTimestampKeys = Object.keys(arrangedTimestamps).sort((a, b) => {
    return a.localeCompare(b);
  });

  let etaIndex = -1;
  let etdIndex = -1;

  return (
    <Container>
      <ListContainer>
        <HeaderRow>
          <DateHeader>
            <div>{t('Dates')}</div>
          </DateHeader>
          <ArrivalHeader>
            <div>{t('Arriving')}</div>
          </ArrivalHeader>
          <DepartureHeader>
            <div>{t('Departing')}</div>
          </DepartureHeader>
        </HeaderRow>
        {loader ? (
          <Loader>
            <Spin size="large" />
          </Loader>
        ) : sortedTimestampKeys.length > 0 ? (
          sortedTimestampKeys.map((day, index) => {
            const sortedEtas = arrangedTimestamps[day].etas.sort((a, b) => {
              return a.ship.foundEta.localeCompare(b.ship.foundEta);
            });
            const sortedEtds = arrangedTimestamps[day].etds.sort((a, b) => {
              return a.ship.foundEtd.localeCompare(b.ship.foundEtd);
            });

            return (
              <DayRow key={index} odd={index % 2 === 0}>
                <DatePart>{dayjs(day).format('DD.MM.YYYY')}</DatePart>
                <ArrivalPart>{sortedEtas.map((ts, i) => Timestamp(ts, ts.ship.foundEta, i, false))}</ArrivalPart>
                <DeparturePart>{sortedEtds.map((ts, i) => Timestamp(ts, ts.ship.foundEtd, i, true))}</DeparturePart>
              </DayRow>
            );
          })
        ) : (
          <NoData>{t('No portcalls available.')}</NoData>
        )}
        {speedModalOpen && (
          <SpeedModalInMap
            vesselName={speedModalOpen.name}
            portcallId={speedModalOpen.portcallId}
            mmsi={speedModalOpen.mmsi}
            setSpeedModalOpen={setSpeedModalOpen}
          />
        )}
      </ListContainer>
      <MobileListContainer>
        <HeaderRow>
          <ArrivalHeader>
            <div>{t('Arriving')}</div>
          </ArrivalHeader>
        </HeaderRow>
        {loader ? (
          <Loader>
            <Spin size="large" />
          </Loader>
        ) : sortedTimestampKeys.length > 0 ? (
          sortedTimestampKeys.map((day, index) => {
            const sortedEtas = arrangedTimestamps[day].etas.sort((a, b) => {
              return a.ship.foundEta.localeCompare(b.ship.foundEta);
            });

            if (sortedEtas.length === 0) {
              return null;
            }

            etaIndex++;

            return (
              <DayRow key={index} odd={etaIndex % 2 === 0}>
                <ArrivalPart>{sortedEtas.map((ts, i) => Timestamp(ts, ts.ship.foundEta, i, false))}</ArrivalPart>
              </DayRow>
            );
          })
        ) : (
          <NoData>{t('No portcalls available.')}</NoData>
        )}
        <HeaderRow style={{ marginTop: '24px' }}>
          <DepartureHeader>
            <div>{t('Departing')}</div>
          </DepartureHeader>
        </HeaderRow>
        {loader ? (
          <Loader>
            <Spin size="large" />
          </Loader>
        ) : sortedTimestampKeys.length > 0 ? (
          sortedTimestampKeys.map((day, index) => {
            let sortedEtds = arrangedTimestamps[day].etds.sort((a, b) => {
              return a.ship.foundEtd.localeCompare(b.ship.foundEtd);
            });

            if (sortedEtds.length === 0) {
              return null;
            }

            etdIndex++;

            return (
              <DayRow key={index} odd={etdIndex % 2 === 0}>
                <DeparturePart>{sortedEtds.map((ts, i) => Timestamp(ts, ts.ship.foundEtd, i, true))}</DeparturePart>
              </DayRow>
            );
          })
        ) : (
          <NoData>{t('No portcalls available.')}</NoData>
        )}
      </MobileListContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px', fontWeight: 600 }}>
        <AtBerth>*</AtBerth> {t('These vessels are at berth.')}
      </div>
    </Container>
  );
};

export default ActivityListComponent;
