import React, { useEffect, useState, useRef } from 'react';
import Draggable from 'react-draggable';
import styled, { keyframes } from 'styled-components';
import Icon from '../ui/Icon';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledCardInfo = styled.div`
  position: absolute;
  z-index: 65;
  top: 30px;
  right: 8px;
  min-width: 284px;
  animation: ${props => (props.show ? fadeIn : fadeOut)} 0.125s;
  padding: 0px ${({ theme }) => theme.sizing.gap} ${({ theme }) => theme.sizing.gap} ${({ theme }) => theme.sizing.gap};
  background: ${props => props.theme.color.white};
  border-left: 1px solid ${({ theme }) => theme.color.grey_light};
  border-right: 1px solid ${({ theme }) => theme.color.grey_light};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  @supports (-ms-ime-align: auto) {
    top: 175px;
  }
`;

const ScrollContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const DragHandle = styled.div`
  height: 30px;
  width: calc(100% + 16px);
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
  cursor: move;
  display: flex;
  justify-content: flex-end;
  margin-left: -8px;
`;

const Button = styled.div`
  cursor: pointer;
  width: 15px;
  margin-top: 3px;
`;

const CardInfo = ({ show, setShow, children }) => {
  const [shouldRender, setRender] = useState(show);

  const draggableRef = useRef(null);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <Draggable handle=".activity-info-drag-handle" bounds={{ top: -30 }} nodeRef={draggableRef}>
        <StyledCardInfo show={show} onAnimationEnd={onAnimationEnd} ref={draggableRef}>
          <DragHandle className="activity-info-drag-handle">
            <Button small warning onClick={() => setShow(false)}>
              <Icon type="close" />
            </Button>
          </DragHandle>
          <ScrollContainer>{children}</ScrollContainer>
        </StyledCardInfo>
      </Draggable>
    )
  );
};

export default CardInfo;
