import Icon from './Icon';
import Input from './Input';
import Label from './Label';
import React from 'react';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  position: relative;
`;

const StyledSearch = styled(Input)`
  padding-left: ${({ theme }) => theme.sizing.gap_huge};
  min-height: 1rem;
  height: 32px;
  font-size: 13px;
`;

const StyledSubmit = styled.button`
  position: absolute;
  left: ${({ theme }) => theme.sizing.gap_small};
  top: 8px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  i {
    width: 20px;
    height: 20px;
    svg {
      fill: ${({ theme }) => theme.color.grey};
      margin-top: -6px;
    }
  }
`;

const HeaderSearch = ({ label, ...props }) => (
  <SearchWrapper>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <StyledSearch type="search" autoComplete="off" {...props} inline />
    <StyledSubmit>
      <Icon type="search" />
    </StyledSubmit>
  </SearchWrapper>
);

export default HeaderSearch;
