import React, { createContext, useEffect, useRef, useState } from 'react';

export const SideHelpContext = createContext();

export const SideHelpProvider = ({ children }) => {
  const [sideHelpOpen, setSideHelpOpen] = useState(false);
  const [sideWikiProps, setSideWikiProps] = useState(null);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const updateSideHelpOpen = e => {
    if (!e) {
      setSideWikiProps(null);
    }
    setSideHelpOpen(e);
  };

  return (
    <SideHelpContext.Provider
      value={{
        sideHelpOpen,
        setSideHelpOpen: updateSideHelpOpen,
        sideWikiProps,
        setSideWikiProps,
      }}
    >
      {children}
    </SideHelpContext.Provider>
  );
};
