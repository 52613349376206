import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// import Spin from 'antd/es/spin';

import Timeline from './calendar/Timeline';

import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';

const Calendar = styled.div`
  position: relative;
`;

// const SpinContainer = styled.div`
//   position: absolute;
//   top: -6px;
//   right: 6px;
//   z-index: 90;
// `;

const BerthPlanningToolCalendar = () => {
  const { getBerthStartData, rowHeightFromLocalStorageChecked } = useContext(BerthPlanningToolContext);

  const [berthsAvailable, setBerthsAvailable] = useState(false);

  useEffect(() => {
    if (rowHeightFromLocalStorageChecked && !berthsAvailable) {
      getBerthStartData();
      setBerthsAvailable(true);
    }
  }, [berthsAvailable, getBerthStartData, rowHeightFromLocalStorageChecked]);

  // If you want to use loader, it needs to be added to the contexts imports
  return (
    <Calendar style={{ height: 'calc(100% - 50px)' }}>
      {/* {loader > 0 && (
        <SpinContainer data-testid="buu">
          <Spin size="small" />
        </SpinContainer>
      )} */}
      <Timeline />
    </Calendar>
  );
};

export default BerthPlanningToolCalendar;
