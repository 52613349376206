import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import Tooltip from 'antd/es/tooltip';
import HelpModalWithIcon from '../info/HelpModalWithIcon';
import { GeoAssetType } from '../info/WikiConstants';

const Container = styled.div`
  max-width: 260px;
`;

const Row = styled.div`
  display: flex;
`;

const Value = styled.div`
  overflow-wrap: break-word;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;
  line-height: 17px;
  max-height: 68px;
`;

const Title = styled.div`
  margin-right: 6px;
  white-space: nowrap;
  margin-top: 1px;
`;

const AssetPopup = ({ data, propertiesOn, id }) => {
  const { namespace, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const createTitle = key => {
    let title = key.replace(/_/g, ' ');
    title = title.charAt(0).toUpperCase() + title.slice(1);

    return title;
  };

  return (
    <Container>
      {propertiesOn && <h3 style={{ marginBottom: '6px' }}>{t('Properties')}</h3>}
      {Object.keys(data).map((key, index) => {
        const title = createTitle(key);
        return (
          <Row key={index}>
            <Title key={index}>{title}:</Title>
            <Tooltip
              title={JSON.stringify(data[key])}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <Value key={index}>{JSON.stringify(data[key])}</Value>
            </Tooltip>
          </Row>
        );
      })}
      {modules?.wiki_module === 'enabled' && user.permissions.includes('view_wiki') && (
        <div style={{ marginTop: '4px', marginLeft: '-6px', display: 'flex', justifyContent: 'center' }}>
          <HelpModalWithIcon objectType={GeoAssetType} objectId={id} />
        </div>
      )}
    </Container>
  );
};

export default AssetPopup;
