import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import dayjs from 'dayjs';

import Icon from '../ui/Icon';
import Heading from '../ui/Heading';
import { UserContext } from '../../context/UserContext';
import ShipBadges from '../activity/ShipBadges';
import ShipRoute from '../activity/ShipRoute';
import { TIME_FORMAT } from '../../utils/constants';
import DateComponent from '../ui/DateComponent';

const CardWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding: 12px ${({ theme }) => theme.sizing.gap} 6px ${({ theme }) => theme.sizing.gap};
  background: white;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.style.border_radius};
  width: 264px;
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const CardInnerWrap = styled.div``;

const ShipName = styled(Heading)`
  display: flex;
  margin-bottom: 6px;
  font-size: 17px;
  line-height: 18px;
  white-space: nowrap;
`;

const Agent = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IMORow = styled.div`
  display: flex;
  flex-wrap: none;
  height: 18px;
  line-height: 18px;
`;

const ShipIMOLinkIconContainer = styled.span`
  display: none;
  margin-left: 4px;
  cursor: pointer;
`;

const ShipIMO = styled.div`
  margin-right: 8px;
  display: flex;
  &:hover {
    ${ShipIMOLinkIconContainer} {
      display: inline;
    }
  }
`;

const ShipIMOText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
`;

const NextEvent = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};
  font-size: 12px;
`;

const NextEventTitle = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
`;

const NextEventTimestamp = styled(DateComponent)``;

const Nationality = styled.sup`
  margin-left: ${({ theme }) => theme.sizing.gap_tiny};
  font-size: 12px;
  user-select: none;
  margin-top: 11px;
  top: -1.5em;
  position: relative;
  height: 10px;
`;

const BadgesContainer = styled.div`
  width: 100%;

  div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    span {
      margin-bottom: 3px;
    }
  }
`;

const Placeholder = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: 12px;
`;

const VesselName = styled.div`
  max-width: ${props =>
    'calc(224px ' + (props.nationality ? '- 18px ' : '- 0px ') + (props.decisions ? '- 18px' : '- 0px') + ')'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StaticShipIMOLinkIconContainer = styled.div`
  width: 18px;
  margin-top: -1px;
`;

const DataNotAvailable = styled.span`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.text.small};
`;

const VesselTimestampModalCard = ({ data }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    id,
    imo,
    vessel_name,
    nationality,
    from_port,
    to_port,
    next_port,
    port_area_name,
    badges,
    next_event,
    next_events,
    agent,
  } = data;

  let transitions = null;
  if (data?.berth_transitions?.length) {
    transitions = data.berth_transitions;
  }

  return (
    <CardWrapper>
      <CardInner>
        <CardInnerWrap>
          <ShipName h3>
            <Tooltip
              placement="top"
              title={vessel_name}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              <VesselName nationality={!!nationality} transitions={!!transitions} decisions={false}>
                {vessel_name}
              </VesselName>
            </Tooltip>
            <Nationality>{nationality}</Nationality>
          </ShipName>
          <IMORow>
            <ShipIMO>
              <ShipIMOText>
                IMO <span style={{ fontWeight: 400 }}>{imo}</span>
              </ShipIMOText>
              <StaticShipIMOLinkIconContainer>
                <ShipIMOLinkIconContainer>
                  {user.permissions.includes('manage_port_call') && (
                    <Icon onClick={() => window.history.push(`/admin/port-calls/${id}`)} type="external-link" fill="none" />
                  )}
                </ShipIMOLinkIconContainer>
              </StaticShipIMOLinkIconContainer>
            </ShipIMO>
            <Tooltip
              placement="top"
              title={agent}
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
            >
              <div>
                <Agent>{agent}</Agent>
              </div>
            </Tooltip>
          </IMORow>
          <BadgesContainer>
            <ShipBadges badges={badges} vessel_name={vessel_name} transitions={transitions} />
          </BadgesContainer>
          {from_port ? (
            <ShipRoute
              from={from_port ? from_port : t('Unknown port')}
              to={to_port ? to_port : t('Unknown port')}
              portArea={port_area_name}
              next={next_port ? next_port : t('Unknown port')}
              aisDestination={data.ais_data ? data.ais_data.destination : undefined}
              aisETA={data.ais_data ? data.ais_data.formattedEta : undefined}
            />
          ) : (
            <Placeholder style={{ marginBottom: '3px' }}>{t('Trip details unknown')}</Placeholder>
          )}
          {!next_events &&
            (next_event?.ts && next_event?.title ? (
              <NextEvent>
                <NextEventTitle>{t(next_event.title)}</NextEventTitle>
                <NextEventTimestamp format={TIME_FORMAT} date={next_event.ts} />
              </NextEvent>
            ) : (
              <Placeholder>{t('ETA unknown')}</Placeholder>
            ))}
          {!!next_events &&
            next_events.map((e, i) => (
              <NextEvent key={i}>
                <NextEventTitle>{t(e.title)}</NextEventTitle>
                {e.ts ? (
                  <NextEventTimestamp format={TIME_FORMAT} date={e.ts} />
                ) : (
                  <DataNotAvailable>-</DataNotAvailable>
                )}
              </NextEvent>
            ))}
          <div style={{ marginTop: '8px' }}>
            <NextEvent>
              <NextEventTitle>{t('AIS destination')}:</NextEventTitle>
              <NextEventTitle>{data.ais_data?.destination || '-'}</NextEventTitle>
            </NextEvent>
            {data.ais_data?.formattedEta ? (
              <NextEvent>
                <NextEventTitle>{t('AIS ETA')}</NextEventTitle>
                <NextEventTitle>
                  {data.ais_data?.formattedEta
                    ? dayjs(data.ais_data?.formattedEta)
                      .utc()
                      .format('DD.MM. HH:mm') + ' UTC'
                    : '-'}
                </NextEventTitle>
              </NextEvent>
            ) : (
              <Placeholder>{t('AIS ETA unknown')}</Placeholder>
            )}
          </div>
        </CardInnerWrap>
      </CardInner>
    </CardWrapper>
  );
};

export default VesselTimestampModalCard;
