import Modal from 'antd/es/modal';
import React, { useRef, useEffect } from 'react';

const MovableModalContainer = ({ children, title, open, ...props }) => {
  const modalRef = useRef();
  const position = useRef({
    startX: 0,
    startY: 0,
    dx: 0,
    dy: 0,
    tx: 0,
    ty: 0,
  });

  const handleDrag = event => {
    if (modalRef.current) {
      const tx = event.pageX - position.current.startX;
      const ty = event.pageY - position.current.startY;
      const transformStr = `translate(${tx}px,${ty}px)`;
      modalRef.current.style.transform = transformStr;
      position.current.dx = tx;
      position.current.dy = ty;
    }
  };

  const handleStart = event => {
    modalRef.current = document.getElementsByClassName('ant-modal-content')[0];
    document.addEventListener('mousemove', handleDrag);
    position.current.startX = event.pageX - position.current.dx;
    position.current.startY = event.pageY - position.current.dy;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleDrag);

    setTimeout(() => {
      const handle = document.getElementsByClassName('drag-handle')[0];
      if (handle) {
        handle.style.transform = 'translate(0px,0px)';
      }
    }, 1000);
  };

  useEffect(() => {
    const handle = document.getElementsByClassName('drag-handle')[0];
    if (handle) {
      handle.addEventListener('mousedown', handleStart);
    }
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      const handle = document.getElementsByClassName('drag-handle')[0];
      if (handle) {
        handle.removeEventListener('mousedown', handleStart);
      }
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={open}
      title={
        <div className="drag-handle" style={{ cursor: 'move' }}>
          {title}
        </div>
      }
      {...props}
    >
      {children}
    </Modal>
  );
};

export default MovableModalContainer;
