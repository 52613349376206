import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import MarkDownHandler from '../info/MarkDownHandler';
import { UserContext } from '../../context/UserContext';
import Spin from 'antd/es/spin';

const Container = styled.div`
  width: 800px;
  min-width: 400px;
  min-height: 500px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  height: 100%;
  padding: 24px;
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
    min-width: 300px;
    font-size: 13px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 6px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const StatusList = () => {
  const { apiCall } = useContext(UserContext);

  const [startLoader, setStartLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [markDownData, setMarkDownData] = useState(null);

  const fetchData = async () => {
    setStartLoader(true);
    let result;
    try {
      result = await apiCall('get', 'release-notes');
    } catch (e) {
      setMarkDownData(null);
      setStartLoader(false);
    }

    if (result?.data && result.status === 200) {
      setMarkDownData(result.data.markdown.text);
      setTitle(result.data.markdown.title);
    }

    setStartLoader(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {startLoader ? (
        <Loader>
          <Spin size="medium" />
        </Loader>
      ) : (
        <>
          <Title>{title}</Title>
          <MarkDownHandler markDownData={markDownData} editable={false} />
        </>
      )}
    </Container>
  );
};
export default StatusList;
