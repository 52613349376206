import React, { useContext } from 'react';
import { darken } from 'polished';

import { UserContext } from '../../context/UserContext';
import styled from 'styled-components';

const Circle = styled.div`
  background-color: ${props => props.color};
  color: white;
  border-radius: 50%;
  font-size: ${props => (props.size === 'small' ? '12px' : props.size === 'big' ? '18px' : '14px')};
  height: ${props => (props.size === 'small' ? '30px' : props.size === 'big' ? '52px' : '38px')};
  width: ${props => (props.size === 'small' ? '30px' : props.size === 'big' ? '52px' : '38px')};
  line-height: ${props => (props.size === 'small' ? '30px' : props.size === 'big' ? '52px' : '38px')};
  text-align: center;
  cursor: ${props => (props.hover ? 'pointer' : 'default')};
  margin: auto;

  ${props => {
    if (props.hover) {
      return `
        &:hover {
          background-color: ${darken(0.05, props.color)};
        }
      `;
    }
  }}
`;

const COLOR0 = '#578c1e';
const COLOR1 = '#2a72c1';
const COLOR2 = '#ebd044';
const COLOR3 = '#df7c40';
const COLOR4 = '#a64de2';
const COLOR5 = '#d56ba8';
const COLOR6 = '#2aad9b';
const COLOR7 = '#2ac0e1';
const COLOR8 = '#eb4d5d';
const COLOR9 = '#a9d044';

const ProfileCircle = ({ clickFunction, small = false, big = false, givenUser }) => {
  const { user } = useContext(UserContext);
  let initials = user.first_name.substring(0, 1) + user.last_name.substring(0, 1);

  let profileName = user.email;

  if (givenUser) {
    profileName = givenUser.email;
    initials = givenUser.first_name.substring(0, 1) + givenUser.last_name.substring(0, 1);
  }

  const getColor = () => {
    const count = profileName
      .split('')
      .map(c => c.charCodeAt(0))
      .reduce((current, previous) => previous + current);

    switch (count % 10) {
      case 0:
        return COLOR0;
      case 1:
        return COLOR1;
      case 2:
        return COLOR2;
      case 3:
        return COLOR3;
      case 4:
        return COLOR4;
      case 5:
        return COLOR5;
      case 6:
        return COLOR6;
      case 7:
        return COLOR7;
      case 8:
        return COLOR8;
      case 9:
        return COLOR9;
      default:
        return COLOR0;
    }
  };

  return (
    <Circle
      size={small ? 'small' : big ? 'big' : 'medium'}
      onClick={clickFunction}
      hover={!!clickFunction}
      color={getColor()}
    >
      {initials}
    </Circle>
  );
};

export default ProfileCircle;
