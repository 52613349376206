import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { UserContext } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import { ServiceOrderProvider } from './context/ServiceOrderContext';
import { FilteringProvider } from './context/FilteringContext';
import { FleetFilteringProvider } from './context/FleetFilteringContext';
import { WeatherProvider } from './context/WeatherContext';
import { TimestampProvider } from './context/TimestampContext';
import { TimestampFilteringProvider } from './context/TimestampFilteringContext';
import { InvoicingProvider } from './context/InvoicingContext';
import { NewInvoicingProvider } from './context/NewInvoicingContext';
import { StatisticsProvider } from './context/StatisticsContext';
import { EmissionsProvider } from './context/EmissionsContext';
import { WikiProvider } from './context/WikiContext';

import { isPortUnikie } from './utils/utils';

import Loader from './components/ui/Loader';

import Activity from './views/Activity';
import Logistics from './views/Logistics';
import Notifications from './views/Notifications';
import Vessels from './views/vessels/Vessels';
import PortCallTolerances from './views/vessels/PortCallTolerances';
import VesselTimestamps from './views/vessels/VesselTimestamps';
import VesselTimestamp from './views/vessels/VesselTimestamp';
import InboundVessels from './views/inbound_vessels/InboundVessels';
import Ports from './views/ports/Ports';
import VisVessels from './views/vis/VisVessels';
import VisSentTextMessages from './views/vis/VisSentTextMessages';
import VisReceivedTextMessages from './views/vis/VisReceivedTextMessages';
import VisSentVoyagePlans from './views/vis/VisSentVoyagePlans';
import VisReceivedVoyagePlans from './views/vis/VisReceivedVoyagePlans';
import VisSendTextMessage from './views/vis/VisSendTextMessage';
import VisSendRta from './views/vis/VisSendRta';
import VisNotifications from './views/vis/VisNotifications';
import VisServiceFind from './views/vis/VisServiceFind';
import VisServiceConfiguration from './views/vis/VisServiceConfiguration';
import VisVoyagePlanView from './views/vis/VisVoyagePlanView';
import AdminUsers from './views/admin/AdminUsers';
import AdminRegisterCodes from './views/admin/AdminRegisterCodes';
import AdminAccessControl from './views/admin/AdminAccessControl';
import AdminTranslations from './views/admin/AdminTranslations';
import AdminModules from './views/admin/AdminModules';
import AdminApiKeys from './views/admin/AdminApiKeys';
import AdminPortCallList from './views/admin/AdminPortCallList';
import AdminPortCallTimesheet from './views/admin/AdminPortCallTimesheet';
import ProfilePage from './views/ProfilePage';
import NotFound from './views/NotFound';
import ResetPassword from './views/ResetPassword';
import PrivacyPolicy from './views/PrivacyPolicy';
import PrivacyPolicyPage from './views/PrivacyPolicyPage';
import HelpPage from './views/HelpPage';
import WikiPage from './views/WikiPage';
import WikiLinkPage from './views/WikiLinkPage';
import Nominations from './views/queue/Nominations';
import Berths from './views/berths/Berths';
import AdminBerthReservations from './views/queue_admin/AdminBerthReservations';
import AdminSlotReservations from './views/queue_admin/AdminSlotReservations';
import AdminNominations from './views/queue_admin/AdminNominations';
import AdminDashboard from './views/queue_admin/AdminDashboard';
import AdminQueueSettings from './views/queue_admin/AdminQueueSettings';
import AdminApiKeyWeights from './views/admin/AdminApiKeyWeights';
import MapView from './views/MapView';
import AdminMapPortActors from './views/admin/AdminMapPortActors';
import MaintenanceMode from './views/MaintenanceMode';
import AdminEmergencyShutdown from './views/admin/AdminEmergencyShutdown';
import LoadingMasterPetroleumJobs from './views/loading_master/LoadingMasterPetroleumJobs';
import LoadingMasterPetroleumJob from './views/loading_master/LoadingMasterPetroleumJob';
import LandingPage from './views/LandingPage';
import BerthPlanningTool from './views/BerthPlanningTool';
import CranePlanningTool from './views/CranePlanningTool';
import Cranes from './views/Cranes';
import Commodities from './views/Commodities';
import Companies from './views/Companies';
import Holidays from './views/Holidays';
import PortWeather from './views/PortWeather';
import WeatherIcons from './views/WeatherIcons';
import SeaLevel from './views/SeaLevel';
import RedirectionComponent from './views/RedirectionComponent';
import Redirect from './views/Redirect';
import FleetView from './views/FleetView';
import FleetVesselView from './views/FleetVesselView';
import FleetDataView from './views/FleetDataView';
import FleetPublicServiceOrders from './views/FleetPublicServiceOrders';
import InvoicingView from './views/InvoicingView';
import InvoicingMatrixView from './views/InvoicingMatrixView';
import InvoicingInvoicesView from './views/InvoicingInvoicesView';
import InvoicingCustomersView from './views/InvoicingCustomersView';
import AdminServiceOrders from './views/service_orders/AdminServiceOrders';
import AdminServices from './views/service_orders/AdminServices';
import AdminServiceProviders from './views/service_orders/AdminServiceProviders';
import AdminServiceTypes from './views/service_orders/AdminServiceTypes';
import GeoAssetTool from './views/admin/GeoAssetTool';
import AdminGroups from './views/admin/AdminGroups';
import AdminOrganizations from './views/admin/AdminOrganizations';
import MapPocView from './views/MapPocView';
import AdminTimestampFiltering from './views/admin/AdminTimestampFiltering';
import Statistics from './views/Statistics';
import IncidentsView from './views/IncidentsView';
import IncidentView from './views/IncidentView';
import StatusView from './views/StatusView';
import WasteView from './views/WasteView';
import Forms from './views/Forms';
import DataExport from './views/DataExport';
import EmissionsView from './views/EmissionsView';
import ActivityList from './views/ActivityList';
import ActivityCards from './views/ActivityCards';
import ActivityListV2 from './views/ActivityListV2';
import AdminPortStamp from './views/admin/AdminPortStamp';
import WasteDocumentView from './views/WasteDocumentView';
import WasteDocumentValidate from './views/WasteDocumentValidation';
import { AlertProvider } from './context/AlertContext';
import AdminAlerts from './views/admin/AdminAlerts';
import InvoiceView from './views/invoicing/InvoiceView';
import ProductsView from './views/invoicing/ProductsView';
import TemplatesView from './views/invoicing/TemplatesView';
import { SideHelpProvider } from './context/SideHelpContext';
import AdminMfaSettings from './views/admin/AdminMfaSettings';
import CustomersView from './views/invoicing/CustomersView';
import ErrorTestingPage from './views/ErrorTestingPage';
import AdminPreAdviceQueue from './views/queue_admin/AdminPreAdviceQueue';
import AdminFormsCodes from './views/admin/AdminFormsCodes';
import AdminPortCallRequests from './views/admin/AdminPortCallRequests';
import AdminPortCallRequest from './views/admin/AdminPortcallRequest';

const Router = () => {
  const {
    verifyingSession,
    modules,
    user,
    maintenanceModeEnabled,
    handlingLoginLogout,
    namespaces,
    namespace,
    namespaceFromURLChecked,
    onlyFleet,
  } = useContext(UserContext);

  const namespaceList = namespaces.map(s => s.namespace);

  const portUnikie = isPortUnikie(namespace);

  if (verifyingSession || handlingLoginLogout) {
    return <Loader />;
  }

  const renderRoute = (props, Component, checks, withoutNamespace = false) => {
    if (withoutNamespace) {
      return <Component {...props} />;
    }

    if (
      namespaceList.includes(props.match.params.urlnamespace) &&
      props.match.params.urlnamespace === namespace &&
      checks
    ) {
      return <Component {...props} />;
    } else if (
      namespaceList.includes(props.match.params.urlnamespace) &&
      props.match.params.urlnamespace !== namespace
    ) {
      return <RedirectionComponent {...props} />;
    } else {
      return <NotFound {...props} />;
    }
  };

  if (user) {
    if (!namespaceFromURLChecked) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/forms/:formType?" render={props => renderRoute(props, Forms, true, true)} />
            <Route path="/:urlnamespace/" render={props => renderRoute(props, RedirectionComponent, true, true)} />
            <Route render={props => renderRoute(props, RedirectionComponent, true, true)} />
          </Switch>
        </BrowserRouter>
      );
    }

    const urlsWithNamespaces = [
      {
        path: '/',
        exact: true,
        component: Activity,
        checks: modules?.activity_module === 'enabled',
      },
      {
        path: '/activity-list',
        exact: true,
        component: ActivityList,
        checks: modules?.activity_list_module === 'enabled',
      },
      {
        path: '/activity-cards',
        exact: true,
        component: ActivityCards,
        checks: modules?.activity_module === 'enabled',
      },
      {
        path: '/activity-list-v2',
        exact: true,
        component: ActivityListV2,
        checks: true,
      },
      {
        path: '/profile',
        exact: true,
        component: ProfilePage,
        checks: true,
      },
      {
        path: '/help-page',
        exact: true,
        component: HelpPage,
        checks: true,
      },
      {
        path: '/wiki',
        exact: true,
        component: WikiPage,
        checks: modules?.wiki_module === 'enabled',
      },
      {
        path: '/wiki-links',
        exact: true,
        component: WikiLinkPage,
        checks: modules?.wiki_module === 'enabled' && user.permissions.includes('manage_wiki'),
      },
      {
        path: '/logistics',
        exact: true,
        component: Logistics,
        checks: modules?.logistics_module === 'enabled',
      },
      {
        path: '/notifications/:tabId?',
        exact: true,
        component: Notifications,
        checks: true,
      },
      {
        path: '/vessels',
        exact: true,
        component: Vessels,
        checks: true,
      },
      {
        path: '/port-call-tolerances',
        exact: true,
        component: PortCallTolerances,
        checks: true,
      },
      {
        path: '/inbound-vessels',
        exact: true,
        component: InboundVessels,
        checks: true,
      },
      {
        path: '/port-weather',
        exact: true,
        component: PortWeather,
        checks: modules?.weather_module === 'enabled',
      },
      {
        path: '/sea-level',
        exact: true,
        component: SeaLevel,
        checks: modules?.sea_level_module === 'enabled',
      },
      {
        path: '/berth-planning-tool',
        exact: true,
        component: BerthPlanningTool,
        checks: modules?.berth_planning_module === 'enabled' && user.permissions.includes('manage_bpt'),
      },
      {
        path: '/vessel-calendar',
        exact: true,
        component: BerthPlanningTool,
        checks:
          modules &&
          modules.berth_planning_module === 'enabled' &&
          user.permissions.includes('view_bpt') &&
          !user.permissions.includes('manage_bpt'),
      },
      {
        path: '/crane-timeline',
        exact: true,
        component: CranePlanningTool,
        checks:
          modules &&
          modules.predictive_berth_planning_module === 'enabled' &&
          user.permissions.includes('view_crane_reservations'),
      },
      {
        path: '/cranes',
        exact: true,
        component: Cranes,
        checks:
          modules &&
          modules.predictive_berth_planning_module === 'enabled' &&
          user.permissions.includes('view_crane_reservations'),
      },
      {
        path: '/commodities',
        exact: true,
        component: Commodities,
        checks: modules?.predictive_berth_planning_module === 'enabled' && user.permissions.includes('manage_pbp'),
      },
      {
        path: '/companies',
        exact: true,
        component: Companies,
        checks: modules?.predictive_berth_planning_module === 'enabled' && user.permissions.includes('manage_pbp'),
      },
      {
        path: '/holidays',
        exact: true,
        component: Holidays,
        checks: modules?.predictive_berth_planning_module === 'enabled',
      },
      {
        path: '/invoicing-holidays',
        exact: true,
        component: Holidays,
        checks: modules?.invoicing_v2_module === 'enabled',
      },
      {
        path: '/loading-master-petroleum-jobs',
        exact: true,
        component: LoadingMasterPetroleumJobs,
        checks: modules?.loading_master_module === 'enabled' && user.permissions.includes('view_loading_job'),
      },
      {
        path: '/loading-master-petroleum-job',
        exact: true,
        component: LoadingMasterPetroleumJob,
        checks: modules?.loading_master_module === 'enabled' && user.permissions.includes('view_loading_job'),
      },
      {
        path: '/ports',
        exact: false,
        component: Ports,
        checks: user.permissions.includes('manage_port'),
      },
      {
        path: '/vessels/vessel-timestamps/:imo',
        exact: false,
        component: VesselTimestamps,
        checks: user.permissions.includes('manage_port_call'),
      },
      {
        path: '/vessels/vessel-timestamp/:id',
        exact: false,
        component: VesselTimestamp,
        checks: user.permissions.includes('manage_port_call'),
      },
      {
        path: '/vis-vessels',
        exact: true,
        component: VisVessels,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/sent-text-messages/:service_id',
        exact: false,
        component: VisSentTextMessages,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/received-text-messages/:service_id',
        exact: false,
        component: VisReceivedTextMessages,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/sent-voyage-plans/:service_id',
        exact: false,
        component: VisSentVoyagePlans,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/received-voyage-plans/:service_id',
        exact: false,
        component: VisReceivedVoyagePlans,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/send-text-message/:service_id',
        exact: false,
        component: VisSendTextMessage,
        checks: user.permissions.includes('send_vis_text_message'),
      },
      {
        path: '/vis-vessels/send-rta/:service_id',
        exact: false,
        component: VisSendRta,
        checks: user.permissions.includes('send_vis_rta'),
      },
      {
        path: '/vis-vessels/vis-notifications',
        exact: true,
        component: VisNotifications,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/vis-service-find',
        exact: true,
        component: VisServiceFind,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/vis-service-configuration',
        exact: true,
        component: VisServiceConfiguration,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/vis-vessels/voyage-plan-view',
        exact: true,
        component: VisVoyagePlanView,
        checks: user.permissions.includes('view_vis_information'),
      },
      {
        path: '/admin/users',
        exact: true,
        component: AdminUsers,
        checks: user.permissions.findIndex(e => e?.includes('manage_user')) !== -1,
      },
      {
        path: '/admin/register-codes',
        exact: true,
        component: AdminRegisterCodes,
        checks: user.permissions.includes('manage_registration_code'),
      },
      {
        path: '/admin/access-control',
        exact: true,
        component: AdminAccessControl,
        checks: user.permissions.includes('manage_permission'),
      },
      {
        path: '/admin/groups',
        exact: true,
        component: AdminGroups,
        checks: user.permissions.findIndex(e => e?.includes('manage_user')) !== -1,
      },
      {
        path: '/admin/organizations',
        exact: true,
        component: AdminOrganizations,
        checks: user.permissions.findIndex(e => e?.includes('manage_user')) !== -1,
      },
      {
        path: '/admin/mfa-settings',
        exact: true,
        component: AdminMfaSettings,
        checks: user.permissions.findIndex(e => e && e.includes('manage_all_user')) !== -1,
      },
      {
        path: '/admin/alerts',
        exact: true,
        component: AdminAlerts,
        checks: user.permissions.findIndex(e => e?.includes('manage_all_user')) !== -1,
      },
      {
        path: '/admin/forms-codes',
        exact: true,
        component: AdminFormsCodes,
        checks: modules && modules.forms_code_module === 'enabled' && user.permissions.includes('manage_forms_code'),
      },
      {
        path: '/admin/port-stamp',
        exact: true,
        component: AdminPortStamp,
        checks: user.permissions.findIndex(e => e?.includes('view_port_binary_assets')) !== -1,
      },
      {
        path: '/admin/string-translations',
        exact: true,
        component: AdminTranslations,
        checks: user.permissions.includes('manage_translation'),
      },
      {
        path: '/admin/api-keys',
        exact: true,
        component: AdminApiKeys,
        checks: user.permissions.includes('manage_api_key'),
      },
      {
        path: '/admin/timestamp-filtering',
        exact: true,
        component: AdminTimestampFiltering,
        checks: user.permissions.includes('manage_setting'),
      },
      {
        path: '/port-calls',
        exact: true,
        component: AdminPortCallList,
        checks: modules?.activity_module === 'enabled' && user.permissions.includes('manage_port_call'),
      },
      {
        path: '/port-call-requests',
        exact: true,
        component: AdminPortCallRequests,
        checks: modules?.activity_module === 'enabled' && user.permissions.includes('manage_port_call'),
      },
      {
        path: '/port-call-request/:id',
        exact: true,
        component: AdminPortCallRequest,
        checks: modules?.activity_module === 'enabled' && user.permissions.includes('manage_port_call'),
      },
      {
        path: '/admin/port-calls/:id',
        exact: true,
        component: AdminPortCallTimesheet,
        checks: modules?.activity_module === 'enabled' && user.permissions.includes('manage_port_call'),
      },
      {
        path: '/admin/modules',
        exact: true,
        component: AdminModules,
        checks: user.permissions.includes('manage_setting'),
      },
      {
        path: '/berths',
        exact: false,
        component: Berths,
        checks: user.permissions.includes('view_berth'),
      },
      {
        path: '/queue-admin/admin-berth-reservations',
        exact: true,
        component: AdminBerthReservations,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_berth_reservation'),
      },
      {
        path: '/queue-admin/admin-slot-reservations',
        exact: true,
        component: AdminSlotReservations,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_queue_slot_reservation'),
      },
      {
        path: '/queue-admin/admin-nominations',
        exact: true,
        component: AdminNominations,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_all_queue_nomination'),
      },
      {
        path: '/queue-admin/admin-dashboard',
        exact: true,
        component: AdminDashboard,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_queue_slot_reservation'),
      },
      {
        path: '/queue-admin/pre-advice-queue',
        exact: true,
        component: AdminPreAdviceQueue,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('manage_queue_slot_reservation'),
      },
      {
        path: '/queue-admin/admin-queue-settings',
        exact: true,
        component: AdminQueueSettings,
        checks: modules?.queue_module === 'enabled' && user.permissions.includes('manage_setting'),
      },
      {
        path: '/admin/api-key-weights/:selected',
        exact: true,
        component: AdminApiKeyWeights,
        checks: user.permissions.includes('manage_api_key'),
      },
      {
        path: '/map',
        component: MapView,
        checks: modules?.map_module === 'enabled',
      },
      {
        path: '/map-poc',
        component: MapPocView,
        checks:
          modules &&
          modules.map_module === 'enabled' &&
          (document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')),
      },
      {
        path: '/admin/map-port-actors',
        exact: true,
        component: AdminMapPortActors,
        checks: user.permissions.includes('manage_sea_chart_vessel') && modules?.map_module === 'enabled',
      },
      {
        path: '/admin/geo-asset-tool',
        exact: true,
        component: GeoAssetTool,
        checks: portUnikie,
      },
      {
        path: '/fleet-view',
        exact: true,
        component: FleetView,
        checks: modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet'),
      },
      {
        path: '/fleet-vessel-view',
        exact: true,
        component: FleetVesselView,
        checks: modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet'),
      },
      {
        path: '/fleet-data-view',
        exact: true,
        component: FleetDataView,
        checks: modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet'),
      },
      {
        path: '/fleet-service-orders',
        exact: true,
        component: FleetPublicServiceOrders,
        checks: modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet'),
      },
      {
        path: '/invoicing',
        exact: true,
        component: InvoicingView,
        checks: modules.invoicing_module === 'enabled',
      },
      {
        path: '/invoicing/matrix',
        exact: true,
        component: InvoicingMatrixView,
        checks: modules.invoicing_module === 'enabled',
      },
      {
        path: '/invoicing/invoices',
        exact: true,
        component: InvoicingInvoicesView,
        checks: modules.invoicing_module === 'enabled',
      },
      {
        path: '/invoicing/customers',
        exact: true,
        component: InvoicingCustomersView,
        checks: modules.invoicing_module === 'enabled' && user.permissions.includes('manage_invoicing'),
      },
      {
        path: '/invoicing-invoices/:page',
        exact: true,
        component: InvoiceView,
        checks: modules.invoicing_v2_module === 'enabled',
      },
      {
        path: '/invoicing-products/:page',
        exact: true,
        component: ProductsView,
        checks: modules.invoicing_v2_module === 'enabled',
      },
      {
        path: '/invoicing-templates',
        exact: true,
        component: TemplatesView,
        checks: modules.invoicing_v2_module === 'enabled',
      },
      {
        path: '/invoicing-customers',
        exact: true,
        component: CustomersView,
        checks: modules.invoicing_v2_module === 'enabled',
      },
      {
        path: '/service-orders',
        exact: true,
        component: AdminServiceOrders,
        checks: modules?.service_order_module === 'enabled' && user.permissions.includes('admin_service_order'),
      },
      {
        path: '/service-orders/services',
        exact: true,
        component: AdminServices,
        checks:
          modules &&
          modules.service_order_module === 'enabled' &&
          user.permissions.includes('manage_service_order_service'),
      },
      {
        path: '/service-orders/service-providers',
        exact: true,
        component: AdminServiceProviders,
        checks:
          modules &&
          modules.service_order_module === 'enabled' &&
          user.permissions.includes('manage_service_order_service'),
      },
      {
        path: '/service-orders/service-types',
        exact: true,
        component: AdminServiceTypes,
        checks:
          modules &&
          modules.service_order_module === 'enabled' &&
          user.permissions.includes('manage_service_order_service'),
      },
      {
        path: '/statistics',
        exact: true,
        component: Statistics,
        checks: modules.port_statistics_module === 'enabled' && user.permissions.includes('view_port_statistics'),
      },
      {
        path: '/emission-statistics',
        exact: true,
        component: EmissionsView,
        checks: modules.emissions_summary_module === 'enabled' && user.permissions.includes('view_emissions_summary'),
      },
      {
        path: '/data-export',
        exact: true,
        component: DataExport,
        checks: modules?.data_export_module === 'enabled' && user.permissions.includes('export_data'),
      },
      {
        path: '/incidents-and-events',
        exact: true,
        component: IncidentsView,
        checks: modules.incident_module === 'enabled' && user.permissions.includes('view_incident'),
      },
      {
        path: '/incident-and-event/:id',
        exact: true,
        component: IncidentView,
        checks: modules.incident_module === 'enabled' && user.permissions.includes('view_incident'),
      },
      {
        path: '/release-notes',
        exact: true,
        component: StatusView,
        checks: true,
      },
      {
        path: '/waste',
        exact: true,
        component: WasteView,
        checks: modules?.waste_module === 'enabled',
      },
      {
        path: '/waste-view-document',
        exact: true,
        component: WasteDocumentView,
        checks: modules?.waste_module === 'enabled' && user.permissions.includes('view_documents'),
      },
      {
        path: '/waste-validate-document',
        exact: true,
        component: WasteDocumentValidate,
        checks: modules?.waste_module === 'enabled' && user.permissions.includes('validate_documents'),
      },
      {
        path: '/error-testing-page',
        exact: true,
        component: ErrorTestingPage,
        checks: document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev'),
      },
    ];

    if (maintenanceModeEnabled) {
      return (
        <BrowserRouter>
          <Switch>
            {user.permissions.includes('control_emergency_shutdown') && (
              <Route
                path="/admin/emergency-shutdown"
                exact
                render={props => renderRoute(props, AdminEmergencyShutdown, true, true)}
              />
            )}
            <Route render={props => renderRoute(props, MaintenanceMode, true, true)} />
          </Switch>
        </BrowserRouter>
      );
    } else if (user.permissions.includes('basic_user_action')) {
      return (
        <BrowserRouter>
          <NotificationProvider>
            <ServiceOrderProvider>
              <FilteringProvider>
                <FleetFilteringProvider>
                  <WeatherProvider>
                    <TimestampProvider>
                      <TimestampFilteringProvider>
                        <InvoicingProvider>
                          <NewInvoicingProvider>
                            <StatisticsProvider>
                              <EmissionsProvider>
                                <WikiProvider>
                                  <AlertProvider>
                                    <SideHelpProvider>
                                      <Switch>
                                        {user.permissions.includes('control_emergency_shutdown') && (
                                          <Route
                                            path={[
                                              '/:urlnamespace/admin/emergency-shutdown',
                                              '/admin/emergency-shutdown',
                                            ]}
                                            exact
                                            render={props => renderRoute(props, AdminEmergencyShutdown, true, true)}
                                          />
                                        )}
                                        <Route
                                          path={['/:urlnamespace/privacy-policy', '/privacy-policy']}
                                          exact
                                          render={props => renderRoute(props, PrivacyPolicyPage, true, true)}
                                        />
                                        <Route
                                          path={['/:urlnamespace/reset-password', '/reset-password']}
                                          exact
                                          render={props => renderRoute(props, ResetPassword, true, true)}
                                        />
                                        {(document.location.hostname === 'localhost' ||
                                          document.location.hostname.includes('ft-testing.dev')) && (
                                          <Route
                                            path={['/:urlnamespace/weather-icons', '/weather-icons']}
                                            render={props => renderRoute(props, WeatherIcons, true, true)}
                                          />
                                        )}

                                        {urlsWithNamespaces.map(url => (
                                          <Redirect
                                            key={url.path}
                                            exact
                                            from={url.path}
                                            to={'/' + namespace + url.path}
                                          />
                                        ))}

                                        {onlyFleet === undefined && (
                                          <Route
                                            render={props => renderRoute(props, RedirectionComponent, true, true)}
                                          />
                                        )}

                                        {onlyFleet && namespace === 'neste' && (
                                          <Redirect
                                            exact
                                            from="/:urlnamespace/"
                                            to={'/' + namespace + '/fleet-vessel-view'}
                                          />
                                        )}
                                        {onlyFleet && (
                                          <Redirect exact from="/:urlnamespace/" to={'/' + namespace + '/fleet-view'} />
                                        )}

                                        {modules?.activity_module === 'disabled' && (
                                          <Redirect
                                            exact
                                            from="/:urlnamespace/"
                                            to={'/' + namespace + '/notifications'}
                                          />
                                        )}

                                        {urlsWithNamespaces.map(url => (
                                          <Route
                                            key={url.path}
                                            path={'/:urlnamespace' + url.path}
                                            exact={url.exact}
                                            render={props => renderRoute(props, url.component, url.checks)}
                                          />
                                        ))}

                                        <Route render={props => renderRoute(props, NotFound, true, true)} />
                                      </Switch>
                                    </SideHelpProvider>
                                  </AlertProvider>
                                </WikiProvider>
                              </EmissionsProvider>
                            </StatisticsProvider>
                          </NewInvoicingProvider>
                        </InvoicingProvider>
                      </TimestampFilteringProvider>
                    </TimestampProvider>
                  </WeatherProvider>
                </FleetFilteringProvider>
              </FilteringProvider>
            </ServiceOrderProvider>
          </NotificationProvider>
        </BrowserRouter>
      );
    } else if (user.role.includes('consignee')) {
      const urlsWithNamespaces = [
        {
          path: '/profile',
          exact: true,
          component: ProfilePage,
          checks: true,
        },
        {
          path: '/help-page',
          exact: true,
          component: HelpPage,
          checks: true,
        },
        {
          path: '/:urlnamespace/queue/nominations',
          exact: true,
          component: Nominations,
          checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_own_queue_nomination'),
        },
        {
          path: '/:urlnamespace/queue-admin/admin-dashboard',
          exact: true,
          component: AdminDashboard,
          checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_queue_slot_reservation'),
        },
        {
          path: '/:urlnamespace/queue-admin/admin-slot-reservations',
          exact: true,
          component: AdminSlotReservations,
          checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_queue_slot_reservation'),
        },
        {
          path: '/:urlnamespace/queue-admin/admin-berth-reservations',
          exact: true,
          component: AdminBerthReservations,
          checks: modules?.queue_module === 'enabled' && user.permissions.includes('view_berth_reservation'),
        },
      ];

      return (
        <BrowserRouter>
          <Switch>
            <Route
              path={['/:urlnamespace/privacy-policy', '/privacy-policy']}
              exact
              render={props => renderRoute(props, PrivacyPolicyPage, true, true)}
            />

            {urlsWithNamespaces.map(url => (
              <Redirect key={url.path} exact from={url.path} to={'/' + namespace + url.path} />
            ))}

            {modules &&
              modules.queue_module === 'enabled' &&
              user.permissions.includes('view_own_queue_nomination') && (
              <Redirect exact from="/:urlnamespace/" to="/:urlnamespace/queue/nominations" />
            )}

            {urlsWithNamespaces.map(url => (
              <Route
                key={url.path}
                path={'/:urlnamespace' + url.path}
                exact={url.exact}
                render={props => renderRoute(props, url.component, url.checks)}
              />
            ))}
            <Route render={props => renderRoute(props, NotFound, true, true)} />
          </Switch>
        </BrowserRouter>
      );
    }
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/privacy-policy" exact render={props => renderRoute(props, PrivacyPolicy, true, true)} />
          <Route path="/reset-password" exact render={props => renderRoute(props, ResetPassword, true, true)} />
          <Route path="/forms/:formType?" render={props => renderRoute(props, Forms, true, true)} />
          <Route path={['/:urlnamespace', '/']} render={props => renderRoute(props, LandingPage, true, true)} />
        </Switch>
      </BrowserRouter>
    );
  }
};

export default Router;
