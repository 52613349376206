import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';
import DebounceAutocomplete from '../../ui/DebounceAutocomplete';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 180px;
`;

const ModalInnerDelete = styled(ModalInner)`
  min-height: 320px;
`;

const Row = styled.div`
  display: flex;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / 5 - 12px);
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 12px;
  margin-bottom: 12px;
`;

const RemoveButtonColumn = styled.div`
  margin-right: 12px;
`;

const TimeColumn = styled.div`
  margin-left: 12px;
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 138px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RedIcon = styled.span`
  color: red;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-item: center;
  svg {
    width: 26px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  padding: 3px 0 0 10px;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
  min-width: 800px;
`;

const UpperScrollContainer = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

const AddOrEditReservationModal = ({ type, closeModal, vessel = undefined, readOnly }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { berths, sendDeleteReservation, sendAddReservation, sendUpdateReservation, dynamicBollards } = useContext(
    BerthPlanningToolContext
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [inputData, setInputData] = useState({
    agent: vessel ? vessel.agent : undefined,
    notes: vessel ? vessel.notes : undefined,
    name: vessel ? vessel.name : undefined,
    imo: vessel ? vessel.imo : undefined,
    start_time: vessel ? vessel.start_time : undefined,
    end_time: vessel ? vessel.end_time : undefined,
    berth_code: vessel ? vessel.berth_code : undefined,
    start_bollard: vessel ? vessel.start_bollard : undefined,
    end_bollard: vessel ? vessel.end_bollard : undefined,
  });

  const [sending, setSending] = useState(false);

  const title = readOnly
    ? t('Reservation')
    : type === 'new'
      ? t('Add new reservation')
      : t('Edit or remove the reservation');

  const dataIsValid = () => {
    return (
      (inputData.imo ? (typeof inputData.imo === 'number' ? true : !isNaN(inputData.imo)) : true) &&
      (inputData.agent || inputData.notes) &&
      inputData.start_time &&
      inputData.end_time &&
      inputData.berth_code &&
      (dynamicBollards || inputData.start_bollard) &&
      (dynamicBollards || inputData.end_bollard)
    );
  };

  const handleBerthChange = value => {
    setInputData({ ...inputData, berth_code: value, start_bollard: undefined, end_bollard: undefined });
  };

  const handleFromBollardChange = value => {
    setInputData({ ...inputData, start_bollard: value });
  };

  const handleToBollardChange = value => {
    setInputData({ ...inputData, end_bollard: value });
  };

  const updateAgent = value => {
    setInputData({ ...inputData, agent: value });
  };

  const updateNotes = value => {
    setInputData({ ...inputData, notes: value.target.value });
  };

  const updateVessel = value => {
    setInputData({ ...inputData, name: value });
  };

  const selectVesselOrImo = (value, obj) => {
    setInputData({ ...inputData, name: obj.data.vessel_name, imo: obj.data.imo });
  };

  const updateImo = value => {
    setInputData({ ...inputData, imo: value });
  };

  const onStartTimeChange = value => {
    setInputData({ ...inputData, start_time: value || null });
  };

  const onEndTimeChange = value => {
    setInputData({ ...inputData, end_time: value || null });
  };

  const deleteReservationClick = () => {
    setDeleteConfirmation(true);
  };

  const closeConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const removeReservation = () => {
    sendDeleteReservation(vessel.id);

    closeModal();
  };

  const sendData = async () => {
    setSending(true);
    let result;
    try {
      let sendData = { ...inputData };
      if (dynamicBollards) {
        sendData.start_bollard = 'B1';
        sendData.end_bollard = 'B1';
      }
      if (type === 'new') {
        result = await sendAddReservation({ ...sendData });
      } else {
        result = await sendUpdateReservation({ ...sendData, id: vessel.id });
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal();
    } else {
      setSending(false);
    }
  };

  const onImoChange = value => {
    setInputData({ ...inputData, imo: value });
  };

  const onNameChange = value => {
    setInputData({ ...inputData, name: value });
  };

  const fetchVesselList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      sort: 'vessel_name',
      search: value,
    };
    const result = await apiCall('get', 'vessels', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.vessel_name + '+' + d.imo, label: d.vessel_name + ' (' + d.imo + ')', data: d };
      });
    }

    return [];
  };

  const fetchAgentList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    const result = await apiCall('get', 'agents', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  const fromBollardOptions = inputData.berth_code ? berths.find(g => g.id === inputData.berth_code).subRows : [];
  const toBollardOptions = inputData.berth_code ? berths.find(g => g.id === inputData.berth_code).subRows : [];

  return (
    <Modal title={title} open={!!type} width={1000} onCancel={closeModal} footer={null}>
      {deleteConfirmation ? (
        <ModalInnerDelete>
          <IconContainer>
            <Icon type="remove-illustration"></Icon>
          </IconContainer>
          <ConfirmationText>{t('Are you sure you want to remove the reservation?')}</ConfirmationText>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight type="button" cancel onClick={closeConfirmation}>
              {t('Back')}
            </ButtonLight>
            <ButtonLight red onClick={removeReservation}>
              {t('Remove')}
            </ButtonLight>
          </ActionButtons>
        </ModalInnerDelete>
      ) : (
        <ModalInnerInput>
          {type !== 'new' && user.permissions.includes('manage_bpt') && (
            <RemoveContainer>
              <RemoveButtonColumn>
                <ButtonContainer onClick={deleteReservationClick}>
                  <RedIcon>
                    <Icon type="trash"></Icon>
                  </RedIcon>
                  <BoldText>{t('Remove reservation')}</BoldText>
                </ButtonContainer>
              </RemoveButtonColumn>
              <TimeColumn>
                <FirstColumn>
                  <RowHeaderForTooltip>{t('Start')}:</RowHeaderForTooltip>
                  <RowHeaderForTooltip>{t('End')}:</RowHeaderForTooltip>
                </FirstColumn>
                <SecondColumn>
                  <TooltipValue>{dayjs(vessel.start_time).format('MMM DD, YYYY HH:mm')}</TooltipValue>
                  <TooltipValue>{dayjs(vessel.end_time).format('MMM DD, YYYY HH:mm')}</TooltipValue>
                </SecondColumn>
              </TimeColumn>
            </RemoveContainer>
          )}
          <UpperScrollContainer>
            <ScrollContainer>
              <Row>
                <RowContainer>
                  <RowHeader>{t('Agent')}</RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Agent name')}
                      value={inputData.agent || ''}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <DebounceAutocomplete
                      placeholder={t('Agent name')}
                      fetchOptions={fetchAgentList}
                      onChange={updateAgent}
                      onSelect={updateAgent}
                      value={inputData.agent}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => updateAgent(null)}
                    />
                  )}
                </RowContainer>
                <RowContainer>
                  <RowHeader>{t('Vessel')}</RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Name or IMO')}
                      value={inputData.name || ''}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <DebounceAutocomplete
                      placeholder={t('Name or IMO')}
                      fetchOptions={fetchVesselList}
                      onChange={onNameChange}
                      onSelect={(value, data) => selectVesselOrImo(value, data)}
                      value={inputData.name}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => updateVessel(null)}
                    />
                  )}
                </RowContainer>
                <RowContainer>
                  <RowHeader>{t('IMO')}</RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Name or IMO')}
                      value={inputData.imo || ''}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <DebounceAutocomplete
                      placeholder={t('Name or IMO')}
                      fetchOptions={fetchVesselList}
                      onChange={onImoChange}
                      onSelect={(value, data) => selectVesselOrImo(value, data)}
                      value={inputData.imo}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => updateImo(null)}
                    />
                  )}
                </RowContainer>
                <RowContainer>
                  <RowHeader>
                    {t('Start')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Reservation start')}
                      value={inputData.start_time ? dayjs(inputData.start_time).format('DD.MM.YYYY HH:mm') : null}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      defaultValue={inputData.start_time ? dayjs(inputData.start_time) : null}
                      style={{
                        width: '100%',
                      }}
                      onChange={onStartTimeChange}
                      onSelect={onStartTimeChange}
                      placeholder={t('Reservation start')}
                    />
                  )}
                </RowContainer>
                <RowContainer>
                  <RowHeader>
                    {t('End')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Reservation end')}
                      value={inputData.end_time ? dayjs(inputData.end_time).format('DD.MM.YYYY HH:mm') : null}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      defaultValue={inputData.end_time ? dayjs(inputData.end_time) : null}
                      style={{
                        width: '100%',
                      }}
                      onChange={onEndTimeChange}
                      onSelect={onEndTimeChange}
                      placeholder={t('Reservation end')}
                    />
                  )}
                </RowContainer>
              </Row>
              <Row>
                <RowContainer>
                  <RowHeader>
                    {t('Berth')}
                    <Required>*</Required>
                  </RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Berth name')}
                      value={inputData.berth_code || ''}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <Select
                      showSearch
                      placeholder={t('Berth name')}
                      optionFilterProp="label"
                      onChange={handleBerthChange}
                      style={{ width: '100%' }}
                      options={berths.map(g => {
                        return { label: g.id === 'unknown' ? t('No berth') : g.short_name, value: g.id };
                      })}
                      value={inputData.berth_code || undefined}
                    />
                  )}
                </RowContainer>
                {!dynamicBollards && (
                  <>
                    <RowContainer>
                      <RowHeader>
                        {t('From bollard')}
                        <Required>*</Required>
                      </RowHeader>
                      {readOnly ? (
                        <Input
                          placeholder={t('Bollard name')}
                          value={
                            inputData.start_bollard
                              ? fromBollardOptions.find(g => g.id === inputData.start_bollard)?.short_name
                              : ''
                          }
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <Select
                          showSearch
                          placeholder={t('Bollard name')}
                          optionFilterProp="label"
                          onChange={handleFromBollardChange}
                          style={{ width: '100%' }}
                          options={fromBollardOptions.map(g => {
                            return { label: g.short_name, value: g.id };
                          })}
                          disabled={!inputData.berth_code || inputData.berth_code === 'unknown'}
                          value={inputData.start_bollard || undefined}
                        />
                      )}
                    </RowContainer>
                    <RowContainer>
                      <RowHeader>
                        {t('To bollard')}
                        <Required>*</Required>
                      </RowHeader>
                      {readOnly ? (
                        <Input
                          placeholder={t('Bollard name')}
                          value={
                            inputData.end_bollard
                              ? fromBollardOptions.find(g => g.id === inputData.end_bollard)?.short_name
                              : ''
                          }
                          disabled={true}
                          style={{ color: '#4a4a4a' }}
                        />
                      ) : (
                        <Select
                          showSearch
                          placeholder={t('Bollard name')}
                          optionFilterProp="label"
                          onChange={handleToBollardChange}
                          style={{ width: '100%' }}
                          options={toBollardOptions.map(g => {
                            return { label: g.short_name, value: g.id };
                          })}
                          disabled={!inputData.berth_code || inputData.berth_code === 'unknown'}
                          value={inputData.end_bollard || undefined}
                        />
                      )}
                    </RowContainer>
                  </>
                )}
                <RowContainer style={{ width: 'calc((100% / 5 * 2) - 12px)' }}>
                  <RowHeader>{t('Notes')}</RowHeader>
                  {readOnly ? (
                    <Input
                      placeholder={t('Notes')}
                      value={inputData.notes || ''}
                      disabled={true}
                      style={{ color: '#4a4a4a' }}
                    />
                  ) : (
                    <Input placeholder={t('Notes')} value={inputData.notes || ''} onChange={updateNotes} />
                  )}
                </RowContainer>
              </Row>
            </ScrollContainer>
          </UpperScrollContainer>
          <MiddleLine />
          {readOnly ? (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Close')}
              </ButtonLight>
            </ActionButtons>
          ) : (
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
                {type === 'new' ? t('Reserve') : t('Save')}
              </ButtonLight>
            </ActionButtons>
          )}
        </ModalInnerInput>
      )}
    </Modal>
  );
};

export default AddOrEditReservationModal;
