import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../../../context/UserContext';
import ButtonLight from '../../../ui/ButtonLight';

const ModalInner = styled.div`
  min-height: 214px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const UpperRowContainer = styled.div`
  display: flex;

  > div {
    width: 49%;
  }
`;

const RowContainer = styled.div`
  padding: 8px 0 12px 0;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const InstructionText = styled.div`
  color: #949c9c;
`;

const AddOrEditLocalHolidayModal = ({ type, closeModal, data, refetchData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    name: data ? data.name : undefined,
    start_date_time: data ? data.date : undefined,
    end_date_time: data ? data.endDate : undefined,
  });

  const [sending, setSending] = useState(false);

  const title = type === 'add' ? t('Add local holiday') : t('Edit local holiday');
  const buttonText = type === 'add' ? t('Add') : t('Save');

  const dataIsValid = () => {
    return inputData.name && inputData.start_date_time;
  };

  const updateName = e => {
    setInputData({ ...inputData, name: e.target.value });
  };

  const onStartDateChange = value => {
    if (value) {
      setInputData({ ...inputData, start_date_time: value.format() });
    } else {
      setInputData({ ...inputData, start_date_time: value });
    }
  };

  const onEndDateChange = value => {
    if (value) {
      setInputData({ ...inputData, end_date_time: value.format() });
    } else {
      setInputData({ ...inputData, end_date_time: value });
    }
  };

  const sendData = async () => {
    setSending(true);
    let result;

    try {
      if (type === 'add') {
        result = await apiCall('post', 'calendar/local-holiday', {
          name: inputData.name,
          start_date_time: inputData.start_date_time
            ? dayjs(inputData.start_date_time)
              .second(0)
              .format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
          end_date_time: inputData.end_date_time
            ? dayjs(inputData.end_date_time)
              .second(0)
              .format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        });
      } else {
        result = await apiCall('put', 'calendar/local-holiday', {
          id: data.id,
          name: inputData.name,
          start_date_time: inputData.start_date_time
            ? dayjs(inputData.start_date_time)
              .second(0)
              .format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
          end_date_time: inputData.end_date_time
            ? dayjs(inputData.end_date_time)
              .second(0)
              .format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        });
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      refetchData();
      closeModal();
    } else {
      setSending(false);
    }
  };

  return (
    <Modal title={title} open={!!type} width={600} footer={null} onCancel={closeModal}>
      <ModalInner>
        <RowContainer>
          <RowHeader>
            {t('Name')}
            <Required>*</Required>
          </RowHeader>
          <Input placeholder={t('Name of the holiday')} value={inputData.name || ''} onChange={updateName} />
        </RowContainer>
        <UpperRowContainer>
          <RowContainer>
            <RowHeader>
              {t('Date / Start date')}
              <Required>*</Required>
            </RowHeader>
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs().startOf('date') }}
              defaultValue={inputData.start_date_time ? dayjs(inputData.start_date_time) : null}
              style={{
                width: '100%',
              }}
              onChange={onStartDateChange}
              placeholder={t('Date / Start date')}
            />
          </RowContainer>
          <RowContainer style={{ width: '2%' }} />
          <RowContainer>
            <RowHeader>{t('End date')}</RowHeader>
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs().endOf('date') }}
              defaultValue={inputData.end_date_time ? dayjs(inputData.end_date_time) : null}
              style={{
                width: '100%',
              }}
              onChange={onEndDateChange}
              placeholder={t('End date')}
            />
          </RowContainer>
        </UpperRowContainer>
        <InstructionText>{t('If end date is not selected, the holiday ends at midnight.')}</InstructionText>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
            {buttonText}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default AddOrEditLocalHolidayModal;
