import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { TIME_FORMAT } from '../../../utils/constants';
import { UserContext } from '../../../context/UserContext';

import NotificationDecisionItem from './NotificationMessageDecisionItem';
import DateComponent from '../../ui/DateComponent';

const ApprovalSpan = styled.span`
  flex: auto;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 5px;
  max-width: 500px;
`;

const StyledDecision = styled.div`
  padding: ${({ theme }) => theme.sizing.gap_tiny} ${({ theme }) => theme.sizing.gap_medium};
  min-width: 360px;
  font-size: ${({ theme }) => theme.text.small};
  font-weight: ${props => (props.newItem ? 700 : 400)};
  border-radius: 0 0 4px 4px;
  background-color: ${({ theme }) => theme.color.beige};
`;

const ResolvedSpan = styled.span`
  font-weight: 400;
  font-style: italic;
  max-width: 500px;
`;

const Time = styled(DateComponent)`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-style: italic;
`;

const NotificationMessageDecisions = ({ data, newItem, messageSent }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { decision_items } = data;
  const resolvedDecision = decision_items
    .filter(e => e.response_type !== null)
    .reduce((a, b) => (a.modified_at > b.modified_at ? a : b), []);

  return (
    <StyledDecision newItem={newItem}>
      <ApprovalSpan>
        {t('Requires approval by')}
        {resolvedDecision.length !== 0 && (
          <span>
            <ResolvedSpan>{t('Resolved at')} </ResolvedSpan>
            <Time format={TIME_FORMAT} date={resolvedDecision.modified_at} />
          </span>
        )}
      </ApprovalSpan>
      {decision_items.map(value => {
        return <NotificationDecisionItem key={value.id} data={value} messageSent={messageSent} />;
      })}
    </StyledDecision>
  );
};

export default NotificationMessageDecisions;
