import React, { useContext, useRef, useState, useEffect } from 'react';
import { TIME_FORMAT, PAGINATION_LIMIT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import { v4 as uuid } from 'uuid';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Switch from 'antd/es/switch';
import Popconfirm from 'antd/es/popconfirm';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import DatePicker from 'antd/es/date-picker';

import { isPortUnikie } from '../../utils/utils';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import Select from '../ui/Select';
import Input from '../ui/Input';
import Form from '../ui/Form';
import SecretText from '../ui/SecretText';

import PageActionForm from '../page/PageActionForm';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const RegisterCodes = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  let loading = true;

  const [apiCallPending, setApiCallPending] = useState(false);
  const { loading: rolesLoading, data: rolesData, error: rolesError } = useApi('get', 'roles', {});
  const roles = rolesError || !rolesData ? [] : rolesData;

  const location = useLocation();

  if (rolesError) {
    message.error(rolesError);
  }

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  let defaultRoles = [];

  let validRoles = ['consignee', 'user', 'first_user', 'second_admin', 'admin'];
  if (!rolesLoading) {
    roles.forEach(function(role) {
      if (validRoles.includes(role.name)) {
        defaultRoles.push({
          key: role.name,
          label: role.readable_name,
          value: role.name,
        });
      }
    });
  }

  const [uniqueMessageKey, setUniqueMessageKey] = useState('');

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'created_at DESC',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: codesLoading, data: codesData, error: codesError, fetchData } = useApi(
    'get',
    'registration-codes',
    newParams
  );

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const codes = codesError || !codesData ? [] : codesData.data;

  if (codesError) {
    message.error(codesError);
  }

  const { start, total } = codesError || !codesData ? { start: 0, total: 0 } : codesData.pagination;

  if (!codesLoading && !rolesLoading) {
    loading = false;
  }

  const handleSave = async values => {
    let apiCallError = null;
    const { description, expires, role, max_uses_count } = values;
    setApiCallPending(true);
    showActions(false);
    try {
      await apiCall('post', 'registration-codes', {
        description,
        role,
        expires: expires ? dayjs(expires).toISOString() : null,
        max_uses_count: parseInt(max_uses_count),
      });
    } catch (e) {
      apiCallError = e;
    }
    values.role = 'user';
    values.description = '';
    values.expires = dayjs().add(1, 'month');
    values.max_uses_count = 1;
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleDelete = async id => {
    let apiCallError = null;
    setApiCallPending(true);
    try {
      await apiCall('delete', `registration-codes/${id}`, {});
    } catch (e) {
      apiCallError = e;
    }
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleUpdate = async (id, enabled, role, expires) => {
    let apiCallError = null;
    setApiCallPending(true);
    try {
      await apiCall('put', `registration-codes/${id}`, {
        enabled: enabled ? 1 : 0,
        role: role,
        expires: expires ? dayjs(expires).toISOString() : null,
      });
    } catch (e) {
      apiCallError = e;
    }
    setApiCallPending(false);
    if (apiCallError !== null) {
      throw apiCallError;
    }
    let params = newParams;
    await fetchData(false, params);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    values.role = 'user';
    values.description = '';
    values.expires = dayjs().add(1, 'month');
    values.max_uses_count = 1;
  };

  const copyToClipboard = text => {
    if (uniqueMessageKey !== '') {
      message.destroy(uniqueMessageKey);
    }
    setUniqueMessageKey(uuid());
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(text);
      message.success({
        key: uniqueMessageKey,
        content: 'Registration code copied to clipboard',
        duration: 3,
      });
    } else {
      window.clipboardData.setData('Text', text);
      message.success({
        key: uniqueMessageKey,
        content: 'Registration code copied to clipboard',
        duration: 3,
      });
    }
  };

  const portUnikie = isPortUnikie(namespace);

  const fields = ['description', 'role', 'expires', 'max_uses_count'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, {
    role: 'user',
    expires: dayjs().add(1, 'month'),
    max_uses_count: 1,
  });

  const columns = [
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      sortableKey: 'enabled',
      render: (text, record) => (
        <Tooltip
          title={
            (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
            (record.expires && dayjs(record.expires).isBefore(dayjs())) ||
            record.max_uses_count === record.used_count
              ? t('This register code does not work anymore.')
              : null
          }
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a' }}
        >
          <Switch
            disabled={
              portUnikie ||
              (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
              (record.expires && dayjs(record.expires).isBefore(dayjs())) ||
              record.max_uses_count === record.used_count
            }
            defaultChecked={text}
            onChange={enabled => handleUpdate(record.id, enabled, record.role, record.expires)}
          />
        </Tooltip>
      ),
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      sortableKey: 'code',
      render: text => (
        <div>
          <SecretText text={text} />
          <Button copy style={{ zIndex: '5' }} onClick={() => copyToClipboard(text)}>
            <Icon type="copy" />
          </Button>
        </div>
      ),
    },
    {
      title: t('Expires'),
      dataIndex: 'expires',
      key: 'expires',
      sortableKey: 'expires',
      render: date => (date ? <DateComponent format={TIME_FORMAT} date={date} /> : t('Not set')),
    },
    {
      title: t('Usage count'),
      key: 'used_count',
      sortableKey: 'used_count',
      render: record => {
        return `${record.used_count} / ${record.max_uses_count}`;
      },
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      sortableKey: 'description',
    },
    {
      title: t('Default role'),
      dataIndex: 'readable_name',
      key: 'readable_name',
      sortableKey: 'readable_name',
    },
    {
      title: t('Created by'),
      dataIndex: 'email',
      key: 'email',
      sortableKey: 'email',
    },
    {
      title: t('Created'),
      dataIndex: 'created_at',
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
  ];

  const actionList = [
    {
      render: record => (
        <Popconfirm
          title={t('Delete code {{code}}?', { code: record.code })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          key="action-1"
          id="pop-confirm-for-new-list"
        >
          <div>
            <ListActionButton red disabled={portUnikie}>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  if (portUnikie) {
    columns.splice(4, 0, {
      title: t('Namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
      render: namespace => namespace && <span>{namespace}</span>,
    });
  }

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: loading,
      text: t('Add new code'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <div ref={actionsRef}>
        <PageActionForm title={t('Add new code')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'role') {
                return (
                  <Select
                    value={values[field]}
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    options={defaultRoles}
                    onChange={handleChange}
                  />
                );
              } else if (field === 'expires') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Code expiration')}</InputLabel>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      style={{
                        width: '100%',
                      }}
                      value={values[field] ? dayjs(values[field]) : null}
                      onChange={value => {
                        handleChange({
                          target: {
                            value: value ? dayjs(value).toISOString() : null,
                            name: field,
                          },
                        });
                      }}
                      placeholder={t('Expires')}
                    />
                  </InputContainer>
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    onChange={handleChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link>{t('Add code')}</Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>

      <List
        rowKey="id"
        columns={columns}
        dataSource={codes}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={loading || rolesLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by description')}
        additionalButtons={additionalButtons}
        transparentBgs
        onRow={record => ({
          style: {
            backgroundColor:
              (record.email?.length > 7 && record.email.substring(record.email.length - 7) === 'deleted') ||
              (record.expires && dayjs(record.expires).isBefore(dayjs())) ||
              record.max_uses_count === record.used_count ||
              !record.enabled
                ? '#e8e8e8'
                : 'white',
          },
        })}
      />
    </>
  );
};

export default RegisterCodes;
