import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { FleetFilteringContext } from '../../context/FleetFilteringContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import SmallPageSearch from '../page/SmallPageSearch';
import { debounce } from 'throttle-debounce';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizing.gap};
`;

const FleetFilterSearch = () => {
  const { namespace } = useContext(UserContext);
  const { fleetQuickSearchText, setFleetQuickSearchText } = useContext(FleetFilteringContext);

  const { t } = useTranslation(namespace);

  const [fleetSearchField, setFleetSearchField] = useState(fleetQuickSearchText);

  const doSet = useCallback(
    value => {
      setFleetQuickSearchText(value);
    },
    [setFleetQuickSearchText]
  );

  const debouncedSet = useMemo(() => debounce(800, doSet), [doSet]);

  const handleFleetSearchChange = e => {
    e.preventDefault();
    setFleetSearchField(e.target.value);

    debouncedSet(e.target.value);
  };

  return (
    <Container>
      <SmallPageSearch placeholder={t('Search')} value={fleetSearchField} onChange={handleFleetSearchChange} />
    </Container>
  );
};

export default FleetFilterSearch;
