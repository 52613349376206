import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import { WikiContext } from '../../context/WikiContext';
import Icon from '../ui/Icon';
import MarkDownHandler from './MarkDownHandler';

const Container = styled.div`
  width: 100%;
  min-height: ${props => (props.canBeSmall ? '100px' : 'calc(100vh - 500px)')};

  textarea::selection {
    background-color: rgba(73, 144, 221, 0.3);
  }
`;

const SaveRow = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
`;

const StyledButtonLight = styled(ButtonLight)`
  display: flex;
  justify-content: center;
  margin-left: 12px;

  i {
    top: -4px;
  }
`;

const EditorContainer = styled.div`
  margin: 6px 0;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: calc(100% - 100px);

  @media (max-width: 500px) {
    flex-flow: wrap;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputTitle = styled.div`
  margin-right: 4px;
  margin-top: 5px;
  font-weight: 600;
  white-space: nowrap;
`;

const InfoText = styled.div`
  margin-top: 5px;
  margin-left: 6px;
  cursor: default;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  color: ${({ theme }) => theme.color.secondary};
  margin-right: 6px;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    margin-top: 8px;
  }
`;

const StyledH4 = styled.h4`
  font-size: 14px !important;
  display: flex !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 3px !important;
  margin-top: 24px;
`;

const StyledH6 = styled.h6`
  font-size: 12px !important;
  margin: 6px 0 0 0 !important;
  display: flex !important;
  font-weight: 600 !important;
  color: #949494 !important;
`;

const MarkDownEditor = ({
  markdownId,
  markdownAnchor,
  newWiki,
  setStartLoader,
  startLoader,
  editModeOn,
  setEditModeOn,
  objectType,
  objectId,
  coordinates,
  shortText,
  setModalTitle,
  setAnchorTitle,
  setEditTitle,
  shouldRefetchData,
  setShouldRefetchData,
  addNewOpen,
  setAddNewOpen,
  removePreviousLink,
  editable = true,
  canBeSmall = false,
  noAnchors = false,
  refetchWikiData,
  backUpId,
}) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { fetchWikiData, fetchWikiLinkData, availableWikiLinks } = useContext(WikiContext);

  const [markDownData, setMarkDownData] = useState(null);
  const [wikiId, setWikiId] = useState(null);
  const [title, setTitle] = useState('');

  const [editValue, setEditValue] = useState('### Title example\nText example');

  const markdownIdRef = useRef();
  const addNewRef = useRef();

  const refetchData = async () => {
    if (setStartLoader) {
      setStartLoader(true);
    }

    if (markdownId) {
      let result;
      try {
        result = await apiCall('get', 'wiki/get', { id: markdownId });
      } catch (e) {
        setMarkDownData(null);
        if (setStartLoader) {
          setStartLoader(false);
        }
      }
      if (result?.data && result.status === 200) {
        setMarkDownData(result.data.markdown.text);
        setTitle(result.data.markdown.title);
        if (setModalTitle) {
          setModalTitle(result.data.markdown.title);
        }
        setWikiId(result.data.markdown.id);
        setEditValue(result.data.markdown.text);
      }
    }
    await fetchWikiData();
    await fetchWikiLinkData();

    if (setStartLoader) {
      setStartLoader(false);
    }
  };

  useEffect(() => {
    if (shouldRefetchData) {
      setShouldRefetchData(false);
      refetchData();
    }
  }, [shouldRefetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!markdownIdRef.current && markdownId) {
      refetchData();
    }
  }, [markdownId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addNewOpen && !addNewRef.current) {
      setMarkDownData(null);
      setTitle('');
      if (setModalTitle) {
        setModalTitle(null);
      }
      setWikiId(null);
      setEditValue('### Title example\nText example');
    }
    addNewRef.current = addNewOpen;
  }, [addNewOpen, markdownId, setModalTitle]);

  const InstructionTooltip = () => {
    return (
      <div style={{ maxWidth: '300px' }}>
        <div style={{ marginBottom: '12px' }}>
          {t(
            'Start writing on the left side of the editor and you will see the preview of the text on the right. In the top panel you can see some additional types of text you can add to the document.'
          )}
        </div>
        <div style={{ marginBottom: '12px' }}>
          {t('When you have finished editing, remember to save your changes.')}
        </div>
        <div style={{ marginBottom: '12px' }}>
          {t(
            'The linking between items and the information text is done by titles, so changing those can result in missing links.'
          )}
        </div>
      </div>
    );
  };

  const saveEdits = async () => {
    if (!wikiId) {
      if (removePreviousLink) {
        await removePreviousLink();
      }
      const result = await apiCall('post', 'wiki/add-and-link', {
        title,
        text: editValue,
        object_type: objectType,
        object_id: objectId,
        lat: coordinates?.lat,
        lon: coordinates?.lng,
      });

      if (result?.status == 200 && backUpId) {
        const backupWiki = availableWikiLinks.find(w => w.object_type === objectType && w.object_id === backUpId);
        if (!backupWiki) {
          await apiCall('post', 'wiki/link', {
            markdown_id: result?.data?.model?.id,
            object_type: objectType,
            object_id: backUpId,
            markdown_anchor: undefined,
            lat: undefined,
            lon: undefined,
            short_text: undefined,
          });
        }
      }

      if (setAddNewOpen) {
        setAddNewOpen(false);
      }
      refetchData();
    } else {
      await apiCall('put', 'wiki/update', {
        title,
        text: editValue,
        id: wikiId,
      });
      refetchData();
    }
    if (setEditModeOn) {
      setEditModeOn(false);
    }
    if (refetchWikiData) {
      refetchWikiData();
    }
  };

  const discardChanges = () => {
    setEditValue(markDownData);
    if (setEditModeOn) {
      setEditModeOn(false);
    }
    if (setAddNewOpen) {
      setAddNewOpen(false);
      refetchData();
    }
  };

  const footnote = {
    name: 'footnote',
    keyCommand: 'footnote',
    buttonProps: { 'aria-label': 'Insert footnote' },
    icon: (
      <svg viewBox="0 0 24 24" width="12" height="12">
        <path d="M2 3h20" fill="transparent" stroke="currentColor" strokeWidth="3" />
        <path d="M2 10h18" fill="transparent" stroke="currentColor" strokeWidth="3" />
        <text x="2" y="21" className="small-icon-font">
          NOTE
        </text>
      </svg>
    ),
    execute: (state, api) => {
      let modifyText = `###### ${state.selectedText}\n`;
      if (!state.selectedText) {
        modifyText = '###### ';
      }
      api.replaceSelection(modifyText);
    },
  };

  return (
    <>
      <Container canBeSmall={canBeSmall}>
        {startLoader ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : editable && (addNewOpen || editModeOn || !markDownData) ? (
          <>
            <EditorContainer>
              <TitleRow>
                <InputContainer>
                  <InputTitle>{t('Title for the page')}:</InputTitle>
                  <Tooltip
                    title={
                      objectType
                        ? t(
                          'This is a title for the whole page consisting of multiple items of the same type not just for one item. For example the type of this item is {{type}}.',
                          { type: objectType.replace(/_/g, ' ') }
                        )
                        : t(
                          'This is a title for the whole page consisting of multiple items of the same type not just for one item.'
                        )
                    }
                    color="white"
                    placement="bottom"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px', maxWidth: '200px' }}
                  >
                    <IconContainer>
                      <Icon type="circle-question-regular"></Icon>
                    </IconContainer>
                  </Tooltip>
                  <Input
                    placeholder={t('Wiki page title')}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    style={{ width: '360px', maxWidth: '80%', color: '#4a4a4a' }}
                  />
                </InputContainer>
                <Tooltip
                  title={InstructionTooltip()}
                  color="white"
                  placement="bottomRight"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                >
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                    <InfoText>{t('Info')}</InfoText>
                  </IconContainer>
                </Tooltip>
              </TitleRow>
              <MDEditor
                value={editValue}
                onChange={setEditValue}
                height={window.innerHeight - 426}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                  components: {
                    h4: props => {
                      return <StyledH4>{props.children}</StyledH4>;
                    },
                    h6: props => {
                      return <StyledH6>{props.children}</StyledH6>;
                    },
                  },
                }}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  commands.group([commands.title1, commands.title2, commands.title3], {
                    name: 'title',
                    groupName: 'title',
                    buttonProps: { 'aria-label': 'Insert title' },
                  }),
                  footnote,
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                  commands.checkedListCommand,
                ]}
                extraCommands={[commands.codeEdit, commands.codeLive, commands.codePreview]}
              />
            </EditorContainer>
            <SaveRow>
              {!newWiki && (
                <StyledButtonLight type="button" cancel onClick={() => discardChanges()}>
                  {t('Discard changes')}
                </StyledButtonLight>
              )}
              <StyledButtonLight disabled={!title} type="button" onClick={() => saveEdits()}>
                {t('Save')}
              </StyledButtonLight>
            </SaveRow>
          </>
        ) : (
          <MarkDownHandler
            markDownData={markDownData}
            markdownAnchor={markdownAnchor}
            editable={editable}
            noAnchors={noAnchors}
            setAnchorTitle={setAnchorTitle}
            objectType={objectType}
            objectId={objectId}
            coordinates={coordinates}
            shortText={shortText}
            markdownId={markdownId}
            setEditTitle={setEditTitle}
          />
        )}
      </Container>
    </>
  );
};

export default MarkDownEditor;
