import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';
import PortCallInvoices from '../components/invoicing/PortCallInvoices';
import { InvoicingContext } from '../context/InvoicingContext';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
  height: 100%;
  box-shadow: none;
`;

const InvoicesContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InvoicingInvoicesView = () => {
  const { getStartData } = useContext(InvoicingContext);
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    getStartData();
    document.title = 'Invoices | Port Activity App';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pagename={t('Invoices')}>
      <AlteredPage fullWidth>
        <InvoicesContainer>
          <PortCallInvoices fullView />
        </InvoicesContainer>
      </AlteredPage>
    </Layout>
  );
};

export default InvoicingInvoicesView;
