import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spin from 'antd/es/spin';

import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import Popup from '../components/ui/Popup';

import Form from '../components/ui/Form';
import useRegister from './useRegister';
import { UserContext } from '../context/UserContext';
import useOnClickOutside from '../hooks/useOnClickOutside';
import GdprPopup from '../components/landingPage/GdprPopup';

const StyledForm = styled(Form)`
  position: relative;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.sizing.gap};
  max-width: 420px;

  input,
  button,
  select {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
  min-width: 40px;
  @media (min-width: 1000px) {
    min-width: 100px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const GdprConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const GdprConfirmationNew = styled(GdprConfirmation)`
  justify-content: center;
`;

const GdprCheckbox = styled.input`
  width: 30px !important;
`;

const ErrorContainer = styled.div`
  height: 20px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.warning};
`;

const RegisterContainer = styled.span`
  box-shadow: 4px 4px 8px rgb(0 0 0 / 45%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-width: 300px;
  min-height: 250px;
  position: absolute;
  z-index: 50;
  top: 100px;
  right: 0px;
  background-color: ${({ theme }) => theme.color.primary};
  @media (min-width: 768px) {
    right: 20px;
  }
`;

const ErrorMessage = styled.div`
  display: block;
`;

const GdprInfoText = styled.div`
  text-align: center;
  margin-bottom: 12px;
  width: 400px;
`;

const Register = ({ withoutCode }) => {
  const { namespace, registerLoader, showRegisterLoader } = useContext(UserContext);

  const { handleRegister, info, setInfo, disabled } = useRegister();
  const { t } = useTranslation(namespace);

  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [gdprPopupOpen, setGdprPopupOpen] = useState(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);

  useOnClickOutside(ref, () => {
    if (gdprPopupOpen) {
      setGdprPopupOpen(isOpen);
    } else {
      setInfo({ ...info, checked: false, firstName: '', lastName: '', code: '', email: '', password: '', confirm: '' });
      setIsOpen(false);
    }
  });

  const handleChecked = () => {
    setInfo({ ...info, checked: !info.checked });
  };

  const handleCheckedTrue = () => {
    setInfo({ ...info, checked: true });
  };

  const handleRegistering = async e => {
    showRegisterLoader(true);
    const res = await handleRegister(e);
    if (res?.['action-required']) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (info.password !== '' && info.confirm !== '' && info.password !== info.confirm) {
      setPasswordsDoNotMatch(true);
    } else {
      setPasswordsDoNotMatch(false);
    }
  }, [info.password, info.confirm]);

  return (
    <span ref={ref}>
      <span>
        <StyledButton onClick={() => setIsOpen(!isOpen)}>{t('Register')}</StyledButton>
      </span>
      {isOpen && (
        <RegisterContainer>
          <StyledForm onSubmit={handleRegistering} autoComplete="off">
            <Input
              label={t('First name')}
              name={t('First name')}
              type="text"
              onChange={e => setInfo({ ...info, firstName: e.target.value })}
              autoFocus
              required
            />
            <Input
              label={t('Last name')}
              name={t('Last name')}
              type="text"
              onChange={e => setInfo({ ...info, lastName: e.target.value })}
              required
            />
            {!withoutCode && (
              <Input
                label={t('Code')}
                name={t('Code')}
                type="text"
                onChange={e => setInfo({ ...info, code: e.target.value })}
                required
              />
            )}
            <Input
              label={t('Email / Username')}
              name={t('Email')}
              type="text"
              onChange={e => setInfo({ ...info, email: e.target.value })}
              required
            />
            <Input
              label={t('Password')}
              name={t('Password')}
              type="password"
              onChange={e => setInfo({ ...info, password: e.target.value })}
              required
            />
            <Input
              label={t('Confirm password')}
              name={t('Confirm')}
              type="password"
              onChange={e => setInfo({ ...info, confirm: e.target.value })}
              required
            />
            {/* If swedish version wants to take new terms and conditions into use, this needs to be changed. */}
            {document.location.hostname === 'notavailable' ? (
              <>
                <GdprConfirmationNew>
                  <GdprPopup
                    isOpen={gdprPopupOpen}
                    setIsOpen={setGdprPopupOpen}
                    handleChecked={handleCheckedTrue}
                    checked={info.checked}
                    orig={true}
                  />
                </GdprConfirmationNew>
                {info.checked ? (
                  <GdprInfoText>{t('The terms and conditions have been accepted.')}</GdprInfoText>
                ) : (
                  <GdprInfoText>
                    {t('The terms and conditions need to be read through and accepted before continuing.')}
                  </GdprInfoText>
                )}
              </>
            ) : (
              <GdprConfirmation>
                <GdprCheckbox type="checkbox" onChange={handleChecked} />
                {t('I accept the Terms and Conditions')}
                <Popup isOpen={gdprPopupOpen} setIsOpen={setGdprPopupOpen} />
              </GdprConfirmation>
            )}
            <Spin spinning={registerLoader}>
              <Button disabled={disabled}>{t('Register')}</Button>
            </Spin>
            <ErrorContainer>
              {passwordsDoNotMatch && <ErrorMessage>{t('Passwords do not match!')}</ErrorMessage>}
            </ErrorContainer>
          </StyledForm>
        </RegisterContainer>
      )}
    </span>
  );
};

export default Register;
