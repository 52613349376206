export const gdprMarkdownData = `### PRIVACY NOTICE 
#### 1. Introduction
At UNIKIE, we are committed to protecting the privacy and security of your personal data. This Privacy Notice explains how we collect, use, process, store, disclose and protect your personal data in accordance with the EU General Data Protection Regulation (GDPR). 

Before registering and using the POLO Application, we encourage you to read this notice carefully to understand our practices regarding your personal data on the part of and relating to POLO Application. To register your account and to use the POLO Application, you will be prompted below to provide your explicit consent to the collection and processing of your personal data inaccordance with this Privacy Notice.

#### 2. Type of personal data collected
The types of personal data we may collect when you register your account and use the POLO
Application include:

* your name;
* your email address;
* your password to the POLO Application;
* your set user level;
* IP address;
* device and system information (such as device model, operating system, browser version, browser local storage information (incl. functionality cookies);
* usage information (such as activity logs, features accessed, interactions, browser header information) and notices regarding new application features.

#### 3. Use of personal data
We may use your Personal data for the following purposes:

* to create and manage your user account on the POLO Application;
* to authenticate your access and set user level and to provide personalized features;
* to provide and operate the POLO Application;
* to analyse and monitor user activity for security purposes;
* to improve functionality of the POLO Application;
* to communicate with you and respond to your inquiries or requests; 
* and to send you promotional information regarding new application features.

#### 4. Legal basis for collection and use of data
Most of the data we collect you will provide us directly yourself, or the data is collected when you use the POLO Application.
We collect and use your personal data in accordance with this Privacy Notice mainly based on your explicit consent, which you are prompted to provide to us below. The other possible legal
bases for processing your personal data include:

* contractual necessity: the processing is necessary for the performance of a contract between you and us; or
* legal obligation: the processing is necessary to comply with a legal obligation imposed to us.

#### 5. Retention of your data
We will retain your personal data for as long as:

* you have a registered account and authorization on the POLO Application;
* it is necessary to fulfil the purposes outlined in this Privacy Notice; or
* it is required by applicable laws and regulations.

Once we have no basis for retaining your personal data, it will be securely deleted or anonymized
(i.e. information which does not relate to an identified or identifiable natural person or personal
data rendered anonymous in such a manner that the data subject is not or no longer identifiable).

#### 6. Data sharing and disclosure
We may share/disclose your personal data to third parties in the following circumstances:

* with your consent or at your directions;
* to our service providers who assist us in providing and operating the POLO Application (such as storage service providers); and
* to regulatory authorities, law enforcement agencies and other third parties if required by law or to protect our legitimate rights property and safety.

In addition, in the event of a merger, acquisition, or transfer of our business assets relating to the
POLO Application, your personal data may be transferred to the acquiring entity.

#### 7. Storage and transfer to third countries
Your personal data will be stored and processed within EU and will not be transferred to third
countries outside EU.

#### 8. Your rights 
You have the right to:

* request a copy of your personal data we hold (we may charge a small fee for this service);
* request correction or complementation of any inaccurate or incomplete personal data;
* request erasure of your personal data, under certain conditions;
* request that we restrict the processing of your personal data, under certain conditions;
* object to our processing of your personal data, under certain conditions;
* request that we transfer your personal data we have collected to another organization, or directly to you, under certain conditions;
* withdraw you consent for processing of your personal data at any time, if the legal basis for
processing is consent; and
* lodge a complaint with the competent data protection authorities.

Please notice that if you request erasure of your personal data, we may not be able to provide you
access to the POLO Application or guarantee functioning of all features.

#### 9. Automated decision-making and profiling
Personal data collected from you in accordance with this Privacy Notice will not be used for
automated decision-making or profiling.

#### 10. Contact Information
If you have any concerns or requests regarding the handling of your Personal data or if you wish to
exercise your rights, please contact us at polo.support@unikie.com.

#### 11. Data security
We implement appropriate technical and organizational measures to protect personal data from
unauthorized access, alteration, disclosure, or destruction. We regularly review and update our
security measures to ensure the ongoing confidentiality, integrity, and availability of personal data.

### YOUR CONCENT
By clicking **I Accept the Terms and Conditions**, you confirm that that you have read and understood the above Privacy Notice
and give your explicit consent to the collection and processing of your personal data as described in the
Privacy Notice and that you understand that you have the right to withdraw your consent at any time.`;
