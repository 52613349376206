import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spin from 'antd/es/spin';

import Input from '../components/ui/Input';
import Button from '../components/ui/Button';

import Form from '../components/ui/Form';
import useLogin from './UseLogin';
import ForgotPasswordV2 from './ForgotPasswordV2';
import { UserContext } from '../context/UserContext';
import Register from './Register';

const StyledForm = styled(Form)`
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: ${({ theme }) => theme.sizing.gap_small};
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
  margin-bottom: 0;
  min-width: 40px;
  @media (min-width: 1000px) {
    margin-right: ${({ theme }) => theme.sizing.gap};
    min-width: 250px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
  min-width: 40px;
  @media (min-width: 1000px) {
    min-width: 100px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const FormContainer = styled.span`
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: 0;
`;

const LoginContainer = styled.div`
  width: 170px;
`;

const LoginLink = styled.a`
  /*float: right;*/
  margin-top: -${({ theme }) => theme.sizing.gap_small};
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const Login = () => {
  const {
    currentAuthView,
    handleSubmit,
    disabled,
    namespace,
    credentials,
    setCredentials,
    codeSubmitDisabled,
    handleSubmitCode,
    setCurrentAuthView,
  } = useLogin();
  const { loginLoader, showLoginLoader } = useContext(UserContext);

  const { i18n } = useTranslation(namespace);
  const t = i18n.getFixedT(i18n.language, namespace);

  const preHandleSubmit = e => {
    handleSubmit(e);
    showLoginLoader(true);
  };

  const preHandleSubmitCode = e => {
    handleSubmitCode(e);
    showLoginLoader(true);
  };

  const codeMethod = currentAuthView.view === 'EMAIL_CODE_LOGIN' ? 'email' : '';

  return currentAuthView.view === 'EMAIL_CODE_LOGIN' ? (
    <span>
      <FormContainer>
        <LoginContainer>
          <LoginLink onClick={() => setCurrentAuthView('LOGIN')}>
            {t('Did not receive a code? Click here to try again')}
          </LoginLink>
        </LoginContainer>
        <StyledForm onSubmit={preHandleSubmitCode}>
          <StyledInput
            label={t('Please enter the 7 digit verification code found in your {{method}}', { method: codeMethod })}
            name={t('Code')}
            type="password"
            value={credentials.code}
            onChange={e => setCredentials({ ...currentAuthView.params, code: e.target.value.trim() })}
            inline
            wrapperStyle={{ width: 'min-content' }}
          />
          <Spin spinning={loginLoader}>
            <StyledButton disabled={codeSubmitDisabled}>{t('Verify')}</StyledButton>
          </Spin>
        </StyledForm>
      </FormContainer>
    </span>
  ) : (
    <span>
      <FormContainer>
        <StyledForm onSubmit={preHandleSubmit}>
          <StyledInput
            label={t('Username / Email')}
            name={t('Email')}
            type="text"
            value={credentials.username}
            onChange={e => setCredentials({ ...credentials, username: e.target.value.trim() })}
            autoFocus
            inline
          />
          <StyledInput
            label={t('Password')}
            name={t('Password')}
            type="password"
            value={credentials.password}
            onChange={e => setCredentials({ ...credentials, password: e.target.value.trim() })}
            inline
          />
          <Spin spinning={loginLoader}>
            <StyledButton disabled={disabled}>{t('Login')}</StyledButton>
          </Spin>
        </StyledForm>
        <Register />
      </FormContainer>
      <div>
        <ForgotPasswordV2 />
      </div>
    </span>
  );
};

export default Login;
