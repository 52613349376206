import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FeatureGroup, useMap } from 'react-leaflet';
import L from 'leaflet';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import CircleMapToolContainer from './CircleMapToolContainer';

// TODO:
// The polyline drawings are shown as straight lines without taken into account the earth's curvature
// At some point we could add geodesic lines
// Check Leaflet.Geodesic

const CircleToolContainer = styled.div`
  background: ${props => (props.enabled ? props.theme.color.secondary : props.theme.color.white)};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  padding: 4px 8px;

  &:hover {
    background: ${props => (props.enabled ? '#4990ee' : '#f4f4f4')};
  }

  svg {
    height: 18px;
    width: 18px;
    margin-left: -2px;
  }
`;

const CircleTool = styled.div`
  position: absolute;
  bottom: 170px;
  right: 10px;
  z-index: 100;
  color: #4a4a4a;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
`;

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawCreated: 'draw:created',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onMousemove: 'mousemove',
  onClick: 'click',
};

export const CircleMapTool = ({ circleDrawing, setCircleDrawing }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [drawing, setDrawing] = useState(false);
  const [editing, setEditing] = useState(false);

  const [center, setCenter] = useState(null);
  const [radius, setRadius] = useState(0);
  const [angle, setAngle] = useState(0);
  const [location, setLocation] = useState(null);

  const [drawingFinished, setDrawingFinished] = useState(false);

  const [cursorLocation, setCursorLocation] = useState(null);

  const map = useMap();

  const onCursorLocationChange = evt => {
    setCursorLocation(evt.latlng);
  };

  L.drawLocal.edit.handlers.edit.tooltip.subtext = t('Location edit');

  useEffect(() => {
    if (drawing) {
      map.on(eventHandlers.onMousemove, onCursorLocationChange);
    } else {
      map.off(eventHandlers.onMousemove);
    }
  }, [drawing]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FeatureGroup>
        <CircleMapToolContainer
          circleDrawing={circleDrawing}
          setDrawing={setDrawing}
          setCursorLocation={setCursorLocation}
          setEditing={setEditing}
          editing={editing}
          drawing={drawing}
          setCircleDrawing={setCircleDrawing}
          cursorLocation={cursorLocation}
          center={center}
          setCenter={setCenter}
          radius={radius}
          setRadius={setRadius}
          angle={angle}
          setAngle={setAngle}
          drawingFinished={drawingFinished}
          setDrawingFinished={setDrawingFinished}
          location={location}
          setLocation={setLocation}
        />
      </FeatureGroup>
      <CircleTool onClick={() => setCircleDrawing(!circleDrawing)}>
        <Tooltip
          title={circleDrawing ? t('Finish drawing') : t('Draw circle')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          placement="left"
        >
          <CircleToolContainer enabled={circleDrawing}>
            <Icon type="circle-tool" />
          </CircleToolContainer>
        </Tooltip>
      </CircleTool>
    </>
  );
};

export default CircleMapTool;
