import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';

const BG = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 55px);
  z-index: 100;
  background-color: rgba(240, 240, 240, 0.65);
`;

const Confirmation = styled.div`
  position: absolute;
  padding: 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-size: 13px;
  max-width: 300px;
  text-align: center;
  top: 40px;
  left: calc((100% - 300px) / 2);
`;

const InfoText = styled.div`
  margin-bottom: 4px;
  padding: 8px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  text-align: left;
  cursor: default;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const HelpText = styled.div`
  margin-top: 24px;
  text-align: left;
`;

const ShortText = styled.div`
  textarea {
    resize: none;
    height: 94px;
  }
`;

const Button = styled(ButtonLight)`
  height: 26px;
  line-height: 22px;
  padding: 0;
`;

const { TextArea } = Input;

const LinkToThisHeader = ({ anchorTitle, refetchData, setAnchorTitle }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [shortText, setShortText] = useState(anchorTitle.shortText || anchorTitle.title || '');

  const anchorWiki = async () => {
    await apiCall('post', 'wiki/link', {
      markdown_id: anchorTitle.markdownId,
      object_type: anchorTitle.objectType,
      object_id: anchorTitle.objectId,
      markdown_anchor: anchorTitle.titleKey,
      lat: anchorTitle.coordinates?.lat,
      lon: anchorTitle.coordinates?.lng,
      short_text: shortText,
    });
    setAnchorTitle(null);
    refetchData();
  };

  const deanchorWiki = async () => {
    await apiCall('post', 'wiki/link', {
      markdown_id: anchorTitle.markdownId,
      object_type: anchorTitle.objectType,
      object_id: anchorTitle.objectId,
      lat: anchorTitle.coordinates?.lat,
      lon: anchorTitle.coordinates?.lng,
      markdown_anchor: '',
      short_text: '',
    });
    setAnchorTitle(null);
    refetchData();
  };

  return (
    <BG>
      <Confirmation>
        {anchorTitle.anchored
          ? t('Do you want to remove the item anchor from {{title}}?', { title: anchorTitle.title })
          : t('Do you want to anchor the item to {{title}}?', { title: anchorTitle.title })}
        {anchorTitle.anchored ? (
          <ShortText>
            <HelpText>{t('Short description')}</HelpText>
            <InfoText>{shortText}</InfoText>
          </ShortText>
        ) : (
          <ShortText>
            <HelpText>
              {t('You can add a short description for this item here and that text will be shown in the tooltip.')}
            </HelpText>
            <TextArea value={shortText} onChange={e => setShortText(e.target.value)} />
          </ShortText>
        )}
        <Buttons>
          <Button cancel type="button" onClick={() => setAnchorTitle(null)}>
            {t('No')}
          </Button>
          <Button type="button" onClick={anchorTitle.anchored ? deanchorWiki : anchorWiki}>
            {t('Yes')}
          </Button>
        </Buttons>
      </Confirmation>
    </BG>
  );
};

export default LinkToThisHeader;
