import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';

import { UserContext } from '../../../context/UserContext';

import ButtonLight from '../../ui/ButtonLight';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';

const ModalInner = styled.div`
  position: relative;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 466px;
`;

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const DarkerBackground = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.color.grey_lighter3};
  margin-right: 2px;
  padding: 4px 12px;
  display: flex;
  padding-bottom: ${props => props.last && '12px'};
  padding-top: ${props => props.first && '12px'};
`;

const DarkerHeader = styled(DarkerBackground)`
  justify-content: center;
  font-weight: bold;
  display: flex;
`;

const LighterBackground = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  padding: 4px 12px;
  display: flex;
  padding-bottom: ${props => props.last && '12px'};
  padding-top: ${props => props.first && '12px'};
`;

const LighterHeader = styled(LighterBackground)`
  justify-content: center;
  font-weight: bold;
  display: flex;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-bottom: -4px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    margin-bottom: 0px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 358px;
`;

const DataSetContainer = styled.div`
  margin-bottom: 12px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const NameRow = styled.div`
  margin-left: 12px;
`;

const DataContainer = styled.div`
  display: block;
`;

const HeaderText = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-bottom: 3px;
  text-transform: capitalize;
  font-weight: 600;
`;

const DataText = styled.div`
  margin-left: 12px;
  margin-bottom: 3px;
`;

const PublishModal = ({ assetDataHistory, assetData, closeModal }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { globalSaveFunction, typeData } = useContext(GeoAssetToolContext);

  const sendData = async () => {
    globalSaveFunction();
    closeModal();
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const isObject = object => {
    return object != null && typeof object === 'object';
  };

  function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  const origData = assetDataHistory && assetDataHistory.length > 0 ? assetDataHistory[0] : [];

  const compared = origData.map(asset => {
    const currentData = assetData.find(d => d.geo_asset_id === asset.geo_asset_id);
    if (!currentData) {
      return {
        changes: [],
        name: asset.name,
        deleted: true,
        geo_asset_id: asset.geo_asset_id,
      };
    } else {
      const keys = Object.keys(asset);

      const changes = keys.map(key => {
        if (key === 'geo_data' || key === 'properties' || key === 'style') {
          if (deepEqual(currentData[key], asset[key])) {
            return null;
          } else {
            return {
              label: key === 'type_id' ? 'type' : key,
              object: true,
              values: {
                old: key === 'type_id' ? typeData[asset[key]]?.name : asset[key],
                new: key === 'type_id' ? typeData[currentData[key]]?.name : currentData[key],
              },
            };
          }
        } else {
          if (asset[key] === currentData[key]) {
            return null;
          } else {
            return {
              label: key === 'type_id' ? 'type' : key,
              object: false,
              values: {
                old: key === 'type_id' ? typeData[asset[key]]?.name : asset[key],
                new: key === 'type_id' ? typeData[currentData[key]]?.name : currentData[key],
              },
            };
          }
        }
      });
      return {
        changes: changes.filter(c => !!c),
        name: asset.name,
        geo_asset_id: asset.geo_asset_id,
      };
    }
  });

  return (
    <Modal title={t('Publish changes')} visible width={800} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <HeaderRow>
          <DarkerHeader>{t('Previous')}</DarkerHeader>
          <LighterHeader>{t('New changes')}</LighterHeader>
        </HeaderRow>
        <ScrollContainer>
          {!!compared &&
            compared.map(d =>
              !d ? null : (
                <DataSetContainer key={d.geo_asset_id} open={d.changes.length > 0 || d.deleted}>
                  <NameRow>{d.name}</NameRow>
                  <DataContainer>
                    {d.deleted && (
                      <Row>
                        <DarkerBackground last={true} first={true}>
                          <HeaderText> </HeaderText>
                        </DarkerBackground>
                        <LighterBackground last={true} first={true}>
                          <DataText style={{ marginLeft: '0px' }}>{t('Asset is deleted.')}</DataText>
                        </LighterBackground>
                      </Row>
                    )}
                    {!d.deleted &&
                      d.changes.map((change, i) => (
                        <Row key={i}>
                          {change.object ? (
                            <DarkerBackground last={i === d.changes.length - 1} first={i === 0}>
                              <HeaderText>{change.label.replace(/[,_]/g, ' ')}</HeaderText>
                            </DarkerBackground>
                          ) : (
                            <DarkerBackground last={i === d.changes.length - 1} first={i === 0}>
                              <HeaderText>{change.label.replace(/[,_]/g, ' ')}:</HeaderText>
                              <DataText>{change.values.old}</DataText>
                            </DarkerBackground>
                          )}
                          {change.object ? (
                            <LighterBackground last={i === d.changes.length - 1} first={i === 0}>
                              <DataText style={{ marginLeft: '0px' }}>
                                {change.label.slice(-1) === 's'
                                  ? t('{{key}} have changed.', {
                                    key: change.label.charAt(0).toUpperCase() + change.label.slice(1).replace(/[,_]/g, ' '),
                                  })
                                  : t('{{key}} has changed.', {
                                    key: change.label.charAt(0).toUpperCase() + change.label.slice(1).replace(/[,_]/g, ' '),
                                  })}
                              </DataText>
                            </LighterBackground>
                          ) : (
                            <LighterBackground last={i === d.changes.length - 1} first={i === 0}>
                              <HeaderText>{change.label.replace(/[,_]/g, ' ')}:</HeaderText>
                              <DataText>{change.values.new}</DataText>
                            </LighterBackground>
                          )}
                        </Row>
                      ))}
                  </DataContainer>
                </DataSetContainer>
              )
            )}
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight onClick={sendData}>{t('Publish')}</ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default PublishModal;
