import React, { useContext } from 'react';

import { FleetViewContext } from '../../../context/FleetViewContext';
import VesselSidePanel from '../VesselSidePanel';

const VesselSidePanelContainer = () => {
  const { sideModalOpen, setSideModalOpen, otherNamespaceData, visibleTimeStart } = useContext(FleetViewContext);

  return (
    <VesselSidePanel
      sideModalOpen={sideModalOpen}
      setSideModalOpen={setSideModalOpen}
      otherNamespaceData={otherNamespaceData}
      startTime={visibleTimeStart}
    />
  );
};

export default VesselSidePanelContainer;
