import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-height: 300px;
  border: 2px solid ${props => props.color};
  border-radius: 3px;
  background-color: white;
  text-align: center;
`;

const Header = styled.div`
  background-color: ${props => props.color};
  padding: 12px;
  width: 100%;
  font-size: 26px;
  font-weight: 700;

  @media (max-width: 650px) {
    font-size: 20px;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
  font-size: 20px;
  background-color: white;

  @media (max-width: 650px) {
    padding: 6px;
  }
`;

const WeekDays = styled.div`
  text-transform: capitalize;
  font-size: 16px;
`;

const Hours = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const SummaryItem = ({ data, color }) => {
  return (
    <Container color={color}>
      <Header color={color}>{data.price}€</Header>
      <ContentContainer>
        {data.periods.map((period, index) => (
          <div key={index}>
            <WeekDays>{`${period.fromDay} - ${period.toDay}`}</WeekDays>
            <Hours>{`${('0' + period.startTime).slice(-2)} - ${('0' + period.endTime).slice(-2)}`}</Hours>
          </div>
        ))}
      </ContentContainer>
    </Container>
  );
};

export default SummaryItem;
