import React, { useState } from 'react';

import MapMarker from './MapMarker';
import MapVessel from './MapVessel';
import FleetMapMarker from './FleetMapMarker'; // eslint-disable-line
import MapCurrentCrane from './MapCurrentCrane';
import CurrentMapVessel from './CurrentMapVessel';

const useMap = ({
  vessels,
  markers,
  cranes,
  appliedZoom,
  isPublicMap,
  fleetData,
  clickPort,
  selectedPort,
  fleetViewOn,
  showVessels,
  deselectPort,
  landing = false,
  fleetCardsOn,
  setSideModalOpen,
  sideModalOpen,
  cranesUpdated,
  selectedPortVessel,
  setSelectedPortVessel,
  timestamps,
  vesselsState,
}) => {
  const [draRoute, setDraRoute] = useState(0);
  const [draColor, setDraColor] = useState('gray');

  const setRoute = (imo, color) => {
    setDraRoute(imo);
    setDraColor(color);
  };

  const createVesselsOverlay = vessels => {
    if (fleetViewOn) {
      if (vessels === undefined) {
        return {
          newVesselOverlay: null,
        };
      }
      let imos = [];
      let selectedImos = [];
      let combinedFleetData = [];

      fleetData.forEach(port => {
        port.expected.forEach(vessel => {
          imos.push(vessel.imo);
          combinedFleetData.push(vessel);
        });
        port.in_port.forEach(vessel => {
          imos.push(vessel.imo);
          combinedFleetData.push(vessel);
        });

        if (selectedPort === port.port_name) {
          port.expected.forEach(vessel => selectedImos.push(vessel.imo));
          port.in_port.forEach(vessel => selectedImos.push(vessel.imo));
        }
      });

      let showableVessels = vessels?.filter(vessel => imos.findIndex(v => vessel.properties.imo === v) > -1) || [];

      return {
        newVesselOverlay: (
          <>
            {showableVessels.map((v, i) =>
              sideModalOpen && sideModalOpen.imo === v.properties.imo ? (
                <CurrentMapVessel
                  key={i}
                  feature={v}
                  isPublicMap={isPublicMap}
                  appliedZoom={appliedZoom}
                  tooltipOpen={false}
                  popupOpen={false}
                  gray={!!selectedPort && selectedImos.findIndex(imo => v.properties.imo === imo) === -1}
                  setSideModalOpen={setSideModalOpen}
                  fleetViewOn={true}
                  fleetData={combinedFleetData}
                />
              ) : (
                <MapVessel
                  key={i}
                  feature={v}
                  isPublicMap={isPublicMap}
                  appliedZoom={appliedZoom}
                  tooltipOpen={false}
                  popupOpen={false}
                  gray={!!selectedPort && selectedImos.findIndex(imo => v.properties.imo === imo) === -1}
                  setSideModalOpen={setSideModalOpen}
                  fleetViewOn={true}
                  fleetData={combinedFleetData}
                  vesselsState={vesselsState}
                />
              )
            )}
          </>
        ),
      };
    } else {
      if (vessels !== undefined) {
        return {
          newVesselOverlay: (
            <>
              {vessels.map((v, i) => (
                <MapVessel
                  key={i}
                  feature={v}
                  isPublicMap={isPublicMap}
                  appliedZoom={appliedZoom}
                  setRoute={setRoute}
                  tooltipOpen={false}
                  popupOpen={false}
                  setSelectedPortVessel={setSelectedPortVessel}
                  selectedPortVessel={selectedPortVessel}
                  timestamps={timestamps}
                  vesselsState={vesselsState}
                />
              ))}
            </>
          ),
        };
      } else {
        return {
          newVesselOverlay: null,
        };
      }
    }
  };

  const createMarkersOverlay = markers => {
    if (markers !== undefined) {
      return (
        <>
          {markers.map((m, i) => (
            <MapMarker key={i} feature={m} appliedZoomLevel={appliedZoom} />
          ))}
        </>
      );
    }
  };

  const createCranesOverlay = cranes => {
    if (cranes !== undefined) {
      return (
        <>
          {cranes.map((crane, i) => {
            return (
              <MapCurrentCrane
                key={i}
                coordinates={[crane.lon, crane.lat]}
                appliedZoomLevel={appliedZoom}
                name={crane.code}
                cranesUpdated={cranesUpdated}
              />
            );
          })}
        </>
      );
    }
  };

  const createFleetViewOverlay = data => {
    if (data !== undefined) {
      let filteredData = data.filter(d => d.port_name !== 'Anchorage');
      return (
        <>
          {filteredData.map((m, i) => (
            <FleetMapMarker
              key={i + (m.port_name === selectedPort ? '-selected' : '') + (fleetCardsOn ? '-card' : '')}
              feature={m}
              clickPort={clickPort}
              selected={selectedPort}
              deselectPort={deselectPort}
              fleetCardsOn={fleetCardsOn}
            />
          ))}
        </>
      );
    }
  };

  const { newVesselOverlay } =
    showVessels || landing
      ? createVesselsOverlay(vessels)
      : {
          newVesselOverlay: null,
        };
  const newMarkerOverlay = createMarkersOverlay(markers);
  const newCraneOverlay = createCranesOverlay(cranes);
  const fleetOverlay = createFleetViewOverlay(fleetData);

  return {
    vesselsOverlay: newVesselOverlay,
    markersOverlay: newMarkerOverlay,
    craneOverlay: newCraneOverlay,
    draRoute,
    draColor,
    fleetOverlay,
  };
};

export default useMap;
