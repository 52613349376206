import React, { useContext, useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import { UserContext } from '../../context/UserContext';

import Modal from 'antd/es/modal';
import Popconfirm from 'antd/es/popconfirm';
import App from 'antd/es/app';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';

import { isPortUnikie } from '../../utils/utils';

import Input from '../ui/Input';
import Button from '../ui/Button';
import Icon from '../ui/Icon';
import LocalSelect from '../ui/Select';
import Form from '../ui/Form';

import PageActionForm from '../page/PageActionForm';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';

import { TIME_FORMAT, TIME_FORMAT_DAY } from '../../utils/constants';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content = styled.p`
  font-size: ${({ theme }) => theme.text.smaller};
  /*color: ${({ theme }) => theme.color.grey};*/
  letter-spacing: 0.025em;
  white-space: nowrap;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

// TODO: fetch from back-end
const SUPPORTED_MFA_TYPES = [
  {
    value: 'email-code',
    name: 'Email code',
  },
];

const Users = () => {
  const { user: sessionUser, namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [rolesArr, setRolesArr] = useState([]);
  const [usersArr, setUsersArr] = useState({ data: [] });

  const [apiCallPending, setApiCallPending] = useState(false);

  const { loading: rolesLoading, data: rolesData, error: rolesError } = useApi('get', 'roles', {});

  const { message } = App.useApp();

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    setRolesArr(rolesData);
  }, [rolesData]);

  function mapOrder(array, order, key) {
    array.sort(function(a, b) {
      let A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }

  const roleOrder = ['admin', 'second_admin', 'first_user', 'user', 'consignee', 'inactive_user'];
  const roles =
    rolesArr && rolesArr.length > 0
      ? mapOrder(
        rolesArr.map(prettyRole => {
          prettyRole.key = prettyRole.name;
          prettyRole.label = prettyRole.readable_name;
          prettyRole.value = prettyRole.name;
          return prettyRole;
        }),
        roleOrder,
        'name'
      )
      : [];

  if (rolesError) {
    message.error(rolesError);
  }

  const initModal = {
    visible: false,
    confirmLoading: false,
    user: {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      expires: null,
      mfa_method: '',
    },
    passwords: {
      password: '',
      password_again: '',
      error: '',
      info: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const initExpirationsModal = {
    visible: false,
    confirmLoading: false,
    expirations: [],
  };

  const [expirationsModal, setExpirationsModal] = useState(initExpirationsModal);

  const initUser = {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    expires: null,
    mfa_method: '',
    created_at: '',
  };

  const initPasswords = {
    password: '',
    password_again: '',
    error: '',
    info: '',
  };

  const [user, setUser] = useState(initUser);
  const [passwords, setPasswords] = useState(initPasswords);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'last_name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'users', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  useEffect(() => {
    setUsersArr(data);
  }, [data]);

  let users =
    usersArr?.data && rolesArr
      ? usersArr.data.map(prettyUser => {
        rolesArr.forEach(role => {
          if (role.id === prettyUser.role_id) {
            prettyUser.role = role.name;
            prettyUser.role_readable_name = role.readable_name;
            prettyUser.namespaces = Array.isArray(prettyUser.namespaces)
              ? prettyUser.namespaces.join(' ')
              : prettyUser.namespaces;
          }
        });
        return prettyUser;
      })
      : [];

  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    message.error(error);
  }

  const showModal = async id => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', `users/${id}`);
      roles.forEach(function(role) {
        if (role.id === data.role_id) {
          data.role = role.value;
        }
      });
      setPasswords(initPasswords);
      setUser({ ...data, ...{ mfa_method: data.mfa_method || '' } });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setPasswords(initPasswords);
    user.updateRole = true;
    setApiCallPending(true);
    try {
      await apiCall('put', `users/${user.id}`, user);
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    let params = newParams;
    await fetchData(false, params);
  };

  const handleModalCancel = () => {
    setModal(initModal);
  };

  const handleModalChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleModalPasswordChange = useCallback(
    e => {
      setPasswords({ ...passwords, [e.target.name]: e.target.value });
    },
    [passwords]
  );

  const handleSave = async values => {
    const { email, first_name, last_name, role, expires, mfa_method } = values;
    setApiCallPending(true);
    try {
      await apiCall('post', 'users', {
        email,
        first_name,
        last_name,
        role,
        expires: expires ? dayjs(expires).toISOString() : null,
        mfa_method,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    showActions(false);
    values.role = 'user';
    values.expires = null;
    values.mfa_method = '';
    let params = newParams;
    await fetchData(false, params);
  };

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `users/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleLockUnlock = async e => {
    setApiCallPending(true);
    try {
      await apiCall('post', 'user-lock', { id: e.id, locked: !e.locked });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleMasterLockUnlock = async e => {
    setApiCallPending(true);
    try {
      await apiCall('post', 'unset-user-master-lock', { id: e.id });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleCancel = e => {
    e.preventDefault();
    setPasswords(initPasswords);
    values.expires = null;
    values.mfa_method = '';
    showActions(false);
  };

  const handlePasswordChange = async () => {
    const { password, password_again } = passwords;
    const okMessage = t('Password has been changed');
    if (password === password_again) {
      setApiCallPending(true);
      try {
        await apiCall('post', 'change-password', { email: user.email, password });
      } catch (e) {
        setApiCallPending(false);
        throw e;
      }
      setApiCallPending(false);
      setPasswords({ password: '', password_again: '', info: okMessage });
    }
  };

  const showExpirations = async email => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', `user-expirations/${email}`);

      setExpirationsModal({
        visible: true,
        confirmLoading: false,
        expirations:
          data?.map(expiration => ({
            id: expiration.id,
            type: expiration.type,
            expires: dayjs(expiration.expires).format(TIME_FORMAT),
            email: email,
          })) || [],
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleExpirationsModalClose = () => {
    setExpirationsModal(initExpirationsModal);
  };

  const handleExpirationDelete = async (id, email) => {
    setExpirationsModal(previous => ({
      ...previous,
      confirmLoading: true,
    }));
    setApiCallPending(true);
    try {
      await apiCall('delete', `user-expirations/${email}/${id}`);
      const { data } = await apiCall('get', `user-expirations/${email}`);
      setExpirationsModal(previous => ({
        ...previous,
        confirmLoading: false,
        expirations:
          data?.map(expiration => ({
            id: expiration.id,
            type: expiration.type,
            expires: dayjs(expiration.expires).format(TIME_FORMAT),
          })) || [],
      }));
    } catch (e) {
      setExpirationsModal(previous => ({
        ...previous,
        confirmLoading: false,
      }));
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const portUnikie = isPortUnikie(namespace);

  const fields = ['first_name', 'last_name', 'email', 'role', 'expires', 'mfa_method'];

  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, {
    role: 'user',
    expires: null,
    mfa_method: '',
  });

  const { password, password_again, info } = passwords;

  const deleteConfirmTitle = (firstname, lastname, email) => {
    return (
      <div>
        <div>
          {t('Delete user')} {firstname} {lastname}
        </div>
        <div>({email})</div>
      </div>
    );
  };

  const columns = [
    {
      title: t('User ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Last name'),
      dataIndex: 'last_name',
      key: 'last_name',
      sortableKey: 'last_name',
    },
    {
      title: t('First name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sortableKey: 'first_name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sortableKey: 'email',
    },
    {
      title: t('Role'),
      dataIndex: 'role_readable_name',
      key: 'role_readable_name',
    },
    {
      title: t('Groups'),
      dataIndex: 'groups',
      key: 'groups',
      render: record => {
        return (
          <>
            {record?.map(group => (
              <Link to={`/admin/groups?search=${group.name}`} key={group.name}>
                <Content>{group.name}</Content>
              </Link>
            ))}
          </>
        );
      },
    },
    {
      title: t('Organizations'),
      dataIndex: 'organizations',
      key: 'organizations',
      render: record => {
        return (
          <>
            {record?.map(organization => (
              <Link to={`/admin/organizations?search=${organization.name}`} key={organization.name}>
                <Content>{organization.name}</Content>
              </Link>
            ))}
          </>
        );
      },
    },
    {
      title: t('Registration'),
      dataIndex: 'registration_type',
      key: 'registration_type',
    },
    {
      title: t('Expires'),
      dataIndex: 'expires',
      key: 'expires',
      sortableKey: 'expires',
      render: date =>
        date ? (
          <DateComponent
            format={TIME_FORMAT}
            date={date}
            style={{ color: dayjs(date) < dayjs() ? 'red' : '#4A4A4A' }}
          />
        ) : (
          t('Not set')
        ),
    },
    {
      title: t('MFA method'),
      dataIndex: 'mfa_method',
      key: 'mfa_method',
      sortableKey: 'mfa_method',
      render: record => {
        const mfaMethod = record ? SUPPORTED_MFA_TYPES.find(({ value }) => value === record) : null;
        return mfaMethod ? mfaMethod.name : t('Not in use');
      },
    },
    {
      title: t('Created by'),
      dataIndex: 'created_by_email',
      key: 'created_by_email',
    },
    {
      title: t('Last activity'),
      dataIndex: 'last_session_time',
      key: 'last_session_time',
      sortableKey: 'last_session_time',
      render: record => record && <DateComponent format={TIME_FORMAT_DAY} date={record} />,
    },
  ];

  if (portUnikie) {
    columns.splice(5, 0, {
      title: t('Namespaces'),
      dataIndex: 'namespaces',
      key: 'namespaces',
    });
  }

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={portUnikie || !sessionUser.permissions.includes('manage_user_' + record.role)}
          key="action-1"
          onClick={() => showModal(record.id)}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          disabled={portUnikie || !sessionUser.permissions.includes('manage_user_' + record.role)}
          key="action-2"
          onClick={() => handleLockUnlock(record)}
        >
          <Icon type="lock" />
          {record.locked ? t('Unlock') : t('Lock')}
        </ListActionButton>
      ),
    },
    {
      render: record =>
        !portUnikie && record.master_locked ? (
          <ListActionButton
            disabled={!sessionUser.permissions.includes('manage_user_' + record.role)}
            key="action-3"
            onClick={() => handleMasterLockUnlock(record)}
          >
            <Icon type="lock" />
            {record.master_locked ? t('Password unlock') : t('Password lock')}
          </ListActionButton>
        ) : null,
    },
    {
      render: record => (
        <ListActionButton
          disabled={!sessionUser.permissions.includes('manage_user_' + record.role)}
          key="action-4"
          onClick={() => showExpirations(record.email)}
        >
          <Icon type="edit" />
          {t('MFA Expirations')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={deleteConfirmTitle(record.first_name, record.last_name, record.email)}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-5"
        >
          <div>
            <ListActionButton
              red
              disabled={
                portUnikie ||
                sessionUser.id === record.id ||
                !sessionUser.permissions.includes(`manage_user_${record.role}`)
              }
              title={sessionUser.id === record.id ? t('Deleting yourself is not allowed') : null}
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      // disabled: loading || rolesLoading,
      disabled: true, // TODO: Can be enabled if some different GDPR contract is signed
      text: t('Add new user'),
      icon: 'plus',
      tooltipWhenDisabled: t('This button is disabled due to GDPR. Contact polo.support@unikie.com to enable it.'),
    },
  ];

  const expirationColumns = [
    {
      title: t('Key type'),
      dataIndex: 'type',
      key: 'type',
      render: type => {
        switch (type) {
          case 'email-code':
            return t('Missing email grace period');
          default:
            return t('Unknown');
        }
      },
    },
    {
      title: t('Expires'),
      dataIndex: 'expires',
      key: 'expires',
    },
  ];

  const expirationsActionList = [
    {
      render: record => (
        <Popconfirm
          title={t('Delete user {{email}} expiration {{id}}?', {
            email: record.email,
            id: record.id,
          })}
          onConfirm={() => handleExpirationDelete(record.id, record.email)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={t('Edit user {{first_name}} {{last_name}}', { last_name: user.first_name, first_name: user.last_name })}
        open={modal.visible}
        onOk={handleOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input name="first_name" label="First name" value={user.first_name} onChange={handleModalChange} />
            <Input name="last_name" label="Last name" value={user.last_name} onChange={handleModalChange} />
            <Input name="email" label="Email" value={user.email} onChange={handleModalChange} />
            <LocalSelect name="role" label="Role" value={user.role} options={roles} onChange={handleModalChange} />
            <InputContainer>
              <InputLabel>{t('User expiration')}</InputLabel>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                value={user.expires ? dayjs(user.expires) : null}
                onChange={value => {
                  handleModalChange({
                    target: {
                      value: value ? dayjs(value).toISOString() : null,
                      name: 'expires',
                    },
                  });
                }}
                placeholder={t('Expires')}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('MFA verification method')}</InputLabel>
              <Select
                allowClear
                placeholder={t('Not in use')}
                optionFilterProp="label"
                onChange={value =>
                  handleModalChange({
                    target: {
                      value: value,
                      name: 'mfa_method',
                    },
                  })
                }
                style={{ minWidth: '180px' }}
                options={SUPPORTED_MFA_TYPES.map(option => {
                  return {
                    label: option.name,
                    value: option.value,
                  };
                })}
                value={user.mfa_method}
              />
            </InputContainer>
            {password === password_again ? '' : <Alert type="error" message={t('Passwords do not match')} />}
            {info && <Alert type="success" message={info} />}
            <Input
              type="password"
              name="password"
              label={t('Password')}
              value={password}
              onChange={handleModalPasswordChange}
            />
            <Input
              type="password"
              name="password_again"
              label={t('Confirm password')}
              value={password_again}
              onChange={handleModalPasswordChange}
            />
            <Button
              disabled={password === '' || password !== password_again}
              style={{ marginRight: '16px' }}
              link
              onClick={handlePasswordChange}
            >
              {t('Change password now')}
            </Button>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm title={t('Add new user')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'role') {
                return (
                  <LocalSelect
                    value={values.role}
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    options={roles}
                    onChange={handleChange}
                  />
                );
              } else if (field === 'expires') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('User expiration')}</InputLabel>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      style={{
                        width: '100%',
                      }}
                      value={values[field] ? dayjs(values[field]) : null}
                      onChange={value => {
                        handleChange({
                          target: {
                            value: value ? dayjs(value).toISOString() : null,
                            name: field,
                          },
                        });
                      }}
                      placeholder={t('Expires')}
                    />
                  </InputContainer>
                );
              } else if (field === 'mfa_method') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('MFA verification method')}</InputLabel>
                    <Select
                      allowClear
                      placeholder={t('Not in use')}
                      optionFilterProp="label"
                      onChange={value =>
                        handleChange({
                          target: {
                            value: value,
                            name: field,
                          },
                        })
                      }
                      style={{ width: '100%' }}
                      options={SUPPORTED_MFA_TYPES.map(option => {
                        return {
                          label: option.name,
                          value: option.value,
                        };
                      })}
                      value={values[field]}
                    />
                  </InputContainer>
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values.field}
                    onChange={handleChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link>{t('Add user')}</Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <Modal
        title={t('Edit user MFA expirations')}
        open={expirationsModal.visible}
        okButtonProps={{ style: { display: 'none' } }}
        confirmLoading={expirationsModal.confirmLoading}
        onCancel={handleExpirationsModalClose}
        cancelText={t('Close')}
      >
        <ModalInner>
          <List
            rowKey="id"
            columns={expirationColumns}
            dataSource={expirationsModal.expirations}
            actions={expirationsActionList}
          />
        </ModalInner>
      </Modal>

      <List
        rowKey="id"
        columns={columns}
        dataSource={users}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={loading || rolesLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name or email')}
        additionalButtons={additionalButtons}
      />
    </>
  );
};

export default Users;
