import React, { createContext, useContext, useCallback, useState } from 'react';

import { UserContext } from './UserContext';
import useApi from '../hooks/useApi';

export const ServiceOrderContext = createContext();

export const ServiceOrderProvider = ({ children }) => {
  const { useUserSocket, modules, user } = useContext(UserContext);

  const [socketUpdate, setSocketUpdate] = useState(0); // 0 - 10

  const { data: serviceOrderData, error: serviceOrderError, fetchData: fetchServiceOrderList } = useApi(
    'get',
    'service-orders/service-order-todos',
    {},
    null,
    modules.service_order_module === 'enabled' &&
      (user.permissions.includes('manage_service_order') ||
        user.permissions.includes('manage_service_order_ack_delivery'))
  );

  let serviceOrders = serviceOrderError
    ? []
    : serviceOrderData?.results
      ? serviceOrderData.results['service-orders']
      : [];

  const getServiceOrders = useCallback(() => {
    fetchServiceOrderList();
  }, [fetchServiceOrderList]);

  useUserSocket('service-orders-changed', () => {
    setSocketUpdate(count => (count === 10 ? 0 : count + 1));
    getServiceOrders();
  });

  return (
    <ServiceOrderContext.Provider
      value={{
        serviceOrders,
        getServiceOrders,
        socketUpdate,
      }}
    >
      {children}
    </ServiceOrderContext.Provider>
  );
};
