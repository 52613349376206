import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { TIME_FORMAT } from '../../utils/constants';

import NotificationDecisionItem from './NotificationDecisionItem';
import { UserContext } from '../../context/UserContext';
import DateComponent from '../ui/DateComponent';

const ApprovalSpan = styled.span`
  flex: auto;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const StyledDecision = styled.div`
  margin-left: ${({ theme }) => theme.sizing.gap_medium};
  margin-top: ${({ theme }) => theme.sizing.gap_tiny};
  max-width: 500px;
`;

const ResolvedSpan = styled.span`
  font-weight: 400;
  font-size: 0.8571rem;
  font-style: italic;
`;

const Time = styled(DateComponent)`
  margin-right: ${({ theme }) => theme.sizing.gap_small};
  font-weight: 400;
  font-size: 0.8571rem;
  font-style: italic;
`;

const NotificationDecision = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { decision_items } = data;
  const resolvedDecision = decision_items
    .filter(e => e.response_type !== null)
    .reduce((a, b) => (a.modified_at > b.modified_at ? a : b), []);

  return (
    <StyledDecision>
      <ApprovalSpan>
        Requires approval by
        {resolvedDecision.length !== 0 && (
          <span>
            <ResolvedSpan>{t('Resolved at')} </ResolvedSpan>
            <Time format={TIME_FORMAT} date={resolvedDecision.modified_at} />
          </span>
        )}
      </ApprovalSpan>
      {decision_items.map(value => {
        return <NotificationDecisionItem key={value.id} data={value} />;
      })}
    </StyledDecision>
  );
};

export default NotificationDecision;
