import Spin from 'antd/es/spin';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InvoicingContext } from '../../context/InvoicingContext';
import { UserContext } from '../../context/UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const Row = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledShipInfo = styled.dl`
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: ${({ theme }) => theme.sizing.gap_medium};
  padding: 20px;
`;

const Description = styled.dt`
  width: 50%;
  font-weight: 550;
  text-transform: uppercase;
`;
const DescriptionItems = styled.dt`
  width: 50%;
`;
const Price = styled.dd`
  width: 25%;
  margin-left: auto;
  font-weight: 550;
  text-transform: uppercase;
`;
const PriceItems = styled.dd`
  width: 25%;
  margin-left: auto;
  font-weight: 550;
`;
const Unit = styled.dd`
  width: 25%;
  margin-left: auto;
  font-weight: 550;
  text-transform: uppercase;
`;
const UnitItems = styled.dd`
  width: 25%;
  margin-left: auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
`;

const pricingData = {
  base_fee: {
    description: 'Base fee 0 - 7 days',
    unit: '/NT',
  },
  additional_base_fee: {
    description: 'Base fee 7+ days',
    unit: '/NT/day',
  },
  water_fee: {
    description: 'Water charge',
    unit: '/cbm',
  },
  household_waste_fee: {
    description: 'Household and other waste',
    unit: '/NT',
  },
  oil_waste_fee: {
    description: 'Oily wastes',
    unit: '/NT',
  },
  min_base_fee: {
    description: 'Min base fee',
    unit: '',
  },
  min_household_waste_fee: {
    description: 'Min household waste payment',
    unit: '',
  },
  max_household_waste_fee: {
    description: 'Max household waste payment',
    unit: '',
  },
};

const PricingCalculations = () => {
  const { namespace } = useContext(UserContext);
  const { contractData, isLoading } = useContext(InvoicingContext);

  const { t } = useTranslation(namespace);

  return (
    <Container>
      <Header>{t('Pricing calculations for port call')}</Header>
      {!isLoading ? (
        <StyledShipInfo>
          <Row style={{ display: 'flex' }}>
            <Description>{t('Item description')}</Description>
            <Price>{t('Price')} (€)</Price>
            <Unit>{t('Unit')}</Unit>
          </Row>
          {Object.entries(contractData).map(([key, value], index) => {
            if (pricingData[key]) {
              return (
                <Row key={index} style={{ display: 'flex' }}>
                  <DescriptionItems>{pricingData[key].description}</DescriptionItems>
                  <PriceItems>{value}</PriceItems>
                  <UnitItems>{pricingData[key].unit}</UnitItems>
                </Row>
              );
            }
          })}
          <Row>
            <DescriptionItems>{t('Mooring & unmooring from the pricing table matrix')}</DescriptionItems>
          </Row>
        </StyledShipInfo>
      ) : (
        <LoaderContainer>
          <Spin size="large" />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default PricingCalculations;
