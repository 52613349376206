import React, { useContext } from 'react';
import styled from 'styled-components';
import { AlertContext } from '../../context/AlertContext';

const Wrapper = styled.div`
  min-height: calc(100vh - 113px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  height: 100%;
  @media print {
    background: white;
    padding: 0;
    min-height: 0;
  }
`;

const Content = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.style.border_radius};
  background: ${({ theme }) => theme.color.white};
  padding: 1.5rem;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  min-height: 240px;
  max-width: ${props => (props.fullWidth ? '100%' : '80vw')};
  @media print {
    padding: 0;
    margin: 0;
    box-shadow: none;
    max-width: 100%;
  }
`;

const PageWithoutHeaderAndMargins = ({ children, ...props }) => {
  const { alertsHeight } = useContext(AlertContext);

  return (
    <Wrapper filteringRowOnDisplay={!!document.querySelector('#filter-tag-row')} alertsHeight={alertsHeight}>
      <Content {...props}>{children}</Content>
    </Wrapper>
  );
};

export default PageWithoutHeaderAndMargins;
