import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export const fleetTimelineTypeStorageKey = 'fleet_timeline_type'; // Same in fleet view context and fleet vessel view context
export const fleetTimelineTypeStorageKeyOptions = ['day', 'twodays', 'week', 'month', 'custom'];

export const getHeight = (data, rowHeight) => {
  let height = 0;

  data.forEach(port => {
    height += 12;
    height += Math.max(port.row_count, 4) * rowHeight;
  });

  return height;
};

const getFirstFreeRow = arr => {
  for (let i = 0; i <= arr.length; i++) {
    if (arr.indexOf(i) === -1) {
      return i;
    }
  }
  return arr.length + 1;
};

export const calculatePortcallRows = (portdata, visibleHours) => {
  portdata.sort((a, b) => {
    return a.eta.localeCompare(b.eta);
  });
  return Object.values(portdata).reduce((acc, value, index) => {
    let outPilotEstimated = false;
    let outPilotOrdered = false;
    let outPilotCommenced = false;
    let outPilotCompleted = false;
    let inPilotEstimated = false;
    let inPilotOrdered = false;
    let inPilotCommenced = false;
    let inPilotCompleted = false;

    let inTimestamp = null;
    let outTimestamp = null;

    let startInTimestamp = null;
    let startOutTimestamp = null;

    if (value.data?.timestamps) {
      value.data.timestamps.forEach(stamp => {
        if (stamp.direction === 'outbound') {
          if (stamp.APC) {
            outTimestamp = { ...stamp, type: 'APC', date: stamp.APC };
            outPilotCompleted = true;
          } else if (stamp.APS) {
            if (!outTimestamp || outTimestamp.type === 'PPS' || outTimestamp.type === 'RPS') {
              outTimestamp = { ...stamp, type: 'APS', date: stamp.APS };
              startOutTimestamp = stamp.APS;
            }
            outPilotCommenced = true;
          } else if (stamp.PPS) {
            if (!outTimestamp || outTimestamp.type === 'RPS') {
              outTimestamp = { ...stamp, type: 'PPS', date: stamp.PPS };
              startOutTimestamp = stamp.PPS;
            }
            outPilotOrdered = true;
          } else if (stamp.RPS) {
            if (!outTimestamp) {
              outTimestamp = { ...stamp, type: 'RPS', date: stamp.RPS };
              startOutTimestamp = stamp.RPS;
            }
            outPilotEstimated = true;
          }
        } else {
          if (stamp.APC) {
            inTimestamp = { ...stamp, type: 'APC', date: stamp.APC };
            inPilotCompleted = true;
          } else if (stamp.APS) {
            if (!inTimestamp || inTimestamp.type === 'PPS' || inTimestamp.type === 'RPS') {
              inTimestamp = { ...stamp, type: 'APS', date: stamp.APS };
              startInTimestamp = stamp.APS;
            }
            inPilotCommenced = true;
          } else if (stamp.PPS) {
            if (!inTimestamp || inTimestamp.type === 'PPS') {
              inTimestamp = { ...stamp, type: 'PPS', date: stamp.PPS };
              startInTimestamp = stamp.PPS;
            }
            inPilotOrdered = true;
          } else if (stamp.RPS) {
            if (!inTimestamp) {
              inTimestamp = { ...stamp, type: 'RPS', date: stamp.RPS };
              startInTimestamp = stamp.RPS;
            }
            inPilotEstimated = true;
          }
        }
      });
    }

    let starttime = null;

    if (startInTimestamp) {
      starttime = dayjs.min([dayjs(startInTimestamp), dayjs(value.eta)]);
    } else {
      starttime = dayjs(value.eta);
    }

    let overlappingRows = [];

    for (let i = 0; i < index; i++) {
      let prevEndTime = null;

      if (acc[i].startOutTimestamp) {
        prevEndTime = dayjs.max([dayjs(acc[i].startOutTimestamp), dayjs(acc[i].etd)]).add(visibleHours / 24, 'hours');
      } else {
        prevEndTime = dayjs(acc[i].etd).add(visibleHours / 24, 'hours');
      }

      if (starttime.isBefore(prevEndTime)) {
        overlappingRows.push(acc[i].row);
      }
    }

    acc.push({
      ...value,
      row: getFirstFreeRow(overlappingRows),
      pilotages: {
        outPilotEstimated,
        outPilotOrdered,
        outPilotCommenced,
        outPilotCompleted,
        inPilotEstimated,
        inPilotOrdered,
        inPilotCommenced,
        inPilotCompleted,
        inTimestamp,
        outTimestamp,
      },
      startInTimestamp,
      startOutTimestamp,
    });

    return acc;
  }, []);
};
