import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import DatePicker from 'antd/es/date-picker';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Label from '../../components/ui/Label';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import DebounceAutocomplete from '../../components/ui/DebounceAutocomplete';
import ServiceDeliveries from './ServiceDeliveries';
import Input from '../../components/ui/Input';
import dayjs from 'dayjs';
import ServiceAck from './ServiceAck';
import { ExternalTypeServiceOrderTaskLine } from '../../components/activity/serviceOrder/externalTypes/ServiceOrderExternalTypes';
import DateComponent from '../../components/ui/DateComponent';

const Page = styled.div`
  background-color: ${({ theme }) => theme.color.grey_lighter};
  * thead > tr > th {
    background-color: ${({ theme }) => theme.color.grey_lighter3} !important;
  }
`;

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Heading1 = styled.p`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Heading2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  font-weight: 700;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Success1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.success};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Failure1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.warning};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Title = styled.div`
  position: absolute;
  padding-top: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondary};
  i {
    width: 24px;
    height: 24px;
  }
  svg {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const WindowCalendar = styled.div`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const AckStatus = styled.span`
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  font-size: ${({ theme }) => theme.text.smaller};
  color: ${({ theme }) => theme.color.grey_dark};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  cursor: pointer;
`;

const AckStatusDisabled = styled(AckStatus)`
  cursor: not-allowed;
  opacity: 0.4;
`;

const ColorBackground = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.white};
`;

const WarningBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning};
`;

const SuccessBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.success};
`;

const GreyBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.grey};
`;

const DeliveryCell = styled.div`
  white-space: nowrap;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const Integrations = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  margin-right: 6px;
  margin-left: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service-order-line';

const ServiceOrderLines = ({ serviceOrderId, orderLines, newParams, fetchData, setNewParams, loading, vesselName }) => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [applyAckChangesDisabled, setApplyAckChangesDisabled] = useState(true);

  const [serviceEditName, setServiceEditName] = useState(null);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initServiceOrderLine = {
    id: '',
    service_id: '',
    service_provider_name: '',
    service_provider_email: '',
    service_provider_phone: '',
    service_type_name: '',
    service_type_description: '',
    quantity: '',
    when: '',
    requested_delivery_time: null,
    notes: '',
    email: '',
    phone: '',
    is_cancelled: '',
    created_by: '',
    updated_by: '',
    created_at: '',
    updated_at: '',
    deliveries: [],
    ack: null,
  };

  const [serviceOrderLine, setServiceOrderLine] = useState(initServiceOrderLine);

  const initModal = {
    visible: false,
    confirmLoading: false,
    'service-order-line': {
      id: '',
      service_id: '',
      service_provider_name: '',
      service_provider_email: '',
      service_provider_phone: '',
      service_type_name: '',
      service_type_description: '',
      quantity: '',
      when: '',
      requested_delivery_time: null,
      notes: '',
      email: '',
      phone: '',
      is_cancelled: '',
      created_by: '',
      updated_by: '',
      created_at: '',
      updated_at: '',
      deliveries: [],
      ack: null,
    },
  };

  const [modal, setModal] = useState(initModal);

  const initAck = {
    id: '',
    order_line_id: '',
    status: '',
    notes: '',
    email: '',
    phone: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
  };

  const [ack, setAck] = useState(initAck);

  const initAckModal = {
    visible: false,
    confirmLoading: false,
    'service-ack': {
      id: '',
      order_line_id: '',
      status: '',
      notes: '',
      email: '',
      phone: '',
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
    },
  };

  const [ackModal, setAckModal] = useState(initAckModal);

  const start = 0;
  const total = 0;

  const showModal = async (entryIdentifier, orderLineId) => {
    setApiCallPending(true);
    try {
      const {
        data: { 'service-order': serviceOrder },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/service-order/${entryIdentifier}`);

      const orderLine = serviceOrder.order_lines?.find(item => item.id === orderLineId);
      if (!orderLine) {
        message.error(t('Could not find order line'), 4);
      } else {
        // Try to get the service by search
        const services = await fetchServices(`${orderLine.service_provider_name} ${orderLine.service_type_name}`);
        if (services?.[0]?.data) {
          setServiceEditName(`${orderLine.service_provider_name} ${orderLine.service_type_name}`);
          setServiceOrderLine({ ...orderLine, service_id: services[0].data.id });
        } else {
          message.error(t('Could not find the service for the order line'), 4);
          setServiceEditName(null);
          setServiceOrderLine({ ...orderLine });
        }
      }
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const showAckModal = async record => {
    if (record.ack) {
      setApiCallPending(true);
      try {
        const {
          data: { 'service-ack': ack },
        } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/service-ack/${record.ack.id}`);
        setAck({ ...ack });
        setAckModal({ ...initModal, visible: true });
      } catch (e) {
        setApiCallPending(false);
        throw e;
      }
      setApiCallPending(false);
    } else {
      setAck({
        ...initAck,
        order_line_id: record.id,
        email: record.service_provider_email,
        phone: record.service_provider_phone,
      });
      setAckModal({ ...initModal, visible: true });
    }
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleSave = async values => {
    const { quantity, when, requested_delivery_time, notes, email, phone } = values;
    const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/service-order`, {
      service_order: {
        id: serviceOrderId,
        order_lines: [
          {
            service_id: serviceOrderLine.service_id,
            quantity: parseFloat(quantity),
            when: when,
            requested_delivery_time: requested_delivery_time ? requested_delivery_time : null,
            notes: notes,
            email: email,
            phone: phone,
          },
        ],
      },
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceOrderLine(initServiceOrderLine);
      handleSetMultiple(initServiceOrderLine);
      history.replace(location.pathname);
      await fetchData(newParams);
    }
    showActions(false);
    setServiceEditName(null);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setServiceOrderLine(initServiceOrderLine);
    handleSetMultiple(initServiceOrderLine);
    setServiceEditName(null);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/service-order`, {
        service_order: {
          id: serviceOrderId,
          order_lines: [
            {
              id: serviceOrderLine.id,
              service_id: serviceOrderLine.service_id,
              quantity: parseFloat(serviceOrderLine.quantity),
              when: serviceOrderLine.when,
              requested_delivery_time: serviceOrderLine.requested_delivery_time
                ? serviceOrderLine.requested_delivery_time
                : null,
              notes: serviceOrderLine.notes,
              email: serviceOrderLine.email,
              phone: serviceOrderLine.phone,
            },
          ],
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setServiceOrderLine(initServiceOrderLine);
    handleSetMultiple(initServiceOrderLine);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setServiceOrderLine(initServiceOrderLine);
    handleSetMultiple(initServiceOrderLine);
  };

  const handleModalServiceChange = (value, obj) => {
    setServiceEditName(value);
    setServiceOrderLine(previous => {
      return {
        ...previous,
        service_id: obj?.data?.id || null,
        service_provider_name: obj?.data?.provider?.name || null,
        service_provider_email: obj?.data?.provider?.email || null,
        service_provider_phone: obj?.data?.provider?.phone || null,
        service_type_name: obj?.data?.type?.name || null,
        service_type_description: obj?.data?.type?.description || null,
      };
    });
  };

  const handleAckModalOk = async () => {
    setAckModal({
      ...ackModal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      let result = null;
      if (ack.id) {
        result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/service-ack`, {
          service_ack: {
            id: ack.id,
            status: ack.status || null,
            notes: ack.notes || null,
            email: ack.email || null,
            phone: ack.phone || null,
          },
        });
      } else {
        result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/service-ack`, {
          service_ack: {
            order_line_id: ack.order_line_id,
            status: ack.status,
            notes: ack.notes,
            email: ack.email,
            phone: ack.phone,
          },
        });
      }
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setAckModal({
        ...ackModal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setAckModal({
      ...initAckModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setAck(initAck);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleAckModalCancel = async () => {
    setAckModal(initAckModal);
    setAck(initAck);
  };

  const handleAckModalChange = e => {
    const { name, value } = e.target;
    setAck({ ...ack, [name]: value });
  };

  const handleAddNewServiceChange = (value, obj) => {
    handleChange({
      target: {
        value: obj?.data,
        name: 'service',
      },
    });
    setServiceEditName(value);
    setServiceOrderLine(previous => {
      return {
        ...previous,
        service_id: obj?.data?.id || null,
        service_provider_name: obj?.data?.provider?.name || null,
        service_provider_email: obj?.data?.provider?.email || null,
        service_provider_phone: obj?.data?.provider?.phone || null,
        service_type_name: obj?.data?.type?.name || null,
        service_type_description: obj?.data?.type?.description || null,
      };
    });
  };

  const handleModalChange = e => {
    e.persist?.();
    const name = e.target.name;
    const value = e.target.value || null;
    setServiceOrderLine(previous => ({ ...previous, [name]: value }));
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setServiceOrderLine({ ...serviceOrderLine, [name]: value });
  };

  const handleCancelOrderLine = async (entryIdentifier, cancelled) => {
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/cancel/${entryIdentifier}`, {
      is_cancelled: cancelled,
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceOrderLine(initServiceOrderLine);
      handleSetMultiple(initServiceOrderLine);
      history.replace(location.pathname);
      await fetchData(newParams);
    }
    showActions(false);
    setServiceEditName(null);
  };

  const fetchServices = async value => {
    const params = {
      query: {
        text: value,
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [
        {
          field: 'provider_name',
          order: 'ASC',
        },
      ],
    };

    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/service/search`, params);
    if (result?.status === 200) {
      return result.data?.results?.['services']?.map(d => {
        return { value: `${d.provider.name} ${d.type.name}`, label: `${d.provider.name} ${d.type.name}`, data: d };
      });
    }

    return [];
  };

  const getWasteIndicator = (index) => {
    return (
      <Fragment key={`waste-indicator-${index}`}>
        <Tooltip placement="top" title={t('Waste service')}>
          <IconContainer>
            <Icon type="waste" />
          </IconContainer>
        </Tooltip>
      </Fragment>
    );
  };

  const getFinnpilotIndicator = (index) => {
    return (
      <Fragment key={`waste-indicator-${index}`}>
        <Tooltip placement="top" title={t('Finnpilot service')}>
          <IconContainer>
            <Icon type="finnpilot-logo" color="#4A4A4A" />
          </IconContainer>
        </Tooltip>
      </Fragment>
    );
  };

  const fields = ['service', 'quantity', 'when', 'requested_delivery_time', 'notes', 'email', 'phone'];
  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {
    service: null,
    requested_delivery_time: null,
  });

  useEffect(() => {
    let isValid =
      serviceOrderLine?.service_id?.length > 0 &&
      serviceOrderLine?.quantity?.length > 0 &&
      (dayjs(serviceOrderLine?.requested_delivery_time, dayjs.ISO_8601).isValid() || serviceOrderLine?.when?.length > 0)
        ? true
        : false;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, serviceOrderLine]);

  useEffect(() => {
    let isValid = ack?.status?.length > 0;
    setApplyAckChangesDisabled(!isValid);
  }, [setApplyAckChangesDisabled, ack]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: t('Provider'),
      key: 'provider',
      sorter: (a, b) => a.service_provider_name?.localeCompare?.(b.service_provider_name),
      render: record => {
        return (
          <>
            <Heading1>{record?.service_provider_name}</Heading1>
            <Content2>{record?.service_provider_email}</Content2>
            <Content2>{record?.service_provider_phone}</Content2>
          </>
        );
      },
    },
    {
      title: t('Type'),
      key: 'type',
      sorter: (a, b) => a.service_type_name?.localeCompare?.(b.service_type_name),
      render: record => {
        return (
          <>
            <Heading1>{record?.service_type_name}</Heading1>
            <Content2>{record?.service_type_description}</Content2>
          </>
        );
      },
    },
    {
      title: t('Requested delivery'),
      dataIndex: 'requested_delivery_time',
      key: 'requested_delivery_time',
      sorter: (a, b) => new Date(a.requested_delivery_time) - new Date(b.requested_delivery_time),
      //sortableKey: 'order_line_requested_delivery_time',
      defaultSortOrder: 'ascend',
      render: record =>
        record && (
          <Heading1>
            <DateComponent format={TIME_FORMAT} date={record} />
          </Heading1>
        ),
    },
    {
      title: t('Details'),
      key: 'details',
      sorter: (a, b) => a.quantity?.localeCompare?.(b.quantity),
      render: record => {
        return (
          <>
            <Heading2>
              {t('Quantity:')} {record?.quantity}
            </Heading2>
            <Content1>
              {t('When: ')} {record?.when}
            </Content1>
            <Content2>
              {t('Notes: ')} {record?.notes}
            </Content2>
          </>
        );
      },
    },
    {
      title: t('Contact'),
      key: 'contact',
      sorter: (a, b) => a.email?.localeCompare?.(b.email),
      render: record => {
        return (
          <>
            <Heading2>{record?.email}</Heading2>
            <Content1>{record?.phone}</Content1>
          </>
        );
      },
    },
    {
      title: t('ACK'),
      //dataIndex: 'ack',
      key: 'ack',
      render: record => (
        <Tooltip
          placement="top"
          title={record.is_cancelled ? t('Cannot change status for cancelled order line') : t('Click to set status')}
        >
          {record.is_cancelled ? (
            <AckStatusDisabled>
              {!record.ack && <GreyBackground>{t('Unanswered')}</GreyBackground>}
              {record.ack?.status === 'rejected' && <WarningBackground>{t('Rejected')}</WarningBackground>}
              {record.ack?.status === 'accepted' && <SuccessBackground>{t('Accepted')}</SuccessBackground>}
            </AckStatusDisabled>
          ) : (
            <AckStatus onClick={() => showAckModal(record)}>
              {!record.ack && <GreyBackground>{t('Unanswered')}</GreyBackground>}
              {record.ack?.status === 'rejected' && <WarningBackground>{t('Rejected')}</WarningBackground>}
              {record.ack?.status === 'accepted' && <SuccessBackground>{t('Accepted')}</SuccessBackground>}
            </AckStatus>
          )}
        </Tooltip>
      ),
    },
    {
      title: t('Deliveries'),
      dataIndex: 'deliveries',
      key: 'deliveries',
      render: record => {
        const cancelled = record?.filter?.(item => item.status === 'cancelled').length || 0;
        const pending = record?.filter?.(item => item.status === 'pending').length || 0;
        const inProgress = record?.filter?.(item => item.status === 'in_progress').length || 0;
        const done = record?.filter?.(item => item.status === 'done').length || 0;
        const all = record?.length || 0;

        return (
          <DeliveryCell>
            <Heading1>{t('Count: {{all}}', { all })}</Heading1>
            <Content2>{t('Pending: {{pending}}', { pending })}</Content2>
            <Content2>{t('In progress: {{inProgress}}', { inProgress })}</Content2>
            <Success1>{t('Done: {{done}}', { done })}</Success1>
            <Failure1>{t('Cancelled: {{cancelled}}', { cancelled })}</Failure1>
          </DeliveryCell>
        );
      },
    },
    /*
    {
      title: t('Status'),
      key: 'status',
      render: record => {
        return record?.is_cancelled ? (
          <Tooltip placement="top" title={t('Order line is cancelled')}>
            <WarningBackground>{t('Cancelled')}</WarningBackground>
          </Tooltip>
        ) : (
          <Tooltip placement="top" title={t('Are all deliveries done (not inluding cancelled deliveries)?')}>
            {record?.done ? (
              <Icon type="check-circle" color="#007AFF" fill="none" />
            ) : (
              <Icon type="x-circle" color="#D0011C" fill="none" />
            )}
          </Tooltip>
        );
      },
    },*/
    {
      title: t('Cancelled'),
      //dataIndex: 'is_cancelled',
      key: 'is_cancelled',
      sorter: (a, b) => Number(a.is_cancelled) - Number(b.is_cancelled),
      //sortableKey: 'order_line_is_cancelled',
      render: record => (
        <Tooltip placement="top" title={t('Click to change the cancellation status of the order line')}>
          <Switch
            disabled={false}
            checked={record?.is_cancelled}
            defaultChecked={record?.is_cancelled}
            onChange={checked => handleCancelOrderLine(record?.id, checked)}
          ></Switch>
        </Tooltip>
      ),
    },
    {
      title: t('Integrations'),
      dataIndex: 'integrations',
      key: 'integrations',
      render: record => (
        <Integrations>
          {record?.map?.((source, index) => {
            if (source === 'waste') {
              return getWasteIndicator(index);
            } else if (source === 'finnpilot') {
              return getFinnpilotIndicator(index);
            }
            return null;
          })}
        </Integrations>
      ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(serviceOrderId, record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    /*
    {
      render: record => (
        <Popconfirm
          title={t('Cancel order line {{id}}?', { id: record.id })}
          onConfirm={() => handleCancelOrderLine(record.id, !record.is_cancelled)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        ><div>
          <ListActionButton red>
            <Icon type="close" />
            {t('Cancel')}
          </ListActionButton></div>
        </Popconfirm>
      ),
    },*/
    {
      render: record => (
        <Popconfirm
          title={t('Delete order line {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-3"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: loading,
      text: t('Add service order line'),
      icon: 'plus',
    },
  ];

  return (
    <Page>
      <Modal
        title={t('Edit service order line')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <InputContainer>
              <InputLabel>{t('Service')}</InputLabel>
              <DebounceAutocomplete
                placeholder={t('Search for service')}
                fetchOptions={fetchServices}
                onChange={(value, data) => handleModalServiceChange(value, data)}
                onSelect={(value, data) => handleModalServiceChange(value, data)}
                value={serviceEditName}
                style={{ width: '100%' }}
                allowClear={true}
                onClear={() => handleModalServiceChange('', null)}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('Quantity')}</InputLabel>
              <StyledInputNumber
                placeholder={t('Quantity')}
                value={serviceOrderLine?.quantity || ''}
                decimalSeparator={','}
                min={0}
                onChange={e => handleModalChange({ target: { value: e ? `${e}` : null, name: 'quantity' } })}
                style={{ width: '100%' }}
                required
              />
            </InputContainer>
            <Input
              name="when"
              label={t('When')}
              value={serviceOrderLine?.when || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <WindowCalendar>
              <Label>{t('Requested delivery time')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                style={{
                  width: '100%',
                }}
                value={
                  serviceOrderLine?.requested_delivery_time ? dayjs(serviceOrderLine?.requested_delivery_time) : null
                }
                onChange={value =>
                  handleModalChange({
                    target: {
                      value: value ? dayjs(value).toISOString() : null,
                      name: 'requested_delivery_time',
                    },
                  })
                }
                placeholder={t('Select time')}
              />
            </WindowCalendar>
            <Input
              name="notes"
              label={t('Notes')}
              value={serviceOrderLine?.notes || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="email"
              label={t('Email')}
              value={serviceOrderLine?.email || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="phone"
              label={t('Phone')}
              value={serviceOrderLine?.phone || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
          </Spin>
        </ModalInner>
      </Modal>
      <Modal
        title={t('Edit service ack')}
        open={ackModal.visible}
        onOk={handleAckModalOk}
        confirmLoading={ackModal.confirmLoading}
        onCancel={handleAckModalCancel}
        okButtonProps={{ disabled: applyAckChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <InputContainer>
              <InputLabel>{t('Status')}</InputLabel>
              <Select
                name="status"
                label={t('Status')}
                value={ack?.status || ''}
                options={[
                  {
                    label: t('Accepted'),
                    value: 'accepted',
                  },
                  {
                    label: t('Rejected'),
                    value: 'rejected',
                  },
                ]}
                style={{ width: '100%' }}
                placeholder={t('Select status')}
                onChange={value =>
                  handleAckModalChange({
                    target: {
                      value: value,
                      name: 'status',
                    },
                  })
                }
              />
            </InputContainer>
            <Input
              name="notes"
              label={t('Notes')}
              value={ack?.notes || ''}
              onChange={handleAckModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="email"
              label={t('Email')}
              value={ack?.email || ''}
              onChange={handleAckModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="phone"
              label={t('Phone')}
              value={ack?.phone || ''}
              onChange={handleAckModalChange}
              style={{ width: '100%' }}
            />
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service order line')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'service') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Service provider')}</InputLabel>
                    <DebounceAutocomplete
                      placeholder={t('Search for provider')}
                      fetchOptions={fetchServices}
                      onChange={(value, data) => handleAddNewServiceChange(value, data)}
                      onSelect={(value, data) => handleAddNewServiceChange(value, data)}
                      value={serviceEditName}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => handleAddNewServiceChange('', null)}
                    />
                  </InputContainer>
                );
              } else if (field === 'requested_delivery_time') {
                return (
                  <WindowCalendar key={field}>
                    <Label>{t('Requested delivery time')}</Label>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      style={{
                        width: '100%',
                      }}
                      value={
                        serviceOrderLine?.requested_delivery_time
                          ? dayjs(serviceOrderLine?.requested_delivery_time)
                          : null
                      }
                      onChange={value =>
                        handleAddNewChange({
                          target: {
                            value: value ? dayjs(value).toISOString() : null,
                            name: 'requested_delivery_time',
                          },
                        })
                      }
                      placeholder={t('Select time')}
                    />
                  </WindowCalendar>
                );
              } else if (field === 'quantity') {
                return (
                  <InputContainer key={field}>
                    <Label>{t(field.replace(/_/g, ' '))}</Label>
                    <StyledInputNumber
                      name={field}
                      field={field}
                      value={values[field]}
                      decimalSeparator={','}
                      min={0}
                      onChange={e => handleAddNewChange({ target: { value: e ? `${e}` : null, name: field } })}
                    />
                  </InputContainer>
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="text"
                    onChange={handleAddNewChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service order line')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <Title>{t('Order lines')}</Title>
      <List
        rowKey="id"
        columns={columns}
        dataSource={orderLines}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name, email or phone')}
        additionalButtons={additionalButtons}
        hideSearch={true}
        expandedRowRender={record => (
          <>
            {record?.data?.service_external_type ? (
              <div style={{ position: 'relative' }}>
                <ExternalTypeServiceOrderTaskLine
                  key={`external-type-line-${record.id}`}
                  vessel={{
                    vessel: {
                      vessel_name: vesselName,
                    },
                  }}
                  line={record}
                  reloadData={() => null}
                  externalType={record.data.service_external_type}
                />
              </div>
            ) : null}
            <ServiceAck
              orderLine={record}
              newParams={newParams}
              fetchData={fetchData}
              setNewParams={setNewParams}
              loading={loading}
            />
            <ServiceDeliveries
              orderLine={record}
              newParams={newParams}
              fetchData={fetchData}
              setNewParams={setNewParams}
              loading={loading}
            />
          </>
        )}
      />
    </Page>
  );
};

export default ServiceOrderLines;
