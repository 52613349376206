import Layout from 'antd/es/layout';
import { MapContainer as Map } from 'react-leaflet';
import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';

import SmallPageSearch from '../page/SmallPageSearch';
import { UserContext } from '../../context/UserContext';

import MapPocInnerContainer from './MapPocInnerContainer';

const TopPanel = styled.div`
  display: flex;
  height: 50px;
  padding-bottom: 6px;
  width: 100%;
`;

const StyledTableSearchRow = styled.div`
  width: 220px;
`;

const VesselPopup = styled.div`
  position: absolute;
  right: 40px;
  top: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.35);
  z-index: 1000;
  width: 200px;
`;

const Header = styled.div`
  font-weight: 600;
  margin-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
`;

const RowHeader = styled.div`
  width: 60px;
`;

const Close = styled.div`
  position: absolute;
  right: 12px;
  top: 4px;
  cursor: pointer;
`;

const Button = styled.div`
  background-color: #4990dd;
  color: white;
  height: 30px;
  line-height: 30px;
  padding: 0 6px;
  margin-top: 1px;
  border-radius: 3px;
  cursor: pointer;
`;

const MapPocContainer = ({ coordinatesString, zoom }) => {
  const { namespace } = useContext(UserContext);

  const { t } = useTranslation(namespace);

  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [selectedVessel, setSelectedVessel] = useState(undefined);
  const [reloadData, setReloadData] = useState(true);

  let timeout = useRef();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  let appliedCoordinates = coordinatesString === undefined ? '59.129089,14.373028' : coordinatesString;
  const [appliedZoom, setAppliedZoom] = useState(zoom === undefined ? 5 : zoom);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchInput(value);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      if (mounted.current) {
        setSearchString(value);
      }
    }, 500);
  };

  return (
    <>
      <Layout width="100%" style={{ backgroundColor: '#00000000' }}>
        <TopPanel>
          <StyledTableSearchRow>
            <SmallPageSearch
              placeholder={t('Search vessel by name')}
              value={searchInput}
              onChange={handleSearchChange}
            />
          </StyledTableSearchRow>
          <Button onClick={() => setReloadData(true)}>Reload data</Button>
        </TopPanel>
      </Layout>
      {/* Disabling tap for Map is needed to support bound popups on Safari
      (bug in leaflet, see https://github.com/Leaflet/Leaflet/issues/7255) */}
      <div style={{ display: 'flex', height: 'calc(100vh - 204px)' }}>
        <Map
          style={{
            height: '100%',
            width: '100%',
          }}
          className="markercluster-map"
          center={appliedCoordinates.split(',')}
          zoom={appliedZoom}
          tap={false}
          zoomControl={false}
          preferCanvas={true}
          renderer={L.canvas({ padding: 0.5 })}
        >
          <MapPocInnerContainer
            setZoom={setAppliedZoom}
            zoom={appliedZoom}
            searchString={searchString}
            selectedVessel={selectedVessel}
            setSelectedVessel={setSelectedVessel}
            reloadData={reloadData}
            setReloadData={setReloadData}
          />
        </Map>
      </div>

      {selectedVessel && (
        <VesselPopup>
          <Close onClick={() => setSelectedVessel(undefined)}>X</Close>
          <Header>Vessel popup</Header>
          <Row>
            <RowHeader>Name:</RowHeader>
            {selectedVessel.name}
          </Row>
          <Row>
            <RowHeader>MMSI:</RowHeader>
            {selectedVessel.mmsi}
          </Row>
          <Row>
            <RowHeader>Color:</RowHeader>
            {selectedVessel.marker_class}
          </Row>
        </VesselPopup>
      )}
    </>
  );
};

export default MapPocContainer;
