import React from 'react';
import styled from 'styled-components';

import SelectWithSearch from '../ui/SelectWithSearch';

const StyledPageSelectWithSearch = styled.div`
  float: left;
`;

const PageSelectWithSearch = props => {
  return (
    <StyledPageSelectWithSearch>
      <SelectWithSearch {...props} />
    </StyledPageSelectWithSearch>
  );
};

export default PageSelectWithSearch;
