import React, { useContext, useRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import List from '../../components/ui/List';
import { TIME_FORMAT_DAY, TIME_FORMAT_HOURS_MINUTES } from '../../utils/constants';
import useApi from '../../hooks/useApi';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import HelpModal from './HelpModal';
import Popconfirm from 'antd/es/popconfirm';
import MarkDownEditor from './MarkDownEditor';

const WikiList = () => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startLoader, setStartLoader] = useState({});
  const [reloader, setReloader] = useState({});

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [editModal, setEditModal] = useState(undefined);
  const editModalRef = useRef();

  const { loading, data, error, fetchData } = useApi('get', 'wiki/list?offset=0&limit=1000');

  const wikis = error || !data ? [] : data;

  useEffect(() => {
    if (editModalRef.current && !editModal) {
      setReloader({ ...reloader, [editModalRef.current]: true });
    }

    editModalRef.current = editModal;
  }, [editModal, reloader]);

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Text'),
      dataIndex: 'text',
      key: 'text',
      render: text => {
        if (!text) {
          return '-';
        } else {
          let shortText = text;
          if (shortText.length > 40) {
            shortText = shortText.substring(0, 40) + '...';
          }
          return shortText;
        }
      },
    },
    {
      title: t('Created at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('Created by'),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: t('Updated at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('Updated by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
  ];

  const handleDelete = async id => {
    await apiCall('delete', 'wiki/delete', {
      id,
    });
    await fetchData();
  };

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          onClick={e => {
            e.stopPropagation();
            setEditModal(record.id);
          }}
          disabled={!user.permissions.includes('manage_wiki')}
        >
          <Icon type="edit" />
          {user.permissions.includes('manage_wiki') ? t('Show and edit') : t('Show')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Are you sure you want to delete this page and all of its links?')}
          onConfirm={e => {
            e.stopPropagation();
            handleDelete(record.id);
          }}
          onCancel={e => {
            e.stopPropagation();
          }}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              red
              disabled={!user.permissions.includes('manage_wiki')}
              onClick={e => e.stopPropagation()}
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <List
        rowKey="id"
        columns={columns}
        dataSource={wikis}
        spinning={loading}
        localPagination={true}
        hideSearch={true}
        actions={actions}
        expandRowByClick={true}
        onExpand={(expanded, record) => setReloader({ ...reloader, [record.id]: true })}
        expandedRowRender={record => {
          return (
            <MarkDownEditor
              markdownId={record.id}
              editable={false}
              startLoader={startLoader[record.id]}
              setStartLoader={value => setStartLoader({ ...startLoader, [record.id]: value })}
              shouldRefetchData={reloader[record.id]}
              setShouldRefetchData={value => setReloader({ ...reloader, [record.id]: value })}
              canBeSmall={true}
            />
          );
        }}
      />
      {!!editModal && (
        <HelpModal open={!!editModal} setOpen={() => setEditModal(undefined)} usableMarkdownId={editModal} />
      )}
    </>
  );
};

export default WikiList;
