import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import InputNumber from 'antd/es/input-number';
import Radio from 'antd/es/radio';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';

import dayjs from 'dayjs';
import Icon from '../../ui/Icon';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: inline-block;
  margin-bottom: 18px;
  background-color: ${({ theme }) => theme.color.beige};
  width: 100%;
  padding: 6px;
  border-radius: 3px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Header = styled.div`
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
`;

const Container = styled.div`
  display: flex;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  display: inline-block;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const InfoText = styled.div`
  font-weight: 600;
  margin-bottom: 12px;
`;

const VesselRow = styled.div`
  display: flex;
`;

const Imo = styled.div`
  width: 25%;
`;

const OldRTA = styled.div`
  width: 30%;
`;

const NewRTA = styled.div`
  width: 30%;
`;

const Laytime = styled.div`
  width: 20%;
`;

const RTABorder = styled.div`
  border: 1px solid #d9d9d9;
  padding: 3px;
  display: inline-block;
  background-color: #fafafa;
`;

const IconContainer = styled.span`
  margin-left: 40px;
  padding-left: 4px;
  margin-top: -1px;

  svg {
    width: 17px;
    height: 17px;
  }
`;

const TimeLabel = styled.div`
  margin-left: 4px;
  margin-right: 12px;
  font-size: 15px;
  margin-top: 5px;
`;

const RadioContainer = styled.div`
  margin-bottom: 6px;
`;

const MultiSelectModal = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    calendarVessels,
    multiselectedVessels,
    setMultiselectedModalOpen,
    canvasTimeStart,
    multiselectedMove,
    canvasWidth,
    canvasTimeEnd,
    setMultiselectedVessels,
    setMoveSelection,
    setMultiselectDisableScrolling,
    keepMultiVessels,
    setMultiselectedMove,
    sendMultiSelectVesselData,
  } = useContext(BerthPlanningToolContext);

  const millisecondInPixels =
    canvasWidth / dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds();

  const getTimeFromPixels = pixels => {
    const pixelInMilliseconds =
      dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;

    return dayjs(canvasTimeStart + pixels * pixelInMilliseconds).format();
  };

  const getStartTime = vessel => {
    if (vessel.state === 6) {
      return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else if (vessel.state === 5) {
      return dayjs.max([dayjs.min([dayjs(vessel.pta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else if (vessel.state === 7) {
      return dayjs.max([dayjs.min([dayjs(vessel.rta), dayjs(vessel.total_start)]), dayjs(canvasTimeStart)]);
    } else {
      return dayjs.max([dayjs(vessel.start_time), dayjs(canvasTimeStart)]);
    }
  };

  const getMinutesFromPixels = pixels => {
    const pixelInMilliseconds =
      dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;
    const milliseconds = pixelInMilliseconds * pixels;
    return Math.floor(milliseconds / 60000) % 60;
  };

  const getHoursFromPixels = pixels => {
    const pixelInMilliseconds =
      dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;
    const milliseconds = pixelInMilliseconds * pixels;
    return Math.floor(milliseconds / 3600000);
  };

  const [moveDirection, setMoveDirection] = useState(multiselectedMove['x'] < 0 ? 'minus' : 'plus');
  const [moveAmountHours, setMoveAmountHours] = useState(getHoursFromPixels(Math.abs(multiselectedMove['x'])));
  const [moveAmountMinutes, setMoveAmountMinutes] = useState(getMinutesFromPixels(Math.abs(multiselectedMove['x'])));

  const title = t('Update slot reservations');

  const dataIsValid = () => {
    return (moveAmountHours || moveAmountHours === 0) && (moveAmountMinutes || moveAmountMinutes === 0);
  };

  const sendData = () => {
    sendMultiSelectVesselData({
      vessels: multiselectedVessels,
      direction: moveDirection,
      hours: moveAmountHours,
      minutes: moveAmountMinutes,
    });
    closeModal();
  };

  const onAdjustmentHoursChange = e => {
    setMoveAmountHours(e);
  };
  const onAdjustmentMinutesChange = e => {
    setMoveAmountMinutes(e);
  };

  const closeModal = () => {
    keepMultiVessels(multiselectedVessels);
    setMultiselectedModalOpen(false);
    setMultiselectedVessels([]);
    setMoveSelection({
      startX: undefined,
      startY: undefined,
      moveX: undefined,
      moveY: undefined,
      scrollTop: undefined,
      scrollLeft: undefined,
      stopped: false,
    });
    setMultiselectDisableScrolling(false);
    setMultiselectedMove({ x: 0, id: undefined });
  };

  const pixelsFromTimes = (moveAmountHours * 3600000 + moveAmountMinutes * 60000) * millisecondInPixels;

  const selectedVessels = multiselectedVessels.map(v => {
    const vessel = calendarVessels.find(vl => vl.port_call_master_id === v.id);
    const left =
      dayjs.duration(dayjs(getStartTime(vessel)).diff(dayjs(canvasTimeStart))).asMilliseconds() * millisecondInPixels;
    return {
      name: vessel.name,
      imo: vessel.imo,
      oldRta: vessel.rta,
      rta: getTimeFromPixels(left + pixelsFromTimes * (moveDirection === 'plus' ? 1 : -1)),
      port_call_master_id: vessel.port_call_master_id,
      laytime: vessel.laytime,
    };
  });

  return (
    <Modal title={title} open={true} width={800} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <ScrollContainer>
          <FirstColumnSplit>
            <InfoText>{t('Adjust here how much you want to move the selected vessels.')}</InfoText>
            <RowContainer style={{ marginRight: '0px' }}>
              <RowHeader>
                {t('Adjustment in hours and minutes')}
                <Required>*</Required>
              </RowHeader>
              <RadioContainer>
                <Radio.Group defaultValue={moveDirection} onChange={e => setMoveDirection(e.target.value)}>
                  <Radio value="plus">{t('Forward')}</Radio>
                  <Radio value="minus">{t('Backward')}</Radio>
                </Radio.Group>
              </RadioContainer>
              <Container>
                <InputNumber
                  value={moveAmountHours}
                  style={{
                    width: '60px',
                    height: '34px',
                  }}
                  onChange={onAdjustmentHoursChange}
                  placeholder={t('Adjustment')}
                  min={0}
                />
                <TimeLabel>{t('hours')}</TimeLabel>
                <InputNumber
                  value={moveAmountMinutes}
                  style={{
                    width: '60px',
                    height: '34px',
                  }}
                  onChange={onAdjustmentMinutesChange}
                  placeholder={t('Adjustment')}
                  min={0}
                  max={59}
                />
                <TimeLabel>{t('minutes')}</TimeLabel>
              </Container>
            </RowContainer>
          </FirstColumnSplit>
          <RowContainer>
            <Header>{t('Selected vessels')}</Header>
          </RowContainer>
          {selectedVessels.map(vessel => (
            <Row key={vessel.port_call_master_id}>
              <VesselRow>
                <Imo style={{ fontWeight: 600 }}>{vessel.name}</Imo>
                <OldRTA>{t('Old RTA')}</OldRTA>
                <NewRTA>{t('New RTA')}</NewRTA>
                <Laytime>{t('Laytime')}</Laytime>
              </VesselRow>
              <VesselRow>
                <Imo style={{ marginTop: '6px' }}>{vessel.imo}</Imo>
                <OldRTA>
                  <RTABorder>{vessel.oldRta ? dayjs(vessel.oldRta).format('DD.MM.YYYY HH:mm') : '-'}</RTABorder>
                  <IconContainer>
                    <Icon type="arrow-right" />
                  </IconContainer>
                </OldRTA>
                <NewRTA>
                  <RTABorder style={{ fontWeight: 600 }}>
                    {vessel.rta ? dayjs(vessel.rta).format('DD.MM.YYYY HH:mm') : '-'}
                  </RTABorder>
                </NewRTA>
                <Laytime style={{ marginTop: '6px' }}>
                  {vessel.laytime ? dayjs.duration(vessel.laytime).format('HH:mm') : '-'}
                </Laytime>
              </VesselRow>
            </Row>
          ))}
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid()} onClick={sendData}>
            {t('Save')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default MultiSelectModal;
