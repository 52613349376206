import shipIconSource from '../../images/icons/ship-bg.svg';
import draftIconSource from '../../images/icons/draft-icon-map.svg';
import { Tooltip } from 'chart.js';

Tooltip.positioners.myCustomPositioner = function(items) {
  const pos = Tooltip.positioners.average(items);

  if (pos === false) {
    return false;
  }

  return {
    x: pos.x,
    y: this.chart.scales.y.getPixelForValue(this.chart.scales.y.min),
  };
};

export const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = '#ffffff';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.border = '1px solid #D8D8D8';
    tooltipEl.style.color = '#4A4A4A';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    tooltipEl.className = 'custom-tooltip-div';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context, t) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableBody = document.createElement('tbody');
    tableBody.style.fontSize = '12px';
    tableBody.style.fontFamily = "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"; // eslint-disable-line quotes

    let speedValues = [];
    let draftValues = [];

    for (let i = 0; i < titleLines.length; i++) {
      const datasetLabel = tooltip.dataPoints[i].dataset.label;
      const value = tooltip.dataPoints[i].formattedValue;

      let newValue = true;

      if (datasetLabel === t('Draft')) {
        const valueAlreadyCreated = draftValues.findIndex(v => v === value) > -1;

        if (valueAlreadyCreated) {
          newValue = false;
        } else {
          draftValues.push(value);
        }
      }

      if (datasetLabel === t('Vessel speed')) {
        const valueAlreadyCreated = speedValues.findIndex(v => v === value) > -1;

        if (valueAlreadyCreated) {
          newValue = false;
        } else {
          speedValues.push(value);
        }
      }

      if (newValue) {
        const title = titleLines[i];
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const td1 = document.createElement('td');
        td1.style.borderWidth = 0;
        td1.style.fontWeight = 'bold';
        const text1 = document.createTextNode(title);

        td1.appendChild(text1);
        tr.appendChild(td1);
        tableBody.appendChild(tr);

        const body = bodyLines[i];

        const colors = tooltip.labelColors[i];
        let indicator = null;
        if (datasetLabel === t('Draft')) {
          indicator = document.createElement('img');
          indicator.src = draftIconSource;
          indicator.style.marginLeft = '-1px';
          indicator.style.marginTop = '-2px';
          indicator.style.marginRight = '2px';
        } else if (datasetLabel === t('Latest speed')) {
          indicator = document.createElement('img');
          indicator.src = shipIconSource;
          indicator.style.height = '17px';
          indicator.style.width = '17px';
          indicator.style.marginRight = '4px';
          indicator.style.marginTop = '-1px';
        } else {
          indicator = document.createElement('span');
          indicator.style.background = colors.backgroundColor;
          indicator.style.borderColor = colors.borderColor;
          indicator.style.borderWidth = '2px';
          indicator.style.marginRight = '8px';
          indicator.style.marginLeft = '4px';
          indicator.style.marginTop = '4px';
          indicator.style.borderRadius = '50%';
          indicator.style.height = '8px';
          indicator.style.width = '8px';
          indicator.style.display = 'inline-block';
        }

        const tr2 = document.createElement('tr');
        tr2.style.backgroundColor = 'inherit';
        tr2.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.display = 'flex';
        td.style.borderWidth = 0;
        td.style.whiteSpace = 'nowrap';
        tr2.style.lineHeight = '16px';
        tr2.style.position = 'relative';
        tr2.style.top = '-4px';

        const text = document.createTextNode(body);

        td.appendChild(indicator);
        td.appendChild(text);
        tr2.appendChild(td);
        tableBody.appendChild(tr2);
      }
    }

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    // tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 14 + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
