import React, { createContext, useContext, useEffect, useRef } from 'react';

import useApi from '../hooks/useApi';
import { UserContext } from './UserContext';
import { SideHelpType } from '../components/info/WikiConstants';

export const WikiContext = createContext();

export const WikiProvider = ({ children }) => {
  const { modules, user } = useContext(UserContext);

  const { loading, data, error, fetchData } = useApi(
    'get',
    'wiki/list-links?offset=0&limit=1000',
    null,
    null,
    modules?.wiki_module === 'enabled' && user.permissions.includes('view_wiki')
  );

  const { loading: loadingWiki, data: dataWiki, error: errorWiki, fetchData: fetchDataWiki } = useApi(
    'get',
    'wiki/list?offset=0&limit=1000',
    null,
    null,
    modules?.wiki_module === 'enabled' && user.permissions.includes('view_wiki')
  );

  const availableWikiLinks = error ? [] : data || [];
  const availableWikis = errorWiki ? [] : dataWiki || [];

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const sideHelpLinks = availableWikiLinks?.filter(l => l.object_type === SideHelpType);
  const sideHelpPages = availableWikis?.filter(w => sideHelpLinks?.some(l => l.markdown_id === w.id));

  return (
    <WikiContext.Provider
      value={{
        availableWikiLinks,
        availableWikis,
        loadingWikiLinks: loading,
        loadingWikis: loadingWiki,
        fetchWikiData: fetchDataWiki,
        fetchWikiLinkData: fetchData,
        sideHelpPages,
      }}
    >
      {children}
    </WikiContext.Provider>
  );
};
