import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonLight from '../../ui/ButtonLight';
import { UserContext } from '../../../context/UserContext';
import Icon from '../../ui/Icon';
import DebounceSelect from '../../ui/DebounceSelect';

const RowAdderTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 16px;
  font-weight: 600;
`;

const RowAdderBottom = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 2px 16px;
  display: flex;
  justify-content: space-between;

  button {
    margin-bottom: 10px;
  }
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: 100%;
`;

const RowAdderBody = styled.div`
  padding: 16px 24px;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
`;

const AddPriceModification = styled.div`
  margin-top: 8px;
  margin-left: 24px;
`;

const TrashContainer = styled.div`
  line-height: 21px;
  margin-left: 12px;
  margin-top: 1px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-right: 4px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(212, 212, 212, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const RowAdderContainer = styled.div`
  width: 500px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e8e8e8;
  display: block;
  height: fit-content;
`;

const AddInputButton = styled.span`
  color: ${props => (props.disabled ? props.theme.color.grey_light : props.theme.color.secondary)};
  display: inline-flex;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  i {
    height: 21px;
    width: 21px;
  }

  svg {
    height: 21px;
    width: 21px;
  }
`;

const AddInputText = styled.div`
  color: ${props => (props.disabled ? props.theme.color.grey_light : props.theme.color.secondary)};
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const AUTOMATICCARGO = 'POLO_AUTOMATIC_CARGO';

const RowAdder = ({
  rowAdderOpen,
  setRowAdderOpen,
  rowAdderSelection,
  rowAdderModifications,
  setRowAdderModifications,
  setRowAdderSelection,
  setInputData,
  inputData,
  setRowInputChanged,
  template,
}) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const addPriceModification = () => {
    setRowAdderModifications([
      ...rowAdderModifications,
      {
        name: '',
        amount: 0,
      },
    ]);
  };

  const automaticCargo = rowAdderSelection?.code === AUTOMATICCARGO;
  const allowAutomaticCargo = template || !!inputData.port_call_data?.cargo_invoice_rows;

  const handleProductAdderChange = (e, data) => {
    setRowAdderSelection(data?.data);

    if (data?.data?.code === AUTOMATICCARGO) {
      setRowAdderModifications([]);
    } else {
      if (rowAdderModifications?.length === 0) {
        setRowAdderModifications(
          data?.data?.modifiers.map(m => {
            return {
              ...m,
              new: true,
            };
          }) || []
        );
      }
    }
  };

  const handleModificationChange = (e, index, data) => {
    const newArray = [...rowAdderModifications];
    newArray[index] = data.data;
    setRowAdderModifications(newArray);
  };

  const handleAddRow = () => {
    if (rowAdderSelection.code === AUTOMATICCARGO && !template) {
      let cargoRows = [];
      if (inputData.port_call_data?.cargo_invoice_rows) {
        Object.values(inputData.port_call_data.cargo_invoice_rows).forEach(list => {
          list.forEach(v => {
            cargoRows.push(v);
          });
        });
      }

      setInputData({
        ...inputData,
        rows: [
          ...inputData.rows,
          ...cargoRows.map(c => {
            let combUnit = '';
            c.product.pricing.units.forEach((u, i) => {
              if (i > 0) {
                combUnit += ' / ';
              }
              combUnit += u.name;
            });

            return {
              modifications: [],
              amounts: c.amounts.map(u => {
                return {
                  amount: u.amount,
                  unit_name: u.unit_name,
                };
              }),
              code: c.product.code,
              name: c.product.name,
              price: 0,
              unit_amount: c.product.pricing.unit_price,
              combined_unit: combUnit,
              vat: c.product.vat,
              timeframe: { from: null, to: null, at: null },
              product_data: c.product,
            };
          }),
        ],
      });
      setRowAdderSelection(null);
      setRowAdderModifications([]);
      setRowAdderOpen(null);
    } else {
      const mods = rowAdderModifications.filter(m => m.name?.length > 0);
      let combUnit = '';
      rowAdderSelection.pricing.units.forEach((u, i) => {
        if (i > 0) {
          combUnit += ' / ';
        }
        combUnit += u.name;
      });
      setInputData({
        ...inputData,
        rows: [
          ...inputData.rows,
          {
            modifications: mods.map(m => {
              return {
                name: m.name,
                code: m.code,
                price: 0,
                adjustment: m.adjustment,
              };
            }),
            amounts: rowAdderSelection.pricing.units.map(u => {
              return {
                amount: 0,
                unit_name: u.name,
              };
            }),
            code: rowAdderSelection.code,
            name: rowAdderSelection.name,
            price: 0,
            unit_amount: rowAdderSelection.pricing.unit_price,
            combined_unit: combUnit,
            vat: rowAdderSelection.vat,
            timeframe: { from: null, to: null, at: null },
            product_data: rowAdderSelection,
          },
        ],
      });
      setRowAdderSelection(null);
      setRowAdderModifications([]);
      setRowAdderOpen(null);
    }
  };

  const handleEditRow = () => {
    const mods = rowAdderModifications.filter(m => m.code?.length > 0);
    let combUnit = rowAdderSelection.combined_unit || '';

    if (!rowAdderSelection.combined_unit) {
      rowAdderSelection.pricing.units.forEach((u, i) => {
        if (i > 0) {
          combUnit += ' / ';
        }
        combUnit += u.name;
      });
    }

    const newRows = [...inputData.rows];
    newRows[rowAdderOpen.index] = {
      modifications: mods.map(m => {
        return {
          name: m.name,
          code: m.code,
          price: 0,
          adjustment: m.adjustment,
        };
      }),
      amounts: rowAdderSelection.pricing
        ? rowAdderSelection.pricing.units.map((u, i) => {
          return {
            amount: inputData.rows[rowAdderOpen.index]?.amounts[i]?.amount || 0,
            unit_name: u.name,
          };
        })
        : rowAdderSelection.amounts,
      code: rowAdderSelection.code,
      name: rowAdderSelection.name,
      price: 0,
      unit_amount: rowAdderSelection.pricing ? rowAdderSelection.pricing.unit_price : rowAdderSelection.unit_amount,
      combined_unit: combUnit,
      vat: rowAdderSelection.vat,
      timeframe: inputData.rows[rowAdderOpen.index]?.timeframe || { from: null, to: null, at: null },
      product_data: rowAdderSelection,
    };
    setInputData({
      ...inputData,
      rows: newRows,
    });
    setRowAdderSelection(null);
    setRowAdderModifications([]);
    setRowAdderOpen(null);
    setRowInputChanged?.(true);
  };

  const deleteMod = i => {
    const newArray = [...rowAdderModifications];
    newArray.splice(i, 1);
    setRowAdderModifications(newArray);
  };

  const fetchProducts = async value => {
    const params = {
      query: { text: value },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [{ field: 'name', order: 'asc' }],
    };

    const result = await apiCall('post', 'invoicing/v2/product/search', params);
    if (result?.status === 200) {
      let list = result.data?.results.products?.map(d => {
        return {
          value: d.code,
          label: d.name + ' (' + d.code + ')',
          data: d,
        };
      });

      if (!rowAdderOpen.new || !allowAutomaticCargo) {
        list = list.filter(r => r.value !== 'POLO_AUTOMATIC_CARGO');
      }

      return list;
    }

    return [];
  };

  const fetchModifications = async value => {
    const params = {
      query: { text: value },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [{ field: 'name', order: 'asc' }],
    };

    const result = await apiCall('post', 'invoicing/v2/modifier/search', params);
    if (result?.status === 200) {
      return result.data?.results.modifiers?.map(d => {
        return {
          value: d.code,
          label: d.name + ' (' + d.code + ')',
          data: d,
        };
      });
    }

    return [];
  };

  return (
    <Container>
      <RowAdderContainer>
        <RowAdderTitle>{rowAdderOpen.new ? t('Add new product row') : t('Edit product row')}</RowAdderTitle>
        <RowAdderBody>
          <RowContainer>
            <RowHeader>{t('Product')}</RowHeader>
            <DebounceSelect
              placeholder={t('Product')}
              fetchOptions={fetchProducts}
              onChange={(value, data) => handleProductAdderChange(value, data)}
              onSelect={(value, data) => handleProductAdderChange(value, data)}
              value={rowAdderSelection?.code}
              style={{ width: '100%' }}
              firstLoad={!rowAdderOpen.new ? rowAdderSelection?.code : null}
            />
          </RowContainer>
          {rowAdderModifications?.map((mod, i) => (
            <RowContainer key={i} style={{ paddingLeft: '36px', paddingTop: '12px' }}>
              {i === 0 && <RowHeader>{t('Price modifications')}</RowHeader>}
              <SelectContainer>
                <DebounceSelect
                  placeholder={t('Modification')}
                  fetchOptions={fetchModifications}
                  onChange={(e, data) => handleModificationChange(e, i, data)}
                  onSelect={(e, data) => handleModificationChange(e, i, data)}
                  value={mod?.code}
                  style={{ width: 'calc(100% - 64px)' }}
                  firstLoad={!rowAdderOpen.new || mod?.new ? mod?.code : null}
                />
                <TrashContainer onClick={() => deleteMod(i)}>
                  <Icon type="trash" />
                </TrashContainer>
              </SelectContainer>
            </RowContainer>
          ))}
          <AddPriceModification>
            <AddInputButton
              style={{ marginTop: '8px' }}
              onClick={() => {
                if (!automaticCargo) {
                  addPriceModification();
                }
              }}
              disabled={automaticCargo}
            >
              <Icon type={'plus'} />
              <AddInputText disabled={automaticCargo}>{t('Add new price modification')}</AddInputText>
            </AddInputButton>
          </AddPriceModification>
        </RowAdderBody>
        <RowAdderBottom>
          <div />
          <div>
            <ButtonLight
              style={{ marginRight: '12px' }}
              cancel
              onClick={() => {
                setRowAdderOpen(false);
                setRowAdderSelection(null);
                setRowAdderModifications([]);
              }}
            >
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight onClick={rowAdderOpen.new ? handleAddRow : handleEditRow} disabled={!rowAdderSelection}>
              {rowAdderOpen.new ? t('Add') : t('Save')}
            </ButtonLight>
          </div>
        </RowAdderBottom>
      </RowAdderContainer>
    </Container>
  );
};

export default RowAdder;
