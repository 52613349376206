import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Switch from 'antd/es/switch';
import Popover from 'antd/es/popover';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';

const Container = styled.div`
  padding: 12px;
`;

const ActionButton = styled(ButtonLight)`
  width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  height: 24px;
  padding: 3px;
  margin-top: 4px;

  i {
    margin-left: 5px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const SwitchLabel = styled.div`
  font-size: 13px;
  margin-left: 12px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : '8px')};

  button {
    margin-top: 3px;
  }
`;

const Indentation = styled.div`
  margin-left: 40px;
`;

const SmallHeader = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.dark_grey};
  max-width: 200px;
`;

const BptLayersComponent = () => {
  const { namespace, modules } = useContext(UserContext);
  const { vesselLayerOn, setVesselLayerOn, vesselOptions, setVesselOptions } = useContext(BerthPlanningToolContext);
  const { t } = useTranslation(namespace);

  const toggleVesselType = (type, e) => {
    setVesselOptions({ ...vesselOptions, [type]: e });
  };

  const LayersInPopover = () => {
    return (
      <Container>
        <SmallHeader>{t('Here you can choose which layers are visible')}</SmallHeader>
        <SwitchContainer>
          <Switch size="small" checked={vesselLayerOn} onChange={e => setVesselLayerOn(e)} aria-label="Show vessels" />
          <SwitchLabel>{t('All vessels')}</SwitchLabel>
        </SwitchContainer>
        {modules.queue_module === 'enabled' && (
          <Indentation>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.accepted}
                onChange={e => toggleVesselType('accepted', e)}
              />
              <SwitchLabel>{t('Accepted')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.offered}
                onChange={e => toggleVesselType('offered', e)}
              />
              <SwitchLabel>{t('Offered')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.notPlanned}
                onChange={e => toggleVesselType('notPlanned', e)}
              />
              <SwitchLabel>{t('Not Planned')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.reserved}
                onChange={e => toggleVesselType('reserved', e)}
              />
              <SwitchLabel>{t('Reserved')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.updated}
                onChange={e => toggleVesselType('updated', e)}
              />
              <SwitchLabel>{t('Updated')}</SwitchLabel>
            </SwitchContainer>
          </Indentation>
        )}
        {modules.queue_module !== 'enabled' && (
          <Indentation>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.planned}
                onChange={e => toggleVesselType('planned', e)}
              />
              <SwitchLabel>{t('Planned')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.uncommitted}
                onChange={e => toggleVesselType('uncommitted', e)}
              />
              <SwitchLabel>{t('Uncommitted')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.notPlanned}
                onChange={e => toggleVesselType('notPlanned', e)}
              />
              <SwitchLabel>{t('Not Planned')}</SwitchLabel>
            </SwitchContainer>
            <SwitchContainer>
              <Switch
                size="small"
                checked={!vesselLayerOn ? false : vesselOptions.reserved}
                onChange={e => toggleVesselType('reserved', e)}
              />
              <SwitchLabel>{t('Reserved')}</SwitchLabel>
            </SwitchContainer>
          </Indentation>
        )}
      </Container>
    );
  };

  return (
    <Popover content={LayersInPopover()} placement="bottom" trigger="click" overlayClassName="wiki-actions-class">
      <div style={{ height: '30px' }}>
        <ActionButton cancel>
          <Icon type="layers" />
        </ActionButton>
      </div>
    </Popover>
  );
};

export default BptLayersComponent;
