import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { MapContainer as Map } from 'react-leaflet';
import L from 'leaflet';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import VesselsSelection from './VesselsSelection';
import { TIME_FORMAT } from '../../utils/constants';
import IncidentsModalMapInnerContainerPreview from './IncidentsModalMapInnerContainerPreview';
import Button from '../ui/Button';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';

const Container = styled.div`
  display: block;
  margin: 6px 0;
`;

const InnerContainer = styled.div`
  padding: 12px 12px 8px 12px;
`;

const TopRow = styled.div`
  display: flex;
  background-color: #f2f2f2;
  width: calc(100% - 3px);
  padding: 2px 6px;
  font-weight: 600;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
`;

const Header = styled.div`
  margin-right: 6px;
`;

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 24px;
  display: inline-grid;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 3px;
    margin-left: -1px;
  }
`;

const Datas = styled.div`
  display: block;
`;

const RowContainer = styled.div`
  width: 100%;
  position: relative;

  textarea {
    resize: none;
  }

  .ant-input[disabled] {
    color: #4a4a4a;
    background-color: white;
    cursor: default;
  }
`;

const TextArea = styled.div`
  text-align: left;
  margin-left: 6px;
  margin-top: 12px;
  white-space: pre-line;
`;

const Text = styled.div`
  margin-left: 6px;
`;

const ColoredText = styled(Text)`
  color: ${props => (props.level > 7 ? '#F44336' : props.level < 4 ? '#80d183' : '#F5BD3E')};
  font-weight: 600;
`;

const Coordinates = styled.div`
  margin-top: 12px;
`;

const Line = styled.div`
  margin: 0px 6px 0px 12px;
`;

const zoomPrevDiv = L.DomUtil.get('zoompreventer');
if (zoomPrevDiv) {
  L.DomEvent.disableClickPropagation(zoomPrevDiv);
}

const AddIncidentPathItemPreview = ({ data, index, inputData, type }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [appliedZoom, setAppliedZoom] = useState(9);

  const dataKey = data.data_model?.name;

  const handleZoomButton = selectedZoom => {
    setAppliedZoom(selectedZoom);
  };

  return (
    <Container id={'incident-path-item-' + index}>
      <TopRow>
        <Header>
          {index + 1}. {t(data.header, { type })}
        </Header>
      </TopRow>
      <InnerContainer>
        <Datas>
          {data.data_model.type === 'string' && data.type !== 'textArea' && (
            <Text>
              {inputData[dataKey] || inputData[dataKey] === false
                ? t(data.options.find(k => k.value === inputData[dataKey])?.label)
                : '-'}
            </Text>
          )}
          {data.data_model.type === 'datetime' && (
            <div style={{ display: 'flex' }}>
              <Text>{inputData[dataKey] ? dayjs(inputData[dataKey]).format(TIME_FORMAT) : t('Not selected.')}</Text>
              {data.addition.type === 'date' && inputData[data.addition.data_model.name] && (
                <>
                  <Line>-</Line>
                  <Text>
                    {inputData[data.addition.data_model.name]
                      ? dayjs(inputData[data.addition.data_model.name]).format(TIME_FORMAT)
                      : t('Not selected.')}
                  </Text>
                </>
              )}
            </div>
          )}
          {data.type === 'slider' && <ColoredText level={inputData[dataKey]}>{inputData[dataKey]}</ColoredText>}
          {!!data.addition && data.addition.type === 'multiselect_vessels' && (
            <VesselsSelection
              data={data.addition}
              inputData={inputData}
              dataKey={data.addition.data_model.name}
              upperDataKey={dataKey}
              preview={true}
            />
          )}
          {(data.addition?.type === 'textArea' || data.type === 'textArea') && (
            <RowContainer>
              <TextArea>
                {data.addition
                  ? data.addition.data_model.name &&
                    inputData[data.addition.data_model.name] &&
                    inputData[data.addition.data_model.name].length > 0
                    ? inputData[data.addition.data_model.name]
                    : ''
                  : data.data_model.name &&
                    inputData[data.data_model.name] &&
                    inputData[data.data_model.name].length > 0
                    ? inputData[data.data_model.name]
                    : ''}
              </TextArea>
            </RowContainer>
          )}
          {!!data.addition && data.addition.type === 'map' && inputData[data.addition.data_model.name]?.length > 0 && (
            <RowContainer>
              <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('small', t)} onError={logError}>
                <Map
                  style={{
                    height: '160px',
                    width: '400px',
                    marginTop: '6px',
                  }}
                  center={
                    inputData[data.addition.data_model.name][0]?.geometry.type === 'Point'
                      ? [
                          inputData[data.addition.data_model.name][0]?.geometry.coordinates[1],
                          inputData[data.addition.data_model.name][0]?.geometry.coordinates[0],
                        ]
                      : [
                          inputData[data.addition.data_model.name][0]?.geometry.coordinates[0][0][1],
                          inputData[data.addition.data_model.name][0]?.geometry.coordinates[0][0][0],
                        ]
                  }
                  zoom={appliedZoom}
                  tap={false}
                  zoomControl={false}
                >
                  <IncidentsModalMapInnerContainerPreview
                    appliedZoom={appliedZoom}
                    data={inputData[data.addition.data_model.name]}
                    type={type}
                  />

                  <ZoomButtonContainer id={'zoompreventer'}>
                    <ZoomButton
                      data-testid="map-zoom-in"
                      copy
                      onClick={() => handleZoomButton(appliedZoom + 1)}
                      style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                    >
                      <Icon type="zoom-in" />
                    </ZoomButton>
                    <ZoomButton
                      data-testid="map-zoom-out"
                      copy
                      onClick={() => handleZoomButton(appliedZoom - 1)}
                      style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                    >
                      <Icon type="zoom-out" />
                    </ZoomButton>
                  </ZoomButtonContainer>
                </Map>
              </ErrorBoundary>
              {inputData[data.addition.data_model.name].length > 0 &&
                (inputData[data.addition.data_model.name][0]?.geometry.type === 'Point' ? (
                  <Coordinates>
                    <span style={{ paddingRight: '12px' }}>{t('Selected coordinates (latitude, longitude):')}</span>
                    {inputData[data.addition.data_model.name][0]?.geometry.type === 'Point'
                      ? '(' +
                        inputData[data.addition.data_model.name][0].geometry.coordinates[1] +
                        ', ' +
                        inputData[data.addition.data_model.name][0].geometry.coordinates[0] +
                        ')'
                      : '-'}
                  </Coordinates>
                ) : (
                  <Coordinates>
                    <span style={{ paddingRight: '12px' }}>{t('Amount of selected areas:')}</span>
                    {inputData[data.addition.data_model.name][0]?.geometry.type === 'Point'
                      ? '0'
                      : inputData[data.addition.data_model.name].length}
                  </Coordinates>
                ))}
            </RowContainer>
          )}
        </Datas>
      </InnerContainer>
    </Container>
  );
};
export default AddIncidentPathItemPreview;
