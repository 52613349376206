import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FeatureGroup, Polyline, Tooltip as LeafletTooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import MeasureMapToolContainer from './MeasureMapToolContainer';
import { METRIC, MILES, NAUTICALMILES, readableDistance } from './MapHelpers';

// TODO:
// The polyline drawings are shown as straight lines without taken into account the earth's curvature
// At some point we could add geodesic lines
// Check Leaflet.Geodesic

const MeasureContainer = styled.div`
  background: ${props => (props.enabled ? props.theme.color.secondary : props.theme.color.white)};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  padding: 4px 8px;

  &:hover {
    background: ${props => (props.enabled ? '#4990ee' : '#f4f4f4')};
  }

  svg {
    height: 22px;
    width: 22px;
    margin-left: -4px;
  }
`;

const Measure = styled.div`
  position: absolute;
  bottom: 132px;
  right: 10px;
  z-index: 100;
  color: #4a4a4a;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
`;

const eventHandlers = {
  onEdited: 'draw:edited',
  onDrawCreated: 'draw:created',
  onDrawStart: 'draw:drawstart',
  onDrawStop: 'draw:drawstop',
  onDrawVertex: 'draw:drawvertex',
  onEditStart: 'draw:editstart',
  onEditMove: 'draw:editmove',
  onEditResize: 'draw:editresize',
  onEditVertex: 'draw:editvertex',
  onEditStop: 'draw:editstop',
  onMousemove: 'mousemove',
  onClick: 'click',
};

export const MeasureMapTool = ({ measureDrawing, setMeasureDrawing }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [measuring, setMeasuring] = useState(false);
  const [editing, setEditing] = useState(false);

  const [distances, setDistances] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cursorLocation, setCursorLocation] = useState(null);

  const [showParts, setShowParts] = useState(false);

  const tooltipRef = useRef();

  const map = useMap();

  const onCursorLocationChange = evt => {
    setCursorLocation(evt.latlng);
  };

  L.drawLocal.edit.handlers.edit.tooltip.subtext = t('Location edit');

  useEffect(() => {
    if (measuring) {
      map.on(eventHandlers.onMousemove, onCursorLocationChange);
    } else {
      map.off(eventHandlers.onMousemove);
    }
  }, [measuring]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    tooltipRef.current.bringToFront();
  }, [measuring, editing]);

  return (
    <>
      <FeatureGroup ref={tooltipRef}>
        {locations?.length > 0 &&
          locations.map((l, index) => {
            if (index === 0) {
              return null;
            }

            return (
              <Polyline
                key={index}
                pathOptions={{
                  color: 'transparent',
                  weight: '2',
                  opacity: '1',
                }}
                positions={[locations[index], locations[index - 1]]}
                eventHandlers={{
                  click: () => {
                    setEditing(true);
                  },
                }}
              >
                <LeafletTooltip>
                  <div style={{ display: 'flex' }}>
                    <div style={{ borderRight: '1px solid #a0a0a0', marginRight: '4px', paddingRight: '4px' }}>
                      {readableDistance(distances[index], METRIC)}
                    </div>
                    <div style={{ borderRight: '1px solid #a0a0a0', marginRight: '4px', paddingRight: '4px' }}>
                      {readableDistance(distances[index], MILES)}
                    </div>
                    <div>{readableDistance(distances[index], NAUTICALMILES)}</div>
                  </div>
                </LeafletTooltip>
              </Polyline>
            );
          })}
      </FeatureGroup>
      <FeatureGroup>
        <MeasureMapToolContainer
          measureDrawing={measureDrawing}
          setMeasuring={setMeasuring}
          setLocations={setLocations}
          setDistances={setDistances}
          setCursorLocation={setCursorLocation}
          setShowParts={setShowParts}
          setEditing={setEditing}
          editing={editing}
          measuring={measuring}
          distances={distances}
          setMeasureDrawing={setMeasureDrawing}
          showParts={showParts}
          locations={locations}
          cursorLocation={cursorLocation}
        />
      </FeatureGroup>
      <Measure onClick={() => setMeasureDrawing(!measureDrawing)}>
        <Tooltip
          title={measureDrawing ? t('Finish measuring') : t('Measure distances')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          placement="left"
        >
          <MeasureContainer enabled={measureDrawing}>
            <Icon type="measure" />
          </MeasureContainer>
        </Tooltip>
      </Measure>
    </>
  );
};

export default MeasureMapTool;
