import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Select from 'antd/es/select';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import DebounceSelect from '../ui/DebounceSelect';
import DebounceAutocomplete from '../ui/DebounceAutocomplete';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  padding: 12px 12px 0 12px;
  overflow-x: auto;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #4a4a4a;
  }
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
  margin-left: 12px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / ${props => props.amount});
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
  min-width: 460px;
`;

const Actions = styled.div``;

const Action = styled.div`
  margin-bottom: 12px;
  margin-left: 24px;
  background: #f2f2f2;
  padding: 12px;
  border-radius: 4px;
`;

const ActionRow = styled.div`
  display: flex;
  width: 370px;
`;

const Title = styled.div`
  margin-right: 12px;
  line-height: 30px;
`;

const AddInputButton = styled.span`
  color: ${props => props.theme.color.secondary};
  display: inline-flex;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 6px;

  i {
    height: 21px;
    width: 21px;
  }

  svg {
    height: 21px;
    width: 21px;
  }
`;

const AddInputText = styled.div`
  color: ${props => props.theme.color.secondary};
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const Remove = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ theme }) => theme.color.warning};
  margin-top: 4px;
  margin-left: 12px;
  width: 20px;
`;

const Container = styled.div`
  display: block;
  margin-top: 6px;
`;

const ListOfVessels = styled.div`
  min-height: 34px;
  margin-top: 6px;
`;

const SelectedVessel = styled.div`
  border-radius: 4px;
  padding: ${props => (props.preview ? '2px 6px' : '4px 6px')};
  border: 1px solid #d8d8d8;
  display: inline-flex;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const NoVessels = styled.div`
  color: ${props => props.disabled && '#cfcfcf'};
  line-height: 34px;
  padding-left: ${props => props.preview && '6px'};
`;

const IconContainer = styled.div`
  margin-left: 12px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.color.warning};
    margin-top: -2px;
  }
`;

const InvoiceAlterationModal = ({ alteration, closeAndReload, closeModal, readOnly, duplicateCurrent }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const type = alteration ? 'edit' : 'new';

  const [inputData, setInputData] = useState({
    id: alteration?.id,
    name: alteration?.name,
    targetIMOs: alteration?.targets?.find(target => target.type === 'imos')?.value.imos || [],
    actions: alteration?.actions || [{ type: null, value: {} }],
  });

  const [input, setInput] = useState('');
  const [sending, setSending] = useState(false);

  const title = readOnly
    ? t('Exception')
    : !alteration || duplicateCurrent
        ? t('Create new exception')
        : t('Edit exception');

  const dataIsValid = () => {
    if (!inputData.name || inputData.targetIMOs.length === 0 || inputData.actions.length === 0) {
      return false;
    }

    let goodActions = true;

    inputData.actions.forEach(a => {
      if (goodActions) {
        if (
          !a.type ||
          !a.value.product_code ||
          ((a.type === 'add_modifier' || a.type === 'remove_modifier') && !a.value.modifier_code)
        ) {
          goodActions = false;
        }
      }
    });
    return goodActions;
  };

  const fetchVesselList = async value => {
    const result = await apiCall('post', 'search/vessel', {
      query: {
        text: value,
      },
      pagination: {
        limit: 100,
      },
      order_by: [
        {
          field: 'name',
          order: 'asc',
        },
      ],
    });
    if (result?.status === 200) {
      return result.data.results.vessels.map(d => {
        return { value: d.imo + '', label: d.name + ' (' + d.imo + ')' };
      });
    }

    return [];
  };

  const fetchProducts = async value => {
    const params = {
      query: { text: value },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [{ field: 'name', order: 'asc' }],
    };

    const result = await apiCall('post', 'invoicing/v2/product/search', params);
    if (result?.status === 200) {
      let list = result.data?.results.products?.map(d => {
        return {
          value: d.code,
          label: d.name + ' (' + d.code + ')',
          data: d,
        };
      });

      list = list.filter(l => l.value !== 'POLO_AUTOMATIC_CARGO');

      return list;
    }

    return [];
  };

  const fetchModifications = async value => {
    const params = {
      query: { text: value },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [{ field: 'name', order: 'asc' }],
    };

    const result = await apiCall('post', 'invoicing/v2/modifier/search', params);
    if (result?.status === 200) {
      return result.data?.results.modifiers?.map(d => {
        return {
          value: d.code,
          label: d.name + ' (' + d.code + ')',
          data: d,
        };
      });
    }

    return [];
  };

  const updateField = (e, name) => {
    let newInputData = { ...inputData };
    newInputData[name] = e.target.value;
    setInputData(newInputData);
  };

  const sendData = async () => {
    setSending(true);
    const dataToBeSend = {
      name: inputData.name,
      actions: inputData.actions.map(action => {
        const value = {
          product_code: action.value.product_code,
        };

        if (action.value.modifier_code) {
          value.modifier_code = action.value.modifier_code;
        }

        return {
          type: action.type,
          value,
        };
      }),
      targets: [
        {
          type: 'imos',
          value: {
            imos: inputData.targetIMOs.map(target => Number(target)),
          },
        },
      ],
    };

    if (inputData.id) {
      dataToBeSend.id = inputData.id;
    }

    let result;
    try {
      result = await apiCall(dataToBeSend.id ? 'put' : 'post', 'invoicing/v2/invoice-alteration', dataToBeSend);
    } catch (e) {
      setSending(false);
    }

    if (result?.data?.invoice_alteration) {
      closeAndReload();
    }
  };

  const addAction = () => {
    let newInputData = { ...inputData };
    newInputData.actions = [...newInputData.actions, { type: null, value: {} }];
    setInputData(newInputData);
  };

  const removeAction = index => {
    const newArray = [...inputData.actions];
    newArray.splice(index, 1);

    setInputData({
      ...inputData,
      actions: newArray,
    });
  };

  const handleTargetChange = value => {
    let newInputData = { ...inputData };
    newInputData.targetIMOs = [...newInputData.targetIMOs, value.value];
    setInputData(newInputData);
    setInput('');
  };

  const removeTarget = index => {
    const newArray = [...inputData.targetIMOs];
    newArray.splice(index, 1);

    setInputData({
      ...inputData,
      targetIMOs: newArray,
    });
  };

  const handleTypeChange = (value, index) => {
    let newInputData = { ...inputData };
    newInputData.actions[index] = {
      type: value,
      value:
        value === 'add_modifier' || value === 'remove_modifier'
          ? {
              product_code: null,
              modifier_code: null,
            }
          : {
              product_code: null,
            },
    };
    setInputData(newInputData);
  };

  const handleProductChange = (value, index) => {
    let newInputData = { ...inputData };
    newInputData.actions[index].value.product_code = value;
    setInputData(newInputData);
  };

  const handleModificationChange = (value, index) => {
    let newInputData = { ...inputData };
    newInputData.actions[index].value.modifier_code = value;
    setInputData(newInputData);
  };

  return (
    <Modal title={title} open={true} width={500} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <ScrollContainer>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Exception name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={alteration.name} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Name')}
                  value={inputData.name || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'name')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Targets')}
                <Required>*</Required>
              </RowHeader>
              <Container>
                <ListOfVessels>
                  {inputData.targetIMOs.length > 0 ? (
                    inputData.targetIMOs.map((vessel, i) => (
                      <SelectedVessel key={i} preview={readOnly}>
                        {vessel}
                        {!readOnly && (
                          <IconContainer onClick={() => removeTarget(i)}>
                            <Icon type="close" />
                          </IconContainer>
                        )}
                      </SelectedVessel>
                    ))
                  ) : (
                    <NoVessels preview={readOnly}>{t('No IMOs selected.')}</NoVessels>
                  )}
                </ListOfVessels>
                {!readOnly && (
                  <DebounceAutocomplete
                    placeholder={t('Search IMOs')}
                    fetchOptions={fetchVesselList}
                    onChange={value => setInput(value)}
                    onSelect={(value, data) => handleTargetChange(data)}
                    value={input}
                    style={{ maxWidth: '240px', width: '100%', marginTop: '6px' }}
                    allowClear={true}
                    onClear={() => setInput('')}
                  />
                )}
              </Container>
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Operations')}
                <Required>*</Required>
              </RowHeader>
              <Actions>
                {inputData.actions?.map((a, i) => {
                  return (
                    <Action key={i}>
                      <ActionRow style={{ width: '400px' }}>
                        <Title>{t('Type')}:</Title>
                        <Select
                          listHeight={150}
                          showSearch
                          placeholder={t('Type')}
                          optionFilterProp="label"
                          onChange={value => handleTypeChange(value, i)}
                          style={{ width: '100%' }}
                          options={[
                            {
                              value: 'add_product',
                              label: t('Add product'),
                            },
                            {
                              value: 'remove_product',
                              label: t('Remove product'),
                            },
                            {
                              value: 'add_modifier',
                              label: t('Add modification'),
                            },
                            {
                              value: 'remove_modifier',
                              label: t('Remove modification'),
                            },
                          ]}
                          value={a.type || null}
                          disabled={readOnly}
                        />
                        {!readOnly && inputData.actions?.length > 1 ? (
                          <Remove onClick={() => removeAction(i)}>
                            <Icon type={'trash'} />
                          </Remove>
                        ) : (
                          <Remove></Remove>
                        )}
                      </ActionRow>
                      {!!a.type && (
                        <ActionRow style={{ marginTop: '8px', paddingLeft: '18px' }}>
                          <Title style={{ width: '100px' }}>{t('Product')}:</Title>
                          <DebounceSelect
                            placeholder={t('Product')}
                            fetchOptions={fetchProducts}
                            onChange={value => handleProductChange(value, i)}
                            onSelect={value => handleProductChange(value, i)}
                            value={a.value.product_code}
                            style={{ maxWidth: '254px', width: '100%' }}
                            firstLoad={!!a.value.product_code}
                            disabled={readOnly}
                          />
                        </ActionRow>
                      )}
                      {(a.type === 'add_modifier' || a.type === 'remove_modifier') && (
                        <ActionRow style={{ marginTop: '8px', paddingLeft: '18px' }}>
                          <Title style={{ width: '100px' }}>{t('Modification')}:</Title>
                          <DebounceSelect
                            placeholder={t('Modification')}
                            fetchOptions={fetchModifications}
                            onChange={e => handleModificationChange(e, i)}
                            onSelect={e => handleModificationChange(e, i)}
                            value={a.value.modifier_code}
                            style={{ maxWidth: '254px', width: '100%' }}
                            firstLoad={!!a.value.modifier_code}
                            disabled={readOnly}
                          />
                        </ActionRow>
                      )}
                    </Action>
                  );
                })}

                {!readOnly && (
                  <AddInputButton onClick={() => addAction()}>
                    <Icon type={'plus'} />
                    <AddInputText>{t('Add action')}</AddInputText>
                  </AddInputButton>
                )}
              </Actions>
            </RowContainer>
          </Row>
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {type === 'new' ? t('Add') : t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default InvoiceAlterationModal;
