import React from 'react';

const BollardIcon = ({ number }) => (
  <svg width="16" height="16">
    <path d="M14.667 8A6.667 6.667 0 1 0 1.333 8a6.667 6.667 0 0 0 13.334 0z" fill="#3bb3ff" />
    <g className="text">
      <text x="50%" y="50%" fontSize="7px" dominantBaseline="middle" textAnchor="middle">
        {number}
      </text>
    </g>
  </svg>
);

export default BollardIcon;
