import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { NotificationContext } from '../../context/NotificationContext';

import styled from 'styled-components';

import List from 'antd/es/list';
import Button from '../ui/Button';

import NotificationChild from './NotificationChild';

const StyledChildren = styled.div``;

const StyledTextarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  padding: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.grey_dark};
  background: ${({ theme }) => theme.color.white};
  min-width: 500px;
  height: 48px;
  min-height: 48px;
  margin-left: ${({ theme }) => theme.sizing.gap_medium};
`;

const CompactButton = styled(Button)`
  margin: 0 0 0 0;
  margin-left: ${({ theme }) => theme.sizing.gap_huge};
`;

const CompactMarginButton = styled(Button)`
  margin: 0 0 0 0;
  margin-left: ${({ theme }) => theme.sizing.gap_small};
`;

const CompactReplyButton = styled(Button)`
  margin: 0 0 0 0;
  margin-left: ${({ theme }) => theme.sizing.gap_medium};
`;

const CompactListItem = styled(List.Item)`
  margin: 0 0 0 0;
  padding: 0;
`;

const NotificationChildren = ({ data, parentType, parentNotificationId, imo }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { apiSendNotification, setNotificationReplyOpen } = useContext(NotificationContext);

  const [replyVisible, setReplyVisible] = useState(false);
  const [replyMessage, setReplyMessage] = useState(undefined);

  const handleShowReply = () => {
    setNotificationReplyOpen(true);
    setReplyVisible(true);
  };

  const handleReplyMessageChange = e => {
    setReplyMessage(e.target.value);
  };

  const handleReplyCancel = () => {
    setReplyMessage(undefined);
    setReplyVisible(false);
    setNotificationReplyOpen(false);
  };

  const handleReplySend = () => {
    let type = 'ship';

    if (parentType === 'port') {
      type = 'port';
    }

    setReplyMessage(undefined);
    setReplyVisible(false);
    setNotificationReplyOpen(false);
    apiSendNotification(type, replyMessage, imo, null, null, parentNotificationId);
  };

  return (
    <>
      {data && (
        <StyledChildren>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <CompactListItem style={{ borderWidth: 0, borderColor: 'transparent' }}>
                <NotificationChild data={item} />
              </CompactListItem>
            )}
          />
        </StyledChildren>
      )}
      {user.permissions.includes('send_push_notification') && !replyVisible && (
        <div>
          <CompactReplyButton link onClick={handleShowReply}>
            {t('Reply to notification')}
          </CompactReplyButton>
        </div>
      )}
      {replyVisible && (
        <>
          <div>
            <StyledTextarea
              value={replyMessage}
              onChange={handleReplyMessageChange}
              data-testid={`send-notification-reply-textarea-${parentNotificationId}`}
            />
          </div>
          <CompactButton link onClick={handleReplySend} disabled={!replyMessage}>
            {t('Send')}
          </CompactButton>
          <CompactMarginButton link warning onClick={handleReplyCancel}>
            {t('Cancel')}
          </CompactMarginButton>
        </>
      )}
    </>
  );
};

export default NotificationChildren;
