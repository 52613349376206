import React, { useState } from 'react';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';

import { getOptions } from './MapUtils';
import dayjs from 'dayjs';
import { onMarkerMouseOver } from './MapHelpers';

const MapCurrentCrane = ({ coordinates, name, appliedZoomLevel, setCraneClicked, cranesUpdated }) => {
  const [direction, setDirection] = useState('top');

  const map = useMap();

  const options = getOptions();

  let iconSize =
    appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
      ? options.smallMarkerIconPixelSize
      : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
        ? options.mediumMarkerIconPixelSize
        : options.largeMarkerIconPixelSize;

  if (appliedZoomLevel < 13) {
    iconSize = 18;
  }

  const getCraneIcon = () => {
    return L.divIcon({
      html: `<div class="div-map-marker-vessel"><img src="${options.craneCurrentIcon}" 
        style="background-color:transparent;
          height:${iconSize}px;
          width:${iconSize}px;
          background-size:${iconSize - options.markerIconSpacingPixels}px;!important;"/></div>`,
      iconSize: [iconSize, iconSize],
      iconAnchor: [iconSize / 2, iconSize / 2],
      className: 'map-vessel-icon-class',
    });
  };

  const getTooltipOffset = () => {
    switch (direction) {
      case 'top':
        return L.point(0, -iconSize);
      case 'bottom':
        return L.point(0, +iconSize);
      case 'left':
        return L.point(-iconSize, 0);
      case 'right':
        return L.point(iconSize, 0);

      default:
        return L.point(0, -iconSize);
    }
  };

  return (
    <Marker
      icon={getCraneIcon()}
      position={L.GeoJSON.coordsToLatLng(coordinates)}
      data-id="current-crane"
      eventHandlers={{
        mouseover: () => {
          if (setCraneClicked) {
            setCraneClicked(name);
          }
          onMarkerMouseOver(map, coordinates, direction, setDirection);
        },
        mouseout: () => (setCraneClicked ? setCraneClicked(undefined) : null),
      }}
    >
      {!!name && (
        <Tooltip direction={direction} key={`${name}-${direction}`} offset={getTooltipOffset()}>
          <h4 style={{ textAlign: 'center' }}>{name}</h4>
          <h5>{cranesUpdated ? dayjs(cranesUpdated).format('DD.MM.YYYY HH:mm') : null}</h5>
        </Tooltip>
      )}
    </Marker>
  );
};

export default MapCurrentCrane;
