import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import { getDuration } from '../../../utils/utils';
import duration from 'dayjs/plugin/duration';
import { getPbpWorksFromData } from '../calendar/utility/contextHelpers';

dayjs.extend(duration);

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
  width: 100%;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Required = styled.span`
  color: red;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;

  .ant-picker-input > input[disabled] {
    color: #4a4a4a;
  }

  .ant-input[disabled] {
    color: #4a4a4a;
  }
`;

const ContainerWithButtons = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 20px;
`;

const TrashButton = styled(Button)`
  margin-left: 7px;
  margin-right: 5px;
  color: #1c1c1c;
  :hover {
    color: #d0011c;
  }
`;

const RowContainerSplitButtons = styled.div`
  padding: 14px 0px 0px 7px;
  display: inline-block;
  min-width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddButton = styled(Button)`
  margin-left: 0px;
  padding-right: 0px;
  color: #4990dd;
  :hover {
    color: #3483d9;
  }
`;

const RowHeaderContainer = styled.div`
  display: flex;
`;

const HeaderEnd = styled.div`
  margin-left: 3px;
`;

const SwitchContainer = styled.div`
  margin-right: 3px;
`;

const PredictiveBerthPlanModalCranes = ({
  data,
  index,
  handleCommodityChangeSplit,
  handleAmountChangeSplit,
  handleCraneOperationChangeSplit,
  handleCraneOperationTypeChangeSplit,
  handleCraneNameChangeSplit,
  handleCraneCompanyChangeSplit,
  onCraneStartChangeSplit,
  onCraneEndChangeSplit,
  onCraneManualEndChangeSplit,
  onCraneDurationChangeSplit,
  removeCraneSplitButton,
  onCraneOperationStartChangeSplit,
  onCraneOperationEndChangeSplit,
  onCraneOperationDurationChangeSplit,
  duplicateCraneSplitButton,
  readOnly,
  workId,
}) => {
  const { commodities, companies, cranes, operationTypes } = useContext(BerthPlanningToolContext);
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [updating, setUpdating] = useState(false);
  const [newChange, setNewChange] = useState(false);

  const timerRef = useRef();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const getCalculatedData = async () => {
      let validData = true;
      data.forEach(d => {
        if (
          !d.start_time ||
          !d.commodity_id ||
          !d.commodity_amount ||
          !d.operation_type ||
          !d.operation ||
          (d.manual_end_time && !d.end_time)
        ) {
          validData = false;
        }

        if (!validData) {
          return;
        }
      });
      if (validData) {
        try {
          let result = await apiCall('post', 'pbp/calculate-plan', {
            works: getPbpWorksFromData(data, false),
          });
          if (result?.status === 200) {
            let data = result.data;
            onCraneOperationDurationChangeSplit(data.duration, index);
            onCraneOperationStartChangeSplit(dayjs(data.start_time), index);
            onCraneOperationEndChangeSplit(data.end_time ? dayjs(data.end_time) : null, index);

            data.works.forEach((work, i) => {
              onCraneEndChangeSplit(work.end_time ? dayjs(work.end_time) : null, index, i);
              onCraneDurationChangeSplit(work.duration, index, i);
            });

            if (mounted.current) {
              setUpdating(false);
            }
          }
        } catch (error) {
          if (mounted.current) {
            setUpdating(false);
          }
        }
      } else {
        if (mounted.current) {
          setUpdating(false);
        }
      }
    };

    if (newChange && !updating) {
      setNewChange(false);
      setUpdating(true);

      getCalculatedData();
    }
  }, [
    apiCall,
    data,
    index,
    newChange,
    onCraneDurationChangeSplit,
    onCraneEndChangeSplit,
    onCraneOperationDurationChangeSplit,
    onCraneOperationEndChangeSplit,
    onCraneOperationStartChangeSplit,
    updating,
  ]);

  const onValueChanged = () => {
    let validData = true;
    data.forEach(d => {
      if (
        !d.start_time ||
        !d.commodity_id ||
        !d.commodity_amount ||
        !d.operation_type ||
        !d.operation ||
        (d.manual_end_time && !d.end_time)
      ) {
        validData = false;
      }

      if (!validData) {
        return;
      }
    });
    if (validData) {
      if (!newChange) {
        setNewChange(true);
      }
    }
  };

  const debounceOnValueChanged = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      onValueChanged();
      timerRef.current = undefined;
    }, 2000);
  };

  const operationTypeList =
    operationTypes && operationTypes.length > 0
      ? operationTypes.map(ot => {
        return { label: ot.name, value: ot.code };
      })
      : [];

  const getOperation = type => {
    if (type === 'load') {
      return t('Load');
    } else if (type === 'unload') {
      return t('Unload');
    }
  };

  return data.map((crane, i) => {
    let editOnlyThis = readOnly && (!!workId || workId === 0) ? workId === crane.id : false;
    const operationOptions = [];
    if (crane.commodity_id) {
      let option = commodities.find(c => c.id === crane.commodity_id);
      if (option?.load) {
        operationOptions.push({ label: t('Load'), value: 'load' });
      }
      if (option?.unload) {
        operationOptions.push({ label: t('Unload'), value: 'unload' });
      }
    }

    let efficiency = null;
    if (crane.commodity_id && crane.operation && user.permissions.includes('manage_pbp')) {
      let option = commodities.find(c => c.id === crane.commodity_id);
      if (crane.operation === 'load') {
        efficiency = option.loading_efficiency_average_per_hour + ' ' + option.efficiency_unit;
      } else {
        efficiency = option.unloading_efficiency_average_per_hour + ' ' + option.efficiency_unit;
      }
    }

    return (
      <ContainerWithButtons key={i} style={{ backgroundColor: i % 2 === 1 ? '#f0f0f0' : 'transparent' }}>
        <RowContainer>
          <Container>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Commodity')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Commodity name')}
                  value={
                    commodities?.length && crane.commodity_id
                      ? commodities.find(b => b.id === crane.commodity_id)?.name
                      : user.permissions.includes('view_pbp')
                        ? crane.commodity_name
                        : '-'
                  }
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  listHeight={80}
                  showSearch
                  placeholder={t('Commodity name')}
                  optionFilterProp="label"
                  onChange={value => {
                    handleCommodityChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  style={{ width: '100%' }}
                  options={
                    commodities?.length
                      ? commodities.map(commodity => {
                        return { label: commodity.name, value: commodity.id };
                      })
                      : []
                  }
                  value={crane.commodity_id || null}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Amount')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Amount')}
                  value={user.permissions.includes('manage_pbp') ? crane.commodity_amount : '-'}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Amount')}
                  onChange={value => {
                    handleAmountChangeSplit(value, index, i);
                    debounceOnValueChanged();
                  }}
                  style={{ width: '100%' }}
                  value={crane.commodity_amount}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Operation')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Operation')}
                  value={
                    user.permissions.includes('view_pbp') &&
                    operationOptions &&
                    operationOptions.length &&
                    crane.operation
                      ? operationOptions.find(b => b.value === crane.operation)?.label
                      : user.permissions.includes('view_pbp')
                        ? getOperation(crane.operation)
                        : '-'
                  }
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  listHeight={80}
                  showSearch
                  placeholder={t('Operation')}
                  optionFilterProp="label"
                  onChange={value => {
                    handleCraneOperationChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  style={{ width: '100%' }}
                  options={operationOptions}
                  value={crane.operation || null}
                  disabled={!crane.commodity_id || operationOptions.length === 0}
                  allowClear={true}
                  onClear={() => handleCraneOperationChangeSplit('', index, i)}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>{t('Efficiency')}</RowHeader>
              <Input
                placeholder={t('Efficiency')}
                style={{ width: '100%' }}
                value={user.permissions.includes('manage_pbp') ? efficiency || '-' : '-'}
                disabled={true}
              />
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Operation type')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Operation type')}
                  value={
                    user.permissions.includes('view_pbp') && operationTypeList && crane.operation_type
                      ? operationTypeList.find(b => b.value === crane.operation_type)?.label
                      : '-'
                  }
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  showSearch
                  listHeight={80}
                  placeholder={t('Operation type')}
                  optionFilterProp="label"
                  onChange={value => {
                    handleCraneOperationTypeChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  style={{ width: '100%' }}
                  options={operationTypeList}
                  name={index}
                  value={crane.operation_type || null}
                  allowClear={true}
                  onClear={() => handleCraneOperationTypeChangeSplit('', index, i)}
                />
              )}
            </RowContainerSplit>
          </Container>
          <Container>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Crane name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Crane')}
                  value={cranes && crane.crane_id ? cranes.find(b => b.id === crane.crane_id)?.name : ''}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  listHeight={80}
                  showSearch
                  placeholder={t('Crane')}
                  optionFilterProp="label"
                  onChange={value => {
                    handleCraneNameChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  style={{ width: '100%' }}
                  options={
                    cranes?.length
                      ? cranes.map(crane => {
                        return { label: crane.name, value: crane.id };
                      })
                      : []
                  }
                  value={crane.crane_id || null}
                  allowClear={true}
                  onClear={() => handleCraneNameChangeSplit('', index, i)}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Company')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Company')}
                  value={
                    user.permissions.includes('view_pbp') && companies && companies.length && crane.company_id
                      ? companies.find(b => b.id === crane.company_id)?.name
                      : user.permissions.includes('view_pbp')
                        ? crane.company_name
                        : '-'
                  }
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  showSearch
                  listHeight={80}
                  placeholder={t('Company name')}
                  optionFilterProp="label"
                  onChange={value => {
                    handleCraneCompanyChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  style={{ width: '100%' }}
                  options={
                    companies?.length
                      ? companies.map(comp => {
                        return { label: comp.name, value: comp.id };
                      })
                      : []
                  }
                  value={crane.company_id || null}
                  allowClear={true}
                  onClear={() => handleCraneCompanyChangeSplit('', index, i)}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>
                {t('Start')}
                <Required>*</Required>
              </RowHeader>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Planned time of arrival')}
                  value={crane.start_time ? dayjs(crane.start_time).format('DD.MM.YYYY HH:mm') : ''}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <DatePicker
                  format="DD.MM.YYYY HH:mm"
                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                  value={crane.start_time}
                  style={{ width: '100%' }}
                  onChange={value => {
                    onCraneStartChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  onSelect={value => {
                    onCraneStartChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  placeholder={t('Planned time of arrival')}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeaderContainer>
                {user.permissions.includes('view_pbp') && (
                  <SwitchContainer>
                    <Switch
                      checked={crane.manual_end_time}
                      size="small"
                      onChange={value => {
                        onCraneManualEndChangeSplit(value, index, i);
                        debounceOnValueChanged();
                      }}
                      disabled={readOnly && !editOnlyThis}
                    />
                  </SwitchContainer>
                )}
                {crane.manual_end_time && user.permissions.includes('view_pbp') ? (
                  <HeaderEnd>
                    {t('Manual end')}
                    <Required>*</Required>
                  </HeaderEnd>
                ) : (
                  <HeaderEnd>{t('End')}</HeaderEnd>
                )}
              </RowHeaderContainer>
              {readOnly && !editOnlyThis ? (
                <Input
                  placeholder={t('Planned time of departure')}
                  value={crane.end_time ? dayjs(crane.end_time).format('DD.MM.YYYY HH:mm') : ''}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <DatePicker
                  format="DD.MM.YYYY HH:mm"
                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                  value={crane.end_time}
                  style={{ width: '100%' }}
                  disabled={!crane.manual_end_time}
                  onChange={value => {
                    onCraneEndChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  onSelect={value => {
                    onCraneEndChangeSplit(value, index, i);
                    onValueChanged();
                  }}
                  placeholder={t('Planned time of departure')}
                />
              )}
            </RowContainerSplit>
            <RowContainerSplit style={{ width: 'calc(100% / 5)' }}>
              <RowHeader>{t('Duration')}</RowHeader>
              <div style={{ display: 'flex' }} data-id="crane-plan-duration">
                <Input
                  disabled={true}
                  value={getDuration(crane.duration)}
                  style={{ width: '100%', color: '#4a4a4a' }}
                />
              </div>
            </RowContainerSplit>
          </Container>
        </RowContainer>
        {!readOnly && (
          <RowContainerSplitButtons>
            <Tooltip title={t('Remove crane work')} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
              <span>
                <TrashButton
                  copy
                  style={{
                    zIndex: '5',
                    marginLeft: '0',
                    color: '#D0011C',
                  }}
                  data-id="remove-crane-work-button"
                  onClick={() => removeCraneSplitButton(index, i)}
                >
                  <Icon type={'trash'} />
                </TrashButton>
              </span>
            </Tooltip>
          </RowContainerSplitButtons>
        )}
        {!readOnly && (
          <RowContainerSplitButtons>
            <Tooltip title={t('Duplicate crane work')} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
              <span>
                <AddButton
                  copy
                  style={{
                    zIndex: '5',
                    marginLeft: '0',
                    color: '#4990DD',
                  }}
                  data-id="add-crane-work-button"
                  onClick={() => duplicateCraneSplitButton(index, i)}
                >
                  <Icon type="duplicate" />
                </AddButton>
              </span>
            </Tooltip>
          </RowContainerSplitButtons>
        )}
      </ContainerWithButtons>
    );
  });
};

export default PredictiveBerthPlanModalCranes;
