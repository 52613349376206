import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import { UserContext } from '../../../context/UserContext';
import { toUnderscore } from '../../../utils/utils';

import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Input from 'antd/es/input';

import Button from '../../ui/Button';
import Icon from '../../ui/Icon';

const ModalInner = styled.div`
  display: block;
  width: 100%;
  padding-right: 9px;
`;

const ModalRows = styled.div`
  width: 100%;
  padding-left: 16px;
  height: auto;
  display: inline-block;
  overflow-y: overlay;
  max-height: 372px;
  overflow-x: hidden;
`;

const RowContainerData = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
  width: 100%;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TrashButton = styled(Button)`
  margin-left: 0px;
  margin-right: ${props => (props.dataLength > 2 ? '30px' : '10px')};
  color: #1c1c1c;
  &:hover {
    color: #d0011c;
  }
`;

const AddTypeFieldLink = styled.a`
  text-transform: none;
  letter-spacing: 0;
`;

const ButtonContainer = styled.div`
  padding: 0px 0px 7px 7px;
  display: inline;
  min-width: 10px;
`;

const Required = styled.span`
  color: red;
`;

const TypeCreationModal = ({ isOpen, initialName, typeOptions, initialNamespace, handleVisibility }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { postType } = useContext(GeoAssetToolContext);
  const [typeFields, setTypeFields] = useState([]);
  const [postBody, setPostBody] = useState({
    name: initialName,
    namespace: initialNamespace,
    allowed_parents: [],
    description: '',
    type_fields: {},
  });

  const { Option } = Select;

  const RequiredOptions = key => {
    return (
      <>
        <Option key={key + ' true' + ' option'} value={true}>
          {t('Required')}
        </Option>
        <Option key={key + ' false' + ' option'} value={false}>
          {t('Optional')}
        </Option>
      </>
    );
  };

  const DataTypeOptions = key => {
    return (
      <>
        <Option key={key + ' string' + ' option'} value={'string'}>
          {t('Text')}
        </Option>
        <Option key={key + ' number' + ' option'} value={'number'}>
          {t('Number')}
        </Option>
        <Option key={key + ' boolean' + ' option'} value={'boolean'}>
          {t('True/False')}
        </Option>
        <Option key={key + ' json' + ' option'} value={'json'}>
          {t('JSON')}
        </Option>
      </>
    );
  };

  const handleOk = () => {
    let postCopy = JSON.parse(JSON.stringify(postBody));
    for (let index = 0; index < typeFields.length; index++) {
      const type = typeFields[index];
      const key = toUnderscore(type.key);
      postCopy.type_fields[key] = { type: type.type, required: type.required };
    }
    postType(postCopy);
    setTypeFields([]);
    setPostBody({
      name: initialName,
      namespace: initialNamespace,
      allowed_parents: [],
      description: '',
      type_fields: {},
    });
    handleVisibility();
  };

  const handleCancel = () => {
    setTypeFields([]);
    setPostBody({
      name: initialName,
      namespace: initialNamespace,
      allowed_parents: [],
      description: '',
      type_fields: {},
    });
    handleVisibility();
  };

  const handleNameChange = e => {
    let postCopy = JSON.parse(JSON.stringify(postBody));
    postCopy.name = e.target.value;
    setPostBody(postCopy);
  };

  const handleDescriptionChange = e => {
    let postCopy = JSON.parse(JSON.stringify(postBody));
    postCopy.description = e.target.value;
    setPostBody(postCopy);
  };

  const handleAllowedParents = e => {
    let postCopy = JSON.parse(JSON.stringify(postBody));
    postCopy.allowed_parents = e;
    setPostBody(postCopy);
  };

  const handleCustomFieldChange = (index, key, e) => {
    let typeFieldCopy = JSON.parse(JSON.stringify(typeFields));
    typeFieldCopy[index][key] = e;
    setTypeFields(typeFieldCopy);
  };

  const addCustomField = () => {
    const customFieldsCopy = JSON.parse(JSON.stringify(typeFields));
    customFieldsCopy.push({ key: null, type: null, required: false });
    setTypeFields(customFieldsCopy);
  };

  const removeFieldButton = index => {
    let typeFieldCopy = JSON.parse(JSON.stringify(typeFields));
    typeFieldCopy.splice(index, 1);
    setTypeFields(typeFieldCopy);
  };

  const validateData = () => {
    if (!postBody.name || !postBody.namespace) return true;
    for (let index = 0; index < typeFields.length; index++) {
      const element = typeFields[index];
      if (!element.key || !element.type) return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        okText={t('Save')}
        maskClosable={false}
        title={t('Create new type') + ' to ' + postBody.namespace}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: validateData() }}
      >
        <ModalInner>
          <ModalRows>
            <RowContainer key={'Type name' + ' ' + 1}>
              <RowContainerData>
                <RowHeader>
                  {t('Type name')}
                  <Required>*</Required>
                </RowHeader>
                <Input
                  placeholder={t('Name for type')}
                  value={postBody.name}
                  defaultValue={initialName}
                  style={{ color: '#4a4a4a', width: '50%' }}
                  onChange={e => handleNameChange(e)}
                />
              </RowContainerData>
            </RowContainer>
            <RowContainer key={'Allowed parents' + ' ' + 3}>
              <RowContainerData>
                <RowHeader>{t('Allowed parents')}</RowHeader>

                <Select
                  mode={'tags'}
                  showSearch
                  placeholder={t('Select type')}
                  optionFilterProp="label"
                  onChange={e => handleAllowedParents(e)}
                  style={{ color: '#4a4a4a', width: '50%' }}
                  value={postBody.allowed_parents}
                >
                  {typeOptions.map(type => (
                    <Option key={type.key} label={type.label}>
                      {type.title}
                    </Option>
                  ))}
                </Select>
              </RowContainerData>
            </RowContainer>
            <RowContainer key={'Description' + ' ' + 4}>
              <RowContainerData>
                <RowHeader>{t('Description')}</RowHeader>
                <Input
                  placeholder={t('Description/Notes')}
                  value={postBody.description}
                  style={{ color: '#4a4a4a', width: '50%' }}
                  onChange={e => handleDescriptionChange(e)}
                />
              </RowContainerData>
            </RowContainer>
            {typeFields.map((row, index) => {
              return (
                <RowContainer key={'Type field' + ' name ' + index}>
                  <RowContainerData>
                    <RowHeader>
                      {t('Field name')} <Required>*</Required>
                    </RowHeader>

                    <Input
                      placeholder={t('')}
                      value={typeFields[index].key}
                      style={{ color: '#4a4a4a' }}
                      onChange={e => handleCustomFieldChange(index, 'key', e.target.value)}
                    />
                  </RowContainerData>
                  <RowContainerData>
                    <RowHeader>
                      {t('Field type')} <Required>*</Required>
                    </RowHeader>
                    <Select
                      placeholder={t('Select type')}
                      style={{ color: '#4a4a4a', width: '100%' }}
                      onChange={e => handleCustomFieldChange(index, 'type', e)}
                    >
                      {DataTypeOptions('TypeField ' + index)}
                    </Select>
                  </RowContainerData>
                  <RowContainerData>
                    <RowHeader>{t('Required')}</RowHeader>
                    <Select
                      placeholder={t('Is required')}
                      style={{ color: '#4a4a4a', width: '100%' }}
                      defaultValue={false}
                      onChange={e => handleCustomFieldChange(index, 'required', e)}
                    >
                      {RequiredOptions('TypeField ' + index)}
                    </Select>
                  </RowContainerData>
                  <TrashButton
                    dataLength={typeFields.length}
                    copy
                    style={{
                      zIndex: '5',
                      marginTop: '23px',
                    }}
                    onClick={() => removeFieldButton(index)}
                  >
                    <Icon type={'trash'} />
                  </TrashButton>
                </RowContainer>
              );
            })}
          </ModalRows>
          <ButtonContainer style={{ marginLeft: 'calc(100% - 163px)' }}>
            <Button
              normal
              style={{ zIndex: '5', marginTop: '-5px', marginLeft: '0px', color: '#4990DD' }}
              onClick={() => addCustomField()}
            >
              <Icon type={'circle-plus'} />
              <AddTypeFieldLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                {t('Add custom field')}
              </AddTypeFieldLink>
            </Button>
          </ButtonContainer>
        </ModalInner>
      </Modal>
    </>
  );
};

export default TypeCreationModal;
