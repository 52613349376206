import React from 'react';
import styled from 'styled-components';

import Add from '../../images/icons/add.svg?react';
import ArrowCollapseAll from '../../images/icons/arrow-collapse-all.svg?react';
import ArrowExpandAll from '../../images/icons/arrow-expand-all.svg?react';
import ArrowExpandMax from '../../images/icons/arrow-expand-max.svg?react';
import ArrowLeft from '../../images/icons/arrow-left.svg?react';
import ArrowRight from '../../images/icons/arrow-right.svg?react';
import BellAdd from '../../images/icons/bell-add.svg?react';
import Bell from '../../images/icons/bell.svg?react';
import Bell2 from '../../images/icons/bell2.svg?react';
import Bell3 from '../../images/icons/bell3.svg?react';
import CalendarClock from '../../images/icons/calendar-clock.svg?react';
import Cellphone from '../../images/icons/cellphone.svg?react';
import Check from '../../images/icons/check.svg?react';
import CheckLight from '../../images/icons/check-light.svg?react';
import CheckDark from '../../images/icons/check-dark.svg?react';
import CheckboxChecked from '../../images/icons/checkbox-checked.svg?react';
import CheckboxUnchecked from '../../images/icons/checkbox-unchecked.svg?react';
import CheckCircle from '../../images/icons/check-circle.svg?react';
import ChevronLeft from '../../images/icons/chevron-left.svg?react';
import ChevronRight from '../../images/icons/chevron-right.svg?react';
import ChevronRight2 from '../../images/icons/chevron-right-2.svg?react';
import ChevronUp from '../../images/icons/chevron-up.svg?react';
import ChevronDown from '../../images/icons/chevron-down.svg?react';
import CirclePlus from '../../images/icons/circle-plus.svg?react';
import Circle from '../../images/icons/circle.svg?react';
import Columns from '../../images/icons/columns.svg?react';
import Close from '../../images/icons/close.svg?react';
import CommentAdd from '../../images/icons/comment-add.svg?react';
import Comment from '../../images/icons/comment.svg?react';
import Container from '../../images/icons/container.svg?react';
import Copy from '../../images/icons/copy.svg?react';
import Dots from '../../images/icons/dots.svg?react';
import DotSingle from '../../images/icons/dot-single.svg?react';
import Download from '../../images/icons/download.svg?react';
import Edit from '../../images/icons/edit.svg?react';
import Email from '../../images/icons/email.svg?react';
import Emergency from '../../images/icons/emergency.svg?react';
import Expand from '../../images/icons/expand.svg?react';
import ExternalLink from '../../images/icons/external-link.svg?react';
import HelpCircle from '../../images/icons/help-circle.svg?react';
import Info from '../../images/icons/info.svg?react';
import KeyAdd from '../../images/icons/key-add.svg?react';
import Key from '../../images/icons/key.svg?react';
import Lock from '../../images/icons/lock.svg?react';
import Logistics from '../../images/icons/logistics.svg?react';
import Logout from '../../images/icons/logout.svg?react';
import MapAdd from '../../images/icons/map-add.svg?react';
import MapClock from '../../images/icons/map-clock.svg?react';
import Map from '../../images/icons/map.svg?react';
import Marker from '../../images/icons/marker.svg?react';
import Menu from '../../images/icons/menu.svg?react';
import Module from '../../images/icons/module.svg?react';
import Notification from '../../images/icons/notification.svg?react';
import Number from '../../images/icons/number.svg?react';
import Pin from '../../images/icons/pin.svg?react';
import Pinned from '../../images/icons/pinned.svg?react';
import Plus from '../../images/icons/plus.svg?react';
import Minus from '../../images/icons/minus.svg?react';
import Port from '../../images/icons/port.svg?react';
import PortCall from '../../images/icons/portcall.svg?react';
import Print from '../../images/icons/print.svg?react';
import Profile from '../../images/icons/profile.svg?react';
import RadioChecked from '../../images/icons/radio-checked.svg?react';
import RadioUnchecked from '../../images/icons/radio-unchecked.svg?react';
import Reservation from '../../images/icons/reservation.svg?react';
import Search from '../../images/icons/search.svg?react';
import SelectArrow from '../../images/icons/select-arrow.svg?react';
import Settings from '../../images/icons/settings.svg?react';
import Ship from '../../images/icons/ship.svg?react';
import FleetView from '../../images/icons/fleet-view.svg?react';
import SorterAsc from '../../images/icons/sorter-asc.svg?react';
import SorterDesc from '../../images/icons/sorter-desc.svg?react';
import SorterFalse from '../../images/icons/sorter-false.svg?react';
import Swap from '../../images/icons/swap.svg?react';
import Receipt from '../../images/icons/receipt.svg?react';
import Receipt2 from '../../images/icons/receipt2.svg?react';
import TextMessage from '../../images/icons/text-message.svg?react';
import TimestampAdd from '../../images/icons/timestamp-add.svg?react';
import ToggleOff from '../../images/icons/toggle-off.svg?react';
import ToggleOn from '../../images/icons/toggle-on.svg?react';
import Translate from '../../images/icons/translate.svg?react';
import Trash from '../../images/icons/trash.svg?react';
import TrashBold from '../../images/icons/trash-bold.svg?react';
import UserAdd from '../../images/icons/user-add.svg?react';
import Users from '../../images/icons/users.svg?react';
import Admin from '../../images/icons/admin.svg?react';
import LoadingMaster from '../../images/icons/loading-master.svg?react';
import UserCheck from '../../images/icons/user-check.svg?react';
import Upload from '../../images/icons/upload.svg?react';
import View from '../../images/icons/view.svg?react';
import XCircle from '../../images/icons/x-circle.svg?react';
import LocationPin from '../../images/icons/location-pin.svg?react';
import RemoveIllustration from '../../images/icons/remove-illustration.svg?react';
import RequestSucc from '../../images/icons/request-succ.svg?react';
import Undo from '../../images/icons/undo.svg?react';
import UndoAlt from '../../images/icons/undo-alt.svg?react';
import RedoAlt from '../../images/icons/redo-alt.svg?react';
import VesselsDownCenter from '../../images/icons/vessels-down-center.svg?react';
import VesselsDown from '../../images/icons/vessels-down.svg?react';
import VesselsDownGrey from '../../images/icons/vessels-down-grey.svg?react';
import EditOutline from '../../images/icons/edit-outline.svg?react';
import ZoomIn from '../../images/icons/zoom-in.svg?react';
import ZoomOut from '../../images/icons/zoom-out.svg?react';
import BerthPlanningIcon from '../../images/icons/berth-planning.svg?react';
import Ellipsis from '../../images/icons/ellipsis.svg?react';
import InfoI from '../../images/icons/info-i.svg?react';
import Clock from '../../images/icons/clock.svg?react';
import PrivateData from '../../images/icons/private-data.svg?react';
import PublicData from '../../images/icons/public-data.svg?react';
import VesselWarning from '../../images/icons/vessel-warning.svg?react';
import VesselWarningRed from '../../images/icons/vessel-warning-red.svg?react';
import Time from '../../images/icons/time.svg?react';
import FleetPort from '../../images/icons/fleet-selected-port-marker.svg?react';
import CraneCurrent from '../../images/icons/map/crane-current.svg?react';
import CranePlanned from '../../images/icons/map/crane-planned.svg?react';
import ExpandView from '../../images/icons/expand-view.svg?react';
import CollapseView from '../../images/icons/collapse-view.svg?react';
import PointEdit from '../../images/icons/point-edit.svg?react';
import ServiceOrder from '../../images/icons/service-order.svg?react';
import Requested from '../../images/icons/requested.svg?react';
import Acknowledged from '../../images/icons/acknowledged.svg?react';
import Delivered from '../../images/icons/delivered.svg?react';
import World from '../../images/icons/world.svg?react';
import GeoAssetToolEdit from '../../images/icons/geo-asset-tool-edit.svg?react';
import Hidden from '../../images/icons/hidden.svg?react';
import Statistics from '../../images/icons/statistics.svg?react';
import Trailers from '../../images/icons/trailers.svg?react';
import Sort from '../../images/icons/sort.svg?react';
import Area from '../../images/icons/area.svg?react';
import ExclamationTriangle from '../../images/icons/exclamation-triangle.svg?react';
import Status from '../../images/icons/status.svg?react';
import SettingWarning from '../../images/icons/setting-warning.svg?react';
import CircleQuestionRegular from '../../images/icons/circle-question-regular.svg?react';
import Waste from '../../images/icons/waste.svg?react';
import Speed from '../../images/icons/speed.svg?react';
import ShipBG from '../../images/icons/ship-bg.svg?react';
import DataExport from '../../images/icons/data-export.svg?react';
import PortCallStats from '../../images/icons/portcall-stats.svg?react';
import Close2 from '../../images/icons/close-2.svg?react';
import Checkmark from '../../images/icons/checkmark.svg?react';
import Link from '../../images/icons/link.svg?react';
import Unlink from '../../images/icons/unlink.svg?react';
import Center from '../../images/icons/center.svg?react';
import CenterLight from '../../images/icons/center-light.svg?react';
import SwapWithoutBorder from '../../images/icons/swapWithoutBorder.svg?react';
import Layers from '../../images/icons/layers.svg?react';
import Warning from '../../images/icons/warning.svg?react';
import ActivityList from '../../images/icons/activity-list.svg?react';
import FilterTimestamps from '../../images/icons/filter-timestamps.svg?react';
import Page from '../../images/icons/page.svg?react';
import StateAdded from '../../images/icons/state-added.svg?react';
import StateUpdated from '../../images/icons/state-updated.svg?react';
import StateDeleted from '../../images/icons/state-deleted.svg?react';
import Banner from '../../images/icons/banner.svg?react';
import Duplicate from '../../images/icons/duplicate.svg?react';
import Refresh from '../../images/icons/refresh.svg?react';
import Asterisk from '../../images/icons/asterisk.svg?react';
import FinnpilotLogo from '../../images/icons/finnpilot-logo.svg?react';
import Measure from '../../images/icons/measure.svg?react';
import CircleTool from '../../images/icons/circle-tool.svg?react';
import Rotate from '../../images/icons/rotate.svg?react';
import Timestamps from '../../images/icons/timestamps.svg?react';
import Options from '../../images/icons/options.svg?react';

const IconWrapper = styled.i`
  display: inline-block;
  position: relative;
  height: ${props => props.height || '1em'};
  width: ${props => props.width || '1em'};
  svg {
    display: inline-block;
    fill: ${props => props.fill || 'currentColor'};
    color: ${props => props.color || 'currentColor'};
    vertical-align: ${props => props.align || 'middle'};
    text-align: center;
    height: ${props => props.height || 'unset'};
    width: ${props => props.width || 'unset'};
  }
`;
const Icon = ({ type, ...props }) => {
  switch (type) {
    case 'add':
      return (
        <IconWrapper {...props}>
          <Add />
        </IconWrapper>
      );
    case 'arrow-collapse-all':
      return (
        <IconWrapper {...props}>
          <ArrowCollapseAll />
        </IconWrapper>
      );
    case 'arrow-expand-all':
      return (
        <IconWrapper {...props}>
          <ArrowExpandAll />
        </IconWrapper>
      );
    case 'arrow-expand-max':
      return (
        <IconWrapper {...props}>
          <ArrowExpandMax />
        </IconWrapper>
      );
    case 'arrow-left':
      return (
        <IconWrapper {...props}>
          <ArrowLeft />
        </IconWrapper>
      );
    case 'arrow-right':
      return (
        <IconWrapper {...props}>
          <ArrowRight />
        </IconWrapper>
      );
    case 'bell-add':
      return (
        <IconWrapper {...props}>
          <BellAdd />
        </IconWrapper>
      );
    case 'bell':
      return (
        <IconWrapper {...props}>
          <Bell />
        </IconWrapper>
      );
    case 'bell2':
      return (
        <IconWrapper {...props}>
          <Bell2 />
        </IconWrapper>
      );
    case 'bell3':
      return (
        <IconWrapper {...props}>
          <Bell3 />
        </IconWrapper>
      );
    case 'calendar-clock':
      return (
        <IconWrapper {...props}>
          <CalendarClock />
        </IconWrapper>
      );
    case 'cellphone':
      return (
        <IconWrapper {...props}>
          <Cellphone />
        </IconWrapper>
      );
    case 'check':
      return (
        <IconWrapper {...props}>
          <Check />
        </IconWrapper>
      );
    case 'check-light':
      return (
        <IconWrapper {...props}>
          <CheckLight />
        </IconWrapper>
      );
    case 'check-dark':
      return (
        <IconWrapper {...props}>
          <CheckDark />
        </IconWrapper>
      );
    case 'checkbox-checked':
      return (
        <IconWrapper {...props}>
          <CheckboxChecked />
        </IconWrapper>
      );
    case 'checkbox-unchecked':
      return (
        <IconWrapper {...props}>
          <CheckboxUnchecked />
        </IconWrapper>
      );
    case 'check-circle':
      return (
        <IconWrapper {...props}>
          <CheckCircle />
        </IconWrapper>
      );
    case 'chevron-left':
      return (
        <IconWrapper {...props}>
          <ChevronLeft />
        </IconWrapper>
      );
    case 'chevron-right':
      return (
        <IconWrapper {...props}>
          <ChevronRight />
        </IconWrapper>
      );
    case 'chevron-right-2':
      return (
        <IconWrapper {...props}>
          <ChevronRight2 />
        </IconWrapper>
      );
    case 'chevron-up':
      return (
        <IconWrapper {...props}>
          <ChevronUp />
        </IconWrapper>
      );
    case 'chevron-down':
      return (
        <IconWrapper {...props}>
          <ChevronDown />
        </IconWrapper>
      );
    case 'circle-plus':
      return (
        <IconWrapper {...props}>
          <CirclePlus />
        </IconWrapper>
      );
    case 'circle':
      return (
        <IconWrapper {...props}>
          <Circle />
        </IconWrapper>
      );
    case 'columns':
      return (
        <IconWrapper {...props}>
          <Columns />
        </IconWrapper>
      );
    case 'close':
      return (
        <IconWrapper {...props}>
          <Close />
        </IconWrapper>
      );
    case 'comment-add':
      return (
        <IconWrapper {...props}>
          <CommentAdd />
        </IconWrapper>
      );
    case 'comment':
      return (
        <IconWrapper {...props}>
          <Comment />
        </IconWrapper>
      );
    case 'container':
      return (
        <IconWrapper {...props}>
          <Container />
        </IconWrapper>
      );
    case 'copy':
      return (
        <IconWrapper {...props}>
          <Copy />
        </IconWrapper>
      );
    case 'dots':
      return (
        <IconWrapper {...props}>
          <Dots />
        </IconWrapper>
      );
    case 'dot-single':
      return (
        <IconWrapper {...props}>
          <DotSingle />
        </IconWrapper>
      );
    case 'download':
      return (
        <IconWrapper {...props}>
          <Download />
        </IconWrapper>
      );
    case 'edit':
      return (
        <IconWrapper {...props}>
          <Edit />
        </IconWrapper>
      );
    case 'ellipsis':
      return (
        <IconWrapper {...props}>
          <Ellipsis />
        </IconWrapper>
      );
    case 'email':
      return (
        <IconWrapper {...props}>
          <Email />
        </IconWrapper>
      );
    case 'emergency':
      return (
        <IconWrapper {...props}>
          <Emergency />
        </IconWrapper>
      );
    case 'expand':
      return (
        <IconWrapper {...props}>
          <Expand />
        </IconWrapper>
      );
    case 'external-link':
      return (
        <IconWrapper {...props}>
          <ExternalLink />
        </IconWrapper>
      );
    case 'help-circle':
      return (
        <IconWrapper {...props}>
          <HelpCircle />
        </IconWrapper>
      );
    case 'info':
      return (
        <IconWrapper {...props}>
          <Info />
        </IconWrapper>
      );
    case 'key-add':
      return (
        <IconWrapper {...props}>
          <KeyAdd />
        </IconWrapper>
      );
    case 'key':
      return (
        <IconWrapper {...props}>
          <Key />
        </IconWrapper>
      );
    case 'lock':
      return (
        <IconWrapper {...props}>
          <Lock />
        </IconWrapper>
      );
    case 'logistics':
      return (
        <IconWrapper {...props}>
          <Logistics />
        </IconWrapper>
      );
    case 'logout':
      return (
        <IconWrapper {...props}>
          <Logout />
        </IconWrapper>
      );
    case 'map-add':
      return (
        <IconWrapper {...props}>
          <MapAdd />
        </IconWrapper>
      );
    case 'map-clock':
      return (
        <IconWrapper {...props}>
          <MapClock />
        </IconWrapper>
      );
    case 'map':
      return (
        <IconWrapper {...props}>
          <Map />
        </IconWrapper>
      );
    case 'marker':
      return (
        <IconWrapper {...props}>
          <Marker />
        </IconWrapper>
      );
    case 'menu':
      return (
        <IconWrapper {...props}>
          <Menu />
        </IconWrapper>
      );
    case 'module':
      return (
        <IconWrapper {...props}>
          <Module />
        </IconWrapper>
      );
    case 'notification':
      return (
        <IconWrapper {...props}>
          <Notification />
        </IconWrapper>
      );
    case 'number':
      return (
        <IconWrapper {...props}>
          <Number />
        </IconWrapper>
      );
    case 'pin':
      return (
        <IconWrapper {...props}>
          <Pin />
        </IconWrapper>
      );
    case 'pinned':
      return (
        <IconWrapper {...props}>
          <Pinned />
        </IconWrapper>
      );
    case 'plus':
      return (
        <IconWrapper {...props}>
          <Plus />
        </IconWrapper>
      );
    case 'minus':
      return (
        <IconWrapper {...props}>
          <Minus />
        </IconWrapper>
      );
    case 'port':
      return (
        <IconWrapper {...props}>
          <Port />
        </IconWrapper>
      );
    case 'port-call':
      return (
        <IconWrapper {...props}>
          <PortCall />
        </IconWrapper>
      );
    case 'print':
      return (
        <IconWrapper {...props}>
          <Print />
        </IconWrapper>
      );
    case 'profile':
      return (
        <IconWrapper {...props}>
          <Profile />
        </IconWrapper>
      );
    case 'radio-checked':
      return (
        <IconWrapper {...props}>
          <RadioChecked />
        </IconWrapper>
      );
    case 'radio-unchecked':
      return (
        <IconWrapper {...props}>
          <RadioUnchecked />
        </IconWrapper>
      );
    case 'reservation':
      return (
        <IconWrapper {...props}>
          <Reservation />
        </IconWrapper>
      );
    case 'search':
      return (
        <IconWrapper {...props}>
          <Search />
        </IconWrapper>
      );
    case 'select-arrow':
      return (
        <IconWrapper {...props}>
          <SelectArrow />
        </IconWrapper>
      );
    case 'settings':
      return (
        <IconWrapper {...props}>
          <Settings />
        </IconWrapper>
      );
    case 'ship':
      return (
        <IconWrapper {...props}>
          <Ship />
        </IconWrapper>
      );
    case 'fleet-view':
      return (
        <IconWrapper {...props}>
          <FleetView />
        </IconWrapper>
      );
    case 'sorter-asc':
      return (
        <IconWrapper {...props}>
          <SorterAsc />
        </IconWrapper>
      );
    case 'sorter-desc':
      return (
        <IconWrapper {...props}>
          <SorterDesc />
        </IconWrapper>
      );
    case 'sorter-false':
      return (
        <IconWrapper {...props}>
          <SorterFalse />
        </IconWrapper>
      );
    case 'swap':
      return (
        <IconWrapper {...props}>
          <Swap />
        </IconWrapper>
      );
    case 'receipt':
      return (
        <IconWrapper {...props}>
          <Receipt />
        </IconWrapper>
      );
    case 'receipt2':
      return (
        <IconWrapper {...props}>
          <Receipt2 />
        </IconWrapper>
      );
    case 'timestamp-add':
      return (
        <IconWrapper {...props}>
          <TimestampAdd />
        </IconWrapper>
      );
    case 'toggle-off':
      return (
        <IconWrapper {...props}>
          <ToggleOff />
        </IconWrapper>
      );
    case 'toggle-on':
      return (
        <IconWrapper {...props}>
          <ToggleOn />
        </IconWrapper>
      );
    case 'translate':
      return (
        <IconWrapper {...props}>
          <Translate />
        </IconWrapper>
      );
    case 'trash':
      return (
        <IconWrapper {...props}>
          <Trash />
        </IconWrapper>
      );
    case 'trash-bold':
      return (
        <IconWrapper {...props}>
          <TrashBold />
        </IconWrapper>
      );
    case 'text-message':
      return (
        <IconWrapper {...props}>
          <TextMessage />
        </IconWrapper>
      );
    case 'user-add':
      return (
        <IconWrapper {...props}>
          <UserAdd />
        </IconWrapper>
      );
    case 'users':
      return (
        <IconWrapper {...props}>
          <Users />
        </IconWrapper>
      );
    case 'admin':
      return (
        <IconWrapper {...props}>
          <Admin />
        </IconWrapper>
      );
    case 'loading-master':
      return (
        <IconWrapper {...props}>
          <LoadingMaster />
        </IconWrapper>
      );
    case 'user-check':
      return (
        <IconWrapper {...props}>
          <UserCheck />
        </IconWrapper>
      );
    case 'upload':
      return (
        <IconWrapper {...props}>
          <Upload />
        </IconWrapper>
      );
    case 'view':
      return (
        <IconWrapper {...props}>
          <View />
        </IconWrapper>
      );
    case 'x-circle':
      return (
        <IconWrapper {...props}>
          <XCircle />
        </IconWrapper>
      );
    case 'location-pin':
      return (
        <IconWrapper {...props}>
          <LocationPin />
        </IconWrapper>
      );
    case 'remove-illustration':
      return (
        <IconWrapper {...props}>
          <RemoveIllustration />
        </IconWrapper>
      );
    case 'request-succ':
      return (
        <IconWrapper {...props}>
          <RequestSucc />
        </IconWrapper>
      );
    case 'undo':
      return (
        <IconWrapper {...props}>
          <Undo />
        </IconWrapper>
      );
    case 'undo-alt':
      return (
        <IconWrapper {...props}>
          <UndoAlt />
        </IconWrapper>
      );
    case 'redo-alt':
      return (
        <IconWrapper {...props}>
          <RedoAlt />
        </IconWrapper>
      );
    case 'vessels-down-center':
      return (
        <IconWrapper {...props}>
          <VesselsDownCenter />
        </IconWrapper>
      );
    case 'vessels-down':
      return (
        <IconWrapper {...props}>
          <VesselsDown />
        </IconWrapper>
      );
    case 'vessels-down-grey':
      return (
        <IconWrapper {...props}>
          <VesselsDownGrey />
        </IconWrapper>
      );
    case 'edit-outline':
      return (
        <IconWrapper {...props}>
          <EditOutline />
        </IconWrapper>
      );
    case 'zoom-in':
      return (
        <IconWrapper {...props}>
          <ZoomIn />
        </IconWrapper>
      );
    case 'zoom-out':
      return (
        <IconWrapper {...props}>
          <ZoomOut />
        </IconWrapper>
      );
    case 'berth-planning':
      return (
        <IconWrapper {...props}>
          <BerthPlanningIcon />
        </IconWrapper>
      );
    case 'info-i':
      return (
        <IconWrapper {...props}>
          <InfoI />
        </IconWrapper>
      );
    case 'clock':
      return (
        <IconWrapper {...props}>
          <Clock />
        </IconWrapper>
      );
    case 'private-data':
      return (
        <IconWrapper {...props}>
          <PrivateData />
        </IconWrapper>
      );
    case 'public-data':
      return (
        <IconWrapper {...props}>
          <PublicData />
        </IconWrapper>
      );
    case 'vessel-warning':
      return (
        <IconWrapper {...props}>
          <VesselWarning />
        </IconWrapper>
      );
    case 'vessel-warning-red':
      return (
        <IconWrapper {...props}>
          <VesselWarningRed />
        </IconWrapper>
      );
    case 'time':
      return (
        <IconWrapper {...props}>
          <Time />
        </IconWrapper>
      );
    case 'fleet-port':
      return (
        <IconWrapper {...props}>
          <FleetPort />
        </IconWrapper>
      );
    case 'crane-current':
      return (
        <IconWrapper {...props}>
          <CraneCurrent />
        </IconWrapper>
      );
    case 'crane-planned':
      return (
        <IconWrapper {...props}>
          <CranePlanned />
        </IconWrapper>
      );
    case 'expand-view':
      return (
        <IconWrapper {...props}>
          <ExpandView />
        </IconWrapper>
      );
    case 'collapse-view':
      return (
        <IconWrapper {...props}>
          <CollapseView />
        </IconWrapper>
      );
    case 'point-edit':
      return (
        <IconWrapper {...props}>
          <PointEdit />
        </IconWrapper>
      );
    case 'service-order':
      return (
        <IconWrapper {...props}>
          <ServiceOrder />
        </IconWrapper>
      );
    case 'requested':
      return (
        <IconWrapper {...props}>
          <Requested />
        </IconWrapper>
      );
    case 'acknowledged':
      return (
        <IconWrapper {...props}>
          <Acknowledged />
        </IconWrapper>
      );
    case 'delivered':
      return (
        <IconWrapper {...props}>
          <Delivered />
        </IconWrapper>
      );
    case 'world':
      return (
        <IconWrapper {...props}>
          <World />
        </IconWrapper>
      );
    case 'geo-asset-tool-edit':
      return (
        <IconWrapper {...props}>
          <GeoAssetToolEdit />
        </IconWrapper>
      );
    case 'hidden':
      return (
        <IconWrapper {...props}>
          <Hidden />
        </IconWrapper>
      );
    case 'statistics':
      return (
        <IconWrapper {...props}>
          <Statistics />
        </IconWrapper>
      );
    case 'trailers':
      return (
        <IconWrapper {...props}>
          <Trailers />
        </IconWrapper>
      );
    case 'sort':
      return (
        <IconWrapper {...props}>
          <Sort />
        </IconWrapper>
      );
    case 'area':
      return (
        <IconWrapper {...props}>
          <Area />
        </IconWrapper>
      );
    case 'exclamation-triangle':
      return (
        <IconWrapper {...props}>
          <ExclamationTriangle />
        </IconWrapper>
      );
    case 'status':
      return (
        <IconWrapper {...props}>
          <Status />
        </IconWrapper>
      );
    case 'circle-question-regular':
      return (
        <IconWrapper {...props}>
          <CircleQuestionRegular />
        </IconWrapper>
      );
    case 'setting-warning':
      return (
        <IconWrapper {...props}>
          <SettingWarning />
        </IconWrapper>
      );
    case 'waste':
      return (
        <IconWrapper {...props}>
          <Waste />
        </IconWrapper>
      );
    case 'speed':
      return (
        <IconWrapper {...props}>
          <Speed />
        </IconWrapper>
      );
    case 'ship-bg':
      return (
        <IconWrapper {...props}>
          <ShipBG />
        </IconWrapper>
      );
    case 'data-export':
      return (
        <IconWrapper {...props}>
          <DataExport />
        </IconWrapper>
      );
    case 'portcall-stats':
      return (
        <IconWrapper {...props}>
          <PortCallStats />
        </IconWrapper>
      );
    case 'close-2':
      return (
        <IconWrapper {...props}>
          <Close2 />
        </IconWrapper>
      );
    case 'checkmark':
      return (
        <IconWrapper {...props}>
          <Checkmark />
        </IconWrapper>
      );
    case 'link':
      return (
        <IconWrapper {...props}>
          <Link />
        </IconWrapper>
      );
    case 'unlink':
      return (
        <IconWrapper {...props}>
          <Unlink />
        </IconWrapper>
      );
    case 'center':
      return (
        <IconWrapper {...props}>
          <Center />
        </IconWrapper>
      );
    case 'center-light':
      return (
        <IconWrapper {...props}>
          <CenterLight />
        </IconWrapper>
      );
    case 'swap-without-border':
      return (
        <IconWrapper {...props}>
          <SwapWithoutBorder />
        </IconWrapper>
      );
    case 'layers':
      return (
        <IconWrapper {...props}>
          <Layers />
        </IconWrapper>
      );
    case 'warning':
      return (
        <IconWrapper {...props}>
          <Warning />
        </IconWrapper>
      );
    case 'activity-list':
      return (
        <IconWrapper {...props}>
          <ActivityList />
        </IconWrapper>
      );
    case 'filter-timestamps':
      return (
        <IconWrapper {...props}>
          <FilterTimestamps />
        </IconWrapper>
      );
    case 'page':
      return (
        <IconWrapper {...props}>
          <Page />
        </IconWrapper>
      );
    case 'state-added':
      return (
        <IconWrapper {...props}>
          <StateAdded />
        </IconWrapper>
      );
    case 'state-updated':
      return (
        <IconWrapper {...props}>
          <StateUpdated />
        </IconWrapper>
      );
    case 'state-deleted':
      return (
        <IconWrapper {...props}>
          <StateDeleted />
        </IconWrapper>
      );
    case 'banner':
      return (
        <IconWrapper {...props}>
          <Banner />
        </IconWrapper>
      );
    case 'duplicate':
      return (
        <IconWrapper {...props}>
          <Duplicate />
        </IconWrapper>
      );
    case 'refresh':
      return (
        <IconWrapper {...props}>
          <Refresh />
        </IconWrapper>
      );
    case 'asterisk':
      return (
        <IconWrapper {...props}>
          <Asterisk />
        </IconWrapper>
      );
    case 'finnpilot-logo':
      return (
        <IconWrapper {...props}>
          <FinnpilotLogo />
        </IconWrapper>
      );
    case 'measure':
      return (
        <IconWrapper {...props}>
          <Measure />
        </IconWrapper>
      );
    case 'circle-tool':
      return (
        <IconWrapper {...props}>
          <CircleTool />
        </IconWrapper>
      );
    case 'rotate':
      return (
        <IconWrapper {...props}>
          <Rotate />
        </IconWrapper>
      );
    case 'timestamps':
      return (
        <IconWrapper {...props}>
          <Timestamps />
        </IconWrapper>
      );
    case 'options':
      return (
        <IconWrapper {...props}>
          <Options />
        </IconWrapper>
      );
    default:
      return null;
  }
};

export default Icon;
