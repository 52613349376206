import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import App from 'antd/es/app';

import { UserContext } from '../context/UserContext';
import useApi from '../hooks/useApi';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import NotificationsActions from '../components/notifications/NotificationsActions';
import NotificationsList from '../components/notifications/NotificationsList';

const Notifications = props => {
  const { namespace, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { tabId } = props.match.params;
  const [selectedTab, setSelectedTab] = useState(tabId || '1');

  const { message } = App.useApp();

  useEffect(() => {
    document.title = 'Notifications | Port Activity App';
  }, []);

  const { loading, data, error, fetchData } = useApi('get', 'notifications/100', {});

  if (error) {
    message.error(error, 4);
  }

  const getNotifications = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const notificationsChanged = useCallback(() => {
    getNotifications();
  }, [getNotifications]);

  useUserSocket('notifications-changed', notificationsChanged);

  const notifications = error || !data ? [] : data;

  const notificationChildren = {};
  if (notifications) {
    notifications.forEach(item => {
      if (item.children) {
        notificationChildren[item.id] = item.children;
      }
    });
  }

  return (
    <Layout pagename={t('Notifications')}>
      <Page fullWidth>
        <NotificationsActions selectedTab={selectedTab} loading={loading} notifications={notifications} />
        <NotificationsList
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          loading={loading}
          notifications={notifications}
          notificationChildren={notificationChildren}
        />
      </Page>
    </Layout>
  );
};

export default Notifications;
