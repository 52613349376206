import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';
import { WeatherContext } from '../context/WeatherContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import SeaLevelGraph from '../components/weather/SeaLevelGraph';

const SeaLevel = () => {
  const { namespace } = useContext(UserContext);
  const { currentSeaLevel } = useContext(WeatherContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Sea Level | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Sea Level')}>
      <Page
        fullWidth
        title={t('Sea Level at {{place}}', {
          place: currentSeaLevel ? currentSeaLevel.mareograph_station.name : '',
        })}
      >
        <SeaLevelGraph />
      </Page>
    </Layout>
  );
};

export default SeaLevel;
