import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const Container = styled.div`
  padding: 12px;
  display: inline-block;
`;

const Header = styled.div`
  font-weight: 600;
  margin-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
`;

const Pair = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Title = styled.div`
  margin-right: 12px;
`;

const Value = styled.div`
  margin-right: 60px;
  font-weight: 600;
`;

const Column = styled.div`
  display: inline-block;
`;

const BerthSettingsExpander = ({ settings }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <Container>
      <Header>{t('Berth settings')}</Header>
      <Column>
        <Pair>
          <Title>{t('Travel duration to berth')}:</Title>
          <Value>
            {settings.travel_duration?.length > 0
              ? dayjs.duration(settings.travel_duration).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('Laytime buffer duration')}:</Title>
          <Value>
            {settings.slot_laytime_buffer_duration?.length > 0
              ? dayjs.duration(settings.slot_laytime_buffer_duration).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('Live ETA alert delay')}:</Title>
          <Value>
            {settings.live_eta_alert_delay?.length > 0
              ? dayjs.duration(settings.live_eta_alert_delay).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('Slot request in advance time limit')}:</Title>
          <Value>
            {settings.slot_request_in_advance_time_limit?.length > 0
              ? dayjs.duration(settings.slot_request_in_advance_time_limit).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
      </Column>
      <Column>
        <Pair>
          <Title>{t('RTA window duration')}:</Title>
          <Value>
            {settings.slot_rta_window_duration?.length > 0
              ? dayjs.duration(settings.slot_rta_window_duration).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('Live ETA alert tolerance')}:</Title>
          <Value>
            {settings.live_eta_alert_tolerance?.length > 0
              ? dayjs.duration(settings.live_eta_alert_tolerance).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('ETD tolerance')}:</Title>
          <Value>
            {settings.slot_etd_tolerance?.length > 0
              ? dayjs.duration(settings.slot_etd_tolerance).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
        <Pair>
          <Title>{t('Slot request in advance maximum time limit')}:</Title>
          <Value>
            {settings.slot_request_in_advance_max_time_limit?.length > 0
              ? dayjs.duration(settings.slot_request_in_advance_max_time_limit).format('HH:mm', { trim: false })
              : '-'}
          </Value>
        </Pair>
      </Column>
      <Pair style={{ marginTop: '24px' }}>
        <Title>{t('Berth operator emails')}:</Title>
        <Value>{settings.operator_emails || '-'}</Value>
      </Pair>
    </Container>
  );
};

export default BerthSettingsExpander;
