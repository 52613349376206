import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';

import Text from 'antd/lib/typography/Text';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';

const Ports = () => {
  const { apiCall, namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [apiCallPending, setApiCallPending] = useState(false);

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Other ports | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'ports', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const ports = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleBlackWhiteIn = async e => {
    setApiCallPending(true);
    await apiCall('post', 'port-whitelist-in', { service_id: e.service_id, whitelist: !e.whitelist_in });
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleBlackWhiteOut = async e => {
    setApiCallPending(true);
    await apiCall('post', 'port-whitelist-out', { service_id: e.service_id, whitelist: !e.whitelist_out });
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Service ID'),
      dataIndex: 'service_id',
      key: 'service_id',
      sortableKey: 'service_id',
    },
    {
      title: t('LOCODES'),
      dataIndex: 'locodes',
      key: 'locodes',
    },
    {
      title: t('Whitelist IN'),
      dataIndex: 'whitelist_in',
      key: 'whitelist_in',
      sortableKey: 'whitelist_in',
      render: record => {
        if (record) {
          return <Text>T</Text>;
        } else {
          return <Text>F</Text>;
        }
      },
    },
    {
      title: t('Whitelist OUT'),
      dataIndex: 'whitelist_out',
      key: 'whitelist_out',
      sortableKey: 'whitelist_out',
      render: record => {
        if (record) {
          return <Text>T</Text>;
        } else {
          return <Text>F</Text>;
        }
      },
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          disabled={!user.permissions.includes('manage_port')}
          onClick={() => handleBlackWhiteIn(record)}
        >
          {record.whitelist_in ? t('Blacklist IN') : t('Whitelist IN')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          disabled={!user.permissions.includes('manage_port')}
          onClick={() => handleBlackWhiteOut(record)}
        >
          {record.whitelist_out ? t('Blacklist OUT') : t('Whitelist OUT')}
        </ListActionButton>
      ),
    },
  ];

  return (
    <Layout pagename={t('Other Ports')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={ports}
          apiCallPending={apiCallPending}
          actions={actions}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search')}
        />
      </Page>
    </Layout>
  );
};

export default Ports;
