import React, { useContext } from 'react';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import { TimestampContext } from '../../context/TimestampContext';
import AddOrEditJITSlotRequestInner from '../berthPlanningTool/modals/AddOrEditJITSlotRequestInner';

const SendJITSlotRequestModal = ({ type, closeModal, vessel = undefined, readOnly, newTime }) => {
  const { apiCall } = useContext(UserContext);
  const { fetchData } = useContext(TimestampContext);

  const sendEditEtdSlotRequest = async data => {
    await apiCall('post', 'slot-reservation-loading-master-etd', {
      id: data.id,
      loading_master_etd: dayjs(data.loading_master_etd).format('YYYY-MM-DDTHH:mm:ssZ'),
    });

    await fetchData();
  };

  const sendDeleteSlotResquest = async id => {
    await apiCall('delete', 'slot-reservations', {
      id,
      cancel_only: true,
      by_vessel: false,
    });

    await fetchData();
  };

  return (
    <AddOrEditJITSlotRequestInner
      vessel={vessel}
      type={type}
      closeModal={closeModal}
      readOnly={readOnly}
      newTime={newTime}
      sendDeleteSlotResquest={sendDeleteSlotResquest}
      sendEditSlotRequest={undefined}
      sendEditEtdSlotRequest={sendEditEtdSlotRequest}
    />
  );
};

export default SendJITSlotRequestModal;
