import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Polygon, Polyline, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

import Button from '../ui/Button';
import Icon from '../ui/Icon';

import { getOptions } from '../map/MapUtils';
import IncidentMarker from './IncidentMarker';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 24px;
  display: inline-grid;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 3px;
    margin-left: -1px;
  }
`;

const ZoomButtonCenter = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
  border: 2px solid rgba(0, 0, 0, 0.2);

  i {
    margin-top: 0px;
    margin-left: -2px;
  }
`;

const ZoomResetContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 13px;
  bottom: 86px;
  display: inline-grid;
  margin: 0;
`;

const IncidentMapInnerContainer = ({
  zoom,
  setZoom,
  incidentData,
  level,
  titleType,
  titleLocation,
  date,
  setAreaMapCenter,
  areaMapCenter,
}) => {
  const options = getOptions();
  const map = useMap();

  useEffect(() => {
    if (areaMapCenter) {
      map.fitBounds(L.GeoJSON.coordsToLatLngs(areaMapCenter.geometry.coordinates, 1), { padding: [100, 100] });
      setAreaMapCenter(null);
    }
  }, [areaMapCenter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (incidentData?.data?.geojson.length > 0 && incidentData.data?.geojson[0]?.geometry.type === 'Polygon') {
      let group = new L.FeatureGroup();
      incidentData?.data?.geojson.forEach(d => {
        group.addLayer(L.polygon(L.GeoJSON.coordsToLatLngs(d.geometry.coordinates, 1)));
      });
      map.fitBounds(group.getBounds(), { padding: [50, 50] });
    }
  }, [incidentData]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  useEffect(() => {
    map.invalidateSize();
  });

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  const recenterMap = () => {
    if (incidentData?.data?.geojson.length > 0 && incidentData.data?.geojson[0]?.geometry.type === 'Polygon') {
      let group = new L.FeatureGroup();
      incidentData?.data?.geojson.forEach(d => {
        group.addLayer(L.polygon(L.GeoJSON.coordsToLatLngs(d.geometry.coordinates, 1)));
      });
      map.fitBounds(group.getBounds(), { padding: [50, 50] });
    } else {
      map.setView(
        [
          incidentData?.data?.geojson[0]?.geometry.coordinates[1],
          incidentData?.data?.geojson[0]?.geometry.coordinates[0],
        ],
        9
      );
    }
  };

  const zoomPrevDiv = L.DomUtil.get('zoompreventer');
  if (zoomPrevDiv) {
    L.DomEvent.disableClickPropagation(zoomPrevDiv);
  }

  return (
    <>
      <ZoomResetContainer>
        <ZoomButtonCenter data-testid="map-center" copy onClick={() => recenterMap()}>
          <Icon type="center" />
        </ZoomButtonCenter>
      </ZoomResetContainer>
      <ZoomButtonContainer id={'zoompreventer'}>
        <ZoomButton
          data-testid="map-zoom-in"
          copy
          onClick={() => handleZoomButton(zoom + 1)}
          style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
        >
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton
          data-testid="map-zoom-out"
          copy
          onClick={() => handleZoomButton(zoom - 1)}
          style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
        >
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      {incidentData?.data?.geojson.length > 0 &&
        (incidentData?.data?.geojson[0]?.geometry.type === 'Point' ? (
          <IncidentMarker
            coords={incidentData?.data?.geojson[0]?.geometry.coordinates}
            level={level}
            titleType={titleType}
            titleLocation={titleLocation}
            date={date}
            event={incidentData.type === 'event'}
          />
        ) : (
          incidentData?.data?.geojson.map((value, id) => {
            if (value.geometry.type === 'Polygon') {
              let color = '#2254c0';
              let color2 = '#052363';
              if (incidentData.type === 'incident') {
                color = level > 7 ? '#F44336' : level < 4 ? '#4c914e' : '#F5BD3E';
                color2 = level > 7 ? '#800f06' : level < 4 ? '#0a400b' : '#ab8329';
              }
              let coords = L.GeoJSON.coordsToLatLngs(value.geometry.coordinates, 1);
              if (
                coords[0][0].lat !== coords[0][coords[0].length - 1].lat ||
                coords[0][0].lng !== coords[0][coords[0].length - 1].lng
              ) {
                coords[0].push(coords[0][0]);
              }
              return (
                <Fragment key={id}>
                  <Polygon
                    positions={coords}
                    fillColor={color}
                    fillOpacity={0.5}
                    color={color}
                    opacity={0.7}
                    pathOptions={{ dashArray: [4, 4] }}
                  />
                  <Polyline
                    positions={coords}
                    pathOptions={{ dashArray: [4, 4], dashOffset: 4, color: color2, weight: '2', opacity: '0.85' }}
                  />
                </Fragment>
              );
            }
          })
        ))}
    </>
  );
};

export default IncidentMapInnerContainer;
