import Spin from 'antd/es/spin';
import Tabs from 'antd/es/tabs';
import Tooltip from 'antd/es/tooltip';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import LineWithTimeGraph from '../statistics/LineWithTimeGraph';
import MapWithRoute from '../statistics/MapWithRoute';
import ClusterGraphsMini from './ClusterGraphsMini';
import VesselInfo from './VesselInfo';
import VesselLocation from './VesselLocation';

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: calc(100vh - 260px);
`;

const Container = styled.div`
  min-width: 600px;
  overflow-x: auto;
  padding-bottom: 440px;

  .ant-tabs-nav-wrap {
    justify-content: flex-end !important;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-color: transparent !important;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-tabs-nav {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  @media (min-width: 501px) {
    .ant-tabs-nav-wrap {
      justify-content: flex-end !important;
    }

    .ant-tabs-tab {
      font-size: 14px !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }
  }

  @media (max-width: 500px) {
    .ant-tabs-nav-wrap {
      justify-content: flex-start !important;
    }

    .ant-tabs-tab {
      font-size: 13px !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const GraphPadding = styled.div`
  padding-top: 40px;
`;

const SpeedInnerModal = ({ portcallId, mmsi, statusDone, setReloadOngoing }) => {
  const { namespace, apiCall, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [selectedTab, setSelectedTab] = useState(portcallId ? '0' : '1');

  const [dashboardData, setDashboardData] = useState({});
  const [markerData, setMarkerData] = useState(null);
  const [startDataAvailable, setStartDataAvailable] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(false);

  useEffect(() => {
    const getDashboardData = async () => {
      let result;
      try {
        result = await apiCall('get', 'statistics/dashboard?name=port_call_data');
      } catch (e) {
        setDashboardData({});
      }
      if (result?.data && result.status === 200) {
        setDashboardData(result.data);
      }
    };

    const getMarkerData = async () => {
      let result;
      try {
        result = await apiCall('get', 'sea-chart/markers');
      } catch (e) {
        setMarkerData(null);
      }
      if (result?.data && result.status === 200) {
        setMarkerData(result.data);
      }
    };

    const getStartData = async () => {
      setIsDashboardDataLoading(true);
      if (!dataFetched) {
        setDataFetched(true);
        await getDashboardData();
        await getMarkerData();
      }
      setIsDashboardDataLoading(false);
    };

    if (!startDataAvailable) {
      getStartData();
      setStartDataAvailable(true);
    }
  }, [apiCall, dataFetched, startDataAvailable]);

  let tabItems = [];
  if (portcallId) {
    tabItems.push({
      label: t('Info'),
      children: <VesselInfo portcallId={portcallId} reloadOngoing={() => setReloadOngoing(true)} />,
      key: '0',
    });
  }
  if (!(modules.port_call_dashboard_module === 'enabled' && user.permissions.includes('view_port_call_dashboard'))) {
    tabItems.push({
      label: t('Location'),
      children: <VesselLocation mmsi={mmsi} />,
      key: '1',
    });
  }

  dashboardData.structure?.[0].forEach((elementId, index) => {
    const element = dashboardData.elements.find(e => e.id === elementId);
    if (element?.type === 'line-with-time') {
      tabItems.push({
        label: (
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
            title={
              !(
                modules.port_call_dashboard_module === 'enabled' &&
                user.permissions.includes('view_port_call_dashboard')
              )
                ? t('AIS is not enabled.')
                : null
            }
          >
            <span>{t(element.header)}</span>
          </Tooltip>
        ),
        children:
          portcallId || mmsi ? (
            <GraphPadding>
              <LineWithTimeGraph column1={true} portcallId={portcallId} mmsi={mmsi} inModal={true} {...element} />
            </GraphPadding>
          ) : (
            <NoData>{t('No data available.')}</NoData>
          ),
        key: (index + 2).toString(),
        disabled: !(
          modules.port_call_dashboard_module === 'enabled' && user.permissions.includes('view_port_call_dashboard')
        ),
      });
    } else if (element?.type === 'map-with-route') {
      tabItems.push({
        label: (
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
            title={
              !(
                modules.port_call_dashboard_module === 'enabled' &&
                user.permissions.includes('view_port_call_dashboard')
              )
                ? t('AIS is not enabled.')
                : null
            }
          >
            <span>{t(element.header)}</span>
          </Tooltip>
        ),
        children:
          portcallId || mmsi ? (
            <MapWithRoute
              showGraph={true}
              portcallId={portcallId}
              mmsi={mmsi}
              inModal={true}
              markerData={markerData}
              {...element}
            />
          ) : (
            <NoData>{t('No data available.')}</NoData>
          ),
        key: (index + 2).toString(),
        disabled: !(
          modules.port_call_dashboard_module === 'enabled' && user.permissions.includes('view_port_call_dashboard')
        ),
      });
    } else if (modules?.emissions_summary_module === 'enabled' && element?.type === 'bar-multi-column-cluster') {
      tabItems.push({
        label: (
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
            title={!statusDone ? t('Emission data is only available after port call is completed.') : null}
          >
            <span>{t(element.header)}</span>
          </Tooltip>
        ),
        children:
          portcallId || mmsi ? (
            <ClusterGraphsMini graphType={element.type} key={index} portcallId={portcallId} {...element} />
          ) : (
            <NoData>{t('No data available.')}</NoData>
          ),
        key: (index + 2).toString(),
        disabled: !statusDone,
      });
    }
  });

  return (
    <ScrollContainer>
      <Container>
        {isDashboardDataLoading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : dashboardData?.elements?.length > 0 ? (
          <Tabs
            defaultActiveKey={selectedTab}
            onChange={e => setSelectedTab(e)}
            animated={false}
            size="small"
            items={tabItems}
          />
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Container>
    </ScrollContainer>
  );
};

export default SpeedInnerModal;
