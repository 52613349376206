export const onMarkerMouseOver = (map, markerCoords, direction, setDirection, latLng = false) => {
  let lat = markerCoords[latLng ? 0 : 1];
  let lng = markerCoords[latLng ? 1 : 0];
  const { lat: middleLat } = map.getCenter();
  const bounds = map.getBounds();
  const fifth = Math.abs(bounds._northEast.lng - bounds._southWest.lng) / 5;
  let directionToBe = lat > middleLat ? 'bottom' : 'top';
  if (lng > bounds._southWest.lng && lng < bounds._southWest.lng + fifth) {
    directionToBe = 'right';
  }
  if (lng < bounds._northEast.lng && lng > bounds._northEast.lng - fifth) {
    directionToBe = 'left';
  }
  if (direction !== directionToBe) {
    setDirection(directionToBe);
  }
};

export const METRIC = 'metric';
export const MILES = 'miles';
export const NAUTICALMILES = 'nautical_miles';

export const readableDistance = (distance, type) => {
  if (!distance) {
    return 0;
  }

  if (type === METRIC) {
    if (distance > 1000) {
      return Math.round(distance / 10) / 100 + ' km';
    } else {
      return Math.round(distance) + ' m';
    }
  } else if (type === MILES) {
    let distance2 = distance / 1.609344;
    if (distance2 > 1000) {
      return Math.round(distance2 / 10) / 100 + ' mi';
    } else {
      return Math.round(distance2) / 1000 + ' mi';
    }
  } else if (type === NAUTICALMILES) {
    let distance2 = distance / 1.852;
    if (distance2 > 1000) {
      return Math.round(distance2 / 10) / 100 + ' M';
    } else {
      return Math.round(distance2) / 1000 + ' M';
    }
  }
};
