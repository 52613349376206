import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { UserContext } from '../../context/UserContext';
import { InvoicingContext } from '../../context/InvoicingContext';

const Content = styled.div`
  margin-bottom: 1em;
  padding: 16px;
`;

const RowContainer = styled.div`
  padding: 8px 0 12px 0;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const defaultInputData = {
  imo: undefined,
  validTs: undefined,
};

const AddShipboardWasteExceptionModal = ({ visible, setIsModalVisible }) => {
  const { namespace } = useContext(UserContext);
  const { postWasteException } = useContext(InvoicingContext);

  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState(defaultInputData);

  const onDateChange = value => {
    if (value) {
      setInputData({ ...inputData, validTs: value });
    }
  };

  const updateImo = e => {
    setInputData({ ...inputData, imo: e.target.value });
  };

  const handleOk = () => {
    postWasteException(inputData.imo, inputData.validTs.format('YYYY-MM-DDTHH:mm:ssZ'));
    setInputData(defaultInputData);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setInputData(defaultInputData);
    setIsModalVisible(false);
  };

  const dataIsValid = () => {
    return inputData.validTs && inputData.imo;
  };

  return (
    <Modal
      title={t('Add shipboard waste exception')}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={400}
      okText={'Add'}
      okButtonProps={{ disabled: !dataIsValid() }}
    >
      <Content>
        <RowContainer>
          <RowHeader>
            {t('Waste exception valid until')}
            <Required>*</Required>
          </RowHeader>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 15 }}
            style={{
              width: '100%',
            }}
            value={inputData.validTs ? dayjs(inputData.validTs) : null}
            onChange={onDateChange}
            onSelect={onDateChange}
            placeholder={t('End date')}
          />
        </RowContainer>
        <RowContainer>
          <RowHeader>
            {t('Imo')}
            <Required>*</Required>
          </RowHeader>
          <Input placeholder={t('Imo')} value={inputData.imo || null} onChange={updateImo} />
        </RowContainer>
      </Content>
    </Modal>
  );
};

export default AddShipboardWasteExceptionModal;
