import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import { UserContext } from '../../../context/UserContext';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import App from 'antd/es/app';
import Spin from 'antd/es/spin';
import { toUnderscore } from '../../../utils/utils';
import './geoAssetTool.css';

import ButtonLight from '../../ui/ButtonLight';
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import MovableModal from '../../activity/MovableModal';

const ModaInner = styled.div`
  display: flex;
  min-height: 100%;
  min-width: 100%;
  padding-top: 40px;
`;

const ModalLeft = styled.div`
  padding: 0 0 0 30px;
  height: calc(100% - 40px);
  border-right: 1px solid #d8d8d8;
  width: 20%;
`;
const ModalRight = styled.div`
  height: calc(100% - 52px);
  padding: 0 10px 0px 30px;
  margin-bottom: 52px;
  width: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
`;

const TypeNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.selected ? '#F8F8F8' : 'null')};
  padding-left: 8px;
  height: 38px;
  margin-bottom: 1px;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  &:last-of-type {
    margin-bottom: 5px;
  }
`;

const TypeName = styled.span`
  cursor: pointer;
  color: ${props => (props.selected ? '#4990DD' : 'null')};
  &:last-of-type {
    margin-bottom: 5px;
  }
`;

const TypeDataField = styled.div`
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  width: 33%;
`;

const TypeDataHeader = styled.div`
  font-weight: 600;
  height: 24px;
  padding-bottom: 9px;
`;

const TypeDataInput = styled.div`
  height: 40px;
  padding-bottom: 9px;
`;

const DataRow = styled.div`
  width: 100%;
  display: flex;
  pointer-events: ${props => (props.enabled ? null : 'none')};
`;

const DataRowInfo = styled.div`
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  margin-bottom: 7px;
`;

const Required = styled.span`
  color: red;
`;

const TrashButton = styled(Button)`
  margin-left: 4px;
  margin-right: 0px;
  padding: 4px;
  width: 28px;
  height: 28px;
  color: #1c1c1c;
  &:hover {
    color: #d0011c;
  }
`;

const AddTypeFieldLink = styled.a`
  text-transform: none;
  text-decoration: ${props => !props.updateAvailable && 'none !important'};
  letter-spacing: 0;
  color: ${props => !props.updateAvailable && '#D8D8D8 !important'};
  cursor: ${props => !props.updateAvailable && 'default'};
`;

const ButtonWrapper = styled.div`
  background-color: white;
  width: 80%;
  position: absolute;
  bottom: 10px;
  right: 0px;
  padding-right: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
`;

const Loading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(0, -50%);
`;

const CreateNewLink = styled.span`
  padding: 8px 8px 8px 8px;
  border-radius: 4px 0 0 4px;
  color: #4990dddd;
  background-color: ${props => (props.selected ? '#F8F8F8' : 'null')};
  &:hover {
    cursor: pointer;
  }
`;

const Eye = styled.span`
  margin-right: 10px;
  color: #4990ddcc;
  &:hover {
    cursor: pointer;
  }
`;

const TypeEditModal = ({ isOpen, handleVisibility, typeData, typeOptions, currentNamespace }) => {
  const { namespace } = useContext(UserContext);
  const { getTypeAssetCount, deleteTypeFunction, saveTypeFunction, postType, filterList, setFilterList } = useContext(
    GeoAssetToolContext
  );
  const { t } = useTranslation(namespace);
  const [typeKeys, setTypeKeys] = useState([]);
  const [typeCountData, setTypeCountData] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [typeFields, setTypeFields] = useState([]);
  const [updateSelected, setUpdateSelected] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [popComfirmOpen, setPopComfirmOpen] = useState(false);
  const [newAssetLoading, setNewAssetLoading] = useState(false);
  const [reloadTypes, setReloadType] = useState(false);

  const { Option } = Select;

  const { message } = App.useApp();

  const emptyNewType = {
    createNew: true,
    name: '',
    namespace: currentNamespace,
    allowed_parents: [],
    description: '',
    type_fields: {},
  };

  useLayoutEffect(() => {
    const keyArray = Object.keys(typeData);
    setTypeKeys(keyArray);
    if (isOpen && keyArray.length > 0) {
      getTypeAssetCount(keyArray).then(result => {
        setTypeCountData(result);
        if (
          selectedType.createNew &&
          typeData[keyArray[keyArray.length - 1]] &&
          typeof result[keyArray[keyArray.length - 1]] === 'number'
        ) {
          setUpdateAvailable(result[keyArray[keyArray.length - 1]] === 0);
          setSelectedType(
            JSON.parse(
              JSON.stringify({
                ...typeData[keyArray[keyArray.length - 1]],
                count: result[keyArray[keyArray.length - 1]],
              })
            )
          );
        } else if (typeData[keyArray[0]] && typeof result[keyArray[0]] === 'number') {
          //setUpdateAvailable(result[keyArray[0]] === 0 ? true : false);
          // TODO: Needs more logic to lock existing fields and to enable only optional new fields
          setUpdateAvailable(true);
          setSelectedType(JSON.parse(JSON.stringify({ ...typeData[keyArray[0]], count: result[keyArray[0]] })));
        } else {
          setSelectedType(JSON.parse(JSON.stringify({ ...emptyNewType })));
        }
      });
    } else if (keyArray.length === 0) setSelectedType(JSON.parse(JSON.stringify({ ...emptyNewType })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reloadTypes]);

  useEffect(() => {
    if (Object.keys(selectedType).length > 0) {
      // setUpdateAvailable(selectedType.count === 0 || selectedType.createNew);
      // TODO: Needs more logic to lock existing fields and to enable only optional new fields
      setUpdateAvailable(true);
      let typeFieldKeys = Object.keys(selectedType.type_fields);
      const tempTypeFields = [];
      for (let index = 0; index < typeFieldKeys.length; index++) {
        const element = typeFieldKeys[index];
        tempTypeFields.push({
          key: element,
          type: selectedType.type_fields[element].type,
          required: selectedType.type_fields[element].required,
        });
      }
      setTypeFields(tempTypeFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSelected]);

  const handleSave = async () => {
    setNewAssetLoading(true);
    const savedType = JSON.parse(JSON.stringify(selectedType));
    if (!savedType.name) {
      setNewAssetLoading(false);
      return message.error(t('Fill out required information'));
    }
    const newTypeFields = {};
    for (let index = 0; index < typeFields.length; index++) {
      const element = typeFields[index];
      if (!element.key || !element.type) return message.error(t('Fill out required information'));
      else {
        const key = toUnderscore(element.key);
        if (key === 'center') return message.error(t('Center is reserved field name'));
        newTypeFields[key] = { type: element.type, required: element.required };
      }
    }
    savedType.type_fields = newTypeFields;
    let result;
    if (savedType.createNew) {
      result = await postType(savedType);
      setSelectedType(JSON.parse(JSON.stringify({ ...emptyNewType })));
      setUpdateSelected(!updateSelected);
      setReloadType(!reloadTypes);
    } else {
      result = await saveTypeFunction(savedType);
    }
    setNewAssetLoading(false);
    if (result) {
      message.success(t('Asset type saved'));
    }
  };

  const handleDelete = () => {
    const result = deleteTypeFunction(selectedType.type_id);
    if (result) {
      const tempTypeKeys = [...typeKeys];
      let newIndex = null;
      for (let index = 0; index < typeKeys.length; index++) {
        const key = typeKeys[index];
        if (key === selectedType.type_id) {
          tempTypeKeys.splice(index, 1);
          setTypeKeys(tempTypeKeys);
          newIndex = index - 1 >= 0 ? index - 1 : index + 1;
          break;
        }
      }
      if (newIndex === null) newIndex = 0;
      const keyArray = Object.keys(typeData);
      if (keyArray && tempTypeKeys.length > 0) {
        if (keyArray.length === 1) newIndex = 0;
        setUpdateAvailable(typeCountData[keyArray[newIndex]] === 0);
        setSelectedType(
          JSON.parse(JSON.stringify({ ...typeData[keyArray[newIndex]], count: typeCountData[keyArray[0]] }))
        );
      } else {
        setSelectedType({});
      }
    }
  };

  const handleCancel = () => {
    setSelectedType({});
    setTypeKeys([]);
    handleVisibility();
  };

  const confirm = () => {
    handleDelete();
  };

  const cancel = () => {
    setPopComfirmOpen(false);
  };

  const handleOpenChange = newOpen => {
    if (!newOpen) {
      setPopComfirmOpen(newOpen);
      return;
    }

    if (updateAvailable) {
      confirm();
    } else {
      setPopComfirmOpen(newOpen);
    }
  };

  const handleCustomFieldChange = (index, key, e) => {
    let typeFieldCopy = JSON.parse(JSON.stringify(typeFields));
    typeFieldCopy[index][key] = e;
    setTypeFields(typeFieldCopy);
  };

  const addCustomField = () => {
    const customFieldsCopy = JSON.parse(JSON.stringify(typeFields));
    customFieldsCopy.push({ key: null, type: null, required: false });
    setTypeFields(customFieldsCopy);
  };

  const removeFieldButton = index => {
    let typeFieldCopy = JSON.parse(JSON.stringify(typeFields));
    typeFieldCopy.splice(index, 1);
    setTypeFields(typeFieldCopy);
  };

  const removeFromFilter = id => {
    for (let index = 0; index < filterList.length; index++) {
      const element = filterList[index];
      if (id === element) {
        let filterListCopy = [...filterList];
        filterListCopy.splice(index, 1);
        setFilterList(filterListCopy);
        if (filterListCopy.length === 0) localStorage.removeItem(`${currentNamespace + 'FilterList'}`);
        else localStorage.setItem(`${currentNamespace + 'FilterList'}`, filterListCopy);
        break;
      }
    }
  };

  const RequiredOptions = key => {
    return (
      <>
        <Option key={key + ' true' + ' option'} value={true}>
          {t('Required')}
        </Option>
        <Option key={key + ' false' + ' option'} value={false}>
          {t('Optional')}
        </Option>
      </>
    );
  };

  const DataTypeOptions = key => {
    return (
      <>
        <Option key={key + ' string' + ' option'} value={'string'}>
          {t('Text')}
        </Option>
        <Option key={key + ' number' + ' option'} value={'number'}>
          {t('Number')}
        </Option>
        <Option key={key + ' boolean' + ' option'} value={'boolean'}>
          {t('True/False')}
        </Option>
        <Option key={key + ' json' + ' option'} value={'json'}>
          {t('JSON')}
        </Option>
      </>
    );
  };

  const TypeSelections = typeKeys.map(key => {
    return (
      <TypeNameRow key={key} selected={key === selectedType.type_id}>
        <TypeName
          selected={key === selectedType.type_id}
          onClick={() => {
            setSelectedType(JSON.parse(JSON.stringify({ ...typeData[key], count: typeCountData[key] })));
            setUpdateSelected(!updateSelected);
          }}
        >
          {typeData[key] ? typeData[key].name : null}
        </TypeName>
        <Eye>
          {typeData[key] && filterList.includes(typeData[key].type_id) ? (
            <Tooltip
              title={t('Show this type in map')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="right"
            >
              <div>
                <Icon
                  onClick={() => {
                    removeFromFilter(typeData[key].type_id);
                  }}
                  type="hidden"
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={t('Hide this type in map')}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="right"
            >
              <div>
                <Icon
                  onClick={() => {
                    setFilterList([...filterList, typeData[key].type_id]);
                    localStorage.setItem(`${currentNamespace + 'FilterList'}`, [...filterList, typeData[key].type_id]);
                  }}
                  type="view"
                />
              </div>
            </Tooltip>
          )}
        </Eye>
      </TypeNameRow>
    );
  });

  return (
    <MovableModal
      title={t('Asset types')}
      open={isOpen}
      destroyOnClose={true}
      footer={null}
      onCancel={handleCancel}
      styles={{ body: { minHeight: '470px', maxHeight: '603px', display: 'flex' } }}
      width={950}
    >
      <ModaInner>
        <ModalLeft>
          {TypeSelections}
          <CreateNewLink
            selected={!!selectedType?.createNew}
            onClick={() => {
              setSelectedType(JSON.parse(JSON.stringify({ ...emptyNewType })));
              setUpdateSelected(!updateSelected);
            }}
          >
            {t('Create new type')} <Icon type="plus" />
          </CreateNewLink>
        </ModalLeft>
        <ModalRight>
          {Object.keys(selectedType).length === 0 ? (
            typeKeys.length > 0 ? (
              <Loading spinning={true} size="default"></Loading>
            ) : (
              t('There are no asset types available.')
            )
          ) : (
            <>
              <Header>{selectedType.createNew ? t('Create new asset for' + ' ' + currentNamespace) : t('Info')}</Header>

              <DataRowInfo style={{ marginBottom: '20px' }}>
                {!selectedType.createNew && (
                  <>
                    <p>
                      {t('Number of created assets')}: <Bold>{selectedType.count}</Bold>
                    </p>
                    <p>
                      {t('Namespace')}: <Bold>{selectedType.namespace}</Bold>
                    </p>
                    <p>
                      {t('UUID')}: <Bold>{selectedType.type_id}</Bold>
                    </p>
                  </>
                )}
              </DataRowInfo>

              <Header>{t('Common fields')}</Header>
              <DataRow enabled={updateAvailable}>
                <TypeDataField>
                  <TypeDataHeader>
                    {t('Asset type name')}
                    <Required>*</Required>
                  </TypeDataHeader>
                  <TypeDataInput>
                    <Input
                      placeholder={t('Type name')}
                      value={selectedType.name}
                      defaultValue={selectedType.name}
                      style={{ color: '#4a4a4a', width: '100%', height: '100%' }}
                      onChange={e => updateAvailable && setSelectedType({ ...selectedType, name: e.target.value })}
                    />
                  </TypeDataInput>
                </TypeDataField>
                <TypeDataField>
                  <TypeDataHeader>{t('Description')}</TypeDataHeader>
                  <TypeDataInput>
                    <Input
                      placeholder={t('Description')}
                      value={selectedType.description}
                      defaultValue={selectedType.description}
                      style={{ color: '#4a4a4a', width: '100%', height: '100%' }}
                      onChange={e =>
                        updateAvailable && setSelectedType({ ...selectedType, description: e.target.value })
                      }
                    />
                  </TypeDataInput>
                </TypeDataField>
                <TypeDataField>
                  <TypeDataHeader>{t('Allowed parents')}</TypeDataHeader>
                  <TypeDataInput>
                    <Select
                      mode={'tags'}
                      showSearch
                      placeholder={t('Select allowed parents')}
                      optionFilterProp="label"
                      onChange={e => updateAvailable && setSelectedType({ ...selectedType, allowed_parents: e })}
                      style={{ color: '#4a4a4a', width: '100%' }}
                      value={selectedType.allowed_parents}
                    >
                      {typeOptions.map(type => (
                        <Option key={type.key} label={type.label}>
                          {type.title}
                        </Option>
                      ))}
                    </Select>
                  </TypeDataInput>
                </TypeDataField>
              </DataRow>
              <Header style={{ marginTop: '11px' }}>{t('Custom fields')}</Header>
              {typeFields?.map((_, index) => {
                return (
                  <DataRow enabled={updateAvailable} key={'Type field' + ' name ' + index}>
                    <TypeDataField>
                      {index === 0 && (
                        <TypeDataHeader>
                          {t('Field name')} <Required>*</Required>
                        </TypeDataHeader>
                      )}
                      <TypeDataInput>
                        <Input
                          placeholder={t('')}
                          value={typeFields[index].key}
                          style={{ color: '#4a4a4a' }}
                          onChange={e => updateAvailable && handleCustomFieldChange(index, 'key', e.target.value)}
                        />
                      </TypeDataInput>
                    </TypeDataField>
                    <TypeDataField>
                      {index === 0 && (
                        <TypeDataHeader>
                          {t('Field type')} <Required>*</Required>
                        </TypeDataHeader>
                      )}
                      <TypeDataInput>
                        <Select
                          placeholder={t('Select type')}
                          style={{ color: '#4a4a4a', width: '100%' }}
                          value={typeFields[index].type || null}
                          onChange={e => updateAvailable && handleCustomFieldChange(index, 'type', e)}
                        >
                          {DataTypeOptions('TypeField ' + index)}
                        </Select>
                      </TypeDataInput>
                    </TypeDataField>
                    <TypeDataField>
                      {index === 0 && <TypeDataHeader>{t('Required')}</TypeDataHeader>}
                      <TypeDataInput>
                        <Select
                          placeholder={t('Is required')}
                          style={{ color: '#4a4a4a', width: 'calc(100% - 32px)' }}
                          value={typeFields[index].required || false}
                          onChange={e => updateAvailable && handleCustomFieldChange(index, 'required', e)}
                        >
                          {RequiredOptions('TypeField ' + index)}
                        </Select>
                        <TrashButton
                          dataLength={typeFields.length}
                          copy
                          onClick={() => updateAvailable && removeFieldButton(index)}
                        >
                          <Icon style={{ display: 'initial' }} type={'trash'} />
                        </TrashButton>
                      </TypeDataInput>
                    </TypeDataField>
                  </DataRow>
                );
              })}
              <DataRow enabled={true}>
                <Tooltip
                  title={updateAvailable ? null : t('Adding fields is not allowed when there are created assets')}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                  placement="top"
                >
                  <div>
                    <Button
                      normal
                      style={{
                        zIndex: '5',
                        marginTop: '-5px',
                        marginLeft: '0px',
                        color: updateAvailable ? '#4990DD' : '#D8D8D8',
                        cursor: !updateAvailable && 'default',
                      }}
                      onClick={() => updateAvailable && addCustomField()}
                    >
                      <Icon type={'circle-plus'} />
                      <AddTypeFieldLink
                        updateAvailable={updateAvailable}
                        href="#"
                        style={{ fontSize: '15px', fontWeight: '400' }}
                      >
                        {t('Add custom field')}
                      </AddTypeFieldLink>
                    </Button>
                  </div>
                </Tooltip>
              </DataRow>
            </>
          )}
        </ModalRight>
        <ButtonWrapper style={Object.keys(selectedType).length === 0 ? { display: 'none' } : {}}>
          <Tooltip
            title={updateAvailable ? null : t('Type editing is not allowed when there are created assets')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="top"
          >
            <div>
              {!selectedType.createNew && (
                <Popconfirm
                  title={t('Are you sure?')}
                  open={popComfirmOpen}
                  onOpenChange={handleOpenChange}
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  icon={null}
                >
                  <ButtonLight
                    type="button"
                    lightred
                    disabled={!updateAvailable}
                    style={{ marginRight: '5px', marginBottom: '0' }}
                  >
                    {t('Delete')}
                  </ButtonLight>
                </Popconfirm>
              )}
              <ButtonLight
                disabled={!updateAvailable || newAssetLoading}
                type="button"
                style={{ marginBottom: '0' }}
                sending={newAssetLoading}
                onClick={() => {
                  handleSave();
                }}
              >
                {t('Save')}
              </ButtonLight>
            </div>
          </Tooltip>
        </ButtonWrapper>
      </ModaInner>
    </MovableModal>
  );
};

export default TypeEditModal;
