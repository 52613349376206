import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonLight from '../../ui/ButtonLight';
import { UserContext } from '../../../context/UserContext';
import InnerInvoiceModal from '../InnerInvoiceModal';
import Input from 'antd/es/input';

const ProductLinkerTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 16px;
  font-weight: 600;
`;

const ProductLinkerBottom = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 2px 16px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-bottom: 10px;
  }
`;

const ProductLinkerBody = styled.div`
  padding: 16px 24px;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(212, 212, 212, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const ProductLinkerContainer = styled.div`
  width: 700px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e8e8e8;
  display: block;
  height: fit-content;
`;

const TemplateContainer = styled.div`
  display: flex;
  background-color: #f0f0f0;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -16px;
  padding: 18px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

const RowContainer = styled.div`
  margin-bottom: 12px;
  width: 400px;
`;

const RowHeader = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
`;

const Required = styled.span`
  color: red;
`;

const SaveAsATemplate = ({ data, closeModal }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    ...data,
    invoice_date: null,
    due_date: null,
    shipping_date: null,
    name: null,
  });
  const [sending, setSending] = useState(false);

  const saveTemplate = async () => {
    setSending(true);
    let result;
    try {
      result = await apiCall('post', 'invoicing/v2/template', {
        name: inputData.name,
        customer_number: inputData.customer,
        tax_class: inputData.tax_class,
        currency_code: inputData.currency,
        template_rows: inputData.rows.map(r => {
          return {
            product_code: r.code,
            modifiers: r.modifications.map(m => {
              return {
                modifier_code: m.code,
              };
            }),
          };
        }),
      });
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal(true);
    } else {
      setSending(false);
    }
  };

  const updateName = value => {
    let newInputData = { ...inputData };
    newInputData.name = value.target.value;
    setInputData(newInputData);
  };

  return (
    <Container>
      <ProductLinkerContainer>
        <ProductLinkerTitle>{t('Save as a template')}</ProductLinkerTitle>
        <ProductLinkerBody>
          <TemplateContainer>
            <RowContainer>
              <RowHeader>
                {t('Template name')}
                <Required>*</Required>
              </RowHeader>
              <Input
                placeholder={t('Name')}
                value={inputData.name || ''}
                style={{ color: '#4a4a4a' }}
                onChange={e => updateName(e)}
              />
            </RowContainer>
          </TemplateContainer>
          <InnerInvoiceModal
            inputData={inputData}
            setInputData={setInputData}
            readOnly={false}
            template={true}
            showSmall={true}
          />
        </ProductLinkerBody>
        <ProductLinkerBottom>
          <ButtonLight style={{ marginRight: '12px' }} cancel onClick={() => closeModal()}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight onClick={saveTemplate} disabled={!inputData.name || sending} sending={sending}>
            {t('Save')}
          </ButtonLight>
        </ProductLinkerBottom>
      </ProductLinkerContainer>
    </Container>
  );
};

export default SaveAsATemplate;
