import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import TimePicker from 'antd/es/time-picker';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import Icon from '../ui/Icon';
import dayjs from 'dayjs';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: auto;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
  margin-left: 12px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / ${props => props.amount});
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
  min-width: 450px;
`;

const RadioButtons = styled.div`
  display: flex;
  margin-top: 6px;
  margin-left: 12px;
`;

const ValidContainer = styled.div`
  margin-bottom: 18px;
  margin-left: 12px;
  margin-top: 18px;

  .ant-radio-disabled + span {
    color: #4a4a4a;
  }
`;

const Cell = styled.div`
  margin-right: 10px;
  align-items: center;
`;

const AddInputButton = styled.span`
  color: ${props => props.theme.color.secondary};
  display: inline-flex;
  cursor: pointer;
  margin-left: 9px;
  margin-top: 6px;

  i {
    height: 21px;
    width: 21px;
  }

  svg {
    height: 21px;
    width: 21px;
  }
`;

const Remove = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ theme }) => theme.color.warning};
  margin-top: ${props => (props.first ? '28px' : '4px')};
  width: 20px;
`;

const AddInputText = styled.div`
  color: ${props => props.theme.color.secondary};
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const ValidRows = styled.div`
  margin-top: 12px;

  .ant-picker-input > input[disabled] {
    color: #4a4a4a;
  }
`;

const Duplicate = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: ${props => (props.first ? '28px' : '4px')};
  width: 20px;
  margin-right: 12px;
`;

const PriceModificationModal = ({ modification, closeAndReload, closeModal, readOnly, duplicateCurrent }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { days } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  const type = modification ? 'edit' : 'new';

  const createTimings = data => {
    if (!data) {
      return {
        type: 'always',
        values: [],
      };
    } else {
      return {
        type: data.type,
        values: data.values.map(v => {
          let newTo = null;
          if (v.to) {
            const toLength = v.to.length;
            if (toLength === 8 && v.to.substring(toLength - 2, toLength) === '59') {
              newTo = dayjs(v.to, 'HH:mm:ss')
                .add(1, 'second')
                .format('HH:mm:ss');
            } else if (
              dayjs(
                data.type === 'range_without_year' ? dayjs().year() + '-' + v.to : v.to,
                'YYYY-MM-DDTHH:mm:ssZ'
              ).seconds() === 59
            ) {
              newTo = dayjs(
                data.type === 'range_without_year' ? dayjs().year() + '-' + v.to : v.to,
                'YYYY-MM-DDTHH:mm:ssZ'
              )
                .add(1, 'second')
                .format('YYYY-MM-DDTHH:mm:ssZ');
            }
          }
          return {
            ...v,
            from: data.type === 'range_without_year' ? dayjs().year() + '-' + v.from : v.from,
            to: newTo ? newTo : v.to,
          };
        }),
      };
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [inputData, setInputData] = useState({
    id: modification?.id,
    name: modification?.name,
    code: modification?.code,
    description: modification?.description,
    adjustment: modification?.adjustment || {
      type: 'percentage',
      value: 0,
    },
    timing: createTimings(modification?.timing),
  });

  const [sending, setSending] = useState(false);

  const title = readOnly
    ? t('Modification')
    : !modification || duplicateCurrent
        ? t('Create new modification')
        : t('Edit modification');

  const dataIsValid = () => {
    if (!inputData.name || !inputData.code || !inputData.adjustment.value || !inputData.description) {
      return false;
    }

    if (inputData.timing.type === 'schedule') {
      if (inputData.timing.values.length === 0) {
        return false;
      } else {
        return !inputData.timing.values.some(v => !v.day || !v.from || !v.to);
      }
    }

    if (inputData.timing.type === 'range') {
      if (inputData.timing.values.length === 0) {
        return false;
      } else {
        return !inputData.timing.values.some(v => !v.from || !v.to);
      }
    }

    return true;
  };

  const updateField = (e, name) => {
    let newInputData = { ...inputData };
    newInputData[name] = e.target.value;
    setInputData(newInputData);
  };

  const updateAmount = e => {
    let newInputData = { ...inputData };
    newInputData.adjustment.value = e.target.value;
    setInputData(newInputData);
  };

  const sendData = async () => {
    setSending(true);
    const dataToBeSend = {
      name: inputData.name,
      code: inputData.code,
      adjustment: {
        type: 'percentage',
        value: inputData.adjustment.value,
      },
      timing:
        inputData.timing.type === 'schedule'
          ? {
              type: inputData.timing.type,
              values: inputData.timing.values.map(v => {
                const from = v.from.length > 12 ? dayjs(v.from) : dayjs(v.from, 'HH:mm:ss');
                const to = v.to.length > 12 ? dayjs(v.to) : dayjs(v.to, 'HH:mm:ss');
                return {
                  day: v.day,
                  from: from.format('HH:mm:ss'),
                  to: to.add(-1, 'second').format('HH:mm:ss'),
                };
              }),
            }
          : inputData.timing.type === 'range'
            ? {
                type: inputData.timing.type,
                values: inputData.timing.values.map(v => {
                  return {
                    from: dayjs(v.from).format('YYYY-MM-DDTHH:mm:ssZ'),
                    to: dayjs(v.to)
                      .add(-1, 'second')
                      .format('YYYY-MM-DDTHH:mm:ssZ'),
                  };
                }),
              }
            : inputData.timing.type === 'range_without_year'
              ? {
                  type: inputData.timing.type,
                  values: inputData.timing.values.map(v => {
                    return {
                      from: dayjs(v.from).format('MM-DDTHH:mm:ssZ'),
                      to: dayjs(v.to)
                        .add(-1, 'second')
                        .format('MM-DDTHH:mm:ssZ'),
                    };
                  }),
                }
              : { type: inputData.timing.type, values: [] },
      description: inputData.description,
    };

    if (!duplicateCurrent && inputData.id) {
      dataToBeSend.id = inputData.id;
    }

    let result;
    try {
      result = await apiCall(dataToBeSend.id ? 'put' : 'post', 'invoicing/v2/modifier', dataToBeSend);
    } catch (e) {
      setSending(false);
    }

    if (result?.data?.modifier) {
      closeAndReload();
    }
  };

  const onValidChange = e => {
    setInputData({ ...inputData, timing: { type: e.target.value, values: [] } });
  };

  const handleDaySelectChange = (index, key, value) => {
    let newValues = [...inputData.timing.values];
    const row = newValues[index];
    row[key] = value;
    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newValues,
      },
    });
  };

  const handleTimesSelectChange = (index, key, value) => {
    let newValues = [...inputData.timing.values];
    const row = newValues[index];
    row[key] = value?.format('YYYY-MM-DDTHH:mm:00Z');
    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newValues,
      },
    });
  };

  const addTimeBasedRow = () => {
    let newArray = [...inputData.timing.values];
    newArray.push({
      day: null,
      from: null,
      to: null,
    });
    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newArray,
      },
    });
  };

  const removeTimeBasedRow = index => {
    const newArray = [...inputData.timing.values];
    newArray.splice(index, 1);

    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newArray,
      },
    });
  };

  const handleDaysSelectChange = (index, key, value) => {
    let newValues = [...inputData.timing.values];
    const row = newValues[index];
    row[key] = value ? value.format('YYYY-MM-DDTHH:mm:00Z') : value;
    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newValues,
      },
    });
  };

  const addDayBasedRow = () => {
    let newArray = [...inputData.timing.values];
    newArray.push({
      from: null,
      to: null,
    });
    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newArray,
      },
    });
  };

  const removeDayBasedRow = index => {
    const newArray = [...inputData.timing.values];
    newArray.splice(index, 1);

    setInputData({
      ...inputData,
      timing: {
        ...inputData.timing,
        values: newArray,
      },
    });
  };

  const duplicateRow = index => {
    let newArray = [...inputData.timing.values];
    if (newArray[index]) {
      newArray.push({ ...newArray[index] });
      setInputData({
        ...inputData,
        timing: {
          ...inputData.timing,
          values: newArray,
        },
      });
    }
  };

  return (
    <Modal title={title} open={true} width={500} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <ScrollContainer>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Modification name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={modification.name} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Name')}
                  value={inputData.name || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'name')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Modification code')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={modification.code} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Code')}
                  value={inputData.code || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'code')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Description')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Description')}
                  value={modification.description}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Description')}
                  value={inputData.description || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'description')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Amount %')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Amount')}
                  value={modification.adjustment?.value}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Amount')}
                  value={inputData.adjustment?.value || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateAmount(e)}
                />
              )}
            </RowContainer>
          </Row>
          <ValidContainer>
            <RowHeader>
              {t('Valid')}
              <Required>*</Required>
            </RowHeader>
            <RadioButtons>
              <Radio.Group onChange={onValidChange} value={inputData.timing.type} disabled={readOnly}>
                <Radio value="always">{t('Always')}</Radio>
                <Radio value="schedule">{t('Weekly schedule')}</Radio>
                <Radio value="range">{t('Time ranges')}</Radio>
                <Radio value="range_without_year">{t('Time ranges without year')}</Radio>
              </Radio.Group>
            </RadioButtons>
            {inputData.timing.type === 'schedule' && (
              <ValidRows>
                {inputData.timing?.values?.map((time, i) => (
                  <Row key={i}>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('Day')}</RowHeader> : null}
                      <Select
                        style={{ width: '124px' }}
                        value={time.day}
                        options={days}
                        onChange={value => handleDaySelectChange(i, 'day', value)}
                        disabled={readOnly}
                      />
                    </Cell>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('Start time')}</RowHeader> : null}
                      <TimePicker
                        style={{ width: '100px' }}
                        value={
                          time.from ? (time.from.length > 12 ? dayjs(time.from) : dayjs(time.from, 'HH:mm:ss')) : null
                        }
                        format={'HH:mm'}
                        onChange={value => handleTimesSelectChange(i, 'from', value)}
                        onSelect={value => handleTimesSelectChange(i, 'from', value)}
                        disabled={readOnly}
                        minuteStep={15}
                      />
                    </Cell>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('End time')}</RowHeader> : null}
                      <TimePicker
                        style={{ width: '100px' }}
                        value={time.to ? (time.to.length > 12 ? dayjs(time.to) : dayjs(time.to, 'HH:mm:ss')) : null}
                        format={'HH:mm'}
                        onChange={value => handleTimesSelectChange(i, 'to', value)}
                        onSelect={value => handleTimesSelectChange(i, 'to', value)}
                        disabled={readOnly}
                        minuteStep={15}
                      />
                    </Cell>
                    {!readOnly && (
                      <Duplicate onClick={() => duplicateRow(i)} first={i === 0}>
                        <Icon type={'duplicate'} />
                      </Duplicate>
                    )}
                    {!readOnly && (
                      <Remove onClick={() => removeTimeBasedRow(i)} first={i === 0}>
                        <Icon type={'trash'} />
                      </Remove>
                    )}
                  </Row>
                ))}
                {!readOnly && (
                  <AddInputButton onClick={() => addTimeBasedRow()}>
                    <Icon type={'plus'} />
                    <AddInputText>{t('Add day and time')}</AddInputText>
                  </AddInputButton>
                )}
              </ValidRows>
            )}
            {inputData.timing.type === 'range' && (
              <ValidRows>
                {inputData.timing?.values?.map((time, i) => (
                  <Row key={i}>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('From')}</RowHeader> : null}
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                        style={{
                          width: '100%',
                          color: '#4a4a4a',
                        }}
                        value={time.from ? dayjs(time.from) : null}
                        onChange={value => handleDaysSelectChange(i, 'from', value)}
                        onSelect={value => handleDaysSelectChange(i, 'from', value)}
                        placeholder={t('From')}
                        disabled={readOnly}
                      />
                    </Cell>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('To')}</RowHeader> : null}
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                        style={{
                          width: '100%',
                          color: '#4a4a4a',
                        }}
                        value={time.to ? dayjs(time.to) : null}
                        onChange={value => handleDaysSelectChange(i, 'to', value)}
                        onSelect={value => handleDaysSelectChange(i, 'to', value)}
                        placeholder={t('To')}
                        disabled={readOnly}
                      />
                    </Cell>
                    {!readOnly && (
                      <Remove onClick={() => removeDayBasedRow(i)} first={i === 0}>
                        <Icon type={'trash'} />
                      </Remove>
                    )}
                  </Row>
                ))}
                {!readOnly && (
                  <AddInputButton onClick={() => addDayBasedRow()}>
                    <Icon type={'plus'} />
                    <AddInputText>{t('Add days')}</AddInputText>
                  </AddInputButton>
                )}
              </ValidRows>
            )}
            {inputData.timing.type === 'range_without_year' && (
              <ValidRows>
                {inputData.timing?.values?.map((time, i) => (
                  <Row key={i}>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('From')}</RowHeader> : null}
                      <DatePicker
                        format="DD.MM HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                        style={{
                          width: '100%',
                          color: '#4a4a4a',
                        }}
                        value={time.from ? dayjs(time.from) : null}
                        onChange={value => handleDaysSelectChange(i, 'from', value)}
                        onSelect={value => handleDaysSelectChange(i, 'from', value)}
                        placeholder={t('From')}
                        disabled={readOnly}
                      />
                    </Cell>
                    <Cell>
                      {i === 0 ? <RowHeader>{t('To')}</RowHeader> : null}
                      <DatePicker
                        format="DD.MM HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 15, defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                        style={{
                          width: '100%',
                          color: '#4a4a4a',
                        }}
                        value={time.to ? dayjs(time.to) : null}
                        onChange={value => handleDaysSelectChange(i, 'to', value)}
                        onSelect={value => handleDaysSelectChange(i, 'to', value)}
                        placeholder={t('To')}
                        disabled={readOnly}
                      />
                    </Cell>
                    {!readOnly && (
                      <Remove onClick={() => removeDayBasedRow(i)} first={i === 0}>
                        <Icon type={'trash'} />
                      </Remove>
                    )}
                  </Row>
                ))}
                {!readOnly && (
                  <AddInputButton onClick={() => addDayBasedRow()}>
                    <Icon type={'plus'} />
                    <AddInputText>{t('Add days')}</AddInputText>
                  </AddInputButton>
                )}
              </ValidRows>
            )}
          </ValidContainer>
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {type === 'new' ? t('Add') : t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default PriceModificationModal;
