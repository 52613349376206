import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMapEvents, useMap, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import Button from '../ui/Button';
import Icon from '../ui/Icon';

import { getOptions } from '../map/MapUtils';
import { UserContext } from '../../context/UserContext';

import shipIcon from '../../images/icons/ship-bg.svg';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 8px;
  bottom: 8px;
  display: inline-grid;
  margin: 0;
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
`;

const VesselLocationMapInnerContainer = ({ zoom, setZoom, data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();
  const map = useMap();

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  useEffect(() => {
    map.invalidateSize();
  });

  const getVesselIcon = () => {
    return L.divIcon({
      html: `<div class="div-map-marker-vessel">
      <img src="${shipIcon}"
        style="height:16px;" />`,
      iconSize: [16, 16],
      iconAnchor: [8, 8],
      className: 'map-vessel-icon-class',
    });
  };

  return (
    <>
      <ZoomButtonContainer>
        <ZoomButton data-testid="map-zoom-in" copy onClick={() => handleZoomButton(zoom + 1)}>
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton data-testid="map-zoom-out" copy onClick={() => handleZoomButton(zoom - 1)}>
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      {!!data && (
        <Marker icon={getVesselIcon()} position={[data.geometry.coordinates[1], data.geometry.coordinates[0]]}>
          <Tooltip direction={'top'} opacity={1}>
            <div>
              <div style={{ marginBottom: '6px' }}>
                <b>{t('Location')}</b>
              </div>
              <div>
                {t('Latitude')}: <b>{data.geometry.coordinates[1]}</b>
              </div>
              <div>
                {t('Longitude')}: <b>{data.geometry.coordinates[0]}</b>
              </div>
            </div>
          </Tooltip>
        </Marker>
      )}
    </>
  );
};

export default VesselLocationMapInnerContainer;
