import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import List from '../../components/ui/List';
import ListActionButton from '../../components/ui/ListActionButton';

const VisVessels = () => {
  const { namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'VIS events | Port Activity App';
  }, []);

  const params = new URLSearchParams(location.search);

  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: 'vessel_name',
  };
  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'vessels', newParams);
  const { loading: loadingPoll, fetchData: fetchPoll } = useApi('get', 'vis-service-poll');

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const visVessels = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleVisServicePoll = async () => {
    await fetchPoll(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
    },
    {
      title: t('Name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
    },
    {
      title: t('Service ID'),
      dataIndex: 'service_id',
      key: 'service_id',
    },
    {
      title: t('Service URL'),
      dataIndex: 'service_url',
      key: 'service_url',
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          disabled={!user.permissions.includes('view_vis_information')}
          onClick={() => history.push(`/vis-vessels/sent-text-messages/${record.service_id}`)}
        >
          {t('Sent TXT')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          disabled={!user.permissions.includes('view_vis_information')}
          onClick={() => history.push(`/vis-vessels/received-text-messages/${record.service_id}`)}
        >
          {t('Recieved TXT')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-3"
          disabled={!user.permissions.includes('view_vis_information')}
          onClick={() => history.push(`/vis-vessels/sent-voyage-plans/${record.service_id}`)}
        >
          {t('Sent VP')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-4"
          disabled={!user.permissions.includes('view_vis_information')}
          onClick={() => history.push(`/vis-vessels/received-voyage-plans/${record.service_id}`)}
        >
          {t('Recieved VP')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-5"
          disabled={!user.permissions.includes('send_vis_text_message')}
          onClick={() => history.push(`/vis-vessels/send-text-message/${record.service_id}`)}
        >
          {t('Send TXT')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-6"
          disabled={!user.permissions.includes('send_vis_rta')}
          onClick={() => history.push(`/vis-vessels/send-rta/${record.service_id}`)}
        >
          {t('Send RTA')}
        </ListActionButton>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: handleVisServicePoll,
      disabled: !user.permissions.includes('view_vis_information'),
      text: t('Poll VIS Service'),
    },
    {
      onClick: () => history.push('/vis-vessels/vis-service-configuration'),
      disabled: !user.permissions.includes('view_vis_information'),
      text: t('View VIS Service Configuration'),
    },
    {
      onClick: () => history.push('/vis-vessels/vis-service-find'),
      disabled: !user.permissions.includes('view_vis_information'),
      text: t('Find VIS Service'),
    },
    {
      onClick: () => history.push('/vis-vessels/vis-notifications'),
      disabled: !user.permissions.includes('view_vis_information'),
      text: t('Notifications'),
    },
  ];

  return (
    <Layout pagename={t('VIS Vessels')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={visVessels}
          actions={actions}
          spinning={loading || loadingPoll}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          additionalButtons={additionalButtons}
        />
      </Page>
    </Layout>
  );
};

export default VisVessels;
