import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import MovableModal from '../activity/MovableModal';
import HelpModalInner from './HelpModalInner';
import { SideHelpContext } from '../../context/SideHelpContext';
import ButtonLight from '../ui/ButtonLight';

const Title = styled.span`
  font-weight: 400;
`;

const Info = styled.span`
  margin-right: 4px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: '1px solid transparent';
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const HelpModal = ({
  objectType,
  objectId,
  open,
  setOpen,
  coordinates,
  usableMarkdownId,
  onlyFullMatch = false,
  refetchWikiData,
  openEditFirst = false,
  backUpId,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sideWikiProps, setSideHelpOpen, setSideWikiProps } = useContext(SideHelpContext);

  const [title, setTitle] = useState(null);
  const [editModeOn, setEditModeOn] = useState(openEditFirst);

  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const closeModal = () => {
    if (editModeOn) {
      setCloseConfirmation(true);
    } else {
      setCloseConfirmation(false);
      setEditModeOn(false);
      setOpen(false);
      setStartLoader(true);
    }
  };

  return open && !sideWikiProps ? (
    <MovableModal
      title={
        <Title>
          <Info>{title || t('Additional information')}</Info>
        </Title>
      }
      open={open}
      width={1000}
      onCancel={closeModal}
      footer={null}
    >
      <HelpModalInner
        objectType={objectType}
        objectId={objectId}
        open={open}
        setOpen={setOpen}
        coordinates={coordinates}
        usableMarkdownId={usableMarkdownId}
        onlyFullMatch={onlyFullMatch}
        refetchWikiData={refetchWikiData}
        backUpId={backUpId}
        setTitle={setTitle}
        closeConfirmation={closeConfirmation}
        setCloseConfirmation={setCloseConfirmation}
        startLoader={startLoader}
        setStartLoader={setStartLoader}
        setEditModeOn={setEditModeOn}
        editModeOn={editModeOn}
      />
      <MiddleLine />
      <ActionButtons>
        <ButtonLight
          type="button"
          onClick={() => {
            setSideWikiProps({
              objectType,
              objectId,
              coordinates,
              usableMarkdownId,
            });
            setSideHelpOpen(true);
            setOpen(false);
          }}
        >
          {t('Move modal to the side')}
        </ButtonLight>
        <ButtonLight type="button" cancel onClick={closeModal}>
          {t('Close')}
        </ButtonLight>
      </ActionButtons>
    </MovableModal>
  ) : null;
};

export default HelpModal;
