import React from 'react';
import styled from 'styled-components';
import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';
import { getWindType } from './weatherUtils';

const BptWeather = styled.div`
  width: 100%;
  background-color: ${props => (props.index === 0 || props.index === 1) && '#fbfbfb'};
  background-color: ${props => props.index === 2 && '#f8f7f7'};
  background-color: ${props => props.index === 3 && '#f3f3f3'};
  padding: 0 4px;
  min-width: ${props => props.notNull && '40px'};
  height: 65px;

  @media (max-width: 1330px) {
    min-width: ${props => props.notNull && '24px'};
    padding: 0 1px;
    padding-top: 4px;
  }
`;

const WindContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px 0 0 0;

  svg {
    width: 12px;
    margin-top: -8px;
  }

  @media (max-width: 1330px) {
    margin: 6px 0 0 0;
    display: inline-block;
    text-align: center;
    width: 100%;
    line-height: 12px;

    svg {
      width: 8px;
      margin-top: 0px;
    }
  }
`;

const WindInfo = styled.div`
  color: black;
  font-size: 12px;
  display: flex;
  margin-left: 2px;

  span {
    font-size: 8px;
    margin-top: 3px;
  }

  @media (max-width: 1330px) {
    font-size: 10px;
    justify-content: center;

    span {
      font-size: 7px;
      margin-top: 2px;
    }
  }
`;

const TempDegree = styled.div`
  font-size: 14px;
  text-align: center;
  span {
    font-size: 9px;
  }

  @media (max-width: 1330px) {
    font-size: 10px;

    span {
      font-size: 7px;
    }
  }
`;

const WeatherSymbol = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;
  margin-top: -2px;
  margin-bottom: -3px;

  svg {
    width: 24px;
    margin-top: -6px;
  }

  @media (max-width: 1330px) {
    display: none;
  }
`;

const WeatherSymbolForSmallScreen = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;
  margin-top: -2px;
  margin-bottom: -3px;

  svg {
    width: 24px;
    margin-top: -6px;
  }

  @media (min-width: 1331px) {
    display: none;
  }
`;

const WeatherSymbolWithRain = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -32px;
  margin-top: -2px;
  margin-bottom: -3px;
  position: relative;

  svg {
    width: 24px;
    margin-top: -6px;
  }

  @media (max-width: 1330px) {
    display: none;
  }
`;

const SmallScreenSpace = styled.div`
  height: 12px;

  @media (min-width: 1331px) {
    display: none;
  }
`;

const RainText = styled.div`
  position: absolute;
  top: 2px;
  left: 75%;
  font-size: 11px;
  color: ${({ theme }) => theme.color.secondary};
`;

const RainUnit = styled.div`
  color: ${({ theme }) => theme.color.grey_light2};
  font-size: 7px;
  margin-top: -4px;
`;

const BerthPlanningWeatherCard = ({ data, type, index }) => {
  if (!data.time) {
    return <BptWeather key={data.time} index={0} />;
  }

  return (
    <BptWeather notNull={!!data.time} index={index}>
      <TempDegree>
        {data.temperature || data.temperature === 0 ? Math.round(data.temperature) : '-'}
        <span>°C</span>
      </TempDegree>
      {data.rain_intensity ? (
        <WeatherSymbolWithRain>
          <WeatherIconCombined type={data.description} descriptionObj={data.description_object} />
          <RainText>
            {data.rain_intensity ? data.rain_intensity : '-'}
            <RainUnit>mm/h</RainUnit>
          </RainText>
        </WeatherSymbolWithRain>
      ) : (
        <WeatherSymbol>
          <WeatherIconCombined type={data.description} descriptionObj={data.description_object} />
        </WeatherSymbol>
      )}
      <WeatherSymbolForSmallScreen>
        <WeatherIconCombined type={data.description} descriptionObj={data.description_object} />
      </WeatherSymbolForSmallScreen>
      <WindContainer>
        {type !== 'month' && (
          <WeatherIcon
            type={data.wind_speed || data.wind_speed === 0 ? getWindType(data.wind_speed) : null}
            rotation={data.wind_direction || 0}
          />
        )}
        {type === 'month' && <SmallScreenSpace />}
        <WindInfo>
          {data.wind_speed || data.wind_speed === 0 ? Math.round(data.wind_speed) : '-'} <span>m/s</span>
        </WindInfo>
      </WindContainer>
    </BptWeather>
  );
};

export default BerthPlanningWeatherCard;
