import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popconfirm from 'antd/es/popconfirm';

import { UserContext } from '../../../../context/UserContext';

import Icon from '../../../ui/Icon';
import FinnpilotOperationNoticeLine from './FinnpilotOperationNoticeLine';
import FinnpilotOperationUpdateLine from './FinnpilotOperationUpdateLine';
import FinnpilotOperationOrderLine from './FinnpilotOperationOrderLine';
import FinnpilotStatusLine from './FinnpilotStatusLine';
import FinnpilotComments from './FinnpilotComments';
import FinnpilotChangeLog from './FinnpilotChangeLog';
import FinnpilotServiceOrderInfo from './FinnpilotServiceOrderInfo';
import { ServiceOrderContext } from '../../../../context/ServiceOrderContext';

const Container = styled.div`
  box-shadow: ${props => props.isTaskList && '1px 1px 3px 2px rgba(0, 0, 0, 0.15)'};
  border-radius: ${props => props.isTaskList && '3px'};
  background-color: ${props => props.isTaskList && '#f9f9f9'};
  min-height: ${props => props.showComments && '300px'};

  textarea {
    resize: none;
    height: 94px;
  }
`;

const ServiceOrderContainer = styled.div`
  display: inline-block;
  width: 100%;
  padding: 8px 12px 8px 16px;
  border-radius: 2px;
  margin-bottom: 10px;
  background-color: 'transparent';
`;

const PilotageContainer = styled.div`
  display: inline-block;
  width: 100%;
  padding: 8px 12px 8px 16px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  right: 0px;
`;

const CancelButton = styled.div`
  margin-right: 18px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;
  font-weight: 600;

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -4px;
    margin-right: -3px;
  }
`;

const SentSuccess = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 2;
`;

const FinnpilotServiceOrderLine = ({ vessel, line, reloadData, isTaskList = false }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { socketUpdate } = useContext(ServiceOrderContext);

  const initialData = {
    state: line?.data?.external_data?.state || '',
    startTime: line?.data?.external_data?.start_time || null,
    route: {
      start: {
        name: '',
        code: line?.data?.external_data?.locode_start || '',
      },
      end: {
        name: '',
        code: line?.data?.external_data?.locode_end || '',
      },
    },
  };
  const [finnpilotData, setFinnpilotData] = useState(initialData);

  const [sentUpdateSuccess, setSentUpdateSuccess] = useState(undefined);
  const [sentNoticeSuccess, setSentNoticeSuccess] = useState(undefined);
  const [sentOrderSuccess, setSentOrderSuccess] = useState(undefined);
  const [sentCommentSuccess, setSentCommentSuccess] = useState(undefined);
  let timer = useRef();

  const [loading, setLoading] = useState(false);
  const [showChanges, setShowChanges] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const closeComments = () => {
    setShowComments(false);
  };

  const openComments = () => {
    setShowComments(true);
  };

  const closeChanges = () => {
    setShowChanges(false);
  };

  const openChanges = () => {
    setShowChanges(true);
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const sendExternalTypeData = async (data, otherData, status, reloadOnlyFinnpilot = false) => {
    const result = await apiCall('post', 'service-orders/service-order-line/external-type-data', data);
    if (result?.status === 200) {
      const message = otherData.vessel?.name || otherData.pilotageId;
      if (status === 'update') {
        setFinnpilotData(initialData);
        setSentUpdateSuccess(message);
        timer.current = setTimeout(() => {
          setSentUpdateSuccess(undefined);
          setSentNoticeSuccess(undefined);
          setSentOrderSuccess(undefined);
          setSentCommentSuccess(undefined);
        }, 3000);
      } else if (status === 'notice') {
        setFinnpilotData(initialData);
        setSentNoticeSuccess(message);
        timer.current = setTimeout(() => {
          setSentUpdateSuccess(undefined);
          setSentNoticeSuccess(undefined);
          setSentOrderSuccess(undefined);
          setSentCommentSuccess(undefined);
        }, 3000);
      } else if (status === 'order') {
        setFinnpilotData(initialData);
        setSentOrderSuccess(message);
        timer.current = setTimeout(() => {
          setSentUpdateSuccess(undefined);
          setSentNoticeSuccess(undefined);
          setSentOrderSuccess(undefined);
          setSentCommentSuccess(undefined);
        }, 3000);
      } else if (status === 'comment') {
        setSentCommentSuccess(message);
        timer.current = setTimeout(() => {
          setSentUpdateSuccess(undefined);
          setSentNoticeSuccess(undefined);
          setSentOrderSuccess(undefined);
          setSentCommentSuccess(undefined);
        }, 3000);
      } else {
        setFinnpilotData(initialData);
      }

      reload(reloadOnlyFinnpilot);
    }
  };

  const fetchFinnpilotData = useCallback(
    async id => {
      let data = {};
      if (mounted.current) {
        setLoading(true);
      }
      const result = await apiCall('get', `service-orders/service-order-line/external-type-data/${id}`, {});
      if (mounted.current) {
        setLoading(false);
      }
      if (result?.status === 200 && result?.data) {
        data = result.data?.result || null;
      }
      if (data && mounted.current) {
        setFinnpilotData(data);
      }
    },
    [apiCall]
  );

  useEffect(() => {
    fetchFinnpilotData(line.id);

    return () => {
      clearTimeout(timer.current);
    };
  }, [fetchFinnpilotData, line.id]);

  const reload = (onlyFinnpilot = false) => {
    if (!onlyFinnpilot) {
      reloadData();
    }
    fetchFinnpilotData(line.id);
  };

  const socketUpdateRef = useRef(socketUpdate);
  useEffect(() => {
    if (socketUpdate !== socketUpdateRef.current) {
      fetchFinnpilotData(line.id);
    }
    socketUpdateRef.current = socketUpdate;
  }, [fetchFinnpilotData, line.id, socketUpdate]);

  const cancelServiceOrder = async id => {
    const result = await apiCall('post', 'service-orders/service-order-line/cancel/' + id, {
      is_cancelled: true,
    });

    if (result?.status === 200) {
      reload();
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.querySelector('#finnpilot-comments');
      if (modal ? !modal.contains(event.target) : true) {
        closeComments();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.querySelector('#finnpilot-changelog');
      if (modal ? !modal.contains(event.target) : true) {
        closeChanges();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container isTaskList={isTaskList} showComments={showComments || showChanges}>
      {sentUpdateSuccess && (
        <SentSuccess>
          {t('Pilotage update for {{service}} submitted successfully.', { service: sentUpdateSuccess })}
        </SentSuccess>
      )}
      {sentNoticeSuccess && (
        <SentSuccess>
          {t('Pilotage notice for {{service}} submitted successfully.', { service: sentNoticeSuccess })}
        </SentSuccess>
      )}
      {sentOrderSuccess && (
        <SentSuccess>
          {t('Pilotage order for {{service}} submitted successfully.', { service: sentOrderSuccess })}
        </SentSuccess>
      )}
      {sentCommentSuccess && (
        <SentSuccess>
          {t('Comment for {{service}} submitted successfully.', { service: sentCommentSuccess })}
        </SentSuccess>
      )}
      {!isTaskList ? (
        <ServiceOrderContainer>
          <FinnpilotServiceOrderInfo data={line} />
          <Buttons>
            {line.status !== 'cancelled' &&
              !line.data?.external_data?.disabledActions?.includes('cancel') &&
              line.canEdit && (
              <Popconfirm
                title={t('Cancel service order for {{name}}?', { name: vessel.vessel_name })}
                onConfirm={() => cancelServiceOrder(line.id)}
                okText={t('Yes')}
                okType="danger"
                cancelText={t('No')}
                icon={null}
                id="pop-confirm-for-new-list"
                key="action-1"
              >
                <CancelButton>
                  <Icon type="trash" />
                  {t('Cancel')}
                </CancelButton>
              </Popconfirm>
            )}
          </Buttons>
        </ServiceOrderContainer>
      ) : null}
      <PilotageContainer>
        <FinnpilotStatusLine data={finnpilotData} openChanges={openChanges} openComments={openComments} />
        {finnpilotData.operationUpdate && line.canEdit ? (
          <FinnpilotOperationUpdateLine
            orderLineId={line.id}
            data={finnpilotData}
            sendData={sendExternalTypeData}
            loading={loading}
          />
        ) : null}
        {finnpilotData.operationNotice && line.canEdit ? (
          <FinnpilotOperationNoticeLine
            orderLineId={line.id}
            data={finnpilotData}
            sendData={sendExternalTypeData}
            loading={loading}
          />
        ) : null}
        {finnpilotData.operationOrder && line.canEdit ? (
          <FinnpilotOperationOrderLine
            orderLineId={line.id}
            data={finnpilotData}
            sendData={sendExternalTypeData}
            loading={loading}
          />
        ) : null}
      </PilotageContainer>
      {!!showChanges && <FinnpilotChangeLog data={finnpilotData} close={closeChanges} isTaskList={isTaskList} />}
      {!!showComments && (
        <FinnpilotComments
          orderLine={line}
          data={finnpilotData}
          close={closeComments}
          sendData={sendExternalTypeData}
          loading={loading}
          isTaskList={isTaskList}
        />
      )}
    </Container>
  );
};

export default FinnpilotServiceOrderLine;
