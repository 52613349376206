import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Checkbox from 'antd/es/checkbox';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import GroupUsers from './GroupUsers';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

const GROUP_BASE_URL = 'group';

const Groups = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [groupsData, setGroupsData] = useState(null);
  const [groupsError, setGroupsError] = useState(null);
  const [groupsLoading, setGroupsLoading] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initGroup = {
    id: null,
    name: '',
    description: '',
    permissions: [],
    created_at: '',
    modified_at: '',
    created_by: '',
    modified_by: '',
  };

  const [group, setGroup] = useState(initGroup);

  const initModal = {
    visible: false,
    confirmLoading: false,
    group: {
      id: null,
      name: '',
      description: '',
      permissions: [],
      created_at: '',
      modified_at: '',
      created_by: '',
      modified_by: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'name',
    search: params.get('search') ? params.get('search') : '',
  };

  const groupsChanged = useCallback(data => {
    if (data) {
      setGroupsData(data);
    } else {
      setGroupsData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: groupsFetchLoading, error: groupsFetchError, fetchData: groupsFetchData } = useApi(
    'get',
    'groups',
    newParams,
    groupsChanged,
    false
  );

  useEffect(() => {
    groupsFetchData(false, newParams);
  }, [newParams, groupsFetchData]);

  const permissionsChanged = useCallback(data => {
    if (data) {
      setPermissions(data);
    } else {
      setPermissions(null);
    }
  }, []);

  const { fetchData: fetchPermissions } = useApi('get', 'permissions', {}, permissionsChanged, false);

  useEffect(() => {
    setGroupsError(groupsFetchError);
  }, [groupsFetchError]);

  useEffect(() => {
    setGroupsLoading(groupsFetchLoading);
  }, [groupsFetchLoading]);

  if (groupsError) {
    message.error(groupsError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groups = groupsLoading || !groupsData?.['groups'] ? [] : groupsData['groups'];

  const { start, total } = groupsError || !groupsData ? { start: 0, total: 0 } : groupsData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      fetchPermissions();
      const { data } = await apiCall('get', `${GROUP_BASE_URL}/${entryIdentifier}`);
      setGroup({
        ...data,
        permissions: data.permissions?.map(permission => permission.name),
      });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${GROUP_BASE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await groupsFetchData(false, defaultParams);
  };

  const handleSave = async values => {
    const { name, description } = values;
    const result = await apiCall('post', `${GROUP_BASE_URL}`, {
      name: name,
      description: description,
      permissions: group?.permissions ? group.permissions : [],
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setGroup(initGroup);
      history.replace(location.pathname);
      groupsFetchData();
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setGroup(initGroup);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${GROUP_BASE_URL}`, {
        id: group.id,
        name: group.name ? group.name : '',
        description: group.description ? group.description : '',
        permissions: group.permissions ? group.permissions : [],
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setGroup(initGroup);
    history.replace(location.pathname);
    await groupsFetchData(false, defaultParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setGroup(initGroup);
  };

  const handleModalChange = e => {
    const { name, value } = e.target;
    setGroup({ ...group, [name]: value });
  };

  const checkboxClicked = (key, event) => {
    const { checked } = event.target;
    setGroup(previous => {
      let permissionsLocal = [];
      if (checked) {
        permissionsLocal = [...previous.permissions, key];
      } else {
        permissionsLocal = previous.permissions.filter(permission => permission !== key);
      }
      return {
        ...previous,
        permissions: permissionsLocal,
      };
    });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setGroup({ ...group, [name]: value });
  };

  const addNewCheckboxClicked = (key, event) => {
    handleChange(event);
    const { checked } = event.target;
    setGroup(previous => {
      let permissionsLocal = [];
      if (checked) {
        permissionsLocal = [...previous.permissions, key];
      } else {
        permissionsLocal = previous.permissions.filter(permission => permission !== key);
      }
      return {
        ...previous,
        permissions: permissionsLocal,
      };
    });
  };

  const fields = ['name', 'description', 'permissions'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, { permissions: [] });

  useEffect(() => {
    let isValid = group?.name?.length > 0;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, group]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      sortableKey: 'description',
    },
    {
      title: t('Permissions'),
      dataIndex: 'permissions',
      key: 'permissions',
      render: record => {
        return (
          <>
            {record?.map(permission => (
              <Content2 key={permission.name}>{permission.readable_name}</Content2>
            ))}
          </>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'modified_at',
      sortableKey: 'modified_at',
      render: record => {
        let modified_by = record?.modified_by || '';
        if (record?.modified_by_name && record?.modified_by_email) {
          modified_by = `${record.modified_by_name} (${record.modified_by_email})`;
        } else if (record?.modified_by_name) {
          modified_by = record.modified_by_name;
        } else if (record?.modified_by_email) {
          modified_by = record.modified_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.modified_at} />
              </Content2>
            )}
            <Content2>{modified_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete group {{name}}?', { name: record.name })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => {
        fetchPermissions();
        showActions(!actions);
      },
      disabled: groupsLoading,
      text: t('Add group'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <Modal
        title={t('Edit services')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="name"
              label={t('Name')}
              value={group.name ? group.name : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="description"
              label={t('Description')}
              value={group.description ? group.description : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <CheckboxContainer>
              {permissions.map((permission, index) => {
                return (
                  <Checkbox
                    key={`permission-${index}`}
                    defaultChecked={group.permissions.includes(permission.name)}
                    checked={group.permissions.includes(permission.name)}
                    disabled={false}
                    name={permission.name}
                    onClick={e => checkboxClicked(permission.name, e)}
                  >
                    {permission.readable_name}
                  </Checkbox>
                );
              })}
            </CheckboxContainer>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm title={t('Add group')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'permissions') {
                return (
                  <CheckboxContainer key={field}>
                    {permissions.map((permission, index) => {
                      return (
                        <Checkbox
                          key={`permission-${index}`}
                          defaultChecked={group.permissions.includes(permission.name)}
                          checked={group.permissions.includes(permission.name)}
                          disabled={false}
                          name={permission.name}
                          onClick={e => addNewCheckboxClicked(permission.name, e)}
                        >
                          {permission.readable_name}
                        </Checkbox>
                      );
                    })}
                  </CheckboxContainer>
                );
              }
              return (
                <Input
                  label={field.replace(/_/g, ' ')}
                  key={field}
                  name={field}
                  field={field}
                  value={values[field]}
                  type="text"
                  onChange={handleAddNewChange}
                />
              );
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add group')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={groups}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={groupsLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name or description')}
        additionalButtons={additionalButtons}
        expandedRowRender={record => <GroupUsers group={record} />}
      />
    </>
  );
};

export default Groups;
