import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { formatTime } from '../utils/utils';
import { mobilePixelMaxWidthLimit } from '../utils/constants';

const StyledClock = styled.div`
  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 12px;
  }
`;

const Clock = ({ timeOnly, ...props }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 6000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return <StyledClock {...props}>{formatTime(date, timeOnly)}</StyledClock>;
};

export default Clock;
