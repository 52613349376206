import dayjs from 'dayjs';

export const getWindType = speed => {
  let windType = 'wind_direction_';

  if (speed === 0) {
    windType += '0';
  } else if (speed >= 0 && speed < 4) {
    windType += '1_3';
  } else if (speed >= 4 && speed < 8) {
    windType += '4_7';
  } else if (speed >= 8 && speed < 14) {
    windType += '8_13';
  } else if (speed >= 14 && speed < 21) {
    windType += '14_20';
  } else if (speed >= 21) {
    windType += '21_32';
  }

  return windType;
};

export const getFromAndToDateForDayForecast = () => {
  const now = dayjs();

  const hourNow = dayjs().hour();
  let addHours = 0;

  if ([0, 6, 12, 18].includes(hourNow)) {
    addHours = 6;
  } else if ([1, 7, 13, 19].includes(hourNow)) {
    addHours = 5;
  } else if ([2, 8, 14, 20].includes(hourNow)) {
    addHours = 4;
  } else if ([3, 9, 15, 21].includes(hourNow)) {
    addHours = 3;
  } else if ([4, 10, 16, 22].includes(hourNow)) {
    addHours = 2;
  } else if ([5, 11, 17, 23].includes(hourNow)) {
    addHours = 1;
  }

  const startHour = now.startOf('hour').add(addHours, 'hours');

  return {
    from_date_time: startHour.format('YYYY-MM-DDTHH:mm:ssZ'),
    to_date_time: startHour.add(18, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'),
  };
};

export const getFromAndToDateForForecast = () => {
  const nexthour = dayjs()
    .startOf('hour')
    .add(1, 'hour');

  return {
    from_date_time: nexthour.format('YYYY-MM-DDTHH:mm:ssZ'),
    to_date_time: nexthour.add(168, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'),
  };
};

export const getFromAndToDateForSeaLevelForecast = () => {
  const nexthour = dayjs()
    .startOf('hour')
    .add(1, 'hour');

  return {
    from_date_time: nexthour.format('YYYY-MM-DDTHH:mm:ssZ'),
    to_date_time: nexthour.add(47, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'),
  };
};
