import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import { getOptions } from '../map/MapUtils';

const IncidentMarker = ({ coords, level, titleType, titleLocation, date, clickMarker, event }) => {
  if (!coords) {
    return null;
  }

  const options = getOptions();

  let iconSize = 24;

  const getIcon = () => {
    let iconWithColor = null;

    if (event) {
      iconWithColor = options.vesselIncidentIconBlue;
    } else if (level > 7) {
      iconWithColor = options.vesselIncidentIconRed;
    } else if (level < 4) {
      iconWithColor = options.vesselIncidentIconGreen;
    } else {
      iconWithColor = options.vesselIncidentIconYellow;
    }

    return L.divIcon({
      html: `<div class="div-map-marker-vessel"><img src="${iconWithColor}"
        style="background-color:transparent;
          height:${iconSize}px;
          width:${iconSize}px;
          background-size:${iconSize - options.markerIconSpacingPixels}px!important;"/></div>`,
      iconSize: [iconSize, iconSize],
      iconAnchor: [iconSize / 2, iconSize],
      className: 'map-vessel-icon-class',
    });
  };

  return (
    <>
      <Marker
        icon={getIcon()}
        position={L.GeoJSON.coordsToLatLng(coords)}
        data-id="incident"
        eventHandlers={{
          click: () => {
            if (clickMarker) {
              clickMarker();
            }
          },
        }}
      >
        {!!titleType && (
          <Tooltip direction="top" offset={L.point(0, -iconSize)}>
            <div style={{ display: 'flex' }}>
              <div>
                <h4>
                  {titleType} <span style={{ textTransform: 'lowercase' }}>{titleLocation}</span>
                </h4>
                <h4>{date}</h4>
              </div>
            </div>
          </Tooltip>
        )}
      </Marker>
    </>
  );
};

export default IncidentMarker;
