import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;

const ErrorTestingMedium = ({ state }) => {
  return <Header>{state.error}</Header>;
};

export default ErrorTestingMedium;
