import React from 'react';
import styled from 'styled-components';
import MarkDownHandler from '../components/info/MarkDownHandler';
import { gdprMarkdownData } from '../components/landingPage/gdpr';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  margin: 24px;
  max-width: 1000px;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <InnerContainer>
        <MarkDownHandler markDownData={gdprMarkdownData} editable={false} />
      </InnerContainer>
    </Container>
  );
};

export default PrivacyPolicy;
