import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import { UserContext } from './UserContext';

export const InvoicingContext = createContext();

const categories = {
  day: {
    id: 'day',
    description: 'Day',
    color: '#a5d6a7',
  },
  night: {
    id: 'night',
    description: 'Night',
    color: '#a7c4f4',
  },
  weekend: {
    id: 'weekend',
    description: 'Weekend',
    color: '#fbe6a2',
  },
};

export const InvoicingProvider = ({ children }) => {
  const { apiCall, user, namespace, modules } = useContext(UserContext);

  const [locode /*, setLocode */] = useState('FIUKI');
  const [selectedNetTonnage, setSelectedNetTonnage] = useState('0');
  const [contractData, setContractData] = useState([]);
  const [contractNetTonnages, setContractNetTonnages] = useState([]);
  const [matrixData, setMatrixData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [isPricingCategoriesModalVisible, setIsPricingCategoriesModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [portCallData, setPortCallData] = useState([]);
  const [wasteExceptionData, setWasteExceptionData] = useState([]);
  const [customerData, setCustomerData] = useState(undefined);

  const getInvoicingData = async () => {
    const portCallData = await getPortCallData();
    setPortCallData(portCallData);
    await getContractData();
  };

  const getMatrixData = async () => {
    let result;
    try {
      result = await apiCall('get', `invoicing/matrixes?locode=${locode}`);
    } catch (e) {
      setMatrixData({});
    }
    if (result?.data && result.status === 200) {
      setMatrixData(result.data);
    }
  };

  const getPortCallData = async () => {
    let result;
    try {
      result = await apiCall('get', 'invoicing/get-port-calls');
    } catch (e) {
      setPortCallData([]);
    }
    if (result?.data && result.status === 200) {
      return result.data;
    }
  };

  const getCategoryData = async () => {
    let result;
    try {
      result = await apiCall('get', `invoicing/edit-matrixes?locode=${locode}`);
    } catch (e) {
      setCategoryData({});
    }

    if (result?.data && result.status === 200) {
      const res = Object.entries(result.data).reduce((acc, [key, item]) => {
        if (!acc[key]) {
          acc[key] = {};
        }

        const res = item.reduce((acc2, item) => {
          if (!acc2[item.category]) {
            acc2[item.category] = {
              price: item.price,
              id: item.category,
            };
            acc2[item.category].periods = [];
          }
          acc2[item.category].periods.push({
            id: item.id,
            price: item.price,
            fromDay: item.from_day,
            toDay: item.to_day,
            startTime: item.start_time,
            endTime: item.end_time,
          });

          return acc2;
        }, {});

        acc[key] = res;
        return acc;
      }, {});
      setCategoryData(res);
    }
  };

  const getContractData = async () => {
    let result;
    try {
      result = await apiCall('get', `invoicing/info?locode=${locode}`);
    } catch (e) {
      setContractNetTonnages([]);
      setContractData({});
    }

    if (result?.status === 200 && result?.data?.contract) {
      setContractNetTonnages(result.data.contract.vessel_net_tonnages.split(','));
      setContractData(result.data.contract);
      return result.data.contract;
    }
  };

  const getCustomerData = async () => {
    if (modules.invoicing_module === 'enabled' && user.permissions.includes('manage_invoicing')) {
      let result;
      try {
        result = await apiCall('get', 'invoicing/customers');
      } catch (e) {
        setCustomerData(undefined);
      }

      if (result?.status === 200 && result?.data) {
        setCustomerData(result.data);
      }
    }
  };

  const getWasteExceptionData = async () => {
    const result = await apiCall('post', 'search/vessel', {
      query: {
        conditions: {
          and: [
            {
              type: 'waste_exception',
              operator: 'gt',
              value: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            },
          ],
        },
      },
      namespace: namespace,
      order_by: [
        {
          field: 'imo',
          order: 'asc',
        },
      ],
    });
    if (result?.status === 200 && result?.data?.results?.vessels) {
      setWasteExceptionData(result.data.results.vessels);
    }
  };

  const postWasteException = async (vesselImo, validUntilTs) => {
    const result = await apiCall('post', 'invoicing/manual-vessel', {
      vessel: {
        imo: parseInt(vesselImo),
        waste_exception: validUntilTs,
      },
    });
    if (result?.status === 200) {
      getWasteExceptionData();
    }
  };

  const putPortcallInvoiceNotes = async (portcallId, notes) => {
    setIsLoading(true);
    const result = await apiCall('put', 'invoicing/notes', {
      data: {
        port_call_id: portcallId,
        notes: notes,
      },
    });
    if (result?.data?.[1]?.[0]) {
      const { notes, port_call_id } = result.data[1][0];
      const newPortCallData = portCallData;
      const index = newPortCallData.findIndex(pc => pc.port_call_id === port_call_id);

      newPortCallData[index].notes = notes;
      setPortCallData(newPortCallData);
    }
    setIsLoading(false);
  };

  const savePortcallInvoiceData = async (id, value) => {
    setIsLoading(true);
    let result;

    try {
      result = await apiCall('post', 'invoicing/data', {
        data: {
          port_call_id: id,
          payload: value,
        },
      });
    } catch (e) {
      setIsLoading(false);
    }

    if (result?.data) {
      const { port_call_id } = result.data;
      const newPortCallData = portCallData;
      const index = newPortCallData.findIndex(pc => pc.port_call_id === port_call_id);

      newPortCallData[index].data = result.data.data;
      setPortCallData(newPortCallData);
    }
    setIsLoading(false);
  };

  const getStartData = async () => {
    setIsLoading(true);
    if (!dataFetched && modules.invoicing_module === 'enabled' && user.permissions.includes('view_invoicing')) {
      setDataFetched(true);
      await getInvoicingData();
      await getWasteExceptionData();
      await getMatrixData();
      await getCategoryData();
    }
    setIsLoading(false);
  };

  const postEditMatrix = async data => {
    const result = await apiCall('post', 'invoicing/edit-matrix', {
      data: {
        locode: locode,
        net_tonnage: parseInt(selectedNetTonnage),
        ...(data.put && {
          ...{
            put: Object.values(data.put).map(item => ({
              id: parseInt(item.id),
              price: parseFloat(item.price),
              from_day: item.fromDay,
              to_day: item.toDay,
              start_time: item.startTime,
              end_time: item.endTime,
            })),
          },
        }),
        ...(data.post && {
          ...{
            post: Object.values(data.post).map(item => ({
              category: item.category,
              price: parseFloat(item.price),
              from_day: item.fromDay,
              to_day: item.toDay,
              start_time: item.startTime,
              end_time: item.endTime,
            })),
          },
        }),
        ...(data.delete && {
          ...{
            delete: Object.values(data.delete).map(item => ({
              id: item.id,
            })),
          },
        }),
      },
    });
    if (result?.status === 200) {
      getMatrixData();
      getCategoryData();
    }
  };

  const resetMatrixByLocode = async () => {
    const result = await apiCall('put', 'invoicing/edit-matrix', {
      data: {
        locode: locode,
        net_tonnage: parseInt(selectedNetTonnage),
      },
    });
    if (result?.status === 200) {
      getMatrixData();
      getCategoryData();
    }
  };

  return (
    <InvoicingContext.Provider
      value={{
        categories,
        contractData,
        contractNetTonnages,
        matrixData,
        categoryData,
        portCallData,
        selectedNetTonnage,
        setSelectedNetTonnage,
        isPricingCategoriesModalVisible,
        setIsPricingCategoriesModalVisible,
        postEditMatrix,
        resetMatrixByLocode,
        isLoading,
        getStartData,
        wasteExceptionData,
        postWasteException,
        customerData,
        getCustomerData,
        putPortcallInvoiceNotes,
        savePortcallInvoiceData,
      }}
    >
      {children}
    </InvoicingContext.Provider>
  );
};
