import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import BerthPlanningToolMap from './BerthPlanningToolMap';

import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: absolute;
  z-index: 200;
  ${props => {
    if (props.mapSize === 1) {
      return `
        padding-left: 0px;
        width: 250px;
        height: 250px;
        bottom: 17px;
        right: 17px;
        @media screen and (max-width: 1160px) {
          width: auto;
          right: 2px;
          min-width: 0;
        }
        @media screen and (max-width: 939px) {
          display: none;
        }
      `;
    } else if (props.mapSize === 2) {
      return `
        margin-top: 40px;
        margin-left: 4px;
        width: calc(100% - 167px);
        height: 50%;
        bottom: 2px;
        right: 2px;
      `;
    } else if (props.mapSize === 3) {
      return `
        margin-top: 40px;
        margin-left: 4px;
        width: calc(100% - 30px);
        height: 94%;
        bottom: 2px;
        right: 2px;
      `;
    }
  }}

  @media (max-width: 1140px) {
    display: none;
  }
`;

const MapContainer = styled.div`
  position: relative;
  padding-top: 1rem;
  width: ${props => (props.mapSize > 1 ? '100%' : '265px')};
  height: ${props => (props.mapSize > 1 ? '100%' : '265px')};
  z-index: ${props => (props.mapSize > 1 ? 115 : 70)};
  box-shadow: ${props => (props.mapSize > 1 ? '0px 0px 15px lightgray' : '0')};
`;

const ButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 25px;
  z-index: 71;
  flex: 1;
  position: absolute;
  bottom: 20px;
  right: 11.5px;
`;

const MapButton = styled(Button)`
  z-index: 1;
  width: 28px;
  height: 28px;
  margin: 0;
`;
const MapButtonClose = styled(Button)`
  z-index: 1;
  width: 10px;
  height: 10px;
  margin: 0;
  color: #4a4a4a;
`;

const CloseMapContainer = styled.div`
  z-index: 1;
  display: inline;
  position: absolute;
  right: 36px;
  top: 13px;
  width: 0;
  height: 0;
  padding-bottom: 20px;
  align-content: center;
`;
const HeaderContainer = styled.div`
  z-index: 1;
  position: absolute;
  flex: 1;
  display: ${props => (props.mapSize > 1 ? 'inline' : 'none')};
  width: ${props => (props.mapSize > 1 ? 'auto' : '0px')};
  height: ${props => (props.mapSize > 1 ? '56px' : '0px')};
  background-color: white;
  padding-left: 18px;
  padding-top: 15px;
  bottom: 2rem;
  right: 0px;
  left: 0px;
  top: -36px;
  border-radius: 4px;
  box-shadow: ${props => (props.mapSize > 1 ? '0px 0px 10px lightgray' : '0')};
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
  cursor: move;
`;

const BerthPlanningToolMapResizableContainer = ({ craneView = false }) => {
  const [mapSize, setMapSize] = useState(1); // small=1,medium=2,big=3
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const draggableRef = useRef(null);

  const clickIcon = () => {
    if (mapSize === 1) {
      setMapSize(2);
    } else if (mapSize === 2) {
      setMapSize(3);
    } else {
      setMapSize(1);
    }
  };

  const clickClose = () => {
    setMapSize(1);
  };

  return (
    <Draggable
      nodeRef={draggableRef}
      handle=".handle"
      cancel=".cancel"
      position={mapSize !== 2 ? { x: 0, y: 0 } : null}
      bounds=".offsetParent"
    >
      <Wrapper mapSize={mapSize} ref={draggableRef}>
        <MapContainer mapSize={mapSize}>
          <ButtonContainer>
            <MapButton data-testid="map-expand" copy onClick={clickIcon}>
              <Icon
                type={mapSize === 1 ? 'arrow-expand-all' : mapSize === 2 ? 'arrow-expand-max' : 'arrow-collapse-all'}
              />
            </MapButton>
          </ButtonContainer>
          <HeaderContainer mapSize={mapSize} className="handle">
            {t('Current situation in map')}
            <CloseMapContainer>
              <MapButtonClose copy onClick={clickClose} className="cancel">
                <Icon data-testid="map-close" type={'close'} style={{ width: '15px' }} />
              </MapButtonClose>
            </CloseMapContainer>
          </HeaderContainer>
          <BerthPlanningToolMap
            showAttribution={mapSize > 1}
            showResize={true}
            showCraneOptions={mapSize > 1}
            craneView={craneView}
          />
        </MapContainer>
      </Wrapper>
    </Draggable>
  );
};

export default BerthPlanningToolMapResizableContainer;
