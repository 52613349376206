import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';

const Container = styled.div`
  max-width: 200px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;

  button {
    margin-bottom: 0 !important;
    height: 30px !important;
    line-height: 15px !important;
    font-size: 14px !important;
    display: flex;
    padding-right: 1rem;
  }
`;

const HelpContainer = styled.span`
  margin-left: 8px;
  cursor: pointer;
  color: #4a4a4a;
  display: flex;

  i {
    margin-right: 8px;
  }

  svg {
    height: 17px !important;
    width: 17px !important;
    margin-top: -2px;
  }
`;

const QuickLinkPopup = ({ addQuickLinkInfo }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <Container>
      <Title>{t('Do you want to add additional information to this location?')}</Title>
      <ButtonRow>
        <ButtonLight type="button" cancel onClick={() => addQuickLinkInfo()}>
          <HelpContainer>
            <Icon type="circle-question-regular" />
          </HelpContainer>
          {t('Add information')}
        </ButtonLight>
      </ButtonRow>
    </Container>
  );
};

export default QuickLinkPopup;
