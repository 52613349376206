import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { TIME_FORMAT_DAY } from '../../utils/constants';
import { UserContext } from '../../context/UserContext';
import { COLORS } from './ClusterGraphs';

Chart.register(...registerables);

const BarContainer = styled.div`
  max-height: 500px;
  height: calc(100vh / 3);

  ${props => {
    if (props.oneColumn) {
      return `
        max-height: 800px;
        height: calc(70vh);
      `;
    }
  }}
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StatisticsBarMultiColumnCluster = ({
  oneColumn,
  column1,
  dataObject,
  timeType,
  selectedTime,
  universalTimePickerOn,
  universalSelectedTime,
  ...props
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  let datasets = [];
  let labels = [];

  const roundNumber = numb => {
    if (numb < 0.5) {
      return Math.round(numb * 1000) / 1000;
    } else if (numb < 2) {
      return Math.round(numb * 100) / 100;
    } else if (numb < 10) {
      return Math.round(numb * 10) / 10;
    } else {
      return Math.round(numb);
    }
  };

  const getColor = index => {
    return COLORS[index % COLORS.length];
  };

  if (dataObject.clusters) {
    let colorIndex = 0;
    for (const cluster of dataObject.clusters) {
      labels.push(cluster.label);

      for (const dataItem of cluster.data) {
        const legend = props.legend[dataItem.label];
        const label = legend ? legend.title : dataItem.label;
        const dataInDatasetsIndex = datasets.findIndex(s => s.label === label);

        if (dataInDatasetsIndex === -1) {
          datasets.push({
            label: label,
            data: [dataItem.values[0].value ? roundNumber(dataItem.values[0].value) : 0],
            backgroundColor: legend ? legend.color : getColor(colorIndex++),
          });
        } else {
          datasets[dataInDatasetsIndex].data.push(dataItem.values[0].value ? roundNumber(dataItem.values[0].value) : 0);
        }
      }
    }
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.6,
    categoryPercentage: 0.8,
    maxBarThickness: 30,
    layout: {
      padding: {
        left: 10,
        right: 10,
        bottom: 40,
        top: 25,
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
    plugins: {
      legend: {
        display: oneColumn ? datasets.length < 30 : column1 ? datasets.length < 20 : datasets.length < 10,
        position: 'bottom',
        labels: {
          boxWidth: 12,
        },
      },
      title: {
        text: dataObject.title ? dataObject.title.replace(/_/g, ' ') : '',
        display: true,
        padding: {
          bottom: 32,
        },
        font: {
          weight: 'normal',
          size: 14,
        },
      },
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'end',
        formatter: function(value) {
          return parseFloat(value).toFixed(2);
        },
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [8, 6],
          color: '#f4f4f4',
        },
      },
    },
  };

  const getTimeSpan = () => {
    let time = universalTimePickerOn ? universalSelectedTime : selectedTime;
    let from = null;
    let to = null;
    if (timeType === 'week') {
      from = dayjs(time).startOf('isoweek');
      to = dayjs(time).endOf('isoweek');
    } else if (timeType === 'month') {
      from = dayjs(time).startOf('month');
      to = dayjs(time).endOf('month');
    } else if (timeType === 'year') {
      from = dayjs(time).startOf('year');
      to = dayjs(time).endOf('year');
    }
    to.isAfter(dayjs()) && (to = dayjs());
    return dayjs(from).format(TIME_FORMAT_DAY) + ' - ' + dayjs(to).format(TIME_FORMAT_DAY);
  };

  const unitPlugin = {
    afterDatasetsDraw: chart => {
      const {
        ctx,
        chartArea: { top, left },
      } = chart;

      ctx.fillStyle = '#5a5a5a';
      ctx.fillText('kg', left - 6, top - 15);
    },
  };

  return datasets.length > 0 ? (
    <BarContainer oneColumn={oneColumn}>
      <Bar plugins={[ChartDataLabels, unitPlugin]} options={options} data={{ labels, datasets }} />
    </BarContainer>
  ) : timeType ? (
    <NoData>{t('No data available between {{timespan}}.', { timespan: getTimeSpan() })}</NoData>
  ) : (
    <NoData>{t('No data available.')}</NoData>
  );
};
export default StatisticsBarMultiColumnCluster;
