import React from 'react';
import Popover from 'antd/es/popover';
import styled from 'styled-components';

import ShipRoutePopover from './ShipRoutePopover';
import Icon from '../ui/Icon';
import { TIME_FORMAT_NO_YEAR } from '../../utils/constants';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: top;
  width: 100%;
`;

const StyledShipRoute = styled.div`
  font-weight: 700;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  overflow: hidden;
  line-height: 1.5em;
  height: 1.5em;
  font-size: 13px;
  margin-bottom: ${props => (props.moreExtraMargin ? '18px' : props.extraMargin ? '13px' : '3px')};
  i {
    position: relative;
    top: 0;
    flex-shrink: 0;
    height: 10px;
    width: 10px;
    margin: 0 ${({ theme }) => theme.sizing.gap_small};
    color: #4a4a4a;
  }
`;

const Port = styled.div`
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-direction: row;
  max-width: 110px;
  color: #4a4a4a;

  min-width: ${props => props.minWidth && '40px'};
`;

const PortArea = styled.span`
  text-transform: capitalize;
`;

const Subtext = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.color.grey};
  top: 13px;
  font-size: 11px;
  font-weight: 500;
`;

const PreviousPortTimestamp = styled(DateComponent)``;

const ShipRoute = ({ from, to, portArea, next, aisDestination, aisETA, previousPortTimeLabel, previousPortTime }) => {
  if (!from && !to) {
    return null;
  }

  return (
    <Container>
      <Popover
        overlayStyle={{ paddingLeft: '10px', paddingRight: '10px' }}
        content={() =>
          ShipRoutePopover(from, to, portArea, next, aisDestination, aisETA, previousPortTime, previousPortTimeLabel)
        }
        placement="right"
        zIndex={101}
      >
        <StyledShipRoute extraMargin={!!portArea} moreExtraMargin={!!previousPortTime}>
          <Port minWidth={!!previousPortTime}>
            {from}
            {previousPortTime && (
              <Subtext>
                <PreviousPortTimestamp format={TIME_FORMAT_NO_YEAR} date={previousPortTime} />
              </Subtext>
            )}
          </Port>
          <Icon type="arrow-right" />
          <Port>
            {to}
            {portArea && (
              <Subtext>
                <PortArea>{portArea.toLowerCase()}</PortArea>
              </Subtext>
            )}
          </Port>
          <Icon type="arrow-right" />
          <Port>{next}</Port>
        </StyledShipRoute>
      </Popover>
    </Container>
  );
};

export default ShipRoute;
