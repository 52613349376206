import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import InnerInvoiceModal from './InnerInvoiceModal';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  overflow-x: auto;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
  position: relative;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
  padding: 0 0 0 18px;
  min-width: 1000px;
`;

const TemplateContainer = styled.div`
  display: flex;
  background-color: #f0f0f0;
  margin-left: -18px;
  padding: 18px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

const RowContainer = styled.div`
  margin-bottom: 12px;
  width: 400px;
`;

const RowHeader = styled.div`
  overflow: auto;
  max-height: calc(100vh - 360px);
`;

const Required = styled.span`
  color: red;
`;

const TemplateModal = ({ template, closeModal, readOnly, duplicateCurrent }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { defaultCurrency } = useContext(NewInvoicingContext);

  const getCombinedUnit = units => {
    let unitString = units[0].name;
    if (units[1]) {
      unitString += ' / ' + units[1].name;
    }

    return unitString;
  };

  const [inputData, setInputData] = useState({
    name: template?.name,
    customer: template?.customer_number,
    customer_data: template?.customer,
    due_date_days: template?.due_date_days,
    currency: template?.currency_code || defaultCurrency || 'EUR',
    tax_class: template?.tax_class,
    description: template?.description,
    your_reference: template?.your_reference,
    rows:
      template?.template_rows?.map(r => {
        return {
          amounts: [
            {
              amount: 0,
              unit_name: null,
            },
          ],
          product_data: r.product,
          info: r.product?.description,
          code: r.product_code,
          name: r.product?.name,
          price: null,
          unit_amount: r.product?.pricing?.unit_price,
          combined_unit: getCombinedUnit(r.product?.pricing?.units),
          vat: r.product?.vat,
          timeframe: { from: null, to: null, at: null },
          modifications: r.modifiers.map(m => {
            return {
              code: m.modifier_code,
              name: m.modifier?.name,
              adjustment: m.modifier?.adjustment,
            };
          }),
        };
      }) || [],
  });

  const [sending, setSending] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const title = readOnly
    ? t('Template')
    : !template || duplicateCurrent
        ? t('Create new template')
        : t('Edit template');

  const dataIsValid = () => {
    return inputData.name && inputData.rows.length > 0;
  };

  const sendData = async () => {
    setSending(true);
    let result;
    try {
      result = await apiCall(template && !duplicateCurrent ? 'put' : 'post', 'invoicing/v2/template', {
        name: inputData.name,
        id: template && !duplicateCurrent ? template.id : null,
        customer_number: inputData.customer,
        tax_class: inputData.tax_class,
        due_date_days: inputData.due_date_days,
        currency_code: inputData.currency,
        description: inputData.description || '',
        your_reference: inputData.your_reference || '',
        template_rows: inputData.rows.map(r => {
          return {
            product_code: r.code,
            modifiers: r.modifications.map(m => {
              return {
                modifier_code: m.code,
              };
            }),
          };
        }),
      });
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      closeModal(true);
    } else {
      setSending(false);
    }
  };

  const updateName = value => {
    let newInputData = { ...inputData };
    newInputData.name = value.target.value;
    setInputData(newInputData);
  };

  return (
    <Modal title={title} open={true} width={1100} onCancel={() => closeModal(false)} footer={null} maskClosable={false}>
      <ModalInnerInput>
        <ScrollContainer>
          <TemplateContainer>
            <RowContainer>
              <RowHeader>
                {t('Template name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={inputData.name} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Name')}
                  value={inputData.name || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateName(e)}
                />
              )}
            </RowContainer>
          </TemplateContainer>
          <InnerInvoiceModal inputData={inputData} setInputData={setInputData} readOnly={readOnly} template={true} />
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons style={{ justifyContent: 'flex-end' }}>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons style={{ justifyContent: 'flex-end' }}>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>
    </Modal>
  );
};

export default TemplateModal;
