import React from 'react';
import styled from 'styled-components';

import LogoErrorImage from '../../images/polo-error.svg?react';

const Container = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 24px;
`;

const ErrorContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow_soft};
  border-radius: 3px;
  background: #ffffff;
`;

const SmallError = styled(ErrorContainer)`
  max-width: 200px;
  width: 100%;
  svg {
    max-width: 60px;
    max-height: 60px;
  }
`;

const MediumError = styled(ErrorContainer)`
  max-width: 500px;
  width: 100%;
  svg {
    max-width: 120px;
    max-height: 120px;
  }
`;

const LargeError = styled(ErrorContainer)`
  max-width: 700px;
  width: 100%;
  svg {
    max-width: 120px;
    max-height: 120px;
  }
`;

const TextContainer = styled.div`
  margin-top: 12px;
`;

const Redirect = styled.div`
  padding: 4px 12px;
  background-color: ${props => props.theme.color.secondary};
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  line-height: 1.1rem;
  height: 40px;
  margin-top: 36px;
  font-weight: 600;
  max-width: 300px;

  a {
    color: white;
    line-height: 30px;
  }
`;

export const ErrorPlaceHolder = (type, t) => {
  const text = t && typeof t === 'function' ? t('Oops! Something went wrong.') : 'Oops! Something went wrong.';
  const buttontext =
    t && typeof t === 'function' ? t('Click here to return to home page') : 'Click here to return to home page';

  if (type === 'large') {
    return (
      <Container>
        <LargeError>
          <LogoErrorImage />
          <TextContainer>{text}</TextContainer>
          <Redirect>
            <a href={window.location.origin}>{buttontext}</a>
          </Redirect>
        </LargeError>
      </Container>
    );
  } else if (type === 'medium') {
    return (
      <Container>
        <MediumError>
          <LogoErrorImage />
          <TextContainer>{text}</TextContainer>
          <Redirect>
            <a href={window.location.origin}>{buttontext}</a>
          </Redirect>
        </MediumError>
      </Container>
    );
  } else {
    return (
      <Container>
        <SmallError>
          <LogoErrorImage />
          <TextContainer>{text}</TextContainer>
        </SmallError>
      </Container>
    );
  }
};
