import { Polygon, Polyline, Circle, Tooltip, useMap, CircleMarker, useMapEvents } from 'react-leaflet';
import GeometryUtil from 'leaflet-geometryutil';
import React, { useContext, useRef, useState, useLayoutEffect, useEffect } from 'react';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import AssetTooltip from './AssetToolTip.js';
import 'leaflet-path-drag';
import 'leaflet-path-transform';
import { darken } from 'polished';

const AssetConstructor = ({ asset, zoom, rotate }) => {
  const {
    updateAssetCoodinates,
    openRightPanel,
    draggableAssets,
    updatePolygonPoint,
    deleteAssets,
    deleteAssetFunction,
    deletePolygonPoint,
    addPointToPolygon,
    assetData,
    rightPanelData,
    rightPanelOpen,
    geoJsonLayerData,
    filterList,
    setNewRotatedCoords,
  } = useContext(GeoAssetToolContext);
  const [constructedAsset, setConstructedAsset] = useState(asset);
  const [hidden, setHidden] = useState(filterList.includes(constructedAsset.type_id));
  const map = useMap();
  const type = constructedAsset.geo_data.type;
  const defaultAssetColor = '#D8D8D8';
  const defaultWeight = 2;
  const defaultRadius = 2;

  const itemRef = useRef();
  const intervalRef = useRef();

  const rotateRef = useRef();

  const assetRef = useRef(null);
  const closestPointToPolygon = useRef(null);
  const draggingPolygon = useRef(false);
  const polygonPointsVisible = useRef(draggableAssets || deleteAssets);
  const [updatePoints, setUpdatePoints] = useState(false);

  const zoomLevelThatDisablesEditing = 10;

  const interactiveLayer = () => {
    if (!rightPanelOpen) return true;
    const id = rightPanelData.geo_asset_id;
    return id === constructedAsset.geo_asset_id && (draggableAssets || deleteAssets);
  };

  useLayoutEffect(() => {
    polygonPointsVisible.current = draggableAssets || deleteAssets;
    setUpdatePoints(!updatePoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggableAssets, deleteAssets, polygonPointsVisible]);

  useLayoutEffect(() => {
    setHidden(filterList.includes(constructedAsset.type_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  useLayoutEffect(() => {
    for (let index = 0; index < assetData.length; index++) {
      const element = assetData[index];
      if (element.geo_asset_id === constructedAsset.geo_asset_id) {
        setConstructedAsset(element);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData]);

  const onRotate = e => {
    updateAssetCoodinates(e.layer.options.data.geo_asset_id, e.layer.getLatLngs());
    const coords = e.layer.getLatLngs().flat();
    let transformedCoords = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      transformedCoords.push([element.lat, element.lng]);
    }
    setNewRotatedCoords(transformedCoords);
  };

  const onScale = e => {
    updateAssetCoodinates(e.layer.options.data.geo_asset_id, e.layer.getLatLngs());
    const coords = e.layer.getLatLngs().flat();
    let transformedCoords = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      transformedCoords.push([element.lat, element.lng]);
    }
    setNewRotatedCoords(transformedCoords);
  };

  useEffect(() => {
    if (rotate && !rotateRef.current && itemRef.current?.transform) {
      itemRef.current.transform.enable({ rotation: true, scaling: true });
      itemRef.current.on('rotateend', onRotate);
      itemRef.current.on('scaleend', onScale);
    } else if (!rotate && rotateRef.current) {
      itemRef.current?.transform?.disable();
      itemRef.current.off('rotateend', onRotate);
      itemRef.current.off('scaleend', onScale);
    }

    rotateRef.current = rotate;
  }, [rotate]); // eslint-disable-line react-hooks/exhaustive-deps

  useMapEvents({
    zoomend: () => {
      if (rotate) {
        const northEast = map.latLngToLayerPoint(itemRef.current.getBounds()._northEast);
        const southWest = map.latLngToLayerPoint(itemRef.current.getBounds()._southWest);
        if (Math.abs(northEast.x - southWest.x) < 10 || Math.abs(northEast.y - southWest.y) < 10) {
          itemRef.current?.transform?.disable();
        } else {
          itemRef.current.transform.enable({ rotation: true, scaling: true });
        }
      }
    },
  });

  useEffect(() => {
    const id = rightPanelData.geo_asset_id;
    if (rightPanelOpen && id === constructedAsset.geo_asset_id) {
      const layerKeys = Object.keys(map._layers);
      const layers = map._layers;
      for (let index = 0; index < layerKeys.length; index++) {
        const element = layers[layerKeys[index]];
        if (element?.options?.data?.geo_asset_id === constructedAsset.geo_asset_id) {
          element.bringToFront();
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData, rightPanelOpen, draggableAssets, deleteAssets]);

  useEffect(() => {
    const selected = rightPanelData.id === constructedAsset.id;

    const origColor = constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor;
    let darkerColor = darken(-0.05, origColor);

    if (origColor === darkerColor) {
      darkerColor = darken(0.05, origColor);
    }

    if (selected) {
      intervalRef.current = setInterval(() => {
        if (itemRef.current) {
          if (itemRef.current.options.color && itemRef.current.options.color === origColor) {
            itemRef.current.setStyle({
              color: darkerColor,
              weight: (constructedAsset.style.weight ? constructedAsset.style.weight - 0.5 : defaultWeight - 0.5) + 1,
              opacity: constructedAsset.style.opacity < 0.2 ? 0.5 : 1,
            });
          } else {
            itemRef.current.setStyle({
              color: origColor,
              weight: constructedAsset.style.weight ? constructedAsset.style.weight - 0.5 : defaultWeight - 0.5,
              opacity: constructedAsset.style.opacity,
            });
          }
        }
      }, 1500);
    } else {
      clearInterval(intervalRef.current);
      if (itemRef.current) {
        itemRef.current.setStyle({
          weight: constructedAsset.style.weight ? constructedAsset.style.weight - 0.5 : defaultWeight,
          color: constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor,
          opacity: constructedAsset.style.opacity,
        });
      }
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [constructedAsset, rightPanelData.id]);

  useEffect(() => {
    const refToItem = itemRef.current;
    return () => {
      refToItem?.transform?.disable();
    };
  }, []);

  const CreatePolygonPoints = constructedAsset.geo_data.coordinates.map((coords, index) => {
    return (
      <CircleMarker
        key={index + '_' + constructedAsset.geo_asset_id}
        draggable={true}
        center={coords}
        weight={constructedAsset.style.weight ? constructedAsset.style.weight - 0.5 : defaultWeight - 0.5}
        color={constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor}
        opacity={constructedAsset.style.opacity}
        fillColor={constructedAsset.style.fillColor}
        fillOpacity={constructedAsset.style.fillOpacity}
        data={constructedAsset.geo_asset_id}
        area={constructedAsset.geo_data_area}
        index={index}
        eventHandlers={{
          mouseover: e => {
            if (draggableAssets && zoom >= zoomLevelThatDisablesEditing && interactiveLayer())
              e.target.dragging.enable();
            else e.target.dragging.disable();
          },
          click: e => {
            if (deleteAssets && interactiveLayer()) {
              deletePolygonPoint(e.target.options.index, e.target.options.data);
            }
          },
          dragend: e => {
            updatePolygonPoint(index, e.target.getLatLng(), e.target.options.data);
          },
        }}
      ></CircleMarker>
    );
  });

  const AssetBody =
    type === 'Point' ? (
      <Circle
        ref={itemRef}
        draggable={true}
        center={constructedAsset.geo_data.coordinates}
        radius={constructedAsset.style.radius ? constructedAsset.style.radius : defaultRadius}
        color={constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor}
        weight={constructedAsset.style.weight ? constructedAsset.style.weight : defaultWeight}
        opacity={constructedAsset.style.opacity}
        fillColor={constructedAsset.style.fillColor}
        fillOpacity={constructedAsset.style.fillOpacity}
        stroke={constructedAsset.style.stroke !== undefined ? constructedAsset.style.stroke : true}
        data={constructedAsset}
        area={constructedAsset.geo_data_area}
        eventHandlers={{
          drag: e => {
            if (!assetRef.current) {
              e.target.closeTooltip();
              assetRef.current = e.target.options.data.geo_asset_id;
            }
          },
          dragend: e => {
            updateAssetCoodinates(assetRef.current, e.target.getLatLng());
            assetRef.current = null;
          },
          click: e => {
            if (deleteAssets && interactiveLayer()) {
              deleteAssetFunction(e.target.options.data);
            } else {
              map.setView(e.target.getLatLng(), zoom);
              openRightPanel(e.target.options.data);
            }
          },
          mouseover: e => {
            if (draggableAssets && zoom >= zoomLevelThatDisablesEditing && interactiveLayer())
              e.target.dragging.enable();
            else e.target.dragging.disable();
          },
        }}
      >
        <Tooltip>
          <AssetTooltip asset={constructedAsset} />
        </Tooltip>
      </Circle>
    ) : type === 'Polygon' && geoJsonLayerData?.geo_asset_id !== asset?.geo_asset_id ? (
      <>
        <Polygon
          ref={itemRef}
          key={constructedAsset.geo_asset_id}
          className={'pointer'}
          data={constructedAsset}
          area={constructedAsset.geo_data_area}
          positions={constructedAsset.geo_data.coordinates}
          draggable={true}
          color={constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor}
          weight={constructedAsset.style.weight ? constructedAsset.style.weight : defaultWeight}
          opacity={constructedAsset.style.opacity}
          fillColor={constructedAsset.style.fillColor}
          fillOpacity={constructedAsset.style.fillOpacity}
          stroke={constructedAsset.style.stroke !== undefined ? constructedAsset.style.stroke : true}
          transform={true}
          eventHandlers={{
            drag: e => {
              if (!assetRef.current) {
                e.target.closeTooltip();
                assetRef.current = e.target.options.data.geo_asset_id;
                polygonPointsVisible.current = false;
                setUpdatePoints(!updatePoints);
              }
            },
            dragend: e => {
              if (assetRef.current) {
                updateAssetCoodinates(assetRef.current, e.target.getLatLngs(), false, e.target.getBounds().getCenter());
                assetRef.current = null;
                polygonPointsVisible.current = true;
                setUpdatePoints(!updatePoints);
              }
            },
            click: e => {
              if (deleteAssets && interactiveLayer()) {
                deleteAssetFunction(e.target.options.data);
              } else if (closestPointToPolygon.current && draggableAssets) {
                const polygonPoints = e.target.getLatLngs().flat();
                for (let index = 0; index < polygonPoints.length; index++) {
                  const pointA = polygonPoints[index];
                  const pointB = index + 1 === polygonPoints.length ? polygonPoints[0] : polygonPoints[index + 1];
                  const isBetween = GeometryUtil.belongsSegment(closestPointToPolygon.current, pointA, pointB);
                  if (isBetween) {
                    addPointToPolygon(closestPointToPolygon.current, index + 1, e.target.options.data.geo_asset_id);
                    closestPointToPolygon.current = null;
                    setUpdatePoints(!updatePoints);
                    break;
                  }
                }
              } else {
                map.setView(e.latlng, zoom);
                openRightPanel(e.target.options.data);
              }
            },
            mouseover: e => {
              if (draggableAssets && zoom >= zoomLevelThatDisablesEditing && interactiveLayer())
                e.target.dragging.enable();
              else e.target.dragging.disable();
              closestPointToPolygon.current = null;
            },
            mousemove: e => {
              if (
                draggableAssets &&
                !draggingPolygon.current &&
                zoom >= zoomLevelThatDisablesEditing &&
                interactiveLayer()
              ) {
                const closestPoint = GeometryUtil.closest(map, e.target, e.latlng);
                if (closestPoint.distance < 3) {
                  e.target.dragging.disable();
                  closestPointToPolygon.current = closestPoint;
                } else {
                  e.target.dragging.enable();
                  closestPointToPolygon.current = null;
                }
              }
            },
          }}
        >
          <Tooltip>
            <AssetTooltip asset={constructedAsset} />
          </Tooltip>
        </Polygon>

        {(draggableAssets || deleteAssets) &&
        zoom >= zoomLevelThatDisablesEditing &&
        polygonPointsVisible.current &&
        interactiveLayer()
          ? CreatePolygonPoints
          : null}
      </>
    ) : geoJsonLayerData?.geo_asset_id !== asset?.geo_asset_id ? (
      <>
        <Polyline
          ref={itemRef}
          key={constructedAsset.geo_asset_id}
          data={constructedAsset}
          area={constructedAsset.geo_data_area}
          positions={constructedAsset.geo_data.coordinates}
          draggable={true}
          color={constructedAsset.style.color ? constructedAsset.style.color : defaultAssetColor}
          weight={constructedAsset.style.weight ? constructedAsset.style.weight : defaultWeight}
          opacity={constructedAsset.style.opacity}
          stroke={constructedAsset.style.stroke !== undefined ? constructedAsset.style.stroke : true}
          transform={true}
          eventHandlers={{
            drag: e => {
              if (!assetRef.current) {
                e.target.closeTooltip();
                assetRef.current = e.target.options.data.geo_asset_id;
                polygonPointsVisible.current = false;
                setUpdatePoints(!updatePoints);
              }
            },
            dragend: e => {
              if (assetRef.current) {
                updateAssetCoodinates(assetRef.current, e.target.getLatLngs());
                assetRef.current = null;
                polygonPointsVisible.current = true;
                setUpdatePoints(!updatePoints);
              }
            },
            click: e => {
              if (deleteAssets && interactiveLayer()) {
                deleteAssetFunction(e.target.options.data);
              } else if (closestPointToPolygon.current && draggableAssets) {
                const polygonPoints = e.target.getLatLngs().flat();
                for (let index = 0; index < polygonPoints.length; index++) {
                  const pointA = polygonPoints[index];
                  const pointB = index + 1 === polygonPoints.length ? polygonPoints[0] : polygonPoints[index + 1];
                  const isBetween = GeometryUtil.belongsSegment(closestPointToPolygon.current, pointA, pointB);
                  if (isBetween) {
                    addPointToPolygon(closestPointToPolygon.current, index + 1, e.target.options.data.geo_asset_id);
                    closestPointToPolygon.current = null;
                    break;
                  }
                }
              } else {
                map.setView(e.latlng, zoom);
                openRightPanel(e.target.options.data);
              }
            },
            mouseover: e => {
              if (draggableAssets && zoom >= zoomLevelThatDisablesEditing && interactiveLayer())
                e.target.dragging.enable();
              else e.target.dragging.disable();
              closestPointToPolygon.current = null;
            },
            mousemove: e => {
              if (draggableAssets && !draggingPolygon.current && interactiveLayer()) {
                const closestPoint = GeometryUtil.closest(map, e.target, e.latlng);
                if (closestPoint.distance < 3) {
                  closestPointToPolygon.current = closestPoint;
                } else {
                  closestPointToPolygon.current = null;
                }
              }
            },
          }}
        >
          <Tooltip>
            <AssetTooltip asset={constructedAsset} />
          </Tooltip>
        </Polyline>
        {(draggableAssets || deleteAssets) &&
        zoom >= zoomLevelThatDisablesEditing &&
        polygonPointsVisible.current &&
        interactiveLayer()
          ? CreatePolygonPoints
          : null}
      </>
    ) : (
      <> </>
    );
  return hidden ? null : AssetBody;
};
export default AssetConstructor;
