import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import App from 'antd/es/app';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';
import NewInvoiceModal from './NewInvoiceModal';
import useApi from '../../hooks/useApi';
import TemplateChoiceModal from './TemplateChoiceModal';
import PortcallInvoiceList from './PortcallInvoiceList';
import Icon from '../ui/Icon';
import VesselInvoice from './VesselInvoice';
import { darken } from 'polished';
import CreditInvoiceModal from './CreditInvoiceModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceButton = styled.div`
  display: inline-flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.secondary};
  color: white;
  border-radius: 3px;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.color.secondary)};
  }

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -2px;
    width: 18px;
    height: 18px;
  }
`;

const InvoicesPerPortcallList = () => {
  const { namespace, useUserSocket, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [portcallList, setPortcallList] = useState([]);
  const [invoices, setInvoices] = useState({});

  const [apiCallPending, setApiCallPending] = useState(false);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(undefined);

  const [readOnlyAndSend, setReadOnlyAndSend] = useState(false);
  const [sendEditModeOn, setSendEditModeOn] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [duplicateCurrent, setDuplicateCurrent] = useState(false);

  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(undefined);

  const [vesselInvoiceId, setVesselInvoiceId] = useState(null);

  const [creditInvoiceModalOpen, setCreditInvoiceModalOpen] = useState(false);
  const [creditInvoice, setCreditInvoice] = useState(undefined);
  const [creditInvoiceData, setCreditInvoiceData] = useState(undefined);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'ata DESC',
    search: 'have:ata',
  };

  const getParamsInRightForm = portcalls => {
    const parameters = {
      limit: 300,
      offset: 0,
      sort: 'invoice_date DESC',
      search: '',
    };

    let idList = portcalls.map(portcall => {
      return {
        type: 'port_call_id',
        operator: 'is',
        value: portcall.id + '',
      };
    });
    let correctParams = {
      query: {
        text: parameters.search,
        conditions: {
          and: [
            {
              type: 'port_call_data_exists',
              operator: 'exists',
              value: true,
            },
            {
              or: idList,
            },
          ],
        },
      },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'invoice_date',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  };

  const getInvoices = useCallback(
    async data => {
      if (mounted.current) {
        if (data?.data) {
          setApiCallPending(true);
          setPortcallList(data.data);
          try {
            const result = await apiCall('post', 'invoicing/v2/invoice/search', getParamsInRightForm(data.data));
            if (result?.status === 200 && result?.data?.results?.invoices) {
              let invoicesPerPortcall = {};
              result.data.results.invoices.forEach(invoice => {
                if (invoice.port_call_data?.port_call_data) {
                  if (!invoicesPerPortcall[invoice.port_call_data.port_call_data.id]) {
                    invoicesPerPortcall[invoice.port_call_data.port_call_data.id] = [invoice];
                  } else {
                    invoicesPerPortcall[invoice.port_call_data.port_call_data.id].push(invoice);
                  }
                }
              });
              setInvoices(invoicesPerPortcall);
            }
          } catch (e) {
            setApiCallPending(false);
            throw e;
          }
          setApiCallPending(false);
        }
      }
    },
    [apiCall]
  );

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'port-calls', newParams, getInvoices);

  useUserSocket('invoicing-invoices-changed', () => getInvoices({ data: portcallList }));

  let portCalls = error ? [] : data ? data.data : [];

  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    getInvoices({ data: portcallList });
  }, [portcallList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mounted.current) {
      fetchData(false, newParams);
    }
  }, [newParams, fetchData]);

  const columns = [
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      render: record => <div style={{ fontWeight: 600, textTransform: 'uppercase' }}>{record}</div>,
    },
    {
      title: t('ATA'),
      dataIndex: 'ata',
      key: 'ata',
      sortableKey: 'ata',
      render: record => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('ATD'),
      dataIndex: 'atd',
      key: 'atd',
      sortableKey: 'atd',
      render: record => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Port area'),
      dataIndex: 'port_area_name',
      key: 'port_area_name',
      render: record => {
        if (record) {
          return <div style={{ textTransform: 'capitalize' }}>{record.toLowerCase()}</div>;
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Sent / Total invoices'),
      dataIndex: 'id',
      key: 'id',
      render: record => {
        if (Object.keys(invoices).length && invoices[record]) {
          const sent = invoices[record].filter(i => i.is_sent);
          return (
            <div>
              {sent.length} / {invoices[record].length}
            </div>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 120,
      render: (record, item) => {
        return (
          <InvoiceButton onClick={() => setVesselInvoiceId(item.id)}>
            <Icon type="plus" />
            {t('Invoice')}
          </InvoiceButton>
        );
      },
    },
  ];

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/invoice/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, newParams);
  };

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          onClick: () => {
            setModalTemplate(undefined);
            setTemplateModalOpen(true);
          },
          text: t('Create new invoice'),
          icon: 'plus',
        },
      ]
    : [];

  const createEmpty = () => {
    setDuplicateCurrent(false);
    setModalInvoice(undefined);
    setInvoiceModalOpen(true);
    setModalTemplate(undefined);
    setTemplateModalOpen(false);
  };

  const chooseTemplate = temp => {
    setDuplicateCurrent(true);
    setModalInvoice({ ...temp, invoice_rows: temp.template_rows, from_template: true });
    setInvoiceModalOpen(true);
    setModalTemplate(undefined);
    setTemplateModalOpen(false);
  };

  const closeInvoiceModal = reload => {
    setModalInvoice(undefined);
    setInvoiceModalOpen(false);
    setDuplicateCurrent(false);
    setReadOnlyAndSend(false);
    setReadOnly(false);
    setSendEditModeOn(false);

    if (reload) {
      fetchData(false, newParams);
    }
  };

  const closeTemplateModal = () => {
    setModalTemplate(undefined);
    setTemplateModalOpen(false);
  };

  const closeCreditModal = () => {
    setCreditInvoice(undefined);
    setCreditInvoiceModalOpen(false);
    setCreditInvoiceData(undefined);
  };

  const setInvoiceData = invoice => {
    setModalInvoice(invoice);
    setInvoiceModalOpen(true);
    closeCreditModal();
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={portCalls}
        spinning={loading}
        apiCallPending={!loading && apiCallPending}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        hideSearch
        additionalButtons={additionalButtons}
        noUrlUpdate={true}
        expandedRowRender={record => (
          <PortcallInvoiceList
            invoices={invoices[record.id]}
            handleDelete={handleDelete}
            setInvoiceModalOpen={setInvoiceModalOpen}
            setModalInvoice={setModalInvoice}
            setReadOnlyAndSend={setReadOnlyAndSend}
            setReadOnly={setReadOnly}
            setDuplicateCurrent={setDuplicateCurrent}
            setCreditInvoice={setCreditInvoice}
            setCreditInvoiceModalOpen={setCreditInvoiceModalOpen}
            setCreditInvoiceData={setCreditInvoiceData}
          />
        )}
      />

      {invoiceModalOpen && (
        <NewInvoiceModal
          invoice={modalInvoice}
          closeModal={closeInvoiceModal}
          readOnly={sendEditModeOn ? false : readOnlyAndSend || readOnly}
          sendAllowed={sendEditModeOn ? false : readOnlyAndSend}
          duplicateCurrent={duplicateCurrent}
          setSendEditModeOn={setSendEditModeOn}
          sendEditModeOn={sendEditModeOn}
        />
      )}

      {templateModalOpen && (
        <TemplateChoiceModal
          closeModal={closeTemplateModal}
          vessel={modalTemplate}
          chooseTemplate={chooseTemplate}
          createEmpty={createEmpty}
        />
      )}

      {vesselInvoiceId && (
        <VesselInvoice
          id={vesselInvoiceId}
          closeModal={() => setVesselInvoiceId(null)}
          setInvoiceData={template => {
            setVesselInvoiceId(null);
            setDuplicateCurrent(true);
            setModalInvoice(template);
            setInvoiceModalOpen(true);
            setModalTemplate(undefined);
            setTemplateModalOpen(false);
          }}
        />
      )}

      {creditInvoiceModalOpen && (
        <CreditInvoiceModal
          closeModal={closeCreditModal}
          id={creditInvoice?.id}
          setInvoiceData={setInvoiceData}
          creditInvoiceData={creditInvoiceData}
        />
      )}
    </Container>
  );
};

export default InvoicesPerPortcallList;
