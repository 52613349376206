import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import ProductList from '../../components/invoicing/ProductList';
import PriceModificationsList from '../../components/invoicing/PriceModificationsList';

const AlteredPage = styled(Page)`
  padding-top: 14px;
`;

const ProductsView = props => {
  const { page } = props.match.params;
  const { namespace } = useContext(UserContext);
  const { invoicePageVisited, setInvoicePageVisited } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Products | Port Activity App';

    if (!invoicePageVisited) {
      setInvoicePageVisited(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();

  const onTabChange = e => {
    history.push('/invoicing-products/' + e);
  };

  const tabItems = [
    {
      label: t('Product list'),
      key: 'products',
      children: <ProductList />,
    },
    {
      label: t('Price modifications'),
      key: 'modifications',
      children: <PriceModificationsList />,
    },
  ];

  return (
    <Layout pagename={t('Products')}>
      <AlteredPage fullWidth>
        <Tabs defaultActiveKey={page} animated={false} onTabClick={onTabChange} items={tabItems} />
      </AlteredPage>
    </Layout>
  );
};

export default ProductsView;
