import React, { useContext } from 'react';
import styled from 'styled-components';

import { AlertContext } from '../../context/AlertContext';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.grey_lighter};
  @media print {
    background: white;
    padding: 0;
    min-height: 0;
  }
`;

const Content = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.style.border_radius};
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.sizing.gap_big};
  padding: ${props => props.smallPadding && '1rem'};
  padding-top: ${props => props.smallTopPadding && '1rem'};
  padding-top: ${props => props.smallPadding && '0rem'};
  padding-right: ${props => props.smallPadding && '0rem'};
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  min-height: calc(100vh - 112px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  max-width: ${props => (props.fullWidth ? '100%' : '80vw')};
  @media print {
    padding: 0;
    margin: 0;
    box-shadow: none;
    max-width: 100%;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    min-height: calc(100vh - 98px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const Page = ({ children, smallTopPadding, smallPadding, ...props }) => {
  const { alertsHeight } = useContext(AlertContext);

  return (
    <Wrapper id="page-wrapper">
      <Content {...props} smallTopPadding={smallTopPadding} smallPadding={smallPadding} alertsHeight={alertsHeight}>
        {children}
      </Content>
    </Wrapper>
  );
};

export default Page;
