import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import DebounceAutocomplete from '../../components/ui/DebounceAutocomplete';
import DateComponent from '../ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const GROUP_USERS_BASE_URL = 'group-users';

const GroupUsers = ({ group }) => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [groupUsersData, setGroupUsersData] = useState(null);
  const [groupUsersError, setGroupUsersError] = useState(null);
  const [groupUsersLoading, setGroupUsersLoading] = useState(null);

  const [searchedName, setSearchedName] = useState('');

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initGroupUser = {
    uuid: null,
    first_name: '',
    last_name: '',
    email: '',
  };

  const [groupUser, setGroupUser] = useState(initGroupUser);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'email',
    search: '',
  };

  const groupsChanged = useCallback(data => {
    if (data?.users) {
      setGroupUsersData(data.users);
    } else {
      setGroupUsersData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: groupUsersFetchLoading, error: groupUsersFetchError, fetchData: groupUsersFetchData } = useApi(
    'get',
    `${GROUP_USERS_BASE_URL}/by-group/${group?.id}`,
    null,
    groupsChanged,
    true
  );

  useEffect(() => {
    setGroupUsersError(groupUsersFetchError);
  }, [groupUsersFetchError]);

  useEffect(() => {
    setGroupUsersLoading(groupUsersFetchLoading);
  }, [groupUsersFetchLoading]);

  if (groupUsersError) {
    message.error(groupUsersError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupUsers = groupUsersLoading || !groupUsersData ? [] : groupUsersData;

  const { start, total } =
    groupUsersError || !groupUsersData
      ? { start: 0, total: 0 }
      : { start: groupUsersData.length, total: groupUsersData.length };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${GROUP_USERS_BASE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await groupUsersFetchData();
  };

  const handleSave = async () => {
    const { uuid } = groupUser;
    const result = await apiCall('put', `${GROUP_USERS_BASE_URL}`, {
      group_id: group?.id,
      user_id: uuid,
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setGroupUser(initGroupUser);
      history.replace(location.pathname);
      groupUsersFetchData();
    }
    showActions(false);
    setSearchedName(null);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setGroupUser(initGroupUser);
    setSearchedName(null);
  };

  const handleAddNewChange = (value, obj) => {
    setSearchedName(value);
    if (obj?.data) {
      setGroupUser({
        uuid: obj.data.uuid,
        first_name: obj.data.first_name,
        last_name: obj.data.last_name,
        email: obj.data.email,
      });
    } else {
      setGroupUser(null);
    }
  };

  const fetchUsers = async value => {
    const params = {
      limit: 20,
      offset: 0,
      sort: 'last_name',
      search: value,
    };

    const result = await apiCall('get', 'users', params);
    if (result?.status === 200) {
      return result.data?.['data']?.map(d => {
        return {
          value: `${d.first_name} ${d.last_name} (${d.email})`,
          label: `${d.first_name} ${d.last_name} (${d.email})`,
          data: d,
        };
      });
    }

    return [];
  };

  const fields = [];
  const { handleSubmit } = useForm(fields, handleSave, {});

  useEffect(() => {
    let isValid = groupUser?.uuid?.length > 0;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, groupUser]);

  const columns = [
    /*
    {
      title: t('UUID'),
      dataIndex: 'uuid',
      key: 'uuid',
    },*/
    {
      title: t('First name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sortableKey: 'first_name',
    },
    {
      title: t('Last name'),
      dataIndex: 'last_name',
      key: 'last_name',
      sortableKey: 'last_name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sortableKey: 'email',
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.created_by_name && record?.created_by_email) {
          created_by = `${record.created_by_name} (${record.created_by_email})`;
        } else if (record?.created_by_name) {
          created_by = record.created_by_name;
        } else if (record?.created_by_email) {
          created_by = record.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'modified_at',
      sortableKey: 'modified_at',
      render: record => {
        let modified_by = record?.modified_by || '';
        if (record?.modified_by_name && record?.modified_by_email) {
          modified_by = `${record.modified_by_name} (${record.modified_by_email})`;
        } else if (record?.modified_by_name) {
          modified_by = record.modified_by_name;
        } else if (record?.modified_by_email) {
          modified_by = record.modified_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.modified_at} />
              </Content2>
            )}
            <Content2>{modified_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <Popconfirm
          title={t('Delete user {{email}} from group {{name}}?', { email: record.email, name: group?.name })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: groupUsersLoading,
      text: t('Add user to group'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add user to group')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px' }}
        >
          <Form onSubmit={handleSubmit}>
            <InputContainer>
              <InputLabel>{t('User')}</InputLabel>
              <DebounceAutocomplete
                placeholder={t('Search for user')}
                fetchOptions={fetchUsers}
                onChange={(value, data) => handleAddNewChange(value, data)}
                onSelect={(value, data) => handleAddNewChange(value, data)}
                value={searchedName}
                style={{ width: '100%' }}
                allowClear={true}
                onClear={() => handleAddNewChange('', null)}
              />
            </InputContainer>
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add user to group')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={groupUsers}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={groupUsersLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        hideSearch={true}
        additionalButtons={additionalButtons}
      />
    </>
  );
};

export default GroupUsers;
