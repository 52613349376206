import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import AutoComplete from 'antd/es/auto-complete';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';

const Container = styled.div`
  margin-bottom: 6px;

  .ant-picker-input > input {
    font-size: 13px !important;
    padding-top: 1px;
  }

  .ant-select-selector {
    height: 28px !important;
  }

  .ant-select-selection-item {
    line-height: 26px !important;
  }

  .ant-select-selection-search {
    input {
      line-height: 26px !important;
      height: 26px !important;
    }
  }
`;

const Header = styled.div`
  font-weight: 600;
  margin-bottom: 12px;
  margin-left: 2px;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(ButtonLight)`
  margin-bottom: 0;
  padding: 0;
  height: 26px;
  line-height: 22px;
  min-width: 80px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;

  .ant-select-selection-placeholder {
    line-height: 26px !important;
  }
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  font-size: 13px;
`;

const Required = styled.span`
  color: red;
`;

const AddEditPortcall = ({ add, portcall, portList, setAddModalOpen, setEditModalOpen, setRemoveModalOpen, imo }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [agentList, setAgentList] = useState([]);
  const [shippingCompanyList, setShippingCompanyList] = useState([]);

  const [inputData, setInputData] = useState(
    add
      ? {
          locode: undefined,
          portarea: undefined,
          eta: undefined,
          etd: undefined,
          shipping_company: undefined,
          agent: undefined,
        }
      : {
          locode: portcall.locode,
          portarea: portcall.portarea ? portcall.portarea[0] : undefined,
          eta: portcall.eta,
          etd: portcall.etd,
          shipping_company: portcall.shipping_company ? portcall.shipping_company[0] : undefined,
          agent: portcall.agent ? portcall.agent[0] : undefined,
        }
  );

  useEffect(() => {
    if (add) {
      setInputData({
        locode: undefined,
        portarea: undefined,
        eta: undefined,
        etd: undefined,
        shipping_company: undefined,
        agent: undefined,
      });
    }
  }, [add]);

  useEffect(() => {
    const getDataBasedOnLocode = async () => {
      const listOfAgents = await apiCall('post', 'fleet/agents/', {
        order_by: [
          {
            field: 'agents',
            order: 'asc',
          },
        ],
      });

      if (listOfAgents?.data?.results?.agents) {
        setAgentList(listOfAgents?.data?.results?.agents);
      }

      const listOfShippingCompanies = await apiCall('post', 'fleet/shipping-companies/', {
        order_by: [
          {
            field: 'shipping_companies',
            order: 'asc',
          },
        ],
      });

      if (listOfShippingCompanies?.data?.results?.shipping_companies) {
        setShippingCompanyList(listOfShippingCompanies?.data?.results?.shipping_companies);
      }
    };

    getDataBasedOnLocode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dataIsValid = () => {
    return inputData.locode && inputData.eta && inputData.etd;
  };

  const updatePort = value => {
    setInputData({ ...inputData, locode: value, portarea: undefined, agent: undefined, shipping_company: undefined });
  };

  const updatePortarea = value => {
    setInputData({ ...inputData, portarea: value });
  };

  const updateEta = value => {
    setInputData({ ...inputData, eta: value });
  };

  const updateEtd = value => {
    setInputData({ ...inputData, etd: value });
  };

  const updateShippingCompany = value => {
    setInputData({ ...inputData, shipping_company: value });
  };

  const updateAgent = value => {
    setInputData({ ...inputData, agent: value });
  };

  const sendData = async () => {
    if (add) {
      apiCall('post', 'fleet/manual-portcall', {
        portcall: {
          vessel_imo: imo,
          eta: dayjs(inputData.eta)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          etd: dayjs(inputData.etd)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          locode: inputData.locode,
          portarea: inputData.portarea,
          agent: inputData.agent,
          shipping_company: inputData.shipping_company,
        },
      });
    } else {
      apiCall('post', 'fleet/manual-portcall', {
        portcall: {
          vessel_imo: imo,
          eta: dayjs(inputData.eta)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          etd: dayjs(inputData.etd)
            .second(0)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          locode: inputData.locode,
          portarea: inputData.portarea,
          agent: inputData.agent,
          shipping_company: inputData.shipping_company,
          port_call_id: portcall.port_call_id,
        },
      });
    }

    closeContainer();
  };

  const closeContainer = () => {
    setAddModalOpen(false);
    if (setEditModalOpen) {
      setEditModalOpen(false);
    }
    if (setRemoveModalOpen) {
      setRemoveModalOpen(false);
    }
  };

  const portAreaList =
    inputData.locode && portList
      ? portList
        .find(port => port.data.locodes[0].locode === inputData.locode)
        ?.data.locodes[0].portareas?.filter(area => !!area.portAreaName) || []
      : [];

  return (
    <Container id="add-edit-portcall">
      <Header>{add ? t('Add new port call') : t('Edit port call')}</Header>
      <RowContainer>
        <RowHeader>
          {t('Port')}
          <Required>*</Required>
        </RowHeader>
        <Select
          disabled={portList.length === 0}
          showSearch
          placeholder={t('Port name')}
          optionFilterProp="label"
          onChange={value => updatePort(value)}
          style={{ width: '100%', marginBottom: '8px', fontSize: '13px' }}
          options={portList.map(p => {
            return { label: p.port_name + ', ' + p.country, value: p.data.locodes[0].locode };
          })}
          value={inputData.locode || null}
          size="medium"
        />
      </RowContainer>
      <RowContainer>
        <RowHeader>{t('Port area')}</RowHeader>
        <Select
          disabled={
            !inputData.locode ||
            portList.length === 0 ||
            (!inputData.portarea && inputData.locode && portAreaList.length === 0)
          }
          listHeight={80}
          showSearch
          placeholder={t('Port area')}
          optionFilterProp="value"
          onChange={value => updatePortarea(value)}
          style={{ width: '100%', marginBottom: '8px', fontSize: '13px' }}
          options={portAreaList.map(p => {
            return { label: p.portAreaName, value: p.portAreaName };
          })}
          value={
            (!inputData.portarea && inputData.locode && portAreaList.length === 0
              ? t('Not available')
              : inputData.portarea) || null
          }
          size="medium"
        />
      </RowContainer>
      <RowContainer>
        <RowHeader>
          {t('ETA')}
          <Required>*</Required>
        </RowHeader>
        <DatePicker
          format="DD.MM.YYYY HH:mm"
          showTime={{ format: 'HH:mm' }}
          value={inputData.eta ? dayjs(inputData.eta) : null}
          style={{ width: '100%', marginBottom: '8px', height: '28px' }}
          onChange={value => updateEta(value)}
          placeholder={t('Estimated time of arrival')}
          size="medium"
        />
      </RowContainer>
      <RowContainer>
        <RowHeader>
          {t('ETD')}
          <Required>*</Required>
        </RowHeader>
        <DatePicker
          format="DD.MM.YYYY HH:mm"
          showTime={{ format: 'HH:mm' }}
          value={inputData.etd ? dayjs(inputData.etd) : null}
          style={{ width: '100%', marginBottom: '8px', height: '28px' }}
          onChange={value => updateEtd(value)}
          placeholder={t('Estimated time of departure')}
          size="medium"
        />
      </RowContainer>
      <RowContainer>
        <RowHeader>{t('Agent')}</RowHeader>
        <AutoComplete
          listHeight={80}
          showSearch
          placeholder={t('Agent name')}
          optionFilterProp="value"
          onChange={value => updateAgent(value)}
          style={{ width: '100%', marginBottom: '8px', fontSize: '13px' }}
          options={agentList.map(p => {
            return { label: p, value: p };
          })}
          value={inputData.agent || null}
          size="medium"
        />
      </RowContainer>
      <RowContainer>
        <RowHeader>{t('Shipping company')}</RowHeader>
        <AutoComplete
          listHeight={80}
          showSearch
          placeholder={t('Shipping company name')}
          optionFilterProp="value"
          onChange={value => updateShippingCompany(value)}
          style={{ width: '100%', marginBottom: '18px', fontSize: '13px' }}
          options={shippingCompanyList.map(p => {
            return { label: p, value: p };
          })}
          value={inputData.shipping_company || null}
          size="medium"
        />
      </RowContainer>
      <Row>
        <StyledButton cancel style={{ marginRight: '6px' }} onClick={closeContainer}>
          {t('Close')}
        </StyledButton>
        <StyledButton disabled={!dataIsValid()} onClick={sendData}>
          {add ? t('Add') : t('Save')}
        </StyledButton>
      </Row>
    </Container>
  );
};

export default AddEditPortcall;
