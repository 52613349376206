import React, { createContext, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { UserContext } from './UserContext';
import useApi from '../hooks/useApi';

import notification from 'antd/es/notification';
import App from 'antd/es/app';

import Icon from '../components/ui/Icon';

import NotificationTitle from '../components/notifications/NotificationTitle';
import NotificationSentBy from '../components/notifications/NotificationSentBy';
import ServiceOrderNotificationTitle from '../components/notifications/ServiceOrderNotificationTitle';

import { TIME_FORMAT } from '../utils/constants';

dayjs.extend(utc);

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationReplyOpen, setNotificationReplyOpen] = useState(false);
  const [selectedImo, setSelectedImo] = useState(null);
  const { apiCall, namespace, portName, user, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const userNotificationsChanged = useCallback(
    data => {
      if (data) {
        const { message, custom_message, type, sender, ship, ship_imo } = data;

        let notificationTitle = <NotificationTitle title={message} />;

        if (type === 'service-order' && data.title) {
          notificationTitle = (
            <ServiceOrderNotificationTitle
              title={data.notification_title ? data.notification_title : data.title}
              message={message}
            />
          );
        } else if (custom_message) {
          let customTitle = '';
          let delimiter = '';
          custom_message.forEach(function(line) {
            if (line.text) {
              customTitle += delimiter + line.text;
              delimiter = ' ';
            } else if (line.time) {
              customTitle +=
                delimiter +
                dayjs(line.time)
                  .local()
                  .format(TIME_FORMAT);
              delimiter = ' ';
            }
          });
          notificationTitle = <NotificationTitle title={customTitle} />;
        }

        let name = '';
        if (type === 'ship') {
          name = ship?.vessel_name || ship_imo;
        } else {
          name = t('{{username}} (Port of {{portname}})', {
            portname: portName,
            username: sender.contact ? sender.contact : sender.email,
          });
        }
        notification.open({
          message: notificationTitle,
          description: <NotificationSentBy sender={name} t={t} />,
          duration: 5,
          placement: 'bottomRight',
          closeIcon: <Icon type="close" />,
        });
      }
    },
    [portName, t]
  );

  // Notifications for pinned vessels
  useUserSocket(`notifications-changed-${user.id}`, userNotificationsChanged);

  const apiSendNotification = async (
    type,
    title,
    imo,
    port_call_master_id = null,
    decisions = null,
    parent_id = null
  ) => {
    const result = await apiCall('post', 'notifications', {
      type: type,
      message: title,
      ship_imo: imo,
      port_call_master_id: port_call_master_id,
      decisions: decisions,
      parent_id: parent_id,
    });
    if (result?.status === 200) {
      // TODO: show success every time to prevent email phishing
      return message.success(t('Notification was sent successfully'), 4);
    }
    return message.error(result.data.error, 4);
  };

  const { data: summaryData, error: summaryError, fetchData: fetchSummary } = useApi('get', 'summaries');

  const getSummaries = useCallback(() => {
    fetchSummary();
  }, [fetchSummary]);

  let portNotificationSummary =
    summaryError || !summaryData ? { total: 0, unread: 0 } : summaryData['port-notifications'];
  let portcallNotificationSummary = summaryError || !summaryData ? {} : summaryData['port-call-notifications'];
  let incidentSummary = summaryError || !summaryData ? { total: 0, unread: 0 } : summaryData['incidents'];

  useUserSocket('notifications-summary-common-changed', getSummaries);
  useUserSocket(`notifications-summary-changed-${user.id}`, getSummaries);
  useUserSocket('incidents-changed', getSummaries);
  useUserSocket(`incidents-changed-${user?.id}`, getSummaries);

  let portcallSummaryCount = 0;
  if (portcallNotificationSummary) {
    Object.keys(portcallNotificationSummary).forEach(s => {
      if (portcallNotificationSummary[s].unread > 0) {
        portcallSummaryCount += portcallNotificationSummary[s].unread;
      }
    });
  }

  const combinedSummary = (portNotificationSummary ? portNotificationSummary.unread : 0) + portcallSummaryCount;

  return (
    <NotificationContext.Provider
      value={{
        apiSendNotification: apiSendNotification,
        notificationReplyOpen: notificationReplyOpen,
        setNotificationReplyOpen: setNotificationReplyOpen,
        selectedImo: selectedImo,
        setSelectedImo: setSelectedImo,
        portNotificationSummary,
        combinedSummary,
        portcallSummaryCount,
        incidentSummary,
        getSummaries,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
