import React, { useReducer, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import { cancel, showAddTimestamp } from '../../utils/actions';

import { TimestampContext } from '../../context/TimestampContext';

import { cardReducer } from '../../utils/reducers';

import App from 'antd/es/app';

import Heading from '../ui/Heading';
import Icon from '../ui/Icon';
import Form from '../ui/Form';
import ButtonLight from '../ui/ButtonLight';
import Button from '../ui/Button';
import Select from '../ui/Select';
import Label from '../ui/Label';
import Datepicker from '../ui/Datepicker';
import SendJITSlotRequestModal from './SendJITSlotRequest';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledCardActions = styled.div``;

const ActionButton = styled(ButtonLight)`
  background: ${props => (props.blue ? props.theme.color.secondary : props.theme.color.grey_lighter3)};
  color: ${props => (props.blue ? props.theme.color.white : props.theme.color.lighter_black)};
  font-size: 14px;
  min-width: 88px;
  margin-bottom: 12px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 34px;
  &:hover {
    color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};
    background: none;
  }
`;

const ActionForm = styled(Form)`
  background: ${({ theme }) => theme.color.white};
  margin: 0 -${({ theme }) => theme.sizing.gap} ${({ theme }) => theme.sizing.gap};
  padding: 0 ${({ theme }) => theme.sizing.gap};
  animation: ${slideDown} 0.125s;
  select,
  input,
  textarea,
  span {
    width: 100%;
  }
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: ${({ theme }) => theme.sizing.gap};
  button {
    margin-right: ${({ theme }) => theme.sizing.gap};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActionHeader = styled(Heading)`
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.secondary};
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.05em;
  padding: ${({ theme }) => theme.sizing.gap};
  margin: 0 calc(-${({ theme }) => theme.sizing.gap} - 1px) ${({ theme }) => theme.sizing.gap}
    calc(-${({ theme }) => theme.sizing.gap} - 1px);
  i {
    width: 20px;
    height: 20px;
    margin-right: ${({ theme }) => theme.sizing.gap_tiny};
    fill: currentColor;
    top: -1px;
  }
`;

const UTCTime = styled.p`
  font-style: italic;
  color: ${({ theme }) => theme.color.grey};
  margin-top: 0.3rem;
  margin-bottom: 0;
  height: 11px;
  width: 100%;
  font-size: 12px;
  text-align: center;
`;

const ActionWrapper = styled.div`
  height: 30px;
  margin-bottom: 2px;
`;

const ActionButtonWrapper = styled.div`
  height: 38px;
  margin-bottom: 2px;
`;

const MapLink = styled.div`
  color: #4990dd;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const ShipIcon = styled(Icon)`
  svg {
    width: 14px;
    height: 14px;
  }
`;

const Pin = styled(Icon)`
  position: relative;
  z-index: 5;
  cursor: pointer;
  height: 20px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const CardActions = ({ imo, vesselName, setOpen, jitForVessel, ...props }) => {
  const { apiCall, namespace, user, modules, setShowableVesselOnMap } = useContext(UserContext);
  const { timestampDefinitions } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();

  const { message } = App.useApp();

  const initCard = {
    showAddTimestamp: false,
    showSendNotification: false,
    showRecommendTime: false,
    showRecommendTimeVis: false,
    showSendMessage: false,
  };

  const [state, dispatch] = useReducer(cardReducer, initCard);

  const initTimestamp = {
    imo: imo,
    vessel_name: vesselName,
    time_type: undefined,
    state: undefined,
    time: undefined,
    payload: {},
  };

  const [timestamp, setTimestamp] = useState(initTimestamp);
  const [jitModalOpen, setJitModalOpen] = useState(false);

  const timestampOptions = timestampDefinitions
    .filter(def => !def.state.includes('LOC'))
    .map((def, index) => {
      const label = t(
        def.name +
          (def.payload?.location ? ' ' + def.payload.location + '' : '') +
          (def.payload?.direction ? ' ' + def.payload.direction + '' : '')
      );
      return {
        key: def.id + index,
        id: def.id,
        value: def.id,
        label,
        title: label,
      };
    });

  timestampOptions.unshift({
    key: 0,
    value: '',
    label: t('Select timestamp type'),
  });

  const handleTimestampChange = e => {
    if (e?.target) {
      const result = timestampDefinitions.filter(obj => obj.id === e.target.value).pop();
      setTimestamp({
        ...timestamp,
        time_type: result?.time_type,
        state: result?.state,
        payload: result?.payload,
      });
    } else if (e) {
      setTimestamp({
        ...timestamp,
        time: dayjs(e)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss+00:00'),
      });
    }
  };

  useEffect(() => {
    if (state.showAddTimestamp) {
      let element = document.getElementById('add-timestamp-class');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }
  }, [state.showAddTimestamp]);

  const sendTimestamp = async e => {
    e.preventDefault();
    const result = await apiCall('post', 'timestamps', timestamp);
    setTimestamp(initTimestamp);
    if (result?.status === 200) {
      dispatch(cancel());
      setOpen(false);
      return message.success(t('Timestamp was sent successfully'), 4);
    }
    dispatch(cancel());
    return message.error(result.data.error, 4);
  };

  const handlePin = async () => {
    let newPinnedVessels = Array.from(props.pinnedVessels);
    const index = newPinnedVessels.indexOf(imo);
    if (index !== -1) {
      newPinnedVessels.splice(index, 1);
    } else {
      newPinnedVessels.push(imo);
    }
    if (props.pinned) {
      await apiCall('put', 'pinned-vessel-ids', { vessel_ids: newPinnedVessels });
      props.setPinned(false);
    } else {
      await apiCall('put', 'pinned-vessel-ids', { vessel_ids: newPinnedVessels });
      props.setPinned(true);
    }

    props.fetchData();
  };

  const handleCancel = () => {
    localStorage.removeItem(`vessel-notification-${imo}`);
    setTimestamp(initTimestamp);
    dispatch(cancel());
  };

  const showOnMap = () => {
    setShowableVesselOnMap({ imo, vesselName });
    history.push('/map');
  };

  const openMarineTraffic = () => {
    window.open('https://www.marinetraffic.com/en/ais/details/ships/imo:' + imo, '_blank', 'noopener,noreferrer');
  };

  return (
    <StyledCardActions {...props}>
      {modules.map_module === 'enabled' && (
        <ActionWrapper>
          <MapLink outline onClick={() => showOnMap()}>
            <Icon type="map" style={{ marginRight: '.8rem' }} />
            {t('Show on map')}
          </MapLink>
        </ActionWrapper>
      )}
      <ActionWrapper>
        <MapLink outline onClick={openMarineTraffic}>
          <ShipIcon type="ship" style={{ marginRight: '.8rem' }} />
          {t('Open in Marine Traffic')}
        </MapLink>
      </ActionWrapper>
      <ActionButtonWrapper>
        <ActionButton outline blue={props.pinned} onClick={handlePin}>
          <Pin
            data-icon={props.pinned ? 'unpin' : 'pin'}
            pinned={props.pinned}
            type={props.pinned ? 'pinned' : 'pin'}
            style={{ marginRight: '.8rem' }}
          />
          {props.pinned ? t('Unpin') : t('Pin to filter notifications')}
        </ActionButton>
      </ActionButtonWrapper>
      {user.permissions.includes('add_manual_timestamp') && (
        <ActionButtonWrapper>
          {!state.showAddTimestamp ? (
            <ActionButton outline onClick={() => dispatch(showAddTimestamp())}>
              <Icon type="timestamp-add" />
              {t('Add timestamp')}
            </ActionButton>
          ) : (
            <ActionForm onSubmit={sendTimestamp} id="add-timestamp-class">
              <ActionHeader h4>
                <Icon type="timestamp-add" />
                {t('Add timestamp')}
              </ActionHeader>
              <Select
                label={t('Timestamp type')}
                name="type"
                options={timestampOptions}
                onChange={handleTimestampChange}
              />
              <Label>{t('Date and time')}</Label>
              <Datepicker onChange={handleTimestampChange} />
              {timestamp.time ? <UTCTime>{timestamp.time} UTC</UTCTime> : <UTCTime></UTCTime>}
              <ActionButtons>
                <Button disabled={typeof timestamp.time === 'undefined'} link>
                  {t('Add new')}
                </Button>
                <Button link onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
              </ActionButtons>
            </ActionForm>
          )}
        </ActionButtonWrapper>
      )}
      {modules &&
        modules.queue_module === 'enabled' &&
        user.permissions.includes('manage_queue_slot_reservation') &&
        jitForVessel?.loading_master_etd_active && (
        <ActionButton outline onClick={() => setJitModalOpen(true)}>
          <Icon type="timestamp-add" />
          {t('Send loading master ETC')}
        </ActionButton>
      )}

      {jitModalOpen && (
        <SendJITSlotRequestModal
          vessel={jitForVessel}
          type={'edit'}
          closeModal={() => {
            setJitModalOpen(false);
            setOpen(false);
          }}
          readOnly={false}
          newTime={undefined}
        />
      )}
    </StyledCardActions>
  );
};

export default CardActions;
