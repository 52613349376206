import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px;
  margin-bottom: 28px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  width: 100%;
`;

const DataElement = styled.div`
  display: inline-block;
  justify-content: center;
  padding: 6px 12px;
  padding-left: ${props => props.first && '0px'};
  padding-right: ${props => props.last && '0px'};
`;

const Header = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  margin-left: 12px;
`;

const Values = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 18px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 6px;
`;

const Value = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 24px;
  font-weight: 700;
`;

const StatisticsKeyValues = props => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useApi('get', props.data_source.url, { ...props.data_source.parameters });
  const dataObject = error || !data ? {} : data.data;

  let dataKeys = Object.keys(dataObject);
  const dataOptions = props.data_options;

  useEffect(() => {
    const timer = setInterval(() => {
      setStartLoader(false);
      fetchData();
    }, 5 * 60000);
    return () => clearInterval(timer);
  }, []); // eslint-disable-line

  if (dataKeys && dataKeys.length > 0) {
    let keyList = [...dataKeys];
    dataKeys = [];

    if (keyList.some(k => k === 'at_berth')) {
      dataKeys.push('at_berth');
      keyList = keyList.filter(k => k !== 'at_berth');
    }

    if (keyList.some(k => k === 'departed')) {
      dataKeys.push('departed');
      keyList = keyList.filter(k => k !== 'departed');
    }

    if (keyList.some(k => k === 'arriving')) {
      dataKeys.push('arriving');
      keyList = keyList.filter(k => k !== 'arriving');
    }

    keyList.forEach(k => dataKeys.push(k));
  }

  return (
    <Container>
      {startLoader && loading ? (
        <Loader>
          <Spin size="medium" />
        </Loader>
      ) : dataKeys ? (
        <Graph>
          <Header>
            {/*eslint-disable-next-line quotes*/}
            {t("Today's overview at ")} <span style={{ textTransform: 'capitalize' }}>{namespace}</span>
          </Header>
          <Values>
            {dataKeys.map((d, i) => (
              <DataElement key={i} first={i === 0} last={i === dataKeys.length - 1}>
                <Label>{dataOptions[d].label}</Label>
                <Value>{dataObject[d]}</Value>
              </DataElement>
            ))}
          </Values>
        </Graph>
      ) : (
        <NoData>{t('No data available.')}</NoData>
      )}
    </Container>
  );
};
export default StatisticsKeyValues;
