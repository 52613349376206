import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';

import EmergencyShutdownControl from '../../components/admin/EmergencyShutdownControl';

const AdminEmergencyShutdown = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Emergency Shutdown | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Emergency Shutdown')}>
      <Page fullWidth>
        <EmergencyShutdownControl />
      </Page>
    </Layout>
  );
};

export default AdminEmergencyShutdown;
