import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';
import { TimestampContext } from '../context/TimestampContext';

import Clock from './Clock';
import Heading from './ui/Heading';
import { defaultPortList, mobilePixelMaxWidthLimit } from '../utils/constants';
import HeaderPortSelect from './HeaderPortSelect';
import DynamicLogo from './ui/DynamicLogo';

const LogoHeader = styled.div`
  display: flex;
  padding-top: 6px;

  svg {
    width: 48px;
    height: 48px;
    margin-right: ${({ theme }) => theme.sizing.gap};
    fill: white;
  }
  h4,
  p {
    white-space: nowrap;
    margin-bottom: 0;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    svg {
      width: 36px;
      height: 36px;
      margin-right: 0.6rem;
    }
  }
`;

const NameWrapper = styled.div`
  margin-top: 2px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-top: 0px;
  }
`;

const StyledHeading = styled(Heading)`
  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 1rem;
  }
`;

const getCurrentPort = (namespace, portList = null) => {
  const ports = portList || defaultPortList;
  const port = ports.find(port => port.key === namespace);
  return port || { label: namespace };
};

const PortName = props => {
  const { namespace, namespaces, user } = useContext(UserContext);
  const { setSearch } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);
  const currentPort = getCurrentPort(namespace, namespaces);

  return (
    <LogoHeader {...props}>
      <DynamicLogo src={currentPort.icon} />
      <NameWrapper>
        <StyledHeading h4>
          <Link to="/" onClick={() => setSearch('')}>
            {currentPort.key === 'vts' || currentPort.key === 'neste'
              ? currentPort.label
              : t('Port of {{portName}}', { portName: currentPort.label })}
          </Link>
          <HeaderPortSelect user={user} />
        </StyledHeading>
        <Clock />
      </NameWrapper>
    </LogoHeader>
  );
};

export default PortName;
