import './MapContainer.css';

import {
  TileLayer,
  ZoomControl,
  useMapEvents,
  useMap as useLeafletMap,
  Polyline,
  Circle,
  GeoJSON,
  Tooltip as LeafletTooltip,
  Popup,
  FeatureGroup,
} from 'react-leaflet';
import React, { useContext, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom'; // eslint-disable-line
import dayjs from 'dayjs';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { SeaChartMarkersContext } from '../../context/SeaChartMarkersContext';
import { SeaChartVesselsContext } from '../../context/SeaChartVesselsContext';
import { CraneContext } from '../../context/CraneContext';
import { FleetFilteringContext } from '../../context/FleetFilteringContext';
import { PublicContext } from '../../context/PublicContext';
import { UserContext } from '../../context/UserContext';
import { TimestampContext } from '../../context/TimestampContext';

import useMap from './UseMap';
import useApi from '../../hooks/useApi';

import { getOptions } from './MapUtils';
import LayersComponent from './LayersComponent';
import FleetViewComponent from './FleetViewComponent';
import { filterVessels } from '../../utils/utils';
import CircleMarkersData from './CircleMarkersData';
import AssetPopup from './AssetPopup';
import { debounce } from 'throttle-debounce';
import QuickLinkPopup from './QuickLinkPopup';
import QuickLinkMarker from './QuickLinkMarker';
import { WikiContext } from '../../context/WikiContext';
import HelpModal from '../info/HelpModal';
import { QuickLinkMapType } from '../info/WikiConstants';
import Icon from '../ui/Icon';
import MeasureMapTool from './MeasureMapTool';
import WMSChart from './WMSChart';
import CircleMapTool from './CircleMapTool';
import { SideHelpContext } from '../../context/SideHelpContext';

const RecenterContainer = styled.div`
  background: ${props => props.theme.color.white};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  padding: 4px 8px;

  &:hover {
    background: #f4f4f4;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-left: -4px;
  }
`;

const Recenter = styled.div`
  position: absolute;
  bottom: 94px;
  right: 10px;
  z-index: 100;
  color: #4a4a4a;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
`;

const MapInnerContainer = ({
  zoom,
  setZoom,
  appliedCoordinates,
  fleetViewOn,
  setFleetViewOn,
  quickLinksOn,
  setQuickLinksOn,
  setSideModalOpen,
  sideModalOpen,
  showRoutes,
  setShowRoutes,
  seaPassageData,
  vesselsState,
  setVesselsState,
  selectedAisVessel,
  setSelectedAisVessel,
  selectedPortVessel,
  setSelectedPortVessel,
  selectedPortVesselLocation,
  setSelectedPortVesselLocation,
  setMeasureDrawing,
  measureDrawing,
  setCircleDrawing,
  circleDrawing,
  setAppliedCoordinates,
}) => {
  const { user: sessionUser, apiCall, modules, useUserSocket, onlyFleet, namespace, mapDefaultZoom } = useContext(
    UserContext
  );
  const { t } = useTranslation(namespace);
  const { availableWikiLinks } = useContext(WikiContext);
  const { filteredImos, allTimestamps } = useContext(TimestampContext);

  const { sideWikiProps, setSideWikiProps, setSideHelpOpen, sideHelpOpen } = useContext(SideHelpContext);

  const { markers } = useContext(SeaChartMarkersContext);
  const { vessels } = useContext(SeaChartVesselsContext);
  const { cranes, cranesUpdated } = useContext(CraneContext);
  const { allMarkers, allVessels, aisVessels } = useContext(PublicContext);
  const { fleetFilter, formData, fleetQuickSearchText } = useContext(FleetFilteringContext);

  // eslint-disable-next-line
  const clusterRef = useRef();
  const [showVessels, setShowVessels] = useState(true);
  const [showMarkers, setShowMarkers] = useState(true);
  const [showCranes, setShowCranes] = useState(false);
  const [mapType, setMapType] = useState('automatic');

  const [selectedPort, setSelectedPort] = useState(undefined);
  const [lastPort, setLastPort] = useState(undefined);
  const [portSliderOpen, setPortSliderOpen] = useState(false);
  const [portFromUrl, setPortFromUrl] = useState(false);
  const [fleetCardsOn, setFleetCardsOn] = useState(false);

  const [locodeData, setLocodeData] = useState([]);
  const [assetData, setAssetData] = useState();
  const [hiddenTypes, setHiddenTypes] = useState([]);

  const [selectedAisVesselLocation, setSelectedAisVesselLocation] = useState(false);

  const [aisOn, setAisOn] = useState(false);

  const [quickLink, setQuickLink] = useState(undefined);
  const [quickLinkModal, setQuickLinkModal] = useState(undefined);

  const sideModalRef = useRef();
  const assetsRef = useRef();

  const GeoAssetToolDataEnabled = useRef(
    document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')
  );

  const location = useLocation();
  const history = useHistory();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    if (!aisOn && (vesselsState === 'all-ais' || vesselsState === 'helcom')) {
      setAisOn(true);
    }
  }, [aisOn, vesselsState]);

  useEffect(() => {
    const getAssetData = async () => {
      if (!GeoAssetToolDataEnabled.current) return;
      //TODO: Types hidden status into localstorage.
      const result = await apiCall('get', 'floora/public/assets', { namespace: namespace });
      if (result?.status === 200 && result?.data?.assets && result?.data?.types) {
        const usedTypes = [];
        for (let index = 0; index < result.data.assets.length; index++) {
          const element = result.data.assets[index];
          if (!usedTypes.includes(element.type_id)) usedTypes.push(element.type_id);
        }
        let tempTypes = [];
        for (let index = 0; index < result.data.types.length; index++) {
          const type = result.data.types[index];
          if (usedTypes.includes(type.type_id)) tempTypes.push(type);
        }
        setAssetData({ assets: result.data.assets, types: tempTypes });
        const filterListContent = localStorage.getItem(`${namespace + 'MapTypeFilterList'}`);
        if (result?.data?.types.length > 0 && filterListContent) {
          const currentFilterListContent = filterListContent.split(',');
          const newFilterListContent = [];
          for (let index = 0; index < result.data.types.length; index++) {
            const element = result.data.types[index];
            if (currentFilterListContent.includes(element.type_id)) newFilterListContent.push(element.type_id);
          }
          setHiddenTypes(newFilterListContent);
          localStorage.setItem(`${namespace + 'MapTypeFilterList'}`, newFilterListContent);
        } else setHiddenTypes([]);
      }
    };

    const getLocodeData = async () => {
      const result = await apiCall('post', 'fleet/search/port', {
        order_by: [
          {
            field: 'port_name',
            order: 'asc',
          },
        ],
      });

      if (result?.status === 200 && result?.data?.results?.ports) {
        setLocodeData(result.data.results.ports);
      }
    };

    if (modules.fleet_module === 'enabled' && sessionUser.permissions.includes('view_fleet')) {
      getLocodeData();
    }

    getAssetData();

    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type === 'fleet') {
      setFleetViewOn(true);
    }
  }, []); // eslint-disable-line

  const getQueryWithoutFilters = () => {
    const fourteenDaysAgo = dayjs().subtract(14, 'days');

    return [
      {
        type: 'eta',
        operator: 'gte',
        value: fourteenDaysAgo.format('YYYY-MM-DDTHH:mm:ssZ'),
      },
      {
        type: 'is_cancelled',
        operator: 'is',
        value: false,
      },
      {
        type: 'departed',
        operator: 'is',
        value: false,
      },
    ];
  };

  const getQueryWithCorrectEtas = useCallback(() => {
    let newQueryAnd =
      fleetFilter.query?.conditions?.and?.filter(cond => {
        if (
          cond[Object.keys(cond)[0]][0].type === 'eta' ||
          cond[Object.keys(cond)[0]][0].type === 'etd' ||
          cond[Object.keys(cond)[0]][0].type === 'is_cancelled' ||
          cond[Object.keys(cond)[0]][0].type === 'departed' ||
          cond[Object.keys(cond)[0]][0].type === 'arriving'
        ) {
          return false;
        }
        return true;
      }) || [];
    let newQueryOr =
      fleetFilter.query?.conditions?.or?.filter(cond => {
        if (
          cond[Object.keys(cond)[0]][0].type === 'eta' ||
          cond[Object.keys(cond)[0]][0].type === 'etd' ||
          cond[Object.keys(cond)[0]][0].type === 'is_cancelled' ||
          cond[Object.keys(cond)[0]][0].type === 'departed' ||
          cond[Object.keys(cond)[0]][0].type === 'arriving'
        ) {
          return false;
        }
        return true;
      }) || [];

    newQueryAnd = [...newQueryAnd, ...getQueryWithoutFilters()];

    let conditions = { ...fleetFilter.query.conditions };

    if (newQueryAnd.length) {
      conditions.and = newQueryAnd;
    }

    if (newQueryOr.length) {
      conditions.or = newQueryOr;
    }

    return {
      conditions,
      ...(fleetQuickSearchText ? { text: fleetQuickSearchText } : {}),
    };
  }, [fleetFilter, fleetQuickSearchText]);

  // eslint-disable-next-line no-unused-vars
  const { loading, data: portData, error, fetchData: fetchPortData } = useApi(
    'post',
    'fleet/search/portcall,manual-portcall',
    {
      query: fleetFilter.query
        ? { ...getQueryWithCorrectEtas() }
        : {
            conditions: {
              and: getQueryWithoutFilters(),
            },
            ...(fleetQuickSearchText ? { text: fleetQuickSearchText } : {}),
          },
      order_by: [
        {
          field: 'eta',
          order: 'asc',
        },
      ],
      group_by: 'locode',
    },
    null,
    modules.fleet_module === 'enabled' && sessionUser.permissions.includes('view_fleet')
  );

  const portcallChanged = useCallback(() => {
    if (modules.fleet_module === 'enabled' && sessionUser.permissions.includes('view_fleet')) {
      fetchPortData(false, {
        query: fleetFilter.query
          ? { ...getQueryWithCorrectEtas() }
          : {
              conditions: {
                and: getQueryWithoutFilters(),
              },
              ...(fleetQuickSearchText ? { text: fleetQuickSearchText } : {}),
            },
        order_by: [
          {
            field: 'eta',
            order: 'asc',
          },
        ],
        group_by: 'locode',
      });
    }
  }, [
    modules.fleet_module,
    sessionUser.permissions,
    fetchPortData,
    fleetFilter.query,
    getQueryWithCorrectEtas,
    fleetQuickSearchText,
  ]);
  useUserSocket('fleet-portcalls-changed', portcallChanged);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let ports =
    error || !portData || locodeData.length === 0
      ? []
      : locodeData.map(locode => {
        const indexKey = Object.keys(portData?.results?.portcalls || {}).find(
          key => key === locode.data.locodes[0].locode
        );
        const item = indexKey ? portData.results.portcalls[indexKey] : null;
        let inPort = item ? item.filter(i => i.ata && !i.atd) : [];
        let expected = item ? item.filter(i => !i.ata) : [];
        const portname = locode.port_name.replace(/ *\([^)]*\) */g, '');
        const coordinates =
            locode.data.locodes[0].coordinates || locode.data.locodes[0].portareas[0].coordinates || null;

        inPort = inPort.sort((b, a) => new Date(b.eta) - new Date(a.eta));
        expected = expected.sort((b, a) => new Date(b.eta) - new Date(a.eta));

        return {
          locode: locode.data.locodes[0].locode,
          port_name: portname,
          in_port: inPort,
          expected: expected,
          coordinates: coordinates ? [coordinates.lat, coordinates.lon] : null,
        };
      });

  if (formData.locode.length || formData.portarea.length) {
    const locodes = formData.locode.map(lo => lo.value);
    const portareas = formData.portarea.map(pa => pa.locode);

    const filteredLocodes = [...new Set([...locodes, ...portareas])];
    const res = filteredLocodes.map(filteredLocode => ports.find(port => port.locode === filteredLocode));
    const filteredPorts = res.filter(item => item !== undefined);

    if (res) {
      ports = filteredPorts;
    }
  }

  useEffect(() => {
    if (modules.fleet_module === 'enabled' && sessionUser.permissions.includes('view_fleet')) {
      fetchPortData(false, {
        query: fleetFilter.query
          ? { ...getQueryWithCorrectEtas() }
          : {
              conditions: {
                and: getQueryWithoutFilters(),
              },
              ...(fleetQuickSearchText ? { text: fleetQuickSearchText } : {}),
            },
        order_by: [
          {
            field: 'eta',
            order: 'asc',
          },
        ],
        group_by: 'locode',
      });
    }
  }, [
    fleetFilter,
    fetchPortData,
    getQueryWithCorrectEtas,
    modules.fleet_module,
    sessionUser.permissions,
    fleetQuickSearchText,
  ]);

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
      debouncedLocalStorageSet();
    },
    moveend: () => {
      debouncedLocalStorageSet();
    },
  });

  useEffect(() => {
    if (ports.length && !portFromUrl) {
      const params = new URLSearchParams(location.search);
      const newPort = params.get('port');
      if (newPort && ports.length) {
        const portData = ports.find(d => d?.port_name === newPort);
        if (portData) {
          setSelectedPort(params.get('port'));
          setFleetViewOn(true);
          setPortSliderOpen(true);
          history.replace(location.pathname + '?type=fleet');
          if (newPort !== 'Anchorage') {
            mapEvents.setView([portData.coordinates[0], portData.coordinates[1]], zoom);
          }
        } else {
          history.replace(location.pathname + '?type=fleet');
          setFleetViewOn(true);
        }
      }
      setPortFromUrl(true);
    }
  }, [history, location, mapEvents, portFromUrl, ports, setFleetViewOn, zoom]);

  const [routeData, setRouteData] = useState([]);

  const routeRef = useRef(0);

  const clickPort = name => {
    setLastPort(selectedPort);
    setSelectedPort(name);
    setPortSliderOpen(true);
    const portData = name ? ports.find(d => d.port_name === name) : null;
    if (portData) {
      if (name !== 'Anchorage') {
        mapEvents.setView([portData.coordinates[0], portData.coordinates[1]], zoom > 9 ? zoom : 9);
      }
    }
  };

  const { draRoute, draColor, markersOverlay, craneOverlay, vesselsOverlay, fleetOverlay } = useMap({
    vessels:
      vesselsState === 'helcom' || vesselsState === 'all-ais'
        ? []
        : fleetViewOn || onlyFleet
          ? namespace === 'vts'
            ? aisVessels
            : allVessels
          : vesselsState === 'all-fi'
            ? filterVessels(allVessels, filteredImos)
            : filterVessels(vessels, filteredImos),
    markers: vesselsState === 'all-fi' || fleetViewOn || onlyFleet ? allMarkers : markers,
    cranes,
    fleetData: ports,
    appliedZoom: zoom,
    clickPort,
    selectedPort,
    fleetViewOn,
    showVessels,
    deselectPort: () => setSelectedPort(undefined),
    fleetCardsOn,
    setSideModalOpen,
    sideModalOpen,
    cranesUpdated,
    selectedPortVessel,
    setSelectedPortVessel,
    timestamps: allTimestamps,
    vesselsState,
  });

  const geodataPermissions = sessionUser.permissions.includes('manage_geodata');

  const options = getOptions();

  const storageKey = 'map.centerAndZoom_' + namespace;

  const updateLocalStorage = useCallback(() => {
    const center = mapEvents.getCenter();
    setAppliedCoordinates(`${center.lat},${center.lng}`);
    localStorage.setItem(storageKey, JSON.stringify({ zoom: mapEvents.getZoom(), center }));
  }, [mapEvents, setAppliedCoordinates, storageKey]);

  const debouncedLocalStorageSet = useMemo(() => debounce(500, updateLocalStorage), [updateLocalStorage]);

  useEffect(() => {
    const value = localStorage.getItem(storageKey);
    try {
      const parsedObject = JSON.parse(value);
      if (parsedObject.center && parsedObject.zoom) {
        mapEvents.setView(parsedObject.center, parsedObject.zoom);
      } else {
        mapEvents.setView(appliedCoordinates.split(','), mapDefaultZoom === undefined ? 5 : mapDefaultZoom);
        localStorage.removeItem(storageKey);
      }
    } catch {
      mapEvents.setView(appliedCoordinates.split(','), mapDefaultZoom === undefined ? 5 : mapDefaultZoom);
      localStorage.removeItem(storageKey);
    }

    map.eachLayer(l => {
      if (l.options.geoAssetCircleMarkers) {
        l.remove();
      }
    });

    setTimeout(() => {
      mapEvents.invalidateSize();
    }, 400);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sideModalOpen !== sideModalRef.current && (!sideModalRef.current || !sideModalOpen)) {
      setTimeout(function() {
        mapEvents.invalidateSize();
      }, 400);
    }
    sideModalRef.current = sideModalOpen;
  }, [mapEvents, sideModalOpen]);

  useEffect(() => {
    setTimeout(function() {
      mapEvents.invalidateSize();
    }, 400);
  }, [mapEvents, sideHelpOpen]);

  const fleetPrevDiv = L.DomUtil.get('clickpreventerfleet');
  if (fleetPrevDiv) {
    L.DomEvent.disableScrollPropagation(fleetPrevDiv);
  }

  const panelPrevDiv = L.DomUtil.get('clickpreventer');
  if (panelPrevDiv) {
    L.DomEvent.disableScrollPropagation(panelPrevDiv);
  }

  useEffect(() => {
    if (selectedAisVesselLocation) {
      setSelectedAisVesselLocation(false);
      mapEvents.setView([selectedAisVessel.lat, selectedAisVessel.lon], zoom);
    }
  }, [mapEvents, selectedAisVessel, selectedAisVesselLocation, setSelectedAisVesselLocation, zoom]);

  useEffect(() => {
    if (selectedPortVesselLocation) {
      setSelectedPortVesselLocation(false);
      mapEvents.setView([selectedPortVessel.lat, selectedPortVessel.lon], zoom);
    }
  }, [mapEvents, selectedPortVessel, selectedPortVesselLocation, setSelectedPortVesselLocation, zoom]);

  const handleDatachange = async () => {
    try {
      const result = await apiCall('get', 'dra-route-by-imo', { imo: draRoute });
      if (mounted.current) {
        setRouteData(result.data);
      }
    } catch {
      console.error(Error);
    }
  };

  useEffect(() => {
    setRouteData([]);
    if (geodataPermissions && mounted.current && routeRef.current !== draRoute && draRoute) {
      handleDatachange();
    }
    // eslint-disable-next-line
  }, [draRoute]);

  useEffect(() => {
    setTimeout(() => {
      const orderArray = assetsRef.current?.getLayers();
      orderArray?.sort((a, b) => b.getLayers()[0].feature.properties.area - a.getLayers()[0].feature.properties.area);
      orderArray?.forEach(o => {
        o.bringToFront();
      });
    }, 500);
  }, [hiddenTypes]);

  const updateVesselsState = type => {
    setVesselsState(type);
  };

  const toggleShowRoutes = state => {
    setShowRoutes(state);
  };

  const clickPreventer = () => {
    const div = L.DomUtil.get('clickpreventer');
    L.DomEvent.disableClickPropagation(div);
  };

  const clickPreventerFleet = () => {
    const div = L.DomUtil.get('clickpreventerfleet');
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  };

  const zoomToVessel = data => {
    mapEvents.setView([data[1], data[0]], 13);
  };

  const zoomToPort = data => {
    mapEvents.setView([data[0], data[1]], zoom > 9 ? zoom : 9);
  };

  const updateSetFleetViewOn = e => {
    setFleetViewOn(e);
    setVesselsState('all-fi');
  };

  const updateQuickLinksOn = e => {
    setQuickLinksOn(e);
  };

  const createQuickLink = useCallback(e => {
    setQuickLink(e.latlng);
  }, []);

  const closeQuickLink = useCallback(() => {
    if (quickLink) {
      setQuickLink(undefined);
    }
  }, [quickLink]);

  const debouncedSet = useMemo(() => debounce(200, createQuickLink), [createQuickLink]);
  const debouncedClick = useMemo(() => debounce(200, closeQuickLink), [closeQuickLink]);

  const map = useLeafletMap();

  if (modules?.wiki_module === 'enabled' && sessionUser.permissions.includes('manage_wiki')) {
    map.on('contextmenu', debouncedSet);
    map.on('click', debouncedClick);
  }

  const quickLinks = availableWikiLinks?.filter(w => w.object_type === QuickLinkMapType) || [];

  const recenterMap = () => {
    mapEvents.setView(appliedCoordinates.split(','), mapDefaultZoom === undefined ? 5 : mapDefaultZoom);
  };

  return (
    <>
      <ZoomControl position="bottomright" />
      <Recenter onClick={recenterMap}>
        <Tooltip
          title={t('Use default centering and zoom')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          placement="left"
        >
          <RecenterContainer>
            <Icon type="center" />
          </RecenterContainer>
        </Tooltip>
      </Recenter>
      <div id={'clickpreventer'} onClick={() => clickPreventer()}>
        <LayersComponent
          mapType={mapType}
          setMapType={setMapType}
          showVessels={showVessels}
          setShowVessels={setShowVessels}
          vesselState={vesselsState}
          setVesselState={updateVesselsState}
          fleetViewOn={fleetViewOn}
          setFleetViewOn={updateSetFleetViewOn}
          quickLinksOn={quickLinksOn}
          setQuickLinksOn={updateQuickLinksOn}
          fleetCardsOn={fleetCardsOn}
          setFleetCardsOn={setFleetCardsOn}
          routesOn={showRoutes}
          setRoutesOn={toggleShowRoutes}
          showMarkers={showMarkers}
          setShowMarkers={setShowMarkers}
          showCranes={showCranes}
          setShowCranes={setShowCranes}
          geodataPermissions={geodataPermissions}
          onlyFleet={onlyFleet}
          types={assetData?.types}
          hiddenTypes={hiddenTypes}
          setHiddenTypes={setHiddenTypes}
        />
      </div>

      <div id={'clickpreventerfleet'} onClick={() => clickPreventerFleet()}>
        {fleetViewOn && (
          <FleetViewComponent
            open={portSliderOpen}
            setOpen={setPortSliderOpen}
            data={selectedPort ? ports.find(d => d.port_name === selectedPort) : null}
            clickPort={clickPort}
            lastPortName={lastPort}
            deselectPort={() => setSelectedPort(undefined)}
            allVessels={
              namespace === 'vts'
                ? aisVessels
                  ? aisVessels.map(v => ({ imo: v.properties.imo, coordinates: v.geometry.coordinates }))
                  : []
                : allVessels
                  ? allVessels.map(v => ({ imo: v.properties.imo, coordinates: v.geometry.coordinates }))
                  : []
            }
            zoomToVessel={zoomToVessel}
            zoomToPort={zoomToPort}
            setSideModalOpen={setSideModalOpen}
            sideModalOpen={sideModalOpen}
          />
        )}
      </div>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
        maxNativeZoom={20}
        maxZoom={20}
      />
      {showRoutes &&
        !!routeData &&
        !!routeData.features &&
        !!draRoute &&
        geodataPermissions &&
        vesselsState === 'port' && (
        <>
          {routeData.features.map((f, i) => {
            return (
              <Polyline
                key={i}
                pathOptions={{
                  color: options.vesselBaseColors[draColor] || 'gray',
                  weight: '2',
                  opacity: '0.85',
                }}
                positions={L.GeoJSON.coordsToLatLngs(f.geometry.coordinates, 0)}
              />
            );
          })}
        </>
      )}
      {showRoutes &&
        !!seaPassageData &&
        seaPassageData.features.map((f, i) => {
          if (f.geometry.type === 'LineString') {
            return (
              <Polyline
                key={i}
                pathOptions={{
                  color: '#4990DD',
                  weight: '2',
                  opacity: '0.85',
                }}
                positions={L.GeoJSON.coordsToLatLngs(f.geometry.coordinates, 0)}
              />
            );
          } else if (f.geometry.type === 'Point') {
            return (
              <Circle
                key={i}
                center={L.GeoJSON.coordsToLatLng(f.geometry.coordinates)}
                pathOptions={{
                  color: '#4990DD',
                }}
                radius={6}
                stroke={true}
              />
            );
          }
        })}
      {vesselsOverlay}
      <WMSChart show={mapType === 'seamap' || (mapType === 'automatic' && zoom >= 14)} />
      {showMarkers && markersOverlay}
      {showCranes && craneOverlay}
      {fleetViewOn && fleetOverlay}
      {aisOn && (
        <CircleMarkersData
          showVessels={showVessels}
          fleetViewOn={fleetViewOn}
          vesselsState={vesselsState}
          zoom={zoom}
          selectedAisVessel={selectedAisVessel}
          setSelectedAisVessel={setSelectedAisVessel}
          setSelectedAisVesselLocation={setSelectedAisVesselLocation}
        />
      )}
      <FeatureGroup ref={assetsRef}>
        {assetData?.assets &&
          assetData.assets.map(e => {
            if (hiddenTypes.includes(e.type_id)) return;
            return (
              <GeoJSON
                key={e.geo_asset_id}
                data={{ ...e.geo_json, properties: { area: e.geo_data_area } }}
                style={e.geo_json.style}
              >
                <LeafletTooltip direction="top">{e.name}</LeafletTooltip>
                {((e.properties && !Array.isArray(e.properties)) ||
                  (modules?.wiki_module === 'enabled' && sessionUser.permissions.includes('view_wiki'))) && (
                  <Popup>
                    <AssetPopup
                      data={e.properties}
                      propertiesOn={e.properties && !Array.isArray(e.properties)}
                      id={e.geo_asset_id}
                    />
                  </Popup>
                )}
              </GeoJSON>
            );
          })}
      </FeatureGroup>

      {!!quickLink && (
        <Popup position={quickLink}>
          <QuickLinkPopup
            addQuickLinkInfo={() => {
              setQuickLinkModal({ coordinates: quickLink });
            }}
          />
        </Popup>
      )}

      {quickLinkModal && (
        <HelpModal
          open={!!quickLinkModal}
          setOpen={() => {
            setQuickLinkModal(undefined);
            setQuickLink(undefined);
          }}
          objectType={QuickLinkMapType}
          objectId={quickLinkModal.coordinates.lat + 'C' + quickLinkModal.coordinates.lng}
          coordinates={quickLinkModal.coordinates}
        />
      )}

      {quickLinks.length > 0 &&
        quickLinksOn &&
        quickLinks.map((link, i) => (
          <QuickLinkMarker
            key={i}
            link={link}
            openModal={() => {
              if (sideWikiProps) {
                setSideWikiProps({
                  coordinates: {
                    lat: link.lat,
                    lng: link.lon,
                  },
                });
                setSideHelpOpen(true);
              } else {
                setQuickLinkModal({
                  coordinates: {
                    lat: link.lat,
                    lng: link.lon,
                  },
                });
              }
            }}
          />
        ))}
      <MeasureMapTool
        setMeasureDrawing={e => {
          setCircleDrawing(false);
          setMeasureDrawing(e);
        }}
        measureDrawing={measureDrawing}
      />
      <CircleMapTool
        setCircleDrawing={e => {
          setMeasureDrawing(false);
          setCircleDrawing(e);
        }}
        circleDrawing={circleDrawing}
      />
    </>
  );
};

export default MapInnerContainer;
