import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';

const ModalInner = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const InfoText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const DeleteVesselPortcallToleranceModal = ({ vessel, closeModal, closeModalAndUpdate }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleOk = async () => {
    const request = await apiCall('delete', 'vessel-port-call-tolerances', { imo: vessel.imo });

    if (request && request.status === 200) {
      closeModalAndUpdate();
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal
      title={t('Delete tolerances for ' + vessel.vessel_name + ' (' + vessel.imo + ')')}
      open={true}
      footer={null}
      onCancel={handleCancel}
    >
      <ModalInner>
        <IconContainer>
          <Icon type="remove-illustration"></Icon>
        </IconContainer>
        <InfoText>{t('Are you sure you want to remove port call tolerances?')}</InfoText>

        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={handleCancel}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight red onClick={handleOk}>
            {t('Delete')}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default DeleteVesselPortcallToleranceModal;
