import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Table from 'antd/es/table';
import Spin from 'antd/es/spin';
import PortCallInvoiceRowShipInfo from './PortCallInvoiceRowShipInfo';
import InvoiceModal from './InvoiceModal';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { InvoicingContext } from '../../context/InvoicingContext';
import PortCallInvoiceRowEditableColumn from './PortCallInvoiceRowEditableColumn';
import Button from '../ui/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  overflow: auto;
`;

const ShowInvoiceButton = styled.div`
  border: 1px solid ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.secondary};
  width: 125px;
  height: 25px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
`;

const StyledTable = styled(Table)`
  && {
    width: 100%;
    margin: 0 0 ${({ theme }) => theme.sizing.gap};
    th,
    td {
      padding: ${({ theme }) => theme.sizing.gap};
      background: ${({ theme }) => theme.color.white};
      border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
      @media print {
        border-bottom: 1pt solid black;
      }
    }
    thead {
      tr {
        th {
          white-space: normal;
          word-wrap: break-word;
          padding: 12px 1rem;
          border-bottom: 1px solid transparent;
        }
      }
    }
    th {
      font-size: ${({ theme }) => theme.text.small};
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      padding: ${({ theme }) => theme.sizing.gap_small} ${({ theme }) => theme.sizing.gap};
    }
    table,
    .ant-table {
      background: none;
      white-space: nowrap;
      th,
      td {
        background: none;
      }
    }
    .ant-table-wrapper + .ant-table-wrapper {
      margin-top: ${({ theme }) => theme.sizing.gap_medium};
    }
  }
`;

const ExportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const AddItemButton = styled.div`
  background-color: white;
  height: 32px;
  min-width: 120px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  margin-left: 8px;

  color: ${props => props.disabled && '#a8a8a8'};
  background-color: ${props => props.disabled && '#f5f5f5'};
  cursor: ${props => props.disabled && 'default'};

  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }

  i {
    margin-top: -2px;
    margin-right: 6px;
    margin-left: -4px;
  }
`;

const PortCallInvoices = ({ fullView = false }) => {
  const { namespace } = useContext(UserContext);
  const { portCallData, isLoading, putPortcallInvoiceNotes, savePortcallInvoiceData } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState(false);
  const [changedData, setChangedData] = useState({});

  const columns = [
    {
      title: 'Vessel',
      dataIndex: 'name',
      key: 'name',
      render: (cell, row) => <PortCallInvoiceRowShipInfo row={row} />,
      sorter: (a, b) => a.port_call_data?.vessel_name.localeCompare(b.port_call_data?.vessel_name),
    },
    {
      title: 'Agent',
      dataIndex: ['port_call_data', 'agent'],
      key: 'agent',
      sorter: (a, b) => a.port_call_data?.agent.localeCompare(b.port_call_data?.agent),
    },
    {
      title: 'ATA',
      dataIndex: ['port_call_data', 'ata'],
      key: 'ata',
      render: ata => dayjs(ata).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) => dayjs(a.port_call_data?.ata).valueOf() - dayjs(b.port_call_data?.ata).valueOf(),
    },
    {
      title: 'ATD',
      dataIndex: ['port_call_data', 'atd'],
      key: 'atd',
      defaultSortOrder: 'descend',
      render: atd => dayjs(atd).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) => dayjs(a.port_call_data?.atd).valueOf() - dayjs(b.port_call_data?.atd).valueOf(),
    },
    {
      title: 'Price (€)',
      dataIndex: ['price_data', 'total'],
      key: 'price',
      render: price => price?.toFixed(2),
      sorter: (a, b) => a.price_data?.total - b.price_data?.total,
    },
  ];

  const showInvoiceColumn = [
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (cell, row) => (
        <ShowInvoiceButton
          onClick={() => {
            setIsModalVisible(true);
            setInvoiceData(row);
          }}
        >
          {t('Show Invoice')}
        </ShowInvoiceButton>
      ),
    },
  ];

  const updateData = (id, type, value, cell) => {
    let newData = changedData;

    newData = {
      ...newData,
      [id]: {
        ...newData[id],
        [type]: value,
      },
    };

    if ((!cell && !value) || (value === cell && newData[id][type])) {
      delete newData[id][type];
      if (Object.keys(newData[id]).length === 0) {
        delete newData[id];
      }
    }

    setChangedData(newData);
  };

  const onSave = async id => {
    const newData = changedData;
    const data = newData[id];
    delete newData[id];
    setChangedData(newData);

    if (data) {
      if (data.notes !== undefined) {
        await putPortcallInvoiceNotes(id, data.notes);
      }
      if (data.cars !== undefined) {
        await savePortcallInvoiceData(id, data);
      }
    }
  };

  const extraColumns = [
    {
      title: 'Net Tonnage',
      dataIndex: ['port_call_data', 'net_tonnage'],
      key: 'net_tonnage',
      sorter: (a, b) => a.port_call_data?.net_tonnage - b.port_call_data?.net_tonnage,
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoice_number - b.invoice_number,
    },
    {
      title: 'Vessel call',
      dataIndex: ['price_data', 'base_fee'],
      key: 'baseFee',
      render: baseFee => baseFee?.toFixed(2),
      sorter: (a, b) => a.price_data?.base_fee - b.price_data?.base_fee,
    },
    {
      title: 'Mooring fee',
      dataIndex: ['price_data', 'mooring_fee'],
      key: 'mooringFee',
      render: mooringFee => mooringFee?.toFixed(2),
      sorter: (a, b) => a.price_data?.mooring_fee - b.price_data?.mooring_fee,
    },
    {
      title: 'Unmooring fee',
      dataIndex: ['price_data', 'unmooring_fee'],
      key: 'unmooringFee',
      render: unmooringFee => unmooringFee?.toFixed(2),
      sorter: (a, b) => a.price_data?.unmooring_fee - b.price_data?.unmooring_fee,
    },
    {
      title: 'Oil waste fee',
      dataIndex: ['price_data', 'oil_waste_fee'],
      key: 'oilWasteFee',
      render: oilWasteFee => oilWasteFee?.toFixed(2),
      sorter: (a, b) => a.price_data?.oil_waste_fee - b.price_data?.oil_waste_fee,
    },
    {
      title: 'Household waste fee',
      dataIndex: ['price_data', 'household_waste_fee'],
      key: 'houseHoldWasteFee',
      render: houseHoldWasteFee => houseHoldWasteFee?.toFixed(2),
      sorter: (a, b) => a.price_data?.household_waste_fee - b.price_data?.household_waste_fee,
    },
    {
      title: 'Water fee',
      dataIndex: ['price_data', 'water_fee'],
      key: 'waterFee',
      render: waterFee => (waterFee ? waterFee?.toFixed(2) : null),
      sorter: (a, b) => a.price_data?.water_fee - b.price_data?.water_fee,
    },
    {
      title: 'Cars',
      dataIndex: ['data', 'cars'],
      key: 'cars',
      render: (cell, row) => (
        <PortCallInvoiceRowEditableColumn cell={cell} row={row} type={'cars'} updateData={updateData} numeric={true} />
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (cell, row) => (
        <PortCallInvoiceRowEditableColumn cell={cell} row={row} type={'notes'} updateData={updateData} />
      ),
    },
    {
      key: 'save',
      render: (cell, row) => {
        return (
          <Button link disabled={changedData[row.port_call_id] ? false : true} onClick={() => onSave(row.port_call_id)}>
            {t('Save')}
          </Button>
        );
      },
    },
  ];

  const convertToCSV = arr => {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map(item => {
        return Object.values(item)
          .join(';')
          .toString();
      })
      .join('\n');
  };

  const exportButtonClick = async () => {
    const pcdata = portCallData.map(pc => {
      return {
        Vessel: pc?.port_call_data?.vessel_name,
        Agent: pc?.port_call_data?.agent,
        Ata: dayjs(pc?.port_call_data?.ata).format('DD.MM.YYYY HH:mm'),
        Atd: dayjs(pc?.port_call_data?.atd).format('DD.MM.YYYY HH:mm'),
        Price: pc?.price_data?.total
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Net Tonnage': pc?.port_call_data?.net_tonnage,
        'Invoice Number': pc?.invoice_number,
        'Vessel Call': pc?.price_data?.base_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Mooring Fee': pc?.price_data?.mooring_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Unmooring Fee': pc?.price_data?.unmooring_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Oil waste fee': pc?.price_data?.oil_waste_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Household waste fee': pc?.price_data?.household_waste_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        'Water fee': pc?.price_data?.water_fee
          ?.toFixed(2)
          ?.toString()
          .replace('.', ','),
        Cars: pc?.data?.cars,
        Notes: pc?.notes,
      };
    });

    let csv = convertToCSV(pcdata);
    const BOM = '\uFEFF';
    csv = BOM + csv;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = `invoices-${dayjs().format('Y-MM-DDTHH.mm.ss')}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Container>
      {fullView && (
        <ExportContainer>
          <AddItemButton onClick={exportButtonClick}>{t('Export into Excel')}</AddItemButton>
        </ExportContainer>
      )}
      <StyledTable
        rowKey={record => record.port_call_id}
        columns={fullView ? [...columns, ...extraColumns] : [...columns, ...showInvoiceColumn]}
        dataSource={portCallData}
        loading={{
          spinning: isLoading,
          indicator: <Spin size="large" />,
        }}
      />
      <InvoiceModal open={isModalVisible} setIsModalVisible={setIsModalVisible} invoice={invoiceData} />
    </Container>
  );
};

export default PortCallInvoices;
