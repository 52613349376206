import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import ServiceOrderLines from './ServiceOrderLines';
import Input from '../../components/ui/Input';
import DateComponent from '../../components/ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Heading1 = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Heading2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  text-transform: uppercase;
  font-weight: 700;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Success1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.success};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Failure1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.warning};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const DeliveryCell = styled.div`
  white-space: nowrap;
`;

const ColorBackground = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.white};
`;

const WarningBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning};
`;

const ServiceOrdersList = styled.div`
  .ant-table {
    table {
      padding-bottom: 150px;
    }
    .ant-table {
      table {
        padding-bottom: 0 !important;
      }
    }
    .ant-table-content {
      overflow: visible;
    }
  }
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service-order';

const ServiceOrders = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [serviceOrdersData, setserviceOrdersData] = useState(null);
  const [serviceOrdersError, setserviceOrdersError] = useState(null);
  const [serviceOrdersLoading, setserviceOrdersLoading] = useState(null);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initServiceOrder = {
    id: '',
    port_call_id: '',
    imo: '',
    mmsi: '',
    vessel_name: '',
    order_lines: [],
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
  };

  const [serviceOrder, setServiceOrder] = useState(initServiceOrder);

  const initModal = {
    visible: false,
    confirmLoading: false,
    'service-order': {
      id: '',
      port_call_id: '',
      imo: '',
      mmsi: '',
      vessel_name: '',
      order_lines: [],
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const isSame = (oldItem, newItem) =>
    ['limit', 'offset', 'sort', 'search'].find(prop => oldItem[prop] !== newItem[prop]) == null;

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'id DESC',
    search: '',
  };

  const serviceOrdersChanged = useCallback(data => {
    if (data?.results) {
      setserviceOrdersData(data.results);
    } else {
      setserviceOrdersData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const {
    loading: serviceOrdersFetchLoading,
    error: serviceOrdersFetchError,
    fetchData: serviceOrdersFetchData,
  } = useApi('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, newParams, serviceOrdersChanged, false);

  const {
    loading: serviceOrdersSearchLoading,
    error: serviceOrdersSearchError,
    fetchData: serviceOrdersFetchSearchData,
  } = useApi('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/search`, {}, serviceOrdersChanged, false);

  const fetchData = useCallback(
    params => {
      const { search, ...rest } = params;
      if (search) {
        const [order_by_field, order_by_order] = params.sort.split(' ');
        serviceOrdersFetchSearchData(false, {
          query: {
            text: params.search,
          },
          pagination: {
            limit: params.limit,
            offset: params.offset,
          },
          order_by: [
            {
              field: order_by_field,
              order: order_by_order ? order_by_order : 'ASC',
            },
          ],
        });
      } else {
        serviceOrdersFetchData(false, rest);
      }
    },
    [serviceOrdersFetchData, serviceOrdersFetchSearchData]
  );

  useEffect(() => {
    fetchData(newParams);
  }, [fetchData, newParams]);

  useEffect(() => {
    setserviceOrdersError(serviceOrdersFetchError);
  }, [serviceOrdersFetchError]);

  useEffect(() => {
    setserviceOrdersError(serviceOrdersSearchError);
  }, [serviceOrdersSearchError]);

  useEffect(() => {
    setserviceOrdersLoading(serviceOrdersFetchLoading);
  }, [serviceOrdersFetchLoading]);

  useEffect(() => {
    setserviceOrdersLoading(serviceOrdersSearchLoading);
  }, [serviceOrdersSearchLoading]);

  if (serviceOrdersError) {
    message.error(serviceOrdersError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serviceOrders =
    serviceOrdersLoading || !serviceOrdersData?.['service-orders'] ? [] : serviceOrdersData['service-orders'];

  const { offset, total } =
    serviceOrdersError || !serviceOrdersData ? { offset: 0, total: 0 } : serviceOrdersData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const {
        data: { 'service-order': serviceOrder },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
      setServiceOrder({ ...serviceOrder });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleSave = async values => {
    const { port_call_id, imo, mmsi, vessel_name, order_lines } = values;
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
      service_order: {
        port_call_id: port_call_id,
        imo: parseInt(imo),
        mmsi: parseInt(mmsi),
        vessel_name: vessel_name,
        order_lines: order_lines,
      },
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceOrder(initServiceOrder);
      handleSetMultiple(initServiceOrder);
      history.replace(location.pathname);
      await fetchData(newParams);
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setServiceOrder(initServiceOrder);
    handleSetMultiple(initServiceOrder);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service_order: {
          id: serviceOrder.id,
          port_call_id: serviceOrder.port_call_id,
          imo: parseInt(serviceOrder.imo),
          mmsi: parseInt(serviceOrder.mmsi),
          vessel_name: serviceOrder.vessel_name,
          //order_lines: serviceOrder.order_lines,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setServiceOrder(initServiceOrder);
    handleSetMultiple(initServiceOrder);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setServiceOrder(initServiceOrder);
    handleSetMultiple(initServiceOrder);
  };

  const handleModalChange = e => {
    const { name, value } = e.target;
    setServiceOrder({ ...serviceOrder, [name]: value });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setServiceOrder({ ...serviceOrder, [name]: value });
  };

  const handleCancelOrder = async entryIdentifier => {
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/cancel/${entryIdentifier}`, {});

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceOrder(initServiceOrder);
      handleSetMultiple(initServiceOrder);
      history.replace(location.pathname);
      fetchData(newParams);
    }
    showActions(false);
  };

  const fields = ['port_call_id', 'imo', 'mmsi', 'vessel_name', 'order_lines'];
  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {
    order_lines: [],
  });

  useEffect(() => {
    let isValid =
      serviceOrder?.port_call_id?.length > 0 &&
      serviceOrder?.imo > 0 &&
      serviceOrder?.mmsi > 0 &&
      serviceOrder?.vessel_name?.length > 0 /*&&
      serviceOrder?.order_lines?.length > 0*/
        ? true
        : false;
    /*
    if (
      serviceOrder?.order_lines?.length > 0 &&
      !serviceOrder?.order_lines?.every(serviceOrderLine => {
        return serviceOrderLine?.service_id?.length > 0 &&
          dayjs(serviceOrderLine?.requested_delivery_time, dayjs.ISO_8601).isValid() &&
          serviceOrderLine?.quantity?.length > 0 &&
          serviceOrderLine?.when?.length > 0
          ? true
          : false;
      })
    ) {
      isValid = false;
    }*/
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, serviceOrder]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Vessel'),
      key: 'vessel',
      sortableKey: 'vessel_name',
      render: record => {
        return (
          <>
            <Heading1>{record?.vessel_name}</Heading1>
            <Content1>
              {t('IMO')} {record?.imo}
            </Content1>
            <Content1>
              {t('MMSI')} {record?.mmsi}
            </Content1>
            <div>
              <Heading2>{t('Port call id:')}</Heading2>
              <Content2>{record?.port_call_id}</Content2>
            </div>
          </>
        );
      },
    },
    {
      title: t('Order Lines'),
      key: 'order_lines',
      render: record => {
        return (
          <>
            <Heading1>{t('Count: {{orderLines}}', { orderLines: record?.order_lines?.length || 0 })}</Heading1>
            {record?.order_lines?.map((orderLine, index) => (
              <Content2 key={`${orderLine.service_provider_name}-${orderLine.service_type_name}-${index}`}>
                {orderLine.service_provider_name}: {orderLine.service_type_name}
              </Content2>
            ))}
          </>
        );
      },
    },
    {
      title: t('Acknowledgement'),
      key: 'ack',
      render: record => {
        const { unAnswered, accepted, rejected } = record?.order_lines
          ? record.order_lines.reduce(
            (acc, curr) => {
              acc.unAnswered += !curr.ack ? 1 : 0;
              acc.accepted += curr.ack?.status === 'accepted' ? 1 : 0;
              acc.rejected += curr.ack?.status === 'rejected' ? 1 : 0;
              return acc;
            },
            {
              unAnswered: 0,
              accepted: 0,
              rejected: 0,
            }
          )
          : {
              unAnswered: 0,
              accepted: 0,
              rejected: 0,
            };

        return (
          <>
            <Content1>{t('Unanswered: {{unAnswered}}', { unAnswered })}</Content1>
            <Success1>{t('Accepted: {{accepted}}', { accepted })}</Success1>
            <Failure1>{t('Rejected: {{rejected}}', { rejected })}</Failure1>
          </>
        );
      },
    },
    {
      title: t('Deliveries'),
      key: 'deliveries',
      render: record => {
        const { deliveries, pending, inProgress, done } = record?.order_lines
          ? record.order_lines.reduce(
            (acc, curr) => {
              acc.deliveries += curr.deliveries?.filter?.(item => item.status !== 'cancelled').length || 0;
              acc.pending += curr.deliveries?.filter?.(item => item.status === 'pending').length || 0;
              acc.inProgress += curr.deliveries?.filter?.(item => item.status === 'in_progress').length || 0;
              acc.done += curr.deliveries?.filter?.(item => item.status === 'done').length || 0;
              return acc;
            },
            {
              deliveries: 0,
              pending: 0,
              inProgress: 0,
              done: 0,
            }
          )
          : {
              deliveries: 0,
              pending: 0,
              inProgress: 0,
              done: 0,
            };

        return (
          <DeliveryCell>
            <Heading1>{t('Count: {{deliveries}}', { deliveries })}</Heading1>
            <Content2>{t('Pending: {{pending}}', { pending })}</Content2>
            <Content2>{t('In progress: {{inProgress}}', { inProgress })}</Content2>
            <Success1>{t('Done: {{done}}', { done })}</Success1>
          </DeliveryCell>
        );
      },
    },
    {
      title: t('Status'),
      key: 'status',
      render: record => {
        const cancelled =
          record?.order_lines?.length > 0 ? record?.order_lines.every(item => item.is_cancelled) : false;
        return cancelled ? (
          <Tooltip placement="top" title={t('Are all order lines cancelled?')}>
            <WarningBackground>{t('Cancelled')}</WarningBackground>
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title={t('Are all deliveries of all order lines done (not inluding cancelled order lines or deliveries)?')}
          >
            {record?.done ? (
              <div>
                <Icon type="check-circle" color="#007AFF" fill="none" />
              </div>
            ) : (
              <div>
                <Icon type="x-circle" color="#D0011C" fill="none" />
              </div>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sortableKey: 'updated_at',
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Cancel all order lines of service order {{id}}?', { id: record.id })}
          onConfirm={() => handleCancelOrder(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="close" />
              {t('Cancel')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete service order {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-3"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: serviceOrdersLoading,
      text: t('Add service order'),
      icon: 'plus',
    },
  ];

  return (
    <ServiceOrdersList>
      <Modal
        title={t('Edit service orders')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="port_call_id"
              label={t('Port call id')}
              value={serviceOrder?.port_call_id || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="imo"
              label={t('IMO')}
              value={serviceOrder?.imo || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              type="number"
              step="1"
              pattern="\d+"
              required
            />
            <Input
              name="mmsi"
              label={t('MMSI')}
              value={serviceOrder?.mmsi || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              type="number"
              step="1"
              pattern="\d+"
              required
            />
            <Input
              name="vessel_name"
              label={t('Vessel name')}
              value={serviceOrder?.vessel_name || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service order')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'order_lines') {
                return null;
              } else if (field === 'imo') {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="number"
                    step="1"
                    pattern="\d+"
                    onChange={handleAddNewChange}
                  />
                );
              } else if (field === 'mmsi') {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="number"
                    step="1"
                    pattern="\d+"
                    onChange={handleAddNewChange}
                  />
                );
              }
              return (
                <Input
                  label={field.replace(/_/g, ' ')}
                  key={field}
                  name={field}
                  field={field}
                  value={values[field]}
                  type="text"
                  onChange={handleAddNewChange}
                />
              );
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service order')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={serviceOrders}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={serviceOrdersLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={offset}
        total={total}
        searchPlaceHolder={t('Search by name, email or phone')}
        additionalButtons={additionalButtons}
        noUrlUpdate={true}
        expandedRowRender={(record, index) => (
          <ServiceOrderLines
            serviceOrderId={serviceOrders[index]?.id}
            orderLines={record?.order_lines}
            newParams={newParams}
            fetchData={fetchData}
            setNewParams={params => setNewParams(previous => (isSame(previous, params) ? previous : params))}
            loading={serviceOrdersLoading}
            vesselName={serviceOrders[index]?.vessel_name}
          />
        )}
      />
    </ServiceOrdersList>
  );
};

export default ServiceOrders;
