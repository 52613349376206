import React from 'react';
import styled from 'styled-components';

import ShipBadge from './ShipBadge';
import Transition from './Transition';

const ShipTags = styled.div`
  margin-bottom: 2px;
  margin-top: 2px;
`;

const ShipBadges = ({ badges, transitions }) => {
  return (
    badges && (
      <ShipTags>
        {badges.map((tag, key) => (
          <ShipBadge tag={tag} key={key} />
        ))}

        <Transition transitions={transitions} />
      </ShipTags>
    )
  );
};

export default ShipBadges;
