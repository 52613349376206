import React from 'react';
import styled from 'styled-components';

import { iterateTimes } from './utility/calendar';
import TimeIndicatorLine from './TimeIndicatorLine';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const Column = styled.div`
  position: absolute;
  border-left: 1px solid #ebebeb;
  z-index: 30;
`;

const ColumnContainer = styled.div`
  position: relative;
`;

const Columns = ({
  getLeftOffsetFromDate,
  canvasTimeStart,
  canvasTimeEnd,
  height,
  indicatorLine,
  setIndicatorLine,
  canvasWidth,
}) => {
  let lines = [];
  let currentInterval = null;

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  let scrollComponent = document.querySelector('#scroll-element');
  let scrollComponentHeight = 0;

  if (scrollComponent) {
    scrollComponentHeight = scrollComponent.getBoundingClientRect().height;
  }

  const range = dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asDays();

  iterateTimes(canvasTimeStart, canvasTimeEnd, range, (time, nextTime) => {
    const left = getLeftOffsetFromDate(time.valueOf());
    const right = getLeftOffsetFromDate(nextTime.valueOf());

    if (!currentInterval) {
      currentInterval =
        (indicatorLine.time.isBefore(nextTime) && indicatorLine.time.isAfter(time)) || indicatorLine.time.isSame(time)
          ? { left, width: right - left }
          : null;
    }

    lines.push(
      <Column
        data-testid={`column-${time.valueOf()}`}
        key={`column-${time.valueOf()}`}
        style={{
          pointerEvents: 'none',
          top: '0px',
          left: `${left}px`,
          width: `${right - left}px`,
          height: `${Math.max(height || 0, scrollComponentHeight, outerComponentHeight)}px`,
          zIndex: 40,
        }}
      />
    );
  });

  return (
    <div>
      <TimeIndicatorLine
        indicatorLine={indicatorLine}
        setIndicatorLine={setIndicatorLine}
        canvasWidth={canvasWidth}
        canvasTimeStart={canvasTimeStart}
        canvasTimeEnd={canvasTimeEnd}
      />

      <ColumnContainer>{lines}</ColumnContainer>
    </div>
  );
};

export default Columns;
