import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { UserContext } from '../../context/UserContext';
import { InvoicingContext } from '../../context/InvoicingContext';

const Content = styled.div`
  margin-bottom: 1em;
  padding: 10px;
`;

const InvoiceContainer = styled.div`
  padding: 40px 0 12px 0;
  width: 100%;
  height: 900px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: row;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_medium};
  margin: 20px;
`;

const TopAreaContainer = styled.div`
  display: flex;
  justify-content: row;
  height: 300px;
  padding: 20px;
`;

const MiddleAreaContainer = styled.div`
  display: flex;
  justify-content: row;
  height: 250px;
  padding: 20px;
`;

const BottomAreaContainer = styled.div`
  height: 300px;
  padding: 30px 100px;
  text-align: right;
`;

const TopArea1 = styled.div`
  width: 100%;
  height: 100%;
`;

const TopArea2 = styled.div`
  width: 100%;
  height: 100%;
`;

const DescriptionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
  margin-bottom: ${({ theme }) => theme.sizing.gap_medium};
`;

const Value = styled.div`
  width: 50%;
`;

const Description = styled.div`
  width: 50%;
`;

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th`
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_medium};
`;

const Tr = styled.tr`
  width: 100%;
`;

const DUE_TIME = 21;

const InvoiceModal = ({ open, setIsModalVisible, invoice }) => {
  const { namespace } = useContext(UserContext);
  const { contractData } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={800} footer={null}>
      <Content>
        {invoice.port_call_data && (
          <InvoiceContainer>
            <HeaderContainer>
              <div style={{ width: '100%' }}>{invoice.port_call_data.vessel_name}</div>
              <div style={{ width: '100%' }}>{t('INVOICE PREVIEW')}</div>
            </HeaderContainer>
            <TopAreaContainer>
              <TopArea1>
                <DescriptionList>
                  <Description>{invoice.port_call_data?.agent}</Description>
                </DescriptionList>
              </TopArea1>
              <TopArea2>
                <DescriptionList>
                  <Description>{t('Date')}:</Description>
                  <Value>{dayjs(invoice.created_at).format('DD.MM.YYYY HH:mm')}</Value>
                  <Description>{t('Invoice number')}:</Description>
                  <Value>{invoice.invoice_number || invoice.invoice_id}</Value>
                  <Description>{t('Terms of payment')}:</Description>
                  <Value>{`${DUE_TIME} days net`}</Value>
                  <Description>{t('Due date')}:</Description>
                  <Value>
                    {dayjs(invoice.created_at)
                      .add(5, 'd')
                      .format('DD.MM.YYYY')}
                  </Value>
                  <Description>{t('Interest rate')}:</Description>
                  <Value>0%</Value>
                  <Description>{t('Currency')}:</Description>
                  <Value>EUR</Value>
                </DescriptionList>
              </TopArea2>
            </TopAreaContainer>
            <MiddleAreaContainer>
              <Table>
                <thead>
                  <Tr>
                    <Th>{t('Product')}</Th>
                    <Th>{t('Product code')}</Th>
                    <Th>{t('Amount')}</Th>
                    <Th>{t('Unit Price')}</Th>
                    <Th>{t('Price')}</Th>
                    <Th>{t('Vat-%')}</Th>
                  </Tr>
                </thead>
                <tbody>
                  <Tr>
                    <td>{`VESSEL CALL, ${invoice?.port_call_data.vessel_name} ${invoice?.port_call_data.net_tonnage}`}</td>
                    <td>{t('DUES ON VESSEL')}</td>
                    <td>{invoice.port_call_data.net_tonnage}</td>
                    <td>{contractData.base_fee.toFixed(2)}</td>
                    <td>{invoice.price_data.base_fee_total.toFixed(2)}</td>
                    <td>0</td>
                  </Tr>
                  <Tr>
                    <td>{t('Mooring fee')}</td>
                    <td>{dayjs(invoice.port_call_data.ata).format('DD.MM.YYYY HH:mm')}</td>
                    <td>1</td>
                    <td>{invoice.price_data.mooring_fee.toFixed(2)}</td>
                    <td>{invoice.price_data.mooring_fee.toFixed(2)}</td>
                    <td>0</td>
                  </Tr>
                  <Tr>
                    <td>{t('Unmooring fee')}</td>
                    <td>{dayjs(invoice.port_call_data.atd).format('DD.MM.YYYY HH:mm')}</td>
                    <td>1</td>
                    <td>{invoice.price_data.unmooring_fee.toFixed(2)}</td>
                    <td>{invoice.price_data.unmooring_fee.toFixed(2)}</td>
                    <td>0</td>
                  </Tr>
                  {!!invoice.price_data.water_fee && (
                    <Tr>
                      <td>{t('Water charge')}</td>
                      <td />
                      <td>{invoice.data?.water_quantity}</td>
                      <td>{contractData.water_fee.toFixed(2)}</td>
                      <td>{invoice.price_data.water_fee.toFixed(2)}</td>
                      <td>0</td>
                    </Tr>
                  )}
                  {!!invoice.price_data.oil_waste_fee && (
                    <Tr>
                      <td>{t('Oil waste')}</td>
                      <td />
                      <td>{invoice.port_call_data.net_tonnage}</td>
                      <td>{contractData.oil_waste_fee.toFixed(2)}</td>
                      <td>{invoice.price_data.oil_waste_fee.toFixed(2)}</td>
                      <td>0</td>
                    </Tr>
                  )}
                  {!!invoice.price_data.household_waste_fee && (
                    <Tr>
                      <td>{t('Household and other waste')}</td>
                      <td />
                      <td>{invoice.port_call_data.net_tonnage}</td>
                      <td>{contractData.household_waste_fee.toFixed(2)}</td>
                      <td>{invoice.price_data.household_waste_fee.toFixed(2)}</td>
                      <td>0</td>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </MiddleAreaContainer>
            <BottomAreaContainer>
              <DescriptionList>
                <Description>{t('Total Excluding VAT')}:</Description>
                <Value>{invoice.price_data.total.toFixed(2)}</Value>
                <Description style={{ fontWeight: 'bold' }}>{t('Invoice total')}:</Description>
                <Value style={{ fontWeight: 'bold' }}>{invoice.price_data.total.toFixed(2)}</Value>
              </DescriptionList>
            </BottomAreaContainer>
          </InvoiceContainer>
        )}
      </Content>
    </Modal>
  );
};

export default InvoiceModal;
