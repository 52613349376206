import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import Tooltip from 'antd/es/tooltip';

const HeaderText = styled.div`
  display: flex;
`;

const Template = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 6px 10px;
  border-bottom: 1px solid ${props => (props.open ? '#f4f4f4' : '#e0e0e0')};
  align-items: center;
  cursor: pointer;

  button {
    height: 28px;
    padding-top: 5px;
    margin-bottom: 0px;
  }

  i {
    margin-top: -1px;
    color: #a0a0a0;
    width: 30px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const TemplateInfo = styled.div`
  padding: 6px 10px 6px 40px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
`;

const Row = styled.div`
  display: flex;
  margin: 3px 0;
`;

const Label = styled.div`
  margin-right: 6px;
`;

const Value = styled.div`
  font-weight: 600;
`;

const Rows = styled.div`
  display: inline-block;
`;

const AsteriskContainer = styled.span`
  color: ${props => props.theme.color.dark_grey};

  svg {
    height: 8px;
    width: 8px;
    margin-top: -2px;
  }
`;

const IconContainer = styled.span`
  i {
    color: ${props => props.theme.color.secondary} !important;
  }

  svg {
    margin-top: -2px;
    height: 18px;
    width: 18px;
  }
`;

const TemplateChoiceModalTemplate = ({ template, chooseTemplate, allowCargo }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [open, setOpen] = useState(false);

  const automaticCargo = template.template_rows.some(r => r.product_code === 'POLO_AUTOMATIC_CARGO');

  return (
    <>
      <Template key={template.id} onClick={() => setOpen(!open)} open={open}>
        <HeaderText>
          <Icon type={'plus'} />
          {template.name}

          {automaticCargo && !allowCargo && (
            <Tooltip
              placement="top"
              title={t('Automatic cargo will be ignored.')}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              <IconContainer style={{ marginLeft: '6px' }}>
                <Icon type="info" />
              </IconContainer>
            </Tooltip>
          )}
        </HeaderText>
        <ButtonLight onClick={() => chooseTemplate(template)}>{t('Select')}</ButtonLight>
      </Template>
      {open && (
        <TemplateInfo>
          <Row>
            <Label>{t('Customer')}:</Label>
            <Value>{template.customer?.name || '-'}</Value>
          </Row>
          <Row>
            <Label>{t('Currency')}:</Label>
            <Value>{template.currency_code || '-'}</Value>
          </Row>
          <Row>
            <Label>{t('Tax class name')}:</Label>
            <Value>{template.tax_class_name || '-'}</Value>
          </Row>
          <Row>
            <Label>{t('Template products')}:</Label>
            <Rows>
              {template.template_rows.map((r, i) => {
                return (
                  <Rows key={i}>
                    <Value>
                      {r.product.name} ({r.product.code})
                    </Value>
                    {r.modifiers.map((m, i) => {
                      return (
                        <Rows key={i}>
                          <Value style={{ marginLeft: '24px' }}>
                            <AsteriskContainer>
                              <Icon type="asterisk" />
                            </AsteriskContainer>
                            {m.modifier.name} ({m.modifier.code})
                          </Value>
                        </Rows>
                      );
                    })}
                  </Rows>
                );
              })}
            </Rows>
          </Row>
        </TemplateInfo>
      )}
    </>
  );
};

export default TemplateChoiceModalTemplate;
