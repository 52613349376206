import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';

const Container = styled.div`
  margin-bottom: 6px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  height: 124px;

  svg {
    margin-left: -60px;
    width: 130px;
  }
`;

const ConfirmationText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-bottom: 18px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(ButtonLight)`
  margin-bottom: 0;
  padding: 0;
  height: 26px;
  line-height: 22px;
  min-width: 80px;
`;

const RemovePortcall = ({ setAddModalOpen, setEditModalOpen, setRemoveModalOpen, portcall }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const sendData = async () => {
    apiCall('post', 'fleet/cancel-manual-portcall', {
      portcall: {
        vessel_imo: portcall.imo,
        port_call_id: portcall.port_call_id,
        locode: portcall.locode,
        eta: portcall.eta,
        etd: portcall.etd,
      },
    });

    closeContainer();
  };

  const closeContainer = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setRemoveModalOpen(false);
  };

  return (
    <Container id="add-edit-portcall">
      <IconContainer>
        <Icon type="remove-illustration"></Icon>
      </IconContainer>
      <ConfirmationText>{t('Are you sure you want to remove the portcall?')}</ConfirmationText>
      <Row>
        <StyledButton cancel style={{ marginRight: '6px' }} onClick={closeContainer}>
          {t('Close')}
        </StyledButton>
        <StyledButton red onClick={sendData}>
          {t('Remove')}
        </StyledButton>
      </Row>
    </Container>
  );
};

export default RemovePortcall;
