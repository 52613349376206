import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';
import ColorIndicator from './ColorIndicator';
import AddOrEditReservationModal from './modals/AddOrEditReservationModal';
import CommittingActions from './CommittingActions';
import Icon from '../ui/Icon';
import {
  color_planned,
  color_not_planned,
  color_reserved,
  color_uncommitted,
  color_updated,
} from '../commonCalendar/utility/constants';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';
import AddOrEditBerthBlockModal from './modals/AddOrEditBerthBlockModal';
import ColorIndicatorOverflow from './ColorIndicatorOverflow';
import BptLayersComponent from './BptLayersComponent';

const TopPanel = styled.div`
  height: 46px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.noManage ? 'flex-start' : 'space-between')};

  @media (max-width: 1140px) {
    display: ${props => props.main && 'none'};
    height: ${props => !props.main && 'unset'};
    flex-wrap: ${props => !props.main && 'wrap'};
    justify-content: ${props => !props.main && 'flex-start'};
  }
`;

const CalendarButtons = styled.div`
  display: flex;
  height: 47px;
  margin-top: 14px;
  margin-left: 6px;
  position: relative;
  margin-right: ${props => (props.noManage ? '24px' : '6px')};

  @media (max-width: 1400px) {
    margin-right: ${props => (props.noManage ? '24px' : '6px')};
    margin-left: 6px;
  }

  @media (max-width: 1140px) {
    margin-top: ${props => props.noMarginBottom && '-5px'};
    margin-top: ${props => props.noMarginTop && '0px'};
    margin-bottom: ${props => !props.noMarginBottom && '24px'};
    margin-right: 12px;
  }
`;

const ColorColumn = styled.div`
  margin-right: 15px;

  @media (max-width: 1400px) {
    margin-right: 5px;
  }
`;

const ColorIndicators = styled.div`
  display: flex;
  margin-right: ${props => (props.noManage ? '24px' : '6px')};
  margin-left: 6px;
  margin-top: ${props => props.moveTop && '-8px'};

  @media (max-width: 1400px) {
    margin-right: ${props => (props.noManage ? '24px' : '6px')};
    margin-left: 6px;
  }

  @media (max-width: 1140px) {
    margin-bottom: 24px;
    margin-right: 12px;
  }
`;

const CalendarTypeButtons = styled.div`
  margin-right: ${props => (props.noManage ? '24px' : '6px')};
  margin-left: 6px;

  @media (max-width: 1400px) {
    margin-right: ${props => (props.noManage ? '24px' : '0px')};
    margin-left: 6px;
  }
`;

const PredictiveBerthPlanningSwitch = styled.div`
  margin-right: ${props => (props.noManage ? '24px' : '6px')};
  margin-left: 6px;
  display: flex;
  color: #4a4a4a;

  @media (max-width: 1400px) {
    margin-right: ${props => (props.noManage ? '24px' : '0px')};
    margin-left: 6px;
  }

  @media (max-width: 1140px) {
    margin-bottom: 24px;
    margin-right: 12px;
    margin-top: -14px;
  }
`;

const ButtonVersion = styled(ButtonLight)`
  font-size: 13px;
  min-width: 60px;
`;

const Label = styled.div`
  padding-left: 6px;
  line-height: 30px;
  font-size: 13px;
  white-space: nowrap;
  cursor: default;
`;

const Value = styled.div`
  text-align: left;
  padding: 4px 0;
`;

const IconContainerCrane = styled.div`
  margin: 3px 4px 0 10px;
`;

const Loader = styled.div`
  margin-right: 14px;
  margin-top: 6px;
`;

const LoaderSpace = styled.div`
  width: 34px;

  @media (max-width: 1140px) {
    display: none;
  }
`;

const BerthPlanningToolTopPanel = ({ main = true }) => {
  const { namespace, user, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [newReservationModalOpen, setNewReservationModalOpen] = useState(false);
  const [newBerthBlockModalOpen, setNewBerthBlockModalOpen] = useState(false);

  const {
    setTimelineType,
    setToday,
    calendarVesselSummary,
    timelineUnit,
    setPredictiveBPTOn,
    predictiveBPTOn,
    setBptModalOpen,
    jitVesselLoader,
  } = useContext(BerthPlanningToolContext);

  const setCalendarTypeDay = () => {
    setTimelineType('day');
  };

  const setCalendarTypeWeek = () => {
    setTimelineType('week');
  };

  const setCalendarTypeMonth = () => {
    setTimelineType('month');
  };

  const clickNewReservation = () => {
    setNewReservationModalOpen(true);
    setBptModalOpen(true);
  };

  const clickNewBerthBlock = () => {
    setNewBerthBlockModalOpen(true);
    setBptModalOpen(true);
  };

  const closeModal = () => {
    setNewReservationModalOpen(false);
    setBptModalOpen(false);
  };

  const closeBerthBlockModal = () => {
    setNewBerthBlockModalOpen(false);
    setBptModalOpen(false);
  };

  const { Option } = Select;
  const handleChange = value => {
    switch (value) {
      case 'day':
        setCalendarTypeDay();
        break;
      case 'week':
        setCalendarTypeWeek();
        break;
      case 'month':
        setCalendarTypeMonth();
        break;

      default:
        break;
    }
  };

  const handlePredictiveChange = () => {
    setPredictiveBPTOn(!predictiveBPTOn);
  };

  return (
    <TopPanel noManage={!user.permissions.includes('manage_bpt')} main={main}>
      <CalendarButtons noManage={!user.permissions.includes('manage_bpt')} noMarginBottom={true}>
        <ButtonVersion cancel onClick={setToday}>
          {t('Today')}
        </ButtonVersion>
        <CalendarTypeButtons>
          <div id="calendar-type-select">
            <Select value={timelineUnit} style={{ width: 88 }} onChange={handleChange}>
              <Option value="day">{t('Day')}</Option>
              <Option value="week">{t('Week')}</Option>
              <Option value="month">{t('Month')}</Option>
            </Select>
          </div>
        </CalendarTypeButtons>
      </CalendarButtons>
      {modules.queue_module === 'enabled' && (
        <ColorIndicators noManage={!user.permissions.includes('manage_bpt')}>
          <ColorColumn>
            <ColorIndicator
              amount={calendarVesselSummary.accepted}
              color={color_planned}
              text={t('Accepted')}
              colorBlindOption={modules.queue_module === 'enabled' ? color_planned : undefined}
            />
            <ColorIndicator
              amount={calendarVesselSummary.offered}
              color={color_uncommitted}
              text={t('Offered')}
              colorBlindOption={modules.queue_module === 'enabled' ? color_uncommitted : undefined}
            />
          </ColorColumn>
          <ColorColumn>
            <ColorIndicator
              amount={calendarVesselSummary.not_planned}
              color={color_not_planned}
              text={t('Not Planned')}
              colorBlindOption={modules.queue_module === 'enabled' ? color_not_planned : undefined}
            />
            <ColorIndicator
              amount={calendarVesselSummary.reserved}
              color={color_reserved}
              text={t('Reserved')}
              colorBlindOption={modules.queue_module === 'enabled' ? color_reserved : undefined}
            />
          </ColorColumn>
          <ColorColumn>
            <ColorIndicator
              amount={calendarVesselSummary.updated}
              color={color_updated}
              text={t('Updated')}
              colorBlindOption={modules.queue_module === 'enabled' ? color_updated : undefined}
            />
          </ColorColumn>
        </ColorIndicators>
      )}
      {modules.queue_module !== 'enabled' && (
        <ColorIndicators noManage={!user.permissions.includes('manage_bpt')} moveTop={namespace === 'gothenburg'}>
          <ColorColumn>
            <ColorIndicator amount={calendarVesselSummary.planned} color={color_planned} text={t('Planned')} />
            <ColorIndicator
              amount={calendarVesselSummary.uncommitted}
              color={color_uncommitted}
              text={t('Uncommitted')}
            />
            {namespace === 'gothenburg' && <ColorIndicatorOverflow color="#ff8c00" text={t('Wind restriction')} />}
          </ColorColumn>
          <ColorColumn>
            <ColorIndicator
              amount={calendarVesselSummary.not_planned}
              color={color_not_planned}
              text={t('Not Planned')}
            />
            <ColorIndicator amount={calendarVesselSummary.reserved} color={color_reserved} text={t('Reserved')} />
          </ColorColumn>
        </ColorIndicators>
      )}
      {namespace === 'gothenburg' && <BptLayersComponent />}
      {modules.queue_module !== 'enabled' && user.permissions.includes('manage_bpt') && <CommittingActions />}
      {modules.predictive_berth_planning_module === 'enabled' && user.permissions.includes('view_crane_reservations') && (
        <Tooltip
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '11px' }}
          title={t('Predictive Berth Planning Tool')}
        >
          <PredictiveBerthPlanningSwitch noManage={!user.permissions.includes('manage_bpt')}>
            <Value data-id="predictive-bpt-switch">
              <Switch checked={predictiveBPTOn} size="small" onChange={handlePredictiveChange} />
            </Value>
            <IconContainerCrane>
              <Icon type="crane-planned" />
            </IconContainerCrane>
            <Label>{t('Predictive BPT')}</Label>
          </PredictiveBerthPlanningSwitch>
        </Tooltip>
      )}
      {user.permissions.includes('manage_bpt') &&
        (modules.queue_module !== 'enabled' ? (
          <CalendarButtons noMarginTop={true}>
            <ButtonVersion cancel onClick={clickNewReservation} disabled={!user.permissions.includes('manage_bpt')}>
              {t('New reservation')}
            </ButtonVersion>
          </CalendarButtons>
        ) : (
          <CalendarButtons noMarginTop={true}>
            {modules.queue_module && jitVesselLoader > 0 ? (
              <Loader>
                <Spin />
              </Loader>
            ) : (
              <LoaderSpace />
            )}
            <ButtonVersion
              cancel
              onClick={clickNewBerthBlock}
              disabled={!user.permissions.includes('manage_berth_reservation')}
            >
              {t('Add new berth block')}
            </ButtonVersion>
          </CalendarButtons>
        ))}

      {!!newReservationModalOpen && <AddOrEditReservationModal type={'new'} closeModal={closeModal} />}
      {!!newBerthBlockModalOpen && <AddOrEditBerthBlockModal type={'new'} closeModal={closeBerthBlockModal} />}
    </TopPanel>
  );
};

export default BerthPlanningToolTopPanel;
