import React, { useState, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tag from 'antd/es/tag';
import { PlusOutlined } from '@ant-design/icons';

import { UserContext } from '../../../context/UserContext';
import { NotificationContext } from '../../../context/NotificationContext';

import Textarea from '../../ui/Textarea';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import ButtonLight from '../../ui/ButtonLight';
import Form from '../../ui/Form';
import ProfileCircle from '../../ui/ProfileCircle';
import { mobilePixelMaxWidthLimit } from '../../../utils/constants';

const NotificationMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 224px;
  position: relative;
  textarea {
    min-height: 50px;
    resize: none;
  }
`;

const MarginTag = styled(Tag)`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
`;

const TagWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  width: unset;
  span {
    width: unset !important;
  }
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 3.2rem;
  flex-wrap: nowrap;
  display: flex;

  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    button {
      min-width: 70px;
    }
  }
`;

const NotificationForm = styled(Form)`
  background: ${({ theme }) => theme.color.white};
  padding: 12px 24px 0 24px;
  select,
  input,
  textarea,
  span {
    width: 100%;
  }
`;

const ActionsAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  height: 110px;
`;

const Message = styled.div`
  display: flex;
  width: 100%;
`;

const ProfileCircleContainer = styled.div`
  display: flex;
  height: 102px;
  width: 48px;
`;

const TextareaContainer = styled.div`
  display: inline-block;
  width: calc(100% - 48px);
`;

const InputContainer = styled.div`
  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    input {
      width: 140px;
      min-width: 60px;
    }
  }
`;

const NotificationMessage = ({ messageType, imo, master_id, messageSent, closeModal }, ref) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { apiSendNotification } = useContext(NotificationContext);

  const initDecisionTags = {
    tags: [],
    inputVisible: false,
    inputValue: '',
    limited: false,
  };

  const [notificationTitle, setNotificationTitle] = useState(undefined);
  const [decisionTags, setDecisionTags] = useState(initDecisionTags);

  const handleNotificationChange = e => {
    setNotificationTitle(e.target.value);
  };

  const handleTagClose = closed => {
    const tags = decisionTags.tags.filter(tag => tag !== closed);
    const limited = tags.length > 4;
    setDecisionTags({ ...decisionTags, tags, limited });
  };

  const handleTagInputChange = e => {
    setDecisionTags({ ...decisionTags, inputValue: e.target.value });
  };

  const showTagInput = () => {
    setDecisionTags({ ...decisionTags, inputVisible: true });
  };

  const handleTagInputConfirm = e => {
    if (e.key === undefined || e.key === 'Enter') {
      const { inputValue } = decisionTags;
      let { tags } = decisionTags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      const limited = tags.length > 4;
      setDecisionTags({
        tags,
        inputVisible: false,
        inputValue: '',
        limited: limited,
      });
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const sendNotification = e => {
    e.preventDefault();
    let type = '';
    if (notificationTitle && imo && decisionTags.tags.length === 0) {
      type = 'ship';
    } else if (notificationTitle && imo && decisionTags.tags.length > 0) {
      type = 'port_call_decision';
    } else if (messageType === 'port') {
      type = 'port';
    }

    if (type) {
      if (type === 'ship') {
        apiSendNotification(type, notificationTitle, imo, master_id);
      } else if (type === 'port_call_decision') {
        apiSendNotification(type, notificationTitle, imo, master_id, decisionTags.tags);
      } else if (type === 'port') {
        apiSendNotification(type, notificationTitle, '');
      }
    }
    let searchElement = document.getElementById('card-notification-textarea');
    searchElement.value = '';
    setNotificationTitle(undefined);
    setDecisionTags(initDecisionTags);
    messageSent();
  };

  if (!user.permissions.includes('send_push_notification')) {
    return <div style={{ width: '100%', height: '50px' }}></div>;
  }

  return (
    <NotificationMessageContainer ref={ref}>
      <NotificationForm onSubmit={sendNotification}>
        <Message>
          <ProfileCircleContainer>
            <ProfileCircle />
          </ProfileCircleContainer>
          <TextareaContainer>
            <Textarea
              id="card-notification-textarea"
              label={t('New notification message')}
              value={notificationTitle}
              onChange={handleNotificationChange}
            />
          </TextareaContainer>
        </Message>
        <ActionsAndButtons>
          {messageType !== 'port' ? (
            <div>
              {master_id !== '' && <Label>{t('Request actions by')}</Label>}
              <TagWrapper>
                {decisionTags.tags.map(value => {
                  return (
                    <MarginTag
                      key={value}
                      closable
                      onClose={e => {
                        e.preventDefault();
                        handleTagClose(value);
                      }}
                    >
                      {value}
                    </MarginTag>
                  );
                })}
              </TagWrapper>
              {decisionTags.inputVisible && !decisionTags.limited && master_id !== '' && (
                <InputContainer>
                  <Input
                    autoFocus
                    value={decisionTags.inputValue}
                    onChange={handleTagInputChange}
                    onBlur={handleTagInputConfirm}
                    onKeyPress={handleTagInputConfirm}
                  />
                </InputContainer>
              )}
              {!decisionTags.inputVisible && !decisionTags.limited && master_id !== '' && (
                <TagWrapper>
                  <Tag onClick={showTagInput}>
                    <PlusOutlined /> New
                  </Tag>
                </TagWrapper>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={handleCancel}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!notificationTitle}>{t('Send')}</ButtonLight>
          </ActionButtons>
        </ActionsAndButtons>
      </NotificationForm>
    </NotificationMessageContainer>
  );
};

export default forwardRef(NotificationMessage);
