import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

import { UserContext } from './UserContext';

export const TimestampFilteringContext = createContext();

export const TimestampFilteringProvider = ({ children }) => {
  const { apiCall, namespace } = useContext(UserContext);

  const [timestampFilteringListSet, setTimestampFilteringListSet] = useState(false);
  const [timestampFilteringList, setTimestampFilteringList] = useState([]);
  const [userOnOffTimestamps, setUserOnOffTimestamps] = useState(false);
  const [showTimestamps, setShowTimestamps] = useState(false);
  const [timestampTypesFromAPI, setTimestampTypesFromAPI] = useState(false);

  const timestampFilteringKey = 'hiddenTimestampTypes_' + namespace;
  const showTimestampFilteringKey = 'showTimestampFiltering_' + namespace;

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const createFilteringList = async () => {
      const result = await apiCall('get', 'settings');

      if (mounted.current && result?.status === 200) {
        const typeListObj = result.data.find(element =>
          Object.prototype.hasOwnProperty.call(element, 'timestamp_filter_list')
        );
        let typeList = typeListObj ? typeListObj.timestamp_filter_list : [];
        setTimestampTypesFromAPI(typeList);
        const showTimestampFilteringObj = result.data.find(element =>
          Object.prototype.hasOwnProperty.call(element, 'timestamp_hide_empty')
        );
        const showTimestampFiltering = showTimestampFilteringObj.timestamp_hide_empty === 'enabled';
        setShowTimestamps(showTimestampFiltering);

        const typeStorage = localStorage.getItem(timestampFilteringKey);
        const showTimestampFilteringStorage = localStorage.getItem(showTimestampFilteringKey);

        if (typeStorage) {
          try {
            const parsedArray = JSON.parse(typeStorage);
            if (Array.isArray(parsedArray)) {
              if (parsedArray.length === 0) {
                setTimestampFilteringList(
                  typeList.map(l => {
                    return { ...l, visible: true };
                  })
                );
              } else {
                setTimestampFilteringList(
                  typeList.map(c => {
                    if (parsedArray.some(item => item === c.key)) {
                      return { ...c, visible: false };
                    } else {
                      return { ...c, visible: true };
                    }
                  })
                );
              }
            }
          } catch {
            setTimestampFilteringList(typeList);
            localStorage.setItem(timestampFilteringKey, JSON.stringify([]));
          }
        } else {
          setTimestampFilteringList(typeList);
        }

        if (showTimestampFilteringStorage) {
          try {
            if (showTimestampFilteringStorage === 'enabled') {
              setUserOnOffTimestamps(true);
            }
          } catch {
            setUserOnOffTimestamps(false);
            localStorage.setItem(showTimestampFilteringKey, 'disabled');
          }
        } else {
          setUserOnOffTimestamps(showTimestampFiltering);
        }
      }
    };

    if (!timestampFilteringListSet) {
      setTimestampFilteringListSet(true);
      createFilteringList();
    }
  }, [apiCall, showTimestampFilteringKey, timestampFilteringKey, timestampFilteringListSet]);

  const updateUserOnOffTimestamps = value => {
    setUserOnOffTimestamps(value);
    localStorage.setItem(showTimestampFilteringKey, value ? 'enabled' : 'disabled');
  };

  const updateTimestampFilteringListAvailable = (key, value) => {
    const index = timestampFilteringList.findIndex(ts => ts.key === key);
    let list = [...timestampFilteringList];
    list.splice(index, 1, { ...list[index], visible: value });
    setTimestampFilteringList(list);
    const keys = list.filter(ts => !ts.visible);
    localStorage.setItem(timestampFilteringKey, JSON.stringify(keys.map(ts => ts.key)));
  };

  const resetToDefaults = () => {
    setTimestampFilteringList(timestampTypesFromAPI);
    localStorage.removeItem(timestampFilteringKey);
  };

  const showAll = () => {
    setTimestampFilteringList(
      [...timestampFilteringList].map(ts => {
        return { ...ts, visible: true };
      })
    );
    localStorage.setItem(timestampFilteringKey, JSON.stringify([]));
  };

  const hideAll = () => {
    setTimestampFilteringList(
      [...timestampFilteringList].map(ts => {
        return { ...ts, visible: false };
      })
    );
    localStorage.setItem(timestampFilteringKey, JSON.stringify(timestampFilteringList.map(ts => ts.key)));
  };

  return (
    <TimestampFilteringContext.Provider
      value={{
        timestampFilteringList,
        setTimestampFilteringList,
        userOnOffTimestamps,
        showTimestamps,
        updateUserOnOffTimestamps,
        updateTimestampFilteringListAvailable,
        showAll,
        hideAll,
        resetToDefaults,
        setTimestampFilteringListSet,
      }}
    >
      {children}
    </TimestampFilteringContext.Provider>
  );
};
