import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spin from 'antd/es/spin';

import Input from '../ui/Input';
import Button from '../ui/Button';
import Paragraph from '../ui/Paragraph';
import Form from '../ui/Form';
import { UserContext } from '../../context/UserContext';
import Footer from '../../images/footers/Footer-Sign_in.svg?react';
import Icon from '../ui/Icon';

const Container = styled.div`
  position: absolute;
  top: 274px;
  left: 30px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    color: #8a8a8a;
  }

  i {
    height: 22px;
    width: 22px;
  }

  svg {
    height: 22px;
    width: 22px;
  }
`;

const AmnesiaLink = styled.a`
  color: ${({ theme }) => theme.color.almost_black};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.emerald_green};
  }
`;

const StyledInput = styled(Input)`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
  width: 100%;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 50px 26px 10px 26px;
`;

const StyledForm = styled(Form)`
  position: relative;
  color: ${({ theme }) => theme.color.almost_black};
  background: white;
  width: 400px;
  padding: 0;
  input,
  button,
  select {
    width: 100%;
  }

  @media (max-width: 899px) {
    width: 100%;
  }
`;

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const ForgotPasswordContainer = styled.div`
  width: 400px;
  position: absolute;
  z-index: 50;
  top: 0px;
  left: 0px;
  background-color: white;
  overflow: hidden;

  @media (max-width: 899px) {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 48px;
  width: 100%;
  overflow: hidden;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.emerald_green};
  font-weight: 600;
  border-radius: 20px;
  color: white;
  padding: 4px 0px;
  text-align: center;
  cursor: pointer;
  height: 36px;
  text-transform: uppercase;
  margin-top: 18px;
  line-height: 26px;
  width: 100%;
  margin-bottom: 30px !important;

  &:hover {
    background-color: #5aa190;
  }
`;

const ParagraphContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 124px;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
  bacground-color: transparent;
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  width: 200px;
  text-align: center;
`;

const EmailContainer = styled.div`
  margin: 30px 26px 50px 26px;
`;

const LandingPageForgotPassword = () => {
  const { setCurrentAuthView, requestPasswordReset, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [email, setEmail] = useState('');
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [emailSent, showEmailSent] = useState(false);
  const [loader, showLoader] = useState(false);

  const disabled = email === '';

  const handleForgotPassword = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (email) {
      showLoader(true);
      const res = await requestPasswordReset(email, namespace);
      if (res) {
        showLoader(false);
        showEmailSent(true);
        setCurrentAuthView('LOGIN');
      }
    }
  };

  const toggleOpen = () => {
    setIsOpen(true);
    showEmailSent(false);
  };

  return (
    <>
      <Container ref={ref} style={{ marginTop: '12px' }}>
        <AmnesiaLink onClick={() => toggleOpen()}>{t('Forgot your password?')}</AmnesiaLink>
      </Container>
      {isOpen && (
        <ForgotPasswordContainer>
          <IconContainer onClick={() => setIsOpen(false)}>
            <Icon type="close-2" />
          </IconContainer>
          <StyledForm onSubmit={handleForgotPassword}>
            <HeaderContainer>
              <HeaderText>{t('Forgot your password?')}</HeaderText>
            </HeaderContainer>
            <StyledParagraph>
              {t('Please enter your e-mail address to receive a link to reset your password.')}
            </StyledParagraph>
            {!emailSent && (
              <EmailContainer>
                <Label>{t('Email')}</Label>
                <StyledInput name={t('Email')} type="email" onChange={e => setEmail(e.target.value)} autoFocus />
                <Spin spinning={loader}>
                  <StyledButton disabled={disabled}>{t('Request Password Reset')}</StyledButton>
                </Spin>
              </EmailContainer>
            )}
            {emailSent && !loader && (
              <ParagraphContainer>
                <StyledParagraph>{t('Email was sent.')}</StyledParagraph>
              </ParagraphContainer>
            )}
          </StyledForm>
          <FooterContainer>
            <Footer />
          </FooterContainer>
        </ForgotPasswordContainer>
      )}
    </>
  );
};

export default LandingPageForgotPassword;
