import { MapContainer as Map } from 'react-leaflet';
import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import L from 'leaflet';

import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';

import GeoAssetMapInner from './GeoAssetMapInner';
import GeoAssetToolLeftPanel from './GeoAssetToolLeftPanel';
import GeoAssetToolRightPanel from './GeoAssetToolRightPanel';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../../ui/ErrorPlaceHolder';
import { logError } from '../../ui/errorLogging';

const mapMargin = 21;

const PanelWrapperLeft = styled.div`
  top: ${mapMargin + 10}px;
  width: 190px;
  min-height: auto;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
`;

const SpinWrapper = styled.div`
  filter: ${props => (props.enabled ? 'grayscale(70%)' : 'none')};
  width: 100%;
  height: 100%;
  transition-duration: 0.2s;
  transition-property: filter;
`;

const GeoAssetMap = ({ coordinatesString, zoom }) => {
  let appliedCoordinates = coordinatesString === undefined ? '59.129089,14.373028' : coordinatesString;
  const [appliedZoom, setAppliedZoom] = useState(zoom === undefined ? 5 : zoom);
  const [selectedAisVessel, setSelectedAisVessel] = useState(undefined);

  const {
    rightPanelOpen,
    setRightPanelOpen,
    rightPanelData,
    setRightPanelData,
    rightPanelListData,
    closeRightPanel,
    globalsaveLoading,
    undoFunction,
    redoFunction,
    undoAvailable,
    redoAvailable,
    globalSaveFunction,
    globalSave,
    setNewRotatedCoords,
    setEditPointsInCreate,
  } = useContext(GeoAssetToolContext);

  //TODO: Verify Mac keys/shortcuts
  const handleKeyUp = event => {
    event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 'z' && undoAvailable) {
      undoFunction();
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'y' && redoAvailable) {
      redoFunction();
    } else if ((event.ctrlKey || event.metaKey) && charCode === 's' && globalSave) {
      globalSaveFunction();
    } else if (event.key === 'Escape' && rightPanelOpen) {
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
    }
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  return (
    <div onKeyUp={handleKeyUp} style={{ height: 'calc(100vh - 155px)', minHeight: '266px', display: 'flex' }}>
      <div
        id="map-container-for-width-geo-tool"
        style={{
          height: '100%',
          width: rightPanelOpen ? 'calc(100% - 320px)' : '100%',
          display: 'flex',
        }}
      >
        <SpinWrapper enabled={globalsaveLoading}>
          <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('medium')} onError={logError}>
            <Map
              style={{ height: '100%', zIndex: 0 }}
              className="geoasset-map"
              center={appliedCoordinates.split(',')}
              zoom={appliedZoom}
              zoomControl={false}
              tap={false}
              renderer={L.canvas({ padding: 0.5 })}
            >
              <Spin
                spinning={globalsaveLoading}
                size={'large'}
                style={{ zIndex: 1000, marginTop: 'calc(50vh - 69px)' }}
              >
                <GeoAssetMapInner
                  zoom={appliedZoom}
                  setZoom={setAppliedZoom}
                  style={{ zIndex: 0 }}
                  setSelectedAisVessel={setSelectedAisVessel}
                  selectedAisVessel={selectedAisVessel}
                />
              </Spin>
            </Map>
          </ErrorBoundary>
        </SpinWrapper>
        <PanelWrapperLeft>
          <GeoAssetToolLeftPanel />
        </PanelWrapperLeft>
      </div>

      <GeoAssetToolRightPanel
        rightPanelOpen={rightPanelOpen}
        setRightPanelOpen={setRightPanelOpen}
        rightPanelData={rightPanelData}
        setRightPanelData={setRightPanelData}
        rightPanelListData={rightPanelListData}
        closeRightPanel={closeRightPanel}
        mapMargin={mapMargin}
      />
    </div>
  );
};

export default GeoAssetMap;
