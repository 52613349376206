import { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';

const useLogin = () => {
  const { codeLogin, login, setCurrentAuthView, namespace, alert, currentAuthView } = useContext(UserContext);

  const initState = {
    username: '',
    password: '',
    code: '',
  };

  const [credentials, setCredentials] = useState(initState);
  const { username, password, code } = credentials;
  const disabled = username === '' || password === '';
  const codeSubmitDisabled = username === '' || password === '' || code === '';

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (username && password) {
      login(username, password);
    }
  };

  const handleSubmitCode = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (username && password && code) {
      codeLogin(username, password, code);
    }
  };

  return {
    alert: alert,
    credentials: credentials,
    currentAuthView: currentAuthView,
    disabled: disabled,
    codeSubmitDisabled: codeSubmitDisabled,
    handleSubmit: handleSubmit,
    handleSubmitCode: handleSubmitCode,
    namespace: namespace,
    setCredentials: setCredentials,
    setCurrentAuthView: setCurrentAuthView,
  };
};

export default useLogin;
