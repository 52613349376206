import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import App from 'antd/es/app';

import Text from 'antd/lib/typography/Text';

import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import Icon from '../../components/ui/Icon';
import SelectWithSearch from '../../components/ui/SelectWithSearch';

const ModalInner = styled.div`
  padding: 24px;
`;

const VesselList = () => {
  const { apiCall, namespace, user, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [apiCallPending, setApiCallPending] = useState(false);

  const { message } = App.useApp();

  const history = useHistory();
  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const initEditedVessel = {
    id: null,
    vessel_type: null,
  };

  const [editedVessel, setEditedVessel] = useState(initEditedVessel);

  const initModal = {
    visible: false,
    confirmLoading: false,
    vessel: {
      id: null,
      vessel_type: null,
    },
  };

  const [modal, setModal] = useState(initModal);

  const params = new URLSearchParams(location.search);

  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'vessel_name',
    search: params.get('search') ? params.get('search') : '',
  };
  const [newParams, setNewParams] = useState(defaultParams);

  const { loading: typesLoading, data: typesData, error: typesError } = useApi('get', 'vessel-types', {});

  if (typesError) {
    message.error(typesError, 4);
  }

  const vesselTypes =
    typesData && typesData.length > 0
      ? typesData.map(type => {
        type.key = type.name;
        type.label = type.name;
        type.value = type.id;
        return type;
      })
      : [];

  const { loading, data, error, fetchData } = useApi('get', 'vessels', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [fetchData, newParams]);

  const vessels = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleShowHideVessel = async e => {
    setApiCallPending(true);
    await apiCall('post', 'vessel-visibility', { imo: e.imo, visible: !e.visible });
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleTimestampAttach = async imo => {
    setApiCallPending(true);
    await apiCall('get', 'timestamps-to-port-calls', { imo: imo });
    setApiCallPending(false);
    let params = newParams;
    fetchData(false, params);
  };

  const showModal = async id => {
    let matchingVessel = vessels.find(vessel => {
      return vessel.id === id;
    });
    if (matchingVessel) {
      setEditedVessel({ ...matchingVessel });
      setModal({ ...initModal, visible: true });
    }
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', 'vessel', {
        id: editedVessel.id,
        vesselType: parseInt(editedVessel.vessel_type),
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    setEditedVessel(initEditedVessel);
    let params = newParams;
    fetchData(false, params);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setEditedVessel(initEditedVessel);
  };

  const handleModalVesselTypeChange = value => {
    setEditedVessel({ ...editedVessel, vessel_type: value });
  };

  const vesselTypeToString = vesselType => {
    if (!vesselTypes) {
      return t('Unknown Vessel Type');
    }
    let matchingType = vesselTypes.find(type => {
      return type.id === vesselType;
    });
    return matchingType ? matchingType['name'] : t('Unknown Vessel Type');
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('Name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('Type'),
      dataIndex: 'vessel_type',
      key: 'vessel_type',
      render: value => value + ' (' + vesselTypeToString(value) + ')',
      sortableKey: 'vessel_type',
    },
    {
      title: t('Visible'),
      dataIndex: 'visible',
      key: 'visible',
      render: record => {
        if (record) {
          return <Text>T</Text>;
        } else {
          return <Text>F</Text>;
        }
      },
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          disabled={!user.permissions.includes('manage_port_call')}
          onClick={() => handleShowHideVessel(record)}
        >
          <Icon type="action" />
          {record.visible ? t('Hide') : t('Show')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          disabled={!user.permissions.includes('manage_vessel')}
          onClick={() => showModal(record.id)}
        >
          <Icon type="action" />
          {t('Change vessel type')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-3"
          disabled={!user.permissions.includes('manage_port_call')}
          onClick={() => history.push(`/vessels/vessel-timestamps/${record.imo}`)}
        >
          <Icon type="action" />
          {t('Show timestamps')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Really attach orphan timestamps to port calls?')}
          onConfirm={() => handleTimestampAttach(record.imo)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-4"
        >
          <div>
            <ListActionButton red disabled={!user.permissions.includes('manage_port_call')}>
              <Icon type="action" />
              {t('Attach orphan timestamps')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <List
        rowKey="id"
        columns={columns}
        dataSource={vessels}
        apiCallPending={apiCallPending}
        actions={actions}
        spinning={loading || typesLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name or exact IMO')}
      />
      <Modal
        title={t('Change vessel type for {{name}}', { name: editedVessel.vessel_name })}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <SelectWithSearch
            name="vessel_type"
            label={t('Vessel type')}
            value={editedVessel.vessel_type}
            options={vesselTypes}
            onChange={handleModalVesselTypeChange}
          />
        </ModalInner>
      </Modal>
    </>
  );
};

export default VesselList;
