import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal';

import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';

const ModalInner = styled.div`
  position: relative;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 480px;
`;

const InfoText = styled.div`
  margin: 24px 200px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const DarkerBackground = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.color.grey_lighter3};
  margin-right: 2px;
  display: inline;
  padding: 12px 0;
`;

const DarkerHeader = styled(DarkerBackground)`
  justify-content: center;
  font-weight: bold;
  display: flex;
`;

const LighterBackground = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.color.grey_lighter};
  display: inline;
  padding: 12px 0;
`;

const LighterHeader = styled(LighterBackground)`
  justify-content: center;
  font-weight: bold;
  display: flex;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 300px;
`;

const DataSetContainer = styled.div`
  margin-bottom: 12px;
`;

const NameRow = styled.div`
  margin-left: 18px;
`;

const DataContainer = styled.div`
  display: flex;
`;

const Columns = styled.div`
  display: flex;
`;

const HeaderColumn = styled.div`
  margin-left: 18px;
`;

const DataColumn = styled.div`
  margin-left: 12px;
`;

const HeaderText = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-bottom: 3px;
`;

const DataText = styled.div`
  margin-left: 12px;
  margin-bottom: 3px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const CommitAll = ({ closeModal }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sendCommitAll } = useContext(BerthPlanningToolContext);

  const [data, setData] = useState(undefined);

  const sendData = async () => {
    sendCommitAll();
    closeModal();
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const getCommitData = async () => {
      const commitData = await apiCall('get', 'bpt-calendar-vessels-updates', {});
      if (commitData.data) {
        let dataList = [];
        for (let d in commitData.data) {
          dataList.push({ ...commitData.data[d], port_call_master_id: d });
        }
        setData(dataList);
      }
    };

    if (!data) {
      getCommitData();
    }
  }, [apiCall, data]);

  return (
    <Modal title={t('Commit all changes')} visible width={800} onCancel={closeModal} footer={null}>
      <ModalInnerInput>
        <InfoText>{t('Are you sure you want commit following changes?')}</InfoText>
        <HeaderRow>
          <DarkerHeader>{t('Previous changes')}</DarkerHeader>
          <LighterHeader>{t('New changes')}</LighterHeader>
        </HeaderRow>
        <ScrollContainer>
          {!!data &&
            data.map(d => (
              <DataSetContainer key={d.port_call_master_id}>
                <NameRow>{d.info.name}</NameRow>
                <DataContainer>
                  <DarkerBackground>
                    <Columns>
                      <HeaderColumn>
                        {d.changes.agent && <HeaderText>{t('Agent')}:</HeaderText>}
                        {d.changes.berth_short_name && <HeaderText>{t('Berth')}:</HeaderText>}
                        {(d.changes.start_bollard_short_name || d.changes.end_bollard_short_name) && (
                          <HeaderText>{t('Bollard')}:</HeaderText>
                        )}
                        {d.changes.pta && <HeaderText>{t('PTA')}:</HeaderText>}
                        {d.changes.ptd && <HeaderText>{t('PTD')}:</HeaderText>}
                      </HeaderColumn>
                      <DataColumn>
                        {d.changes.agent && <DataText>{d.changes.agent.old || '-'}</DataText>}
                        {d.changes.berth_short_name && <DataText>{d.changes.berth_short_name.old || '-'}</DataText>}
                        {d.changes.start_bollard_short_name && (
                          <DataText>
                            <BoldText>{d.changes.start_bollard_short_name?.old || '-'}</BoldText> {t('to')}{' '}
                            <BoldText>{d.changes.end_bollard_short_name?.old || '-'}</BoldText>
                          </DataText>
                        )}
                        {d.changes.end_bollard_short_name && !d.changes.start_bollard_short_name && (
                          <DataText>
                            <BoldText>{d.changes.start_bollard_short_name?.old || '-'}</BoldText> {t('to')}{' '}
                            <BoldText>{d.changes.end_bollard_short_name?.old || '-'}</BoldText>
                          </DataText>
                        )}
                        {d.changes.pta && (
                          <DataText>
                            {d.changes.pta.old ? dayjs(d.changes.pta.old).format('MMM DD, YYYY HH:mm') : '-'}
                          </DataText>
                        )}
                        {d.changes.ptd && (
                          <DataText>
                            {d.changes.ptd.old ? dayjs(d.changes.ptd.old).format('MMM DD, YYYY HH:mm') : '-'}
                          </DataText>
                        )}
                      </DataColumn>
                    </Columns>
                  </DarkerBackground>
                  <LighterBackground>
                    <Columns>
                      <HeaderColumn>
                        {d.changes.agent && <HeaderText>{t('Agent')}:</HeaderText>}
                        {d.changes.berth_short_name && <HeaderText>{t('Berth')}:</HeaderText>}
                        {(d.changes.start_bollard_short_name || d.changes.end_bollard_short_name) && (
                          <HeaderText>{t('Bollard')}:</HeaderText>
                        )}
                        {d.changes.pta && <HeaderText>{t('PTA')}:</HeaderText>}
                        {d.changes.ptd && <HeaderText>{t('PTD')}:</HeaderText>}
                      </HeaderColumn>
                      <DataColumn>
                        {d.changes.agent && <DataText>{d.changes.agent.new}</DataText>}
                        {d.changes.berth_short_name && <DataText>{d.changes.berth_short_name.new}</DataText>}
                        {d.changes.start_bollard_short_name && (
                          <DataText>
                            <BoldText>{d.changes.start_bollard_short_name?.new || '-'}</BoldText> {t('to')}{' '}
                            <BoldText>{d.changes.end_bollard_short_name?.new || '-'}</BoldText>
                          </DataText>
                        )}
                        {d.changes.end_bollard_short_name && !d.changes.start_bollard_short_name && (
                          <DataText>
                            <BoldText>{d.changes.start_bollard_short_name?.new || '-'}</BoldText> {t('to')}{' '}
                            <BoldText>{d.changes.end_bollard_short_name?.new || '-'}</BoldText>
                          </DataText>
                        )}
                        {d.changes.pta && (
                          <DataText>
                            {d.changes.pta.new ? dayjs(d.changes.pta.new).format('MMM DD, YYYY HH:mm') : '-'}
                          </DataText>
                        )}
                        {d.changes.ptd && (
                          <DataText>
                            {d.changes.ptd.new ? dayjs(d.changes.ptd.new).format('MMM DD, YYYY HH:mm') : '-'}
                          </DataText>
                        )}
                      </DataColumn>
                    </Columns>

                    {d.changes.pbp_data && (
                      <DataText style={{ marginTop: '6px', fontWeight: 700, marginLeft: '18px' }}>
                        {t('Crane plans changed.')}
                      </DataText>
                    )}
                  </LighterBackground>
                </DataContainer>
              </DataSetContainer>
            ))}
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight onClick={sendData}>{t('Commit')}</ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default CommitAll;
