import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import styled from 'styled-components';

import { UserContext } from '../../../../context/UserContext';
import ButtonLight from '../../../ui/ButtonLight';

const ModalInner = styled.div`
  min-height: 230px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RowContainer = styled.div`
  padding: 8px 0 12px 0;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const SwitchContainer = styled.div`
  padding-top: 4px;
`;

const AddOrEditCommodityModal = ({ type, closeModal, data, refetchData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    id: data ? data.id : undefined,
    name: data ? data.name : undefined,
    hs_code: data ? data.hs_code : undefined,
    load: data ? data.load : false,
    loading_efficiency_average_per_hour: data ? data.loading_efficiency_average_per_hour : undefined,
    unload: data ? data.unload : false,
    unloading_efficiency_average_per_hour: data ? data.unloading_efficiency_average_per_hour : undefined,
    rain_sensitive: data ? data.rain_sensitive : false,
    efficiency_unit: data ? data.efficiency_unit : false,
  });

  const [sending, setSending] = useState(false);

  const title = type === 'add' ? t('Add commodity') : t('Edit commodity');
  const buttonText = type === 'add' ? t('Add') : t('Save');

  const dataIsValid = () => {
    return inputData.name;
  };

  const updateName = e => {
    setInputData({ ...inputData, name: e.target.value });
  };

  const updateHSCode = e => {
    setInputData({ ...inputData, hs_code: e.target.value });
  };

  const updateLoad = value => {
    setInputData({ ...inputData, load: value });
  };

  const updateLoadingEfficiency = e => {
    setInputData({ ...inputData, loading_efficiency_average_per_hour: e.target.value });
  };

  const updateUnload = value => {
    setInputData({ ...inputData, unload: value });
  };

  const updateUnloadingEfficiency = e => {
    setInputData({ ...inputData, unloading_efficiency_average_per_hour: e.target.value });
  };

  const updateRainSesitive = value => {
    setInputData({ ...inputData, rain_sensitive: value });
  };

  const updateEfficiencyUnit = e => {
    setInputData({ ...inputData, efficiency_unit: e.target.value });
  };

  const sendData = async () => {
    setSending(true);
    let result;

    try {
      if (type === 'add') {
        result = await apiCall('post', 'pbp/port-commodities', {
          name: inputData.name,
          hs_code: inputData.hs_code,
          load: inputData.load,
          loading_efficiency_average_per_hour: inputData.loading_efficiency_average_per_hour,
          unload: inputData.unload,
          unloading_efficiency_average_per_hour: inputData.unloading_efficiency_average_per_hour,
          rain_sensitive: inputData.rain_sensitive,
          efficiency_unit: inputData.efficiency_unit,
        });
      } else {
        result = await apiCall('put', 'pbp/port-commodities', {
          id: data.id,
          name: inputData.name,
          hs_code: inputData.hs_code,
          load: inputData.load,
          loading_efficiency_average_per_hour: inputData.loading_efficiency_average_per_hour,
          unload: inputData.unload,
          unloading_efficiency_average_per_hour: inputData.unloading_efficiency_average_per_hour,
          rain_sensitive: inputData.rain_sensitive,
          efficiency_unit: inputData.efficiency_unit,
        });
      }
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      refetchData();
      closeModal();
    } else {
      setSending(false);
    }
  };

  return (
    <Modal title={title} open={!!type} width={600} footer={null} onCancel={closeModal}>
      <ModalInner>
        <RowContainer>
          <RowHeader>
            {t('Name')}
            <Required>*</Required>
          </RowHeader>
          <Input placeholder={t('Name of the commodity')} value={inputData.name || ''} onChange={updateName} />
        </RowContainer>
        <RowContainer>
          <RowHeader>{t('HS code')}</RowHeader>
          <Input placeholder={t('HS code')} value={inputData.hs_code || ''} onChange={updateHSCode} />
        </RowContainer>
        <RowContainer style={{ display: 'flex', padding: 0 }}>
          <RowContainer style={{ width: '15%' }}>
            <RowHeader>{t('Load')}</RowHeader>
            <SwitchContainer>
              <Switch size="small" checked={inputData.load} onChange={updateLoad} />
            </SwitchContainer>
          </RowContainer>
          <RowContainer style={{ width: '85%' }}>
            <RowHeader>{t('Loading efficiency (/h)')}</RowHeader>
            <Input
              placeholder={t('Loading efficiency')}
              value={inputData.loading_efficiency_average_per_hour || ''}
              onChange={updateLoadingEfficiency}
            />
          </RowContainer>
        </RowContainer>
        <RowContainer style={{ display: 'flex', padding: 0 }}>
          <RowContainer style={{ width: '15%' }}>
            <RowHeader>{t('Unload')}</RowHeader>
            <SwitchContainer>
              <Switch size="small" checked={inputData.unload} onChange={updateUnload} />
            </SwitchContainer>
          </RowContainer>
          <RowContainer style={{ width: '85%' }}>
            <RowHeader>{t('Unloading efficiency (/h)')}</RowHeader>
            <Input
              placeholder={t('Unloading efficiency')}
              value={inputData.unloading_efficiency_average_per_hour || ''}
              onChange={updateUnloadingEfficiency}
            />
          </RowContainer>
        </RowContainer>
        <RowContainer>
          <RowHeader>{t('Efficiency unit')}</RowHeader>
          <Input placeholder={t('Unit')} value={inputData.efficiency_unit || ''} onChange={updateEfficiencyUnit} />
        </RowContainer>
        <RowContainer>
          <RowHeader>{t('Rain sensitive')}</RowHeader>
          <SwitchContainer>
            <Switch size="small" checked={inputData.rain_sensitive} onChange={updateRainSesitive} />
          </SwitchContainer>
        </RowContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
            {buttonText}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default AddOrEditCommodityModal;
