import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Name = styled.span`
  display: inline-block;
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

const PortCallInvoiceRowShipInfo = ({ row }) => {
  return (
    <Container>
      <Name>{row?.port_call_data?.vessel_name}</Name>
    </Container>
  );
};

export default PortCallInvoiceRowShipInfo;
