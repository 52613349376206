import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import DataExportColumnSelect from './DataExportColumnSelect';
import useApi from '../../hooks/useApi';
import DataExportFilters from './DataExportFilters';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

const Container = styled.div`
  width: ${props => (props.listExpanded ? 'calc(100% - 300px)' : 'calc(100% - 20px)')};
  padding: 40px 18px 18px 32px;
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 152px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 138px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? 'calc(100% - 250px)' : 'calc(100% - 10px)')};
    padding: 20px 9px 9px 16px;
  }
`;

const ListContainer = styled.div`
  @media (max-width: 850px) {
    margin-top: 100px;
  }
`;

const TopRow = styled.div`
  width: ${props => (props.listExpanded ? 'calc(100% - 330px)' : 'calc(100% - 50px)')};
  position: absolute;
  top: 2px;
  left: 32px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? 'calc(100% - 260px)' : 'calc(100% - 20px)')};
    top: 0px;
    left: 16px;
    min-width: 320px;
  }
`;

const TopSecondRow = styled.div`
  width: ${props => (props.listExpanded ? 'calc(100% - 560px)' : 'calc(100% - 280px)')};
  position: absolute;
  top: 40px;
  left: 32px;
  display: flex;
  justify-content: space-between;
  margin-left: 230px;

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? 'calc(100% - 260px)' : 'calc(100% - 20px)')};
    top: 30px;
    left: 16px;
    margin-left: 0px;
    display: block;
    min-width: 320px;
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  margin-right: 12px;

  @media (max-width: 850px) {
    margin-left: 6px;
  }
`;

const FilterAndColumnSelect = styled.div`
  display: flex;

  @media (max-width: 850px) {
    margin-top: 6px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 850px) {
    margin-top: 8px;
  }
`;

// TODO: Date selector
// TODO: Filters, can be added to simple_query as follows:
// imo: [1234567, 234567]
// port_call_id: 123
// Parameters will be ANDed, arrays within parameters will be ORed
const DataExport = ({ chosenExport, listExpanded }) => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const [preventDataReload, setPreventDataReload] = useState(true);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    setTimeout(() => {
      setPreventDataReload(false);
    }, 1000);

    return () => {
      mounted.current = false;
    };
  });

  const buildQueryParams = useCallback(
    (inParams, filterList, fetchType) => {
      let fromValue = dayjs().subtract(1, 'week');
      let toValue = dayjs();

      // When we are exporting data, we add 2 days because the limit P3M is treated like 90 days rather than 3 months.
      // The timepicker works correctly and we get the full 3 months, so we don't get too much data because we take minimum of
      // picker and the 92 days
      const limit =
        fetchType === 'preview'
          ? dayjs.duration(chosenExport.view_limit || 0).asMilliseconds()
          : dayjs
            .duration(chosenExport.export_limit || 0)
            .add(2, 'days')
            .asMilliseconds();

      const toValueObject = filterList.find(f => f.name === 'to');
      const fromValueObject = filterList.find(f => f.name === 'from');

      if (fromValueObject?.value && toValueObject?.value) {
        fromValue = fromValueObject.value.clone();
        const fromCopy = fromValue.clone();
        toValue = dayjs.min(toValueObject.value.clone(), fromCopy.add(limit, 'milliseconds'));
      } else if (fromValueObject?.value && !toValueObject?.value) {
        fromValue = fromValueObject.value.clone();
        const fromCopy = fromValue.clone();
        toValue = fromCopy.add(1, 'week');
      } else if (!fromValueObject?.value && toValueObject?.value) {
        toValue = toValueObject.value.clone();
        const toCopy = toValue.clone();
        toValue = toCopy.subtract(1, 'week');
      }

      let orderBy = {
        field: inParams.sort,
        order: 'asc',
      };
      if (inParams.sort.endsWith(' DESC')) {
        orderBy.field = inParams.sort.slice(0, -5);
        orderBy.order = 'desc';
      }

      let simpleQuery = {
        from: fromValue.format('YYYY-MM-DDTHH:mm:00Z'),
        to: toValue.format('YYYY-MM-DDTHH:mm:00Z'),
        search_field: inParams.search,
      };

      let filtersWithoutDatetimes = filterList.filter(f => f.type !== 'datetime');

      filtersWithoutDatetimes.forEach(f => {
        if (f.values && f.values.length > 0) {
          simpleQuery[f.name] = f.values;
        }
      });

      return {
        simple_query: simpleQuery,
        pagination: {
          limit: inParams.limit,
          offset: inParams.offset,
        },
        order_by: [orderBy],
        ...chosenExport.data_source.parameters,
        format: {
          type: 'paginated_list',
        },
      };
    },
    [chosenExport.data_source.parameters, chosenExport.export_limit, chosenExport.view_limit]
  );

  const defaultParams = {
    limit: 50,
    offset: 0,
    sort: chosenExport.columns[0].dataIndex,
    search: '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi(
    'post',
    chosenExport.data_source.url,
    buildQueryParams(newParams, [], 'preview')
  );

  useEffect(() => {
    if (!preventDataReload) {
      fetchData(false, buildQueryParams(newParams, filters, 'preview'));
    }
  }, [newParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const { start, total } =
    error || !data ? { start: 0, total: 0 } : { start: data.pagination.offset, total: data.pagination.total };
  const datas = error || !data ? [] : data.data;

  const [visibleColumns, setVisibleColumns] = useState(chosenExport.columns);
  const showableColumns = chosenExport.columns.filter(c => visibleColumns.some(c2 => c2.key === c.key));

  const [filters, setFilters] = useState(
    chosenExport.filters
      ? Object.keys(chosenExport.filters).map(filter => {
        return {
          type: chosenExport.filters[filter],
          name: filter,
          value: undefined,
          values: [],
        };
      })
      : []
  );

  const reFetchData = () => {
    setNewParams({ ...newParams, limit: 50, offset: 0 });
  };

  const onExportButtonClick = async button => {
    const params = buildQueryParams(newParams, filters, 'export');
    const response = await apiCall(
      'post',
      button.data_source.url,
      {
        ...params,
        format: {
          type: button.data_source.parameters.format.type,
          fields: visibleColumns.map(c => c.key),
        },
      },
      null,
      'blob'
    );

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = 'undefined';
    if (response.headers) {
      const header = response.headers['Content-Disposition'] || response.headers['content-disposition'];
      if (header?.split('filename=')[1]) {
        fileName = header.split('filename=')[1];
      }
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  let showableData = [...datas];
  let id = 0;
  if (chosenExport.columns.findIndex(c => c.key === 'id') === -1) {
    showableData = showableData.map(d => {
      return { ...d, id: id++ };
    });
  }

  return (
    <Container listExpanded={listExpanded} alertsHeight={alertsHeight}>
      <ListContainer>
        <List
          rowKey="id"
          columns={showableColumns}
          dataSource={showableData}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          hideSearch={false}
          noUrlUpdate={true}
        />
      </ListContainer>
      <TopRow>
        <Header>{t(chosenExport.header)}</Header>
      </TopRow>
      <TopSecondRow>
        <FilterAndColumnSelect>
          <DataExportFilters
            filters={filters}
            setFilters={setFilters}
            fetchData={reFetchData}
            chosenExport={chosenExport}
          />
          <DataExportColumnSelect
            columns={chosenExport.columns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            id={chosenExport.id}
          />
        </FilterAndColumnSelect>
        <ButtonContainer>
          {!!chosenExport.buttons &&
            chosenExport.buttons.map((button, i) => (
              <div key={i}>
                <DataExportFilters
                  filters={filters}
                  setFilters={setFilters}
                  fetchData={reFetchData}
                  chosenExport={chosenExport}
                  button={button}
                  buttonClick={onExportButtonClick}
                  index={i}
                />
              </div>
            ))}
        </ButtonContainer>
      </TopSecondRow>
    </Container>
  );
};
export default DataExport;
