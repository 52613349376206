import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import App from 'antd/es/app';
import Spin from 'antd/es/spin';

import Heading from '../ui/Heading';
import Input from '../ui/Input';
import Paragraph from '../ui/Paragraph';
import Button from '../ui/Button';

const EmergencyShutdownControl = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const initAuthorization = {
    password: '',
  };

  const [authorization, setAuthorization] = useState(initAuthorization);

  const { loading, data, error, fetchData } = useApi('get', 'maintenance-mode-status', {});

  if (error) {
    message.error(error, 4);
  }

  const emergencyShutdownEnabled = loading ? false : data;

  const applyChange = async enable => {
    try {
      await apiCall('post', enable ? 'emergency-shutdown-activate' : 'emergency-shutdown-deactivate', {
        authorizationCode: authorization.password,
      });
      clearInput();
    } catch (error) {
      clearInput();
    }
  };

  const clearInput = async () => {
    setAuthorization(initAuthorization);
    fetchData();
  };

  return (
    <>
      <div>
        <Heading h4>{t('Emergency shutdown')}</Heading>
        <Spin spinning={loading}>
          {emergencyShutdownEnabled && !loading && (
            <Paragraph>
              {t('Emergency shutdown is enabled. Most of the services have been disabled for all users.')}
            </Paragraph>
          )}
          {!emergencyShutdownEnabled && !loading && (
            <Paragraph>
              {t(
                'Emergency shutdown is disabled. If enabled, emergency shutdown will disable most of the services for all users until disabled.'
              )}
            </Paragraph>
          )}
          {!loading && (
            <Input
              label={t('Authorization code')}
              name={t('Authorization code')}
              type="password"
              value={authorization.password}
              onChange={e => setAuthorization({ ...authorization, password: e.target.value })}
            />
          )}
          {emergencyShutdownEnabled && !loading && (
            <Button link disabled={!authorization.password.length} onClick={() => applyChange(false)}>
              {t('Disable')}
            </Button>
          )}
          {!emergencyShutdownEnabled && !loading && (
            <Button link disabled={!authorization.password.length} onClick={() => applyChange(true)}>
              {t('Enable')}
            </Button>
          )}
          {!loading && (
            <Button
              link
              disabled={!authorization.password.length}
              onClick={() => clearInput()}
              style={{ marginLeft: 16 }}
            >
              {t('Clear')}
            </Button>
          )}
        </Spin>
      </div>
    </>
  );
};

export default EmergencyShutdownControl;
