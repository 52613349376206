import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { SideHelpContext } from '../../context/SideHelpContext';
import { AlertContext } from '../../context/AlertContext';

import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit, mobilePixelMinWidthLimit } from '../../utils/constants';
import SideHelp from './SideHelp';
import HelpModalInner from '../info/HelpModalInner';

const Container = styled.div`
  background-color: #ffffff;
  z-index: 199;
  width: 400px;
  border-left: 1px solid #e8e8e8;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: inline-block;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 13px 14px 13px;
  border-bottom: 1px solid #e8e8e8;
`;

const HeaderText = styled.div`
  font-weight: 600;
`;

const HelpClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    margin-top: -10px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const Content = styled.div`
  padding: 12px 12px 36px 12px;
  height: calc(100vh - 64px - 49px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  overflow-y: auto;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
`;

const SideHelpWeb = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sideHelpOpen, setSideHelpOpen, sideWikiProps } = useContext(SideHelpContext);
  const { alertsHeight } = useContext(AlertContext);

  const [title, setTitle] = useState(null);
  const [editModeOn, setEditModeOn] = useState(false);

  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  if (!sideHelpOpen) {
    return null;
  }

  return (
    <Container id="side-help-container">
      <Header>
        <HeaderText>{t('Help')}</HeaderText>
        <HelpClose onClick={() => setSideHelpOpen(false)}>
          <Icon type="close" />
        </HelpClose>
      </Header>
      <Content alertsHeight={alertsHeight}>
        {sideWikiProps ? (
          <>
            <Title>{title}</Title>
            <HelpModalInner
              sideOfPage={true}
              objectType={sideWikiProps.objectType}
              objectId={sideWikiProps.objectId}
              open={sideHelpOpen}
              setOpen={setSideHelpOpen}
              coordinates={sideWikiProps.coordinates}
              usableMarkdownId={sideWikiProps.usableMarkdownId}
              setTitle={setTitle}
              closeConfirmation={closeConfirmation}
              setCloseConfirmation={setCloseConfirmation}
              startLoader={startLoader}
              setStartLoader={setStartLoader}
              setEditModeOn={setEditModeOn}
              editModeOn={editModeOn}
            />
          </>
        ) : (
          <SideHelp />
        )}
      </Content>
    </Container>
  );
};

export default SideHelpWeb;
