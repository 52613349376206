import React, { useContext } from 'react';
import styled from 'styled-components';

import { CranePlanningToolContext } from '../../context/CranePlanningToolContext';
import CranePlanningToolReservations from './CranePlanningToolReservations';

const SidePanel = styled.div`
  height: 100%;
  border-left: 1px solid #f8f8f8;
  z-index: 110;
`;

const CranePlanningToolSidePanel = () => {
  const { sidePanelWidth } = useContext(CranePlanningToolContext);

  return (
    <SidePanel style={{ width: sidePanelWidth + 'px' }}>
      <CranePlanningToolReservations />
    </SidePanel>
  );
};

export default CranePlanningToolSidePanel;
