import React, { useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMapEvents, useMap, Polyline, Circle } from 'react-leaflet';
import L from 'leaflet';
import Switch from 'antd/es/switch';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import MapVessel from '../map/MapVessel';
import CurrentMapVessel from '../map/CurrentMapVessel';

import { getOptions } from '../map/MapUtils';
import { PublicContext } from '../../context/PublicContext';
import { FleetVesselViewContext } from '../../context/FleetVesselViewContext';
import { UserContext } from '../../context/UserContext';
import WMSChart from '../map/WMSChart';

const ZoomButtonContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 400;
  right: 8px;
  bottom: ${props => (props.buttonMargin ? '50px' : '36px')};
  display: inline-grid;
  margin: 0;
`;

const ZoomButton = styled(Button)`
  z-index: 50;
  width: 28px;
  height: 28px;
  margin: 0;
`;

const SwitchLabel = styled.div`
  font-size: 13px;
  margin-left: 12px;
  line-height: 22px;
`;

const SwitchContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  margin-top: 12px;
  z-index: 401;
  background-color: #f8f8f8;
  padding: 3px 6px;
  border-radius: 3px;

  button {
    margin-top: 3px;
  }
`;

const SeaPassageMapInnerContainer = ({
  zoom,
  setZoom,
  buttonMargin,
  mapSize,
  seaPassage,
  showRoute,
  setShowSeaPassage,
  portcall,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const options = getOptions();
  const map = useMap();

  const { allVessels = [] } = useContext(PublicContext);
  const { vessels = [], sideModalOpen } = useContext(FleetVesselViewContext);

  const showRouteRef = useRef();

  const mapWithEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapWithEvents.getZoom());
      mapWithEvents.setView(mapWithEvents.getCenter(), mapWithEvents.getZoom());
    },
  });

  useEffect(() => {
    map.invalidateSize();
  });

  useEffect(() => {
    if (showRouteRef.current !== showRoute && !!seaPassage && showRoute) {
      let latlongsArray = [];
      seaPassage.features.map(f => {
        if (f.geometry.type === 'LineString') {
          const latlongs = L.GeoJSON.coordsToLatLngs(f.geometry.coordinates, 0);
          latlongs.forEach(ll => latlongsArray.push(ll));
        }
      });
      let bounds = new L.latLngBounds(latlongsArray);
      map.fitBounds(bounds);
    }
  }, [map, seaPassage, showRoute]);

  const handleZoomButton = selectedZoom => {
    setZoom(selectedZoom);
    mapWithEvents.setView(mapWithEvents.getCenter(), selectedZoom);
  };

  const createVesselsOverlay = () => {
    let imos = [];

    vessels.forEach(vessel => {
      imos.push(vessel.imo);
    });

    let showableVessels = allVessels.filter(vessel => imos.findIndex(v => vessel.properties.imo === v) > -1);

    return (
      <>
        {showableVessels.map((v, i) => {
          return v.properties.imo === sideModalOpen.imo && zoom <= 14 ? (
            <CurrentMapVessel
              key={i}
              feature={v}
              isPublicMap={false}
              appliedZoom={zoom}
              tooltipOpen={false}
              popupOpen={false}
              gray={false}
            />
          ) : (
            <MapVessel
              key={i}
              feature={v}
              isPublicMap={false}
              appliedZoom={zoom}
              tooltipOpen={false}
              popupOpen={false}
              gray={false}
            />
          );
        })}
      </>
    );
  };

  const createVesselsOverlayForCurrent = () => {
    let showableVessels = allVessels.filter(vessel => vessel.properties.imo === sideModalOpen.imo);

    return (
      <>
        {showableVessels.map((v, i) => (
          <CurrentMapVessel
            key={i}
            feature={v}
            isPublicMap={false}
            appliedZoom={zoom}
            tooltipOpen={false}
            popupOpen={false}
            gray={false}
          />
        ))}
      </>
    );
  };

  const toggleSeaPassage = e => {
    if (e) {
      setShowSeaPassage(portcall.sea_passage.route);
    } else {
      setShowSeaPassage(undefined);
    }
  };

  const createShowRouteSwitch = () => {
    return (
      <SwitchContainer aria-label="show-route-container">
        <Switch
          data-testid="map-show-route-switch"
          size="small"
          checked={showRoute}
          onChange={e => toggleSeaPassage(e)}
          aria-label="Routes"
        />
        <SwitchLabel>{t("Vessel's route")}</SwitchLabel> {/*eslint-disable-line quotes*/}
      </SwitchContainer>
    );
  };

  return (
    <>
      <ZoomButtonContainer buttonMargin={buttonMargin}>
        <ZoomButton data-testid="map-zoom-in" copy onClick={() => handleZoomButton(zoom + 1)}>
          <Icon type="zoom-in" />
        </ZoomButton>
        <ZoomButton data-testid="map-zoom-out" copy onClick={() => handleZoomButton(zoom - 1)}>
          <Icon type="zoom-out" />
        </ZoomButton>
      </ZoomButtonContainer>
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <WMSChart show={zoom >= 14} />
      {mapSize === 2 && createVesselsOverlay()}
      {mapSize === 1 && createVesselsOverlayForCurrent()}
      {mapSize === 2 && createShowRouteSwitch()}
      {!!seaPassage &&
        showRoute &&
        seaPassage.features.map((f, i) => {
          if (f.geometry.type === 'LineString') {
            return (
              <Polyline
                key={i}
                pathOptions={{
                  color: '#4990DD',
                  weight: '2',
                  opacity: '0.85',
                }}
                positions={L.GeoJSON.coordsToLatLngs(f.geometry.coordinates, 0)}
              />
            );
          } else if (f.geometry.type === 'Point') {
            return (
              <Circle
                key={i}
                center={L.GeoJSON.coordsToLatLng(f.geometry.coordinates)}
                pathOptions={{
                  color: '#4990DD',
                }}
                radius={6}
                stroke={true}
              />
            );
          }
        })}
    </>
  );
};

export default SeaPassageMapInnerContainer;
