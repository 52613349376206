import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import Icon from '../../ui/Icon';
import dayjs from 'dayjs';

const LineInputContainer = styled.div`
  width: ${props => (props.useOnlyTextWidth ? 'auto' : props.givenWidth ? props.givenWidth + 'px' : '100%')};
  height: ${props => !props.textArea && '26px'};
  margin-top: ${props => props.marginTop && '4px'};
  margin-right: ${props => props.marginRight && '4px'};
`;

const InputContainer = styled.div`
  display: flex;

  textarea {
    resize: none;
    height: 72px;
    width: 340px;
  }

  .ant-picker {
    border: 1px solid #4990dd;
    border-radius: 3px;
    outline: none;
    width: 100%;
    height: 26px;
    box-shadow: none;
    padding-left: 2px;
  }

  .ant-select {
    border: 1px solid #4990dd;
    border-radius: 3px;
    outline: none;
    width: 100%;
    height: 26px;
    box-shadow: none;
  }

  .ant-select-selection-search-input {
    height: 24px !important;
  }

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    height: 24px !important;
    padding-left: 6px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 5px !important;
  }

  .ant-select-selection-item {
    line-height: 24px !important;
  }
`;

const InputField = styled.input`
  border: 1px solid #4990dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
`;

const TextArea = styled.textarea`
  border: 1px solid #4990dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
`;

const ValueContainer = styled.div`
  border-bottom: ${props => !props.readOnly && props.bottomBorder && '1px solid #4a4a4a'};
  cursor: ${props => !props.readOnly && 'pointer'};
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 18px;
  margin-top: 2px;
  color: ${props => props.blue && '#4990dd'};
  width: ${props => props.templateAmount && '40px'};
`;

const Edit = styled.div`
  border-bottom: 1px solid #4a4a4a;
  cursor: ${props => (props.templateAmount ? 'default' : 'pointer')};
  min-width: 20px;
  display: inline-block;
  text-align: center;
  color: #4990dd;
  display: ${props => props.readOnly && !props.templateAmount && 'none'};

  i {
    display: ${props => props.templateAmount && 'none'};
  }
`;

const Customer = styled.div`
  display: inline-block;

  div {
    display: table;
  }
`;

const TextAreaContainer = styled.div`
  max-width: 500px;
  padding-bottom: 2px;
`;

const TextAreaText = styled.span`
  border-bottom: ${props => !props.readOnly && '1px solid #4a4a4a'};
  white-space: pre;
  overflow: hidden;
`;

const InvoiceInput = ({
  value,
  updateValue,
  readOnly = false,
  width,
  tooltipAvailable,
  makeFocus,
  moveNextFocus,
  movePrevFocus,
  removeMakeFocus,
  useOnlyTextWidth,
  onlyNumber = false,
  date = false,
  time = false,
  select,
  options = [],
  customer = null,
  blue = false,
  marginTop,
  templateAmount,
  textArea = false,
}) => {
  const [inputOpen, setInputOpen] = useState(false);
  const [uuidForClick] = useState(uuid());

  const inputRef = useRef();
  const textareaRef = useRef();
  const dateRef = useRef();
  const selectRef = useRef();
  const dropdownWrapperElementRef = useRef();

  useEffect(() => {
    if (makeFocus) {
      removeMakeFocus();
      setInputOpen(true);
      setTimeout(() => {
        inputRef.current?.focus({
          cursor: 'end',
        });
        dateRef.current?.focus({
          cursor: 'end',
        });
        selectRef.current?.focus({
          cursor: 'end',
        });
        textareaRef.current?.focus({
          cursor: 'end',
        });
      }, 100);
    }
  }, [makeFocus, readOnly, removeMakeFocus]);

  useEffect(() => {
    const handleClickOutside = event => {
      const input = document.querySelector('#invoice-input-' + uuidForClick);
      if (
        event.target.outerHTML.includes('invoice-input-container-' + uuidForClick) ||
        ((input ? !input.contains(event.target) : true) &&
          !(
            event.target.outerHTML.includes('ant-select-item-option-content') ||
            event.target.outerHTML.includes('ant-picker') ||
            (event.target.outerHTML.substring(0, 6) === '<span>' &&
              event.target.outerHTML.substring(event.target.outerHTML.length - 7, event.target.outerHTML.length - 0) ===
                '</span>')
          ))
      ) {
        setInputOpen(false);
      }
    };

    const handleTabPress = event => {
      if (event.shiftKey && event.keyCode == 9 && inputOpen) {
        event.preventDefault();
        setInputOpen(false);
        movePrevFocus?.();
      } else if (event.keyCode == 9 && inputOpen) {
        event.preventDefault();
        setInputOpen(false);
        moveNextFocus?.();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleTabPress, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleTabPress, true);
    };
  }, [inputOpen, moveNextFocus, movePrevFocus, uuidForClick]);

  const clickValue = () => {
    if (!readOnly) {
      setInputOpen(true);
      setTimeout(() => {
        inputRef.current?.focus({
          cursor: 'end',
        });
        dateRef.current?.focus({
          cursor: 'end',
        });
        selectRef.current?.focus({
          cursor: 'end',
        });
        textareaRef.current?.focus({
          cursor: 'end',
        });
      }, 100);
    }
  };

  const getActiveOpitonContentElement = rootElement => {
    if (rootElement) {
      const activeOptionElement = rootElement.getElementsByClassName('ant-select-item-option-active')?.[0];
      if (activeOptionElement) {
        const optionContentElement = activeOptionElement.getElementsByClassName('ant-select-item-option-content')?.[0];
        return optionContentElement;
      }
    }
    return null;
  };

  let selectValue = null;
  if (select && !customer) {
    selectValue = options.find(o => o.value == value)?.label;
  }

  return (
    <LineInputContainer
      id={'invoice-input-container-' + uuidForClick}
      givenWidth={width}
      useOnlyTextWidth={!inputOpen && useOnlyTextWidth}
      marginRight={useOnlyTextWidth}
      marginTop={marginTop}
      textArea={textArea}
    >
      {!inputOpen ? (
        !value || value.length === 0 ? (
          <Edit readOnly={readOnly} onClick={!templateAmount ? clickValue : null} templateAmount={templateAmount}>
            <Icon type="edit" />
          </Edit>
        ) : customer ? (
          <Customer>
            <ValueContainer readOnly={readOnly} onClick={clickValue} bottomBorder={true}>
              {customer.name}
            </ValueContainer>
            <ValueContainer readOnly={readOnly} onClick={clickValue} bottomBorder={true}>
              {customer.address}
            </ValueContainer>
            <ValueContainer readOnly={readOnly} onClick={clickValue} bottomBorder={true}>
              {customer.postalcode} {customer.city}
            </ValueContainer>
          </Customer>
        ) : (
          <Tooltip
            title={tooltipAvailable ? value : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px', whiteSpace: 'pre' }}
          >
            <ValueContainer readOnly={readOnly} onClick={clickValue} blue={blue} bottomBorder={!textArea}>
              {!textArea ? (
                (date || time) && value && value.length ? (
                  dayjs(value).format(time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
                ) : (
                  selectValue || value
                )
              ) : (
                <TextAreaContainer>
                  <TextAreaText readOnly={readOnly}>{value}</TextAreaText>
                </TextAreaContainer>
              )}
            </ValueContainer>
          </Tooltip>
        )
      ) : (
        <InputContainer id={'invoice-input-' + uuidForClick}>
          {(date || time) && (
            <DatePicker
              format={time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
              style={{
                width: '100%',
                color: '#4a4a4a',
              }}
              value={value ? dayjs(value) : null}
              onChange={value => updateValue(value ? value.format() : null)}
              onSelect={value => updateValue(value ? value.format() : null)}
              placeholder=""
              ref={dateRef}
              open={inputOpen}
              showTime={time ? { format: 'HH:mm', minuteStep: 1 } : false}
              onOk={() => setInputOpen(false)}
            />
          )}
          {select && (
            <Select
              showAction="focus"
              allowClear
              value={value}
              style={{ color: '#4a4a4a', width: '100%' }}
              onChange={(e, data) => {
                updateValue(e, data?.data);
              }}
              onSelect={(e, data) => {
                updateValue(e, data?.data);
              }}
              options={options}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              ref={selectRef}
              onInputKeyDown={e => {
                if (e.key === 'Enter' && dropdownWrapperElementRef.current) {
                  let activeOptionContentElement = getActiveOpitonContentElement(dropdownWrapperElementRef.current);
                  if (activeOptionContentElement && Array.isArray(options) && options.length > 0) {
                    const activeOption = options.find(el => {
                      return el['label'] === activeOptionContentElement.innerText;
                    });
                    if (activeOption) {
                      updateValue(activeOption.value, activeOption.data);
                    }
                  }
                  e.stopPropagation();
                }
              }}
              dropdownRender={originNode => {
                return <div ref={dropdownWrapperElementRef}>{originNode}</div>;
              }}
            />
          )}
          {textArea && (
            <TextArea type="text" ref={textareaRef} value={value || ''} onChange={e => updateValue(e.target.value)} />
          )}
          {!date && !time && !select && !textArea && (
            <InputField
              type={onlyNumber ? 'number' : 'text'}
              ref={inputRef}
              value={value || ''}
              onChange={e => updateValue(e.target.value)}
            />
          )}
        </InputContainer>
      )}
    </LineInputContainer>
  );
};

export default InvoiceInput;
