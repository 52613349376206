import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import Popover from 'antd/es/popover';
import DatePicker from 'antd/es/date-picker';
import { FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ColumnSelect = styled.div`
  margin: 2px 0 0 14px;
  display: flex;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 4px 4px 0px;
  border-radius: 3px;
  color: ${props => (props.active ? props.theme.color.secondary : props.theme.color.grey_dark)};
  background-color: ${props => (props.modalOpen ? '#f3f3f3' : 'transparent')};
  height: 28px;

  &:hover {
    background-color: #f8f8f8;
  }

  @media (max-width: 850px) {
    margin: 2px 0 0 0px;
  }
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -1px;

  svg {
    height: 18px;
    width: 18px;
    margin-top: 3px;
    margin-right: 4px;
  }
`;

const OuterContainer = styled.div`
  border-radius: 5px;
  background-color: white;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  width: 700px;
  max-width: calc(100vw - 400px);

  @media (max-width: 850px) {
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 220px);
  }
`;

const InnerContainer = styled.div`
  overflow: auto;
  padding: 4px 28px 10px 28px;
  display: inline-block;
  width: 100%;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: 100%;
  margin-right: 12px;
  margin-bottom: 12px;
  min-height: 70px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Tags = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 3px;
`;

const TagContainer = styled.div`
  margin: 2px 4px 2px 0;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 0 7px 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  padding: 6px 0px;
`;

const Column = styled.div`
  margin-right: ${props => (props.first ? '80px' : '0px')};
  width: calc((100% - 80px) / 2);

  @media (max-width: 850px) {
    margin-right: ${props => (props.first ? '10px' : '0px')};
    width: calc((100% - 10px) / 2);
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 24px;

  button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = styled(ButtonLight)`
  padding-left: 16px;
  padding-right: 16px;
`;

const Limits = styled.div`
  margin-bottom: 3px;
  display: flex;
`;

const Limit = styled.div`
  display: flex;
`;

const LimitContainer = styled.div`
  padding: 3px 6px;
  background-color: #f8f8f8;
  border-radius: 4px;
  display: inline-block;
`;

const LimitHeader = styled.div`
  display: flex;
  margin-right: 18px;
  margin-bottom: 4px;

  i {
    color: ${({ theme }) => theme.color.secondary};
    margin-left: 4px;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  @media (max-width: 850px) {
    max-width: calc(100% / 3);
  }
`;

const Value = styled.div`
  margin-left: 6px;
  font-weight: 600;
`;

const ExportButton = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
  margin-left: 12px;
  background-color: white;
  height: 32px;
  min-width: 90px;
  line-height: 30px;
  padding: 0 8px;
  text-align: center;

  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }

  @media (max-width: 850px) {
    margin-left: ${props => (props.index === 0 ? '0px' : '12px')};
  }
`;

const { Search } = Input;

const DataExportFilters = ({ filters, setFilters, fetchData, chosenExport, button, buttonClick, index }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [modalOpen, setModalOpen] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleModalChange = () => {
    setModalOpen(open => !open);
  };

  const updateDateTime = (name, value) => {
    const list = filters.map(f => {
      if (f.name !== name) {
        return f;
      } else {
        return { ...f, value };
      }
    });

    setFilters(list);
  };

  const updateInputValue = (name, value) => {
    const list = filters.map(f => {
      if (f.name !== name) {
        return f;
      } else {
        return { ...f, value: value.target.value };
      }
    });

    setFilters(list);
  };

  const removeTag = (name, value) => {
    const list = filters.map(f => {
      if (f.name !== name) {
        return f;
      } else {
        const newValues = [...f.values];
        const index = newValues.findIndex(item => value === item);
        newValues.splice(index, 1);
        return { ...f, values: newValues };
      }
    });

    setFilters(list);
  };

  const addInputValue = (name, value) => {
    if (!value || value.length === 0) {
      return;
    }

    const list = filters.map(f => {
      if (f.name !== name) {
        return f;
      } else {
        if (f.values.findIndex(v => v === value) > -1) {
          return { ...f, value: null };
        }

        let valueList = [...f.values, value];
        return { ...f, value: null, values: valueList };
      }
    });

    setFilters(list);
  };

  const clearAll = () => {
    const list = filters.map(f => {
      return { ...f, values: [], value: null };
    });

    setFilters(list);
  };

  const applyFilters = () => {
    fetchData();
    setModalOpen(false);
  };

  function disabledDate(current, type) {
    if (type === 'from') {
      const toValueObject = filters.find(f => f.name === 'to');

      if (toValueObject.value) {
        let toValue = toValueObject.value.clone();
        toValue = toValue
          .subtract(dayjs.duration(chosenExport.export_limit || 0).asMilliseconds(), 'milliseconds')
          .subtract(1, 'day');
        return current && current < toValue;
      }
    } else if (type === 'to') {
      const fromValueObject = filters.find(f => f.name === 'from');

      if (fromValueObject.value) {
        let fromValue = fromValueObject.value.clone();
        fromValue = fromValue
          .add(dayjs.duration(chosenExport.export_limit || 0).asMilliseconds(), 'milliseconds')
          .add(1, 'day');
        return current && current > fromValue;
      }
    }
  }

  const createFilteringObject = (filter, index) => {
    let name = '';

    if (filter.name) {
      name = filter.name.replace(/_/g, ' ');
      name = name.charAt(0).toUpperCase() + name.slice(1);
    }

    if (filter.type === 'datetime') {
      return (
        <RowContainer key={index}>
          <RowHeader>{t(name)}</RowHeader>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime={{ format: 'HH:mm', minuteStep: 15 }}
            value={filter.value ? dayjs(filter.value) : null}
            style={{
              width: '100%',
            }}
            onChange={value => updateDateTime(filter.name, value)}
            placeholder={t(name)}
            allowClear
            disabledDate={current => disabledDate(current, filter.name)}
          />
        </RowContainer>
      );
    } else {
      return (
        <RowContainer key={index}>
          <RowHeader>{t(name)}</RowHeader>
          <Search
            placeholder={t(name)}
            value={filter.value}
            onSearch={value => addInputValue(filter.name, value)}
            onChange={value => updateInputValue(filter.name, value)}
            enterButton={t('Add')}
          />
          <Tags>
            {filter.values.map((value, i) => (
              <TagContainer key={i}>
                <Tag closable open={true} onClose={() => removeTag(filter.name, value)}>
                  {value}
                </Tag>
              </TagContainer>
            ))}
          </Tags>
        </RowContainer>
      );
    }
  };

  const parseDuration = duration => {
    if (!duration) {
      return '-';
    }

    let durationWithMoment = dayjs.duration(duration);
    let durationString = '';

    if (durationWithMoment.years() > 0) {
      durationString += durationWithMoment.years() + ' ' + t('year(s)') + ' ';
    }
    if (durationWithMoment.months() > 0) {
      durationString += durationWithMoment.months() + ' ' + t('month(s)') + ' ';
    }
    if (durationWithMoment.days() > 0) {
      durationString += durationWithMoment.days() + ' ' + t('day(s)') + ' ';
    }
    if (durationWithMoment.hours() > 0) {
      durationString += durationWithMoment.hours() + ' ' + t('hour(s)') + ' ';
    }

    return durationString;
  };

  const columnSelect = (
    <OuterContainer>
      <InnerContainer>
        <Title>{!button ? t('Filters') : t(button.label)}</Title>
        <Limits>
          <LimitHeader>
            {t('Time span limits')}:
            <Tooltip
              placement="top"
              title={t(
                'The date picker restricts the selection to the export limit, and the preview handles automatically its own limit starting from the FROM value. The default time span is one week.'
              )}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a' }}
            >
              <div>
                <Icon type="info" />
              </div>
            </Tooltip>
          </LimitHeader>
          <LimitContainer>
            <Limit>
              {t('Preview')}: <Value>{parseDuration(chosenExport.view_limit)}</Value>
            </Limit>
            <Limit>
              {t('Export')}: <Value>{parseDuration(chosenExport.export_limit)}</Value>
            </Limit>
          </LimitContainer>
        </Limits>
        <ColumnContainer>
          <Column first={true}>
            {filters.filter((p, i) => i % 2 === 0).map((filter, index) => createFilteringObject(filter, index))}
          </Column>
          <Column first={false}>
            {filters.filter((p, i) => i % 2 === 1).map((filter, index) => createFilteringObject(filter, index))}
          </Column>
        </ColumnContainer>
        <Buttons>
          <Button cancel type="button" onClick={clearAll}>
            {t('Clear all')}
          </Button>
          {button ? (
            <Button
              type="button"
              onClick={() => {
                buttonClick(button);
                setModalOpen(false);
              }}
            >
              {t(button.label)}
            </Button>
          ) : (
            <Button type="button" onClick={applyFilters}>
              {t('Fetch data')}
            </Button>
          )}
        </Buttons>
      </InnerContainer>
    </OuterContainer>
  );

  return (
    <Popover
      open={modalOpen}
      onOpenChange={handleModalChange}
      trigger="click"
      content={columnSelect}
      placement={button ? 'bottomLeft' : 'bottom'}
    >
      {button ? (
        <ExportButton type="button" cancel index={index}>
          {t(button.label)}
        </ExportButton>
      ) : (
        <ColumnSelect active={!!filters && !!filters.find(f => !!f.value || f.values.length > 0)} modalOpen={modalOpen}>
          <IconContainer>
            <FilterOutlined />
          </IconContainer>
          {t('Filters')}
        </ColumnSelect>
      )}
    </Popover>
  );
};

export default DataExportFilters;
