import React, { useState } from 'react';
import { Marker, Tooltip, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';

import { getOptions } from './MapUtils';
import { onMarkerMouseOver } from './MapHelpers';

const MapCrane = ({ coordinates, name, appliedZoomLevel, index, craneAmount, setCraneClicked, endCoordinates }) => {
  const [direction, setDirection] = useState('top');

  const map = useMap();

  const options = getOptions();

  let iconSize =
    appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
      ? options.smallMarkerIconPixelSize
      : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
        ? options.mediumMarkerIconPixelSize
        : options.largeMarkerIconPixelSize;

  if (appliedZoomLevel < 13) {
    iconSize = 18;
  }

  const getCraneIcon = () => {
    return L.divIcon({
      html: `<div class="div-map-marker-vessel"><img src="${options.cranePlannedIcon}" 
        style="background-color:transparent;
          height:${iconSize}px;
          width:${iconSize}px;
          background-size:${iconSize - options.markerIconSpacingPixels}px;!important;"/></div>`,
      iconSize: [iconSize, iconSize],
      iconAnchor: [iconSize / 2, iconSize / 2],
      className: 'map-vessel-icon-class',
    });
  };

  const rightSideCranes = 1; // rightSide = 1, leftSide = -1
  const turnedCoordinates = L.GeoJSON.coordsToLatLngs(coordinates, 1)[0];

  const centerLat =
    (Math.max(...turnedCoordinates.map(item => item.lat)) + Math.min(...turnedCoordinates.map(item => item.lat))) / 2;
  const centerLng =
    (Math.max(...turnedCoordinates.map(item => item.lng)) + Math.min(...turnedCoordinates.map(item => item.lng))) / 2;

  const lngWidthMove = (turnedCoordinates[3].lat - turnedCoordinates[4].lat) * 2 * rightSideCranes;

  const latWidthMove = (turnedCoordinates[3].lng - turnedCoordinates[4].lng) * 2 * rightSideCranes;

  const bottomCenter = [
    (turnedCoordinates[3].lat + turnedCoordinates[4].lat) / 2,
    (turnedCoordinates[3].lng + turnedCoordinates[4].lng) / 2,
  ];

  const latLengthMove = turnedCoordinates[1].lat - bottomCenter[0];
  const lngLengthMove = turnedCoordinates[1].lng - bottomCenter[1];

  const getPosition = () => {
    if (craneAmount === 1) {
      return L.GeoJSON.coordsToLatLng([centerLng + lngWidthMove, centerLat + latWidthMove]);
    } else {
      if (index === 0) {
        return L.GeoJSON.coordsToLatLng([bottomCenter[1] + lngWidthMove, bottomCenter[0] + latWidthMove]);
      } else {
        return L.GeoJSON.coordsToLatLng([
          bottomCenter[1] + (lngLengthMove / (craneAmount - 1)) * index + lngWidthMove,
          bottomCenter[0] + (latLengthMove / (craneAmount - 1)) * index + latWidthMove,
        ]);
      }
    }
  };

  const getTooltipOffset = () => {
    switch (direction) {
      case 'top':
        return L.point(0, -iconSize);
      case 'bottom':
        return L.point(0, +iconSize);
      case 'left':
        return L.point(-iconSize, 0);
      case 'right':
        return L.point(iconSize, 0);

      default:
        return L.point(0, -iconSize);
    }
  };

  return (
    <>
      <Marker
        icon={getCraneIcon()}
        position={getPosition()}
        data-id="crane"
        eventHandlers={{
          mouseover: () => {
            setCraneClicked(name);
            onMarkerMouseOver(map, [getPosition().lng, getPosition().lat], direction, setDirection);
          },
          mouseout: () => setCraneClicked(undefined),
        }}
      >
        {!!name && (
          <Tooltip direction={direction} key={`${name}-${direction}`} offset={getTooltipOffset()}>
            <h4>{name}</h4>
          </Tooltip>
        )}
      </Marker>
      {!!endCoordinates && (
        <Polyline
          pathOptions={{ color: 'white', dashArray: '6 9', dashOffset: '0', weight: 2 }}
          positions={[getPosition(), L.GeoJSON.coordsToLatLng(endCoordinates)]}
        />
      )}
    </>
  );
};

export default MapCrane;
