import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Icon from './ui/Icon';
import { mobilePixelMaxWidthLimit } from '../utils/constants';
import { NotificationContext } from '../context/NotificationContext';

const ButtonContainer = styled.div`
  width: 30px;
  margin-right: 24px;
  position: relative;
  cursor: pointer;

  svg {
    height: 28px;
    width: 24px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-right: 18px;
    width: 24px;
  }
`;

const CountIndicator = styled.div`
  background-color: ${props => props.theme.color.warning};
  color: white;
  width: 26px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 35%;
  position: absolute;
  top: -6px;
  right: -9px;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    font-size: 11px;
    width: 22px;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    fill: white !important;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    svg {
      height: 22px;
      width: 22px;
    }
  }
`;

const IncidentSummaryButton = () => {
  const { incidentSummary } = useContext(NotificationContext);

  const history = useHistory();

  const count = incidentSummary ? incidentSummary.unread : 0;

  const onClick = () => {
    history.push('/incidents-and-events');
  };

  return (
    <ButtonContainer onClick={onClick}>
      <StyledIcon type="exclamation-triangle" />
      {count > 0 && <CountIndicator>{count}</CountIndicator>}
    </ButtonContainer>
  );
};

export default IncidentSummaryButton;
