import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Switch from 'antd/es/switch';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../../components/ui/ButtonLight';
import Icon from '../../components/ui/Icon';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  padding: 12px 12px 0 12px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 400px;
  position: relative;
`;

const RowContainerSplit = styled.div`
  padding: 0 0 18px 0;
  display: flex;

  > :first-child {
    margin-right: 16px;
  }
`;

const RowContainerSplitSplit = styled.div`
  width: calc(50% - 8px);
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  display: inline-flex;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  min-height: 317px;
  padding: 6px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;

  svg {
    height: 14px;
    width: 14px;
    margin-left: 10px;
    margin-top: -2px;
  }
`;

const ErrorContainer = styled.div`
  font-size: 11px;
  color: #ff0000;
  height: 8px;
  margin-left: 3px;
  min-width: 10px;
`;

const BerthSettingsModal = ({ berth, closeModal, onDataSaved }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const times = [
    'live_eta_alert_delay',
    'live_eta_alert_tolerance',
    'slot_etd_tolerance',
    'slot_laytime_buffer_duration',
    'slot_request_in_advance_max_time_limit',
    'slot_request_in_advance_time_limit',
    'slot_rta_window_duration',
    'travel_duration',
  ];

  const createInputData = data => {
    const settings = { operator_emails: data['operator_emails'] || '', reservable: data['reservable'] };

    Object.keys(data).forEach(input => {
      if (times.includes(input)) {
        if (data[input].length > 0) {
          settings[input] = dayjs.duration(data[input]).format('HH:mm', { trim: false });
        } else {
          settings[input] = '';
        }
      }
    });

    return settings;
  };

  const createErrorData = data => {
    const settings = {};

    Object.keys(data).forEach(input => {
      if (times.includes(input)) {
        settings[input] = false;
      }
    });

    return settings;
  };

  const [inputData, setInputData] = useState(createInputData(berth.settings));
  const [errorData, setErrorData] = useState(createErrorData(berth.settings));

  const sendData = async () => {
    const settings = {};

    Object.keys(inputData).forEach(input => {
      if (times.includes(input)) {
        settings[input] = inputData[input].length > 0 ? dayjs.duration(inputData[input]).toISOString() : '';
      }

      if (input === 'operator_emails') {
        settings[input] = inputData[input];
      }
    });

    const result = await apiCall('put', 'berth-setting', {
      ...settings,
      reservable: inputData.reservable,
      berth_code: berth.settings.berth_code,
    });

    if (result?.status === 200) {
      onDataSaved();
      closeModal();
    }
  };

  const handleReservableChange = checked => {
    let newInputData = { ...inputData };
    newInputData['reservable'] = checked;
    setInputData(newInputData);
  };

  const handleChange = (e, name) => {
    let newInputData = { ...inputData };
    newInputData[name] = e.target.value;

    if (times.includes(name)) {
      let newErrorData = { ...errorData };

      if (e.target.value?.length > 0) {
        let invalid = false;
        let parts = e.target.value.split(':');

        if (parts.length !== 2) {
          invalid = true;
        }

        if (!invalid) {
          let numberRegex = /^\d+$/;

          if (!numberRegex.test(parts[0]) || !numberRegex.test(parts[1])) {
            invalid = true;
          }
        }
        newErrorData[name] = invalid;
      } else {
        newErrorData[name] = false;
      }
      setErrorData(newErrorData);
    }

    setInputData(newInputData);
  };

  const isDataValid = () => {
    let valid = true;
    times.forEach(time => {
      if (inputData[time].length > 0) {
        let parts = inputData[time].split(':');

        if (parts.length !== 2) {
          valid = false;
        }

        if (valid) {
          let numberRegex = /^\d+$/;

          if (!numberRegex.test(parts[0]) || !numberRegex.test(parts[1])) {
            valid = false;
          }
        }
      }
    });

    return valid;
  };

  return (
    <Modal
      title={t('Berth settings for {{berth}} ({{code}})', { berth: berth.name, code: berth.code })}
      open={true}
      width={760}
      onCancel={closeModal}
      footer={null}
    >
      <ModalInnerInput>
        <ScrollContainer>
          <RowContainerSplit style={{ marginBottom: '18px' }}>
            <RowHeader>{t('Reservable')}</RowHeader>
            <div>
              <Switch checked={inputData.reservable} onChange={checked => handleReservableChange(checked)} />
            </div>
          </RowContainerSplit>
          <RowContainerSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('How long does it take from ship to travel from outer port area to berth. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('Travel duration to berth')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.travel_duration || ''}
                onChange={e => handleChange(e, 'travel_duration')}
              />
              {errorData.travel_duration ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('RTA window offered to ship. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('RTA window duration')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.slot_rta_window_duration || ''}
                onChange={e => handleChange(e, 'slot_rta_window_duration')}
              />
              {errorData.slot_rta_window_duration ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
          </RowContainerSplit>
          <RowContainerSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('Time automatically added to laytime to compensate for possible delays. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('Laytime buffer duration')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.slot_laytime_buffer_duration || ''}
                onChange={e => handleChange(e, 'slot_laytime_buffer_duration')}
              />
              {errorData.slot_laytime_buffer_duration ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t(
                  'How much JIT ETA can be late compared to live ETA until ship is considered to be late. (HH:mm)'
                )}
                placement="top"
              >
                <RowHeader>
                  {t('Live ETA alert tolerance')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.live_eta_alert_tolerance || ''}
                onChange={e => handleChange(e, 'live_eta_alert_tolerance')}
              />
              {errorData.live_eta_alert_tolerance ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
          </RowContainerSplit>
          <RowContainerSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('How long ship can be late until alert is triggered. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('Live ETA alert delay')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.live_eta_alert_delay || ''}
                onChange={e => handleChange(e, 'live_eta_alert_delay')}
              />
              {errorData.live_eta_alert_delay ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('How much ETD can change before queue is altered. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('ETD tolerance')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.slot_etd_tolerance || ''}
                onChange={e => handleChange(e, 'slot_etd_tolerance')}
              />
              {errorData.slot_etd_tolerance ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
          </RowContainerSplit>
          <RowContainerSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('How much in advance slot request can be made before putting it to pre-advice queue. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('Slot request in advance time limit')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.slot_request_in_advance_time_limit || ''}
                onChange={e => handleChange(e, 'slot_request_in_advance_time_limit')}
              />
              {errorData.slot_request_in_advance_time_limit ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('How much in advance slot request can be made before it is rejected. (HH:mm)')}
                placement="top"
              >
                <RowHeader>
                  {t('Slot request in advance maximum time limit')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={'HH:mm'}
                value={inputData.slot_request_in_advance_max_time_limit || ''}
                onChange={e => handleChange(e, 'slot_request_in_advance_max_time_limit')}
              />
              {errorData.slot_request_in_advance_max_time_limit ? (
                <ErrorContainer>{t('The input format is HH:mm.')}</ErrorContainer>
              ) : (
                <ErrorContainer />
              )}
            </RowContainerSplitSplit>
          </RowContainerSplit>
          <RowContainerSplit>
            <RowContainerSplitSplit>
              <Tooltip
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
                title={t('Live ETA alerts and automatic berth block shifts are sent to these emails.')}
                placement="top"
              >
                <RowHeader>
                  {t('Berth operator emails (comma separated list)')}
                  <IconContainer>
                    <Icon type="circle-question-regular"></Icon>
                  </IconContainer>
                </RowHeader>
              </Tooltip>
              <Input
                placeholder={t('Berth operator emails')}
                value={inputData.operator_emails || ''}
                onChange={e => handleChange(e, 'operator_emails')}
              />
            </RowContainerSplitSplit>
          </RowContainerSplit>
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight disabled={!isDataValid()} onClick={sendData}>
            {t('Save')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default BerthSettingsModal;
