import React, { createContext, useCallback, useEffect, useContext } from 'react';
import useApi from '../hooks/useApi';
import App from 'antd/es/app';
import { UserContext } from './UserContext';

export const CraneContext = createContext();

export const CraneProvider = ({ children }) => {
  const { modules, user } = useContext(UserContext);
  const { message } = App.useApp();

  const { loading, data, error, fetchData } = useApi(
    'get',
    'pbp/live-locations',
    {},
    null,
    modules &&
      modules.predictive_berth_planning_module === 'enabled' &&
      user.permissions.includes('view_crane_reservations')
  );

  const reFetch = useCallback(() => {
    if (
      modules &&
      modules.predictive_berth_planning_module === 'enabled' &&
      user.permissions.includes('view_crane_reservations')
    ) {
      fetchData();
    }
  }, [fetchData, modules, user.permissions]);

  useEffect(() => {
    const timer = setInterval(reFetch, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, [reFetch]);

  if (error) {
    message.error(error, 4);
  }

  const cranes = error ? [] : data?.cranes?.data || [];
  const cranesUpdated = error ? null : data?.cranes?.updated;

  return (
    <CraneContext.Provider
      value={{
        cranes,
        cranesUpdated,
        loading,
        cranesAvailable: modules?.predictive_berth_planning_module === 'enabled',
      }}
    >
      {children}
    </CraneContext.Provider>
  );
};
