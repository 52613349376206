import React from 'react';
import styled from 'styled-components';

import ClearMoon from '../../images/icons/weather2/clear_moon.svg?react';
import ClearSun from '../../images/icons/weather2/clear_sun.svg?react';
import Fog from '../../images/icons/weather2/fog.svg?react';

import HeavyFreezingRain from '../../images/icons/weather2/overcast/heavy_freezing_rain.svg?react';
import HeavyRainSnow from '../../images/icons/weather2/overcast/heavy_rain_snow.svg?react';
import HeavyRain from '../../images/icons/weather2/overcast/heavy_rain.svg?react';
import HeavySleet from '../../images/icons/weather2/overcast/heavy_sleet.svg?react';
import HeavySnow from '../../images/icons/weather2/overcast/heavy_snow.svg?react';
import HeavyThunderstorms from '../../images/icons/weather2/overcast/heavy_thunderstorms.svg?react';

import LightFreezingRain from '../../images/icons/weather2/overcast/light_freezing_rain.svg?react';
import LightRainSnow from '../../images/icons/weather2/overcast/light_rain_snow.svg?react';
import LightRain from '../../images/icons/weather2/overcast/light_rain.svg?react';
import LightSleet from '../../images/icons/weather2/overcast/light_sleet.svg?react';
import LightSnow from '../../images/icons/weather2/overcast/light_snow.svg?react';
import LightThunderstorms from '../../images/icons/weather2/overcast/light_thunderstorms.svg?react';

import ModerateFreezingRain from '../../images/icons/weather2/overcast/moderate_freezing_rain.svg?react';
import ModerateRainSnow from '../../images/icons/weather2/overcast/moderate_rain_snow.svg?react';
import ModerateRain from '../../images/icons/weather2/overcast/moderate_rain.svg?react';
import ModerateSleet from '../../images/icons/weather2/overcast/moderate_sleet.svg?react';
import ModerateSnow from '../../images/icons/weather2/overcast/moderate_snow.svg?react';
import ModerateThunderstorms from '../../images/icons/weather2/overcast/moderate_thunderstorms.svg?react';

import MostlyClearMoon from '../../images/icons/weather2/mostly_clear_moon.svg?react';
import MostlyClearSun from '../../images/icons/weather2/mostly_clear_sun.svg?react';
import MostlyClear from '../../images/icons/weather2/mostly_clear.svg?react';

import MostlyCloudyMoon from '../../images/icons/weather2/mostly_cloudy_moon.svg?react';
import MostlyCloudySun from '../../images/icons/weather2/mostly_cloudy_sun.svg?react';
import MostlyCloudy from '../../images/icons/weather2/mostly_cloudy.svg?react';

import Overcast from '../../images/icons/weather2/overcast.svg?react';

import PartlyClearMoon from '../../images/icons/weather2/partly_clear_moon.svg?react';
import PartlyClearSun from '../../images/icons/weather2/partly_clear_sun.svg?react';
import PartlyClear from '../../images/icons/weather2/partly_clear.svg?react';

import PartlyCloudyMoon from '../../images/icons/weather2/partly_cloudy_moon.svg?react';
import PartlyCloudySun from '../../images/icons/weather2/partly_cloudy_sun.svg?react';
import PartlyCloudy from '../../images/icons/weather2/partly_cloudy.svg?react';

import HeavyFreezingRainMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_freezing_rain.svg?react';
import HeavyRainSnowMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_rain_snow.svg?react';
import HeavyRainMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_rain.svg?react';
import HeavySleetMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_sleet.svg?react';
import HeavySnowMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_snow.svg?react';
import HeavyThunderstormsMostlyClear from '../../images/icons/weather2/mostly_clear/heavy_thunderstorms.svg?react';

import LightFreezingRainMostlyClear from '../../images/icons/weather2/mostly_clear/light_freezing_rain.svg?react';
import LightRainSnowMostlyClear from '../../images/icons/weather2/mostly_clear/light_rain_snow.svg?react';
import LightRainMostlyClear from '../../images/icons/weather2/mostly_clear/light_rain.svg?react';
import LightSleetMostlyClear from '../../images/icons/weather2/mostly_clear/light_sleet.svg?react';
import LightSnowMostlyClear from '../../images/icons/weather2/mostly_clear/light_snow.svg?react';
import LightThunderstormsMostlyClear from '../../images/icons/weather2/mostly_clear/light_thunderstorms.svg?react';

import ModerateFreezingRainMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_freezing_rain.svg?react';
import ModerateRainSnowMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_rain_snow.svg?react';
import ModerateRainMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_rain.svg?react';
import ModerateSleetMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_sleet.svg?react';
import ModerateSnowMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_snow.svg?react';
import ModerateThunderstormsMostlyClear from '../../images/icons/weather2/mostly_clear/moderate_thunderstorms.svg?react';

import HeavyFreezingRainPartlyClear from '../../images/icons/weather2/partly_clear/heavy_freezing_rain.svg?react';
import HeavyRainSnowPartlyClear from '../../images/icons/weather2/partly_clear/heavy_rain_snow.svg?react';
import HeavyRainPartlyClear from '../../images/icons/weather2/partly_clear/heavy_rain.svg?react';
import HeavySleetPartlyClear from '../../images/icons/weather2/partly_clear/heavy_sleet.svg?react';
import HeavySnowPartlyClear from '../../images/icons/weather2/partly_clear/heavy_snow.svg?react';
import HeavyThunderstormsPartlyClear from '../../images/icons/weather2/partly_clear/heavy_thunderstorms.svg?react';

import LightFreezingRainPartlyClear from '../../images/icons/weather2/partly_clear/light_freezing_rain.svg?react';
import LightRainSnowPartlyClear from '../../images/icons/weather2/partly_clear/light_rain_snow.svg?react';
import LightRainPartlyClear from '../../images/icons/weather2/partly_clear/light_rain.svg?react';
import LightSleetPartlyClear from '../../images/icons/weather2/partly_clear/light_sleet.svg?react';
import LightSnowPartlyClear from '../../images/icons/weather2/partly_clear/light_snow.svg?react';
import LightThunderstormsPartlyClear from '../../images/icons/weather2/partly_clear/light_thunderstorms.svg?react';

import ModerateFreezingRainPartlyClear from '../../images/icons/weather2/partly_clear/moderate_freezing_rain.svg?react';
import ModerateRainSnowPartlyClear from '../../images/icons/weather2/partly_clear/moderate_rain_snow.svg?react';
import ModerateRainPartlyClear from '../../images/icons/weather2/partly_clear/moderate_rain.svg?react';
import ModerateSleetPartlyClear from '../../images/icons/weather2/partly_clear/moderate_sleet.svg?react';
import ModerateSnowPartlyClear from '../../images/icons/weather2/partly_clear/moderate_snow.svg?react';
import ModerateThunderstormsPartlyClear from '../../images/icons/weather2/partly_clear/moderate_thunderstorms.svg?react';

import HeavyFreezingRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_freezing_rain.svg?react';
import HeavyRainSnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_rain_snow.svg?react';
import HeavyRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_rain.svg?react';
import HeavySleetMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_sleet.svg?react';
import HeavySnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_snow.svg?react';
import HeavyThunderstormsMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/heavy_thunderstorms.svg?react';

import LightFreezingRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_freezing_rain.svg?react';
import LightRainSnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_rain_snow.svg?react';
import LightRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_rain.svg?react';
import LightSleetMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_sleet.svg?react';
import LightSnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_snow.svg?react';
import LightThunderstormsMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/light_thunderstorms.svg?react';

import ModerateFreezingRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_freezing_rain.svg?react';
import ModerateRainSnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_rain_snow.svg?react';
import ModerateRainMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_rain.svg?react';
import ModerateSleetMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_sleet.svg?react';
import ModerateSnowMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_snow.svg?react';
import ModerateThunderstormsMostlyCloudy from '../../images/icons/weather2/mostly_cloudy/moderate_thunderstorms.svg?react';

import HeavyFreezingRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_freezing_rain.svg?react';
import HeavyRainSnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_rain_snow.svg?react';
import HeavyRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_rain.svg?react';
import HeavySleetPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_sleet.svg?react';
import HeavySnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_snow.svg?react';
import HeavyThunderstormsPartlyCloudy from '../../images/icons/weather2/partly_cloudy/heavy_thunderstorms.svg?react';

import LightFreezingRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_freezing_rain.svg?react';
import LightRainSnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_rain_snow.svg?react';
import LightRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_rain.svg?react';
import LightSleetPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_sleet.svg?react';
import LightSnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_snow.svg?react';
import LightThunderstormsPartlyCloudy from '../../images/icons/weather2/partly_cloudy/light_thunderstorms.svg?react';

import ModerateFreezingRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_freezing_rain.svg?react';
import ModerateRainSnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_rain_snow.svg?react';
import ModerateRainPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_rain.svg?react';
import ModerateSleetPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_sleet.svg?react';
import ModerateSnowPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_snow.svg?react';
import ModerateThunderstormsPartlyCloudy from '../../images/icons/weather2/partly_cloudy/moderate_thunderstorms.svg?react';

import WeatherIcon from './WeatherIcon';

const Wrapper = styled.i`
  height: 1em;
  width: 1em;
  position: relative;
  display: inline-block;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const WeatherIconCombined = ({ description, descriptionObj, rotation, ...props }) => {
  const getNightDayBaseIcon = () => {
    if (descriptionObj.base_type === 'moon') {
      if (descriptionObj.cloudiness_type === 'clear') {
        return <ClearMoon />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <MostlyClearMoon />;
      } else if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <PartlyClearMoon />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <PartlyCloudyMoon />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <MostlyCloudyMoon />;
      }
    } else if (descriptionObj.base_type === 'sun') {
      if (descriptionObj.cloudiness_type === 'clear') {
        return <ClearSun />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <MostlyClearSun />;
      } else if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <PartlyClearSun />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <PartlyCloudySun />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <MostlyCloudySun />;
      }
    }

    return null;
  };

  const getCloudTypeIcon = () => {
    if (descriptionObj.cloudiness_type === 'mostly_clear') {
      return <MostlyClear />;
    } else if (descriptionObj.cloudiness_type === 'partly_clear') {
      return <PartlyClear />;
    } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
      return <PartlyCloudy />;
    } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
      return <MostlyCloudy />;
    } else if (descriptionObj.cloudiness_type === 'overcast') {
      return <Overcast />;
    }

    return null;
  };

  const getThunderTypeIcon = () => {
    if (descriptionObj.thunder_type === 'light_thunderstorms') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightThunderstormsPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightThunderstormsPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightThunderstormsMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightThunderstormsMostlyClear />;
      } else {
        return <LightThunderstorms />;
      }
    } else if (descriptionObj.thunder_type === 'moderate_thunderstorms') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateThunderstormsPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateThunderstormsPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateThunderstormsMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateThunderstormsMostlyClear />;
      } else {
        return <ModerateThunderstorms />;
      }
    } else if (descriptionObj.thunder_type === 'heavy_thunderstorms') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavyThunderstormsPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavyThunderstormsPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavyThunderstormsMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavyThunderstormsMostlyClear />;
      } else {
        return <HeavyThunderstorms />;
      }
    }

    return null;
  };

  const getRainTypeIcon = () => {
    if (descriptionObj.rain_type === 'light_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightRainMostlyClear />;
      } else {
        return <LightRain />;
      }
    } else if (descriptionObj.rain_type === 'moderate_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateRainMostlyClear />;
      } else {
        return <ModerateRain />;
      }
    } else if (descriptionObj.rain_type === 'heavy_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavyRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavyRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavyRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavyRainMostlyClear />;
      } else {
        return <HeavyRain />;
      }
    } else if (descriptionObj.rain_type === 'light_freezing_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightFreezingRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightFreezingRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightFreezingRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightFreezingRainMostlyClear />;
      } else {
        return <LightFreezingRain />;
      }
    } else if (descriptionObj.rain_type === 'moderate_freezing_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateFreezingRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateFreezingRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateFreezingRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateFreezingRainMostlyClear />;
      } else {
        return <ModerateFreezingRain />;
      }
    } else if (descriptionObj.rain_type === 'heavy_freezing_rain') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavyFreezingRainPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavyFreezingRainPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavyFreezingRainMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavyFreezingRainMostlyClear />;
      } else {
        return <HeavyFreezingRain />;
      }
    } else if (descriptionObj.rain_type === 'light_sleet') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightSleetPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightSleetPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightSleetMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightSleetMostlyClear />;
      } else {
        return <LightSleet />;
      }
    } else if (descriptionObj.rain_type === 'moderate_sleet') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateSleetPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateSleetPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateSleetMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateSleetMostlyClear />;
      } else {
        return <ModerateSleet />;
      }
    } else if (descriptionObj.rain_type === 'heavy_sleet') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavySleetPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavySleetPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavySleetMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavySleetMostlyClear />;
      } else {
        return <HeavySleet />;
      }
    } else if (descriptionObj.rain_type === 'light_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightSnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightSnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightSnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightSnowMostlyClear />;
      } else {
        return <LightSnow />;
      }
    } else if (descriptionObj.rain_type === 'moderate_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateSnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateSnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateSnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateSnowMostlyClear />;
      } else {
        return <ModerateSnow />;
      }
    } else if (descriptionObj.rain_type === 'heavy_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavySnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavySnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavySnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavySnowMostlyClear />;
      } else {
        return <HeavySnow />;
      }
    } else if (descriptionObj.rain_type === 'light_rain_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <LightRainSnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <LightRainSnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <LightRainSnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <LightRainSnowMostlyClear />;
      } else {
        return <LightRainSnow />;
      }
    } else if (descriptionObj.rain_type === 'moderate_rain_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <ModerateRainSnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <ModerateRainSnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <ModerateRainSnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <ModerateRainSnowMostlyClear />;
      } else {
        return <ModerateRainSnow />;
      }
    } else if (descriptionObj.rain_type === 'heavy_rain_snow') {
      if (descriptionObj.cloudiness_type === 'partly_clear') {
        return <HeavyRainSnowPartlyClear />;
      } else if (descriptionObj.cloudiness_type === 'partly_cloudy') {
        return <HeavyRainSnowPartlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_cloudy') {
        return <HeavyRainSnowMostlyCloudy />;
      } else if (descriptionObj.cloudiness_type === 'mostly_clear') {
        return <HeavyRainSnowMostlyClear />;
      } else {
        return <HeavyRainSnow />;
      }
    } else if (descriptionObj.rain_type === 'fog') {
      return <Fog />;
    }

    return null;
  };

  if (descriptionObj) {
    return (
      <Wrapper>
        {getNightDayBaseIcon()}
        {getCloudTypeIcon()}
        {getRainTypeIcon()}
        {getThunderTypeIcon()}
      </Wrapper>
    );
  } else {
    return <WeatherIcon type={description} rotation={rotation} {...props} />;
  }
};

export default WeatherIconCombined;
