import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from '../ui/Icon';
import { InvoicingContext } from '../../context/InvoicingContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 20px;

  i {
    width: 20px;
  }
`;

const Category = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const ColorRectangle = styled.div`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
`;

const Categories = () => {
  const { setIsPricingCategoriesModalVisible, selectedNetTonnage, categories, categoryData } = useContext(
    InvoicingContext
  );

  return (
    <Container>
      {categoryData[selectedNetTonnage] &&
        Object.values(categories).map((category, index) => (
          <Category key={index}>
            <ColorRectangle color={category.color} />
            {`${category.description} (${categoryData[selectedNetTonnage][category.id].price}€)`}
          </Category>
        ))}
      <Icon
        type="edit-outline"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsPricingCategoriesModalVisible(old => !old)}
      />
    </Container>
  );
};

export default Categories;
