import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { Chart, registerables } from 'chart.js';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import MapWithRouteMap from './MapWithRouteMap';
import SpeedModalTimeGraphMini from '../activity/SpeedModalTimeGraphMini';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 320px);
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${props => (props.inModal ? null : props.theme.fx.box_shadow)};
  border-radius: ${props => (props.inModal ? null : props.theme.style.border_radius)};
  border: ${props => (props.inModal ? null : '1px solid transparent')};
  padding: 0 12px;
  margin-bottom: 14px;

  .ant-select-show-arrow {
    height: 24px !important;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
  padding-right: 4px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const EndOfFirstRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const MapWithRoute = ({ portcallId, mmsi, inModal = false, showGraph = false, markerData, ...props }) => {
  const buildQueryParams = () => {
    if (portcallId) {
      return {
        ...props.data_source.parameters,
        port_call_id: portcallId,
      };
    } else {
      return {
        ...props.data_source.parameters,
        mmsi: mmsi,
      };
    }
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [mapHoverData, setMapHoverData] = useState(undefined);
  const [graphHoverData, setGraphHoverData] = useState(undefined);

  const { loading, data, error } = useApi('get', props.data_source.url, buildQueryParams());

  let dataList = error || !data ? [] : data.data;
  const auxData = error || !data ? {} : data.aux_data;

  return (
    <Container inModal={inModal}>
      <Graph>
        {!inModal ? (
          <HeaderRow>
            <Header>{t('Speed')}</Header>
            <EndOfFirstRow>
              {loading && (
                <MiniLoader>
                  <Spin size="small" />
                </MiniLoader>
              )}
            </EndOfFirstRow>
          </HeaderRow>
        ) : null}

        {loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : dataList && dataList.length > 0 ? (
          <>
            <MapWithRouteMap
              dataList={dataList}
              showGraph={showGraph}
              hoverData={graphHoverData}
              setHoverData={setMapHoverData}
              markerData={markerData}
            />
            {showGraph && (
              <SpeedModalTimeGraphMini
                auxData={auxData}
                dataList={dataList}
                hoverData={mapHoverData}
                setHoverData={setGraphHoverData}
                {...props}
              />
            )}
          </>
        ) : (
          <NoData>{t('No speed data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default MapWithRoute;
