import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import StatisticsGraph from '../components/statistics/StatisticsGraphs';

const StatisticsPage = styled(Page)`
  background-color: #f8f8f8;
`;

const Statistics = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Statistics | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Statistics')}>
      <StatisticsPage fullWidth>
        <StatisticsGraph />
      </StatisticsPage>
    </Layout>
  );
};

export default Statistics;
