import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const ColorSquare = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 8px;

  ${props => {
    if (props.colorBlindOption) {
      // reserved
      if (props.colorBlindOption === '#FFAB91') {
        return `
          background: repeating-linear-gradient(90deg,
            #FFAB91,
            #FFAB91 2px,
            #ffd2c7 2px,
            #ffd2c7 4px);
        `;
        // accepted
      } else if (props.colorBlindOption === '#A5D6A7') {
        return `
          border: 1px solid #39543a;
        `;
        // offered
      } else if (props.colorBlindOption === '#FFE77C') {
        return `
          border: 1px dotted #5c4c04;
        `;
        // updated
      } else if (props.colorBlindOption === '#d5b5eb') {
        return `
          border: 1px dashed #5e486e;
        `;
      }
    }
  }};
`;

const TextAndAmount = styled.div`
  font-size: 11.5px;
  color: ${({ theme }) => theme.color.grey};
  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 10.5px;
  }
`;

const ColorIndicator = ({ amount, color, text, colorBlindOption }) => {
  return (
    <Container>
      <ColorSquare color={color} colorBlindOption={colorBlindOption} />
      <TextAndAmount>
        {text} (<span style={{ fontWeight: 700 }}>{amount ? amount : '-'}</span>)
      </TextAndAmount>
    </Container>
  );
};

export default ColorIndicator;
