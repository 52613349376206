import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LandingPageLeftInfoPanel from './LandingPageLeftInfoPanel';
import LandingPageSignInPanel from './LandingPageSignInPanel';
import LandingPageSignUpPanel from './LandingPageSignUpPanel';

import EUInterreg from '../../images/footers/eu-interreg-new.jpg';
import UnikieLogoImg from '../../images/footers/Unikie_logo.png';
import LogoFintraffic from '../../images/logo-fintraffic.svg?react';
import Footer from '../../images/footers/Footer-Main.svg?react';
import LogoImage from '../../images/logo.svg?react';

import useLogin from '../../views/UseLogin';

import Lighthouse from './lighthouse.png';

import LogoAppStore from '../../images/LogoAppStore.svg?react';
import LogoPlayStore from '../../images/LogoPlayStore.png';

const LandingContainer = styled.div`
  background-color: white;
  position: relative;
`;

const Web = styled.div`
  position: relative;

  @media (min-width: 900px) {
    display: block;
  }
  @media (max-width: 899px) {
    display: none;
  }

  @media (max-height: 700px) {
    min-height: 700px;
  }
`;

const Mobile = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;

  @media (min-width: 900px) {
    display: none;
  }
  @media (max-width: 899px) {
    display: block;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100vh;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-height: 700px) {
    height: 700px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100vh;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100vh - 40px);
  background-color: ${({ theme }) => theme.color.dark_blue};

  @media (max-height: 700px) {
    top: 700px;
  }
`;

const FintrafficLine = styled.div`
  width: 100%;
  overflow: hidden;
  height: 40px;
  background-color: ${({ theme }) => theme.color.dark_blue};
  display: flex;
  justify-content: center;

  @media (max-width: 899px) {
    background-color: transparent;
    position: absolute;
    top: calc(100vh - 54px);
    height: 54px;
    background-color: white;
  }
`;

const IconContainer = styled.div`
  svg {
    width: 100px;
  }
`;

const ColumnContainer = styled.div`
  padding: 44px 26px;
  background: white;
  margin: 0 6px;
`;

const Columns = styled.div`
  display: flex;
`;

const Column = styled.div`
  width: calc(100% / 3);
  padding: 0 36px;
`;

const MiddleHeader = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  height: 24px;
  padding: 0 36px;
  margin-bottom: 24px;
`;

const MiddleHeaderText = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
`;

const MiddleText = styled.div`
  margin-top: 12px;
`;

const FooterContainer = styled.div`
  display: block;
  position: relative;
  height: 88px;
  width: 100%;
  overflow: hidden;
  background-color: white;

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
  }
`;

const UnikieContainer = styled.div`
  display: block;
  position: absolute;
  height: 48px;
  top: 26px;
  left: calc(50% - 40px);

  svg {
    display: block;
    vertical-align: middle;
    text-align: center;
    width: 80px;
  }

  img {
    width: 72px;
    margin-top: 8px;
  }
`;

const FintrafficContainer = styled.div`
  display: inline-block;
  position: absolute;
  height: 48px;
  top: 8px;
  left: calc(50% - 40px);

  svg {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 80px;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 48px;
  background-color: white;
  margin: 0 6px;

  img {
    width: 280px;
    object-fit: contain;
  }

  @media (max-width: 899px) {
    padding-bottom: 48px;
    padding-top: 0px;
    display: inline-flex;
    justify-content: center;
    width: 100%;

    img {
      width: 200px;
      margin: auto;
    }
  }
`;

const Logos = styled.div`
  justify-content: center;
`;

const MobileTop = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 60px;
  background-image: linear-gradient(white, transparent);
`;

const HeaderTexts = styled.div`
  margin-left: 18px;
`;

const MobileFooter = styled.div`
  display: inline-block;
  position: relative;
  height: 54px;

  svg {
    display: inline-block;
    height: 54px;
    vertical-align: middle;
    text-align: center;
  }
`;

const HeaderRow1 = styled.div`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 4px;
`;

const HeaderRow2 = styled.div`
  color: ${({ theme }) => theme.color.middle_grey};
  letter-spacing: 2px;
`;

const OneView = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const MobileButtons = styled.div`
  position: absolute;
  bottom: 54px;
  width: 100%;
`;

const MobileButtonsBg = styled.div`
  position: absolute;
  height: 60%;
  width: 100%;
  background-image: linear-gradient(transparent, white);
`;

const MobileButtonsBgWhite = styled.div`
  position: absolute;
  top: 60%;
  height: 40%;
  width: 100%;
  background-color: white;
`;

const MobileButton = styled.div`
  z-index: 50;
  position: relative;
  width: calc(100% - 100px);
  margin: 24px 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 20px;
  color: white;
  padding: 0px 18px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;

  &:hover {
    background-color: ${props => props.hoverColor};
  }

  @media (max-height: 500px) {
    margin: 12px 50px;
    height: 30px;
    line-height: 30px;
  }
`;

const MobileText = styled.div`
  margin: 36px 50px 24px 50px;
  color: ${({ theme }) => theme.color.dark_blue};
  z-index: 50;
  position: relative;
  text-align: center;

  @media (max-height: 500px) {
    margin: 24px 50px 12px 50px;
  }
`;

const MobileInfoContainer = styled.div`
  margin: 42px 50px;
`;

const MobileInfo = styled.div`
  position: relative;
`;

const LogoContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;

  @media (max-width: 899px) {
    display: flex;
    margin-right: 0px;
    margin-bottom: 18px;
  }
`;

const StoreLink = styled.a`
  line-height: 0;
  margin-top: 3px;
`;

const infoJson = {
  header: 'An application to improve the flow of information for efficient and economical port visits',
  texts: [
    {
      text: [
        {
          normal:
            'Port Activity App offers real-time arrival and departure times of ships, emission calculation, berth planning, information for undocking and mooring vessels, invoicing services as well as ordering water, waste services, tugboat, and pilot - all in the same application. The application works on both computers and mobile devices, which makes especially on-call operations more efficient in ports.',
        },
      ],
    },
    {
      text: [
        {
          normal:
            'Port Activity is used in over 20 ports, with approximately 1 000 users and nearly 200 organizations behind it. ',
        },
        {
          bold: 'The application is meant for professionals working with port visits, ',
        },
        {
          normal: 'and the right to use the service is checked upon registration.',
        },
      ],
    },
    {
      text: [
        {
          normal:
            'Port Activity was created in the EU-funded Efficient Flow project, as part of the Port Flow Optimisation project package. The goal of the project package was to improve the flow of information between different stakeholders operating in ports. Project partners in Efficient Flow were SAMK, Fintraffic VTS, Swedish Maritime Administration, and the ports of Rauma and Gävle. The application was awarded with a Highly Commended recognition in the final of Seatrade Awards 2021, in the new category Port & Terminal Digital Technology Award.',
        },
      ],
    },
  ],
};

const APP_STORE_HREF = 'https://apps.apple.com/fi/app/port-activity-rauma/id1498429922';
const SWE_APP_STORE_HREF = 'https://apps.apple.com/fi/app/port-activity-g%C3%A4vle/id1498430023';
const PLAY_STORE_HREF = 'https://play.google.com/store/apps/details?id=com.unikie.portactivity.rauma';
const SWE_PLAY_STORE_HREF = 'https://play.google.com/store/apps/details?id=com.unikie.portactivity.gavle';

const LandingPageContainer = () => {
  const { namespace } = useLogin();
  const { i18n } = useTranslation(namespace);
  const t = i18n.getFixedT(i18n.language, namespace);

  const scrollRef = useRef();
  const mobileScrollRef = useRef();

  const [mobileSignInOpen, setMobileSignInOpen] = useState(false);
  const [mobileSignUpOpen, setMobileSignUpOpen] = useState(false);

  const [signUpOpen, setSignUpOpen] = useState(false);

  const isSwedishVersion =
    window.location.host.includes('portactivity.se') || window.location.host.includes('se.ft-testing.dev');

  const handleClick = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onMobileSignIn = () => {
    setMobileSignInOpen(true);
  };

  const onMobileWhoCan = () => {
    mobileScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onMobileSignUp = () => {
    setMobileSignUpOpen(true);
  };

  const openSignUp = () => {
    setSignUpOpen(true);
  };

  return (
    <LandingContainer>
      <Web>
        <VideoContainer>
          <video className="videoTag" autoPlay loop muted>
            <source src="https://storage.googleapis.com/port-activity-media/lighthouse.mp4" type="video/mp4" />
          </video>
        </VideoContainer>
        <LandingPageLeftInfoPanel handleClick={handleClick} openSignUp={openSignUp} />
        {!signUpOpen && <LandingPageSignInPanel openSignUpPanel={() => setSignUpOpen(true)} />}
        {signUpOpen && <LandingPageSignUpPanel closeModal={() => setSignUpOpen(false)} />}
        <BottomContainer>
          <FintrafficLine>
            <IconContainer>
              <LogoFintraffic />
            </IconContainer>
          </FintrafficLine>
          <ColumnContainer>
            <MiddleHeader>
              <MiddleHeaderText>{infoJson.header}</MiddleHeaderText>
            </MiddleHeader>
            <Columns ref={scrollRef}>
              {infoJson.texts.map((text, i) => (
                <Column key={i}>
                  <MiddleText>
                    {text.text.map((t, i) => {
                      if (t.normal) {
                        return <span key={i}>{t.normal}</span>;
                      } else if (t.bold) {
                        return (
                          <span key={i} style={{ fontWeight: 600 }}>
                            {t.bold}
                          </span>
                        );
                      }
                    })}
                  </MiddleText>
                </Column>
              ))}
            </Columns>
          </ColumnContainer>
          <BottomRow>
            <LogoContainer>
              <StoreLink href={isSwedishVersion ? SWE_APP_STORE_HREF : APP_STORE_HREF}>
                <LogoAppStore />
              </StoreLink>
              <StoreLink href={isSwedishVersion ? SWE_PLAY_STORE_HREF : PLAY_STORE_HREF}>
                <img style={{ height: '60px', width: '150px' }} src={LogoPlayStore} alt="Play store" />
              </StoreLink>
            </LogoContainer>
            <img alt={t('EU interreg')} src={EUInterreg} />
          </BottomRow>
          <FooterContainer>
            <Footer />
            <UnikieContainer>
              <img alt={t('Unikie')} src={UnikieLogoImg} />
            </UnikieContainer>
          </FooterContainer>
        </BottomContainer>
      </Web>
      <Mobile>
        <ImageContainer>
          <img src={Lighthouse} alt="Lighthouse background" />
        </ImageContainer>
        <MobileTop>
          <LogoImage style={{ fill: '#0F67CC', width: '42px' }} />
          <HeaderTexts>
            <HeaderRow1>PORT ACTIVITY</HeaderRow1>
            <HeaderRow2>FINLAND</HeaderRow2>
          </HeaderTexts>
        </MobileTop>
        <FintrafficLine>
          <MobileFooter>
            <Footer />
          </MobileFooter>
          <FintrafficContainer>
            <LogoFintraffic />
          </FintrafficContainer>
        </FintrafficLine>
        <OneView>
          <MobileButtons>
            <MobileButtonsBg />
            <MobileButtonsBgWhite />
            <MobileButton color="#025a45" hoverColor="#5aa190" onClick={onMobileSignIn}>
              {t('Sign in')}
            </MobileButton>
            <MobileButton color="#07608D" hoverColor="#5d8da6" onClick={onMobileWhoCan}>
              {t('Who can join?')}
            </MobileButton>
            <MobileButton color="#023978" hoverColor="#729bc0" onClick={onMobileSignUp}>
              {t('Sign up')}
            </MobileButton>
            <MobileText>{infoJson.header}</MobileText>
          </MobileButtons>
        </OneView>
        <MobileInfo ref={mobileScrollRef}>
          {infoJson.texts.map((text, i) => (
            <MobileInfoContainer key={i}>
              <MiddleText>
                {text.text.map((t, i) => {
                  if (t.normal) {
                    return <span key={i}>{t.normal}</span>;
                  } else if (t.bold) {
                    return (
                      <span key={i} style={{ fontWeight: 600 }}>
                        {t.bold}
                      </span>
                    );
                  }
                })}
              </MiddleText>
            </MobileInfoContainer>
          ))}
        </MobileInfo>
        <BottomRow>
          <Logos>
            <LogoContainer>
              <StoreLink href={isSwedishVersion ? SWE_APP_STORE_HREF : APP_STORE_HREF}>
                <LogoAppStore />
              </StoreLink>
              <StoreLink href={isSwedishVersion ? SWE_PLAY_STORE_HREF : PLAY_STORE_HREF}>
                <img style={{ height: '60px' }} src={LogoPlayStore} alt="Play store" />
              </StoreLink>
            </LogoContainer>
            <img alt={t('EU interreg')} src={EUInterreg} />
          </Logos>
        </BottomRow>

        {mobileSignInOpen && (
          <LandingPageSignInPanel
            closeModal={() => setMobileSignInOpen(false)}
            openSignUpPanel={() => setMobileSignUpOpen(true)}
          />
        )}
        {mobileSignUpOpen && <LandingPageSignUpPanel closeModal={() => setMobileSignUpOpen(false)} />}

        <FooterContainer>
          <Footer />
          <UnikieContainer>
            <img alt={t('Unikie')} src={UnikieLogoImg} />
          </UnikieContainer>
        </FooterContainer>
      </Mobile>
    </LandingContainer>
  );
};

export default LandingPageContainer;
