import { MapContainer as Map } from 'react-leaflet';
import React, { useState } from 'react';
import styled from 'styled-components';

import LandingPageMapInnerContainer from './LandingPageMapInnerContainer';

const MapContainer = styled.div`
  .leaflet-control-attribution.leaflet-control {
    bottom: 10px;
  }
`;

const LandingPageMapContainer = ({ coordinatesString, zoom }) => {
  let appliedCoordinates = coordinatesString === undefined ? '59.129089,14.373028' : coordinatesString;
  const [appliedZoom, setAppliedZoom] = useState(zoom === undefined ? 5 : zoom);

  return (
    <MapContainer>
      <Map
        style={{ height: 'calc(100vh - 250px)', borderRadius: '100% / 0 0 2% 2%' }}
        className="markercluster-map"
        center={appliedCoordinates.split(',')}
        zoom={appliedZoom}
        tap={false}
      >
        <LandingPageMapInnerContainer zoom={appliedZoom} setZoom={setAppliedZoom} />
      </Map>
    </MapContainer>
  );
};

export default LandingPageMapContainer;
