import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';

import { color_not_planned } from '../../commonCalendar/utility/constants';
import dayjs from 'dayjs';
import Icon from '../../ui/Icon';
import ModalLinks from './ModalLinks';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc((100% - 24px) / 3);
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  padding: 3px 0 0 10px;
`;

const Background = styled.span`
  padding: 2px 5px;
  border-radius: 3px;
  background-color: ${color_not_planned};
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const InfoRowSplit = styled.div`
  margin: 0 0 4px;
  width: auto;
  padding-right: 25px;
`;

const InfoContainer = styled.div`
  width: auto;
  vertical-align: top;
  margin-top: 6px;
  display: flex;
`;

const Info = styled.div`
  white-space: nowrap;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 12px;
  margin-bottom: 12px;
`;

const RemoveButtonColumn = styled.div`
  margin-right: 12px;
`;

const TimeColumn = styled.div`
  margin-left: 12px;
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const RedIcon = styled.span`
  color: red;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-item: center;
  svg {
    width: 26px;
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 138px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const ModalInnerDelete = styled(ModalInner)`
  min-height: 320px;
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditJITSlotRequestBerth = ({ closeModal, vessel = undefined, readOnly, newBerth }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { sendUpdateNotPlannedBerth, berths, handlePortcallDelete } = useContext(BerthPlanningToolContext);

  const [linksDisabled, setLinksDisabled] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [inputData, setInputData] = useState({
    port_call_id: vessel.port_call_id,
    berth_code: newBerth || vessel.berth_code,
  });

  const title = t('Update berth');

  const dataIsValid = () => {
    return inputData.port_call_id && inputData.berth_code;
  };

  const handleBerthChange = value => {
    if (!linksDisabled) {
      setLinksDisabled(true);
    }
    setInputData({ ...inputData, berth_code: value });
  };

  const sendData = () => {
    sendUpdateNotPlannedBerth({ ...inputData });
    closeModal(true);
  };

  const closeConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const removeSlotRequest = () => {
    handlePortcallDelete(vessel);

    closeModal(true);
  };

  const deleteSlotRequestClick = () => {
    setDeleteConfirmation(true);
  };

  const berthOptions = [];
  berths.forEach(g => {
    g.subRows.forEach(b => {
      berthOptions.push({ label: b.short_name, value: b.id });
    });
  });

  return (
    <Modal title={title} open={true} width={800} onCancel={closeModal} footer={null}>
      {deleteConfirmation ? (
        <ModalInnerDelete>
          <IconContainer>
            <Icon type="remove-illustration"></Icon>
          </IconContainer>
          <ConfirmationText>{t('Are you sure you want to cancel the port call?')}</ConfirmationText>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Close')}
            </ButtonLight>
            <ButtonLight type="button" cancel onClick={closeConfirmation}>
              {t('Back')}
            </ButtonLight>
            <ButtonLight red onClick={removeSlotRequest}>
              {t('Cancel')}
            </ButtonLight>
          </ActionButtons>
        </ModalInnerDelete>
      ) : (
        <ModalInnerInput>
          <ScrollContainer>
            {!readOnly && (
              <RemoveContainer>
                <RemoveButtonColumn>
                  <ButtonContainer onClick={deleteSlotRequestClick}>
                    <RedIcon>
                      <Icon type="trash"></Icon>
                    </RedIcon>
                    <BoldText>{t('Cancel port call')}</BoldText>
                  </ButtonContainer>
                </RemoveButtonColumn>
                <TimeColumn>
                  <FirstColumn>
                    <RowHeaderForTooltip>{t('ETA')}:</RowHeaderForTooltip>
                    <RowHeaderForTooltip>{t('ETD')}:</RowHeaderForTooltip>
                  </FirstColumn>
                  <SecondColumn>
                    <TooltipValue>{vessel.eta ? dayjs(vessel.eta).format('DD.MM.YYYY HH:mm') : '-'}</TooltipValue>
                    <TooltipValue>{vessel.etd ? dayjs(vessel.etd).format('DD.MM.YYYY HH:mm') : '-'}</TooltipValue>
                  </SecondColumn>
                </TimeColumn>
              </RemoveContainer>
            )}
            <FirstColumnSplit>
              <Info>
                <InfoContainer>
                  <InfoRowSplit>
                    {t('Vessel:')}
                    <BoldText>{vessel.name || '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Imo:')}
                    <BoldText>{vessel.imo || '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Draft:')}
                    <BoldText>{vessel.draft || '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Beam:')}
                    <BoldText>{vessel.beam || '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('Loa:')}
                    <BoldText>{vessel.loa || '-'}</BoldText>
                  </InfoRowSplit>
                </InfoContainer>
                <InfoContainer>
                  <InfoRowSplit>
                    {t('ETA:')}
                    <BoldText>{vessel.eta ? dayjs(vessel.eta).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('ETD:')}
                    <BoldText>{vessel.etd ? dayjs(vessel.etd).format('DD.MM.YYYY HH:mm') : '-'}</BoldText>
                  </InfoRowSplit>
                </InfoContainer>
                <InfoContainer>
                  <InfoRowSplit>
                    {t('Berth:')}
                    <BoldText>{vessel.berth_short_name || '-'}</BoldText>
                  </InfoRowSplit>
                  <InfoRowSplit>
                    {t('State:')}
                    <BoldText style={{ textTransform: 'capitalize' }}>
                      <Background state={vessel.state}>{t('Not planned')}</Background>
                    </BoldText>
                  </InfoRowSplit>
                </InfoContainer>
              </Info>
            </FirstColumnSplit>
            <Row>
              <RowContainer style={{ marginRight: '0px' }}>
                <RowHeader>
                  {t('Berth')}
                  <Required>*</Required>
                </RowHeader>
                {readOnly ? (
                  <Input
                    placeholder={t('Berth')}
                    value={vessel.berth_short_name}
                    disabled={true}
                    style={{ color: '#4a4a4a' }}
                  />
                ) : (
                  <Select
                    showSearch
                    placeholder={t('Berth name')}
                    optionFilterProp="label"
                    onChange={handleBerthChange}
                    style={{ width: '100%' }}
                    options={berthOptions}
                    value={inputData.berth_code || undefined}
                  />
                )}
              </RowContainer>
            </Row>
          </ScrollContainer>
          <MiddleLine />
          <BottomRow>
            <ModalLinks vessel={vessel} linksDisabled={linksDisabled} />
            {readOnly ? (
              <ActionButtons>
                <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
                  {t('Close')}
                </ButtonLight>
              </ActionButtons>
            ) : (
              <ActionButtons>
                <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
                  {t('Cancel')}
                </ButtonLight>
                <ButtonLight disabled={!dataIsValid()} onClick={sendData}>
                  {t('Save')}
                </ButtonLight>
              </ActionButtons>
            )}
          </BottomRow>
        </ModalInnerInput>
      )}
    </Modal>
  );
};

export default EditJITSlotRequestBerth;
