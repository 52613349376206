import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { fromEvent } from 'file-selector';

import { UserContext } from '../../context/UserContext';
import App from 'antd/es/app';
import Spin from 'antd/es/spin';
import ButtonLight from '../ui/ButtonLight';
import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LeftContainer = styled.div`
  width: 340px;
  min-width: 300px;
  border-right: 1px solid #e8e8e8;
  height: calc(100vh - 168px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  padding-right: 12px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 154px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const Text = styled.div`
  margin-top: 2px;
`;

const RightContainer = styled.div`
  width: calc(100% - 340px);
  min-width: 600px;
`;

const HelpText = styled.div`
  margin-bottom: 24px;
`;

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const NoDocument = styled.div`
  margin-top: 150px;
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-bottom: 0px;
  }
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

const PageNumber = styled.div`
  margin-right: 12px;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.disabled && props.theme.color.grey_light2};
`;

const StyledButtonLight = styled(ButtonLight)`
  font-size: 13px;
  display: flex;
  padding: 4px 10px;
  height: 28px;
  min-width: 60px;
  margin-top: 24px;
`;

const ValidityContainer = styled.div`
  background-color: ${props => (props.red ? '#ffa7a7' : props.green ? '#A5D6A7' : '#f8f8f8')};
  height: 140px;
  padding: 12px;
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

const ValidationText = styled.div`
  margin-top: 34px;
  max-width: 200px;
  text-align: center;
`;

const NoValidationMade = styled.div`
  margin-top: 50px;
`;

const WasteValidateDocument = () => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { alertsHeight } = useContext(AlertContext);

  const [uploading, setUploading] = useState(false);
  const [validDocument, setValidDocument] = useState(null);

  const inputFile = useRef();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [blob, setBlob] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleUpload = async data => {
    if (data) {
      try {
        const result = await apiCall('post', 'document/validate', {
          content_type: 'pdf',
          contents: data.replace('data:application/pdf;base64,', ''),
        });

        if (result?.data && result.status === 200) {
          setValidDocument({ valid: result.data.valid });
        }
      } catch (e) {
        message.error(t('Could not validate document: {{message}}', { message: e.message }));
        setUploading(false);
      }
    }
    setUploading(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const uploadFile = async evt => {
    setUploading(true);
    setPageNumber(1);

    try {
      const files = await fromEvent(evt);
      if (files?.[0]) {
        setBlob(files[0]);
        getBase64(files[0]).then(data => handleUpload(data));
      }
    } catch (e) {
      setUploading(false);
      message.error(t('Could not validate document: {{message}}', { message: e.message }));
    }
  };

  return (
    <Container>
      <LeftContainer alertsHeight={alertsHeight}>
        <HelpText>{t('Here you can check the validity of a waste receipt pdf.')}</HelpText>
        <HelpText>{t('Upload the pdf here and check if the document is an official Port Activity document.')}</HelpText>
        <RowContainerSplit>
          <InputRow>
            <StyledButtonLight cancel onClick={() => inputFile.current.click()}>
              <Icon type="upload" style={{ fontSize: '20px' }} />
              <Text>{t('Upload pdf')}</Text>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                accept=".pdf"
                onChange={e => uploadFile(e)}
                onClick={e => (e.target.value = null)}
              />
            </StyledButtonLight>
          </InputRow>
        </RowContainerSplit>

        <ValidityContainer
          red={!uploading && validDocument && !validDocument.valid}
          green={!uploading && validDocument && validDocument.valid}
        >
          {uploading ? (
            <Loader>
              <Spin size="small" />
            </Loader>
          ) : validDocument ? (
            <ValidationText>
              {validDocument.valid
                ? t('Document is a valid Port Activity document.')
                : t('Document is NOT a valid Port Activity document.')}
            </ValidationText>
          ) : (
            <NoValidationMade>{t('Nothing has been validated.')}</NoValidationMade>
          )}
        </ValidityContainer>
      </LeftContainer>
      <RightContainer>
        {blob ? (
          <>
            <Buttons>
              <PageNumber>{t('Page {{pageNumber}} of {{numPages}}', { pageNumber, numPages })}</PageNumber>
              {numPages > 1 && (
                <IconContainer onClick={previousPage} disabled={pageNumber <= 1} style={{ marginLeft: 0 }}>
                  <Icon type="chevron-left" />
                </IconContainer>
              )}
              {numPages > 1 && (
                <IconContainer onClick={nextPage} disabled={pageNumber >= numPages}>
                  <Icon type="chevron-right" />
                </IconContainer>
              )}
            </Buttons>
            <Document loading={null} file={blob} className="waste-pdf-file" onLoadSuccess={onDocumentLoadSuccess} options={options}>
              <Page loading={null} className="pdf-page" pageNumber={pageNumber} width={600} />
            </Document>
          </>
        ) : (
          <NoDocument>{t('No document available.')}</NoDocument>
        )}
      </RightContainer>
    </Container>
  );
};
export default WasteValidateDocument;
