import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../../context/UserContext';
import useApi from '../../../../hooks/useApi';

import List from '../../../ui/List';
import ListActionButton from '../../../ui/ListActionButton';
import Icon from '../../../ui/Icon';
import AddOrEditLocalHolidayModal from './AddOrEditLocalHolidayModal';
import ChangePublicHolidayStatusModal from './ChangePublicHolidayStatusModal';
import RemoveLocalHolidayModal from './RemoveLocalHolidayModal';

const StatusOval = styled.div`
  background-color: ${props => (!props.disabled ? props.theme.color.secondary : props.theme.color.grey_light2)};
  color: ${props => (!props.disabled ? props.theme.color.white : props.theme.color.grey_dark)};
  font-size: 12px;
  width: 66px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  cursor: default;
`;

const YearPickerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 26px;
  left: 22px;
  z-index: 100;
`;

const IconContainer = styled.div`
  line-height: 19px;
  height: 24px;
  width: 14px;
  margin-left: 6px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.disabled && props.theme.color.grey_light2};
`;

const YearText = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin: 0 12px 0 18px;
`;

const HolidayList = () => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [year, setYear] = useState(dayjs().year());

  const currentYear = dayjs().year();

  const [statusModalOpen, setStatusModalOpen] = useState(undefined);
  const [localHolidayModalData, setLocalHolidayModalData] = useState(undefined);
  const [localHolidayModalType, setLocalHolidayModalType] = useState(undefined);
  const [localHolidayDeleteModal, setLocalHolidayDeleteModal] = useState(undefined);

  const { loading, data, error, fetchData } = useApi('get', 'calendar/holidays', { year });

  const holidays =
    error || !data
      ? []
      : data.map((d, index) => {
        return { ...d, index };
      });

  const columns = [
    {
      title: t('Local name'),
      dataIndex: 'localName',
      key: 'localName',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Date / Start date'),
      dataIndex: 'date',
      key: 'date',
      render: record => {
        if (record) {
          return dayjs(record).format('DD.MM.YYYY HH:mm');
        } else {
          return '-';
        }
      },
    },
    {
      title: t('End date'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: record => {
        if (record) {
          return dayjs(record).format('DD.MM.YYYY HH:mm');
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Public'),
      dataIndex: 'public',
      key: 'public',
      render: record => {
        if (record) {
          return t('Yes');
        } else {
          return t('No');
        }
      },
    },
    {
      title: t('Status'),
      dataIndex: 'excluded',
      key: 'excluded',
      render: (record, item) => {
        if (item.public) {
          if (record) {
            return <StatusOval disabled={true}>{t('Disabled')}</StatusOval>;
          } else {
            return <StatusOval disabled={false}>{t('Active')}</StatusOval>;
          }
        } else {
          return '-';
        }
      },
    },
  ];

  const actions = [
    {
      render: record =>
        !record.public ? (
          <ListActionButton
            key="action-1"
            onClick={() => {
              setLocalHolidayModalData(record);
              setLocalHolidayModalType('edit');
            }}
          >
            <Icon type="edit" />
            {t('Edit')}
          </ListActionButton>
        ) : (
          <ListActionButton
            key="action-1"
            onClick={() => {
              setStatusModalOpen(record);
            }}
          >
            <Icon type="edit" />
            {t('Change status')}
          </ListActionButton>
        ),
    },
    {
      render: record =>
        !record.public ? (
          <ListActionButton
            key="action-2"
            onClick={() => {
              setLocalHolidayDeleteModal(record);
            }}
            red
          >
            <Icon type="trash" />
            {t('Remove')}
          </ListActionButton>
        ) : null,
    },
  ];

  const refetchData = async () => {
    await fetchData(false, { year });
  };

  const addItemButton = {
    onClick: () => {
      setLocalHolidayModalData(undefined);
      setLocalHolidayModalType('add');
    },
    text: t('Add local holiday'),
  };

  const closeStatusModal = async () => {
    setStatusModalOpen(undefined);
  };

  const closeLocalHolidayModal = async () => {
    setLocalHolidayModalData(undefined);
    setLocalHolidayModalType(undefined);
  };

  const closeLocalHolidayDeleteModal = async () => {
    setLocalHolidayDeleteModal(undefined);
  };

  const goToPreviousYear = () => {
    if (year <= currentYear) {
      return;
    }

    fetchData(false, { year: year - 1 });
    setYear(year => year - 1);
  };

  const goToNextYear = () => {
    if (year > currentYear + 10) {
      return;
    }

    fetchData(false, { year: year + 1 });
    setYear(year => year + 1);
  };

  return (
    <div>
      <YearPickerContainer data-id="year-picker-container">
        <IconContainer onClick={goToPreviousYear} disabled={year <= currentYear} style={{ marginLeft: 0 }}>
          <Icon type="chevron-left" />
        </IconContainer>
        <YearText>{year}</YearText>
        <IconContainer onClick={goToNextYear} disabled={year > currentYear + 10}>
          <Icon type="chevron-right" />
        </IconContainer>
      </YearPickerContainer>
      <div style={{ marginTop: user.permissions.includes('manage_holidays') ? '0px' : '24px' }}>
        <List
          rowKey="index"
          columns={columns}
          dataSource={holidays}
          actions={user.permissions.includes('manage_holidays') ? actions : []}
          spinning={loading}
          addItemButton={user.permissions.includes('manage_holidays') ? addItemButton : undefined}
        />
      </div>
      {!!statusModalOpen && (
        <ChangePublicHolidayStatusModal
          data={statusModalOpen}
          closeModal={closeStatusModal}
          refetchData={refetchData}
        />
      )}
      {!!localHolidayModalType && (
        <AddOrEditLocalHolidayModal
          type={localHolidayModalType}
          data={localHolidayModalData}
          closeModal={closeLocalHolidayModal}
          refetchData={refetchData}
        />
      )}
      {!!localHolidayDeleteModal && (
        <RemoveLocalHolidayModal
          data={localHolidayDeleteModal}
          closeModal={closeLocalHolidayDeleteModal}
          refetchData={refetchData}
        />
      )}
    </div>
  );
};

export default HolidayList;
