import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import NotificationListMessageDecisions from './NotificationListMessageDecisions';
import Icon from '../ui/Icon';
import Popconfirm from 'antd/es/popconfirm';
import ProfileCircle from '../ui/ProfileCircle';

const NotificationMessageListItemContainer = styled.div`
  width: ${props => (props.child ? '95%' : '100%')};
  align-self: flex-start;
  margin-left: ${props => (props.child ? '5%' : '0')};
  display: inline-block;
  margin-top: ${props => (props.child ? '15px' : '24px')};
`;

const MoreButton = styled.div`
  cursor: pointer;
  height: 12px;
  border-radius: 4px;
  display: none;

  &:hover {
    background-color: #efefef;
  }

  svg {
    fill: #bbbbbb !important;
    height: 18px;
    width: 18px;
    margin-top: -28px;
    margin-left: 2px;
  }
`;

const NotificationMessageListItemText = styled.div`
  font-size: 13px;
  border-radius: ${props => (props.decision ? '4px 4px 0 0' : '4px')};
  padding: 10px;
  font-weight: ${props => (props.newItem ? 700 : 400)};
  background-color: ${({ theme }) => theme.color.beige};
  position: relative;

  &:hover {
    .more-button {
      display: flex;
    }
  }
`;

const NotificationMessageListItemTime = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.tiny};
  width: 100%;
`;

const SenderRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const Sender = styled.div`
  color: ${({ theme }) => theme.color.grey_dark};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Rows = styled.div`
  margin-left: 12px;
  width: calc(100% - 42px);
`;

const SenderSmall = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 14px;
`;

const Unread = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.secondary};
  margin-top: 8px;
  cursor: pointer;
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 9px;
  background-color: white;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  z-index: 100;
`;

const PopupRow = styled.div`
  padding: 3px 6px;
  cursor: pointer;
  margin: 2px 0px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const MessageActions = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  height: 12px;
  width: 20px;
`;

const NotificationListMessage = ({
  data,
  child,
  readMessage,
  onRead,
  viewPortTop,
  viewPortBottom,
  scrolling,
  addToRememberList,
}) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [newItem, setNewItem] = useState(!data.read);
  const [oneReadSend, setOneReadSend] = useState(false);

  const ref = useRef();
  let timer = useRef();

  const handleMarkRead = async () => {
    if (!data.read && !oneReadSend) {
      setOneReadSend(true);
      onRead(data.id);
    }
  };

  useEffect(() => {
    if (ref.current && newItem) {
      const rect = ref.current.getClientRects();
      if (rect[0]) {
        if (rect[0].top > viewPortTop && rect[0].top < viewPortBottom && !scrolling) {
          timer.current = setTimeout(() => {
            setNewItem(false);
            handleMarkRead();
          }, 1000);
        }
      }
    }
    return () => {
      clearTimeout(timer.current);
    };
  });

  const [messagePopupOpen, setMessagePopupOpen] = useState(false);
  const [morePopupOpen, setMorePopupOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#delete-message-container');
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (dropdown ? !dropdown.contains(event.target) : true) &&
        event.target.outerHTML !== '<span>' + t('Yes') + '</span>'
      ) {
        setMorePopupOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [t]);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#mark-as-read-container');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setMessagePopupOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const setAsRead = async () => {
    addToRememberList(data.id);
    setMessagePopupOpen(false);
    await apiCall('post', 'notifications-read', {
      notification_ids: [data.id],
    });
    readMessage();
  };

  const deleteMessage = async () => {
    setMorePopupOpen(false);
    await apiCall('delete', 'notifications', { id: data.id });
  };

  return (
    <NotificationMessageListItemContainer child={child} ref={ref}>
      <NotificationMessageListItemTime>
        <SenderRow>
          <ProfileCircle small={child} givenUser={data.sender} />
          <Rows>
            <Sender>
              <div>
                <span style={{ fontWeight: 600 }}>{data.sender.first_name + ' ' + data.sender.last_name}</span>{' '}
                {data.ship ? (
                  <span>
                    {t('from')}{' '}
                    <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>{data.ship.vessel_name}</span>
                  </span>
                ) : null}
              </div>
              {!data.read && <Unread onClick={() => setMessagePopupOpen(true)} />}
              {messagePopupOpen && (
                <PopupContainer id="mark-as-read-container" ref={ref}>
                  <PopupRow onClick={() => setAsRead()}>{t('Mark as read')}</PopupRow>
                </PopupContainer>
              )}
            </Sender>
            <SenderSmall>
              <div>{data.sender.email}</div>
              <div>
                {dayjs(data.created_at).format('DD.MM.YYYY')}
                <span style={{ fontWeight: 600, marginLeft: '3px' }}>{dayjs(data.created_at).format('HH:mm')}</span>
              </div>
            </SenderSmall>
          </Rows>
        </SenderRow>
      </NotificationMessageListItemTime>
      <NotificationMessageListItemText decision={!!data.decision}>
        {data.message}
        {user.permissions.includes('send_push_notification') && (
          <MessageActions>
            <MoreButton onClick={() => setMorePopupOpen(true)} className="more-button">
              <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
            </MoreButton>
          </MessageActions>
        )}
        {morePopupOpen && (
          <PopupContainer id="delete-message-container" ref={ref}>
            <Popconfirm
              title={
                child
                  ? t('Are you sure you want to delete the message?')
                  : t('Are you sure you want to delete the message and all possible replies?')
              }
              onConfirm={() => deleteMessage()}
              onCancel={() => setMorePopupOpen(false)}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              placement="top"
              overlayStyle={{ maxWidth: '240px' }}
            >
              <PopupRow>{t('Delete the message')}</PopupRow>
            </Popconfirm>
          </PopupContainer>
        )}
      </NotificationMessageListItemText>
      {data.decision && <NotificationListMessageDecisions data={data.decision} />}
    </NotificationMessageListItemContainer>
  );
};

export default NotificationListMessage;
