import dayjs from 'dayjs';
import React from 'react';

export const optionLists = {
  vessels_involved: [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ],
  people_in_danger: [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ],
  location_option: [
    {
      value: 'open_sea',
      label: 'In the open sea',
    },
    {
      value: 'approaching_port',
      label: 'When approaching port',
    },
    {
      value: 'at_berth',
      label: 'At berth',
    },
  ],
  incident_type: [
    {
      value: 'pollution',
      label: 'Pollution',
      color: '#A7C4F4',
    },
    {
      value: 'collision',
      label: 'Collision',
      color: '#FFAB91',
    },
    {
      value: 'machinery_damage',
      label: 'Machinery damage',
      color: '#efb3d5',
    },
    {
      value: 'fire_explosion',
      label: 'Fire / Explosion',
      color: '#A5D6A7',
    },
    {
      value: 'grounding_stranding',
      label: 'Grounding / Stranding',
      color: '#FBE6A2',
    },
    {
      value: 'other',
      label: 'Other',
      color: '#D9D9D9',
    },
  ],
};

export const wizardData = {
  path: [
    {
      header: 'Any people in danger?',
      type: 'options',
      options: [
        {
          value: false,
          label: 'No',
        },
        {
          value: true,
          label: 'Yes',
        },
      ],
      data_model: {
        name: 'people_in_danger',
        type: 'string',
      },
      mandatory: true,
      available_obj: {
        incident: true,
        event: true,
      },
      mandatory_obj: {
        incident: true,
        event: false,
      },
    },
    {
      header: 'Any vessels involved?',
      type: 'options',
      mandatory: true,
      available_obj: {
        incident: true,
        event: true,
      },
      mandatory_obj: {
        incident: true,
        event: false,
      },
      options: [
        {
          value: false,
          label: 'No',
        },
        {
          value: true,
          label: 'Yes',
        },
      ],
      data_model: {
        name: 'vessels_involved',
        type: 'string',
      },
      addition: {
        when: true,
        info_text: 'Add all involved vessels here.',
        type: 'multiselect_vessels',
        mandatory: true,
        available_obj: {
          incident: true,
          event: true,
        },
        mandatory_obj: {
          incident: true,
          event: true,
        },
        data_model: {
          name: 'vessels',
          type: 'array',
          in_data: true,
        },
      },
    },
    {
      header: 'Where will/did the {{type}} happen?',
      type: 'options',
      available_obj: {
        incident: true,
        event: true,
      },
      mandatory_obj: {
        incident: true,
        event: false,
      },
      mandatory: true,
      options: [
        {
          value: 'open_sea',
          label: 'In the open sea',
        },
        {
          value: 'approaching_port',
          label: 'When approaching port',
        },
        {
          value: 'at_berth',
          label: 'At berth',
        },
      ],
      data_model: {
        name: 'location_option',
        type: 'string',
        key_name: true,
      },
      addition: {
        when: 'any',
        info_text: 'Select the location of the {{type}} in the map.',
        type: 'map',
        mandatory: false,
        available_obj: {
          incident: true,
          event: true,
        },
        mandatory_obj: {
          incident: false,
          event: false,
        },
        data_model: {
          name: 'point',
          type: 'point',
          in_data: true,
          in_data_key: 'geojson',
        },
      },
    },
    {
      header: 'What is/was the type of the {{type}}?',
      type: 'options',
      mandatory: true,
      available_obj: {
        incident: true,
        event: false,
      },
      mandatory_obj: {
        incident: true,
        event: false,
      },
      options: [
        {
          value: 'pollution',
          label: 'Pollution',
        },
        {
          value: 'collision',
          label: 'Collision',
        },
        {
          value: 'machinery_damage',
          label: 'Machinery damage',
        },
        {
          value: 'fire_explosion',
          label: 'Fire / Explosion',
        },
        {
          value: 'grounding_stranding',
          label: 'Grounding / Stranding',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      data_model: {
        name: 'incident_type',
        type: 'string',
        key_name: true,
      },
      addition: {
        when: 'any',
        info_text: 'Give details about the {{type}}.',
        type: 'textArea',
        mandatory: false,
        available_obj: {
          incident: true,
          event: false,
        },
        mandatory_obj: {
          incident: false,
          event: false,
        },
        data_model: {
          name: 'details',
          type: 'string',
        },
      },
    },
    {
      header: 'What happened/will happen?',
      info_text: 'Give details about the {{type}}.',
      type: 'textArea',
      mandatory: false,
      available_obj: {
        incident: false,
        event: true,
      },
      mandatory_obj: {
        incident: false,
        event: true,
      },
      data_model: {
        name: 'details',
        type: 'string',
      },
    },
    {
      header: 'When will/did the {{type}} happen?',
      type: 'date',
      mandatory: true,
      available_obj: {
        incident: true,
        event: true,
      },
      mandatory_obj: {
        incident: true,
        event: true,
      },
      data_model: {
        name: 'date',
        type: 'datetime',
      },
      addition: {
        when: 'any',
        type: 'date',
        mandatory: false,
        available_obj: {
          incident: false,
          event: true,
        },
        mandatory_obj: {
          incident: false,
          event: false,
        },
        data_model: {
          name: 'end_date',
          type: 'datetime',
        },
      },
    },
    {
      header: 'What is/was the severity of the {{type}}?',
      type: 'slider',
      mandatory: true,
      available_obj: {
        incident: true,
        event: false,
      },
      mandatory_obj: {
        incident: true,
        event: false,
      },
      options: {
        min: 1,
        max: 10,
        step: 1,
        marks: {
          1: {
            style: {
              color: '#80d183',
              fontWeight: 600,
            },
            label: (
              <>
                <div>1</div>
                <div>Minor</div>
              </>
            ),
          },
          2: {
            style: {
              color: '#80d183',
              fontWeight: 600,
            },
            label: '2',
          },
          3: {
            style: {
              color: '#80d183',
              fontWeight: 600,
            },
            label: '3',
          },
          4: {
            style: {
              color: '#F5BD3E',
              fontWeight: 600,
            },
            label: '4',
          },
          5: {
            style: {
              color: '#F5BD3E',
              fontWeight: 600,
            },
            label: (
              <>
                <div>5</div>
                <div>Medium</div>
              </>
            ),
          },
          6: {
            style: {
              color: '#F5BD3E',
              fontWeight: 600,
            },
            label: '6',
          },
          7: {
            style: {
              color: '#F5BD3E',
              fontWeight: 600,
            },
            label: '7',
          },
          8: {
            style: {
              color: '#F44336',
              fontWeight: 600,
            },
            label: '8',
          },
          9: {
            style: {
              color: '#F44336',
              fontWeight: 600,
            },
            label: '9',
          },
          10: {
            style: {
              color: '#F44336',
              fontWeight: 600,
            },
            label: (
              <>
                <div>10</div>
                <div>Major</div>
              </>
            ),
          },
        },
      },
      data_model: {
        name: 'level_of_emergency',
        type: 'int',
      },
    },
  ],
  endCheckBox: {
    label: 'This {{type}} is visible to authority.',
    data_model: {
      name: 'visible_to_authority',
      type: 'boolean',
      default_value: true,
    },
  },
  autoCloseTime: {
    label: 'Close this {{type}} automatically',
    type: 'date',
    mandatory: false,
    data_model: {
      name: 'auto_close_ts',
      type: 'datetime',
      default_value: dayjs()
        .add(2, 'weeks')
        .toISOString(),
    },
  },
  sendNotificationCheckBox: {
    label: 'Send port notification about this {{type}}',
    data_model: {
      name: 'send_notification',
      type: 'boolean',
      default_value: false,
    },
  },
};

export const listData = [
  {
    id: 1,
    people_in_danger: 'no',
    vessels_involved: 'yes',
    vessels: [{ name: 'Anna', imo: 9566796 }],
    location_option: 'open_sea',
    level_of_emergency: 1,
    coordinates: {
      lon: 22.994384765625004,
      lat: 63.88664437747208,
    },
    incident_type: 'pollution',
    date: '2023-07-04T11:21:32+03:00',
    details:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    global: true,
  },
  {
    id: 2,
    people_in_danger: 'no',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'open_sea',
    level_of_emergency: 1,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'pollution',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: true,
  },
  {
    id: 8,
    people_in_danger: 'no',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'at_berth',
    level_of_emergency: 1,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'collision',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: true,
  },
  {
    id: 3,
    people_in_danger: 'no',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'open_sea',
    level_of_emergency: 3,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'pollution',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: false,
  },
  {
    id: 4,
    people_in_danger: 'yes',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'approaching_port',
    level_of_emergency: 2,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'machinery_damage',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: true,
  },
  {
    id: 5,
    people_in_danger: 'yes',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'open_sea',
    level_of_emergency: 1,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'other',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: true,
  },
  {
    id: 6,
    people_in_danger: 'no',
    vessels_involved: 'no',
    vessels: [],
    location_option: null,
    level_of_emergency: 2,
    coordinates: {
      lon: 23.79913330078125,
      lat: 63.55466875345656,
    },
    incident_type: 'grounding_stranding',
    date: '2023-07-03T11:21:32+03:00',
    details: 'Some fuel leaked to somewhere.',
    global: true,
  },
  {
    id: 7,
    people_in_danger: 'no',
    vessels_involved: 'no',
    vessels: [],
    location_option: 'open_sea',
    level_of_emergency: 1,
    coordinates: {
      lat: null,
      lon: null,
    },
    incident_type: 'fire_explosion',
    date: null,
    details: 'Some fuel leaked to somewhere.',
    global: false,
  },
];
