import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../../../context/UserContext';
import SidebarHeader from './SidebarHeader';

const CalendarHeaderContainer = styled.div`
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 35%);
  margin-bottom: 2px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  padding-top: 6px;
  border-top: 1px solid #f8f8f8;
  margin-left: 3px;
  padding-top: ${props => (props.weatherOn ? '6px' : '63px')};
  width: calc(100% - 150px);
`;

const TimelineHeaders = props => {
  const { modules, user } = useContext(UserContext);
  const scrollHeaderRef = useRef();

  const getRootStyle = () => {
    return {
      ...props.style,
      display: 'flex',
      width: '100%',
      height: `${modules.weather_module === 'enabled' && '130px'}`,
    };
  };

  const getCalendarHeaderStyle = () => {
    const { calendarHeaderStyle } = props;
    return {
      ...calendarHeaderStyle,
      overflow: 'hidden',
    };
  };

  let calendarHeaders = [];
  const children = Array.isArray(props.children) ? props.children.filter(c => c) : [props.children];
  React.Children.map(children, child => {
    calendarHeaders.push(child);
  });

  const refHandler = el => {
    scrollHeaderRef.current = el;
    props.scrollRef(el);
  };

  return (
    <div data-testid="headerRootDiv" style={getRootStyle()}>
      <SidebarHeader />
      <CalendarHeaderContainer
        ref={refHandler}
        style={getCalendarHeaderStyle()}
        data-testid="headerContainer"
        weatherOn={modules.weather_module === 'enabled'}
        bptManaged={user.permissions.includes('manage_bpt')}
      >
        {calendarHeaders}
      </CalendarHeaderContainer>
    </div>
  );
};

export default TimelineHeaders;
