import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Checkbox from 'antd/es/checkbox';
import Tooltip from 'antd/es/tooltip';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import SelectWithSearch from '../../components/ui/SelectWithSearch';
import DateComponent from '../../components/ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Integrations = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  margin-right: 6px;
  margin-left: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service-type';

const ServiceTypes = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [serviceTypesData, setServiceTypesData] = useState(null);
  const [serviceTypesError, setServiceTypesError] = useState(null);
  const [serviceTypesLoading, setServiceTypesLoading] = useState(null);
  const [allowPublicViewing, setAllowPublicViewing] = useState(false);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initServiceType = {
    id: null,
    name: '',
    description: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
    type_code: null,
    allow_public_viewing: false,
  };

  const [serviceType, setServiceType] = useState(initServiceType);

  const initModal = {
    visible: false,
    confirmLoading: false,
    serviceType: {
      id: null,
      name: '',
      description: '',
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
      type_code: null,
      allow_public_viewing: false,
    },
  };

  const [modal, setModal] = useState(initModal);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'name',
    search: '',
  };

  const typesChanged = useCallback(data => {
    if (data?.results) {
      setServiceTypesData(data.results);
    } else {
      setServiceTypesData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: serviceTypesFetchLoading, error: serviceTypesFetchError, fetchData: serviceTypesFetchData } = useApi(
    'get',
    `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`,
    newParams,
    typesChanged,
    false
  );

  const {
    loading: serviceTypesSearchLoading,
    error: serviceTypesSearchError,
    fetchData: serviceTypesFetchSearchData,
  } = useApi('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/search`, {}, typesChanged, false);

  const {
    loading: serviceTypeCodesLoading,
    data: serviceTypeCodesData,
    error: serviceTypeCodesError,
    //fetchData: serviceTypeCodesFetchData,
  } = useApi('get', `${SERVICE_ORDERS_BASE_URL}/service-type-codes`, {});

  useEffect(() => {
    const { search, ...rest } = newParams;
    if (search) {
      const [order_by_field, order_by_order] = newParams.sort.split(' ');
      serviceTypesFetchSearchData(false, {
        query: {
          text: newParams.search,
        },
        pagination: {
          limit: newParams.limit,
          offset: newParams.offset,
        },
        order_by: [
          {
            field: order_by_field,
            order: order_by_order ? order_by_order : 'ASC',
          },
        ],
      });
    } else {
      serviceTypesFetchData(false, rest);
    }
  }, [newParams, serviceTypesFetchData, serviceTypesFetchSearchData]);

  useEffect(() => {
    setServiceTypesError(serviceTypesFetchError);
  }, [serviceTypesFetchError]);

  useEffect(() => {
    setServiceTypesError(serviceTypesSearchError);
  }, [serviceTypesSearchError]);

  useEffect(() => {
    setServiceTypesLoading(serviceTypesFetchLoading);
  }, [serviceTypesFetchLoading]);

  useEffect(() => {
    setServiceTypesLoading(serviceTypesSearchLoading);
  }, [serviceTypesSearchLoading]);

  if (serviceTypesError) {
    message.error(serviceTypesError, 4);
  }

  if (serviceTypeCodesError) {
    message.error(serviceTypeCodesError, 4);
  }

  const serviceTypeCodes =
    serviceTypeCodesError || !serviceTypeCodesData?.['service_type_codes']
      ? []
      : serviceTypeCodesData?.['service_type_codes'].map(type => {
        type.key = type.code;
        type.label = type.name;
        type.value = type.code;
        return type;
      });
  // Add empty option
  serviceTypeCodes.unshift({
    key: 'null',
    label: '',
    value: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serviceTypes =
    serviceTypesLoading || !serviceTypesData?.['service-types'] ? [] : serviceTypesData['service-types'];

  const { offset, total } =
    serviceTypesError || !serviceTypesData ? { offset: 0, total: 0 } : serviceTypesData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const {
        data: { 'service-type': type },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
      setServiceType({
        ...type,
        type_code: type.data?.type_code || null,
      });
      setAllowPublicViewing(type?.data?.allow_public_viewing || false);
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await serviceTypesFetchData(false, defaultParams);
  };

  const handleSetAllowPublicViewing = async values => {
    const { id, name, description, data } = values;
    const { allow_public_viewing, type_code } = data || {};

    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service_type: {
          id,
          name: name ? name : null,
          description: description ? description : null,
          allow_public_viewing: !allow_public_viewing,
          type_code: type_code ? type_code : null,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await serviceTypesFetchData(false, defaultParams);
  };

  const handleSave = async values => {
    const { name, description, type_code } = values;
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
      service_type: {
        name: name,
        description: description,
        allow_public_viewing: allowPublicViewing,
        type_code: type_code,
      },
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceType(initServiceType);
      handleSetMultiple(initServiceType);
      setAllowPublicViewing(false);
      history.replace(location.pathname);
      await serviceTypesFetchData(false, defaultParams);
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setServiceType(initServiceType);
    handleSetMultiple(initServiceType);
    setAllowPublicViewing(false);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service_type: {
          id: serviceType.id,
          name: serviceType.name ? serviceType.name : null,
          description: serviceType.description ? serviceType.description : null,
          allow_public_viewing: allowPublicViewing,
          type_code: serviceType.type_code ? serviceType.type_code : null,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setServiceType(initServiceType);
    handleSetMultiple(initServiceType);
    setAllowPublicViewing(false);
    history.replace(location.pathname);
    await serviceTypesFetchData(false, defaultParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setServiceType(initServiceType);
    handleSetMultiple(initServiceType);
    setAllowPublicViewing(false);
  };

  const handleModalChange = e => {
    const { name, value } = e.target;
    setServiceType({ ...serviceType, [name]: value });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setServiceType({ ...serviceType, [name]: value });
  };

  const getWasteIndicator = () => {
    return (
      <div key="waste">
        <Tooltip placement="top" title={t('Waste service')}>
          <IconContainer>
            <Icon type="waste" />
          </IconContainer>
        </Tooltip>
      </div>
    );
  };

  const fields = ['name', 'description', 'type_code'];
  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {});

  useEffect(() => {
    let isValid = serviceType?.name?.length > 0;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, serviceType]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      sortableKey: 'description',
    },
    {
      title: t('Type code'),
      key: 'type_code_name',
      sortableKey: 'type_code_name',
      render: record => {
        const type_code_name = record?.data?.type_code_name || '';
        const type_code = record?.data?.type_code || '';
        return (
          <>
            <Content2>{type_code_name}</Content2>
            <Content2>{type_code}</Content2>
          </>
        );
      },
    },
    {
      title: t('Allow public viewing'),
      key: 'allow_public_viewing',
      sortableKey: 'allow_public_viewing',
      render: record => (
        <Popconfirm
          title={t('Are you sure?')}
          onConfirm={() => handleSetAllowPublicViewing(record)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-show-in-fleet"
        >
          <Checkbox
            defaultChecked={record?.data?.allow_public_viewing}
            checked={record?.data?.allow_public_viewing}
            onClick={e => e.preventDefault()}
          />
        </Popconfirm>
      ),
    },
    {
      title: t('Integrations'),
      dataIndex: 'integrations',
      key: 'integrations',
      render: record => (
        <Integrations>
          {record?.map?.(source => {
            if (source === 'waste') {
              return getWasteIndicator();
            }
            return null;
          })}
        </Integrations>
      ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sortableKey: 'updated_at',
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete service type {{name}}?', { name: record.name })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: serviceTypesLoading || serviceTypeCodesLoading,
      text: t('Add service type'),
      icon: 'plus',
    },
  ];

  return (
    <>
      <Modal
        title={t('Edit services')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="name"
              label={t('Name')}
              value={serviceType.name ? serviceType.name : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="description"
              label={t('description')}
              value={serviceType.description ? serviceType.description : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <SelectWithSearch
              label={t('Type code')}
              name="type_code"
              value={serviceType.type_code ? serviceType.type_code : null}
              defaultValue={serviceType.type_code ? serviceType.type_code : null}
              options={serviceTypeCodes}
              onChange={value =>
                handleAddNewChange({
                  target: {
                    value: value,
                    name: 'type_code',
                  },
                })
              }
              style={{ width: '100%' }}
            />
            <Checkbox
              key={'allow_public_viewing'}
              defaultChecked={false}
              checked={allowPublicViewing}
              onClick={() => setAllowPublicViewing(current => !current)}
            >
              {t('Allow public viewing')}
            </Checkbox>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service type')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'type_code') {
                return (
                  <SelectWithSearch
                    label={t('Type code')}
                    key={field}
                    name={field}
                    value={values.field}
                    options={serviceTypeCodes}
                    onChange={value =>
                      handleAddNewChange({
                        target: {
                          value: value,
                          name: field,
                        },
                      })
                    }
                    style={{ width: '100%' }}
                  />
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="text"
                    onChange={handleAddNewChange}
                  />
                );
              }
            })}
            <Checkbox
              key={'allow_public_viewing'}
              defaultChecked={false}
              checked={allowPublicViewing}
              onClick={() => setAllowPublicViewing(current => !current)}
            >
              {t('Allow public viewing')}
            </Checkbox>
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service type')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={serviceTypes}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={serviceTypesLoading || serviceTypeCodesLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={offset}
        total={total}
        searchPlaceHolder={t('Search by name or description')}
        additionalButtons={additionalButtons}
      />
    </>
  );
};

export default ServiceTypes;
