import React, { useRef, useEffect, useContext, useState } from 'react';
import { Marker, Tooltip, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';

import VesselTooltip from './VesselTooltip';
import VesselPopup from './VesselPopup';

import { getOptions } from './MapUtils';
import { UserContext } from '../../context/UserContext';
import SpeedModalInMap from './SpeedModalInMap';
import { onMarkerMouseOver } from './MapHelpers';

const CIRCUMFERENCE_OF_EARTH = 2 * Math.PI * 6378137;
const RADIANS_CONVERT = Math.PI / 180;

const MapVessel = ({
  feature,
  isPublicMap,
  appliedZoom,
  setRoute,
  gray = false,
  setSideModalOpen,
  fleetViewOn,
  fleetData,
  setSelectedPortVessel,
  selectedPortVessel,
  timestamps,
  vesselsState,
}) => {
  const vesselRef = useRef();
  const options = getOptions();

  const map = useMap();

  const { user, modules } = useContext(UserContext);

  const [speedModalOpen, setSpeedModalOpen] = useState(false);
  const [direction, setDirection] = useState('top');

  useEffect(() => {
    if (selectedPortVessel && selectedPortVessel.mmsi === feature.properties.mmsi) {
      vesselRef.current.openPopup();
      setSelectedPortVessel(undefined);
    }
  }, [feature.properties.mmsi, selectedPortVessel, setSelectedPortVessel]);

  const markerClass = gray ? 'vessel_gray' : feature.properties.marker_class;

  const distancePerPixel = (latitude, zoomlevel, circumference, radiansConstant) => {
    let radians = latitude * radiansConstant;
    let scalePixel = (circumference * Math.cos(radians)) / 2 ** (zoomlevel + 8);
    return scalePixel;
  };

  const scaledSize = (distancePerPixel, length, backupSize) => {
    if (length) {
      return (1 / distancePerPixel) * length;
    } else {
      return (1 / distancePerPixel) * backupSize;
    }
  };

  const getVesselIcon = (feature, appliedZoomLevel) => {
    const iconSizeStyle =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? '-small'
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? '-medium'
          : '-large';

    const vesselIconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselIconPixelSize
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? options.mediumVesselIconPixelSize
          : options.largeVesselIconPixelSize;

    const courseIconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselCourseIconPixelSize
        : appliedZoomLevel >= options.mediumIconsZoomRange.start && appliedZoomLevel <= options.mediumIconsZoomRange.end
          ? options.mediumVesselCourseIconPixelSize
          : options.largeVesselCourseIconPixelSize;

    let markerStyleClass = 'marker-image-vessel' + iconSizeStyle;
    let markerCourseStyleClass = 'marker-image-course-over-ground' + iconSizeStyle;
    let markerIconFile;
    if (feature.properties.course_over_ground_degrees !== null) {
      if (feature.grey) {
        markerIconFile = options.vesselColorToIconMappings['defaultColor'];
      } else {
        markerIconFile =
          options.vesselColorToIconMappings[markerClass] !== undefined
            ? options.vesselColorToIconMappings[markerClass]
            : options.vesselColorToIconMappings['defaultColor'];
      }
    } else {
      if (feature.grey) {
        markerIconFile = options.vesselFullColorToIconMappings['defaultColor'];
      } else {
        markerIconFile =
          options.vesselFullColorToIconMappings[markerClass] !== undefined
            ? options.vesselFullColorToIconMappings[markerClass]
            : options.vesselFullColorToIconMappings['defaultColor'];
      }
    }

    let vesselMarkerProperties = {
      markerStyle: markerStyleClass,
      markerCourseStyle: markerCourseStyleClass,
      markerIconSize: vesselIconSize,
      courseIconSize: courseIconSize,
      courseIconOffset: (vesselIconSize - courseIconSize) / 2,
      markerIconFile: markerIconFile,
    };

    let vesselRotationDegrees = feature.properties.heading_degrees ? feature.properties.heading_degrees : '0';

    let htmlContent = `<div class="div-map-marker-vessel">
        <img class="${vesselMarkerProperties.markerStyle}"
          src="${vesselMarkerProperties.markerIconFile}"
          style="transform:rotate(${vesselRotationDegrees}deg);
          height:${vesselMarkerProperties.markerIconSize}px;
          left: 24%;
          important;" />`;
    if (feature.properties.course_over_ground_degrees) {
      htmlContent += `<div class="${vesselMarkerProperties.markerCourseStyle}"
          style="transform:rotate(${feature.properties.course_over_ground_degrees}deg);
          height:${vesselMarkerProperties.courseIconSize}px;
          width:${vesselMarkerProperties.courseIconSize}px;
          top:${vesselMarkerProperties.courseIconOffset}px;
          left:${vesselMarkerProperties.courseIconOffset}px;
          important;" />`;
    }
    htmlContent += '</div>';

    return L.divIcon({
      html: htmlContent,
      iconSize: [vesselMarkerProperties.markerIconSize, vesselMarkerProperties.markerIconSize],
      iconAnchor: [vesselMarkerProperties.markerIconSize / 2, vesselMarkerProperties.markerIconSize / 2],
      className: 'map-vessel-icon-class',
    });
  };

  const getScaledVesselIcon = (feature, appliedZoomLevel) => {
    feature.properties.mp = distancePerPixel(
      feature.properties.latitude,
      appliedZoom,
      CIRCUMFERENCE_OF_EARTH,
      RADIANS_CONVERT
    );

    let vesselScaledLength = scaledSize(
      feature.properties.mp,
      feature.properties.loa,
      options.scaledVesselBackupIconPixelSize
    );
    let vesselScaledBreadth = scaledSize(
      feature.properties.mp,
      feature.properties.beam,
      options.scaledVesselBackupIconPixelSize / 3
    );
    let vesselScaledBowOffset = scaledSize(feature.properties.mp, feature.properties.offset_bow, 0);
    let vesselScaledPortOffset = scaledSize(
      feature.properties.mp,
      feature.properties.offset_port,
      feature.properties.beam ? feature.properties.beam / 2 : options.scaledVesselBackupIconPixelSize / 6
    );
    const iconSizeStyle =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? '-small'
        : '-scaled';

    const vesselIconLength =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselIconPixelSize
        : vesselScaledLength;

    const vesselIconBreadth =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselIconPixelSize / 2
        : vesselScaledBreadth;

    const courseIconSize =
      appliedZoomLevel >= options.smallIconsZoomRange.start && appliedZoomLevel <= options.smallIconsZoomRange.end
        ? options.smallVesselCourseIconPixelSize
        : vesselScaledBreadth;

    let markerStyleClass = 'marker-image-vessel' + iconSizeStyle;
    let markerCourseStyleClass = 'marker-image-course-over-ground' + iconSizeStyle;
    let markerIconFile;
    if (appliedZoomLevel <= options.smallIconsZoomRange.end) {
      if (feature.grey) {
        markerIconFile = options.vesselColorToIconMappings['defaultColor'];
      } else {
        markerIconFile =
          options.vesselColorToIconMappings[markerClass] !== undefined
            ? options.vesselColorToIconMappings[markerClass]
            : options.vesselColorToIconMappings['defaultColor'];
      }
    } else {
      if (feature.grey) {
        markerIconFile = options.vesselFullColorToIconMappings['defaultColor'];
      } else {
        markerIconFile =
          options.vesselFullColorToIconMappings[markerClass] !== undefined
            ? options.vesselFullColorToIconMappings[markerClass]
            : options.vesselFullColorToIconMappings['defaultColor'];
      }
    }

    let vesselMarkerProperties = {
      markerStyle: markerStyleClass,
      markerCourseStyle: markerCourseStyleClass,
      markerIconLength: vesselIconLength,
      markerIconBreadth: vesselIconBreadth,
      courseIconSize: courseIconSize,
      courseIconOffset: (vesselIconLength - courseIconSize) / 2,
      markerIconFile: markerIconFile,
    };

    let vesselRotationDegrees = feature.properties.heading_degrees ? feature.properties.heading_degrees : '0';

    let transformOriginX =
      markerStyleClass === 'marker-image-vessel-scaled' && feature.properties.offset_port
        ? (feature.properties.offset_port / feature.properties.beam) * 100 + '%'
        : undefined;

    let transformOriginY =
      markerStyleClass === 'marker-image-vessel-scaled' && feature.properties.offset_bow
        ? (feature.properties.offset_bow / feature.properties.loa) * 100 + '%'
        : undefined;

    let htmlContent = `<div class="div-map-marker-vessel">
        <img class="${vesselMarkerProperties.markerStyle}"
        src="${vesselMarkerProperties.markerIconFile}";
        style="
        height:${vesselMarkerProperties.markerIconLength}px;
        width:${vesselMarkerProperties.markerIconBreadth}px;
        left: ${iconSizeStyle === '-small' ? '25%' : vesselScaledLength / 2 - vesselScaledPortOffset + 'px'};
        ${
  feature.properties.offset_bow && feature.properties.loa
    ? `top:  ${iconSizeStyle === '-small' ? 0 : vesselScaledLength / 2 - vesselScaledBowOffset + 'px'};`
    : '0;'
}
        transform: rotate(${vesselRotationDegrees}deg);
        transform-origin: ${transformOriginX} ${transformOriginY};
        !important;" />
        `;
    if (feature.properties.course_over_ground_degrees && appliedZoomLevel <= options.smallIconsZoomRange.end) {
      htmlContent += `<div class="${vesselMarkerProperties.markerCourseStyle}"
          style="transform:rotate(${feature.properties.course_over_ground_degrees}deg);
          height:${vesselMarkerProperties.courseIconSize}px;
          width:${vesselMarkerProperties.courseIconSize}px;
          top:${vesselMarkerProperties.courseIconOffset}px;
          left:${vesselMarkerProperties.courseIconOffset}px;
          important;" />`;
    } else if (feature.properties.course_over_ground_degrees && feature.properties.speed_knots) {
      htmlContent += `<div class="${vesselMarkerProperties.markerCourseStyle}"

            style="transform:rotate(${feature.properties.course_over_ground_degrees + 180}deg);
            height:${vesselMarkerProperties.courseIconSize}px;
            width:${vesselMarkerProperties.courseIconSize}px;
            top:${-vesselMarkerProperties.courseIconSize}px;
            left:${-vesselMarkerProperties.courseIconSize / 2}px;
            important;" />

            <svg id="SVGRoot" width="${courseIconSize}" height="${courseIconSize * 6}"
            version="1.1"
            preserveAspectRatio="none"
            viewBox="0 0 20 120"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
              <metadata>
                <rdf:RDF>
                  <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                    <dc:title/>
                  </cc:Work>
                </rdf:RDF>
              </metadata>
                <g transform="matrix(.54683 0 -4.9933e-8 .52063 -24.997 -21.618)" fill="none" stroke="currentColor" stroke-width="2.5">
                  <path d="m64 81-3e-6 ${parseFloat(
    38 + (feature.properties.speed_knots <= 16.5 ? feature.properties.speed_knots : 16.5) * 9
  )}"/>,
                  <path d="m53.028 ${parseFloat(
    107 + (feature.properties.speed_knots <= 16.5 ? feature.properties.speed_knots : 16.5) * 9
  )} 10.972 12.889 10.972 -12.889"/>
                </g>
            </svg>`;
    }

    htmlContent += '</div>';

    return L.divIcon({
      html: htmlContent,
      iconSize: [vesselMarkerProperties.markerIconLength, vesselMarkerProperties.markerIconLength],
      iconAnchor: [vesselMarkerProperties.markerIconLength / 2, vesselMarkerProperties.markerIconLength / 2],
      className: 'map-vessel-icon-class',
    });
  };

  const setRouteOnClick = () => {
    if (setRoute) {
      setRoute(feature.properties.imo, markerClass);
    }
    if (setSideModalOpen) {
      setSideModalOpen({
        imo: feature.properties.imo,
        name: feature.properties.name,
        port_call_id: undefined,
        eta: undefined,
      });
    }
  };

  let fleetTooltipData = undefined;
  let fleetTooltipAisData = undefined;

  if (fleetViewOn) {
    fleetTooltipData = fleetData
      .filter(vessel => vessel.imo === feature.properties.imo)
      .sort((vessel1, vessel2) => vessel1.eta - vessel2.eta);
    fleetTooltipAisData = fleetData
      .filter(vessel => vessel.imo === feature.properties.imo)
      .sort((a, b) => {
        return new Date(b.eta) - new Date(a.eta);
      });
  }

  let timestampData = undefined;
  if (timestamps && timestamps.length > 0) {
    timestampData = timestamps
      .filter(vessel => vessel.ship.imo === feature.properties.imo)
      .sort((vessel1, vessel2) => vessel1.ship.current_eta - vessel2.ship.current_eta);
  }

  return (
    <Marker
      icon={isPublicMap ? getVesselIcon(feature, appliedZoom) : getScaledVesselIcon(feature, appliedZoom)}
      position={L.GeoJSON.coordsToLatLng(feature.geometry.coordinates)}
      eventHandlers={{
        click: setRouteOnClick,
        mouseover: () => onMarkerMouseOver(map, feature.geometry.coordinates, direction, setDirection),
      }}
      ref={vesselRef}
    >
      <Tooltip
        direction={direction}
        className={'map-vessel-tooltip'}
        opacity={1}
        key={`${feature.properties.imo}-${direction}`}
      >
        <VesselTooltip
          feature={feature}
          fleetViewOn={fleetViewOn}
          fleetData={fleetTooltipData ? fleetTooltipData[0] : undefined}
          fleetAisData={fleetTooltipAisData ? fleetTooltipAisData[0] : undefined}
          timestampData={timestampData ? timestampData[0] : undefined}
        />
      </Tooltip>
      {!setSideModalOpen && (
        <Popup>
          <VesselPopup
            feature={feature}
            isPublicMap={isPublicMap}
            setSpeedModalOpen={setSpeedModalOpen}
            speedModalButton={
              vesselsState === 'port' &&
              modules.port_call_dashboard_module === 'enabled' &&
              user.permissions.includes('view_port_call_dashboard')
            }
          />
        </Popup>
      )}
      {speedModalOpen && (
        <SpeedModalInMap
          vesselName={feature.properties.name}
          portcallId={timestampData?.[0] ? timestampData[0].ship.id : undefined}
          mmsi={feature.properties.mmsi}
          setSpeedModalOpen={setSpeedModalOpen}
        />
      )}
    </Marker>
  );
};

export default MapVessel;
