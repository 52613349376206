import React from 'react';
import styled from 'styled-components';

const DotList = styled.div`
  display: flex;
  width: 60px;
  justify-content: center;
`;

const Dot = styled.div`
  background-color: ${props => (props.current ? props.theme.color.secondary : '#a8a8a8')};
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 9px;
`;

const MiniDot = styled.div`
  background-color: #a8a8a8;
  height: 2px;
  width: 2px;
  border-radius: 1px;
  margin-left: 1px;
  margin-right: 2px;
  margin-top: 10px;
`;

const Dots = ({ total, currentIndex }) => {
  const DotCreator = () => {
    const dotContainer = [];

    if (total < 6) {
      for (let i = 0; i < total; i++) {
        dotContainer.push(<Dot key={i} current={i === currentIndex} />);
      }
    } else {
      if (currentIndex < 3) {
        dotContainer.push(<Dot key={0} current={currentIndex === 0} />);
        dotContainer.push(<Dot key={1} current={currentIndex === 1} />);
        dotContainer.push(<Dot key={2} current={currentIndex === 2} />);
        dotContainer.push(<Dot key={3} current={false} />);
        dotContainer.push(<MiniDot key={4} />);
      } else if (currentIndex > total - 4) {
        dotContainer.push(<MiniDot key={0} />);
        dotContainer.push(<Dot key={1} current={false} />);
        dotContainer.push(<Dot key={2} current={currentIndex === total - 3} />);
        dotContainer.push(<Dot key={3} current={currentIndex === total - 2} />);
        dotContainer.push(<Dot key={4} current={currentIndex === total - 1} />);
      } else {
        dotContainer.push(<MiniDot key={0} />);
        dotContainer.push(<Dot key={1} current={false} />);
        dotContainer.push(<Dot key={2} current={true} />);
        dotContainer.push(<Dot key={3} current={false} />);
        dotContainer.push(<MiniDot key={4} />);
      }
    }

    return dotContainer;
  };

  return <DotList id="portcall-carousel-dots">{DotCreator()}</DotList>;
};

export default Dots;
