import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Table from 'antd/es/table';
import Tabs from 'antd/es/tabs';

import { UserContext } from '../../context/UserContext';
import { WeatherContext } from '../../context/WeatherContext';

import DayWeatherCard from './DayWeatherCard';
import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';
import WeatherText from '../ui/WeatherText';
import { getWindType } from './weatherUtils';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Wrapper = styled.div`
  padding: 10px 16px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  margin: 10px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin: 0px;
    margin-top: 6px;
  }

  .ant-tabs-tab {
    text-trasform: lowercase !important;
    font-weight: normal;
    text-transform: capitalize;
    background-color: #f3f3f3;
    border: none;
    padding: 0;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 2px;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-table-thead > tr > th {
    background: #ffffff;
    font-weight: 600;
    color: #747d7d;
  }
  .ant-tabs-tab-active {
    font-weight: normal !important;
    background: #e2f0ff;
  }
  .ant-table-content {
    overflow-x: auto;
  }
`;

const CardHeader = styled.div`
  display: flex;
  padding: 0 0 10px 0;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 1.25rem;
`;

const CardDetails = styled.div`
  margin-top: 20px;
`;

const WeatherSituation = styled.div`
  display: flex;

  svg {
    margin-top: -10px;
    width: 40px;
    height: 40px;
  }

  label {
    margin-left: 30px;
  }
`;

const WindContainer = styled.div`
  display: flex;
  white-space: nowrap;

  svg {
    width: 18px;
    margin-top: -3px;
  }
`;

const WindInfo = styled.div`
  margin-left: 12px;
`;

const DateRow = styled.div`
  font-weight: 700;
`;

const PortHourlyWeatherTable = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { forecastWeather } = useContext(WeatherContext);

  const [currentTab, setCurrentTab] = useState(undefined);

  const columns = [
    {
      title: t('Hours'),
      dataIndex: 'time',
      key: 'time',
      render: (value, obj) => {
        return obj.headerRow ? <DateRow>{dayjs(value).format('ddd DD, MMM')}</DateRow> : dayjs(value).format('HH:mm');
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 8 : 1 };
      },
      width: '8%',
    },
    {
      title: t('Temperature'),
      dataIndex: 'temperature',
      key: 'temperature',
      render: value => {
        return <strong>{value || value === 0 ? Math.round(value) : '-'} °C</strong>;
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
      width: '15%',
    },
    {
      title: t('Weather situation'),
      dataIndex: 'description',
      key: 'description',
      render: (value, obj) => {
        return (
          <WeatherSituation>
            <WeatherIconCombined type={value} descriptionObj={obj.description_object} />
            <WeatherText
              style={{ whiteSpace: 'nowrap' }}
              type={value}
              descriptionText={obj.description_object ? obj.description_object.description_text : null}
            />
          </WeatherSituation>
        );
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
    },
    {
      title: t('Rain intensity'),
      dataIndex: 'rain_intensity',
      key: 'rain_intensity',
      render: value => {
        return `${value || value === 0 ? value : '-'} mm/h`;
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
    },
    {
      title: t('Wind'),
      dataIndex: 'wind_speed',
      key: 'wind_speed',
      render: (value, obj) => {
        return (
          <WindContainer>
            <WeatherIcon type={value || value === 0 ? getWindType(value) : null} rotation={obj.wind_direction || 0} />
            <WindInfo> {value || value === 0 ? Math.round(value) : '-'} m/s</WindInfo>
          </WindContainer>
        );
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
      width: '10%',
    },
    {
      title: t('Gusts'),
      dataIndex: 'wind_gust',
      key: 'wind_gust',
      render: value => {
        return `${value || value === 0 ? Math.round(value) : '-'} m/s`;
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
      width: '10%',
    },
    {
      title: t('Humidity'),
      dataIndex: 'humidity',
      key: 'humidity',
      render: value => {
        return `${value || value === 0 ? Math.round(value) : '-'} %`;
      },
      onCell: (value, obj) => {
        return { colSpan: obj.headerRow ? 0 : 1 };
      },
      width: '10%',
    },
  ];

  let dataToShow = [];
  let tabOptions = [];

  if (forecastWeather?.length) {
    const currentDay = dayjs(forecastWeather[0].time).date();
    const currentHour = dayjs(forecastWeather[0].time).hour();

    if (currentHour >= 15) {
      tabOptions.push({
        dayNumber: currentDay,
        date: forecastWeather[0].time,
        data: forecastWeather[0],
      });
    } else {
      tabOptions.push({
        dayNumber: currentDay,
        date: forecastWeather[0].time,
        data: forecastWeather[15 - currentHour],
      });
    }

    if (!currentTab) {
      setCurrentTab(currentDay);
    }

    for (let i = 0; i < forecastWeather.length; i++) {
      const dayNumber = dayjs(forecastWeather[i].time).date();

      if (!tabOptions.find(o => o.dayNumber === dayNumber)) {
        if (forecastWeather[i + 15]) {
          tabOptions.push({
            dayNumber: dayNumber,
            date: forecastWeather[0].time,
            data: forecastWeather[i + 15],
          });
        } else {
          tabOptions.push({
            dayNumber: dayNumber,
            date: forecastWeather[0].time,
            data: forecastWeather[forecastWeather.length - 1],
          });
        }
      }

      if (
        dayjs(forecastWeather[i].time).date() === Number(currentTab) ||
        (dataToShow.length > 0 && dataToShow.length < 24)
      ) {
        dataToShow.push(forecastWeather[i]);

        if (
          dataToShow.length < 24 &&
          dayjs(forecastWeather[i].time).format('HH') === '23' &&
          !!forecastWeather[i + 1]
        ) {
          dataToShow.push({
            time: dayjs(forecastWeather[i + 1].time)
              .add(1, 'minutes')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss+00:00'),
            headerRow: true,
          });
        }
      }
    }
  }

  const handleTabChange = tab => {
    setCurrentTab(tab);
  };

  // If we have rain probability available, we'll show it
  if (dataToShow?.[0]?.rain_probability) {
    columns.splice(3, 0, {
      title: t('Rain probability'),
      dataIndex: 'rain_probability',
      key: 'rain_probability',
      render: (value, obj) => {
        return {
          children: `${value || value === 0 ? value : '-'} %`,
          props: { colSpan: obj.headerRow ? 0 : 1 },
        };
      },
    });
  }

  const tabItems = [];

  tabOptions.forEach(option => {
    tabItems.push({
      label: <DayWeatherCard data={option.data} />,
      children: (
        <CardDetails>
          <Table
            dataSource={dataToShow}
            columns={columns}
            pagination={false}
            rowKey="time"
            rowClassName={record => {
              if (record.headerRow) {
                return 'weather-header-row';
              }
            }}
          />
        </CardDetails>
      ),
      key: option.dayNumber.toString(),
    });
  });

  return (
    <Wrapper>
      <CardHeader id="weather-hourly-title">
        <Title>{t('Hourly Weather')}</Title>
      </CardHeader>
      <Tabs
        activeKey={currentTab ? currentTab.toString() : null}
        onChange={tab => handleTabChange(tab)}
        items={tabItems}
      />
    </Wrapper>
  );
};
export default PortHourlyWeatherTable;
