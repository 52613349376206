import { TileLayer, ZoomControl, useMapEvents, useMap } from 'react-leaflet';
import React, { useRef, useEffect } from 'react';

import { getOptions } from '../map/MapUtils';
import PocCircleMarkers from '../map-poc/PocCircleMarkers';

import useApi from '../../hooks/useApi';

const MapInnerContainer = ({
  setZoom,
  zoom,
  searchString,
  selectedVessel,
  setSelectedVessel,
  reloadData,
  setReloadData,
}) => {
  const { data, fetchData } = useApi('get', 'ais/all', {});
  // const { data, fetchData } = useApi('get', 'ais/helcom', {});

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const options = getOptions();
  const map = useMap();

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
    },
  });

  useEffect(() => {
    if (!map || !reloadData) return;
    fetchData(false);
    setReloadData(false);
  }, [fetchData, map, reloadData, setReloadData]);

  return data ? (
    <>
      <ZoomControl position="bottomright" />
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
      />
      <PocCircleMarkers
        searchString={searchString}
        vesselsData={data}
        selectedVessel={selectedVessel}
        setSelectedVessel={setSelectedVessel}
        zoom={zoom}
      />
    </>
  ) : null;
};

export default MapInnerContainer;
