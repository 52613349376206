import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';

const RowContainerSplit = styled.div`
  display: flex;
  padding: 6px 0;
`;

const Header = styled.div`
  margin-right: 6px;
  white-space: nowrap;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
`;

const RowContainer = styled.div`
  padding: 6px 0 9px 0;
`;

const InfoContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const AckHeader = styled.div`
  font-weight: 600;
  margin-bottom: -10px;
`;

const Value = styled.div`
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ServiceOrderAckInfo = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  return (
    <InfoContainer>
      <AckHeader>{t('Acknowledgement')}</AckHeader>
      <RowContainer>
        <Container style={{ width: 'calc((100% - 130px) * 0.2)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Email')}:</Header>
            <Tooltip title={data.ack?.email} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Value>{data.ack?.email || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) * 0.18)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Phone')}:</Header>
            <Tooltip title={data.ack?.phone} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Value>{data.ack?.phone || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
        <Container style={{ width: 'calc((100% - 130px) / 20 * 6)', paddingRight: '12px' }}>
          <RowContainerSplit>
            <Header>{t('Note')}:</Header>
            <Tooltip title={data.ack?.notes} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Value>{data.ack?.notes || '-'}</Value>
            </Tooltip>
          </RowContainerSplit>
        </Container>
      </RowContainer>
    </InfoContainer>
  );
};

export default ServiceOrderAckInfo;
