import * as React from "react";
const SvgLogoCentralBalticColor = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 3584 1084", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(4.16667,0,0,4.16667,0,0)" }, /* @__PURE__ */ React.createElement("path", { d: "M335.129,217.18C320.818,217.18 310.264,205.916 310.264,191.609C310.264,177.441 320.748,165.895 335.342,165.895C344.337,165.895 349.719,169.08 354.68,173.758L350.853,177.863C346.675,173.898 341.999,171.066 335.27,171.066C324.29,171.066 316.074,179.92 316.074,191.465C316.074,203.014 324.361,212.008 335.27,212.008C342.069,212.008 346.533,209.389 351.278,204.854L354.963,208.467C349.79,213.711 344.125,217.18 335.129,217.18Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.943,195.998C389.378,189.342 385.553,183.535 378.256,183.535C371.881,183.535 367.065,188.844 366.356,195.998L389.943,195.998ZM379.248,212.434C384.277,212.434 387.818,210.379 390.794,207.262L394.194,210.309C390.51,214.416 386.049,217.18 379.105,217.18C369.048,217.18 360.83,209.459 360.83,198.053C360.83,187.43 368.268,178.928 378.396,178.928C389.235,178.928 395.471,187.572 395.471,198.336C395.471,198.836 395.471,199.398 395.399,200.039L366.356,200.039C367.136,207.971 372.802,212.434 379.248,212.434Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M410.479,216.33L405.024,216.33L405.024,179.709L410.479,179.709L410.479,186.084C412.889,182.115 416.713,178.928 423.019,178.928C431.872,178.928 437.045,184.881 437.045,193.59L437.045,216.33L431.589,216.33L431.589,194.936C431.589,188.139 427.904,183.887 421.459,183.887C415.156,183.887 410.479,188.492 410.479,195.361L410.479,216.33Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M456.019,205.916C456.019,210.381 458.499,212.008 462.18,212.008C464.023,212.008 465.58,211.654 467.493,210.734L467.493,215.408C465.58,216.402 463.525,216.969 460.907,216.969C455.026,216.969 450.563,214.064 450.563,206.625L450.563,184.523L445.465,184.523L445.465,179.709L450.563,179.709L450.563,168.66L456.019,168.66L456.019,179.709L467.636,179.709L467.636,184.523L456.019,184.523L456.019,205.916Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M482.718,216.33L477.263,216.33L477.263,179.709L482.718,179.709L482.718,189.27C485.409,183.178 490.724,178.787 497.662,179.072L497.662,184.949L497.241,184.949C489.235,184.949 482.718,190.688 482.718,201.736L482.718,216.33Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M506.944,205.42C506.944,210.096 511.196,212.787 516.153,212.787C522.886,212.787 528.41,208.68 528.41,202.873L528.41,199.33C525.719,198.551 522.106,197.77 517.641,197.77C510.771,197.77 506.944,200.744 506.944,205.42ZM506.308,186.721L504.679,182.258C508.716,180.418 512.684,179.213 517.997,179.213C527.984,179.213 533.582,184.313 533.582,193.947L533.582,216.33L528.337,216.33L528.337,210.805C525.788,214.133 521.539,217.107 515.091,217.107C508.293,217.107 501.423,213.285 501.423,205.633C501.423,197.842 507.795,193.732 517.077,193.732C521.75,193.732 525.079,194.371 528.337,195.293L528.337,194.016C528.337,187.43 524.3,184.029 517.43,184.029C513.109,184.029 509.708,185.16 506.308,186.721Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("rect", { x: 545.686, y: 164.617, width: 5.452, height: 51.713, style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M620.338,202.373C620.338,196.922 615.803,193.805 607.162,193.805L591.508,193.805L591.508,211.299L608.154,211.299C615.662,211.299 620.338,207.971 620.338,202.373ZM617.574,179.92C617.574,174.963 613.68,171.771 606.596,171.771L591.508,171.771L591.508,188.844L606.17,188.844C612.902,188.844 617.574,185.799 617.574,179.92ZM623.24,179.141C623.24,185.658 619.275,188.986 615.379,190.688C621.258,192.459 626.006,195.859 626.006,202.729C626.006,211.299 618.852,216.33 608.014,216.33L585.984,216.33L585.984,166.746L607.094,166.746C616.865,166.746 623.24,171.564 623.24,179.141Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M638.957,205.42C638.957,210.096 643.205,212.787 648.166,212.787C654.895,212.787 660.42,208.68 660.42,202.873L660.42,199.33C657.729,198.551 654.117,197.77 649.654,197.77C642.783,197.77 638.957,200.744 638.957,205.42ZM638.322,186.721L636.691,182.258C640.729,180.418 644.693,179.213 650.006,179.213C659.994,179.213 665.592,184.313 665.592,193.947L665.592,216.33L660.35,216.33L660.35,210.805C657.801,214.133 653.551,217.107 647.104,217.107C640.303,217.107 633.434,213.285 633.434,205.633C633.434,197.842 639.809,193.732 649.086,193.732C653.76,193.732 657.092,194.371 660.35,195.293L660.35,194.016C660.35,187.43 656.313,184.029 649.439,184.029C645.117,184.029 641.719,185.16 638.322,186.721Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("rect", { x: 677.699, y: 164.617, width: 5.451, height: 51.713, style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M703.193,205.916C703.193,210.381 705.672,212.008 709.355,212.008C711.199,212.008 712.756,211.654 714.67,210.734L714.67,215.408C712.756,216.402 710.701,216.969 708.082,216.969C702.201,216.969 697.74,214.064 697.74,206.625L697.74,184.523L692.641,184.523L692.641,179.709L697.74,179.709L697.74,168.66L703.193,168.66L703.193,179.709L714.811,179.709L714.811,184.523L703.193,184.523L703.193,205.916Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M724.932,179.709L730.389,179.709L730.389,216.332L724.932,216.332L724.932,179.709ZM724.582,165.684L730.814,165.684L730.814,171.703L724.582,171.703L724.582,165.684Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M759.568,217.18C748.871,217.18 740.795,208.467 740.795,198.125C740.795,187.713 748.871,178.928 759.568,178.928C766.51,178.928 770.832,181.832 774.23,185.443L770.617,189.27C767.715,186.227 764.457,183.744 759.496,183.744C752.059,183.744 746.393,190.049 746.393,197.984C746.393,205.988 752.201,212.291 759.854,212.291C764.525,212.291 768.137,209.953 771.043,206.836L774.512,210.096C770.832,214.205 766.439,217.18 759.568,217.18Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.359,42.821L310.264,42.821L310.264,104.563C316.793,103.326 323.501,102.135 330.359,101.002L330.359,42.821Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.197,81.182L469.64,81.182L469.64,64.22L453.197,64.22L453.197,46.344L433.361,46.344L433.361,64.22L425.013,64.22L425.013,81.182L433.361,81.182L433.361,88.862C439.91,88.354 446.518,87.886 453.197,87.454L453.197,81.182Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M515.44,78.833C520.309,78.833 524.104,80.883 526.662,84.343C533.264,84.204 539.896,84.1 546.564,84.034C541.961,71.857 531.828,62.916 515.44,62.916C499.95,62.916 488.032,72.446 483.21,85.799C489.977,85.487 496.796,85.213 503.661,84.981C506.336,81.102 510.335,78.833 515.44,78.833Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M599.621,84.26C601.41,83.874 603.311,83.663 605.35,83.663L606.396,83.663L606.396,62.915C594.654,62.395 588.125,68.655 584.082,78.313L584.082,64.219L564.25,64.219L564.25,83.939C564.612,83.939 564.973,83.936 565.335,83.936C576.852,83.936 588.285,84.047 599.621,84.26Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M659.9,83.663L660.941,83.663L660.941,62.915C649.203,62.395 642.676,68.656 638.633,78.311L638.633,64.219L618.799,64.219L618.799,84.721C629.111,85.024 639.326,85.411 649.42,85.885C652.395,84.407 655.908,83.663 659.9,83.663Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M703.877,78.833C711.221,78.833 716.135,83.473 718.016,90.616C724.504,91.213 730.914,91.852 737.242,92.523C734.59,76.237 723.889,62.916 703.877,62.916C687.904,62.916 675.736,73.055 671.234,87.065C677.645,87.454 683.996,87.877 690.295,88.334C692.771,82.45 697.438,78.833 703.877,78.833Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M766.777,95.797L766.777,95.536C766.777,86.01 774.084,79.355 783.74,79.355C793.395,79.355 800.828,86.01 800.828,95.536L800.828,95.797C800.828,97.63 800.553,99.354 800.037,100.955C806.996,102.105 813.791,103.307 820.402,104.563L820.402,64.217L800.572,64.217L800.572,73.353C795.227,67.481 788.83,62.913 777.998,62.913C762.457,62.913 747.939,73.956 747.031,93.612C753.719,94.375 760.311,95.186 766.791,96.039C766.791,95.955 766.777,95.881 766.777,95.797Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.141,81.32C377.449,80.695 380.357,80.086 381.785,80.086C383.216,80.086 386.119,80.695 387.431,81.32C392.479,83.721 394.76,87.308 395.416,92.313C401.701,91.651 408.077,91.032 414.522,90.446C412.3,80.272 406.202,71.69 396.793,66.907C392.469,64.711 387.454,62.907 381.785,62.907C376.116,62.907 371.098,64.711 366.781,66.907C355.048,72.866 348.457,84.732 348.124,98.225C354.602,97.279 361.211,96.373 367.931,95.512C367.971,88.747 370.069,84.207 376.141,81.32Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.359,134.158L330.359,100.997C323.501,102.136 316.793,103.321 310.264,104.564L310.264,134.158L330.359,134.158Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.718,135.334C460.371,135.334 465.201,133.768 469.376,131.283L469.376,115.367C466.503,116.931 463.242,117.849 459.592,117.849C455.152,117.849 453.195,115.63 453.195,111.062L453.195,87.45C446.518,87.883 439.907,88.357 433.361,88.864L433.361,114.318C433.361,130.508 441.583,135.334 453.718,135.334Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M480.861,99.581C480.861,120.979 496.391,135.722 517.399,135.722C530.054,135.722 539.449,130.765 545.974,122.675L534.622,112.625C529.141,117.718 524.314,119.804 517.662,119.804C508.788,119.804 502.521,115.107 500.565,106.107L549.236,106.107C549.367,104.274 549.498,102.451 549.498,100.885C549.498,94.966 548.519,89.209 546.564,84.034C539.898,84.098 533.264,84.202 526.662,84.342C528.493,86.815 529.693,90.008 530.185,93.707L500.307,93.707C500.901,90.299 502.036,87.342 503.666,84.979C496.798,85.216 489.977,85.485 483.21,85.797C481.695,89.988 480.861,94.547 480.861,99.321L480.861,99.581Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M584.082,134.158L584.082,108.322C584.082,94.207 589.875,86.361 599.621,84.256C588.285,84.045 576.852,83.938 565.335,83.938L564.25,83.938L564.25,134.158L584.082,134.158Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M638.627,134.158L638.627,108.322C638.627,96.676 642.57,89.299 649.42,85.883C639.324,85.411 629.109,85.022 618.797,84.719L618.797,134.158L638.627,134.158Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M669.301,99.581C669.301,120.979 684.824,135.722 705.838,135.722C718.492,135.722 727.887,130.765 734.412,122.675L723.059,112.625C717.58,117.718 712.754,119.804 706.096,119.804C697.227,119.804 690.959,115.107 689.004,106.107L737.674,106.107C737.799,104.274 737.938,102.451 737.938,100.885C737.938,98.041 737.693,95.25 737.244,92.524C730.922,91.848 724.51,91.213 718.016,90.614C718.279,91.601 718.479,92.629 718.623,93.707L688.74,93.707C689.082,91.757 689.605,89.963 690.295,88.336C684.002,87.876 677.645,87.452 671.23,87.067C669.994,90.907 669.301,95.02 669.301,99.321L669.301,99.581Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M746.947,95.797C746.947,116.545 761.689,128.419 778,128.419C788.568,128.419 794.963,124.108 800.834,117.063L800.834,120.459C800.834,132.986 794.443,139.506 780.871,139.506C771.607,139.506 764.43,137.159 757.246,133.115L750.473,147.99C759.336,152.688 770.041,155.291 781.391,155.291C794.963,155.291 805.012,152.424 811.531,145.904C817.406,140.029 820.406,130.892 820.406,118.369L820.406,104.563C813.791,103.307 806.994,102.105 800.037,100.955C797.879,107.637 791.537,111.977 783.74,111.977C774.168,111.977 766.91,105.561 766.789,96.039C760.311,95.186 753.721,94.375 747.031,93.612C747.006,94.251 746.947,94.872 746.947,95.536L746.947,95.797Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.646,95.683L395.646,134.138L415.481,134.138L415.481,99.295C415.481,96.253 415.143,93.29 414.525,90.446C408.079,91.034 401.703,91.652 395.415,92.313C395.556,93.377 395.646,94.487 395.646,95.683Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.092,99.297L348.092,134.136L367.926,134.136L367.926,95.681C367.926,95.628 367.934,95.571 367.934,95.51C361.214,96.372 354.604,97.278 348.126,98.229C348.118,98.586 348.092,98.942 348.092,99.297Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.688,138.401C115.906,138.401 82.849,121.873 82.849,101.481C82.849,81.088 115.906,64.561 156.688,64.561C197.468,64.561 230.525,81.088 230.525,101.481C230.525,121.873 197.468,138.401 156.688,138.401ZM156.688,42.935C92.018,42.935 39.593,69.147 39.593,101.481C39.593,133.818 92.018,160.029 156.688,160.029C221.355,160.029 273.779,133.818 273.779,101.481C273.779,69.147 221.355,42.935 156.688,42.935Z", style: {
  fill: "rgb(93,169,221)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.688,73.426C125.699,73.426 100.578,85.986 100.578,101.481C100.578,116.974 125.699,129.535 156.688,129.535C187.675,129.535 212.794,116.974 212.794,101.481C212.794,85.986 187.675,73.426 156.688,73.426Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M273.78,101.482C273.78,99.06 273.486,96.676 272.918,94.334L229.979,96.969C230.34,98.45 230.524,99.956 230.524,101.482C230.524,107.914 226.911,114.246 221.114,119.521C213.566,126.244 201.94,131.67 187.902,134.949L206.179,154.557C228.368,149.379 246.754,140.816 258.729,130.205L258.741,130.209C258.799,130.157 258.853,130.104 258.911,130.051C258.979,129.991 259.053,129.931 259.121,129.869L259.107,129.866C268.222,121.528 273.78,111.639 273.78,101.482Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M39.593,101.481C39.593,91.195 44.898,81.53 54.216,73.127L92.151,83.527C86.226,88.842 82.849,94.963 82.849,101.481C82.849,103.004 83.032,104.506 83.39,105.979L40.457,108.627C39.887,106.283 39.593,103.9 39.593,101.481Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M179.071,44.004C203.822,46.398 225.79,52.69 242.324,61.55L210.638,76.274C200.209,70.695 186.36,66.735 170.762,65.231L179.071,44.004Z", style: {
  fill: "rgb(31,65,154)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.688,69.305C171.026,69.305 182.649,78.315 182.649,92.657C182.649,94.666 182.421,96.625 181.987,98.504C181.832,99.178 181.652,99.838 181.447,100.488C178.962,106.184 168.821,110.445 156.688,110.445C144.553,110.445 134.41,106.184 131.926,100.488C131.719,99.838 131.539,99.178 131.382,98.504C130.951,96.625 130.723,94.666 130.723,92.657C130.723,78.315 142.346,69.305 156.688,69.305Z", style: {
  fill: "rgb(237,24,71)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.505,54.656C152.505,52.202 154.379,50.212 156.687,50.212C158.995,50.212 160.867,52.202 160.867,54.656L160.867,75.4C160.867,77.858 158.995,79.848 156.687,79.848C154.379,79.848 152.505,77.858 152.505,75.4L152.505,54.656Z", style: {
  fill: "white",
  fillRule: "nonzero"
} })));
export default SvgLogoCentralBalticColor;
