import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT } from '../../utils/constants';

import useApi from '../../hooks/useApi';

import List from '../ui/List';
import Icon from '../ui/Icon';
import Tooltip from 'antd/es/tooltip';
import DateComponent from '../ui/DateComponent';

const FleetDataViewContainer = styled.div`
  width: 100%;
  margin: 0 12px;
`;

const ColorBackground = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.white};
`;

const WarningBackground = styled(ColorBackground)`
  background-color: ${({ theme }) => theme.color.warning};
`;

const Heading1 = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Heading2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  text-transform: uppercase;
  font-weight: 700;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Success1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.success};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Failure1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.warning};
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const DeliveryCell = styled.div`
  white-space: nowrap;
`;

const FleetServiceOrdersTable = () => {
  const { namespace, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const defaultParams = {
    limit: 50,
    offset: 0,
    sort: 'id',
    search: '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Vessel'),
      key: 'vessel',
      sortableKey: 'vessel_name',
      render: record => {
        return (
          <>
            <Heading1>{record?.vessel_name}</Heading1>
            <Content1>
              {t('IMO')} {record?.imo}
            </Content1>
            <Content1>
              {t('MMSI')} {record?.mmsi}
            </Content1>
            <div>
              <Heading2>{t('Port call id:')}</Heading2>
              <Content2>{record?.port_call_id}</Content2>
            </div>
          </>
        );
      },
    },
    {
      title: t('Order Lines'),
      key: 'order_lines',
      render: record => {
        return (
          <>
            <Heading1>{t('Count: {{orderLines}}', { orderLines: record?.order_lines?.length || 0 })}</Heading1>
            {record?.order_lines?.map((orderLine, index) => (
              <Content2 key={`${orderLine.service_provider_name}-${orderLine.service_type_name}-${index}`}>
                {orderLine.service_provider_name}: {orderLine.service_type_name}
              </Content2>
            ))}
          </>
        );
      },
    },
    {
      title: t('Service Type'),
      dataIndex: 'order_lines',
      key: 'service_type',
      sortableKey: 'service_type',
      render: record => (record ? record[0].service_type_name : null),
    },
    {
      title: t('Acknowledgement'),
      key: 'ack',
      render: record => {
        const { unAnswered, accepted, rejected } = record?.order_lines
          ? record.order_lines.reduce(
            (acc, curr) => {
              acc.unAnswered += !curr.ack ? 1 : 0;
              acc.accepted += curr.ack?.status === 'accepted' ? 1 : 0;
              acc.rejected += curr.ack?.status === 'rejected' ? 1 : 0;
              return acc;
            },
            {
              unAnswered: 0,
              accepted: 0,
              rejected: 0,
            }
          )
          : {
              unAnswered: 0,
              accepted: 0,
              rejected: 0,
            };

        return (
          <>
            <Content1>{t('Unanswered: {{unAnswered}}', { unAnswered })}</Content1>
            <Success1>{t('Accepted: {{accepted}}', { accepted })}</Success1>
            <Failure1>{t('Rejected: {{rejected}}', { rejected })}</Failure1>
          </>
        );
      },
    },
    {
      title: t('Deliveries'),
      key: 'deliveries',
      render: record => {
        const { deliveries, pending, inProgress, done } = record?.order_lines
          ? record.order_lines.reduce(
            (acc, curr) => {
              acc.deliveries += curr.deliveries?.filter?.(item => item.status !== 'cancelled').length || 0;
              acc.pending += curr.deliveries?.filter?.(item => item.status === 'pending').length || 0;
              acc.inProgress += curr.deliveries?.filter?.(item => item.status === 'in_progress').length || 0;
              acc.done += curr.deliveries?.filter?.(item => item.status === 'done').length || 0;
              return acc;
            },
            {
              deliveries: 0,
              pending: 0,
              inProgress: 0,
              done: 0,
            }
          )
          : {
              deliveries: 0,
              pending: 0,
              inProgress: 0,
              done: 0,
            };

        return (
          <DeliveryCell>
            <Heading1>{t('Count: {{deliveries}}', { deliveries })}</Heading1>
            <Content2>{t('Pending: {{pending}}', { pending })}</Content2>
            <Content2>{t('In progress: {{inProgress}}', { inProgress })}</Content2>
            <Success1>{t('Done: {{done}}', { done })}</Success1>
          </DeliveryCell>
        );
      },
    },
    {
      title: t('Status'),
      key: 'status',
      render: record => {
        const cancelled =
          record?.order_lines?.length > 0 ? record?.order_lines.every(item => item.is_cancelled) : false;
        return cancelled ? (
          <Tooltip placement="top" title={t('Are all order lines cancelled?')}>
            <WarningBackground>{t('Cancelled')}</WarningBackground>
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title={t('Are all deliveries of all order lines done (not inluding cancelled order lines or deliveries)?')}
          >
            {record?.done ? (
              <Icon type="check-circle" color="#007AFF" fill="none" />
            ) : (
              <Icon type="x-circle" color="#D0011C" fill="none" />
            )}
          </Tooltip>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sortableKey: 'updated_at',
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const { loading, data, error, fetchData } = useApi(
    'post',
    'service-orders/service-order/search',
    newParams,
    null,
    false
  );

  useEffect(() => {
    const [order_by_field, order_by_order] = newParams.sort.split(' ');

    if (modules?.service_order_module === 'enabled' && user.permissions.includes('view_service_order')) {
      fetchData(false, {
        query: {
          text: newParams.search,
          conditions: {
            and: [
              {
                type: 'order_line_allow_public_viewing',
                operator: 'is',
                value: true,
              },
            ],
          },
        },
        pagination: {
          limit: newParams.limit,
          offset: newParams.offset,
        },
        order_by: [
          {
            field: order_by_field,
            order: order_by_order || 'ASC',
          },
        ],
      });
    }
  }, [newParams, fetchData, modules, user.permissions]);

  let showableData = error || !data ? [] : data.results ? data.results['service-orders'] : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  return (
    <FleetDataViewContainer>
      <List
        rowKey="id"
        columns={columns}
        dataSource={showableData}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
      />
    </FleetDataViewContainer>
  );
};

export default FleetServiceOrdersTable;
