import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';

import { UserContext } from '../../context/UserContext';
import DataExport from './DataExport';
import ExportList from './ExportList';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 168px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});

  @media (max-width: ${mobilePixelMaxWidthLimit} && min-width: 851px) {
    height: calc(100vh - 154px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }

  @media (max-width: 850px) {
    height: calc(100vh - 138px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const ListContainer = styled.div`
  display: flex;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const MiddleLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 120px;
  width: ${props => (props.listExpanded ? 'calc(100% - 300px)' : 'calc(100% - 20px)')};

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? 'calc(100% - 250px)' : 'calc(100% - 10px)')};
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const NothingSelected = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  text-align: center;
  width: ${props => (props.listExpanded ? 'calc(100% - 300px)' : 'calc(100% - 20px)')};

  @media (max-width: 850px) {
    width: ${props => (props.listExpanded ? 'calc(100% - 250px)' : 'calc(100% - 10px)')};
  }
`;

const DataExportListing = () => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const [startDataAvailable, setStartDataAvailable] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [listExpanded, setListExpanded] = useState(true);
  const [listData, setListData] = useState(undefined);
  const [chosenExport, setChosenExport] = useState(undefined);

  const [middleLoading, setMiddleLoading] = useState(false);

  let timer = useRef();

  useEffect(() => {
    const getStartData = async () => {
      let result;
      try {
        setDataLoading(true);
        result = await apiCall('get', 'statistics/dashboard?name=data_export');
      } catch (e) {
        setListData({});
        setDataLoading(false);
      }
      if (result?.data) {
        setDataLoading(false);
        setListData(result.data);
      }
    };

    if (!startDataAvailable) {
      getStartData();
      setStartDataAvailable(true);
    }
  }, [apiCall, dataLoading, startDataAvailable]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const onChosenExportClick = selection => {
    setMiddleLoading(true);
    setChosenExport(selection);

    timer.current = setTimeout(() => {
      setMiddleLoading(false);
    }, 1000);
  };

  return (
    <Container alertsHeight={alertsHeight}>
      {dataLoading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : listData?.elements?.length > 0 ? (
        <ListContainer>
          <ExportList
            listData={listData.elements}
            chosenExport={chosenExport}
            setChosenExport={onChosenExportClick}
            listExpanded={listExpanded}
            setListExpanded={setListExpanded}
          />
          {chosenExport ? (
            middleLoading ? (
              <MiddleLoader listExpanded={listExpanded}>
                <Spin size="large" />
              </MiddleLoader>
            ) : (
              <DataExport chosenExport={chosenExport} listExpanded={listExpanded} />
            )
          ) : (
            <NothingSelected listExpanded={listExpanded}>{t('No data export selected.')}</NothingSelected>
          )}
        </ListContainer>
      ) : (
        <NoData>{t('No data available.')}</NoData>
      )}
    </Container>
  );
};
export default DataExportListing;
