import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useToggle from '../../hooks/useToggle';
import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Modal from 'antd/es/modal';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import PageActionForm from '../../components/page/PageActionForm';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import Select from '../../components/ui/Select';
import Button from '../../components/ui/Button';
// import Icon from '../../components/ui/Icon';
// import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import Icon from '../../components/ui/Icon';
import BerthSettingsModal from './BerthSettingsModal';
import BerthSettingsExpander from './BerthSettingsExpander';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const EditButtonContainer = styled.div`
  margin-left: 12px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 4px;
  display: inline-block;
`;

const EditButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
  height: 24px;
  width: 24px;
  padding-left: 2px;
  border-radius: 3px;

  i {
    height: 18px;
    width: 18px;
  }

  svg {
    margin-top: -1px;
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  margin-top: -2px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const Berths = () => {
  const { apiCall, namespace, user, alert, setAlert, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);
  const [apiCallPending, setApiCallPending] = useState(false);

  const [modalOpen, setModalOpen] = useState(null);

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Berths | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'berths', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const berths = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const initModal = {
    visible: false,
    confirmLoading: false,
    berth: {
      code: '',
      name: '',
      reservable: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const initBerth = {
    id: null,
    code: '',
    name: '',
    reservable: '',
  };

  const [berth, setBerth] = useState(initBerth);

  const handleOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    let reservableBool = false;
    if (berth.reservable === 'true' || berth.reservable === true) {
      reservableBool = true;
    }
    try {
      await apiCall('put', 'berths', {
        id: berth.id,
        code: berth.code,
        name: berth.name,
        reservable: reservableBool,
      });
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    let params = newParams;
    await fetchData(false, params);
  };

  const handleModalCancel = () => {
    setModal(initModal);
  };

  const handleModalChange = e => {
    setBerth({ ...berth, [e.target.name]: e.target.value });
  };

  const handleSave = async values => {
    const { code, name, reservable } = values;
    let reservableBool = false;
    if (reservable === 'true') {
      reservableBool = true;
    }
    setApiCallPending(true);
    try {
      await apiCall('post', 'berths', {
        code,
        name,
        reservable: reservableBool,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    showActions(false);

    values.reservable = 'true';
    let params = newParams;
    await fetchData(false, params);
  };

  const fields = ['code', 'name', 'reservable'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, { reservable: 'true' });

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
  };

  let columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Berth code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('Berth name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Reservable'),
      dataIndex: 'reservable',
      key: 'reservable',
      render: text =>
        text ? (
          <IconContainer>
            <Icon type="check-light" />
          </IconContainer>
        ) : null,
    },
  ];

  if (user.permissions.includes('manage_berth')) {
    columns.push({
      title: t('Edit'),
      dataIndex: 'edit',
      key: 'edit',
      width: '80px',
      render: (text, record) => (
        <EditButtonContainer>
          <EditButton onClick={() => setModalOpen(record)}>
            <Icon type="edit" />
          </EditButton>
        </EditButtonContainer>
      ),
    });
  }

  if (modules.queue_module !== 'enabled') {
    let nomFieldIndex = fields.findIndex(f => f === 'reservable');
    let nomColumnIndex = columns.findIndex(c => c.key === 'reservable');

    if (nomFieldIndex > -1) {
      fields.splice(nomFieldIndex, 1);
    }
    if (nomColumnIndex > -1) {
      columns.splice(nomColumnIndex, 1);
    }
  }

  const onDataSaved = () => {
    fetchData(false, newParams);
  };

  return (
    <Layout pagename={t('Berths')}>
      <Modal
        title={t('Edit berth {{name}}', { name: berth.name })}
        open={modal.visible}
        onOk={handleOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input name="code" label="code" value={berth.code} onChange={handleModalChange} />
            <Input name="name" label="name" value={berth.name} onChange={handleModalChange} />
            {modules.queue_module === 'enabled' && (
              <Select
                name="reservable"
                label="Reservable"
                value={berth.reservable}
                options={[
                  { key: 'Yes', value: true, label: 'Yes' },
                  { key: 'No', value: false, label: 'No' },
                ]}
                onChange={handleModalChange}
              />
            )}
          </Spin>
        </ModalInner>
      </Modal>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <div ref={actionsRef}>
        <PageActionForm title={t('Add new berth')} icon="plus" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'reservable') {
                return (
                  <Select
                    value={values.field}
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    options={[
                      { key: 'Yes', value: true, label: 'Yes' },
                      { key: 'No', value: false, label: 'No' },
                    ]}
                    onChange={handleChange}
                  />
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values.field}
                    onChange={handleChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link>{t('Add berth')}</Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={berths}
          apiCallPending={apiCallPending}
          spinning={loading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          hideSearch={true}
          expandedRowRender={record => <BerthSettingsExpander settings={record.settings} />}
        />
      </Page>

      {!!modalOpen && (
        <BerthSettingsModal berth={modalOpen} closeModal={() => setModalOpen(null)} onDataSaved={onDataSaved} />
      )}
    </Layout>
  );
};

export default Berths;
