import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Spin from 'antd/es/spin';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;
  overflow: hidden;
`;

const DoughnutContainer = styled.div`
  height: ${props => (props.column1 ? 'calc(100vh / 3)' : 'calc(100vh / 4)')};
  max-height: ${props => (props.column1 ? '400px' : '300px')};
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-bottom: 12px;
  margin-left: 12px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const StatisticsDoughnut = props => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [startLoader, setStartLoader] = useState(true);

  const { loading, data, error, fetchData } = useApi('get', props.data_source.url, props.data_source.parameters);
  const dataObject = error || !data ? {} : data.data;
  const legendObject = props.legend;
  const legendKeys = Object.keys(legendObject);

  useEffect(() => {
    const timer = setInterval(() => {
      setStartLoader(false);
      fetchData();
    }, 5 * 60000);
    return () => clearInterval(timer);
  }, []); // eslint-disable-line

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 30,
        bottom: 30,
        top: 20,
      },
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 12,
          font: {
            size: 14,
          },
        },
        title: {
          display: false,
          padding: 25,
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        font: {
          size: 14,
        },
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
  };

  let datasets = null;
  for (const key of legendKeys) {
    const value = dataObject[key];
    if (value) {
      datasets === null && (datasets = { labels: [], datasets: [{ label: '', data: [], backgroundColor: [] }] });
      datasets.labels.push(legendObject[key].title);
      datasets.datasets[0].data.push(value ? [value] : [0]);
      datasets.datasets[0].backgroundColor.push(legendObject[key].color);
    }
  }

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
        </HeaderRow>

        {startLoader && loading ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : datasets ? (
          <DoughnutContainer>
            <Doughnut plugins={[ChartDataLabels]} options={options} data={datasets} />
          </DoughnutContainer>
        ) : (
          <NoData>{t('No data available.')}</NoData>
        )}
      </Graph>
    </Container>
  );
};
export default StatisticsDoughnut;
