import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import CraneList from '../components/berthPlanningTool/cranePlanning/cranes/CraneList';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

const Cranes = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Cranes | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Cranes')}>
      <PageWithoutHeaderAndMargins fullWidth>
        <CraneList />
      </PageWithoutHeaderAndMargins>
    </Layout>
  );
};

export default Cranes;
