import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Popconfirm from 'antd/es/popconfirm';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { TIME_FORMAT, TIME_FORMAT_DAY } from '../../utils/constants';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Sent = styled.div`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const CreditInvoiceList = ({
  invoices,
  handleDelete,
  setInvoiceModalOpen,
  setModalInvoice,
  setReadOnly,
  setReadOnlyAndSend,
  setCreditInvoice,
  setCreditInvoiceModalOpen,
  setCreditInvoiceData,
  invoiceRecord,
}) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      render: () => <div>{t('Credit')}</div>,
    },
    {
      title: t('Date'),
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: record => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Due date'),
      dataIndex: 'due_date',
      key: 'due_date',
      render: (record, item) => {
        if (item.calculated?.used_due_date) {
          return dayjs(item.calculated.used_due_date).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Total'),
      dataIndex: 'price',
      key: 'price',
      render: (record, item) => {
        return item.calculated.total_sum_including_vat + ' ' + item.currency_code;
      },
    },
    {
      title: t('Tax class'),
      dataIndex: 'tax_class_name',
      key: 'tax_class_name',
    },
    {
      title: t('Sent'),
      dataIndex: 'is_sent',
      key: 'is_sent',
      render: record =>
        record && (
          <Sent>
            <Icon type="check-light" />
          </Sent>
        ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => {
            if (!record.is_sent) {
              return null;
            }

            return (
              <ListActionButton
                key="action-2"
                onClick={() => {
                  setReadOnly(true);
                  setModalInvoice(record);
                  setInvoiceModalOpen(true);
                }}
              >
                <Icon type="page" />
                {t('Open')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-3"
                onClick={() => {
                  setCreditInvoice(invoiceRecord);
                  setCreditInvoiceModalOpen(true);
                  setCreditInvoiceData(record);
                  setReadOnlyAndSend(true);
                }}
                disabled={record.is_sent}
              >
                <Icon type="page" />
                {t('Preview and send')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <ListActionButton
                key="action-1"
                onClick={() => {
                  setCreditInvoice(invoiceRecord);
                  setCreditInvoiceModalOpen(true);
                  setCreditInvoiceData(record);
                }}
                disabled={record.is_sent}
              >
                <Icon type="edit" />
                {t('Edit')}
              </ListActionButton>
            );
          },
        },
        {
          render: record => {
            if (record.is_sent) {
              return null;
            }
            return (
              <Popconfirm
                title={t('Are you sure you want to remove the invoice?')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('Yes')}
                okType="danger"
                cancelText={t('No')}
                icon={null}
                id="pop-confirm-for-new-list"
                key="action-5"
              >
                <ListActionButton red title={t('Delete')} disabled={record.is_sent}>
                  <Icon type="trash" />
                  {t('Delete')}
                </ListActionButton>
              </Popconfirm>
            );
          },
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setReadOnly(true);
                setModalInvoice(record);
                setInvoiceModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={invoices}
        actions={actionList}
        noUrlUpdate={true}
        expandererList={true}
      />
    </Container>
  );
};

export default CreditInvoiceList;
