import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Table from 'antd/es/table';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { InvoicingContext } from '../../context/InvoicingContext';
import Icon from '../ui/Icon';

const AddNew = styled.div`
  align-self: flex-end;
  font-weight: 550;
  background-color: white;
  cursor: pointer;
  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

const StyledTable = styled(Table)`
  && {
    width: auto;
    min-width: unset !important;
    margin: 0 0 ${({ theme }) => theme.sizing.gap};
    th,
    td {
      padding: ${({ theme }) => theme.sizing.gap};
      background: ${({ theme }) => theme.color.white};
      border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
      @media print {
        border-bottom: 1pt solid black;
      }
    }
    thead {
      tr {
        th {
          padding: 12px 1rem;
          border-bottom: 1px solid transparent;
        }
      }
    }
    th {
      font-size: ${({ theme }) => theme.text.small};
      font-weight: 700;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      white-space: nowrap;
      padding: ${({ theme }) => theme.sizing.gap_small} ${({ theme }) => theme.sizing.gap};
    }
    table,
    .ant-table {
      background: none;
      th,
      td {
        background: none;
      }
    }
    .ant-table-wrapper + .ant-table-wrapper {
      margin-top: ${({ theme }) => theme.sizing.gap_medium};
    }
  }
`;

const EditableCell = ({ editing, dataIndex, title, inputType, children, ...rest }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...rest}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Missing ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CustomerTable = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { customerData } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);

  const [data, setData] = useState(customerData);
  const [editingKey, setEditingKey] = useState('');
  const [addedRowCount, setAddedRowCount] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    setData(customerData);
    setEditingKey('');
  }, [customerData]);

  const isEditing = record => record.customer_id === editingKey;

  const edit = record => {
    form.setFieldsValue({
      ...record,
      data: {
        ...record.data,
      },
    });
    setEditingKey(record.customer_id);
  };

  const remove = async record => {
    if (record.customer_id.startsWith('new-')) {
      const newData = [...data];
      const index = newData.findIndex(item => record.customer_id === item.customer_id);
      newData.splice(index, 1);
      setData(newData);
    } else {
      const result = await apiCall('delete', 'invoicing/customer', { id: parseInt(record.customer_id) });

      if (result?.status === 200) {
        const newData = [...data];
        const index = newData.findIndex(item => record.customer_id === item.customer_id);
        newData.splice(index, 1);
        setData(newData);
      }
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async customerId => {
    try {
      const row = await form.validateFields();
      const index = data.findIndex(item => customerId === item.customer_id);
      const item = { ...data[index], ...row };

      for (const key in item.data) {
        if (item.data[key] === '') {
          delete item.data[key];
        }
      }

      const result = await apiCall('post', 'invoicing/customer', { data: item });

      if (result?.data && result?.status === 200) {
        const newData = [...data];
        const newCustomer = result.data;
        const index = newData.findIndex(record => record.customer_id === newCustomer.customer_id);
        newData.splice(index, 1, newCustomer);
        setData(newData);
        setEditingKey('');
      }
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  };

  const addRow = () => {
    const newData = [...data];
    const newRowCount = addedRowCount + 1;
    const newRow = {
      customer_id: `new-${newRowCount}`,
      agent: '',
      data: {
        ovt: '',
        zip: '',
        city: '',
        name: '',
        street: '',
        country: '',
        penaltyPercent: '',
        invoiceOperator: '',
        einvoiceAddress: '',
        invoiceChannel: '',
        email: '',
        contactPersonName: '',
      },
      created_at: '',
      updated_at: '',
    };
    newData.push(newRow);
    setData(newData);
    setAddedRowCount(newRowCount);
    edit(newRow);
  };

  const columns = [
    {
      title: 'Agent',
      key: 'agent',
      dataIndex: 'agent',
      editable: true,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: ['data', 'name'],
      editable: true,
    },
    {
      title: 'Street',
      key: 'street',
      dataIndex: ['data', 'street'],
      editable: true,
    },
    {
      title: 'Zip',
      key: 'zip',
      dataIndex: ['data', 'zip'],
      editable: true,
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: ['data', 'city'],
      editable: true,
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: ['data', 'country'],
      editable: true,
    },
    {
      title: 'Invoice channel',
      key: 'invoiceChannel',
      dataIndex: ['data', 'invoiceChannel'],
      editable: true,
    },
    {
      title: 'Invoice Operator',
      key: 'invoiceOperator',
      dataIndex: ['data', 'invoiceOperator'],
      editable: true,
    },
    {
      title: 'EInvoice Address',
      key: 'einvoiceAddress',
      dataIndex: ['data', 'einvoiceAddress'],
      editable: true,
    },
    {
      title: 'OVT',
      key: 'ovt',
      dataIndex: ['data', 'ovt'],
      editable: true,
    },
    {
      title: 'Contact Person Name',
      key: 'contactPersonName',
      dataIndex: ['data', 'contactPersonName'],
      editable: true,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: ['data', 'email'],
      editable: true,
    },
    {
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.customer_id)}
              style={{
                marginRight: 8,
              }}
            >
              {t('Save')}
            </Typography.Link>
            <Typography.Link onClick={() => cancel()}>{t('Cancel')}</Typography.Link>
          </span>
        ) : (
          <span style={{ backgroundColor: 'white' }}>
            <Typography.Link style={{ marginRight: 8 }} disabled={editingKey !== ''} onClick={() => edit(record)}>
              {t('Edit')}
            </Typography.Link>
            <Typography.Link disabled={editingKey !== ''} onClick={() => remove(record)}>
              {t('Delete')}
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map(column => {
    if (!column.editable) {
      return column;
    }

    return {
      ...column,
      onCell: record => ({
        record,
        inputType: column.inputType ? column.inputType : 'text',
        dataIndex: column.dataIndex,
        title: column.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Container>
      <Form form={form} component={false}>
        <StyledTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          rowKey={customer => customer.customer_id}
          columns={mergedColumns}
          dataSource={data}
          scroll={{ x: 'max-content' }}
          pagination={{ hideOnSinglePage: true }}
          loading={{
            spinning: !data,
            indicator: <Spin size="large" />,
          }}
        />
      </Form>
      <AddNew onClick={() => addRow()}>
        <Icon type="plus" style={{ fontSize: '20px' }} />
        <span style={{ marginLeft: 6 }}>{t('Add new')}</span>
      </AddNew>
    </Container>
  );
};

export default CustomerTable;
