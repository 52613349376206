import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';

import { UserContext } from '../../../context/UserContext';
import ButtonLight from '../../ui/ButtonLight';

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Header = styled.div`
  font-weight: 600;
  margin-right: 44px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 0px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const AcknowledgeServiceOrderModalLine = ({ data, sendData }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState({
    email: data.service_provider_email,
    phone: data.service_provider_phone,
    notes: null,
  });

  const updateNotesSplit = e => {
    let newInputData = { ...inputData };
    newInputData['notes'] = e.target.value;
    setInputData(newInputData);
  };

  const updateEmailSplit = e => {
    let newInputData = { ...inputData };
    newInputData['email'] = e.target.value;
    setInputData(newInputData);
  };

  const updatePhoneSplit = e => {
    let newInputData = { ...inputData };
    newInputData['phone'] = e.target.value;
    setInputData(newInputData);
  };

  const createDataToBeSent = status => {
    sendData(
      {
        service_ack: {
          order_line_id: data.id,
          status: status,
          notes: inputData.notes,
          email: inputData.email,
          phone: inputData.phone,
        },
      },
      data,
      status
    );
  };

  return (
    <InfoContainer>
      <RowContainer>
        <Container style={{ margin: '4px 0 12px 0' }}>
          <Header>{t('Acknowledge or reject')}</Header>
        </Container>
        <Container>
          <RowContainerSplit style={{ width: '20%' }}>
            <RowHeader>{t('Email')}</RowHeader>
            <Input placeholder={t('Email')} value={inputData.email || ''} onChange={e => updateEmailSplit(e)} />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: '20%' }}>
            <RowHeader>{t('Phone')}</RowHeader>
            <Input placeholder={t('Phone number')} value={inputData.phone || ''} onChange={e => updatePhoneSplit(e)} />
          </RowContainerSplit>
          <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
            <RowHeader>{t('Note')}</RowHeader>
            <Input placeholder={t('Note')} value={inputData.notes || ''} onChange={e => updateNotesSplit(e)} />
          </RowContainerSplit>
          <RowContainerSplit
            style={{ width: 'calc(100% / 5)', marginTop: '23px', marginLeft: '12px', display: 'flex' }}
          >
            <Popconfirm
              title={t('Acknowledge service?')}
              onConfirm={() => createDataToBeSent('accepted')}
              okText={t('Yes')}
              okType="primary"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery"
              key="action-1"
              cancelButtonProps={{ type: 'link' }}
            >
              <div>
                <ButtonLight disabled={data.disabled}>{t('Acknowledge')}</ButtonLight>
              </div>
            </Popconfirm>
            <Popconfirm
              title={t('Reject service?')}
              onConfirm={() => createDataToBeSent('rejected')}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-cancel"
              key="action-2"
              cancelButtonProps={{ type: 'link' }}
            >
              <div>
                <ButtonLight disabled={data.disabled} cancel style={{ marginLeft: '6px' }}>
                  {t('Reject')}
                </ButtonLight>
              </div>
            </Popconfirm>
          </RowContainerSplit>
        </Container>
      </RowContainer>
    </InfoContainer>
  );
};

export default AcknowledgeServiceOrderModalLine;
