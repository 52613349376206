import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tabs from 'antd/es/tabs';
import Switch from 'antd/es/switch';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import VesselTimestampModal from './modals/VesselTimestampModal';
import VesselInfoModal from './modals/VesselInfoModal';
import AddEditPortcall from './AddEditPortcall';
import RemovePortcall from './RemovePortcall';
import WorkFlow from './WorkFlow';
import PortcallHistory from './PortcallHistory';
import SeaPassageMapResizableContainer from './SeaPassageMapResizableContainer';

const Container = styled.div`
  background-color: #f8f8f8;
  padding: 0px 10px 16px 10px;
  border-radius: 3px;
  margin-top: 4px;

  .ant-tabs-tab {
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  margin-right: 10px;
  line-height: 24px;
`;

const ColorBlock = styled.div`
  width: 10px;
  height: 100%;
  background-color: ${props => props.color || 'transparent'};
`;

const Text = styled.div`
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
`;

const PortAreaText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  text-transform: capitalize;
`;

const Button = styled.div`
  cursor: pointer;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -2px;
    height: 16px;
    width: 16px;
  }
`;

const Warning = styled.div`
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  margin-top: 12px;
  margin-left: ${props => props.vesselView && '20px'};
`;

const WarningIcon = styled(Icon)`
  svg {
    margin-top: -1px;
    height: 14px;
    width: 14px;
  }
`;

const SwitchLabel = styled.div`
  font-size: 13px;
  margin-left: 12px;
  line-height: 22px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;

  button {
    margin-top: 3px;
  }
`;

const SourceTag = styled.div`
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  height: 18px;
  line-height: 16px;
  padding: 0 4px;
  display: inline-block;
  border-radius: 4px;
  color: #4a4a4a;
  font-weight: 700;
  font-size: 11px;
`;

const MapWrapper = styled.div`
  height: 180px;
  width: 100%;
  position: relative;
  margin-top: 16px;
`;

const PortcallData = ({
  portcall,
  otherNamespaceData,
  addModalOpen,
  setAddModalOpen,
  portList,
  vesselView,
  imo,
  currentPortcall,
  showSeaPassage,
  setShowSeaPassage,
  setSeaPassageData,
  innerMap,
}) => {
  const { namespace, modules } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [currentTab, setCurrentTab] = useState('1');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const addModalOpenRef = useRef(false);

  useEffect(() => {
    if (addModalOpen && !addModalOpenRef.current) {
      setCurrentTab('1');
    }
    addModalOpenRef.current = addModalOpen;
  }, [addModalOpen]);

  if (!portcall) {
    return null;
  }

  const toggleSeaPassage = e => {
    if (e) {
      setShowSeaPassage(true);
      if (!innerMap) {
        setSeaPassageData(portcall.sea_passage.route);
      }
    } else {
      setShowSeaPassage(false);
      if (!innerMap) {
        setShowSeaPassage(undefined);
      }
    }
  };

  const tabItems = [
    {
      label: <span>{t('Port call')}</span>,
      children:
        !addModalOpen && !editModalOpen && !removeModalOpen ? (
          <>
            <Text>
              {t('Port')}:{' '}
              <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                {portcall.data.portName.replace(/ *\([^)]*\) */g, '')}
              </span>
              {portcall.portarea[0] && (
                <PortAreaText style={{ fontWeight: 600, paddingLeft: '4px' }}>
                  ({portcall.portarea[0].toLowerCase()})
                </PortAreaText>
              )}
            </Text>
            <Text style={{ marginBottom: '10px' }}>
              {t('Source')}: <SourceTag>{portcall.data.dataSource}</SourceTag>
            </Text>
            <Columns style={{ marginBottom: '20px' }}>
              {vesselView && (
                <Column>
                  <ColorBlock color="#A5D6A7" />
                </Column>
              )}
              <Column style={{ width: '188px' }}>
                <Text>
                  {t('ETA')}:{' '}
                  {portcall.eta ? (
                    <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                      {dayjs(portcall.eta).format('DD.MM.YYYY')}{' '}
                      <span style={{ paddingLeft: '8px' }}>{dayjs(portcall.eta).format('HH:mm')}</span>
                    </span>
                  ) : (
                    '-'
                  )}
                </Text>
                <Text>
                  {t('ETD')}:{' '}
                  {portcall.etd ? (
                    <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                      {dayjs(portcall.etd).format('DD.MM.YYYY')}{' '}
                      <span style={{ paddingLeft: '8px' }}>{dayjs(portcall.etd).format('HH:mm')}</span>
                    </span>
                  ) : (
                    '-'
                  )}
                </Text>
              </Column>
              {modules.fleet_planning_module === 'enabled' && portcall.data.dataSource === 'manual' && (
                <Column>
                  <Button style={{ color: '#4990DD' }} onClick={() => setEditModalOpen(true)}>
                    <Icon type="edit" />
                    {t('Edit')}
                  </Button>
                  <Button style={{ color: '#d0011c' }} onClick={() => setRemoveModalOpen(true)}>
                    <Icon type="trash" />
                    {t('Remove')}
                  </Button>
                </Column>
              )}
            </Columns>

            {modules.sea_passage_module === 'enabled' && (
              <Columns>
                {vesselView && (
                  <Column>
                    <ColorBlock color="#A7C4F4" />
                  </Column>
                )}
                <Column style={{ width: '228px' }}>
                  <Text>
                    {t('ATA')}:{' '}
                    {portcall.ata ? (
                      <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                        {dayjs(portcall.ata).format('DD.MM.YYYY')}{' '}
                        <span style={{ paddingLeft: '8px' }}>{dayjs(portcall.ata).format('HH:mm')}</span>
                      </span>
                    ) : (
                      '-'
                    )}
                  </Text>
                  {!!portcall.sea_passage && (
                    <Text>
                      {t('Sea passage duration')}:{' '}
                      <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                        {Math.round(portcall.sea_passage.travel_time_in_hours)}h
                      </span>
                    </Text>
                  )}
                  {!!portcall.sea_passage && (
                    <Text>
                      {t('Sea passage length')}:{' '}
                      <span style={{ fontWeight: 600, paddingLeft: '4px' }}>
                        {Math.round(portcall.sea_passage.distance_nm)}nm
                      </span>
                    </Text>
                  )}
                </Column>
              </Columns>
            )}
            {modules.sea_passage_module === 'enabled' && portcall.delayed && (
              <Warning vesselView={vesselView}>
                <WarningIcon type="vessel-warning" style={{ marginRight: '8px' }} />
                {t('Delay')}
                <span style={{ color: '#F5BD3E', paddingLeft: '4px' }}>5h</span>
              </Warning>
            )}
            {portcall.sea_passage?.route && !!setShowSeaPassage ? (
              <SwitchContainer>
                <Switch size="small" checked={showSeaPassage} onChange={e => toggleSeaPassage(e)} aria-label="Routes" />
                <SwitchLabel>{t("Vessel's route")}</SwitchLabel> {/*eslint-disable-line quotes*/}
              </SwitchContainer>
            ) : (
              <SwitchContainer>{t('No route available.')}</SwitchContainer>
            )}
            {!!setShowSeaPassage && innerMap && (
              <MapWrapper>
                <SeaPassageMapResizableContainer
                  seaPassage={portcall.sea_passage ? portcall.sea_passage.route : undefined}
                  showRoute={!!showSeaPassage}
                  setShowSeaPassage={setShowSeaPassage}
                  portcall={portcall}
                />
              </MapWrapper>
            )}
          </>
        ) : !!addModalOpen || !!editModalOpen ? (
          <AddEditPortcall
            add={addModalOpen}
            portcall={portcall}
            portList={portList}
            setAddModalOpen={setAddModalOpen}
            setEditModalOpen={setEditModalOpen}
            setRemoveModalOpen={setRemoveModalOpen}
            imo={imo}
          />
        ) : (
          <RemovePortcall
            setAddModalOpen={setAddModalOpen}
            setEditModalOpen={setEditModalOpen}
            setRemoveModalOpen={setRemoveModalOpen}
            portcall={portcall}
          />
        ),
      key: '1',
    },
  ];

  if (portcall.data.dataSource !== 'manual') {
    tabItems.push({
      label: <span>{t('Timestamp')}</span>,
      children: <VesselTimestampModal vessel={portcall} dataFromOtherNamespaces={otherNamespaceData} />,
      key: '2',
    });
  } else {
    tabItems.push({
      label: <span>{t('History')}</span>,
      children: <PortcallHistory currentPortcall={currentPortcall} />,
      key: '2',
    });
  }

  if (modules.workflow_module === 'enabled') {
    tabItems.push({
      label: <span>{t('Workflow')}</span>,
      children: <WorkFlow currentPortcall={currentPortcall} />,
      key: '3',
    });
  }

  return (
    <>
      <Container id="vessel-side-panel-portcall-data">
        <Tabs activeKey={currentTab} onTabClick={key => setCurrentTab(key)} size="small" items={tabItems} />
      </Container>
      {currentTab === '1' && <VesselInfoModal vessel={portcall} dataFromOtherNamespaces={otherNamespaceData} />}
    </>
  );
};

export default PortcallData;
