import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useToggle from '../../hooks/useToggle';

import { UserContext } from '../../context/UserContext';
import { NotificationContext } from '../../context/NotificationContext';

import Button from '../ui/Button';
import Form from '../ui/Form';
import Textarea from '../ui/Textarea';
import Icon from '../ui/Icon';

import PageAction from '../page/PageAction';
import PageActionForm from '../page/PageActionForm';

import PageSelectWithSearch from '../page/PageSelectWithSearch';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const StyledPageAction = styled(PageAction)`
  margin-bottom: 0;
`;

const NotificationActions = ({ selectedTab, loading, notifications }) => {
  const { namespace, user } = useContext(UserContext);
  const { apiSendNotification, selectedImo, setSelectedImo } = useContext(NotificationContext);
  const { t } = useTranslation(namespace);

  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);
  const [notificationTitle, setNotificationTitle] = useState(undefined);

  const handleCancel = e => {
    e.preventDefault();
    setNotificationTitle(undefined);
    showActions(false);
  };

  const handleImoChange = value => {
    setSelectedImo(value);
  };

  const openNotification = e => {
    e.preventDefault();
    apiSendNotification('port', notificationTitle, '');
    setNotificationTitle(undefined);
    showActions(false);
  };

  const handleChange = e => {
    setNotificationTitle(e.target.value);
  };

  const imoOptions = notifications.reduce(
    (acc, val) => {
      if (val.ship && !acc.some(item => item.value === val.ship.imo)) {
        acc.push({
          key: val.ship.imo,
          value: val.ship.imo,
          label: `${val.ship.vessel_name} - IMO ${val.ship.imo}`,
        });
      }
      return acc;
    },
    [{ key: null, value: null, label: 'No filter' }]
  );

  const hasPermissionToSendNotification = user.permissions.includes('send_push_notification');

  return (
    <>
      {selectedTab === '2' && (
        <PageSelectWithSearch
          name="ship_imo"
          label={t('Filter by IMO')}
          value={selectedImo}
          options={imoOptions}
          onChange={handleImoChange}
          style={{ marginRight: hasPermissionToSendNotification ? '0px' : '12px' }}
        />
      )}
      <div ref={actionsRef}>
        <StyledPageAction>
          {hasPermissionToSendNotification && (
            <Button style={{ marginBottom: '1rem' }} disabled={loading} onClick={() => showActions(true)}>
              <Icon type="bell-add" />
              {t('Send port notification')}
            </Button>
          )}
          <PageActionForm title={t('Send port notification')} icon="bell-add" show={actions}>
            <Form autoComplete="off" onSubmit={openNotification}>
              <Textarea label={t('Notification message')} value={notificationTitle} onChange={handleChange} />
              <FormActions>
                <Button disabled={notificationTitle === undefined} link>
                  {t('Send notification')}
                </Button>
                <Button link onClick={e => handleCancel(e)}>
                  {t('Cancel')}
                </Button>
              </FormActions>
            </Form>
          </PageActionForm>
        </StyledPageAction>
      </div>
    </>
  );
};

export default NotificationActions;
