import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import SpeedInnerModal from '../activity/SpeedInnerModal';
import MovableModal from '../activity/MovableModal';
import { TimestampContext } from '../../context/TimestampContext';

const Title = styled.span`
  font-weight: 400;
`;

const Name = styled.span`
  text-transform: uppercase;
  font-weight: 600;
`;

const SpeedHistory = styled.span`
  margin-right: 4px;
`;

const SpeedModalInMap = ({ vesselName, portcallId, mmsi, setSpeedModalOpen }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { fetchData } = useContext(TimestampContext);

  const [reloadOngoing, setReloadOngoing] = useState(false);

  const closeModal = () => {
    if (reloadOngoing) {
      fetchData();
    }
    setSpeedModalOpen(false);
  };

  return (
    <MovableModal
      title={
        <Title>
          <SpeedHistory>{t('Port call dashboard')}</SpeedHistory>(<Name>{vesselName}</Name>)
        </Title>
      }
      open={true}
      width={800}
      onCancel={closeModal}
      footer={null}
    >
      <SpeedInnerModal portcallId={portcallId} mmsi={mmsi} statusDone={false} setReloadOngoing={setReloadOngoing} />
    </MovableModal>
  );
};

export default SpeedModalInMap;
