import React, { useRef, useEffect, useCallback, useContext, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Sidebar } from './Sidebar';
import Columns from '../../commonCalendar/Columns';
import { CalendarRows } from './CalendarRows';
import ScrollElement from '../../commonCalendar/ScrollElement';

import { getCanvasWidth } from '../../commonCalendar/utility/calendar';
import TimelineHeaders from './headers/TimelineHeaders';
import DateHeader from './headers/DateHeader';
import VerticalScrollBar from '../../commonCalendar/VerticalScrollBar';

import Slider from 'antd/es/slider';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import Icon from '../../ui/Icon';
import MultiSelectModal from '../modals/MultiSelectModal';

import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import { UserContext } from '../../../context/UserContext';
import {
  collapsedRowHeightConstant,
  maxRowHeightConstant,
  minRowHeightConstant,
} from '../../commonCalendar/utility/constants';

const TimelineContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  height: 100%;
  position: relative;
`;

const EmptySpaceForScrollbar = styled.div`
  height: 10px;
`;

const OuterComponent = styled.div`
  display: block;
  overflow-y: ${props => (props.disableScrolling ? 'hidden' : 'scroll')};
  overflow-x: hidden;
  white-space: nowrap;
  height: calc(100% - ${props => props.headerHeight}px);
  position: relative;
`;

const ZoomContainer = styled.div`
  z-index: 110;
  position: absolute;
  right: 5px;
  bottom: 20px;
  display: inline;
`;

const ExpandContainer = styled.div`
  z-index: 110;
  position: absolute;
  right: 48px;
  bottom: 20px;
  display: inline;
`;

const ZoomIcon = styled(Icon)`
  display: flex;
  z-index: 110;
  width: 17px;
  min-width: 17px;
  height: 17px;
  min-height: 17px;
  background-color: white;
  color: #626262;
  left: 15.5px;
  border-radius: 3px;
  :hover {
    cursor: pointer;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ExpandIcon = styled(Icon)`
  display: flex;
  z-index: 110;
  width: 17px;
  min-width: 17px;
  height: 17px;
  min-height: 17px;
  background-color: white;
  color: #626262;
  border-radius: 3px;
  :hover {
    cursor: pointer;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ZoomSlider = styled(Slider)`
  z-index: 111;
  height: 100px;
  min-width: 28px;
  min-height: 80px;
  padding-left: 12px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding-top: 150px;
`;

const ReactCalendarTimeline = () => {
  const {
    visibleTimeStart,
    visibleTimeEnd,
    canvasTimeStart,
    width,
    canvasWidth,
    setWidth,
    timelineUnit,
    sidebarWidth,
    setSidebarWidth,
    setCanvasWidth,
    setContainerLimits,
    containerLimits,
    berths,
    loadMoreLeft,
    loadMoreRight,
    setDraggingMove,
    draggingArea,
    draggingMove,
    setCollapsedRowHeightFunction,
    setZoomSliderValue,
    zoomSliderValue,
    setScrollOffset,
    forceUpdate,
    setForceUpdate,
    disableScrolling,
    getLeftOffsetFromDate,
    height,
    indicatorLine,
    canvasTimeEnd,
    setIndicatorLine,
    sidePanelWidth,
    multiselectedVessels,
    multiselectDisableScrolling,
    setMultiselectDisableScrolling,
    multiselectedModalOpen,
    setRowHeightFromLocalStorageChecked,
  } = useContext(BerthPlanningToolContext);

  const { modules, expandedViewState, setExpandedViewState, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [scrollbarWidth, setScrollbarWidth] = useState(undefined);
  const [berthsAvailable, setBerthsAvailable] = useState(undefined);

  const [scrollElementScrollTop, setScrollElementScrollTop] = useState(undefined);

  const visibleTimeStartRef = useRef();
  const canvasTimeStartRef = useRef();
  const widthRef = useRef();

  const scrollComponentRef = useRef();
  const scrollHeaderRef = useRef();
  const containerRef = useRef();

  const scrollUpdateRef = useRef();
  const outerRef = useRef();

  const sidePanelWidthRef = useRef();
  const expandedViewRef = useRef();

  const [draggingValue, setDraggingValue] = useState(0);
  const draggingMoveRef = useRef();

  useEffect(() => {
    if (multiselectedVessels.length > 0 && !multiselectDisableScrolling) {
      setMultiselectDisableScrolling(true);
    } else if (multiselectedVessels.length === 0 && multiselectDisableScrolling) {
      setMultiselectDisableScrolling(false);
    }
  }, [multiselectDisableScrolling, multiselectedVessels, setMultiselectDisableScrolling]);

  useEffect(() => {
    if (!draggingArea) {
      setDraggingValue(0);
    } else if (draggingMoveRef.current !== draggingMove) {
      if (draggingMove !== 0) {
        setDraggingValue(draggingValue + draggingMove);
      }

      setDraggingMove(0);
      draggingMoveRef.current = draggingMove;
    }
  }, [draggingArea, draggingMove, draggingValue, setDraggingMove]);

  useEffect(() => {
    scrollUpdateRef.current = true;
  }, []);

  const storageKey = 'bptZoom_' + namespace;

  useEffect(() => {
    const value = localStorage.getItem(storageKey);
    if (!value) {
      setRowHeightFromLocalStorageChecked(true);
      return;
    }
    try {
      const zoomNumber = Number(value);
      if (zoomNumber >= minRowHeightConstant && zoomNumber <= maxRowHeightConstant) {
        setCollapsedRowHeightFunction(zoomNumber);
        setZoomSliderValue(zoomNumber);
      } else {
        localStorage.setItem(storageKey, collapsedRowHeightConstant);
      }
    } catch {
      localStorage.setItem(storageKey, collapsedRowHeightConstant);
    }

    setRowHeightFromLocalStorageChecked(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return {
      width,
    };
  };

  const resize = useCallback(() => {
    const { width: containerWidth } = containerRef.current.getBoundingClientRect();
    const { width: windowWidth } = getWindowDimensions();

    if (sidebarWidth === 150 && windowWidth < 1100) {
      setSidebarWidth(120);
    } else if (sidebarWidth === 120 && windowWidth > 1099) {
      setSidebarWidth(150);
    }
    let width2 = containerWidth - sidebarWidth;

    if (width !== width2) {
      setWidth(width2);
      setCanvasWidth(getCanvasWidth(width2));
    }

    scrollComponentRef.current.scrollLeft = width2;
    scrollHeaderRef.current.scrollLeft = width2;
    setScrollOffset(width2);
  }, [sidebarWidth, width, setScrollOffset, setSidebarWidth, setWidth, setCanvasWidth]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useEffect(() => {
    if (sidePanelWidth !== sidePanelWidthRef.current) {
      resize();
    }

    sidePanelWidthRef.current = sidePanelWidth;
  }, [resize, sidePanelWidth]);

  useEffect(() => {
    if (expandedViewState === 3 && expandedViewRef.current !== 3) {
      setTimeout(() => {
        resize();
      }, 200);
    }

    expandedViewRef.current = expandedViewState;
  }, [expandedViewState, resize]);

  useEffect(() => {
    return () => {
      setExpandedViewState(1);
    };
  }, [setExpandedViewState]);

  useLayoutEffect(() => {
    if (visibleTimeStartRef.current !== visibleTimeStart) {
      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollUpdateRef.current = true;
      if (draggingArea) {
        setDraggingMove(scrollComponentRef.current.scrollLeft - scrollLeft);
      } else {
        setDraggingMove(0);
      }
      scrollComponentRef.current.scrollLeft = scrollLeft;
    }
  });

  useEffect(() => {
    if (visibleTimeStartRef.current !== visibleTimeStart) {
      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollHeaderRef.current.scrollLeft = scrollLeft;
      setScrollOffset(scrollLeft);
    }

    if (forceUpdate && visibleTimeStartRef.current === visibleTimeStart) {
      setForceUpdate(false);

      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollHeaderRef.current.scrollLeft = scrollLeft;
      scrollComponentRef.current.scrollLeft = scrollLeft;
      setScrollOffset(scrollLeft);
    }

    if (width !== widthRef.current) {
      resize();
    }

    if (berths.length && !berthsAvailable) {
      setBerthsAvailable(true);
      resize();
    }

    if (scrollComponentRef.current && containerLimits.top === 0) {
      const rect = scrollComponentRef.current.getBoundingClientRect();
      setContainerLimits({ top: rect.top, left: rect.left });
    }

    widthRef.current = width;
    visibleTimeStartRef.current = visibleTimeStart;
    canvasTimeStartRef.current = canvasTimeStart;
  }, [
    visibleTimeStart,
    width,
    berths.length,
    berthsAvailable,
    containerLimits.top,
    canvasTimeStart,
    visibleTimeEnd,
    resize,
    setContainerLimits,
    setScrollOffset,
    forceUpdate,
    setForceUpdate,
  ]);

  const onScroll = scrollX => {
    if ((scrollX < width / 2 || width * 1.5 < scrollX) && scrollUpdateRef.current) {
      scrollUpdateRef.current = false;

      if (scrollX < width / 2) {
        loadMoreLeft(scrollX);
      } else {
        loadMoreRight(scrollX);
      }
    } else {
      scrollHeaderRef.current.scrollLeft = scrollComponentRef.current.scrollLeft;
      setScrollOffset(scrollComponentRef.current.scrollLeft);
    }
  };

  const onScrollY = () => {
    setScrollElementScrollTop(outerRef.current.scrollTop);
  };

  const onScrollByDrag = scrollX => {
    scrollHeaderRef.current.scrollLeft = scrollComponentRef.current.scrollLeft + scrollX;
    scrollComponentRef.current.scrollLeft = scrollComponentRef.current.scrollLeft + scrollX;
    setScrollOffset(scrollComponentRef.current.scrollLeft + scrollX);
  };

  const onScrollByDragHorizontally = scrollY => {
    outerRef.current.scrollTop = outerRef.current.scrollTop + scrollY;
  };

  const getHeaderScrollElementRef = el => {
    scrollHeaderRef.current = el;
  };

  const getScrollElementRef = el => {
    scrollComponentRef.current = el;
  };

  const scrollWithScrollbBar = value => {
    outerRef.current.scrollTop = value;
    setScrollElementScrollTop(value);
  };

  // Checks scrollbar width and the scrollElement adjusts its width based on the value
  if (scrollbarWidth === undefined) {
    const component = document.querySelector('#outer-component');

    if (component) {
      const width = component.offsetWidth - component.clientWidth;
      setScrollbarWidth(width);
    }
  }

  const onZoomChange = value => {
    setCollapsedRowHeightFunction(value);
    setZoomSliderValue(value);
    localStorage.setItem(storageKey, value);
  };

  return (
    <TimelineContainer ref={el => (containerRef.current = el)}>
      <ZoomContainer>
        <ZoomIcon
          aria-label="zoom-in"
          type={'zoom-in'}
          onClick={() =>
            onZoomChange(zoomSliderValue === maxRowHeightConstant ? maxRowHeightConstant : zoomSliderValue + 1)
          }
        />
        <ZoomSlider
          min={minRowHeightConstant}
          max={maxRowHeightConstant}
          step={1}
          defaultValue={7}
          vertical={true}
          onChange={value => onZoomChange(value)}
          value={zoomSliderValue}
        />
        <ZoomIcon
          aria-label="zoom-out"
          type={'zoom-out'}
          onClick={() =>
            onZoomChange(zoomSliderValue === minRowHeightConstant ? minRowHeightConstant : zoomSliderValue - 1)
          }
        />
      </ZoomContainer>
      {user.permissions.includes('manage_bpt') && (
        <Tooltip
          title={
            expandedViewState === 3
              ? t('Collapse calendar view')
              : expandedViewState === 2
                ? t('Maximize calendar view')
                : t('Expand calendar view')
          }
          color="white"
          overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
          placement="left"
        >
          <ExpandContainer>
            <ExpandIcon
              aria-label={expandedViewState === 3 ? 'collapse-view' : 'expand-view'}
              type={expandedViewState === 3 ? 'collapse-view' : 'expand-view'}
              onClick={() => setExpandedViewState(state => (state === 1 ? 2 : state === 2 ? 3 : 1))}
            />
          </ExpandContainer>
        </Tooltip>
      )}
      <TimelineHeaders scrollRef={getHeaderScrollElementRef}>
        <DateHeader height={40} unit={timelineUnit} />
      </TimelineHeaders>
      <OuterComponent
        ref={outerRef}
        headerHeight={scrollHeaderRef.current ? scrollHeaderRef.current.getBoundingClientRect().height : 120}
        disableScrolling={disableScrolling || multiselectDisableScrolling}
        id="outer-component"
        tallerHeader={modules.weather_module === 'enabled'}
        onScroll={onScrollY}
      >
        {!berthsAvailable && (
          <LoaderContainer>
            <Spin size="large" />
          </LoaderContainer>
        )}
        <Sidebar />
        <ScrollElement
          scrollRef={getScrollElementRef}
          onScroll={onScroll}
          scrollbarWidth={scrollbarWidth}
          scrollLeft={scrollComponentRef.current?.scrollLeft}
          scrollByDrag={onScrollByDrag}
          draggingValue={draggingValue}
          width={width}
          draggingArea={draggingArea}
          disableScrolling={disableScrolling || multiselectDisableScrolling}
          scrollElementScrollTop={scrollElementScrollTop}
          scrollHeight={outerRef.current ? outerRef.current.getBoundingClientRect().height : 0}
          noScroll={multiselectedVessels.length > 0}
          multiselect={true}
        >
          <div>
            <Columns
              getLeftOffsetFromDate={getLeftOffsetFromDate}
              canvasTimeStart={canvasTimeStart}
              canvasTimeEnd={canvasTimeEnd}
              timelineUnit={timelineUnit}
              height={height}
              indicatorLine={indicatorLine}
              setIndicatorLine={setIndicatorLine}
              canvasWidth={canvasWidth}
            />
            <CalendarRows
              scrollTop={outerRef.current?.scrollTop}
              scrollLeft={scrollComponentRef.current?.scrollLeft}
              onScrollByDragHorizontally={onScrollByDragHorizontally}
              draggingValue={draggingValue}
            />
          </div>

          <EmptySpaceForScrollbar data-id="scrollbar" style={{ width: canvasWidth + 'px' }} />
        </ScrollElement>
      </OuterComponent>
      <VerticalScrollBar
        scrollTop={outerRef.current ? outerRef.current.scrollTop : 0}
        scrollWithScrollbBar={scrollWithScrollbBar}
        scrollElementScrollTop={scrollElementScrollTop}
      />

      {multiselectedModalOpen && <MultiSelectModal />}
    </TimelineContainer>
  );
};

export default ReactCalendarTimeline;
