import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Tabs from 'antd/es/tabs';

import { UserContext } from '../../../context/UserContext';
import { ServiceOrderContext } from '../../../context/ServiceOrderContext';
import ServiceOrderTaskList from './ServiceOrderTaskList';
import ButtonLight from '../../ui/ButtonLight';
import useApi from '../../../hooks/useApi';
import { TimestampContext } from '../../../context/TimestampContext';

const ModalInner = styled.div`
  height: 70vh;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;

  .ant-tabs-tab {
    font-size: 14px !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active {
    font-weight: 600 !important;
  }
`;

const TitleRowContainer = styled.div`
  display: inline-block;
  i {
    width: 32px;
    height: 20px;
    margin-right: 12px;
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 14px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ServiceOrderTaskListModal = ({ open, close }) => {
  const { namespace, modules, user } = useContext(UserContext);
  const { allTimestamps } = useContext(TimestampContext);
  const { serviceOrders, socketUpdate } = useContext(ServiceOrderContext);
  const { t } = useTranslation(namespace);

  const listRef = useRef();

  const [currentTab, setCurrentTab] = useState('1');

  const [newParams, setNewParams] = useState({
    sort: 'order_line_deliveries_updated_at',
  });

  let allPortcallIds = '';
  allTimestamps.forEach(ts => (allPortcallIds += ts.ship.master_id + ','));

  const { loading, data: serviceOrderData, error: serviceOrderError, fetchData: fetchServiceOrderList } = useApi(
    'get',
    'service-orders/service-order-done-todos',
    { ...newParams, portcall_ids: allPortcallIds.substring(0, allPortcallIds.length - 1) },
    null,
    false
  );

  useEffect(() => {
    if (
      modules.service_order_module === 'enabled' &&
      user.permissions.includes('manage_service_order_ack_delivery') &&
      allPortcallIds.length > 0
    ) {
      fetchServiceOrderList(false, {
        ...newParams,
        portcall_ids: allPortcallIds.substring(0, allPortcallIds.length - 1),
      });
    }
  }, [newParams, fetchServiceOrderList, allPortcallIds, modules.service_order_module, user.permissions]);

  let serviceOrdersDone = serviceOrderError
    ? []
    : serviceOrderData?.results
      ? serviceOrderData.results['service-orders']
      : [];

  let doneList = [];

  serviceOrdersDone.forEach(so => {
    so.order_lines.forEach(ol => {
      doneList.push({ ...so, id: so.id + '-' + ol.id, order_lines: [ol] });
    });
  });

  let serviceOrdersAll = [];

  serviceOrders.forEach(so => {
    so.order_lines.forEach(ol => {
      serviceOrdersAll.push({ ...so, id: so.id + '-' + ol.id, order_lines: [ol] });
    });
  });

  let doneTotal = doneList.length;

  const getServiceOrders = useCallback(() => {
    fetchServiceOrderList();
  }, [fetchServiceOrderList]);

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const getListElementRef = el => {
    listRef.current = el;
  };

  const socketUpdateRef = useRef(socketUpdate);
  useEffect(() => {
    if (socketUpdate !== socketUpdateRef.current) {
      getServiceOrders();
    }
    socketUpdateRef.current = socketUpdate;
  }, [getServiceOrders, socketUpdate]);

  const TitleRow = () => {
    return (
      <TitleRowContainer>
        {t('Service requests ({{amount}})', {
          amount: serviceOrdersAll.length,
        })}
      </TitleRowContainer>
    );
  };

  let toBeAcknowledged = serviceOrdersAll.filter(
    so => !so.order_lines[0].ack && so.order_lines[0].deliveries.length === 0
  );
  let toBeDelivered = serviceOrdersAll.filter(so => so.order_lines[0].ack && so.order_lines[0].deliveries.length === 0);

  const tabItems = [
    {
      label: <span>{t('All active service request(s) ({{amount}})', { amount: serviceOrdersAll.length })}</span>,
      children: <ServiceOrderTaskList orders={serviceOrdersAll} tallRow={true} />,
      key: '1',
    },
    {
      label: <span>{t('To be acknowledged ({{amount}})', { amount: toBeAcknowledged.length })}</span>,
      children: <ServiceOrderTaskList orders={toBeAcknowledged} tallRow={true} />,
      key: '2',
    },
    {
      label: <span>{t('To be delivered ({{amount}})', { amount: toBeDelivered.length })}</span>,
      children: <ServiceOrderTaskList orders={toBeDelivered} tallRow={true} />,
      key: '3',
    },
    {
      label: <span>{t('Done ({{amount}})', { amount: doneTotal })}</span>,
      children: (
        <ServiceOrderTaskList
          getListRef={getListElementRef}
          orders={doneList}
          done={true}
          listAttributes={{
            hideSearch: true,
            newParams,
            setParams: setNewParams,
            spinning: loading,
            scrollToTop: scrollToTop,
          }}
          tallRow={false}
        />
      ),
      key: '4',
    },
  ];

  return (
    <Modal title={TitleRow()} open={open} width={1200} onCancel={close} footer={null}>
      <ModalInner>
        <Tabs
          activeKey={currentTab ? currentTab.toString() : null}
          onTabClick={key => setCurrentTab(key)}
          items={tabItems}
        />
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={close}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default ServiceOrderTaskListModal;
