import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popconfirm from 'antd/es/popconfirm';

import { UserContext } from '../../../../context/UserContext';
import ButtonLight from '../../../ui/ButtonLight';
import ExternalField from './ExternalFields';
import { findFromData } from '../../../../utils/utils';

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Header = styled.div`
  font-weight: 600;
  margin-right: 44px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 0px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Required = styled.span`
  color: red;
`;

const FinnpilotOperationUpdateLine = ({ orderLineId, data, sendData, loading }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [inputData, setInputData] = useState(
    data.operationUpdate?.fields?.reduce?.((acc, value) => {
      acc[value.key] = null;
      if (value.defaultValueFrom?.length) {
        const res = findFromData(value.defaultValueFrom, data);
        if (res) {
          acc[value.key] = res;
        }
      }
      return acc;
    }, {}) || {}
  );
  const [isInputDataValid, setIsInputDataValid] = useState(false);

  useEffect(() => {
    const updated =
      data.operationUpdate?.fields?.reduce?.((acc, value) => {
        acc[value.key] = null;
        if (value.defaultValueFrom?.length) {
          const res = findFromData(value.defaultValueFrom, data);
          if (res) {
            acc[value.key] = res;
          }
        }
        return acc;
      }, {}) || {};
    setInputData(updated);
  }, [data]);

  const updateInputData = (value, index, key) => {
    let newInputData = {
      ...inputData,
    };
    newInputData[key] = value;
    setInputData(newInputData);
  };

  const createDataToBeSent = status => {
    if (
      !data.operationUpdate?.fields?.every?.(field => {
        return !(field.required && !inputData[field.key]);
      })
    ) {
      return;
    }
    sendData(
      {
        order_line_id: orderLineId,
        external_type_action: 'operationUpdate',
        external_data: inputData,
      },
      data,
      status
    );
  };

  useEffect(() => {
    const isValid = data.operationUpdate?.fields?.every?.(field => {
      return !(field.required && !inputData[field.key]);
    });
    setIsInputDataValid(isValid);
  }, [setIsInputDataValid, inputData, data.operationUpdate.fields]);

  return (
    <InfoContainer>
      <RowContainer>
        <Container style={{ margin: '4px 0 12px 0' }}>
          <Header>{t('Update estimate')}</Header>
        </Container>
        <Container>
          {data.operationUpdate?.fields?.map?.((field, idx) => {
            let title = field.name.replace('_', ' ');
            title = title.charAt(0).toUpperCase() + title.toLowerCase().slice(1);
            return (
              <RowContainerSplit
                key={`update-field-${idx}`}
                /*style={{ width: `calc(100% / ${data.operationUpdate.fields.length})` }}*/
              >
                <RowHeader>
                  {t(title)}
                  {field.required ? <Required>*</Required> : null}
                </RowHeader>
                <ExternalField
                  type={field.type}
                  index={0}
                  field={field.key}
                  value={inputData[field.key] || undefined}
                  handleUpdate={updateInputData}
                  //placeHolder={field.name}
                  options={field.options}
                />
              </RowContainerSplit>
            );
          })}
          <RowContainerSplit
            style={{
              /*width: `calc(100% / ${data.operationUpdate.fields.length + 1})`,*/
              marginTop: '23px',
              marginLeft: '12px',
            }}
          >
            <Popconfirm
              title={t('Confirm update?')}
              onConfirm={() => createDataToBeSent('update')}
              okText={t('Yes')}
              okType="primary"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-delivery"
              key="action-1"
              cancelButtonProps={{ type: 'link' }}
            >
              <ButtonLight disabled={!isInputDataValid || loading}>{t('Update')}</ButtonLight>
            </Popconfirm>
          </RowContainerSplit>
        </Container>
      </RowContainer>
    </InfoContainer>
  );
};

export default FinnpilotOperationUpdateLine;
