import Tooltip from 'antd/es/tooltip';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import MovableModal from './MovableModal';
import SpeedInnerModal from './SpeedInnerModal';
import { TimestampContext } from '../../context/TimestampContext';

const SpeedContainer = styled.span`
  margin-left: 8px;
  cursor: pointer;
  display: ${props => (props.showIconAlways ? 'flex' : 'none')};

  svg {
    height: 17px;
    width: 17px;
    margin-top: -4px;
  }
`;

const Title = styled.span`
  font-weight: 400;
`;

const Name = styled.span`
  text-transform: uppercase;
  font-weight: 600;
`;

const SpeedHistory = styled.span`
  margin-right: 4px;
`;

const SpeedModal = ({ vesselName, mmsi, portcallId, showIconAlways, statusDone }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { fetchData } = useContext(TimestampContext);

  const [open, setOpen] = useState(false);
  const [reloadOngoing, setReloadOngoing] = useState(false);

  const closeModal = () => {
    if (reloadOngoing) {
      fetchData();
    }
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={t('Port call dashboard')}
        color="white"
        overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
      >
        <SpeedContainer onClick={() => setOpen(true)} showIconAlways={showIconAlways} className="speed-icon-container">
          <Icon type="portcall-stats" />
        </SpeedContainer>
      </Tooltip>

      {open && (
        <MovableModal
          title={
            <Title>
              <SpeedHistory>{t('Port call dashboard')}</SpeedHistory>(<Name>{vesselName}</Name>)
            </Title>
          }
          open={open}
          width={800}
          onCancel={closeModal}
          footer={null}
        >
          <SpeedInnerModal
            portcallId={portcallId}
            mmsi={mmsi}
            statusDone={statusDone}
            setReloadOngoing={setReloadOngoing}
          />
        </MovableModal>
      )}
    </>
  );
};

export default SpeedModal;
