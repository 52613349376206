import React, { useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import List from '../../components/ui/List';
import useApi from '../../hooks/useApi';
import { TIME_FORMAT_DAY, TIME_FORMAT_HOURS_MINUTES } from '../../utils/constants';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';

const WikiLinkList = () => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const { loading, data, error, fetchData } = useApi('get', 'wiki/list-links?offset=0&limit=1000');

  const links = error || !data ? [] : data;

  const columns = [
    {
      title: t('Markdown id'),
      dataIndex: 'markdown_id',
      key: 'markdown_id',
    },
    {
      title: t('Markdown anchor'),
      dataIndex: 'markdown_anchor',
      key: 'markdown_anchor',
    },
    {
      title: t('Object type'),
      dataIndex: 'object_type',
      key: 'object_type',
    },
    {
      title: t('Object id'),
      dataIndex: 'object_id',
      key: 'object_id',
    },
    {
      title: t('Latitude'),
      dataIndex: 'lat',
      key: 'lat',
    },
    {
      title: t('Longitude'),
      dataIndex: 'lon',
      key: 'lon',
    },
    {
      title: t('Short text'),
      dataIndex: 'short_text',
      key: 'short_text',
      render: text => {
        if (!text) {
          return '-';
        } else {
          let shortText = text;
          if (shortText.length > 20) {
            shortText = shortText.substring(0, 20) + '...';
          }
          return (
            <Tooltip
              title={text}
              color="white"
              overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
              placement="top"
            >
              {shortText}
            </Tooltip>
          );
        }
      },
    },
    {
      title: t('Created at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('Created by'),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: t('Updated at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: record =>
        record ? (
          <>
            {dayjs(record).format(TIME_FORMAT_DAY)}{' '}
            <span style={{ fontWeight: 600 }}>{dayjs(record).format(TIME_FORMAT_HOURS_MINUTES)}</span>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: t('Updated by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
  ];

  const handleDelete = async record => {
    await apiCall('delete', 'wiki/unlink', {
      markdown_id: record.markdown_id,
      object_type: record.object_type,
      object_id: record.object_id,
    });
    await fetchData();
  };

  const actions = [
    {
      render: record => (
        <Popconfirm
          title={t('Are you sure you want to delete this link?')}
          onConfirm={() => handleDelete(record)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red disabled={!user.permissions.includes('manage_wiki')}>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <List
      rowKey={record => record.object_id + '-' + record.object_type}
      columns={columns}
      dataSource={links}
      spinning={loading}
      localPagination={true}
      hideSearch={true}
      actions={actions}
    />
  );
};

export default WikiLinkList;
