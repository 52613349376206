import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';

import SeaPassage from './SeaPassage';
import FleetTimelineServiceOrders from '../FleetTimelineServiceOrders';
import VesselTimestampFleetModalCard from '../modals/VesselTimestampFleetModalCard';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);
dayjs.extend(duration);

const PortcallContainer = styled.div`
  position: absolute;
  border-radius: 3px;
  z-index: 50;
  opacity: 1;
  box-shadow: none;
  background-color: ${props => (props.blue ? '#A7C4F4' : '#a5d6a7')};
  background-color: ${props => props.current && '#e3edff'};
  border: 1px solid #ffffff;
  border: ${props => props.manual && '2px dotted #639665'};
  cursor: pointer;
  z-index: 100;
`;

const PortcallInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PortcallNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px;
  color: #000000;
  font-weight: 600;
`;

const Portcall = ({ portcall, nextPortcall, serviceOrders }) => {
  const {
    canvasTimeStart,
    canvasTimeEnd,
    millisecondInPixels,
    rowHeight,
    seaPassageEnabled,
    setSideModalOpen,
  } = useContext(FleetVesselViewContext);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  let actualAvailable = true;
  let vesselAtPort = false;

  // If portcall doesn't have eta or etd, we cannot draw anything on the timeline
  if (!portcall.eta || !portcall.etd) {
    return null;
  }

  if (!portcall.ata || !portcall.atd) {
    actualAvailable = false;
  }

  if (portcall.ata && !portcall.atd && (!nextPortcall || (nextPortcall && !nextPortcall.ata))) {
    vesselAtPort = true;
  }

  const etaMoment = dayjs(portcall.eta);
  const etdMoment = dayjs(portcall.etd);

  const ataMoment = actualAvailable || vesselAtPort ? dayjs(portcall.ata) : null;
  const atdMoment = actualAvailable ? dayjs(portcall.atd) : null;

  const canvasStartMoment = dayjs(canvasTimeStart);
  const canvasEndMoment = dayjs(canvasTimeEnd);

  const starttime = dayjs.max([etaMoment, canvasStartMoment]);
  const endtime = dayjs.min([etdMoment, canvasEndMoment]);

  const starttimeActual = actualAvailable || vesselAtPort ? dayjs.max([ataMoment, canvasStartMoment]) : null;
  const endtimeActual = actualAvailable ? dayjs.min([atdMoment, canvasEndMoment]) : null;

  const left = dayjs.duration(dayjs(starttime).diff(canvasStartMoment)).asMilliseconds() * millisecondInPixels;
  const width = dayjs.duration(dayjs(endtime).diff(starttime)).asMilliseconds() * millisecondInPixels;

  const leftActual =
    actualAvailable || vesselAtPort
      ? dayjs.duration(dayjs(starttimeActual).diff(canvasStartMoment)).asMilliseconds() * millisecondInPixels
      : null;
  const widthActual = actualAvailable
    ? dayjs.duration(dayjs(endtimeActual).diff(starttimeActual)).asMilliseconds() * millisecondInPixels
    : null;

  let top = 2;

  const getPortcallProps = value => {
    const fontSize = seaPassageEnabled ? (rowHeight > 28 ? '12px' : '10px') : rowHeight > 20 ? '12px' : '10px';

    if (value === 4) {
      const start = dayjs.max([dayjs(nextPortcall.eta), canvasStartMoment]);
      const end = dayjs.min([dayjs(nextPortcall.etd), canvasEndMoment]);
      const nextWidth = portcall.sea_passage.travel_time_in_minutes
        ? dayjs.duration(end.diff(start)).asMilliseconds() * millisecondInPixels
        : null;
      const nextLeft =
        dayjs
          .duration(dayjs(dayjs().add(portcall.sea_passage.travel_time_in_minutes, 'm')).diff(canvasStartMoment))
          .asMilliseconds() * millisecondInPixels;
      return {
        style: {
          width: isNaN(nextWidth) ? 0 : nextWidth > 16 ? nextWidth : 16,
          top: 2 * top + (rowHeight - 4) / 2 - 2,
          left: nextLeft,
          height: (rowHeight - 4) / 2 - 1,
          fontSize,
        },
      };
    } else if (value === 3) {
      return {
        style: {
          width: isNaN(width) ? 0 : width > 16 ? width : 16,
          top: 2 * top + (rowHeight - 4) / 2 - 2,
          left: leftActual,
          height: (rowHeight - 4) / 2 - 1,
          fontSize,
        },
      };
    } else if (seaPassageEnabled && value === 2) {
      return {
        style: {
          width: isNaN(widthActual) ? 0 : widthActual > 16 ? widthActual : 16,
          top: 2 * top + (rowHeight - 4) / 2 - 2,
          left: leftActual,
          height: (rowHeight - 4) / 2 - 1,
          fontSize,
        },
      };
    } else if (seaPassageEnabled && value === 1) {
      return {
        style: {
          width: isNaN(width) ? 0 : width > 16 ? width : 16,
          top: top + 1,
          left,
          height: (rowHeight - 4) / 2 - 1,
          fontSize,
        },
      };
    }

    return {
      style: {
        width: isNaN(width) ? 0 : width > 16 ? width : 16,
        top,
        left,
        height: rowHeight - 4,
        fontSize,
      },
    };
  };

  const showVesselModal = () => {
    setSideModalOpen({
      imo: portcall.imo,
      name: portcall.data.vesselName,
      port_call_id: portcall.port_call_id,
      eta: portcall.eta,
    });
  };

  const PortcallToolTip = () => {
    return (
      <VesselTimestampFleetModalCard
        data={portcall.data}
        imo={portcall.imo}
        master_id={portcall.master_id}
        notifications={undefined}
        decisions={undefined}
        transitions={undefined}
        privateData={undefined}
        map={true}
        fleetAisData={portcall}
        marineTraffic={true}
      />
    );
  };

  return (
    <>
      <PortcallContainer
        data-testid="portcall"
        {...getPortcallProps(1)}
        onClick={showVesselModal}
        blue={false}
        manual={portcall.data.dataSource === 'manual'}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <Tooltip
          placement="right"
          title={PortcallToolTip()}
          overlayInnerStyle={{ padding: '0' }}
          color="white"
          open={tooltipOpen}
        >
          <PortcallInnerContainer>
            <PortcallNameContainer>{portcall.data.portName.replace(/ *\([^)]*\) */g, '')}</PortcallNameContainer>
            {serviceOrders && (
              <FleetTimelineServiceOrders
                serviceOrders={serviceOrders}
                setTooltipOpen={setTooltipOpen}
                vesselStarttime={starttime}
                vesselEndtime={endtime}
                millisecondInPixels={millisecondInPixels}
              />
            )}
          </PortcallInnerContainer>
        </Tooltip>
      </PortcallContainer>

      {actualAvailable && seaPassageEnabled && (
        <PortcallContainer
          data-testid="portcall-actual"
          {...getPortcallProps(2)}
          onClick={showVesselModal}
          blue={true}
          manual={portcall.data.dataSource === 'manual'}
        >
          <Tooltip placement="right" title={PortcallToolTip()} color="white" open={tooltipOpen}>
            <PortcallInnerContainer>
              <PortcallNameContainer>{portcall.data.portName.replace(/ *\([^)]*\) */g, '')}</PortcallNameContainer>
            </PortcallInnerContainer>
          </Tooltip>
        </PortcallContainer>
      )}

      {vesselAtPort && seaPassageEnabled && (
        <PortcallContainer
          data-testid="portcall-vessel-at-port"
          {...getPortcallProps(3)}
          onClick={showVesselModal}
          blue={true}
          current={true}
          manual={portcall.data.dataSource === 'manual'}
        >
          <Tooltip placement="right" title={PortcallToolTip()} color="white" open={tooltipOpen}>
            <PortcallInnerContainer>
              <PortcallNameContainer>{portcall.data.portName.replace(/ *\([^)]*\) */g, '')}</PortcallNameContainer>
            </PortcallInnerContainer>
          </Tooltip>
        </PortcallContainer>
      )}

      {!!nextPortcall?.eta &&
        !!nextPortcall?.etd &&
        seaPassageEnabled &&
        !!portcall.sea_passage?.travel_time_in_minutes &&
        !!portcall.atd &&
        !nextPortcall.ata && (
        <PortcallContainer
          data-testid="portcall-vessel-at-port"
          {...getPortcallProps(4)}
          onClick={showVesselModal}
          blue={true}
          current={true}
          manual={nextPortcall.data.dataSource === 'manual'}
        >
          <Tooltip placement="right" title={PortcallToolTip()} color="white" open={tooltipOpen}>
            <PortcallInnerContainer>
              <PortcallNameContainer>
                {nextPortcall.data.portName.replace(/ *\([^)]*\) */g, '')}
              </PortcallNameContainer>
            </PortcallInnerContainer>
          </Tooltip>
        </PortcallContainer>
      )}

      {seaPassageEnabled && portcall.sea_passage && nextPortcall && (
        <SeaPassage
          portcall={portcall}
          nextPortcall={nextPortcall}
          onClick={showVesselModal}
          calculatedActual={
            !!nextPortcall?.eta &&
            !!nextPortcall?.etd &&
            seaPassageEnabled &&
            !!portcall.sea_passage?.travel_time_in_minutes &&
            !!portcall.atd &&
            !nextPortcall.ata
          }
        />
      )}
    </>
  );
};

export default Portcall;
