import { useEffect, useRef } from 'react';
import L, { Control } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import '../../../node_modules/leaflet-draw/dist/leaflet.draw.css';
import circleToPolygon from 'circle-to-polygon';

const DrawControl = ({ drawAssets }) => {
  const drawRef = useRef();
  const map = useMap();
  const context = useLeafletContext();

  const _onCreated = e => {
    let type = e.layerType;
    let layer = e.layer;
    map.removeLayer(layer);

    if (type === 'circle') {
      const numOfEdges =
        parseInt(layer.options.radius) > 12
          ? parseInt(layer.options.radius) > 40
            ? 40
            : parseInt(layer.options.radius)
          : 6 + parseInt(layer.options.radius);
      const coords = layer.getLatLng();
      const newCircle = circleToPolygon([coords.lng, coords.lat], parseInt(layer.options.radius), {
        numberOfEdges: numOfEdges,
      });
      let transformedCoords = [];
      const circleCoords = newCircle.coordinates.flat();
      for (let index = 0; index < circleCoords.length; index++) {
        const element = circleCoords[index];
        transformedCoords.push([element[0], element[1]]);
      }
      drawAssets([transformedCoords]);
    } else if (type === 'polygon') {
      const coords = layer.getLatLngs().flat();
      let transformedCoords = [];
      for (let index = 0; index < coords.length; index++) {
        const element = coords[index];
        transformedCoords.push([element.lng, element.lat]);
      }
      drawAssets([transformedCoords]);
    }
  };

  useEffect(() => {
    map.on('draw:created', _onCreated);

    drawRef.current = createDrawElement(context);
    map.addControl(drawRef.current);

    return () => {
      map.off('draw:created', _onCreated);

      drawRef.current?.remove(map);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default DrawControl;

function createDrawElement(context) {
  const { layerContainer } = context;
  const options = {
    edit: {
      edit: false,
      remove: false,
      featureGroup: layerContainer,
    },
  };

  options.draw = {
    polyline: false,
    polygon: {
      icon: new L.DivIcon({
        iconSize: new L.Point(7, 7),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      shapeOptions: {
        guidelineDistance: 10,
        color: 'navy',
        weight: 2,
      },
    },
    circle: {
      shapeOptions: {
        color: 'navy',
        weight: 2,
        radius: 2,
        fillOpacity: 0.6,
        opacity: 0.6,
      },
    },
    circlemarker: false,
    marker: false,
    rectangle: false,
  };

  options.position = 'topright';

  return new Control.Draw(options);
}
