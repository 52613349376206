import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import DebounceAutocomplete from '../ui/DebounceAutocomplete';
import Icon from '../ui/Icon';

const Container = styled.div`
  display: block;
  margin-top: 6px;
`;

const ListOfVessels = styled.div`
  min-height: 34px;
  margin-top: 6px;
`;

const SelectedVessel = styled.div`
  border-radius: 4px;
  padding: ${props => (props.preview ? '2px 6px' : '4px 6px')};
  border: 1px solid #b1b1b1;
  display: inline-flex;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const NoVessels = styled.div`
  color: ${props => props.disabled && '#cfcfcf'};
  line-height: 34px;
  padding-left: ${props => props.preview && '6px'};
`;

const InfoText = styled.div`
  color: ${props => props.disabled && '#cfcfcf'};
`;

const IconContainer = styled.div`
  margin-left: 12px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.color.warning};
    margin-top: -2px;
  }
`;

const VesselsSelection = ({ data, disabled, inputData, setInputData, dataKey, preview = false, upperDataKey }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [input, setInput] = useState('');
  let vessels = inputData[dataKey];

  const setVessels = useCallback(
    list => {
      setInputData({ ...inputData, [dataKey]: list });
    },
    [dataKey, inputData, setInputData]
  );

  useEffect(() => {
    if (disabled) {
      if (!!input || input.length > 0) {
        setInput('');
      }
      if (vessels.length > 0) {
        setVessels([]);
      }
    }
  }, [disabled, input, setVessels, vessels]);

  const addVessel = data => {
    const vesselList = [...vessels];
    vesselList.push({ name: data.data.vessel_name, imo: data.data.imo });
    setVessels(vesselList);
    setInput('');
  };

  const fetchVesselList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      sort: 'vessel_name',
      search: value,
    };
    const result = await apiCall('get', 'vessels', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.vessel_name + '+' + d.imo, label: d.vessel_name + ' (' + d.imo + ')', data: d };
      });
    }

    return [];
  };

  const removeFromSpot = index => {
    const vesselList = [...vessels];
    vesselList.splice(index, 1);
    setVessels(vesselList);
  };

  if (!(data.when === 'any' || data.when === inputData[upperDataKey])) {
    return null;
  }

  return (
    <Container>
      {!preview && <InfoText disabled={disabled}>{t(data.info_text)}</InfoText>}
      <ListOfVessels>
        {vessels.length > 0 ? (
          vessels.map((vessel, i) => (
            <SelectedVessel key={i} preview={preview}>
              {vessel.name} ({vessel.imo}){' '}
              {!preview && (
                <IconContainer onClick={() => removeFromSpot(i)}>
                  <Icon type="close" />
                </IconContainer>
              )}
            </SelectedVessel>
          ))
        ) : (
          <NoVessels preview={preview} disabled={disabled}>
            {t('No vessels selected.')}
          </NoVessels>
        )}
      </ListOfVessels>
      {!preview && (
        <DebounceAutocomplete
          placeholder={t('Add vessel')}
          fetchOptions={fetchVesselList}
          onChange={value => setInput(value)}
          onSelect={(value, data) => addVessel(data)}
          value={input}
          style={{ maxWidth: '240px', width: '100%', marginTop: '6px' }}
          allowClear={true}
          onClear={() => setInput('')}
          disabled={disabled}
        />
      )}
    </Container>
  );
};
export default VesselsSelection;
