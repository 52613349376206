import React, { useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';
import { UserContext } from '../../../context/UserContext';

import { smallSidebarWidth } from '../../commonCalendar/utility/constants';
import Icon from '../../ui/Icon';

const SidebarContainer = styled.div`
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px -1px 1px 0px rgb(0 0 0 / 20%);
  margin-right: 2px;
`;

const Name = styled.div`
  width: 100%;
  text-align: right;
  color: black;
  font-size: 13px;
  padding-right: 8px;
  cursor: pointer;
  line-height: ${props => props.rowHeight}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
`;

const NameText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: ${props => props.chosen && '#4990DD'};
  font-weight: ${props => props.chosen && 700};
  font-size: ${props => (props.rowHeight > 20 ? 13 : 11)}px;
`;

const TooltipContainer = styled.div`
  max-width: 300px;
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.grey};
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 240px;
`;

const Common = styled.div`
  display: flex;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const WarningIcon = styled(Icon)`
  svg {
    margin-top: -2px;
    height: 16px;
    width: 16px;
  }
`;

export const Sidebar = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { vessels, rowHeight, seaPassageEnabled, setSideModalOpen, sideModalOpen } = useContext(FleetVesselViewContext);

  let outerComponent = document.querySelector('#outer-component');
  let outerComponentHeight = 0;

  if (outerComponent) {
    outerComponentHeight = outerComponent.getBoundingClientRect().height;
  }

  const sidebarStyle = {
    width: `${smallSidebarWidth}px`,
    minHeight: `${outerComponentHeight}px`,
  };

  const onVesselClick = (imo, name) => {
    setSideModalOpen({
      imo: imo,
      name: name,
      port_call_id: undefined,
      eta: undefined,
    });
  };

  let vesselLines = vessels.map((vessel, index) => {
    const vesselsStyle = {
      width: `${smallSidebarWidth}px`,
      height: `${rowHeight}px`,
    };

    const VesselToolTip = () => {
      return (
        <TooltipContainer data-id="vessel-sidebar-tooltip">
          <Common>
            <FirstColumn>
              <RowHeaderForTooltip>{t('Name')}:</RowHeaderForTooltip>
              <RowHeaderForTooltip>{t('IMO')}:</RowHeaderForTooltip>
            </FirstColumn>
            <SecondColumn>
              <TooltipValue>{vessel.vesselName}</TooltipValue>
              <TooltipValue>{vessel.imo}</TooltipValue>
            </SecondColumn>
          </Common>
        </TooltipContainer>
      );
    };

    return (
      <Tooltip
        key={index}
        placement="right"
        title={VesselToolTip}
        color="white"
        overlayInnerStyle={{ color: '#4a4a4a' }}
      >
        <Name
          key={index}
          style={vesselsStyle}
          rowHeight={rowHeight}
          onClick={() => onVesselClick(vessel.imo, vessel.vesselName)}
        >
          {seaPassageEnabled && vessel.delayed && <WarningIcon type="vessel-warning" style={{ marginRight: '8px' }} />}
          <NameText rowHeight={rowHeight} chosen={sideModalOpen && sideModalOpen.imo === vessel.imo}>
            {vessel.vesselName}
          </NameText>
        </Name>
      </Tooltip>
    );
  });

  return <SidebarContainer style={sidebarStyle}>{vesselLines}</SidebarContainer>;
};
