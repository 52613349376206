import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';
import { WeatherContext } from '../context/WeatherContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';

import PortCurrentWeatherCard from '../components/weather/PortCurrentWeatherCard';
import PortWeatherForecastCard from '../components/weather/PortWeatherForecastCard';
import PortHourlyWeatherTable from '../components/weather/PortHourlyWeatherTable';
import WeatherStationSelect from '../components/weather/WeatherStationSelect';

const PortWeatherContainer = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const PortWeather = () => {
  const { namespace } = useContext(UserContext);
  const { chosenStation } = useContext(WeatherContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Weather | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Port Weather')} scrollId="weather-layout">
      <Page fullWidth smallTopPadding>
        <WeatherStationSelect title={t('Weather at {{place}}', { place: chosenStation ? chosenStation.name : '-' })} />
        <PortWeatherContainer>
          <PortCurrentWeatherCard />
          <PortWeatherForecastCard />
        </PortWeatherContainer>
        <PortHourlyWeatherTable />
      </Page>
    </Layout>
  );
};

export default PortWeather;
