import React, { useContext } from 'react';
import styled from 'styled-components';

import CranePlanningToolCalendar from './CranePlanningToolCalendar';

import { CranePlanningToolContext } from '../../context/CranePlanningToolContext';

const CalendarContainer = styled.div`
  height: 100%;
  min-width: 790px;
`;

const CranePlanningToolCalendarContainer = () => {
  const { sidePanelWidth } = useContext(CranePlanningToolContext);

  return (
    <CalendarContainer style={{ width: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <CranePlanningToolCalendar />
    </CalendarContainer>
  );
};

export default CranePlanningToolCalendarContainer;
