import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';

import Icon from '../../../ui/Icon';
import ButtonLight from '../../../ui/ButtonLight';

import { UserContext } from '../../../../context/UserContext';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const InfoText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const RemoveCommodityModal = ({ closeModal, data, refetchData }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const handleOk = async () => {
    await apiCall('delete', 'pbp/port-commodities', {
      id: data.id,
    });

    refetchData();
    closeModal(false);
  };

  return (
    <Modal
      title={t('Remove commodity')}
      open={true}
      width={600}
      footer={null}
      headStyle={{ backgroundColor: 'red' }}
      onCancel={closeModal}
    >
      <ModalInner>
        <IconContainer>
          <Icon type="remove-illustration"></Icon>
        </IconContainer>
        <InfoText>
          {t('Are you sure you want to remove commodity')}{' '}
          <span style={{ fontWeight: 600, marginLeft: '4px' }}>{data.name}</span>?
        </InfoText>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
          <ButtonLight red onClick={handleOk}>
            {t('Remove')}
          </ButtonLight>
        </ActionButtons>
      </ModalInner>
    </Modal>
  );
};

export default RemoveCommodityModal;
