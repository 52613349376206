import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { TimestampContext } from '../../context/TimestampContext';

import Tooltip from 'antd/es/tooltip';
import Popover from 'antd/es/popover';

import Heading from '../ui/Heading';
import Icon from '../ui/Icon';

import CardActions from './CardActions';
import CardInfo from './CardInfo';
import { TIME_FORMAT } from '../../utils/constants';
import NotificationRow from './NotificationRow';
import AddServiceOrderModal from './serviceOrder/AddServiceOrderModal';
import ServiceOrderModal from './serviceOrder/ServiceOrderModal';
import ServiceOrderTooltip from './serviceOrder/ServiceOrderTooltip';
import ServiceDot from './serviceOrder/ServiceDot';
import SpeedModal from './SpeedModal';
import TimestampLine from './TimestampLine';
import DateComponent from '../ui/DateComponent';

const CardWrapper = styled.div`
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 12px 12px 4px 12px;
  margin: 6px;
  z-index: ${props => (props.open ? 16 : 15)};
  position: relative;
  background: ${props => (props.pinned ? props.theme.color.highlight : props.theme.color.white)};

  &:hover {
    .speed-icon-container {
      display: flex;
    }
    .timestamp-side-panel {
      display: flex;
    }
  }
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const CardInnerWrap = styled.div``;

const ShipName = styled(Heading)`
  display: flex;
  margin-bottom: 1px;
  margin-top: -4px;
  font-size: 17px;
  line-height: 22px;
  white-space: nowrap;
`;

const VesselName = styled.span`
  max-width: ${props =>
    'calc(100% - 98px ' + (props.nationality ? '- 18px ' : '- 0px ') + (props.decisions ? '- 18px' : '- 0px') + ')'};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 20px;
`;

const Agent = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.grey};
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IMORow = styled.div`
  display: flex;
  flex-wrap: none;
`;

const NextEvent = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.small};
`;

const NextEventTitle = styled.span`
  margin-right: ${({ theme }) => theme.sizing.gap_tiny};
`;

const NextEventTimestamp = styled(DateComponent)``;

const Nationality = styled.sup`
  margin-left: ${({ theme }) => theme.sizing.gap_tiny};
  font-size: ${({ theme }) => theme.text.small};
  user-select: none;
  margin-top: 10px;
  top: -1.5em;
  position: relative;
  height: 10px;
`;

const Placeholder = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.text.small};
`;

const DataNotAvailable = styled.span`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.text.small};
`;

const CardActionPanel = styled.div`
  position: absolute;
  right: 8px;
  top: 10px;
  display: flex;
  justify-content: flex-end;
  align-item: center;
  width: 75px;
  gap: 0.2rem;
`;

const ThreeDotContainer = styled.div`
  height: 17px;
  width: 26px;
  padding-left: 2px;
  @media (min-width: 1110px) {
    right: 10px;
  }
  @media (min-width: 900px) and (max-width: 1109px) {
    right: calc(6rem + 1038px - 100vw);
  }
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  z-index: 62;
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
  i {
    margin-top: -8px;
    svg {
      margin-top: -6px;
    }
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
`;

const PopoverContent = styled.div`
  z-index: 1;
  background: ${({ theme }) => theme.color.white};
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: start;
  align-item: center;
`;

const RequestContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 2px;
  margin-top: 1px;

  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 600;
`;

const AgentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 76px);
`;

const Warning = styled.div`
  color: #db3535;
  margin-left: 6px;
  margin-right: -2px;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
  }
`;

const Warning2 = styled.div`
  color: #db3535;
  margin-right: 8px;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
  }
`;

const ServiceOrders = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 30px;
  background-color: #f2f2f2;
  border-radius: 3px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  display: none;

  svg {
    height: 15px;
    width: 15px;
    margin-top: -4px;
  }
`;

const NewServiceOrder = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 4px;

  svg {
    fill: ${({ theme }) => theme.color.secondary};
    width: 15px;
    height: 15px;
    margin-top: -4px;
  }
`;

const Card = ({
  data,
  cardOpen,
  notifications,
  decisions,
  transitions,
  serviceOrders,
  reloadServiceOrderData,
  jitForVessel,
  hasWarnings,
  clickCard,
  portcalls,
  width,
  size,
  ...props
}) => {
  const { apiCall, namespace, user, modules } = useContext(UserContext); // eslint-disable-line
  const { pinnedVessels, fetchData } = useContext(TimestampContext);
  const { t } = useTranslation(namespace);

  const {
    id,
    imo,
    mmsi,
    call_sign,
    vessel_name,
    nationality,
    is_vis,
    vis_service_id,
    type,
    status,
    loa,
    beam,
    draft,
    net_tonnage,
    gross_weight,
    badges,
    next_event,
    next_events,
    vessel_type_name,
    master_id,
    agent,
    dead_weight,
  } = data;

  const [open, setOpen] = useState(cardOpen);
  const [pinned, setPinned] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.querySelector('#popover-content');
      const jitmodal = document.querySelector('#edit-jit-content');

      if (modal ? !modal.contains(event.target) : true) {
        if (
          !(
            jitmodal?.contains(event.target) ||
            event.target.outerHTML.includes('ant-picker') ||
            (event.target.outerHTML.substring(0, 6) === '<span>' &&
              event.target.outerHTML.substring(event.target.outerHTML.length - 7, event.target.outerHTML.length - 0) ===
                '</span>') ||
            event.target.outerHTML.includes('activity-info-drag-handle')
          )
        ) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [addServiceOrderModalOpen, setAddServiceOrderModalOpen] = useState(false);
  const [serviceOrderModalTab, setServiceOrderModalTab] = useState(undefined);

  useEffect(() => {
    if (pinnedVessels.includes(imo)) {
      setPinned(true);
    } else {
      setPinned(false);
    }
  }, [imo, pinnedVessels, badges, is_vis]);

  const handleClick = () => {
    if (open && localStorage.getItem(`vessel-notification-${imo}`)) {
      localStorage.removeItem(`vessel-notification-${imo}`);
    }
    setOpen(!open);
  };

  const hasPermissions =
    user.permissions.includes('add_manual_timestamp') ||
    user.permissions.includes('send_push_notification') ||
    user.permissions.includes('send_rta_web_form') ||
    user.permissions.includes('send_vis_message')
      ? true
      : false;

  const hasData =
    mmsi ||
    call_sign ||
    net_tonnage ||
    gross_weight ||
    dead_weight ||
    type ||
    status ||
    loa ||
    beam ||
    draft ||
    vessel_type_name
      ? true
      : false;

  let requested = [];
  let acknowledged = [];
  let delivered = [];

  if (serviceOrders) {
    serviceOrders.forEach(so => {
      if (so.order_lines) {
        so.order_lines.forEach(ol => {
          if (ol.deliveries.length > 0) {
            delivered.push(ol);
          } else if (ol.ack) {
            acknowledged.push({ service_order_id: so.id, ...ol });
          } else {
            requested.push({ service_order_id: so.id, ...ol });
          }
        });
      }
    });
  }

  const DotClickTooltip = type => {
    return (
      <span>
        {t('Click to open')}
        <span style={{ fontWeight: 600, padding: '0 6px' }}>{t(type)}</span>
        {t('details')}
      </span>
    );
  };

  return (
    <CardWrapper
      open={open}
      pinned={pinned}
      {...props}
      style={{ width: width - 24 + 'px', height: size === 'large' ? '112px' : size === 'medium' ? '76px' : '36px' }}
    >
      <CardInner>
        <CardInnerWrap>
          <CardActionPanel>
            <NotificationRow
              notifications={notifications}
              decisions={decisions}
              imo={imo}
              vessel_name={vessel_name}
              master_id={master_id}
              small={true}
            />
            <ThreeDotContainer
              link
              show={hasPermissions || hasData}
              open={open}
              onClick={handleClick}
              aria-label={open ? 'close-activity-view-card-button' : 'open-activity-view-card-button'}
            >
              <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
            </ThreeDotContainer>
          </CardActionPanel>

          <CardInfo show={open} setShow={setOpen}>
            <BackgroundContainer>
              <PopoverContent id="popover-content">
                <InfoHeader>{vessel_name}</InfoHeader>
                <CardActions
                  portCallId={id}
                  pinned={pinned}
                  imo={imo}
                  setOpen={setOpen}
                  vesselName={vessel_name}
                  isVis={is_vis}
                  visServiceId={vis_service_id}
                  masterId={master_id}
                  fetchData={fetchData}
                  setPinned={setPinned}
                  pinnedVessels={pinnedVessels}
                  jitForVessel={jitForVessel}
                />
              </PopoverContent>
            </BackgroundContainer>
          </CardInfo>

          {(size === 'medium' || size === 'large') && (
            <ServiceOrders>
              <DotContainer>
                {requested && requested.filter(r => !r.is_cancelled).length > 0 && (
                  <Popover
                    content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                    placement="bottom"
                    trigger="hover"
                  >
                    <Tooltip title={DotClickTooltip('Request')}>
                      <RequestContainer onClick={() => setServiceOrderModalTab('1')}>
                        <ServiceDot status={'requested'} clickable={true} />
                      </RequestContainer>
                    </Tooltip>
                  </Popover>
                )}
                {acknowledged &&
                  acknowledged.filter(a => !a.is_cancelled).length >
                    0 && (
                  <Popover
                    content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                    placement="bottom"
                    trigger="hover"
                  >
                    <Tooltip title={DotClickTooltip('Acknowledgement')}>
                      <RequestContainer onClick={() => setServiceOrderModalTab('2')}>
                        <ServiceDot
                          status={
                            acknowledged?.every(item => item.ack?.status === 'rejected') ? 'rejected' : 'acknowledged'
                          }
                          clickable={true}
                        />
                      </RequestContainer>
                    </Tooltip>
                  </Popover>
                )}
                {delivered && delivered.length > 0 && (
                  <Popover
                    content={ServiceOrderTooltip(requested, acknowledged, delivered, t, false)}
                    trigger="hover"
                    placement="bottom"
                  >
                    <Tooltip title={DotClickTooltip('Delivery')}>
                      <RequestContainer onClick={() => setServiceOrderModalTab('3')}>
                        <ServiceDot status={'delivered'} clickable={true} />
                      </RequestContainer>
                    </Tooltip>
                  </Popover>
                )}
                {user.permissions.includes('manage_service_order') && (
                  <Tooltip
                    title={'Add new service request'}
                    color="#ffffff"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px' }}
                    placement="left"
                  >
                    <NewServiceOrder onClick={() => setAddServiceOrderModalOpen(true)}>
                      <Icon type={'circle-plus'} />
                    </NewServiceOrder>
                  </Tooltip>
                )}
              </DotContainer>
            </ServiceOrders>
          )}

          <ShipName h3>
            <Tooltip
              placement="top"
              title={
                <div>
                  <div style={{ fontWeight: 600, textAlign: 'center' }}>{vessel_name}</div>
                  <div style={{ textAlign: 'center' }}>{imo}</div>
                </div>
              }
              color="#ffffff"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px' }}
            >
              <VesselName
                nationality={!!nationality}
                transitions={!!transitions}
                decisions={!!decisions && !!decisions.total}
              >
                {vessel_name}
              </VesselName>
            </Tooltip>
            <Nationality>{nationality}</Nationality>
            <SpeedModal vesselName={vessel_name} portcallId={id} statusDone={status === 'done'} mmsi={mmsi} />
            <Tooltip
              title={t('Show timestamps')}
              color="white"
              overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
            >
              <IconContainer onClick={clickCard} className="timestamp-side-panel">
                <Icon type="timestamps" />
              </IconContainer>
            </Tooltip>
          </ShipName>
          {(size === 'medium' || size === 'large') && (
            <IMORow>
              <AgentContainer>
                <Tooltip
                  placement="bottom"
                  title={agent}
                  color="#ffffff"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '12px' }}
                >
                  <div>
                    <Agent>{agent}</Agent>
                  </div>
                </Tooltip>
                {hasWarnings && (
                  <Tooltip
                    title={
                      <div style={{ display: 'flex' }}>
                        <Warning2>
                          <Icon type="warning" />
                        </Warning2>
                        {t('Check warning details from below.')}
                      </div>
                    }
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                  >
                    <Warning>
                      <Icon type="warning" />
                    </Warning>
                  </Tooltip>
                )}
              </AgentContainer>
            </IMORow>
          )}
          {size === 'large' &&
            !next_events &&
            (next_event?.ts && next_event?.title ? (
              <NextEvent>
                <NextEventTitle>{t(next_event.title)}</NextEventTitle>
                <NextEventTimestamp format={TIME_FORMAT} date={next_event.ts} />
              </NextEvent>
            ) : (
              <Placeholder>{t('ETA unknown')}</Placeholder>
            ))}
          {size === 'large' &&
            !!next_events &&
            next_events.map((e, i) => (
              <NextEvent key={i}>
                <NextEventTitle>{t(e.title)}</NextEventTitle>
                {e.ts ? (
                  <NextEventTimestamp format={TIME_FORMAT} date={e.ts} />
                ) : (
                  <DataNotAvailable>-</DataNotAvailable>
                )}
              </NextEvent>
            ))}
        </CardInnerWrap>
        {(size === 'medium' || size === 'large') && <TimestampLine portcalls={portcalls} />}
      </CardInner>
      {addServiceOrderModalOpen && (
        <AddServiceOrderModal
          vessel={data}
          closeModal={() => setAddServiceOrderModalOpen(false)}
          reloadData={reloadServiceOrderData}
        />
      )}
      {!!serviceOrderModalTab && (
        <ServiceOrderModal
          vessel={data}
          closeModal={() => setServiceOrderModalTab(undefined)}
          reloadData={reloadServiceOrderData}
          requested={requested}
          acknowledged={acknowledged}
          delivered={delivered}
          serviceOrderModalTab={serviceOrderModalTab}
          setServiceOrderModalTab={setServiceOrderModalTab}
        />
      )}
    </CardWrapper>
  );
};

export default Card;
