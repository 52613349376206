import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Spin from 'antd/es/spin';
import App from 'antd/es/app';
import Tooltip from 'antd/es/tooltip';

import Paragraph from '../ui/Paragraph';

import PageAction from '../page/PageAction';
import Icon from '../ui/Icon';
import ApiKeyWeightsTimestampsModal from './ApiKeyWeightsTimestampsModal';

const PriorityInfoText = styled(Paragraph)`
  width: 70%;
  font-size: 14px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
`;

const Container = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  min-width: 330px;
`;

const Item = styled.div`
  display: flex;
  background-color: ${props => (props.row % 2 ? '#f8f8f8' : 'white')};
  padding: 12px 14px 0px 14px;
  min-height: 75px;
  justify-content: space-between;
`;

const Data = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  margin-right: 24px;
`;

const Header = styled.div`
  width: 160px;
  min-width: 160px;
`;

const ApiKey = styled.div`
  width: 100%;
  margin-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Priorities = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const Priority = styled.div`
  width: 140px;
  margin-right: 12px;
  margin-bottom: 18px;
`;

const PriorityHeader = styled.div`
  font-weight: 600;
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const EditButtonContainer = styled.div`
  margin-left: ${props => !props.header && '12px'};
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: ${props => !props.header && '12px'};
  margin-top: ${props => props.header && '4px'};
  display: ${props => (!props.header ? 'inline-block' : 'none')};

  @media (max-width: 500px) {
    display: ${props => (props.header ? 'inline-block' : 'none')};
  }
`;

const EditButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;
  height: 24px;
  width: 24px;
  padding-left: 2px;
  border-radius: 3px;

  i {
    height: 18px;
    width: 18px;
  }

  svg {
    margin-top: -1px;
    margin-right: -3px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const ApiKeyWeightsTimestamps = () => {
  const { user, namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [rebuildTimestampData, setRebuildTimestampData] = useState(true);
  const [timestampData, setTimestampData] = useState([]);

  const [modalOpen, setModalOpen] = useState(null);

  const { message } = App.useApp();

  const { loading, data, error, fetchData } = useApi('get', 'timestamp-api-key-weights');

  if (error) {
    message.error(error, 4);
  }

  const emptyApiKey = [
    {
      id: -1,
      name: '',
      is_active: true,
    },
  ];

  let apiKeys = loading ? [] : emptyApiKey.concat(data.api_keys);
  apiKeys.forEach(p => {
    p.label = p.name;
    p.value = p.id;
    p.key = p.label;
  });
  apiKeys = apiKeys.filter(k => k.is_active);
  apiKeys = apiKeys.filter(
    (value, index, self) => index === self.findIndex(t => t.place === value.place && t.name === value.name)
  );

  const timestamps = loading ? [] : data.timestamps;

  if (rebuildTimestampData && !loading) {
    let tempTimestampData = [];
    timestamps.forEach(p => {
      let tempTimestamp = { state: p.state, key: p.state + '_' + p.time_type };
      tempTimestamp.time_type = p.time_type;
      tempTimestamp.title = p.time_type
        .concat(' ')
        .concat(p.state)
        .replace(/_/g, ' ');
      tempTimestamp.api_keys = p.api_keys.slice(0);
      tempTimestamp.new_api_keys = [];
      let i;
      for (i = 0; i < 5; i++) {
        if (p.api_keys[i] === undefined) {
          tempTimestamp.new_api_keys.push({ api_key_id: -1 });
        } else {
          tempTimestamp.new_api_keys.push({ api_key_id: p.api_keys[i].api_key_id });
        }
      }

      tempTimestampData.push(tempTimestamp);
    });

    setTimestampData(tempTimestampData);
    setRebuildTimestampData(false);
  }

  const saveData = async ({ inputData, timestamp_time_type, timestamp_state }) => {
    setApiCallPending(true);

    let api_key_ids = [];
    inputData.forEach(item => {
      let ids = '';
      item.keys.forEach((k, i) => {
        if (i > 0) {
          ids += '|';
        }
        ids += k.api_key_id;
      });
      if (ids.length > 0) {
        api_key_ids.push(ids);
      }
    });

    try {
      await apiCall('post', 'timestamp-api-key-weights', {
        timestamp_time_type,
        timestamp_state,
        api_key_ids,
      });
    } catch (e) {
      setTimestampData([]);
      await fetchData(false);
      setRebuildTimestampData(true);
      setApiCallPending(false);
      throw e;
    }

    setTimestampData([]);
    await fetchData(false);
    setRebuildTimestampData(true);
    setApiCallPending(false);
  };

  const createTooltip = (apiKey, prio, timestamp) => {
    return (
      <div>
        <div style={{ fontWeight: 600, marginBottom: '6px' }}>{t(timestamp)}</div>
        <div>{t('Priority {{number}}', { number: prio })}</div>
        <div>{t(apiKey)}</div>
      </div>
    );
  };

  return (
    <>
      <PageAction>
        <PriorityInfoText>
          {t(
            'Timestamps can be assigned with API keys with different priorities. Only higher priorities will be able to overwrite lower priority timestamps. Priorities are numbered starting from Priority 1. Manually added timestamps will always have the highest priority and will overwrite any other timestamp within a port call.'
          )}
        </PriorityInfoText>
      </PageAction>

      <Container>
        <MainHeader>{t('Priorities')}</MainHeader>
        {loading || apiCallPending ? (
          <Loader>
            <Spin size="large" />
          </Loader>
        ) : (
          timestampData.map((timestamp, i) => {
            const weights = [];
            timestamp.api_keys.forEach(a => {
              if (weights.findIndex(w => w === a.weight) === -1) {
                weights.push(a.weight);
              }
            });
            weights.sort((a, b) => b - a);

            const apiKeysSorted = [];
            weights.forEach(w => {
              apiKeysSorted.push({
                weight: w,
                keys: timestamp.api_keys.filter(k => k.weight === w),
              });
            });

            return (
              <Item key={i} row={i}>
                <Data>
                  <HeaderContainer>
                    <Header>{t(timestamp.title)}</Header>
                    {user.permissions.includes('manage_api_key') && (
                      <EditButtonContainer header={true}>
                        <EditButton onClick={() => setModalOpen(timestamp)}>
                          <Icon type="edit" />
                        </EditButton>
                      </EditButtonContainer>
                    )}
                  </HeaderContainer>
                  <Priorities>
                    {apiKeysSorted.map((prio, index) => (
                      <Priority key={prio.weight}>
                        <PriorityHeader>{t('Priority {{number}}', { number: index + 1 })}</PriorityHeader>
                        {prio.keys.map(apikey => (
                          <Tooltip
                            title={createTooltip(apikey.api_key_name, index + 1, timestamp.title)}
                            color="white"
                            overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                            key={apikey.api_key_id}
                          >
                            <ApiKey>{t(apikey.api_key_name)}</ApiKey>
                          </Tooltip>
                        ))}
                      </Priority>
                    ))}
                  </Priorities>
                </Data>
                {user.permissions.includes('manage_api_key') && (
                  <EditButtonContainer header={false}>
                    <EditButton onClick={() => setModalOpen(timestamp)}>
                      <Icon type="edit" />
                    </EditButton>
                  </EditButtonContainer>
                )}
              </Item>
            );
          })
        )}
      </Container>

      {!!modalOpen && (
        <ApiKeyWeightsTimestampsModal
          timestamp={modalOpen}
          closeModal={() => setModalOpen(null)}
          saveData={saveData}
          apiKeys={apiKeys}
        />
      )}
    </>
  );
};

export default ApiKeyWeightsTimestamps;
