import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tooltip from 'antd/es/tooltip';
import App from 'antd/es/app';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import { PAGINATION_LIMIT, TIME_FORMAT, TIME_FORMAT_DAY } from '../../utils/constants';
import NewInvoiceModal from './NewInvoiceModal';
import useApi from '../../hooks/useApi';
import Icon from '../ui/Icon';
import ListActionButton from '../ui/ListActionButton';
import CreditInvoiceModal from './CreditInvoiceModal';
import CreditInvoiceList from './CreditInvoiceList';
import DateComponent from '../ui/DateComponent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const IconContainer = styled.span`
  color: #4990dd;
  margin-left: 4px;

  i {
    width: 18px;
    height: 18px;
  }

  svg {
    margin-top: -2px;
  }
`;

const ColorContainer = styled.div`
  position: relative;
`;

const TypeColorWithoutColor = styled.div`
  text-transform: capitalize;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px 8px;
  opacity: 100%;
  position: absolute;
  top: 0px;
`;

const TypeColor = styled.div`
  text-transform: capitalize;
  background-color: ${props => props.color || 'transparent'};
  border-radius: 4px;
  padding: 1px 8px;
  opacity: 40%;
`;

const ColorText = styled.div`
  margin: auto;
`;

const SentInvoicesList = () => {
  const { namespace, useUserSocket, user, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(undefined);

  const [creditInvoiceData, setCreditInvoiceData] = useState(undefined);
  const [creditInvoiceModalOpen, setCreditInvoiceModalOpen] = useState(false);
  const [creditInvoice, setCreditInvoice] = useState(undefined);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [readOnlyAndSend, setReadOnlyAndSend] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [sendEditModeOn, setSendEditModeOn] = useState(false);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'invoice_date DESC',
    search: '',
  };

  const getParamsInRightForm = useCallback(parameters => {
    let correctParams = {
      query: {
        text: parameters.search,
        conditions: {
          and: [
            {
              and: [
                {
                  type: 'is_sent',
                  operator: 'is',
                  value: true,
                },
              ],
            },
          ],
        },
      },
      pagination: {
        limit: parameters.limit,
        offset: parameters.offset,
      },
    };

    if (parameters.sort) {
      let sortList = parameters.sort.split(' ');
      if (sortList.length === 1) {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'asc',
          },
        ];
      } else {
        correctParams.order_by = [
          {
            field: sortList[0],
            order: 'desc',
          },
        ];
      }
    } else {
      correctParams.order_by = [
        {
          field: 'invoice_date',
          order: 'asc',
        },
      ];
    }

    return correctParams;
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi(
    'post',
    'invoicing/v2/invoice/search',
    getParamsInRightForm(newParams),
    null,
    false
  );

  useUserSocket('invoicing-invoices-changed', () => fetchData(false, getParamsInRightForm(newParams)));

  let invoices = error || !data ? [] : data.results ? data.results.invoices : [];

  const { start, total } =
    error || !data
      ? { start: 0, total: 0 }
      : data.results
        ? { start: data.results.pagination.offset, total: data.results.pagination.total }
        : { start: 0, total: 0 };

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    fetchData(false, getParamsInRightForm(newParams));
  }, [newParams, fetchData, getParamsInRightForm]);

  const getTypeTooltip = item => {
    if (item.type === 'manual') {
      return null;
    }

    if (item.type === 'automatic' && !!item.template?.name) {
      return (
        <div>
          <span>{t('Automatic invoice name')}:</span> <span style={{ fontWeight: 600 }}>{item.template.name}</span>
        </div>
      );
    }

    return null;
  };

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Customer name'),
      dataIndex: 'customer_number',
      key: 'customer_number',
      sortableKey: 'customer_number',
      render: (record, item) => {
        return item.customer.name;
      },
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (record, item) => {
        return (
          <Tooltip placement="top" title={getTypeTooltip(item)} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            <div style={{ display: 'flex' }}>
              <ColorContainer>
                <TypeColor color={item.template?.color?.length > 0 ? item.template.color : null}>
                  <div style={{ color: 'transparent' }}>{record}</div>
                </TypeColor>
                <TypeColorWithoutColor>
                  <ColorText>{record}</ColorText>
                </TypeColorWithoutColor>
              </ColorContainer>

              {item.type === 'automatic' && !!item.template?.name && (
                <IconContainer>
                  <Icon type="info" />
                </IconContainer>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('Vessel'),
      dataIndex: 'vessel',
      key: 'vessel',
      render: (record, item) => {
        if (item.port_call_data?.port_call_data?.vessel_name) {
          return item.port_call_data.port_call_data.vessel_name;
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Date'),
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      sortableKey: 'invoice_date',
      render: record => {
        if (record) {
          return dayjs(record).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Due date'),
      dataIndex: 'due_date',
      key: 'due_date',
      sortableKey: 'due_date',
      render: (record, item) => {
        if (item.calculated?.used_due_date) {
          return dayjs(item.calculated.used_due_date).format(TIME_FORMAT_DAY);
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Total'),
      dataIndex: 'price',
      key: 'price',
      render: (record, item) => {
        return item.calculated.total_sum_including_vat + ' ' + item.currency_code;
      },
    },
    {
      title: t('Tax class'),
      dataIndex: 'tax_class_name',
      key: 'tax_class_name',
    },
    {
      title: t('Credit invoices'),
      dataIndex: 'credit_invoices',
      key: 'credit_invoices',
      render: record => {
        if (record.length) {
          const sent = record.filter(i => i.is_sent);
          return (
            <div>
              {sent.length} / {record.length}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.created_at} />
              </Content2>
            )}
            <Content2>{record.created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      render: record => {
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record.updated_at} />
              </Content2>
            )}
            <Content2>{record.updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-3"
              onClick={() => {
                setReadOnly(true);
                setModalInvoice(record);
                setInvoiceModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
        {
          render: record => (
            <ListActionButton
              key="action-2"
              onClick={() => {
                setCreditInvoice(record);
                setCreditInvoiceModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Create credit invoice')}
            </ListActionButton>
          ),
        },
      ]
    : [
        {
          render: record => (
            <ListActionButton
              key="action-3"
              onClick={() => {
                setReadOnly(true);
                setModalInvoice(record);
                setInvoiceModalOpen(true);
              }}
            >
              <Icon type="page" />
              {t('Open')}
            </ListActionButton>
          ),
        },
      ];

  const closeInvoiceModal = () => {
    setModalInvoice(undefined);
    setInvoiceModalOpen(false);
    setReadOnlyAndSend(false);
    setReadOnly(false);
    setSendEditModeOn(false);
  };

  const closeCreditModal = () => {
    setCreditInvoice(undefined);
    setCreditInvoiceModalOpen(false);
    setCreditInvoiceData(undefined);
  };

  const setInvoiceData = invoice => {
    setModalInvoice(invoice);
    setInvoiceModalOpen(true);
    closeCreditModal();
  };

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `invoicing/v2/invoice/${id}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    await fetchData(false, getParamsInRightForm(newParams));
  };

  return (
    <Container>
      <List
        rowKey="id"
        columns={columns}
        dataSource={invoices}
        spinning={loading}
        apiCallPending={!loading && apiCallPending}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search')}
        actions={actionList}
        noUrlUpdate={true}
        expandedRowRender={record => (
          <CreditInvoiceList
            invoices={record.credit_invoices}
            customer={record.customer}
            handleDelete={handleDelete}
            setInvoiceModalOpen={setInvoiceModalOpen}
            setModalInvoice={setModalInvoice}
            setReadOnlyAndSend={setReadOnlyAndSend}
            setReadOnly={setReadOnly}
            setCreditInvoiceData={setCreditInvoiceData}
            setCreditInvoice={setCreditInvoice}
            setCreditInvoiceModalOpen={setCreditInvoiceModalOpen}
            invoiceRecord={record}
          />
        )}
      />

      {invoiceModalOpen && (
        <NewInvoiceModal
          invoice={modalInvoice}
          closeModal={closeInvoiceModal}
          readOnly={sendEditModeOn ? false : readOnlyAndSend || readOnly}
          sendAllowed={sendEditModeOn ? false : readOnlyAndSend}
          setSendEditModeOn={setSendEditModeOn}
          sendEditModeOn={sendEditModeOn}
        />
      )}

      {creditInvoiceModalOpen && (
        <CreditInvoiceModal
          closeModal={closeCreditModal}
          id={creditInvoice?.id}
          setInvoiceData={setInvoiceData}
          creditInvoiceData={creditInvoiceData}
        />
      )}
    </Container>
  );
};

export default SentInvoicesList;
