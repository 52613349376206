import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';
import { TimestampContext } from '../../../context/TimestampContext';

import Icon from '../../ui/Icon';

const Container = styled.div`
  font-size: 13px;
  padding: 0 6px;
`;

const Header = styled.div`
  font-weight: 600;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const DataContainer = styled.div`
  margin-top: 12px;
  padding: 4px;
`;

const Legend = styled.div`
  display: flex;
`;

const Columns = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
`;

const LegendText = styled.div`
  margin-left: 0px;
`;

const IconContainer = styled(Icon)`
  svg {
    margin-top: -1px;
    width: 14px;
    height: 14px;
  }
`;

const SubColumn = styled.div`
  margin-right: 4px;
`;

const IconText = styled.div``;

const IconText2 = styled(IconText)`
  margin-right: 10px;
`;

const Row = styled.div`
  display: flex;
  line-height: 20px;
  margin-bottom: ${props => (props.biggerSpace ? '6px' : '0px')};
`;

const BoldPart = styled.span`
  font-weight: 700;
`;

const SemiboldPart = styled.span`
  font-weight: 600;
`;

const supportedTimestamps = [
  // 'ATA',
  'ATB',
  // 'ATD',
  // 'ETA',
  'ETB',
  'ETP',
  // 'ETD',
  // 'RPS',
  // 'PPS',
  // 'APS',
  // 'APC',
];

const VesselInfoModal = ({ vessel, dataFromOtherNamespaces }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { timestamps } = useContext(TimestampContext);

  if (!vessel) {
    return null;
  }

  let privateData = vessel.master_id
    ? timestamps.find(ts => ts.ship.master_id === vessel.master_id.toString())
    : undefined;
  const timestampList = [];

  if (!privateData && dataFromOtherNamespaces) {
    Object.keys(dataFromOtherNamespaces).forEach(key => {
      if (!privateData && vessel.master_id) {
        privateData = dataFromOtherNamespaces[key].find(ts => ts.ship.master_id === vessel.master_id.toString());
        if (privateData) {
          privateData.portcalls[0].events.forEach(event => {
            event.timestamps.forEach(ts => timestampList.push(ts));
          });
        }
      }
    });
  }

  const addtionalTimestampsTitles = timestamps => {
    if (timestamps.length) {
      return timestamps.map(timestamp =>
        Object.entries(timestamp).map(([key]) => {
          if (supportedTimestamps.includes(key)) {
            return (
              <Row biggerSpace={true} key={`timestamp-key-${key}`}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                {timestamp.direction ? (
                  <IconText>{t('{{key}} {{direction}}', { key, direction: timestamp.direction })}</IconText>
                ) : (
                  <IconText>{t(key)}</IconText>
                )}
              </Row>
            );
          }
        })
      );
    }
    return null;
  };

  const addtionalTimestampsDatas = timestamps => {
    if (timestamps.length) {
      return timestamps.map(timestamp =>
        Object.entries(timestamp).map(([key, value]) => {
          if (supportedTimestamps.includes(key)) {
            return (
              <Row biggerSpace={true} key={`timestamp-value-${key}`}>
                <IconText2>:</IconText2>
                <Tooltip
                  title={timestamp.source ? timestamp.source : '-'}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                >
                  <IconText>
                    <SemiboldPart>{dayjs(value).format('DD.MM.YYYY')} </SemiboldPart>
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(value).format('HH:mm')}</BoldPart>
                  </IconText>
                </Tooltip>
              </Row>
            );
          }
        })
      );
    }
    return null;
  };

  let pilotages = {};
  if (vessel.data?.timestamps?.length) {
    vessel.data.timestamps.forEach(stamp => {
      let pilotKey = Object.keys(stamp).find(key => ['RPS', 'PPS', 'APS', 'APC'].includes(key));
      if (pilotKey) {
        const inbound = stamp.direction === 'inbound';
        pilotages[pilotKey + (inbound ? 'in' : 'out')] = {
          ...stamp,
          date: stamp[pilotKey],
        };
      }
    });
  }

  return (
    <Container>
      <DataContainer>
        <HeaderRow>
          <Header>{t('Decorated data')}</Header>
          <Legend>
            <IconContainer type="public-data" style={{ marginRight: '12px' }} />
            <LegendText style={{ marginRight: '16px' }}>{t('Public')}</LegendText>
            <IconContainer type="private-data" style={{ marginRight: '12px', color: '#4990DD' }} />
            <LegendText style={{ color: '#4990DD' }}>{t('Private')}</LegendText>
          </Legend>
        </HeaderRow>
        <Columns>
          <Column>
            <SubColumn>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Vessel')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('IMO')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Agent')}</IconText>
              </Row>
              <Row biggerSpace={true} style={{ height: '40px' }}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Shipping company')}</IconText>
              </Row>
              <Row biggerSpace={false}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('ETA')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                <IconText>{t('ETA')}</IconText>
              </Row>
              <Row biggerSpace={false}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('ETD')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                <IconText>{t('ETD')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                <IconText>{t('PTA')}</IconText>
              </Row>
              {(vessel.ata || privateData?.ship?.ata) && (
                <Row biggerSpace={false}>
                  <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                  <IconText>{t('ATA')}</IconText>
                </Row>
              )}
              {(vessel.ata || privateData?.ship?.ata) && (
                <Row biggerSpace={true}>
                  <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                  <IconText>{t('ATA')}</IconText>
                </Row>
              )}
              {(vessel.atd || privateData?.ship?.atd) && (
                <Row biggerSpace={false}>
                  <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                  <IconText>{t('ATD')}</IconText>
                </Row>
              )}
              {(vessel.atd || privateData?.ship?.atd) && (
                <Row biggerSpace={true}>
                  <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                  <IconText>{t('ATD')}</IconText>
                </Row>
              )}
              {addtionalTimestampsTitles(vessel.data?.timestamps || [])}

              <Row biggerSpace={true}>
                <IconText style={{ fontWeight: 600, whiteSpace: 'nowrap', marginTop: '12px' }}>
                  {t('Inbound pilotages')}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Requested')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Ordered')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Commenced')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px', marginBottom: '18px' }} />
                <IconText>{t('Completed')}</IconText>
              </Row>

              <Row biggerSpace={true}>
                <IconText style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{t('Outbound pilotages')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Requested')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Ordered')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Commenced')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px', marginBottom: '18px' }} />
                <IconText>{t('Completed')}</IconText>
              </Row>

              <Row biggerSpace={true}>
                <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Berth')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="private-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Bollards')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Length')}</IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconContainer type="public-data" style={{ marginRight: '12px' }} />
                <IconText>{t('Draft')}</IconText>
              </Row>
            </SubColumn>
            <SubColumn
              style={{
                overflow: 'hidden',
                maxWidth: '148px',
              }}
            >
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <SemiboldPart>
                  <IconText>{vessel.data.vesselName}</IconText>
                </SemiboldPart>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <SemiboldPart>
                  <IconText>{vessel.imo}</IconText>
                </SemiboldPart>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <Tooltip
                  title={vessel.agent ? vessel.agent[0] : '-'}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                >
                  <IconText
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '160px',
                      fontWeight: 600,
                    }}
                  >
                    {vessel.agent ? vessel.agent[0] : '-'}
                  </IconText>
                </Tooltip>
              </Row>
              <Row biggerSpace={true} style={{ height: '40px', lineHeight: '62px' }}>
                <IconText2>:</IconText2>
                <Tooltip
                  title={vessel.shipping_company ? vessel.shipping_company[0] : '-'}
                  color="white"
                  overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
                >
                  <IconText
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '180px',
                    }}
                  >
                    <SemiboldPart>{vessel.shipping_company ? vessel.shipping_company[0] : '-'}</SemiboldPart>
                  </IconText>
                </Tooltip>
              </Row>
              <Row biggerSpace={false}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>{dayjs(vessel.eta).format('DD.MM.YYYY')} </SemiboldPart>
                  <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(vessel.eta).format('HH:mm')}</BoldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {privateData?.ship?.current_eta ? dayjs(privateData.ship.current_eta).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  <BoldPart style={{ paddingLeft: '8px' }}>
                    {privateData?.ship?.current_eta ? dayjs(privateData.ship.current_eta).format('HH:mm') : ''}
                  </BoldPart>
                </IconText>
              </Row>
              <Row biggerSpace={false}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>{dayjs(vessel.etd).format('DD.MM.YYYY')} </SemiboldPart>
                  <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(vessel.etd).format('HH:mm')}</BoldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {privateData?.ship?.current_etd ? dayjs(privateData.ship.current_etd).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  <BoldPart style={{ paddingLeft: '8px' }}>
                    {privateData?.ship?.current_etd ? dayjs(privateData.ship.current_etd).format('HH:mm') : ''}
                  </BoldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {privateData?.ship?.planned_eta ? dayjs(privateData.ship.planned_eta).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  <BoldPart style={{ paddingLeft: '8px' }}>
                    {privateData?.ship?.planned_eta ? dayjs(privateData.ship.planned_eta).format('HH:mm') : ''}
                  </BoldPart>
                </IconText>
              </Row>
              {(vessel.ata || privateData?.ship?.ata) && (
                <Row biggerSpace={false}>
                  <IconText2>:</IconText2>
                  <IconText>
                    <SemiboldPart>{dayjs(vessel.ata).format('DD.MM.YYYY')} </SemiboldPart>
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(vessel.ata).format('HH:mm')}</BoldPart>
                  </IconText>
                </Row>
              )}
              {(vessel.ata || privateData?.ship?.ata) && (
                <Row biggerSpace={true}>
                  <IconText2>:</IconText2>
                  <IconText>
                    <SemiboldPart>
                      {privateData?.ship?.ata ? dayjs(privateData.ship.ata).format('DD.MM.YYYY') : '-'}{' '}
                    </SemiboldPart>
                    <BoldPart style={{ paddingLeft: '8px' }}>
                      {privateData?.ship?.ata ? dayjs(privateData.ship.ata).format('HH:mm') : ''}
                    </BoldPart>
                  </IconText>
                </Row>
              )}
              {(vessel.atd || privateData?.ship?.atd) && (
                <Row biggerSpace={false}>
                  <IconText2>:</IconText2>
                  <IconText>
                    <SemiboldPart>{dayjs(vessel.atd).format('DD.MM.YYYY')} </SemiboldPart>
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(vessel.atd).format('HH:mm')}</BoldPart>
                  </IconText>
                </Row>
              )}
              {(vessel.atd || privateData?.ship?.atd) && (
                <Row biggerSpace={true}>
                  <IconText2>:</IconText2>
                  <IconText>
                    <SemiboldPart>
                      {privateData?.ship?.atd ? dayjs(privateData.ship.atd).format('DD.MM.YYYY') : '-'}{' '}
                    </SemiboldPart>
                    <BoldPart style={{ paddingLeft: '8px' }}>
                      {privateData?.ship?.atd ? dayjs(privateData.ship.atd).format('HH:mm') : ''}
                    </BoldPart>
                  </IconText>
                </Row>
              )}
              {addtionalTimestampsDatas(vessel.data?.timestamps ? vessel.data.timestamps : [])}

              <Row biggerSpace={true} style={{ marginTop: '12px', height: '25px' }}></Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.RPSin ? dayjs(pilotages.RPSin.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.RPSin ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.RPSin.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.PPSin ? dayjs(pilotages.PPSin.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.PPSin ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.PPSin.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.APSin ? dayjs(pilotages.APSin.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.APSin ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.APSin.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.APCin ? dayjs(pilotages.APCin.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.APCin ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.APCin.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>

              <Row biggerSpace={true} style={{ marginTop: '12px', height: '25px' }}></Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.RPSout ? dayjs(pilotages.RPSout.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.RPSout ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.RPSout.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.PPSout ? dayjs(pilotages.PPSout.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.PPSout ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.PPSout.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.APSout ? dayjs(pilotages.APSout.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.APSout ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.APSout.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>
              <Row biggerSpace={true} style={{ marginBottom: '17px' }}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {pilotages.APCout ? dayjs(pilotages.APCout.date).format('DD.MM.YYYY') : '-'}{' '}
                  </SemiboldPart>
                  {pilotages.APCout ? (
                    <BoldPart style={{ paddingLeft: '8px' }}>{dayjs(pilotages.APCout.date).format('HH:mm')}</BoldPart>
                  ) : null}
                </IconText>
              </Row>

              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart style={{ whiteSpace: 'nowrap' }}>{privateData?.ship?.berth_name || '-'}</SemiboldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>
                    {(privateData?.ship?.start_bollard || privateData?.ship?.end_bollard) && t('Bollards')}{' '}
                    {privateData?.ship?.start_bollard || ''}-{privateData?.ship?.end_bollard || ''}
                  </SemiboldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>{vessel?.data?.loa || '-'}</SemiboldPart>
                </IconText>
              </Row>
              <Row biggerSpace={true}>
                <IconText2>:</IconText2>
                <IconText>
                  <SemiboldPart>{vessel?.data?.draft || '-'}</SemiboldPart>
                </IconText>
              </Row>
            </SubColumn>
          </Column>
        </Columns>
      </DataContainer>
    </Container>
  );
};

export default VesselInfoModal;
