import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../ui/Icon';
import { apiCallFake } from './InfoTooltipData';

const Container = styled.div`
  color: ${({ theme }) => theme.color.grey_dark};
  max-width: 280px;
  min-width: 200px;
  min-height: 90px;
  padding: 6px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  color: ${({ theme }) => theme.color.secondary};

  svg {
    height: ${props => (props.small ? '14px' : '16px')};
    width: ${props => (props.small ? '14px' : '16px')};
    margin-left: ${props => (props.small ? '-1px' : '-2px')};
    margin-top: ${props => (props.small ? '-3px' : '-10px')};
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 -14px;
  padding-left: 14px;
  padding-bottom: 10px;
  font-weight: 600;
`;

const Row = styled.div`
  margin: 8px 0;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const InfoTooltip = ({ textKey, small = false, direction = 'top' }) => {
  const [data, setData] = useState(undefined);

  const CreateTooltip = () => {
    return data ? (
      <Container>
        <Title>{data.title}</Title>
        {data.rows.map((row, index) => (
          <Row key={index}>{row}</Row>
        ))}
      </Container>
    ) : (
      <Container>
        <Loader>
          <Spin size="medium" />
        </Loader>
      </Container>
    );
  };

  const onOpenChange = async open => {
    if (open) {
      const result = await apiCallFake('get', 'info', { key: textKey });
      if (result?.status === 200) {
        setData(result.data);
      }
    } else {
      setData(undefined);
    }
  };

  return (
    <Tooltip title={CreateTooltip()} onOpenChange={onOpenChange} color="white" placement={direction}>
      <IconContainer small={small}>
        <Icon type="circle-question-regular"></Icon>
      </IconContainer>
    </Tooltip>
  );
};

export default InfoTooltip;
