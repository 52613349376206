import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT, TIME_FORMAT_DAY, TIME_FORMAT_HOURS_MINUTES } from '../../utils/constants';
import Icon from '../ui/Icon';
import Tooltip from 'antd/es/tooltip';
import IncidentHistoryItemMap from './IncidentHistoryItemMap';
import dayjs from 'dayjs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  padding: 3px;
  border-radius: 3px;
  margin-bottom: 12px;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  height: 30px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }
`;

const Circle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 12px;
  margin-top: 1px;
  margin-left: 3px;
  border: 2px solid #4a4a4a;
  background-color: #4a4a4a;
`;

const Icons = styled.div`
  margin-left: 18px;
  display: flex;
`;

const IconContainer = styled.div`
  i {
    margin-right: 10px;
    margin-top: ${props => (props.negMargin ? '-2px' : '2px')};
  }

  svg {
    margin-top: -3px;
    height: 12px;
    width: 12px;
  }
`;

const AddIconContainer = styled(IconContainer)`
  svg {
    color: #82c484;
  }
`;

const EditIconContainer = styled(IconContainer)`
  svg {
    color: #83a8e6;
  }
`;

const DeleteIconContainer = styled(IconContainer)`
  svg {
    color: #d4675b;
  }
`;

const ItemSet = styled.div`
  padding: 3px;
  margin-bottom: 6px;
  margin-top: 12px;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #e8e8e8;
`;

const Data = styled.div`
  margin-top: 8px;
  display: flex;
  flex-flow: wrap;
`;

const DataPair = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 3px;
  font-size: 13px;
  white-space: nowrap;
  margin-right: 24px;
`;

const DataPairVessels = styled(DataPair)`
  white-space: wrap;
  margin-top: 4px;
`;

const Name = styled.div`
  margin-right: 6px;
  white-space: nowrap;
`;

const Value = styled.div`
  font-weight: 600;
`;

const ListOfVessels = styled.div`
  margin-top: -2px;
`;

const SelectedVessel = styled.div`
  border-radius: 4px;
  padding: 2px 6px;
  border: 1px solid #b1b1b1;
  display: inline-flex;
  margin-right: 6px;
  margin-bottom: 2px;
`;

const TextArea = styled.div`
  text-align: left;
  white-space: pre-line;
  font-weight: 600;
`;

const Details = styled.div``;

const IncidentHistoryItem = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [itemOpen, setItemOpen] = useState(false);

  const isObject = object => {
    return object != null && typeof object === 'object' && !Array.isArray(object);
  };

  const lists = [];

  if (isObject(data.data.added)) {
    lists.push('added');
  }
  if (isObject(data.data.updated)) {
    lists.push('updated');
  }
  if (isObject(data.data.deleted)) {
    lists.push('deleted');
  }

  return (
    <Container>
      <TopRow onClick={() => setItemOpen(!itemOpen)}>
        <Circle />
        {dayjs(data.updated_at).format(TIME_FORMAT_DAY)}
        <span style={{ fontWeight: 600, paddingLeft: '6px' }}>
          {dayjs(data.updated_at).format(TIME_FORMAT_HOURS_MINUTES)}
        </span>
        <Icons>
          {isObject(data.data.added) && (
            <Tooltip title={t('Data added')} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <AddIconContainer>
                <Icon type="state-added" />
              </AddIconContainer>
            </Tooltip>
          )}

          {isObject(data.data.updated) && (
            <Tooltip title={t('Data updated')} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <EditIconContainer>
                <Icon type="state-updated" />
              </EditIconContainer>
            </Tooltip>
          )}

          {isObject(data.data.deleted) && (
            <Tooltip title={t('Data deleted')} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <DeleteIconContainer>
                <Icon type="state-deleted" />
              </DeleteIconContainer>
            </Tooltip>
          )}
        </Icons>
        <span style={{ fontStyle: 'italic', paddingRight: '6px', marginLeft: 'auto', fontSize: '13px' }}>
          {data.created_by_email}
        </span>
      </TopRow>
      {itemOpen && (
        <Details>
          {lists.map((item, index) => (
            <ItemSet key={index}>
              <Title>
                {item === 'added' ? (
                  <AddIconContainer negMargin={true}>
                    <Icon type="state-added" />
                  </AddIconContainer>
                ) : item === 'updated' ? (
                  <EditIconContainer negMargin={true}>
                    <Icon type="state-updated" />
                  </EditIconContainer>
                ) : (
                  <DeleteIconContainer negMargin={true}>
                    <Icon type="state-deleted" />
                  </DeleteIconContainer>
                )}
                {t('Data ' + item)}
              </Title>
              <Data>
                {!!data.data[item].level_of_emergency && (
                  <DataPair>
                    <Name>{t('Level of emergency')}:</Name>
                    <Value>{data.data[item].level_of_emergency}</Value>
                  </DataPair>
                )}
                {(!!data.data[item].people_in_danger || data.data[item].people_in_danger === false) && (
                  <DataPair>
                    <Name>{t('People in danger')}:</Name>
                    <Value>{data.data[item].people_in_danger ? t('Yes') : t('No')}</Value>
                  </DataPair>
                )}
                {(!!data.data[item].vessels_involved || data.data[item].vessels_involved === false) && (
                  <DataPair>
                    <Name>{t('Vessels involved')}:</Name>
                    <Value>{data.data[item].vessels_involved ? t('Yes') : t('No')}</Value>
                  </DataPair>
                )}
                {(!!data.data[item].visible_to_authority || data.data[item].visible_to_authority === false) && (
                  <DataPair>
                    <Name>{t('Visible to authority')}:</Name>
                    <Value>{data.data[item].visible_to_authority ? t('Yes') : t('No')}</Value>
                  </DataPair>
                )}
                {!!data.data[item].incident_type_name && (
                  <DataPair>
                    <Name>{t('Incident type')}:</Name>
                    <Value>{data.data[item].incident_type_name}</Value>
                  </DataPair>
                )}
                {!!data.data[item].location_option_name && (
                  <DataPair>
                    <Name>{t('Location')}:</Name>
                    <Value>{data.data[item].location_option_name}</Value>
                  </DataPair>
                )}
                {!!data.data[item].date && (
                  <DataPair>
                    <Name>{t('Date')}:</Name>
                    <Value>{dayjs(data.data[item].date).format(TIME_FORMAT)}</Value>
                  </DataPair>
                )}
                {!!data.data[item].end_date && (
                  <DataPair>
                    <Name>{t('End date')}:</Name>
                    <Value>{dayjs(data.data[item].end_date).format(TIME_FORMAT)}</Value>
                  </DataPair>
                )}
                {!!data.data[item].status && (
                  <DataPair>
                    <Name>{t('Status')}:</Name>
                    <Value>{data.data[item].status}</Value>
                  </DataPair>
                )}
                {'auto_close_ts' in data.data[item] && (
                  <DataPair>
                    <Name>{t('Close automatically')}:</Name>
                    <Value>
                      {data.data[item].auto_close_ts
                        ? dayjs(data.data[item].auto_close_ts).format(TIME_FORMAT)
                        : t('No')}
                    </Value>
                  </DataPair>
                )}
              </Data>

              {!!data.data[item]?.data?.vessels && Array.isArray(data.data[item]?.data?.vessels) && (
                <DataPairVessels>
                  <Name>{t('Vessels')}:</Name>
                  <ListOfVessels>
                    {data.data[item].data.vessels.map((vessel, i) => (
                      <SelectedVessel key={i}>
                        {vessel.name} ({vessel.imo})
                      </SelectedVessel>
                    ))}
                  </ListOfVessels>
                </DataPairVessels>
              )}

              {!!data.data[item]?.data?.vessels && !Array.isArray(data.data[item]?.data?.vessels) && (
                <DataPairVessels>
                  <Name>{t('Vessels')}:</Name>
                  <ListOfVessels>
                    {Object.keys(data.data[item].data.vessels).map((vesselNumber, i) => (
                      <SelectedVessel key={i}>
                        {data.data[item].data.vessels[vesselNumber].name} (
                        {data.data[item].data.vessels[vesselNumber].imo})
                      </SelectedVessel>
                    ))}
                  </ListOfVessels>
                </DataPairVessels>
              )}
              {!!data.data[item].details && (
                <DataPair>
                  <Name>{t('Details')}:</Name>
                  <TextArea>{data.data[item].details}</TextArea>
                </DataPair>
              )}
              {!!data.data[item]?.data?.geojson && (
                <IncidentHistoryItemMap
                  geojson={data.data[item]?.data?.geojson}
                  level={data.data[item].level_of_emergency}
                  item={item}
                  type={data.data[item].type}
                />
              )}
            </ItemSet>
          ))}
        </Details>
      )}
    </Container>
  );
};
export default IncidentHistoryItem;
