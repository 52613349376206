import React from 'react';
import styled from 'styled-components';
import ServiceOrderInfoTable from './ServiceOrderInfoTable';

const Container = styled.div`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  color: #4a4a4a;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-left: ${props => props.padding && '16px'};
  padding: 16px;
`;

const FleetHelpText = styled.div`
  margin-top: 24px;
  font-size: 13px;
`;

const ServiceOrderLine = styled.div`
  border-radius: 2px;
  height: 10px;
  width: 4px;
  margin-right: 4px;
  margin-left: 2px;
`;

const ServiceOrderCircle = styled.div`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
  padding: 0px 2px;
  color: white;
  font-weight: 700;
  line-height: 7px;
  padding-left: 3px;
  font-size: 8px;
`;

/* states: requested = 1, acknowledged = 2, delivered = 3 */

const ServiceOrderTooltip = (requested, acknowledged, delivered, t, fleetHelpText) => {
  const dataSource = [];
  const pilotDataSource = [];

  requested
    .sort((a, b) => {
      return a.service_type_name.localeCompare(b.service_type_name);
    })
    .forEach(re => {
      const orderLine = {
        key: re.id,
        services: re.service_type_name,
        state: 1,
        cancelled: re.is_cancelled,
        when: re.when,
        requested_delivery_time: re.requested_delivery_time,
        original_start_time: re.data?.external_data?.original_start_time,
        start_time: re.data?.external_data?.start_time,
        time1: re.created_at,
        quantity: re.quantity,
      };
      if (re.data?.service_external_type === 'finnpilot') {
        pilotDataSource.push(orderLine);
      } else {
        dataSource.push(orderLine);
      }
    });

  acknowledged
    .sort((a, b) => {
      return a.service_type_name.localeCompare(b.service_type_name);
    })
    .forEach(re => {
      const orderLine = {
        key: re.id,
        services: re.service_type_name,
        state: 2,
        cancelled: re.is_cancelled || re.ack?.status === 'rejected',
        when: re.when,
        requested_delivery_time: re.requested_delivery_time,
        original_start_time: re.data?.external_data?.original_start_time,
        start_time: re.data?.external_data?.start_time,
        time1: re.created_at,
        time2: re.ack?.created_at,
        quantity: re.quantity,
      };
      if (re.data?.service_external_type === 'finnpilot') {
        pilotDataSource.push(orderLine);
      } else {
        dataSource.push(orderLine);
      }
    });

  delivered
    .sort((a, b) => {
      return a.service_type_name.localeCompare(b.service_type_name);
    })
    .forEach(re => {
      const orderLine = {
        key: re.id,
        services: re.service_type_name,
        state: 3,
        cancelled: re.is_cancelled || re.ack?.status === 'rejected',
        when: re.when,
        requested_delivery_time: re.requested_delivery_time,
        delivery_time: re.deliveries?.[0]?.delivery_time,
        original_start_time: re.data?.external_data?.original_start_time,
        start_time: re.data?.external_data?.start_time,
        time1: re.created_at,
        time2: re.ack?.created_at,
        time3: re.deliveries?.[0]?.created_at,
        delivery_quantity: re.deliveries?.[0]?.quantity,
        quantity: re.quantity,
      };
      if (re.data?.service_external_type === 'finnpilot') {
        pilotDataSource.push(orderLine);
      } else {
        dataSource.push(orderLine);
      }
    });

  return (
    <Container padding={fleetHelpText}>
      <ServiceOrderInfoTable otherData={dataSource} pilotData={pilotDataSource} />
      {fleetHelpText && !!t && (
        <FleetHelpText>
          <table>
            <tbody>
              <tr>
                <td>
                  <div style={{ display: 'flex', marginRight: '12px' }}>
                    <ServiceOrderLine style={{ backgroundColor: '#407505' }} />
                  </div>
                </td>
                <td>{t('Service order has delivery time or requested delivery time')}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: 'flex', marginRight: '12px' }}>
                    <ServiceOrderCircle style={{ backgroundColor: '#407505' }}>!</ServiceOrderCircle>
                  </div>
                </td>
                <td>{t('Service order has delivery time or requested delivery time before ETA or after ETD')}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ display: 'flex', marginRight: '12px' }}>
                    <ServiceOrderCircle style={{ backgroundColor: '#407505' }} />
                  </div>
                </td>
                <td>{t('Service order does not have delivery time or requested delivery time')}</td>
              </tr>
            </tbody>
          </table>
        </FleetHelpText>
      )}
    </Container>
  );
};

export default ServiceOrderTooltip;
