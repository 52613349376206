import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { InvoicingContext } from '../../context/InvoicingContext';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import AddShipboardWasteExceptionModal from './AddShipboardWasteExceptionModal';
import Popconfirm from 'antd/es/popconfirm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  color: #4a4a4a;
`;

const DeleteButton = styled.span`
  display: none;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.color.warning};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  &:hover {
    ${DeleteButton} {
      display: initial;
    }
  }
`;

const AddNew = styled.div`
  padding: 20px;
  font-weight: 550;
  cursor: pointer;
  &:hover {
    border-color: ${props => !props.disabled && props.theme.color.secondary};
    color: ${props => !props.disabled && props.theme.color.secondary};
  }
`;

const StyledShipInfo = styled.dl`
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizing.gap_medium};
  padding: 20px;
`;

const VesselName = styled.dt`
  width: 40%;
  font-weight: 550;
  text-transform: uppercase;
`;

const VesselNameItems = styled.dt`
  width: 40%;
`;

const Imo = styled.dd`
  width: 30%;
  margin-left: auto;
  font-weight: 550;
  text-transform: uppercase;
`;

const ImoItems = styled.dd`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 30%;
  margin-left: auto;
  gap: 10px;
`;

const WasteExceptionValidTs = styled.dd`
  width: 30%;
  font-weight: 550;
  text-transform: uppercase;
`;

const WasteExceptionValidTsItems = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 30%;
  margin-left: auto;
  gap: 10px;
`;

const WasteException = () => {
  const { namespace } = useContext(UserContext);
  const { wasteExceptionData, postWasteException } = useContext(InvoicingContext);
  const { t } = useTranslation(namespace);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleRemove = vessel => {
    const validUntilTs = dayjs('1900-01-01T00:00:00.000Z');
    postWasteException(vessel.imo, validUntilTs);
  };

  return (
    <Container>
      <Header>{t('Shipboard waste exception')}</Header>
      <StyledShipInfo>
        <Row style={{ display: 'flex' }}>
          {/* eslint-disable-next-line quotes */}
          <VesselName>{t("Vessel's name")}</VesselName>
          <Imo>{t('IMO')}</Imo>
          <WasteExceptionValidTs>{t('Valid until')}</WasteExceptionValidTs>
        </Row>
        {wasteExceptionData.map((vessel, index) => (
          <Row key={index} style={{ display: 'flex' }}>
            <VesselNameItems>{vessel.name}</VesselNameItems>
            <ImoItems>{vessel.imo}</ImoItems>
            <WasteExceptionValidTsItems>
              {vessel.private_data?.waste_exception
                ? dayjs(vessel.private_data.waste_exception).format('DD.MM.YYYY')
                : 'N/A'}
              <Popconfirm
                title={t('Are you sure you want to remove the exception?')}
                onConfirm={() => handleRemove(vessel)}
                okText={t('Yes')}
                okType="danger"
                cancelText={t('No')}
                icon={null}
                id="pop-confirm-for-new-list"
                key="action-5"
              >
                <DeleteButton>
                  <Icon type={'trash'} />
                </DeleteButton>
              </Popconfirm>
            </WasteExceptionValidTsItems>
          </Row>
        ))}
      </StyledShipInfo>
      <AddNew onClick={() => setIsModalVisible(true)}>
        <Icon type="plus" style={{ fontSize: '20px' }} />
        <span style={{ marginLeft: 6 }}>{t('Add new')}</span>
      </AddNew>
      <AddShipboardWasteExceptionModal open={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </Container>
  );
};

export default WasteException;
