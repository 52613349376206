import { useContext, useEffect, useRef, useState } from 'react';
import L, { Control } from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet';
import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import { useLeafletContext } from '@react-leaflet/core';
import '../../../../node_modules/leaflet-draw/dist/leaflet.draw.css';
import circleToPolygon from 'circle-to-polygon';

const DrawControlsInner = () => {
  const {
    drawAssets,
    newAssetLayer,
    setNewAssetLayer,
    newAssetPoints,
    setNewAssetPoints,
    rightPanelData,
    rightPanelOpen,
    newAssetStyle,
    newAssetCoords,
    styleUpdate,
    setRightPanelData,
    setRightPanelOpen,
    setDraggableAssets,
    setDeleteAssets,
    currentNamespace,
    setNewRotatedCoords,
    newAssetRotation,
    editPointsInCreate,
    setNewAssetCoords,
    setEditPointsInCreate,
  } = useContext(GeoAssetToolContext);
  const drawRef = useRef();
  const context = useLeafletContext();

  const [drawControlCreated, setDrawControlCreated] = useState(false);

  const map = useMap();

  const _onCreated = e => {
    if (newAssetLayer) map.removeLayer(newAssetLayer.layer);
    setRightPanelOpen(false);
    setRightPanelData({});
    setEditPointsInCreate(false);
    setNewRotatedCoords(null);
    let type = e.layerType;
    let layer = e.layer;
    const style = layer.options;
    if (type === 'circle') {
      map.removeLayer(layer);
      const numOfEdges =
        parseInt(layer.options.radius) > 12
          ? parseInt(layer.options.radius) > 40
            ? 40
            : parseInt(layer.options.radius)
          : 6 + parseInt(layer.options.radius);
      const coords = layer.getLatLng();
      const newCircle = circleToPolygon([coords.lng, coords.lat], parseInt(layer.options.radius), {
        numberOfEdges: numOfEdges,
      });
      let transformedCoords = [];
      const circleCoords = newCircle.coordinates.flat();
      for (let index = 0; index < circleCoords.length; index++) {
        const element = circleCoords[index];
        transformedCoords.push([element[1], element[0]]);
      }
      transformedCoords.push([circleCoords[0][1], circleCoords[0][0]]);
      drawAssets([transformedCoords], 'Polygon', style, false, true, {
        isCircle: true,
        center: coords,
        segments: numOfEdges,
      });
    } else if (type === 'polygon' || type === 'polyline') {
      setNewAssetLayer(e);
      const coords = layer.getLatLngs().flat();
      let transformedCoords = [];
      for (let index = 0; index < coords.length; index++) {
        const element = coords[index];
        transformedCoords.push([element.lat, element.lng]);
      }
      if (type === 'polygon') {
        transformedCoords.push([coords[0].lat, coords[0].lng]);
      }
      drawAssets(
        type === 'polygon' ? [transformedCoords] : transformedCoords,
        type === 'polyline' ? 'LineString' : 'Polygon',
        style
      );
    } else if (type === 'rectangle') {
      setNewAssetLayer(e);
      const coords = layer.getLatLngs().flat();
      let transformedCoords = [];
      for (let index = 0; index < coords.length; index++) {
        const element = coords[index];
        transformedCoords.push([element.lat, element.lng]);
      }
      drawAssets([transformedCoords], 'Polygon', style);
    }
  };

  const onRotate = e => {
    newAssetLayer.layer.setLatLngs(e.layer.getLatLngs());
    const coords = e.layer.getLatLngs().flat();
    let transformedCoords = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      transformedCoords.push([element.lat, element.lng]);
    }
    setNewRotatedCoords(transformedCoords);
  };

  const onScale = e => {
    newAssetLayer.layer.setLatLngs(e.layer.getLatLngs());
    const coords = e.layer.getLatLngs().flat();
    let transformedCoords = [];
    for (let index = 0; index < coords.length; index++) {
      const element = coords[index];
      transformedCoords.push([element.lat, element.lng]);
    }
    setNewRotatedCoords(transformedCoords);
  };

  useMapEvents({
    zoomend: () => {
      if (newAssetLayer) {
        const northEast = map.latLngToLayerPoint(newAssetLayer.layer.getBounds()._northEast);
        const southWest = map.latLngToLayerPoint(newAssetLayer.layer.getBounds()._southWest);
        if (Math.abs(northEast.x - southWest.x) < 10 || Math.abs(northEast.y - southWest.y) < 10) {
          newAssetLayer.layer?.transform?.disable();
        } else {
          newAssetLayer.layer.transform.enable({ rotation: true, scaling: true });
        }
      }
    },
  });

  const onDragEnd = (e, i) => {
    const newPoint = e.target.getLatLng();
    const oldCoords = newAssetLayer.layer.getLatLngs().flat();
    const coordsList = [];
    for (let index = 0; index < oldCoords.length; index++) {
      const element = oldCoords[index];
      if (index === i) {
        coordsList.push([newPoint.lat, newPoint.lng]);
      } else {
        coordsList.push([element.lat, element.lng]);
      }
    }
    setNewAssetCoords(coordsList);
  };

  useEffect(() => {
    if (newAssetLayer) {
      newAssetLayer.layer.off('rotateend');
      newAssetLayer.layer.off('scaleend');
      newAssetLayer.layer?.transform?.disable();
      map.removeLayer(newAssetLayer.layer);

      if (newAssetPoints) {
        map.removeLayer(newAssetPoints);
      }

      if (!rightPanelData.newAsset) {
        setNewAssetLayer(null);
      } else {
        newAssetLayer.layer.options = { ...newAssetLayer.layer.options, ...newAssetStyle };
        if (
          newAssetLayer.layerType === 'circle' &&
          typeof newAssetCoords[0] === 'number' &&
          typeof newAssetCoords[1] === 'number'
        ) {
          newAssetStyle.radius && newAssetLayer.layer.setRadius(newAssetStyle.radius);
          if (newAssetCoords.length > 1) {
            newAssetLayer.layer.setLatLng({ lat: newAssetCoords[0], lng: newAssetCoords[1] });
          }
        } else if (
          newAssetCoords.length > 1 &&
          newAssetLayer.layerType !== 'circle' &&
          typeof newAssetCoords[0] === 'object'
        ) {
          const setCoords = [];
          for (let index = 0; index < newAssetCoords.length; index++) {
            const coord = newAssetCoords[index];
            setCoords.push({ lat: coord[0], lng: coord[1] });
          }
          if (newAssetRotation) {
            newAssetLayer.layer.setLatLngs(setCoords);
          } else {
            if (newAssetLayer.layerType === 'polyline') {
              newAssetLayer.layer = L.polyline(setCoords, {
                ...newAssetLayer.layer.options,
                ...newAssetStyle,
                transform: true,
              });
            } else {
              newAssetLayer.layer = L.polygon(setCoords, {
                ...newAssetLayer.layer.options,
                ...newAssetStyle,
                transform: true,
              });
            }
          }
          if (editPointsInCreate) {
            newAssetLayer.layer?.transform?.disable();
            const coords = newAssetLayer.layer.getLatLngs().flat();
            const points = coords.map((c, index) => {
              return L.circleMarker(c, {
                ...newAssetLayer.layer.options,
                ...newAssetStyle,
                fill: true,
                draggable: true,
              }).on('dragend', e => onDragEnd(e, index));
            });
            const group = L.featureGroup(points);
            setNewAssetPoints(group);
            map.addLayer(group);
          } else {
            if (newAssetPoints) {
              map.removeLayer(newAssetPoints);
              setNewAssetPoints(null);
            }
          }
        } else {
          newAssetLayer.layer.setStyle = { ...newAssetLayer.layer.options, ...newAssetStyle };
        }
        map.addLayer(newAssetLayer.layer);
        if (!editPointsInCreate) {
          newAssetLayer.layer.transform?.enable({ rotation: true, scaling: true });
          newAssetLayer.layer.on('rotateend', onRotate);
          newAssetLayer.layer.on('scaleend', onScale);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData, rightPanelOpen, newAssetStyle, styleUpdate, newAssetCoords, editPointsInCreate]);

  map.on('draw:drawstart', function() {
    setDraggableAssets(false);
    setDeleteAssets(false);
    const drawTypeList = ['polygon', 'polyline', 'circle'];
    for (let index = 0; index < drawTypeList.length; index++) {
      const element = drawTypeList[index];
      try {
        const elementList = document.getElementsByClassName(`leaflet-draw-draw-${element}`);
        if (elementList.length > 0) {
          elementList[0].style.backgroundColor = '#FFF';
        }
      } catch {
        console.error('draw:drawstart failed.');
      }
    }
    const enabled = document.getElementsByClassName('leaflet-draw-toolbar-button-enabled')[0];
    if (enabled) {
      enabled.style.backgroundColor = '#4990DD';
    }

    if (newAssetLayer) {
      newAssetLayer.layer.off('rotateend');
      newAssetLayer.layer.off('scaleend');
      newAssetLayer.layer?.transform?.disable();
      map.removeLayer(newAssetLayer.layer);
      setNewAssetLayer(null);
    }
  });

  map.on('draw:drawstop', function() {
    const drawTypeList = ['polygon', 'polyline', 'circle', 'rectangle'];
    for (let index = 0; index < drawTypeList.length; index++) {
      const element = drawTypeList[index];
      try {
        const leafletElements = document.getElementsByClassName(`leaflet-draw-draw-${element}`);
        if (leafletElements?.length > 0) {
          leafletElements[0].style.backgroundColor = '#FFF';
        }
      } catch {
        console.error('draw:drawstop failed.');
      }
    }
  });

  useEffect(() => {
    if (currentNamespace && !drawControlCreated) {
      setDrawControlCreated(true);
      map.on('draw:created', _onCreated);

      drawRef.current = createDrawElement(context);
      map.addControl(drawRef.current);
    }

    return () => {
      map.off('draw:created', _onCreated);

      drawRef.current?.remove(map);
    };
  }, [currentNamespace]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default DrawControlsInner;

function createDrawElement(context) {
  const { layerContainer } = context;
  const options = {
    edit: {
      edit: false,
      remove: false,
      featureGroup: layerContainer,
    },
  };

  options.draw = {
    polyline: {
      icon: new L.DivIcon({
        iconSize: new L.Point(7, 7),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      shapeOptions: {
        color: 'navy',
        weight: 2,
      },
      guidelineDistance: 10,
    },
    polygon: {
      icon: new L.DivIcon({
        iconSize: new L.Point(7, 7),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      shapeOptions: {
        guidelineDistance: 10,
        color: 'navy',
        weight: 2,
      },
    },
    circle: {
      shapeOptions: {
        color: 'navy',
        weight: 2,
        radius: 2,
        fillOpacity: 0.6,
        opacity: 0.6,
      },
    },
    circlemarker: false,
    marker: false,
    rectangle: {
      icon: new L.DivIcon({
        iconSize: new L.Point(7, 7),
        className: 'leaflet-div-icon leaflet-editing-icon',
      }),
      shapeOptions: {
        guidelineDistance: 10,
        color: 'navy',
        weight: 2,
      },
    },
  };

  options.position = 'topright';

  return new Control.Draw(options);
}
