import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';
import FleetDataViewTable from '../components/fleetView/FleetDataViewTable';

const AlteredPage = styled(PageWithoutHeaderAndMargins)`
  padding: 1.5rem;
`;

const FleetDataViewContainer = styled.div`
  display: flex;
`;

const FleetDataView = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Fleet Data View | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Fleet Data View')}>
      <AlteredPage fullWidth>
        <FleetDataViewContainer>
          <FleetDataViewTable />
        </FleetDataViewContainer>
      </AlteredPage>
    </Layout>
  );
};

export default FleetDataView;
