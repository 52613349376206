import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import ServiceProviderUsers from './ServiceProviderUsers';
import DateComponent from '../../components/ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const ServiceProvidersList = styled.div`
  .ant-table {
    table {
      padding-bottom: 0 !important;
    }
    .ant-table-content {
      overflow: visible;
    }
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const ExternalType = styled.div`
  display: flex;
  justify-content: center;
`;

const ExternalTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div`
  margin-right: 12px;
  margin-left: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service-provider';

const ServiceProviders = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [serviceProvidersData, setServiceProvidersData] = useState(null);
  const [serviceProvidersError, setServiceProvidersError] = useState(null);
  const [serviceProvidersLoading, setServiceProvidersLoading] = useState(null);
  const [externalTypes, setExternalTypes] = useState([]);

  const { message } = App.useApp();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initServiceProvider = {
    id: null,
    name: '',
    email: '',
    phone: null,
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
    external_type: null,
  };

  const [serviceProvider, setServiceProvider] = useState(initServiceProvider);

  const initModal = {
    visible: false,
    confirmLoading: false,
    serviceProvider: {
      id: null,
      name: '',
      email: '',
      phone: null,
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
      external_type: null,
    },
  };

  const [modal, setModal] = useState(initModal);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'name',
    search: '',
  };

  const providersChanged = useCallback(data => {
    if (data?.results) {
      setServiceProvidersData(data.results);
    } else {
      setServiceProvidersData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const {
    loading: serviceProvidersFetchLoading,
    error: serviceProvidersFetchError,
    fetchData: serviceProvidersFetchData,
  } = useApi('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, newParams, providersChanged, false);

  const {
    loading: serviceProvidersSearchLoading,
    error: serviceProvidersSearchError,
    fetchData: serviceProvidersFetchSearchData,
  } = useApi('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/search`, {}, providersChanged, false);

  useEffect(() => {
    const { search, ...rest } = newParams;
    if (search) {
      const [order_by_field, order_by_order] = newParams.sort.split(' ');
      serviceProvidersFetchSearchData(false, {
        query: {
          text: newParams.search,
        },
        pagination: {
          limit: newParams.limit,
          offset: newParams.offset,
        },
        order_by: [
          {
            field: order_by_field,
            order: order_by_order ? order_by_order : 'ASC',
          },
        ],
      });
    } else {
      serviceProvidersFetchData(false, rest);
    }
  }, [newParams, serviceProvidersFetchData, serviceProvidersFetchSearchData]);

  useEffect(() => {
    setServiceProvidersError(serviceProvidersFetchError);
  }, [serviceProvidersFetchError]);

  useEffect(() => {
    setServiceProvidersError(serviceProvidersSearchError);
  }, [serviceProvidersSearchError]);

  useEffect(() => {
    setServiceProvidersLoading(serviceProvidersFetchLoading);
  }, [serviceProvidersFetchLoading]);

  useEffect(() => {
    setServiceProvidersLoading(serviceProvidersSearchLoading);
  }, [serviceProvidersSearchLoading]);

  if (serviceProvidersError) {
    message.error(serviceProvidersError, 4);
  }

  const fetchRelatedTypeData = useCallback(async () => {
    setApiCallPending(true);
    let data = [];
    const result = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/related-type-data`, {});
    setApiCallPending(false);
    if (result?.status === 200 && result?.data) {
      data = result.data?.['external-types'] || [];
    }
    setExternalTypes(data);
  }, [apiCall]);

  useEffect(() => {
    fetchRelatedTypeData();
  }, [fetchRelatedTypeData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serviceProviders =
    serviceProvidersLoading || !serviceProvidersData?.['service-providers']
      ? []
      : serviceProvidersData['service-providers'];

  const { offset, total } =
    serviceProvidersError || !serviceProvidersData ? { offset: 0, total: 0 } : serviceProvidersData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const {
        data: { 'service-provider': provider },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
      setServiceProvider({
        ...provider,
        external_type: provider.data?.external_type || null,
      });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await serviceProvidersFetchData(false, defaultParams);
  };

  const handleSave = async values => {
    const { name, email, phone, external_type } = values;
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
      service_provider: {
        name: name,
        email: email,
        phone: phone,
        external_type: external_type || null,
      },
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setServiceProvider(initServiceProvider);
      handleSetMultiple(initServiceProvider);
      history.replace(location.pathname);
      serviceProvidersFetchData();
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setServiceProvider(initServiceProvider);
    handleSetMultiple(initServiceProvider);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service_provider: {
          id: serviceProvider.id,
          name: serviceProvider.name ? serviceProvider.name : null,
          email: serviceProvider.email ? serviceProvider.email : null,
          phone: serviceProvider.phone ? serviceProvider.phone : null,
          external_type: serviceProvider.external_type || null,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setServiceProvider(initServiceProvider);
    handleSetMultiple(initServiceProvider);
    history.replace(location.pathname);
    await serviceProvidersFetchData(false, defaultParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setServiceProvider(initServiceProvider);
    handleSetMultiple(initServiceProvider);
  };

  const handleModalChange = e => {
    const { name, value } = e.target;
    setServiceProvider({ ...serviceProvider, [name]: value });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setServiceProvider({ ...serviceProvider, [name]: value });
  };

  const getFinnpilotIndicator = () => {
    return (
      <ExternalTypeContainer>
        <Tooltip placement="top" title={t('Finnpilot service')}>
          <IconContainer>
            <Icon type="finnpilot-logo" color="#4A4A4A" />
          </IconContainer>
        </Tooltip>
        {'Finnpilot'}
      </ExternalTypeContainer>
    );
  };

  const fields = ['name', 'email', 'phone', 'external_type'];
  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {});

  useEffect(() => {
    let isValid =
      (serviceProvider?.email?.length > 0 || serviceProvider?.phone?.length > 0) && serviceProvider?.name?.length > 0
        ? true
        : false;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, serviceProvider]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sortableKey: 'name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sortableKey: 'email',
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      key: 'phone',
      sortableKey: 'phone',
    },
    {
      title: t('External type'),
      dataIndex: ['data', 'external_type'],
      key: 'external_type',
      sortableKey: 'external_type',
      render: record => <ExternalType>{record === 'finnpilot' ? getFinnpilotIndicator() : record}</ExternalType>,
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sortableKey: 'updated_at',
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete service provider {{name}}?', { name: record.name })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: serviceProvidersLoading,
      text: t('Add service provider'),
      icon: 'plus',
    },
  ];

  return (
    <ServiceProvidersList>
      <Modal
        title={t('Edit services')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="name"
              label={t('Name')}
              value={serviceProvider.name ? serviceProvider.name : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="email"
              label={t('Email')}
              value={serviceProvider.email ? serviceProvider.email : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
              required
            />
            <Input
              name="phone"
              label={t('Phone')}
              value={serviceProvider.phone ? serviceProvider.phone : ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <InputContainer>
              <InputLabel>{t('External type for provider')}</InputLabel>
              <Select
                allowClear
                placeholder={t('Undefined')}
                optionFilterProp="label"
                onChange={value =>
                  handleModalChange({
                    target: {
                      value: value,
                      name: 'external_type',
                    },
                  })
                }
                style={{ width: '100%' }}
                options={externalTypes.map(option => {
                  return {
                    label: option.name,
                    value: option.value,
                  };
                })}
                value={serviceProvider.external_type}
              />
            </InputContainer>
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service provider')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'external_type') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('External type for provider')}</InputLabel>
                    <Select
                      allowClear
                      placeholder={t('Undefined')}
                      optionFilterProp="label"
                      onChange={value =>
                        handleAddNewChange({
                          target: {
                            value: value,
                            name: field,
                          },
                        })
                      }
                      style={{ width: '100%' }}
                      options={externalTypes.map(option => {
                        return {
                          label: option.name,
                          value: option.value,
                        };
                      })}
                      value={values[field]}
                    />
                  </InputContainer>
                );
              } else {
                return (
                  <Input
                    label={field.replace(/_/g, ' ')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="text"
                    onChange={handleAddNewChange}
                  />
                );
              }
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service provider')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={serviceProviders}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={serviceProvidersLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={offset}
        total={total}
        searchPlaceHolder={t('Search by name, email or phone')}
        additionalButtons={additionalButtons}
        expandedRowRender={record => <ServiceProviderUsers provider={record} />}
      />
    </ServiceProvidersList>
  );
};

export default ServiceProviders;
