import React, { useContext, useRef, useState, useEffect } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../context/UserContext';

import useToggle from '../../hooks/useToggle';
import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import PageActionForm from '../../components/page/PageActionForm';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import Select from '../../components/ui/Select';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Label from '../../components/ui/Label';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const WindowCalendar = styled.div`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const Nominations = () => {
  const { apiCall, namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);
  const [apiCallPending, setApiCallPending] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Nominations | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'vessel_name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'own-nominations', newParams);
  const { loading: berthsLoading, data: berthsData, error: berthsError } = useApi('get', 'all-nominatable-berths', {});

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const nominations = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  nominations.forEach(p => {
    p._row = start + counter;
    counter++;
    p.berths = p.berth_names.join();
  });

  const fetchedNominatableBerths = berthsError || !berthsData ? [] : berthsData;
  let nominatableBerths = [...fetchedNominatableBerths];
  nominatableBerths.unshift({ id: 0, name: 'Not selected' });
  counter = 1;
  nominatableBerths.forEach(p => {
    p._row = counter;
    counter++;
    p.key = p.id;
    p.label = p.name;
    p.value = p.id;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const initModal = {
    visible: false,
    confirmLoading: false,
    datesDirty: false,
    berthsDirty: false,
  };

  const [modal, setModal] = useState(initModal);

  const initNomination = {
    id: null,
    company_name: '',
    email: '',
    imo: '',
    vessel_name: '',
    window_start: '',
    window_end: '',
    first_berth: '',
    second_berth: '',
  };

  const [nomination, setNomination] = useState(initNomination);

  const showModal = async id => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', 'own-nomination-by-id', { id });
      let first_berth = data['berth_ids'][0] === undefined ? '0' : data['berth_ids'][0];
      let second_berth = data['berth_ids'][1] === undefined ? '0' : data['berth_ids'][1];
      let window_start = dayjs.utc(dayjs(data['window_start'])).format('YYYY-MM-DDTHH:mm:ss+00:00');
      let window_end = dayjs.utc(dayjs(data['window_end'])).format('YYYY-MM-DDTHH:mm:ss+00:00');
      setStartDate(dayjs(data['window_start']).toDate());
      setEndDate(dayjs(data['window_end']).toDate());
      setNomination({ ...data, first_berth, second_berth, window_start, window_end });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    let berth_ids = [];
    if (nomination.first_berth !== '0') {
      berth_ids.push(nomination.first_berth);
    }
    if (nomination.second_berth !== '0') {
      berth_ids.push(nomination.second_berth);
    }
    setApiCallPending(true);
    try {
      await apiCall('put', 'own-nominations', {
        id: nomination.id,
        company_name: nomination.company_name,
        email: nomination.email,
        imo: nomination.imo,
        vessel_name: nomination.vessel_name,
        ...(modal.datesDirty ? { window_start: nomination.window_start } : {}),
        ...(modal.datesDirty ? { window_end: nomination.window_end } : {}),
        ...(modal.berthsDirty ? { berth_ids: berth_ids } : {}),
      });
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    resetDates();
    let params = newParams;
    await fetchData(false, params);
  };

  const handleModalCancel = () => {
    setModal(initModal);
    resetDates();
  };

  const handleModalChange = e => {
    if (e.target.name === 'first_berth' || e.target.name === 'second_berth') {
      modal.berthsDirty = true;
    }

    setNomination({ ...nomination, [e.target.name]: e.target.value });
  };

  const handleSave = async values => {
    const { company_name, email, imo, vessel_name, window_start, window_end, first_berth, second_berth } = values;
    let berth_ids = [];
    if (first_berth !== '0') {
      berth_ids.push(first_berth);
    }
    if (second_berth !== '0') {
      berth_ids.push(second_berth);
    }
    setApiCallPending(true);
    try {
      await apiCall('post', 'own-nominations', {
        company_name,
        email,
        imo,
        vessel_name,
        window_start,
        window_end,
        berth_ids,
      });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    showActions(false);
    resetDates();

    let params = newParams;
    await fetchData(false, params);
  };

  const fields = [
    'company_name',
    'email',
    'imo',
    'vessel_name',
    'window_start',
    'window_end',
    'first_berth',
    'second_berth',
  ];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave, {
    first_berth: '0',
    second_berth: '0',
    window_start: dayjs.utc(dayjs().set({ second: 0, minute: 0, hour: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00'),
    window_end: dayjs.utc(dayjs().set({ second: 59, minute: 59, hour: 23 })).format('YYYY-MM-DDTHH:mm:ss+00:00'),
  });

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    resetDates();
  };

  const handleDelete = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', 'own-nominations', { id });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const resetDates = () => {
    setStartDate(dayjs().toDate());
    setEndDate(dayjs().toDate());
    values.window_start = dayjs.utc(dayjs().set({ second: 0, minute: 0, hour: 0 })).format('YYYY-MM-DDTHH:mm:ss+00:00');
    values.window_end = dayjs
      .utc(dayjs().set({ second: 59, minute: 59, hour: 23 }))
      .format('YYYY-MM-DDTHH:mm:ss+00:00');
  };

  const handleWindowStartChange = e => {
    setStartDate(e);
    let startMoment = dayjs(e);
    startMoment.set({ second: 0, minute: 0, hour: 0 });
    if (modal.visible) {
      modal.datesDirty = true;
      nomination.window_start = dayjs.utc(startMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    } else {
      values.window_start = dayjs.utc(startMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const handleWindowEndChange = e => {
    setEndDate(e);
    let endMoment = dayjs(e);
    endMoment.set({ second: 59, minute: 59, hour: 23 });
    if (modal.visible) {
      modal.datesDirty = true;
      nomination.window_end = dayjs.utc(endMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    } else {
      values.window_end = dayjs.utc(endMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Company name'),
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('Window start'),
      dataIndex: 'window_start',
      key: 'window_start',
      sortableKey: 'window_start',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Window end'),
      dataIndex: 'window_end',
      key: 'window_end',
      sortableKey: 'window_end',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Berths'),
      dataIndex: 'berths',
      key: 'berths',
    },
    {
      title: t('Status'),
      dataIndex: 'readable_nomination_status',
      key: 'readable_nomination_status',
      sortableKey: 'readable_nomination_status',
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={
            !user.permissions.includes('manage_own_queue_nomination') || record.readable_nomination_status !== 'Open'
          }
          key="action-1"
          onClick={() => showModal(record.id)}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete nomination {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              red
              disabled={
                !user.permissions.includes('manage_own_queue_nomination') ||
                record.readable_nomination_status !== 'Open'
              }
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: loading || berthsLoading || !user.permissions.includes('manage_own_queue_nomination'),
      text: t('Add new nomination'),
      icon: 'plus',
    },
  ];

  return (
    <Layout pagename={t('Nominations')}>
      <Modal
        title={t('Edit nomination {{id}}', { id: nomination.id })}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <Input
              name="company_name"
              label="company name"
              value={nomination.company_name}
              onChange={handleModalChange}
            />
            <Input name="email" label="email" value={nomination.email} onChange={handleModalChange} />
            <Input name="imo" label="imo" value={nomination.imo} onChange={handleModalChange} />
            <Input name="vessel_name" label="vessel_name" value={nomination.vessel_name} onChange={handleModalChange} />
            <WindowCalendar>
              <Label>{t('Window start')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 5 }}
                style={{
                  width: '100%',
                }}
                value={startDate ? dayjs(startDate) : null}
                onChange={handleWindowStartChange}
                minDate={dayjs().toDate()}
                maxDate={dayjs()
                  .add(1, 'month')
                  .toDate()}
              />
            </WindowCalendar>
            <WindowCalendar>
              <Label>{t('Window end')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 5 }}
                style={{
                  width: '100%',
                }}
                value={endDate ? dayjs(endDate) : null}
                onChange={handleWindowEndChange}
                minDate={dayjs().toDate()}
                maxDate={dayjs()
                  .add(1, 'month')
                  .toDate()}
              />
            </WindowCalendar>
            <Select
              value={nomination.first_berth}
              label="First berth"
              name="first_berth"
              options={nominatableBerths}
              onChange={handleModalChange}
            />
            <Select
              value={nomination.second_berth}
              label="Second berth"
              name="second_berth"
              options={nominatableBerths}
              onChange={handleModalChange}
            />
          </Spin>
        </ModalInner>
      </Modal>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <div ref={actionsRef}>
          <PageActionForm
            title={t('Add new nomination')}
            icon="plus"
            show={actions}
            style={{ top: '70px', right: '10px' }}
          >
            <Form autoComplete="off" onSubmit={handleSubmit}>
              {fields.map((field, index) => {
                if (field === 'first_berth' || field === 'second_berth') {
                  return (
                    <Select
                      value={values.field}
                      label={field.replace(/_/g, ' ')}
                      key={field}
                      name={field}
                      options={nominatableBerths}
                      onChange={handleChange}
                    />
                  );
                } else if (field === 'window_start') {
                  return (
                    <WindowCalendar key={index}>
                      <Label>{t('Window start')}</Label>
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 5 }}
                        style={{
                          width: '100%',
                        }}
                        value={startDate ? dayjs(startDate) : null}
                        onChange={handleWindowStartChange}
                        minDate={dayjs().toDate()}
                        maxDate={dayjs()
                          .add(1, 'month')
                          .toDate()}
                      />
                    </WindowCalendar>
                  );
                } else if (field === 'window_end') {
                  return (
                    <WindowCalendar key={index}>
                      <Label>{t('Window end')}</Label>
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 5 }}
                        style={{
                          width: '100%',
                        }}
                        value={endDate ? dayjs(endDate) : null}
                        onChange={handleWindowEndChange}
                        minDate={startDate}
                        maxDate={dayjs()
                          .add(1, 'month')
                          .toDate()}
                      />
                    </WindowCalendar>
                  );
                } else {
                  return (
                    <Input
                      label={field.replace(/_/g, ' ')}
                      key={field}
                      name={field}
                      field={field}
                      value={values.field}
                      onChange={handleChange}
                    />
                  );
                }
              })}
              <FormActions>
                <Button link>{t('Add nomination')}</Button>
                <Button link onClick={e => handleCancel(e)}>
                  {t('Cancel')}
                </Button>
              </FormActions>
            </Form>
          </PageActionForm>
        </div>
        <List
          rowKey="id"
          columns={columns}
          dataSource={nominations}
          apiCallPending={apiCallPending}
          actions={actionList}
          spinning={loading || berthsLoading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search')}
          additionalButtons={additionalButtons}
        />
      </Page>
    </Layout>
  );
};

export default Nominations;
