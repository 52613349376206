import React from 'react';
import styled from 'styled-components';

import Label from './Label';

const InputWrapper = styled.div`
  margin-bottom: ${props => (props.inline ? 0 : props.theme.sizing.gap)};
`;

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.color.grey_light};
  border-radius: ${({ theme }) => theme.style.border_radius};
  padding: ${({ theme }) => theme.sizing.gap_small};
  color: ${({ theme }) => theme.color.grey_dark};
  background: ${({ theme }) => theme.color.white};
  min-width: 180px;
  min-height: 2.8rem;
  max-width: 100%;
  margin-left: 2px;
  &:disabled {
    border: none;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(73, 144, 221, 0.2);
    border: 1px solid rgba(73, 144, 221, 0.7);
  }
  &:focus-visible {
    outline: none;
  }
  ::placeholder {
    color: #bfbfbf;
  }
`;

const Info = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey_light};
  margin-top: ${({ theme }) => theme.sizing.gap_tiny};
  margin-left: ${({ theme }) => theme.sizing.gap_small};
`;

const Input = ({ label, info, ...props }) => (
  <InputWrapper inline={props.inline} style={props.wrapperStyle ? props.wrapperStyle : undefined}>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <StyledInput {...props} />
    {info && <Info>{info}</Info>}
  </InputWrapper>
);

export default Input;
