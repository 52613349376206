import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

import { CalendarRow } from './CalendarRow';

const RowLines = styled.div`
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
`;

const scrollAreaLimit = 100;

export const CalendarRows = ({ scrollTop, scrollLeft, onScrollByDragHorizontally, draggingValue }) => {
  const {
    berths = [],
    calendarVessels,
    draggingArea,
    keepVesselLocation,
    keepUpcomingVessel,
    setDraggingRowInfo,
    berthsOpen,
    berthAreasAvailable,
  } = useContext(BerthPlanningToolContext);

  let draggingInRow;

  const [timer1On, setTimer1On] = useState(false);
  const [timer2On, setTimer2On] = useState(false);
  let timeInterval1 = useRef();
  let timeInterval2 = useRef();

  useEffect(() => {
    if (draggingArea && !draggingArea.new) {
      let index = berths.findIndex(g => draggingArea.top >= g.startHeight && draggingArea.top < g.endHeight);
      if (index > 0) {
        setDraggingRowInfo({
          height: berths[index - 1] && berths[index - 1].subRows[0] ? berths[index - 1].subRows[0].length : 0,
          open: berthsOpen[berths[index - 1].id],
        });
      }
    } else if (draggingArea?.new) {
      let index = berths.findIndex(
        g => draggingArea.top + scrollTop >= g.startHeight && draggingArea.top + scrollTop < g.endHeight
      );
      if (index > 0) {
        setDraggingRowInfo({
          height: berths[index - 1] ? berths[index - 1].subRows[0].length : 0,
          open: berthsOpen[berths[index - 1].id],
        });
      }
    }
  }, [berths, draggingArea, berthsOpen, scrollTop, setDraggingRowInfo]);

  if (draggingArea && !draggingArea.new) {
    let index = berths.findIndex(g => draggingArea.top >= g.startHeight && draggingArea.top < g.endHeight);
    draggingInRow = index;
  } else if (draggingArea?.new) {
    let index = berths.findIndex(
      g => draggingArea.top + scrollTop >= g.startHeight && draggingArea.top + scrollTop < g.endHeight
    );
    draggingInRow = index;
  }

  useEffect(() => {
    if (draggingInRow < 0 && draggingArea && draggingArea.update) {
      if (draggingArea.new) {
        keepUpcomingVessel({
          port_call_master_id: draggingArea.port_call_master_id,
        });
      } else {
        keepVesselLocation({
          port_call_master_id: draggingArea.port_call_master_id,
        });
      }
    }
  }, [draggingArea, draggingInRow, keepUpcomingVessel, keepVesselLocation]);

  useEffect(() => {
    const scrollUp = () => {
      const scrollY = draggingArea.new ? -10 : -12;
      onScrollByDragHorizontally(scrollY);
    };

    const scrollDown = () => {
      const scrollY = draggingArea.new ? 10 : 12;
      onScrollByDragHorizontally(scrollY);
    };

    if (!draggingArea && (timer1On || timer2On)) {
      setTimer1On(false);
      setTimer2On(false);
      clearInterval(timeInterval1.current);
      clearInterval(timeInterval2.current);
    } else if (draggingArea) {
      if (draggingArea.new) {
        let middle = (draggingArea.top + draggingArea.bottom) / 2;
        let top = 0;
        let bottom = top + document.querySelector('#outer-component').getBoundingClientRect().height;

        if (middle < top + scrollAreaLimit) {
          if (!timer1On) {
            setTimer1On(true);
            timeInterval1.current = setInterval(scrollUp, 100);

            setTimer2On(false);
            clearInterval(timeInterval2.current);
          }
        } else if (middle > bottom - scrollAreaLimit) {
          if (!timer2On) {
            setTimer2On(true);
            timeInterval2.current = setInterval(scrollDown, 100);

            setTimer1On(false);
            clearInterval(timeInterval1.current);
          }
        } else {
          setTimer1On(false);
          setTimer2On(false);
          clearInterval(timeInterval1.current);
          clearInterval(timeInterval2.current);
        }
      } else {
        let middle = (draggingArea.top + draggingArea.bottom) / 2;
        let height = document.querySelector('#outer-component').getBoundingClientRect().height;

        if (middle < scrollTop + scrollAreaLimit) {
          if (!timer1On) {
            setTimer1On(true);
            timeInterval1.current = setInterval(scrollUp, 100);

            setTimer2On(false);
            clearInterval(timeInterval2.current);
          }
        } else if (middle > scrollTop + height - scrollAreaLimit) {
          if (!timer2On) {
            setTimer2On(true);
            timeInterval2.current = setInterval(scrollDown, 100);

            setTimer1On(false);
            clearInterval(timeInterval1.current);
          }
        } else {
          setTimer1On(false);
          setTimer2On(false);
          clearInterval(timeInterval1.current);
          clearInterval(timeInterval2.current);
        }
      }
    }
  }, [draggingArea, onScrollByDragHorizontally, scrollTop, timer1On, timer2On]);

  useEffect(() => {
    return () => {
      clearInterval(timeInterval1.current);
      clearInterval(timeInterval2.current);
    };
  }, []);

  return (
    <RowLines>
      {berths.map((berth, i) => {
        let vesselsForRow = calendarVessels.filter(v =>
          berthAreasAvailable ? berth.subRows.findIndex(b => b.id === v.berth_code) > -1 : v.berth_code === berth.id
        );
        const open = berthsOpen[berth.id];

        if (berth.id === 'unknown') {
          vesselsForRow = calendarVessels.filter(v => !v.berth_code);
        }

        if (!berth.show) {
          return null;
        }

        return (
          <CalendarRow
            draggingInRow={draggingInRow === i}
            key={i}
            open={open}
            berth={berth}
            vessels={vesselsForRow}
            scrollLeft={scrollLeft}
            scrollTop={scrollTop}
            draggingValue={draggingValue}
            first={i === 0}
          />
        );
      })}
    </RowLines>
  );
};
