import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';

const BG = styled.div`
  position: absolute;
  width: ${props => (props.sideOfPage ? '376px' : '100%')};
  height: ${props => (props.sideOfPage ? 'calc(100vh - 184px)' : 'calc(100% - 55px)')};
  z-index: 100;
  background-color: rgba(240, 240, 240, 0.65);
`;

const Confirmation = styled.div`
  position: absolute;
  padding: 24px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  max-width: 300px;
  text-align: center;
  top: 40px;
  left: calc((100% - 300px) / 2);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = styled(ButtonLight)`
  height: 26px;
  line-height: 22px;
  padding: 0;
`;

const WikiListSelect = ({ availableWikis, selectMarkdown, setWikiListOpen, sideOfPage }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [page, setPage] = useState(null);

  return (
    <BG sideOfPage={sideOfPage}>
      <Confirmation>
        {t('Pick an existing page that you want to link this item to.')}
        <Select
          showSearch
          listHeight={120}
          placeholder={t('Pages')}
          optionFilterProp="label"
          onChange={value => setPage(value)}
          style={{ width: '100%', marginTop: '12px', textAlign: 'left' }}
          options={availableWikis.map(g => {
            return { label: g.title, value: g.id };
          })}
          value={page}
          allowClear={true}
          onClear={() => setPage(null)}
        />
        <Buttons>
          <Button cancel type="button" onClick={() => setWikiListOpen(false)}>
            {t('Close')}
          </Button>
          <Button
            type="button"
            disabled={!page}
            onClick={() => {
              selectMarkdown(page);
              setWikiListOpen(false);
            }}
          >
            {t('Save')}
          </Button>
        </Buttons>
      </Confirmation>
    </BG>
  );
};

export default WikiListSelect;
