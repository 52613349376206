import React from 'react';
import styled from 'styled-components';
import InvoiceInput from './InvoiceInput';

const Container = styled.div`
  display: flex;
`;

const Middle = styled.div`
  font-size: 16px;
  margin: 0 8px 0 4px;
  padding-top: 6px;
`;

const End = styled.div`
  margin: 0 0 0 8px;
  padding-top: 4px;
`;

const InvoiceAmountInput = ({
  amounts,
  readOnly,
  moveFocus,
  updateRowInput,
  removeMakeFocus,
  makeFocus,
  index,
  templateAmount,
}) => {
  return (
    <Container>
      <InvoiceInput
        key={amounts[0].unit_name}
        onlyNumber={true}
        value={amounts[0].amount}
        updateValue={value => updateRowInput?.(value, 'amount', index, amounts[0].unit_name)}
        width={40}
        makeFocus={makeFocus === 'amount/' + index}
        moveNextFocus={() => moveFocus('amount', index)}
        movePrevFocus={() => moveFocus('amount', index, true)}
        removeMakeFocus={removeMakeFocus}
        readOnly={readOnly}
        useOnlyTextWidth={amounts.length > 1}
        marginTop={true}
        templateAmount={templateAmount}
      />
      {amounts[1] && <Middle>*</Middle>}
      {amounts[1] && (
        <InvoiceInput
          key={amounts[1].unit_name}
          onlyNumber={true}
          value={amounts[1].amount}
          updateValue={value => updateRowInput?.(value, 'amount', index, amounts[1].unit_name)}
          width={40}
          makeFocus={makeFocus === 'second_amount/' + index}
          moveNextFocus={() => moveFocus('second_amount', index)}
          movePrevFocus={() => moveFocus('second_amount', index, true)}
          removeMakeFocus={removeMakeFocus}
          readOnly={readOnly}
          useOnlyTextWidth={amounts.length > 1}
          marginTop={true}
          templateAmount={templateAmount}
        />
      )}
      {amounts[1] && (
        <End>
          {' '}
          = {amounts[0].amount && amounts[1].amount ? Number(amounts[0].amount) * Number(amounts[1].amount) : 0}
        </End>
      )}
    </Container>
  );
};

export default InvoiceAmountInput;
