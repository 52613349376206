import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Popover from 'antd/es/popover';

import { UserContext } from '../../context/UserContext';
import Icon from '../../components/ui/Icon';

const Container = styled.div`
  display: flex;
  position: relative;
  margin-left: 18px;
`;

const Header = styled.div`
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  padding: 3px 6px 4px 6px;
  border-radius: 3px;
  font-weight: 600;
  background-color: ${props => (props.dropDownOpen ? '#f3f3f3' : 'transparent')};

  &:hover {
    background-color: #f8f8f8;
  }
`;

const WarningIcon = styled(Icon)`
  svg {
    margin-top: -2px;
    height: 16px;
    width: 16px;
  }
`;

const Link = styled.div`
  font-size: 13px;
  padding: 6px 18px;
  cursor: pointer;
  color: #4a4a4a;
  background-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const DelayedVessels = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const delayed = [
    {
      name: 'Tellus',
      delay: 5,
    },
    {
      name: 'Tellus 2',
      delay: 15,
    },
    {
      name: 'Tellus 3',
      delay: 35,
    },
    {
      name: 'Ardmore Enterprise',
      delay: 45,
    },
  ];

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#delayed-vessels');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return delayed.length > 0 ? (
    <Container id="delayed-vessels" ref={ref}>
      <Popover
        open={dropDownOpen}
        onOpenChange={setDropDownOpen}
        trigger="click"
        content={delayed.map((b, i) => {
          return (
            <Link key={i}>
              {b.name}{' '}
              <span style={{ color: '#F5BD3E', fontWeight: 700, paddingLeft: '12px', paddingRight: '2px' }}>
                {b.delay}
                {t('h')}
              </span>
              <span style={{ color: '#a8a8a8', paddingLeft: '4px' }}>{t('delay')}</span>
            </Link>
          );
        })}
        placement="bottomLeft"
      >
        <Header>
          <WarningIcon type="vessel-warning" style={{ marginRight: '8px' }} />
          {t('Delayed vessels')} (<span style={{ color: '#F5BD3E', fontWeight: 700 }}>{'5'}</span>)
        </Header>
      </Popover>
    </Container>
  ) : null;
};

export default DelayedVessels;
