import React, { useContext, useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import { TIME_FORMAT, PAGINATION_LIMIT } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import Popconfirm from 'antd/es/popconfirm';
import App from 'antd/es/app';
import Tooltip from 'antd/es/tooltip';
import Spin from 'antd/es/spin';

import Icon from '../../components/ui/Icon';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import DateComponent from '../../components/ui/DateComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.p`
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  margin-right: 4px;
`;

const Content = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey_medium};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const ResponseRow = styled.div`
  background-color: ${props => (props.isEvenRow ? props.theme.color.grey_lighter4 : props.theme.color.white)};
  font-size: ${({ theme }) => theme.text.smaller};
`;

const AdminPreAdviceQueue = () => {
  const { apiCall, namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const { message } = App.useApp();

  const [apiCallPending, setApiCallPending] = useState(false);
  const [entriesData, setEntriesData] = useState(null);
  const [entriesError, setEntriesError] = useState(null);
  const [entriesLoading, setEntriesLoading] = useState(null);

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Pre-Advice Queue | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'requested_trigger_time DESC',
  };

  const entriesChanged = useCallback(data => {
    if (data) {
      setEntriesData(data);
    } else {
      setEntriesData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: entriesFetchLoading, error: entriesFetchError, fetchData: entriesFetchData } = useApi(
    'get',
    'pre-advice-queue/list',
    newParams,
    entriesChanged,
    false
  );

  useEffect(() => {
    entriesFetchData(false, newParams);
  }, [newParams, entriesFetchData]);

  useEffect(() => {
    setEntriesError(entriesFetchError);
  }, [entriesFetchError]);

  useEffect(() => {
    setEntriesLoading(entriesFetchLoading);
  }, [entriesFetchLoading]);

  if (entriesError) {
    message.error(entriesError, 4);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const entries = entriesLoading || !entriesData?.['entries'] ? [] : entriesData['entries'];

  const { offset, total } = entriesError || !entriesData ? { offset: 0, total: 0 } : entriesData.pagination;

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `pre-advice-queue/delete/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    history.replace(location.pathname);
    await entriesFetchData(false, defaultParams);
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Vessel name'),
      key: 'vessel_name',
      render: record => {
        return record.payload?.vessel_name || '';
      },
    },
    {
      title: t('Eta'),
      key: 'eta',
      render: record =>
        record.payload?.eta ? <DateComponent format={TIME_FORMAT} date={record.payload?.eta} /> : t('Not set'),
    },
    {
      title: t('Laytime'),
      key: 'laytime',
      render: record => {
        return record.payload?.laytime || '';
      },
    },
    {
      title: t('Berth'),
      key: 'berth',
      render: record => {
        return (
          <>
            <div>
              <Label>{t('Primary')}</Label>
              <Content>{record.payload?.primary_berth || ''}</Content>
            </div>
            <div>
              <Label>{t('Secondary')}</Label>
              <Content>{record.payload?.secondary_berth || ''}</Content>
            </div>
          </>
        );
      },
    },
    {
      title: t('Vessel info'),
      key: 'vessel',
      render: record => {
        return (
          <>
            <Row>
              <Label>{t('Imo')}</Label> <Content>{record.payload?.imo || ''}</Content>
            </Row>
            <Row>
              <Label>{t('Loa')}</Label> <Content>{record.payload?.loa || ''}</Content>
            </Row>
            <Row>
              <Label>{t('Beam')}</Label> <Content>{record.payload?.beam || ''}</Content>
            </Row>
            <Row>
              <Label>{t('Draft')}</Label> <Content>{record.payload?.draft || ''}</Content>
            </Row>
          </>
        );
      },
    },
    {
      title: t('Requested trigger time'),
      dataIndex: 'requested_trigger_time',
      key: 'requested_trigger_time',
      sortableKey: 'requested_trigger_time',
      render: date => (date ? <DateComponent format={TIME_FORMAT} date={date} /> : t('Not set')),
    },
    {
      title: t('Current trigger time'),
      dataIndex: 'current_trigger_time',
      key: 'current_trigger_time',
      sortableKey: 'current_trigger_time',
      render: date => (date ? <DateComponent format={TIME_FORMAT} date={date} /> : t('Not set')),
    },
    {
      title: t('Success time'),
      dataIndex: 'success_time',
      key: 'success_time',
      sortableKey: 'success_time',
      render: date => (date ? <DateComponent format={TIME_FORMAT} date={date} /> : t('Not set')),
    },
    {
      title: t('Send count'),
      key: 'send_count',
      render: record => {
        const tooltipContent = record.response_history?.length ? (
          <>
            <Content style={{ color: 'black' }}>{t('Response History')}</Content>
            {record.response_history.map((item, i) => (
              <ResponseRow key={`response-row-${i}`} isEvenRow={i % 2 === 0}>
                <div>
                  {t('Time: ')}
                  <DateComponent format={TIME_FORMAT} date={item.time} />
                </div>
                <div>
                  {t('Response: ')}
                  <pre>{JSON.stringify(item.response, null, 2)}</pre>
                </div>
              </ResponseRow>
            ))}
          </>
        ) : null;

        return record.send_count ? (
          <Tooltip title={tooltipContent} color="white" overlayInnerStyle={{ color: '#4a4a4a' }}>
            {record.send_count}
          </Tooltip>
        ) : (
          0
        );
      },
    },
    {
      title: t('Email'),
      key: 'email',
      render: record => {
        return record.payload?.email || '';
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <Popconfirm
          title={t('Are you sure you want to delete entry {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          key="action-1"
          id="pop-confirm-for-new-list"
        >
          <div>
            <ListActionButton
              red
              disabled={
                apiCallPending || !user.permissions.includes('manage_queue_slot_reservation') || record.success_time
              }
            >
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Layout pagename={t('Pre-Advice Queue')}>
      <Spin spinning={apiCallPending}>
        <Page fullWidth>
          <List
            rowKey="id"
            columns={columns}
            dataSource={entries}
            apiCallPending={apiCallPending}
            actions={actionList}
            spinning={entriesLoading}
            setParams={setNewParams}
            newParams={newParams}
            start={offset}
            total={total}
            hideSearch
          />
        </Page>
      </Spin>
    </Layout>
  );
};

export default AdminPreAdviceQueue;
