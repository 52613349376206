import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import ActivityListComponentV2 from '../components/activity/ActivityListComponentV2';

const ActivityListV2 = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Activity List V2 | Port Activity App';
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout filtering pagename={t('Activity List V2')}>
      <Page fullWidth>
        <ActivityListComponentV2 />
      </Page>
    </Layout>
  );
};

export default ActivityListV2;
