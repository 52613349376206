import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../context/UserContext';
import Icon from '../../ui/Icon';
import { TIME_FORMAT } from '../../../utils/constants';

const SvgContainer = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 53;
  height: 1px;

  svg {
    top: 0;
    left: 0;
    position: absolute;
  }
`;

const Dot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #4990dd;
  cursor: pointer;
  z-index: 54;
`;

const Time = styled.div`
  position: absolute;
  z-index: 54;
  padding: 1px 5px 1px 2px;
  background-color: ${props => (props.blue ? '#4990dd' : 'white')};
  border: ${props => (props.blue ? '1px solid #4990dd' : '1px solid #a8a8a8')};
  font-size: 10px;
  font-weight: 600;
  color: ${props => (props.blue ? 'white' : '#a8a8a8')};
  line-height: 11px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;

  svg {
    color: ${props => (props.blue ? 'white' : '#a8a8a8')};
  }
`;

const TooltipContainer = styled.div`
  max-width: 180px;
  height: 64px;
  display: flex;
  font-size: 11px;
  line-height: 20px;
  display: inline;
  color: ${({ theme }) => theme.color.grey};
`;

const Common = styled.div`
  display: flex;
  padding: 0 10px;
`;

const TimeIcon = styled(Icon)`
  svg {
    margin-top: -1px;
    height: 13px;
    width: 13px;
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
  line-height: 21px;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const Value = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.color.grey_dark};
`;

const Calculation = styled.div`
  margin-left: 12px;
`;

const SeaPassageLine = ({
  style,
  current,
  time,
  currentWidth,
  onClick,
  dotted,
  blueTime = false,
  start,
  end,
  comparableTime = null,
  seaPassage,
  passedTime,
  atd = false,
  ata = false,
  nextAta,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const yellowWarning = comparableTime ? comparableTime + (passedTime || 0) + 2 < time : false;
  const redWarning = comparableTime ? comparableTime + (passedTime || 0) > time + 2 : false;

  const SeaPassageToolTip = () => {
    return (
      <TooltipContainer data-testid={'sea-passage-tooltip'}>
        <Common>
          <RowHeaderForTooltip>
            {current || (nextAta && seaPassage && !dotted)
              ? t('Sea passage duration')
              : blueTime
                ? t('Calculated sea passage duration')
                : t('Estimated sea passage duration')}
            : <Value>{time}h</Value>
          </RowHeaderForTooltip>
        </Common>
        {!comparableTime && !current && seaPassage && seaPassage.travel_time_in_hours && !nextAta ? (
          <Common>
            <Calculation>
              <div>
                {t('Distance')}: <Value>{seaPassage.distance_nm}nm</Value>
              </div>
              <div>
                {t('Speed')}: <Value>{seaPassage.speed}nm/h</Value>
              </div>
            </Calculation>
          </Common>
        ) : null}
        {passedTime && !nextAta ? (
          <Common>
            <div>
              {t('Sea passage duration so far')}: <Value>{passedTime}h</Value>
            </div>
          </Common>
        ) : null}
        <Common>
          {comparableTime && !nextAta ? (
            <div>
              <div>
                {t('Calculated sea passage duration')}: <Value>{comparableTime}h</Value>
              </div>
              {seaPassage ? (
                <Calculation>
                  <div>
                    {t('Distance')}: <Value>{seaPassage.distance_nm}nm</Value>
                  </div>
                  <div>
                    {t('Speed')}: <Value>{seaPassage.speed}nm/h</Value>
                  </div>
                </Calculation>
              ) : null}
              {!!yellowWarning && !nextAta && (
                <div style={{ maxWidth: '200px', marginTop: '6px' }}>
                  <TimeIcon type="vessel-warning" style={{ marginRight: '8px' }} />
                  {passedTime ? (
                    <Value>
                      {t(
                        'Passage duration so far combined with calculated duration falls below the estimated duration by over 2 hours.'
                      )}
                    </Value>
                  ) : (
                    <Value>{t('Calculated duration falls below the estimated duration by over 2 hours.')}</Value>
                  )}
                </div>
              )}
              {!!redWarning && !nextAta && (
                <div style={{ maxWidth: '200px', marginTop: '6px' }}>
                  <TimeIcon type="vessel-warning-red" style={{ marginRight: '8px' }} />
                  {passedTime ? (
                    <Value>
                      {t(
                        'Passage duration so far combined with calculated duration exceeds the estimated duration by over 2 hours.'
                      )}
                    </Value>
                  ) : (
                    <Value>{t('Calculated duration exceeds the estimated duration by over 2 hours.')}</Value>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </Common>
        <Common style={{ marginTop: '6px' }}>
          <FirstColumn>
            <RowHeaderForTooltip>
              {blueTime ? t('Current time') : atd ? t('Prev portcall ATD') : t('Prev portcall ETD')}:
            </RowHeaderForTooltip>
            <RowHeaderForTooltip>
              {current
                ? t('Current time')
                : ata
                  ? nextAta
                    ? t('Next portcall ATA')
                    : t('Next portcall calculated ATA')
                  : t('Next portcall ETA')}
              :
            </RowHeaderForTooltip>
          </FirstColumn>
          <FirstColumn>
            <TooltipValue>{start.format(TIME_FORMAT)}</TooltipValue>
            <TooltipValue>{end.format(TIME_FORMAT)}</TooltipValue>
          </FirstColumn>
        </Common>
      </TooltipContainer>
    );
  };

  if (style.width < 0) {
    return null;
  }

  return (
    <Tooltip placement="top" title={SeaPassageToolTip} color="white">
      <SvgContainer
        data-testid="sea-passage-svg-line"
        style={{ top: style.top, left: style.left, width: style.width }}
        onClick={onClick}
      >
        <svg height="1" width={style.width}>
          <g fill="none" stroke="#a8a8a8" strokeWidth="1">
            {dotted ? (
              <path strokeDasharray="2,4" d={'M0 0 l' + style.width + ' 0'} />
            ) : (
              <path d={'M0 0 l' + style.width + ' 0'} />
            )}
          </g>
        </svg>

        {current && (
          <>
            <Dot
              data-testid="sea-passage-current-dot"
              style={{
                top: -2,
                left: currentWidth - 2,
              }}
              onClick={onClick}
            />
            <svg height="2" width={currentWidth}>
              <g fill="none" stroke="#4990dd" strokeWidth="2">
                <path d={'M0 0 l' + currentWidth + ' 0 l0 2 l' + -currentWidth + ' 0 l0 -2'} />
              </g>
            </svg>
          </>
        )}

        {style.width > 70 && (
          <Time
            data-testid="sea-passage-estimated-time"
            style={{
              top: -7,
              left: style.width / 2 - 23,
            }}
            onClick={onClick}
            blue={blueTime}
          >
            {!!yellowWarning && !nextAta && <TimeIcon type="vessel-warning" style={{ marginRight: '8px' }} />}
            {!!redWarning && !nextAta && <TimeIcon type="vessel-warning-red" style={{ marginRight: '8px' }} />}
            {((!yellowWarning && !redWarning) || nextAta) && <TimeIcon type="time" style={{ marginRight: '8px' }} />}
            {time}h
          </Time>
        )}
      </SvgContainer>
    </Tooltip>
  );
};

export default SeaPassageLine;
