import React, { Suspense } from 'react';
import * as Sentry from '@sentry/browser';
import i18n from './i18n'; // eslint-disable-line
import WebFont from 'webfontloader';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import App from 'antd/es/app';
import notification from 'antd/es/notification';
import ConfigProvider from 'antd/es/config-provider';
import { createRoot } from 'react-dom/client';

import { theme } from './theme/vars';
import GlobalStyle from './theme/globalStyle';

import { UserProvider } from './context/UserContext';
import Router from './router';

import Loader from './components/ui/Loader';
import { SENTRY_DSN } from './utils/constants';
import versionData from './version';
import { getEnvironmentName } from './utils/utils';
import { ErrorPlaceHolder } from './components/ui/ErrorPlaceHolder';
import { logError } from './components/ui/errorLogging';

Sentry.init({
  debug: !!SENTRY_DSN && getEnvironmentName(document.location.hostname) === 'development',
  dsn: SENTRY_DSN,
  environment: getEnvironmentName(document.location.hostname),
  release: versionData.hash,
});

Sentry.setTags({
  version: versionData.version,
  build: versionData.build,
  ts: versionData.ts,
});

WebFont.load({
  google: { families: ['Open Sans:400,600,700'] },
});

const Application = () => {
  window.addEventListener('offline', () => {
    notification.error({
      message: 'You are offline',
      description: 'Please connect back to the internet in order to continue using the website',
      placement: 'topLeft',
      duration: 0,
    });
  });
  window.addEventListener('online', () => {
    notification.destroy();
    window.location.reload();
    notification.success({
      message: 'Online status restored',
      placement: 'topLeft',
      duration: 5,
    });
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", "Segoe UI", Tahoma, sans-serif',
          borderRadius: 4,
          colorPrimary: '#4990dd',
          colorLink: '#4990dd',
        },
        components: {
          Tabs: {
            margin: 12,
          },
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense fallback={<Loader />}>
          <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('large')} onError={logError}>
            <App>
              <UserProvider>
                <Router />
              </UserProvider>
            </App>
          </ErrorBoundary>
        </Suspense>
      </ThemeProvider>
    </ConfigProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Application />);
