import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 2px;
`;

const ColorSquare = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 8px;
  position: relative;
`;

const BG = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 5px;
`;

const FullColor = styled.div`
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  opacity: 0.4;
`;

const TextAndAmount = styled.div`
  font-size: 11.5px;
  color: ${({ theme }) => theme.color.grey};
  white-space: nowrap;

  @media (max-width: 1400px) {
    margin-top: 1px;
    font-size: 10.5px;
  }
`;

const ColorIndicatorOverflow = ({ color, text }) => {
  return (
    <Container>
      <ColorSquare>
        <BG color="#ebebeb" />
        <BG color="#ffffff" />
        <FullColor color={color} />
      </ColorSquare>
      <TextAndAmount>{text}</TextAndAmount>
    </Container>
  );
};

export default ColorIndicatorOverflow;
