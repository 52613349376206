import React, { useContext } from 'react';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import { EmissionsContext } from '../../context/EmissionsContext';
import Icon from '../ui/Icon';
import dayjs from 'dayjs';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const UniversalTimePickerContainer = styled.div`
  margin-right: 2rem;
  display: flex;
  margin-top: 4px;

  .ant-select-show-arrow {
    height: 24px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fbfbfb;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: block;
    margin-right: 0rem;
  }
`;

const TimePickers = styled.div`
  display: flex;
  padding-left: 36px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    padding-left: 0px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-left: 6px;
  margin-top: 1px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-bottom: 12px;
  }
`;

const SwitchLabel = styled.div`
  margin-left: 12px;
  line-height: 22px;
  margin-right: 18px;
`;

const Button = styled.div`
  margin: 1px 4px 4px 0;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px 0 0;
  color: ${props => props.disabled && '#e8e8e8'};
  cursor: ${props => props.disabled && 'default'};
  padding-bottom: 8px;

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f3f3f3')};
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const UniversalEmissionsTimePicker = () => {
  const { namespace } = useContext(UserContext);
  const {
    universalTimePickerOn,
    setUniversalTimePickerOn,
    universalTimeType,
    setUniversalTimeType,
    universalSelectedTime,
    setUniversalSelectedTime,
  } = useContext(EmissionsContext);

  const { t } = useTranslation(namespace);

  const moveBackwards = () => {
    if (!universalTimePickerOn) {
      return;
    }

    if (universalTimeType === 'month') {
      const newTime = dayjs(universalSelectedTime)
        .subtract(1, 'month')
        .toDate();
      setUniversalSelectedTime(newTime);
    } else if (universalTimeType === 'year') {
      const newTime = dayjs(universalSelectedTime)
        .subtract(1, 'year')
        .toDate();
      setUniversalSelectedTime(newTime);
    }
  };

  const moveForwards = () => {
    if (!universalTimePickerOn) {
      return;
    }

    if (universalTimeType === 'month') {
      const newTime = dayjs(universalSelectedTime)
        .add(1, 'month')
        .toDate();
      setUniversalSelectedTime(newTime);
    } else if (universalTimeType === 'year') {
      const newTime = dayjs(universalSelectedTime)
        .add(1, 'year')
        .toDate();
      setUniversalSelectedTime(newTime);
    }
  };

  const moveToToday = () => {
    if (!universalTimePickerOn) {
      return;
    }

    setUniversalSelectedTime(dayjs().toDate());
  };

  return (
    <UniversalTimePickerContainer>
      <SwitchContainer>
        <div style={{ marginTop: '-1px' }}>
          <Switch
            size="small"
            checked={universalTimePickerOn}
            onChange={() => setUniversalTimePickerOn(!universalTimePickerOn)}
          />
        </div>
        <SwitchLabel>{t('Common time range')}</SwitchLabel>
      </SwitchContainer>
      <TimePickers>
        <Button disabled={!universalTimePickerOn} onClick={moveToToday} style={{ marginRight: '16px' }}>
          {t('Today')}
        </Button>
        <Button disabled={!universalTimePickerOn} onClick={moveBackwards}>
          <RotatedIcon type="chevron-right-2" />
        </Button>
        <Button disabled={!universalTimePickerOn} onClick={moveForwards} style={{ marginRight: '12px' }}>
          <Icon type="chevron-right-2" />
        </Button>
        <Select
          disabled={!universalTimePickerOn}
          value={universalTimeType}
          style={{ width: 140, marginBottom: '12px' }}
          onChange={e => {
            setUniversalTimeType(e);
          }}
          size="small"
          options={[
            {
              value: 'month',
              label: t('Month'),
            },
            {
              value: 'year',
              label: t('Year'),
            },
          ]}
        />
      </TimePickers>
    </UniversalTimePickerContainer>
  );
};

export default UniversalEmissionsTimePicker;
