import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox';
import Switch from 'antd/es/switch';

import { UserContext } from '../../context/UserContext';
import { TimestampFilteringContext } from '../../context/TimestampFilteringContext';

import ButtonLight from '../ui/ButtonLight';
import { mobilePixelMaxWidthLimit, mobilePixelMinWidthLimit } from '../../utils/constants';

const ModalInnerInput = styled.div`
  min-height: 200px;
  position: absolute;
  padding: 24px;
  top: 46px;
  left: 50px;
  background-color: white;
  z-index: 999;
  width: 600px;
  max-width: calc(100vw - 50px);
  max-height: calc(100vh - 210px);
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.35);

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    left: unset;
    right: 10px;
    top: 56px;
  }
`;

const Header = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 18px;
`;

const ListContainer = styled.div`
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const MobileListContainer = styled.div`
  display: flex;

  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: none;
  }
`;

const FilterButtons = styled.div`
  text-align: left;
  margin-top: 24px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 600px;
  max-height: calc(100vh - 250px);
`;

const SwitchLabelAvailable = styled.div`
  font-size: 14px;
  margin-left: 12px;
`;

const SwitchContainerAvailable = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;

  button {
    margin-top: 3px;
  }
`;

const SwitchLabel = styled.div`
  font-size: 13px;
  margin-left: 12px;
  line-height: 22px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;

  button {
    margin-top: 3px;
  }
`;

const HelpText = styled.div`
  margin-top: 24px;
  max-width: 400px;
`;

const Button = styled(ButtonLight)`
  height: 26px;
  line-height: 22px;
  padding: 0;
`;

const TimestampFilteringModal = ({ closeModal }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    timestampFilteringList,
    updateTimestampFilteringListAvailable,
    updateUserOnOffTimestamps,
    userOnOffTimestamps,
    showAll,
    hideAll,
    resetToDefaults,
  } = useContext(TimestampFilteringContext);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#filter-timestamps-modal');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        closeModal();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeModal]);

  const toggleAvailableTimestamps = () => {
    updateUserOnOffTimestamps(!userOnOffTimestamps);
  };

  const toggleTimestamp = ts => {
    updateTimestampFilteringListAvailable(ts.key, !ts.visible);
  };

  const firstColumn = timestampFilteringList.filter((p, i) => i < timestampFilteringList.length / 2);
  const secondColumn = timestampFilteringList.filter((p, i) => i >= timestampFilteringList.length / 2);

  return (
    <ModalInnerInput id="filter-timestamps-modal" ref={ref}>
      <ScrollContainer>
        <Header>{t('Timestamp filtering')}</Header>
        <SwitchContainerAvailable>
          <div style={{ marginTop: '-2px' }}>
            <Switch size="small" checked={userOnOffTimestamps} onChange={() => toggleAvailableTimestamps()} />
          </div>
          <SwitchLabelAvailable>{t('Filter timestamps')}</SwitchLabelAvailable>
        </SwitchContainerAvailable>
        {userOnOffTimestamps ? (
          <>
            <HelpText>
              {/*eslint-disable quotes*/}
              {t(
                'When timestamp filtering is enabled, the rows that have timestamps and all the checked timestamp types are shown.'
              )}
              {/*eslint-enable quotes*/}
            </HelpText>
            <FilterButtons>
              <Button cancel type="button" onClick={showAll}>
                {t('Select all')}
              </Button>
              <Button cancel type="button" onClick={hideAll}>
                {t('Unselect all')}
              </Button>
              <Button
                cancel
                type="button"
                onClick={resetToDefaults}
                style={{ paddingLeft: '8px', paddingRight: '8px' }}
              >
                {t('Reset to default selection')}
              </Button>
            </FilterButtons>
            <ListContainer>
              <div style={{ marginRight: '100px' }}>
                {firstColumn.map((item, i) => (
                  <SwitchContainer key={i}>
                    <Checkbox size="small" checked={item.visible} onChange={() => toggleTimestamp(item)} />
                    <SwitchLabel>{t(item.key)}</SwitchLabel>
                  </SwitchContainer>
                ))}
              </div>
              <div>
                {secondColumn.map((item, i) => (
                  <SwitchContainer key={i}>
                    <Checkbox size="small" checked={item.visible} onChange={() => toggleTimestamp(item)} />
                    <SwitchLabel>{t(item.key)}</SwitchLabel>
                  </SwitchContainer>
                ))}
              </div>
            </ListContainer>
            <MobileListContainer>
              <div style={{ marginRight: '100px' }}>
                {timestampFilteringList.map((item, i) => (
                  <SwitchContainer key={i}>
                    <Checkbox size="small" checked={item.visible} onChange={() => toggleTimestamp(item)} />
                    <SwitchLabel>{t(item.key)}</SwitchLabel>
                  </SwitchContainer>
                ))}
              </div>
            </MobileListContainer>
          </>
        ) : (
          <HelpText>{t('Enable timestamp filtering above.')}</HelpText>
        )}
      </ScrollContainer>
    </ModalInnerInput>
  );
};

export default TimestampFilteringModal;
