import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import { TIME_FORMAT, TIME_FORMAT_NO_YEAR } from '../../utils/constants';
import ProgressLine from '../fleetView/ProgressLine';
import DateComponent from '../ui/DateComponent';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
`;

const AISContainer = styled.div`
  margin-top: 12px;
`;

const AISText = styled.div`
  font-size: 13px;
  font-weight: 600;
`;

const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

const Subtext = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 11px;
  font-weight: 500;
`;

const PreviousPortTimestamp = styled(DateComponent)``;

const ShipRoutePopover = (
  from,
  to,
  portArea,
  next,
  aisDestination,
  aisETA,
  previousPortTime,
  previousPortTimeLabel
) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const routes = [
    {
      description: from,
    },
    {
      description: to,
      portArea: portArea,
    },
    {
      description: next,
    },
  ];

  return (
    <div style={{ padding: '12px' }}>
      {routes.map((item, index) => (
        <Row key={index}>
          <ProgressLine
            start={index === 0}
            end={index === routes.length - 1}
            thisChecked={false}
            nextChecked={false}
            long={!!previousPortTime && index === 0}
            fill="#a8a8a8"
          />
          <div>
            {item.portArea ? (
              <Text>
                <CapitalizedText>{item.portArea.toLowerCase()}</CapitalizedText>
                {` (${item.description})`}
              </Text>
            ) : (
              <Text>{item.description}</Text>
            )}
            {!!previousPortTime && index === 0 && (
              <Subtext style={{ marginTop: '-6px' }}>
                {previousPortTimeLabel + ' '}
                <PreviousPortTimestamp format={TIME_FORMAT_NO_YEAR} date={previousPortTime} />
              </Subtext>
            )}
          </div>
        </Row>
      ))}
      {!!aisDestination && (
        <AISContainer>
          <AISText>
            {t('AIS Destination')}: {aisDestination || '-'}
          </AISText>
          <AISText>
            {t('AIS ETA')}: {aisETA ? dayjs(aisETA).format(TIME_FORMAT) : '-'}
          </AISText>
        </AISContainer>
      )}
    </div>
  );
};

export default ShipRoutePopover;
