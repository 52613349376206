import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
  width: ${props => (props.small ? '9px' : '11px')};
  height: ${props => (props.small ? '9px' : '11px')};
  border-radius: 50%;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  margin: 2px;
  ${props => {
    if (props.status == 'requested') {
      return `
      background: #F44336;
      `;
    } else if (props.status == 'acknowledged') {
      return `
      background: #F5BD3E;
      `;
    } else if (props.status == 'delivered') {
      return `
      background: #407505;
      `;
    } else if (props.status == 'rejected') {
      return `
      background-color: #D8D8D8;
      `;
    }
  }}
`;

const ServiceDot = ({ status, clickable, small }) => {
  return <Dot status={status} clickable={clickable} small={small} />;
};
export default ServiceDot;
