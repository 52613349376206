import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import InvoiceInput from './invoiceHelps/InvoiceInput';
import InvoiceModalTable from './invoiceHelps/InvoiceModalTable';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import Radio from 'antd/es/radio';
import Tooltip from 'antd/es/tooltip';
import Icon from '../ui/Icon';
import ItemLinker from './invoiceHelps/ItemLinker';

const TopPart = styled.div`
  width: 100%;
  display: flex;
  padding: 6px 0px;
`;

const FirstColumn = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SecondColumn = styled.div`
  width: 40%;
`;

const Row = styled.div`
  display: flex;
`;

const Title = styled.div`
  margin-right: 12px;
`;

const IconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 16px;
    width: 16px;
  }
`;

const LinkIconContainer = styled.span`
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 3px;
  color: ${props => props.disabled && '#a8a8a8'};

  &:hover {
    background-color: ${props => !props.disabled && '#f2f2f2'};
  }

  svg {
    height: 24px;
    width: 24px;
    margin-top: -2px;
  }
`;

const RadioButtons = styled.div`
  margin-top: 12px;
`;

const InfoText = styled.div`
  margin-bottom: 6px;
`;

const DueDateTooltip = styled.div`
  max-width: 200px;
  padding: 12px;
`;

const Required = styled.span`
  color: #ca2323;
`;

const InnerInvoiceModal = ({
  inputData,
  setInputData,
  readOnly,
  totalWithoutTax,
  totalWithTax,
  setRowInputChanged,
  template,
  automated,
  showSmall,
  dueDateInDays,
  setDueDateInDays,
  linksAllowed,
  creditInvoice,
  creditableProducts,
}) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { customers, taxClasses, currencies, invoiceLinks } = useContext(NewInvoicingContext);

  const [itemLinkerCustomerOpen, setItemLinkerCustomerOpen] = useState(false);
  const [itemLinkerCustomerInput, setItemLinkerCustomerInput] = useState(null);

  const [itemLinkerDateOpen, setItemLinkerDateOpen] = useState(false);
  const [itemLinkerDateInput, setItemLinkerDateInput] = useState(null);

  const [deleteOngoing, setDeleteOngoing] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [makeFocus, setMakeFocus] = useState(null);

  const updateInput = (value, title) => {
    let newInputData = { ...inputData };
    newInputData[title] = value;
    setInputData(newInputData);
  };

  const nullDueDates = () => {
    let newInputData = { ...inputData };
    newInputData.due_date = null;
    newInputData.due_date_days = null;
    setInputData(newInputData);
  };

  const updateCustomer = (value, data) => {
    let newInputData = { ...inputData };
    newInputData.customer = value;
    newInputData.customer_data = data;
    setInputData(newInputData);
  };

  const updateRowInput = (value, title, i, amountName) => {
    if (amountName) {
      let newInputRows = [...inputData.rows];
      const index = newInputRows[i].amounts.findIndex(a => a.unit_name === amountName);
      newInputRows[i].amounts[index].amount = value;
      setInputData({ ...inputData, rows: newInputRows });
      setRowInputChanged?.(true);
    } else {
      let newInputRows = [...inputData.rows];
      newInputRows[i][title] = value;
      setInputData({ ...inputData, rows: newInputRows });
      setRowInputChanged?.(true);
    }
  };

  const fieldOrderInvoice = [
    'customer',
    'description',
    'invoice_date',
    'due_date',
    'shipping_date',
    'currency',
    'tax_class',
    'your_reference',
  ];
  const fieldOrderTemplate = ['customer', 'description', 'due_date', 'currency', 'tax_class'];

  const moveGeneralFocus = (name, backwards) => {
    const fieldOrder = template || automated ? fieldOrderTemplate : fieldOrderInvoice;
    const number = fieldOrder.findIndex(f => f === name);

    if (backwards) {
      if (number === 0) {
        if (inputData.rows?.length === 0) {
          setMakeFocus(fieldOrder[fieldOrder.length - 1]);
        } else {
          if (inputData.rows[inputData.rows?.length - 1].empty_row) {
            setMakeFocus('vat/' + (inputData.rows?.length - 1));
          } else {
            const secondAmountAvailable = inputData.rows[inputData.rows?.length - 1].amounts.length === 2;
            if (secondAmountAvailable) {
              setMakeFocus('second_amount/' + (inputData.rows?.length - 1));
            } else {
              setMakeFocus('amount/' + (inputData.rows?.length - 1));
            }
          }
        }
      } else {
        setMakeFocus(fieldOrder[number - 1]);
      }
    } else {
      if (number === fieldOrder.length - 1) {
        if (inputData.rows?.length === 0 || template) {
          setMakeFocus(fieldOrder[0]);
        } else if (automated) {
          setMakeFocus('amount/0');
        } else {
          setMakeFocus('timeframe/0');
        }
      } else {
        setMakeFocus(fieldOrder[number + 1]);
      }
    }
  };

  const moveFocus = (name, index, backwards) => {
    const rowFieldOrder = ['timeframe', 'name', 'code', 'amount', 'second_amount', 'unit_amount', 'unit_unit', 'vat'];
    const rowFieldNumber = rowFieldOrder.findIndex(f => f === name);

    const secondAmountNumber = rowFieldOrder.findIndex(f => f === 'second_amount');
    const amountNumber = rowFieldOrder.findIndex(f => f === 'amount');

    const prevRowEmpty = inputData.rows[index - 1]?.empty_row;
    const currentRowEmpty = inputData.rows[index]?.empty_row;

    const prevRowSecondAmount = inputData.rows[index - 1]?.amounts.length === 2;
    const currentRowSecondAmount = inputData.rows[index]?.amounts.length === 2;

    if (backwards) {
      if (rowFieldNumber === 0) {
        if (index === 0) {
          setMakeFocus(fieldOrderInvoice[fieldOrderInvoice.length - 1]);
        } else {
          if (prevRowEmpty) {
            setMakeFocus(rowFieldOrder[rowFieldOrder.length - 1] + '/' + (index - 1));
          } else {
            if (prevRowSecondAmount) {
              setMakeFocus('second_amount/' + (index - 1));
            } else {
              setMakeFocus('amount/' + (index - 1));
            }
          }
        }
      } else {
        if (rowFieldNumber === secondAmountNumber + 1) {
          if (currentRowSecondAmount) {
            setMakeFocus('second_amount/' + index);
          } else {
            setMakeFocus('amount/' + index);
          }
        } else {
          if (currentRowEmpty) {
            setMakeFocus(rowFieldOrder[rowFieldNumber - 1] + '/' + index);
          } else {
            if (rowFieldNumber === secondAmountNumber) {
              setMakeFocus('amount/' + index);
            } else if (automated) {
              if (index === 0) {
                setMakeFocus(fieldOrderTemplate[fieldOrderTemplate.length - 1]);
              } else {
                setMakeFocus('amount/' + (index - 1));
              }
            } else {
              setMakeFocus('timeframe/' + index);
            }
          }
        }
      }
    } else {
      if (rowFieldNumber === rowFieldOrder.length - 1) {
        if (index === inputData.rows.length - 1) {
          setMakeFocus(fieldOrderInvoice[0]);
        } else {
          setMakeFocus('timeframe/' + (index + 1));
        }
      } else {
        if (currentRowEmpty) {
          if (rowFieldNumber === amountNumber && !currentRowSecondAmount) {
            setMakeFocus(rowFieldOrder[rowFieldNumber + 2] + '/' + index);
          } else {
            setMakeFocus(rowFieldOrder[rowFieldNumber + 1] + '/' + index);
          }
        } else {
          if (rowFieldNumber === 0) {
            setMakeFocus('amount/' + index);
          } else {
            if (rowFieldNumber === amountNumber && currentRowSecondAmount) {
              setMakeFocus('second_amount/' + index);
            } else {
              if (index === inputData.rows.length - 1) {
                setMakeFocus(fieldOrderInvoice[0]);
              }
              if (automated) {
                setMakeFocus('amount/' + (index + 1));
              } else {
                setMakeFocus('timeframe/' + (index + 1));
              }
            }
          }
        }
      }
    }
  };

  const dueDateOptions = [
    {
      value: '1',
      label: '1 ' + t('day'),
    },
    {
      value: '2',
      label: '2 ' + t('days'),
    },
    {
      value: '3',
      label: '3 ' + t('days'),
    },
    {
      value: '4',
      label: '4 ' + t('days'),
    },
    {
      value: '5',
      label: '5 ' + t('days'),
    },
    {
      value: '6',
      label: '6 ' + t('days'),
    },
    {
      value: '7',
      label: '7 ' + t('days'),
    },
    {
      value: '8',
      label: '8 ' + t('days'),
    },
    {
      value: '9',
      label: '9 ' + t('days'),
    },
    {
      value: '10',
      label: '10 ' + t('days'),
    },
    {
      value: '14',
      label: '14 ' + t('days'),
    },
    {
      value: '21',
      label: '21 ' + t('days'),
    },
    {
      value: '28',
      label: '28 ' + t('days'),
    },
    {
      value: '30',
      label: '30 ' + t('days'),
    },
  ];

  const updateCustomerLink = value => {
    let newInputData = { ...inputData };
    newInputData.links = newInputData.links.filter(o => o.parameter !== 'invoice_customer');
    if (value) {
      newInputData.links.push({
        parameter: 'invoice_customer',
        code: value,
      });
    }
    setInputData(newInputData);
  };

  const updateDateLink = value => {
    let newInputData = { ...inputData };
    newInputData.links = newInputData.links.filter(o => o.parameter !== 'invoice_date');
    if (value) {
      newInputData.links.push({
        parameter: 'invoice_date',
        code: value,
      });
    }
    setInputData(newInputData);
  };

  const customerLink = inputData?.links?.find(l => l.parameter === 'invoice_customer');
  const dateLink = inputData?.links?.find(l => l.parameter === 'invoice_date');

  const customerLinkOptions = invoiceLinks.find(i => i.parameter === 'invoice_customer')?.links || [];
  const dateLinkOptions = invoiceLinks.find(i => i.parameter === 'invoice_date')?.links || [];

  const customerLinkName = customerLinkOptions?.find(l => l.code === customerLink?.code)?.name;
  const dateLinkName = dateLinkOptions?.find(l => l.code === dateLink?.code)?.name;

  return (
    <>
      <TopPart>
        <FirstColumn>
          <Row style={{ height: '80px' }}>
            <Title style={{ width: '104px' }}>
              {t('Customer')}
              {!(template || automated) && <Required>*</Required>}:
            </Title>
            <InvoiceInput
              value={inputData.customer || ''}
              updateValue={(value, data) => updateCustomer(value, data)}
              width={200}
              makeFocus={makeFocus === 'customer'}
              moveNextFocus={() => moveGeneralFocus('customer')}
              movePrevFocus={() => moveGeneralFocus('customer', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              select={true}
              options={customers}
              customer={inputData.customer_data}
              readOnly={readOnly || creditInvoice}
              useOnlyTextWidth={linksAllowed}
            />
            {!!customerLink && (
              <Tooltip
                placement="top"
                title={t('Link') + ': ' + customerLinkName}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <IconContainer style={{ marginLeft: '12px' }}>
                  <Icon type="info" />
                </IconContainer>
              </Tooltip>
            )}
            {linksAllowed && user.permissions.includes('manage_invoicing') ? (
              customerLink ? (
                <LinkIconContainer
                  onClick={() => {
                    setItemLinkerCustomerInput(customerLink?.code);
                    setItemLinkerCustomerOpen(true);
                  }}
                >
                  <Icon type="link" />
                </LinkIconContainer>
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    inputData.customer
                      ? t(
                        'You can have either selected customer or a link to get the customer when the invoice is created.'
                      )
                      : t('You can add a link to get the customer when the invoice is created.')
                  }
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <LinkIconContainer
                    onClick={() => {
                      if (!inputData.customer) {
                        setItemLinkerCustomerInput(null);
                        setItemLinkerCustomerOpen(true);
                      }
                    }}
                    disabled={!!inputData.customer}
                    style={{ marginLeft: '12px' }}
                  >
                    <Icon type="unlink" />
                  </LinkIconContainer>
                </Tooltip>
              )
            ) : null}
          </Row>
          <Row>
            <Title style={{ width: '104px' }}>
              {t('Description')}:
              {!readOnly && (
                <Tooltip
                  placement="top"
                  title={t('The description limit is 500 characters.')}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <IconContainer style={{ marginLeft: '6px' }}>
                    <Icon type="info" />
                  </IconContainer>
                </Tooltip>
              )}
            </Title>
            <InvoiceInput
              value={inputData.description || ''}
              updateValue={value => {
                if (value?.length >= 500) {
                  updateInput(value.substring(0, 500), 'description');
                } else {
                  updateInput(value, 'description');
                }
              }}
              width={500}
              makeFocus={makeFocus === 'description'}
              moveNextFocus={() => moveGeneralFocus('description')}
              movePrevFocus={() => moveGeneralFocus('description', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              readOnly={readOnly}
              textArea={true}
            />
          </Row>
        </FirstColumn>
        <SecondColumn>
          <Row>
            <Title style={{ width: '110px' }}>
              {t('Date')}
              {!(template || automated) && <Required>*</Required>}:
            </Title>
            <InvoiceInput
              date={true}
              value={inputData.invoice_date}
              updateValue={value => updateInput(value, 'invoice_date')}
              width={160}
              makeFocus={makeFocus === 'invoice_date'}
              moveNextFocus={() => moveGeneralFocus('invoice_date')}
              movePrevFocus={() => moveGeneralFocus('invoice_date', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              readOnly={readOnly}
              templateAmount={template || automated}
              useOnlyTextWidth={linksAllowed}
            />
            {!!dateLink && (
              <Tooltip
                placement="top"
                title={t('Link') + ': ' + dateLinkName}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a' }}
              >
                <IconContainer style={{ marginLeft: '12px' }}>
                  <Icon type="info" />
                </IconContainer>
              </Tooltip>
            )}
            {linksAllowed && user.permissions.includes('manage_invoicing') ? (
              dateLink ? (
                <LinkIconContainer
                  onClick={() => {
                    setItemLinkerDateInput(dateLink?.code);
                    setItemLinkerDateOpen(true);
                  }}
                >
                  <Icon type="link" />
                </LinkIconContainer>
              ) : (
                <Tooltip
                  placement="top"
                  title={t('You can add a link to use a specific date when the invoice is created.')}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <LinkIconContainer
                    onClick={() => {
                      setItemLinkerDateInput(null);
                      setItemLinkerDateOpen(true);
                    }}
                    style={{ marginLeft: '12px' }}
                  >
                    <Icon type="unlink" />
                  </LinkIconContainer>
                </Tooltip>
              )
            ) : null}
          </Row>
          <Row>
            <Title style={{ width: '110px' }}>
              {t('Due date')}
              {!(template || automated) && <Required>*</Required>}:
              {!(template || automated) && !readOnly && (
                <Tooltip
                  placement="top"
                  title={
                    <DueDateTooltip>
                      <InfoText>
                        {t(
                          'You can choose either an amount of days the invoice will be due in or a specific due date.'
                        )}
                      </InfoText>
                      <RadioButtons>
                        <Radio.Group
                          onChange={e => {
                            setDueDateInDays(e.target.value === 'due_date_days');
                            nullDueDates();
                          }}
                          value={dueDateInDays ? 'due_date_days' : 'due_date'}
                          disabled={readOnly}
                        >
                          <Radio value="due_date_days">{t('Due in x days')}</Radio>
                          <Radio value="due_date">{t('Specific due date')}</Radio>
                        </Radio.Group>
                      </RadioButtons>
                    </DueDateTooltip>
                  }
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <IconContainer style={{ marginLeft: '3px' }}>
                    <Icon type="info" />
                  </IconContainer>
                </Tooltip>
              )}
            </Title>
            {dueDateInDays || template || automated ? (
              <InvoiceInput
                value={inputData.due_date_days}
                updateValue={value => updateInput(value, 'due_date_days')}
                width={160}
                makeFocus={makeFocus === 'due_date'}
                moveNextFocus={() => moveGeneralFocus('due_date')}
                movePrevFocus={() => moveGeneralFocus('due_date', true)}
                removeMakeFocus={() => setMakeFocus(null)}
                readOnly={readOnly}
                options={dueDateOptions}
                select={true}
              />
            ) : (
              <InvoiceInput
                date={true}
                value={inputData.due_date}
                updateValue={value => updateInput(value, 'due_date')}
                width={160}
                makeFocus={makeFocus === 'due_date'}
                moveNextFocus={() => moveGeneralFocus('due_date')}
                movePrevFocus={() => moveGeneralFocus('due_date', true)}
                removeMakeFocus={() => setMakeFocus(null)}
                readOnly={readOnly}
                templateAmount={template || automated}
              />
            )}
          </Row>
          <Row>
            <Title style={{ width: '110px' }}>{t('Shipping date')}:</Title>
            <InvoiceInput
              date={true}
              value={inputData.shipping_date}
              updateValue={value => updateInput(value, 'shipping_date')}
              width={160}
              makeFocus={makeFocus === 'shipping_date'}
              moveNextFocus={() => moveGeneralFocus('shipping_date')}
              movePrevFocus={() => moveGeneralFocus('shipping_date', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              readOnly={readOnly}
              templateAmount={template || automated}
            />
          </Row>
          <Row style={{ marginTop: '12px' }}>
            <Title style={{ width: '110px' }}>
              {t('Currency')}
              {!template && <Required>*</Required>}:
            </Title>
            <InvoiceInput
              value={inputData.currency}
              updateValue={value => updateInput(value, 'currency')}
              width={160}
              makeFocus={makeFocus === 'currency'}
              moveNextFocus={() => moveGeneralFocus('currency')}
              movePrevFocus={() => moveGeneralFocus('currency', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              select={true}
              options={currencies}
              readOnly={readOnly}
            />
          </Row>
          <Row>
            <Title style={{ width: '110px' }}>
              {t('Tax class')}
              {!template && <Required>*</Required>}:
            </Title>
            <InvoiceInput
              value={inputData.tax_class}
              updateValue={value => updateInput(value, 'tax_class')}
              width={200}
              makeFocus={makeFocus === 'tax_class'}
              moveNextFocus={() => moveGeneralFocus('tax_class')}
              movePrevFocus={() => moveGeneralFocus('tax_class', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              select={true}
              options={taxClasses}
              readOnly={readOnly}
            />
          </Row>
          <Row>
            <Title style={{ width: '110px' }}>{t('Your reference')}:</Title>
            <InvoiceInput
              value={inputData.your_reference}
              updateValue={value => updateInput(value, 'your_reference')}
              width={200}
              makeFocus={makeFocus === 'your_reference'}
              moveNextFocus={() => moveGeneralFocus('your_reference')}
              movePrevFocus={() => moveGeneralFocus('your_reference', true)}
              removeMakeFocus={() => setMakeFocus(null)}
              readOnly={readOnly}
            />
          </Row>
        </SecondColumn>
      </TopPart>
      <InvoiceModalTable
        updateRowInput={updateRowInput}
        inputData={inputData}
        setInputData={setInputData}
        moveFocus={moveFocus}
        makeFocus={makeFocus}
        setMakeFocus={setMakeFocus}
        totalWithTax={totalWithTax}
        totalWithoutTax={totalWithoutTax}
        setRowInputChanged={setRowInputChanged}
        readOnly={readOnly}
        template={template}
        showSmall={showSmall}
        setDeleteOngoing={setDeleteOngoing}
        deleteOngoing={deleteOngoing}
        creditInvoice={creditInvoice}
        creditableProducts={creditableProducts}
        automated={automated}
      />

      {itemLinkerCustomerOpen && (
        <ItemLinker
          closeModal={() => {
            setItemLinkerCustomerOpen(false);
            setItemLinkerCustomerInput(null);
          }}
          value={itemLinkerCustomerInput}
          options={customerLinkOptions}
          text={t('Choose the customer type that should be used when the invoice is being created.')}
          changeLink={e => updateCustomerLink(e)}
        />
      )}

      {itemLinkerDateOpen && (
        <ItemLinker
          closeModal={() => {
            setItemLinkerDateOpen(false);
            setItemLinkerDateInput(null);
          }}
          value={itemLinkerDateInput}
          options={dateLinkOptions}
          text={t('Choose the date type that should be used when the invoice is being created.')}
          changeLink={e => updateDateLink(e)}
        />
      )}
    </>
  );
};

export default InnerInvoiceModal;
