import React, { useContext } from 'react';
import styled from 'styled-components';

import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

import { CalendarRow } from './CalendarRow';

const RowLines = styled.div`
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
`;

export const CalendarRows = () => {
  const { craneList = [], reservationCraneList } = useContext(CranePlanningToolContext);

  return (
    <RowLines>
      {craneList.map((crane, i) => {
        return <CalendarRow key={i} index={i} crane={crane} />;
      })}

      {reservationCraneList.map((crane, i) => {
        return <CalendarRow key={i} index={i} crane={crane} />;
      })}
    </RowLines>
  );
};
