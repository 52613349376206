import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Popconfirm from 'antd/es/popconfirm';
import Spin from 'antd/es/spin';
import App from 'antd/es/app';

import { UserContext } from '../../context/UserContext';
import List from '../ui/List';
import useApi from '../../hooks/useApi';
import ListActionButton from '../ui/ListActionButton';
import Icon from '../ui/Icon';
import CustomerModal from './CustomerModal';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import ButtonLight from '../ui/ButtonLight';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Expander = styled.div`
  padding: 12px 24px;
  display: flex;
`;

const Column = styled.div`
  display: inline-block;
  margin-right: 36px;
`;

const Pair = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Title = styled.div`
  margin-right: 12px;
`;

const Value = styled.div`
  font-weight: 600;
`;

const Loader = styled.div`
  margin-top: 6px;
  margin-right: 12px;
`;

const CustomersList = () => {
  const { namespace, user, apiCall, useUserSocket } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const { getCustomerStartData } = useContext(NewInvoicingContext);

  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [modalCustomer, setModalCustomer] = useState(undefined);

  const [refrechingCache, setRefrechingCache] = useState(false);

  const { loading, data, error, fetchData } = useApi('get', 'invoicing/v2/external-customers', {});

  let customers = error || !data ? [] : data.results;

  if (error) {
    message.error(error);
  }

  useEffect(() => {
    getCustomerStartData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const customersChanged = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useUserSocket('invoicing-external-customers-changed', customersChanged);

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Customer number'),
      dataIndex: 'customer_number',
      key: 'customer_number',
    },
    {
      title: t('Address'),
      dataIndex: 'address',
      key: 'address',
      render: (value, item) => (
        <div>
          <div>{value}</div>
          <div>
            {item.postalcode} {item.city}
          </div>
        </div>
      ),
    },
    {
      title: t('Country'),
      dataIndex: 'country_name',
      key: 'country_name',
      render: (value, item) => (
        <div>
          <div>
            {value || '-'} {item.country_code ? '(' + item.country_code + ')' : ''}
          </div>
        </div>
      ),
    },
    {
      title: t('Contact person'),
      dataIndex: 'contact_person',
      key: 'contact_person',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  const handleRefresh = async () => {
    setRefrechingCache(true);
    try {
      await apiCall('post', 'invoicing/v2/refresh-customer-cache');
    } catch (e) {
      setRefrechingCache(false);
      throw e;
    }
    setRefrechingCache(false);
    await fetchData(false);
  };

  const actionList = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: record => (
            <ListActionButton
              key="action-1"
              onClick={() => {
                setModalCustomer(record);
                setCustomerModalOpen(true);
              }}
            >
              <Icon type="edit" />
              {t('Edit')}
            </ListActionButton>
          ),
        },
      ]
    : null;

  const additionalButtons = user.permissions.includes('manage_invoicing')
    ? [
        {
          render: () =>
            refrechingCache ? (
              <Loader>
                <Spin size="small" />
              </Loader>
            ) : null,
        },
        {
          render: () => (
            <Popconfirm
              title={
                <div style={{ maxWidth: '200px' }}>
                  {t('Are you sure you want to manually update the local customer database from external service?')}
                </div>
              }
              onConfirm={() => handleRefresh()}
              okText={t('Yes')}
              okType="danger"
              cancelText={t('No')}
              icon={null}
              id="pop-confirm-for-new-list"
              key="action-5"
            >
              <div>
                <ButtonLight blue>
                  <Icon type="refresh" />
                  {t('Refresh cache')}
                </ButtonLight>
              </div>
            </Popconfirm>
          ),
        },
        {
          onClick: () => {
            setModalCustomer(undefined);
            setCustomerModalOpen(true);
          },
          text: t('Create new customer'),
          icon: 'plus',
        },
      ]
    : [];

  const closeCustomerModal = () => {
    setModalCustomer(undefined);
    setCustomerModalOpen(false);
  };

  const closeCustomerModalAndReload = () => {
    setModalCustomer(undefined);
    setCustomerModalOpen(false);
    fetchData(false);
  };

  return (
    <Container>
      <List
        rowKey="customer_number"
        columns={columns}
        dataSource={customers}
        spinning={loading}
        searchPlaceHolder={t('Search')}
        additionalButtons={additionalButtons}
        actions={actionList}
        localPagination={true}
        localPaginationFields={['name', 'country_name', 'country_code', 'address', 'city', 'email', 'postalcode']}
        expandedRowRender={record => (
          <Expander>
            <Column>
              <Pair>
                <Title>{t('VAT number')}:</Title>
                <Value>{record.vat_number || '-'}</Value>
              </Pair>
              <Pair>
                <Title>{t('Account type')}:</Title>
                <Value>{record.account_type_name || '-'}</Value>
              </Pair>
              <Pair>
                <Title>{t('E-invoice operator ID')}:</Title>
                <Value>{record.einvoice_operator_id || '-'}</Value>
              </Pair>
            </Column>
            <Column>
              <Pair>
                <Title>{t('Business ID')}:</Title>
                <Value>{record.business_id || '-'}</Value>
              </Pair>
              <Pair>
                <Title>{t('Sales invoice delivery method name')}:</Title>
                <Value>{record.sales_invoice_delivery_method_name || '-'}</Value>
              </Pair>
              <Pair>
                <Title>{t('E-invoice address')}:</Title>
                <Value>{record.einvoice_address || '-'}</Value>
              </Pair>
            </Column>
          </Expander>
        )}
        noUrlUpdate={true}
      />

      {customerModalOpen && (
        <CustomerModal
          customer={modalCustomer}
          closeModal={closeCustomerModal}
          closeAndReload={closeCustomerModalAndReload}
          readOnly={false}
        />
      )}
    </Container>
  );
};

export default CustomersList;
