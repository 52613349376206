import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tag from 'antd/es/tag';
import { withRouter, useHistory, useLocation } from 'react-router-dom';

import { FilteringContext } from '../../context/FilteringContext';
import { UserContext } from '../../context/UserContext';

import SearchContainer from '../SearchContainer';
import Icon from '../ui/Icon';
import { toTitleCase } from '../../utils/utils';
import TimestampFilteringModal from '../activity/TimestampFilteringModal';
import TimestampFilteringButton from '../activity/TimestampFilteringButton';
import { TimestampFilteringContext } from '../../context/TimestampFilteringContext';
import UniversalEmissionsTimePicker from '../statistics/UniversalEmissionsTimePicker';
import FilterContainer from './FilterContainer';
import TimestampFilteringButtonMobile from '../activity/TimestampFilteringButtonMobile';
import MobileSearch from './MobileSearch';
import MobileCommonStatisticsTimePicker from './MobileCommonStatisticsTimePicker';
import { mobilePixelMaxWidthLimit, mobilePixelMinWidthLimit } from '../../utils/constants';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 16;

  @media print {
    display: none;
  }
`;

const StartContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 18px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-left: 2px;
  }
`;

const EndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 18px;
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const EmissionsContainer = styled.div`
  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const Search = styled.div`
  margin-right: ${props => (props.addMargin ? '24px' : '8px')};
`;

const TagContainer = styled.div`
  background: white;
  font-size: 20px;
  margin-right: 10px;
  height: 22px;
  margin-top: 6px;
  display: flex;
`;

const TextContainer = styled.div`
  margin: 6px 10px;
`;

const MoreIcon = styled.span`
  cursor: pointer;

  svg {
    margin-top: -15px;
  }
`;

const MoreIcon2 = styled.span`
  cursor: pointer;
  margin-left: 6px;

  svg {
    margin-top: -8px;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-content: center;
  padding: 0 12px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    padding-right: 4px;
  }
`;

const BreadcrumbContainer = styled.div`
  font-size: 14px;
  display: flex;
  color: ${({ theme }) => theme.color.secondary};
`;

const Breadcrumb = styled.div`
  font-size: 14px;
  align-self: center;
  margin-right: 6px;
  cursor: pointer;
`;

const QuickButtons = styled.div`
  display: flex;
  margin-left: 18px;
  align-items: center;
  position: relative;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const QuickButton = styled.div`
  font-size: 14px;
  margin: 0 10px;
  color: ${props => (props.current ? props.theme.color.secondary : props.theme.color.grey_dark)};
  font-weight: ${props => (props.current ? 700 : 400)};
  cursor: pointer;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const Popover = styled.div`
  background-color: white;
  position: absolute;
  right: -60px;
  top: 40px;
  padding: 3px 0;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 80;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    right: unset;
    left: 16px;
    top: 46px;

    min-width: 180px;
  }
`;

const PopoverMobile = styled(Popover)`
  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: none;
  }
`;

const Link = styled.div`
  font-size: 13px;
  padding: 6px 12px;
  cursor: pointer;
  color: #4a4a4a;
  font-weight: ${props => (props.current ? 700 : 400)};
  background-color: ${props => (props.current ? '#f0fbff' : 'white')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: ${props => (props.chosen ? '#f0fbff' : '#f2f2f2')};
  }
`;

const MobileMoreIcon = styled.div`
  i {
    color: ${({ theme }) => theme.color.secondary};
    margin-top: 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: none;
  }
`;

const FilterTagList = ({ filtering, navStructure, ...props }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { tags, removeStorageData, setFilterPopoverOpen } = useContext(FilteringContext);
  const { showTimestamps } = useContext(TimestampFilteringContext);

  const history = useHistory();
  const location = useLocation();

  const [quickButtonsPopoverOpen, setQuickButtonsPopoverOpen] = useState(false);
  const [quickButtonsMobilePopoverOpen, setQuickButtonsMobilePopoverOpen] = useState(false);
  const [timestampFilteringOpen, setTimestampFilteringOpen] = useState(false);
  const [timestampFilteringDisabled, setTimestampFilteringDisabled] = useState(false);

  const timerRef = useRef();

  const removeTag = e => {
    removeStorageData(e);
  };

  const crop = text => {
    if (text.length > 14) {
      return text.substring(0, 14) + '...';
    }
    return text;
  };

  const openTimestampFilterModal = () => {
    if (!timestampFilteringDisabled) {
      setTimestampFilteringOpen(true);
      setTimestampFilteringDisabled(true);
    }
  };

  const closeTimestampFilterModal = () => {
    setTimestampFilteringOpen(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setTimestampFilteringDisabled(false);
      timerRef.current = undefined;
    }, 300);
  };

  const showableTags = tags.length > 3 ? tags.slice(0, 3) : tags;

  let showableBreadcrumbs = [];
  let quickButtons = [];

  switch (location.pathname) {
    case '/' + namespace + '/fleet-view':
    case '/' + namespace + '/fleet-vessel-view':
    case '/' + namespace + '/fleet-data-view':
    case '/' + namespace + '/map':
    case '/' + namespace + '/fleet-service-orders':
      if (
        (location.pathname === '/' + namespace + '/map' && location.search.includes('type=fleet')) ||
        location.pathname !== '/' + namespace + '/map'
      ) {
        quickButtons = navStructure.find(n => n.id === 'fleet').links;
        if (namespace === 'neste') {
          showableBreadcrumbs.push({ path: '/fleet-vessel-view', pagename: 'Fleet View' });
        } else {
          showableBreadcrumbs.push({ path: '/fleet-view', pagename: 'Fleet View' });
        }
      } else {
        quickButtons = [];
        showableBreadcrumbs.push({ path: '/map', pagename: 'Map' });
      }
      break;
    case '/' + namespace + '/vessels':
    case '/' + namespace + '/port-call-tolerances':
    case '/' + namespace + '/port-calls':
    case '/' + namespace + '/inbound-vessels':
    case '/' + namespace + '/vis-vessels':
    case '/' + namespace + '/ports':
    case '/' + namespace + '/vis-vessels/vis-service-configuration':
    case '/' + namespace + '/vis-vessels/vis-service-find':
    case '/' + namespace + '/vis-vessels/vis-notifications':
    case '/' + namespace + '/port-call-requests':
      quickButtons = navStructure.find(n => n.id === 'vessels').links;
      showableBreadcrumbs.push({ path: '/vessels', pagename: 'Vessels' });
      break;
    case '/' + namespace + '/admin/users':
    case '/' + namespace + '/admin/register-codes':
    case '/' + namespace + '/admin/access-control':
    case '/' + namespace + '/admin/groups':
    case '/' + namespace + '/admin/organizations':
    case '/' + namespace + '/admin/mfa-settings':
      quickButtons = navStructure.find(n => n.id === 'users').links;
      showableBreadcrumbs.push({ path: '/admin/users', pagename: 'Users Management' });
      break;
    case '/' + namespace + '/admin/api-keys':
    case '/' + namespace + '/admin/api-key-weights/timestamps':
    case '/' + namespace + '/admin/api-key-weights/payloads':
      quickButtons = navStructure.find(n => n.id === 'api-keys').links;
      showableBreadcrumbs.push({ path: '/admin/api-keys', pagename: 'API Keys' });
      break;
    case '/' + namespace + '/admin/string-translations':
    case '/' + namespace + '/admin/geo-asset-tool':
    case '/' + namespace + '/admin/modules':
    case '/' + namespace + '/admin/alerts':
    case '/' + namespace + '/admin/forms-codes':
    case '/' + namespace + '/admin/timestamp-filtering':
    case '/' + namespace + '/admin/map-port-actors':
    case '/' + namespace + '/admin/port-stamp':
    case '/admin/emergency-shutdown':
      quickButtons = navStructure.find(n => n.id === 'admin').links;
      showableBreadcrumbs.push({ path: '/admin/string-translations', pagename: 'Admin' });
      break;
    case '/' + namespace + '/queue-admin/admin-dashboard':
    case '/' + namespace + '/queue-admin/admin-queue-settings':
    case '/' + namespace + '/queue-admin/admin-slot-reservations':
    case '/' + namespace + '/berths':
    case '/' + namespace + '/queue-admin/admin-berth-reservations':
    case '/' + namespace + '/queue/nominations':
    case '/' + namespace + '/queue-admin/admin-nominations':
    case '/' + namespace + '/queue-admin/pre-advice-queue':
      quickButtons = navStructure.find(n => n.id === 'jit').links;
      showableBreadcrumbs.push({ path: '/queue-admin/admin-dashboard', pagename: 'Just-in-time' });
      break;
    case '/privacy-policy':
    case '/' + namespace + '/help-page':
    case '/' + namespace + '/wiki':
    case '/' + namespace + '/wiki-links':
      quickButtons = navStructure.find(n => n.id === 'more').links;
      showableBreadcrumbs.push({ path: '/privacy-policy', pagename: 'More' });
      break;
    case '/' + namespace + '/':
    case '/' + namespace:
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/', pagename: 'Activity' });
      break;
    case '/' + namespace + '/activity-cards':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/activity-cards', pagename: 'Activity Cards' });
      break;
    case '/' + namespace + '/activity-list':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/activity-list', pagename: 'Activity List' });
      break;
    case '/' + namespace + '/activity-list-v2':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/activity-list-v2', pagename: 'Activity List V2' });
      break;
    case '/' + namespace + '/berth-planning-tool':
    case '/' + namespace + '/vessel-calendar':
    case '/' + namespace + '/cranes':
    case '/' + namespace + '/companies':
    case '/' + namespace + '/holidays':
    case '/' + namespace + '/commodities':
    case '/' + namespace + '/crane-timeline':
      quickButtons = navStructure.find(n => n.id === 'bpt').links;
      showableBreadcrumbs.push({
        path: user.permissions.includes('manage_bpt') ? '/berth-planning-tool' : 'vessel-calendar',
        pagename: user.permissions.includes('manage_bpt') ? 'Berth Planning Tool' : 'Vessel Calendar',
      });
      break;
    case '/' + namespace + '/notifications':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/notifications', pagename: 'Notifications' });
      break;
    case '/' + namespace + '/logistics':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/logistics', pagename: 'Logistics' });
      break;
    case '/' + namespace + '/loading-master-petroleum-jobs':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/loading-master-petroleum-jobs', pagename: 'Loading Master' });
      break;
    case '/' + namespace + '/sea-level':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/sea-level', pagename: 'Sea Level' });
      break;
    case '/' + namespace + '/port-weather':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/port-weather', pagename: 'Weather' });
      break;
    case '/' + namespace + '/profile':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/profile', pagename: 'Profile' });
      break;
    case '/' + namespace + '/statistics':
    case '/' + namespace + '/emission-statistics':
      quickButtons = navStructure.find(n => n.id === 'statistics').links;
      if (quickButtons && quickButtons.length > 0) {
        const firstAvailable = quickButtons.find(b => b.checks);
        if (firstAvailable) {
          showableBreadcrumbs.push({ path: firstAvailable.path, pagename: 'Statistics' });
        }
      }
      break;
    case '/' + namespace + '/data-export':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/data-export', pagename: 'Data Export' });
      break;
    case '/' + namespace + '/area-occupancy':
    case '/' + namespace + '/area-occupancy/history':
      quickButtons = navStructure.find(n => n.id === 'area-occupancy').links;
      showableBreadcrumbs.push({ path: '/area-occupancy', pagename: 'Area Occupancy' });
      break;
    case '/' + namespace + '/incidents-and-events':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/incidents-and-events', pagename: 'Incidents and events' });
      break;
    case '/' + namespace + '/release-notes':
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/release-notes', pagename: 'Release notes' });
      break;
    case '/' + namespace + '/waste':
    case '/' + namespace + '/waste-view-document':
    case '/' + namespace + '/waste-validate-document':
      quickButtons = navStructure.find(n => n.id === 'waste').links;
      showableBreadcrumbs.push({ path: '/waste', pagename: 'Waste' });
      break;
    case '/' + namespace + '/invoicing':
    case '/' + namespace + '/invoicing/invoices':
    case '/' + namespace + '/invoicing/matrix':
    case '/' + namespace + '/invoicing/customers':
      quickButtons = navStructure.find(n => n.id === 'invoicing').links;
      showableBreadcrumbs.push({ path: '/invoicing', pagename: 'Invoicing' });
      break;
    case '/' + namespace + '/invoicing-invoices/per-portcall':
    case '/' + namespace + '/invoicing-invoices/saved':
    case '/' + namespace + '/invoicing-invoices/sent':
    case '/' + namespace + '/invoicing-invoices/automated':
    case '/' + namespace + '/invoicing-invoices/exceptions':
    case '/' + namespace + '/invoicing-products/products':
    case '/' + namespace + '/invoicing-products/modifications':
    case '/' + namespace + '/invoicing-templates':
    case '/' + namespace + '/invoicing-customers':
    case '/' + namespace + '/invoicing-holidays':
      quickButtons = navStructure.find(n => n.id === 'invoicing2').links;
      showableBreadcrumbs.push({ path: '/invoicing-invoices/per-portcall', pagename: 'Invoicing' });
      break;
    case '/' + namespace + '/service-orders':
    case '/' + namespace + '/service-orders/services':
    case '/' + namespace + '/service-orders/service-providers':
    case '/' + namespace + '/service-orders/service-types': {
      quickButtons = navStructure.find(n => n.id === 'service-orders').links;
      const firstAvailable = quickButtons.find(button => button.checks && button.path);
      if (firstAvailable) {
        showableBreadcrumbs.push({ path: firstAvailable.path, pagename: 'Service orders' });
      }
      break;
    }
    default:
      quickButtons = [];
      showableBreadcrumbs = [];
  }

  if (showableBreadcrumbs.length === 0) {
    if (location.pathname.includes('admin/port-calls/')) {
      showableBreadcrumbs.push({ path: location.pathname, pagename: 'Port Call Timesheet' });
    } else if (location.pathname.includes('vessels/vessel-timestamps/')) {
      showableBreadcrumbs.push({ path: location.pathname, pagename: 'Vessel Timestamps' });
    } else if (location.pathname.includes('vessels/vessel-timestamp/')) {
      showableBreadcrumbs.push({ path: location.pathname, pagename: 'Vessel Timestamp' });
    } else if (location.pathname.includes('incident-and-event/')) {
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/incidents-and-events', pagename: 'Incidents and events' });
    } else if (location.pathname.includes('port-call-request/')) {
      quickButtons = [];
      showableBreadcrumbs.push({ path: '/port-call-requests', pagename: 'Port Call Requests' });
    }
  }

  quickButtons = quickButtons
    .filter(b => b.checks)
    .map(b => {
      let button = { ...b };
      if (
        '/' + namespace + b.path === location.pathname ||
        b.alternativePaths?.findIndex(p => '/' + namespace + p === location.pathname) > -1 ||
        (location.pathname.includes('/map') && b.path.includes('/map')) ||
        (location.pathname.includes('/privacy-policy') && b.path.includes('/privacy-policy')) ||
        (location.pathname.includes('/emergency-shutdown') && b.path.includes('/emergency-shutdown'))
      ) {
        button.current = true;
      } else {
        button.current = false;
      }

      return button;
    });

  let currentIndex = quickButtons.findIndex(b => b.current);

  if (currentIndex > 2 && quickButtons.length !== 4) {
    quickButtons.splice(2, 0, quickButtons.splice(currentIndex, 1)[0]);
  }

  let showableQuickButtons =
    quickButtons.length > 3
      ? quickButtons.length === 4
        ? quickButtons
        : quickButtons.filter(b => b.checks).slice(0, 3)
      : quickButtons;

  return (
    <>
      <Row id="filter-tag-row">
        <StartContainer>
          <Breadcrumbs>
            {showableBreadcrumbs.map((b, i) => (
              <BreadcrumbContainer key={i}>
                <Breadcrumb onClick={() => history.push(b.path)}>{t(b.pagename)}</Breadcrumb>
              </BreadcrumbContainer>
            ))}
          </Breadcrumbs>
          {quickButtons.length > 0 && (
            <MobileMoreIcon onClick={() => setQuickButtonsMobilePopoverOpen(true)}>
              <Icon type="chevron-down" />
            </MobileMoreIcon>
          )}
          {showTimestamps && (location.pathname === '/' + namespace + '/' || location.pathname === '/' + namespace) && (
            <TimestampFilteringButton
              timestampFilteringOpen={timestampFilteringOpen}
              openTimestampFilterModal={openTimestampFilterModal}
            />
          )}
          <QuickButtons>
            {showableQuickButtons.map((b, i) => {
              if (b.checks) {
                return (
                  <QuickButton key={i} onClick={() => history.push(b.path)} current={b.current}>
                    {t(b.title)}
                  </QuickButton>
                );
              } else {
                return null;
              }
            })}
            {quickButtons.length !== 4 && quickButtons.length > 3 && (
              <MoreIcon2 onClick={() => setQuickButtonsPopoverOpen(true)}>
                <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
              </MoreIcon2>
            )}

            {quickButtonsPopoverOpen && (
              <>
                <Blocker onClick={() => setQuickButtonsPopoverOpen(false)} />
                <Popover>
                  {quickButtons.map((b, i) => {
                    if (i < 3) {
                      return null;
                    }

                    let current = false;
                    if (
                      '/' + namespace + b.path === location.pathname ||
                      b.alternativePaths?.findIndex(p => '/' + namespace + p === location.pathname) > -1 ||
                      (location.pathname.includes('/map') && b.path.includes('/map')) ||
                      (location.pathname.includes('/privacy-policy') && b.path.includes('/privacy-policy'))
                    ) {
                      current = true;
                    }

                    if (b.checks) {
                      return (
                        <Link key={i} onClick={() => history.push(b.path)} current={current}>
                          {t(b.title)}
                        </Link>
                      );
                    }
                  })}
                </Popover>
              </>
            )}
          </QuickButtons>

          {quickButtonsMobilePopoverOpen && (
            <>
              <Blocker onClick={() => setQuickButtonsMobilePopoverOpen(false)} />
              <PopoverMobile>
                {quickButtons.map((b, i) => {
                  let current = false;
                  if (
                    '/' + namespace + b.path === location.pathname ||
                    b.alternativePaths?.findIndex(p => '/' + namespace + p === location.pathname) > -1 ||
                    (location.pathname.includes('/map') && b.path.includes('/map')) ||
                    (location.pathname.includes('/privacy-policy') && b.path.includes('/privacy-policy'))
                  ) {
                    current = true;
                  }

                  if (b.checks) {
                    return (
                      <Link key={i} onClick={() => history.push(b.path)} current={current}>
                        {t(b.title)}
                      </Link>
                    );
                  }
                })}
              </PopoverMobile>
            </>
          )}
        </StartContainer>
        <EndContainer>
          <Container>
            {!!showableTags.length && filtering && (
              <>
                <TextContainer>{t('Filters')}:</TextContainer>
                <TagContainer>
                  {showableTags.map((item, index) => (
                    <Tag closable open={true} key={index} onClose={() => removeTag(item)}>
                      {item.folder === 'agent' ? crop(item.display) : crop(toTitleCase(item.display))}
                    </Tag>
                  ))}
                  {tags.length > 3 && (
                    <MoreIcon onClick={() => setFilterPopoverOpen(true)}>
                      <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
                    </MoreIcon>
                  )}
                </TagContainer>
              </>
            )}
            <Search
              addMargin={
                !(props.match.path === '/:urlnamespace/' || props.match.path === '/:urlnamespace/activity-cards')
              }
            >
              {(props.match.path === '/:urlnamespace/' || props.match.path === '/:urlnamespace/activity-cards') && (
                <SearchContainer />
              )}
            </Search>
          </Container>

          <MobileContainer>
            {location.pathname.includes('/emission-statistics') && <MobileCommonStatisticsTimePicker />}
            {showTimestamps &&
              (location.pathname === '/' + namespace + '/' || location.pathname === '/' + namespace) && (
              <TimestampFilteringButtonMobile
                timestampFilteringOpen={timestampFilteringOpen}
                openTimestampFilterModal={openTimestampFilterModal}
              />
            )}
            <FilterContainer mobile={true} />
            {props.match.path === '/:urlnamespace/' && <MobileSearch />}
          </MobileContainer>
          <EmissionsContainer>
            {location.pathname.includes('/emission-statistics') && <UniversalEmissionsTimePicker />}
          </EmissionsContainer>

          {timestampFilteringOpen && <TimestampFilteringModal closeModal={closeTimestampFilterModal} />}
        </EndContainer>
      </Row>
    </>
  );
};

export default withRouter(FilterTagList);
