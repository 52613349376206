import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Icon from '../../components/ui/Icon';
import AddVesselPortcallToleranceModal from '../../components/vessel/AddVesselPortcallToleranceModal';
import EditVesselPortcallToleranceModal from '../../components/vessel/EditVesselPortcallToleranceModal';
import DeleteVesselPortcallToleranceModal from '../../components/vessel/DeleteVesselPortcallToleranceModal';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';

const VesselTolerances = () => {
  const { namespace, user, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [addModal, setAddModal] = useState(false);
  const [vesselForEditing, setVesselForEditing] = useState(undefined);
  const [vesselForDeleting, setVesselForDeleting] = useState(undefined);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'vessel_name',
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);

  const { loading, data, error, fetchData } = useApi('get', 'vessel-port-call-tolerances', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const vessels = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  vessels.forEach(p => {
    p._row = start + counter;
    counter++;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const handleAddModal = async () => {
    setAddModal(true);
  };

  const handleEdit = async e => {
    setVesselForEditing(e);
  };

  const handleDelete = async e => {
    setVesselForDeleting(e);
  };

  const closeAddModalAndUpdate = () => {
    setAddModal(false);
    fetchData(false, newParams);
  };

  const closeEditModalAndUpdate = () => {
    setVesselForEditing(undefined);
    fetchData(false, newParams);
  };

  const closeDeleteModalAndUpdate = () => {
    setVesselForDeleting(undefined);
    fetchData(false, newParams);
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('Name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('Start tolerance'),
      dataIndex: 'start_tolerance',
      key: 'start_tolerance',
      sortableKey: 'start_tolerance',
    },
    {
      title: t('End tolerance'),
      dataIndex: 'end_tolerance',
      key: 'end_tolerance',
      sortableKey: 'end_tolerance',
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={!user.permissions.includes('manage_port_call')}
          key="action-1"
          onClick={() => handleEdit(record)}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          disabled={!user.permissions.includes('manage_port_call')}
          key="action-2"
          onClick={() => handleDelete(record)}
          red
        >
          <Icon type="trash" />
          {t('Delete')}
        </ListActionButton>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => handleAddModal(),
      disabled: !user.permissions.includes('manage_port_call'),
      text: t('Add tolerance'),
      icon: 'plus',
    },
  ];

  return (
    <>
      {addModal && (
        <AddVesselPortcallToleranceModal
          closeModal={() => setAddModal(false)}
          closeModalAndUpdate={closeAddModalAndUpdate}
        />
      )}
      {vesselForEditing && (
        <EditVesselPortcallToleranceModal
          closeModal={() => setVesselForEditing(undefined)}
          closeModalAndUpdate={closeEditModalAndUpdate}
          vessel={vesselForEditing}
        />
      )}
      {vesselForDeleting && (
        <DeleteVesselPortcallToleranceModal
          closeModal={() => setVesselForDeleting(undefined)}
          closeModalAndUpdate={closeDeleteModalAndUpdate}
          vessel={vesselForDeleting}
        />
      )}
      <List
        rowKey="id"
        columns={columns}
        dataSource={vessels}
        actions={actionList}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name or exact IMO')}
        additionalButtons={additionalButtons}
      />
    </>
  );
};

export default VesselTolerances;
