import { TileLayer, useMapEvents, ZoomControl, useMap, FeatureGroup } from 'react-leaflet';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useCallback, useMemo } from 'react';
import L from 'leaflet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';
import Spin from 'antd/es/spin';

import { GeoAssetToolContext } from '../../../context/GeoAssetToolContext';
import { UserContext } from '../../../context/UserContext';

import AssetConstructor from './AssetConstructor';
import TypeEditModal from './TypeEditModal';
import DrawControl from './DrawControls';
import { getOptions } from '../../map/MapUtils';
import Icon from '../../ui/Icon';
import './geoAssetTool.css';
import CircleMarkers from '../../map/CircleMarkers';
import useApi from '../../../hooks/useApi';
import WMSChart from '../../map/WMSChart';
import { debounce } from 'throttle-debounce';

const Tools = styled.div`
  background-color: white;
  align-items: center;
  width: 34px;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  top: 138px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1000;
`;

const ToolButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.disabled && '#e8e8e8 !important'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
  i {
    width: 60%;
    height: 60%;
  }
`;

const RecenterContainer = styled.div`
  background: ${props => props.theme.color.white};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  padding: 4px 8px;

  &:hover {
    background: #f4f4f4;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-left: -4px;
  }
`;

const Recenter = styled.div`
  position: absolute;
  bottom: 94px;
  right: 10px;
  z-index: 100;
  color: #4a4a4a;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
`;

const SpinContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(212, 212, 212, 0.8);
  z-index: 1001;
  position: relative;
  justify-content: center;
  display: flex;
  padding-top: 200px;
`;

const GeoAssetMapInner = ({
  zoom,
  setZoom,
  selectedAisVessel,
  setSelectedAisVessel,
  mainMap = false,
  appliedCoordinates,
  setAppliedCoordinates,
}) => {
  const {
    assetData,
    setDraggableAssets,
    draggableAssets,
    setDeleteAssets,
    deleteAssets,
    setRotateAssets,
    rotateAssets,
    currentNamespace,
    styleUpdate,
    oldAssetStyle,
    rightPanelData,
    rightPanelOpen,
    newAssetStyle,
    undoFunction,
    redoFunction,
    undoAvailable,
    redoAvailable,
    geoJsonLayerData,
    geoJsonLayerUpdate,
    setGeoJsonLayerData,
    setGeoJsonLayerUpdate,
    drawAssets,
    typeData,
    filterList,
    getAssetsAndTypes,
    listAssets,
    globalsaveLoading,
    setRotateAssetsCount,
    rotateAssetsCount,
  } = useContext(GeoAssetToolContext);

  let defaultPrecision = {
    km: 2,
    ha: 2,
    m: 0,
    mi: 2,
    ac: 2,
    yd: 0,
    ft: 0,
    nm: 2
  };

  const readableArea = (area, isMetric, precisionOrig) => {
    let areaStr,
      units,
      precision = L.Util.extend({}, defaultPrecision, precisionOrig);

    if (isMetric) {
      units = ['ha', 'm'];
      let type = typeof isMetric;
      if (type === 'string') {
        units = [isMetric];
      } else if (type !== 'boolean') {
        units = isMetric;
      }

      if (area >= 1000000 && units.indexOf('km') !== -1) {
        areaStr = L.GeometryUtil.formattedNumber(area * 0.000001, precision['km']) + ' km²';
      } else if (area >= 10000 && units.indexOf('ha') !== -1) {
        areaStr = L.GeometryUtil.formattedNumber(area * 0.0001, precision['ha']) + ' ha';
      } else {
        areaStr = L.GeometryUtil.formattedNumber(area, precision['m']) + ' m²';
      }
    } else {
      area /= 0.836127; // Square yards in 1 meter

      if (area >= 3097600) { //3097600 square yards in 1 square mile
        areaStr = L.GeometryUtil.formattedNumber(area / 3097600, precision['mi']) + ' mi²';
      } else if (area >= 4840) { //4840 square yards in 1 acre
        areaStr = L.GeometryUtil.formattedNumber(area / 4840, precision['ac']) + ' acres';
      } else {
        areaStr = L.GeometryUtil.formattedNumber(area, precision['yd']) + ' yd²';
      }
    }

    return areaStr;
  };

  L.Draw.Rectangle.include({
    _getTooltipText: function () {
      let tooltipText = L.Draw.SimpleShape.prototype._getTooltipText.call(this),
        shape = this._shape,
        showArea = this.options.showArea,
        latLngs, area, subtext;

      if (shape) {
        latLngs = this._shape._defaultShape ? this._shape._defaultShape() : this._shape.getLatLngs();
        area = L.GeometryUtil.geodesicArea(latLngs);
        subtext = showArea ? readableArea(area, this.options.metric) : '';
      }

      return {
        text: tooltipText.text,
        subtext: subtext
      };
    }
  });

  L.Draw.Circle.include({
    _onMouseMove: function(e) {
      let latlng = e.latlng,
        showRadius = this.options.showRadius,
        useMetric = this.options.metric,
        radius;

      this._tooltip?.updatePosition(latlng);
      if (this._isDrawing) {
        this._drawShape(latlng);

        // Get the new radius (rounded to 1 dp)
        radius = this._shape.getRadius().toFixed(1);

        let subtext = '';
        if (showRadius) {
          subtext =
            L.drawLocal.draw.handlers.circle.radius +
            ': ' +
            L.GeometryUtil.readableDistance(radius * 1, useMetric, this.options.feet, this.options.nautic);
        }
        this._tooltip.updateContent({
          text: this._endLabelText,
          subtext: subtext,
        });
      }
    },
  });

  const { namespace, mapDefaultZoom } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const map = useMap();
  const options = getOptions();
  const assetLeafletIdRef = useRef(null);

  const rightPanelRef = useRef();
  const assetsRef = useRef();

  const [redoFade, setRedoFade] = useState(false);
  const [undoFade, setUndoFade] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);

  const [showVessels, setShowVessels] = useState(false);

  const storageKey = 'map.centerAndZoom_' + namespace;

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
      debouncedLocalStorageSet();
    },
    moveend: () => {
      debouncedLocalStorageSet();
    },
  });

  const updateLocalStorage = useCallback(() => {
    const center = mapEvents.getCenter();
    setAppliedCoordinates(`${center.lat},${center.lng}`);
    localStorage.setItem(storageKey, JSON.stringify({ zoom: mapEvents.getZoom(), center }));
  }, [mapEvents, setAppliedCoordinates, storageKey]);

  const debouncedLocalStorageSet = useMemo(() => debounce(500, updateLocalStorage), [updateLocalStorage]);

  useEffect(() => {
    if (rightPanelOpen !== rightPanelRef.current) {
      setTimeout(function() {
        mapEvents.invalidateSize();
      }, 400);
    }
    rightPanelRef.current = rightPanelOpen;
  }, [mapEvents, rightPanelOpen]);

  const { data: helcomData, fetchData: fetchHelcomData } = useApi('get', 'ais/helcom', {}, null);

  useEffect(() => {
    if (mainMap) {
      getAssetsAndTypes(namespace);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let vesselInterval = undefined;
    vesselInterval = setInterval(() => {
      fetchHelcomData();
    }, 180000);

    return () => clearInterval(vesselInterval);
  }, [fetchHelcomData, namespace]);

  const coordinateFunction = () => {
    const coords = [];
    if (geoJsonLayerData?.geometry?.type === 'Point') {
      const coordOne = geoJsonLayerData.geometry.coordinates[0];
      const coordTwo = geoJsonLayerData.geometry.coordinates[1];
      coords.push(coordTwo);
      coords.push(coordOne);
    } else {
      const array =
        geoJsonLayerData?.geometry?.type === 'Polygon'
          ? geoJsonLayerData.geometry.coordinates[0]
          : geoJsonLayerData.geometry.coordinates;
      if (array.length) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          const coordOne = element[0];
          const coordTwo = element[1];
          coords.push([coordTwo, coordOne]);
        }
        if (geoJsonLayerData?.geometry?.type === 'Polygon') return [coords];
      }
    }
    return coords;
  };

  const removeOldGeoJsonLayer = () => {
    map.eachLayer(function(layer) {
      if (layer.tag && layer.tag === 'GeoJSONLayer') {
        map.removeLayer(layer);
        return true;
      }
    });
    return false;
  };

  const addGeoJsonLayer = (geoJson, coords) => {
    geoJson.geometry.coordinates = coords;
    if (coords.length > 0) {
      L.geoJSON(geoJson, {
        style: geoJsonLayerData.style,
        onEachFeature: function(feature, layer) {
          layer.tag = 'GeoJSONLayer';
        },
      }).addTo(map);
    }
  };

  useEffect(() => {
    removeOldGeoJsonLayer();
    if (geoJsonLayerData) {
      const coords = coordinateFunction();
      addGeoJsonLayer(geoJsonLayerData, coords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJsonLayerData]);

  useEffect(() => {
    const layerKeys = Object.keys(map._layers);
    const layers = map._layers;
    if (!assetLeafletIdRef.current && oldAssetStyle.geo_asset_id) {
      for (let index = 0; index < layerKeys.length; index++) {
        const element = layers[layerKeys[index]];
        if (element?.options?.data?.geo_asset_id === oldAssetStyle.geo_asset_id) {
          assetLeafletIdRef.current = element._leaflet_id;
          break;
        }
      }
    }
    if (assetLeafletIdRef.current && layers[assetLeafletIdRef.current]) {
      const type = layers[assetLeafletIdRef.current].options.data.geo_data.type;
      if (type === 'Point') {
        layers[assetLeafletIdRef.current].setLatLng({
          lat: oldAssetStyle.geo_data.coordinates[0],
          lng: oldAssetStyle.geo_data.coordinates[1],
        });
        oldAssetStyle.style.radius && layers[assetLeafletIdRef.current].setRadius(oldAssetStyle.style.radius);
      } else {
        const newCoords = [];
        for (let index = 0; index < oldAssetStyle.geo_data.coordinates.length; index++) {
          const coord = oldAssetStyle.geo_data.coordinates[index];
          newCoords.push({ lat: coord[0], lng: coord[1] });
        }
        layers[assetLeafletIdRef.current].setLatLngs(newCoords);
      }
      layers[assetLeafletIdRef.current].setStyle(oldAssetStyle.style);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleUpdate]);

  useEffect(() => {
    if ((!rightPanelOpen || !rightPanelData.fromGeoJson) && !rightPanelData?.createdFromGeoJsonLayer) {
      setGeoJsonLayerData();
      setGeoJsonLayerUpdate(!geoJsonLayerUpdate);
    }
    if (assetLeafletIdRef.current) {
      const layers = map._layers;
      for (let index = 0; index < assetData.length; index++) {
        const asset = assetData[index];
        if (asset.geo_asset_id === layers[assetLeafletIdRef.current]?.options?.data?.geo_asset_id) {
          const type = asset.geo_data.type;
          if (type === 'Point' || type === 'Circle') {
            layers[assetLeafletIdRef.current].setLatLng({
              lat: asset.geo_data.coordinates[0],
              lng: asset.geo_data.coordinates[1],
            });
            asset.style.radius
              ? layers[assetLeafletIdRef.current].setRadius(asset.style.radius)
              : layers[assetLeafletIdRef.current].setRadius(2);
          } else {
            const newCoords = [];
            for (let index = 0; index < asset.geo_data.coordinates.length; index++) {
              const coord = asset.geo_data.coordinates[index];
              newCoords.push({ lat: coord[0], lng: coord[1] });
            }
            layers[assetLeafletIdRef.current].setLatLngs(newCoords);
          }
          layers[assetLeafletIdRef.current].setStyle(asset.style);
          break;
        }
      }
    }
    assetLeafletIdRef.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelData, rightPanelOpen, newAssetStyle]);

  useLayoutEffect(() => {
    const typeIds = Object.keys(typeData || []);
    let options = [];
    for (let index = 0; index < typeIds.length; index++) {
      const typeid = typeIds[index];
      options.push({ key: typeid, value: typeid, label: typeData[typeid].name, title: typeData[typeid].name });
    }
    setTypeOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeData]);

  useEffect(() => {
    if (!showVessels) {
      map.eachLayer(function(layer) {
        if (layer?.options?.tag === 'vessel_marker') {
          map.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVessels]);

  const recenterMap = () => {
    mapEvents.setView(appliedCoordinates.split(','), mapDefaultZoom === undefined ? 5 : mapDefaultZoom);
  };

  useEffect(() => {
    if (Object.keys(rightPanelData).length === 0) {
      setTimeout(() => {
        const orderArray = assetsRef.current?.getLayers();
        orderArray?.sort((a, b) => b.options.area - a.options.area);
        orderArray?.forEach(o => {
          o.bringToFront();
        });
      }, 500);
    }
  }, [assetData, rightPanelData]);

  const AssetLayer = assetData.map(asset => {
    return (
      <AssetConstructor
        key={rotateAssets ? asset.geo_asset_id + '-count-' + rotateAssetsCount : asset.geo_asset_id}
        asset={asset}
        zoom={zoom}
        rotate={
          rotateAssets &&
          (asset.geo_asset_id === rightPanelData.geo_asset_id || (!asset.geo_asset_id && !rightPanelData.geo_asset_id))
        }
      />
    );
  });

  return (
    <>
      {globalsaveLoading && (
        <SpinContainer>
          <Spin size={'large'} />
        </SpinContainer>
      )}
      <ZoomControl position="bottomright" />
      <TileLayer
        url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey}
        attribution={options.tileserver_copyright}
        maxNativeZoom={20}
        maxZoom={20}
      />
      <DrawControl />
      <Recenter onClick={recenterMap}>
        <Tooltip
          title={t('Use default centering and zoom')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          placement="left"
        >
          <RecenterContainer>
            <Icon type="center" />
          </RecenterContainer>
        </Tooltip>
      </Recenter>
      {currentNamespace && (
        <Tools>
          <Tooltip
            title={t('Edit assets/points')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A', // draggableAssets ? '#FFF' : '#4A4A4A',
                backgroundColor: draggableAssets ? '#4990DD' : '#FFF',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                setDraggableAssets(!draggableAssets);
                setDeleteAssets(false);
                setRotateAssets(false);
              }}
            >
              <Icon color={draggableAssets ? '#4990DD' : '#FFF'} type="geo-asset-tool-edit" />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('Rotate asset')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A', // draggableAssets ? '#FFF' : '#4A4A4A',
                backgroundColor: rotateAssets ? '#4990DD' : '#FFF',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                if (rightPanelOpen) {
                  setRotateAssets(!rotateAssets);
                  setRotateAssetsCount(count => count + 1);
                  setDraggableAssets(false);
                  setDeleteAssets(false);
                }
              }}
              disabled={!rightPanelOpen}
            >
              <Icon color={rightPanelOpen ? '#4a4a4a' : '#a0a0a0'} type="rotate" />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('Delete assets/points')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A', // deleteAssets ? '#FFF' : '#4A4A4A'
                backgroundColor: deleteAssets ? '#FF0000' : '#FFF',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                setDraggableAssets(false);
                setRotateAssets(false);
                setDeleteAssets(!deleteAssets);
              }}
            >
              <Icon type="trash-bold" />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('Undo')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A',
                backgroundColor: '#FFF',
                borderBottom: '1px solid #ccc',
                filter: `opacity(${undoAvailable ? '1' : '0.6'})`,
                cursor: undoAvailable ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (undoAvailable) {
                  setUndoFade(true);
                  undoFunction();
                }
              }}
              onAnimationEnd={() => {
                setUndoFade(false);
              }}
            >
              <Icon type="undo-alt" className={undoFade ? 'fade' : ''} />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('Redo')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A',
                backgroundColor: '#FFF',
                borderBottom: '1px solid #ccc',
                filter: `opacity(${redoAvailable ? '1' : '0.6'})`,
                cursor: redoAvailable ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (redoAvailable) {
                  setRedoFade(true);
                  redoFunction();
                }
              }}
              onAnimationEnd={() => {
                setRedoFade(false);
              }}
            >
              <Icon type="redo-alt" className={redoFade ? 'fade' : ''} />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('Import GeoJSON')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                drawAssets([], '', {}, true);
              }}
            >
              <Icon type="upload" />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={t('List assets')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4A',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                listAssets();
              }}
            >
              <Icon type="menu" />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={showVessels ? t('Hide vessels') : t('Show vessels')}
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4ADD',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                setShowVessels(!showVessels);
              }}
            >
              <Icon type="vessels-down-center" fill={showVessels ? '#1890FF' : '#4A4A4AAA'} />
            </ToolButton>
          </Tooltip>
          <Tooltip
            title={
              filterList.length > 0
                ? `Manage type data (${filterList.length} type${filterList.length === 1 ? '' : 's'} hidden)`
                : t('Manage type data')
            }
            color="white"
            overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
            placement="left"
          >
            <ToolButton
              style={{
                color: '#4A4A4ADD',
                backgroundColor: '#FFF',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsEditModalVisible(true);
              }}
            >
              <Icon type={filterList.length > 0 ? 'setting-warning' : 'settings'} />
            </ToolButton>
          </Tooltip>
        </Tools>
      )}
      <FeatureGroup ref={assetsRef}>{AssetLayer}</FeatureGroup>
      <CircleMarkers
        vesselsData={!helcomData || !showVessels ? {} : helcomData}
        selectedAisVessel={selectedAisVessel}
        setSelectedAisVessel={setSelectedAisVessel}
        geoAsset={true}
      />
      <TypeEditModal
        isOpen={isEditModalVisible}
        typeData={typeData}
        handleVisibility={() => {
          setIsEditModalVisible(!isEditModalVisible);
        }}
        typeOptions={typeOptions}
        currentNamespace={currentNamespace}
      />
      <WMSChart show={zoom >= 14} />
    </>
  );
};

export default GeoAssetMapInner;
