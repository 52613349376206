import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Alert from 'antd/es/alert';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import List from '../../components/ui/List';
import ListActionButton from '../../components/ui/ListActionButton';

const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const VisReceivedVoyagePlans = props => {
  const { namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { service_id } = props.match.params;

  const defaultParams = {
    from_service_id: service_id,
    limit: 50,
    offset: 0,
    sort: 'time DESC',
  };
  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'vis-voyage-plans', newParams);

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const history = useHistory();

  const visMessages = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;
  let counter = 1;
  visMessages.forEach(p => {
    p._row = start + counter;
    counter++;
  });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: t('From'),
      dataIndex: 'from_name',
      key: 'from_name',
    },
    {
      title: t('To'),
      dataIndex: 'to_name',
      key: 'to_name',
    },
    {
      title: t('Voyage plan ID'),
      dataIndex: 'message_id',
      key: 'message_id',
    },
    {
      title: t('RTZ state'),
      dataIndex: 'rtz_state_name',
      key: 'rtz_state_name',
    },
    {
      title: t('eta'),
      dataIndex: 'eta',
      key: 'eta',
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton
          disabled={!user.permissions.includes('view_vis_information')}
          key="action-1"
          onClick={() =>
            history.push({
              pathname: '/vis-vessels/voyage-plan-view',
              data: record,
            })
          }
        >
          {t('Show')}
        </ListActionButton>
      ),
    },
  ];

  return (
    <Layout pagename={t('VIS Received Voyage Plans')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <Header>{t('VIS received voyage plans from {{service_id}}', { service_id })}</Header>
        <List
          rowKey="id"
          columns={columns}
          dataSource={visMessages}
          spinning={loading}
          actions={actionList}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          hideSearch={true}
        />
      </Page>
    </Layout>
  );
};

export default VisReceivedVoyagePlans;
