import React, { useContext, useState, useEffect, useRef } from 'react';
import { TIME_FORMAT } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import Input from '../../components/ui/Input';
import Icon from '../../components/ui/Icon';
import Label from '../../components/ui/Label';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import DateComponent from '../../components/ui/DateComponent';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const WindowCalendar = styled.div`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const AdminSlotReservations = () => {
  const { apiCall, namespace, user, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const [apiCallPending, setApiCallPending] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [jitEtaDate, setJitEtaDate] = useState(new Date());

  const location = useLocation();

  let mounted = useRef(false);
  useEffect(() => {
    document.title = 'Slot requests | Port Activity App';
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const params = new URLSearchParams(location.search);
  const defaultParams = {
    limit: 50,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'id DESC', // TODO: change to 'rta_window_start DESC NULLS LAST' when api supports it
    search: params.get('search') ? params.get('search') : '',
  };

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'slot-reservations', newParams);
  const { loading: berthsLoading, data: berthsData, error: berthsError } = useApi('get', 'all-nominatable-berths', {});

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  const slotRequests = error || !data ? [] : data.data;
  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  const fetchedNominatableBerths = berthsError || !berthsData ? [] : berthsData;
  let nominatableBerths = [...fetchedNominatableBerths];
  nominatableBerths.unshift({ id: 0, name: 'Not selected' });

  if (error) {
    setAlert({ type: 'error', message: error });
  }

  const initModal = {
    visible: false,
    confirmLoading: false,
    datesDirty: false,
    berthsDirty: false,
  };

  const [modal, setModal] = useState(initModal);

  const initSlotRequest = {
    id: null,
    laytime: '',
    jit_eta: '',
    rta_window_start: '',
  };

  const [slotRequest, setSlotRequest] = useState(initSlotRequest);

  const showModal = async id => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('get', 'slot-reservation-by-id', { id });
      data['rta_window_start'] = data['rta_window_start']
        ? data['rta_window_start']
        : dayjs.utc().format('YYYY-MM-DDTHH:mm:ss+00:00');
      data['jit_eta'] = data['jit_eta'] ? data['jit_eta'] : data['rta_window_start'];
      let rta_window_start = dayjs.utc(dayjs(data['rta_window_start'])).format('YYYY-MM-DDTHH:mm:ss+00:00');
      let jit_eta = dayjs.utc(dayjs(data['jit_eta'])).format('YYYY-MM-DDTHH:mm:ss+00:00');
      setStartDate(dayjs(data['rta_window_start']).toDate());
      setJitEtaDate(dayjs(data['jit_eta']).toDate());
      setSlotRequest({ ...data, rta_window_start, jit_eta });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    let isoLaytime = dayjs.duration(slotRequest.laytime).toISOString();
    try {
      await apiCall('put', 'slot-reservations', {
        id: slotRequest.id,
        laytime: isoLaytime,
        jit_eta: slotRequest.jit_eta,
        rta_window_start: slotRequest.rta_window_start,
      });
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    let params = newParams;
    await fetchData(false, params);
  };

  const handleModalCancel = () => {
    setModal(initModal);
  };

  const handleModalChange = e => {
    setSlotRequest({ ...slotRequest, [e.target.name]: e.target.value });
  };

  const handleCancelSlotRequest = async id => {
    setApiCallPending(true);
    try {
      await apiCall('delete', 'slot-reservations', { id, cancel_only: true, by_vessel: false });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    let params = newParams;
    await fetchData(false, params);
  };

  const handleRtaWindowStartChange = e => {
    setStartDate(e);
    let startMoment = dayjs(e);
    startMoment.set({ second: 0 });
    if (modal.visible) {
      modal.datesDirty = true;
      slotRequest.rta_window_start = dayjs.utc(startMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const handleJitEtaChange = e => {
    setJitEtaDate(e);
    let jitEtaMoment = dayjs(e);
    jitEtaMoment.set({ second: 0 });
    if (modal.visible) {
      modal.datesDirty = true;
      slotRequest.jit_eta = dayjs.utc(jitEtaMoment).format('YYYY-MM-DDTHH:mm:ss+00:00');
    }
  };

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('IMO'),
      dataIndex: 'imo',
      key: 'imo',
      sortableKey: 'imo',
    },
    {
      title: t('Vessel name'),
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      sortableKey: 'vessel_name',
    },
    {
      title: t('ETA'),
      dataIndex: 'eta',
      key: 'eta',
      sortableKey: 'eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('RTA start'),
      dataIndex: 'rta_window_start',
      key: 'rta_window_start',
      sortableKey: 'rta_window_start',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('RTA end'),
      dataIndex: 'rta_window_end',
      key: 'rta_window_end',
      sortableKey: 'rta_window_end',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Laytime'),
      dataIndex: 'laytime',
      key: 'laytime',
      sortableKey: 'laytime',
      render: record => {
        return dayjs.duration(record).format('HH:mm');
      },
    },
    {
      title: t('JIT eta'),
      dataIndex: 'jit_eta',
      key: 'jit_eta',
      sortableKey: 'jit_eta',
      render: record => record && <DateComponent format={TIME_FORMAT} date={record} />,
    },
    {
      title: t('Berth'),
      dataIndex: 'berth_name',
      key: 'berth_name',
      sortableKey: 'berth_name',
    },
    {
      title: t('Request status'),
      dataIndex: 'readable_slot_reservation_status',
      key: 'readable_slot_reservation_status',
      sortableKey: 'readable_slot_reservation_status',
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          disabled={
            !(
              record.slot_reservation_status === 'offered' ||
              record.slot_reservation_status === 'accepted' ||
              record.slot_reservation_status === 'updated'
            ) || !user.permissions.includes('manage_queue_slot_reservation')
          }
          onClick={() => showModal(record.id)}
          key="action-1"
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Cancel request {{id}}?', { id: record.id })}
          onConfirm={() => handleCancelSlotRequest(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton
              red
              disabled={
                record.slot_reservation_status === 'cancelled_by_port' ||
                record.slot_reservation_status === 'cancelled_by_vessel' ||
                record.slot_reservation_status === 'completed' ||
                !user.permissions.includes('manage_queue_slot_reservation')
              }
            >
              <Icon type="trash" />
              {t('Cancel')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Layout pagename={t('Slot Requests')}>
      <Modal
        title={t('Edit slot request {{id}}', { id: slotRequest.id })}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <WindowCalendar>
              <Label>{t('RTA window start')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 5 }}
                style={{
                  width: '180px',
                }}
                value={startDate ? dayjs(startDate) : null}
                onChange={handleRtaWindowStartChange}
              />
            </WindowCalendar>
            <WindowCalendar>
              <Label>{t('JIT ETA')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 5 }}
                style={{
                  width: '180px',
                }}
                value={jitEtaDate ? dayjs(jitEtaDate) : null}
                onChange={handleJitEtaChange}
              />
            </WindowCalendar>
            <Input name="laytime" label="laytime" value={slotRequest.laytime} onChange={handleModalChange} />
          </Spin>
        </ModalInner>
      </Modal>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <List
          rowKey="id"
          columns={columns}
          dataSource={slotRequests}
          apiCallPending={apiCallPending}
          actions={actions}
          spinning={loading || berthsLoading}
          setParams={setNewParams}
          newParams={newParams}
          start={start}
          total={total}
          searchPlaceHolder={t('Search by name or exact IMO')}
        />
      </Page>
    </Layout>
  );
};

export default AdminSlotReservations;
