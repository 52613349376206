import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/es/checkbox';
import Spin from 'antd/es/spin';
import Switch from 'antd/es/switch';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';

const Loader = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Container = styled.div`
  display: flex;
  margin-left: 12px;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  margin-left: 20px;
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-left: 12px;
  margin-bottom: 12px;
`;

const Header2 = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-left: 12px;
  margin-bottom: 30px;
  max-width: 600px;
`;

const ListContainer = styled.div`
  display: flex;
`;

const SwitchLabel = styled.div`
  margin-left: 12px;
  line-height: 22px;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 18px;

  button {
    margin-top: 3px;
  }
`;

const Wrapper = styled.div`
  padding: 18px 28px 18px 16px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  margin: 5px 10px;
`;

const TimestampFiltering = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [clicked, setClicked] = useState(false);

  const { loading, data, error, fetchData } = useApi('get', 'settings');

  const typeListObj =
    error || !data
      ? undefined
      : data.find(element => Object.prototype.hasOwnProperty.call(element, 'timestamp_filter_list'));
  const typeList = typeListObj ? typeListObj.timestamp_filter_list : [];
  const showFunctionalityObj =
    error || !data
      ? undefined
      : data.find(element => Object.prototype.hasOwnProperty.call(element, 'timestamp_hide_empty'));
  const showFunctionality = showFunctionalityObj ? showFunctionalityObj.timestamp_hide_empty === 'enabled' : false;

  const handleToggle = async (key, visible) => {
    setClicked(true);
    const index = typeList.findIndex(t => t.key === key);
    let options = [...typeList];
    options.splice(index, 1, { key, visible: !visible });

    const result = await apiCall('put', 'timestamp-filter-settings', { value: options });
    if (result?.status === 200) {
      fetchData();
    }

    setClicked(false);
  };

  const handleShowFunctionalityToggle = async () => {
    setClicked(true);

    const result = await apiCall('put', 'settings', {
      name: 'timestamp_hide_empty',
      value: showFunctionality ? 'disabled' : 'enabled',
    });
    if (result?.status === 200) {
      fetchData();
    }

    setClicked(false);
  };

  const firstColumn = typeList.filter((p, i) => i < typeList.length / 2);
  const secondColumn = typeList.filter((p, i) => i >= typeList.length / 2);

  return (
    <Page>
      {(loading || clicked) && (
        <Loader>
          <Spin size="large" />
        </Loader>
      )}
      <Wrapper>
        <Header>{t('Timestamp filtering')}</Header>
        <Container>
          <Switch checked={showFunctionality} onChange={() => handleShowFunctionalityToggle()} />
          <Label>{t('Enable timestamp filtering for all users in the Activity view')}</Label>
        </Container>
      </Wrapper>

      <Wrapper>
        {!showFunctionality && (
          <Header2 style={{ fontWeight: 600 }}>
            {/*eslint-disable-next-line quotes*/}
            {t('Timestamp filtering needs to be enabled first.')}
          </Header2>
        )}
        <Header2>
          {/*eslint-disable-next-line quotes*/}
          {t(
            'When timestamp filtering is enabled, the user will see all timestamps that are available and all timestamp types that are selected. This set is used as a default selection for the users, but they can override these in the timestamp filtering modal in their activity view if they want.'
          )}
        </Header2>
        <ListContainer>
          <div style={{ marginRight: '100px' }}>
            {firstColumn.map((item, i) => (
              <SwitchContainer key={i}>
                <Checkbox
                  size="small"
                  checked={item.visible}
                  disabled={!showFunctionality}
                  onChange={() => handleToggle(item.key, item.visible)}
                />
                <SwitchLabel>{t(item.key)}</SwitchLabel>
              </SwitchContainer>
            ))}
          </div>
          <div>
            {secondColumn.map((item, i) => (
              <SwitchContainer key={i}>
                <Checkbox
                  size="small"
                  checked={item.visible}
                  disabled={!showFunctionality}
                  onChange={() => handleToggle(item.key, item.visible)}
                />
                <SwitchLabel>{t(item.key)}</SwitchLabel>
              </SwitchContainer>
            ))}
          </div>
        </ListContainer>
      </Wrapper>
    </Page>
  );
};

export default TimestampFiltering;
