import React, { createContext, useEffect, useCallback, useState, useContext } from 'react';
import dayjs from 'dayjs';

import useApi from '../hooks/useApi';

import {
  getFromAndToDateForDayForecast,
  getFromAndToDateForForecast,
  getFromAndToDateForSeaLevelForecast,
} from '../components/weather/weatherUtils';
import { UserContext } from './UserContext';

export const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
  const { namespace, modules } = useContext(UserContext);

  const [stationUidFromLocalStorage, setStationUidFromLocalStorage] = useState(undefined);
  const [chosenStation, setChosenStation] = useState(undefined);

  const storageKey = 'selectedStation_' + namespace;

  useEffect(() => {
    const getStationFromLocalStorage = () => {
      const value = localStorage.getItem(storageKey);
      if (!value) {
        return undefined;
      }

      return value;
    };

    const savedStation = getStationFromLocalStorage();

    if (savedStation) {
      setStationUidFromLocalStorage(savedStation);
    }
  }, [storageKey]);

  const chooseStation = station => {
    localStorage.setItem(storageKey, station.uid);
    setChosenStation(station);
  };
  const { loading: loadingStations, data: stationsData, error: stationsError } = useApi(
    'get',
    'location-lookup/nearby',
    {
      type: 'weather_station',
      radius: 50000,
    },
    null,
    modules.weather_module === 'enabled'
  );

  const { loading: loadingCurrent, data: currentData, error: currentError, fetchData: fetchCurrentData } = useApi(
    'get',
    'weather/current',
    chosenStation
      ? {
          uid: chosenStation.uid,
        }
      : {},
    null,
    !!chosenStation
  );

  const {
    loading: loadingForecastForDay,
    data: forecastForDayData,
    error: forecastForDayError,
    fetchData: fetchForecastForDayData,
  } = useApi(
    'get',
    'weather/forecast',
    chosenStation
      ? {
          ...getFromAndToDateForDayForecast(),
          step: 'PT6H',
          uid: chosenStation.uid,
        }
      : {
          ...getFromAndToDateForDayForecast(),
          step: 'PT6H',
        },
    null,
    !!chosenStation
  );

  const { loading: loadingForecast, data: forecastData, error: forecastError, fetchData: fetchForecastData } = useApi(
    'get',
    'weather/forecast',
    chosenStation
      ? {
          ...getFromAndToDateForForecast(),
          step: 'PT1H',
          uid: chosenStation.uid,
        }
      : {
          ...getFromAndToDateForForecast(),
          step: 'PT1H',
        },
    null,
    !!chosenStation
  );

  const {
    loading: loadingCurrentSeaLevel,
    data: currentSeaLevelData,
    error: currentSeaLevelError,
    fetchData: fetchCurrentSeaLevelData,
  } = useApi('get', 'sea-level/observation', null, null, modules.sea_level_module === 'enabled');

  const {
    loading: loadingSeaLevelForecast,
    data: seaLevelForecastData,
    error: seaLevelForecastError,
    fetchData: fetchSeaLevelForecastData,
  } = useApi(
    'get',
    'sea-level/forecast',
    {
      step: 'PT1H',
      ...getFromAndToDateForSeaLevelForecast(),
    },
    null,
    modules.sea_level_module === 'enabled'
  );

  const currentWeather = currentError || !currentData ? {} : currentData;
  const forecastForDayWeather = forecastForDayError || !forecastForDayData ? [] : forecastForDayData.forecast;
  const forecastWeather = forecastError || !forecastData ? [] : forecastData.forecast;

  const stationList = stationsError || !stationsData ? [] : stationsData.results;

  const currentSeaLevel = currentSeaLevelError || !currentSeaLevelData ? null : currentSeaLevelData;
  const seaLevelForecast = seaLevelForecastError || !seaLevelForecastData ? null : seaLevelForecastData.forecast;

  if (stationUidFromLocalStorage && !chosenStation && stationList && stationList.length) {
    const station = stationList.find(s => s.uid === stationUidFromLocalStorage);
    setChosenStation(station || stationList[0]);
  }

  if (chosenStation && stationList && stationList.length && !stationList.find(s => s.uid === chosenStation.uid)) {
    localStorage.setItem(storageKey, stationList[0].uid);
    setChosenStation(stationList[0]);
  }

  if (!stationUidFromLocalStorage && !chosenStation && stationList && stationList.length) {
    localStorage.setItem(storageKey, stationList[0].uid);
    setChosenStation(stationList[0]);
  }

  useEffect(() => {
    if (chosenStation && modules.weather_module === 'enabled') {
      fetchCurrentData(false, {
        uid: chosenStation.uid,
      });
      fetchForecastForDayData(false, {
        ...getFromAndToDateForDayForecast(),
        step: 'PT6H',
        uid: chosenStation.uid,
      });
      fetchForecastData(false, {
        ...getFromAndToDateForForecast(),
        step: 'PT1H',
        uid: chosenStation.uid,
      });
    }
  }, [chosenStation, fetchCurrentData, fetchForecastData, fetchForecastForDayData]); //eslint-disable-line

  const reFetch = useCallback(() => {
    if (modules.weather_module === 'enabled') {
      fetchCurrentData(
        false,
        chosenStation
          ? {
              uid: chosenStation.uid,
            }
          : {}
      );
      fetchForecastForDayData(
        false,
        chosenStation
          ? {
              ...getFromAndToDateForDayForecast(),
              step: 'PT6H',
              uid: chosenStation.uid,
            }
          : {
              ...getFromAndToDateForDayForecast(),
              step: 'PT6H',
            }
      );
      fetchForecastData(
        false,
        chosenStation
          ? {
              ...getFromAndToDateForForecast(),
              step: 'PT1H',
              uid: chosenStation.uid,
            }
          : {
              ...getFromAndToDateForForecast(),
              step: 'PT1H',
            }
      );

      if (modules.sea_level_module === 'enabled') {
        fetchCurrentSeaLevelData(false, null);
        fetchSeaLevelForecastData(false, {
          step: 'PT1H',
          ...getFromAndToDateForSeaLevelForecast(),
        });
      }
    }
  }, [chosenStation, fetchCurrentData, fetchForecastData, fetchForecastForDayData]); //eslint-disable-line

  useEffect(() => {
    const timer = setInterval(reFetch, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, [reFetch]);

  const lastWeatherUpdate = currentWeather.time ? dayjs(currentWeather.time).format('HH:mm') : '-';

  return (
    <WeatherContext.Provider
      value={{
        loadingCurrent,
        currentWeather,
        loadingForecastForDay,
        forecastForDayWeather,
        loadingForecast,
        forecastWeather,
        lastWeatherUpdate,
        loadingStations,
        stationList,
        chosenStation,
        chooseStation,
        loadingCurrentSeaLevel,
        loadingSeaLevelForecast,
        currentSeaLevel,
        seaLevelForecast,
      }}
    >
      {children}
    </WeatherContext.Provider>
  );
};
