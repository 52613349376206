import React from 'react';
import styled from 'styled-components';

import StatisticsKeyValues from './StatisticsKeyValues';
import StatisticsBar2ColumnWithTime from './StatisticsBar2ColumnWithTime';
import StatisticsBar2ColumnCompare from './StatisticsBar2ColumnCompare';
import StatisticsBar1ColumnWithoutLegend from './StatisticsBar1ColumnWithoutLegend';
import StatisticsBar1Column from './StatisticsBar1Column';
import StatisticsLatestUpdates from './StatisticsLatestUpdates';
import StatisticsDoughnut from './StatisticsDoughnut';
import LineWithTimeGraph from './LineWithTimeGraph';
import ClusterGraphs from './ClusterGraphs';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: inline-block;
  }
`;

const Column = styled.div`
  width: 100%;
  min-height: 100px;
  padding-right: 14px;
  display: inline-block;
`;

const Column1 = styled.div`
  width: 65%;
  min-height: 100px;
  padding-right: 14px;
  display: inline-block;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    width: 100%;
    padding-right: 0px;
  }
`;

const Column2 = styled.div`
  width: 35%;
  min-height: 100px;
  padding-left: 14px;
  display: inline-block;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    width: 100%;
    padding-left: 0px;
  }
`;

const StatisticsStructure = ({ structure, elements }) => {
  const getGraphType = (element, index, column1, oneColumn) => {
    const graphData = elements.find(e => e.id === element);

    if (graphData) {
      switch (graphData.type) {
        case 'key-values':
          return <StatisticsKeyValues key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'bar-2-column-time':
          return <StatisticsBar2ColumnWithTime key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'bar-2-column-compare':
          return <StatisticsBar2ColumnCompare key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'bar-1-column-without-legend':
          return (
            <StatisticsBar1ColumnWithoutLegend key={index} column1={column1} oneColumn={oneColumn} {...graphData} />
          );
        case 'bar-1-column':
          return (
            <StatisticsBar1Column key={index} column1={column1} oneColumn={oneColumn} {...graphData} yAxel={true} />
          );
        case 'bar-1-column-without-y-axel':
          return (
            <StatisticsBar1Column key={index} column1={column1} oneColumn={oneColumn} {...graphData} yAxel={false} />
          );
        case 'latest-updates':
          return <StatisticsLatestUpdates key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'pie-with-legend':
          return <StatisticsDoughnut key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'line-with-time':
          return <LineWithTimeGraph key={index} column1={column1} oneColumn={oneColumn} {...graphData} />;
        case 'bar-multi-column-cluster':
        case 'pie-with-legend-cluster':
          return (
            <ClusterGraphs
              graphType={graphData.type}
              key={index}
              column1={column1}
              oneColumn={oneColumn}
              {...graphData}
            />
          );

        default:
          return null;
      }
    }

    return null;
  };

  return structure.length === 1 ? (
    <Container>
      <Column>{structure[0].map((element, index) => getGraphType(element, index, true, true))}</Column>
    </Container>
  ) : (
    <Container>
      <Column1>{structure[0].map((element, index) => getGraphType(element, index, true, false))}</Column1>
      <Column2>{structure[1].map((element, index) => getGraphType(element, index, false, false))}</Column2>
    </Container>
  );
};
export default StatisticsStructure;
