import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import { invoicingCategoryColors } from './invoiceHelps/invoiceHelpers';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import dayjs from 'dayjs';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: auto;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 280px);
  min-width: 952px;
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Category = styled.div`
  display: flex;
  margin-right: 18px;
`;

const Limits = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Limit = styled.div`
  background-color: ${props => (props.selected ? props.theme.color.secondary : '#ffffff')};
  color: ${props => (props.selected ? '#ffffff' : '#4a4a4a')};
  border: 1px solid #a0a0a0;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.color.secondary};
  }
`;

const Header = styled.div`
  margin-right: 24px;
  font-weight: 600;
  line-height: 30px;
`;

const MatrixContainer = styled.div`
  margin-top: 6px;
  background-color: #f2f2f2;
  margin-right: 12px;
  padding: 6px 12px 12px 0;
  border-radius: 4px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Units = styled.div`
  margin-left: 48px;
  margin-right: 12px;
  font-weight: 600;
`;

const Capitalizing = styled.div`
  text-transform: capitalize;
`;

const Cell = styled.td`
  min-width: 100px;
  text-align: center;
  background-color: ${props => props.color};
  font-size: 12px;
  font-weight: 600;
  border-bottom: ${props => props.bottomBorder && '1px solid #a8a8a8'};
`;

const ColorRectangle = styled.div`
  background-color: ${props => props.color};
  width: 18px;
  height: 18px;
  margin-right: 12px;
  margin-top: 2px;
  border-radius: 3px;
`;

const PublicHolidays = styled.div`
  margin-top: 24px;
`;

const Price = styled.div`
  margin-left: 12px;
`;

const PublicHoliday = styled.div`
  background-color: ${props => props.color};
  padding: 6px 24px;
  margin-bottom: 6px;
  display: inline-flex;
  border-radius: 3px;
  font-weight: 600;
`;

const PriceMatrixModal = ({ product, closeModal }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { defaultCurrency } = useContext(NewInvoicingContext);

  const currency = defaultCurrency || 'EUR';

  const [selection, setSelection] = useState(0);

  const { pricing } = product;

  const getDynamicLimitValueTitle = limit => {
    return limit.limits.lower + ' - ' + (limit.limits.upper || '');
  };

  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const colors = invoicingCategoryColors;

  const rowAmount =
    product.pricing.matrix.resolution_minutes === 60 ? 24 : product.pricing.matrix.resolution_minutes === 30 ? 48 : 96;

  const publicHolidays = [];
  const categories = [];
  product.pricing.unit_prices.forEach((p, limitIndex) => {
    p.timings.forEach(timing => {
      if (categories.findIndex(c => c === timing.frontend_data) === -1) {
        categories.push(timing.frontend_data);
      }
      timing.values.forEach(v => {
        if (v.day === 'public_holiday' && limitIndex === selection) {
          publicHolidays.push({
            category: timing.frontend_data,
            data: v,
            price: timing.price,
          });
        }
      });
    });
  });

  const getTime = index => {
    if (rowAmount === 24) {
      return index + ':00';
    } else if (rowAmount === 48) {
      return Math.floor(index / 2) + (index % 2 === 1 ? ':30' : ':00');
    } else {
      return (
        Math.floor(index / 4) + (index % 4 === 3 ? ':45' : index % 4 === 2 ? ':30' : index % 4 === 1 ? ':15' : ':00')
      );
    }
  };

  const getColor = cat => {
    const index = categories.findIndex(c => c === cat) || 0;
    return colors[index];
  };

  const monList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'mon');
  const tueList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'tue');
  const wedList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'wed');
  const thuList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'thu');
  const friList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'fri');
  const satList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'sat');
  const sunList = product.pricing.matrix.matrix[selection].unit_prices.filter(u => u.day === 'sun');

  const getCellData = (day, index) => {
    switch (day) {
      case 'mon':
        return monList[index];
      case 'tue':
        return tueList[index];
      case 'wed':
        return wedList[index];
      case 'thu':
        return thuList[index];
      case 'fri':
        return friList[index];
      case 'sat':
        return satList[index];
      case 'sun':
        return sunList[index];
      default:
        return '-';
    }
  };

  const getHolidayTimes = holiday => {
    const from = dayjs(holiday.data.from, 'HH:mm:ss');
    const to = dayjs(holiday.data.to, 'HH:mm:ss').add(1, 'second');

    return from.format('HH:mm') + ' - ' + to.format('HH:mm');
  };

  let unitString = ' / ' + product.pricing.units[0].name;
  if (product.pricing.units[1]) {
    unitString += ' / ' + product.pricing.units[1].name;
  }

  return (
    <Modal
      title={t('Pricing matrix for {{name}}', { name: product.name })}
      open={true}
      width={1000}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <ModalInnerInput>
        <ScrollContainer>
          <Limits>
            <Header>{t('Limits')}:</Header>
            {pricing.unit_prices.map((limit, i) => (
              <Limit
                key={limit.limits.lower}
                style={{ minWidth: '50px', marginRight: '18px' }}
                onClick={() => {
                  setSelection(i);
                }}
                selected={selection === i}
              >
                {getDynamicLimitValueTitle(limit)}
              </Limit>
            ))}
          </Limits>
          <TopRow>
            <Categories>
              {categories.map((cat, j) => (
                <Category key={cat}>
                  <ColorRectangle color={colors[j]} />
                  {cat}
                </Category>
              ))}
            </Categories>
            <Units>
              {currency} {unitString}
            </Units>
          </TopRow>
          <MatrixContainer>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  {['Hours', ...days].map((day, index) => {
                    return (
                      <th key={index}>
                        <Capitalizing>{day}</Capitalizing>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {[...Array(rowAmount)].map((x, rowIndex) => (
                  <tr key={rowIndex}>
                    <Cell key={rowIndex} style={{ width: '70px' }}>
                      {getTime(rowIndex)}
                    </Cell>
                    {days.map((day, dayIndex) => {
                      const cell = getCellData(day, rowIndex);
                      return cell ? (
                        <Cell
                          key={dayIndex}
                          color={getColor(cell.frontend_data)}
                          bottomBorder={
                            rowAmount === 96 ? rowIndex % 4 === 3 : rowAmount === 48 ? rowIndex % 2 === 1 : false
                          }
                        >
                          {cell.price}
                        </Cell>
                      ) : (
                        <Cell key={dayIndex}>{t('N/A')}</Cell>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </MatrixContainer>
          {publicHolidays.length > 0 && (
            <PublicHolidays>
              <Header>{t('Public holidays')}</Header>
              {publicHolidays.map((p, index) => {
                return (
                  <PublicHoliday key={index} color={getColor(p.category)}>
                    {getHolidayTimes(p)}
                    <Price>
                      {p.price} {currency} {unitString}
                    </Price>
                  </PublicHoliday>
                );
              })}
            </PublicHolidays>
          )}
        </ScrollContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Close')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default PriceMatrixModal;
