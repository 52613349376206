import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Tabs from 'antd/es/tabs';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';

import ApiKeyWeightsTimestamps from '../../components/admin/ApiKeyWeightsTimestamps';
import ApiKeyWeightsPayloads from '../../components/admin/ApiKeyWeightsPayloads';

const TabContainer = styled.div`
  .ant-tabs-tab {
    padding: 0 0 16px 0 !important;
  }
`;

const AdminApiKeyWeights = props => {
  const { selected } = props.match.params;
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();

  useEffect(() => {
    document.title = 'API key priorities | Port Activity App';
  }, []);

  const handleTabChange = e => {
    history.push('/admin/api-key-weights/' + e);
  };

  const tabItems = [
    {
      label: t('Timestamps'),
      children: <ApiKeyWeightsTimestamps />,
      key: 'timestamps',
    },
    {
      label: t('Payloads'),
      children: <ApiKeyWeightsPayloads />,
      key: 'payloads',
    },
  ];

  return (
    <Layout pagename={t('API Key Priorities')}>
      <Page fullWidth>
        <TabContainer>
          <Tabs defaultActiveKey={selected} animated={false} size="large" onChange={handleTabChange} items={tabItems} />
        </TabContainer>
      </Page>
    </Layout>
  );
};

export default AdminApiKeyWeights;
