import Spin from 'antd/es/spin';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InvoicingContext } from '../../context/InvoicingContext';
import { UserContext } from '../../context/UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  overflow-x: auto;
  table {
    margin-left: 6px;
    border-collapse: collapse;
  }
  table thead tr:first-child {
    background: #f8f8f8;
    padding: 30px 0;
    font-weight: normal !important;
    line-height: 4 !important;
  }
  tbody tr td:first-child {
    background: #f8f8f8;
    width: 100px;
    font-weight: normal;
  }
  tbody tr td {
    font-weight: normal;
    font-size: 14px;
    min-width: 80px;
    line-height: 2 !important;
  }

  @media (max-width: 650px) {
    table {
      margin-left: 0px;
    }
  }
`;

const Cell = styled.td`
  min-width: 100px;
  text-align: center;
  background-color: ${props => props.color};
  font-size: 12px;
  font-weight: 600;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
`;

const PricingTableMatrix = () => {
  const { categories, selectedNetTonnage, matrixData } = useContext(InvoicingContext);
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const getColor = category => {
    return categories[category]?.color;
  };

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return (
    <Container>
      {matrixData?.[selectedNetTonnage] ? (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              {['Hours', ...days].map((day, index) => {
                return <th key={index}>{day}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {[...Array(24)].map((x, rowIndex) => (
              <tr key={rowIndex}>
                <Cell key={rowIndex}>{rowIndex}:00</Cell>
                {days.map((day, dayIndex) => {
                  const cell = matrixData[selectedNetTonnage].find(
                    matrixItem => matrixItem.time === rowIndex && matrixItem.day === day
                  );
                  return cell ? (
                    <Cell key={dayIndex} color={getColor(cell.category)}>
                      {cell.price}
                    </Cell>
                  ) : (
                    <Cell key={dayIndex}>{t('N/A')}</Cell>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <LoaderContainer>
          <Spin size="large" />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default PricingTableMatrix;
