import React, { useContext } from 'react';
import Vessel from './Vessel';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';

const NotPlannedVessels = ({ vessels, open, berth, draggingValue }) => {
  const { dynamicBollards, rowHeight, collapsedRowHeight } = useContext(BerthPlanningToolContext);
  const heightMultiplier = dynamicBollards ? 2 : 1;
  const notPlannedRowStart =
    rowHeight + heightMultiplier * (open ? rowHeight : collapsedRowHeight) * berth.subRows.length;

  return (
    <>
      {vessels.map(v => {
        const notPlannedPlace = notPlannedRowStart + v.not_planned_row * 2 * (open ? rowHeight : collapsedRowHeight);
        return (
          <Vessel
            key={v.tagId}
            vessel={v}
            open={open}
            berth={berth}
            notPlannedPlace={notPlannedPlace}
            draggingValue={draggingValue}
          />
        );
      })}
    </>
  );
};

export default NotPlannedVessels;
