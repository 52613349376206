import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import { Popup as PopupComponent } from 'reactjs-popup';
import styled from 'styled-components';

import Icon from '../ui/Icon';

const PopupWrapper = styled.div`
  position: relative;
`;

const Modal = styled.div`
  background: ${({ theme }) => theme.color.grey_lighter};
  padding: 32px 20px 20px 20px;
  border: 1px solid #000;
  border-radius: 10px;
  max-width: 250px;
  position: fixed;
  right: 435px;
  top: 110px;
  @media (min-height: 845px) {
    top: 430px;
  }
  @media (min-height: 1034px) {
    top: 700px;
    max-width: 405px;
    right: 20px;
  }
`;

const CloseIcon = styled.a`
  position: absolute;
  top: 6px;
  left: 6px;
`;

const PopupButton = styled.button`
  background: ${({ theme }) => theme.color.primary};
  border: none;
  cursor: pointer;
  text-decoration: underline;
  min-width: 156px;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const Popup = ({ isOpen, setIsOpen }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const closeModal = () => setIsOpen(false);
  return (
    <PopupWrapper>
      <PopupButton type="button" className="button" onClick={() => setIsOpen(true)}>
        {t('Terms and Conditions')}
      </PopupButton>
      <PopupComponent open={isOpen} onClose={closeModal} position="left center" arrow={true}>
        <Modal className="modal">
          <CloseIcon className="close" href="#" onClick={() => setIsOpen(false)}>
            <Icon type="x-circle" color={({ theme }) => theme.color.grey_dark} fill="none" />
          </CloseIcon>
          {t('GDPR notice')}
        </Modal>
      </PopupComponent>
    </PopupWrapper>
  );
};

export default Popup;
