import React, { useRef, useEffect, useCallback, useContext, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { Sidebar } from './Sidebar';
import Columns from '../../commonCalendar/Columns';
import { CalendarRows } from './CalendarRows';
import ScrollElement from '../../commonCalendar/ScrollElement';

import { getCanvasWidth } from '../../commonCalendar/utility/calendar';
import TimelineHeaders from './headers/TimelineHeaders';
import DateHeader from './headers/DateHeader';
import VerticalScrollBar from '../../commonCalendar/VerticalScrollBar';

import Slider from 'antd/es/slider';
import Spin from 'antd/es/spin';

import Icon from '../../ui/Icon';

import { FleetViewContext } from '../../../context/FleetViewContext';

import { sideModalWidth } from '../../commonCalendar/utility/constants';
import VesselSidePanelContainer from './VesselSidePanelContainer';

const TimelineContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  height: 100%;
  position: relative;
  width: ${props => props.width};
`;

const EmptySpaceForScrollbar = styled.div`
  height: 10px;
`;

const OuterComponent = styled.div`
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  height: calc(100% - 66px);
  position: relative;
`;

const ZoomContainer = styled.div`
  z-index: 110;
  position: absolute;
  right: 5px;
  bottom: 20px;
  display: inline;
`;

const ZoomIcon = styled(Icon)`
  display: flex;
  z-index: 110;
  width: 17px;
  min-width: 17px;
  height: 17px;
  min-height: 17px;
  background-color: white;
  color: #626262;
  left: 15.5px;
  border-radius: 3px;
  :hover {
    cursor: pointer;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const ZoomSlider = styled(Slider)`
  z-index: 111;
  height: 100px;
  min-width: 28px;
  min-height: 80px;
  padding-left: 12px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding-top: 150px;
`;

const ReactCalendarTimeline = () => {
  const {
    visibleTimeStart,
    visibleTimeEnd,
    canvasTimeStart,
    width,
    canvasWidth,
    setWidth,
    timelineUnit,
    setCanvasWidth,
    ports = {},
    loader,
    loadMoreLeft,
    loadMoreRight,
    setRowHeightFunction,
    setZoomSliderValue,
    zoomSliderValue,
    setScrollOffset,
    forceUpdate,
    setForceUpdate,
    getLeftOffsetFromDate,
    height,
    indicatorLine,
    canvasTimeEnd,
    setIndicatorLine,
    sideModalOpen,
    sidebarWidth,
    sidebarSelection,
  } = useContext(FleetViewContext);

  const [scrollbarWidth, setScrollbarWidth] = useState(undefined);
  const [portsAvailable, setPortsAvailable] = useState(undefined);

  const [scrollElementScrollTop, setScrollElementScrollTop] = useState(undefined);

  const visibleTimeStartRef = useRef();
  const canvasTimeStartRef = useRef();
  const widthRef = useRef();

  const scrollComponentRef = useRef();
  const scrollHeaderRef = useRef();
  const containerRef = useRef();

  const scrollUpdateRef = useRef();
  const outerRef = useRef();
  const sideModalRef = useRef();

  useEffect(() => {
    scrollUpdateRef.current = true;
  }, []);

  const resize = useCallback(() => {
    const { width: containerWidth } = containerRef.current.getBoundingClientRect();

    let width2 = containerWidth - sidebarWidth;

    if (width !== width2) {
      setWidth(width2);
      setCanvasWidth(getCanvasWidth(width2));
    }

    scrollComponentRef.current.scrollLeft = width2;
    scrollHeaderRef.current.scrollLeft = width2;
    setScrollOffset(width2);
  }, [sidebarWidth, width, setScrollOffset, setWidth, setCanvasWidth]);

  useEffect(() => {
    if (sideModalOpen !== sideModalRef.current && (!sideModalRef.current || !sideModalOpen)) {
      resize();
    }
    sideModalRef.current = sideModalOpen;
  }, [resize, sideModalOpen]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useLayoutEffect(() => {
    if (visibleTimeStartRef.current !== visibleTimeStart) {
      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollUpdateRef.current = true;
      scrollComponentRef.current.scrollLeft = scrollLeft;
    }
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (visibleTimeStartRef.current !== visibleTimeStart) {
      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollHeaderRef.current.scrollLeft = scrollLeft;
      setScrollOffset(scrollLeft);
    }

    if (forceUpdate && visibleTimeStartRef.current === visibleTimeStart) {
      setForceUpdate(false);

      const viewWidthInTime = visibleTimeEnd - visibleTimeStart;
      const scrollLeft = Math.round((width * (visibleTimeStart - canvasTimeStart)) / viewWidthInTime);
      scrollHeaderRef.current.scrollLeft = scrollLeft;
      scrollComponentRef.current.scrollLeft = scrollLeft;
      setScrollOffset(scrollLeft);
    }

    if (width !== widthRef.current) {
      resize();
    }

    if (Object.keys(ports).length && !portsAvailable) {
      setPortsAvailable(true);
      resize();
    }

    widthRef.current = width;
    visibleTimeStartRef.current = visibleTimeStart;
    canvasTimeStartRef.current = canvasTimeStart;
  }, [
    visibleTimeStart,
    width,
    ports,
    portsAvailable,
    canvasTimeStart,
    visibleTimeEnd,
    resize,
    setScrollOffset,
    forceUpdate,
    setForceUpdate,
    sidebarSelection,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onScroll = scrollX => {
    if ((scrollX < width / 2 || width * 1.5 < scrollX) && scrollUpdateRef.current) {
      scrollUpdateRef.current = false;

      if (scrollX < width / 2) {
        loadMoreLeft(scrollX);
      } else {
        loadMoreRight(scrollX);
      }
    } else {
      scrollHeaderRef.current.scrollLeft = scrollComponentRef.current.scrollLeft;
      setScrollOffset(scrollComponentRef.current.scrollLeft);
    }
  };

  const onScrollY = () => {
    setScrollElementScrollTop(outerRef.current.scrollTop);
  };

  const onScrollByDrag = scrollX => {
    scrollHeaderRef.current.scrollLeft = scrollComponentRef.current.scrollLeft + scrollX;
    scrollComponentRef.current.scrollLeft = scrollComponentRef.current.scrollLeft + scrollX;
    setScrollOffset(scrollComponentRef.current.scrollLeft + scrollX);
  };

  const getHeaderScrollElementRef = el => {
    scrollHeaderRef.current = el;
  };

  const getScrollElementRef = el => {
    scrollComponentRef.current = el;
  };

  const scrollWithScrollbBar = value => {
    outerRef.current.scrollTop = value;
    setScrollElementScrollTop(value);
  };

  if (scrollbarWidth === undefined) {
    const component = document.querySelector('#outer-component');

    if (component) {
      const width = component.offsetWidth - component.clientWidth;
      setScrollbarWidth(width);
    }
  }

  const onZoomChange = value => {
    setRowHeightFunction(14 + value * 2);
    setZoomSliderValue(value);
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <TimelineContainer
        ref={el => (containerRef.current = el)}
        width={sideModalOpen ? 'calc(100% - ' + sideModalWidth + 'px)' : '100%'}
      >
        <ZoomContainer>
          <ZoomIcon
            aria-label="zoom-in"
            type={'zoom-in'}
            onClick={() => onZoomChange(zoomSliderValue === 10 ? 10 : zoomSliderValue + 1)}
          />
          <ZoomSlider
            min={0}
            mid={5}
            max={10}
            step={1}
            defaultValue={5}
            vertical={true}
            onChange={value => onZoomChange(value)}
            value={zoomSliderValue}
          />
          <ZoomIcon
            aria-label="zoom-out"
            type={'zoom-out'}
            onClick={() => onZoomChange(zoomSliderValue === 0 ? 0 : zoomSliderValue - 1)}
          />
        </ZoomContainer>
        <TimelineHeaders scrollRef={getHeaderScrollElementRef}>
          <DateHeader height={40} unit={timelineUnit} />
        </TimelineHeaders>
        <OuterComponent ref={outerRef} id="outer-component" onScroll={onScrollY}>
          {loader && (
            <LoaderContainer>
              <Spin size="large" />
            </LoaderContainer>
          )}
          <Sidebar />
          <ScrollElement
            scrollRef={getScrollElementRef}
            onScroll={onScroll}
            scrollbarWidth={scrollbarWidth}
            scrollLeft={scrollComponentRef.current?.scrollLeft}
            scrollByDrag={onScrollByDrag}
            width={width}
          >
            <div>
              <Columns
                getLeftOffsetFromDate={getLeftOffsetFromDate}
                canvasTimeStart={canvasTimeStart}
                canvasTimeEnd={canvasTimeEnd}
                height={height}
                indicatorLine={indicatorLine}
                setIndicatorLine={setIndicatorLine}
                canvasWidth={canvasWidth}
              />
              <CalendarRows />
            </div>

            <EmptySpaceForScrollbar data-id="scrollbar" style={{ width: canvasWidth + 'px' }} />
          </ScrollElement>
        </OuterComponent>
        <VerticalScrollBar
          scrollTop={outerRef.current ? outerRef.current.scrollTop : 0}
          scrollWithScrollbBar={scrollWithScrollbBar}
          scrollElementScrollTop={scrollElementScrollTop}
        />
      </TimelineContainer>
      {sideModalOpen && <VesselSidePanelContainer />}
    </div>
  );
};

export default ReactCalendarTimeline;
