import React, { useContext, useState } from 'react';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';
import VesselSidePanel from '../VesselSidePanel';

const VesselSidePanelContainer = () => {
  const { sideModalOpen, setSideModalOpen, otherNamespaceData, visibleTimeStart } = useContext(FleetVesselViewContext);

  const [showSeaPassage, setShowSeaPassage] = useState(undefined);

  return (
    <VesselSidePanel
      sideModalOpen={sideModalOpen}
      setSideModalOpen={setSideModalOpen}
      otherNamespaceData={otherNamespaceData}
      vesselView={true}
      showSeaPassage={showSeaPassage}
      setShowSeaPassage={setShowSeaPassage}
      innerMap={true}
      startTime={visibleTimeStart}
    />
  );
};

export default VesselSidePanelContainer;
