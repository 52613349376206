import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useHistory, withRouter } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import HelpModalWithIcon from '../info/HelpModalWithIcon';
import { VesselType } from '../info/WikiConstants';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const Button = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  margin-top: 18px;
  font-size: 13px;

  i {
    margin-right: 10px;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
    margin-top: -2px !important;
  }

  &:hover {
    color: #3576bd;
  }
`;

const Row = styled.div`
  margin: 3px 0;
  font-size: 13px;
`;

const VesselPopup = ({ feature, isPublicMap, setSpeedModalOpen, speedModalButton }) => {
  const { namespace, modules, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const history = useHistory();

  let formattedTimestamp = dayjs(feature.properties.location_timestamp).fromNow();

  const getFormattedETA = eta => {
    return dayjs(eta).fromNow();
  };

  const showOnActivityView = () => {
    history.push('/?search=' + feature.properties.name);
  };

  return (
    <div>
      <h2>{feature.properties.name}</h2>
      <Row>
        {t('Type')}: <b>{t(feature.properties.vessel_type_name)}</b>
      </Row>
      <Row>
        {t('MMSI')}: <b>{feature.properties.mmsi ? feature.properties.mmsi : t('Not available')}</b>
      </Row>
      <Row>
        {t('IMO')}: <b>{feature.properties.imo ? feature.properties.imo : t('Not available')}</b>
      </Row>
      <Row>
        {t('Latitude')}: <b>{feature.properties.latitude}</b>
      </Row>
      <Row>
        {t('Longitude')}: <b>{feature.properties.longitude}</b>
      </Row>
      {!isPublicMap && (
        <div>
          <Row>
            {t('LOA')}: <b>{feature.properties.loa ? feature.properties.loa : t('Not available')}</b>
          </Row>
          <Row>
            {t('Beam')}: <b>{feature.properties.beam ? feature.properties.beam : t('Not available')}</b>
          </Row>
        </div>
      )}
      <Row>
        {t('Speed (knots)')}:{' '}
        <b>{feature.properties.speed_knots ? feature.properties.speed_knots : t('Not available')}</b>
      </Row>
      <Row>
        {t('Heading (degrees)')}:{' '}
        <b>{feature.properties.heading_degrees ? feature.properties.heading_degrees : t('Not available')}</b>
      </Row>
      <Row>
        {t('Course over ground (degrees)')}:{' '}
        <b>
          {feature.properties.course_over_ground_degrees
            ? feature.properties.course_over_ground_degrees
            : t('Not available')}
        </b>
      </Row>
      <Row>
        {t('Location timestamp')}: <b>{formattedTimestamp}</b>
      </Row>
      {!!feature.properties.dead_reckoning_eta && (
        <Row>
          {t('Dead reckoning ETA')}: <b>{getFormattedETA(feature.properties.dead_reckoning_eta)}</b>
        </Row>
      )}
      {!!feature.properties.mooring_status && (
        <Row>
          {t('Mooring status')}: <b>{t(feature.properties.mooring_status)}</b>
        </Row>
      )}
      {!!feature.properties.estimated_travel_distance && (
        <Row>
          {t('Estimated travel distance')}: <b>{feature.properties.estimated_travel_distance}</b>
        </Row>
      )}
      {!isPublicMap && (
        <Button onClick={showOnActivityView}>
          <Icon type="port" />
          {t('Show vessel on timeline')}
        </Button>
      )}
      {speedModalButton && (
        <Button onClick={() => setSpeedModalOpen(true)} style={{ marginTop: '8px' }}>
          <Icon type="portcall-stats" />
          {t('Open port call dashboard')}
        </Button>
      )}
      {modules?.wiki_module === 'enabled' && user.permissions.includes('view_wiki') && (
        <div style={{ marginTop: '12px', marginLeft: '-6px', display: 'flex', justifyContent: 'center' }}>
          <HelpModalWithIcon objectType={VesselType} objectId={feature.properties.imo.toString()} />
        </div>
      )}
    </div>
  );
};

export default withRouter(VesselPopup);
