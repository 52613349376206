import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import Page from '../../components/ui/Page';
import Alert from 'antd/es/alert';
import Services from './Services';

const AdminServices = () => {
  const { namespace, alert, setAlert } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Service orders | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Services')}>
      {alert && <Alert message={alert.message} type={alert.type} banner closable afterClose={() => setAlert(null)} />}
      <Page fullWidth>
        <Services />
      </Page>
    </Layout>
  );
};

export default AdminServices;
