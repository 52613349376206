import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';

import { UserContext } from '../../../../context/UserContext';
import dayjs from 'dayjs';

const RowContainerSplit = styled.div`
  display: flex;
  padding: 6px 0;
`;

const Header = styled.div`
  margin-right: 6px;
  white-space: nowrap;
`;

const Container = styled.div`
  display: inline-block;
`;

const RowContainer = styled.div`
  width: 100%;
  padding: 0 0 9px 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Value = styled.div`
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StatusOval = styled.div`
  width: 114px;
  height: 24px;
  display: inline-block;
  border-radius: 12px;
  text-align: center;
  cursor: default;
  ${props => {
    if (props.status == 'requested') {
      return `
          background-color: #F44336;
          color: white;
        `;
    } else if (props.status == 'cancelled' || props.status == 'rejected' || props.status == 'delivery_cancelled') {
      return `
          background-color: #D8D8D8;
          color: #868686;
        `;
    } else if (props.status == 'acknowledged') {
      return `
          background-color: #F5BD3E;
          color: white;
        `;
    } else if (props.status == 'done' || props.status == 'pending' || props.status == 'in_progress') {
      return `
          background-color: #407505;
          color: white;
        `;
    }
  }}
`;

const StatusText = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const FinnpilotServiceOrderInfo = ({ data }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  let created_by = data.created_by || '';
  if (data.data?.created_by_name && data.data?.created_by_email) {
    created_by = `${data.data?.created_by_name} (${data.data?.created_by_email})`;
  } else if (data.data?.created_by_name) {
    created_by = data.data?.created_by_name;
  } else if (data.data?.created_by_email) {
    created_by = data.data?.created_by_email;
  }
  /* eslint-disable no-case-declarations */
  let tooltipText = '';
  let acknowledgedBy = '';
  let deliveredBy = '';
  let deliveryStatus = '';
  let deliveredAt = null;
  switch (data.status) {
    case 'requested':
    case 'cancelled':
      let updated_by_canc = data.updated_by || '';
      if (data.data?.updated_by_name && data.data?.updated_by_email) {
        updated_by_canc = `${data.data?.updated_by_name} (${data.data?.updated_by_email})`;
      } else if (data.data?.updated_by_name) {
        updated_by_canc = data.data?.updated_by_name;
      } else if (data.data?.updated_by_email) {
        updated_by_canc = data.data?.updated_by_email;
      }
      tooltipText = t('Requested by {{updated_by}}', { updated_by: updated_by_canc });
      break;
    case 'rejected':
    case 'acknowledged':
      let created_by = data.ack?.created_by || '';
      const emailOrPhone = data.ack?.data?.created_by_email || data.ack?.data?.created_by_phone;
      if (data.ack?.data?.created_by_name && emailOrPhone) {
        created_by = `${data.ack?.data.created_by_name} (${emailOrPhone})`;
      } else if (data.ack?.data?.created_by_name) {
        created_by = data.ack?.data.created_by_name;
      } else if (emailOrPhone) {
        created_by = emailOrPhone;
      }
      acknowledgedBy = created_by;
      tooltipText = t('{{action}} by {{created_by}}', {
        action: data.status === 'rejected' ? 'Rejected' : 'Acknowledged',
        created_by,
      });
      break;
    case 'done':
    case 'pending':
    case 'in_progress':
    case 'delivery_cancelled':
      const delivery = data.deliveries?.[0];
      let updated_by = delivery?.updated_by || '';
      if (delivery?.data?.updated_by_name && delivery?.data?.updated_by_email) {
        updated_by = `${delivery?.data.updated_by_name} (${delivery?.data.updated_by_email})`;
      } else if (delivery?.data?.updated_by_name) {
        updated_by = delivery?.data.updated_by_name;
      } else if (delivery?.data?.updated_by_email) {
        updated_by = delivery?.data.updated_by_email;
      }
      deliveredBy = updated_by;
      deliveryStatus =
          delivery?.status === 'cancelled' ? 'Cancelled' : delivery?.status === 'done' ? 'Delivered' : '';
      deliveredAt = delivery.created_at;
      tooltipText = t('{{action}} by {{updated_by}}', {
        action:
            delivery?.status === 'cancelled' ? 'Cancelled' : delivery?.status === 'done' ? 'Delivered' : 'Updated',
        updated_by,
      });
      break;
    default:
      return;
  }
  /* eslint-enable no-case-declarations */

  return (
    <RowContainer>
      <Container style={{ width: 'calc(100% - 130px)' }}>
        <Row>
          <RowContainerSplit style={{ minWidth: 'calc(100% * 0.38)', paddingRight: '24px' }}>
            <Header>{t('Service')}:</Header>
            <Tooltip title={data.service_name} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
              <Value>{data.service_name}</Value>
            </Tooltip>
          </RowContainerSplit>
          <StatusContainer>
            <RowContainerSplit style={{ paddingRight: '24px' }}>
              <Header>{t('Created at')}:</Header>
              <Tooltip
                title={created_by || (data.created_at ? dayjs(data.created_at).format('DD.MM.YYYY HH:mm') : '')}
                color="white"
                overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
              >
                <Value>{data.created_at ? dayjs(data.created_at).format('DD.MM.YYYY HH:mm') : '-'}</Value>
              </Tooltip>
            </RowContainerSplit>
            {data.ack?.created_at && data.ack?.status ? (
              <RowContainerSplit style={{ paddingRight: '24px' }}>
                <Header>
                  {t('{{action}} at', { action: data.ack.status === 'rejected' ? 'Rejected' : 'Acknowledged' })}:
                </Header>
                <Tooltip
                  title={acknowledgedBy || dayjs(data.ack.created_at).format('DD.MM.YYYY HH:mm')}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                >
                  <Value>{dayjs(data.ack.created_at).format('DD.MM.YYYY HH:mm')}</Value>
                </Tooltip>
              </RowContainerSplit>
            ) : null}
            {deliveredAt && deliveryStatus && deliveredBy ? (
              <RowContainerSplit style={{ paddingRight: '24px' }}>
                <Header>{t('{{action}} at', { action: deliveryStatus })}:</Header>
                <Tooltip title={deliveredBy} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
                  <Value>{dayjs(deliveredAt).format('DD.MM.YYYY HH:mm')}</Value>
                </Tooltip>
              </RowContainerSplit>
            ) : null}
          </StatusContainer>
        </Row>
      </Container>
      <Container style={{ width: '130px' }}>
        <RowContainerSplit style={{ marginTop: '-2px' }}>
          <Header>{t('Status')}:</Header>
        </RowContainerSplit>
        <RowContainerSplit>
          <Tooltip title={tooltipText} color="white" overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}>
            <StatusOval status={data.status}>
              <StatusText>{data.status?.replace('_', ' ')}</StatusText>
            </StatusOval>
          </Tooltip>
        </RowContainerSplit>
      </Container>
    </RowContainer>
  );
};

export default FinnpilotServiceOrderInfo;
