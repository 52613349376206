import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';

import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../../components/ui/Icon';
import Input from '../../components/ui/Input';
import Label from '../../components/ui/Label';
import Select from '../../components/ui/Select';
import Heading from '../../components/ui/Heading';
import DateComponent from '../../components/ui/DateComponent';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const GradeWrapper = styled.span`
  position: relative;
  background: ${props => props.theme.color.white};
  border-radius: ${({ theme }) => theme.style.border_radius};
  box-shadow: 0px 1px ${props => props.theme.color.grey_light};
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${props => props.theme.color.grey_light};
  margin: 0.5rem;
  padding-left: 20px;
`;

const InputGroup = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputContainer = styled.span`
  padding: 15px;
  margin: 0px 20px 0px -20px;
`;

const RemoveIcon = styled.span`
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 1rem;
  cursor: pointer;
  svg {
    color: ${props => props.theme.color.warning};
  }
`;

const EtcText = styled(Heading)`
  margin-bottom: 0;
  padding: 0.5rem;
`;

const PetroleumGrade = ({
  data,
  index,
  setGradeValueById,
  removeGrade,
  validGrades,
  jobNotStarted,
  jobOngoing,
  jobDone,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const {
    id,
    code,
    current_flow_rate,
    starting_volume,
    remaining_volume,
    loading_sequence,
    remaining_pump_time_seconds,
    etc,
  } = data;

  const handleInputChange = e => {
    setGradeValueById(id, e.target.name, e.target.value);
  };

  const roundVolume = volume => {
    const roundedVolume = Math.round(volume * 100) / 100;
    return roundedVolume;
  };

  const loading_sequence_list = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  return (
    <GradeWrapper>
      <InputGroup>
        <InputContainer>
          <Select
            value={code}
            label={`${t('Grade')} ${index + 1}`}
            name="code"
            options={validGrades}
            onChange={handleInputChange}
            disabled={jobDone}
          />
        </InputContainer>
        <InputContainer>
          <Input
            name="current_flow_rate"
            label={t('Pump rate (m\u00B3/h)')}
            value={current_flow_rate}
            onChange={handleInputChange}
            required
            disabled={jobDone}
          />
        </InputContainer>
        <InputContainer>
          <Select
            value={loading_sequence}
            label={t('Loading sequence')}
            name="loading_sequence"
            options={loading_sequence_list}
            onChange={handleInputChange}
            disabled={jobDone}
          />
        </InputContainer>
        {jobNotStarted && (
          <InputContainer>
            <Input
              name="starting_volume"
              label={t('Start volume (m\u00B3)')}
              value={starting_volume}
              onChange={handleInputChange}
              required
              disabled={jobDone || jobOngoing}
            />
          </InputContainer>
        )}
        {!jobNotStarted && (
          <InputContainer>
            <Input
              name="remaining_volume"
              label={t('Remaining volume (m\u00B3)')}
              value={roundVolume(remaining_volume)}
              onChange={handleInputChange}
              required
              disabled={jobDone || jobNotStarted}
            />
          </InputContainer>
        )}
        <InputContainer>
          <Label>{t('Remaining pump time')}</Label>
          <EtcText h3>
            {remaining_pump_time_seconds !== null ? (
              dayjs.duration(remaining_pump_time_seconds * 1000).format('HH:mm', { trim: false })
            ) : (
              <span>N/A</span>
            )}
          </EtcText>
        </InputContainer>
        {jobOngoing && (
          <InputContainer>
            <Label>{t('ETC')}</Label>
            <EtcText h3>{etc ? <DateComponent format={TIME_FORMAT} date={etc} /> : <span>N/A</span>}</EtcText>
          </InputContainer>
        )}
      </InputGroup>
      {!jobDone && user.permissions.includes('manage_loading_job') && (
        <RemoveIcon>
          <Icon type="trash" onClick={() => removeGrade(id)} />
        </RemoveIcon>
      )}
    </GradeWrapper>
  );
};

export default PetroleumGrade;
