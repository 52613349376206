import vesselIconBlack from '../../images/icons/map/vessel-icon-black.svg';
import vesselIconBlue from '../../images/icons/map/vessel-icon-blue.svg';
import vesselIconCyan from '../../images/icons/map/vessel-icon-cyan.svg';
import vesselIconGray from '../../images/icons/map/vessel-icon-gray.svg';
import vesselIconGreen from '../../images/icons/map/vessel-icon-green.svg';
import vesselIconOrange from '../../images/icons/map/vessel-icon-orange.svg';
import vesselIconPurple from '../../images/icons/map/vessel-icon-purple.svg';
import vesselIconRed from '../../images/icons/map/vessel-icon-red.svg';
import vesselIconWhite from '../../images/icons/map/vessel-icon-white.svg';
import vesselIconYellow from '../../images/icons/map/vessel-icon-yellow.svg';
import vesselIconFullBlack from '../../images/icons/map/vessel-icon-full-black.svg';
import vesselIconFullBlue from '../../images/icons/map/vessel-icon-full-blue.svg';
import vesselIconFullCyan from '../../images/icons/map/vessel-icon-full-cyan.svg';
import vesselIconFullGray from '../../images/icons/map/vessel-icon-full-gray.svg';
import vesselIconFullGreen from '../../images/icons/map/vessel-icon-full-green.svg';
import vesselIconFullOrange from '../../images/icons/map/vessel-icon-full-orange.svg';
import vesselIconFullPurple from '../../images/icons/map/vessel-icon-full-purple.svg';
import vesselIconFullRed from '../../images/icons/map/vessel-icon-full-red.svg';
import vesselIconFullWhite from '../../images/icons/map/vessel-icon-full-white.svg';
import vesselIconFullYellow from '../../images/icons/map/vessel-icon-full-yellow.svg';

import vesselIconCurrentBlue from '../../images/icons/map/vessel-icon-blue-current.svg';
import vesselIconCurrentBlack from '../../images/icons/map/vessel-icon-black-current.svg';
import vesselIconCurrentCyan from '../../images/icons/map/vessel-icon-cyan-current.svg';
import vesselIconCurrentGray from '../../images/icons/map/vessel-icon-gray-current.svg';
import vesselIconCurrentGreen from '../../images/icons/map/vessel-icon-green-current.svg';
import vesselIconCurrentOrange from '../../images/icons/map/vessel-icon-orange-current.svg';
import vesselIconCurrentPurple from '../../images/icons/map/vessel-icon-purple-current.svg';
import vesselIconCurrentRed from '../../images/icons/map/vessel-icon-red-current.svg';
import vesselIconCurrentWhite from '../../images/icons/map/vessel-icon-white-current.svg';
import vesselIconCurrentYellow from '../../images/icons/map/vessel-icon-yellow-current.svg';

import craneIcon from '../../images/icons/map/crane.svg';
import cranePlannedIcon from '../../images/icons/map/crane-planned.svg';
import craneCurrentIcon from '../../images/icons/map/crane-current.svg';
import incidentVesselIconGreen from '../../images/icons/incident-marker-green.svg';
import incidentVesselIconYellow from '../../images/icons/incident-marker-yellow.svg';
import incidentVesselIconRed from '../../images/icons/incident-marker-red.svg';
import incidentVesselIconBlue from '../../images/icons/incident-marker-blue.svg';

const defaultOptions = {
  tileserver: '',
  tileserver_copyright:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | &copy; <a href="https://www.traficom.fi/fi/ajankohtaista/paikkatietoaineistot/aineistojen-kaytto-ja-lisenssit">Traficom</a> CC 4.0 WMS',
  smallIconsZoomRange: { start: 0, end: 14 },
  mediumIconsZoomRange: { start: 15, end: 17 },
  largeIconsZoomRange: { start: 18, end: 18 },
  smallMarkerIconPixelSize: 12,
  mediumMarkerIconPixelSize: 20,
  largeMarkerIconPixelSize: 28,
  markerIconSpacingPixels: 4,
  smallVesselIconPixelSize: 18,
  scaledVesselBackupIconPixelSize: 58,
  smallVesselCourseIconPixelSize: 6,
  largeVesselCourseIconPixelSize: 1,
  vesselColorToIconMappings: {
    vessel_blue: vesselIconBlue,
    vessel_cyan: vesselIconCyan,
    vessel_purple: vesselIconPurple,
    vessel_green: vesselIconGreen,
    vessel_gray: vesselIconGray,
    vessel_black: vesselIconBlack,
    vessel_white: vesselIconWhite,
    vessel_red: vesselIconRed,
    vessel_orange: vesselIconOrange,
    vessel_yellow: vesselIconYellow,
    defaultColor: vesselIconGray,
  },
  vesselFullColorToIconMappings: {
    vessel_blue: vesselIconFullBlue,
    vessel_cyan: vesselIconFullCyan,
    vessel_purple: vesselIconFullPurple,
    vessel_green: vesselIconFullGreen,
    vessel_gray: vesselIconFullGray,
    vessel_black: vesselIconFullBlack,
    vessel_white: vesselIconFullWhite,
    vessel_red: vesselIconFullRed,
    vessel_orange: vesselIconFullOrange,
    vessel_yellow: vesselIconFullYellow,
    defaultColor: vesselIconFullGray,
  },
  vesselColorCurrentToIconMappings: {
    vessel_blue: vesselIconCurrentBlue,
    vessel_cyan: vesselIconCurrentCyan,
    vessel_purple: vesselIconCurrentPurple,
    vessel_green: vesselIconCurrentGreen,
    vessel_gray: vesselIconCurrentGray,
    vessel_black: vesselIconCurrentBlack,
    vessel_white: vesselIconCurrentWhite,
    vessel_red: vesselIconCurrentRed,
    vessel_orange: vesselIconCurrentOrange,
    vessel_yellow: vesselIconCurrentYellow,
    defaultColor: vesselIconCurrentGray,
  },
  vesselBaseColors: {
    vessel_blue: 'blue',
    vessel_cyan: 'cyan',
    vessel_purple: 'purple',
    vessel_green: 'green',
    vessel_gray: 'gray',
    vessel_black: 'black',
    vessel_white: 'white',
    vessel_red: 'red',
    vessel_orange: 'orange',
    vessel_yellow: 'yellow',
    defaultColor: 'gray',
  },
  initWithVesselDelayMilliSeconds: 1000,
  lineMarkerDefaultStyle: { color: '#000000', weight: 2, opacity: 1.0 },
  polygonMarkerDefaultStyle: { color: '#000000', weight: 2, opacity: 1.0, fillColor: '#8f8c8c', fillOpacity: 0.3 },
  craneIcon: craneIcon,
  cranePlannedIcon: cranePlannedIcon,
  craneCurrentIcon: craneCurrentIcon,
  vesselIncidentIconGreen: incidentVesselIconGreen,
  vesselIncidentIconYellow: incidentVesselIconYellow,
  vesselIncidentIconRed: incidentVesselIconRed,
  vesselIncidentIconBlue: incidentVesselIconBlue,
};

export const getOptions = () => {
  let options = defaultOptions;

  if (import.meta.env.VITE_REACT_APP_MAP_TILE_SERVER) {
    const tokens = import.meta.env.VITE_REACT_APP_MAP_TILE_SERVER.split('?');
    if (tokens.length > 1) {
      options.tileserver = tokens[0];
      options.tileserver_apikey = '?' + tokens[1];
    } else {
      options.tileserver = import.meta.env.VITE_REACT_APP_MAP_TILE_SERVER;
      options.tileserver_apikey = '';
    }
  }

  if (import.meta.env.VITE_REACT_APP_MAP_TILE_SERVER_COPYRIGHT) {
    options.tileserver_copyright = import.meta.env.VITE_REACT_APP_MAP_TILE_SERVER_COPYRIGHT;
  }

  return options;
};
