import React, { useContext } from 'react';
import styled from 'styled-components';

import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

import Work from './Work';
import Reservation from './Reservation';

const RowContainer = styled.div`
  position: relative;
`;

const CalendarChildRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  background-color: ${props => (props.isEvenRow ? props.theme.color.grey_lighter2 : props.theme.color.white)};
`;

export const CalendarRow = ({ crane, index }) => {
  const { canvasWidth, rowHeight, reservations } = useContext(CranePlanningToolContext);

  const createRows = () => {
    return (
      <CalendarChildRow
        key={`row-child-${index}`}
        style={{
          width: `${canvasWidth}px`,
          height: `${rowHeight}px`,
        }}
        color={crane.color}
        isEvenRow={index % 2 === 0}
      />
    );
  };

  let reservationsForCrane = reservations.filter(res => res.crane_id === Number(crane.craneId));
  let works = [...crane.works];

  return (
    <RowContainer key={`row-container-${index}`}>
      {createRows()}
      {reservationsForCrane.map((p, j) => (
        <Reservation key={j} reservation={p} />
      ))}
      {works.map((p, j) => (
        <Work key={j} work={p} />
      ))}
    </RowContainer>
  );
};
