import { useState, useEffect, useCallback, useRef } from 'react';
import dayjs from 'dayjs';

const createStatisticsData = (method, path, params) => {
  if (path === 'statistics/data') {
    return {
      data: {
        data: [
          { time: '2023-02-19T13:00:00+00:00', speed: Math.round(Math.random() * 50) },
          { time: '2023-02-19T22:00:00+00:00', speed: Math.round(Math.random() * 50) },
          { time: '2023-02-20T03:00:00+00:00', speed: Math.round(Math.random() * 50) },
          { time: '2023-02-20T16:00:00+00:00', speed: Math.round(Math.random() * 50) },
          { time: '2023-02-20T23:00:00+00:00', speed: Math.round(Math.random() * 50) },
        ],
      },
    };
  } else if (path === '/statistics/key-values') {
    return {
      data: {
        data: [
          {
            label: 'Vessels at berth',
            value: Math.round(Math.random() * 30),
          },
          {
            label: 'Available berths',
            value: Math.round(Math.random() * 30) + ' / 30',
          },
          {
            label: 'Arriving in 48h',
            value: Math.round(Math.random() * 30),
          },
          {
            label: 'Active service orders',
            value: Math.round(Math.random() * 30),
          },
        ],
      },
    };
  } else if (path === '/statistics/vessel-types') {
    return {
      data: {
        data: [
          {
            key: 'value1',
            value: Math.round(Math.random() * 70),
          },
          {
            key: 'value2',
            value: Math.round(Math.random() * 70),
          },
          {
            key: 'value3',
            value: Math.round(Math.random() * 70),
          },
          {
            key: 'value4',
            value: Math.round(Math.random() * 70),
          },
          {
            key: 'value5',
            value: Math.round(Math.random() * 70),
          },
          {
            key: 'value6',
            value: Math.round(Math.random() * 70),
          },
        ],
      },
    };
  } else if (path === '/statistics/net-tonnage') {
    return {
      data: {
        data: [
          {
            key: 'value1',
            value: Math.round(Math.random() * 70000),
          },
          {
            key: 'value2',
            value: Math.round(Math.random() * 70000),
          },
          {
            key: 'value3',
            value: Math.round(Math.random() * 70000),
          },
        ],
        legend:
          params.timeType === 'week'
            ? [
                {
                  title: 'Current week (Oct 28 - Dec 2)',
                  color: '#A7C4F4',
                  key: 'value1',
                },
                {
                  title: 'Last week (same time) (Oct 21 - Oct 25)',
                  color: '#A5D6A7',
                  key: 'value2',
                },
                {
                  title: 'Whole last week (Oct 21 - Oct 27)',
                  color: '#FFAB91',
                  key: 'value3',
                },
              ]
            : params.timeType === 'month'
              ? [
                  {
                    title: 'Current month (Nov 1 - 28)',
                    color: '#A7C4F4',
                    key: 'value1',
                  },
                  {
                    title: 'Last month (same time) (Oct 1 - 28)',
                    color: '#A5D6A7',
                    key: 'value2',
                  },
                  {
                    title: 'Whole last month (Oct 1 - 31)',
                    color: '#FFAB91',
                    key: 'value3',
                  },
                ]
              : [
                  {
                    title: 'Current year (Jan 1 -  Dec 2)',
                    color: '#A7C4F4',
                    key: 'value1',
                  },
                  {
                    title: 'Last year (same time) (Jan 1 -  Dec 2)',
                    color: '#A5D6A7',
                    key: 'value2',
                  },
                  {
                    title: 'Whole last year (Jan 1 -  Dec 31)',
                    color: '#FFAB91',
                    key: 'value3',
                  },
                ],
      },
    };
  } else if (path === '/statistics/service-orders') {
    if (params.dataType === 'type') {
      return {
        data: {
          data: [
            {
              label: 'Tug in',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Tug out',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Mooring',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Pilot in',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Pilot out',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Waste picking',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Water',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Fuel',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Fuel 2',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Tug in 2',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Tug out 2',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Mooring 2',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Very long service order type name',
              value: Math.round(Math.random() * 20),
            },
          ],
        },
      };
    } else {
      return {
        data: {
          data: [
            {
              label: 'Requested',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Acknowledged',
              value: Math.round(Math.random() * 20),
            },
            {
              label: 'Delivered',
              value: Math.round(Math.random() * 20),
            },
          ],
        },
      };
    }
  } else if (path === '/statistics/portcalls') {
    if (params.timeType === 'week') {
      const time = dayjs(params.time)
        .startOf('isoWeek')
        .subtract(1, 'day');
      const counter = [6, 5, 4, 3, 2, 1, 0];
      return {
        data: {
          data: counter.map(() => {
            return {
              label: time.add(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
              value1: Math.round(Math.random() * 30),
              value2: time.isAfter(dayjs()) ? null : Math.round(Math.random() * 30),
              value3: time.isAfter(dayjs()) ? null : Math.round(Math.random() * 30),
            };
          }),
        },
      };
    } else if (params.timeType === 'year') {
      const time = dayjs(params.time)
        .startOf('year')
        .subtract(1, 'month');
      const counter = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
      return {
        data: {
          data: counter.map(() => {
            return {
              label: time.add(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ'),
              value1: Math.round(Math.random() * 30),
              value2: time.isAfter(dayjs()) ? null : Math.round(Math.random() * 30),
              value3: time.isAfter(dayjs()) ? null : Math.round(Math.random() * 30),
            };
          }),
        },
      };
    }
  } else if (path === '/statistics/latest-updates') {
    return {
      data: {
        data: [
          {
            message: {
              boldedBegin: 'MIRVA VG',
              middle: 'requested service order',
              boldedEnd: 'Tug in',
            },
            updated_by: 'Erik',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          {
            message: {
              boldedBegin: 'AFRODITE',
              middle: 'requested service order',
              boldedEnd: 'Mooring',
            },
            updated_by: 'Henrik',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          {
            message: {
              boldedBegin: 'SONILAND',
              middle: 'cancelled service order',
              boldedEnd: 'Tug in',
            },
            updated_by: 'Mikko',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          {
            message: {
              boldedBegin: 'SNOW CRYSTAL',
              middle: 'got a new timestamp',
              boldedEnd: 'ETA',
            },
            updated_by: 'Pertti',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          {
            message: {
              boldedBegin: 'VIKSTRAUM',
              middle: 'got a new timestamp with a type',
              boldedEnd: 'Pilotage Commenced',
            },
            updated_by: 'Jaska',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          {
            message: {
              boldedBegin: 'POLYALAND',
              middle: 'requested service order',
              boldedEnd: 'Fuel',
            },
            updated_by: 'Samu',
            updated_at: dayjs()
              .subtract(Math.round(Math.random() * 10), 'days')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
        ],
      },
    };
  } else if (path === '/statistics/active-users') {
    const percent = Math.round(Math.random() * 100);
    return {
      data: {
        data: [
          {
            label: 'Active',
            value: percent,
          },
          {
            label: 'Inactive',
            value: 100 - percent,
          },
        ],
      },
    };
  }
};

const useFakeApi = (method, path, firstParams, callback, allow = true) => {
  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(allow);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (cancelled, nextParams) => {
      setLoading(true);
      const params = nextParams || firstParams;
      try {
        const { data } = createStatisticsData(method, path, params);
        await new Promise(res => setTimeout(res, 500));
        if (!cancelled && mounted.current) {
          setError(null);
          setData(data);
          setLoading(false);
          if (callback) {
            callback(data);
          }
        }
      } catch (e) {
        const responseErrorMessage = e?.response?.data?.error;
        setError(responseErrorMessage || 'Oops! Something went wrong.');
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    let cancelled = false;
    if (allow) {
      fetchData(cancelled, firstParams);
    }
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line
  }, [fetchData]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export default useFakeApi;
