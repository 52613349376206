import './MapContainer.css';

import { MapContainer as Map } from 'react-leaflet';
import React from 'react';
import L from 'leaflet';

import GeoAssetMapInner from '../admin/geoAssetTool/GeoAssetMapInner';

const MainGeoAssetMap = ({
  zoom,
  setZoom,
  selectedAisVessel,
  setSelectedAisVessel,
  appliedCoordinates,
  globalsaveLoading,
  setAppliedCoordinates,
}) => {
  return (
    <Map
      style={{
        height: '100%',
        width: '100%',
        zIndex: 0,
      }}
      className="markercluster-map"
      center={appliedCoordinates.split(',')}
      zoom={zoom}
      tap={false}
      zoomControl={false}
      renderer={L.canvas({ padding: 0.5 })}
    >
      <GeoAssetMapInner
        appliedCoordinates={appliedCoordinates}
        zoom={zoom}
        setZoom={setZoom}
        style={{ zIndex: 0 }}
        setSelectedAisVessel={setSelectedAisVessel}
        selectedAisVessel={selectedAisVessel}
        mainMap={true}
        globalsaveLoading={globalsaveLoading}
        setAppliedCoordinates={setAppliedCoordinates}
      />
    </Map>
  );
};

export default MainGeoAssetMap;
