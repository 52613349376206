import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';

export const authenticate = async (socket, token) => {
  if (socket && token) {
    try {
      const res = await socket.authenticate(token);
      if (res.authError) {
        //console.log(res.authError);
        Sentry.captureException(new Error(res.authError));
      }
      return res.isAuthenticated;
    } catch (error) {
      //console.log(error);
      Sentry.captureException(error);
      return false;
    }
  }

  return true;
};

// this is used for maintenance mode which doesn't require authentication
export const subscribeSocketChannel = async (socket, channel, callback) => {
  let myChannel = socket.subscribe(channel);
  await myChannel.listener('subscribe').once();

  for await (let data of myChannel) {
    callback(data);
  }
};

const unsubscribeSocketChannel = async (socket, channel) => {
  if (socket) {
    socket.unsubscribe(channel);
  }
};

const useSocket = async (socket, channel, callback) => {
  const subCounter = useRef(0);

  const subscribeSocketChannelWithAuth = async (socket, channel, callback) => {
    let myChannel = socket.subscribe(channel);
    await myChannel.listener('subscribe').once();

    subCounter.current = subCounter.current + 1;
    const subCountCurrent = subCounter.current;

    for await (let data of myChannel) {
      // if the callback is from previous renders or the component is already unmounted, break the loop
      // only mounted and current render's callback should be called
      if (subCounter.current - subCountCurrent > 0 || mounted.current === false) break;
      callback(data);
    }
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    if (socket) {
      subscribeSocketChannelWithAuth(socket, channel, callback);
    }

    return () => {
      if (socket) {
        unsubscribeSocketChannel(socket, channel);
      }
    };
    // eslint-disable-next-line
  }, [channel, socket, callback]);

  return {};
};

export default useSocket;
