import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Draggable from 'react-draggable';
import Tooltip from 'antd/es/tooltip';

import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';

import { color_uncommitted } from '../commonCalendar/utility/constants';

import AddOrEditVesselsModal from './modals/AddOrEditVesselsModal';
import RemoveVesselsModal from './modals/RemoveVesselsModal';
import PromoteVesselsModal from './modals/PromoteVesselsModal';
import AddOrEditBerthPlanModal from './modals/AddOrEditBerthPlanModal';
import Icon from '../ui/Icon';

import {
  TIME_FORMAT,
  TIME_FORMAT_DAY,
  TIME_FORMAT_HOURS_MINUTES,
  mobilePixelMaxWidthLimit,
} from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';

const Container = styled.div`
  margin-left: 5px;
  margin-bottom: 180px;
  position: relative;
  z-index: 0;

  @media (max-width: 1140px) {
    margin-bottom: 0px;
    width: 100% !important;
    max-width: 300px;
  }
`;

const ButtonVersion = styled(ButtonLight)`
  font-size: 13px;
  min-width: 60px;
  margin-bottom: 10px;
  margin-top: 2px;
`;

const ItemContainer = styled.div`
  background-color: white;
  z-index: 70;
  position: relative;
  display: flex;
`;

const Title = styled.div`
  font-size: 14px;
  color: #000000;
  margin-bottom: 12px;
  margin-top: 6px;

  @media (max-width: 1330px) {
    font-size: 13px;
    margin-bottom: 6px;
  }
`;

const ScrollContainer = styled.div`
  height: calc(100% - 80px);
  position: absolute;
  z-index: 60;

  @media (max-width: 1140px) {
    height: calc(100vh - 370px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
    position: relative;
    top: 0px !important;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 356px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const VesselContainer = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  border-radius: 4px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
  margin-right: 6px;
  position: relative;
  z-index: 61;
  width: 228px;
  cursor: default;

  @media (max-width: 1140px) {
    width: 100%;
  }
`;

const VesselData = styled.div`
  padding: 8px 12px;
`;

const VesselNameContainer = styled.div`
  display: flex;
  margin-bottom: 4px;

  @media (max-width: 1140px) {
    display: inline-block;
  }
`;

const VesselName = styled.div`
  text-transform: uppercase;
  margin-right: 0px;
  font-weight: 700;
  text-overflow: ellipsis;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1140px) {
    font-size: 12px;
  }
`;

const VesselImo = styled.div`
  text-transform: uppercase;
  margin-left: 8px;
  font-weight: 700;
  text-overflow: ellipsis;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1140px) {
    font-size: 12px;
    margin-left: 0px;
  }
`;

/*const VesselNationality = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin-top: 2px;
`;*/

const VesselInfoContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grey};
`;

const InfoTexts = styled.div`
  margin-right: 4px;
  font-weight: 700;
  max-width: 128px;
  white-space: nowrap;
`;

const InfoDividers = styled.div`
  margin: 0px 4px 0px 2px;
`;

const FirstRow = styled.div`
  font-size: 12px;

  @media (max-width: 1330px) {
    font-size: 11px;
  }
`;

const FirstRowAgent = styled(FirstRow)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SecondRow = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.color.grey};

  @media (max-width: 1330px) {
    font-size: 10px;
  }
`;

const SourceRow = styled.div`
  font-size: 11px;
  height: 22px;
  margin-bottom: -2px;
  margin-top: 4px;

  @media (max-width: 1330px) {
    font-size: 10px;
  }
`;

const NotesRow = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grey};
  font-size: 11px;

  @media (max-width: 1330px) {
    font-size: 10px;
  }
`;

const NotesText = styled.div`
  font-size: 11px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.grey};

  @media (max-width: 1330px) {
    font-size: 10px;
  }
`;

const NotesRows = styled.div`
  margin-left: 6px;
  color: ${({ theme }) => theme.color.grey};
`;

const NotesKey = styled.div`
  margin-right: 6px;
  font-weight: 400;
`;

const NotesValue = styled.div`
  margin-left: 0x;
`;

const ThreeDotContainer = styled.div`
  position: absolute;
  margin-top: 3px;
  height: 17px;
  width: 26px;
  padding-left: 2px;
  right: 10px;
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  z-index: 62;
  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
  i {
    margin-top: -8px;
    svg {
      margin-top: -6px;
    }
  }
`;

const DragVessel = styled.div`
  position: relative;
  background-color: ${color_uncommitted};
  border: ${props => (props.overlapping ? '1px solid red' : '1px solid white')};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InvisibleContainer = styled.div`
  position: relative;
`;

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  z-index: 79;
`;

const ThreeDotPopupContainer = styled.div`
  background-color: white;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 80;
  position: absolute;
  top: 15px;
  left: 115px;
  padding: 4px 4px 4px 4px;
  font-weight: bold;
`;

const ThreeDotPopupRow = styled.div`
  display: flex;
  cursor: pointer;
  margin: 10px;

  span {
    margin-right: 9px;
    margin-top: 6px;
  }
`;

const IconContainer = styled.div`
  margin-top: -2px;
  width: 26px;
`;

const IconContainerRemove = styled.div`
  margin-left: -3px;
  width: 29px;
  margin-top: -1px;

  svg {
    width: 20px;
    fill: #d0011c;
  }
`;

const SourceTag = styled.div`
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  text-transform: uppercase;
  height: 18px;
  padding: 0 4px;
  display: inline-block;
  border-radius: 4px;
  color: #4a4a4a;
`;

const TooltipContainer = styled.div`
  height: 48px;
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const SmallTooltipContainer = styled.div`
  height: 16px;
  display: flex;
  font-size: 11px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.grey};
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  margin-right: 0px;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CreatebBy = styled.div`
  font-weight: normal;
`;

const Column = styled.div`
  width: 70px;
`;

const NoVesselsText = styled.div`
  width: 228px;
  text-align: center;
  font-size: 13px;
  margin-top: 18px;
`;

const BerthPlanningToolUpcomingVessels = () => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { alertsHeight } = useContext(AlertContext);

  const {
    canvasWidth,
    setDraggingArea,
    timelineUnit,
    draggingOverlapping,
    containerLimits,
    upcomingVessels,
    draggingRowInfo,
    collapsedRowHeight,
    rowHeight,
    sidePanelWidth,
  } = useContext(BerthPlanningToolContext);

  const dragging = useRef();
  const [dragItem, setDragItem] = useState({ index: undefined, top: undefined, left: undefined });
  const [topAmount, setTopAmount] = useState(89);
  const [modalType, setModalType] = useState(undefined);
  const [modalData, setModalData] = useState(undefined);
  const [removeModalId, setRemoveModalId] = useState(undefined);
  const [removeModalName, setRemoveModalName] = useState(undefined);
  const [removeModalImo, setRemoveModalImo] = useState(undefined);
  const [promoteModalId, setPromoteModalId] = useState(undefined);
  const [promoteModalName, setPromoteModalName] = useState(undefined);
  const [promoteModalImo, setPromoteModalImo] = useState(undefined);
  const [threeDotPopupIndex, setThreeDotPopupIndex] = useState(undefined);
  const [berthPlanModalData, setBerthPlanModalData] = useState(undefined);

  const [settingOk, setSettingOk] = useState(true);

  const dragRefs = useRef(null);
  const scrollRef = useRef();

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const clickThreeDots = i => {
    setThreeDotPopupIndex(i);
  };

  const getVesselProps = () => {
    const width = timelineUnit === 'week' ? canvasWidth / 21 : canvasWidth / 6;
    const height =
      (draggingRowInfo.open ? rowHeight : collapsedRowHeight) *
      (draggingRowInfo.height ? Math.ceil(upcomingVessels[dragItem.index].loa / draggingRowInfo.height) : 2);
    const fontSize = '10px';

    return {
      style: {
        width,
        height,
        fontSize,
      },
    };
  };

  const throttledSet = position => {
    if (dragging.current && dragItem.index !== undefined && settingOk) {
      setDraggingArea({
        top: dragItem.top + position.y - containerLimits.top,
        right:
          dragItem.left +
          position.x +
          (timelineUnit === 'week' ? canvasWidth / 21 : canvasWidth / 6) -
          containerLimits.left,
        bottom:
          dragItem.top +
          position.y +
          (draggingRowInfo.open ? rowHeight : collapsedRowHeight) *
            (draggingRowInfo.height ? Math.ceil(upcomingVessels[dragItem.index].loa / draggingRowInfo.height) : 2) -
          containerLimits.top,
        left: dragItem.left + position.x - containerLimits.left,
        id: upcomingVessels[dragItem.index].port_call_master_id,
        bollardAmount: draggingRowInfo.height
          ? Math.ceil(upcomingVessels[dragItem.index].loa / draggingRowInfo.height)
          : 2,
        new: true,
        tagId: upcomingVessels[dragItem.index].tagId,
      });

      setSettingOk(false);
      setTimeout(() => {
        if (mounted.current) {
          setSettingOk(true);
        }
      }, 150);
    }
  };

  const onStop = (i, position) => {
    if (dragging.current) {
      dragging.current = false;
      setDragItem({ index: undefined, top: undefined, left: undefined });
      setTopAmount(89);
      setDraggingArea({
        top: dragItem.top + position.y - containerLimits.top,
        right:
          dragItem.left +
          position.x +
          (timelineUnit === 'week' ? canvasWidth / 21 : canvasWidth / 6) -
          containerLimits.left,
        bottom:
          dragItem.top +
          position.y +
          (draggingRowInfo.open ? rowHeight : collapsedRowHeight) *
            (draggingRowInfo.height ? Math.ceil(upcomingVessels[dragItem.index].loa / draggingRowInfo.height) : 2) -
          containerLimits.top,
        left: dragItem.left + position.x - containerLimits.left,
        port_call_master_id: upcomingVessels[dragItem.index].port_call_master_id,
        bollardAmount: draggingRowInfo.height
          ? Math.ceil(upcomingVessels[dragItem.index].loa / draggingRowInfo.height)
          : 2,
        update: true,
        new: true,
        tagId: upcomingVessels[dragItem.index].tagId,
      });
    } else {
      setBerthPlanModalData(upcomingVessels[i]);
    }
  };

  const onDrag = (i, position) => {
    if (Math.abs(position.x) > 3 || Math.abs(position.y) > 3) {
      if (dragItem.index === undefined) {
        const rect = dragRefs.current.getBoundingClientRect();
        setTopAmount(89 - scrollRef.current.scrollTop);
        setDragItem({ index: i, top: rect.top - position.x, left: rect.left - position.y });
      }
      dragging.current = true;
      throttledSet(position);
    }
  };

  const promoteIsValid = () => {
    return (
      upcomingVessels[threeDotPopupIndex].imo &&
      upcomingVessels[threeDotPopupIndex].eta &&
      upcomingVessels[threeDotPopupIndex].etd
    );
  };

  const showAddModal = () => {
    setModalType('add');
    setModalData(undefined);
  };

  const showEditModal = () => {
    setModalType('edit');
    setModalData(upcomingVessels[threeDotPopupIndex]);
    setThreeDotPopupIndex(undefined);
  };

  const closeModal = () => {
    setModalType(undefined);
  };

  const closeRemoveModal = () => {
    setRemoveModalId(undefined);
    setRemoveModalImo(undefined);
    setRemoveModalName(undefined);
  };

  const closePromoteModal = () => {
    setPromoteModalId(undefined);
    setPromoteModalImo(undefined);
    setPromoteModalName(undefined);
  };

  const closeBerthPlanModal = () => {
    setBerthPlanModalData(undefined);
  };

  const showRemoveModal = () => {
    setRemoveModalId(upcomingVessels[threeDotPopupIndex].id);
    setRemoveModalImo(upcomingVessels[threeDotPopupIndex].imo);
    setRemoveModalName(upcomingVessels[threeDotPopupIndex].name);
    setThreeDotPopupIndex(undefined);
  };

  const showPromoteModal = () => {
    setPromoteModalId(upcomingVessels[threeDotPopupIndex].id);
    setPromoteModalImo(upcomingVessels[threeDotPopupIndex].imo);
    setPromoteModalName(upcomingVessels[threeDotPopupIndex].name);
    setThreeDotPopupIndex(undefined);
  };

  const VesselToolTip = v => {
    if (!v.created_by_email) {
      return (
        <>
          <SmallTooltipContainer>
            <FirstColumn>
              <RowHeaderForTooltip>{t('Created at')}:</RowHeaderForTooltip>
            </FirstColumn>
            <SecondColumn>
              {!!v.created_at && (
                <CreatebBy>
                  {dayjs(v.created_at).format(TIME_FORMAT_DAY)}{' '}
                  <span style={{ fontWeight: 700 }}>{dayjs(v.created_at).format(TIME_FORMAT_HOURS_MINUTES)}</span>
                </CreatebBy>
              )}
            </SecondColumn>
          </SmallTooltipContainer>
        </>
      );
    }

    return (
      <>
        <TooltipContainer>
          <FirstColumn>
            <RowHeaderForTooltip>{t('Added by')}:</RowHeaderForTooltip>
          </FirstColumn>
          <SecondColumn>
            <TooltipValue>{v.created_by_first_name + ' ' + v.created_by_last_name}</TooltipValue>
            <CreatebBy>{v.created_by_email}</CreatebBy>
            {!!v.created_at && (
              <CreatebBy>
                {dayjs(v.created_at).format(TIME_FORMAT_DAY)}{' '}
                <span style={{ fontWeight: 700 }}>{dayjs(v.created_at).format(TIME_FORMAT_HOURS_MINUTES)}</span>
              </CreatebBy>
            )}
          </SecondColumn>
        </TooltipContainer>
      </>
    );
  };

  const getNotesRows = notes => {
    try {
      const object = JSON.parse(notes);
      const keys = Object.keys(object);
      return (
        <>
          <SecondRow>{t('Notes')}</SecondRow>
          <NotesRows>
            {keys.map(key => {
              return (
                <NotesRow key={key}>
                  <NotesKey>
                    {key[0].toUpperCase() + key.substring(1).replace(/[A-Z]/g, s => ' ' + s.toLowerCase())}:
                  </NotesKey>
                  <NotesValue>{object[key] ? object[key].toString() : '-'}</NotesValue>
                </NotesRow>
              );
            })}
          </NotesRows>
        </>
      );
    } catch {
      return (
        <div style={{ display: 'flex' }}>
          <SecondRow style={{ marginRight: '12px' }}>{t('Notes')}:</SecondRow>
          <NotesText>{notes ? notes : '-'}</NotesText>
        </div>
      );
    }
  };

  return (
    <Container style={{ width: sidePanelWidth - 5 + 'px', height: 'calc(100% - ' + sidePanelWidth + 'px)' }}>
      <ItemContainer>
        <ButtonVersion onClick={showAddModal} disabled={!user.permissions.includes('manage_bpt')}>
          {t('Add new vessel')}
        </ButtonVersion>
      </ItemContainer>
      <ItemContainer>
        <Title>{t('Upcoming vessels')}</Title>
      </ItemContainer>
      <ScrollContainer
        ref={scrollRef}
        style={{
          overflowX: dragItem.index !== undefined ? 'visible' : 'hidden',
          overflowY: dragItem.index !== undefined ? 'visible' : 'scroll',
          top: topAmount + 'px',
        }}
        alertsHeight={alertsHeight}
      >
        {upcomingVessels.length === 0 && <NoVesselsText>{t('No upcoming vessels.')}</NoVesselsText>}
        {upcomingVessels.map((v, i) => (
          <InvisibleContainer key={v.tagId}>
            {user.permissions.includes('manage_bpt') && !(dragItem.index !== undefined && dragItem.index === i) && (
              <ThreeDotContainer onClick={() => clickThreeDots(i)}>
                <Icon type="ellipsis" style={{ fontSize: '22px', fontWeight: 'bold' }} />
              </ThreeDotContainer>
            )}
            <Draggable
              nodeRef={dragRefs}
              disabled={true}
              onStop={(e, position) => onStop(i, position)}
              onDrag={(e, position) => onDrag(i, position)}
            >
              <VesselContainer
                ref={dragRefs}
                style={
                  dragItem.index !== undefined &&
                  dragItem.index === i && { backgroundColor: 'transparent', boxShadow: 'none' }
                }
              >
                {!(dragItem.index !== undefined && dragItem.index === i) && (
                  <Tooltip
                    placement="left"
                    title={() => VesselToolTip(v)}
                    color="white"
                    overlayStyle={{ pointerEvents: 'none' }}
                  >
                    <VesselData>
                      <VesselNameContainer>
                        <VesselName>{v.name || '-'}</VesselName>
                        {/*<VesselNationality>{v.nationality || '-'}</VesselNationality>*/}
                        <VesselImo>{v.imo || '-'}</VesselImo>
                      </VesselNameContainer>
                      <VesselInfoContainer>
                        <Column>
                          <FirstRow>{t('Agent')}</FirstRow>
                          <SecondRow>{t('ETA')}</SecondRow>
                          <SecondRow>{t('ETD')}</SecondRow>
                          <SourceRow>{t('Data source')}</SourceRow>
                        </Column>
                        <InfoDividers>
                          <FirstRow>:</FirstRow>
                          <SecondRow>:</SecondRow>
                          <SecondRow>:</SecondRow>
                          <SourceRow>:</SourceRow>
                        </InfoDividers>
                        <InfoTexts>
                          <FirstRowAgent>{v.agent || '-'}</FirstRowAgent>
                          <SecondRow>{dayjs(v.eta).format(TIME_FORMAT)}</SecondRow>
                          <SecondRow>{v.etd ? dayjs(v.etd).format(TIME_FORMAT) : '-'}</SecondRow>
                          <SourceRow>{v.source ? <SourceTag>{v.source}</SourceTag> : '-'}</SourceRow>
                        </InfoTexts>
                      </VesselInfoContainer>
                      {!!v.notes && (
                        <Tooltip
                          placement="top"
                          title={v.notes}
                          color="white"
                          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                        >
                          {getNotesRows(v.notes)}
                        </Tooltip>
                      )}
                    </VesselData>
                  </Tooltip>
                )}
                {dragItem.index !== undefined && dragItem.index === i && (
                  <DragVessel {...getVesselProps()} overlapping={draggingOverlapping && dragging.current}>
                    {upcomingVessels[i].name}
                  </DragVessel>
                )}
              </VesselContainer>
            </Draggable>

            {threeDotPopupIndex === i && (
              <div>
                <Blocker onClick={() => setThreeDotPopupIndex(undefined)} />
                <ThreeDotPopupContainer>
                  <ThreeDotPopupRow onClick={showEditModal}>
                    <IconContainer>
                      <Icon type="edit-outline"></Icon>
                    </IconContainer>
                    {t('Edit')}
                  </ThreeDotPopupRow>
                  {promoteIsValid() && (
                    <ThreeDotPopupRow onClick={showPromoteModal}>
                      <IconContainer>
                        <Icon type="port-call"></Icon>
                      </IconContainer>
                      {t('Promote')}
                    </ThreeDotPopupRow>
                  )}
                  <ThreeDotPopupRow onClick={showRemoveModal}>
                    <IconContainerRemove>
                      <Icon type="trash"></Icon>
                    </IconContainerRemove>
                    {t('Remove')}
                  </ThreeDotPopupRow>
                </ThreeDotPopupContainer>
              </div>
            )}
          </InvisibleContainer>
        ))}
      </ScrollContainer>

      {!!modalType && <AddOrEditVesselsModal type={modalType} data={modalData} closeModal={closeModal} />}
      {!!removeModalId && (
        <RemoveVesselsModal
          id={removeModalId}
          name={removeModalName}
          imo={removeModalImo}
          closeModal={closeRemoveModal}
        />
      )}
      {!!promoteModalId && (
        <PromoteVesselsModal
          id={promoteModalId}
          name={promoteModalName}
          imo={promoteModalImo}
          closeModal={closePromoteModal}
        />
      )}
      {!!berthPlanModalData && (
        <AddOrEditBerthPlanModal type={'add'} vessel={berthPlanModalData} closeModal={closeBerthPlanModal} />
      )}
    </Container>
  );
};

export default BerthPlanningToolUpcomingVessels;
