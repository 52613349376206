import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';
import Checkbox from 'antd/es/checkbox';
import DatePicker from 'antd/es/date-picker';

import { UserContext } from '../../context/UserContext';
import ButtonLight from '../ui/ButtonLight';
import { wizardData as initialWizardData } from './incidentWizardData';
import AddIncidentPathItem from './AddIncidentPathItem';
import AddIncidentPathItemPreview from './AddIncidentPathItemPreview';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../ui/Icon';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  height: calc(100vh - 220px);
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  display: inline-block;
  width: 100%;
  margin-bottom: -6px;

  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 400px) {
      min-width: 70px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const SmallMiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 278px);
  margin: 0 6px 0 12px;
  padding-bottom: 20px;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-left: 12px;
  line-height: 22px;
  white-space: nowrap;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 12px;

  button {
    margin-top: 3px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 12px;
  margin-left: 12px;
  justify-content: flex-start;
`;

const DateLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  align-self: flex-start;
  margin-bottom: 4px;
`;

const DateTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 12px;
  margin-left: 12px;
  justify-content: flex-start;
`;

const DateText = styled.span`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
`;

const Options = styled.div`
  display: flex;
  justify-content: center;
`;

const Option = styled.div`
  background-color: ${props => (props.selected ? props.theme.color.secondary : props.theme.color.white)};
  border: 1px solid ${props => (props.selected ? props.theme.color.secondary : '#b1b1b1')};
  color: ${props => (props.selected ? props.theme.color.white : props.theme.color.grey_dark)};
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 12px;
  margin-bottom: 6px;
  min-width: 60px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border: 1px solid ${props => props.theme.color.secondary};
    color: ${props => (props.selected ? props.theme.color.white : props.theme.color.secondary)};
  }
`;

const TypeSelection = styled.div`
  height: calc(100vh - 278px);
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 20%;
`;

const TypeSelectionText = styled.div`
  display: inline-block;
  text-align: center;
  margin-bottom: 24px;
`;

const ChangeButton = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  margin-left: 12px;
  font-size: 18px;
  line-height: 19px;
  cursor: pointer;
`;

const TypeOption = styled.div`
  padding: 8px 6px;
  display: flex;
  position: relative;
`;

const FilterModal = styled.div`
  position: absolute;
  padding: 24px;
  top: 38px;
  left: 200px;
  z-index: 999;
  background-color: white;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  max-width: 300px;
`;

const ModalSmallHeader = styled.div`
  margin-bottom: 12px;
`;

const AddIncidentModal = ({ closeModal, addData, editData, sendDisabled }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const editOn = !!editData;

  const [mapOn, setMapOn] = useState(false);
  const [pointMap, setPointMap] = useState(true);

  const [type, setType] = useState(null);
  const [changeModalOpen, setChangeModalOpen] = useState(false);

  const [checkMapStartValues, setCheckMapStartValues] = useState(true);
  const [checkTypeWhenEdit, setCheckTypeWhenEdit] = useState(true);

  const wizardData = initialWizardData;

  const createInputItem = type => {
    if (type === 'array') {
      return [];
    } else if (type === 'string') {
      return null;
    } else if (type === 'point') {
      return [];
    } else if (type === 'datetime') {
      return null;
    } else if (type === 'boolean') {
      return true;
    } else if (type === 'int') {
      return 1;
    }
  };

  const createInputData = (data, type) => {
    const dataObject = { type };

    data.path.forEach(item => {
      if (!item.available_obj[type]) {
        return;
      }

      if (!editOn) {
        if (item.data_model) {
          dataObject[item.data_model.name] = createInputItem(item.data_model.type);
        }

        if (item.addition) {
          dataObject[item.addition.data_model.name] = createInputItem(item.addition.data_model.type);
        }
      } else {
        if (item.data_model) {
          if (item.data_model.key_name) {
            dataObject[item.data_model.name] =
              editData[item.data_model.name + '_key'] ||
              (item.data_model.type === 'array'
                ? []
                : editData[item.data_model.name + '_key'] === false
                  ? false
                  : null);
          } else {
            dataObject[item.data_model.name] =
              editData[item.data_model.name] ||
              (item.data_model.type === 'array' ? [] : editData[item.data_model.name] === false ? false : null);
          }
        }

        if (item.addition) {
          if (item.addition.data_model.in_data_key) {
            dataObject[item.addition.data_model.name] =
              editData.data[item.addition.data_model.in_data_key] ||
              (item.addition.data_model.type === 'array'
                ? []
                : editData[item.addition.data_model.name] === false
                  ? false
                  : null);
          } else if (item.addition.data_model.key_name) {
            dataObject[item.addition.data_model.name] =
              editData[item.addition.data_model.name + '_key'] ||
              (item.addition.data_model.type === 'array'
                ? []
                : editData[item.addition.data_model.name + '_key'] === false
                  ? false
                  : null);
          } else if (item.addition.data_model.in_data) {
            dataObject[item.addition.data_model.name] =
              editData.data[item.addition.data_model.name] ||
              (item.addition.data_model.type === 'array'
                ? []
                : editData.data[item.addition.data_model.name] === false
                  ? false
                  : null);
          } else {
            dataObject[item.addition.data_model.name] =
              editData[item.addition.data_model.name] ||
              (item.addition.data_model.type === 'array'
                ? []
                : editData[item.addition.data_model.name] === false
                  ? false
                  : null);
          }
        }
      }
    });

    if (data.endCheckBox) {
      if (!editOn) {
        dataObject[data.endCheckBox.data_model.name] = data.endCheckBox.data_model.default_value;
      } else {
        dataObject[data.endCheckBox.data_model.name] = editData[data.endCheckBox.data_model.name];
      }
    }

    if (data.autoCloseTime) {
      if (!editOn) {
        dataObject[data.autoCloseTime.data_model.name] = data.autoCloseTime.data_model.default_value;
      } else {
        dataObject[data.autoCloseTime.data_model.name] = editData[data.autoCloseTime.data_model.name];
      }
    }

    if (data.sendNotificationCheckBox && type === 'incident') {
      if (!editOn) {
        dataObject[data.sendNotificationCheckBox.data_model.name] =
          data.sendNotificationCheckBox.data_model.default_value;
      } else {
        dataObject[data.sendNotificationCheckBox.data_model.name] =
          editData[data.sendNotificationCheckBox.data_model.name];
      }
    }

    return dataObject;
  };

  const createOpenData = data => {
    return data.path.map((item, index) => {
      if (index === 0 || !!editData) {
        return true;
      } else {
        return false;
      }
    });
  };

  const [inputData, setInputData] = useState({});
  const [editOrigData, setEditOrigData] = useState({});
  const [openData, setOpenData] = useState([]);

  const [previewOn, setPreviewOn] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const dropdown = document.querySelector('#filter-modal');
      if (ref.current && !ref.current.contains(event.target) && (dropdown ? !dropdown.contains(event.target) : true)) {
        setChangeModalOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTypeSelected = newType => {
    setType(newType);
    setInputData(createInputData(wizardData, newType));
    setEditOrigData(createInputData(wizardData, newType));
    setOpenData(createOpenData(wizardData));
  };

  useEffect(() => {
    if (checkTypeWhenEdit && editOn) {
      onTypeSelected(editData.type);
      setCheckTypeWhenEdit(false);
    }
  }, [checkTypeWhenEdit, editData, editOn, onTypeSelected]);

  useEffect(() => {
    if (editOn && checkMapStartValues && inputData.point) {
      setCheckMapStartValues(false);

      if (inputData.point.length > 0) {
        setMapOn(true);

        if (inputData.point[0].geometry.type === 'Polygon') {
          setPointMap(false);
        }
      }
    }
  }, [checkMapStartValues, editOn, inputData.point]);

  const goBack = () => {
    setPreviewOn(false);
    setTimeout(() => {
      let element = document.getElementById('incident-path-item-0');
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, 100);
  };

  const previewData = () => {
    setPreviewOn(true);
    setTimeout(() => {
      let element = document.getElementById('incident-path-item-0');
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, 100);
  };

  const sendUpdatedData = async () => {
    const dataToBeSent = { id: editData.id, type };

    Object.keys(inputData).forEach(keyName => {
      if (JSON.stringify(inputData[keyName]) !== JSON.stringify(editOrigData[keyName])) {
        dataToBeSent[keyName] = inputData[keyName];
      }
    });

    // TODO: make dynamic based on the fields & types
    let incident_type = dataToBeSent.incident_type;
    if (incident_type) {
      let incidentTypeName = incident_type;
      const incidentTypeItem = wizardData.path.find(item => item.data_model.name === 'incident_type');
      if (incidentTypeItem) {
        incidentTypeName = incidentTypeItem.options.find(item => item.value === incident_type)?.label || incident_type;
      }

      dataToBeSent.incident_type_key = incident_type;
      dataToBeSent.incident_type_name = incidentTypeName;
      delete dataToBeSent.incident_type;
    }
    let location_option = dataToBeSent.location_option;
    if (location_option) {
      let locationOptionName = location_option;
      const locationOptionItem = wizardData.path.find(item => item.data_model.name === 'location_option');
      if (locationOptionItem) {
        locationOptionName =
          locationOptionItem.options.find(item => item.value === location_option)?.label || location_option;
      }

      dataToBeSent.location_option_key = location_option;
      dataToBeSent.location_option_name = locationOptionName;
      delete dataToBeSent.location_option;
    }

    if (dataToBeSent.point) {
      dataToBeSent.geojson = dataToBeSent.point;
      delete dataToBeSent.point;
    }

    const closable = await addData(dataToBeSent);

    if (closable) {
      closeModal();
    }
  };

  const sendData = async () => {
    if (editOn) {
      sendUpdatedData();
      return;
    }
    // TODO: make dynamic based on the fields & types
    const { point, date, end_date, incident_type, location_option, ...data } = inputData;
    let incidentTypeName = incident_type;
    const incidentTypeItem = wizardData.path.find(item => item.data_model.name === 'incident_type');
    if (incidentTypeItem) {
      incidentTypeName = incidentTypeItem.options.find(item => item.value === incident_type)?.label || incident_type;
    }
    let locationOptionName = location_option;
    const locationOptionItem = wizardData.path.find(item => item.data_model.name === 'location_option');
    if (locationOptionItem) {
      locationOptionName =
        locationOptionItem.options.find(item => item.value === location_option)?.label || location_option;
    }

    const newData = {
      ...data,
      geojson: point,
      date: date.toISOString(),
    };

    if (end_date) {
      newData.end_date = end_date.toISOString();
    }

    if (location_option) {
      newData.location_option_key = location_option;
      newData.location_option_name = locationOptionName;
    }

    if (incident_type) {
      newData.incident_type_key = incident_type;
      newData.incident_type_name = incidentTypeName;
    }

    const closable = await addData(newData);

    if (closable) {
      closeModal();
    }
  };

  const toggleCheckbox = (name, value) => {
    setInputData({ ...inputData, [name]: value.target.checked });
  };

  const onDateTimeChange = (name, value) => {
    setInputData({ ...inputData, [name]: value ? value.toISOString() : null });
  };

  const goNext = i => {
    let openList = [...openData];
    openList.splice(i, 1, false);
    if (i + 1 < openList.length) {
      openList.splice(i + 1, 1, true);
      setTimeout(() => {
        let element = document.getElementById('incident-path-item-' + (i + 1));
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      }, 400);
    }
    setOpenData(openList);
  };

  const isDataValid = () => {
    if (!inputData.type) {
      return false;
    }

    for (const data of wizardData.path) {
      const dataKey = data.data_model?.name;
      if (data.mandatory_obj[type] && (inputData[dataKey] === null || inputData[dataKey] === '')) {
        return false;
      }

      if (data.addition?.type === 'map' || data.type === 'map') {
        if (!mapOn) {
          if (inputData[data.addition ? data.addition.data_model.name : data.data_model.name].length > 0) {
            return false;
          }
        } else {
          if (
            pointMap &&
            (data.addition
              ? inputData[data.addition.data_model.name].length !== 1 ||
                inputData[data.addition.data_model.name][0].geometry.type !== 'Point'
              : inputData[data.data_model.name].length !== 1 ||
                inputData[data.data_model.name][0].geometry.type !== 'Point')
          ) {
            return false;
          } else if (
            !pointMap &&
            (data.addition
              ? inputData[data.addition.data_model.name].length === 0 ||
                inputData[data.addition.data_model.name][0].geometry.type !== 'Polygon'
              : inputData[data.data_model.name].length === 0 ||
                inputData[data.data_model.name][0].geometry.type !== 'Polygon')
          ) {
            return false;
          }
        }
      } else if (
        (data.addition?.when === 'any' || data.addition?.when === inputData[dataKey]) &&
        data.addition?.mandatory_obj[type]
      ) {
        if (data.addition.data_model.type === 'array' && !inputData[data.addition.data_model.name].length) {
          return false;
        } else if (inputData[data.addition.data_model.name] === null) {
          return false;
        }
      }
    }
    return true;
  };

  const showablePath = wizardData.path.filter(d => d.available_obj[type]);

  return (
    <Modal
      title={editOn ? t('Edit incident or event') : t('Announce incident or event')}
      open={true}
      width={800}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <ModalInnerInput>
        {!type && !editOn && (
          <TypeSelection>
            <TypeSelectionText>{t('Choose the announcement type')}:</TypeSelectionText>
            <Options>
              <Option selected={type === 'incident'} onClick={() => onTypeSelected('incident')}>
                {t('Incident')}
              </Option>
              <Option selected={type === 'event'} onClick={() => onTypeSelected('event')}>
                {t('Event')}
              </Option>
            </Options>
          </TypeSelection>
        )}
        {!!type &&
          (previewOn ? (
            <ScrollContainer>
              <TypeOption>
                {t('Announcement type')}:{' '}
                <span style={{ paddingLeft: '24px' }}>
                  {type.charAt(0).toUpperCase() + type.toLowerCase().slice(1)}
                </span>
              </TypeOption>
              {showablePath.map((item, index) => {
                return (
                  <AddIncidentPathItemPreview type={type} key={index} data={item} index={index} inputData={inputData} />
                );
              })}
              <SmallMiddleLine />
              {wizardData.autoCloseTime &&
                (inputData[wizardData.autoCloseTime.data_model.name] ? (
                  <DateTextContainer>
                    <DateText style={{ paddingRight: '4px' }}>
                      {t('This {{type}} will be closed automatically at', { type })}
                    </DateText>
                    <DateText style={{ fontWeight: 600 }}>
                      {inputData[wizardData.autoCloseTime.data_model.name]
                        ? dayjs(inputData[wizardData.autoCloseTime.data_model.name]).format(TIME_FORMAT)
                        : '-'}
                    </DateText>
                    .
                  </DateTextContainer>
                ) : (
                  <DateTextContainer>
                    <DateText style={{ paddingRight: '4px' }}>
                      {t('This {{type}} will not be closed automatically.', { type })}
                    </DateText>
                  </DateTextContainer>
                ))}
              {wizardData.endCheckBox && (
                <SwitchContainer>
                  <Checkbox size="small" checked={inputData[wizardData.endCheckBox.data_model.name]} disabled={true} />
                  <SwitchLabel>{t(wizardData.endCheckBox.label, { type })}</SwitchLabel>
                </SwitchContainer>
              )}
              {wizardData.sendNotificationCheckBox && type === 'incident' && (
                <SwitchContainer>
                  <Checkbox
                    size="small"
                    checked={inputData[wizardData.sendNotificationCheckBox.data_model.name]}
                    disabled={true}
                  />
                  <SwitchLabel>{t(wizardData.sendNotificationCheckBox.label, { type })}</SwitchLabel>
                </SwitchContainer>
              )}
            </ScrollContainer>
          ) : (
            <ScrollContainer>
              <TypeOption>
                {t('Announcement type')}:{' '}
                <span style={{ paddingLeft: '24px' }}>
                  {type.charAt(0).toUpperCase() + type.toLowerCase().slice(1)}
                </span>
                {!editOn && (
                  <Tooltip
                    title={t('Changing the announcement type will result in losing all already added data.')}
                    color="white"
                    overlayInnerStyle={{ color: '#4a4a4a', fontSize: '14px', maxWidth: '300px' }}
                  >
                    <ChangeButton onClick={() => setChangeModalOpen(true)}>
                      <Icon type="edit" />
                    </ChangeButton>
                  </Tooltip>
                )}
                {changeModalOpen && (
                  <FilterModal id="filter-modal" ref={ref}>
                    <ModalSmallHeader>{t('Choose announcement type')}:</ModalSmallHeader>
                    <ModalSmallHeader>
                      {t('Be aware that changing the announcement type will result in losing all already added data.')}
                    </ModalSmallHeader>
                    <Options>
                      <Option
                        onClick={() => {
                          onTypeSelected('incident');
                          setChangeModalOpen(false);
                        }}
                      >
                        {t('Incident')}
                      </Option>
                      <Option
                        onClick={() => {
                          onTypeSelected('event');
                          setChangeModalOpen(false);
                        }}
                      >
                        {t('Event')}
                      </Option>
                    </Options>
                  </FilterModal>
                )}
              </TypeOption>
              {type === 'event' && (
                <TypeOption style={{ fontSize: '12px', paddingBottom: '0px' }}>
                  {t('Star marked sections are mandatory.')}
                </TypeOption>
              )}
              {showablePath.map((item, index) => {
                return (
                  <AddIncidentPathItem
                    type={type}
                    key={index}
                    data={item}
                    index={index}
                    inputData={inputData}
                    setInputData={setInputData}
                    openData={openData}
                    setOpenData={setOpenData}
                    goNext={goNext}
                    last={showablePath.length - 1 === index}
                    mapOn={mapOn}
                    setMapOn={setMapOn}
                    pointMap={pointMap}
                    setPointMap={setPointMap}
                    editOn={editOn}
                  />
                );
              })}
              <SmallMiddleLine />
              <Row>
                {wizardData.autoCloseTime && (
                  <DateContainer>
                    <DateLabel>{t(wizardData.autoCloseTime.label, { type })}</DateLabel>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 1 }}
                      value={
                        inputData[wizardData.autoCloseTime.data_model.name]
                          ? dayjs(inputData[wizardData.autoCloseTime.data_model.name])
                          : null
                      }
                      style={{
                        width: '240px',
                      }}
                      onChange={value => onDateTimeChange(wizardData.autoCloseTime.data_model.name, value)}
                      onSelect={value => onDateTimeChange(wizardData.autoCloseTime.data_model.name, value)}
                      placeholder={t('Time')}
                    />
                  </DateContainer>
                )}
                {wizardData.endCheckBox && (
                  <SwitchContainer>
                    <Checkbox
                      size="small"
                      checked={inputData[wizardData.endCheckBox.data_model.name]}
                      onChange={e => toggleCheckbox(wizardData.endCheckBox.data_model.name, e)}
                    />
                    <SwitchLabel>{t(wizardData.endCheckBox.label, { type })}</SwitchLabel>
                  </SwitchContainer>
                )}
                {wizardData.sendNotificationCheckBox && type === 'incident' && (
                  <SwitchContainer>
                    <Checkbox
                      size="small"
                      checked={inputData[wizardData.sendNotificationCheckBox.data_model.name]}
                      onChange={e => toggleCheckbox(wizardData.sendNotificationCheckBox.data_model.name, e)}
                    />
                    <SwitchLabel>{t(wizardData.sendNotificationCheckBox.label, { type })}</SwitchLabel>
                  </SwitchContainer>
                )}
              </Row>
            </ScrollContainer>
          ))}
        <MiddleLine />
        <ActionButtons>
          {!previewOn && (
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
          )}
          {previewOn && (
            <ButtonLight cancel onClick={goBack}>
              {t('Go back')}
            </ButtonLight>
          )}
          {!previewOn && (
            <ButtonLight disabled={!isDataValid()} onClick={previewData}>
              {t('Preview')}
            </ButtonLight>
          )}
          {previewOn && (
            <ButtonLight disabled={sendDisabled} onClick={sendData}>
              {t('Send')}
            </ButtonLight>
          )}
        </ActionButtons>
      </ModalInnerInput>
    </Modal>
  );
};

export default AddIncidentModal;
