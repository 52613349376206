import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import DatePicker from 'antd/es/date-picker';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import ButtonLight from '../../ui/ButtonLight';
import DebounceAutocomplete from '../../ui/DebounceAutocomplete';
import Button from '../../ui/Button';
import Icon from '../../ui/Icon';
import { TIME_FORMAT, TIME_FORMAT_HOURS_MINUTES } from '../../../utils/constants';

const ModalInner = styled.div`
  z-index: 999;
  padding: 0;
  background-color: white;
  min-height: 145px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const InputContainer = styled.div`
  padding: 8px 0 12px 0;
  margin-right: 12px;
  width: calc((100% / 6) - 12px);
`;

const FieldContainer = styled.div`
  display: flex;
  width: calc(100% - ${props => (props.amount > 1 ? '34px' : '0px')});
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const Row = styled.div`
  display: flex;
`;

const ScrollContainer = styled.div`
  padding: 10px 4px 4px 16px;
  overflow-y: auto;
  max-height: calc(100vh - 490px);
  min-height: 120px;
  min-width: 800px;
`;

const UpperScrollContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

const SplitButtons = styled.div`
  padding: 0px 0px 7px 7px;
  display: inline;
  min-width: 10px;
`;

const RowContainerSplitButtons = styled.div`
  padding: 0px 0px 7px 0px;
  display: inline;
  min-width: 10px;
`;

const AddBerthTransitionsLink = styled.a`
  text-transform: none;
  letter-spacing: 0;
`;

const TrashButton = styled(Button)`
  margin-left: 2px;
  margin-right: 5px;
  color: #1c1c1c;
  :hover {
    color: #d0011c;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PromotionSwitch = styled.div`
  display: flex;
  margin-left: 16px;
  margin-top: 20px;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  margin-left: 12px;
  line-height: 16px;
`;

const SpinnerContainer = styled.div`
  display: table;
  margin: auto;
`;

class ErrorField {
  #key;
  #value;
  #message;
  #type;

  constructor(key, value, type, message) {
    this.#key = key;
    this.#value = value;
    this.#type = type;
    this.#message = message;
  }

  key() {
    return this.#key;
  }

  value() {
    return this.#key;
  }

  message() {
    return this.#message;
  }

  type() {
    return this.#type;
  }
}

class ErrorCollection {
  #allErrorFields;

  constructor() {
    this.#allErrorFields = [];
  }

  add(errorField) {
    this.#allErrorFields[errorField.key()] = errorField;
  }

  getErrorField(fieldKey) {
    return this.#allErrorFields[fieldKey];
  }

  clearErrorField(fieldKey) {
    this.#allErrorFields[fieldKey] = undefined;
  }
}

const AddOrEditVesselsModal = ({ type, closeModal, data }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { berths, sendAddUpcomingVessel, sendUpdateUpcomingVessel, berthAreasAvailable } = useContext(
    BerthPlanningToolContext
  );

  const [inputData, setInputData] = useState([
    {
      agent: data ? data.agent : undefined,
      name: data ? data.name : undefined,
      berth_code: data ? data.berth_code : undefined,
      imo: data ? data.imo : undefined,
      eta: data ? data.eta : undefined,
      etd: data ? data.etd : undefined,
      errorFields: new ErrorCollection(),
    },
  ]);

  const [promotionOn, setPromotionOn] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  const [sending, setSending] = useState(false);

  let berthList = [];
  if (berthAreasAvailable) {
    berths.forEach(g => {
      g.subRows.forEach(b => {
        berthList.push({ short_name: b.short_name, id: b.id });
      });
    });
  } else {
    berthList = berths;
  }

  const title = type === 'add' ? t('Add upcoming vessel') : t('Edit upcoming vessel');
  const buttonText = type === 'add' ? (promotionOn ? t('Add and promote') : t('Add')) : t('Save');

  const dataIsValid = () => {
    let valid = true;
    inputData.forEach(d => {
      if (!promotionOn) {
        if (!d.imo || !d.eta) {
          valid = false;
        }
      } else {
        if (!d.imo || !d.eta || !d.etd) {
          valid = false;
        }
      }
    });
    return valid;
  };

  const createMultiLines = (value, index) => {
    let newInputData = [...inputData];
    value = value.trimEnd();
    let rows = value.split('\n');

    const newRows = rows.length - (inputData.length - index);

    for (let i = 0; i < newRows; i++) {
      newInputData.push({
        agent: undefined,
        name: undefined,
        berth_code: undefined,
        imo: undefined,
        eta: undefined,
        etd: undefined,
        errorFields: new ErrorCollection(),
      });
    }

    rows.forEach((row, index1) => {
      let values = row.split('\t');

      values.forEach((value, index2) => {
        switch (index2) {
          case 0:
            newInputData[index1 + index]['agent'] = value;
            break;
          case 1:
            newInputData[index1 + index]['name'] = value;
            break;
          case 2:
            newInputData[index1 + index]['imo'] = value;
            break;
          case 3: {
            let berth = findBerth(value);
            if (berth) {
              newInputData[index1 + index]['berth_code'] = berth.id;
            } else {
              // Invalid berth code.
              let error = new ErrorField('berth_code', value, 'berth code', '');
              newInputData[index1 + index]['errorFields'].add(error);
              newInputData[index1 + index]['berth_code'] = value;
            }
            break;
          }
          case 4: {
            // Parse date/time.

            let momentETA = dayjs(value, TIME_FORMAT, true);
            let dateString = undefined;

            // Check if given date/time is valid
            if (momentETA.isValid()) {
              dateString = momentETA.format();
            } else {
              //errorFields
              newInputData[index1 + index]['errorFields'].add(new ErrorField('eta', value, 'eta', ''));
            }

            newInputData[index1 + index]['eta'] = dateString;
            break;
          }
          case 5: {
            let momentETD = dayjs(value, TIME_FORMAT, true);
            let dateString = undefined;

            // Check if given date/time is valid
            if (momentETD.isValid()) {
              dateString = momentETD.format();
            } else {
              //errorFields
              newInputData[index1 + index]['errorFields'].add(new ErrorField('etd', value, 'etd', ''));
            }

            newInputData[index1 + index]['etd'] = dateString;
            break;
          }
          default:
            break;
        }
      });
    });

    setInputData(newInputData);
  };

  const findBerth = berthValue => {
    return berthList.find(b => berthValue === b.id || berthValue === b.name || berthValue === b.short_name);
  };

  const updateAgent = (value, index) => {
    if (value?.includes('\n') && type === 'add') {
      createMultiLines(value, index);
      return;
    }
    let newInputData = [...inputData];
    newInputData[index]['agent'] = value;
    setInputData(newInputData);
  };

  const updateVessel = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['name'] = value;
    setInputData(newInputData);
  };

  const selectVesselOrImo = (value, obj, index) => {
    let newInputData = [...inputData];
    newInputData[index]['name'] = obj.data.vessel_name;
    newInputData[index]['imo'] = obj.data.imo;
    setInputData(newInputData);
  };

  const updateImo = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['imo'] = value;
    setInputData(newInputData);
  };

  const onETAChange = (value, index) => {
    if (value) {
      let newInputData = [...inputData];
      newInputData[index]['eta'] = value.format();
      newInputData[index]['errorFields'].clearErrorField('eta');
      setInputData(newInputData);
    } else {
      let newInputData = [...inputData];
      newInputData[index]['eta'] = value;
      setInputData(newInputData);
    }
  };

  const onETDChange = (value, index) => {
    if (value) {
      let newInputData = [...inputData];
      newInputData[index]['etd'] = value.format();
      newInputData[index]['errorFields'].clearErrorField('etd');
      setInputData(newInputData);
    } else {
      let newInputData = [...inputData];
      newInputData[index]['etd'] = value;
      setInputData(newInputData);
    }
  };

  const handleBerthChange = (value, index) => {
    let newInputData = [...inputData];

    // display error if necessary
    if (!findBerth(value)) {
      let error = new ErrorField('berth_code', value, 'berth code', '');
      newInputData[index]['errorFields'].add(error);
    } else {
      newInputData[index]['errorFields'].clearErrorField('berth_code');
    }

    newInputData[index]['berth_code'] = value;
    setInputData(newInputData);
  };

  const fetchVesselList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      sort: 'vessel_name',
      search: value,
    };
    const result = await apiCall('get', 'vessels', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.vessel_name + d.imo, label: d.vessel_name + ' (' + d.imo + ')', data: d };
      });
    }

    return [];
  };

  const fetchAgentList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    const result = await apiCall('get', 'agents', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  const addSplitButton = () => {
    let newInputData = [...inputData];
    newInputData.push({
      agent: undefined,
      name: undefined,
      berth_code: undefined,
      imo: undefined,
      eta: undefined,
      etd: undefined,
      errorFields: data ? data.errorFields : new ErrorCollection(),
    });
    setInputData(newInputData);
  };

  const populateWithUnsavedVessels = async data => {
    if (data.result != 'ERROR_UNSAVED_VESSELS') {
      return;
    }

    let newInputData = [];
    data.unsaved_vessels.forEach(vessel => {
      let errorFields = new ErrorCollection();
      if (vessel.errorFieldKey) {
        let errorMessage = vessel.invalidParameterError ? vessel.invalidParameterError : vessel.duplicateEntryError;
        errorFields.add(
          new ErrorField(vessel.errorFieldKey, vessel.errorFieldKey, vessel.errorFieldType, errorMessage)
        );
      }

      newInputData.push({
        agent: vessel.vessel.agent,
        name: vessel.vessel.name,
        berth_code: vessel.vessel.berth_code,
        imo: vessel.vessel.imo,
        eta: vessel.vessel.eta,
        etd: vessel.vessel.etd,
        errorFields: errorFields,
      });
    });

    setInputData(newInputData);
  };

  const sendData = async () => {
    // Display progress bar
    setLoadingState(true);

    setSending(true);

    let apiResult;
    try {
      if (type === 'add') {
        apiResult = await sendAddUpcomingVessel(inputData, promotionOn);
      } else {
        apiResult = await sendUpdateUpcomingVessel({ ...inputData[0], id: data.id });
      }
    } catch (e) {
      setSending(false);
    }

    setSending(false);

    let responseData = await Promise.resolve(apiResult.data);

    if (responseData.result == 'ERROR_UNSAVED_VESSELS') {
      // Any vessels that could not be saved successfully are re-entered into modal box.
      await populateWithUnsavedVessels(responseData);

      // Hide progress bar
      setLoadingState(false);

      if (type === 'add') {
        const countSuccessfulVessels = responseData.countSuccessfulVessels;
        const countFailedVessels = responseData.countFailedVessels;
        const totalVesselCount = countSuccessfulVessels + countFailedVessels;
        let unsavedVesselsModalBoxTitle = '';
        let unsavedVesselsModalBoxBody = '';

        if (countSuccessfulVessels === 0) {
          unsavedVesselsModalBoxTitle = t('No vessels could be added.');
          unsavedVesselsModalBoxBody = t(
            'Some of your vessels contained incorrect information. Please make the necessary corrections and try again.'
          );
          Modal.error({
            title: unsavedVesselsModalBoxTitle,
            content: unsavedVesselsModalBoxBody,
          });
        } else {
          unsavedVesselsModalBoxTitle = t('{{count}} of {{totalCount}} vessels have been added.', {
            count: countSuccessfulVessels,
            totalCount: totalVesselCount,
          });
          unsavedVesselsModalBoxBody = t(
            '{{count}} vessel(s) have now been added. However {{countFailed}} vessel(s) could not be added because they contained incorrect information. If you want, you can now make the necessary corrections and try to add them again.',
            { count: countSuccessfulVessels, countFailed: countFailedVessels }
          );
          Modal.warning({
            title: unsavedVesselsModalBoxTitle,
            content: unsavedVesselsModalBoxBody,
          });
        }
      }

      if (type === 'edit') {
        Modal.warning({
          title: t('Vessel could not be saved'),
          content: t('Vessel contained incorrect information. Please make the necessary corrections and try again.'),
        });
      }

      return;
    }

    closeModal();
  };

  const onImoChange = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['imo'] = value;
    setInputData(newInputData);
    newInputData[index]['errorFields'].clearErrorField('imo');
  };

  const onNameChange = (value, index) => {
    let newInputData = [...inputData];
    newInputData[index]['name'] = value;
    setInputData(newInputData);
  };

  const removeSplitButton = index => {
    let inputDataForDeleting = [...inputData];
    inputDataForDeleting.splice(index, 1);
    setInputData(inputDataForDeleting);
  };

  const getVesselErrorField = (vessel, fieldKey) => {
    if (vessel.errorFields) {
      return vessel.errorFields.getErrorField(fieldKey);
    } else {
      return undefined;
    }
  };

  return (
    <Modal title={title} open={!!type} width={1000} onCancel={closeModal} footer={null}>
      <ModalInner>
        <UpperScrollContainer>
          <ScrollContainer>
            {inputData.map((d, index) => (
              <Row key={index}>
                <FieldContainer amount={inputData.length}>
                  <InputContainer>
                    {index === 0 && <RowHeader>{t('Agent')}</RowHeader>}
                    <DebounceAutocomplete
                      placeholder={t('Agent name')}
                      fetchOptions={fetchAgentList}
                      onChange={value => updateAgent(value, index)}
                      onSelect={value => updateAgent(value, index)}
                      value={d.agent}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => updateAgent(null, index)}
                    />
                  </InputContainer>
                  <InputContainer>
                    {index === 0 && <RowHeader>{t('Vessel')}</RowHeader>}
                    <DebounceAutocomplete
                      placeholder={t('Vessel name')}
                      fetchOptions={fetchVesselList}
                      onChange={value => onNameChange(value, index)}
                      onSelect={(value, data) => selectVesselOrImo(value, data, index)}
                      value={d.name}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => updateVessel(null, index)}
                      index={index}
                    />
                  </InputContainer>
                  <InputContainer>
                    {index === 0 && (
                      <RowHeader>
                        {t('IMO')}
                        <Required>*</Required>
                      </RowHeader>
                    )}
                    <Form.Item
                      hasFeedback
                      validateStatus={getVesselErrorField(d, 'imo') && 'error'}
                      help={getVesselErrorField(d, 'imo') && 'Incorrect IMO number.'}
                    >
                      <DebounceAutocomplete
                        placeholder={t('Vessel exact IMO')}
                        fetchOptions={fetchVesselList}
                        onChange={value => onImoChange(value, index)}
                        onSelect={(value, data, index) => selectVesselOrImo(value, data, index)}
                        value={d.imo}
                        style={{ width: '100%' }}
                        allowClear={true}
                        onClear={() => updateImo(null, index)}
                        index={index}
                      />
                    </Form.Item>
                  </InputContainer>
                  <InputContainer>
                    {index === 0 && <RowHeader>{t('Berth')}</RowHeader>}
                    <Form.Item
                      hasFeedback
                      validateStatus={getVesselErrorField(d, 'berth_code') && 'error'}
                      help={getVesselErrorField(d, 'berth_code') && 'Incorrect berth code.'}
                    >
                      <Select
                        showSearch
                        placeholder={t('Berth name')}
                        optionFilterProp="label"
                        onChange={value => handleBerthChange(value, index)}
                        style={{ width: '100%' }}
                        options={berthList.map(g => {
                          return { label: g.id === 'unknown' ? t('No berth') : g.short_name, value: g.id };
                        })}
                        value={d.berth_code || undefined}
                      />
                    </Form.Item>
                  </InputContainer>
                  <InputContainer>
                    {index === 0 && (
                      <RowHeader>
                        {t('ETA')}
                        <Required>*</Required>
                      </RowHeader>
                    )}
                    <Form.Item
                      hasFeedback
                      validateStatus={getVesselErrorField(d, 'eta') && 'error'}
                      help={getVesselErrorField(d, 'eta') && 'Incorrect ETA.'}
                    >
                      <DatePicker
                        format={TIME_FORMAT}
                        showTime={{ format: TIME_FORMAT_HOURS_MINUTES, minuteStep: 15 }}
                        value={d.eta ? dayjs(d.eta) : null}
                        style={{
                          width: '100%',
                        }}
                        onChange={value => onETAChange(value, index)}
                        onSelect={value => onETAChange(value, index)}
                        placeholder={t('Estimated time of arrival')}
                      />
                    </Form.Item>
                  </InputContainer>
                  <InputContainer>
                    {index === 0 && (
                      <RowHeader>
                        {t('ETD')}
                        {promotionOn && <Required>*</Required>}
                      </RowHeader>
                    )}
                    <Form.Item
                      hasFeedback
                      validateStatus={getVesselErrorField(d, 'etd') && 'error'}
                      help={getVesselErrorField(d, 'etd') && 'Incorrect ETD.'}
                    >
                      <DatePicker
                        format={TIME_FORMAT}
                        showTime={{ format: TIME_FORMAT_HOURS_MINUTES, minuteStep: 15 }}
                        value={d.etd ? dayjs(d.etd) : null}
                        style={{
                          width: '100%',
                        }}
                        onChange={value => onETDChange(value, index)}
                        onSelect={value => onETDChange(value, index)}
                        placeholder={t('Estimated time of departure')}
                      />
                    </Form.Item>
                  </InputContainer>
                </FieldContainer>
                {inputData.length > 1 && type === 'add' && (
                  <RowContainerSplitButtons>
                    <TrashButton
                      copy
                      style={{
                        zIndex: 5,
                        marginTop: `${index === 0 ? 31 : 8}px`,
                        marginLeft: 0,
                        color: '#D0011C',
                      }}
                      onClick={() => removeSplitButton(index)}
                    >
                      <Icon type={'trash'} />
                    </TrashButton>
                  </RowContainerSplitButtons>
                )}
              </Row>
            ))}
            {isLoading && (
              <SpinnerContainer>
                <Spin size="large" />
              </SpinnerContainer>
            )}
            {type === 'add' && (
              <SplitButtons style={{ marginLeft: 'calc(100% - 186px)' }}>
                <Button
                  normal
                  style={{ zIndex: '5', marginTop: '5px', marginLeft: '62px', color: '#4990DD' }}
                  onClick={() => addSplitButton()}
                >
                  <Icon type={'circle-plus'} />
                  <AddBerthTransitionsLink href="#" style={{ fontSize: '15px', fontWeight: '400' }}>
                    {t('Add vessel')}
                  </AddBerthTransitionsLink>
                </Button>
              </SplitButtons>
            )}
          </ScrollContainer>
        </UpperScrollContainer>

        <MiddleLine />

        <ButtonContainer>
          {type === 'add' ? (
            <PromotionSwitch>
              <Switch
                size="small"
                checked={promotionOn}
                onChange={e => setPromotionOn(e)}
                aria-label="Auto promotion"
              />
              <SwitchLabel>{t('Promote all vessels')}</SwitchLabel>
            </PromotionSwitch>
          ) : (
            <div />
          )}
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || isLoading || sending} sending={sending} onClick={sendData}>
              {sending ? t('Add') : buttonText}
            </ButtonLight>
          </ActionButtons>
        </ButtonContainer>
      </ModalInner>
    </Modal>
  );
};

export default AddOrEditVesselsModal;
