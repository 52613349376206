import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../../context/UserContext';
import useApi from '../../../../hooks/useApi';

import List from '../../../ui/List';
import ListActionButton from '../../../ui/ListActionButton';
import Icon from '../../../ui/Icon';
import AddOrEditCommodityModal from './AddOrEditCommodityModal';
import RemoveCommodityModal from './RemoveCommodityModal';

const CommodityList = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [commodityModalData, setCommodityModalData] = useState(undefined);
  const [commodityModalType, setCommodityModalType] = useState(undefined);
  const [commodityDeleteModal, setCommodityDeleteModal] = useState(undefined);

  const { loading, data, error, fetchData } = useApi('get', 'pbp/port-commodities', {});

  const holidays = error || !data ? [] : data;

  const columns = [
    {
      title: t('Id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('HS code'),
      dataIndex: 'hs_code',
      key: 'hs_code',
      render: record => <div style={{ whiteSpace: 'nowrap' }}>{record}</div>,
    },
    {
      title: t('Load'),
      dataIndex: 'load',
      key: 'load',
      render: record => {
        if (record) {
          return t('Yes');
        } else {
          return t('No');
        }
      },
    },
    {
      title: t('Loading efficiency (/h)'),
      dataIndex: 'loading_efficiency_average_per_hour',
      key: 'loading_efficiency_average_per_hour',
      render: (record, item) => {
        if (record) {
          return record + item.efficiency_unit;
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Unload'),
      dataIndex: 'unload',
      key: 'unload',
      render: record => {
        if (record) {
          return t('Yes');
        } else {
          return t('No');
        }
      },
    },
    {
      title: t('Unloading efficiency (/h)'),
      dataIndex: 'unloading_efficiency_average_per_hour',
      key: 'unloading_efficiency_average_per_hour',
      render: (record, item) => {
        if (record) {
          return record + item.efficiency_unit;
        } else {
          return '-';
        }
      },
    },
    {
      title: t('Rain sensitive'),
      dataIndex: 'rain_sensitive',
      key: 'rain_sensitive',
      render: record => {
        if (record) {
          return t('Yes');
        } else {
          return t('No');
        }
      },
    },
  ];

  const actions = [
    {
      render: record => (
        <ListActionButton
          key="action-1"
          onClick={() => {
            setCommodityModalData(record);
            setCommodityModalType('edit');
          }}
        >
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          onClick={() => {
            setCommodityDeleteModal(record);
          }}
          red
        >
          <Icon type="trash" />
          {t('Remove')}
        </ListActionButton>
      ),
    },
  ];

  const refetchData = async () => {
    await fetchData(false, {});
  };

  const addItemButton = {
    onClick: () => {
      setCommodityModalData(undefined);
      setCommodityModalType('add');
    },
    text: t('Add commodity'),
  };

  const closeCommodityModal = async () => {
    setCommodityModalData(undefined);
    setCommodityModalType(undefined);
  };

  const closeCommodityDeleteModal = async () => {
    setCommodityDeleteModal(undefined);
  };

  return (
    <div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={holidays}
        actions={actions}
        spinning={loading}
        addItemButton={addItemButton}
      />
      {!!commodityModalType && (
        <AddOrEditCommodityModal
          type={commodityModalType}
          data={commodityModalData}
          closeModal={closeCommodityModal}
          refetchData={refetchData}
        />
      )}
      {!!commodityDeleteModal && (
        <RemoveCommodityModal
          data={commodityDeleteModal}
          closeModal={closeCommodityDeleteModal}
          refetchData={refetchData}
        />
      )}
    </div>
  );
};

export default CommodityList;
