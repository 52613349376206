import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import { UserContext } from '../../../context/UserContext';

import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';
import ModalLinks from './ModalLinks';

import { color_planned, color_uncommitted, color_updated } from '../../commonCalendar/utility/constants';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const ModalInnerDelete = styled(ModalInner)`
  min-height: 320px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc((100% - 24px) / 3);
  margin-right: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
`;

const Required = styled.span`
  color: red;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 12px;
  margin-bottom: 12px;
`;

const RemoveButtonColumn = styled.div`
  margin-right: 12px;
`;

const TimeColumn = styled.div`
  margin-left: 12px;
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const FirstColumn = styled.div`
  margin-right: 10px;
`;

const SecondColumn = styled.div`
  max-width: 138px;
`;

const RowHeaderForTooltip = styled.div`
  font-weight: normal;
`;

const TooltipValue = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RedIcon = styled.span`
  color: red;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-item: center;
  svg {
    width: 26px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  padding: 3px 0 0 10px;
`;

const Background = styled.span`
  padding: 2px 5px;
  border-radius: 3px;
  ${props => {
    if (props.state === 5) {
      return `
        background-color: ${color_planned};
      `;
    } else if (props.state === 7) {
      return `
        background-color: ${color_updated};
      `;
    } else if (props.state === 6) {
      return `
        background-color: ${color_uncommitted};
      `;
    }
  }}
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const ConfirmationText = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-right: 200px;
  height: 124px;
  margin-top: 80px;
`;

const EtdConfirmationText = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  width: 60%;
  margin: auto;
`;

const EtdIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin: auto;
  height: 64px;
  margin-top: 60px;

  svg {
    height: 40px;
    width: 40px;
  }
`;
const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

const FirstColumnSplit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const InfoRowSplit = styled.div`
  margin: 0 0 4px;
  width: auto;
  padding-right: 25px;
`;

const InfoContainer = styled.div`
  width: auto;
  vertical-align: top;
  margin-top: 6px;
  display: flex;
`;

const Info = styled.div`
  white-space: nowrap;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddOrEditJITSlotRequestInner = ({
  type,
  closeModal,
  vessel = undefined,
  readOnly,
  newTime,
  sendDeleteSlotResquest,
  sendEditSlotRequest,
  sendEditEtdSlotRequest,
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [linksDisabled, setLinksDisabled] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [etdConfirmation, setEtdConfirmation] = useState(false);

  const [inputData, setInputData] = useState(
    vessel.loading_master_etd_active
      ? {
          loading_master_etd: vessel.loading_master_etd_prefill,
        }
      : newTime
        ? {
            rta_window_start: newTime,
            laytime: vessel.laytime ? dayjs.duration(vessel.laytime).format('HH:mm') : null,
          }
        : {
            rta_window_start: vessel.slot_reservation_status === 'accepted' ? vessel.pta : vessel.rta,
            laytime: vessel.laytime ? dayjs.duration(vessel.laytime).format('HH:mm') : null,
          }
  );

  const title = readOnly
    ? t('Slot request')
    : type === 'new'
      ? t('Add slot request')
      : t('Edit or remove the slot request');

  const checkLaytime = laytime => {
    let splitted = laytime.split(':');

    if (splitted.length !== 2) {
      return false;
    }

    if (isNaN(splitted[0]) || isNaN(splitted[1])) {
      return false;
    }

    if (splitted[0].toString().length > 2 || splitted[1].toString().length > 2) {
      return false;
    }

    return !(splitted[0] > 99 || splitted[0] < 0 || splitted[1] > 59 || splitted[1] < 0);
  };

  const dataIsValid = () => {
    return vessel.loading_master_etd_active
      ? inputData.loading_master_etd
      : inputData.rta_window_start && inputData.laytime && checkLaytime(inputData.laytime);
  };

  const handleRTAWindowChange = value => {
    if (!linksDisabled) {
      setLinksDisabled(true);
    }
    setInputData({ ...inputData, rta_window_start: value });
  };

  const handleETDWindowChange = value => {
    let modifiedValue = null;

    if (!linksDisabled) {
      setLinksDisabled(true);
    }

    const now = dayjs();
    if (value?.isBefore(now)) {
      modifiedValue = now.add(5 - (now.minute() % 5), 'minutes');
    }

    setInputData({ ...inputData, loading_master_etd: modifiedValue || value });
  };

  const handleETDWindowSelect = value => {
    let modifiedValue = null;

    if (!linksDisabled) {
      setLinksDisabled(true);
    }

    const now = dayjs();
    if (value?.isBefore(now)) {
      modifiedValue = now.add(5 - (now.minute() % 5), 'minutes');
    }

    setInputData({ ...inputData, loading_master_etd: modifiedValue || value });
  };

  const onLaytimeChange = e => {
    if (!linksDisabled) {
      setLinksDisabled(true);
    }
    setInputData({ ...inputData, laytime: e.target.value });
  };

  const deleteSlotRequestClick = () => {
    setDeleteConfirmation(true);
  };

  const closeConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const closeEtdConfirmation = () => {
    setEtdConfirmation(false);
  };

  const removeSlotRequest = () => {
    sendDeleteSlotResquest(vessel.slot_reservation_id);

    closeModal(true);
  };

  const sendEtdData = () => {
    sendEditEtdSlotRequest({ ...inputData, id: vessel.slot_reservation_id });
    closeModal(true);
  };

  const sendData = () => {
    if (vessel.loading_master_etd_active) {
      if (dayjs(vessel.loading_master_etd_warning_max).isBefore(dayjs(inputData.loading_master_etd))) {
        setEtdConfirmation(true);
      } else {
        sendEtdData();
      }
    } else {
      sendEditSlotRequest({ ...inputData, id: vessel.slot_reservation_id });
      closeModal(true);
    }
  };

  const disabledDate = current => {
    return current && current < dayjs().startOf('day');
  };

  const disabledDateTime = choice => {
    if (!choice) {
      return null;
    }

    const now = dayjs();
    const allFree = choice.isAfter(now, 'day');
    if (allFree) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    } else if (now.isSame(choice, 'day')) {
      const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
      const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

      const disabledHours = hours.slice(0, now.hour());
      let disabledMinutes = [];

      if (now.isSame(choice, 'hour')) {
        disabledMinutes = minutes.filter(m => m <= now.minute());
      }

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
      };
    } else {
      return {
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        disabledMinutes: () => [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      };
    }
  };

  return (
    <Modal title={title} open={!!type} width={800} onCancel={() => closeModal(false)} footer={null}>
      <div id="edit-jit-content">
        {etdConfirmation ? (
          <ModalInnerDelete>
            <EtdIconContainer>
              <Icon type="vessel-warning"></Icon>
            </EtdIconContainer>
            <EtdConfirmationText>
              {t('Given loading master ETC is more than configured tolerance.')}
            </EtdConfirmationText>
            <EtdConfirmationText>{t('This will cause queue to move forwards.')}</EtdConfirmationText>
            <EtdConfirmationText>{t('Are you sure you want to set the given loading master ETC?')}</EtdConfirmationText>
            <MiddleLine style={{ marginTop: '42px' }} />
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight type="button" cancel onClick={closeEtdConfirmation}>
                {t('Back')}
              </ButtonLight>
              <ButtonLight onClick={sendEtdData}>{t('Confirm')}</ButtonLight>
            </ActionButtons>
          </ModalInnerDelete>
        ) : deleteConfirmation ? (
          <ModalInnerDelete>
            <IconContainer>
              <Icon type="remove-illustration"></Icon>
            </IconContainer>
            <ConfirmationText>{t('Are you sure you want to remove the slot request?')}</ConfirmationText>
            <MiddleLine />
            <ActionButtons>
              <ButtonLight type="button" cancel onClick={closeModal}>
                {t('Cancel')}
              </ButtonLight>
              <ButtonLight type="button" cancel onClick={closeConfirmation}>
                {t('Back')}
              </ButtonLight>
              <ButtonLight red onClick={removeSlotRequest}>
                {t('Remove')}
              </ButtonLight>
            </ActionButtons>
          </ModalInnerDelete>
        ) : (
          <ModalInnerInput>
            {type !== 'new' && !readOnly && (
              <RemoveContainer>
                <RemoveButtonColumn>
                  <ButtonContainer onClick={deleteSlotRequestClick}>
                    <RedIcon>
                      <Icon type="trash"></Icon>
                    </RedIcon>
                    <BoldText>{t('Remove slot request')}</BoldText>
                  </ButtonContainer>
                </RemoveButtonColumn>
                <TimeColumn>
                  <FirstColumn>
                    <RowHeaderForTooltip>{t('RTA')}:</RowHeaderForTooltip>
                    {(vessel.state === 5 || vessel.state === 7) && (
                      <RowHeaderForTooltip>{t('RTA conf')}:</RowHeaderForTooltip>
                    )}
                    <RowHeaderForTooltip>{t('Laytime')}:</RowHeaderForTooltip>
                  </FirstColumn>
                  <SecondColumn>
                    <TooltipValue>{dayjs(vessel.rta_window_start).format('MMM DD, YYYY HH:mm')}</TooltipValue>
                    {(vessel.state === 5 || vessel.state === 7) && (
                      <TooltipValue>{vessel.pta ? dayjs(vessel.pta).format('MMM DD, YYYY HH:mm') : '-'}</TooltipValue>
                    )}
                    <TooltipValue>{dayjs.duration(vessel.laytime).format('HH:mm')}</TooltipValue>
                  </SecondColumn>
                </TimeColumn>
              </RemoveContainer>
            )}
            <ScrollContainer>
              <FirstColumnSplit>
                <Info>
                  <InfoContainer>
                    <InfoRowSplit>
                      {t('Vessel:')}
                      <BoldText>{vessel.name}</BoldText>
                    </InfoRowSplit>
                    <InfoRowSplit>
                      {t('Imo:')}
                      <BoldText>{vessel.imo}</BoldText>
                    </InfoRowSplit>
                    <InfoRowSplit>
                      {t('Draft:')}
                      <BoldText>{vessel.draft}</BoldText>
                    </InfoRowSplit>
                    <InfoRowSplit>
                      {t('Beam:')}
                      <BoldText>{vessel.beam}</BoldText>
                    </InfoRowSplit>
                    <InfoRowSplit>
                      {t('Loa:')}
                      <BoldText>{vessel.loa}</BoldText>
                    </InfoRowSplit>
                  </InfoContainer>
                  <InfoContainer>
                    <InfoRowSplit>
                      {t('Berth:')}
                      <BoldText>{vessel.berth_short_name}</BoldText>
                    </InfoRowSplit>
                    <InfoRowSplit>
                      {t('State:')}
                      <BoldText style={{ textTransform: 'capitalize' }}>
                        <Background state={vessel.state}>{vessel.slot_reservation_status}</Background>
                      </BoldText>
                    </InfoRowSplit>
                  </InfoContainer>
                </Info>
              </FirstColumnSplit>
              <Row>
                {vessel.loading_master_etd_active ? (
                  <RowContainer>
                    <RowHeader>
                      {t('Loading Master ETC')}
                      <Required>*</Required>
                    </RowHeader>
                    {readOnly ? (
                      <Input
                        placeholder={t('Loading Master ETC')}
                        value={
                          inputData.loading_master_etd
                            ? dayjs(inputData.loading_master_etd).format('DD.MM.YYYY HH:mm')
                            : ''
                        }
                        disabled={true}
                        style={{ color: '#4a4a4a' }}
                      />
                    ) : (
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 5 }}
                        value={inputData.loading_master_etd ? dayjs(inputData.loading_master_etd) : null}
                        style={{
                          width: '100%',
                        }}
                        onChange={handleETDWindowChange}
                        onSelect={handleETDWindowSelect}
                        placeholder={t('Loading Master ETC')}
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                      />
                    )}
                  </RowContainer>
                ) : (
                  <RowContainer>
                    <RowHeader>
                      {t('RTA')}
                      <Required>*</Required>
                    </RowHeader>
                    {readOnly ? (
                      <Input
                        placeholder={t('RTA')}
                        value={
                          inputData.rta_window_start ? dayjs(inputData.rta_window_start).format('DD.MM.YYYY HH:mm') : ''
                        }
                        disabled={true}
                        style={{ color: '#4a4a4a' }}
                      />
                    ) : (
                      <DatePicker
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm', minuteStep: 5 }}
                        defaultValue={inputData.rta_window_start ? dayjs(inputData.rta_window_start) : null}
                        style={{
                          width: '100%',
                        }}
                        onChange={handleRTAWindowChange}
                        placeholder={t('RTA')}
                      />
                    )}
                  </RowContainer>
                )}
                {!vessel.loading_master_etd_active && (
                  <RowContainer style={{ marginRight: '0px' }}>
                    <RowHeader>
                      {t('Laytime (hh:mm)')}
                      <Required>*</Required>
                    </RowHeader>
                    {readOnly ? (
                      <Input
                        placeholder={t('Laytime')}
                        value={inputData.laytime}
                        disabled={true}
                        style={{ color: '#4a4a4a' }}
                      />
                    ) : (
                      <Input
                        defaultValue={inputData.laytime}
                        style={{
                          width: '100%',
                        }}
                        onChange={onLaytimeChange}
                        placeholder={t('Laytime')}
                      />
                    )}
                  </RowContainer>
                )}
              </Row>
            </ScrollContainer>
            <MiddleLine />
            <BottomRow>
              <ModalLinks vessel={vessel} linksDisabled={linksDisabled} />
              {readOnly ? (
                <ActionButtons>
                  <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
                    {t('Close')}
                  </ButtonLight>
                </ActionButtons>
              ) : (
                <ActionButtons>
                  <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
                    {t('Cancel')}
                  </ButtonLight>
                  <ButtonLight disabled={!dataIsValid()} onClick={sendData}>
                    {type === 'new' ? t('Reserve') : t('Save')}
                  </ButtonLight>
                </ActionButtons>
              )}
            </BottomRow>
          </ModalInnerInput>
        )}
      </div>
    </Modal>
  );
};

export default AddOrEditJITSlotRequestInner;
