import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const updateBerths = (
  berthsData,
  vesselsData,
  rowHeight,
  collapsedRowHeight,
  openBerths,
  berthAreasAvailable,
  filteringStorageData,
  gavleFiltering,
  serverData = false
) => {
  const dynamicBollards = vesselsData.dynamic_bollards ? vesselsData.dynamic_bollards : {};
  const dynamicBollardsAvailable = !!vesselsData.dynamic_bollards;

  let startHeight = 0;
  let berthList = [];

  if (serverData) {
    startHeight =
      rowHeight +
      (vesselsData.not_planned.unknown.not_planned_rows || 1) *
        2 *
        (openBerths['unknown'] ? rowHeight : collapsedRowHeight);

    berthList.push({
      subRows: [],
      id: 'unknown',
      length: 0,
      name: 'No berth available',
      notes: '',
      startHeight: 0,
      endHeight: startHeight,
      notPlannedCount: vesselsData.not_planned.unknown?.not_planned_rows || 1,
      show: true,
    });
  }

  if (serverData && !berthAreasAvailable) {
    for (let g in berthsData) {
      for (let r in berthsData[g].berths) {
        const id = r;

        let subRowList = [];
        if (dynamicBollards[r]) {
          for (let bCt = 1; bCt <= dynamicBollards[r]; bCt++) {
            subRowList.push({
              berth_code: r,
              id: 'B' + bCt,
              code: 'B' + bCt,
              display_order: bCt,
              geo_asset_uuid: null,
              short_name: '',
              name: '' + bCt,
              length: 1000,
              notes: 'Dynamic bollard',
              lat: berthsData[g].berths[r].start_lat,
              lon: berthsData[g].berths[r].start_lon,
              rowCount: 1,
            });
          }
        } else {
          for (let b in berthsData[g].berths[r].bollards) {
            const bId = b;
            subRowList.push({ ...berthsData[g].berths[r].bollards[b], id: bId, rowCount: 1 });
          }
        }

        const start = startHeight;
        startHeight =
          startHeight +
          rowHeight +
          subRowList.length *
            (openBerths[id]
              ? (dynamicBollardsAvailable ? 2 : 1) * rowHeight
              : (dynamicBollardsAvailable ? 2 : 1) * collapsedRowHeight) +
          (vesselsData.not_planned[id]?.not_planned_rows || 0) * 2 * (openBerths[id] ? rowHeight : collapsedRowHeight);

        berthList.push({
          ...berthsData[g].berths[r],
          id: id,
          subRows: subRowList,
          notPlannedCount: vesselsData.not_planned[id]?.not_planned_rows || 0,
          startHeight: start,
          endHeight: startHeight,
          show: true,
        });
      }
    }
  } else {
    for (let g in berthsData) {
      const id = serverData ? g : berthsData[g].id;
      let subRowIndex = 0;

      if (id === 'unknown') {
        startHeight =
          startHeight +
          rowHeight +
          (vesselsData.not_planned.unknown.not_planned_rows || 1) *
            2 *
            (openBerths['unknown'] ? rowHeight : collapsedRowHeight);

        berthList.push({
          ...berthsData[g],
          id: id,
          subRows: [],
          notPlannedCount: vesselsData.not_planned.unknown.not_planned_rows || 1,
          startHeight: 0,
          endHeight: startHeight,
        });
      } else {
        let subRowList = [];
        let subRowCount = 0;

        if (berthsData[g].subRows) {
          let subRows = [];

          if (dynamicBollards[berthsData[g].code]) {
            for (let bCt = 1; bCt <= dynamicBollards[berthsData[g].code]; bCt++) {
              subRows.push({
                berth_code: berthsData[g].code,
                id: 'B' + bCt,
                code: 'B' + bCt,
                display_order: bCt,
                geo_asset_uuid: null,
                short_name: '',
                name: '' + bCt,
                length: 1000,
                notes: 'Dynamic bollard',
                lat: berthsData[g].start_lat,
                lon: berthsData[g].start_lon,
                rowCount: 1,
              });
            }

            berthsData[g].subRows = subRows;
          }

          for (let b in berthsData[g].subRows) {
            const bId = serverData ? b : berthsData[g].subRows[b].id;
            const rowCount = 1 + (vesselsData.not_planned[bId]?.not_planned_rows || 0);
            const rowIndex = subRowIndex;
            subRowCount += rowCount;
            subRowIndex += rowCount;
            subRowList.push({ ...berthsData[g].subRows[b], id: bId, rowCount, rowIndex });
          }
        } else if (berthsData[g].berths) {
          for (let b in berthsData[g].berths) {
            const bId = serverData ? b : berthsData[g].berths[b].id;
            const rowCount = 1 + (vesselsData.not_planned[bId]?.not_planned_rows || 0);
            const rowIndex = subRowIndex;
            subRowCount += rowCount;
            subRowIndex += rowCount;
            subRowList.push({ ...berthsData[g].berths[b], id: bId, rowCount, rowIndex });
          }
        } else {
          for (let b in berthsData[g].bollards) {
            const bId = serverData ? b : berthsData[g].bollards[b].id;
            const rowCount = 1 + (vesselsData.not_planned[bId]?.not_planned_rows || 0);
            const rowIndex = subRowIndex;
            subRowCount += rowCount;
            subRowIndex += rowCount;
            subRowList.push({ ...berthsData[g].bollards[b], id: bId, rowCount, rowIndex });
          }
        }

        const start = startHeight;
        let show = true;
        if (
          !gavleFiltering ||
          (!filteringStorageData
            ? true
            : filteringStorageData.port_area_code.length === 0 ||
              filteringStorageData.port_area_code.findIndex(code => code === id) > -1)
        ) {
          startHeight =
            startHeight +
            rowHeight +
            subRowCount *
              (openBerths[id]
                ? (berthAreasAvailable || dynamicBollardsAvailable ? 2 : 1) * rowHeight
                : (berthAreasAvailable || dynamicBollardsAvailable ? 2 : 1) * collapsedRowHeight) +
            (vesselsData.not_planned[id]?.not_planned_rows || 0) *
              2 *
              (openBerths[id]
                ? (berthAreasAvailable ? 2 : 1) * rowHeight
                : (berthAreasAvailable ? 2 : 1) * collapsedRowHeight);
        }

        if (
          gavleFiltering &&
          (!filteringStorageData || filteringStorageData.port_area_code.length === 0
            ? false
            : filteringStorageData.port_area_code.findIndex(code => code === id) === -1)
        ) {
          show = false;
        }

        berthList.push({
          ...berthsData[g],
          id: id,
          subRows: subRowList,
          notPlannedCount: vesselsData.not_planned[id]?.not_planned_rows || 0,
          startHeight: start,
          endHeight: startHeight,
          show,
        });
      }
    }
  }

  return berthList;
};

export const getBerthAreasAvailableFromData = data => {
  if (data.configuration.show_berth_areas) {
    return true;
  }

  return false;
};

export const createBerthsOpenObject = (data, bAreasAvailable) => {
  let openBerths = { unknown: false };

  if (bAreasAvailable) {
    for (let g in data) {
      openBerths[g] = false;
    }
  } else {
    for (let g in data) {
      for (let b in data[g].berths) {
        openBerths[b] = false;
      }
    }
  }

  return openBerths;
};

const createVesselDataObject = (data, state) => {
  return {
    ...data,
    tagId: data.port_call_master_id + '_' + dayjs(),
    state: state,
    eta: dayjs(data.eta)
      .local()
      .format(),
    etd: dayjs(data.etd)
      .local()
      .format(),
    pta: data.pta
      ? dayjs(data.pta)
        .local()
        .format()
      : null,
    ptd: data.ptd
      ? dayjs(data.ptd)
        .local()
        .format()
      : null,
  };
};

export const setVesselsData = (vesselData, draggingArea, calendarVessels, ignoreDraggingArea = true) => {
  let vesselList = [];

  if (draggingArea && !ignoreDraggingArea) {
    const dragItem = calendarVessels.find(v => v.port_call_master_id === draggingArea.port_call_master_id);
    vesselList.push(dragItem);
  }

  for (let f in vesselData) {
    if (f === 'not_planned') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 1));
          }
        }
      }
    } else if (f === 'uncommitted') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 2));
          }
        }
      }
    } else if (f === 'planned') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 3));
          }
        }
      }
    } else if (f === 'reserved') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 4));
          }
        }
      }
    } else if (f === 'accepted') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 5));
          }
        }
      }
    } else if (f === 'offered') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 6));
          }
        }
      }
    } else if (f === 'updated') {
      for (let g in vesselData[f]) {
        for (let v in vesselData[f][g]) {
          if (
            v !== 'not_planned_rows' &&
            (ignoreDraggingArea ||
              !(draggingArea && vesselData[f][g][v].port_call_master_id === draggingArea.port_call_master_id))
          ) {
            vesselList.push(createVesselDataObject(vesselData[f][g][v], 7));
          }
        }
      }
    }
  }

  return vesselList;
};

export const setUpcomingVesselsData = vesselList => {
  return vesselList.map(v => {
    return { ...v, tagId: v.port_call_master_id + '_' + dayjs() };
  });
};

export const getPbpWorksFromData = (data, includeId = true) => {
  return data?.map(d => {
    return {
      ...(includeId ? { id: d.id || null } : {}),
      sequence_number: 1,
      operation: d.operation,
      commodity_id: d.commodity_id,
      commodity_amount: d.commodity_amount,
      manual_end_time: !!d.manual_end_time,
      crane_id: d.crane_id,
      company_id: d.company_id,
      start_time: d.start_time
        ? dayjs(d.start_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null,
      end_time: d.end_time
        ? dayjs(d.end_time)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null,
      operation_type: d.operation_type,
    };
  }) || [];
};
