import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';

import ButtonLight from '../ui/ButtonLight';
import { TimestampContext } from '../../context/TimestampContext';
import Icon from '../ui/Icon';
import DebounceAutocomplete from '../ui/DebounceAutocomplete';

const Container = styled.div`
  width: 548px;
`;

const Item = styled.td`
  padding: 0 24px 12px 24px;

  input {
    border-color: ${props => props.bold && '#74ace8'};
  }

  .ant-select-selection-search-input {
    border-color: ${props => props.bold && '#74ace8'};
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${props => props.bold && '#74ace8'};
  }
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  font-weight: ${props => props.bold && 600};
  color: ${props => props.bold && props.theme.color.secondary};
`;

const Buttons = styled.div`
  width: calc(100% - 24px);
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  padding-top: 12px;
  padding-right: 24px;
  margin-right: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.grey_light};
`;

const InputContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin-left: 4px;
  margin-top: 4px;
  height: 24px;
  width: 24px;
  border-radius: 3px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => props.disabled && '#c8c8c8'};

  &:hover {
    background-color: ${props => !props.disabled && '#f0f0f0'};
  }

  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
    margin-left: 4px;
  }
`;

const InputWithError = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  font-size: 10px;
  right: 0px;
  margin-top: -2px;
`;

const VesselDataEdit = ({ portcallId, data, closeEdit, reload }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { iso31661alpha2 } = useContext(TimestampContext);

  const [inputData, setInputData] = useState({});
  const [sending, setSending] = useState(false);

  const nationalities = [];
  Object.keys(iso31661alpha2).forEach(n => {
    nationalities.push({
      label: iso31661alpha2[n] + ' (' + n + ')',
      value: n,
    });
  });

  const dataIsValid = () => {
    if (Object.keys(inputData).length === 0) {
      return false;
    }

    if (Object.keys(inputData).findIndex(k => k === 'from_port') > -1 && inputData.from_port.length !== 5) {
      return false;
    }

    if (Object.keys(inputData).findIndex(k => k === 'next_port') > -1 && inputData.next_port.length !== 5) {
      return false;
    }

    let someEdited = false;

    Object.keys(inputData).forEach(inputKey => {
      if (inputData[inputKey] !== data[inputKey]) {
        if (!someEdited) {
          someEdited = true;
        }
      }
    });

    return someEdited;
  };

  const sendData = async () => {
    setSending(true);
    let result;

    let inputCopy = { ...inputData };

    let payloadData = {
      port_call_id: portcallId,
      time_type: 'Actual',
      state: 'PortActivity_Data',
      payload: {
        agent: 'MI6',
      },
    };

    if (inputData.vessel_name && inputData.vessel_name !== data.vessel_name) {
      payloadData.vessel_name = inputData.vessel_name;
      delete inputCopy.vessel_name;
    }

    Object.keys(inputCopy).forEach(inputKey => {
      if (inputData[inputKey] !== data[inputKey]) {
        payloadData.payload[inputKey] = inputCopy[inputKey];
      }
    });

    try {
      result = await apiCall('post', 'admin-timestamp', payloadData);
    } catch (e) {
      setSending(false);
    }

    if (result?.status === 200) {
      reload();
      closeEdit();
    } else {
      setSending(false);
    }
  };

  const fetchAgentList = async value => {
    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    const result = await apiCall('get', 'agents', params);

    if (result?.status === 200) {
      return result.data.data.map(d => {
        return { value: d.name, label: d.name, data: d };
      });
    }

    return [];
  };

  const updateInput = (value, key) => {
    const newInputData = { ...inputData };
    newInputData[key] = value;
    setInputData(newInputData);
  };

  const removeInput = key => {
    const newInputData = { ...inputData };
    delete newInputData[key];
    setInputData(newInputData);
  };

  const createItem = (fieldName, key) => {
    const updated =
      Object.keys(inputData).findIndex(k => k === key) > -1 && inputData[key]?.toString() !== data[key]?.toString();

    let showPortError = false;

    if (
      (key === 'from_port' || key === 'next_port') &&
      Object.keys(inputData).findIndex(k => k === key) > -1 &&
      !(inputData[key]?.length === 5 || inputData[key]?.length === 0)
    ) {
      showPortError = true;
    }

    return (
      <Item bold={updated}>
        <RowHeader bold={updated}>{t(fieldName)}</RowHeader>
        <InputContainer>
          {key === 'nationality' ? (
            <Select
              showSearch
              placeholder={t(fieldName)}
              optionFilterProp="value"
              onChange={value => updateInput(value, key)}
              onSelect={value => updateInput(value, key)}
              style={{ width: '200px' }}
              options={nationalities}
              value={
                Object.keys(inputData).findIndex(k => k === key) > -1
                  ? inputData.nationality || null
                  : data.nationality || null
              }
              allowClear={true}
              onClear={() => updateInput(null, key)}
            />
          ) : key === 'agent' ? (
            <DebounceAutocomplete
              placeholder={t('Agent name')}
              fetchOptions={fetchAgentList}
              onChange={value => updateInput(value, key)}
              onSelect={value => updateInput(value, key)}
              value={
                Object.keys(inputData).findIndex(k => k === key) > -1 ? inputData.agent || null : data.agent || null
              }
              style={{ width: '200px' }}
              allowClear={true}
              onClear={() => updateInput(null, key)}
            />
          ) : (
            <InputWithError>
              <Input
                placeholder={fieldName}
                value={Object.keys(inputData).findIndex(k => k === key) > -1 ? inputData[key] : data[key]}
                onChange={e => updateInput(e.target.value, key)}
                style={{ width: '200px' }}
              />
              {showPortError && <Error>{t('Locode has 5 characters')}</Error>}
            </InputWithError>
          )}
          <Tooltip
            placement="top"
            title={updated ? t('Revert changes') : null}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a' }}
          >
            <IconContainer
              onClick={() => {
                if (updated) {
                  removeInput(key);
                }
              }}
              disabled={!updated}
            >
              <Icon type="undo"></Icon>
            </IconContainer>
          </Tooltip>
        </InputContainer>
      </Item>
    );
  };

  return (
    <Container>
      <table>
        <tbody>
          <tr>
            {createItem('Vessel name', 'vessel_name')}
            {createItem('Net tonnage', 'net_tonnage')}
          </tr>
          <tr>
            {createItem('MMSI', 'mmsi')}
            {createItem('Dead weight', 'dead_weight')}
          </tr>
          <tr>
            {createItem('Nationality', 'nationality')}
            {createItem('Loa', 'vessel_loa')}
          </tr>
          <tr>
            {createItem('Agent', 'agent')}
            {createItem('Beam', 'vessel_beam')}
          </tr>
          <tr>
            {createItem('From port (locode)', 'from_port')}
            {createItem('Draft', 'vessel_draft')}
          </tr>
          <tr>{createItem('Next port (locode)', 'next_port')}</tr>
        </tbody>
      </table>
      <Buttons>
        <ButtonLight cancel onClick={() => closeEdit()} style={{ marginRight: '12px' }}>
          {t('Cancel')}
        </ButtonLight>
        <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
          {t('Save')}
        </ButtonLight>
      </Buttons>
    </Container>
  );
};

export default VesselDataEdit;
