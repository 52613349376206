export const defaultHeaderFormats = {
  week: {
    long: 'ddd DD',
    mediumLong: 'ddd DD',
    medium: 'ddd DD',
    short: 'ddd DD',
  },
  day: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
  month: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
};
