import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';

import { UserContext } from './UserContext';
import {
  buildQuery,
  filterUnique,
  getFleetFilterStorageData,
  iso31661alpha2,
  setFleetFilterStorageData,
} from '../components/filter/fleetFilterHelpers';

export const FleetFilteringContext = createContext();

const defaultFormData = {
  etaStart: null,
  etaEnd: null,
  etdStart: null,
  etdEnd: null,
  portarea: [],
  locode: [],
  text: '',
  agent: [],
  vesselType: [],
  arriving: false,
  departed: false,
  etaSlider: [],
  etdSlider: [],
  imo: null,
  vessel: [],
  portAreaSelect: [],
  nationality: [],
  shippingCompany: [],
  vtsArea: [],
};

const getFleetQueryFromLocalStorage = namespace => {
  const storedFilter = getFleetFilterStorageData(namespace);
  if (storedFilter?.formData) {
    const result = buildQuery(storedFilter.formData);
    return { query: result?.query };
  } else {
    return {};
  }
};

const getFleetFormDataFromLocalStorage = namespace => {
  const storedFilter = getFleetFilterStorageData(namespace);
  if (storedFilter?.formData) {
    return storedFilter.formData;
  } else {
    return defaultFormData;
  }
};

export const FleetFilteringProvider = ({ children }) => {
  const { apiCall, namespace, user, modules } = useContext(UserContext);

  const [fleetFilter, setFleetFilterInternal] = useState(() => getFleetQueryFromLocalStorage(namespace));
  const [fleetFilters, setFleetFilters] = useState([]);
  const [fleetQuickSearchText, setFleetQuickSearchTextInternal] = useState('');

  const [filteringOptionsDataRetrieved, setFilteringOptionsDataRetrieved] = useState(false);
  const [portAreaData, setPortAreaData] = useState([]);
  const [agentData, setAgentData] = useState([]);
  const [vesselTypeData, setVesselTypeData] = useState([]);
  const [vesselData, setVesselData] = useState([]);
  const [shippingCompanyData, setShippingCompanyData] = useState([]);
  const [nationalityData, setNationalityData] = useState([]);
  const [vtsAreaData, setVtsAreaData] = useState([]);
  const [formData, setFormData] = useState(() => getFleetFormDataFromLocalStorage(namespace));
  const [activeFilter, setActiveFilter] = useState(undefined);
  const [isFiltering, setIsFiltering] = useState(false);

  const [filteringDataAvailable, setFilteringDataAvailable] = useState(false);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const storedFilter = getFleetFilterStorageData(namespace);
    if (storedFilter) {
      setFormData(storedFilter.formData);
      setActiveFilter({ name: storedFilter.name, id: storedFilter.id });
    }
    setFilteringDataAvailable(true);
    // Run only when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filtersActive =
      formData.etaStart ||
      formData.etaEnd ||
      formData.etdStart ||
      formData.etdEnd ||
      formData.portarea?.length ||
      formData.locode?.length ||
      formData.agent?.length ||
      formData.vesselType?.length ||
      formData.arriving ||
      formData.departed ||
      formData.etaSlider?.length ||
      formData.etdSlider?.length ||
      formData.imo ||
      formData.vessel?.length ||
      formData.portAreaSelect?.length ||
      formData.nationality?.length ||
      formData.shippingCompany?.length ||
      formData.vtsArea?.length;
    setIsFiltering(filtersActive);
  }, [formData]);

  useEffect(() => {
    const getPortAreaData = async () => {
      const result = await apiCall('post', 'fleet/search/port', {
        order_by: [
          {
            field: 'port_name',
            order: 'asc',
          },
        ],
      });
      if (result?.status === 200 && result?.data) {
        const portAreaData = result.data;
        const portAreas = portAreaData?.results?.ports
          ? portAreaData.results.ports.map(l => {
            const filteredPortAreas = l.data?.locodes[0].portareas
              ? l.data?.locodes[0].portareas.filter(
                pa => pa.portAreaName && pa.portAreaName.substring(0, 3) !== 'Ei '
              )
              : [];
            return {
              label: camelize(l.port_name.replace(/ *\([^)]*\) */g, '')),
              value: `LO_${l.data?.locodes[0]?.locode}`,
              children: filteredPortAreas.map(p => {
                return {
                  label: camelize(p.portAreaName.replace(/ *\([^)]*\) */g, '')),
                  value: `PA_${l.data?.locodes[0]?.locode}_${p.portAreaName}`,
                };
              }),
            };
          })
          : [];

        const vtsAreas = [
          ...new Set(
            portAreaData.results?.ports
              ?.flatMap(port => port.data?.locodes?.map(locode => locode.vts_area_name) || [])
              .filter(area => area)
              .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || []
          ),
        ];

        if (mounted.current) {
          const filteredData = portAreas.map(({ children, ...others }) => ({
            children: filterUnique(children || [], 'value'),
            ...others,
          }));
          setPortAreaData(filteredData);
          const areas = vtsAreas.map(area => ({
            value: area,
            label: area,
          }));
          setVtsAreaData(areas);
        }
      }
    };

    const getAgentData = async () => {
      const result = await apiCall('post', 'fleet/agents', {
        order_by: [
          {
            field: 'agents',
            order: 'asc',
          },
        ],
      });
      if (result?.status === 200 && result?.data?.results?.agents) {
        if (mounted.current) {
          setAgentData(result.data.results.agents);
        }
      }
    };

    const getVesselTypeData = async () => {
      const result = await apiCall('post', 'fleet/vessel-types', {
        order_by: [
          {
            field: 'name',
            order: 'asc',
          },
        ],
      });
      if (result?.status === 200 && result?.data?.results?.vessel_types) {
        if (mounted.current) {
          setVesselTypeData(result.data.results.vessel_types);
        }
      }
    };

    const getVesselData = async () => {
      const result = await apiCall('post', 'search/vessel', {
        order_by: [
          {
            field: 'name',
            order: 'asc',
          },
        ],
      });
      if (result?.status === 200 && result?.data?.results?.vessels) {
        if (mounted.current) {
          const vesselData = result.data?.results?.vessels.map(item => ({
            value: `${item.imo}`,
            mmsi: `${item.mmsi}`,
            label: `${item.name} ${item.imo}`,
          }));
          setVesselData(vesselData);
        }
      }
    };

    const getShippingCompanyData = async () => {
      const result = await apiCall('post', 'fleet/shipping-companies/', {
        order_by: [
          {
            field: 'shipping_companies',
            order: 'asc',
          },
        ],
      });
      if (result?.status === 200 && result?.data?.results?.shipping_companies) {
        if (mounted.current) {
          const shippingCompanyData = result.data?.results?.shipping_companies.map(item => ({
            value: item,
            label: item,
          }));
          setShippingCompanyData(shippingCompanyData);
        }
      }
    };

    const getCountryData = async () => {
      const result = await apiCall('post', 'fleet/countries');
      if (result?.status === 200 && result?.data?.results?.countries) {
        if (mounted.current) {
          filterCountries(result.data.results.countries);
        }
      }
    };

    const filterCountries = countries => {
      const filtered = Object.entries(iso31661alpha2)
        .filter(([code]) => {
          return countries.includes(code);
        })
        .map(([code, country]) => ({
          value: code,
          label: `${country} (${code})`,
        }));
      setNationalityData(filtered);
    };

    if (
      !filteringOptionsDataRetrieved &&
      modules.fleet_module === 'enabled' &&
      user.permissions.includes('view_fleet')
    ) {
      getPortAreaData();
      getAgentData();
      getVesselTypeData();
      getVesselData();
      getShippingCompanyData();
      getCountryData();
    }

    setFilteringOptionsDataRetrieved(true);
  }, [apiCall, modules.fleet_module, filteringOptionsDataRetrieved, user.permissions]);

  const getFleetFilters = async () => {
    const res = await apiCall('get', `fleet/filters?userId=${user.id}`);
    if (res.data) {
      setFleetFilters(res.data);
    }
  };

  const camelize = str => {
    return str.replace(/./g, function(word, index) {
      if (str[index - 1] && str[index - 1] === ' ') {
        return word.toUpperCase();
      }

      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    });
  };

  const setFleetFilter = useCallback(
    filter => {
      const fleetFilter = filter;
      if (fleetQuickSearchText) {
        if (!fleetFilter.query) {
          fleetFilter.query = {};
        }
        fleetFilter.query.text = fleetQuickSearchText;
      }
      setFleetFilterInternal(fleetFilter);
    },
    [fleetQuickSearchText]
  );

  const setFleetQuickSearchText = quickSearchText => {
    setFleetQuickSearchTextInternal(quickSearchText);
    setFleetFilterInternal(prevFleetFilter => {
      let fleetFilter = {
        ...prevFleetFilter,
      };
      if (quickSearchText) {
        if (!fleetFilter.query) {
          fleetFilter.query = {};
        }
        fleetFilter.query.text = quickSearchText;
      } else {
        delete fleetFilter.query?.text;
      }
      if (Object.keys(fleetFilter.query).length === 0) {
        delete fleetFilter.query;
      }
      return fleetFilter;
    });
  };

  const handleFormChange = item => {
    let newValue = null;

    if (item.key === 'arriving' || item.key === 'departed') {
      newValue = false;
    }

    if (item.key === 'locode') {
      newValue = formData.locode.filter(locode => locode.value !== item.value);
    }

    if (item.key === 'portarea') {
      newValue = formData.portarea.filter(portarea => portarea.value !== item.value);
    }

    if (item.key === 'nationality') {
      newValue = formData.nationality.filter(nationality => nationality.value !== item.value);
    }

    if (item.key === 'shippingCompany') {
      newValue = formData.shippingCompany.filter(shippingCompany => shippingCompany.value !== item.value);
    }

    if (item.key === 'portAreaSelect') {
      newValue = formData.portAreaSelect.filter(portarea => portarea.value !== item.value);
    }

    if (item.key === 'agent') {
      newValue = formData.agent.filter(agent => agent !== item.value);
    }

    if (item.key === 'vesselType') {
      newValue = formData.vesselType.filter(vesselType => vesselType !== item.value);
    }

    if (item.key === 'vessel') {
      newValue = formData.vessel.filter(vessel => vessel.value !== item.value);
    }

    if (item.key === 'etaSlider') {
      newValue = [];
    }

    if (item.key === 'etdSlider') {
      newValue = [];
    }

    if (item.key === 'vtsArea') {
      newValue = formData.vtsArea.filter(area => area.value !== item.value);
    }

    const newFormData = formData;
    newFormData[item.key] = newValue;

    const result = buildQuery(newFormData);
    if (result) {
      setFleetFilter(result);
      setFormData({ ...newFormData });
      setFleetFilterStorageData(namespace, { formData: newFormData });
    } else {
      setFleetFilter({});
      setFormData({ ...defaultFormData });
      setFleetFilterStorageData(namespace, null);
    }
    setActiveFilter(null);
  };

  const clearFormData = () => {
    setFormData({ ...defaultFormData });
  };

  return (
    <FleetFilteringContext.Provider
      value={{
        activeFilter,
        setActiveFilter,
        fleetFilter,
        fleetFilters,
        setFleetFilter,
        getFleetFilters,
        portAreaData,
        formData,
        setFormData,
        clearFormData,
        handleFormChange,
        agentData,
        vesselTypeData,
        vesselData,
        fleetQuickSearchText,
        setFleetQuickSearchText,
        shippingCompanyData,
        nationalityData,
        vtsAreaData,
        isFiltering,
        filteringDataAvailable,
      }}
    >
      {children}
    </FleetFilteringContext.Provider>
  );
};
