import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import { UserContext } from '../../../context/UserContext';

import NotPlannedVessels from './NotPlannedVessels';
import Vessel from './Vessel';
import WindWarningRow from './WindWarningRow';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const RowContainer = styled.div`
  position: relative;
`;

const CalendarParentRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  background-color: ${({ theme }) => theme.color.white};
  border-top: ${props => (!props.first ? '1px solid ' + props.theme.color.grey_light : 'none')};
`;

const CalendarChildRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  background-color: ${props => (props.isEvenRow ? props.theme.color.grey_lighter4 : props.theme.color.white)};
  position: relative;
`;

const NotPlannedRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  background-color: white;
`;

export const CalendarRow = ({ berth, vessels, open, draggingInRow, scrollLeft, scrollTop, draggingValue, first }) => {
  const {
    canvasWidth,
    canvasTimeEnd,
    canvasTimeStart,
    draggingArea,
    updateVesselLocation,
    keepVesselLocation,
    setDraggingArea,
    setDraggingOverlapping,
    draggingOverlapping,
    keepUpcomingVessel,
    updateUpcomingVessel,
    rowHeight,
    collapsedRowHeight,
    berthAreasAvailable,
    updateQueueVesselLocation,
    multiselectedVessels,
    additionalData,
    dynamicBollards,
  } = useContext(BerthPlanningToolContext);
  const { namespace } = useContext(UserContext);

  useEffect(() => {
    const getTimeFromPixels = pixels => {
      const pixelInMilliseconds =
        dayjs.duration(dayjs(canvasTimeEnd).diff(dayjs(canvasTimeStart))).asMilliseconds() / canvasWidth;

      return dayjs(canvasTimeStart + pixels * pixelInMilliseconds).format();
    };

    const getBollards = (top, bollardAmount) => {
      let bollardData = { firstBollard: null, secondBollard: null, keepOldPlace: false };

      if (!berth.subRows || berth.subRows.length === 0) {
        return bollardData;
      }

      berth.subRows.forEach((b, j) => {
        let bollardsPixelStart =
          berth.startHeight + rowHeight + j * (dynamicBollards ? 2 : 1) * (open ? rowHeight : collapsedRowHeight);
        let bollardsPixelEnd = bollardsPixelStart + (dynamicBollards ? 2 : 1) * (open ? rowHeight : collapsedRowHeight);

        if (j === 0) {
          bollardsPixelStart -= rowHeight;
        }

        if (top >= bollardsPixelStart && top < bollardsPixelEnd) {
          bollardData.firstBollard = b.id;

          if (berth.subRows[j + bollardAmount - 1]) {
            bollardData.secondBollard = berth.subRows[j + bollardAmount - 1].id;
          }

          if (bollardData.firstBollard && !bollardData.secondBollard) {
            bollardData.keepOldPlace = true;
          }
          return;
        }
      });

      if (!bollardData.firstBollard && !bollardData.secondBollard) {
        bollardData.keepOldPlace = true;
      }

      return bollardData;
    };

    if (draggingInRow && draggingArea) {
      if (!berthAreasAvailable) {
        const { firstBollard, secondBollard, keepOldPlace } = getBollards(
          draggingArea.top + (draggingArea.new ? scrollTop : 0),
          draggingArea.bollardAmount
        );

        let overlapping = false;

        // We decided to allow overlapping, that's why these are in comments.

        /* OVERLAPPING STARTS */
        // const timeStart = dayjs(
        //   getTimeFromPixels(
        //     draggingArea.left + (!draggingArea.new ? draggingValue : 0) + (draggingArea.new ? scrollLeft : 0)
        //   )
        // );
        // const timeEnd = dayjs(
        //   getTimeFromPixels(
        //     draggingArea.right + (!draggingArea.new ? draggingValue : 0) + (draggingArea.new ? scrollLeft : 0)
        //   )
        // );

        // const firstIndex = firstBollard ? berth.subRows.findIndex(b => b.id === firstBollard) : null;
        // const secondIndex = secondBollard ? berth.subRows.findIndex(b => b.id === secondBollard) : null;

        // if (firstIndex !== null) {
        //   for (const ves of vessels) {
        //     const vesselStartTime = dayjs(ves.pta || ves.eta);
        //     const vesselEndTime = dayjs(ves.ptd || ves.etd);
        //     const vesselFirstIndex = ves.start_bollard ? berth.subRows.findIndex(b => b.id === ves.start_bollard) : null;
        //     const vesselSecondIndex = ves.end_bollard ? berth.subRows.findIndex(b => b.id === ves.end_bollard) : null;
        //     if (
        //       ves.state !== 1 &&
        //       draggingArea.port_call_master_id !== ves.port_call_master_id &&
        //       ((vesselStartTime.isBefore(timeStart) && timeStart.isBefore(vesselEndTime)) ||
        //         (vesselStartTime.isBefore(timeEnd) && timeEnd.isBefore(vesselEndTime)) ||
        //         (timeStart.isBefore(vesselStartTime) && vesselEndTime.isBefore(timeEnd))) &&
        //       ((vesselFirstIndex <= firstIndex && firstIndex <= vesselSecondIndex) ||
        //         (vesselFirstIndex <= secondIndex && secondIndex <= vesselSecondIndex) ||
        //         (firstIndex <= vesselFirstIndex && vesselSecondIndex <= secondIndex))
        //     ) {
        //       overlapping = true;
        //       break;
        //     }
        //   }
        // }
        /* OVERLAPPING ENDS */

        if (keepOldPlace || berth.id === 'unknown') {
          overlapping = true;
        }

        if (draggingArea.update) {
          setDraggingArea({ ...draggingArea, update: false });

          if (draggingArea.new) {
            if (overlapping || keepOldPlace || draggingArea.tooBig) {
              keepUpcomingVessel({
                port_call_master_id: draggingArea.port_call_master_id,
              });
            } else {
              updateUpcomingVessel({
                port_call_master_id: draggingArea.port_call_master_id,
                berth_code: berth.id,
                start_bollard: firstBollard,
                end_bollard: secondBollard,
                pta: getTimeFromPixels(draggingArea.left + scrollLeft),
                ptd: getTimeFromPixels(draggingArea.right + scrollLeft),
                state: firstBollard ? 2 : 1,
                berth_transitions: draggingArea.berth_transitions,
                dragging: true,
              });
            }
          } else {
            if (overlapping || keepOldPlace || draggingArea.tooBig) {
              keepVesselLocation({
                port_call_master_id: draggingArea.port_call_master_id,
              });
            } else {
              updateVesselLocation({
                port_call_master_id: draggingArea.port_call_master_id,
                berth_code: berth.id,
                start_bollard: firstBollard,
                end_bollard: secondBollard,
                pta: getTimeFromPixels(draggingArea.left + draggingValue),
                ptd: getTimeFromPixels(draggingArea.right + draggingValue),
                state: firstBollard ? 2 : 1,
                berth_transitions: draggingArea.berth_transitions,
                dragging: true,
                pbp_data: draggingArea.pbp_data || null,
              });
            }
          }
          setDraggingOverlapping(false);
        } else {
          if (!draggingOverlapping && overlapping) setDraggingOverlapping(true);
          else if (draggingOverlapping && !overlapping) setDraggingOverlapping(false);
        }
      } else {
        let overlapping = false;
        let topValue = draggingArea.top + (draggingArea.new ? scrollTop : 0);
        let subRowId = undefined;

        if (berth.id !== 'unknown') {
          let startHeightForSubRows = berth.startHeight + rowHeight;
          berth.subRows.forEach((b, j) => {
            let subRowPixelStart = startHeightForSubRows;
            let subRowPixelEnd = subRowPixelStart + b.rowCount * (open ? 2 * rowHeight : 2 * collapsedRowHeight);
            startHeightForSubRows = subRowPixelEnd;
            if (j === 0) {
              subRowPixelStart -= rowHeight;
            }

            if (topValue >= subRowPixelStart && topValue < subRowPixelEnd) {
              subRowId = b.id;
            }
          });

          const timeStart = dayjs(
            getTimeFromPixels(
              draggingArea.left + (!draggingArea.new ? draggingValue : 0) + (draggingArea.new ? scrollLeft : 0)
            )
          );
          const timeEnd = dayjs(
            getTimeFromPixels(
              draggingArea.right + (!draggingArea.new ? draggingValue : 0) + (draggingArea.new ? scrollLeft : 0)
            )
          );

          if (subRowId !== undefined) {
            for (const ves of vessels) {
              if (ves.port_call_master_id !== draggingArea.port_call_master_id) {
                const vesselStartTime = dayjs(
                  ves.drawable?.collision?.start_time
                    ? ves.drawable.collision.start_time
                    : ves.total_start || ves.start_time
                );
                const vesselEndTime = dayjs(
                  ves.drawable?.collision?.end_time ? ves.drawable.collision.end_time : ves.total_end || ves.end_time
                );
                if (
                  (vesselStartTime.isBefore(timeStart) && timeStart.isBefore(vesselEndTime)) ||
                  (vesselStartTime.isBefore(timeEnd) && timeEnd.isBefore(vesselEndTime)) ||
                  (timeStart.isBefore(vesselStartTime) && vesselEndTime.isBefore(timeEnd))
                ) {
                  if (subRowId === ves.berth_code) {
                    if (draggingArea.state !== 1 && ves.state !== 1) {
                      overlapping = true;
                      break;
                    }
                  }
                }
              }
            }
          }
        } else {
          overlapping = true;
        }

        if (draggingArea.update) {
          setDraggingArea({ ...draggingArea, update: false });
          if (overlapping && multiselectedVessels.length === 0) {
            keepVesselLocation({
              port_call_master_id: draggingArea.port_call_master_id,
            });
          } else {
            updateQueueVesselLocation({
              port_call_master_id: draggingArea.port_call_master_id,
              berth_code: subRowId,
              pta: getTimeFromPixels(draggingArea.left + draggingValue),
              ptd: getTimeFromPixels(draggingArea.right + draggingValue),
              onlyBerthChange: draggingArea.state === 1,
            });
          }
          setDraggingOverlapping(false);
        } else {
          if (!draggingOverlapping && overlapping) setDraggingOverlapping(true);
          else if (draggingOverlapping && !overlapping) setDraggingOverlapping(false);
        }
      }
    }
  }, [
    canvasTimeEnd,
    canvasTimeStart,
    canvasWidth,
    draggingArea,
    draggingInRow,
    draggingOverlapping,
    berth.subRows,
    berth.id,
    berth.startHeight,
    keepUpcomingVessel,
    keepVesselLocation,
    open,
    scrollLeft,
    scrollTop,
    setDraggingArea,
    setDraggingOverlapping,
    updateUpcomingVessel,
    updateVesselLocation,
    vessels,
    draggingValue,
    berth,
    collapsedRowHeight,
    rowHeight,
    berthAreasAvailable,
    updateQueueVesselLocation,
    multiselectedVessels.length,
    dynamicBollards,
  ]);

  const getNotPlannedRows = count => {
    let rows = [];
    for (let i = 0; i < count; i++) {
      rows.push(
        <NotPlannedRow
          key={`row-not-planned-${berth.id}-${i}`}
          style={{
            width: `${canvasWidth}px`,
            height: open ? `${2 * rowHeight}px` : `${2 * collapsedRowHeight}px`,
          }}
        />
      );
    }

    return rows;
  };

  return (
    <RowContainer key={`row-container-${berth.id}`}>
      <CalendarParentRow
        style={{
          width: `${canvasWidth}px`,
          height: `${rowHeight}px`,
        }}
        first={first}
      />
      {berth.subRows?.map((b, i) => (
        <CalendarChildRow
          key={`row-child-${berth.id}-${b.id}`}
          isEvenRow={i % 2 === 0}
          style={{
            width: `${canvasWidth}px`,
            height: open
              ? `${berthAreasAvailable || dynamicBollards ? 2 * b.rowCount * rowHeight : rowHeight}px`
              : `${
                berthAreasAvailable || dynamicBollards ? 2 * b.rowCount * collapsedRowHeight : collapsedRowHeight
              }px`,
          }}
        >
          {namespace === 'gothenburg' && <WindWarningRow berthId={berth.id} bollardId={b.id} />}
        </CalendarChildRow>
      ))}
      {getNotPlannedRows(berth.notPlannedCount)}

      {vessels
        .filter(v => v.state !== 1)
        .map(v => (
          <Vessel
            key={v.tagId}
            vessel={v}
            open={open}
            berth={berth}
            draggingValue={draggingValue}
            additionalData={additionalData?.[v.port_call_id]}
          />
        ))}
      {vessels.filter(v => v.state === 1).length > 0 && (
        <NotPlannedVessels
          vessels={vessels.filter(v => v.state === 1)}
          open={open}
          berth={berth}
          draggingValue={draggingValue}
        />
      )}
    </RowContainer>
  );
};
