import React, { useContext } from 'react';
import styled from 'styled-components';
import { FleetServiceOrderContext } from '../../../context/FleetServiceOrderContext';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';

import Portcall from './Portcall';

const RowContainer = styled.div`
  position: relative;
`;

const CalendarChildRow = styled.div`
  box-sizing: border-box;
  z-index: 40;
  background-color: ${props => (props.isEvenRow ? props.theme.color.grey_lighter2 : props.theme.color.white)};
`;

export const CalendarRow = ({ vessel, index }) => {
  const { canvasWidth, rowHeight } = useContext(FleetVesselViewContext);
  const { serviceOrders = [] } = useContext(FleetServiceOrderContext);

  const createRows = () => {
    return (
      <CalendarChildRow
        key={`row-child-${index}`}
        style={{
          width: `${canvasWidth}px`,
          height: `${rowHeight}px`,
        }}
        color={vessel.color}
        isEvenRow={index % 2 === 0}
      />
    );
  };

  let portcalls = [...vessel.portcalls];

  return (
    <RowContainer key={`row-container-${index}`}>
      {createRows()}
      {portcalls.map((p, j) => (
        <Portcall
          key={j}
          portcall={p}
          nextPortcall={portcalls[j + 1]}
          serviceOrders={serviceOrders.filter(so => so.port_call_id == p.master_id)}
        />
      ))}
    </RowContainer>
  );
};
