import Tooltip from 'antd/es/tooltip';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { WikiContext } from '../../context/WikiContext';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import HelpModal from './HelpModal';
import { SideHelpContext } from '../../context/SideHelpContext';

const HelpContainer = styled.div`
  margin-left: 8px;
  cursor: pointer;
  color: #4a4a4a;
  display: flex;
  justify-content: center;

  svg {
    height: 17px !important;
    width: 17px !important;
    margin-top: -4px;
  }
`;

const Add = styled.div`
  font-size: 18px;
  line-height: 12px;
  margin-right: 2px;
`;

const HelpModalWithIcon = ({ objectType, objectId }) => {
  const { namespace, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { availableWikiLinks } = useContext(WikiContext);

  const { sideWikiProps, setSideWikiProps, setSideHelpOpen } = useContext(SideHelpContext);

  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    if (sideWikiProps) {
      setSideWikiProps({
        objectType,
        objectId,
      });
      setSideHelpOpen(true);
    } else {
      setOpen(true);
    }
  };

  let fullLink = true;

  // full link
  let wikiLink = availableWikiLinks.find(w => w.object_type === objectType && w.object_id === objectId);

  if (!wikiLink) {
    fullLink = false;
    wikiLink = availableWikiLinks.find(w => w.object_type === objectType);
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {wikiLink && fullLink && wikiLink.short_text && (
        <div
          style={{ border: '1px solid #e8e8e8', padding: '6px', borderRadius: '3px', margin: '6px', cursor: 'pointer' }}
          onClick={clickOpen}
        >
          {wikiLink.short_text}
        </div>
      )}
      {wikiLink && fullLink && !wikiLink.short_text && (
        <Tooltip
          title={t('Show additional information')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
        >
          <HelpContainer onClick={clickOpen}>
            <Icon type="circle-question-regular" />
          </HelpContainer>
        </Tooltip>
      )}
      {(!wikiLink || (wikiLink && !fullLink)) && user.permissions.includes('manage_wiki') && (
        <Tooltip
          title={t('Add additional information')}
          color="white"
          overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
        >
          <HelpContainer onClick={clickOpen}>
            <Add>+</Add>
            <Icon type="circle-question-regular" />
          </HelpContainer>
        </Tooltip>
      )}
      {open && <HelpModal open={open} setOpen={setOpen} objectId={objectId} objectType={objectType} />}
    </div>
  );
};

export default HelpModalWithIcon;
