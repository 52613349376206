import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import Tooltip from 'antd/es/tooltip';
import Tag from 'antd/es/tag';

import { toTitleCase } from '../../utils/utils';

const ShipTag = styled(Tag)`
  && {
    border: none;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.025em;
    padding: 0 ${({ theme }) => theme.sizing.gap_small};
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.secondary};
    ${props => {
    if (props.value === 'arriving') {
      return `
          color: ${props.theme.color.grey_dark};
          background: ${props.theme.color.white};
          border: 1px solid ${props.theme.color.grey_light};
          &:hover {
            border-color: ${darken(0.1, props.theme.color.grey_light)};
          }
        `;
    } else if (props.value === 'at berth') {
      return `
          background: ${props.theme.color.success};
          &:hover {
            background: ${darken(0.05, props.theme.color.success)};
          }
        `;
    } else if (props.value === 'departing') {
      return `
          color: ${props.theme.color.grey_dark};
          background: ${props.theme.color.highlight};
          border: 1px solid ${props.theme.color.grey_light};
          &:hover {
            background: ${darken(0.05, props.theme.color.highlight)};
          }
        `;
    }
  }};
  }
`;

const ShipBadge = ({ tag }) => {
  const textLimit = 27;
  let isLong = false;
  const badgeText = tag.value.substring(0, textLimit) + '...';

  if (tag.value.length > textLimit + 3) {
    isLong = true;
  }

  if (isLong) {
    return (
      <Tooltip placement="bottom" title={tag.value} color="#4990DD">
        <ShipTag type={tag.type} value={tag.value}>
          {tag.parameter === 'bollards' ? badgeText : toTitleCase(badgeText)}
        </ShipTag>
      </Tooltip>
    );
  } else {
    return (
      <ShipTag type={tag.type} value={tag.value}>
        {tag.parameter === 'bollards' ? tag.value : toTitleCase(tag.value)}
      </ShipTag>
    );
  }
};

export default ShipBadge;
