import React, { useContext } from 'react';
import { Marker, Popup, Tooltip as LeafletTooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';

import Icon from '../ui/Icon';
import { UserContext } from '../../context/UserContext';
import { WikiContext } from '../../context/WikiContext';

const TooltipContainer = styled.div`
  width: ${props => (props.longText ? '300px' : '100px')};
  white-space: normal;
  text-align: center;
`;

const ModalLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HelpContainer = styled.span`
  margin-left: -3px;
  cursor: pointer;
  color: #4a4a4a;
  display: flex;

  svg {
    height: 17px !important;
    width: 17px !important;
    margin-top: -4px;
  }
`;

const CancelButton = styled.div`
  margin-top: 18px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;
  font-weight: 600;

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -4px;
    margin-right: -3px;
  }
`;

const QuickLinkMarker = ({ link, openModal }) => {
  const { namespace, user, apiCall } = useContext(UserContext);
  const { fetchWikiData, fetchWikiLinkData } = useContext(WikiContext);
  const { t } = useTranslation(namespace);

  const getMarkerIcon = () => {
    return L.divIcon({
      html: ` <div class="div-map-marker-quick-notice" style="background-color:transparent;
          height:20px;
          width:20px;
          background-size:20px;!important;"/>`,
      iconSize: [20, 20],
      iconAnchor: [10, 20],
      className: 'map-marker-icon-class',
    });
  };

  const map = useMap();

  const deleteMarker = async () => {
    map.closePopup();
    await apiCall('delete', 'wiki/unlink', {
      markdown_id: link.markdown_id,
      object_type: link.object_type,
      object_id: link.object_id,
    });
    await fetchWikiData();
    await fetchWikiLinkData();
  };

  return (
    <Marker position={[link.lat, link.lon]} icon={getMarkerIcon()}>
      <LeafletTooltip offset={[0, -20]} direction="top">
        <TooltipContainer longText={link.short_text ? link.short_text.length > 60 : false}>
          {link.short_text ? link.short_text : t('No description')}
        </TooltipContainer>
      </LeafletTooltip>
      <Popup offset={[0, -20]} direction="top">
        <ModalLink>
          <Tooltip
            title={t('Show additional information')}
            color="white"
            overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
          >
            <HelpContainer
              onClick={() => {
                openModal();
                map.closePopup();
              }}
            >
              <Icon type="circle-question-regular" />
            </HelpContainer>
          </Tooltip>
        </ModalLink>
        {user.permissions.includes('manage_wiki') && (
          <Popconfirm
            title={t('Are you sure you want to remove the marker?')}
            onConfirm={() => deleteMarker()}
            okText={t('Yes')}
            okType="danger"
            cancelText={t('No')}
            icon={null}
            placement="top"
            overlayStyle={{ maxWidth: '240px' }}
          >
            <CancelButton>
              <Icon type="trash" />
              {t('Remove marker')}
            </CancelButton>
          </Popconfirm>
        )}
      </Popup>
    </Marker>
  );
};

export default QuickLinkMarker;
