import React, { createContext, useCallback, useEffect, useContext } from 'react';
import useApi from '../hooks/useApi';
import App from 'antd/es/app';
import { UserContext } from './UserContext';

export const PublicContext = createContext();

export const PublicProvider = ({ children }) => {
  const { namespace } = useContext(UserContext);

  const { message } = App.useApp();

  const { loading, data, error, fetchData } = useApi('get', 'public/map-markers', {});
  const { data: vesselData, error: vesselError, fetchData: fetchVesselData } = useApi(
    'get',
    'public/map-vessels',
    {},
    null,
    namespace !== 'vts'
  );
  const { data: aisData, error: aisError, fetchData: fetchAisData } = useApi(
    'get',
    'ais/geojson/namespace',
    {},
    null,
    namespace === 'vts'
  );

  if (error || aisError) {
    message.error(error, 4);
  }

  const getMarkers = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const getVessels = useCallback(() => {
    fetchVesselData();
  }, [fetchVesselData]);

  const getAisVessels = useCallback(() => {
    fetchAisData();
  }, [fetchAisData]);

  useEffect(() => {
    let vesselInterval = undefined;

    if (namespace === 'vts') {
      vesselInterval = setInterval(() => {
        getAisVessels();
      }, 60000);
    } else {
      vesselInterval = setInterval(() => {
        getVessels();
      }, 60000);
    }

    return () => clearInterval(vesselInterval);
  }, [getAisVessels, getVessels, namespace]);

  const markers = error || !data ? undefined : data;
  const vessels = vesselError || !vesselData ? undefined : vesselData;
  const aisVessels = aisError || !aisData ? undefined : aisData;

  return (
    <PublicContext.Provider
      value={{
        getMarkers: getMarkers,
        allMarkers: markers,
        getVessels: getVessels,
        allVessels: vessels,
        aisVessels,
        getAisVessels,
        loading: loading,
      }}
    >
      {children}
    </PublicContext.Provider>
  );
};
