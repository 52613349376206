import React, { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Tooltip from 'antd/es/tooltip';
import Paragraph from '../ui/Paragraph';
import { TIME_FORMAT } from '../../utils/constants';
import Icon from '../ui/Icon';
import DateComponent from '../ui/DateComponent';

const Source = styled.p`
  font-weight: 700;
`;

const Description = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.sizing.gap_tinier};
`;

const Time = styled.div`
  display: block;
  font-style: italic;
  margin-bottom: 0;
  margin-right: 6px;
`;

const TimestampTime = styled.div`
  display: block;
  margin-bottom: 0;
  margin-right: 6px;
`;

const Container = styled.div`
  display: flex;
`;

const SmallContainer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.text.small};
`;

const RelativeTime = styled(DateComponent)`
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
`;

const IconContainer = styled.div`
  color: #db3535;
  margin-right: 8px;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -5px;
  }
`;

const WarningLine = styled.div`
  display: flex;
`;

const Warnings = styled.div`
  margin-top: 12px;
  max-width: 300px;
`;

const TimestampTooltip = ({ data, textKey, warnings, children, ...props }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { source, created_at, time } = data;

  const TooltipTitle = () => (
    <>
      <Source>{source ? t('Timestamp received from {{source}}', { source: source }) : null}</Source>
      <Description>{t(textKey)}</Description>
      {time ? (
        <Container>
          <TimestampTime>{dayjs(time).format(TIME_FORMAT)}</TimestampTime>(<RelativeTime fromNow date={time} />)
        </Container>
      ) : null}
      {created_at ? (
        <SmallContainer>
          <Time>{t('Received at {{time}}', { time: dayjs(created_at).format(TIME_FORMAT) })}</Time>
        </SmallContainer>
      ) : null}
      {!!warnings && (
        <Warnings>
          {warnings.map((w, i) => {
            return (
              <WarningLine key={i}>
                <IconContainer>
                  <Icon type="warning" />
                </IconContainer>
                {w.warning}
              </WarningLine>
            );
          })}
        </Warnings>
      )}
    </>
  );

  return (
    <Tooltip color="white" overlayInnerStyle={{ color: '#4a4a4a' }} title={<TooltipTitle />} {...props}>
      {children}
    </Tooltip>
  );
};

export default TimestampTooltip;
