import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';

import Layout from '../../components/Layout';
import PageWithOutHeaderAndMargins from '../../components/ui/PageWithoutHeaderAndMargins';

import GeoAssetMap from '../../components/admin/geoAssetTool/GeoAssetMap';
import { GeoAssetToolProvider } from '../../context/GeoAssetToolContext';

const AdminGeoAssetTool = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Geo Asset Tool | Port Activity App';
  }, []);

  return (
    <Layout pagename={t('Geo Asset Tool')}>
      <PageWithOutHeaderAndMargins fullWidth>
        <GeoAssetToolProvider>
          <GeoAssetMap />
        </GeoAssetToolProvider>
      </PageWithOutHeaderAndMargins>
    </Layout>
  );
};

export default AdminGeoAssetTool;
