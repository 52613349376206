import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const StyledDate = styled.span`
  white-space: nowrap;
`;

const DateComponent = ({ format, date }) => {
  if (!format || !date) {
    return null;
  }

  return <StyledDate>{dayjs(date).format(format)}</StyledDate>;
};

export default DateComponent;
