import React from 'react';
import styled from 'styled-components';
import NotificationListMessage from './NotificationListMessage';
import NotificationListReplyMessage from './NotificationListReplyMessage';

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  padding-bottom: 24px;
  border-top: 1px solid #f5f5f5;

  &:last-child {
    border-top-color: transparent;
  }
`;

const NotificationListMessages = ({
  data,
  readMessage,
  onRead,
  viewPortTop,
  viewPortBottom,
  scrolling,
  addToRememberList,
}) => {
  function reverse(array) {
    return array.map((item, idx) => array[array.length - 1 - idx]);
  }

  const createChildMessages = (children, ship) => {
    if (!children || children.length === 0) {
      return undefined;
    } else {
      return (
        <>
          {reverse(children).map(c => {
            const cData = { ...c, ship };
            return (
              <NotificationListMessage
                key={c.id}
                child={true}
                data={cData}
                readMessage={readMessage}
                onRead={onRead}
                viewPortTop={viewPortTop}
                viewPortBottom={viewPortBottom}
                scrolling={scrolling}
                addToRememberList={addToRememberList}
              />
            );
          })}
        </>
      );
    }
  };

  return (
    <Messages>
      <NotificationListMessage
        child={false}
        data={data}
        readMessage={readMessage}
        onRead={onRead}
        viewPortTop={viewPortTop}
        viewPortBottom={viewPortBottom}
        scrolling={scrolling}
        addToRememberList={addToRememberList}
      />
      {createChildMessages(data.children, data.ship)}
      <NotificationListReplyMessage
        data={data}
        childrenAvailable={!!data.children && data.children.length > 0}
        messageSent={readMessage}
      />
    </Messages>
  );
};

export default NotificationListMessages;
