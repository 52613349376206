import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import Page from '../components/ui/Page';
import ActivityCardsComponent from '../components/activity/ActivityCardsComponent';

const ActivityCards = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = 'Activity Cards | Port Activity App';
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout filtering pagename={t('Activity List')}>
      <Page fullWidth smallPadding>
        <ActivityCardsComponent />
      </Page>
    </Layout>
  );
};

export default ActivityCards;
