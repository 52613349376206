import React, { useEffect, useState, useContext } from 'react';

import { UserContext } from '../../context/UserContext';

import ReactDOMServer from 'react-dom/server';
import { Marker, GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import BollardIcon from './BollardIcon';

const getIcon = number => {
  return L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<BollardIcon number={number} />),
  });
};

const BerthOverlay = () => {
  const { apiCall } = useContext(UserContext);
  const [bollards, setBollards] = useState([]);
  const [berths, setBerths] = useState(undefined);

  const getBollards = async () => {
    const response = await apiCall('get', 'heidi/bollards', {});
    if (response.data) {
      setBollards(response.data);
    }
  };

  const getBerths = async () => {
    const response = await apiCall('get', 'heidi/berths', {});
    if (response.data) {
      setBerths(response.data);
    }
  };

  useEffect(() => {
    getBollards();
    getBerths();
    // eslint-disable-next-line
  }, []);

  const style = {}; //TODO
  return (
    <>
      {Object.entries(bollards).map(([key, bollard]) => {
        const [code, lat, lon] = bollard;
        return <Marker key={key} position={[lat, lon]} icon={getIcon(code)} />;
      })}
      {berths && <GeoJSON key={1} style={style} data={berths} />}
    </>
  );
};

export default BerthOverlay;
