import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import AutoComplete from 'antd/es/auto-complete';
import Tooltip from 'antd/es/tooltip';
import DatePicker from 'antd/es/date-picker';
import TimePicker from 'antd/es/time-picker';

import ButtonLight from '../ui/ButtonLight';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import { NewInvoicingContext } from '../../context/NewInvoicingContext';
import ProductLinker from './invoiceHelps/ProductLinker';
import dayjs from 'dayjs';
import { invoicingCategoryColors } from './invoiceHelps/invoiceHelpers';

const ModalInner = styled.div`
  position: relative;
  min-width: 300px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;
  padding-bottom: 12px;
  overflow-x: auto;

  .ant-radio-disabled + span {
    color: #4a4a4a;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #4a4a4a;
  }

  .ant-picker-input > input[disabled] {
    color: #4a4a4a;
  }
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 172px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
  margin-left: 12px;
`;

const PricingRow = styled.div`
  display: flex;
  margin-bottom: 6px;
  margin-left: 12px;
`;

const MatrixContainer = styled.div`
  margin-bottom: 36px;
  margin-left: 12px;
`;

const Pricing = styled.div`
  margin-bottom: 6px;
  margin-left: 12px;
  margin-top: 18px;
`;

const RowContainer = styled.div`
  padding: 2px 0 3px 0;
  width: calc(100% / ${props => props.amount});
  margin-right: 12px;
`;

const PricingRowContainer = styled.div`
  padding: 2px 0 3px 0;
  margin-right: 12px;
  display: ${props => props.notSimple && 'flex'};
  margin-top: ${props => props.notSimple && '12px'};
`;

const FirstPricingRowContainer = styled(PricingRowContainer)`
  input.ant-input {
    text-align: right;
  }
`;

const RadioButtons = styled.div`
  display: flex;
  margin-bottom: 6px;
  margin-left: 12px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  margin-right: ${props => props.notSimple && '12px'};
  line-height: ${props => props.notSimple && '32px'};
`;

const PriceHeader = styled.div`
  line-height: 32px;
  margin-right: 12px;
`;

const Required = styled.span`
  color: red;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 360px);
  min-width: ${props => (props.simple ? '650px' : '950px')};
`;

const UnitBG = styled.div`
  margin-bottom: 6px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 12px;
  background-color: #f2f2f2;
  border-radius: 3px;
  width: 99%;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
`;

const Limits = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const InputContainer = styled.div`
  margin-bottom: 6px;
  display: flex;
`;

const LimitContainer = styled.div`
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  text-align: center;
  margin-top: 6px;
`;

const LimitPriceContainer = styled.div`
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 5px;
`;

const IconContainer = styled.div`
  line-height: 21px;
  margin-left: 4px;
  cursor: pointer;

  color: ${props => props.disabled && '#c8c8c8'};
  cursor: ${props => props.disabled && 'default'};

  i {
    height: 22px;
    width: 22px;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-top: 4px;
    margin-right: 4px;
  }
`;

const TrashContainer = styled.div`
  line-height: 21px;
  margin-left: 12px;
  margin-top: 1px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;
    margin-top: 4px;
    margin-right: 4px;
  }
`;

const LimitTrashContainer = styled.div`
  line-height: 21px;
  margin-left: 0px;
  margin-top: 1px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    margin-top: 4px;
    margin-right: 4px;
  }
`;

const AddInputButton = styled.span`
  color: ${props => props.theme.color.secondary};
  display: inline-flex;
  cursor: pointer;

  i {
    height: 18px;
    width: 18px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const RemoveCategoryButton = styled.span`
  color: ${props => props.theme.color.warning};
  display: inline-flex;
  cursor: pointer;

  i {
    height: 16px;
    width: 16px;
    margin-top: 1px;
  }

  svg {
    height: 16px;
    width: 16px;
  }
`;

const AddInputText = styled.div`
  color: ${props => props.theme.color.secondary};
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const RemoveInputText = styled.div`
  color: #4a4a4a;
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 600;
`;

const LimitInputContainer = styled.div`
  margin-left: 24px;
`;

const Limit = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: #fafafa;
  margin-right: 24px;
  border-radius: 3px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
`;

const Cell = styled.div`
  margin-right: 24px;
  align-items: center;
`;

const AddNewContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.color.secondary};
  margin-right: 5px;
  margin-top: ${props => props.first && props.noRows && '14px'};
  margin-top: ${props => !props.first && props.noRows && '6px'};

  i {
    height: 18px;
    width: 18px;
    margin-top: -1px;
    margin-right: 4px;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const AddNew = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 70px;
  font-weight: 600;
`;

const Remove = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ theme }) => theme.color.warning};
  margin-top: ${props => (props.first ? '28px' : '4px')};
  width: 20px;
`;

const Duplicate = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: ${props => (props.first ? '28px' : '4px')};
  width: 20px;
  margin-right: 12px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 36px;
`;

const CategoryInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 160px;
  margin-bottom: 6px;
  font-weight: 600;
`;

const ColorRectangle = styled.div`
  background-color: ${props => props.color};
  width: 18px;
  height: 18px;
  margin-right: 12px;
`;

const LimitButton = styled(ButtonLight)`
  background-color: ${props => (props.selected ? props.theme.color.secondary : '#ffffff')};
  color: ${props => (props.selected ? '#ffffff' : '#4a4a4a')};
  border: 1px solid #a0a0a0;
  font-weight: 600;

  &:hover {
    color: #ffffff;
  }
`;

const MatrixLimit = styled.div`
  display: flex;
`;

const MinMaxFeeContainer = styled.div`
  background: #f2f2f2;
  border-radius: 3px;
  width: 99%;
  padding: 12px;
`;

const RangeContainer = styled.div`
  display: inline-block;
  margin-bottom: 16px;
`;

const FeeLimitContainer = styled.div`
  display: flex;
`;

const ValidRows = styled.div`
  margin-top: 12px;
`;

const UseCategories = styled.div`
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
`;

const UseCategoriesText = styled.div`
  margin-right: 12px;
  font-weight: 600;
  line-height: 30px;
`;

const InfoIconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 18px;
    width: 18px;
    margin-top: 6px;
  }
`;

const DateLinkIconContainer = styled.span`
  color: ${props => props.theme.color.secondary};

  svg {
    height: 18px;
    width: 18px;
    margin-left: 4px;
  }
`;

const DescriptionRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const Description = styled.div`
  display: flex;
`;

const DescriptionText = styled.div`
  margin-right: 12px;
  line-height: 32px;
`;

const ProductModal = ({ product, closeModal, readOnly, closeAndReload, duplicateCurrent }) => {
  const { namespace, apiCall } = useContext(UserContext);
  const { days, units, invoiceLinks } = useContext(NewInvoicingContext);
  const { t } = useTranslation(namespace);

  const type = product ? 'edit' : 'new';

  const [addDynamicLimitOpen, setAddDynamicLimitOpen] = useState(false);
  const [addDynamicInput, setAddDynamicInput] = useState('');

  const [addMatrixLimitOpen, setAddMatrixLimitOpen] = useState(false);
  const [addMatrixLimitInput, setAddMatrixLimitInput] = useState('');

  const [matrixSelection, setMatrixSelection] = useState(0);

  const [addMatrixCategoryOpen, setAddMatrixCategoryOpen] = useState(false);
  const [addMatrixCategoryInput, setAddMatrixCategoryInput] = useState('');

  const [productLinkerOpen, setProductLinkerOpen] = useState(false);
  const [productLinkerInput, setProductLinkerInput] = useState(null);

  const [copyCategories, setCopyCategories] = useState(null);

  const createDynamicPrices = list => {
    let prices = {};

    if (list?.length > 0) {
      list.forEach(l => {
        prices[l.limits.lower] = { price: l.price.toString() };
      });
    } else {
      prices = {
        0: {
          price: null,
        },
      };
    }

    return prices;
  };

  const createMatrixPrices = list => {
    let prices = {};

    if (list?.length > 0) {
      list.forEach(l => {
        prices[l.limits.lower] = {
          categories: l.timings.map(timing => {
            return {
              price: timing.price.toString(),
              name: timing.frontend_data,
              times: timing.values.map(v => {
                let newTo = null;
                if (v.to) {
                  const toLength = v.to.length;
                  if (toLength === 8 && v.to.substring(toLength - 2, toLength) === '59') {
                    newTo = dayjs(v.to, 'HH:mm:ss')
                      .add(1, 'second')
                      .format('HH:mm:ss');
                  }
                }

                return {
                  to: newTo ? newTo : v.to,
                  from: v.from,
                  day: v.day,
                };
              }),
            };
          }),
        };
      });
    } else {
      prices = {
        0: {
          categories: [],
        },
      };
    }

    return prices;
  };

  const createFees = data => {
    if (!data) {
      return [];
    } else {
      return data.map(d => {
        return {
          value: d.value,
          description: d.description,
          timing: {
            type: d.timing.type,
            values: d.timing.values.map(v => {
              let newTo = null;
              if (v.to) {
                const toLength = v.to.length;
                if (toLength === 8 && v.to.substring(toLength - 2, toLength) === '59') {
                  newTo = dayjs(v.to, 'HH:mm:ss')
                    .add(1, 'second')
                    .format('HH:mm:ss');
                } else if (
                  dayjs(
                    d.timing.type === 'range_without_year' ? dayjs().year() + '-' + v.to : v.to,
                    'YYYY-MM-DDTHH:mm:ssZ'
                  ).seconds() === 59
                ) {
                  newTo = dayjs(
                    d.timing.type === 'range_without_year' ? dayjs().year() + '-' + v.to : v.to,
                    'YYYY-MM-DDTHH:mm:ssZ'
                  )
                    .add(1, 'second')
                    .format('YYYY-MM-DDTHH:mm:ssZ');
                }
              }
              return {
                ...v,
                from: d.timing.type === 'range_without_year' ? dayjs().year() + '-' + v.from : v.from,
                to: newTo ? newTo : v.to,
              };
            }),
          },
        };
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [inputData, setInputData] = useState({
    id: product?.id,
    name: product?.name,
    code: product?.code,
    description: product?.description,
    unit_price_selection: product?.pricing?.type || 'simple',
    price_per_amount: product?.pricing?.unit_price?.toString(),
    links: product?.links || [],
    input_fields: product?.pricing.units.map(u => {
      return {
        ...u,
        data: u,
      };
    }) || [
      {
        name: null,
        data: null,
      },
    ],
    dynamic_prices: createDynamicPrices(product?.pricing?.type === 'dynamic' ? product?.pricing.unit_prices : []),
    matrix_prices: createMatrixPrices(
      product?.pricing?.type === 'dynamic_time_based' ? product?.pricing.unit_prices : []
    ),
    min_fees: createFees(product?.min_fees),
    max_fees: createFees(product?.max_fees),
    vat: product?.vat?.toString(),
  });

  const [sending, setSending] = useState(false);

  const title = readOnly ? t('Product') : !product || duplicateCurrent ? t('Create new product') : t('Edit product');

  const checkNumber = value => {
    if (!value) {
      return true;
    }

    let numberRegex = /^\d+$/;

    let includesDot = value.includes('.');
    let includesComma = value.includes(',');

    if (includesDot && includesComma) {
      return false;
    } else if (!includesDot && !includesComma) {
      if (!numberRegex.test(value)) {
        return false;
      } else {
        return true;
      }
    }

    let parts = [];

    if (includesDot && !includesComma) {
      parts = value.split('.');
    } else {
      parts = value.split(',');
    }

    if (parts.length !== 2) {
      return false;
    } else if (!numberRegex.test(parts[0]) || !numberRegex.test(parts[1])) {
      return false;
    }

    return true;
  };

  const dataIsValid = () => {
    if (!inputData.name || !inputData.code || !inputData.vat || !inputData.description) {
      return false;
    }

    if (inputData.vat && !checkNumber(inputData.vat)) {
      return false;
    }

    if (inputData.min_fees.length > 0) {
      let valid = true;
      inputData.min_fees.some(f => {
        if (f.value?.length === 0) {
          valid = false;
        } else if (
          (f.timing.type === 'schedule' || f.timing.type === 'range') &&
          (f.timing.values.length === 0 ||
            f.timing.values.some(v => !v.from || !v.to || (f.timing.type === 'schedule' ? !v.day : false)))
        ) {
          valid = false;
        }
      });

      if (!valid) {
        return false;
      }
    }

    if (inputData.max_fees.length > 0) {
      let valid = true;
      inputData.max_fees.some(f => {
        if (f.value?.length === 0) {
          valid = false;
        } else if ((f.timing.type === 'schedule' || f.timing.type === 'range') && f.timing.values.length === 0) {
          valid = false;
        }
      });

      if (!valid) {
        return false;
      }
    }

    if (inputData.unit_price_selection === 'simple') {
      if (!inputData.price_per_amount || (inputData.price_per_amount && !checkNumber(inputData.price_per_amount))) {
        return false;
      }
      if (
        (inputData.input_fields[0] && !inputData.input_fields[0].name) ||
        (inputData.input_fields[1] && !inputData.input_fields[1].name)
      ) {
        return false;
      }
    }

    if (inputData.unit_price_selection === 'dynamic') {
      if (
        Object.keys(inputData.dynamic_prices).some(m => {
          return !inputData.dynamic_prices[m].price;
        })
      ) {
        return false;
      }
      if (
        (inputData.input_fields[0] && !inputData.input_fields[0].name) ||
        (inputData.input_fields[1] && !inputData.input_fields[1].name)
      ) {
        return false;
      }
    }

    if (inputData.unit_price_selection === 'dynamic_time_based') {
      if (
        Object.keys(inputData.matrix_prices).some(m => {
          return inputData.matrix_prices[m].categories.some(c => {
            return (
              !c.price ||
              c.times.some(time => {
                return !time.day || !time.from || !time.to;
              })
            );
          });
        })
      ) {
        return false;
      }
      if (
        (inputData.input_fields[0] && !inputData.input_fields[0].name) ||
        (inputData.input_fields[1] && !inputData.input_fields[1].name)
      ) {
        return false;
      }
    }
    return true;
  };

  const addInput = () => {
    setInputData({
      ...inputData,
      input_fields: [
        ...inputData.input_fields,
        {
          name: null,
          unit: null,
        },
      ],
    });
  };

  const addDynamicLimit = () => {
    let newValues = { ...inputData.dynamic_prices };
    newValues[addDynamicInput] = {
      price: null,
    };
    setInputData({
      ...inputData,
      dynamic_prices: newValues,
    });
    setAddDynamicInput('');
    setAddDynamicLimitOpen(false);
  };

  const addMatrixLimit = () => {
    let newValues = { ...inputData.matrix_prices };
    newValues[addMatrixLimitInput] = { categories: [] };
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
    setAddMatrixLimitInput('');
    setAddMatrixLimitOpen(false);
  };

  const removePriceCategory = index => {
    let newValues = { ...inputData.matrix_prices };
    newValues[matrixSelection].categories.splice(index, 1);
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
  };

  const handleCategoryCopy = () => {
    let newValues = { ...inputData.matrix_prices };
    newValues[matrixSelection].categories = JSON.parse(JSON.stringify(newValues[copyCategories].categories));
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
    setCopyCategories(null);
  };

  const addMatrixCategory = () => {
    let newValues = { ...inputData.matrix_prices };
    newValues[matrixSelection].categories.push({
      name: addMatrixCategoryInput,
      price: null,
      times: [],
    });
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
    setAddMatrixCategoryInput('');
    setAddMatrixCategoryOpen(false);
  };

  const deleteInput = index => {
    const newArray = [...inputData.input_fields];
    newArray.splice(index, 1);
    setInputData({
      ...inputData,
      input_fields: newArray,
    });
  };

  const deleteDynamicLimit = limit => {
    let newValues = { ...inputData.dynamic_prices };
    delete newValues[limit];
    setInputData({
      ...inputData,
      dynamic_prices: newValues,
    });
  };

  const deleteMatrixLimit = limit => {
    let newValues = { ...inputData.matrix_prices };
    delete newValues[limit];
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
  };

  const updateUnitPrice = (e, index, data) => {
    let newInputData = { ...inputData };
    newInputData.input_fields[index].name = e;
    newInputData.input_fields[index].data = data.data;
    setInputData(newInputData);
  };

  const updateDynamicLimitValue = (e, limit) => {
    let newValues = { ...inputData.dynamic_prices };
    newValues[limit].price = e.target.value;
    setInputData({
      ...inputData,
      dynamic_prices: newValues,
    });
  };

  const updateField = (e, name, number = false) => {
    let newInputData = { ...inputData };
    if (number) {
      newInputData[name] = e;
    } else {
      newInputData[name] = e.target.value;
    }
    setInputData(newInputData);
  };

  const onPricingChange = e => {
    setInputData({ ...inputData, unit_price_selection: e.target.value });
  };

  const handleNumber = value => {
    if (!value) {
      return value;
    }
    let newValue = value.replace(',', '.');
    return Number(newValue).toFixed(2);
  };

  const sendData = async () => {
    setSending(true);
    let dynamicData = [];
    if (inputData.unit_price_selection === 'dynamic') {
      const list = Object.keys(inputData.dynamic_prices).map(p => {
        return {
          limits: {
            lower: p,
          },
          price: inputData.dynamic_prices[p].price,
        };
      });
      list.sort((a, b) => {
        return a.limits.lower.localeCompare(b.limits.lower);
      });

      dynamicData = list.map((k, i) => {
        return {
          limits: {
            lower: k.limits.lower,
            upper: list[i + 1]?.limits.lower || null,
          },
          price: handleNumber(k.price),
        };
      });
    }

    let matrixData = [];
    if (inputData.unit_price_selection === 'dynamic_time_based') {
      const list = Object.keys(inputData.matrix_prices).map(p => {
        return {
          limits: {
            lower: p,
          },
          timings: inputData.matrix_prices[p].categories.map(c => {
            return {
              frontend_data: c.name,
              type: 'schedule',
              values: c.times.map(time => {
                const toTime =
                  time.to.length > 12 ? dayjs(time.to).add(-1, 'second') : dayjs(time.to, 'HH:mm:ss').add(-1, 'second');
                return { ...time, to: toTime.format('HH:mm:ss') };
              }),
              price: c.price,
            };
          }),
        };
      });
      list.sort((a, b) => {
        return a.limits.lower.localeCompare(b.limits.lower);
      });

      matrixData = list.map((k, i) => {
        return {
          limits: {
            lower: k.limits.lower,
            upper: list[i + 1]?.limits.lower || null,
          },
          timings: k.timings,
        };
      });
    }

    const pricing = {
      type: inputData.unit_price_selection,
      units: inputData.input_fields.map(u => {
        return {
          name: u.data.name,
          description: u.data.description,
          links: u.links?.[0]?.code
            ? [
                {
                  code: u.links[0].code,
                  parameter: u.links[0].parameter,
                },
              ]
            : [],
        };
      }),
    };

    if (inputData.unit_price_selection === 'dynamic') {
      pricing.unit_prices = dynamicData;
      pricing.limitting_units = inputData.input_fields.map(u => {
        return u.data.name;
      });
    } else if (inputData.unit_price_selection === 'dynamic_time_based') {
      pricing.unit_prices = matrixData;
      pricing.limitting_units = inputData.input_fields.map(u => {
        return u.data.name;
      });
    } else if (inputData.unit_price_selection === 'simple') {
      pricing.unit_price = inputData.price_per_amount ? handleNumber(inputData.price_per_amount) : null;
    }

    const dataToBeSend = {
      name: inputData.name,
      code: inputData.code,
      vat: inputData.vat ? handleNumber(inputData.vat) : null,
      pricing: pricing,
      description: inputData.description,
      min_fees: inputData.min_fees.map(fee => {
        return {
          value: fee.value,
          description: fee.description,
          timing: {
            type: fee.timing.type,
            values: Array.isArray(fee.timing.values)
              ? fee.timing.values.map(v => {
                const from = v.from.length > 12 ? dayjs(v.from) : dayjs(v.from, 'HH:mm:ss');
                const to = v.to.length > 12 ? dayjs(v.to) : dayjs(v.to, 'HH:mm:ss');
                return fee.timing.type === 'schedule'
                  ? {
                      day: v.day,
                      from: from.format('HH:mm:ss'),
                      to: to.add(-1, 'second').format('HH:mm:ss'),
                    }
                  : fee.timing.type === 'range'
                    ? {
                        from: from.format('YYYY-MM-DDTHH:mm:ssZ'),
                        to: to.add(-1, 'second').format('YYYY-MM-DDTHH:mm:ssZ'),
                      }
                    : fee.timing.type === 'range_without_year'
                      ? {
                          from: from.format('MM-DDTHH:mm:ssZ'),
                          to: to.add(-1, 'second').format('MM-DDTHH:mm:ssZ'),
                        }
                      : null;
              })
              : [],
          },
        };
      }),
      max_fees: inputData.max_fees.map(fee => {
        return {
          value: fee.value,
          description: fee.description,
          timing: {
            type: fee.timing.type,
            values: Array.isArray(fee.timing.values)
              ? fee.timing.values.map(v => {
                const from = v.from.length > 12 ? dayjs(v.from) : dayjs(v.from, 'HH:mm:ss');
                const to = v.to.length > 12 ? dayjs(v.to) : dayjs(v.to, 'HH:mm:ss');
                return fee.timing.type === 'schedule'
                  ? {
                      day: v.day,
                      from: from.format('HH:mm:ss'),
                      to: to.add(-1, 'second').format('HH:mm:ss'),
                    }
                  : fee.timing.type === 'range'
                    ? {
                        from: from.format('YYYY-MM-DDTHH:mm:ssZ'),
                        to: to.add(-1, 'second').format('YYYY-MM-DDTHH:mm:ssZ'),
                      }
                    : fee.timing.type === 'range_without_year'
                      ? {
                          from: from.format('MM-DDTHH:mm:ssZ'),
                          to: to.add(-1, 'second').format('MM-DDTHH:mm:ssZ'),
                        }
                      : null;
              })
              : [],
          },
        };
      }),
    };

    if (inputData.links?.length > 0) {
      const links = inputData.links.filter(l => l.code);
      dataToBeSend.links = links.map(l => {
        return { code: l.code, parameter: l.parameter };
      });
    }

    if (!duplicateCurrent && inputData.id) {
      dataToBeSend.id = inputData.id;
    }

    let result;
    try {
      result = await apiCall(dataToBeSend.id ? 'put' : 'post', 'invoicing/v2/product', dataToBeSend);
    } catch (e) {
      setSending(false);
    }

    if (result?.data?.product) {
      closeAndReload();
    }
  };

  const createRealPrice = () => {
    if (!inputData.price_per_amount) {
      return '-';
    }

    let price = inputData.price_per_amount + ' €';

    inputData.input_fields?.forEach(p => {
      if (p.unit) {
        price += ' / ' + p.unit;
      }
    });

    return price;
  };

  const checkLimitInputInvalidity = () => {
    if (addDynamicInput.length === 0) {
      return true;
    } else {
      let numberRegex = /^\d+$/;
      return !numberRegex.test(addDynamicInput);
    }
  };

  const checkMatrixLimitInputInvalidity = () => {
    if (addMatrixLimitInput.length === 0) {
      return true;
    } else {
      let numberRegex = /^\d+$/;
      return !numberRegex.test(addMatrixLimitInput);
    }
  };

  const getDynamicLimitValueTitle = (limit, next, last, amount) => {
    if (amount === 1) {
      return '-';
    }
    if (Number(limit) === 0) {
      return '0 - ' + (Number(next) - 1);
    } else if (last) {
      return '> ' + limit;
    } else {
      return limit + ' - ' + (Number(next) - 1);
    }
  };

  const handleSelectChange = (categoryIndex, rowIndex, key, value, time) => {
    let newValues = { ...inputData.matrix_prices };
    const row = newValues[matrixSelection].categories[categoryIndex].times[rowIndex];
    row[key] = time ? (value ? value.format('HH:mm:00') : value) : value;
    setInputData({ ...inputData, matrix_prices: newValues });
  };

  const addRow = index => {
    let newMatrix = { ...inputData.matrix_prices[matrixSelection] };
    newMatrix.categories[index].times.push({
      day: null,
      from: null,
      to: null,
    });
    setInputData({ ...inputData, matrix_prices: { ...inputData.matrix_prices, [matrixSelection]: newMatrix } });
  };

  const removeRow = (categoryIndex, rowIndex) => {
    let newValues = { ...inputData.matrix_prices };
    const row = newValues[matrixSelection].categories[categoryIndex].times;
    row.splice(rowIndex, 1);
    setInputData({ ...inputData, matrix_prices: newValues });
  };

  const duplicateRow = (categoryIndex, rowIndex) => {
    let newValues = { ...inputData.matrix_prices };
    const row = newValues[matrixSelection].categories[categoryIndex].times;
    row.splice(rowIndex + 1, 0, { ...newValues[matrixSelection].categories[categoryIndex].times[rowIndex] });
    setInputData({ ...inputData, matrix_prices: newValues });
  };

  let limits = [];
  if (inputData.unit_price_selection === 'dynamic') {
    limits = Object.keys(inputData.dynamic_prices).sort((a, b) => {
      return Number(a) > Number(b);
    });
  } else if (inputData.unit_price_selection === 'dynamic_time_based') {
    limits = Object.keys(inputData.matrix_prices).sort((a, b) => {
      return Number(a) > Number(b);
    });
  }

  const addNewFeeLimit = type => {
    if (type === 'min') {
      setInputData({
        ...inputData,
        min_fees: [
          ...inputData.min_fees,
          {
            value: null,
            description: null,
            timing: {
              type: 'always',
              values: [],
            },
          },
        ],
      });
    } else {
      setInputData({
        ...inputData,
        max_fees: [
          ...inputData.max_fees,
          {
            value: null,
            description: null,
            timing: {
              type: 'always',
              values: [],
            },
          },
        ],
      });
    }
  };

  const handleDaySelectChange = (index, key, value, limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[limitIndex].timing.values[index][key] = value;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[limitIndex].timing.values[index][key] = value;
      setInputData(newInput);
    }
  };

  const handleTimesSelectChange = (index, key, value, limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      if (value && [0, 15, 30, 45].includes(value.minutes())) {
        newInput.min_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDTHH:mm:00Z');
      } else {
        newInput.min_fees[limitIndex].timing.values[index][key] = null;
      }
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      if (value && [0, 15, 30, 45].includes(value.minutes())) {
        newInput.max_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDTHH:mm:00Z');
      } else {
        newInput.max_fees[limitIndex].timing.values[index][key] = null;
      }
      setInputData(newInput);
    }
  };

  const addTimeBasedRow = (limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[limitIndex].timing.values = [
        ...newInput.min_fees[limitIndex].timing.values,
        {
          day: null,
          from: null,
          to: null,
        },
      ];
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[limitIndex].timing.values = [
        ...newInput.max_fees[limitIndex].timing.values,
        {
          day: null,
          from: null,
          to: null,
        },
      ];
      setInputData(newInput);
    }
  };

  const removeTimeBasedRow = (index, limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      const newArray = [...inputData.min_fees[limitIndex].timing.values];
      newArray.splice(index, 1);
      newInput.min_fees[limitIndex].timing.values = newArray;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      const newArray = [...inputData.max_fees[limitIndex].timing.values];
      newArray.splice(index, 1);
      newInput.max_fees[limitIndex].timing.values = newArray;
      setInputData(newInput);
    }
  };

  const handleDaysSelectChange = (index, key, value, limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      if (value && [0, 15, 30, 45].includes(value.minutes())) {
        newInput.min_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDTHH:mm:00Z');
      } else if (value) {
        newInput.min_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDT00:00:00Z');
      } else {
        newInput.min_fees[limitIndex].timing.values[index][key] = null;
      }
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      if (value && [0, 15, 30, 45].includes(value.minutes())) {
        newInput.max_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDTHH:mm:00Z');
      } else if (value) {
        newInput.max_fees[limitIndex].timing.values[index][key] = value?.format('YYYY-MM-DDT00:00:00Z');
      } else {
        newInput.max_fees[limitIndex].timing.values[index][key] = null;
      }
      setInputData(newInput);
    }
  };

  const addDayBasedRow = (limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[limitIndex].timing.values = [
        ...newInput.min_fees[limitIndex].timing.values,
        {
          from: null,
          to: null,
        },
      ];
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[limitIndex].timing.values = [
        ...newInput.max_fees[limitIndex].timing.values,
        {
          from: null,
          to: null,
        },
      ];
      setInputData(newInput);
    }
  };

  const removeFee = (limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      const newArray = [...inputData.min_fees];
      newArray.splice(limitIndex, 1);
      newInput.min_fees = newArray;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      const newArray = [...inputData.max_fees];
      newArray.splice(limitIndex, 1);
      newInput.max_fees = newArray;
      setInputData(newInput);
    }
  };

  const removeDayBasedRow = (index, limitIndex, type) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      const newArray = [...inputData.min_fees[limitIndex].timing.values];
      newArray.splice(index, 1);
      newInput.min_fees[limitIndex].timing.values = newArray;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      const newArray = [...inputData.max_fees[limitIndex].timing.values];
      newArray.splice(index, 1);
      newInput.max_fees[limitIndex].timing.values = newArray;
      setInputData(newInput);
    }
  };

  const updateFeeField = (value, type, index) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[index].value = value.target.value;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[index].value = value.target.value;
      setInputData(newInput);
    }
  };

  const updateFeeDescField = (value, type, index) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[index].description = value.target.value;
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[index].description = value.target.value;
      setInputData(newInput);
    }
  };

  const updateFeeTimeChange = (value, type, index) => {
    if (type === 'min') {
      const newInput = { ...inputData };
      newInput.min_fees[index].timing.type = value.target.value;
      newInput.min_fees[index].timing.values = [];
      setInputData(newInput);
    } else {
      const newInput = { ...inputData };
      newInput.max_fees[index].timing.type = value.target.value;
      newInput.max_fees[index].timing.values = [];
      setInputData(newInput);
    }
  };

  const updateCategoryPrice = (value, index) => {
    let newValues = { ...inputData.matrix_prices };
    newValues[matrixSelection].categories[index].price = value.target.value;
    setInputData({
      ...inputData,
      matrix_prices: newValues,
    });
  };

  const getCopyOptions = () => {
    let list = limits.map((m, i) => {
      return {
        categories: inputData.matrix_prices[m].categories.length,
        value: i,
        label: getDynamicLimitValueTitle(m, limits[i + 1], i === limits.length - 1, limits.length),
      };
    });

    list = list.filter(l => l.categories > 0);

    return list;
  };

  const MinMaxFeeCreator = type => {
    const list = type === 'min' ? inputData.min_fees : inputData.max_fees;
    return (
      <MinMaxFeeContainer>
        {list.map((fee, i) => {
          return (
            <div key={i}>
              <DescriptionRow>
                <Description>
                  <DescriptionText>{t('Description')}:</DescriptionText>
                  <Input
                    placeholder={t('Description')}
                    value={fee.description || ''}
                    style={{ color: '#4a4a4a', height: '32px', width: '200px', marginRight: '40px' }}
                    onChange={e => updateFeeDescField(e, type, i)}
                    disabled={readOnly}
                  />
                </Description>
                {!readOnly && (
                  <Remove onClick={() => removeFee(i, type)}>
                    <Icon type={'trash'} />
                  </Remove>
                )}
              </DescriptionRow>
              <FeeLimitContainer>
                <Input
                  placeholder={t('Fee')}
                  value={fee.value || ''}
                  style={{ color: '#4a4a4a', height: '32px', width: '140px', marginRight: '40px' }}
                  onChange={e => updateFeeField(e, type, i)}
                  disabled={readOnly}
                />

                <RangeContainer>
                  <RadioButtons>
                    <Radio.Group
                      onChange={e => updateFeeTimeChange(e, type, i)}
                      value={fee.timing.type}
                      disabled={readOnly}
                    >
                      <Radio value="always">{t('Always')}</Radio>
                      <Radio value="schedule">{t('Weekly schedule')}</Radio>
                      <Radio value="range">{t('Time ranges')}</Radio>
                      <Radio value="range_without_year">{t('Time ranges without year')}</Radio>
                    </Radio.Group>
                  </RadioButtons>
                  {fee.timing.type === 'schedule' && (
                    <ValidRows>
                      {fee.timing?.values?.map((time, j) => (
                        <Row key={j}>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('Day')}</RowHeader> : null}
                            <Select
                              style={{ width: '124px' }}
                              value={time.day}
                              options={days}
                              onChange={value => handleDaySelectChange(j, 'day', value, i, type)}
                              disabled={readOnly}
                              placeholder={t('Day')}
                            />
                          </Cell>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('Start time')}</RowHeader> : null}
                            <TimePicker
                              style={{ width: '100px' }}
                              value={
                                time.from
                                  ? time.from.length > 12
                                    ? dayjs(time.from)
                                    : dayjs(time.from, 'HH:mm:ss')
                                  : null
                              }
                              format={'HH:mm'}
                              onChange={value => handleTimesSelectChange(j, 'from', value, i, type)}
                              onSelect={value => handleTimesSelectChange(j, 'from', value, i, type)}
                              disabled={readOnly}
                              minuteStep={15}
                            />
                          </Cell>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('End time')}</RowHeader> : null}
                            <TimePicker
                              style={{ width: '100px' }}
                              value={
                                time.to ? (time.to.length > 12 ? dayjs(time.to) : dayjs(time.to, 'HH:mm:ss')) : null
                              }
                              format={'HH:mm'}
                              onChange={value => handleTimesSelectChange(j, 'to', value, i, type)}
                              onSelect={value => handleTimesSelectChange(j, 'to', value, i, type)}
                              disabled={readOnly}
                              minuteStep={15}
                            />
                          </Cell>
                          {!readOnly && (
                            <Remove onClick={() => removeTimeBasedRow(j, i, type)} first={j === 0}>
                              <Icon type={'trash'} />
                            </Remove>
                          )}
                        </Row>
                      ))}
                      {!readOnly && (
                        <AddInputButton onClick={() => addTimeBasedRow(i, type)}>
                          <Icon type={'plus'} />
                          <AddInputText>{t('Add day and time')}</AddInputText>
                        </AddInputButton>
                      )}
                    </ValidRows>
                  )}
                  {fee.timing.type === 'range' && (
                    <ValidRows>
                      {fee.timing?.values?.map((time, j) => (
                        <Row key={j}>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('From')}</RowHeader> : null}
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              showTime={{
                                format: 'HH:mm',
                                minuteStep: 15,
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                              }}
                              style={{
                                width: '100%',
                                color: '#4a4a4a',
                              }}
                              value={time.from ? dayjs(time.from) : null}
                              onChange={value => handleDaysSelectChange(j, 'from', value, i, type)}
                              onSelect={value => handleDaysSelectChange(j, 'from', value, i, type)}
                              placeholder={t('From')}
                              disabled={readOnly}
                            />
                          </Cell>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('To')}</RowHeader> : null}
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              showTime={{
                                format: 'HH:mm',
                                minuteStep: 15,
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                              }}
                              style={{
                                width: '100%',
                                color: '#4a4a4a',
                              }}
                              value={time.to ? dayjs(time.to) : null}
                              onChange={value => handleDaysSelectChange(j, 'to', value, i, type)}
                              onSelect={value => handleDaysSelectChange(j, 'to', value, i, type)}
                              placeholder={t('To')}
                              disabled={readOnly}
                            />
                          </Cell>
                          {!readOnly && (
                            <Remove onClick={() => removeDayBasedRow(j, i, type)} first={j === 0}>
                              <Icon type={'trash'} />
                            </Remove>
                          )}
                        </Row>
                      ))}
                      {!readOnly && (
                        <AddInputButton onClick={() => addDayBasedRow(i, type)}>
                          <Icon type={'plus'} />
                          <AddInputText>{t('Add days')}</AddInputText>
                        </AddInputButton>
                      )}
                    </ValidRows>
                  )}
                  {fee.timing.type === 'range_without_year' && (
                    <ValidRows>
                      {fee.timing?.values?.map((time, j) => (
                        <Row key={j}>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('From')}</RowHeader> : null}
                            <DatePicker
                              format="DD.MM HH:mm"
                              showTime={{
                                format: 'HH:mm',
                                minuteStep: 15,
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                              }}
                              style={{
                                width: '100%',
                                color: '#4a4a4a',
                              }}
                              value={time.from ? dayjs(time.from) : null}
                              onChange={value => handleDaysSelectChange(j, 'from', value, i, type)}
                              onSelect={value => handleDaysSelectChange(j, 'from', value, i, type)}
                              placeholder={t('From')}
                              disabled={readOnly}
                            />
                          </Cell>
                          <Cell>
                            {j === 0 ? <RowHeader>{t('To')}</RowHeader> : null}
                            <DatePicker
                              format="DD.MM HH:mm"
                              showTime={{
                                format: 'HH:mm',
                                minuteStep: 15,
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                              }}
                              style={{
                                width: '100%',
                                color: '#4a4a4a',
                              }}
                              value={time.to ? dayjs(time.to) : null}
                              onChange={value => handleDaysSelectChange(j, 'to', value, i, type)}
                              onSelect={value => handleDaysSelectChange(j, 'to', value, i, type)}
                              placeholder={t('To')}
                              disabled={readOnly}
                            />
                          </Cell>
                          {!readOnly && (
                            <Remove onClick={() => removeDayBasedRow(j, i, type)} first={j === 0}>
                              <Icon type={'trash'} />
                            </Remove>
                          )}
                        </Row>
                      ))}
                      {!readOnly && (
                        <AddInputButton onClick={() => addDayBasedRow(i, type)}>
                          <Icon type={'plus'} />
                          <AddInputText>{t('Add days')}</AddInputText>
                        </AddInputButton>
                      )}
                    </ValidRows>
                  )}
                </RangeContainer>
              </FeeLimitContainer>
            </div>
          );
        })}
        {!readOnly && (
          <AddInputButton onClick={() => addNewFeeLimit(type)} style={{ marginTop: list.length > 0 ? '12px' : '0px' }}>
            <Icon type={'plus'} />
            <AddInputText>{type === 'min' ? t('Add minimum fee') : t('Add maximum fee')}</AddInputText>
          </AddInputButton>
        )}
      </MinMaxFeeContainer>
    );
  };

  const updateProductLink = (value, inputValue) => {
    let newInputData = { ...inputData };
    newInputData.input_fields[inputValue.index].links = [
      {
        parameter: 'unit_amount',
        code: value,
      },
    ];
    setInputData(newInputData);
    setProductLinkerInput(null);
    setProductLinkerOpen(false);
  };

  const PRODUCT_AT = 'product_at';

  const updateDateLink = value => {
    let newInputData = { ...inputData };
    const link = newInputData.links.find(l => l.parameter === PRODUCT_AT);

    if (link) {
      link.code = value;
    } else {
      newInputData.links.push({
        parameter: PRODUCT_AT,
        code: value,
      });
    }
    setInputData(newInputData);
  };

  const unitLinkList = invoiceLinks.find(i => i.parameter === 'unit_amount')?.links || [];
  const productDateLinkList = invoiceLinks.find(i => i.parameter === PRODUCT_AT)?.links || [];

  const dateLink = inputData.links.find(l => l.parameter === PRODUCT_AT);

  return (
    <Modal
      title={title}
      open={true}
      width={inputData.unit_price_selection === 'simple' ? 700 : 1000}
      onCancel={closeModal}
      footer={null}
      maskClosable={false}
    >
      <ModalInnerInput>
        <ScrollContainer simple={inputData.unit_price_selection === 'simple'}>
          <Row>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Product name')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Name')} value={inputData.name} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Name')}
                  value={inputData.name || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'name')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>
                {t('Product code')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('Code')} value={inputData.code} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('Code')}
                  value={inputData.code || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'code')}
                />
              )}
            </RowContainer>
            <RowContainer amount={3}>
              <RowHeader>
                {t('VAT %')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input placeholder={t('VAT')} value={inputData.vat} disabled={true} style={{ color: '#4a4a4a' }} />
              ) : (
                <Input
                  placeholder={t('VAT')}
                  value={inputData.vat || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'vat')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1}>
              <RowHeader>
                {t('Description')}
                <Required>*</Required>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Description')}
                  value={inputData.description}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Input
                  placeholder={t('Description')}
                  value={inputData.description || ''}
                  style={{ color: '#4a4a4a' }}
                  onChange={e => updateField(e, 'description')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={3}>
              <RowHeader style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                {t('Automated invoices date link')}
                <Tooltip
                  placement="top"
                  title={t(
                    'This link will be used to determine the correct time if the product is used in an automated invoice.'
                  )}
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a' }}
                >
                  <DateLinkIconContainer>
                    <Icon type="info" />
                  </DateLinkIconContainer>
                </Tooltip>
              </RowHeader>
              {readOnly ? (
                <Input
                  placeholder={t('Date')}
                  value={dateLink?.name || '-'}
                  disabled={true}
                  style={{ color: '#4a4a4a' }}
                />
              ) : (
                <Select
                  style={{ width: '100%' }}
                  value={dateLink?.code}
                  options={productDateLinkList.map(option => {
                    return {
                      label: option.name,
                      value: option.code,
                    };
                  })}
                  onChange={value => updateDateLink(value)}
                  allowClear={true}
                  onClear={() => updateDateLink(null)}
                  disabled={readOnly}
                  placeholder={t('Link')}
                />
              )}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1} style={{ marginRight: '0px' }}>
              <RowHeader>{t('Minimum fees')}</RowHeader>
              {MinMaxFeeCreator('min')}
            </RowContainer>
          </Row>
          <Row>
            <RowContainer amount={1} style={{ marginRight: '0px' }}>
              <RowHeader>{t('Maximum fees')}</RowHeader>
              {MinMaxFeeCreator('max')}
            </RowContainer>
          </Row>
          <Pricing>
            <RowHeader>
              {t('Pricing')}
              <Required>*</Required>
            </RowHeader>
            <RadioButtons>
              <Radio.Group onChange={onPricingChange} value={inputData.unit_price_selection} disabled={readOnly}>
                <Radio value="simple">{t('Simple pricing')}</Radio>
                <Radio value="dynamic">{t('Dynamic pricing')}</Radio>
                <Radio value="dynamic_time_based">{t('Time based dynamic pricing')}</Radio>
              </Radio.Group>
            </RadioButtons>
            <UnitBG>
              <PricingRow>
                {inputData.unit_price_selection === 'simple' && (
                  <FirstPricingRowContainer notSimple={inputData.unit_price_selection !== 'simple'}>
                    <RowHeader>{t('Price per amount')}</RowHeader>
                    <Input
                      placeholder={t('Price')}
                      value={inputData.price_per_amount || ''}
                      style={{ color: '#4a4a4a', width: '124px' }}
                      onChange={e => updateField(e, 'price_per_amount')}
                      disabled={readOnly}
                    />
                  </FirstPricingRowContainer>
                )}
                <PricingRowContainer
                  style={{ marginLeft: inputData.unit_price_selection === 'simple' ? '18px' : '0px' }}
                  notSimple={inputData.unit_price_selection !== 'simple'}
                >
                  <RowHeader notSimple={inputData.unit_price_selection !== 'simple'}>
                    {t('Input(s)')}
                    {inputData.unit_price_selection !== 'simple' && ':'}
                  </RowHeader>
                  <Inputs>
                    {inputData.input_fields.map((u, i) => {
                      const linkName = u.links?.[0]?.code
                        ? unitLinkList?.find(l => l.code === u.links[0].code)?.name
                        : null;
                      return (
                        <InputContainer key={i}>
                          <Select
                            style={{ color: '#4a4a4a', width: '180px' }}
                            value={u.name}
                            options={units}
                            onChange={(value, data) => updateUnitPrice(value, i, data)}
                            disabled={readOnly}
                          />
                          {!!linkName && (
                            <Tooltip
                              placement="top"
                              title={t('Link') + ': ' + linkName}
                              color="white"
                              overlayInnerStyle={{ color: '#4a4a4a' }}
                            >
                              <InfoIconContainer style={{ marginLeft: '12px' }}>
                                <Icon type="info" />
                              </InfoIconContainer>
                            </Tooltip>
                          )}
                          {!readOnly && (
                            <IconContainer
                              onClick={() => {
                                if (u.name) {
                                  setProductLinkerInput({ ...u, index: i });
                                  setProductLinkerOpen(true);
                                }
                              }}
                              disabled={!u.name}
                            >
                              {u.links?.[0]?.code ? <Icon type="link" /> : <Icon type="unlink" />}
                            </IconContainer>
                          )}
                          {inputData.input_fields.length === 2 && !readOnly && (
                            <TrashContainer onClick={() => deleteInput(i)}>
                              <Icon type="trash" />
                            </TrashContainer>
                          )}
                        </InputContainer>
                      );
                    })}
                    {inputData.input_fields.length === 1 && !readOnly && (
                      <AddInputButton onClick={() => addInput()}>
                        <Icon type={'plus'} />
                        <AddInputText>{t('Add second input')}</AddInputText>
                      </AddInputButton>
                    )}
                  </Inputs>
                </PricingRowContainer>
              </PricingRow>
              {inputData.unit_price_selection === 'simple' && (
                <PricingRow style={{ marginTop: '12px' }}>
                  <PricingRowContainer style={{ display: 'flex' }}>
                    <PriceHeader>{t('Price')}:</PriceHeader>
                    <Input
                      placeholder=""
                      value={createRealPrice()}
                      style={{ color: '#4a4a4a', width: '180px' }}
                      disabled={true}
                    />
                  </PricingRowContainer>
                </PricingRow>
              )}
              {inputData.unit_price_selection === 'dynamic' && (
                <>
                  <PricingRow style={{ marginTop: '12px' }}>
                    <PricingRowContainer style={{ display: 'flex' }}>
                      <PriceHeader>{t('Limit(s)')}:</PriceHeader>
                      <Limits>
                        {limits.map((u, i) => (
                          <LimitContainer key={i}>
                            <Limit>{u}</Limit>
                            {!readOnly && (
                              <LimitTrashContainer onClick={() => deleteDynamicLimit(u)}>
                                {Number(u) !== 0 && <Icon type="trash" />}
                              </LimitTrashContainer>
                            )}
                          </LimitContainer>
                        ))}
                        {addDynamicLimitOpen ? (
                          <LimitInputContainer>
                            <Input
                              placeholder={t('Input')}
                              value={addDynamicInput}
                              style={{ color: '#4a4a4a', width: '180px' }}
                              onChange={e => setAddDynamicInput(e.target.value)}
                            />
                            <ButtonLight
                              style={{ minWidth: '50px', marginLeft: '6px' }}
                              disabled={checkLimitInputInvalidity()}
                              onClick={() => addDynamicLimit()}
                            >
                              {t('Add')}
                            </ButtonLight>
                          </LimitInputContainer>
                        ) : (
                          !readOnly && (
                            <AddInputButton
                              style={{ marginLeft: '18px', marginTop: '3px' }}
                              onClick={() => setAddDynamicLimitOpen(true)}
                            >
                              <Icon type={'plus'} />
                              <AddInputText>{t('Add limit')}</AddInputText>
                            </AddInputButton>
                          )
                        )}
                      </Limits>
                    </PricingRowContainer>
                  </PricingRow>
                  <PricingRow style={{ marginTop: '12px' }}>
                    <PricingRowContainer style={{ display: 'flex' }}>
                      <PriceHeader>{t('Price(s)')}:</PriceHeader>
                      <Limits>
                        {limits.map((u, i) => (
                          <LimitPriceContainer key={i}>
                            <RowHeader>
                              {getDynamicLimitValueTitle(u, limits[i + 1], i === limits.length - 1, limits.length)}
                            </RowHeader>
                            <Input
                              placeholder={t('Price')}
                              value={inputData.dynamic_prices[u].price || ''}
                              style={{ color: '#4a4a4a', width: '180px' }}
                              onChange={e => updateDynamicLimitValue(e, u)}
                              disabled={readOnly}
                            />
                          </LimitPriceContainer>
                        ))}
                      </Limits>
                    </PricingRowContainer>
                  </PricingRow>
                </>
              )}
              {inputData.unit_price_selection === 'dynamic_time_based' && (
                <>
                  <PricingRow style={{ marginTop: '12px' }}>
                    <PricingRowContainer style={{ display: 'flex' }}>
                      <PriceHeader>{t('Limit(s)')}:</PriceHeader>
                      <Limits>
                        {limits.map((u, i) => (
                          <MatrixLimit key={i}>
                            <LimitContainer>
                              <Limit>{u}</Limit>
                              {i > 0 && !readOnly && (
                                <LimitTrashContainer onClick={() => deleteMatrixLimit(u)}>
                                  <Icon type="trash" />
                                </LimitTrashContainer>
                              )}
                            </LimitContainer>
                            <LimitButton
                              style={{ minWidth: '50px', marginRight: '18px' }}
                              onClick={() => {
                                setMatrixSelection(u);
                                setCopyCategories(null);
                              }}
                              selected={Number(matrixSelection) === Number(u)}
                            >
                              {getDynamicLimitValueTitle(u, limits[i + 1], i === limits.length - 1, limits.length)}
                            </LimitButton>
                          </MatrixLimit>
                        ))}
                        {addMatrixLimitOpen ? (
                          <LimitInputContainer>
                            <Input
                              placeholder={t('Input')}
                              value={addMatrixLimitInput}
                              style={{ color: '#4a4a4a', width: '180px' }}
                              onChange={e => setAddMatrixLimitInput(e.target.value)}
                            />
                            <ButtonLight
                              style={{ minWidth: '50px', marginLeft: '6px' }}
                              disabled={checkMatrixLimitInputInvalidity()}
                              onClick={() => addMatrixLimit()}
                            >
                              {t('Add')}
                            </ButtonLight>
                            <ButtonLight
                              style={{ minWidth: '50px', marginLeft: '6px' }}
                              onClick={() => {
                                setAddMatrixLimitOpen(false);
                                setAddMatrixLimitInput('');
                              }}
                              cancel
                            >
                              {t('Close')}
                            </ButtonLight>
                          </LimitInputContainer>
                        ) : (
                          !readOnly && (
                            <AddInputButton
                              style={{ marginLeft: '18px', marginTop: '3px' }}
                              onClick={() => setAddMatrixLimitOpen(true)}
                            >
                              <Icon type={'plus'} />
                              <AddInputText>{t('Add limit')}</AddInputText>
                            </AddInputButton>
                          )
                        )}
                      </Limits>
                    </PricingRowContainer>
                  </PricingRow>
                  <MatrixContainer>
                    <RowHeader>{t('Categories')}</RowHeader>
                    {inputData.matrix_prices[matrixSelection].categories.length === 0 && getCopyOptions().length > 0 && (
                      <UseCategories>
                        <UseCategoriesText>{t('Use categories from')}:</UseCategoriesText>
                        <Select
                          style={{ width: '180px' }}
                          value={copyCategories}
                          options={getCopyOptions()}
                          onChange={value => setCopyCategories(value)}
                          placeholder={t('Select limit')}
                        />
                        <ButtonLight
                          style={{ minWidth: '50px', marginLeft: '6px', marginBottom: 0 }}
                          disabled={!copyCategories && copyCategories !== 0}
                          onClick={() => handleCategoryCopy()}
                        >
                          {t('Select')}
                        </ButtonLight>
                      </UseCategories>
                    )}
                    {inputData.matrix_prices[matrixSelection].categories.map((category, categoryIndex) => {
                      return (
                        <CategoryContainer key={categoryIndex}>
                          <InfoContainer>
                            <CategoryInfo key={categoryIndex}>
                              <ColorRectangle color={invoicingCategoryColors[categoryIndex]} />
                              {category.name}
                            </CategoryInfo>
                            <div style={{ display: 'flex' }}>
                              <RowHeader style={{ marginRight: '12px', lineHeight: '30px' }}>{t('Price')}:</RowHeader>
                              <Input
                                placeholder={t('Price')}
                                value={category.price || ''}
                                style={{ color: '#4a4a4a' }}
                                onChange={e => updateCategoryPrice(e, categoryIndex)}
                                disabled={readOnly}
                              />
                            </div>
                            {category.times.length === 0 && (
                              <RemoveCategoryButton
                                style={{ marginTop: '12px' }}
                                onClick={() => removePriceCategory(categoryIndex)}
                              >
                                <Icon type={'trash'} />
                                <RemoveInputText>{t('Remove category')}</RemoveInputText>
                              </RemoveCategoryButton>
                            )}
                          </InfoContainer>
                          <Category>
                            <div>{t('Schedule')}:</div>
                            <Rows>
                              {category.times?.map((item, rowIndex) => {
                                const isFirstRow = categoryIndex === 0 && rowIndex === 0;
                                return (
                                  <Row key={`${category.name}-${rowIndex}`}>
                                    <Cell>
                                      {isFirstRow ? <RowHeader>{t('Day')}</RowHeader> : null}
                                      <Select
                                        style={{ width: '180px' }}
                                        value={item.day}
                                        options={days}
                                        onChange={value => handleSelectChange(categoryIndex, rowIndex, 'day', value)}
                                        placeholder={t('Select day')}
                                        disabled={readOnly}
                                        allowClear
                                      />
                                    </Cell>
                                    <Cell>
                                      {isFirstRow ? <RowHeader>{t('Start time')}</RowHeader> : null}
                                      <TimePicker
                                        style={{ width: '140px' }}
                                        value={
                                          item.from
                                            ? item.from.length > 12
                                              ? dayjs(item.from)
                                              : dayjs(item.from, 'HH:mm:ss')
                                            : null
                                        }
                                        format={'HH:mm'}
                                        onChange={value =>
                                          handleSelectChange(categoryIndex, rowIndex, 'from', value, true)
                                        }
                                        onSelect={value =>
                                          handleSelectChange(categoryIndex, rowIndex, 'from', value, true)
                                        }
                                        disabled={readOnly}
                                        minuteStep={15}
                                      />
                                    </Cell>
                                    <Cell>
                                      {isFirstRow ? <RowHeader>{t('End time')}</RowHeader> : null}
                                      <TimePicker
                                        style={{ width: '140px' }}
                                        value={
                                          item.to
                                            ? item.to.length > 12
                                              ? dayjs(item.to)
                                              : dayjs(item.to, 'HH:mm:ss')
                                            : null
                                        }
                                        format={'HH:mm'}
                                        onChange={value =>
                                          handleSelectChange(categoryIndex, rowIndex, 'to', value, true)
                                        }
                                        onSelect={value =>
                                          handleSelectChange(categoryIndex, rowIndex, 'to', value, true)
                                        }
                                        disabled={readOnly}
                                        minuteStep={15}
                                      />
                                    </Cell>
                                    <Cell>
                                      {isFirstRow ? <RowHeader /> : null}
                                      <div style={{ display: 'flex' }}>
                                        {!readOnly && (
                                          <Duplicate
                                            onClick={() => duplicateRow(categoryIndex, rowIndex)}
                                            first={rowIndex === 0 && categoryIndex === 0}
                                          >
                                            <Icon type={'duplicate'} />
                                          </Duplicate>
                                        )}
                                        {!readOnly && (
                                          <Remove
                                            onClick={() => removeRow(categoryIndex, rowIndex)}
                                            first={rowIndex === 0 && categoryIndex === 0}
                                          >
                                            <Icon type={'trash'} />
                                          </Remove>
                                        )}
                                      </div>
                                    </Cell>
                                  </Row>
                                );
                              })}
                              {!readOnly && (
                                <AddNewContainer
                                  first={categoryIndex === 0}
                                  noRows={inputData.matrix_prices[matrixSelection].categories.length === 0}
                                >
                                  <AddNew onClick={() => addRow(categoryIndex)}>
                                    <Icon type={'plus'} />
                                    {t('Add new row')}
                                  </AddNew>
                                </AddNewContainer>
                              )}
                            </Rows>
                          </Category>
                        </CategoryContainer>
                      );
                    })}
                    {addMatrixCategoryOpen ? (
                      <div>
                        <AutoComplete
                          placeholder={t('Category name')}
                          value={addMatrixCategoryInput}
                          style={{ color: '#4a4a4a', width: '180px', marginTop: '24px' }}
                          onChange={value => setAddMatrixCategoryInput(value)}
                          listHeight={120}
                          filterOption={(inputValue, option) =>
                            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          options={['Day', 'Night', 'Weekend', 'Holidays'].map(o => {
                            return {
                              value: o,
                              label: o,
                            };
                          })}
                          size="medium"
                        />
                        <ButtonLight
                          style={{ minWidth: '50px', marginLeft: '6px' }}
                          disabled={addMatrixCategoryInput.length === 0}
                          onClick={() => addMatrixCategory()}
                        >
                          {t('Add')}
                        </ButtonLight>
                        <ButtonLight
                          style={{ minWidth: '50px', marginLeft: '6px' }}
                          onClick={() => {
                            setAddMatrixCategoryOpen(false);
                            setAddMatrixCategoryInput('');
                          }}
                          cancel
                        >
                          {t('Close')}
                        </ButtonLight>
                      </div>
                    ) : (
                      !readOnly && (
                        <AddInputButton
                          style={{ marginLeft: '18px', marginTop: '24px' }}
                          onClick={() => setAddMatrixCategoryOpen(true)}
                        >
                          <Icon type={'plus'} />
                          <AddInputText>{t('Add price category')}</AddInputText>
                        </AddInputButton>
                      )
                    )}
                  </MatrixContainer>
                </>
              )}
            </UnitBG>
          </Pricing>
        </ScrollContainer>
        <MiddleLine />
        {readOnly ? (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={() => closeModal(false)}>
              {t('Close')}
            </ButtonLight>
          </ActionButtons>
        ) : (
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!dataIsValid() || sending} sending={sending} onClick={sendData}>
              {type === 'new' ? t('Add') : t('Save')}
            </ButtonLight>
          </ActionButtons>
        )}
      </ModalInnerInput>

      {productLinkerOpen && (
        <ProductLinker
          closeModal={() => {
            setProductLinkerInput(null);
            setProductLinkerOpen(false);
          }}
          inputValue={productLinkerInput}
          setLink={updateProductLink}
          unitLinkList={unitLinkList}
        />
      )}
    </Modal>
  );
};

export default ProductModal;
