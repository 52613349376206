import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';

import { UserContext } from '../context/UserContext';

import ButtonLight from './ui/ButtonLight';
import { isEmailValid } from '../utils/utils';

const ColumnsSplit = styled.div`
  display: block;
  width: 100%;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .ant-input[disabled] {
    border: 1px solid #e0e0e0;
  }
`;

const ModalInner = styled.div`
  min-height: 170px;
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  padding-top: 6px;

  textarea {
    resize: none;
    height: 94px;
  }
`;

const RowContainerSplit = styled.div`
  padding: 10px;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MethodButtons = styled.div`
  padding: 12px;
`;

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  margin-bottom: -6px;
  button {
    margin-bottom: 0px;
    margin-right: ${({ theme }) => theme.sizing.gap_small};
    border: 1px solid #d9d9d9;
    &:last-child {
      margin-right: 0;
      border: '1px solid transparent';
    }
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const Required = styled.span`
  color: red;
`;

const Info = styled.div`
  padding: 12px;
`;

const ProfileChangePasswordModal = ({ closeModal, setSentSuccess }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [verificationRequested, setVerificationRequested] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState(null);
  const [inputData, setInputData] = useState({
    newPassword: null,
    confirmNewPassword: null,
    verificationCode: null,
    password: null,
  });

  const skipCode = !isEmailValid(user.email);

  const title = t('Change your password');

  const listDataIsValid = () => {
    return (
      !!inputData.newPassword &&
      !!inputData.confirmNewPassword &&
      !!inputData.verificationCode &&
      !!inputData.password &&
      inputData.newPassword === inputData.confirmNewPassword
    );
  };

  const setNewPassword = e => {
    const newInputData = { ...inputData };
    newInputData['newPassword'] = e.target.value;
    setInputData(newInputData);
  };

  const setConfirmNewPassword = e => {
    const newInputData = { ...inputData };
    newInputData['confirmNewPassword'] = e.target.value;
    setInputData(newInputData);
  };

  const setVerificationCode = e => {
    const newInputData = { ...inputData };
    newInputData['verificationCode'] = e.target.value;
    setInputData(newInputData);
  };

  const setPassword = e => {
    const newInputData = { ...inputData };
    newInputData['password'] = e.target.value;
    setInputData(newInputData);
  };

  const requestVerificationCode = useCallback(
    async method => {
      setApiCallPending(true);
      try {
        const { data } = await apiCall('get', 'profile-verification-code', {
          method,
        });
        setApiCallPending(false);
        if (data?.result === 'OK') {
          setVerificationRequested(true);
          setVerificationMethod(data.method);
          if (data.code) {
            setInputData(previous => ({
              ...previous,
              verificationCode: data.code,
            }));
          }
        }
      } catch (e) {
        setApiCallPending(false);
        throw e;
      }
    },
    [apiCall]
  );

  const sendData = async () => {
    setApiCallPending(true);
    try {
      const { data } = await apiCall('post', 'profile-password', {
        new_password: inputData.newPassword,
        password: inputData.password,
        verification_method: verificationMethod,
        verification_code: inputData.verificationCode,
      });
      setApiCallPending(false);
      if (data?.result === 'OK') {
        setSentSuccess(true);
        closeModal();
      }
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
  };

  useEffect(() => {
    const skipCode = !isEmailValid(user.email);
    if (skipCode) {
      requestVerificationCode('email-code');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const method = verificationMethod === 'email-code' ? 'email' : 'unknown';

  return (
    <Modal title={title} open={true} width={400} footer={null} onCancel={closeModal}>
      {verificationRequested ? (
        <ModalInner>
          <Info>
            {skipCode
              ? t('Change your password with your current password')
              : t(
                'Change your password with a verification code found from your {{method}} and your current password',
                {
                  method,
                }
              )}
          </Info>
          <ColumnsSplit>
            <RowContainerSplit>
              <RowHeader>
                {t('New password')}
                <Required>*</Required>
              </RowHeader>
              <Input
                placeholder={t('New password')}
                name="newPassword"
                type="password"
                value={inputData.newPassword}
                onChange={e => setNewPassword(e)}
              />
            </RowContainerSplit>
            <RowContainerSplit>
              <RowHeader>
                {t('Confirm new password')}
                <Required>*</Required>
              </RowHeader>
              <Input
                placeholder={t('Confirm new password')}
                name="newPassword"
                type="password"
                value={inputData.confirmNewPassword}
                onChange={e => setConfirmNewPassword(e)}
              />
            </RowContainerSplit>
            {!skipCode ? (
              <RowContainerSplit>
                <RowHeader>
                  {t('Verification code from {{method}}', { method })}
                  <Required>*</Required>
                </RowHeader>
                <Input
                  placeholder={t('Verification code from {{method}}', { method })}
                  name="verificationCode"
                  type="password"
                  autoComplete="new-password"
                  value={inputData.verificationCode}
                  onChange={e => setVerificationCode(e)}
                />
              </RowContainerSplit>
            ) : null}
            {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
            <input
              style={{
                width: '1px',
                height: '1px',
                border: '1px solid white',
                position: 'absolute',
                left: '10px',
                top: 0,
                zIndex: 1,
              }}
              type="text"
              name="fakeusernameremembered"
            />
            <div
              style={{
                width: '10px',
                height: '10px',
                borderColor: 'transparent !important',
                backgroundColor: 'white',
                position: 'absolute',
                left: '10px',
                top: 0,
                zIndex: 2,
              }}
            />
            <RowContainerSplit>
              <RowHeader>
                {t('Current password')}
                <Required>*</Required>
              </RowHeader>
              <Input
                placeholder={t('Password')}
                type="password"
                autoComplete="new-password"
                value={inputData.password}
                onChange={e => setPassword(e)}
              />
            </RowContainerSplit>
          </ColumnsSplit>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
            <ButtonLight disabled={!listDataIsValid() || apiCallPending} onClick={sendData}>
              {t('Send')}
            </ButtonLight>
          </ActionButtons>
        </ModalInner>
      ) : !skipCode ? (
        <ModalInner>
          <Info>{t('Please select the verification method')}</Info>
          <MethodButtons>
            <ButtonLight disabled={apiCallPending} onClick={() => requestVerificationCode('email-code')}>
              {t('Send email to {{email}}', { email: user.email })}
            </ButtonLight>
          </MethodButtons>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
          </ActionButtons>
        </ModalInner>
      ) : (
        <ModalInner>
          <Info>{t('Verifying, please wait')}</Info>
          <MiddleLine />
          <ActionButtons>
            <ButtonLight type="button" cancel onClick={closeModal}>
              {t('Cancel')}
            </ButtonLight>
          </ActionButtons>
        </ModalInner>
      )}
    </Modal>
  );
};

export default ProfileChangePasswordModal;
