import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';

const useRegister = withoutCode => {
  const { register, namespace, setAlert, showRegisterLoader } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const initState = {
    checked: false,
    firstName: '',
    lastName: '',
    code: '',
    email: '',
    password: '',
    confirm: '',
  };

  const [info, setInfo] = useState(initState);

  const handleRegister = async e => {
    e.preventDefault();
    const { firstName, lastName, code, email, password, confirm } = info;
    if (firstName && lastName && (code || withoutCode) && email && password && confirm) {
      if (password !== confirm) {
        setAlert({
          type: 'error',
          message: t('Registration error'),
          description: t('Passwords do not match!'),
        });
        return;
      }
      if (password.length < 1) {
        setAlert({
          type: 'error',
          message: t('Registration error'),
          description: t('Password cannot be empty!'),
        });
        return;
      }
      const res = await register(firstName, lastName, code, email, password);
      showRegisterLoader(false);
      if (res) {
        setAlert({
          type: 'info',
          message: t('Registration complete'),
          description: t('User account was successfully created.'),
        });
      }
      return res;
    }
  };

  const disabled =
    !info.checked ||
    info.firstName === '' ||
    info.lastName === '' ||
    (info.code === '' && !withoutCode) ||
    info.email === '' ||
    info.password === '' ||
    info.confirm === '' ||
    (info.password !== '' && info.confirm !== '' && info.password !== info.confirm)
      ? true
      : false;

  return {
    handleRegister: handleRegister,
    info: info,
    setInfo: setInfo,
    disabled: disabled,
  };
};

export default useRegister;
