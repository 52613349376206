import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from '../../../ui/Icon';
import { UserContext } from '../../../../context/UserContext';
import Empty from 'antd/es/empty';

import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../../../utils/constants';

const Modal = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  width: 540px;
  height: calc(100% - 55px);
  background-color: white;
  border-left: 2px solid #f0f0f0;
  color: ${({ theme }) => theme.color.grey_dark};
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const IconContainer = styled.div`
  padding: 0 6px 2px 3px;
  cursor: pointer;
  margin-right: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 24px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: black;
`;

const ChangesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f0f0f0;
  flex-grow: 1;
  height: calc(100% - 255px);
`;
const Container = styled.div`
  padding: 0 24px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
`;

const Changes = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

const ChangeRow = styled.div`
  width: ${props => (props.child ? '95%' : '100%')};
  align-self: flex-start;
  margin-left: ${props => (props.child ? '5%' : '0')};
  display: inline-block;
  margin-top: ${props => (props.child ? '15px' : '24px')};
`;

const ChangeTime = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.text.tiny};
  width: 100%;
`;

const AuthorRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const Rows = styled.div`
  width: 100%;
`;

const AuthorSmall = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 14px;
`;

const ChangeText = styled.div`
  font-size: 13px;
  border-radius: ${props => (props.decision ? '4px 4px 0 0' : '4px')};
  padding: 10px;
  font-weight: ${props => (props.newItem ? 700 : 400)};
  background-color: ${({ theme }) => theme.color.beige};
  position: relative;

  &:hover {
    .more-button {
      display: flex;
    }
  }
`;

const FinnpilotChangeLog = ({ close, data, isTaskList }) => {
  const { namespace } = useContext(UserContext);

  const { t } = useTranslation(namespace);

  function reverse(array) {
    return array.map((item, idx) => array[array.length - 1 - idx]);
  }

  let changes = !data?.changeHistory ? [] : data.changeHistory;
  const offset = isTaskList ? 0 : 55;

  return (
    <Modal id="finnpilot-changelog" style={{ top: `${offset}px`, height: `calc(100% - ${offset}px)` }}>
      <HeaderRow>
        <Header>{t('Change history')}</Header>
        <IconContainer onClick={close}>
          <Icon type="close" />
        </IconContainer>
      </HeaderRow>
      <ChangesContainer>
        <Container>
          <Changes>
            {changes.length > 0 ? (
              reverse(changes).map((change, index) => <Change key={`change-${index}`} child={false} data={change} />)
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No changes.')} />
            )}
          </Changes>
        </Container>
      </ChangesContainer>
    </Modal>
  );
};

const Change = ({ data, child }) => {
  return (
    <ChangeRow child={child}>
      <ChangeTime>
        <AuthorRow>
          <Rows>
            <AuthorSmall>
              <span style={{ fontSize: '14px', fontWeight: 600, color: '#4A4A4A' }}>{data.authorType}</span>{' '}
              <div>
                {dayjs(data.timestamp).format('DD.MM.YYYY')}
                <span style={{ fontWeight: 600, marginLeft: '3px' }}>{dayjs(data.timestamp).format('HH:mm')}</span>
              </div>
            </AuthorSmall>
          </Rows>
        </AuthorRow>
      </ChangeTime>
      <ChangeText>
        {Object.entries(data.details).map(([key, value], idx) => (
          <div key={idx}>
            {key}: {dayjs(value, dayjs.ISO_8601, true).isValid() ? dayjs(value).format(TIME_FORMAT) : value}
          </div>
        ))}
      </ChangeText>
    </ChangeRow>
  );
};

export default FinnpilotChangeLog;
