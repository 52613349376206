import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { TIME_FORMAT_DAY } from '../../utils/constants';
import { UserContext } from '../../context/UserContext';
import { COLORS } from './ClusterGraphs';

Chart.register(...registerables);

const BarContainer = styled.div`
  max-height: 500px;
  height: calc(100vh / 3);

  ${props => {
    if (props.oneColumn) {
      return `
        max-height: 800px;
        height: calc(70vh);
      `;
    }
  }}
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Legends = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px 0;
`;

const Legend = styled.div`
  display: flex;
  justify-content: center;
  margin: 2px 6px;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  cursor: default;
`;

const Square = styled.div`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
  margin-top: 3px;
  margin-right: 6px;
`;

const LegendText = styled.div`
  display: flex;
  justify-content: center;
`;

const PieWithLegendCluster = ({
  oneColumn,
  dataObject,
  timeType,
  selectedTime,
  universalTimePickerOn,
  universalSelectedTime,
  ...props
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const getColor = index => {
    return COLORS[index % COLORS.length];
  };

  const thousandFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup
      .slice()
      .reverse()
      .find(function(item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
  };

  let datasets = null;

  if (dataObject.clusters) {
    let colorIndex = 0;
    const cluster = dataObject.clusters[0];

    if (cluster) {
      for (const dataItem of cluster.data) {
        const legend = props.legend[dataItem.label];

        datasets === null && (datasets = { labels: [], datasets: [{ label: '', data: [], backgroundColor: [] }] });
        datasets.labels.push(legend ? legend.title : dataItem.label);

        datasets.datasets[0].data.push(dataItem.values[0]?.value ? Math.round(dataItem.values[0]?.value) : 0);
        datasets.datasets[0].backgroundColor.push(legend ? legend.color : getColor(colorIndex++));
      }
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 30,
        top: 20,
      },
    },
    plugins: {
      title: {
        text: dataObject?.clusters && dataObject?.clusters[0] ? dataObject.clusters[0].label.replace(/_/g, ' ') : '',
        display: true,
        padding: {
          bottom: 32,
        },
        font: {
          weight: 'normal',
          size: 14,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: function(ctx) {
          if (ctx.dataset.data.length > 6) {
            const max = Math.max(...ctx.dataset.data);
            if (ctx.dataset.data[ctx.dataIndex] < max * 0.1) {
              return 'auto';
            }
          }
          return true;
        },
        anchor: 'end',
        align: 'end',
        font: {
          size: 14,
        },
        formatter: function(e) {
          return e > 1000 ? thousandFormatter(e, 0) : e;
        },
      },
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
        borderColor: '#D8D8D8',
        borderWidth: 1,
      },
    },
  };

  const getTimeSpan = () => {
    let time = universalTimePickerOn ? universalSelectedTime : selectedTime;
    let from = null;
    let to = null;
    if (timeType === 'week') {
      from = dayjs(time).startOf('isoweek');
      to = dayjs(time).endOf('isoweek');
    } else if (timeType === 'month') {
      from = dayjs(time).startOf('month');
      to = dayjs(time).endOf('month');
    } else if (timeType === 'year') {
      from = dayjs(time).startOf('year');
      to = dayjs(time).endOf('year');
    }
    to.isAfter(dayjs()) && (to = dayjs());
    return dayjs(from).format(TIME_FORMAT_DAY) + ' - ' + dayjs(to).format(TIME_FORMAT_DAY);
  };

  const createLegends = () => {
    if (datasets.labels.length > 16) {
      return null;
    }
    const legendList = [];
    for (let i = 0; i < datasets.labels.length; i++) {
      legendList.push(
        <Legend key={i}>
          <Square color={datasets.datasets[0].backgroundColor[i]} />
          <LegendText>{datasets.labels[i]}</LegendText>
        </Legend>
      );
    }

    return legendList;
  };

  const unitPlugin = {
    afterDatasetsDraw: chart => {
      const {
        ctx,
        chartArea: { top, height, left, width },
      } = chart;

      ctx.fillStyle = '#5a5a5a';
      ctx.font = 'normal 13px sans-serif';
      ctx.fillText('kg', left + width / 2 - 6, top + height / 2 + 6);
    },
  };

  return datasets ? (
    <div>
      <BarContainer oneColumn={oneColumn}>
        <Doughnut plugins={[ChartDataLabels, unitPlugin]} options={options} data={datasets} />
      </BarContainer>
      <Legends>{createLegends()}</Legends>
    </div>
  ) : (
    <NoData>{t('No data available between {{timespan}}.', { timespan: getTimeSpan() })}</NoData>
  );
};
export default PieWithLegendCluster;
