import React from 'react';
import styled from 'styled-components';

import Search from '../ui/Search';

const StyledPageSearch = styled.div`
  button {
    top: 4px !important;

    i {
      width: 20px;
      height: 20px;
    }
  }

  input {
    min-width: 200px;
    height: 32px !important;
    min-height: 32px !important;
    padding-left: 2.2rem;
    font-size: 13px;
  }
`;

const SmallPageSearch = props => {
  return (
    <StyledPageSearch>
      <Search autoComplete="off" {...props} />
    </StyledPageSearch>
  );
};

export default SmallPageSearch;
