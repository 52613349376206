import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { UserContext } from '../../../context/UserContext';

import ButtonLight from '../../ui/ButtonLight';
import DeliverServiceOrderModalLine from './DeliverServiceOrderModalLine';
import ServiceOrderInfo from './ServiceOrderInfo';
import ServiceOrderAckInfo from './ServiceOrderAckInfo';
import ServiceOrderDeliveryInfo from './ServiceOrderDeliveryInfo';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Popconfirm from 'antd/es/popconfirm';

import Icon from '../../ui/Icon';
import DebounceSelect from '../../ui/DebounceSelect';
import { ExternalTypeServiceOrderLine } from './externalTypes/ServiceOrderExternalTypes';

const ColumnsSplit = styled.div`
  display: block;
  width: 100%;
  max-height: calc(100vh - 440px);
  overflow-y: auto;
  .ant-input[disabled] {
    border: 1px solid #e0e0e0;
  }
`;

const ModalInner = styled.div`
  min-height: 230px;

  textarea {
    resize: none;
    height: 94px;
  }
`;

const SecondColumnSplit = styled.div`
  width: 100%;
  display: inline-block;
`;

const RowContainerSplit = styled.div`
  padding: 0 7px 7px 0;
  display: inline;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: inline;
  width: 100%;
`;

const OrderLine = styled.div`
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const NothingToBeDelivered = styled.div`
  display: flex;
  justify-content: center;
  margin: 56px;
`;

const PartContainer = styled.div`
  display: inline;
  width: 100%;
`;

const DeliveryRow = styled.div`
  display: flex;
  width: 100%;
`;

const StaticDeliveryRow = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0px;
`;

const EditButton = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.color.secondary};
  cursor: pointer;
  font-weight: 600;

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -4px;
    margin-right: -3px;
  }
`;

const CancelButton = styled.div`
  margin-right: 18px;
  color: ${({ theme }) => theme.color.warning};
  cursor: pointer;
  font-weight: 600;

  i {
    margin-right: 8px;
  }

  svg {
    margin-top: -4px;
    margin-right: -3px;
  }
`;

const SentSuccess = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 6px 12px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
`;

const ConfirmUpdateTitle = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmUpdateText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const { TextArea } = Input;

const DeliverServiceOrderModal = ({ vessel, lines, reloadData, update }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const inputData = lines.map(l => {
    return {
      id: l.id,
      service_name: l.service_provider_name + ' ' + l.service_type_name,
      quantity: l.quantity,
      when: l.when,
      notes: l.notes,
      requested_delivery_time: l.requested_delivery_time,
      email: l.email,
      phone: l.phone,
      service_order_id: l.service_order_id,
      ack: l.ack,
      deliveries: l.deliveries,
      status: l.is_cancelled
        ? 'cancelled'
        : l.deliveries?.length
          ? l.deliveries[0].status === 'cancelled'
            ? 'delivery_cancelled'
            : l.deliveries[0].status
          : l.ack?.status
            ? l.ack?.status === 'accepted'
              ? 'acknowledged'
              : l.ack?.status
            : 'requested',
      disabled: !('canAckDeliver' in l ? l.canAckDeliver : true),
      service_provider_or_ack_email: l.ack?.email || l.service_provider_email,
      service_provider_or_ack_phone: l.ack?.phone || l.service_provider_phone,
      created_by: l.created_by,
      created_at: l.created_at,
      data: l.data,
      canEdit: 'canEdit' in l ? l.canEdit : true,
    };
  });
  const [editIndex, setEditIndex] = useState(undefined);

  const [sentSuccess, setSentSuccess] = useState(undefined);
  const [sentCancelSuccess, setSentCancelSuccess] = useState(undefined);
  let timer = useRef();

  const sendDeliveryData = async (data, otherData, status) => {
    if (update) {
      const result = await apiCall('put', 'service-orders/service-delivery', data);

      if (result?.status === 200) {
        if (status === 'done') {
          setSentSuccess(otherData.service_name);
          timer.current = setTimeout(() => {
            setSentSuccess(undefined);
            setSentCancelSuccess(undefined);
          }, 3000);
        } else {
          setSentCancelSuccess(otherData.service_name);
          timer.current = setTimeout(() => {
            setSentSuccess(undefined);
            setSentCancelSuccess(undefined);
          }, 3000);
        }

        reloadData();
      }
    } else {
      const result = await apiCall('post', 'service-orders/service-delivery', data);

      if (result?.status === 200) {
        if (status === 'done') {
          setSentSuccess(otherData.service_name);
          timer.current = setTimeout(() => {
            setSentSuccess(undefined);
            setSentCancelSuccess(undefined);
          }, 3000);
        } else {
          setSentCancelSuccess(otherData.service_name);
          timer.current = setTimeout(() => {
            setSentSuccess(undefined);
            setSentCancelSuccess(undefined);
          }, 3000);
        }

        reloadData();
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const updateEdit = (e, type) => {
    let newEditData = { ...editIndex };
    newEditData[type] = e.target.value;
    setEditIndex(newEditData);
  };

  const updateEditTime = value => {
    let newEditData = { ...editIndex };
    newEditData['requested_delivery_time'] = value?.format() || null;
    setEditIndex(newEditData);
  };

  const saveEdit = async () => {
    const result = await apiCall('put', 'service-orders/service-order', {
      service_order: {
        id: editIndex.service_order_id,
        order_lines: [
          {
            id: editIndex.id,
            service_id: editIndex.service_id,
            quantity: parseFloat(editIndex.quantity),
            when: editIndex.when,
            requested_delivery_time: editIndex.requested_delivery_time,
            notes: editIndex.notes,
            email: editIndex.email,
            phone: editIndex.phone,
          },
        ],
      },
    });

    if (result?.status === 200) {
      setEditIndex(undefined);
      reloadData();
    }
  };

  const cancelServiceOrder = async id => {
    const result = await apiCall('post', 'service-orders/service-order-line/cancel/' + id, {
      is_cancelled: true,
    });

    if (result?.status === 200) {
      reloadData();
    }
  };

  const updateServiceSplit = (value, data) => {
    let newEditData = { ...editIndex };
    newEditData['service_id'] = value;
    if (data.data?.provider?.email?.length > 0) {
      newEditData['email'] = data.data.provider.email;
    }
    if (data.data?.provider?.phone?.length > 0) {
      newEditData['phone'] = data.data.provider.phone;
    }
    newEditData['service_name'] = data.label;
    setEditIndex(newEditData);
  };

  const handleServiceSearchChange = value => {
    let newEditData = { ...editIndex };
    newEditData['service_search'] = value;
    setEditIndex(newEditData);
  };

  const fetchServiceList = async value => {
    const params = {
      query: {
        text: value,
      },
    };
    const result = await apiCall('post', 'service-orders/service/search', params);
    if (result?.status === 200 && result?.data) {
      return result.data.results.services.map(d => {
        return { value: d.id, label: d.provider.name + ' ' + d.type.name, data: d };
      });
    }
    return [];
  };

  return (
    <ModalInner>
      {sentSuccess && <SentSuccess>{t('{{service}} delivered successfully.', { service: sentSuccess })}</SentSuccess>}
      {sentCancelSuccess && (
        <SentSuccess>{t('{{service}} cancelled successfully.', { service: sentCancelSuccess })}</SentSuccess>
      )}
      <ColumnsSplit>
        <SecondColumnSplit>
          {inputData.length === 0 && (
            <NothingToBeDelivered>{t('There is nothing to be delivered.')}</NothingToBeDelivered>
          )}
          {inputData.map((row, index) => {
            const editing = editIndex && editIndex.id === row.id;
            return (
              <OrderLine
                key={row.id}
                style={index ? { borderTop: '1px solid #f0f0f0', paddingTop: '10px' } : undefined}
              >
                {row.data?.service_external_type ? (
                  <ExternalTypeServiceOrderLine
                    key={row.id}
                    vessel={vessel}
                    line={row}
                    reloadData={reloadData}
                    externalType={row.data.service_external_type}
                  />
                ) : (
                  <InfoContainer key={row.id} style={{ backgroundColor: index % 2 === 1 ? '#f9f9f9' : 'transparent' }}>
                    <RowContainer>
                      {editing ? (
                        <DeliveryRow>
                          <PartContainer style={{ width: '60%' }}>
                            <Container>
                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('Service')}</RowHeader>
                                <DebounceSelect
                                  placeholder={t('Provider and type')}
                                  fetchOptions={fetchServiceList}
                                  onChange={value => handleServiceSearchChange(value)}
                                  onSelect={(value, data) => updateServiceSplit(value, data)}
                                  value={editIndex.service_search || editIndex.service_name}
                                  style={{ width: '100%' }}
                                  allowClear={true}
                                  onClear={() => updateServiceSplit('', index)}
                                />
                              </RowContainerSplit>

                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('Quantity')}</RowHeader>
                                <StyledInputNumber
                                  placeholder={t('Amount')}
                                  value={editIndex.quantity}
                                  decimalSeparator={','}
                                  min={0}
                                  onChange={e => updateEdit({ target: { value: e } }, 'quantity')}
                                />
                              </RowContainerSplit>

                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('When')}</RowHeader>
                                <Input value={editIndex.when} onChange={e => updateEdit(e, 'when')} />
                              </RowContainerSplit>
                            </Container>
                            <Container>
                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('Email')}</RowHeader>
                                <Input value={editIndex.email} onChange={e => updateEdit(e, 'email')} />
                              </RowContainerSplit>
                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('Phone')}</RowHeader>
                                <Input value={editIndex.phone} onChange={e => updateEdit(e, 'phone')} />
                              </RowContainerSplit>
                              <RowContainerSplit style={{ width: 'calc(100% / 3)' }}>
                                <RowHeader>{t('Requested delivery time')}</RowHeader>
                                <DatePicker
                                  format="DD.MM.YYYY HH:mm"
                                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                  style={{
                                    width: '100%',
                                  }}
                                  value={
                                    editIndex.requested_delivery_time ? dayjs(editIndex.requested_delivery_time) : null
                                  }
                                  onChange={value => updateEditTime(value)}
                                  placeholder={t('Requested delivery time')}
                                  required
                                />
                              </RowContainerSplit>
                            </Container>
                          </PartContainer>

                          <RowContainerSplit style={{ width: 'calc(100% * 0.4 - 92px)' }}>
                            <RowHeader>{t('Request note')}</RowHeader>
                            <TextArea value={editIndex.notes} onChange={e => updateEdit(e, 'notes')} />
                          </RowContainerSplit>

                          <ButtonContainer style={{ width: '92px' }}>
                            <Popconfirm
                              title={
                                <>
                                  <ConfirmUpdateTitle>
                                    {t('Update service order {{name}}?', {
                                      name: editIndex.service_name,
                                    })}
                                  </ConfirmUpdateTitle>
                                  <ConfirmUpdateText>{t('The acknowledgement status will be reset')}</ConfirmUpdateText>
                                </>
                              }
                              onConfirm={() => saveEdit()}
                              okText={t('Yes')}
                              okType="danger"
                              cancelText={t('No')}
                              icon={null}
                              id="pop-confirm-for-new-list"
                              key="action-2"
                            >
                              <ButtonLight>{t('Save')}</ButtonLight>
                            </Popconfirm>
                            <ButtonLight cancel onClick={() => setEditIndex(undefined)}>
                              {t('Cancel')}
                            </ButtonLight>
                          </ButtonContainer>
                        </DeliveryRow>
                      ) : (
                        <StaticDeliveryRow>
                          <ServiceOrderInfo data={row} />
                          {(row.status === 'acknowledged' || row.status === 'rejected') && (
                            <Buttons style={row.disabled ? { position: 'static' } : { position: 'absolute' }}>
                              {row.status !== 'cancelled' &&
                                !row.data?.external_data?.disabledActions?.includes('edit') &&
                                user.permissions.includes('manage_service_order') && (
                                <EditButton onClick={() => setEditIndex({ ...row })}>
                                  <Icon type="edit" />
                                  {t('Edit')}
                                </EditButton>
                              )}
                              {row.status !== 'cancelled' &&
                                row.status !== 'rejected' &&
                                !row.data?.external_data?.disabledActions?.includes('cancel') &&
                                user.permissions.includes('manage_service_order') && (
                                <Popconfirm
                                  title={t('Cancel service order for {{name}}?', { name: vessel.vessel_name })}
                                  onConfirm={() => cancelServiceOrder(row.id)}
                                  okText={t('Yes')}
                                  okType="danger"
                                  cancelText={t('No')}
                                  icon={null}
                                  id="pop-confirm-for-new-list"
                                  key="action-1"
                                >
                                  <CancelButton>
                                    <Icon type="trash" />
                                    {t('Cancel')}
                                  </CancelButton>
                                </Popconfirm>
                              )}
                            </Buttons>
                          )}
                          <ServiceOrderAckInfo data={row} />
                          {!!row.deliveries &&
                            row.deliveries.length > 0 &&
                            (row.deliveries[0].status === 'done' || row.deliveries[0].status === 'cancelled') && (
                            <ServiceOrderDeliveryInfo data={row} />
                          )}
                        </StaticDeliveryRow>
                      )}
                      {row.status !== 'cancelled' &&
                        !row.data?.external_data?.disabledActions?.includes('delivery') &&
                        !row.disabled &&
                        row.ack.status !== 'rejected' &&
                        (row.deliveries && row.deliveries.length > 0
                          ? row.deliveries[0].status !== 'done' && row.deliveries[0].status !== 'cancelled'
                          : true) && (
                        <DeliverServiceOrderModalLine data={row} sendData={sendDeliveryData} update={update} />
                      )}
                    </RowContainer>
                  </InfoContainer>
                )}
              </OrderLine>
            );
          })}
        </SecondColumnSplit>
      </ColumnsSplit>
    </ModalInner>
  );
};

export default DeliverServiceOrderModal;
