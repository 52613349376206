import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 12px;
  height: ${props => (props.long ? '37px' : '26px')};
  justify-content: center;
  margin-right: 10px;
  color: ${props => (props.fill ? props.fill : 'white')};
`;

const ProgressLine = ({ start, end, thisChecked, nextChecked, long, fill }) => {
  return (
    <Container id="progress-line" long={long} fill={fill}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={long ? '0 0 12 37' : '0 0 12 26'}>
        {!start && <rect width="1" height="11" x="5" y="0" fill={thisChecked ? '#4990DD' : '#a8a8a8'} />}
        <circle
          cx="5.5"
          cy={thisChecked ? '13' : '14'}
          r="3"
          fill={thisChecked ? '#4990DD' : 'currentColor'}
          strokeWidth="1"
          stroke={thisChecked ? '#4990DD' : '#a8a8a8'}
        />
        {!end && <rect width="1" height={long ? '21' : '16'} x="5" y="16" fill={nextChecked ? '#4990DD' : '#a8a8a8'} />}
      </svg>
    </Container>
  );
};

export default ProgressLine;
