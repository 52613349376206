import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../context/UserContext';

import Layout from '../components/Layout';
import PageWithoutHeaderAndMargins from '../components/ui/PageWithoutHeaderAndMargins';

import MapContainer from '../components/map/MapContainer';
import { SeaChartMarkersProvider } from '../context/SeaChartMarkersContext';
import { SeaChartVesselsProvider } from '../context/SeaChartVesselsContext';
import { PublicProvider } from '../context/PublicContext';
import { CraneProvider } from '../context/CraneContext';
import { GeoAssetToolProvider } from '../context/GeoAssetToolContext';

const MapView = () => {
  const { namespace, mapDefaultCoordinates, mapDefaultZoom } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const location = useLocation();

  useEffect(() => {
    document.title = 'Map | Port Activity App';
  }, []);

  return (
    <Layout filtering={location.search.includes('?type=fleet') ? false : true} pagename={t('Map')}>
      <PageWithoutHeaderAndMargins fullWidth>
        <SeaChartMarkersProvider>
          <SeaChartVesselsProvider>
            <CraneProvider>
              <GeoAssetToolProvider>
                <PublicProvider>
                  <MapContainer coordinatesString={mapDefaultCoordinates} zoom={mapDefaultZoom} />
                </PublicProvider>
              </GeoAssetToolProvider>
            </CraneProvider>
          </SeaChartVesselsProvider>
        </SeaChartMarkersProvider>
      </PageWithoutHeaderAndMargins>
    </Layout>
  );
};

export default MapView;
