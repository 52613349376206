import './MapContainer.css';

import Layout from 'antd/es/layout';
import AutoComplete from 'antd/es/auto-complete';
import Switch from 'antd/es/switch';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import 'leaflet-draw';

import { UserContext } from '../../context/UserContext';

import FleetFilterContainer from '../filter/FleetFilterContainer';
import FleetFilterSearch from '../filter/FleetFilterSearch';
import { FleetFilteringContext } from '../../context/FleetFilteringContext';

import { sideModalWidth } from '../commonCalendar/utility/constants';
import VesselSidePanel from '../fleetView/VesselSidePanel';
import DelayedVessels from '../fleetView/DelayedVessels';
import { SeaChartVesselsContext } from '../../context/SeaChartVesselsContext';
import { PublicContext } from '../../context/PublicContext';
import { GeoAssetToolContext } from '../../context/GeoAssetToolContext';
import GeoAssetToolRightPanel from '../admin/geoAssetTool/GeoAssetToolRightPanel';
import Icon from '../ui/Icon';
import ButtonLight from '../ui/ButtonLight';
import PublishModal from '../admin/geoAssetTool/PublishModal';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';
import { AlertContext } from '../../context/AlertContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPlaceHolder } from '../ui/ErrorPlaceHolder';
import { logError } from '../ui/errorLogging';
import MainMap from './MainMap';
import MainGeoAssetMap from './MainGeoAssetMap';

const TopPanel = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  padding-bottom: 6px;
  width: 100%;
`;

const StyledTableSearchRow = styled.div`
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    > * {
      margin-right: 20px;
    }
    > button:last-child {
      margin-bottom: ${({ theme }) => theme.sizing.gap};
    }
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

const FleetFilter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const DelayedVesselsContainer = styled.div`
  margin-left: -18px;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const FleetFilterSearchContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const NoVesselFound = styled.div`
  border: 1px solid #f5bd3e;
  padding: 5px 12px;
  border-radius: 3px;
  position: absolute;
  top: 20px;
  left: calc(50% - 100px);
`;

const GeoButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 0px !important;
`;

const GeoSwitch = styled.div`
  margin-top: 0px;
`;

const SwitchLabel = styled.div`
  margin-left: 12px;
  line-height: 23px;
`;

const SpinWrapper = styled.div`
  filter: ${props => (props.enabled ? 'grayscale(70%)' : 'none')};
  width: 100%;
  height: 100%;
  transition-duration: 0.2s;
  transition-property: filter;
`;

const SaveButtonWrapper = styled.div`
  left: 32px;
  top: 82px;
  position: absolute;
  visibility: ${props => (props.enabled ? 'visible' : 'hidden')};
  &:hover {
    color: #d0011c;
  }
`;

const ContainerForWidth = styled.div`
  display: flex;
  height: calc(100vh - 204px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 190px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const MapContainerForWidth = styled.div`
  ${props => {
    if (props.measureDrawingEnabled) {
      return `
        .leaflet-draw-section {
          margin-top: -70px;
        }

        .leaflet-marker-draggable {
          height: 10px !important;
          width: 10px !important;
          margin-left: -5px !important;
          margin-top: -5px !important;
        }
      `;
    }
  }}
`;

const mapMargin = 71;

const MapContainer = ({ coordinatesString, zoom }) => {
  const { namespace, namespaces, apiCall, modules, showableVesselOnMap, setShowableVesselOnMap, user } = useContext(
    UserContext
  );
  const { fleetQuickSearchText } = useContext(FleetFilteringContext);

  const { alertsHeight } = useContext(AlertContext);

  const { t } = useTranslation(namespace);
  const location = useLocation();
  const [fleetViewOn, setFleetViewOn] = useState(false);
  const [quickLinksOn, setQuickLinksOn] = useState(true);
  const [showRoutes, setShowRoutes] = useState(false);

  const [vesselsState, setVesselsState] = useState(namespace === 'vts' ? 'all-fi' : 'port'); // port, all-fi, helcom, all-ais
  const [selectedAisVessel, setSelectedAisVessel] = useState(undefined);
  const [portSearch, setPortSearch] = useState('');
  const [selectedPortVessel, setSelectedPortVessel] = useState(undefined);
  const [selectedPortVesselLocation, setSelectedPortVesselLocation] = useState(false);

  const [otherNamespaceData, setOtherNamespaceData] = useState({});

  const [sideModalOpen, setSideModalOpen] = useState(undefined);
  const [seaPassageData, setSeaPassageData] = useState(undefined);

  const [noVesselFound, setNoVesselFound] = useState(undefined);

  const [geoAssetToolOn, setGeoAssetToolOn] = useState(false);
  const [geoAssetToolPublish, setGeoAssetToolPublish] = useState(false);

  const [measureDrawing, setMeasureDrawing] = useState(false);
  const [circleDrawing, setCircleDrawing] = useState(false);

  const { vessels } = useContext(SeaChartVesselsContext);
  const { allVessels, aisVessels } = useContext(PublicContext);

  const timerRef = useRef();

  const {
    rightPanelOpen,
    setRightPanelOpen,
    rightPanelData,
    setRightPanelData,
    closeRightPanel,
    globalsaveLoading,
    undoFunction,
    redoFunction,
    undoAvailable,
    redoAvailable,
    globalSaveFunction,
    globalSave,
    nullAssetsAndTypes,
    rightPanelListData,
    assetDataHistory,
    assetData,
    setNewRotatedCoords,
    setEditPointsInCreate,
  } = useContext(GeoAssetToolContext);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    if (showableVesselOnMap) {
      if (vessels?.length) {
        const vesselToShow = showableVesselOnMap;
        setShowableVesselOnMap(undefined);
        const v = vessels.find(v => v.properties.imo === vesselToShow.imo);
        if (v) {
          setSelectedPortVessel({
            name: v.properties.name,
            mmsi: v.properties.mmsi,
            lat: v.geometry.coordinates[1],
            lon: v.geometry.coordinates[0],
          });
          setSelectedPortVesselLocation(true);
        } else {
          setNoVesselFound(vesselToShow.vesselName);

          setTimeout(() => {
            setNoVesselFound(undefined);
          }, 7000);
        }
      }
    }
  }, [showableVesselOnMap, vessels]); // eslint-disable-line

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const port = params.get('port');
    if (type === 'fleet' || port) {
      setFleetViewOn(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const getDataFromOtherNamespaces = async () => {
      let dataCollection = {};
      for (const ns of namespaces) {
        if (ns.namespace !== namespace) {
          const result = await apiCall('get', 'ongoing-port-calls', undefined, undefined, undefined, ns.namespace);

          if (result.data) {
            const { portcalls } = result.data || { portcalls: [] };
            dataCollection[ns.namespace] = portcalls;
          }
        }
      }
      setOtherNamespaceData(dataCollection);
    };

    if (modules.fleet_module === 'enabled' && fleetViewOn) {
      getDataFromOtherNamespaces();

      clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        getDataFromOtherNamespaces();
      }, 10 * 60 * 1000);
    }
  }, [fleetViewOn]); // eslint-disable-line

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const handleKeyUp = event => {
    event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 'z' && undoAvailable) {
      undoFunction();
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'y' && redoAvailable) {
      redoFunction();
    } else if ((event.ctrlKey || event.metaKey) && charCode === 's' && globalSave) {
      globalSaveFunction();
    } else if (event.key === 'Escape' && rightPanelOpen) {
      setRightPanelOpen(false);
      setRightPanelData({});
      setEditPointsInCreate(false);
      setNewRotatedCoords(null);
    }
  };

  const [appliedZoom, setAppliedZoom] = useState(zoom === undefined ? 5 : zoom);
  const [appliedCoordinates, setAppliedCoordinates] = useState(
    coordinatesString === undefined ? '59.129089,14.373028' : coordinatesString
  );

  return (
    <>
      <Layout width="100%" style={{ backgroundColor: '#00000000' }}>
        <TopPanel>
          <StyledTableSearchRow>
            {modules.sea_passage_module === 'enabled' && (
              <DelayedVesselsContainer>
                <DelayedVessels />
              </DelayedVesselsContainer>
            )}
            {fleetViewOn && (
              <FleetFilter>
                <FleetFilterContainer containerId="fleet-filter-in-map" />
              </FleetFilter>
            )}
            {!geoAssetToolOn ? (
              fleetViewOn ? (
                <FleetFilterSearchContainer>
                  <FleetFilterSearch />
                </FleetFilterSearchContainer>
              ) : (
                <AutoComplete
                  style={{
                    width: 200,
                  }}
                  options={
                    vesselsState === 'all-fi'
                      ? namespace === 'vts'
                        ? aisVessels?.map(v => {
                          return { value: v.properties.name, data: v };
                        }) || []
                        : allVessels?.map(v => {
                          return { value: v.properties.name, data: v };
                        }) || []
                      : vessels?.map(v => {
                        return { value: v.properties.name, data: v };
                      }) || []
                  }
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(value, option) => {
                    setSelectedPortVessel({
                      name: option.data.properties.name,
                      mmsi: option.data.properties.mmsi,
                      lat: option.data.geometry.coordinates[1],
                      lon: option.data.geometry.coordinates[0],
                    });
                    setSelectedPortVesselLocation(true);
                    setPortSearch('');
                  }}
                  value={portSearch}
                  onChange={e => setPortSearch(e)}
                  placeholder={t('Search vessel by name')}
                />
              )
            ) : null}
            {!fleetViewOn &&
              user.role === 'admin' &&
              (document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
              <GeoButtonContainer>
                <GeoSwitch>
                  <Switch
                    size="small"
                    checked={geoAssetToolOn}
                    onChange={e => {
                      setGeoAssetToolOn(e);
                      if (!e) {
                        nullAssetsAndTypes();
                      } else {
                        setMeasureDrawing(false);
                        setCircleDrawing(false);
                      }
                    }}
                    aria-label="Geo-asset-tool"
                  />
                </GeoSwitch>
                <SwitchLabel>{t('Enable Geo Asset Tool')}</SwitchLabel>
              </GeoButtonContainer>
            )}
            {!!noVesselFound && (
              <NoVesselFound>{t('No {{vesselName}} found.', { vesselName: noVesselFound })}</NoVesselFound>
            )}
          </StyledTableSearchRow>
        </TopPanel>
      </Layout>
      {/* Disabling tap for Map is needed to support bound popups on Safari
      (bug in leaflet, see https://github.com/Leaflet/Leaflet/issues/7255) */}
      <ContainerForWidth onKeyUp={handleKeyUp} alertsHeight={alertsHeight}>
        <MapContainerForWidth
          id="map-container-for-width"
          style={{
            height: '100%',
            width: sideModalOpen || rightPanelOpen ? 'calc(100% - ' + sideModalWidth + 'px)' : '100%',
          }}
          measureDrawingEnabled={!geoAssetToolOn}
        >
          <SpinWrapper enabled={globalsaveLoading}>
            <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('medium', t)} onError={logError}>
              {!geoAssetToolOn ? (
                <MainMap
                  zoom={appliedZoom}
                  setZoom={setAppliedZoom}
                  appliedCoordinates={appliedCoordinates}
                  fleetViewOn={fleetViewOn}
                  setFleetViewOn={setFleetViewOn}
                  quickLinksOn={quickLinksOn}
                  setQuickLinksOn={setQuickLinksOn}
                  fleetSearch={fleetQuickSearchText}
                  setSideModalOpen={setSideModalOpen}
                  sideModalOpen={sideModalOpen}
                  setShowRoutes={setShowRoutes}
                  showRoutes={showRoutes}
                  seaPassageData={seaPassageData}
                  vesselsState={vesselsState}
                  setVesselsState={setVesselsState}
                  setSelectedAisVessel={setSelectedAisVessel}
                  selectedAisVessel={selectedAisVessel}
                  selectedPortVessel={selectedPortVessel}
                  setSelectedPortVessel={setSelectedPortVessel}
                  selectedPortVesselLocation={selectedPortVesselLocation}
                  setSelectedPortVesselLocation={setSelectedPortVesselLocation}
                  measureDrawing={measureDrawing}
                  setMeasureDrawing={setMeasureDrawing}
                  circleDrawing={circleDrawing}
                  setCircleDrawing={setCircleDrawing}
                  setAppliedCoordinates={setAppliedCoordinates}
                />
              ) : (
                <MainGeoAssetMap
                  appliedCoordinates={appliedCoordinates}
                  zoom={appliedZoom}
                  setZoom={setAppliedZoom}
                  style={{ zIndex: 0 }}
                  setSelectedAisVessel={setSelectedAisVessel}
                  selectedAisVessel={selectedAisVessel}
                  globalsaveLoading={globalsaveLoading}
                  setAppliedCoordinates={setAppliedCoordinates}
                />
              )}
            </ErrorBoundary>
          </SpinWrapper>
        </MapContainerForWidth>
        {sideModalOpen && (
          <VesselSidePanel
            sideModalOpen={sideModalOpen}
            setSideModalOpen={setSideModalOpen}
            otherNamespaceData={otherNamespaceData}
            setShowSeaPassage={setShowRoutes}
            showSeaPassage={showRoutes}
            setSeaPassageData={setSeaPassageData}
          />
        )}
        {geoAssetToolOn && (
          <GeoAssetToolRightPanel
            rightPanelOpen={rightPanelOpen}
            setRightPanelOpen={setRightPanelOpen}
            rightPanelData={rightPanelData}
            setRightPanelData={setRightPanelData}
            rightPanelListData={rightPanelListData}
            closeRightPanel={closeRightPanel}
            mapMargin={mapMargin}
            mainMap={true}
          />
        )}
        {geoAssetToolOn && (
          <SaveButtonWrapper enabled={globalSave}>
            <ButtonLight
              type="button"
              style={{ marginBottom: '0px' }}
              onClick={() => {
                setGeoAssetToolPublish(true);
              }}
            >
              <Icon type="world" />
              {t('Publish')}
            </ButtonLight>
          </SaveButtonWrapper>
        )}
        {geoAssetToolPublish && (
          <PublishModal
            assetDataHistory={assetDataHistory}
            assetData={assetData}
            closeModal={() => setGeoAssetToolPublish(false)}
          />
        )}
      </ContainerForWidth>
    </>
  );
};

export default MapContainer;
