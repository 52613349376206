import React from 'react';

import styled from 'styled-components';

import LogoAppStore from '../images/LogoAppStore.svg?react';
import LogoPlayStore from '../images/LogoPlayStore.png?react';

import LogoCentralBalticImage from '../images/logo-central-baltic-color.svg?react';
import LogoEUImage from '../images/logo-eu-flag.svg?react';

const APP_STORE_HREF = 'https://apps.apple.com/fi/app/port-activity-rauma/id1498429922';
const SWE_APP_STORE_HREF = 'https://apps.apple.com/fi/app/port-activity-g%C3%A4vle/id1498430023';
const PLAY_STORE_HREF = 'https://play.google.com/store/apps/details?id=com.unikie.portactivity.rauma';
const SWE_PLAY_STORE_HREF = 'https://play.google.com/store/apps/details?id=com.unikie.portactivity.gavle';
const FINTRAFFIC_HREF = 'https://www.fintraffic.fi/en';
const UNIKIE_HREF = 'https://www.unikie.com/en/expertise/maritime-smart-ports/';
const UNIKIE_HREF2 = 'https://www.unikie.com/en/solution/port-flow-optimization/';

const FooterLink = styled.a`
  margin-top: -${({ theme }) => theme.sizing.gap};
  margin-bottom: -${({ theme }) => theme.sizing.gap};
  margin-left: ${({ theme }) => theme.sizing.gap_small};
  color: #77afed;
  &:hover {
    color: #a6ccf7;
  }
`;

const StoreLink = styled.a`
  line-height: 0;
`;

const FooterHeading = styled.p`
  margin-top: ${({ theme }) => theme.sizing.gap_tiny};
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
  font-weight: 700;
  text-transform: uppercase;
`;

const FooterContent = styled.span`
  padding-left: ${({ theme }) => theme.sizing.gap};
  padding-right: ${({ theme }) => theme.sizing.gap};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const FooterContainer = styled.footer`
  height: 150px;
  background-color: ${({ theme }) => theme.color.primary};
  min-width: 1050px;
`;

const LogoContainer = styled.span`
  margin-top: -${({ theme }) => theme.sizing.gap_small};
  display: inline-flex;
  align-items: center;
`;

const FooterItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.sizing.gap};
`;

const MenuItem = styled.li`
  float: left;
  width: 250px;
  padding-left: ${({ theme }) => theme.sizing.gap_small};
  @media (min-width: 1400px) {
    margin-left: 60px;
    &:first-child {
      margin-left: 0px;
    }
    width: 340px;
  }
`;

const SmallMenuItem = styled.li`
  float: left;
  width: 250px;
  padding-left: ${({ theme }) => theme.sizing.gap_small};
  @media (min-width: 1400px) {
    margin-left: 40px;
    width: 270px;
  }
`;

const LogoWrapper = styled.a`
  margin-top: ${({ theme }) => theme.sizing.gap};
  display: block;
  margin-bottom: ${({ theme }) => theme.sizing.gap_small};
  opacity: 0.7;
  transition: ${({ theme }) => theme.transition.cubic(0.4)};
  max-width: 9rem;
  &:hover {
    opacity: 0.9;
  }
`;

const getAppStoreLogos = isSwedishVersion => (
  <LogoContainer>
    <StoreLink href={isSwedishVersion ? SWE_APP_STORE_HREF : APP_STORE_HREF}>
      <LogoAppStore />
    </StoreLink>
    <StoreLink href={isSwedishVersion ? SWE_PLAY_STORE_HREF : PLAY_STORE_HREF}>
      <img style={{ height: '60px' }} src={LogoPlayStore} alt="Play store" />
    </StoreLink>
  </LogoContainer>
);

const getEULogos = () => (
  <LogoContainer>
    <LogoWrapper href="https://europa.eu/" style={{ marginRight: '20px' }}>
      <LogoEUImage alt="EU" />
    </LogoWrapper>
    <LogoWrapper href="https://centralbaltic.eu">
      <LogoCentralBalticImage alt="Central Baltic" />
    </LogoWrapper>
  </LogoContainer>
);

const footerItemsFin = [
  {
    header: 'Fintraffic',
    content:
      'Vessel Traffic Management provides Vessel Traffic Service (VTS) to merchant shipping and other marine traffic and maintains safety radio operations.',
    link: {
      href: FINTRAFFIC_HREF,
      text: 'Fintraffic >',
    },
  },
  {
    header: 'Unikie Maritime and smart ports',
    content: 'Track, route & manage. Robust solutions for maritime and smart ports.',
    link: {
      href: UNIKIE_HREF,
      text: 'Maritime and smart ports >',
    },
  },
  {
    header: 'Download the APP',
    content: getAppStoreLogos(),
    small: true,
  },
  {
    header: '',
    content: getEULogos(),
    small: true,
  },
];

const footerItemsSwe = [
  {
    header: 'Unikie Maritime and smart ports',
    content: 'Track, route & manage. Robust solutions for maritime and smart ports.',
    link: {
      href: UNIKIE_HREF,
      text: 'Maritime and smart ports >',
    },
  },
  {
    header: 'Port Activity App',
    content: `The app is available for Swedish ports as SaaS solution with AIS, MSW and Pilot interfaces. 
    All ports and port actors can join. Further information from your local port or`,
    link: {
      href: UNIKIE_HREF2,
      text: 'Unikie >',
    },
  },
  {
    header: 'Download the APP',
    content: getAppStoreLogos(true),
    small: true,
  },
];

const FooterItem = ({ item }) => {
  if (item.small) {
    return (
      <SmallMenuItem>
        <FooterHeading>{item.header}</FooterHeading>
        {item.content}
        {item.link && <FooterLink href={item.link.href}>{item.link.text}</FooterLink>}
      </SmallMenuItem>
    );
  }

  return (
    <MenuItem>
      <FooterHeading>{item.header}</FooterHeading>
      {item.content}
      {item.link && <FooterLink href={item.link.href}>{item.link.text}</FooterLink>}
    </MenuItem>
  );
};

const LandingFooter = ({ isSwedishVersion }) => {
  const footerItems = isSwedishVersion ? footerItemsSwe : footerItemsFin;
  return (
    <FooterContainer>
      <FooterContent>
        <FooterItems>
          {footerItems.map((item, index) => (
            <FooterItem key={index} item={item} />
          ))}
        </FooterItems>
      </FooterContent>
    </FooterContainer>
  );
};

export default LandingFooter;
