import React, { useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'antd/es/tooltip';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import DateComponent from '../ui/DateComponent';

const Line = styled.div`
  width: 100%;
  height: 16px;
  display: flex;
`;

const PortcallSet = styled.div`
  height: 100%;
  display: flex;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
`;

const PortcallContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SwimLine = styled.div`
  position: absolute;
  height: 2px;
  margin-top: 6px;
  background-color: #b8d4f2;
  width: 100%;
`;

const Dot = styled.div`
  position: relative;
  height: ${props => (props.big ? '10px' : '6px')};
  width: ${props => (props.big ? '10px' : '6px')};
  margin-top: ${props => (props.big ? '2px' : '4px')};
  border-radius: 5px;
  background-color: ${props => (props.big ? '#4990dd' : '#76aee8')};
`;

const Time = styled(DateComponent)`
  font-style: italic;
  font-size: ${({ theme }) => theme.text.small};
  margin-bottom: 0;
  white-space: nowrap;
`;

const TimestampLine = ({ portcalls }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const amounts = portcalls[0].events.map(p => p.timestamps.length);
  const total = amounts.reduce((partialSum, a) => partialSum + a, 0);

  return (
    <Line>
      {portcalls[0].events.map((pc, i) => {
        if (pc.timestamps.length === 0) {
          return null;
        }
        return (
          <PortcallSet
            key={i}
            style={{ width: 'calc(100% * ' + amounts[i] / total + ')' }}
            port={pc.location === 'port'}
          >
            {pc.timestamps.map((stamp, j) => {
              const timeState = stamp.state?.replace(/_/g, ' ');
              const maybeLocation = stamp?.payload?.location;
              const maybeDirection = stamp?.payload?.direction;
              const extraData =
                '' + (maybeLocation ? ' ' + maybeLocation : '') + (maybeDirection ? ' ' + maybeDirection : '');
              const timestampTextKey = `${stamp.time_type} ${timeState}${extraData}`;

              return (
                <Tooltip
                  key={j}
                  title={
                    <div>
                      <div>{t(timestampTextKey)}</div>
                      {stamp.time && <Time format={'DD.MM. HH:mm'} date={stamp.time} />}
                    </div>
                  }
                  color="white"
                  overlayInnerStyle={{ color: '#4a4a4a', fontSize: '13px' }}
                >
                  <PortcallContainer style={{ width: 'calc(100% / ' + amounts[i] + ')' }}>
                    <SwimLine port={pc.location === 'port'} />
                    <Dot big={!!stamp.time} port={pc.location === 'port'} />
                  </PortcallContainer>
                </Tooltip>
              );
            })}
          </PortcallSet>
        );
      })}
    </Line>
  );
};

export default TimestampLine;
