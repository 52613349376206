import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { BerthPlanningToolContext } from '../../../context/BerthPlanningToolContext';
import { CranePlanningToolContext } from '../../../context/CranePlanningToolContext';

import ButtonLight from '../../ui/ButtonLight';
import Icon from '../../ui/Icon';
import Button from '../../ui/Button';
import Spin from 'antd/es/spin';
import AddOrEditPredictiveBerthPlanModal from '../../berthPlanningTool/modals/AddOrEditPredictiveBerthPlanModal';

const ActionButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  button {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const MiddleLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey_light};
  margin-top: 12px;
`;

const CloseModalButton = styled(Button)`
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 1;
  width: auto;
  height: auto;
  margin: 0;
  color: #4a4a4a;
`;

const ModalInner = styled.div`
  z-index: 999;
  position: fixed;
  top: 20px;
  left: max(109px, calc((100% - 1200px) / 2));
  margin-top: 100px;
  padding: 0;
  width: calc(100% - 218px);
  background-color: white;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  min-width: 500px;
`;

const ModalInnerInput = styled(ModalInner)`
  min-height: 195px;
  padding-top: 55px;
`;

const HeaderContainer = styled.div`
  z-index: 1;
  position: absolute;
  display: inline;
  width: 100%;
  height: 56px;
  padding-left: 22px;
  padding-top: 15px;
  bottom: 2rem;
  right: 0px;
  left: 0px;
  top: 0px;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 700;
  color: #4a4a4a;
  border-bottom: 1px solid #f0f0f0;
`;

const Blocker = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 64px 0;
  height: 200px;
`;

const EditWorkModal = ({ work, closeModal }) => {
  const { namespace, apiCall, user } = useContext(UserContext);
  const { berths } = useContext(BerthPlanningToolContext);
  const { updateCranes } = useContext(CranePlanningToolContext);
  const { t } = useTranslation(namespace);

  const [data, setData] = useState(undefined);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const vesselData = await apiCall('get', 'bpt-calendar-vessels', {
        port_call_master_id: work.port_call_master_id,
      });

      if (vesselData?.data) {
        setData(vesselData.data[0]);
      }
    };

    if (!dataFetched) {
      setDataFetched(true);
      getData();
    }
  }, [apiCall, dataFetched, work.port_call_master_id]);

  if (!!data && !!berths && !!berths.length) {
    return (
      <AddOrEditPredictiveBerthPlanModal
        vessel={data}
        type={'edit'}
        closeModal={closeModal}
        readOnly={true}
        workId={user.permissions.includes('manage_pbp') ? work.id : undefined}
        updateCranes={updateCranes}
      />
    );
  }

  return (
    <>
      <Blocker />
      <ModalInnerInput>
        <HeaderContainer>
          {t('Edit work')}
          <CloseModalButton copy onClick={closeModal}>
            <Icon type={'close'} style={{ width: '15px' }} />
          </CloseModalButton>
        </HeaderContainer>
        <SpinnerContainer>
          <Spin size="large" />
        </SpinnerContainer>
        <MiddleLine />
        <ActionButtons>
          <ButtonLight type="button" cancel onClick={closeModal}>
            {t('Cancel')}
          </ButtonLight>
        </ActionButtons>
      </ModalInnerInput>
    </>
  );
};

export default EditWorkModal;
