import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import { BerthPlanningToolContext } from '../../context/BerthPlanningToolContext';

const MultiSelectionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 41;
  cursor: crosshair;
`;

const SelectionBorders = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const MultiSelection = ({ leftScroll, scrollElementScrollTop, scrollHeight }) => {
  const { width, setDisableScrolling, moveSelection, setMoveSelection, setMultiselectedVessels } = useContext(
    BerthPlanningToolContext
  );

  const multiSelectionComponent = useRef();
  const onMouseMove = e => {
    setMoveSelection(slct => {
      return {
        ...slct,
        moveX: Math.min(
          Math.max(e.clientX - multiSelectionComponent.current.getBoundingClientRect().left, 2),
          width - 2
        ),
        moveY: Math.min(
          Math.max(e.clientY - multiSelectionComponent.current.getBoundingClientRect().top, 1),
          scrollHeight + scrollElementScrollTop
        ),
        scrollTop: scrollElementScrollTop,
        scrollLeft: leftScroll,
        stopped: false,
      };
    });
  };

  const onMouseUp = () => {
    setMoveSelection(slct => {
      return {
        ...slct,
        stopped: true,
      };
    });

    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    setDisableScrolling(false);
  };

  const onMouseDown = e => {
    setMoveSelection({
      startX: e.clientX - multiSelectionComponent.current.getBoundingClientRect().left,
      startY: e.clientY - multiSelectionComponent.current.getBoundingClientRect().top,
      moveX: undefined,
      moveY: undefined,
      scrollTop: scrollElementScrollTop,
      scrollLeft: leftScroll,
      stopped: false,
    });
    setMultiselectedVessels([]);

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    setDisableScrolling(true);
  };

  const getSelectionStyle = () => {
    if (
      !moveSelection.startX ||
      !moveSelection.startY ||
      !moveSelection.moveX ||
      !moveSelection.moveY ||
      moveSelection.stopped
    ) {
      return { display: 'none' };
    }

    return {
      position: 'sticky',
      top: Math.min(moveSelection.startY, moveSelection.moveY),
      left: Math.min(moveSelection.startX, moveSelection.moveX),
      width: Math.abs(moveSelection.startX - moveSelection.moveX),
      height: Math.abs(moveSelection.startY - moveSelection.moveY),
      border: '1px dashed #4990dd',
    };
  };

  return (
    <MultiSelectionContainer
      id="multi-moveSelection"
      ref={multiSelectionComponent}
      onMouseDown={onMouseDown}
      style={{ left: leftScroll }}
    >
      <SelectionBorders style={getSelectionStyle()} />
    </MultiSelectionContainer>
  );
};

export default MultiSelection;
