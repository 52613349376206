import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import styled from 'styled-components';
import { InvoicingContext } from '../../context/InvoicingContext';
import { UserContext } from '../../context/UserContext';
import Icon from '../ui/Icon';
import VesselNetTonnageSelector from './VesselNetTonnageSelector';

const Content = styled.div`
  margin-bottom: 1em;
  padding: 10px;
`;

const InnerContainer = styled.div`
  overflow-x: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowHeader = styled.div`
  margin-bottom: 1px;
  min-width: 20px;
  min-height: 20px;
`;

const CategoryContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  gap: 10px;
  align-items: center;
`;

const AddNewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  color: ${({ theme }) => theme.color.secondary};
  gap: 5px;
`;

const AddNew = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 40px;
`;

const Remove = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ theme }) => theme.color.warning};
  margin-top: 4px;
  width: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 150px;
  padding: 10px;
`;

const CategoryInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  gap: 4px;
`;

const ColorRectangle = styled.div`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 40px;
  gap: 15px;
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;

const EditPricingCategoriesModal = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const {
    isPricingCategoriesModalVisible,
    setIsPricingCategoriesModalVisible,
    categories,
    categoryData,
    selectedNetTonnage,
    postEditMatrix,
  } = useContext(InvoicingContext);

  const [modalValues, setModalValues] = useState({});
  const [updateRequest, setUpdateRequest] = useState({});

  useEffect(() => {
    const deepClone = JSON.parse(JSON.stringify(categoryData));
    setModalValues(deepClone);
  }, [categoryData]);

  const handleOk = () => {
    const newUpdateRequest = updateRequest;
    postEditMatrix(newUpdateRequest);
    setIsPricingCategoriesModalVisible(false);
    setUpdateRequest({});
  };

  const handleCancel = () => {
    setUpdateRequest({});
    const deepClone = JSON.parse(JSON.stringify(categoryData));
    setModalValues(deepClone);
    setIsPricingCategoriesModalVisible(false);
  };

  const days = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
  ];

  const hours = [
    { label: '00:00', value: 0 },
    { label: '01:00', value: 1 },
    { label: '02:00', value: 2 },
    { label: '03:00', value: 3 },
    { label: '04:00', value: 4 },
    { label: '05:00', value: 5 },
    { label: '06:00', value: 6 },
    { label: '07:00', value: 7 },
    { label: '08:00', value: 8 },
    { label: '09:00', value: 9 },
    { label: '10:00', value: 10 },
    { label: '11:00', value: 11 },
    { label: '12:00', value: 12 },
    { label: '13:00', value: 13 },
    { label: '14:00', value: 14 },
    { label: '15:00', value: 15 },
    { label: '16:00', value: 16 },
    { label: '17:00', value: 17 },
    { label: '18:00', value: 18 },
    { label: '19:00', value: 19 },
    { label: '20:00', value: 20 },
    { label: '21:00', value: 21 },
    { label: '22:00', value: 22 },
    { label: '23:00', value: 23 },
    { label: '24:00', value: 24 },
  ];

  const addRow = (rowIndex, category) => {
    const newValues = modalValues;
    const newIndex = newValues[selectedNetTonnage][category.id].periods.length;
    const newRow = {
      category: category.id,
      newRowId: `new-${rowIndex}-${newIndex}`,
      fromDay: 'Monday',
      toDay: 'Wednesday',
      startTime: 0,
      endTime: 3,
      price: 1,
    };

    newValues[selectedNetTonnage][category.id].periods.push(newRow);
    setModalValues({ ...newValues });
    setUpdateRequest(old => ({ ...old, post: { ...old.post, ...{ [`new-${rowIndex}-${newIndex}`]: { ...newRow } } } }));
  };

  const removeRow = (category, rowIndex, item) => {
    const newValues = modalValues;
    newValues[selectedNetTonnage][category.id].periods.splice(rowIndex, 1);
    setModalValues({ ...newValues });
    if (item.newRowId) {
      if (updateRequest.post[item.newRowId]) {
        delete updateRequest.post[item.newRowId];
        return;
      }
    }
    setUpdateRequest(old => ({ ...old, delete: { ...old.delete, ...{ [item.id]: { id: item.id } } } }));
  };

  const handlePriceChange = (item, category, rowIndex, value) => {
    const newValues = modalValues;
    newValues[selectedNetTonnage][category.id].periods[rowIndex]['price'] = parseFloat(value);
    setModalValues({ ...newValues });
    if (!item.id) {
      setUpdateRequest(old => ({ ...old, post: { ...old.post, ...{ [item.newRowId]: { ...item } } } }));
    } else {
      setUpdateRequest(old => ({ ...old, put: { ...old.put, ...{ [item.id]: { ...item } } } }));
    }
  };

  const handleSelectChange = (item, category, rowIndex, key, value) => {
    const newValues = modalValues;
    newValues[selectedNetTonnage][category.id].periods[rowIndex][key] = value;
    setModalValues({ ...newValues });
    if (!item.id) {
      setUpdateRequest(old => ({ ...old, post: { ...old.post, ...{ [item.newRowId]: { ...item } } } }));
    } else {
      setUpdateRequest(old => ({ ...old, put: { ...old.put, ...{ [item.id]: { ...item } } } }));
    }
  };

  return (
    <Modal
      title={t('Edit pricing categories')}
      open={isPricingCategoriesModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      okText={'Save'}
      okButtonProps={{ style: { marginRight: '40px' } }}
    >
      <InnerContainer>
        <SelectorContainer>
          <VesselNetTonnageSelector />
        </SelectorContainer>
        <Content>
          {modalValues[selectedNetTonnage] &&
            Object.values(categories).map((category, categoryIndex) => {
              const categoryValues = modalValues[selectedNetTonnage][category.id];
              return (
                <CategoryContainer key={categoryIndex}>
                  <InfoContainer>
                    {categoryIndex === 0 ? <RowHeader>{t('Categories')}</RowHeader> : null}
                    <CategoryInfo key={categoryIndex}>
                      <ColorRectangle color={category.color} />
                      {`${category.description} (${categoryValues.price}€)`}
                    </CategoryInfo>
                  </InfoContainer>
                  <Category>
                    <Rows>
                      {categoryValues.periods?.map((item, rowIndex) => {
                        const isFirstRow = categoryIndex === 0 && rowIndex === 0;
                        return (
                          <Row key={`${categoryValues.description}-${rowIndex}`}>
                            <Cell>
                              {isFirstRow ? <RowHeader>{t('From day')}</RowHeader> : null}
                              <Select
                                style={{ width: '125px' }}
                                value={item.fromDay}
                                options={days}
                                onChange={value => handleSelectChange(item, category, rowIndex, 'fromDay', value)}
                              />
                            </Cell>
                            <Cell>
                              {isFirstRow ? <RowHeader>{t('To day')}</RowHeader> : null}
                              <Select
                                style={{ width: '125px' }}
                                value={item.toDay}
                                options={days}
                                onChange={value => handleSelectChange(item, category, rowIndex, 'toDay', value)}
                              />
                            </Cell>
                            <Cell>
                              {isFirstRow ? <RowHeader>{t('Start time')}</RowHeader> : null}
                              <Select
                                style={{ width: '100px' }}
                                value={item.startTime}
                                options={hours.slice(0, -1)}
                                onChange={value => handleSelectChange(item, category, rowIndex, 'startTime', value)}
                              />
                            </Cell>
                            <Cell>
                              {isFirstRow ? <RowHeader>{t('End time')}</RowHeader> : null}
                              <Select
                                style={{ width: '100px' }}
                                value={item.endTime}
                                options={hours.slice(1, 25)}
                                onChange={value => handleSelectChange(item, category, rowIndex, 'endTime', value)}
                              />
                            </Cell>
                            <Cell>
                              {isFirstRow ? <RowHeader>{t('Price (€)')}</RowHeader> : null}
                              <StyledInputNumber
                                style={{ width: '100%', minWidth: '70px' }}
                                value={item.price}
                                min={1}
                                precision={2}
                                onChange={value => handlePriceChange(item, category, rowIndex, value)}
                              />
                            </Cell>
                            <Cell>
                              {isFirstRow ? <RowHeader /> : null}
                              {rowIndex !== 0 && categoryValues.periods.at(-1) ? (
                                <Remove onClick={() => removeRow(category, rowIndex, item)}>
                                  <Icon type={'trash'} />
                                </Remove>
                              ) : (
                                <div style={{ minWidth: '20px' }} />
                              )}
                            </Cell>
                          </Row>
                        );
                      })}
                      <AddNewContainer>
                        <AddNew onClick={() => addRow(categoryIndex, category)}>
                          <Icon type={'circle-plus'} />
                          {t('Add new')}
                        </AddNew>
                      </AddNewContainer>
                    </Rows>
                  </Category>
                </CategoryContainer>
              );
            })}
        </Content>
      </InnerContainer>
    </Modal>
  );
};

export default EditPricingCategoriesModal;
