import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../context/UserContext';
import { AlertContext } from '../context/AlertContext';
import { SideHelpContext } from '../context/SideHelpContext';

import PortName from './PortName';
import Profile from './Profile';
import SideMenu from './SideMenu';
import HeaderWeather from './weather/HeaderWeather';
import HeaderSeaLevel from './weather/HeaderSeaLevel';
import FilterContainer from './filter/FilterContainer';
import FilterTagList from './filter/FilterTagList';
import { isPortUnikie } from '../utils/utils';
import ServiceOrderTaskListButton from './ServiceOrderTaskListButton';
import AllNotifications from './AllNotifications';
import MobileMenu from './MobileMenu';
import { mobilePixelMaxWidthLimit, mobilePixelMinWidthLimit } from '../utils/constants';
import AlertBanner from './incidents/AlertBanner';
import IncidentSummaryButton from './IncidentSummaryButton';
import SideHelpWeb from './sideHelp/SideHelpWeb';
import Icon from './ui/Icon';
import SideHelpMobile from './sideHelp/SideHelpMobile';
import { logError } from './ui/errorLogging';
import { ErrorPlaceHolder } from './ui/ErrorPlaceHolder';

const Header = styled.header`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  z-index: 201;
  height: 64px;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  padding: 0 ${({ theme }) => theme.sizing.gap_medium};

  @media print {
    display: none;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: 50px;
    padding: 0 0.8rem;
  }
`;

const StartContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  align-items: center;
`;

const MobileEndContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  align-items: center;

  @media (min-width: ${mobilePixelMinWidthLimit}) {
    display: none;
  }
`;

const HideTinyMobile = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  align-items: center;

  @media (max-width: 500px) {
    display: none;
  }
`;

const EndContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  align-items: center;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const Content = styled.div`
  position: relative;
  margin-left: ${props => props.menuWidth};
  transition: width 0.3s;
  transition: margin-left 0.3s;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-left: 0px;
    width: 100%;
  }

  @media (min-width: ${mobilePixelMinWidthLimit}) {
    width: calc(100% - ${props => props.menuWidth} - ${props => (props.sideHelpOpen ? '400px' : '0px')});
  }
`;

const ScrollContent = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: ${props => (props.noTopBar ? '100%' : 'calc(100% - 48px)')};

  @media print {
    overflow-y: visible;
  }
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 64px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  width: 100%;
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 50px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const SideMenuCover = styled.div`
  height: calc(100vh - 64px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  z-index: 999;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    height: calc(100vh - 50px ${props => (props.alertsHeight ? `- ${props.alertsHeight}px` : '')});
  }
`;

const Weathers = styled.div`
  display: flex;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    display: none;
  }
`;

const HelpIcon = styled.div`
  width: 30px;
  margin-right: 10px;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    margin-right: 12px;
    margin-top: -4px;
    width: 24px;
  }
`;

const Layout = ({ children, filtering, scrollId }) => {
  const { user, modules, namespace, onlyFleet, expandedViewState } = useContext(UserContext);
  const { alertsHeight } = useContext(AlertContext);
  const { sideHelpOpen, setSideHelpOpen, setSideWikiProps } = useContext(SideHelpContext);
  const { t } = useTranslation(namespace);

  const [menuWidth, setMenuWidth] = useState('62px');

  const portUnikie = isPortUnikie(namespace);

  const bptManager = user.permissions.includes('manage_bpt');
  const pbpManager = user.permissions.includes('manage_pbp');

  useEffect(() => {
    setSideWikiProps(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let menuList = [
    {
      type: 'link',
      title: 'Activity',
      checks: !portUnikie && modules.activity_module === 'enabled' && user.permissions.includes('basic_user_action'),
      path: '/',
      icon: 'port',
    },
    {
      type: 'link',
      title: 'Activity List',
      checks:
        !portUnikie && modules.activity_list_module === 'enabled' && user.permissions.includes('basic_user_action'),
      path: '/activity-list',
      icon: 'activity-list',
    },
    {
      type: 'link',
      title: 'Map',
      checks: !portUnikie && modules.map_module === 'enabled' && user.permissions.includes('basic_user_action'),
      path: '/map',
      icon: 'map',
      id: 'map',
    },
    {
      type: 'folder',
      title: bptManager ? 'Berth Planning Tool' : 'Vessel Calendar',
      checks: !portUnikie && modules.berth_planning_module === 'enabled' && user.permissions.includes('view_bpt'),
      path: bptManager ? '/berth-planning-tool' : '/vessel-calendar',
      icon: 'berth-planning',
      id: 'bpt',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Timeline',
          checks: !portUnikie && modules.berth_planning_module === 'enabled' && user.permissions.includes('view_bpt'),
          path: bptManager ? '/berth-planning-tool' : '/vessel-calendar',
        },
        {
          type: 'link',
          title: 'Crane timeline',
          checks:
            !portUnikie &&
            modules.predictive_berth_planning_module === 'enabled' &&
            user.permissions.includes('view_crane_reservations'),
          path: '/crane-timeline',
        },
        {
          type: 'link',
          title: 'Cranes',
          checks:
            !portUnikie &&
            modules.berth_planning_module === 'enabled' &&
            modules.predictive_berth_planning_module === 'enabled' &&
            user.permissions.includes('view_crane_reservations'),
          path: '/cranes',
        },
        {
          type: 'link',
          title: 'Commodities',
          checks:
            !portUnikie &&
            modules.berth_planning_module === 'enabled' &&
            modules.predictive_berth_planning_module === 'enabled' &&
            pbpManager,
          path: '/commodities',
        },
        {
          type: 'link',
          title: 'Holidays',
          checks:
            !portUnikie &&
            modules.berth_planning_module === 'enabled' &&
            modules.predictive_berth_planning_module === 'enabled' &&
            user.permissions.includes('view_bpt'),
          path: '/holidays',
        },
        {
          type: 'link',
          title: 'Companies',
          checks:
            !portUnikie &&
            modules.berth_planning_module === 'enabled' &&
            modules.predictive_berth_planning_module === 'enabled' &&
            pbpManager,
          path: '/companies',
        },
      ],
    },
    {
      type: 'link',
      title: 'Notifications',
      checks: !onlyFleet && !portUnikie && user.permissions.includes('basic_user_action'),
      path: '/notifications',
      icon: 'notification',
    },
    {
      type: 'link',
      title: 'Logistics',
      checks: !portUnikie && modules.logistics_module === 'enabled' && user.permissions.includes('basic_user_action'),
      path: '/logistics',
      icon: 'logistics',
    },
    {
      type: 'link',
      title: 'Loading Master',
      checks:
        !portUnikie && modules.loading_master_module === 'enabled' && user.permissions.includes('view_loading_job'),
      path: '/loading-master-petroleum-jobs',
      icon: 'loading-master',
    },
    {
      type: 'folder',
      id: 'fleet',
      title: 'Fleet View',
      icon: 'fleet-view',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Timeline',
          checks:
            !portUnikie &&
            namespace !== 'neste' &&
            modules.fleet_module === 'enabled' &&
            user.permissions.includes('view_fleet'),
          path: '/fleet-view',
        },
        {
          type: 'link',
          title: 'Vessel Timeline',
          checks: !portUnikie && modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet'),
          path: '/fleet-vessel-view',
        },
        {
          type: 'link',
          title: 'Data',
          checks:
            !portUnikie &&
            namespace !== 'neste' &&
            modules.fleet_module === 'enabled' &&
            user.permissions.includes('view_fleet'),
          path: '/fleet-data-view',
        },
        {
          type: 'link',
          title: 'Map',
          checks:
            !portUnikie &&
            modules.fleet_module === 'enabled' &&
            user.permissions.includes('view_fleet') &&
            modules.map_module === 'enabled',
          path: '/map?type=fleet',
        },
        {
          type: 'link',
          title: 'Service Orders',
          checks: modules.fleet_module === 'enabled' && user.permissions.includes('view_fleet') && namespace === 'vts',
          path: '/fleet-service-orders',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Vessels',
      id: 'vessels',
      icon: 'ship',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Vessels',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('basic_user_action'),
          path: '/vessels',
        },
        {
          type: 'link',
          title: 'Port Call Tolerances',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('basic_user_action'),
          path: '/port-call-tolerances',
        },
        {
          type: 'link',
          title: 'Port Call Timesheets',
          checks:
            !onlyFleet &&
            !portUnikie &&
            modules.activity_module === 'enabled' &&
            user.permissions.includes('manage_port_call'),
          path: '/port-calls',
        },
        {
          type: 'link',
          title: 'Port Call Requests',
          checks:
            !onlyFleet &&
            !portUnikie &&
            modules.activity_module === 'enabled' &&
            user.permissions.includes('manage_port_call'),
          path: '/port-call-requests',
        },
        {
          type: 'link',
          title: 'Inbound Vessels',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('basic_user_action'),
          path: '/inbound-vessels',
        },
        {
          type: 'link',
          title: 'VIS Vessels',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('view_vis_information'),
          path: '/vis-vessels',
        },
        {
          type: 'link',
          title: 'Other Ports',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('manage_port'),
          path: '/ports',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Just-in-time',
      id: 'jit',
      icon: 'reservation',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Queue',
          checks:
            !portUnikie &&
            modules.queue_module === 'enabled' &&
            user.permissions.includes('view_queue_slot_reservation'),
          path: '/queue-admin/admin-dashboard',
        },
        {
          type: 'link',
          title: 'Pre-Advice Queue',
          checks:
            !portUnikie &&
            modules.queue_module === 'enabled' &&
            user.permissions.includes('manage_queue_slot_reservation'),
          path: '/queue-admin/pre-advice-queue',
        },
        {
          type: 'link',
          title: 'Queue Settings',
          checks: !portUnikie && modules.queue_module === 'enabled' && user.permissions.includes('manage_setting'),
          path: '/queue-admin/admin-queue-settings',
        },
        {
          type: 'link',
          title: 'Slot Requests',
          checks:
            !portUnikie &&
            modules.queue_module === 'enabled' &&
            user.permissions.includes('view_queue_slot_reservation'),
          path: '/queue-admin/admin-slot-reservations',
        },
        {
          type: 'link',
          title: 'Berths',
          checks: !portUnikie && modules.queue_module === 'enabled' && user.permissions.includes('view_berth'),
          path: '/berths',
        },
        {
          type: 'link',
          title: 'Berth Reservations',
          checks:
            !portUnikie && modules.queue_module === 'enabled' && user.permissions.includes('view_berth_reservation'),
          path: '/queue-admin/admin-berth-reservations',
        },
        {
          type: 'link',
          title: 'Admin Nominations',
          checks:
            !portUnikie && modules.queue_module === 'enabled' && user.permissions.includes('view_all_queue_nomination'),
          path: '/queue-admin/admin-nominations',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Users Management',
      icon: 'users',
      id: 'users',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Users',
          checks: user.permissions.findIndex(e => e.includes('manage_user')) !== -1,
          path: '/admin/users',
        },
        {
          type: 'link',
          title: 'Registration Codes',
          checks: user.permissions.includes('manage_registration_code'),
          path: '/admin/register-codes',
        },
        {
          type: 'link',
          title: 'Access Control',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('manage_permission'),
          path: '/admin/access-control',
        },
        {
          type: 'link',
          title: 'Groups',
          checks: user.permissions.findIndex(e => e.includes('manage_user')) !== -1,
          path: '/admin/groups',
        },
        {
          type: 'link',
          title: 'Organizations',
          checks: user.permissions.findIndex(e => e.includes('manage_user')) !== -1,
          path: '/admin/organizations',
        },
        {
          type: 'link',
          title: 'MFA Settings',
          checks: user.permissions.findIndex(e => e.includes('manage_all_user')) !== -1,
          path: '/admin/mfa-settings',
        },
      ],
    },
    {
      type: 'folder',
      title: 'API Keys',
      icon: 'key',
      id: 'api-keys',
      open: false,
      links: [
        {
          type: 'link',
          title: 'API Keys',
          checks: !onlyFleet && user.permissions.includes('manage_api_key'),
          path: '/admin/api-keys',
        },
        {
          type: 'link',
          title: 'API Key Priorities',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('manage_api_key'),
          path: '/admin/api-key-weights/timestamps',
          alternativePaths: ['/admin/api-key-weights/payloads'],
        },
      ],
    },
    {
      type: 'folder',
      title: 'Invoicing',
      icon: 'receipt',
      id: 'invoicing',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Invoicing and pricing',
          checks: !portUnikie && modules.invoicing_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing',
        },
        {
          type: 'link',
          title: 'Invoice per port call',
          checks: !portUnikie && modules.invoicing_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing/invoices',
        },
        {
          type: 'link',
          title: 'Pricing table matrix',
          checks: !portUnikie && modules.invoicing_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing/matrix',
        },
        {
          type: 'link',
          title: 'Customers',
          checks:
            !portUnikie && modules.invoicing_module === 'enabled' && user.permissions.includes('manage_invoicing'),
          path: '/invoicing/customers',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Invoicing',
      icon: 'receipt',
      id: 'invoicing2',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Invoices',
          checks:
            !portUnikie && modules.invoicing_v2_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing-invoices/per-portcall',
          alternativePaths: [
            '/invoicing-invoices/saved',
            '/invoicing-invoices/sent',
            '/invoicing-invoices/automated',
            '/invoicing-invoices/exceptions',
          ],
        },
        {
          type: 'link',
          title: 'Templates',
          checks:
            !portUnikie && modules.invoicing_v2_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing-templates',
        },
        {
          type: 'link',
          title: 'Products',
          checks:
            !portUnikie && modules.invoicing_v2_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing-products/products',
          alternativePaths: ['/invoicing-products/modifications'],
        },
        {
          type: 'link',
          title: 'Customers',
          checks:
            !portUnikie && modules.invoicing_v2_module === 'enabled' && user.permissions.includes('view_invoicing'),
          path: '/invoicing-customers',
        },
        {
          type: 'link',
          title: 'Holidays',
          checks:
            !portUnikie && modules.invoicing_v2_module === 'enabled' && user.permissions.includes('manage_invoicing'),
          path: '/invoicing-holidays',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Service orders',
      id: 'service-orders',
      icon: 'service-order',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Service orders',
          checks:
            !portUnikie &&
            namespace !== 'vts' &&
            modules.service_order_module === 'enabled' &&
            user.permissions.includes('admin_service_order'),
          path: '/service-orders',
        },
        {
          type: 'link',
          title: 'Services',
          checks:
            !portUnikie &&
            namespace !== 'vts' &&
            modules.service_order_module === 'enabled' &&
            user.permissions.includes('manage_service_order_service'),
          path: '/service-orders/services',
        },
        {
          type: 'link',
          title: 'Service providers',
          checks:
            !portUnikie &&
            namespace !== 'vts' &&
            modules.service_order_module === 'enabled' &&
            user.permissions.includes('manage_service_order_service'),
          path: '/service-orders/service-providers',
        },
        {
          type: 'link',
          title: 'Service types',
          checks:
            !portUnikie &&
            namespace !== 'vts' &&
            modules.service_order_module === 'enabled' &&
            user.permissions.includes('manage_service_order_service'),
          path: '/service-orders/service-types',
        },
      ],
    },
    {
      type: 'folder',
      title: 'Admin',
      icon: 'admin',
      id: 'admin',
      open: false,
      links: [
        {
          type: 'link',
          title: 'String Translations',
          checks: !onlyFleet && user.permissions.includes('manage_translation'),
          path: '/admin/string-translations',
        },
        {
          type: 'link',
          title: 'Geo Asset Tool',
          checks: portUnikie,
          path: '/admin/geo-asset-tool',
        },
        {
          type: 'link',
          title: 'Modules',
          checks: !onlyFleet && user.permissions.includes('manage_setting'),
          path: '/admin/modules',
        },
        {
          type: 'link',
          title: 'Alerts',
          checks: user.permissions.includes('manage_all_user'),
          path: '/admin/alerts',
        },
        {
          type: 'link',
          title: 'Forms Codes',
          checks: modules.forms_code_module === 'enabled' && user.permissions.includes('manage_forms_code'),
          path: '/admin/forms-codes',
        },
        {
          type: 'link',
          title: 'Port Stamp',
          checks: !onlyFleet && user.permissions.includes('view_port_binary_assets'),
          path: '/admin/port-stamp',
        },
        {
          type: 'link',
          title: 'Map Port Actors',
          checks:
            !onlyFleet &&
            !portUnikie &&
            modules.map_module === 'enabled' &&
            user.permissions.includes('manage_sea_chart_vessel'),
          path: '/admin/map-port-actors',
        },
        {
          type: 'link',
          title: 'Timestamp Filtering',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('manage_setting'),
          path: '/admin/timestamp-filtering',
        },
        {
          type: 'link',
          title: 'Emergency Shutdown',
          checks: !onlyFleet && !portUnikie && user.permissions.includes('control_emergency_shutdown'),
          path: '/admin/emergency-shutdown',
        },
      ],
    },
    {
      type: 'link',
      title: 'Data Export',
      checks:
        !onlyFleet &&
        !portUnikie &&
        modules &&
        modules.data_export_module === 'enabled' &&
        user.permissions.includes('export_data'),
      path: '/data-export',
      icon: 'data-export',
    },
    {
      type: 'folder',
      title: 'Statistics',
      id: 'statistics',
      path: '/statistics',
      icon: 'statistics',
      links: [
        {
          type: 'link',
          title: 'Port Statistics',
          checks:
            !portUnikie &&
            modules.port_statistics_module === 'enabled' &&
            user.permissions.includes('view_port_statistics'),
          path: '/statistics',
        },
        {
          type: 'link',
          title: 'Emission Statistics',
          checks:
            !portUnikie &&
            modules.emissions_summary_module === 'enabled' &&
            user.permissions.includes('view_emissions_summary'),
          path: '/emission-statistics',
        },
      ],
    },
    {
      type: 'link',
      title: 'Incidents and events',
      checks: modules.incident_module === 'enabled' && user.permissions.includes('view_incident'),
      path: '/incidents-and-events',
      icon: 'exclamation-triangle',
    },
    {
      type: 'link',
      title: 'Release notes',
      checks: true,
      path: '/release-notes',
      icon: 'status',
    },
    {
      type: 'folder',
      title: 'Waste',
      id: 'waste',
      icon: 'waste',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Waste list',
          checks: !portUnikie && modules.waste_module === 'enabled' && user.permissions.includes('view_waste'),
          path: '/waste',
        },
        {
          type: 'link',
          title: 'View document',
          checks:
            !portUnikie &&
            modules.waste_module === 'enabled' &&
            user.permissions.includes('view_waste') &&
            user.permissions.includes('view_documents'),
          path: '/waste-view-document',
        },
        {
          type: 'link',
          title: 'Validate document',
          checks:
            !portUnikie &&
            modules.waste_module === 'enabled' &&
            user.permissions.includes('view_waste') &&
            user.permissions.includes('validate_documents'),
          path: '/waste-validate-document',
        },
      ],
    },
    {
      type: 'folder',
      title: 'More',
      icon: 'info',
      id: 'more',
      open: false,
      links: [
        {
          type: 'link',
          title: 'Privacy Policy',
          checks: true,
          path: '/privacy-policy',
        },
        {
          type: 'link',
          title: 'Help',
          checks: !portUnikie && user.permissions.includes('basic_user_action'),
          path: '/help-page',
        },
        {
          type: 'link',
          title: 'Wiki',
          checks: modules.wiki_module === 'enabled',
          path: '/wiki',
        },
        {
          type: 'link',
          title: 'Wiki Links',
          checks: modules.wiki_module === 'enabled' && user.permissions.includes('manage_wiki'),
          path: '/wiki-links',
        },
      ],
    },
  ];

  if (onlyFleet) {
    const fleetIndex = menuList.findIndex(item => item.id === 'fleet');
    const mapIndex = menuList.findIndex(item => item.id === 'map');

    if (fleetIndex && mapIndex) {
      [menuList[fleetIndex], menuList[mapIndex]] = [menuList[mapIndex], menuList[fleetIndex]];
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [navStructure, setNavStructure] = useState(menuList);

  return (
    <>
      <Header>
        <StartContainer>
          <PortName />
          <Weathers>
            {modules.weather_module === 'enabled' && <HeaderWeather />}
            {modules.sea_level_module === 'enabled' && <HeaderSeaLevel />}
          </Weathers>
        </StartContainer>
        <EndContainer>
          <FilterContainer />
          {modules &&
            user &&
            modules.service_order_module === 'enabled' &&
            (user.permissions.includes('manage_service_order_ack_delivery') ||
              user.permissions.includes('manage_service_order')) && <ServiceOrderTaskListButton />}
          {modules && user && modules.incident_module === 'enabled' && user.permissions.includes('view_incident') && (
            <IncidentSummaryButton />
          )}
          <AllNotifications />
          {(document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
            <HelpIcon onClick={() => setSideHelpOpen(!sideHelpOpen)}>
              <Icon type="help-circle" color="white" fill="none" />
            </HelpIcon>
          )}
          <Profile user={user} />
        </EndContainer>
        <MobileEndContainer>
          <HideTinyMobile>
            {modules &&
              user &&
              modules.service_order_module === 'enabled' &&
              user.permissions.includes('manage_service_order_ack_delivery') && <ServiceOrderTaskListButton />}
            {modules && user && modules.incident_module === 'enabled' && user.permissions.includes('view_incident') && (
              <IncidentSummaryButton />
            )}
            <AllNotifications />
            {(document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
              <HelpIcon onClick={() => setSideHelpOpen(!sideHelpOpen)}>
                <Icon type="help-circle" color="white" fill="none" />
              </HelpIcon>
            )}
          </HideTinyMobile>
          <MobileMenu
            navStructure={navStructure}
            setNavStructure={setNavStructure}
            sideHelpOpen={sideHelpOpen}
            setSideHelpOpen={setSideHelpOpen}
          />
        </MobileEndContainer>
      </Header>
      <AlertBanner />
      <Container alertsHeight={alertsHeight} id="container">
        {expandedViewState < 3 && (
          <SideMenu
            alertsHeight={alertsHeight}
            menuWidth={expandedViewState === 3 ? '0px' : menuWidth}
            navStructure={navStructure}
            setNavStructure={setNavStructure}
            setMenuWidth={setMenuWidth}
          />
        )}
        {menuWidth === '16px' && expandedViewState < 3 && <SideMenuCover onClick={() => setMenuWidth('62px')} />}
        <Content menuWidth={expandedViewState === 3 ? '0px' : menuWidth} sideHelpOpen={sideHelpOpen}>
          {expandedViewState < 3 && <FilterTagList filtering={filtering} navStructure={navStructure} />}
          <ErrorBoundary FallbackComponent={() => ErrorPlaceHolder('medium', t)} onError={logError}>
            <ScrollContent id={scrollId} noTopBar={expandedViewState === 3}>
              {children}
            </ScrollContent>
          </ErrorBoundary>
        </Content>
        {(document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
          <SideHelpWeb />
        )}
        {(document.location.hostname === 'localhost' || document.location.hostname.includes('ft-testing.dev')) && (
          <SideHelpMobile />
        )}
      </Container>
    </>
  );
};

export default withRouter(Layout);
