import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import dayjs from 'dayjs';

import { UserContext } from '../../context/UserContext';
import useApi from '../../hooks/useApi';

import { Chart, registerables } from 'chart.js';
import Icon from '../ui/Icon';

import StatisticsBarMultiColumnCluster from './StatisticsBarMultiColumnCluster';
import PieWithLegendCluster from './PieWithLegendCluster';
import { EmissionsContext } from '../../context/EmissionsContext';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

Chart.register(...registerables);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  padding: 12px 12px 0 12px;
  margin-bottom: 28px;

  .ant-select-show-arrow {
    height: 24px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fbfbfb;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const MiniLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-right: 2px;
  width: 38px;
  min-width: 38px;
`;

const Graph = styled.div`
  display: inline-block;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin-left: 12px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
  margin-right: 8px;
  white-space: nowrap;
  margin-top: 1px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    text-wrap: wrap;
  }
`;

const Button = styled.div`
  margin: 1px 4px 0 0;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 4px 0 2px;
  color: ${props => props.disabled && '#e8e8e8'};
  cursor: ${props => props.disabled && 'default'};
  margin-bottom: 12px;

  svg {
    margin-top: -2px;
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#f3f3f3')};
  }
`;

const RotatedIcon = styled(Icon)`
  margin-left: -1px;

  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

const EndOfFirstRow = styled.div`
  display: flex;
  margin-top: 2px;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const FilterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const FilterContainer = styled.div`
  display: inline-block;
  flex-wrap: wrap;
  margin-left: 12px;
  max-width: 608px;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const FiltersHeader = styled.div`
  display: flex;
  margin-right: 12px;
  margin-bottom: 6px;
  justify-content: flex-end;
  cursor: pointer;
  color: ${props => (props.filtered ? props.theme.color.secondary : props.theme.color.grey_dark)};

  &:hover {
    text-decoration: underline;
  }
`;

const FilterHeaderText = styled.div`
  margin-left: 6px;
`;

const IconContainer = styled.div`
  line-height: 16px;
  height: 24px;
  width: 14px;
  margin-left: 6px;

  svg {
    margin-top: 4px;
  }
`;

export const COLORS = [
  '#A5D6A7',
  '#FFCBCB',
  '#A7C4F4',
  '#b9faf3',
  '#d47ed9',
  '#FBE6A2',
  '#D5B5EB',
  '#FFAB91',
  '#f57187',
  '#53f5d2',
];

const ClusterGraphs = ({ graphType, oneColumn, column1, ...props }) => {
  const buildQueryParams = () => {
    let time = universalTimePickerOn ? universalSelectedTime : selectedTime;
    if (dayjs(time).isAfter(dayjs())) {
      time = dayjs().toDate();
      if (universalTimePickerOn) {
        setUniversalSelectedTime(time);
      } else {
        setSelectedTime(time);
      }
    }

    const timeTypeToUse = universalTimePickerOn ? universalTimeType : timeType;
    let from = null;
    let to = null;
    if (timeTypeToUse === 'week') {
      from = dayjs(time).startOf('isoweek');
      to = dayjs(time).endOf('isoweek');
    } else if (timeTypeToUse === 'month') {
      from = dayjs(time)
        .startOf('month')
        .add(comparison ? -(comparison - 1) : 0, 'months');
      to = dayjs(time).endOf('month');
    } else if (timeTypeToUse === 'year') {
      from = dayjs(time)
        .startOf('year')
        .add(comparison ? -(comparison - 1) : 0, 'years');
      to = dayjs(time).endOf('year');
    }
    to.isAfter(dayjs()) && (to = dayjs());

    let newParams = {
      ...props.data_source.parameters,
      time_option: timeTypeToUse,
      group_by: groupBy,
      timezone: dayjs.tz.guess(),
      from: from.toISOString(),
      to: to.toISOString(),
    };

    for (let filter of Object.keys(filterValues)) {
      if (props.complex_filters.find(f => f.key === filter).type === 'multi_select') {
        if (filterValues[filter].length > 0) {
          for (let i = 0; i < filterValues[filter].length; i++) {
            if (i === 0) {
              newParams[filter] = filterValues[filter][i];
            } else {
              newParams[filter] = newParams[filter] + filterValues[filter][i];
            }

            if (i < filterValues[filter].length - 1) {
              newParams[filter] = newParams[filter] + '|';
            }
          }
        }
      } else {
        if (filterValues[filter]) {
          newParams[filter] = filterValues[filter];
        }
      }
    }
    return newParams;
  };

  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { universalTimePickerOn, universalTimeType, universalSelectedTime, setUniversalSelectedTime } = useContext(
    EmissionsContext
  );

  const timeOptions = props.time_options;
  const groupByOptions = props.group_by;

  const [timeType, setTimeType] = useState(timeOptions[0]);
  const [groupBy, setGroupBy] = useState(groupByOptions[0].key);
  const [comparison, setComparison] = useState(props.comparison?.allowed ? props.comparison.default_value : undefined);
  const [selectedTime, setSelectedTime] = useState(dayjs().toDate());

  const [filterValues, setFilterValues] = useState(
    props.complex_filters.reduce((o, item) => ({ ...o, [item.key]: item.type === 'multi_select' ? [] : undefined }), {})
  );
  const [startLoader, setStartLoader] = useState(true);
  const [neededParametersAdded, setNeededParametersAdded] = useState(false);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const { loading: loadingFilters, data: dataFilters, error: errorFilters } = useApi(
    'get',
    props.filter_values_source.url,
    props.filter_values_source.parameters
  );

  const { loading, data, error, fetchData } = useApi(
    'get',
    props.data_source.url,
    buildQueryParams(),
    undefined,
    props.complex_filters.findIndex(f => !f.optional) === -1
  );

  const dataObjectFilters = useMemo(() => (errorFilters || !dataFilters ? {} : dataFilters.data), [
    dataFilters,
    errorFilters,
  ]);
  const dataObject = error || !data ? {} : data.data;

  useEffect(() => {
    if (startLoader && universalTimePickerOn) {
      setStartLoader(false);
    }
  }, [startLoader, universalTimePickerOn]);

  useEffect(() => {
    if (!neededParametersAdded) {
      if (props.complex_filters.findIndex(f => !f.optional) > -1) {
        if (Object.keys(dataObjectFilters).length > 0) {
          let neededFilters = props.complex_filters.filter(f => !f.optional);
          let newFilters = { ...filterValues };
          for (let filter of neededFilters) {
            if (filter.default_value && dataObjectFilters[filter.key].findIndex(f => f === filter.default_value) > -1) {
              newFilters[filter.key] = filter.default_value;
            } else {
              newFilters[filter.key] = dataObjectFilters[filter.key][0];
            }
          }
          setFilterValues(newFilters);
          setNeededParametersAdded(true);
        }
      } else {
        setNeededParametersAdded(true);
      }
    }
  }, [dataObjectFilters, filterValues, neededParametersAdded, props.complex_filters]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let neededFilters = props.complex_filters.filter(f => !f.optional);
    if (neededFilters.length > 0) {
      let allowed = true;

      if (props.comparison?.allowed && props.comparison?.default_value) {
        if (!comparison) {
          allowed = false;
        }
      }

      if (allowed) {
        for (let filter of neededFilters) {
          if (!filterValues[filter.key]) {
            allowed = false;
            break;
          }
        }
      }

      if (allowed) {
        fetchData(false, buildQueryParams());
      }
    } else {
      fetchData(false, buildQueryParams());
    }
  }, [
    filterValues,
    timeType,
    groupBy,
    selectedTime,
    fetchData,
    props.complex_filters,
    comparison,
    universalTimePickerOn,
    universalTimeType,
    universalSelectedTime,
    props.comparison?.allowed,
    props.comparison?.default_value,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const moveBackwards = () => {
    if (universalTimePickerOn) {
      return;
    }

    setStartLoader(false);
    if (timeType === 'week') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'week')
        .toDate();
      setSelectedTime(newTime);
    } else if (timeType === 'month') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'month')
        .toDate();
      setSelectedTime(newTime);
    } else if (timeType === 'year') {
      const newTime = dayjs(selectedTime)
        .subtract(1, 'year')
        .toDate();
      setSelectedTime(newTime);
    }
  };

  const moveForwards = () => {
    if (universalTimePickerOn) {
      return;
    }

    setStartLoader(false);
    if (timeType === 'week') {
      const newTime = dayjs(selectedTime)
        .add(1, 'week')
        .toDate();
      setSelectedTime(newTime);
    } else if (timeType === 'month') {
      const newTime = dayjs(selectedTime)
        .add(1, 'month')
        .toDate();
      setSelectedTime(newTime);
    } else if (timeType === 'year') {
      const newTime = dayjs(selectedTime)
        .add(1, 'year')
        .toDate();
      setSelectedTime(newTime);
    }
  };

  const moveToToday = () => {
    if (universalTimePickerOn) {
      return;
    }

    setStartLoader(false);
    setSelectedTime(dayjs().toDate());
  };

  const usableTimeType = universalTimePickerOn ? universalTimeType : timeType;

  let comparisonOptions = [
    {
      value: 1,
      label:
        usableTimeType === 'year'
          ? '1 ' + t('year')
          : usableTimeType === 'month'
            ? '1 ' + t('month')
            : '1 ' + t('week'),
    },
    {
      value: 2,
      label:
        usableTimeType === 'year'
          ? '2 ' + t('years')
          : usableTimeType === 'month'
            ? '2 ' + t('months')
            : '2 ' + t('weeks'),
    },
    {
      value: 3,
      label:
        usableTimeType === 'year'
          ? '3 ' + t('years')
          : usableTimeType === 'month'
            ? '3 ' + t('months')
            : '3 ' + t('weeks'),
    },
    {
      value: 4,
      label:
        usableTimeType === 'year'
          ? '4 ' + t('years')
          : usableTimeType === 'month'
            ? '4 ' + t('months')
            : '4 ' + t('weeks'),
    },
    {
      value: 5,
      label:
        usableTimeType === 'year'
          ? '5 ' + t('years')
          : usableTimeType === 'month'
            ? '5 ' + t('months')
            : '5 ' + t('weeks'),
    },
  ];

  if (props.comparison?.allowed) {
    comparisonOptions = comparisonOptions.filter(o => o.value <= props.comparison.limit);
  }

  const optionalFilters = props.complex_filters ? props.complex_filters.filter(f => f.optional) : [];
  const mandatoryFilters = props.complex_filters ? props.complex_filters.filter(f => !f.optional) : [];

  const somethingFiltered =
    optionalFilters.findIndex(f => {
      const stateValue = filterValues[f.key];
      if (Array.isArray(stateValue)) {
        return stateValue.length > 0;
      } else {
        return !!stateValue;
      }
    }) > -1;

  const getGraph = () => {
    if (graphType === 'bar-multi-column-cluster') {
      return (
        <StatisticsBarMultiColumnCluster
          column1={column1}
          oneColumn={oneColumn}
          dataObject={dataObject}
          timeType={timeType}
          selectedTime={selectedTime}
          universalTimePickerOn={universalTimePickerOn}
          universalSelectedTime={universalSelectedTime}
          {...props}
        />
      );
    } else if (graphType === 'pie-with-legend-cluster') {
      return (
        <PieWithLegendCluster
          column1={column1}
          oneColumn={oneColumn}
          dataObject={dataObject}
          timeType={timeType}
          selectedTime={selectedTime}
          universalTimePickerOn={universalTimePickerOn}
          universalSelectedTime={universalSelectedTime}
          {...props}
        />
      );
    }
  };

  return (
    <Container>
      <Graph>
        <HeaderRow>
          <Header>{props.header}</Header>
          <EndOfFirstRow>
            <Button disabled={universalTimePickerOn} onClick={moveToToday} style={{ marginRight: '16px' }}>
              {t('Latest')}
            </Button>
            <Button disabled={universalTimePickerOn} onClick={moveBackwards}>
              <RotatedIcon type="chevron-right-2" />
            </Button>
            <Button disabled={universalTimePickerOn} onClick={moveForwards} style={{ marginRight: '12px' }}>
              <Icon type="chevron-right-2" />
            </Button>
            {(loading || loadingFilters) && !startLoader ? (
              <MiniLoader>
                <Spin size="small" />
              </MiniLoader>
            ) : (
              <MiniLoader />
            )}
            <Select
              disabled={universalTimePickerOn}
              value={timeType}
              style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
              onChange={e => {
                setStartLoader(false);
                setTimeType(e);
              }}
              size="small"
              options={timeOptions.map(o => {
                return {
                  value: o,
                  label: t(o.charAt(0).toUpperCase() + o.substring(1)),
                };
              })}
            />
            {props.comparison?.allowed && (
              <Select
                value={comparison}
                style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
                onChange={e => {
                  setStartLoader(false);
                  setComparison(e);
                }}
                placeholder={t('Comparison')}
                size="small"
                options={comparisonOptions}
              />
            )}
            <Select
              value={groupBy}
              style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
              onChange={e => {
                setStartLoader(false);
                setGroupBy(e);
              }}
              size="small"
              options={groupByOptions.map(o => {
                return {
                  value: o.key,
                  label: o.label,
                };
              })}
            />
            {mandatoryFilters.map((filter, i) => (
              <Select
                key={i}
                mode={filter.type === 'multi_select' ? 'multiple' : 'default'}
                maxTagCount={1}
                maxTagTextLength={6}
                maxTagPlaceholder="+"
                value={filterValues[filter.key]}
                style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
                onChange={e => {
                  setStartLoader(false);
                  setFilterValues(filters => {
                    const newFilters = { ...filters };
                    newFilters[filter.key] = e;

                    return newFilters;
                  });
                }}
                placeholder={filter.label}
                size="small"
                options={
                  dataObjectFilters[filter.key]
                    ? dataObjectFilters[filter.key].map(f => {
                      return {
                        value: f,
                        label: f,
                      };
                    })
                    : []
                }
              />
            ))}
          </EndOfFirstRow>
        </HeaderRow>

        {optionalFilters.length > 0 && (
          <FilterRow>
            <FilterContainer>
              <FiltersHeader onClick={() => setFiltersOpen(!filtersOpen)} filtered={somethingFiltered}>
                {!filtersOpen ? (
                  <IconContainer>
                    <Icon type="chevron-down" />
                  </IconContainer>
                ) : (
                  <IconContainer>
                    <Icon type="chevron-up" />
                  </IconContainer>
                )}
                <FilterHeaderText>{t('Filters')}</FilterHeaderText>
              </FiltersHeader>
              {filtersOpen && (
                <Filters>
                  {optionalFilters.map((filter, i) => (
                    <Select
                      key={i}
                      mode={filter.type === 'multi_select' ? 'multiple' : 'default'}
                      maxTagCount={1}
                      maxTagTextLength={6}
                      maxTagPlaceholder="+"
                      value={filterValues[filter.key]}
                      style={{ width: 140, marginRight: '12px', marginBottom: '12px' }}
                      onChange={e => {
                        setStartLoader(false);
                        setFilterValues(filters => {
                          const newFilters = { ...filters };
                          newFilters[filter.key] = e;

                          return newFilters;
                        });
                      }}
                      placeholder={filter.label}
                      size="small"
                      options={
                        dataObjectFilters[filter.key]
                          ? dataObjectFilters[filter.key].map(f => {
                            return {
                              value: f,
                              label: f,
                            };
                          })
                          : []
                      }
                    />
                  ))}
                </Filters>
              )}
            </FilterContainer>
          </FilterRow>
        )}

        {startLoader && (loading || loadingFilters) ? (
          <Loader>
            <Spin size="medium" />
          </Loader>
        ) : (
          getGraph()
        )}
      </Graph>
    </Container>
  );
};
export default ClusterGraphs;
