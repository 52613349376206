import React, { useState, useReducer, useEffect, createContext, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/browser';
import jwt_decode from 'jwt-decode';
import timezone from 'dayjs/plugin/timezone';

import { API_URL, defaultPortList } from '../utils/constants';
import { createSocket } from '../utils/socket';

dayjs.extend(timezone);

import {
  verifySession,
  setUser,
  setNS,
  setAuthView,
  setLogout,
  handleLoginLogout,
  refreshSessionState,
} from '../utils/actions';
import { sessionReducer } from '../utils/reducers';
import { setSentryUser } from '../utils/utils';

import App from 'antd/es/app';

import useSocket, { authenticate, subscribeSocketChannel } from '../hooks/useSocket';
import { throttle } from 'throttle-debounce';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const initSession = {
    currentAuthView: {
      view: 'LOGIN',
      params: null,
    },
    portName: '',
    namespace: undefined,
    namespaces: [],
    sessionId: null,
    verifyingSession: false,
    refreshingSession: false,
    handlingLoginLogout: false,
    user: undefined,
    modules: undefined,
    session: undefined,
    rtaPointCoordinates: '',
    mapDefaultCoordinates: undefined,
    mapDefaultZoom: undefined,
    jwt: undefined,
    activeAlerts: [],
  };

  const { message } = App.useApp();

  const [session, dispatch] = useReducer(sessionReducer, initSession);
  const [sessionNotCommon, setSessionNotCommon] = useState(false);

  const [alert, setAlert] = useState(null);

  const [maintenanceModeSusbscribed, setMaintenanceModeSubscribed] = useState(false);
  const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState(null);

  const [registerLoader, showRegisterLoader] = useState(false);
  const [loginLoader, showLoginLoader] = useState(false);

  const [namespaceFromURL, setNamespaceFromURL] = useState(undefined);
  const [namespaceFromURLChecked, setNamespaceFromURLChecked] = useState(false);

  const [socketCreated, setSocketCreated] = useState(false);
  const [notAuthenticatedSocket, setNotAuthenticatedSocket] = useState(undefined);
  const [socketAuthenticated, setSocketAuthenticated] = useState(false);
  const [socket, setSocket] = useState(undefined);

  const [onlyFleet, setOnlyFleet] = useState(undefined);

  const [expandedViewState, setExpandedViewState] = useState(1);

  const [showableVesselOnMap, setShowableVesselOnMap] = useState(undefined);

  const expiresTs = useRef(Math.round(new Date().getTime() / 1000) + 1440);

  const { t } = useTranslation(session.namespace);

  const isIE = !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;

  const logoutStateInits = useCallback(() => {
    document.title = 'Port Activity App';

    localStorage.removeItem('sessionId');
    localStorage.removeItem('jwt');
    localStorage.removeItem('saveMissingTranslations');
    localStorage.removeItem('socketcluster.authToken');

    setAlert(null);
    setMaintenanceModeEnabled(null);
    dispatch(setLogout());
    expiresTs.current = null;
  }, [setAlert, setMaintenanceModeEnabled]);

  const setMaintenanceModeState = useCallback(
    enabled => {
      setMaintenanceModeEnabled(enabled);
      if (window.location.pathname !== '/' && window.location.pathname !== '') {
        window.history.pushState({}, null, '/');
      }
    },
    [setMaintenanceModeEnabled]
  );

  const getOnlyFleet = modules => {
    return modules.fleet_module === 'enabled' && modules.activity_module !== 'enabled';
  };

  const apiCall = useCallback(
    async (method, path, data, tests, responseType, namespaceToHeader) => {
      if (path.match(/^\//)) {
        throw new Error('Paths must not begin with slash "/"');
      }
      const { sessionIdForTest, namespaceForTest } = tests || {};
      const url = `${API_URL}/${path}`;
      const { sessionId, ttl, namespace, jwt } = session;
      let headers = {};
      headers['Namespace'] = namespaceForTest ? namespaceForTest : namespaceToHeader ? namespaceToHeader : namespace;
      if (sessionIdForTest || sessionId) {
        if (path !== 'login' && path !== 'logout' && path !== 'session') {
          headers['Authorization'] = 'JWT ' + jwt;
        } else {
          headers['Authorization'] = 'Bearer ' + (sessionIdForTest ? sessionIdForTest : sessionId);
        }
        headers['ClientTimeZone'] = dayjs.tz.guess();
      }
      let queryString = '';
      if (method === 'get' && data) {
        const keys = Object.keys(data);
        if (keys.length > 0) {
          queryString = '?';
          keys.forEach(k => {
            if (Array.isArray(data[k])) {
              queryString += data[k].map((value, index) => `${k}[${index}]=${encodeURIComponent(value)}&`).join('');
            } else {
              queryString = `${queryString}${k}=${encodeURIComponent(data[k])}&`;
            }
          });
        }
      }

      try {
        const result = await axios({
          method,
          url: url + queryString,
          data,
          headers,
          ...(responseType ? { responseType: responseType } : {}),
        });
        if (headers['Authorization'] && path !== 'login' && path !== 'logout') {
          // Note: Php session time to live is 1440 seconds = 24 minutes.
          //       We update expiresAt manually on client since we know that
          //       every api call extends session time to live.
          expiresTs.current = Math.round(new Date().getTime() / 1000) + parseInt(ttl) - 10;
        }
        return result;
      } catch (error) {
        if (path === 'register') {
          showRegisterLoader(false);
        }
        if (path === 'login') {
          showLoginLoader(false);
        }
        data = [];
        // note: 400 status codes and above are thrown as errors
        // note: 440 comes when session has expired
        if (error.message.match(/Request failed with status code 440/)) {
          logoutStateInits();
        } else {
          const responseErrorMessage = error.response?.data && error.response.data.error;
          if (responseErrorMessage && error.response.data.error.match(/Service in maintenance mode/)) {
            setMaintenanceModeState(true);
            return [];
          } else {
            message.error(responseErrorMessage || t('Something unexpected happened'), 4);
            Sentry.captureException(error);
            throw error;
          }
        }
      }
    },
    [session, logoutStateInits, setMaintenanceModeState, message, t]
  );

  const setSession = useCallback(
    async (sessionId, silent = false) => {
      if (sessionId && session.namespace !== 'common') {
        if (!silent) {
          dispatch(verifySession(true));
        }
        dispatch(refreshSessionState(true));
        try {
          const result = await apiCall(
            'get',
            'session',
            {},
            { sessionIdForTest: sessionId, namespaceForTest: session.namespace || 'common' }
          );
          const {
            data: {
              user,
              session: { expires_ts: expiresAt, ttl },
              modules,
              rta_point_coordinates: rtaPointCoordinates,
              map_default_coordinates: mapDefaultCoordinates,
              map_default_zoom: mapDefaultZoom,
              jwt,
              userNamespaces: namespaces,
              active_alerts: activeAlerts,
            },
          } = result;
          setOnlyFleet(getOnlyFleet(modules));
          dispatch(
            setUser({
              id: sessionId,
              user,
              expiresAt,
              ttl,
              modules,
              ns: session.namespace,
              namespaces,
              verify: false,
              refreshing: false,
              rtaPointCoordinates,
              mapDefaultCoordinates,
              mapDefaultZoom,
              jwt,
              activeAlerts,
            })
          );
          setSentryUser(sessionId, modules, session, user);
          expiresTs.current = expiresAt;

          if (!sessionNotCommon && session.namespace) {
            setSessionNotCommon(true);
          }
        } catch (error) {
          dispatch(refreshSessionState(false));
          dispatch(verifySession(false));
        }
      }
    },
    [apiCall, session, sessionNotCommon]
  );

  useEffect(() => {
    if (
      !socket &&
      !socketCreated &&
      session.namespaces &&
      session.namespaces.length &&
      session.namespace &&
      session.namespaces.map(s => s.namespace).includes(session.namespace)
    ) {
      setSocketCreated(true);
      let createdSocket = createSocket(session.namespace);
      setNotAuthenticatedSocket(createdSocket);

      const sessionId = localStorage.getItem('sessionId');
      setSession(sessionId);
    }
  }, [socket, session.namespace, session.namespaces, socketCreated, setSession]);

  useEffect(() => {
    async function authenticateSocket() {
      let authenticated = await authenticate(notAuthenticatedSocket, session.jwt);

      if (authenticated) {
        setSocketAuthenticated(true);
        setSocket(notAuthenticatedSocket);
      }
    }

    if (notAuthenticatedSocket && !socketAuthenticated && sessionNotCommon) {
      authenticateSocket();
    }
  }, [notAuthenticatedSocket, session.jwt, sessionNotCommon, setSocketAuthenticated, socket, socketAuthenticated]);

  useEffect(() => {
    if (namespaceFromURL && session.namespaces && session.namespaces.length && !namespaceFromURLChecked) {
      setNamespaceFromURLChecked(true);
      if (namespaceFromURL === 'common') {
        setNamespace(session.namespaces[0].namespace);
      } else {
        setNamespace(namespaceFromURL);
      }
    }
  }, [namespaceFromURL, namespaceFromURLChecked, session]);

  const initLocalStorage = (sessionId, user) => {
    localStorage.setItem('sessionId', sessionId);
    if (user?.permissions && user.permissions.includes('manage_translation')) {
      localStorage.setItem('saveMissingTranslations', true);
    }
  };

  const apiCallWithoutNamespace = useCallback(
    async (method, path, data) => {
      if (path.match(/^\//)) {
        throw new Error('Paths must not begin with slash "/"');
      }
      const url = `${API_URL}/${path}`;
      const { ttl } = session;
      let headers = {};
      headers['Namespace'] = undefined;
      headers['ClientTimeZone'] = dayjs.tz.guess();

      let queryString = '';
      if (method === 'get' && data) {
        const keys = Object.keys(data);
        if (keys.length > 0) {
          queryString = '?';
          keys.forEach(k => {
            if (Array.isArray(data[k])) {
              queryString += data[k].map((value, index) => `${k}[${index}]=${encodeURIComponent(value)}&`).join('');
            } else {
              queryString = `${queryString}${k}=${encodeURIComponent(data[k])}&`;
            }
          });
        }
      }
      try {
        const result = await axios({
          method,
          url: url + queryString,
          data,
          headers,
        });
        if (headers['Authorization'] && path !== 'login' && path !== 'logout') {
          // Note: Php session time to live is 1440 seconds = 24 minutes.
          //       We update expiresAt manually on client since we know that
          //       every api call extends session time to live.
          expiresTs.current = Math.round(new Date().getTime() / 1000) + parseInt(ttl) - 10;
        }
        return result;
      } catch (error) {
        data = [];
        // note: 400 status codes and above are thrown as errors
        // note: 440 comes when session has expired
        if (error.message.match(/Request failed with status code 440/)) {
          logoutStateInits();
        } else {
          const responseErrorMessage = error?.response?.data?.error;
          if (responseErrorMessage && error.response.data.error.match(/Service in maintenance mode/)) {
            setMaintenanceModeState(true);
            return [];
          } else {
            message.error(responseErrorMessage || t('Something unexpected happened'), 4);
            Sentry.captureException(error);
            throw error;
          }
        }
      }
    },
    [session, logoutStateInits, setMaintenanceModeState, message, t]
  );

  const codeLogin = useCallback(
    async (username, password, code) => {
      setAlert(null);
      try {
        showLoginLoader(true);
        const result = await apiCall('post', 'code-login', {
          email: username,
          password: password,
          code: code,
        });
        if (result?.status === 200) {
          dispatch(handleLoginLogout(true));
          if (result.data.session_id) {
            const {
              data: {
                session_id: sessionId,
                user,
                session: { expires_ts: expiresAt, ttl },
                modules,
                rta_point_coordinates: rtaPointCoordinates,
                map_default_coordinates: mapDefaultCoordinates,
                map_default_zoom: mapDefaultZoom,
                userNamespaces,
                jwt,
                active_alerts: activeAlerts,
              },
            } = result;
            let firstNamespace = null;
            if (userNamespaces) {
              firstNamespace = userNamespaces[0].namespace;
            }
            firstNamespace = firstNamespace ? firstNamespace : session.namespace;
            const namespaces = userNamespaces ? userNamespaces : session.userNamespaces;
            setOnlyFleet(getOnlyFleet(modules));
            initLocalStorage(sessionId, user);
            dispatch(
              setUser({
                id: sessionId,
                user,
                expiresAt,
                ttl,
                modules,
                ns: firstNamespace,
                namespaces: namespaces,
                verify: false,
                refreshing: false,
                rtaPointCoordinates,
                mapDefaultCoordinates,
                mapDefaultZoom,
                jwt,
                activeAlerts,
              })
            );
            showLoginLoader(false);
            setSentryUser(sessionId, modules, session, user);
            expiresTs.current = expiresAt;
            window.location.reload();
            return true;
          }
        }
        setAlert({
          type: 'error',
          message: t('Login error'),
          description: result.data.message,
        });
      } catch (error) {
        showLoginLoader(false);
        return false;
      }
      return false;
    },
    [apiCall, setAlert, session, t]
  );

  const setCurrentAuthView = useCallback(
    (view, params = null) => {
      if ((alert && alert.type === 'error') || view !== 'LOGIN') {
        setAlert(null);
      }
      dispatch(setAuthView(view, params));
    },
    [alert]
  );

  const login = useCallback(
    async (username, password) => {
      setAlert(null);
      try {
        showLoginLoader(true);
        const result = await apiCall('post', 'login', {
          email: username,
          password: password,
        });
        if (result?.status === 200) {
          if (result.data['action-required'] === 'email-code') {
            setCurrentAuthView('EMAIL_CODE_LOGIN', { username, password });
            showLoginLoader(false);
            return true;
          }

          dispatch(handleLoginLogout(true));
          if (result.data.session_id) {
            const {
              data: {
                session_id: sessionId,
                user,
                session: { expires_ts: expiresAt, ttl },
                modules,
                rta_point_coordinates: rtaPointCoordinates,
                map_default_coordinates: mapDefaultCoordinates,
                map_default_zoom: mapDefaultZoom,
                userNamespaces,
                jwt,
                active_alerts: activeAlerts,
              },
            } = result;
            let firstNamespace = null;
            if (userNamespaces) {
              firstNamespace = userNamespaces[0].namespace;
            }
            firstNamespace = firstNamespace ? firstNamespace : session.namespace;
            const namespaces = userNamespaces ? userNamespaces : session.userNamespaces;
            setOnlyFleet(getOnlyFleet(modules));
            initLocalStorage(sessionId, user);
            dispatch(
              setUser({
                id: sessionId,
                user,
                expiresAt,
                ttl,
                modules,
                ns: firstNamespace,
                namespaces: namespaces,
                verify: false,
                refreshing: false,
                rtaPointCoordinates,
                mapDefaultCoordinates,
                mapDefaultZoom,
                jwt,
                activeAlerts,
              })
            );
            showLoginLoader(false);
            setSentryUser(sessionId, modules, session, user);
            expiresTs.current = expiresAt;
            window.location.reload();
            return true;
          }
        }
        setAlert({
          type: 'error',
          message: t('Login error'),
          description: result.data.message,
        });
      } catch (error) {
        return false;
      }
      return false;
    },
    [apiCall, t, setCurrentAuthView, session]
  );

  const logout = useCallback(
    async skipApiCall => {
      // TODO: error handling
      !skipApiCall && apiCall('post', 'logout', {});
      logoutStateInits();
      dispatch(handleLoginLogout(true));
      window.history.pushState({}, null, '/');
      window.location.reload();
    },
    [apiCall, logoutStateInits]
  );

  const register = async (firstName, lastName, code, email, password) => {
    let result = null;
    if (code !== '') {
      result = await apiCall('post', 'register', {
        first_name: firstName,
        last_name: lastName,
        code: code,
        email: email,
        password: password,
      });
    } else {
      result = await apiCall('post', 'codeless-register', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      });
    }
    if (result?.status === 200) {
      if (result.data['action-required'] === 'email-code') {
        setCurrentAuthView('EMAIL_CODE_LOGIN', { username: email, password });
        showRegisterLoader(false);
        return result.data;
      }

      const {
        data: {
          session_id: sessionId,
          user,
          session: { expires_ts: expiresAt, ttl },
          modules,
          rta_point_coordinates: rtaPointCoordinates,
          map_default_coordinates: mapDefaultCoordinates,
          map_default_zoom: mapDefaultZoom,
          userNamespaces: namespaces,
          jwt,
          active_alerts: activeAlerts,
        },
      } = result;
      const priorityNamespace = result.data.priority_namespace ? result.data.priority_namespace : session.namespace;
      if (sessionId) {
        initLocalStorage(sessionId, user);
        setOnlyFleet(getOnlyFleet(modules));
        dispatch(
          setUser({
            id: sessionId,
            user,
            expiresAt,
            ttl,
            modules,
            namespaces,
            ns: priorityNamespace,
            verify: false,
            refreshing: false,
            rtaPointCoordinates,
            mapDefaultCoordinates,
            mapDefaultZoom,
            jwt,
            activeAlerts,
          })
        );
        setSentryUser(sessionId, modules, session, user);
        expiresTs.current = expiresAt;
        window.location.reload();
        return {
          success: true,
        };
      }
    }
    setAlert({
      type: 'error',
      message: t('Register error'),
      description: result.data.error,
    });
    return null;
  };

  const requestPasswordReset = async (email, namespace) => {
    const result = await apiCall('post', 'request-password-reset', {
      email: email,
      port: namespace,
    });
    if (result?.status === 200) {
      // TODO: show success every time to prevent email phishing
      return true;
    }
    setAlert({
      type: 'error',
      message: t('Password reset error'),
      description: result.data.error,
    });
    return false;
  };

  const resetPassword = async (password, token) => {
    const result = await apiCall('post', 'reset-password', {
      password: password,
      token: token,
    });
    if (result?.status === 200) {
      // TODO: show success every time to prevent email phishing
      return true;
    }
    setAlert({
      type: 'error',
      message: t('Password reset error'),
      description: result.data.error,
    });
    return false;
  };

  const setNamespace = async ns => {
    await dispatch(setNS(ns));
  };

  const handleMaintenanceModeChanged = status => {
    setMaintenanceModeState(status);
  };

  const fetchMaintenanceModeStateIfNotSet = useCallback(async () => {
    if (maintenanceModeEnabled === null && session?.user && session?.namespace !== undefined) {
      const { data } = await apiCall('get', 'maintenance-mode-status');
      setMaintenanceModeEnabled(data);
    }
  }, [session, maintenanceModeEnabled, apiCall, setMaintenanceModeEnabled]);

  if (!maintenanceModeSusbscribed && notAuthenticatedSocket) {
    setMaintenanceModeSubscribed(true);
    subscribeSocketChannel(notAuthenticatedSocket, 'maintenance-mode-changed', handleMaintenanceModeChanged);
  }

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    setSession(sessionId);
    // TODO: fixme
    // eslint-disable-next-line
  }, []);

  const sessionObserver = useCallback(() => {
    if (session.sessionId && expiresTs.current && expiresTs.current * 1000 - new Date().getTime() < 1000) {
      // session expiring (soon), do logout so no hanging UI without permission there
      logout(false); // still do logout call to make sure session is expired
    } else if (session.jwt) {
      fetchMaintenanceModeStateIfNotSet();
      // Refresh session if JWT token is expiring
      if (session.jwt) {
        try {
          const { exp: jwtExp } = jwt_decode(session.jwt, { header: true });
          if (new Date().getTime() >= (jwtExp - 300) * 1000) {
            const sessionId = localStorage.getItem('sessionId');
            setSession(sessionId);
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    }
  }, [logout, session, fetchMaintenanceModeStateIfNotSet, setSession]);

  useEffect(() => {
    const id = setInterval(sessionObserver, 10 * 1000);
    return () => clearInterval(id);
  }, [sessionObserver]);

  const useUserSocket = (channel, callback) => {
    useSocket(socket, channel, callback, session.jwt);
  };

  const portObject = defaultPortList.find(port => port.value === session.namespace);

  // Nav scrollbar check so that the side menu doesn't take too much space
  const [navScrollbarWidth, setNavScrollbarWidth] = useState(undefined);

  if (!(navScrollbarWidth && navScrollbarWidth > 0) && !navigator.userAgent.includes('Firefox')) {
    const component = document.querySelector('#navigation-container');

    if (component) {
      const width = component.offsetWidth - component.clientWidth;

      if (navScrollbarWidth === undefined || (navScrollbarWidth === 0 && width > 0)) {
        setNavScrollbarWidth(width);
      }
    }
  }

  const resize = useCallback(() => {
    const component = document.querySelector('#navigation-container');
    if (component && !navigator.userAgent.includes('Firefox')) {
      const width = component.offsetWidth - component.clientWidth;
      setNavScrollbarWidth(width);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  const refreshSession = useCallback(async () => {
    const sessionId = localStorage.getItem('sessionId');
    await setSession(sessionId, true);
  }, [setSession]);

  const handlerefreshSession = useMemo(
    () =>
      throttle(1500, false, () => {
        // spread out request per client
        setTimeout(() => {
          refreshSession();
        }, Math.floor(Math.random() * 5000));
      }),
    [refreshSession]
  );

  useUserSocket('session-changed', handlerefreshSession);
  useUserSocket(`session-changed-${session.user?.id}`, handlerefreshSession);

  return (
    <UserContext.Provider
      value={{
        currentAuthView: session.currentAuthView,
        portName: portObject ? portObject.label : session.namespace,
        namespace: session.namespace,
        namespaces: session.namespaces,
        sessionId: session.sessionId,
        verifyingSession: session.verifyingSession,
        refreshingSession: session.refreshingSession,
        handlingLoginLogout: session.handlingLoginLogout,
        user: session.user,
        modules: session.modules,
        rtaPointCoordinates: session.rtaPointCoordinates,
        mapDefaultCoordinates: session.mapDefaultCoordinates,
        mapDefaultZoom: session.mapDefaultZoom,
        codeLogin: codeLogin,
        login: login,
        logout: logout,
        apiCall: apiCall,
        apiCallWithoutNamespace,
        register: register,
        requestPasswordReset: requestPasswordReset,
        resetPassword: resetPassword,
        setNamespace: setNamespace,
        setCurrentAuthView: setCurrentAuthView,
        maintenanceModeEnabled: maintenanceModeEnabled,
        setMaintenanceModeState: setMaintenanceModeState,
        alert: alert,
        setAlert: setAlert,
        isIE: isIE,
        isEdge: isEdge,
        jwt: session.jwt,
        useUserSocket: useUserSocket,
        activeAlerts: session.activeAlerts,
        registerLoader,
        showRegisterLoader,
        loginLoader,
        showLoginLoader,
        namespaceFromURL,
        setNamespaceFromURL,
        namespaceFromURLChecked,
        navScrollbarWidth,
        onlyFleet,
        expandedViewState,
        setExpandedViewState,
        showableVesselOnMap,
        setShowableVesselOnMap,
        refreshSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
