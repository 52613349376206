import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { UserContext } from '../../context/UserContext';
import { WeatherContext } from '../../context/WeatherContext';

import WeatherIcon from '../ui/WeatherIcon';
import WeatherIconCombined from '../ui/WeatherIconCombined';
import WeatherText from '../ui/WeatherText';
import { getWindType } from './weatherUtils';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const Wrapper = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  box-shadow: ${({ theme }) => theme.fx.box_shadow};
  border-radius: ${({ theme }) => theme.style.border_radius};
  border: 1px solid transparent;
  width: 44%;
  margin: 5px 10px;

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    width: 100%;
    margin: 5px 0px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 18px;
`;

const DateContainer = styled.div`
  font-size: 14px;
`;

const Time = styled.div`
  font-size: 14px;
`;

const CardDetails = styled.div`
  display: flex;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const WeatherDetails = styled.div`
  width: 33.33%;

  @media (max-width: 440px) {
    width: calc((100% - 54px) / 2);
  }
`;

const Row = styled.div`
  display: flex;
  padding: 4px 0;

  svg {
    margin-left: 10px;
    width: 18px;
    margin-top: -3px;
  }
`;

const RowInfo = styled.div`
  padding-left: 25px;
  color: black;
  font-weight: 600;
`;

const Temperature = styled.div`
  font-size: 22px;
  text-align: center;

  @media (max-width: 440px) {
    font-size: 18px;
    max-width: 90px;
  }
`;

const TempDegree = styled.div`
  font-size: 40px;
  color: ${({ theme }) => theme.color.secondary};
  text-align: center;

  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

const WeatherSymbol = styled.div`
  width: 33.33%;
  display: block;
  text-align: center;
  svg {
    width: 72px;
    height: 72px;
    margin-left: -7px;
  }

  @media (max-width: 440px) {
    width: 54px;

    svg {
      width: 58px;
    }
  }
`;

const StyledWeatherText = styled(WeatherText)`
  font-size: ${props => (props.length > 20 ? '16px' : '22px')};

  @media (max-width: 440px) {
    font-size: 14px;
  }
`;

const PortCurrentWeatherCard = () => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const { currentWeather, lastWeatherUpdate } = useContext(WeatherContext);

  return (
    <Wrapper>
      <CardHeader>
        <Title>
          {t('Current Weather')} <DateContainer>{`${dayjs().format('dddd, MMM DD')}`}</DateContainer>
        </Title>
        <Time>{t('{{lastWeatherUpdate}} EEST (Last updated)', { lastWeatherUpdate })}</Time>
      </CardHeader>
      <CardDetails>
        <WeatherDetails>
          <Row>
            {t('Wind')}
            <WeatherIcon
              type={
                currentWeather.wind_speed || currentWeather.wind_speed === 0
                  ? getWindType(currentWeather.wind_speed)
                  : null
              }
              rotation={currentWeather.wind_direction || 0}
            />
            <RowInfo>
              {currentWeather.wind_speed || currentWeather.wind_speed === 0
                ? Math.round(currentWeather.wind_speed)
                : '-'}{' '}
              m/s
            </RowInfo>
          </Row>
          <Row>
            {t('Gust')}{' '}
            <RowInfo>
              {currentWeather.wind_gust || currentWeather.wind_gust === 0 ? Math.round(currentWeather.wind_gust) : '-'}{' '}
              m/s
            </RowInfo>
          </Row>
          <Row>
            {t('Rain')}{' '}
            <RowInfo>
              {currentWeather.rain_intensity || currentWeather.rain_intensity === 0
                ? currentWeather.rain_intensity
                : '-'}{' '}
              mm/h
            </RowInfo>
          </Row>
        </WeatherDetails>
        <Temperature>
          <TempDegree>
            {currentWeather.temperature || currentWeather.temperature === 0
              ? Math.round(currentWeather.temperature)
              : '-'}{' '}
            °C
          </TempDegree>
          <StyledWeatherText
            length={
              currentWeather.description_object?.description_text
                ? currentWeather.description_object.description_text.length
                : 0
            }
            type={currentWeather.description}
            descriptionText={
              currentWeather.description_object?.description_text
                ? currentWeather.description_object.description_text
                : null
            }
          />
        </Temperature>
        <WeatherSymbol>
          <WeatherIconCombined type={currentWeather.description} descriptionObj={currentWeather.description_object} />
        </WeatherSymbol>
      </CardDetails>
    </Wrapper>
  );
};
export default PortCurrentWeatherCard;
