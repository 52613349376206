import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from '../ui/Icon';
import Tooltip from 'antd/es/tooltip';
import { UserContext } from '../../context/UserContext';

const Container = styled.div`
  margin-left: 6px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const LinkToThisHeaderButton = ({ anchored, data, setAnchorTitle }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const createAnchorData = async () => {
    setAnchorTitle(data);
  };

  return (
    <Container>
      <Tooltip
        title={anchored ? t('Remove the anchor from this title') : t('Anchor item to this title')}
        color="white"
        overlayInnerStyle={{ padding: '6px', color: '#4a4a4a', textAlign: 'center' }}
        placement="top"
      >
        <IconContainer onClick={createAnchorData}>
          {anchored ? <Icon type="unlink" /> : <Icon type="link" />}
        </IconContainer>
      </Tooltip>
    </Container>
  );
};

export default LinkToThisHeaderButton;
