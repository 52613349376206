import React, { useContext } from 'react';
import dayjs from 'dayjs';

import { FleetVesselViewContext } from '../../../context/FleetVesselViewContext';

import SeaPassageLine from './SeaPassageLine';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const SeaPassage = ({ portcall, nextPortcall, onClick, calculatedActual }) => {
  const { canvasTimeStart, rowHeight, millisecondInPixels } = useContext(FleetVesselViewContext);

  let portcallAvailable = true;
  let actualAvailable = true;
  let nextPortcallAvailable = true;
  let nextActualAvailable = true;

  if (!portcall.eta || !portcall.etd) {
    portcallAvailable = false;
  }

  if (!portcall.ata || !portcall.atd) {
    actualAvailable = false;
  }

  if (!nextPortcall.eta || !nextPortcall.etd) {
    nextPortcallAvailable = false;
  }

  if (!nextPortcall.ata) {
    nextActualAvailable = false;
  }

  const now = dayjs();

  const canvasTimeStartMoment = dayjs(canvasTimeStart);

  const endtime = portcallAvailable ? dayjs(portcall.etd) : null;
  const endtimeActual = actualAvailable ? dayjs(portcall.atd) : null;

  const nextStarttime = nextPortcallAvailable ? dayjs(nextPortcall.eta) : null;
  const nextStarttimeActual = nextActualAvailable ? dayjs(nextPortcall.ata) : null;

  const seaPassageEnd = dayjs().add(portcall.sea_passage.travel_time_in_minutes, 'm');

  const left = portcallAvailable
    ? dayjs.duration(endtime.diff(canvasTimeStartMoment)).asMilliseconds() * millisecondInPixels
    : null;

  const leftNow = portcallAvailable
    ? dayjs.duration(now.diff(canvasTimeStartMoment)).asMilliseconds() * millisecondInPixels
    : null;

  const seaPassagewidth = nextPortcallAvailable
    ? dayjs.duration(nextStarttime.diff(endtime)).asMilliseconds() * millisecondInPixels
    : null;

  const leftActual = actualAvailable
    ? dayjs.duration(endtimeActual.diff(canvasTimeStartMoment)).asMilliseconds() * millisecondInPixels
    : null;
  const widthActual = nextActualAvailable
    ? dayjs.duration(nextStarttimeActual.diff(endtimeActual)).asMilliseconds() * millisecondInPixels
    : null;
  const widthActualCurrent =
    !nextActualAvailable && actualAvailable
      ? dayjs.duration(now.diff(endtimeActual)).asMilliseconds() * millisecondInPixels
      : null;

  const seaPassageWidth = seaPassageEnd
    ? dayjs.duration(seaPassageEnd.diff(now)).asMilliseconds() * millisecondInPixels
    : 0;

  return (
    <>
      {portcallAvailable && nextPortcallAvailable && (
        <SeaPassageLine
          style={{
            top: 2 + (rowHeight - 4) / 4,
            width: seaPassagewidth,
            backgroundColor: '#dedede',
            left: left,
            height: nextStarttime.isBefore(now) ? 2 : 1,
          }}
          time={Math.round(dayjs.duration(nextStarttime.diff(endtime)).asHours())}
          comparableTime={portcall.sea_passage ? Math.round(portcall.sea_passage.travel_time_in_hours) : null}
          passedTime={Math.round(dayjs.duration(now.diff(endtimeActual)).asHours())}
          seaPassage={portcall.sea_passage}
          onClick={onClick}
          start={endtime}
          end={nextStarttime}
          dotted={true}
          nextAta={!!nextStarttimeActual}
        />
      )}
      {actualAvailable && nextActualAvailable && (
        <SeaPassageLine
          style={{
            top: 1 + ((rowHeight - 4) / 4) * 3,
            width: widthActual,
            backgroundColor: '#dedede',
            left: leftActual,
            height: 1,
          }}
          time={Math.round(dayjs.duration(nextStarttimeActual.diff(endtimeActual)).asHours())}
          seaPassage={portcall.sea_passage}
          onClick={onClick}
          start={endtimeActual}
          end={nextStarttimeActual}
          ata={true}
          atd={true}
          nextAta={!!nextStarttimeActual}
        />
      )}
      {actualAvailable && !!widthActualCurrent && nextPortcallAvailable && (
        <SeaPassageLine
          style={{
            top: 1 + ((rowHeight - 4) / 4) * 3,
            width: widthActualCurrent,
            backgroundColor: '#4990DD',
            left: leftActual,
            height: 1,
          }}
          current={true}
          currentWidth={widthActualCurrent}
          time={Math.round(dayjs.duration(now.diff(endtimeActual)).asHours())}
          seaPassage={portcall.sea_passage}
          onClick={onClick}
          start={endtimeActual}
          end={now}
          atd={true}
          ata={true}
          nextAta={!!nextStarttimeActual}
        />
      )}
      {!!seaPassageWidth && nextPortcallAvailable && calculatedActual && !!portcall.sea_passage.speed && (
        <SeaPassageLine
          style={{
            top: 1 + ((rowHeight - 4) / 4) * 3,
            width: seaPassageWidth,
            backgroundColor: '#dedede',
            left: leftNow,
            height: 1,
          }}
          time={Math.round(portcall.sea_passage.travel_time_in_hours)}
          seaPassage={portcall.sea_passage}
          onClick={onClick}
          start={now}
          end={seaPassageEnd}
          blueTime={true}
          ata={true}
          atd={true}
          test={portcall.imo === 9259068 && portcall.locode === 'FIINK'}
          nextAta={!!nextStarttimeActual}
        />
      )}
    </>
  );
};

export default SeaPassage;
