import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import DatePicker from 'antd/es/date-picker';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../components/ui/Input';
import dayjs from 'dayjs';
import Label from '../../components/ui/Label';
import DateComponent from '../../components/ui/DateComponent';

const Page = styled.div`
  background-color: ${({ theme }) => theme.color.grey_lighter3};
  table > thead > tr > th {
    background-color: #E8E8E8 !important; /*${({ theme }) => theme.color.grey_light} !important;*/
  }
`;

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Heading1 = styled.p`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content1 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Heading2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  font-weight: 700;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Title = styled.div`
  position: absolute;
  padding-top: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondary};
  i {
    width: 24px;
    height: 24px;
  }
  svg {
    margin-right: ${({ theme }) => theme.sizing.gap_small};
  }
`;

const WindowCalendar = styled.div`
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizing.gap};
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service-delivery';

const ServiceDeliveries = ({ orderLine, newParams, fetchData, setNewParams, loading }) => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const { message } = App.useApp();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initDelivery = {
    id: '',
    order_line_id: orderLine?.id || '',
    status: '',
    quantity: orderLine?.quantity || '',
    delivery_time: orderLine?.requested_delivery_time || null,
    notes: '',
    email: '',
    phone: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
  };

  const [delivery, setDelivery] = useState(initDelivery);

  const initModal = {
    visible: false,
    confirmLoading: false,
    'service-delivery': {
      id: '',
      order_line_id: orderLine?.id || '',
      status: '',
      quantity: '',
      delivery_time: null,
      notes: '',
      email: '',
      phone: '',
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
    },
  };

  const [modal, setModal] = useState(initModal);

  const start = 0;
  const total = 0;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const {
        data: { 'service-delivery': delivery },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
      setDelivery({
        ...delivery,
        quantity: `${delivery.quantity}`,
      });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleSave = async values => {
    const { status, quantity, delivery_time, notes, email, phone } = values;
    const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
      service_delivery: {
        order_line_id: orderLine.id,
        status: status,
        quantity: parseFloat(quantity),
        delivery_time: delivery_time ? delivery_time : null,
        notes: notes,
        email: email,
        phone: phone,
      },
    });

    if (result?.data.result === 'ERROR' && result?.data.message.length) {
      message.error(result.data.message, 4);
    } else {
      setDelivery(initDelivery);
      handleSetMultiple(initDelivery);
      history.replace(location.pathname);
      await fetchData(newParams);
    }
    showActions(false);
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setDelivery(initDelivery);
    handleSetMultiple(initDelivery);
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service_delivery: {
          id: delivery.id,
          status: delivery.status || null,
          quantity: parseFloat(delivery.quantity) || null,
          delivery_time: delivery.delivery_time || null,
          notes: delivery.notes || null,
          email: delivery.email || null,
          phone: delivery.phone || null,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      throw e;
    }
    setApiCallPending(false);
    setModal({
      ...initModal,
      visible: false,
      confirmLoading: false,
    });
    showActions(false);
    setDelivery(initDelivery);
    handleSetMultiple(initDelivery);
    history.replace(location.pathname);
    await fetchData(newParams);
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setDelivery(initDelivery);
    handleSetMultiple(initDelivery);
  };

  const handleModalChange = e => {
    const { name, value } = e.target;
    setDelivery({ ...delivery, [name]: value });
  };

  const handleAddNewChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setDelivery({ ...delivery, [name]: value });
  };

  const fields = ['status', 'quantity', 'delivery_time', 'notes', 'email', 'phone'];
  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {
    quantity: orderLine?.quantity || '',
    delivery_time: orderLine?.requested_delivery_time || null,
    email: orderLine?.service_provider_email,
    phone: orderLine?.service_provider_phone,
  });

  useEffect(() => {
    let isValid =
      orderLine?.id?.length > 0 &&
      delivery?.status?.length > 0 &&
      delivery?.quantity?.length > 0 &&
      dayjs(delivery?.delivery_time, dayjs.ISO_8601).isValid()
        ? true
        : false;
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, delivery, orderLine.id.length]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status?.localeCompare?.(b.status),
      render: record => {
        return <Heading2>{record}</Heading2>;
      },
    },
    {
      title: t('Quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity?.localeCompare?.(b.quantity),
      render: record => {
        return <Heading2>{record}</Heading2>;
      },
    },
    {
      title: t('Delivery'),
      dataIndex: 'delivery_time',
      key: 'delivery_time',
      sorter: (a, b) => new Date(a.delivery_time) - new Date(b.delivery_time),
      //sortableKey: 'order_line_deliveries_delivery_time',
      defaultSortOrder: 'ascend',
      render: record =>
        record && (
          <Heading1>
            <DateComponent format={TIME_FORMAT} date={record} />
          </Heading1>
        ),
    },
    {
      title: t('Notes'),
      dataIndex: 'notes',
      key: 'notes',
      sorter: (a, b) => a.notes?.localeCompare?.(b.notes),
      render: record => {
        return <Heading2>{record}</Heading2>;
      },
    },
    {
      title: t('Contact'),
      key: 'contact',
      sorter: (a, b) => a.email?.localeCompare?.(b.email),
      render: record => {
        return (
          <>
            <Heading2>{record?.email}</Heading2>
            <Content1>{record?.phone}</Content1>
          </>
        );
      },
    },
    {
      title: t('Created'),
      key: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete service delivery {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: !orderLine?.ack || orderLine?.ack?.status === 'rejected' || orderLine?.is_cancelled ? true : loading,
      text: t('Add service delivery'),
      icon: 'plus',
    },
  ];

  return (
    <Page>
      <Modal
        title={t('Edit service delivery')}
        open={modal.visible}
        onOk={handleModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <InputContainer>
              <InputLabel>{t('Status')}</InputLabel>
              <Select
                name="status"
                label={t('Status')}
                value={delivery?.status || ''}
                options={[
                  {
                    label: t('Pending'),
                    value: 'pending',
                  },
                  {
                    label: t('In progress'),
                    value: 'in_progress',
                  },
                  {
                    label: t('Done'),
                    value: 'done',
                  },
                  {
                    label: t('Cancelled'),
                    value: 'cancelled',
                  },
                ]}
                style={{ width: '100%' }}
                placeholder={t('Select status')}
                onChange={value =>
                  handleModalChange({
                    target: {
                      value: value,
                      name: 'status',
                    },
                  })
                }
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('Quantity')}</InputLabel>
              <StyledInputNumber
                placeholder={t('Quantity')}
                value={delivery?.quantity || ''}
                decimalSeparator={','}
                min={0}
                onChange={e => handleModalChange({ target: { value: e ? `${e}` : null, name: 'quantity' } })}
                style={{ width: '100%' }}
                required
              />
            </InputContainer>
            <WindowCalendar>
              <Label>{t('Delivery time')}</Label>
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                style={{
                  width: '100%',
                }}
                value={delivery?.delivery_time ? dayjs(delivery?.delivery_time) : null}
                onChange={value =>
                  handleModalChange({
                    target: {
                      value: value ? dayjs(value).toISOString() : null,
                      name: 'delivery_time',
                    },
                  })
                }
                placeholder={t('Select time')}
                required
              />
            </WindowCalendar>
            <Input
              name="notes"
              label={t('Notes')}
              value={delivery?.notes || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="email"
              label={t('Email')}
              value={delivery?.email || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
            <Input
              name="phone"
              label={t('Phone')}
              value={delivery?.phone || ''}
              onChange={handleModalChange}
              style={{ width: '100%' }}
            />
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service delivery')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'status') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Status')}</InputLabel>
                    <Select
                      name={field}
                      label={field.replace(/_/g, ' ')}
                      value={values[field]}
                      options={[
                        {
                          label: t('Pending'),
                          value: 'pending',
                        },
                        {
                          label: t('In progress'),
                          value: 'in_progress',
                        },
                        {
                          label: t('Done'),
                          value: 'done',
                        },
                        {
                          label: t('Cancelled'),
                          value: 'cancelled',
                        },
                      ]}
                      style={{ width: '100%' }}
                      placeholder={t('Select status')}
                      onChange={value =>
                        handleAddNewChange({
                          target: {
                            value: value,
                            name: 'status',
                          },
                        })
                      }
                    />
                  </InputContainer>
                );
              } else if (field === 'delivery_time') {
                return (
                  <WindowCalendar key={field}>
                    <Label>{t('Delivery time')}</Label>
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime={{ format: 'HH:mm', minuteStep: 15 }}
                      style={{
                        width: '100%',
                      }}
                      value={values[field] ? dayjs(values[field]) : null}
                      onChange={value =>
                        handleAddNewChange({
                          target: {
                            value: value ? dayjs(value).toISOString() : null,
                            name: 'delivery_time',
                          },
                        })
                      }
                      placeholder={t('Select time')}
                      required
                    />
                  </WindowCalendar>
                );
              } else if (field === 'quantity') {
                return (
                  <InputContainer key={field}>
                    <Label>{t(field.replace(/_/g, ' '))}</Label>
                    <StyledInputNumber
                      placeholder={t('Quantity')}
                      key={field}
                      name={field}
                      field={field}
                      value={values[field]}
                      decimalSeparator={','}
                      min={0}
                      onChange={e => handleAddNewChange({ target: { value: e ? `${e}` : null, name: 'quantity' } })}
                    />
                  </InputContainer>
                );
              }
              return (
                <Input
                  label={field.replace(/_/g, ' ')}
                  key={field}
                  name={field}
                  field={field}
                  value={values[field]}
                  type="text"
                  onChange={handleAddNewChange}
                />
              );
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service delivery')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <Title>{t('Deliveries')}</Title>
      <List
        rowKey={record => `order-line-deliveries-${record?.id}`}
        columns={columns}
        dataSource={orderLine?.deliveries}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={loading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by status, quantity, delivery_time, notes, email or phone')}
        additionalButtons={additionalButtons}
        hideSearch={true}
      />
    </Page>
  );
};

export default ServiceDeliveries;
