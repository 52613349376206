import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT, TIME_FORMAT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';

import App from 'antd/es/app';
import Popconfirm from 'antd/es/popconfirm';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import Switch from 'antd/es/switch';
import Input from 'antd/es/input';
import { ExclamationCircleFilled } from '@ant-design/icons';

import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import Form from '../../components/ui/Form';
import ListActionButton from '../../components/ui/ListActionButton';
import List from '../../components/ui/List';
import PageActionForm from '../../components/page/PageActionForm';
import { useHistory, useLocation } from 'react-router-dom';
import DebounceAutocomplete from '../../components/ui/DebounceAutocomplete';
import ServiceUsers from './ServiceUsers';
import LocalInput from '../../components/ui/Input';
import ExternalField from '../../components/activity/serviceOrder/externalTypes/ExternalFields';
import { isEmailValid } from '../../utils/utils';
import DateComponent from '../../components/ui/DateComponent';

const { TextArea } = Input;
const { confirm } = Modal;

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.div`
  font-size: 0.8571rem;
  font-weight: 700;
  -webkit-letter-spacing: 0.025em;
  -moz-letter-spacing: 0.025em;
  -ms-letter-spacing: 0.025em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

const Heading1 = styled.p`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content = styled.p`
  font-size: ${({ theme }) => theme.text.smaller};
  /*color: ${({ theme }) => theme.color.grey};*/
  letter-spacing: 0.025em;
  white-space: nowrap;
`;

const Content2 = styled.p`
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.color.grey};
  letter-spacing: 0.025em;
  margin-bottom: ${({ theme }) => theme.sizing.gap_tiny};
`;

const Content3 = styled.p`
  font-size: ${({ theme }) => theme.text.smaller};
  /*color: ${({ theme }) => theme.color.grey};*/
  letter-spacing: 0.025em;
  white-space: nowrap;
  padding-top: 8px;
`;

const Required = styled.span`
  color: red;
`;

const ServicesList = styled.div`
  .ant-table {
    table {
      padding-bottom: 0 !important;
    }
    .ant-table-content {
      overflow: visible;
    }
  }
`;

const SwitchGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const SwitchTitle = styled.p`
  font-size: ${({ theme }) => theme.text.smaller};
  letter-spacing: 0.025em;
  white-space: nowrap;
  padding-left: 8px;
`;

const Integrations = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  margin-right: 6px;
  margin-left: 6px;

  svg {
    fill: ${({ theme }) => theme.color.grey_dark} !important;
    height: 18px;
    width: 18px;
  }
`;

const RelatedFieldsContainer = styled.div`
  padding: 8px 0;
`;
const RelatedFieldsLabel = styled(InputLabel)`
  padding-bottom: 8px;
`;

const SERVICE_ORDERS_BASE_URL = 'service-orders';
const SERVICE_URL = 'service';

const Services = () => {
  const { apiCall, modules, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const location = useLocation();

  const actionsRef = useRef();
  const [actions, showActions] = useState(false);

  const [apiCallPending, setApiCallPending] = useState(false);
  const [applyChangesDisabled, setApplyChangesDisabled] = useState(true);
  const [servicesData, setServicesData] = useState(null);
  const [servicesError, setServicesError] = useState(null);
  const [servicesLoading, setServicesLoading] = useState(null);

  const { message } = App.useApp();

  const [serviceEditName, setServiceEditName] = useState({
    provider: null,
    type: null,
  });

  const [externalTypes, setExternalTypes] = useState([]);
  const [integratedTypes, setIntegratedTypes] = useState([]);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const initService = {
    id: '',
    provider: {
      id: '',
      name: '',
      email: '',
      phone: '',
      external_type: null,
    },
    type: {
      id: '',
      name: '',
      description: '',
    },
    ack_emails_enabled: false,
    delivery_emails_enabled: false,
    cc_emails: null,
    phone_number: '',
    sms_notification: false,
    phone_call_confirmation: false,
    phone_call_before_minutes: 180,
    phone_call_ignore_ack: false,
    external_type_data: {},
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
    integrated_type_data: null,
  };

  const [service, setService] = useState(initService);
  const [oldService, setOldService] = useState(initService);

  const initModal = {
    visible: false,
    confirmLoading: false,
    service: {
      id: '',
      provider: {
        id: '',
        name: '',
        email: '',
        phone: '',
        external_type: null,
      },
      type: {
        id: '',
        name: '',
        description: '',
      },
      ack_emails_enabled: false,
      delivery_emails_enabled: false,
      cc_emails: null,
      phone_number: '',
      sms_notification: false,
      phone_call_confirmation: false,
      phone_call_before_minutes: 180,
      phone_call_ignore_ack: false,
      external_type_data: {},
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
      integrated_type_data: null,
    },
  };

  const [modal, setModal] = useState(initModal);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    sort: 'provider_name',
    search: '',
  };

  const servicesChanged = useCallback(data => {
    if (data?.results) {
      setServicesData(data.results);
    } else {
      setServicesData(null);
    }
  }, []);

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading: servicesFetchLoading, error: servicesFetchError, fetchData: servicesFetchData } = useApi(
    'get',
    `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`,
    newParams,
    servicesChanged,
    false
  );

  const { loading: servicesSearchLoading, error: servicesSearchError, fetchData: servicesFetchSearchData } = useApi(
    'post',
    `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/search`,
    {},
    servicesChanged,
    false
  );

  useEffect(() => {
    const { search, ...rest } = newParams;
    if (search) {
      const [order_by_field, order_by_order] = newParams.sort.split(' ');
      servicesFetchSearchData(false, {
        query: {
          text: newParams.search,
        },
        pagination: {
          limit: newParams.limit,
          offset: newParams.offset,
        },
        order_by: [
          {
            field: order_by_field,
            order: order_by_order ? order_by_order : 'ASC',
          },
        ],
      });
    } else {
      servicesFetchData(false, rest);
    }
  }, [newParams, servicesFetchData, servicesFetchSearchData]);

  useEffect(() => {
    setServicesError(servicesFetchError);
  }, [servicesFetchError]);

  useEffect(() => {
    setServicesError(servicesSearchError);
  }, [servicesSearchError]);

  useEffect(() => {
    setServicesLoading(servicesFetchLoading);
  }, [servicesFetchLoading]);

  useEffect(() => {
    setServicesLoading(servicesSearchLoading);
  }, [servicesSearchLoading]);

  if (servicesError) {
    message.error(servicesError, 4);
  }

  const fetchRelatedTypeData = useCallback(async () => {
    setApiCallPending(true);
    let externalTypeData = [];
    let integratedTypeData = [];
    const result = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/related-type-data`, {});
    setApiCallPending(false);
    if (result?.status === 200 && result?.data) {
      externalTypeData = result.data?.['external-types'] || [];
      integratedTypeData = result.data?.['integrated-types'] || [];
    }
    setExternalTypes(externalTypeData);
    setIntegratedTypes(integratedTypeData);
  }, [apiCall]);

  useEffect(() => {
    fetchRelatedTypeData();
  }, [fetchRelatedTypeData]);

  const selectedExternalType = externalTypes.find(({ value }) => value === service.provider?.data?.external_type);
  const showPhoneActions = !selectedExternalType?.disablePhoneActions;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const services = servicesLoading || !servicesData?.['services'] ? [] : servicesData['services'];

  const { offset, total } = servicesError || !servicesData ? { offset: 0, total: 0 } : servicesData.pagination;

  const showModal = async entryIdentifier => {
    setApiCallPending(true);
    try {
      const {
        data: { service },
      } = await apiCall('get', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
      delete service.data?.external_data?.supportedOrderLineFields;
      delete service.data?.external_data?.supportedOrderFields;
      setService({
        ...service,
        ack_emails_enabled: service.data?.ack_emails_enabled || false,
        delivery_emails_enabled: service.data?.delivery_emails_enabled || false,
        cc_emails: service.data?.cc_emails?.join('\n') || '',
        sms_notification: service.data?.sms_notification || false,
        phone_call_confirmation: service.data?.phone_call_confirmation || false,
        phone_number: service.data?.phone_number || '',
        phone_call_before_minutes: service.data?.phone_call_before_minutes,
        phone_call_ignore_ack: service.data?.phone_call_ignore_ack || false,
        external_type_data: Object.entries(service.data?.external_data || {}).length ? service.data.external_data : {},
        integrated_type_data: Object.entries(service.data?.integrated_type_data || {}).length
          ? service.data.integrated_type_data
          : null,
      });
      setOldService({
        ...service,
        ack_emails_enabled: service.data?.ack_emails_enabled || false,
        delivery_emails_enabled: service.data?.delivery_emails_enabled || false,
        cc_emails: service.data?.cc_emails?.join('\n') || '',
        sms_notification: service.data?.sms_notification || false,
        phone_call_confirmation: service.data?.phone_call_confirmation || false,
        phone_number: service.data?.phone_number || '',
        phone_call_before_minutes: service.data?.phone_call_before_minutes,
        phone_call_ignore_ack: service.data?.phone_call_ignore_ack || false,
        external_type_data: Object.entries(service.data?.external_data || {}).length ? service.data.external_data : {},
        integrated_type_data: Object.entries(service.data?.integrated_type_data || {}).length
          ? service.data.integrated_type_data
          : null,
      });
      setServiceEditName({
        provider: service.provider.name,
        type: service.type.name,
      });
      setModal({ ...initModal, visible: true });
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }
    setApiCallPending(false);
  };

  const handleDelete = async entryIdentifier => {
    setApiCallPending(true);
    try {
      await apiCall('delete', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}/${entryIdentifier}`);
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }
    showActions(false);
    setApiCallPending(false);
    history.replace(location.pathname);
    await servicesFetchData(false, defaultParams);
  };

  const handleSave = async values => {
    const {
      provider,
      type,
      ack_emails_enabled,
      delivery_emails_enabled,
      cc_emails,
      sms_notification,
      phone_call_confirmation,
      phone_number,
      phone_call_before_minutes,
      phone_call_ignore_ack,
    } = values;
    try {
      const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service: {
          provider_id: provider.id,
          type_id: type.id,
          ack_emails_enabled: ack_emails_enabled || false,
          delivery_emails_enabled: delivery_emails_enabled || false,
          cc_emails: cc_emails?.length ? cc_emails.split(/\r?\n/) : [],
          ...(showPhoneActions ? { sms_notification: sms_notification || false } : {}),
          ...(showPhoneActions ? { phone_call_confirmation: phone_call_confirmation || false } : {}),
          ...(showPhoneActions ? { phone_number: phone_number || '' } : {}),
          ...(showPhoneActions
            ? { phone_call_before_minutes: phone_call_before_minutes ? parseInt(phone_call_before_minutes) : 180 }
            : {}),
          ...(showPhoneActions ? { phone_call_ignore_ack: phone_call_ignore_ack || false } : {}),
          ...(provider.data?.external_type ? { external_type_data: service.external_type_data } : {}),
          ...(Object.entries(service.integrated_type_data || {}).length
            ? { integrated_type_data: service.integrated_type_data }
            : {}),
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      } else {
        setService(initService);
        handleSetMultiple(initService);
        history.replace(location.pathname);
        servicesFetchData();
      }
      showActions(false);
      setServiceEditName({
        provider: null,
        type: null,
      });
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setService(initService);
    handleSetMultiple(initService);
    setServiceEditName({
      provider: null,
      type: null,
    });
  };

  const confirmModalOk = async () => {
    // Show confirmation if phone call settings have changed
    if (
      service.phone_call_confirmation !== oldService.phone_call_confirmation ||
      oldService.phone_number !== service.phone_number ||
      oldService.phone_call_before_minutes !== service.phone_call_before_minutes ||
      oldService.phone_call_ignore_ack !== service.phone_call_ignore_ack
    ) {
      confirm({
        title: t('Do you really want to update phone call settings?'),
        icon: <ExclamationCircleFilled />,
        content: service.phone_call_confirmation
          ? t('All pending phone calls for service {{id}} will be re-scheduled!', { id: service.id })
          : t('All phone calls for service {{id}} will be disabled!', { id: service.id }),
        onOk() {
          handleModalOk();
        },
      });
    } else {
      handleModalOk();
    }
  };

  const handleModalOk = async () => {
    setModal({
      ...modal,
      confirmLoading: true,
    });
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service: {
          id: service.id,
          provider_id: service.provider?.id || null,
          type_id: service.type?.id || null,
          ack_emails_enabled: service.ack_emails_enabled || false,
          delivery_emails_enabled: service.delivery_emails_enabled || false,
          cc_emails: service.cc_emails?.length ? service.cc_emails.split(/\r?\n/) : [],
          ...(modules.sms_module === 'enabled'
            ? { sms_notification: showPhoneActions ? service.sms_notification || false : false }
            : {}),
          ...(modules.phone_call_module === 'enabled'
            ? { phone_call_confirmation: showPhoneActions ? service.phone_call_confirmation || false : false }
            : {}),
          phone_number: showPhoneActions ? service.phone_number || '' : '',
          phone_call_before_minutes: showPhoneActions ? parseInt(service.phone_call_before_minutes) || 0 : 0,
          phone_call_ignore_ack: showPhoneActions ? service.phone_call_ignore_ack || false : false,
          external_type_data: service.provider?.data?.external_type ? service.external_type_data : null,
          integrated_type_data: Object.entries(service.integrated_type_data || {}).length
            ? service.integrated_type_data
            : null,
        },
      });
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
      setApiCallPending(false);
      setModal({
        ...initModal,
        visible: false,
        confirmLoading: false,
      });
      showActions(false);
      setService(initService);
      handleSetMultiple(initService);
      history.replace(location.pathname);
      await servicesFetchData(false, defaultParams);
    } catch (e) {
      setModal({
        ...modal,
        confirmLoading: false,
      });
      setApiCallPending(false);
      //throw e;
    }
  };

  const handleModalCancel = async () => {
    setModal(initModal);
    setService(initService);
    setOldService(initService);
    handleSetMultiple(initService);
  };

  const handleModalChange = (value, obj, type) => {
    setServiceEditName(previous => {
      return {
        ...previous,
        [type]: value,
      };
    });
    setService(previous => {
      return {
        ...previous,
        [type]: obj?.data || null,
      };
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setService({ ...service, [name]: value });
  };

  const handleExternalTypeDataChange = (value, index, key) => {
    let newInputData = {
      ...service,
    };
    newInputData['external_type_data'][key] = value;
    setService(newInputData);
  };

  const handleIntegratedTypeDataChange = (value, index, type, key) => {
    let newInputData = {
      ...service,
    };
    if (value) {
      if (!newInputData['integrated_type_data']) {
        newInputData['integrated_type_data'] = {};
      }
      if (!newInputData['integrated_type_data'][type]) {
        newInputData['integrated_type_data'][type] = {};
      }
      newInputData['integrated_type_data'][type][key] = value;
    } else {
      delete newInputData['integrated_type_data']?.[type]?.[key];
      if (!Object.keys(newInputData['integrated_type_data']?.[type]).length) {
        delete newInputData['integrated_type_data'][type];
      }
      if (!Object.keys(newInputData['integrated_type_data']).length) {
        newInputData['integrated_type_data'] = null;
      }
    }
    setService(newInputData);
  };

  const handleAddNewChange = (value, obj, type) => {
    handleChange({
      target: {
        value: obj?.data,
        name: type,
      },
    });
    setServiceEditName(previous => {
      return {
        ...previous,
        [type]: value,
      };
    });
    setService(previous => {
      return {
        ...previous,
        [type]: obj?.data || null,
      };
    });
  };

  const handleAddNewInputChange = e => {
    handleChange(e);
    const { name, value } = e.target;
    setService({ ...service, [name]: value });
  };

  const confirmChangeServiceSetting = (entryIdentifier, key, value) => {
    confirm({
      title: t('Do you really want to update phone call settings?'),
      icon: <ExclamationCircleFilled />,
      content: value
        ? t('All pending phone calls for service {{id}} will be re-scheduled!', { id: entryIdentifier })
        : t('All phone calls for service {{id}} will be disabled!', { id: entryIdentifier }),
      onOk() {
        handleChangeServiceSetting(entryIdentifier, key, value);
      },
    });
  };

  const handleChangeServiceSetting = async (entryIdentifier, key, value) => {
    setApiCallPending(true);
    try {
      const result = await apiCall('put', `${SERVICE_ORDERS_BASE_URL}/${SERVICE_URL}`, {
        service: {
          id: entryIdentifier,
          [key]: value,
        },
      });
      setApiCallPending(false);
      if (result?.data.result === 'ERROR' && result?.data.message.length) {
        message.error(result.data.message, 4);
      }
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }
    servicesFetchData();
  };

  const fetchServiceProviders = async value => {
    const params = {
      query: {
        conditions: {
          and: [
            {
              type: 'name',
              operator: 'contains',
              value: value,
            },
          ],
        },
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [
        {
          field: 'name',
          order: 'ASC',
        },
      ],
    };

    try {
      const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/service-provider/search`, params);
      if (result?.status === 200) {
        return result.data?.results?.['service-providers']?.map(d => {
          return { value: d.name, label: d.name, data: d };
        });
      }
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }

    return [];
  };

  const fetchServiceTypes = async value => {
    const params = {
      query: {
        conditions: {
          and: [
            {
              type: 'name',
              operator: 'contains',
              value: value,
            },
          ],
        },
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
      order_by: [
        {
          field: 'name',
          order: 'ASC',
        },
      ],
    };

    try {
      const result = await apiCall('post', `${SERVICE_ORDERS_BASE_URL}/service-type/search`, params);
      if (result?.status === 200) {
        return result.data?.results?.['service-types']?.map(d => {
          return { value: d.name, label: d.name, data: d };
        });
      }
    } catch (e) {
      setApiCallPending(false);
      //throw e;
    }

    return [];
  };

  const getWasteIndicator = () => {
    return (
      <div key="waste">
        <Tooltip placement="top" title={t('Waste service')}>
          <IconContainer>
            <Icon type="waste" />
          </IconContainer>
        </Tooltip>
      </div>
    );
  };

  const getFinnpilotIndicator = () => {
    return (
      <div key="finnpilot">
        <Tooltip placement="top" title={t('Finnpilot service')}>
          <IconContainer>
            <Icon type="finnpilot-logo" color="#4A4A4A" />
          </IconContainer>
        </Tooltip>
      </div>
    );
  };

  const fields = [
    'provider',
    'type',
    'external_type_data',
    'integrated_type_data',
    'ack_emails_enabled',
    'delivery_emails_enabled',
    'cc_emails',
  ];
  if (showPhoneActions) {
    fields.push(
      ...[
        'phone_number',
        'sms_notification',
        'phone_call_confirmation',
        'phone_call_before_minutes',
        'phone_call_ignore_ack',
      ]
    );
  }

  const { values, handleChange, handleSubmit, handleSetMultiple } = useForm(fields, handleSave, {
    provider: null,
    type: null,
    ack_emails_enabled: false,
    delivery_emails_enabled: false,
    cc_emails: null,
    sms_notification: false,
    phone_call_confirmation: false,
    phone_number: '',
    phone_call_before_minutes: '180',
    phone_call_ignore_ack: false,
  });

  useEffect(() => {
    let isValid = service?.provider?.id?.length > 0 && service?.type?.id?.length > 0;
    if (isValid && service.cc_emails?.length > 0) {
      const emails = service.cc_emails.split(/\r?\n/);
      isValid = emails.every(isEmailValid);
    }
    if (isValid && service.phone_call_confirmation) {
      isValid = !!service.phone_number;
    }
    if (isValid && service.sms_notification) {
      isValid = !!service.phone_number;
    }
    setApplyChangesDisabled(!isValid);
  }, [setApplyChangesDisabled, service]);

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sortableKey: 'id',
    },
    {
      title: t('Provider'),
      key: 'provider',
      sorter: (a, b) => a.provider?.name?.localeCompare?.(b.provider?.name),
      render: record => {
        return (
          <>
            <Heading1>{record?.provider?.name}</Heading1>
            <Content2>{record?.provider?.email}</Content2>
            <Content2>{record?.provider?.phone}</Content2>
          </>
        );
      },
    },
    {
      title: t('Type'),
      key: 'type',
      sorter: (a, b) => a.type?.name?.localeCompare?.(b.type?.name),
      render: record => {
        const externalType = record?.provider.data?.external_type
          ? externalTypes.find(({ value }) => value === record.provider.data.external_type)?.name ||
            record?.provider.data?.external_type
          : record?.provider.data?.external_type;
        return (
          <>
            <Heading1>{record?.type?.name}</Heading1>
            <Content2>{record?.type?.description}</Content2>
            {externalType ? <Content3>{t('External type: {{type}}', { type: externalType })}</Content3> : null}
            {externalType && record?.data?.external_data
              ? Object.entries(record?.data?.external_data)
                .filter(([key]) => key !== 'supportedOrderLineFields')
                .map(([key, value], index) => (
                  <Content3 key={`external-data-${index}`}>{`${key}: ${value}`}</Content3>
                ))
              : null}
          </>
        );
      },
    },
    {
      title: t('receive emails'),
      key: 'receive_emails',
      render: record => {
        const ackEnabled = record?.data?.ack_emails_enabled;
        const deliveryEnabled = record?.data?.delivery_emails_enabled;
        return (
          <div>
            <Tooltip
              placement="top"
              title={t('Acknowledgement emails {{status}}', { status: ackEnabled ? 'enabled' : 'disabled' })}
            >
              <SwitchGroup>
                <Switch
                  checked={ackEnabled}
                  defaultChecked={ackEnabled}
                  onChange={checked => handleChangeServiceSetting(record?.id, 'ack_emails_enabled', checked)}
                ></Switch>
                <SwitchTitle>{t('Ack')}</SwitchTitle>
              </SwitchGroup>
            </Tooltip>
            <Tooltip
              placement="top"
              title={t('Delivery emails {{status}}', { status: deliveryEnabled ? 'enabled' : 'disabled' })}
            >
              <SwitchGroup>
                <Switch
                  checked={deliveryEnabled}
                  defaultChecked={deliveryEnabled}
                  onChange={checked => handleChangeServiceSetting(record?.id, 'delivery_emails_enabled', checked)}
                ></Switch>
                <SwitchTitle>{t('Delivery')}</SwitchTitle>
              </SwitchGroup>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Cc emails'),
      key: 'cc_emails',
      render: record => {
        return (
          <Tooltip placement="top" title={t('Additional emails for service order updates')}>
            {record?.data?.cc_emails?.map?.(cc_email => (
              <Content key={cc_email}>{cc_email}</Content>
            ))}
          </Tooltip>
        );
      },
    },
    {
      title: t('Send SMS'),
      key: 'sms_notification',
      sorter: (a, b) => Number(a.data?.sms_notification || false) - Number(b.data?.sms_notification || false),
      render: record => {
        const selectedExternalType = externalTypes.find(({ value }) => value === record?.provider.data?.external_type);
        const phoneActionsEnabled = !selectedExternalType?.disablePhoneActions;
        const enabled = modules.sms_module === 'enabled' && record?.data?.phone_number && phoneActionsEnabled;
        return (
          <Tooltip
            placement="top"
            title={
              modules.sms_module !== 'enabled'
                ? t('SMS module not enabled')
                : !phoneActionsEnabled
                    ? t('External type does not support SMS')
                    : !record?.data?.phone_number
                        ? t('Service phone number not set')
                        : t('Click to enable / disable SMS notification')
            }
          >
            <Switch
              disabled={!enabled}
              checked={record?.data?.sms_notification}
              defaultChecked={record?.data?.sms_notification}
              onChange={checked => handleChangeServiceSetting(record?.id, 'sms_notification', checked)}
            ></Switch>
            {enabled ? (
              <>
                <Content3>{t('When service is requested')}</Content3>
                <Content3>
                  {record?.data?.phone_number
                    ? t('to {{phone}}', { phone: record?.data?.phone_number })
                    : t('Phone number missing!')}
                </Content3>
              </>
            ) : null}
          </Tooltip>
        );
      },
    },
    {
      title: t('Phone call'),
      key: 'phone_call_confirmation',
      sorter: (a, b) =>
        Number(a.data?.phone_call_confirmation || false) - Number(b.data?.phone_call_confirmation || false),
      render: record => {
        const selectedExternalType = externalTypes.find(({ value }) => value === record?.provider.data?.external_type);
        const phoneActionsEnabled = !selectedExternalType?.disablePhoneActions;
        const enabled = modules.phone_call_module === 'enabled' && record?.data?.phone_number && phoneActionsEnabled;
        return (
          <Tooltip
            placement="top"
            title={
              modules.phone_call_module !== 'enabled'
                ? t('Phone call module not enabled')
                : !phoneActionsEnabled
                    ? t('External type does not support phone calls')
                    : !record?.data?.phone_number
                        ? t('Service phone number not set')
                        : t('Click to enable / disable phone call reminder')
            }
          >
            <Switch
              disabled={!enabled}
              checked={record?.data?.phone_call_confirmation}
              defaultChecked={record?.data?.phone_call_confirmation}
              onChange={checked => confirmChangeServiceSetting(record?.id, 'phone_call_confirmation', checked)}
            ></Switch>
            {enabled ? (
              <>
                <Content3>
                  {record?.data?.phone_call_before_minutes
                    ? t('{{time}} minutes before delivery', { time: record?.data?.phone_call_before_minutes })
                    : t('At requested delivery time')}
                </Content3>
                <Content3>
                  {record?.data?.phone_number
                    ? t('to {{phone}}', { phone: record?.data?.phone_number })
                    : t('Phone number missing!')}
                </Content3>
                <Content3>
                  {record?.data?.phone_call_ignore_ack
                    ? t("Call even if order line is ack'd") // eslint-disable-line quotes
                    : t("No call after order line is ack'd") /* eslint-disable-line quotes */}
                </Content3>
              </>
            ) : null}
          </Tooltip>
        );
      },
    },
    {
      title: t('Integrations'),
      dataIndex: 'integrations',
      key: 'integrations',
      render: record => (
        <Integrations>
          {record?.map?.(source => {
            if (source === 'waste') {
              return getWasteIndicator();
            } else if (source === 'finnpilot') {
              return getFinnpilotIndicator();
            }
            return null;
          })}
        </Integrations>
      ),
    },
    {
      title: t('Created'),
      key: 'created_at',
      sortableKey: 'created_at',
      render: record => {
        let created_by = record?.created_by || '';
        if (record?.data?.created_by_name && record?.data?.created_by_email) {
          created_by = `${record.data.created_by_name} (${record.data.created_by_email})`;
        } else if (record?.data?.created_by_name) {
          created_by = record.data.created_by_name;
        } else if (record?.data?.created_by_email) {
          created_by = record.data.created_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.created_at} />
              </Content2>
            )}
            <Content2>{created_by}</Content2>
          </>
        );
      },
    },
    {
      title: t('Updated'),
      key: 'updated_at',
      sortableKey: 'updated_at',
      render: record => {
        let updated_by = record?.updated_by || '';
        if (record?.data?.updated_by_name && record?.data?.updated_by_email) {
          updated_by = `${record.data.updated_by_name} (${record.data.updated_by_email})`;
        } else if (record?.data?.updated_by_name) {
          updated_by = record.data.updated_by_name;
        } else if (record?.data?.updated_by_email) {
          updated_by = record.data.updated_by_email;
        }
        return (
          <>
            {record && (
              <Content2>
                <DateComponent format={TIME_FORMAT} date={record?.updated_at} />
              </Content2>
            )}
            <Content2>{updated_by}</Content2>
          </>
        );
      },
    },
  ];

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => showModal(record.id)}>
          <Icon type="edit" />
          {t('Edit')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <Popconfirm
          title={t('Delete service {{id}}?', { id: record.id })}
          onConfirm={() => handleDelete(record.id)}
          okText={t('Yes')}
          okType="danger"
          cancelText={t('No')}
          icon={null}
          id="pop-confirm-for-new-list"
          key="action-2"
        >
          <div>
            <ListActionButton red>
              <Icon type="trash" />
              {t('Delete')}
            </ListActionButton>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const additionalButtons = [
    {
      onClick: () => showActions(!actions),
      disabled: servicesLoading,
      text: t('Add service'),
      icon: 'plus',
    },
  ];

  return (
    <ServicesList>
      <Modal
        title={t('Edit services')}
        open={modal.visible}
        onOk={confirmModalOk}
        confirmLoading={modal.confirmLoading}
        onCancel={handleModalCancel}
        okButtonProps={{ disabled: applyChangesDisabled }}
      >
        <ModalInner>
          <Spin spinning={apiCallPending}>
            <InputContainer>
              <InputLabel>{t('Service provider')}</InputLabel>
              <DebounceAutocomplete
                placeholder={t('Search for provider')}
                fetchOptions={fetchServiceProviders}
                onChange={(value, data) => handleModalChange(value, data, 'provider')}
                onSelect={(value, data) => handleModalChange(value, data, 'provider')}
                value={serviceEditName['provider']}
                style={{ width: '100%' }}
                allowClear={true}
                onClear={() => handleModalChange('', null, 'provider')}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('Service type')}</InputLabel>
              <DebounceAutocomplete
                placeholder={t('Search for type')}
                fetchOptions={fetchServiceTypes}
                onChange={(value, data) => handleModalChange(value, data, 'type')}
                onSelect={(value, data) => handleModalChange(value, data, 'type')}
                value={serviceEditName['type']}
                style={{ width: '100%' }}
                allowClear={true}
                onClear={() => handleModalChange('', null, 'type')}
              />
            </InputContainer>
            {selectedExternalType?.fields?.length ? (
              <RelatedFieldsContainer>
                <RelatedFieldsLabel>
                  {t('{{name}} additional configuration', { name: selectedExternalType.name })}
                </RelatedFieldsLabel>
                {selectedExternalType.fields.map((field, idx) => {
                  return (
                    <InputContainer key={`external-type-field-${idx}`} style={{ width: '100%' }}>
                      <InputLabel>
                        {t(field.name.replace('_', ' '))}
                        {field.required ? <Required>*</Required> : null}
                      </InputLabel>
                      <ExternalField
                        type={field.type}
                        index={0}
                        field={field.name}
                        value={service.external_type_data?.[field.name] || null}
                        handleUpdate={handleExternalTypeDataChange}
                        placeHolder={t(field.name)}
                        options={field.options}
                      />
                    </InputContainer>
                  );
                })}
              </RelatedFieldsContainer>
            ) : null}
            {integratedTypes?.length
              ? integratedTypes.map(type => {
                return type.fields?.length && service?.type?.integrations?.includes?.(type.value) ? (
                  <RelatedFieldsContainer key={type.value}>
                    <RelatedFieldsLabel>
                      {t('Additional configuration for {{name}}', { name: type.name })}
                    </RelatedFieldsLabel>
                    {type.fields?.map?.((field, idx) => {
                      return (
                        <InputContainer key={`integrated-type-field-${idx}`} style={{ width: '100%' }}>
                          <InputLabel>
                            {t(field.label)}
                            {field.required ? <Required>*</Required> : null}
                          </InputLabel>
                          <ExternalField
                            type={field.type}
                            index={0}
                            field={field.name}
                            value={service.integrated_type_data?.[type.value]?.[field.name] || null}
                            handleUpdate={(value, index, key) =>
                              handleIntegratedTypeDataChange(value, index, type.value, key)
                            }
                            placeHolder={t(field.label)}
                            options={field.options}
                          />
                        </InputContainer>
                      );
                    })}
                  </RelatedFieldsContainer>
                ) : null;
              })
              : null}
            <InputContainer>
              <InputLabel>{t('Receive ack emails')}</InputLabel>
              <Switch
                checked={service.ack_emails_enabled}
                defaultChecked={service.ack_emails_enabled}
                onChange={checked =>
                  handleInputChange({
                    target: {
                      value: checked,
                      name: 'ack_emails_enabled',
                    },
                  })
                }
              ></Switch>
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('Receive delivery emails')}</InputLabel>
              <Switch
                checked={service.delivery_emails_enabled}
                defaultChecked={service.delivery_emails_enabled}
                onChange={checked =>
                  handleInputChange({
                    target: {
                      value: checked,
                      name: 'delivery_emails_enabled',
                    },
                  })
                }
              ></Switch>
            </InputContainer>
            <InputContainer>
              <InputLabel>{t('Cc emails for service order requests (one email / line)')}</InputLabel>
              <TextArea
                name="cc_emails"
                label={t('Cc emails for service order requests (one email / line)')}
                value={service.cc_emails}
                type="email"
                onChange={handleInputChange}
                style={{ width: '100%' }}
                rows={4}
                //autoSize
              />
            </InputContainer>
            {showPhoneActions ? (
              <div>
                <LocalInput
                  name="phone_number"
                  label={t('Phone number')}
                  value={service.phone_number}
                  onChange={handleInputChange}
                  style={{ width: '100%' }}
                  type="text"
                />
                <InputContainer>
                  <InputLabel>{t('Send SMS notification')}</InputLabel>
                  <Tooltip
                    placement="top"
                    title={
                      modules.sms_module !== 'enabled'
                        ? t('SMS module not enabled')
                        : !service.phone_number
                            ? t('Service phone number not set')
                            : t('Click to enable / disable SMS notification')
                    }
                  >
                    <Switch
                      disabled={!(modules.sms_module === 'enabled' && service.phone_number)}
                      checked={service.sms_notification}
                      defaultChecked={service.sms_notification}
                      onChange={checked =>
                        handleInputChange({
                          target: {
                            value: checked,
                            name: 'sms_notification',
                          },
                        })
                      }
                    ></Switch>
                  </Tooltip>
                </InputContainer>
                <InputContainer>
                  <InputLabel>{t('Phone call reminder')}</InputLabel>
                  <Tooltip
                    placement="top"
                    title={
                      modules.phone_call_module !== 'enabled'
                        ? t('Phone call module not enabled')
                        : !service.phone_number
                            ? t('Service phone number not set')
                            : t('Click to enable / disable phone call reminder')
                    }
                  >
                    <Switch
                      disabled={!(modules.phone_call_module === 'enabled' && service.phone_number)}
                      checked={service.phone_call_confirmation}
                      defaultChecked={service.phone_call_confirmation}
                      onChange={checked =>
                        handleInputChange({
                          target: {
                            value: checked,
                            name: 'phone_call_confirmation',
                          },
                        })
                      }
                    ></Switch>
                  </Tooltip>
                </InputContainer>
                <LocalInput
                  name="phone_call_before_minutes"
                  label={t('Receive call before requested delivery time (minutes)')}
                  value={service.phone_call_before_minutes}
                  onChange={handleInputChange}
                  style={{ width: '100%' }}
                  type="number"
                />
                <InputContainer>
                  <InputLabel>{t('Receive call even if order line is acknowledged')}</InputLabel>
                  <Tooltip
                    placement="top"
                    title={
                      modules.phone_call_module !== 'enabled'
                        ? t('Phone call module not enabled')
                        : !service.phone_number
                            ? t('Service phone number not set')
                            : service.phone_call_ignore_ack
                              ? t('By default, call is performed regardless of whether order line is acknowledged')
                              : t('By default, call is not performed after order line is acknowledged')
                    }
                  >
                    <Switch
                      disabled={!(modules.phone_call_module === 'enabled' && service.phone_number)}
                      checked={service.phone_call_ignore_ack}
                      defaultChecked={service.phone_call_ignore_ack}
                      onChange={checked =>
                        handleInputChange({
                          target: {
                            value: checked,
                            name: 'phone_call_ignore_ack',
                          },
                        })
                      }
                    ></Switch>
                  </Tooltip>
                </InputContainer>
              </div>
            ) : null}
          </Spin>
        </ModalInner>
      </Modal>
      <div ref={actionsRef}>
        <PageActionForm
          title={t('Add service')}
          icon="plus"
          show={actions}
          style={{ top: '70px', right: '10px', zIndex: 80 }}
        >
          <Form onSubmit={handleSubmit}>
            {fields.map(field => {
              if (field === 'provider') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Service provider')}</InputLabel>
                    <DebounceAutocomplete
                      placeholder={t('Search for provider')}
                      fetchOptions={fetchServiceProviders}
                      onChange={(value, data) => handleAddNewChange(value, data, field)}
                      onSelect={(value, data) => handleAddNewChange(value, data, field)}
                      value={serviceEditName[field]}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => handleAddNewChange('', null, field)}
                    />
                  </InputContainer>
                );
              } else if (field === 'type') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Service type')}</InputLabel>
                    <DebounceAutocomplete
                      placeholder={t('Search for type')}
                      fetchOptions={fetchServiceTypes}
                      onChange={(value, data) => handleAddNewChange(value, data, field)}
                      onSelect={(value, data) => handleAddNewChange(value, data, field)}
                      value={serviceEditName[field]}
                      style={{ width: '100%' }}
                      allowClear={true}
                      onClear={() => handleAddNewChange('', null, field)}
                    />
                  </InputContainer>
                );
              } else if (field === 'external_type_data' && selectedExternalType?.fields?.length) {
                return (
                  <RelatedFieldsContainer key={field}>
                    <RelatedFieldsLabel>
                      {t('{{name}} additional configuration', { name: selectedExternalType.name })}
                    </RelatedFieldsLabel>
                    {selectedExternalType?.fields?.map?.((field, idx) => {
                      return (
                        <InputContainer key={`external-type-field-${idx}`} style={{ width: '100%' }}>
                          <InputLabel>
                            {t(field.name.replace('_', ' '))}
                            {field.required ? <Required>*</Required> : null}
                          </InputLabel>
                          <ExternalField
                            type={field.type}
                            index={0}
                            field={field.name}
                            value={service.external_type_data?.[field.name] || null}
                            handleUpdate={handleExternalTypeDataChange}
                            placeHolder={t(field.name)}
                            options={field.options}
                          />
                        </InputContainer>
                      );
                    })}
                  </RelatedFieldsContainer>
                );
              } else if (field === 'integrated_type_data' && integratedTypes?.length) {
                return integratedTypes.map(type => {
                  return type.fields?.length && service?.type?.integrations?.includes?.(type.value) ? (
                    <RelatedFieldsContainer key={type.value}>
                      <RelatedFieldsLabel>
                        {t('Additional configuration for {{name}}', { name: type.name })}
                      </RelatedFieldsLabel>
                      {type.fields?.map?.((field, idx) => {
                        return (
                          <InputContainer key={`integrated-type-field-${idx}`} style={{ width: '100%' }}>
                            <InputLabel>
                              {t(field.label)}
                              {field.required ? <Required>*</Required> : null}
                            </InputLabel>
                            <ExternalField
                              type={field.type}
                              index={0}
                              field={field.name}
                              value={service.integrated_type_data?.[type.value]?.[field.name] || null}
                              handleUpdate={(value, index, key) =>
                                handleIntegratedTypeDataChange(value, index, type.value, key)
                              }
                              placeHolder={t(field.label)}
                              options={field.options}
                            />
                          </InputContainer>
                        );
                      })}
                    </RelatedFieldsContainer>
                  ) : null;
                });
              } else if (field === 'ack_emails_enabled') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Receive ack emails')}</InputLabel>
                    <Switch
                      key={field}
                      checked={values[field]}
                      defaultChecked={values[field]}
                      onChange={checked =>
                        handleAddNewInputChange({
                          target: {
                            value: checked,
                            name: field,
                          },
                        })
                      }
                    ></Switch>
                  </InputContainer>
                );
              } else if (field === 'delivery_emails_enabled') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Receive delivery emails')}</InputLabel>
                    <Switch
                      key={field}
                      checked={values[field]}
                      defaultChecked={values[field]}
                      onChange={checked =>
                        handleAddNewInputChange({
                          target: {
                            value: checked,
                            name: field,
                          },
                        })
                      }
                    ></Switch>
                  </InputContainer>
                );
              } else if (field === 'cc_emails') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Cc emails for service order requests (one email / line)')}</InputLabel>
                    <TextArea
                      label={t('Cc emails for service order requests (one email / line)')}
                      name={field}
                      field={field}
                      value={values[field]}
                      type="email"
                      onChange={handleAddNewInputChange}
                      rows={4}
                      //autoSize
                    />
                  </InputContainer>
                );
              } else if (field === 'phone_number') {
                return (
                  <LocalInput
                    label={t('Phone number')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="text"
                    onChange={handleAddNewInputChange}
                  />
                );
              } else if (field === 'sms_notification') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Send SMS notification')}</InputLabel>
                    <Tooltip
                      placement="top"
                      title={
                        modules.sms_module !== 'enabled'
                          ? t('SMS module not enabled')
                          : !values['phone_number']
                              ? t('Service phone number not set')
                              : t('Click to enable / disable SMS notification')
                      }
                    >
                      <Switch
                        key={field}
                        disabled={!(modules.sms_module === 'enabled' && values['phone_number'])}
                        checked={values[field]}
                        defaultChecked={values[field]}
                        onChange={checked =>
                          handleAddNewInputChange({
                            target: {
                              value: checked,
                              name: field,
                            },
                          })
                        }
                      ></Switch>
                    </Tooltip>
                  </InputContainer>
                );
              } else if (field === 'phone_call_confirmation') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Phone call reminder')}</InputLabel>
                    <Tooltip
                      placement="top"
                      title={
                        modules.phone_call_module !== 'enabled'
                          ? t('Phone call module not enabled')
                          : !values['phone_number']
                              ? t('Service phone number not set')
                              : t('Click to enable / disable phone call reminder')
                      }
                    >
                      <Switch
                        key={field}
                        disabled={!(modules.phone_call_module === 'enabled' && values['phone_number'])}
                        checked={values[field]}
                        defaultChecked={values[field]}
                        onChange={checked =>
                          handleAddNewInputChange({
                            target: {
                              value: checked,
                              name: field,
                            },
                          })
                        }
                      ></Switch>
                    </Tooltip>
                  </InputContainer>
                );
              } else if (field === 'phone_call_before_minutes') {
                return (
                  <LocalInput
                    label={t('Receive call before requested delivery time (minutes)')}
                    key={field}
                    name={field}
                    field={field}
                    value={values[field]}
                    type="number"
                    onChange={handleAddNewInputChange}
                  />
                );
              } else if (field === 'phone_call_ignore_ack') {
                return (
                  <InputContainer key={field}>
                    <InputLabel>{t('Receive call even if order line is acknowledged')}</InputLabel>
                    <Tooltip
                      placement="top"
                      title={
                        modules.phone_call_module !== 'enabled'
                          ? t('Phone call module not enabled')
                          : !values['phone_number']
                              ? t('Service phone number not set')
                              : values[field]
                                ? t('Call is performed regardless of whether order line is acknowledged')
                                : t('Call is not performed after order line is acknowledged')
                      }
                    >
                      <Switch
                        key={field}
                        disabled={!(modules.phone_call_module === 'enabled' && values['phone_number'])}
                        checked={values[field]}
                        defaultChecked={values[field]}
                        onChange={checked =>
                          handleAddNewInputChange({
                            target: {
                              value: checked,
                              name: field,
                            },
                          })
                        }
                      ></Switch>
                    </Tooltip>
                  </InputContainer>
                );
              }
            })}
            <FormActions>
              <Button link disabled={!!applyChangesDisabled}>
                {t('Add service')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="created_at"
        columns={columns}
        dataSource={services}
        apiCallPending={apiCallPending}
        actions={actionList}
        spinning={servicesLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={offset}
        total={total}
        searchPlaceHolder={t('Search by name, email or phone')}
        additionalButtons={additionalButtons}
        expandedRowRender={record => <ServiceUsers service={record} />}
      />
    </ServicesList>
  );
};

export default Services;
