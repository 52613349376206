import { useEffect, useRef, useContext } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserContext } from '../../context/UserContext';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const CIRCUMFERENCE_OF_EARTH = 2 * Math.PI * 6378137;

L.Canvas.include({
  _updateMarkerVessel: function(layer) {
    if (!this._drawing || layer._empty()) {
      return;
    }

    this._ctx.globalAlpha = 1;

    if (layer.options.iconData.small.dot) {
      let pointPos = layer._point,
        ctx = this._ctx;

      ctx.beginPath();
      ctx.fillStyle = layer.options.iconData.color;
      ctx.arc(pointPos.x, pointPos.y, 4.1, 0, Math.PI * 2, false);
      ctx.closePath();
      ctx.fill();

      ctx.beginPath();
      ctx.strokeStyle = 'black';
      ctx.arc(pointPos.x, pointPos.y, 4.1, 0, Math.PI * 2, false);
      ctx.lineWidth = 0.2;
      ctx.closePath();
      ctx.stroke();
    } else {
      if (layer.options.iconData.actualSize.available && layer._map?._zoom && layer._map._zoom >= 14) {
        let pointPos = layer._point,
          ctx = this._ctx;

        this._layers[layer._leaflet_id] = layer;
        let iconData = layer.options.iconData;

        const distancePerPixelFunc = (latitude, zoomlevel) => {
          let radians = toRadians(latitude);
          let scalePixel = (CIRCUMFERENCE_OF_EARTH * Math.cos(radians)) / 2 ** (zoomlevel + 8);
          return scalePixel;
        };

        const toRadians = angle => {
          return angle * (Math.PI / 180);
        };

        const scaledSize = (distancePerPixel, value) => {
          return (1 / distancePerPixel) * value;
        };

        const {
          latitude,
          vesselRotation,
          bowStarboardAngle,
          bowPortAngle,
          sternStarboardAngle,
          sternPortAngle,
          furtherSide,
          bowMiddleAngle,
          toBow,
          toStern,
          toPort,
          toStarboard,
          width,
          beakLength,
          arrowAngle,
          speed,
        } = iconData.actualSize.vessel;

        const distancePerPixel = distancePerPixelFunc(latitude, layer._map._zoom);

        const vesselStartX =
          Math.cos(toRadians(vesselRotation - bowMiddleAngle)) *
          scaledSize(distancePerPixel, Math.sqrt((furtherSide - width / 2) ** 2 + toBow ** 2));
        const vesselStartY =
          Math.sin(toRadians(vesselRotation - bowMiddleAngle)) *
          scaledSize(distancePerPixel, Math.sqrt((furtherSide - width / 2) ** 2 + toBow ** 2));

        const vesselStartX2 =
          Math.cos(toRadians(vesselRotation + bowStarboardAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toStarboard ** 2 + (toBow - beakLength) ** 2));
        const vesselStartY2 =
          Math.sin(toRadians(vesselRotation + bowStarboardAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toStarboard ** 2 + (toBow - beakLength) ** 2));

        const vesselStartX3 =
          Math.cos(toRadians(vesselRotation + sternStarboardAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toStarboard ** 2 + toStern ** 2));
        const vesselStartY3 =
          Math.sin(toRadians(vesselRotation + sternStarboardAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toStarboard ** 2 + toStern ** 2));

        const vesselStartX5 =
          Math.cos(toRadians(vesselRotation - sternPortAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toPort ** 2 + toStern ** 2));
        const vesselStartY5 =
          Math.sin(toRadians(vesselRotation - sternPortAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toPort ** 2 + toStern ** 2));

        const vesselStartX6 =
          Math.cos(toRadians(vesselRotation - bowPortAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toPort ** 2 + (toBow - beakLength) ** 2));
        const vesselStartY6 =
          Math.sin(toRadians(vesselRotation - bowPortAngle)) *
          scaledSize(distancePerPixel, Math.sqrt(toPort ** 2 + (toBow - beakLength) ** 2));

        const arrowDirection = 140;
        const speedLength = !speed || speed === 0 ? 0 : Math.min(speed * 4, 100);
        const arrowLength = speedLength > 0 ? toBow + speedLength : 0;

        const vesselArrowX = Math.cos(toRadians(arrowAngle)) * (scaledSize(distancePerPixel, arrowLength) + 5);
        const vesselArrowY = Math.sin(toRadians(arrowAngle)) * (scaledSize(distancePerPixel, arrowLength) + 5);

        const vesselArrowX2 = Math.cos(toRadians(arrowAngle)) * (scaledSize(distancePerPixel, arrowLength) + 1);
        const vesselArrowY2 = Math.sin(toRadians(arrowAngle)) * (scaledSize(distancePerPixel, arrowLength) + 1);

        const zoom14or15 = layer._map._zoom === 14 || layer._map._zoom === 15;
        const arrowWidth = zoom14or15 ? 6 : 10;

        const vesselArrowXLeft = Math.cos(toRadians(arrowAngle - arrowDirection)) * arrowWidth;
        const vesselArrowYLeft = Math.sin(toRadians(arrowAngle - arrowDirection)) * arrowWidth;

        const vesselArrowXRight = Math.cos(toRadians(arrowAngle + arrowDirection)) * arrowWidth;
        const vesselArrowYRight = Math.sin(toRadians(arrowAngle + arrowDirection)) * arrowWidth;

        let region = new Path2D();
        region.moveTo(pointPos.x + vesselStartX, pointPos.y + vesselStartY);
        region.lineTo(pointPos.x + vesselStartX2, pointPos.y + vesselStartY2);
        region.lineTo(pointPos.x + vesselStartX3, pointPos.y + vesselStartY3);
        region.lineTo(pointPos.x + vesselStartX5, pointPos.y + vesselStartY5);
        region.lineTo(pointPos.x + vesselStartX6, pointPos.y + vesselStartY6);
        region.closePath();
        ctx.fillStyle = iconData.color;
        ctx.fill(region, 'evenodd');

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 0.4;
        ctx.moveTo(pointPos.x + vesselStartX, pointPos.y + vesselStartY);
        ctx.lineTo(pointPos.x + vesselStartX2, pointPos.y + vesselStartY2);
        ctx.lineTo(pointPos.x + vesselStartX3, pointPos.y + vesselStartY3);
        ctx.lineTo(pointPos.x + vesselStartX5, pointPos.y + vesselStartY5);
        ctx.lineTo(pointPos.x + vesselStartX6, pointPos.y + vesselStartY6);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.arc(pointPos.x, pointPos.y, scaledSize(distancePerPixel, 1), 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.stroke();

        if (arrowLength > 0) {
          ctx.beginPath();
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1;
          ctx.moveTo(pointPos.x, pointPos.y);
          ctx.lineTo(pointPos.x + vesselArrowX, pointPos.y + vesselArrowY);
          ctx.closePath();
          ctx.stroke();

          let regionArrow = new Path2D();
          regionArrow.moveTo(
            pointPos.x + vesselArrowX + vesselArrowXLeft,
            pointPos.y + vesselArrowY + vesselArrowYLeft
          );
          regionArrow.lineTo(pointPos.x + vesselArrowX, pointPos.y + vesselArrowY);
          regionArrow.lineTo(
            pointPos.x + vesselArrowX + vesselArrowXRight,
            pointPos.y + vesselArrowY + vesselArrowYRight
          );
          regionArrow.lineTo(pointPos.x + vesselArrowX2, pointPos.y + vesselArrowY2);
          regionArrow.closePath();
          ctx.fillStyle = 'black';
          ctx.fill(regionArrow, 'evenodd');
        }
      } else {
        let pointPos = layer._point,
          ctx = this._ctx;

        this._layers[layer._leaflet_id] = layer;

        let iconData = layer.options.iconData;

        let region = new Path2D();
        region.moveTo(pointPos.x + iconData.small.vessel.vesselStartX, pointPos.y + iconData.small.vessel.vesselStartY);
        region.lineTo(
          pointPos.x + iconData.small.vessel.vesselStartX2,
          pointPos.y + iconData.small.vessel.vesselStartY2
        );
        region.lineTo(
          pointPos.x + iconData.small.vessel.vesselStartX3,
          pointPos.y + iconData.small.vessel.vesselStartY3
        );
        region.lineTo(
          pointPos.x + iconData.small.vessel.vesselStartX4,
          pointPos.y + iconData.small.vessel.vesselStartY4
        );
        region.lineTo(
          pointPos.x + iconData.small.vessel.vesselStartX5,
          pointPos.y + iconData.small.vessel.vesselStartY5
        );
        region.lineTo(
          pointPos.x + iconData.small.vessel.vesselStartX6,
          pointPos.y + iconData.small.vessel.vesselStartY6
        );
        region.closePath();
        ctx.fillStyle = iconData.color;
        ctx.fill(region, 'evenodd');

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 0.4;
        ctx.moveTo(pointPos.x + iconData.small.vessel.vesselStartX, pointPos.y + iconData.small.vessel.vesselStartY);
        ctx.lineTo(pointPos.x + iconData.small.vessel.vesselStartX2, pointPos.y + iconData.small.vessel.vesselStartY2);
        ctx.lineTo(pointPos.x + iconData.small.vessel.vesselStartX3, pointPos.y + iconData.small.vessel.vesselStartY3);
        ctx.lineTo(pointPos.x + iconData.small.vessel.vesselStartX4, pointPos.y + iconData.small.vessel.vesselStartY4);
        ctx.lineTo(pointPos.x + iconData.small.vessel.vesselStartX5, pointPos.y + iconData.small.vessel.vesselStartY5);
        ctx.lineTo(pointPos.x + iconData.small.vessel.vesselStartX6, pointPos.y + iconData.small.vessel.vesselStartY6);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.fillStyle = 'white';
        ctx.arc(pointPos.x, pointPos.y, 4.1, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.arc(pointPos.x, pointPos.y, 4.1, 0, Math.PI * 2, false);
        ctx.lineWidth = 0.2;
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 0.5;
        ctx.moveTo(pointPos.x + iconData.small.arrow.lineStartX, pointPos.y + iconData.small.arrow.lineStartY);
        ctx.lineTo(pointPos.x + iconData.small.arrow.lineEndX, pointPos.y + iconData.small.arrow.lineEndY);
        ctx.moveTo(pointPos.x + iconData.small.arrow.leftLineStartX, pointPos.y + iconData.small.arrow.leftLineStartY);
        ctx.lineTo(pointPos.x + iconData.small.arrow.lineElineStartXndX, pointPos.y + iconData.small.arrow.lineStartY);
        ctx.lineTo(pointPos.x + iconData.small.arrow.rightLineEndX, pointPos.y + iconData.small.arrow.rightLineEndY);
        ctx.lineTo(pointPos.x + iconData.small.arrow.lineEndX2, pointPos.y + iconData.small.arrow.lineEndY2);
        ctx.closePath();
        ctx.stroke();
      }
    }
  },
});

let VesselMarker = L.CircleMarker.extend({
  _updatePath: function() {
    this._renderer._updateMarkerVessel(this);
  },
});

const CircleMarkers = ({ vesselsData, setSelectedAisVessel, selectedAisVessel, geoAsset }) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const map = useMap();

  const _bindPopupClick = function(e) {
    if (e.popup) {
      e.popup._closeButton.addEventListener('mouseup', _bindPopupClickHandler);
    }
  };
  const _unbindPopupClick = function(e) {
    if (e.popup) {
      e.popup._closeButton.removeEventListener('mouseup', _bindPopupClickHandler);
    }
  };

  map.on('popupopen', _bindPopupClick);
  map.on('popupclose', _unbindPopupClick);

  const _bindPopupClickHandler = function() {
    if (setSelectedAisVessel) {
      setSelectedAisVessel(undefined);
    }
  };

  const layerGroupRef = useRef(undefined);

  useEffect(() => {
    const onClick = data => {
      if (setSelectedAisVessel(undefined)) {
        setSelectedAisVessel(data.target.options.data);
      }
    };

    const toRadians = angle => {
      return angle * (Math.PI / 180);
    };

    const toCelcius = angle => {
      return angle * (180 / Math.PI);
    };

    const getSmallIconValues = (rotation, arrowDegrees) => {
      let dot = false;
      let degrees = arrowDegrees - 90;

      const lineStartX = Math.cos(toRadians(degrees)) * 3;
      const lineStartY = Math.sin(toRadians(degrees)) * 3;

      const lineEndX = Math.cos(toRadians(degrees + 180)) * 3;
      const lineEndY = Math.sin(toRadians(degrees + 180)) * 3;

      const leftLineStartX = Math.cos(toRadians(degrees - 60)) * 1.7;
      const leftLineStartY = Math.sin(toRadians(degrees - 60)) * 1.7;

      const rightLineEndX = Math.cos(toRadians(degrees + 60)) * 1.7;
      const rightLineEndY = Math.sin(toRadians(degrees + 60)) * 1.7;

      const lineEndX2 = Math.cos(toRadians(degrees)) * 2.3;
      const lineEndY2 = Math.sin(toRadians(degrees)) * 2.3;

      let vesselRotation = 0;

      if (rotation === 511) {
        dot = true;
      } else {
        vesselRotation = rotation - 90;
      }

      const vesselStartX = Math.cos(toRadians(vesselRotation)) * 8.5;
      const vesselStartY = Math.sin(toRadians(vesselRotation)) * 8.5;

      const vesselStartX2 = Math.cos(toRadians(vesselRotation + 51)) * 5;
      const vesselStartY2 = Math.sin(toRadians(vesselRotation + 51)) * 5;

      const vesselStartX3 = Math.cos(toRadians(vesselRotation + 156)) * 10;
      const vesselStartY3 = Math.sin(toRadians(vesselRotation + 156)) * 10;

      const vesselStartX4 = Math.cos(toRadians(vesselRotation + 180)) * 7.5;
      const vesselStartY4 = Math.sin(toRadians(vesselRotation + 180)) * 7.5;

      const vesselStartX5 = Math.cos(toRadians(vesselRotation - 156)) * 10;
      const vesselStartY5 = Math.sin(toRadians(vesselRotation - 156)) * 10;

      const vesselStartX6 = Math.cos(toRadians(vesselRotation - 51)) * 5;
      const vesselStartY6 = Math.sin(toRadians(vesselRotation - 51)) * 5;

      return {
        vessel: {
          vesselStartX,
          vesselStartY,
          vesselStartX2,
          vesselStartY2,
          vesselStartX3,
          vesselStartY3,
          vesselStartX4,
          vesselStartY4,
          vesselStartX5,
          vesselStartY5,
          vesselStartX6,
          vesselStartY6,
        },
        arrow: {
          lineStartX,
          lineStartY,
          lineEndX,
          lineEndY,
          leftLineStartX,
          leftLineStartY,
          rightLineEndX,
          rightLineEndY,
          lineEndX2,
          lineEndY2,
        },
        dot,
      };
    };

    const getActualSizeValues = (rotation, arrowDegrees, actualSizes, latitude, speed) => {
      const { toBow, toStern, toPort, toStarboard } = actualSizes;

      const width = toPort + toStarboard;
      const beakLength = width < toBow ? width : toBow;
      const arrowAngle = arrowDegrees - 90;

      let vesselRotation = 0;

      if (rotation !== 511) {
        vesselRotation = rotation - 90;
      }

      const bowStarboardAngle = toCelcius(Math.atan(toStarboard / (toBow - beakLength)));
      const bowPortAngle = toCelcius(Math.atan(toPort / (toBow - beakLength)));
      const sternStarboardAngle = 180 - toCelcius(Math.atan(toStarboard / toStern));
      const sternPortAngle = 180 - toCelcius(Math.atan(toPort / toStern));

      const furtherSide = Math.max(toPort, toStarboard);
      let bowMiddleAngle;

      if (furtherSide === toStarboard) {
        bowMiddleAngle = toCelcius(Math.atan(toBow / (furtherSide - width / 2))) - 90;
      } else {
        bowMiddleAngle = 90 - toCelcius(Math.atan(toBow / (furtherSide - width / 2)));
      }

      return {
        available: (toBow > 0 || toStern > 0) && (toPort > 0 || toStarboard > 0),
        vessel: {
          latitude,
          vesselRotation,
          bowStarboardAngle,
          bowPortAngle,
          sternStarboardAngle,
          sternPortAngle,
          furtherSide,
          bowMiddleAngle,
          toBow,
          toStern,
          toPort,
          toStarboard,
          width,
          beakLength,
          arrowAngle,
          speed,
        },
        arrow: {},
      };
    };

    const getIconData = (rotation, arrowDegrees, colorClass, actualSizes, latitude, speed) => {
      function getColor(color) {
        switch (color) {
          case 'vessel_blue':
            return '#0000FF';
          case 'vessel_cyan':
            return '#00FFFF';
          case 'vessel_purple':
            return '#FF00FF';
          case 'vessel_green':
            return '#00FF00';
          case 'vessel_gray':
            return '#A9A9A9';
          case 'vessel_black':
            return 'black';
          case 'vessel_white':
            return 'white';
          case 'vessel_red':
            return '#FF0000';
          case 'vessel_orange':
            return '#FFA500';
          case 'vessel_yellow':
            return '#FFFF00';
          default:
            return '#A9A9A9';
        }
      }

      return {
        small: getSmallIconValues(rotation, arrowDegrees),
        actualSize: getActualSizeValues(rotation, arrowDegrees, actualSizes, latitude, speed),
        color: getColor(colorClass),
      };
    };

    let layers = [];
    let selectedLayer;

    if (layerGroupRef.current) {
      layerGroupRef.current.clearLayers();
      layerGroupRef.current = undefined;
    }

    if (!map || !vesselsData.headers || !vesselsData.data) return;

    layerGroupRef.current = L.layerGroup();

    let nameIndex = vesselsData.headers.findIndex(h => h === 'name');
    let mmsiIndex = vesselsData.headers.findIndex(h => h === 'mmsi');
    let imoIndex = vesselsData.headers.findIndex(h => h === 'imo');
    let lonIndex = vesselsData.headers.findIndex(h => h === 'lon');
    let latIndex = vesselsData.headers.findIndex(h => h === 'lat');
    let headingDegreesIndex = vesselsData.headers.findIndex(h => h === 'heading_degrees');
    let courseOverGroundDegreesIndex = vesselsData.headers.findIndex(h => h === 'course_over_ground_degrees');
    let shipTypeIndex = vesselsData.headers.findIndex(h => h === 'shiptype');
    let toBowIndex = vesselsData.headers.findIndex(h => h === 'to_bow');
    let toSternIndex = vesselsData.headers.findIndex(h => h === 'to_stern');
    let toPortIndex = vesselsData.headers.findIndex(h => h === 'to_port');
    let toStarboardIndex = vesselsData.headers.findIndex(h => h === 'to_starboard');
    let speedIndex = vesselsData.headers.findIndex(h => h === 'speed_knots');
    let locationTimestampIndex = vesselsData.headers.findIndex(h => h === 'location_timestamp');
    let destinationIndex = vesselsData.headers.findIndex(h => h === 'destination');

    let monthIndex = vesselsData.headers.findIndex(h => h === 'month');
    let dayIndex = vesselsData.headers.findIndex(h => h === 'day');
    let hourIndex = vesselsData.headers.findIndex(h => h === 'hour');
    let minutesIndex = vesselsData.headers.findIndex(h => h === 'minute');

    const getEta = etadata => {
      if (etadata[dayIndex] === 0 || etadata[monthIndex] === 0) {
        return t('Not available');
      } else {
        let hour = etadata[hourIndex]
          ? etadata[hourIndex] < 10
            ? '0' + etadata[hourIndex]
            : etadata[hourIndex]
          : '00';
        let minute = etadata[minutesIndex]
          ? etadata[minutesIndex] < 10
            ? '0' + etadata[minutesIndex]
            : etadata[minutesIndex]
          : '00';
        return `${etadata[dayIndex]}.${etadata[monthIndex]}. ${hour}:${minute} UTC`;
      }
    };

    for (let i = 0; i < vesselsData.data.length; i++) {
      const selected = selectedAisVessel && vesselsData.data[i][mmsiIndex] === selectedAisVessel.mmsi;
      const latlng = [vesselsData.data[i][latIndex], vesselsData.data[i][lonIndex]];

      layers[i] = new VesselMarker(latlng, {
        iconData: getIconData(
          vesselsData.data[i][headingDegreesIndex] ? Math.round(vesselsData.data[i][headingDegreesIndex]) : 0,
          vesselsData.data[i][courseOverGroundDegreesIndex]
            ? Math.round(vesselsData.data[i][courseOverGroundDegreesIndex])
            : 0,
          vesselsData.vessel_type_marker_map[vesselsData.data[i][shipTypeIndex]],
          {
            toBow: vesselsData.data[i][toBowIndex],
            toStern: vesselsData.data[i][toSternIndex],
            toPort: vesselsData.data[i][toPortIndex],
            toStarboard: vesselsData.data[i][toStarboardIndex],
          },
          vesselsData.data[i][latIndex],
          vesselsData.data[i][speedIndex]
        ),
        selected: selected,
        data: {
          name: vesselsData.data[i][nameIndex],
          mmsi: vesselsData.data[i][mmsiIndex],
          lat: vesselsData.data[i][latIndex],
          lon: vesselsData.data[i][lonIndex],
        },
        tag: 'vessel_marker',
      })
        .bindPopup(
          `<div><h2>${vesselsData.data[i][nameIndex]}</h2>
          <div style="margin-top: 6px"><b>${t('AIS ETA')}:</b> ${getEta(vesselsData.data[i])}</div>
          <div style="margin-bottom: 10px"><b>${t('AIS Destination')}:</b> ${
  vesselsData.data[i][destinationIndex]
}</div>
          <div><b>${t('Type')}:</b> ${t(vesselsData.data[i][shipTypeIndex])}</div>
          <div><b>${t('MMSI')}:</b> ${vesselsData.data[i][mmsiIndex] || t('Not available')}</div>
          <div><b>${t('IMO')}:</b> ${vesselsData.data[i][imoIndex] || t('Not available')}</div>
          <div><b>${t('Latitude')}:</b> ${vesselsData.data[i][latIndex]}</div>
          <div><b>${t('Longitude')}:</b> ${vesselsData.data[i][lonIndex]}</div>
          <div><b>${t('LOA')}: </b>${vesselsData.data[i][toBowIndex] + vesselsData.data[i][toSternIndex] ||
            t('Not available')}</div>
          <div><b>${t('Beam')}: </b>${vesselsData.data[i][toPortIndex] + vesselsData.data[i][toStarboardIndex] ||
            t('Not available')}</div>
          <div><b>${t('Speed (knots)')}:</b> ${vesselsData.data[i][speedIndex] || t('Not available')}</div>
          <div><b>${t('Heading (degrees)')}:</b> ${(vesselsData.data[i][headingDegreesIndex] &&
          vesselsData.data[i][headingDegreesIndex] !== 511
  ? Math.round(vesselsData.data[i][headingDegreesIndex])
  : 0) || t('Not available')}</div>
          <div><b>${t('Course over ground (degrees)')}:</b> ${(vesselsData.data[i][courseOverGroundDegreesIndex]
  ? Math.round(vesselsData.data[i][courseOverGroundDegreesIndex])
  : 0) || t('Not available')}</div>
          <div><b>${t('Location timestamp')}:</b> ${dayjs(vesselsData.data[i][locationTimestampIndex]).fromNow()}</div>`
        )
        .bindTooltip(vesselsData.data[i][nameIndex], { direction: 'top' })
        .on('click', onClick);

      if (selected) {
        selectedLayer = layers[i];
      }
    }

    layerGroupRef.current = L.layerGroup(layers, { geoAssetCircleMarkers: geoAsset }).addTo(map);

    if (selectedLayer && map.getBounds().contains(selectedLayer._latlng)) {
      selectedLayer.openPopup();
    }
  }, [geoAsset, map, selectedAisVessel, setSelectedAisVessel, t, vesselsData]);

  return null;
};

export default CircleMarkers;
