import React, { Fragment, useEffect, useRef } from 'react';
import { Polygon, Polyline, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';

import { getOptions } from '../map/MapUtils';
import IncidentMarker from './IncidentMarker';
import WMSChart from '../map/WMSChart';

const IncidentsModalMapInnerContainerPreview = ({ appliedZoom, data, level, type }) => {
  const options = getOptions();
  const map = useMap();

  const zoomRef = useRef();

  useEffect(() => {
    map.invalidateSize();
  });

  useEffect(() => {
    if (zoomRef.current !== appliedZoom) {
      map.setView(map.getCenter(), appliedZoom);
    }

    zoomRef.current = appliedZoom;
  }, [appliedZoom]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data.length > 0 && data[0]?.geometry.type === 'Polygon') {
      let group = new L.FeatureGroup();
      data.forEach(d => {
        group.addLayer(L.polygon(L.GeoJSON.coordsToLatLngs(d.geometry.coordinates, 1)));
      });
      map.fitBounds(group.getBounds());
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TileLayer url={options.tileserver + '/{z}/{x}/{y}.png' + options.tileserver_apikey} />
      <WMSChart show={appliedZoom >= 14} />
      {data[0]?.geometry.type === 'Point' ? (
        <IncidentMarker coords={data[0]?.geometry.coordinates} level={1} />
      ) : (
        data.map((value, id) => {
          if (value.geometry.type === 'Polygon') {
            let color = '#2254c0';
            let color2 = '#052363';
            if (type === 'incident') {
              color = level > 7 ? '#F44336' : level < 4 ? '#4c914e' : '#F5BD3E';
              color2 = level > 7 ? '#800f06' : level < 4 ? '#0a400b' : '#ab8329';
            }
            let coords = L.GeoJSON.coordsToLatLngs(value.geometry.coordinates, 1);
            if (
              coords[0][0].lat !== coords[0][coords[0].length - 1].lat ||
              coords[0][0].lng !== coords[0][coords[0].length - 1].lng
            ) {
              coords[0].push(coords[0][0]);
            }
            return (
              <Fragment key={id}>
                <Polygon positions={coords} fillColor={color} fillOpacity={0.5} color={color} opacity={0.7} />
                <Polyline
                  positions={coords}
                  pathOptions={{ dashArray: [4, 4], dashOffset: 4, color: color2, weight: '2', opacity: '0.85' }}
                />
              </Fragment>
            );
          }
        })
      )}
    </>
  );
};

export default IncidentsModalMapInnerContainerPreview;
