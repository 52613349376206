import { getDateFromSliderValue } from './FleetFilterDateSlider';

const insertIf = (condition, ...elements) => {
  return condition ? elements : [];
};

export const buildQuery = (data, startTime = null, endTime = null) => {
  let status = [];
  if (data.arriving) {
    status.push({
      type: 'arriving',
      operator: 'is',
      value: true,
    });
  }
  if (data.departed) {
    status.push({
      type: 'departed',
      operator: 'is',
      value: true,
    });
  }

  let etds = [];
  let etas = [];
  if (data.etaSlider?.length) {
    const etaStart = getDateFromSliderValue(data.etaSlider[0]).format('YYYY-MM-DDTHH:mm:ssZ');
    const etaEnd = getDateFromSliderValue(data.etaSlider[1]).format('YYYY-MM-DDTHH:mm:ssZ');

    etas.push({
      type: 'eta',
      operator: 'gte',
      value: etaStart,
    });
    etas.push({
      type: 'eta',
      operator: 'lte',
      value: etaEnd,
    });
  } else if (startTime && endTime) {
    /*
    etas.push({
      type: 'eta',
      operator: 'gte',
      value: startTime,
    });*/
    etas.push({
      type: 'eta',
      operator: 'lte',
      value: endTime,
    });
  }

  if (data.etdSlider?.length) {
    const etdStart = getDateFromSliderValue(data.etdSlider[0]).format('YYYY-MM-DDTHH:mm:ssZ');
    const etdEnd = getDateFromSliderValue(data.etdSlider[1]).format('YYYY-MM-DDTHH:mm:ssZ');

    etds.push({
      type: 'etd',
      operator: 'gte',
      value: etdStart,
    });
    etds.push({
      type: 'etd',
      operator: 'lte',
      value: etdEnd,
    });
  } else if (startTime && endTime) {
    etds.push({
      type: 'etd',
      operator: 'gte',
      value: startTime,
    });
    /*
    // Disabled to allow fetching of lengthy portcalls
    etds.push({
      type: 'etd',
      operator: 'lte',
      value: endTime,
    });*/
  }

  const locodes = data.portAreaSelect?.reduce((acc, item) => {
    const [, locode] = item.value.split('_');
    acc[locode] = [];
    return acc;
  }, {});

  const portareas = data.portAreaSelect?.reduce((acc, item) => {
    const [key, locode, ...rest] = item.value.split('_');
    const portarea = rest.join('_');

    if (key === 'PA') {
      if (!acc[locode]) {
        acc[locode] = [];
      }
      acc[locode].push({
        type: 'portarea',
        value: portarea,
        operator: 'is',
      });
    }
    return acc;
  }, locodes);

  let portareaslocodes;
  if (portareas) {
    portareaslocodes = Object.entries(portareas).map(([key, value]) => ({
      and: [
        {
          type: 'locode',
          value: key,
          operator: 'is',
        },
        ...insertIf(value?.length > 0, { or: value }),
      ],
    }));
  }

  const agents = data.agent?.map(item => ({
    type: 'agent',
    value: item,
    operator: 'is',
  }));

  const vesselTypes = data.vesselType?.map(item => ({
    type: 'vessel_type_name',
    value: item,
    operator: 'is',
  }));

  const shippingCompanies = data.shippingCompany?.map(item => ({
    type: 'shipping_company',
    value: item.value,
    operator: 'is',
  }));

  const nationalities = data.nationality?.map(item => ({
    type: 'nationality',
    value: item.value,
    operator: 'is',
  }));

  let imo = [];
  if (data.imo && !isNaN(data.imo)) {
    imo.push({
      type: 'imo',
      operator: 'eq',
      value: parseInt(data.imo),
    });
  }

  const vessels = data.vessel?.reduce((acc, item) => {
    if (!isNaN(item.value)) {
      acc.push({
        type: 'imo',
        operator: 'eq',
        value: parseInt(item.value),
      });
    }
    return acc;
  }, []);

  const vtsAreas = data.vtsArea?.map(item => ({
    type: 'vts_area_name',
    value: item.value,
    operator: 'is',
  }));

  const object = {
    query: {
      //...(data.text && { ...{ text: data.text } }),
      conditions: {
        // or: [...insertIf(etas.length > 0, { and: etas }), ...insertIf(etds.length > 0, { and: etds })],
        or: [],
        and: [
          ...insertIf(etas.length > 0, { and: etas }),
          ...insertIf(etds.length > 0, { and: etds }),
          ...insertIf(portareaslocodes?.length > 0, { or: portareaslocodes }),
          ...insertIf(agents?.length > 0, { or: agents }),
          ...insertIf(vesselTypes?.length > 0, { or: vesselTypes }),
          ...insertIf(status?.length > 0, { or: status }),
          ...insertIf(imo?.length > 0, { or: imo }),
          ...insertIf(vessels?.length > 0, { or: vessels }),
          ...insertIf(nationalities?.length > 0, { or: nationalities }),
          ...insertIf(shippingCompanies?.length > 0, { or: shippingCompanies }),
          ...insertIf(vtsAreas?.length > 0, { or: vtsAreas }),
          {
            type: 'is_cancelled',
            operator: 'is',
            value: false,
          },
        ],
      },
    },
  };

  if (object.query.conditions['or'].length === 0) {
    delete object.query.conditions['or'];
  }

  if (object.query.conditions['and'].length === 0) {
    delete object.query.conditions['and'];
  }

  return object;
};

export const setFleetFilterStorageData = (namespace, data) => {
  localStorage.setItem('fleetFilter_' + namespace, JSON.stringify(data));
};

export const getFleetFilterStorageData = namespace => {
  const value = localStorage.getItem('fleetFilter_' + namespace);
  if (!value) {
    return null;
  }

  const parsedValue = JSON.parse(value);
  return parsedValue;
};

export function filterUnique(arr, ...keys) {
  return arr.filter(
    (element, index, array) => array.findIndex(element2 => keys.every(key => element2[key] === element[key])) === index
  );
}

// TODO fetch from vesseli-service
export const iso31661alpha2 = {
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, the Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  // eslint-disable-next-line quotes
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  XK: 'Kosovo',
  // eslint-disable-next-line quotes
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  // eslint-disable-next-line quotes
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia, the Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
