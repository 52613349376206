import React, { useContext } from 'react';

import { UserContext } from '../../context/UserContext';

import styled from 'styled-components';

import Icon from '../ui/Icon';
import { mobilePixelMaxWidthLimit } from '../../utils/constants';

const DecisionLabel = styled.div`
  display: block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 0 0;
  width: 218px;

  i {
    margin-left: 6px;
    margin-right: 6px;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-top: -3px;
  }

  @media (max-width: ${mobilePixelMaxWidthLimit}) {
    width: 180px;
  }
`;

const Decision = styled.span`
  align-items: center;
  flex: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.span`
  text-align: center;
`;

const DecisionEntry = styled.span`
  padding-right: ${({ theme }) => theme.sizing.gap_medium};
  text-align: center;
  flex: auto;
  display: inline-flex;
  justify-content: space-between;
`;

const DecisionButton = styled.span`
  min-width: 75px;
  padding: ${({ theme }) => theme.sizing.gap_tiny};
  margin: ${({ theme }) => theme.sizing.gap_tiny};
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  color: ${props =>
    props.warning
      ? props.theme.color.warning
      : props.cancel
        ? props.theme.color.grey_dark
        : props.theme.color.secondary};
  white-space: nowrap;

  svg {
    fill: ${props => (props.warning ? props.theme.color.warning : props.theme.color.secondary)} !important;
    margin-top: -2px;
    height: 12px;
    width: 12px;
  }

  i {
    margin-right: 6px;
  }

  &:hover {
    background-color: #eeeeee;
    border-radius: 4px;
  }
`;

const ResponseIcon = ({ responseType }) => {
  if (responseType) {
    if (responseType === 'positive') {
      return <Icon type="check-circle" color="#007AFF" fill="none" />;
    } else if (responseType === 'negative') {
      return <Icon type="x-circle" color="#D0011C" fill="none" />;
    } else if (responseType === 'neutral') {
      return <Icon type="circle" color="#407505" fill="none" />;
    }
  }
  return <Icon type="help-circle" color="#4A4A4A" fill="none" />;
};

const NotificationDecisionItem = ({ data }) => {
  const { apiCall, user } = useContext(UserContext);
  const { id, label, response_name, response_options, response_type } = data;
  const responseNames = Object.keys(response_options);

  const handleButton = async (e, id, responseName) => {
    e.preventDefault();
    await apiCall('post', 'decision-item-response', { id: id, response: responseName });
  };

  return (
    <Decision>
      <DecisionEntry>
        <DecisionLabel>
          <ResponseIcon responseType={response_type} />
          {label}
        </DecisionLabel>
      </DecisionEntry>

      <ButtonContainer>
        {user.permissions.includes('send_push_notification') && response_name && (
          <DecisionButton cancel onClick={e => handleButton(e, id, '')}>
            Cancel
          </DecisionButton>
        )}

        {responseNames.map(value => {
          return (
            <span key={value}>
              {user.permissions.includes('send_push_notification') &&
                !response_name &&
                response_options[value].type === 'positive' && (
                <DecisionButton onClick={e => handleButton(e, id, value)}>
                  <Icon type="check-dark" />
                  {value}
                </DecisionButton>
              )}
              {user.permissions.includes('send_push_notification') &&
                !response_name &&
                response_options[value].type === 'negative' && (
                <DecisionButton warning onClick={e => handleButton(e, id, value)}>
                  <Icon type="close" />
                  {value}
                </DecisionButton>
              )}
            </span>
          );
        })}
      </ButtonContainer>
    </Decision>
  );
};

export default NotificationDecisionItem;
