import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import AutoComplete from 'antd/es/auto-complete';
import Spin from 'antd/es/spin';
import Input from 'antd/es/input';

import { UserContext } from '../../context/UserContext';

const DebounceAutocomplete = ({
  fetchOptions,
  debounceTimeout = 800,
  index,
  onSelect,
  placeholder,
  allowClear,
  ...props
}) => {
  const { namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (mounted.current) {
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then(newOptions => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          if (mounted.current) {
            setOptions(newOptions);
            setFetching(false);
          }
        });
      }
    };

    return debounce(debounceTimeout, loadOptions);
  }, [fetchOptions, debounceTimeout]);

  const fetcher = e => {
    e.persist();
    let searchValue = '';

    if (e.target.value.length) {
      searchValue = e.target.value;
    }

    setOptions([]);
    setFetching(true);
    fetchOptions(searchValue).then(newOptions => {
      if (mounted.current) {
        setOptions(newOptions);
        setFetching(false);
      }
    });
  };

  return (
    <AutoComplete
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={fetcher}
      notFoundContent={fetching ? <Spin size="small" /> : t('No results')}
      onSelect={!!index || index === 0 ? (value, option) => onSelect(value, option, index) : onSelect}
      {...props}
      allowClear={allowClear}
      options={options}
    >
      <Input placeholder={placeholder} />
    </AutoComplete>
  );
};

export default DebounceAutocomplete;
