import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PAGINATION_LIMIT } from '../../utils/constants';

import { UserContext } from '../../context/UserContext';

import { v4 as uuid } from 'uuid';

import useApi from '../../hooks/useApi';
import useForm from '../../hooks/useForm';
import useToggle from '../../hooks/useToggle';

import TreeSelect from 'antd/es/tree-select';
import Modal from 'antd/es/modal';
import Popconfirm from 'antd/es/popconfirm';
import Switch from 'antd/es/switch';
import App from 'antd/es/app';

import { isPortUnikie } from '../../utils/utils';

import Button from '../ui/Button';
import Icon from '../ui/Icon';
import Form from '../ui/Form';
import Input from '../ui/Input';
import SecretText from '../ui/SecretText';

import PageActionForm from '../page/PageActionForm';
import List from '../../components/ui/List';
import ListActionButton from '../ui/ListActionButton';

const FormActions = styled.div`
  text-align: right;
  button {
    margin-bottom: 0;
  }
`;

const ModalInner = styled.div`
  padding: 24px;
`;

const InfoText = styled.div`
  text-align: left;
  margin: 32px 0 24px 0;
`;

const BindContainer = styled.div`
  text-align: left;
`;

const BindRow = styled.div`
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const BindText = styled.div`
  line-height: 31px;
  max-width: calc(100% - 22px);
`;

const RowContainerSplitButtons = styled.div`
  display: inline;
  min-width: 10px;
`;

const TrashButton = styled(Button)`
  padding: 0px;
  color: #1c1c1c;
  :hover {
    color: #d0011c;
  }
`;

const ApiKeys = () => {
  const { apiCall, namespace } = useContext(UserContext);
  const { t } = useTranslation(namespace);

  const { message } = App.useApp();

  const actionsRef = useRef();
  const [actions, showActions] = useToggle(false, actionsRef);

  const [uniqueMessageKey, setUniqueMessageKey] = useState('');
  const [addBindModalOpen, setAddBindModalOpen] = useState(undefined);
  const [deleteBindModalOpen, setDeleteBindModalOpen] = useState(undefined);
  const [bindFunction, setBindFunction] = useState(undefined);

  const [addingAvailable, setAddingAvailable] = useState(true);

  const params = new URLSearchParams(window.location.search);

  const defaultParams = {
    limit: PAGINATION_LIMIT,
    offset: params.get('offset') ? params.get('offset') : 0,
    sort: params.get('sort') ? params.get('sort') : 'name',
    search: params.get('search') ? params.get('search') : '',
  };

  let mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [newParams, setNewParams] = useState(defaultParams);
  const { loading, data, error, fetchData } = useApi('get', 'api-keys', newParams);
  const { loading: sdLoading, data: sdData, error: sdError, fetchData: sdFetchData } = useApi(
    'get',
    'service-discovery/self',
    {}
  );
  const { loading: sdpLoading, data: sdpData, error: sdpError, fetchData: sdpFetchData } = useApi(
    'get',
    'service-discovery/providers',
    {}
  );

  useEffect(() => {
    fetchData(false, newParams);
  }, [newParams, fetchData]);

  if (error) {
    message.error(error, 4);
  }

  const apiKeys = loading ? [] : data.data;

  const { start, total } = error || !data ? { start: 0, total: 0 } : data.pagination;

  const binds = sdError || !sdData ? [] : sdData.binds;
  const providers = sdpError || !sdpData ? [] : sdpData;

  const handleSave = async values => {
    setAddingAvailable(false);
    const { name } = values;
    await apiCall('post', 'api-keys', { name });
    showActions(false);
    setAddingAvailable(true);
    fetchData();
  };

  const handleCancel = e => {
    e.preventDefault();
    showActions(false);
    setAddingAvailable(true);
  };

  const handleToggle = async (id, is_active) => {
    await apiCall('put', `api-keys/${is_active ? 'disable' : 'enable'}`, { id });
    fetchData();
  };

  const fields = ['name'];
  const { values, handleChange, handleSubmit } = useForm(fields, handleSave);

  const copyToClipboard = text => {
    if (uniqueMessageKey !== '') {
      message.destroy(uniqueMessageKey);
    }
    setUniqueMessageKey(uuid());
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(text);
      message.success({
        key: uniqueMessageKey,
        content: 'API key copied to clipboard',
        duration: 3,
      });
    } else {
      window.clipboardData.setData('Text', text);
      message.success({
        key: uniqueMessageKey,
        content: 'API key copied to clipboard',
        duration: 3,
      });
    }
  };

  const portUnikie = isPortUnikie(namespace);

  const getBoundFunctions = (text, record) => {
    const filteredBinds = binds.filter(b => b.api_key === record.key);
    if (filteredBinds.length === 0) {
      return '-';
    }

    return filteredBinds.map((bind, i) => {
      const prov = providers.find(p => p.functions.findIndex(b => b.uid === bind.provider_function_uid) > -1);

      if (!prov) {
        return '-';
      }

      const func = prov.functions.find(b => b.uid === bind.provider_function_uid);

      return <div key={i}>{func ? prov.name + ': ' + func.name : '-'}</div>;
    });
  };

  const columns = [
    {
      title: t('Enabled'),
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      render: (text, record) => (
        <Switch
          disabled={portUnikie}
          defaultChecked={text}
          onChange={() => handleToggle(record.id, record.is_active)}
        ></Switch>
      ),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('API key'),
      dataIndex: 'key',
      key: 'key',
      render: text => (
        <div>
          <SecretText text={text} />
          <Button copy style={{ zIndex: '5' }} onClick={() => copyToClipboard(text)}>
            <Icon type="copy" />
          </Button>
        </div>
      ),
    },
    {
      title: t('Bound user'),
      dataIndex: 'bound_user_id',
      key: 'bound_user_id',
    },
    {
      title: t('Bound function'),
      dataIndex: 'bound_function',
      key: 'bound_function',
      infoKey: 'api-keys-bound-function-column',
      render: (text, record) => getBoundFunctions(text, record),
    },
  ];

  if (portUnikie) {
    columns.splice(2, 0, {
      title: t('Namespace'),
      dataIndex: 'namespace',
      key: 'namespace',
    });
  }

  const additionalButtons = [
    {
      onClick: () => showActions(true),
      disabled: loading,
      text: t('Add API key'),
      icon: 'key-add',
    },
  ];

  const handleDeleteBind = async uid => {
    let params = newParams;
    try {
      await apiCall('delete', 'service-discovery/bind', { uid });
    } catch (e) {
      fetchData(false, params);
      throw e;
    }
    await fetchData(false, params);
    await sdFetchData(false, {});
    await sdpFetchData(false, {});
  };

  const handleBindFunctionChange = async () => {
    let params = newParams;
    try {
      await apiCall('post', 'service-discovery/bind', {
        provider_uid: bindFunction.provider,
        provider_function_uid: bindFunction.function,
        api_key: addBindModalOpen,
      });
    } catch (e) {
      fetchData(false, params);
      throw e;
    }
    setAddBindModalOpen(false);
    setBindFunction(undefined);
    await fetchData(false, params);
    await sdFetchData(false, {});
    await sdpFetchData(false, {});
  };

  const updateBindFunction = e => {
    if (!e) {
      setBindFunction(undefined);
    } else {
      const provider = providers.find(p => p.functions.findIndex(f => f.uid === e) > -1);
      setBindFunction({ function: e, provider: provider.uid });
    }
  };

  const actionList = [
    {
      render: record => (
        <ListActionButton key="action-1" onClick={() => setAddBindModalOpen(record.key)}>
          <Icon type="add" />
          {t('Add bind')}
        </ListActionButton>
      ),
    },
    {
      render: record => (
        <ListActionButton
          key="action-2"
          red
          disabled={!binds.find(b => b.api_key === record.key)}
          onClick={() => setDeleteBindModalOpen(record.key)}
        >
          <Icon type="trash" />
          {t('Delete binds')}
        </ListActionButton>
      ),
    },
  ];

  const options = [];

  for (let index = 0; index < providers.length; index++) {
    options.push({
      title: providers[index].name,
      value: providers[index].uid,
      selectable: false,
      children: providers[index].functions.map(f => {
        if (providers[index].binds.length === 0) {
          return { title: f.name, value: f.uid, disabled: false };
        } else {
          const disabled = providers[index].binds.find(b => b.provider_function_uid === f.uid);
          return { title: f.name, value: f.uid, disabled };
        }
      }),
    });
  }

  const deleteBinds = deleteBindModalOpen ? binds.filter(b => b.api_key === deleteBindModalOpen) : [];

  return (
    <>
      {portUnikie ? (
        <span>
          <b>{t('Note')}:</b>{' '}
          {t(
            'This list is an informative overview of API keys. API key permissions are checked only within the slave. Always check API keys directly from the slave'
          )}
          .
        </span>
      ) : null}
      <div ref={actionsRef}>
        <PageActionForm title={t('Add API key')} icon="key-add" show={actions} style={{ top: '70px', right: '10px' }}>
          <Form onSubmit={handleSubmit}>
            {fields.map(field => (
              <Input
                label={field.replace(/_/g, ' ')}
                key={field}
                name={field}
                field={field}
                value={values.field}
                onChange={handleChange}
              />
            ))}
            <FormActions>
              <Button link disabled={!addingAvailable}>
                {t('Add API key')}
              </Button>
              <Button link onClick={e => handleCancel(e)}>
                {t('Cancel')}
              </Button>
            </FormActions>
          </Form>
        </PageActionForm>
      </div>
      <List
        rowKey="id"
        columns={columns}
        dataSource={apiKeys}
        spinning={loading || sdLoading || sdpLoading}
        setParams={setNewParams}
        newParams={newParams}
        start={start}
        total={total}
        searchPlaceHolder={t('Search by name or email')}
        additionalButtons={additionalButtons}
        actions={actionList}
      />

      <Modal
        title={t('Add bind for {{name}}', { name: apiKeys.find(k => k.key === addBindModalOpen)?.name })}
        open={!!addBindModalOpen}
        onOk={handleBindFunctionChange}
        onCancel={() => {
          setAddBindModalOpen(undefined);
          setBindFunction(undefined);
        }}
        okButtonProps={{ disabled: !bindFunction }}
        styles={{
          footer: { borderTop: '1px solid #d8d8d8', paddingTop: '14px', marginBottom: '-6px' },
          body: { borderTop: '1px solid #d8d8d8', marginTop: '16px' },
        }}
      >
        <ModalInner>
          <InfoText>
            {t('Choose a function from a provider to bind to {{name}}.', {
              name: apiKeys.find(k => k.key === addBindModalOpen)?.name,
            })}
          </InfoText>
          <TreeSelect
            placeholder={t('Function')}
            treeNodeFilterProp="title"
            onChange={e => updateBindFunction(e)}
            style={{ width: '100%', marginBottom: '64px' }}
            treeData={options}
            value={bindFunction ? bindFunction.function : undefined}
            treeDefaultExpandAll
            allowClear
          />
        </ModalInner>
      </Modal>

      <Modal
        title={t('Delete bind from {{name}}', { name: apiKeys.find(k => k.key === deleteBindModalOpen)?.name })}
        open={!!deleteBindModalOpen}
        onCancel={() => {
          setDeleteBindModalOpen(undefined);
          setBindFunction(undefined);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText={t('Close')}
      >
        <ModalInner>
          <InfoText style={{ marginTop: '12px' }}>
            {t('Choose a bound function to delete from {{name}}.', {
              name: apiKeys.find(k => k.key === deleteBindModalOpen)?.name,
            })}
          </InfoText>
          <BindContainer>
            {deleteBinds.map((bind, i) => {
              const prov = providers.find(p => p.functions.findIndex(b => b.uid === bind.provider_function_uid) > -1);

              if (!prov) {
                return null;
              }

              const func = prov.functions.find(b => b.uid === bind.provider_function_uid);

              if (!func) {
                return null;
              }

              return (
                <BindRow key={i} first={i === 0}>
                  <BindText>
                    {prov.name}: {func.name}
                  </BindText>
                  <RowContainerSplitButtons>
                    <Popconfirm
                      title={t('Are you sure you want to delete the bind?')}
                      onConfirm={() => handleDeleteBind(bind.uid)}
                      okText={t('Yes')}
                      okType="danger"
                      cancelText={t('No')}
                      icon={null}
                      id="pop-confirm-for-new-list"
                    >
                      <span>
                        <TrashButton
                          copy
                          style={{
                            zIndex: '5',
                            marginLeft: '0',
                            color: '#D0011C',
                          }}
                        >
                          <Icon type={'trash'} />
                        </TrashButton>
                      </span>
                    </Popconfirm>
                  </RowContainerSplitButtons>
                </BindRow>
              );
            })}
            {!!deleteBinds && deleteBinds.length === 0 && <BindText>{t('No binds to delete.')}</BindText>}
          </BindContainer>
        </ModalInner>
      </Modal>
    </>
  );
};

export default ApiKeys;
